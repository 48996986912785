<ng-container>
    <mat-card class="mat-elevation-z3">
        <mat-card-title>{{ system?.name }}</mat-card-title>
            <div mat-card-avatar class="system-box-img" #parent>
                   <img [src]="'/files/awninglinephoto/' + system?.photos[0]?.img" [alt]="system?.name" defaultImg>
            </div>
            <div [innerHTML]="system?.description" id="tinymce"></div>
        <button mat-raised-button color="primary" (click)="close()">
            {{ 'INTERFACE|Wybierz' | translate }}
        </button>
    </mat-card>
</ng-container>
