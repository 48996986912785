import {NgModule}           from '@angular/core';
import { DependenciesService } from './dependencies.service';

@NgModule({
  imports:      [ ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    DependenciesService,
  ]
})
export class DependenciesModule { }
