export default function ModalMontagesInfoCtrl($uibModalInstance, details) {
    'ngInject';

    var vm = this;
    vm.details = details;
    vm.close   = close;

    /**
     * Zamyka okno modalne
     */
    function close() {
        $uibModalInstance.close();
    }
}
