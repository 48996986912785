import angular from 'angular';
import {downgradeInjectable} from '@angular/upgrade/static';

import { SyncMethodsService } from './sync-methods.service';
import SyncWorkerFactory from './SyncWorkerFactory';

export default angular.module('icc.sync.infrastructure', [])
    .factory('SyncMethodsService', downgradeInjectable(SyncMethodsService))
    .factory('SyncWorkerFactory', SyncWorkerFactory)
    .name;
