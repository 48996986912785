import { Injectable } from '@angular/core';
import { logger } from '@icc/common/helpers';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { WindowConfiguration } from '../configurations/WindowConfiguration';

export type ValidationElement = (keyof WindowConfiguration['valid']);

@Injectable()
export class ValidationService {
    loaded: {
        [key: string]: boolean | null;
    } = {};

    isValidElements(conf: WindowActiveConfiguration | WindowConfiguration, elements: ValidationElement[], comment = '') {
        logger.debug(
            '%cVALID ' + elements.map(el => '%c' + el).join(',') + ' %c%s %s',
            'background: #b2dfdb; color: #004d40;',
            ...elements.map(el =>
                this.isValid(el, conf)
                    ? 'background: #b2dfdb; color: darkgreen'
                    : 'background: #b2dfdb; color: darkred'
            ),
            elements.every(el => this.isValid(el, conf))
                ? 'background: #b2dfdb; color: darkgreen'
                : 'background: #b2dfdb; color: darkred',
            elements.every(el => this.isValid(el, conf)) ? '✔' : '✘',
            comment
        );
        return elements.every(el => this.isValid(el, conf));
    }

    valid(conf: WindowActiveConfiguration | WindowConfiguration, element: ValidationElement) {
        logger.debug(
            '%cVALID %c%s %s',
            'background: #f9fdd2; color: #004d40;',
            'background: #f9fdd2; color: darkgreen',
            element,
            '✔'
        );
        const updatedConf = {...conf};
        if (element.indexOf('loaded') === 0) {
            this.loaded[element] = true;
        } else {
            updatedConf.valid[element] = true;
        }
        return updatedConf;
    }

    invalid(conf: WindowActiveConfiguration | WindowConfiguration, element: ValidationElement) {
        logger.debug(
            '%cVALID %c%s %s',
            'background: #f9fdd2; color: #004d40;',
            'background: #f9fdd2; color: darkred',
            element,
            '✘'
        );
        if (element.indexOf('loaded') === 0) {
            this.loaded[element] = false;
        } else {
            conf.valid[element] = false;
        }
    }

    indeterminate(conf: WindowActiveConfiguration | WindowConfiguration, element: ValidationElement) {
        logger.debug(
            '%cVALID %c%s %s',
            'background: #f9fdd2; color: #004d40;',
            'background: #f9fdd2; color: darkblue',
            element,
            '?'
        );
        if (element.indexOf('loaded') === 0) {
            this.loaded[element] = null;
        } else {
            conf.valid[element] = null;
        }
    }

    someIndeterminate(conf: WindowActiveConfiguration | WindowConfiguration, exceptions: ValidationElement[]) {
        Object.keys(conf.valid)
            .filter((element: ValidationElement) => exceptions.indexOf(element) === -1)
            .forEach((element: ValidationElement) => this.indeterminate(conf, element));
    }

    private isValid(element: ValidationElement, conf: WindowActiveConfiguration | WindowConfiguration) {
        return element.indexOf('loaded') === 0 ? Boolean(this.loaded[element]) : Boolean(conf.valid[element]);
    }
}
