import { PleatedBlindInitService } from './pleated-blind-init.service';
import { SharedActionTypes, ConfiguratorsDataLoaded } from './../../../../shared/src/lib/+state/shared.actions';
import { PleatedBlindActionTypes } from './pleated-blind.actions';
import { pleatedBlindQuery } from './pleated-blind.selectors';
import { map, withLatestFrom } from 'rxjs/operators';
import { PleatedBlindState } from './pleated-blind.reducer';
import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { fromSharedActions } from '@icc/configurator/shared';

@Injectable()
export class PleatedBlindEffects {

    @Effect()
    changedConfiguration$ = this.actions$.pipe(
        ofType(
            PleatedBlindActionTypes.SetPleatedBlindShape, 
            PleatedBlindActionTypes.SetPleatedBlindSystem, 
            PleatedBlindActionTypes.SetPleatedBlindVariant, 
            PleatedBlindActionTypes.SetPleatedBlindMainFabric,
            PleatedBlindActionTypes.SetPleatedBlindAdditionalFabric,
            PleatedBlindActionTypes.SetPleatedBlindProfilesColor,
            PleatedBlindActionTypes.SetPleatedBlindDrive,
            PleatedBlindActionTypes.SetPleatedBlindDriveSide,
            PleatedBlindActionTypes.SetPleatedBlindGuidanceType,
            PleatedBlindActionTypes.SetPleatedBlindClosureType,
        ),
        withLatestFrom(this.store.pipe(select(pleatedBlindQuery.getPleatedBlindConfiguration))),
        map(([action, configuration]) => {
            return new fromSharedActions.ConfigurationChanged({
                configuration
            });
        })
    )

    @Effect({
        dispatch: false
    })
    configuratorsDataLoaded$ = this.actions$.pipe(
        ofType<ConfiguratorsDataLoaded>(SharedActionTypes.ConfiguratorsDataLoaded),
        map(action => action.payload),
        map(payload => {
            if (payload.data) {
                this.pleatedBlindInitService.init();
            }
        })
    );

    constructor(
        private actions$: Actions,
        private store: Store<PleatedBlindState>,
        private pleatedBlindInitService: PleatedBlindInitService,
    ) {}
}
