<form [formGroup]="dimensionsForm">
  <mat-form-field>
    <mat-label translate>DIMENSION|Szerokość (S)</mat-label>
    <input matInput iccDimensionInput type="number" formControlName="width">
    <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
  </mat-form-field>
  <mat-form-field>
    <mat-label translate>DIMENSION|Wysokość (H)</mat-label>
    <input matInput iccDimensionInput type="number" formControlName="height">
    <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
  </mat-form-field>
  <mat-form-field *ngIf="hasSashDimensions$ | async">
    <mat-label translate>DIMENSION|Szerokość skrzydła</mat-label>
    <input matInput iccDimensionInput type="number" formControlName="sashWidth">
    <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
  </mat-form-field>
  <mat-form-field *ngIf="hasSashDimensions$ | async">
    <mat-label translate>DIMENSION|Wysokość skrzydła</mat-label>
    <input matInput iccDimensionInput type="number" formControlName="sashHeight">
    <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
  </mat-form-field>
  <p>
    <mat-icon>info</mat-icon>{{ 'MOSQUITO|Podane wymiary są wymiarami zewnętrznymi moskitiery (z uwzględnieniem profilu dodatkowego, jeśli będzie wybrany)' | translate }}
  </p>
</form>
