import { Action } from '@ngrx/store';
import { Shape } from '@icc/window';
import { IPleatedBlindActiveConfiguration } from '@icc/common/configurations/PleatedBlindActiveConfiguration';

export enum PleatedBlindActionTypes {
    LoadPleatedBlind = '[PleatedBlind] Load PleatedBlind',
    LoadPleatedBlindFromStartState = '[PleatedBlind] Load PleatedBlind From Start State',
    LoadPleatedBlindDefaultsFromStartState = '[PleatedBlind] Load PleatedBlind Defaults From Start State',
    LoadPleatedBlindAllDefaults = '[PleatedBlind] Load PleatedBlind All Defaults',
    LoadPleatedBlindConfiguration = '[PleatedBlind] Load PleatedBlind Configuration',
    PleatedBlindLoaded = '[PleatedBlind] PleatedBlind Loaded',
    PleatedBlindLoadError = '[PleatedBlind] PleatedBlind Load Error',
    SavePleatedBlindConfiguration = '[PleatedBlind] Save PleatedBlind Configuration',
    UpdatePleatedBlindConfiguration = '[PleatedBlind] Update PleatedBlind Configuration',
    SetPleatedBlindSystem = '[PleatedBlind System] Set system',
    SetPleatedBlindVariant = '[PleatedBlind Variant] Set variant',
    SetPleatedBlindMainFabric = '[PleatedBlind Fabric] Set main fabric',
    SetPleatedBlindAdditionalFabric = '[PleatedBlind Fabric] Set additional fabric',
    SetPleatedBlindProfilesColor = '[PleatedBlind Profiles Color] Set profiles color',
    SetPleatedBlindShape = '[PleatedBlind Dimension] Set shape',
    SetPleatedBlindDrive = '[PleatedBlind Drive] Set drive type',
    SetPleatedBlindDriveSide = '[PleatedBlind Drive] Set drive side',
    SetPleatedBlindGuidanceType = '[PleatedBlind Drive] Set guidance type',
    SetPleatedBlindClosureType = '[PleatedBlind Drive] Set closure type'
}

export class LoadPleatedBlind implements Action {
    readonly type = PleatedBlindActionTypes.LoadPleatedBlind;
}

export class PleatedBlindLoadError implements Action {
    readonly type = PleatedBlindActionTypes.PleatedBlindLoadError;
    constructor(public payload: any) {}
}

export class LoadPleatedBlindFromStartState implements Action {
    readonly type = PleatedBlindActionTypes.LoadPleatedBlindFromStartState;
    constructor(public payload: { startState: IPleatedBlindActiveConfiguration }) {}
}

export class LoadPleatedBlindDefaultsFromStartState implements Action {
    readonly type = PleatedBlindActionTypes.LoadPleatedBlindDefaultsFromStartState;
    constructor(public payload: { startState: IPleatedBlindActiveConfiguration }) {}
}

export class LoadPleatedBlindAllDefaults implements Action {
    readonly type = PleatedBlindActionTypes.LoadPleatedBlindAllDefaults;
}

export class LoadPleatedBlindConfiguration implements Action {
    readonly type = PleatedBlindActionTypes.LoadPleatedBlindConfiguration;
    constructor(public payload: { configuration: IPleatedBlindActiveConfiguration }) {}
}

export class PleatedBlindLoaded implements Action {
    readonly type = PleatedBlindActionTypes.PleatedBlindLoaded;
}

export class SavePleatedBlindConfiguration implements Action {
    readonly type = PleatedBlindActionTypes.SavePleatedBlindConfiguration;
}

export class UpdatePleatedBlindConfiguration implements Action {
    readonly type = PleatedBlindActionTypes.UpdatePleatedBlindConfiguration;
    constructor(public payload: { configuration: IPleatedBlindActiveConfiguration }) {}
}

export class SetPleatedBlindSystem implements Action {
    readonly type = PleatedBlindActionTypes.SetPleatedBlindSystem;
    constructor(public payload: { system }) {}
}

export class SetPleatedBlindVariant implements Action {
    readonly type = PleatedBlindActionTypes.SetPleatedBlindVariant;
    constructor(public payload: { variant }) {}
}

export class SetPleatedBlindMainFabric implements Action {
    readonly type = PleatedBlindActionTypes.SetPleatedBlindMainFabric;
    constructor(public payload: { mainFabric }) {}
}

export class SetPleatedBlindAdditionalFabric implements Action {
    readonly type = PleatedBlindActionTypes.SetPleatedBlindAdditionalFabric;
    constructor(public payload: { additionalFabric }) {}
}

export class SetPleatedBlindProfilesColor implements Action {
    readonly type = PleatedBlindActionTypes.SetPleatedBlindProfilesColor;
    constructor(public payload: { profilesColor }) {}
}

export class SetPleatedBlindShape implements Action {
    readonly type = PleatedBlindActionTypes.SetPleatedBlindShape;
    constructor(public payload: { Shape: Shape }) {}
}

export class SetPleatedBlindDrive implements Action {
    readonly type = PleatedBlindActionTypes.SetPleatedBlindDrive;
    constructor(public payload: { drive }) {}
}

export class SetPleatedBlindDriveSide implements Action {
    readonly type = PleatedBlindActionTypes.SetPleatedBlindDriveSide;
    constructor(public payload: { driveSide }) {}
}

export class SetPleatedBlindGuidanceType implements Action {
    readonly type = PleatedBlindActionTypes.SetPleatedBlindGuidanceType;
    constructor(public payload: { guidanceType }) {}
}

export class SetPleatedBlindClosureType implements Action {
    readonly type = PleatedBlindActionTypes.SetPleatedBlindClosureType;
    constructor(public payload: { closureType }) {}
}

export type PleatedBlindAction = LoadPleatedBlind
    | PleatedBlindLoaded
    | PleatedBlindLoadError
    | LoadPleatedBlindFromStartState
    | LoadPleatedBlindDefaultsFromStartState
    | LoadPleatedBlindAllDefaults
    | LoadPleatedBlindConfiguration
    | UpdatePleatedBlindConfiguration
    | SetPleatedBlindSystem
    | SetPleatedBlindVariant
    | SetPleatedBlindMainFabric
    | SetPleatedBlindAdditionalFabric
    | SetPleatedBlindProfilesColor
    | SetPleatedBlindShape
    | SetPleatedBlindDrive
    | SetPleatedBlindDriveSide
    | SetPleatedBlindGuidanceType
    | SetPleatedBlindClosureType;

export const fromPleatedBlindActions = {
    LoadPleatedBlind,
    PleatedBlindLoaded,
    PleatedBlindLoadError,
    LoadPleatedBlindFromStartState,
    LoadPleatedBlindDefaultsFromStartState,
    LoadPleatedBlindAllDefaults,
    LoadPleatedBlindConfiguration,
    SetPleatedBlindSystem,
    SetPleatedBlindVariant,
    SetPleatedBlindMainFabric,
    SetPleatedBlindAdditionalFabric,
    SetPleatedBlindProfilesColor,
    SetPleatedBlindShape,
    SetPleatedBlindDrive,
    SetPleatedBlindDriveSide,
    SetPleatedBlindGuidanceType,
    SetPleatedBlindClosureType
};
