/**
 * Kontroler systemu siatek
 * @param {object} $scope                 Angular Scope
 * @param {object} $uibModal                 Dostarcza obsługę okien modalnych
 * @param {object} filterFilter           Filtry
 * @param {object} MosquitoSystemsService Fabryka systemów siatek
 * @param {object} CurConfService         Liczenie cen
 */
export default function SystemsCtrl($scope, $uibModal, filterFilter, MosquitoSystemsService, CurConfService, EventBusService) { // jshint ignore:line
    'ngInject';

    var vm = this;

    vm.subStep     = 1;
    vm.systems     = [];
    vm.allSystems  = true;
    vm.systemType  = {};

    vm.selectSystem        = selectSystem;
    vm.selectType          = selectType;

    if (MosquitoSystemsService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        vm.systems = MosquitoSystemsService.systems;
    }

    /**
     * Funkcja wyboru systemu
     * @param  {object} system System
     */
    function selectSystem(system) {
        MosquitoSystemsService.selectSystem(system, true, true);
    }

    /**
     * Funkcja wyboru typu
     * @param  {object} type typ
     */
    function selectType(type) {
        vm.systemType = type;
    }

}
