import angular from 'angular';

/**
 * @ngdoc filter
 * @name ICC.Configurators.colorsByType
 *
 * @description
 * Filtruje kolory wg typu.
 */
export default function ColorsByTypeFilter() {
    'ngInject';

    /**
     * @func colorsByTypeFilter
     * @memberof colorsByType
     * @param  {object[]} colors Kolory
     * @param  {int}      group  Typ
     * @return {object[]}        Przefiltrowane kolory
     */
    return function(colors, group) {
        var colorsArr = [];
        angular.forEach(colors, function(obj) {
            colorsArr.push(obj);
        });
        return colorsArr.filter(function(color) {
            return color.type === group;
        });
    };
}
