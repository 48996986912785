import angular from 'angular';

export default function MainStepsCtrl(StepFactory, UserService) {
    'ngInject';

    var vm = this;

    // Wybór kroku
    vm.selectStep = StepFactory.selectStep.bind(StepFactory);

    vm.getStep = StepFactory.getStep.bind(StepFactory);

    vm.filterDisabled = function (el) {
        return !el.disabled && (!angular.isArray(el.disabledGroups) || (angular.isDefined(user) && angular.isDefined(user.access) && el.disabledGroups.indexOf(user.access) === -1)) && (!angular.isArray(el.disabledActions) || !findActionsInLocalStorage(el.disabledActions));
    };

    vm.mainSteps = StepFactory.getSteps();

    function findActionsInLocalStorage(groups) {
        var inSessionSorage = false;
        for (var i = 0; i < groups.length; i++) {
            if (sessionStorage.getItem(groups[i])) {
                inSessionSorage = true;
            }
        }
        return inSessionSorage;
    }

    var user = UserService.get();
}
