import {vendorModules} from '../vendor';

import * as angular from 'angular';

import Routes from './app/routes';

import iccConfigurator from './configurator/configurator.module.ajs';
import iccNewConfigurator from './new-configurator/new-configurator.module.ajs';
import iccSync from './sync/sync';
import iccPanel from './panel/panel.module.ajs';
import iccStart from './start/start';
import iccVisualizer from './visualizer/visualizer';
import { iccPriceCommon } from '@icc/price/ajs';
import iccPrice from './price/price.module.ajs';
import iccOffer from './offer/offer';
import iccComplaint from './complaint/complaint';
import iccHeader from './header/header';
import iccFooter from './footer/footer';
import iccTutorial from './tutorials/tutorials';
import iccDependencies from './dependencies/dependencies.module.ajs';
import iccGtm from './gtm/gtm';
import iccTimeLimits from '@icc/common/time-limit/time-limit.module.ajs';
import iccLicense from './license/license.module.ajs';
import AppDirectives from './app/directives/directives';
import Filters from './app/filters/filters';
import Interceptors from './app/interceptors/interceptors';

import AppCtrl from './AppCtrl';
import { Core } from '@icc/common/Core';
import { StateService } from '@icc/common/state.service';
import { TokenService } from './authorization/token.service';
import { AuthService } from './authorization/auth.service';
import { LangService } from './app/lang.service';
import {RoutesGuardsService} from './app/routes-guard.service.ajs';
import {ScrollbarService} from './app/scrollbar.service.ajs';

import {downgradeComponent, downgradeInjectable} from '@angular/upgrade/static';
import {IccDrawComponent} from '@icc/draw';
import {LogoutCtrl} from './authorization/logout.ctrl';
import {LoginCtrl} from './authorization/login.ctrl';
import { pseudolocaleInterpolation } from '@icc/common/translations/pseudolocale-interpolation';
import { Pseudolocalizer } from '@icc/helpers';
import { Store } from '@ngrx/store';

const modules = [
    ...vendorModules,
    AppDirectives,
    Filters,
    Interceptors,
    iccConfigurator,
    iccSync,
    iccPanel,
    iccStart,
    iccVisualizer,
    iccPriceCommon,
    iccPrice,
    iccOffer,
    iccComplaint,
    iccHeader,
    iccFooter,
    iccTutorial,
    iccDependencies,
    iccGtm,
    iccTimeLimits,
    iccLicense,
    iccNewConfigurator,
];

export default angular.module('icc', modules)
.controller('AppCtrl', AppCtrl)
.controller('LogoutCtrl', LogoutCtrl)
.controller('LoginCtrl', LoginCtrl)
.service('Core', Core)
.service('RoutesGuardsService', RoutesGuardsService)
.service('ScrollbarService', ScrollbarService)
.factory('StateService', downgradeInjectable(StateService))
.factory('TokenService', downgradeInjectable(TokenService))
.factory('AuthService', downgradeInjectable(AuthService))
.factory('LangService', downgradeInjectable(LangService))
.factory('Store', downgradeInjectable(Store))
.factory('pseudolocaleInterpolation', pseudolocaleInterpolation)
.directive('iccDraw', downgradeComponent({component: IccDrawComponent}) as angular.IDirectiveFactory)
.config(Routes)
.config(function($httpProvider) {
    'ngInject';

    $httpProvider.interceptors.push('IccInterceptor');
})
.config((hotkeysProvider) => {
    'ngInject';

    hotkeysProvider.includeCheatSheet = false;
    hotkeysProvider.templateTitle = 'Skróty klawiaturowe';
    hotkeysProvider.cheatSheetDescription  = 'Pokaż/ukryj ten ekran';
})
.config(function($translateProvider, tmhDynamicLocaleProvider, EnvConfig) {
    'ngInject';

    $translateProvider.useStaticFilesLoader({
        prefix: '/translations/',
        suffix: '.project.json'
    });
    $translateProvider.useSanitizeValueStrategy(null);
    $translateProvider.useMessageFormatInterpolation();
    $translateProvider.preferredLanguage(LANG_CODE);
    if (EnvConfig?.pseudolocalization?.enable) {
        Pseudolocalizer.setConfig(EnvConfig?.pseudolocalization);
        $translateProvider.useInterpolation('pseudolocaleInterpolation');
    }

    tmhDynamicLocaleProvider.localeLocationPattern('/application/dist/application/locale/{{locale}}.js');
})
.config([
    '$compileProvider',
    '$sceDelegateProvider',
    function($compileProvider, $sceDelegateProvider) {
        $sceDelegateProvider.resourceUrlWhitelist([
            'self',
            'icc-res://**'
          ]);
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|icc-res|mailto|chrome-extension|file|cdvfile):/);
        $compileProvider.imgSrcSanitizationWhitelist(/^\s*(https?|icc-res|file|cdvfile|blob|content):|data:image\//);
    },
])
.run(function ($rootScope, tmhDynamicLocale, UserService, StateService, SynchronizeService, DatabaseManager, RoutesGuardsService) {
    'ngInject';

    RoutesGuardsService.init();
    $rootScope.Math = Math;
    DatabaseManager.setEmitter($rootScope.$emit.bind($rootScope));
    tmhDynamicLocale.set(LANG_CODE);
    StateService.init();
    SynchronizeService.refreshIDB();
});
