import { IccColor, IccConstructColor, IccConstructColorLegacy, IccProfileSideColors } from '@icc/common/data-types';
import { ColorLegacy, ColorIdLegacy, ColorId } from '@icc/common/configurations/parts/common';
import { Color, ConstructionColor, ProfileSideColors } from '../../parts/common/Colors';

export function iccColorToColorLegacy(color: Partial<IccConstructColor>): ColorLegacy {
    return {
        id: Number(color.id),
        name: color.name,
        code: color.code,
        color: color.color,
        type: color.type,
        color_img: color.color_img,
        RAL: false,
        isDefault: Boolean(color.isDefault),
        groups: (color.groups || []).map(Number),
    };
}

export function iccColorToColorIdLegacy(color: Partial<IccConstructColorLegacy>): ColorIdLegacy {
    return {
        id: Number(color?.id),
        RAL: Boolean(color?.RAL),
        isDefault: Boolean(color?.isDefault),
    };
}

export function iccColorToColorId(color: Partial<IccConstructColor> | Partial<IccColor>): ColorId {
    return {
        id: Number(color?.id),
        isDefault: Boolean(color?.isDefault),
    };
}

export function getColorUid(color: ColorIdLegacy) {
    return color.id;
}

export function activeSideColorsToSideColors(
    constrColors: Record<number, ConstructionColor>,
    colors: Record<number, Color>,
    sideColors: ProfileSideColors,
    langCode: string
): IccProfileSideColors {
    return {
        outer:
            sideColors.outer && sideColors.outer.id
                ? convertName(constrColors[getColorUid(sideColors.outer)], langCode)
                : null,
        inner:
            sideColors.inner && sideColors.inner.id
                ? convertName(constrColors[getColorUid(sideColors.inner)], langCode)
                : null,
        core:
            sideColors.core && sideColors.core.id
                ? convertName(colors[getColorUid(sideColors.core)], langCode)
                : null,
        alushell:
            sideColors.alushell && sideColors.alushell.id
                ? convertName(colors[getColorUid(sideColors.alushell)], langCode)
                : null,
    };
}

function convertName<
    T extends {
        name: Record<string, string>;
    }
>(
    obj: T,
    langCode: string
): T & {
    name: string;
} {
    return {
        ...obj,
        name: obj.name[langCode],
    };
}
