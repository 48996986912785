import { Injectable } from '@angular/core';

@Injectable()
export abstract class TranslateService {
    abstract instant(
        translationId: string,
        interpolateParams?: any,
        interpolationId?: string,
        forceLanguage?: string,
        sanitizeStrategy?: string
    ): string;
}
