import { GarageDoorDefaultsService } from './../+state/garage-door-defaults.service';
import { ReinforcementsService } from './../../../../../common/src/lib/configurators/reinforcements.service';
import { isUndefined, IssuesService, IssueLevel, StepsService } from '@icc/helpers';

import { GarageDoorFacade } from './../+state/garage-door.facade';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { StepComponent, _ } from '@icc/configurator/shared';
import { Subscription } from 'rxjs';

import { Shape } from '@icc/window';
import { GarageDoorDimensions } from '@icc/common/configurations/parts/garage_door';
import { TranslateService, ConfigurationsService, EventBusService, AppConfigFactory, APP_CONFIG } from '@icc/common';
import { UnitConverterService } from '@icc/configurator/ui';

@Component({
    selector: 'icc-dimension',
    templateUrl: './dimensions.component.html',
    styleUrls: ['./dimensions.component.scss'],
})
export class DimensionsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('CONFIGURATOR|Wymiary');
    static stepIcon = {
        ligature: 'settings_overscan',
    };

    public configurator = 'pleated_blind';
    public stepId = 'montage';
    public title = _('CONFIGURATOR|Wymiary');

    private subscriptions: Subscription[] = [];

    public dimensionsForm: FormGroup = this.fb.group({});
    public dimensions: Partial<GarageDoorDimensions> | null = null;
    sizeRange;
    public fields: {
        name: string;
        field?;
        show?: boolean,
        min?: number,
        max?: number
    }[] = [
      {
        name: 'width',
        field: 'width',
        min: this.defaultsService.getMinWidth(),
        max: this.defaultsService.getMaxWidth()
      },
      {
        name: 'height',
        field: 'height',
        min: this.defaultsService.getMinHeight(),
        max: this.defaultsService.getMaxHeight()
      },
      {
        name: 'lintelHeight',
        field: 'lintelHeight',
        min: this.defaultsService.getMinLintelHeight(),
        max: this.defaultsService.getMaxLintelHeight()
      },
      {
        name: 'garageDepth',
        field: !this.config().IccConfig.Configurators.garage_door.garageDepthDisabled ? 'garageDepth' : null,
      },
      {
        name: 'leftJambWidth',
        field: !this.config().IccConfig.Configurators.garage_door.jambWidthsDisabled ? 'leftJambWidth' : null,
      },
      {
        name: 'rightJambWidth',
        field: !this.config().IccConfig.Configurators.garage_door.jambWidthsDisabled ? 'rightJambWidth' : null,
      },
    ];

    hintParams;

    dimensionUnit = this.unitConverterService.getUnit();

    constructor(
        private fb: FormBuilder,
        private garageDoorFacade: GarageDoorFacade,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private defaultsService: GarageDoorDefaultsService,
        private unitConverterService: UnitConverterService
    ) {
        super();
    }

    ngOnInit() {
      this.hintParams = {
        min: 200,
        max: 100,
        step: 5
      };
      this.garageDoorFacade.selectedDimensions$.subscribe(dimensions => {
        this.dimensions = dimensions;
      })
      const group = this.fb.group({});
      this.fields.forEach(field => {
          if (field.field) {
              group.addControl(
                  field.field,
                  this.fb.control(this.dimensions[field.field], {
                      updateOn: 'blur',
                  })
              );
          }
      });
      this.dimensionsForm = group;
      this.dimensionsForm.valueChanges.subscribe(value => {
          const newDimensions: GarageDoorDimensions = {
              ...this.dimensions,
              ...value,
          };
          this.garageDoorFacade.setDimensions(newDimensions);
      });
      this.fields = this.fields;
    }

    ngOnDestroy() {
      this.subscriptions.map(el => el.unsubscribe());
    }
}
