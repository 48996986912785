import { Injectable, Inject, Injector } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';

import {
    LoadConfigurator,
    ConfiguratorLoadError,
    ConfiguratorActionTypes,
    SaveConfiguration,
    ExitConfigurator,
    SaveInformationDialogClosed,
    SaveButtonClicked,
    SaveInformationDialog,
    SaveConfigurationRedirect,
    fromConfiguratorActions,
    OpenPrintDialog,
    PrintDialogClosed,
    CreateConfiguratorDealerOffer,
    ChangeLanguage,
    ChangeCurrency,
    StepChangedOnSave
} from './root.actions';
import { Store, select } from '@ngrx/store';
import { InitService } from '../init.service';
import { map, withLatestFrom, switchMap, filter, tap, exhaustMap } from 'rxjs/operators';
import { configuratorQuery } from './root.selectors';
import {
    ConfiguratorLoaded,
    ConfiguratorPartialState,
    ConfiguratorState,
    SharedActionTypes,
    fromSharedActions,
    LoadConfiguratorFromStartState,
    LoadConfiguratorDefaultsFromStartState,
    LoadConfiguratorAllDefaults,
    IssuesService,
    SwitchEnableStep,
    sharedQuery,
    ConfigurationChanged,
    ConfiguratorsDataLoaded,
    OpenModalConfigurator,
    CloseModalConfigurator,
    Issue
} from '@icc/configurator/shared';
import { ConfiguratorConfigurationsService } from '../configurations.service';
import { ConfiguratorDatabaseManager } from '../database-manager';
import { DatabaseManagerToken } from '@icc/helpers/browser';
import { ConfiguratorConfiguratorsDataService } from '../configurators-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { EventBusService, UserService, core, TranslateService, ConfigurationsService, AppConfigFactory, APP_CONFIG, StateService, ConfiguratorsDataService } from '@icc/common';
import { BrowserDiscountsAndMultipliersService } from '@icc/legacy/price/discounts-and-multipliers.service';
import { MeasurementsService } from '@icc/legacy/configurator/steps/window/dimensions/measurements.service';
import { combineLatest } from 'rxjs';
import { PriceService } from '@icc/price';
import { SaveInformationComponent } from '../save-information/save-information.component';
import { MatDialog } from '@angular/material/dialog';
import { ClientInfoDialogComponent } from 'libs/configurator/pages/src/lib/client-info-dialog/client-info-dialog.component';
import { ConfiguratorTranslateService } from '../translate.service';
import { AnomalyService } from '../anomaly.service';
import { ConfiguratorStepsService } from '../step/steps.service';

@Injectable()
export class ConfiguratorEffects {

    loadedConfigurator = false;

    @Effect({
        dispatch: false
    })
    changeLanguage$ = this.actions$.pipe(
        ofType<ChangeLanguage>(ConfiguratorActionTypes.ChangeLanguage),
        map((action) => {
            if (action.payload.languageCode) {
                localStorage.setItem('languageCode', action.payload.languageCode);
                window.location.reload();
            }
        })
    )

    @Effect({
        dispatch: false
    })
    changeCurrency$ = this.actions$.pipe(
        ofType<ChangeCurrency>(ConfiguratorActionTypes.ChangeCurrency),
        map((action) => {
            if (action.payload.currency) {
                localStorage.setItem('currencyCode', action.payload.currency);
            }
        })
    )

    @Effect() loadConfigurator$ = this.dataPersistence.fetch(
        ConfiguratorActionTypes.LoadConfigurator,
        {
            run: (action: LoadConfigurator) => {
                return this.initService.loadOptions(action.payload.options).pipe(
                    map(options => {
                        this.loadedConfigurator = true;
                        this.databaseManager.initAll(
                            options.options.user || {
                                id: null,
                                user_language: options.options.languageCode || 'en',
                            },
                            this.config().machine,
                            {
                                IccConfig: options.options.config,
                            }
                        );
                        if (options.options.offer) {
                            this.stateService.setState({
                                offer_id: options.options.offer.tmp_id,
                                offers  : [{
                                    doc: options.options.offer
                                }],
                                client  : null,
                                key     : null,
                                offer   : options.options.offer
                            });
                        }
                        this.userService.setUser(options.options.userData);
                        if (options.options.embeddedInB2B) {
                            this.configuratorConfiguratorsDataService.load();
                        } else {
                            this.configuratorConfiguratorsDataService.loadDataFromServer(
                                options.options
                            );
                        }
                        this.discountsAndMultipliersService.loadDataFromServer();
                        if (options.options.mode === 'layoutThumbnail' && options.options.defaultWindowLayoutId) {
                            sessionStorage.removeItem('adminMistake');
                            sessionStorage.setItem('adminThumb', String(options.options.defaultWindowLayoutId));
                        } else  {
                            sessionStorage.removeItem('adminThumb');
                        }
                        this.currentConfiguratorService.changeConfigurator(options.options.type);
                        this.initService.getAppConfig();
                        return new ConfiguratorLoaded(options);
                    })
                );
            },

            onError: (action: LoadConfigurator, error) => {
                console.error('Error', error);
                return new ConfiguratorLoadError(error);
            },
        }
    );

    @Effect()
    loadedConfigurator$ = this.actions$.pipe(
        ofType<ConfiguratorLoaded>(SharedActionTypes.ConfiguratorLoaded),
        map(action => action.payload),
        tap(() => this.store.dispatch(new fromSharedActions.CloseAllPages())),
        map(payload => {
            const options = payload.options;
            if (options.startState != null
                && options.startState.type
                && this.configuratorsDataService.loaded
                && this.oldConfiguration
            ) {
                const savedConfiguration = this.configurationsService.createSimpleConfiguration(
                    core.copy(options.startState),
                    {
                        colors: this.configuratorsDataService.data.colors || {},
                        windowColorsAll: this.configuratorsDataService.data.windowColorsAll || [],
                    },
                    this.configuratorsDataService,
                );
                this.configurationsService.init(
                    this.configurationsService.createActiveConfiguration(
                        core.copy(savedConfiguration),
                        this.configuratorsDataService,
                        options.languageCode
                    ), options.mode !== 'loadAllDefaults',
                );
                this.configurationsService.conf.measurementsAttachments = options.measurementsAttachments;
                this.currentConfiguratorService.changeConfigurator(options.type);
                this.eventBusService.post({
                    key: 'initializedConfigurator',
                    value: {},
                });
                this.priceService.syncPrice(true);
            }
            return new fromSharedActions.LoadConfiguratorAllDefaults();
        })
    );


    @Effect()
    loadConfiguratorAllDefaults$ = this.actions$.pipe(
        ofType<LoadConfiguratorAllDefaults>(SharedActionTypes.LoadConfiguratorAllDefaults),
        map(() => {
            this.configurationsService.init();
            this.priceService.syncingPrice = true;
            this.priceService.syncPrice(true);
            return new fromSharedActions.ConfigurationLoaded({
                configuration: this.configurationsService.conf.Current
            });
        })
    );

    @Effect()
    loadedWindowData$ = this.configuratorsDataService.loaded$.pipe(
        filter(loaded => loaded && this.loadedConfigurator),
        withLatestFrom(this.store.pipe(select(sharedQuery.getOptions))),
        map(([data, options]) => {
            if (options.startState != null && options.startState.type) {
                const savedConfiguration = this.configurationsService.createSimpleConfiguration(
                    core.copy(options.startState),
                    {
                        colors: this.configuratorsDataService.data.colors || {},
                        windowColorsAll: this.configuratorsDataService.data.windowColorsAll || [],
                    },
                    this.configuratorsDataService,
                );
                this.configurationsService.init(
                    this.configurationsService.createActiveConfiguration(
                        savedConfiguration,
                        this.configuratorsDataService,
                        options.languageCode
                    ),
                    options.mode !== 'loadAllDefaults',
                    options?.editedPositionPrices
                );
                this.configurationsService.conf.measurementsAttachments = options.measurementsAttachments;
            }
            if (options.defaultState != null && options.defaultState.type) {
                this.configurationsService.conf.Default = options.defaultState;
                if(options.startState == null) {
                    this.configurationsService.conf.Current = this.configurationsService.createActiveConfiguration();
                }
            }
            if (!options.startState && !options.defaultState) {
                this.configurationsService.conf.Current = this.configurationsService.createActiveConfiguration();
            }
            this.currentConfiguratorService.changeConfigurator(options.type);
            this.eventBusService.post({
                key: 'initializedConfigurator',
                value: {},
            });
            return new fromSharedActions.ConfigurationLoaded({
                configuration: this.configurationsService.conf.Current
            });
        })
    );

    @Effect()
    initalized$ = this.store.pipe(select(sharedQuery.getOptions)).pipe(
        filter(options => !!options.init$ && this.loadedConfigurator),
        switchMap(options => options.init$),
        withLatestFrom(this.store.pipe(select(sharedQuery.getOptions))),
        map(([data, options]) => {
            if (options.startState != null && options.startState.type) {
                const savedConfiguration = this.configurationsService.createSimpleConfiguration(
                    core.copy(options.startState),
                    {
                        colors: this.configuratorsDataService.data.colors || {},
                        windowColorsAll: this.configuratorsDataService.data.windowColorsAll || [],
                    },
                    this.configuratorsDataService,
                );
                this.configurationsService.init(
                    this.configurationsService.createActiveConfiguration(
                        savedConfiguration,
                        this.configuratorsDataService,
                        options.languageCode
                    ),
                    options.mode !== 'loadAllDefaults'
                );
            }
            if (options.defaultState != null && options.defaultState.type) {
                this.configurationsService.conf.Default = options.defaultState;
                if(options.startState == null) {
                    this.configurationsService.conf.Current = this.configurationsService.createActiveConfiguration();
                }
            }
            if (!options.startState && !options.defaultState) {
                this.configurationsService.conf.Current = this.configurationsService.createActiveConfiguration();
            }
            this.currentConfiguratorService.changeConfigurator(options.type);
            this.eventBusService.post({
                key: 'initializedConfigurator',
                value: {},
            });
            return new fromSharedActions.ConfigurationLoaded({
                configuration: this.configurationsService.conf.Current
            });
        })
    );

    @Effect()
    openDialog = this.actions$.pipe(
        ofType<SaveInformationDialog>(ConfiguratorActionTypes.SaveInformationDialog),
        withLatestFrom(
            this.store.pipe(select(configuratorQuery.getOptions)),
            this.configurationsService.configuration$
        ),
        tap(() => this.store.dispatch(new fromSharedActions.EnableSavingButton())),
        exhaustMap(([action, options, configuration]) => {
            const dialogRef = this.dialog.open(SaveInformationComponent, {
                data: {
                    issues: action.payload.issues || [],
                    actions: options.actions && options.actions.filter(a => a.show(configuration, action.payload.isBlockedAddToOffer)) || [],
                    message: action.payload.message || '',
                    shopMessage: true,
                },
            });
            dialogRef.afterOpened().toPromise().then(() => {});

            return dialogRef.afterClosed();
        }),
        map((result: any) => {
            return new SaveInformationDialogClosed({
                action: result
            });
        }),
    );

    @Effect({
        dispatch: false
    })
    configurationChanged$ = this.actions$.pipe(
        ofType<ConfigurationChanged>(SharedActionTypes.ConfigurationChanged),
        map(action => action.payload),
        map(payload => {
            if (payload.configuration) {
                this.configurationsService.conf.Current = payload.configuration as ConfiguratorConfigurationsService['conf']['Current'];
                this.configurationsService.updatedConfiguration();
                this.eventBusService.post({
                    key: 'configurationChanged',
                    value: {},
                })
            }
        })
    );

    @Effect()
    loadedConfiguratorsData$ = this.configuratorsDataService.loaded$.pipe(
        filter(loaded => loaded),
        map(() => {
            return new fromSharedActions.ConfiguratorsDataLoaded({
                data: this.configuratorsDataService.data
            });
        })
    );

    @Effect({
        dispatch: false
    })
    saveDialogClosed = this.actions$.pipe(
        ofType<SaveInformationDialogClosed>(ConfiguratorActionTypes.SaveInformationDialogClosed),
        map((action) => {
            if (action.payload.action) {
                action.payload.action.callback();
            }
        })
    );

    @Effect()
    saveButtonClicked$ = this.actions$.pipe(
        ofType<SaveButtonClicked>(ConfiguratorActionTypes.SaveButtonClicked),
        withLatestFrom(
            this.store.pipe(select(configuratorQuery.getOptions)),
            this.configurationsService.configuration$
        ),
        tap(() => this.store.dispatch(new fromSharedActions.DisableSavingButton())),
        switchMap(([payload, options, configuration]) => {
            const returnButton = payload.payload?.returnButton;
            const selectStepIssues = this.stepsService.selectStep('$save', false, true);

            if (Array.isArray(selectStepIssues)) {
                const message = this.translateService.instant(
                    'CONFIGURATOR|Niepoprawna konfiguracja.'
                );

                if (!selectStepIssues.length) {
                    selectStepIssues.push(new Issue({
                        key: 'step-canceled',
                        description: this.translateService.instant(
                            'CONFIGURATOR|Niepoprawna konfiguracja.'
                        ),
                        blockAddToOffer: true,
                    }));
                }

                return [
                    new SaveInformationDialog({
                        issues: [
                            ...configuration.Issues,
                            ...selectStepIssues
                        ],
                        isBlockedAddToOffer: true,
                        message,
                    })
                ];
            }
            return [
                new StepChangedOnSave({returnButton})
            ]
        })
    );

    @Effect()
    stepChangedOnSave$ = this.actions$.pipe(
        ofType<StepChangedOnSave>(ConfiguratorActionTypes.StepChangedOnSave),
        withLatestFrom(
            this.store.pipe(select(configuratorQuery.getOptions)),
            this.configurationsService.configuration$
        ),
        // tap(() => this.store.dispatch(new fromSharedActions.DisableSavingButton())),
        switchMap(([payload, options, configuration]) => {
            this.issuesService.validateAll(configuration);
            this.priceService.count(configuration);
            this.measurementsService.measurements = [];
            const returnButton = payload.payload?.returnButton;
            const isPrintAction = returnButton?.returnButton === 'PRINT' || options.returnButton === 'PRINT';
            if (this.issuesService.isBlockedAddToOffer(configuration)) {
                const message = this.translateService.instant(
                    'CONFIGURATOR|Niepoprawna konfiguracja.'
                );
                return [
                    new SaveInformationDialog({
                        issues: configuration.Issues,
                        isBlockedAddToOffer: true,
                        message,
                    })
                ];
            } else {
                if (this.config().IccConfig.Configurators.printPdfFromConfiguration && isPrintAction) {
                    return [
                        new OpenPrintDialog({returnButton, configurationShortcode: options.configurationShortcode})
                    ]
                } else if (!isPrintAction) {
                    return options.notifyCallback ? [
                        new SaveConfiguration({returnButton})
                    ] : [new SaveConfiguration({returnButton})];
                }
            }
        })
    );


    @Effect()
    openPrintDialog$ = this.actions$.pipe(
        ofType<OpenPrintDialog>(ConfiguratorActionTypes.OpenPrintDialog),
        withLatestFrom(
            this.configurationsService.configuration$
        ),
        tap(() => this.store.dispatch(new fromSharedActions.EnableSavingButton())),
        exhaustMap(([payload, configuration]) => {
            const dialogRef = this.dialog.open(ClientInfoDialogComponent, {
                data: {
                    issues: [],
                    actions: [],
                    message: '',
                    shopMessage: false,
                    configuration,
                    returnButton: payload?.payload?.returnButton,
                    configurationShortcode: payload?.payload?.configurationShortcode
                },
                height: '90vh',
                width: '90vw'
            });
            return dialogRef.afterClosed();
        }),
        map((result: any) => new PrintDialogClosed(result)),
    );

    @Effect()
    printDialogClosed$ = this.actions$.pipe(
        ofType<PrintDialogClosed>(ConfiguratorActionTypes.PrintDialogClosed),
        map((result: any) => {
            if (result && result.payload && result.payload.action === 'print') {
                const returnButton = result.payload.returnButton;
                if(result.payload.data) {
                    return new CreateConfiguratorDealerOffer({data: result.payload.data, returnButton});
                } else {
                    return new SaveConfiguration({data: result.payload.data, returnButton});
                }
            } else {
                return new fromSharedActions.EnableSavingButton()
            }
        }),
    );

    @Effect()
    createConfiguratorDealerOffer$ = this.actions$.pipe(
        ofType<CreateConfiguratorDealerOffer>(ConfiguratorActionTypes.CreateConfiguratorDealerOffer),
        switchMap((result: any) => {
            const returnButton = result.payload.returnButton;
            return this.http.post(
                (this.config().EnvConfig?.remoteHost || window.location.origin)
                + '/api/configurator-offers/create',
                {
                    data: result.payload.data
                },
            )
            .pipe(map(data => new SaveConfiguration({data, returnButton})))
        }),
    );


    @Effect()
    saveConfiguration$ = this.actions$.pipe(
        ofType<SaveConfiguration>(ConfiguratorActionTypes.SaveConfiguration),
        withLatestFrom(
            this.store.pipe(select(configuratorQuery.getOptions)),
            this.configurationsService.configuration$
        ),
        switchMap(([payload, options, configuration]) => {
            const simpleConfiguration = this.configurationsService.createSimpleConfiguration(
                configuration,
                undefined,
                undefined,
                undefined,
                options.languageCode || 'en'
            );
            this.anomalyService.checkAnomalies(simpleConfiguration);
            if (options.notifyCallback) {
                return options.notifyCallback({
                    configuration: simpleConfiguration,
                    activeConfiguration: configuration,
                    defaultConfiguration: this.configurationsService.conf.Default,
                    image: document.querySelector('.preview')
                }).pipe(map(result => {
                    const actions = options.actions && options.actions.filter(a => a.show(configuration, false)) || [];
                    if (actions && actions.length > 1) {
                        return new SaveInformationDialog({
                            issues: [],
                            isBlockedAddToOffer: false,
                            message: result.message
                        });
                    } else {
                        return new SaveConfigurationRedirect({
                            url: ''
                        })
                    }
                }));
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                const myParam = String(urlParams.get('key'));
                return this.http
                    .post<{
                        redirectUrl: string
                    }>(
                        (this.config().EnvConfig?.remoteHost || window.location.origin)
                        + '/api/configurator/save',
                        {
                            configuration: simpleConfiguration,
                            offerInfo: (payload.payload && payload.payload.data) || null,
                            returnButton: payload?.payload?.returnButton,
                            options,
                            syncDate: {
                                lastUtcConfiguratorData: this.configuratorsDataService.data.lastUtcConfiguratorData,
                                lastUtcPrice: this.configuratorsDataService.data.lastUtcPrice
                            }
                        },
                        {
                            headers: new HttpHeaders({
                                key: myParam,
                            }),
                        }
                    )
                    .pipe(map(data => new SaveConfigurationRedirect({
                            url: data?.redirectUrl ?? '',
                        })));
            }
        })
    );

    @Effect({
        dispatch: false
    })
    redirectAfterSave$ = this.actions$.pipe(
        ofType<SaveConfigurationRedirect>(ConfiguratorActionTypes.SaveConfigurationRedirect),
        map((action) => {
            if (action.payload.url) {
                window.location.href = action.payload.url;
            }
        })
    )

    @Effect({ dispatch: false })
    exitConfiguratorHome$ = this.actions$.pipe(
        ofType<ExitConfigurator>(ConfiguratorActionTypes.ExitConfiguratorHome),
        withLatestFrom(this.store.pipe(select(configuratorQuery.getOptions))),
        map(([, options]) => {
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = String(urlParams.get('key'));
            this.http
                .post(
                    (this.config().EnvConfig?.remoteHost || window.location.origin) + '/api/configurator/exit/home',
                    {
                        url: options.homeUrl || options.returnUrl || '',
                    },
                    {
                        headers: new HttpHeaders({
                            key: myParam,
                        }),
                    }
                )
                .toPromise()
                .then((data: any) => {
                    window.location.href = data.redirectUrl;
                });
        })
    );

    @Effect({ dispatch: false })
    exitConfigurator$ = this.actions$.pipe(
        ofType<ExitConfigurator>(ConfiguratorActionTypes.ExitConfigurator),
        withLatestFrom(this.store.pipe(select(configuratorQuery.getOptions))),
        map(([, options]) => {
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = String(urlParams.get('key'));
            this.http
                .post(
                    (this.config().EnvConfig?.remoteHost || window.location.origin) + '/api/configurator/exit',
                    {
                        url: options.lastUrl || options.homeUrl || options.returnUrl || '',
                    },
                    {
                        headers: new HttpHeaders({
                            key: myParam,
                        }),
                    }
                )
                .toPromise()
                .then((data: any) => {
                    window.location.href = data.redirectUrl;
                });
        })
    );

    @Effect()
    modifiedSteps$ = combineLatest([
        this.store.pipe(select(configuratorQuery.getAllSteps)),
        this.configurationsService.configuration$,
        this.store.pipe(select(configuratorQuery.getOptions)),
        this.configuratorsDataService.data$
    ]).pipe(
        filter(([steps, conf, options, data]) => {
            return options.config != null;
        }),
        map(([steps, conf, options, data]) => {
            const filteredSteps = steps
            .filter(step => step.configurator === conf.type)
            .map(step => {
                const newStepState = step.stepEnable(conf, options, data);
                return {
                    switch: step.enableRx !== newStepState,
                    id: step.id,
                    newStepState
                }
            }).filter(step => step.switch).reduce<Record<string, boolean>>((p, s) => {
                p[s.id] = s.newStepState;
                return p;
            }, {});
            return new fromSharedActions.SwitchEnableRxSteps(filteredSteps);
        }
    ));

    @Effect()
    changeButton$ = combineLatest([
        this.configurationsService.configuration$,
        this.store.pipe(select(configuratorQuery.getOptions)),
        this.configuratorsDataService.data$
    ]).pipe(
        map(([conf, options, data]) => {
            const button = typeof options.returnButton === 'function' ? options.returnButton(conf, options, data): options.returnButton;
            return new fromSharedActions.ChangeReturnButton(button);
        }
    ));

    oldConfiguration: any = null;

    @Effect()
    openModalConfigurator$ = this.actions$.pipe(
        ofType<OpenModalConfigurator>(SharedActionTypes.OpenModalConfigurator),
        withLatestFrom(
            this.store.pipe(select(configuratorQuery.getOptions)),
            this.configurationsService.configuration$
        ),
        switchMap(([action, options, configuration]) => {
            const data = action?.payload?.data || null;
            this.oldConfiguration = configuration;
            options = { ...options };
            options.type = action.payload.configuratorType;
            options.startState = this.configurationsService.createActiveConfiguration(
                this.oldConfiguration.hasRoller ? {
                    type: 'roller_shutter',
                    Height: this.oldConfiguration.RollerShutter.realRollerHeight,
                    Width: this.oldConfiguration.RollerShutter.realBoxWidth,
                    RollerShutter: this.oldConfiguration.RollerShutter
                } as any :
                data && data.type === 'roller_shutter' ? {
                    type: 'roller_shutter',
                    Height: data.height,
                    Width: data.width,
                } as any : null,
                this.configuratorsDataService,
                options.languageCode
            );
            this.currentConfiguratorService.changeConfigurator(options.type);
            return [new ConfiguratorLoaded({
                options,
                steps: this.initService.parseSteps(options.config.Configurators[options.type][this.initService.getStepsFromPreset(options.preset)], options.type),
            })];
        })
    );

    @Effect()
    closeModalConfigurator$ = this.actions$.pipe(
        ofType<CloseModalConfigurator>(SharedActionTypes.CloseModalConfigurator),
        withLatestFrom(
            this.store.pipe(select(configuratorQuery.getOptions)),
            this.configurationsService.configuration$
        ),
        switchMap(([action, options, configuration]) => {
            if (this.oldConfiguration) {
                this.oldConfiguration.RollerShutter = (configuration as any).RollerShutter;
                this.oldConfiguration.RollerShutter.Accessories = (configuration as any).Accessories;
                console.log('roller accessories', core.copy(this.oldConfiguration.RollerShutter.Accessories));
                this.oldConfiguration.hasRoller = true;
                this.oldConfiguration.RollerShutter.hasRoller = true;
                options = { ...options };
                options.type = this.oldConfiguration.type;
                options.startState = this.oldConfiguration;
                this.currentConfiguratorService.changeConfigurator(options.type);
                this.oldConfiguration = null;
                this.priceService.count(options.startState);
                return [new ConfiguratorLoaded({
                    options,
                    steps: this.initService.parseSteps(options.config.Configurators[options.type][this.initService.getStepsFromPreset(options.preset)], options.type),
                })];
            } else {
                return [];
            }
        })
    );

    constructor(
        private actions$: Actions,
        private store: Store<ConfiguratorState>,
        private dataPersistence: DataPersistence<ConfiguratorPartialState>,
        private initService: InitService,
        private issuesService: IssuesService,
        private configurationsService: ConfigurationsService,
        private configuratorConfiguratorsDataService: ConfiguratorConfiguratorsDataService,
        private userService: UserService,
        private discountsAndMultipliersService: BrowserDiscountsAndMultipliersService,
        @Inject(DatabaseManagerToken) private databaseManager: ConfiguratorDatabaseManager,
        private currentConfiguratorService: CurrentConfiguratorService,
        private http: HttpClient,
        private configuratorsDataService: ConfiguratorsDataService,
        private priceService: PriceService,
        private eventBusService: EventBusService,
        private dialog: MatDialog,
        private translateService: ConfiguratorTranslateService,
        private measurementsService: MeasurementsService,
        private stateService: StateService,
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
        private anomalyService: AnomalyService,
        private stepsService: ConfiguratorStepsService,
    ) {}
}
