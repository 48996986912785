import { isUndefined } from '@icc/helpers';

/**
 * Funkcja liczenia szyb
 */
export function GlassCountFilter() {
    return function(glass, count) {
        const glassArr = [];
        Object.keys(glass).forEach(key => {
            glassArr.push(glass[key]);
        });
        if (isUndefined(count) || count === null || count === '') {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {
                return Number(count) === Number(gl.glazing_count);
            });
        }
    };
}
