import angular from 'angular';

export default function CheckDeleteFilter() {
    'ngInject';

    return function(input) {
        if (typeof input == 'undefined') {
            return false;
        }
        var arr = [];
        angular.forEach(input, function(v) {
            if (!!!v.doc.delete) {
                arr.push(v);
            }
        });
        return arr;
    };
}
