export default function JoinFilter() {
    'ngInject';

    return function(n) {
        if (Array.isArray(n)) {
            return n.join('');
        } else {
            try {
                var o = JSON.parse(n);
                if (Array.isArray(o)) {
                    return o.join('');
                }
            } catch (e) {
                return n;
            }
        }
    };
}
