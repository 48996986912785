<icc-select-box
    [title]="'COLOR|Kolor profili obramowania furtki' | translate"
    [description]="(selectedWicketDoorFrameProfilesColor$ | async)?.name"
    [color]="'#' + (selectedWicketDoorFrameProfilesColor$ | async)?.color"
    [imageUrl]="'/files/garagedoorcolor/' + (selectedWicketDoorFrameProfilesColor$ | async)?.color_img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [colorSelect]="true"
    (select)="selectWicketDoorFrameProfilesColor()"
></icc-select-box>

<icc-select-box
    [title]="'COLOR|Kolor klamki' | translate"
    [description]="(selectedWicketDoorHandleColor$ | async)?.name"
    [color]="'#' + (selectedWicketDoorHandleColor$ | async)?.color"
    [imageUrl]="'/files/garagedoorcolor/' + (selectedWicketDoorHandleColor$ | async)?.color_img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [colorSelect]="true"
    (select)="selectWicketDoorHandleColor()"
></icc-select-box>
