import 'angular-ui-bootstrap';

/**
 * Kontroler modal'a zatwierdzenia oferty przez klienta b2c
 *
 * @export
 * @class ModalClientConfirmOfferCtrl
 */
export class ModalClientConfirmOfferCtrl {

    options;
    cityInvalid;
    postCodeInvalid;
    clientEmailInvalid;
    consentsInvalid = [];

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private $filter,
        public offer,
        public user,
    ) {
        'ngInject';

        this.options = {
            clientEmail: '',
            contact: false,
            city: '',
            postCode: '',
            phone: '',
            comment: '',
            consents: [],
            info: [],
        };

        this.options.consents = user.consents.filter(c => c.type === 'consent')
            .map(c => (
                {
                    ...c,
                    short_description: this.removePTags(c.short_description),
                    selected: false,
                    showDescription: false,
                    hasDescription: c.long_description.replace(/<[^>]*>/g, '').trim().length > 0,
                    afterContact: c.places.includes('contact')
                }
            )) ?? [],
        this.options.info = user.consents.filter(i => i.type === 'information')
            .map(i => (
                {
                    ...i,
                    short_description: this.removePTags(i.short_description),
                    showDescription: false,
                    hasDescription: i.long_description.replace(/<[^>]*>/g, '').trim().length > 0,
                    afterContact: i.places.includes('contact')
                }
            )) ?? [],
        this.cityInvalid = false;
        this.postCodeInvalid = false;
        this.clientEmailInvalid = false;
        this.options.consents.forEach(consent => (
            this.consentsInvalid[consent.id] = consent.required ? true : false
        ));
    }

    /**
     * Zamyka modal.
     *
     * @memberOf ModalClientConfirmOfferCtrl
     */
    send() {
        const clientOptions = {
            clientEmail: this.options.clientEmail,
            contact: this.options.contact,
            city: this.options.contact ? this.options.city : '',
            postCode: this.options.contact ? this.options.postCode : '',
            phone: this.options.contact ? this.options.phone : '',
            comment: this.options.contact ? this.options.comment : '',
            consents: this.options.contact ? this.options.consents.filter(c => c.selected).map(c => ({title: c.short_description, description: c.long_description})) : [],
        };
        if(this.formValid()) {
            this.$uibModalInstance.close(clientOptions);
        }

    }

    /**
     * Zamyka modal.
     *
     * @memberOf ModalClientConfirmOfferCtrl
     */
    close() {
        this.$uibModalInstance.close();
    }

    formValid() {
        let valid = true;
        this.cityInvalid = false;
        this.postCodeInvalid = false;
        this.clientEmailInvalid = false;
        if(this.options.contact && !(this.options.city || this.options.postCode)) {
            valid = false;
            this.cityInvalid = true;
            this.postCodeInvalid = true;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if(!this.options.clientEmail || !emailRegex.test(this.options.clientEmail)) {
            valid = false;
            this.clientEmailInvalid = true;
        }

        this.options.consents.forEach(consent => {
            const consentsInValid = consent.required
                ? consent.afterContact
                    ? this.options.contact
                        ? !consent.selected
                        : false
                    : !consent.selected
                : false;

            this.consentsInvalid[consent.id] = consentsInValid;
            if(consentsInValid) {
                valid = false;
            }
        });

        return valid
    }

    formatPhone() {
        const phone = this.options.phone.replace(/\D/g, '');

        if (phone.length === 9) {
            this.options.phone = phone.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
        } else if(phone.length === 7 ) {
            this.options.phone = phone.replace(/(\d{3})(\d{2})(\d{2})/, '$1 $2 $3');
        } else {
            this.options.phone = phone.replace(/(\d{3})(?=\d)/g, '$1 ');
        }
    }

    removePTags(textWithPTags: string): string {
        const regex = /<\/?p[^>]*>/g;
        return textWithPTags.replace(regex, '');
      }
}
