/**
 * Kontroler kolorow
 * @param {object} $scope              Scope
 * @param {object} MosquitoColorsService Fabryka kolorow bram
 */
export default function ColorsCtrl($scope, MosquitoColorsService) {
    'ngInject';

    var vm = this;

    vm.valid             = MosquitoColorsService.validate.bind(MosquitoColorsService);
    vm.openModalColor    = MosquitoColorsService.openModalColor.bind(MosquitoColorsService);

    $scope.$on('changedStep', function(event, newVal, oldVal) {
        if (newVal.code != 'color' && oldVal.code == 'color' && newVal.code !== oldVal.code) {
            vm.valid();
        }
    });
}
