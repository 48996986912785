<ng-container *ngIf="mode === 'list'" class = "systemItemsList">
    <icc-list
        [tabs]="!filtering ? tabs : []"
        [items]="systems"
        itemTemplate="itemImgTitle"
        [itemImgHeight]="isAnySystemDoorType ? 'high' : 'medium'"
        (select)="selectSystem($event)"
        [selected]="systemId$ | async"
        (showInfo)="showInfo($event)"
        (tabChange)="tabChange($event)"
    ></icc-list>
</ng-container>

<ng-container *ngIf="mode === 'details'">
    <mat-card class="mat-elevation-z3">
        <mat-card-header>
            <div mat-card-avatar class="system-box-img" #parent>
                   <img [src]="'/files/windowline/' + (system$ | async)?.title_image" [alt]="(system$ | async)?.name" defaultImg>
            </div>
            <mat-card-title class="system-box-title">{{ (system$ | async)?.name }}</mat-card-title>
            <mat-card-subtitle class="buttons">
                <button mat-raised-button color="primary" (click)="openList()">
                    {{ 'INTERFACE|Zmień' | translate }}
                </button>
            </mat-card-subtitle>
        </mat-card-header>
    </mat-card>

    <mat-card class="mat-elevation-z3" *ngIf='showProfileSetInSystemStep'>
            <mat-card-header class = "profileSet">
                <div class = "title">{{ 'WINDOW|Zestaw profili' | translate }}</div>
                <button mat-raised-button color = "primary" (click)="profileSet($event)">{{ 'INTERFACE|Zmień' | translate }}</button>
            </mat-card-header>
    </mat-card>


</ng-container>


