import {
    Directive,
    HostListener,
    ElementRef,
    Input,
    AfterViewInit,
    ChangeDetectorRef,
    OnInit,
    OnDestroy,
    OnChanges,
} from '@angular/core';
import { SharedFacade } from '../+state/shared.facade';
import { Subscription, fromEvent } from 'rxjs';
import { DrawService } from '@icc/common/configurators/draw.service';

@Directive({
    selector: '[iccDrawRefresh]',
})
export class DrawRefreshDirective implements AfterViewInit, OnInit, OnDestroy, OnChanges {
    @Input() parentElement?: HTMLElement;

    private subscriptions: Subscription[] = [];

    constructor(
        private el: ElementRef,
        private cdRef: ChangeDetectorRef,
        private sharedFacade: SharedFacade,
        private drawService: DrawService
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.sharedFacade.previewAnimationDone$.subscribe(() => {
                this.onResize();
                this.cdRef.detectChanges();
            })
        );

        if (this.parentElement) {
          this.subscriptions.push(fromEvent(this.parentElement, 'resize').subscribe(() => {
            this.onResize();
          }))
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    ngOnChanges() {
        this.resize();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?: any) {
        this.resize();
    }

    public resize() {
        this.drawService.details.timestamp = Date.now();
        this.cdRef.detectChanges();
    }

    ngAfterViewInit() {
        this.onResize();
        this.cdRef.detectChanges();
    }
}
