import { Component, OnInit, Input, ApplicationRef, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'icc-new-configurator',
    templateUrl: './new-configurator.component.html',
    styles: [],
})
export class NewConfiguratorComponent implements OnInit {
    @Input() options: any;

    constructor(private applicationRef: ApplicationRef, private cdr: ChangeDetectorRef) {
        console.log('NewConfiguratorComponent constructor', applicationRef);
        requestAnimationFrame(() => cdr.detectChanges());
    }

    ngOnInit() {
        console.log('NewConfiguratorComponent ngOnInit', this.options);
    }
}
