import { Action } from '@ngrx/store';
import { IGarageDoorActiveConfiguration } from '@icc/common/configurations/GarageDoorActiveConfiguration';
import { PanelsData } from '@icc/common/configurations/parts/garage_door/PanelsData';

export enum GarageDoorActionTypes {
    UpdateGarageDoorConfiguration =                 '[GarageDoor] Update GarageDoor Configuration',
    SetModalListType =                              '[GarageDoor ModalList Set Modal List Type',
    SetGarageDoorSystem =                           '[GarageDoor System] Set system',
    SetGarageDoorDimensions =                       '[GarageDoor Dimensions] Set dimensions',
    SetGarageDoorMechanism =                        '[GarageDoor Mechanism] Set Mechanism',
    SetGarageDoorGuidance =                         '[GarageDoor Mechanism] Set Guidance',
    SetGarageDoorPanelsData =                       '[GarageDoor Panel] Set Panels Data',
    SetGarageDoorGlazingsGlobalImposts =            '[GarageDoor Inserts] Set Glazings Global Imposts',
    SetGarageDoorWindowsGlobalType =                '[GarageDoor Inserts] Set Windows Global Type',
    SetGarageDoorWindowsGlobalGlazing =             '[GarageDoor Inserts] Set Windows Global Glazing',
    SetGarageDoorGlazingPanelsGlobalGlazing =       '[GarageDoor Inserts] Set Glazing Panels Global Glazing',
    SetGarageDoorWindowFramesGlobalOuterColor =     '[GarageDoor Inserts] Set Windows Frames Global Outer Color',
    SetGarageDoorGlazingPanelsGlobalColor =         '[GarageDoor Inserts] Set Glazing Panels Global Color',
    SetGarageDoorVentilationGratesGlobalOuterColor ='[GarageDoor Inserts] Set Ventilation Grates Global Outer Color',
    SetGarageDoorPanel =                            '[GarageDoor Panel] Set Panel',
    SetGarageDoorPanelInternal =                    '[GarageDoor Panel] Set Panel Internal',
    SetGarageDoorPanelStructure =                   '[GarageDoor Panel] Set Panel Structure',
    SetGarageDoorPanelInternalStructure =           '[GarageDoor Panel] Set Panel Internal Structure',
    SetGarageDoorPlinthPanel =                      '[GarageDoor Panel] Set Plinth Panel',
    SetGarageDoorPlinthPanelStructure =             '[GarageDoor Panel] Set Plinth Panel Structure',
    SetGarageDoorPanelColor =                       '[GarageDoor Panel] Set Panel Color',
    SetGarageDoorPanelInternalColor =               '[GarageDoor Panel] Set Panel Internal Color',
    SetGarageDoorDrive =                            '[GarageDoor Drive] Set Drive',
    SetGarageDoorDriveType =                        '[GarageDoor Drive] Set Drive Type',
    SetGarageDoorDriveSide =                        '[GarageDoor Drive] Set Drive Side',
    SetGarageDoorWicketDoor =                       '[GarageDoor WicketDoor] Set Wicket Door',
    SetGarageDoorWicketDoorDirection =              '[GarageDoor WicketDoor] Set Wicket Door Direction',
    SetGarageDoorWicketDoorHandleColor =            '[GarageDoor WicketDoor] Set Wicket Door Handle Color',
    SetGarageDoorWicketDoorFrameProfilesColor =     '[GarageDoor WicketDoor] Set Wicket Door Frame Profiles Color'
}

export class SetModalListType implements Action {
    readonly type = GarageDoorActionTypes.SetModalListType;
    constructor(public payload: { modalListType }) {}
}

export class SetGarageDoorSystem implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorSystem;
    constructor(public payload: { system }) {}
}

export class SetGarageDoorDimensions implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorDimensions;
    constructor(public payload: { dimensions }) {}
}

export class SetGarageDoorMechanism implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorMechanism;
    constructor(public payload: { mechanism }) {}
}

export class SetGarageDoorGuidance implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorGuidance;
    constructor(public payload: { guidance }) {}
}

export class SetGarageDoorPanelsData implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorPanelsData;
    constructor(public payload: PanelsData) {}
}

export class SetGarageDoorGlazingsGlobalImposts implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorGlazingsGlobalImposts;
    constructor(public payload: { glazingsGlobalImposts }) {}
}

export class SetGarageDoorWindowsGlobalType implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorWindowsGlobalType;
    constructor(public payload: { windowsGlobalType }) {}
}

export class SetGarageDoorWindowFramesGlobalOuterColor implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorWindowFramesGlobalOuterColor;
    constructor(public payload: { windowFramesGlobalOuterColor }) {}
}

export class SetGarageDoorGlazingPanelsGlobalColor implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorGlazingPanelsGlobalColor;
    constructor(public payload: { glazingPanelsGlobalColor }) {}
}

export class SetGarageDoorWindowsGlobalGlazing implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorWindowsGlobalGlazing;
    constructor(public payload: { windowsGlobalGlazing }) {}
}

export class SetGarageDoorGlazingPanelsGlobalGlazing implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorGlazingPanelsGlobalGlazing;
    constructor(public payload: { glazingPanelsGlobalGlazing }) {}
}

export class SetGarageDoorVentilationGratesGlobalOuterColor implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorVentilationGratesGlobalOuterColor;
    constructor(public payload: { ventilationGratesGlobalOuterColor }) {}
}

export class SetGarageDoorPanel implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorPanel;
    constructor(public payload: { panel }) {}
}

export class SetGarageDoorPanelInternal implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorPanelInternal;
    constructor(public payload: { panelInternal }) {}
}

export class SetGarageDoorPanelStructure implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorPanelStructure;
    constructor(public payload: { panelStructure }) {}
}

export class SetGarageDoorPanelInternalStructure implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorPanelInternalStructure;
    constructor(public payload: { panelInternalStructure }) {}
}

export class SetGarageDoorPlinthPanel implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorPlinthPanel;
    constructor(public payload: { plinthPanel }) {}
}

export class SetGarageDoorPlinthPanelStructure implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorPlinthPanelStructure;
    constructor(public payload: { plinthPanelStructure }) {}
}

export class SetGarageDoorPanelColor implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorPanelColor;
    constructor(public payload: { panelColor }) {}
}

export class SetGarageDoorPanelInternalColor implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorPanelInternalColor;
    constructor(public payload: { panelInternalColor }) {}
}

export class SetGarageDoorDrive implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorDrive;
    constructor(public payload: { drive }) {}
}

export class SetGarageDoorDriveType implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorDriveType;
    constructor(public payload: { driveType }) {}
}

export class SetGarageDoorDriveSide implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorDriveSide;
    constructor(public payload: { driveSide }) {}
}

export class SetGarageDoorWicketDoor implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorWicketDoor;
    constructor(public payload: { wicketDoor }) {}
}

export class SetGarageDoorWicketDoorDirection implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorWicketDoorDirection;
    constructor(public payload: { wicketDoorDirection }) {}
}

export class SetGarageDoorWicketDoorHandleColor implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorWicketDoorHandleColor;
    constructor(public payload: { wicketDoorHandleColor }) {}
}

export class SetGarageDoorWicketDoorFrameProfilesColor implements Action {
    readonly type = GarageDoorActionTypes.SetGarageDoorWicketDoorFrameProfilesColor;
    constructor(public payload: { wicketDoorFrameProfilesColor }) {}
}

export class UpdateGarageDoorConfiguration implements Action {
    readonly type = GarageDoorActionTypes.UpdateGarageDoorConfiguration;
    constructor(public payload: { configuration: IGarageDoorActiveConfiguration }) {}
}

export type GarageDoorAction = UpdateGarageDoorConfiguration
    | SetModalListType
    | SetGarageDoorSystem
    | SetGarageDoorDimensions
    | SetGarageDoorMechanism
    | SetGarageDoorGuidance
    | SetGarageDoorPanelsData
    | SetGarageDoorGlazingsGlobalImposts
    | SetGarageDoorWindowsGlobalType
    | SetGarageDoorWindowsGlobalGlazing
    | SetGarageDoorGlazingPanelsGlobalGlazing
    | SetGarageDoorWindowFramesGlobalOuterColor
    | SetGarageDoorGlazingPanelsGlobalColor
    | SetGarageDoorVentilationGratesGlobalOuterColor
    | SetGarageDoorPanel
    | SetGarageDoorPanelInternal
    | SetGarageDoorPanelStructure
    | SetGarageDoorPlinthPanel
    | SetGarageDoorPlinthPanelStructure
    | SetGarageDoorPanelInternalStructure
    | SetGarageDoorPanelColor
    | SetGarageDoorPanelInternalColor
    | SetGarageDoorDrive
    | SetGarageDoorDriveType
    | SetGarageDoorDriveSide
    | SetGarageDoorWicketDoor
    | SetGarageDoorWicketDoorDirection
    | SetGarageDoorWicketDoorHandleColor
    | SetGarageDoorWicketDoorFrameProfilesColor;

export const fromGarageDoorActions = {
    UpdateGarageDoorConfiguration,
    SetModalListType,
    SetGarageDoorSystem,
    SetGarageDoorDimensions,
    SetGarageDoorMechanism,
    SetGarageDoorGuidance,
    SetGarageDoorPanelsData,
    SetGarageDoorGlazingsGlobalImposts,
    SetGarageDoorWindowsGlobalType,
    SetGarageDoorWindowsGlobalGlazing,
    SetGarageDoorGlazingPanelsGlobalGlazing,
    SetGarageDoorWindowFramesGlobalOuterColor,
    SetGarageDoorGlazingPanelsGlobalColor,
    SetGarageDoorVentilationGratesGlobalOuterColor,
    SetGarageDoorPanel,
    SetGarageDoorPanelInternal,
    SetGarageDoorPanelStructure,
    SetGarageDoorPanelInternalStructure,
    SetGarageDoorPlinthPanel,
    SetGarageDoorPlinthPanelStructure,
    SetGarageDoorPanelColor,
    SetGarageDoorPanelInternalColor,
    SetGarageDoorDrive,
    SetGarageDoorDriveType,
    SetGarageDoorDriveSide,
    SetGarageDoorWicketDoor,
    SetGarageDoorWicketDoorDirection,
    SetGarageDoorWicketDoorHandleColor,
    SetGarageDoorWicketDoorFrameProfilesColor
};
