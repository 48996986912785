const FillingsModalTemplate = require('ngtemplate-loader!./fillings-modal.component.html');
import FillingsModalController from './fillings-modal.controller';

export const FillingsModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: FillingsModalTemplate,
  controller: FillingsModalController
};
