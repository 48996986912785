import { InfoService } from '@icc/helpers';
import { ui } from 'angular';
import { RootScope } from '../app/RootScope';
import { Store } from '@ngrx/store';
import { ConfiguratorPartialState, DismissMessage } from '@icc/configurator/shared';

/**
 * Fabryka komunikatów.
 *
 * @export
 * @param {object} $uibModal   $uibModal
 * @param {object} $q          $q
 * @param {object} $filter     $filter
 * @param {object} $rootScope  $rootScope
 * @param {object} $location   $location
 * @param {object} $timeout    $timeout
 * @return {object} Fabryka
 */
export class LegacyInfoService extends InfoService {
    constructor(
        private $uibModal: ui.bootstrap.IModalService,
        private $q: ng.IQService,
        private $filter: ng.IFilterService,
        private $rootScope: RootScope,
        private $location: ng.ILocationService,
        private $timeout: ng.ITimeoutService,
        private $sce: ng.ISCEService,
        private store: Store<ConfiguratorPartialState>,
    ) {
        super();
    }

    /**
     * Otwiera modal informacyjny.
     *
     * @param {string}   template Link do szablonu.
     * @param {string}   message  Komunikat
     * @param {string}   title    Tytuł
     * @param {bool}     footer   Czy wyświetlać ok.
     * @param {object}   data     Dane
     * @param {function} callback Callback
     *
     * @return {Promise} Promise
     */
    openInfoModal({
        template,
        message,
        title,
        footer = false,
        data = {},
        ownTemplateUrl,
        issue,
    }: {
        template?: string;
        message: string;
        title: string;
        footer?: boolean;
        data?: any;
        ownTemplateUrl?: string;
        issue?: boolean;
    }): PromiseLike<void> {
        return this.$q(resolve => {
            const modalInstance = this.$uibModal.open({
                templateUrl: ownTemplateUrl ? ownTemplateUrl : 'modalInfo.html',
                controller: 'ModalInfoCtrl as info',
                windowClass: 'alert-dimensions',
                resolve: {
                    template: () => template,
                    message: () => this.$sce.trustAsHtml(message),
                    title: () => title,
                    footer: () => footer,
                    data: () => data,
                },
            });

            modalInstance.closed.finally(() => {
                resolve();
            });
        });
    }

    dismissInfoModal() {
        this.store.dispatch(new DismissMessage());
    }

    /**
     * Otwiera ostrzeżenie.
     *
     * @param {string}   message Komunikat
     * @param {function} callback Callback
     * @return {Promise} Promise
     */
    openWarning(message: string, issue: boolean = false) {
        return this.openInfoModal({
            message,
            title: this.$filter('translate')('INTERFACE|Ostrzeżenie'),
            issue
        });
    }

    dismissWarning(message: string) {
    }

    /**
     * Otwiera okno z pytaniem o potwierdzenie wykonania operacji
     * @param  {string}   title     Tytul okna
     * @param  {string}   question  Tresc komunikatu
     * @param  {Function} callback  Funkcja w przypadku potwierdzenia
     * @param  {Function} callback2 Funkcja w przypadku zaniechania
     */
    openConfirmModal(
        title: string,
        question: string,
        options: {
            name: string;
            callback: () => void;
            accent?: boolean;
        }[]
    ) {
        var modalInstance = this.$uibModal.open({
            templateUrl: 'modalConfirm.html',
            controller: 'ModalConfirmCtrl as mconfirm',
            windowClass: 'confirm-modal',
            resolve: {
                title: () => title,
                question: () => question,
                options: () => options,
            },
        });
        modalInstance.result.then(function afterConfirmModal(result) {
            if (result.callback instanceof Function) {
                result.callback();
            }
        });
    }

    /**
     * Ukrywa info.
     */
    hideInfo() {
        this.$rootScope.text = false;
        sessionStorage.removeItem('text');
    }

    /**
     * Wyświetla info.
     *
     * @param {string} text    Tekst
     * @param {string} style   Styl
     * @param {string} url     Url
     * @param {number} timeout Timeout
     */
    showInfo(
        text: string,
        style?: string | null,
        url?: string | (() => void) | null,
        timeout: number = 9000
    ) {
        this.$rootScope.text = false;

        if (typeof url === 'string') {
            this.$location.url(url);
        } else if (typeof url === 'function') {
            url();
        }

        this.$rootScope.text = text;

        if (style) {
            this.$rootScope.style = style;
        } else {
            this.$rootScope.style = 'popup-info';
        }
        if (timeout) {
            this.$timeout(() => {
                this.$rootScope.text = false;
                sessionStorage.removeItem('text');
            }, timeout);
        }
    }
}
InfoService.$inject = [
    '$uibModal',
    '$q',
    '$filter',
    '$rootScope',
    '$location',
    '$timeout',
    '$sce',
    'IccConfig',
    'EventBusService',
];
