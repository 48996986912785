<div class="configuratorContentStepsDefault" style="height: 100%">
    <div id="canvas-content" style="display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    height:100%;"
     #parent
   >
        <icc-draw id="canvas-draw" (redrawn)="redrawn()" [details]="drawService.details" fitTo="width" iccDrawResize
        [drawWidth]="drawService.details.width" [drawHeight]="drawService.details.height" [parentElement]="parent"
                  [options]="drawOptions" ng-style="{
                      display: 'block',
                      margin: '0 auto',

                }">
        </icc-draw>
    </div>
</div>