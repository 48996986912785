import * as angular from 'angular';
import {downgradeInjectable} from '@angular/upgrade/static';

import ColorBoxDirective from './ColorBoxDirective';
import ColorsByGroupFilter from './ColorsByGroupFilter';
import ColorsByNameOrCodeFilter from './ColorsByNameOrCodeFilter';
import ColorsByTypeFilter from './ColorsByTypeFilter';
import ColorsCtrl from './ColorsCtrl';
import ModalColorPVCCtrl from './ModalColorPVCCtrl';
import ModalWoodCtrl from './ModalWoodCtrl';
import { ColorsDefaultsService } from '@icc/common/colors/colors-defaults.service';
import { ModalPvcColorsCtrl } from './ModalPvcColorsCtrl';
import { ModalDecoPanelColorsCtrl } from './ModalDecoPanelColorsCtrl';
import { ModalProfileColorsCtrl } from './ModalProfileColorsCtrl';

export default angular.module('icc.configurator.steps.window.colors', [])
    .controller('ColorsCtrl', ColorsCtrl)
    .controller('ModalProfileColorsCtrl', ModalProfileColorsCtrl)
    .controller('ModalPvcColorsCtrl', ModalPvcColorsCtrl)
    .controller('ModalDecoPanelColorsCtrl', ModalDecoPanelColorsCtrl)
    .controller('ModalColorPVCCtrl', ModalColorPVCCtrl)
    .controller('ModalWoodCtrl', ModalWoodCtrl)
    .directive('iccColorBox', ColorBoxDirective)
    .filter('colorsByGroup', ColorsByGroupFilter)
    .filter('colorsByNameOrCode', ColorsByNameOrCodeFilter)
    .filter('colorsByType', ColorsByTypeFilter)
    .factory('ColorsDefaultsService', downgradeInjectable(ColorsDefaultsService))
    .name;
