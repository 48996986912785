import { CoupledWindowService } from './coupled-window.service';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { JoinPositionModalComponent } from './join-position-modal/join-position-modal.component';
import { EditingCoupledWindowService } from './editing-coupled-window.service';
import { MoveElementModalComponent } from './move-element-modal/move-element-modal.component';
import { SillsModalComponent } from './sills-modal/sills-modal.component';

export default angular
    .module('icc.configurator.coupled_window', [])
    .component('joinPositionModal', JoinPositionModalComponent)
    .component('moveElementModal', MoveElementModalComponent)
    .component('sillsModal', SillsModalComponent)
    .factory('CoupledWindowService', downgradeInjectable(CoupledWindowService))
    .factory('EditingCoupledWindowService', downgradeInjectable(EditingCoupledWindowService)).name;
