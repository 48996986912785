/**
 * Funkcja filtrów cen akcesoriów
 * @param {object} PriceAccessoryFactory      Fabryka podstawowych cen
 * @param {object} ConfigurationsService Fabryka konfiguracji
 */
export default function PriceMarketFilter(PriceBaseService) {
    'ngInject';

    return function(price) {
        return PriceBaseService.addMarginMarketFactor(price);
    };
}
