import { Common } from '../Common';
import { core } from '../Core';
import {logger} from '../helpers';

export default function Unit2DFilter(UserService, IccConfig) {
    'ngInject';

    /**
     * Filtr do konwersji wymarów.
     * @param  {number} data           Liczba do konwersji
     * @param  {number} hasunit        1 lub 0 - czy do wymiaru dołączany ma być symbol wymiaru (mm, ″)
     * @param  {number} inmm           Czy do wymiaru innego niż w mm dołączyć informacje o wymiarze w jednostkach mm?
     * @param  {string} dimension_unit Docelowa jednostka, przy braku bierze domyślną jednostkę użytkownika / rynku
     * @return {string}                Skonwertowany wymiar.
     */
    return (data, hasunit = 0, inmm = 0, dimension_unit = '') => {
        const Core = core;
        const dimensionUnit = dimension_unit || UserService.get().dimension_unit;
        const unit = {
            type     : dimensionUnit === 'mm' ? 0 : 1,
            unit     : dimensionUnit,
            separator: dimensionUnit === 'mm' ? ',' : '.'
        };

        let nums = [];

        if (Common.isString(data)) {
            if (data.indexOf('x') > -1) {
                nums = data.split('x');
            } else {
                nums.push(+data);
            }
        } else if (!Common.isNumber(data) || isNaN(data) || !Common.isDefined(data)) {
            return 'NaN';
        } else {
            nums.push(data);
        }

        let unitext = '';
        if (hasunit) {
            if (unit.type === 0) {
                unitext = 'mm';
            } else {
                unitext = '″';
            }
        }

        // jeden wymiar
        if (nums.length === 1) {
            // milimetry, zwróć po prostu - nie dodawaj nawet drugiego wymiaru, bo po co
            if (unit.type === 0) {
                return nums[0] + unitext;
            // do konwertowanych jednostek dodaj wymiar
            } else if (inmm) {
                return Core.formatNumber(parseInt(nums[0]) / IccConfig.Configurators.inchToMilimeter, 2, 100, ' ', unit.separator)
                    + unitext + ' (' + parseInt(nums[0]) + ' mm)';
            } else {
                return Core.formatNumber(parseInt(nums[0]) / IccConfig.Configurators.inchToMilimeter, 2, 100, ' ', unit.separator)
                    + unitext;
            }
        // dwa wymiary
        } else if (nums.length === 2) {
            const inInch = nums[0].includes(`″`);
            // milimetry, zwróć po prostu - nie dodawaj nawet drugiego wymiaru, bo po co
            if (unit.type === 0) {
                if (inInch) {
                    const firstDimension = Core.formatNumber(nums[0].replace(`″`, '') * IccConfig.Configurators.inchToMilimeter, 2, 100, ' ', ',');
                    const secundDimension = Core.formatNumber(nums[1].replace(`″`, '') * IccConfig.Configurators.inchToMilimeter, 2, 100, ' ', ',');
                    return firstDimension + 'x' + secundDimension + unitext;
                } else {
                    return parseInt(nums[0]) + 'x' + parseInt(nums[1]) + unitext;
                }
            // do konwertowanych jednostek dodaj wymiar
            } else if (inmm) {
                if(inInch) {
                    const firstDimension = Core.formatNumber(nums[0].replace(`″`, '') * IccConfig.Configurators.inchToMilimeter, 2, 100, ' ', ',');
                    const secundDimension = Core.formatNumber(nums[1].replace(`″`, '') * IccConfig.Configurators.inchToMilimeter, 2, 100, ' ', ',');
                    return nums[0] + 'x' + nums[1] + ' (' + firstDimension + ' mm' + ' x ' + secundDimension + ' mm)';
                } else {
                    const firstDimension = Core.formatNumber(parseInt(nums[0]) / IccConfig.Configurators.inchToMilimeter, 2, 100, ' ', unit.separator);
                    const secundDimension = Core.formatNumber(parseInt(nums[1]) / IccConfig.Configurators.inchToMilimeter, 2, 100, ' ', unit.separator);
                    return firstDimension + unitext + 'x' + secundDimension + unitext + ' (' + parseInt(nums[0]) + 'x' + parseInt(nums[1]) + ' mm)';
                }
            } else {
                return Core.formatNumber(parseInt(nums[0]) / IccConfig.Configurators.inchToMilimeter, 2, 100, ' ', unit.separator)
                    + 'x' + Core.formatNumber(parseInt(nums[1]) / IccConfig.Configurators.inchToMilimeter, 2, 100, ' ', unit.separator)
                    + unitext;
            }
        }
    };
}
