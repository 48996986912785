/**
 * Kontroler linii markiz
 * @param {object} $scope                   Scope
 * @param {object} AwningSystemsService     Fabryka linii markiz
 */
export default function SystemsCtrl($scope, AwningSystemsService, EventBusService) {
    'ngInject';

    var vm = this;

    vm.subStep = 1;
    vm.systems = AwningSystemsService.systems;
    vm.allSystems = true;
    vm.selectSystem = selectSystem.bind(AwningSystemsService);

    if (AwningSystemsService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjujaca
     */
    function init() {
        vm.systems = AwningSystemsService.systems;
    }

    /**
     * Wybór systemu
     * @param  {Object} system System
     */
    function selectSystem(system) {
        AwningSystemsService.selectSystem(system, true, true);
    }

}
