import { DatabaseManager,  DatabaseManagerToken } from '@icc/helpers/browser';
import { IccDatabase, IccSimpleDatabase } from '@icc/helpers/browser';
import { OfferSequenceService } from '@icc/common/offers/OfferSequenceService';
import { core } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';
import { OffersService } from './offers.service';
import { ManyPositionsService } from './many-positions.service';
import { PositionDetailedSummaryService } from '@icc/common/PositionDetailedSummaryService';
import { OfferTransportCostService } from '@icc/common/offers/OfferTransportCostService';
import { OfferDiscountsService } from '@icc/common/offers/OfferDiscountsService';
import { TimeLimitService } from '@icc/common/time-limit/time-limit.service';
import { OfferGroupService } from './offer-group.service';
import { StateService } from '@icc/common/state.service';
import { UserService } from '@icc/common/user.service';
import { Injectable, Inject } from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { EventBusService } from '@icc/common/event-bus.service';
import { OfferSummaryServiceStatic } from '@icc/common/offers/offer-summary.service';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { OfferHistoryService } from '@icc/common/offers/offer-history.service';
import { currencyExchange } from '@icc/helpers';

@Injectable()
export class OfferSummaryService {
    constructor(
        private stateService: StateService,
        @Inject(DatabaseManagerToken) private databaseManager: DatabaseManager,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private offersService: OffersService,
        private manyPositionsService: ManyPositionsService,
        private offerGroupService: OfferGroupService,
        private userService: UserService,
        private eventBusService: EventBusService,
        private timeLimitService: TimeLimitService,
        private configuratorsDataService: ConfiguratorsDataService,
    ) {
        eventBusService.subscribe<any>('correctTotalOfferPrice', async data => {
            const offer = await this.offersService.get(data.value.offerId, true);
            const updatedOffer = await this.autoUpdateOffer(
                offer.tmp_id,
                null,
                null,
                data.value.sequence,
                true
            );
            if (core.isWorker()) {
                (self as any).postMessage({
                    subject: 'emittedEvent',
                    name: 'correctedTotalOfferPrice',
                    value: {
                        offer: updatedOffer,
                    },
                });
            } else {
                eventBusService.post({
                    key: 'correctedTotalOfferPrice',
                    value: {
                        offer: updatedOffer,
                    },
                });
            }
        });
    }

    /**
     * Automatyczne uaktualnienie oferty po zmianach w pozycjach
     * @param  {string} _id              Id oferty
     * @param  {object} posObj           Objekt pozycji
     * @param  {string} action           Akcja na pozycji
     * @param  {object} preparedSequence Gotowa struktura pozycji
     * @return {object}                  Promise
     */
    async autoUpdateOffer(
        offerId,
        posObj?,
        action?,
        preparedSequence?,
        forceUpdateBasedOnDB = false,
        dealer = null,
        dataToUpdate = {},
        measurePrice: boolean | {old: number, new: number} = false
    ) {
        const user: any = this.userService.get();
        if (!dealer) {
            dealer = user.dealer;
        }

        let offer = await this.offersService.get(offerId);
        if (Object.keys(dataToUpdate).length !== 0) {
            offer = Common.extend(offer, dataToUpdate);
        }
        const newOfferData = {
            circuit: 0,
            shutter_circuit: 0,
            door_circuit: 0,
            window_circuit: 0,
            quantity: 0,
            weight: 0,
            weight_positions_quantity: 0,
            area: 0,
            glazing_area: 0,
            number_items: 0,
            valuation: 0,
            sequence: '[]',

            client_discount_position: 0,

            client_price_before_discount_position: 0,
            client_price_before_discount: 0,
            client_price_before_discount_dealer: 0,
            client_price: 0,

            dealer_client_price_before_discount_position: 0,
            dealer_client_price_before_discount: 0,
            dealer_client_price_before_discount_dealer: 0,
            dealer_client_price: 0,

            dealer_price_before_discount_position: 0,
            dealer_price_before_discount: 0,
            dealer_price: 0,

            transport_cost: 0,
            client_transport_cost: 0,
            client_discount_special: 0,
            time_limit: 0,
            country_id: offer.country_id,
            postal_code: offer.postal_code,
            transport_cost_type: null,
            transport_cost_type_id: offer.transport_cost_type_id,
            point_of_service: offer.point_of_service,
            unmatched_oversize_rules: {},
            transport_costs: [],
            transport_from_producent_to_client:  offer.transport_from_producent_to_client,
            transport_from_producent:  offer.transport_from_producent,

            summary: {
                client: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        glassPromotions: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        basePromotions: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                dealer: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        glassPromotions: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        basePromotions: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                promotions: {
                    raster: [],
                    glass: [],
                },
            },
        };

        const sequence = OfferSequenceService.updateOfferSequence(
            preparedSequence || offer.sequence,
            [posObj],
            action,
            this.config().IccConfig
        );
        const searched = OfferSequenceService.keysFromSequence(sequence);

        const positionsData = await this.manyPositionsService.listById(
            searched,
            offer.tmpId,
            true,
            forceUpdateBasedOnDB
        );
        let positions = positionsData.pos;

        positions = positions.map(el => {
            if (!Common.isObject(el.doc.details)) {
                el.doc.details = core.parseJson(el.doc.details);
            }
            return el;
        });
        const position = positions.filter(elem => {
            return Common.isObject(posObj) && elem.doc.tmp_id === posObj.id;
        });
        const positionsGroupsData = this.offerGroupService.updatePositionsGroupsData(
            offer.positions_groups_data,
            sequence,
            position[0]
        );
        let transportPosition = null;
        let i = 0;
        let offerGroupDiscounts = [];
        const transportCost = await this.calculateTransportCost(positions, user);
        while (i < positions.length) {
            if (!Common.isUndefined(positions[i].doc)) {
                newOfferData.weight += positions[i].doc.weight * positions[i].doc.quantity;
                if (newOfferData.weight && positions[i].doc?.confType!=='additional') {
                    newOfferData.weight_positions_quantity+=positions[i]?.doc?.quantity || 0;
                }
                newOfferData.glazing_area += positions[i].doc.glazing_area * positions[i].doc.quantity;
                if ((!positions[i].doc.coupled_position_id && !this.config().IccConfig.Offer.separateCircuits)
                     || (this.config().IccConfig.Offer.separateCircuits && positions[i].doc.confType!=='coupled_window'
                )) {
                    newOfferData.area += positions[i].doc.area * positions[i].doc.quantity;
                    newOfferData.circuit += positions[i].doc.circuit * positions[i].doc.quantity;
                    newOfferData.shutter_circuit += positions[i].doc.shutter_circuit * positions[i].doc.quantity;
                    newOfferData.door_circuit += positions[i].doc.door_circuit * positions[i].doc.quantity;
                    newOfferData.window_circuit += positions[i].doc.window_circuit * positions[i].doc.quantity;
                }

                if (!positions[i].doc.coupled_position_id) {
                    newOfferData.number_items += 1;
                    newOfferData.quantity += ~~positions[i].doc.quantity || 0;
                }

                if (
                    !positions[i].doc.standard
                    && !positions[i].doc.valuated_price
                    && !positions[i].doc.coupled_position_id
                ) {
                    newOfferData.valuation = 1;
                }

                if (
                    this.config().IccConfig.Offer.calculatedDealerTransportCost
                    && positions[i].doc.confType === 'transport_cost'
                    && transportCost !== 0
                    && positions[i].doc.dealer_price !== transportCost
                ) {
                    positions[i].doc.dealer_price = transportCost;
                    positions[i].doc.dealer_price_before_discount = transportCost;
                    positions[i].doc.client_price = transportCost;
                    positions[i].doc.client_price_before_discount = transportCost;

                    transportPosition = core.copy(positions[i].doc);
                }

                if (IccConfig.Offer.newSummaryOrder) {
                    OfferSummaryServiceStatic.sumPositionPricesNew(positions[i], newOfferData, offer);
                } else {
                    OfferSummaryServiceStatic.sumPositionPrices(positions[i], newOfferData, offer);
                }

                if (this.config().IccConfig.Offer.detailedSummary) {
                    newOfferData.summary = PositionDetailedSummaryService.detailedSummary(
                        positions[i].doc,
                        this.config().IccConfig,
                        offer.currency,
                        newOfferData.summary
                    );
                }

                i++;
            }

        }

        if (i === positions.length) {
            newOfferData.client_transport_cost = offer.client_transport_cost;
            newOfferData.client_discount_position = offer.client_discount_position;
            newOfferData.client_discount_special = offer.client_discount_special;

            if (
                ['logistic-minimum', 'm2-cost', 'weight', 'transport-types'].includes(
                    String(this.config().IccConfig.Offer.transportCostType)
                )
                && (offer.transport_from_producent || offer.transport_from_producent_to_client)
                && !offer.split_transport_cost
            ) {
                newOfferData.transport_cost = offer.transport_cost;
                if (
                    this.config().IccConfig.Offer.transportCostType === 'm2-cost'
                    && !Number(offer.order)
                    && offer.transport_from_producent
                ) {
                    newOfferData.transport_cost = OfferTransportCostService.transportM2Cost(
                        user.dealer,
                        newOfferData
                    );
                }
                if (
                    this.config().IccConfig.Offer.transportCostType === 'weight'
                    && !Number(offer.order)
                    && offer.transport_from_producent
                ) {
                    newOfferData.transport_cost = OfferTransportCostService.transportWeightCost(
                        dealer,
                        newOfferData
                    );
                }
                if (
                    this.config().IccConfig.Offer.transportCostType === 'transport-types'
                    && !Number(offer.order)
                    && offer.transport_from_producent_to_client
                ) {
                    const transportCostsData = await (this.databaseManager.get('TransportCosts') as IccSimpleDatabase).get();
                    transportCostsData.data = core.parseJson(transportCostsData.data);
                    const transportCosts = transportCostsData.data.transportCosts;
                    const transportCostTypes = transportCostsData.data.transportCostTypes;

                    const transportTypes = OfferTransportCostService.transportTypesCost(
                        newOfferData,
                        positions.map(el => el.doc),
                        transportCostTypes,
                        transportCosts
                    );
                    if (transportTypes.transportTypes.length > 0) {
                        if (!newOfferData.transport_cost_type_id || transportTypes.transportTypes.every(t => t.transportCostTypeId !== offer.transport_cost_type_id)) {
                            newOfferData.transport_cost_type_id = transportTypes.transportTypes[0].transportCostTypeId;
                        }
                        const transportCostType = transportTypes.transportTypes.find(c => c.transportCostTypeId === offer.transport_cost_type_id);
                        newOfferData.transport_cost_type = transportCostType?.type;
                        newOfferData.transport_cost = currencyExchange(Number(transportCostType?.price), offer.currency);
                        newOfferData.valuation = ['expected_price', 'on_request'].indexOf(transportCostType?.type) > -1 ? 1 : newOfferData.valuation;
                        transportTypes.transportTypes.forEach(type => {
                            newOfferData.transport_costs.push(type.transportCostTypeId);
                            if (type.unmatchedRules?.length > 0) {
                                newOfferData.unmatched_oversize_rules[type.transportCostTypeId] = type.unmatchedRules;
                            }
                        });
                    } else {
                        newOfferData.transport_cost = 0;
                        newOfferData.valuation = 1;
                        newOfferData.unmatched_oversize_rules = transportTypes.unmatchedRules;
                        newOfferData.transport_costs = null;
                    }
                }

                if (newOfferData.transport_cost) {
                    OfferTransportCostService.addTransportService(newOfferData, IccConfig);
                }
            }

            if (
                !offer.transport_from_producent_to_client
                && IccConfig.Offer.transportCostType === 'transport-types'
                && !Number(offer.order)
                && !offer.split_transport_cost
            ) {
                newOfferData.transport_cost = 0;
            }

            if (
                ['logistic-minimum', 'm2-cost', 'weight', 'transport-types'].includes(
                    String(this.config().IccConfig.Offer.transportCostType)
                )
                && offer.client_transport_cost
                && (Number(offer.dealer_status) === 1 || Number(offer.dealer_status) === 0 || !offer.client_split_transport_cost)
                && Number(offer.order) === 0
            ) {
                OfferTransportCostService.addClientTransportService(
                    newOfferData,
                    this.config().IccConfig
                );
            }

            if (this.config().IccConfig.Offer.newSummaryOrder) {
                offerGroupDiscounts = OfferSummaryServiceStatic.addDiscountsAndMontagesPricesNew(newOfferData, offer, this.config().IccConfig, user, offerGroupDiscounts);
            } else {
                offerGroupDiscounts = OfferSummaryServiceStatic.addDiscountsAndMontagesPrices(newOfferData, offer, this.config().IccConfig, user, offerGroupDiscounts);
            }

            if (this.config().IccConfig.Configurators.timeLimits && !~~offer.order) {
                newOfferData.time_limit = this.timeLimitService.getOfferTimeLimit(positions);
            }

            if (
                core.roundPrice(offer.dealer_price) !== core.roundPrice(newOfferData.dealer_price)
                || core.roundPrice(offer.client_price) !== core.roundPrice(newOfferData.client_price)
                || (Common.isObject(measurePrice) && core.roundPrice(measurePrice.old) !== core.roundPrice(measurePrice.new))
            ) {
                OfferHistoryService.addHistoryEntry(offer, user, [{
                    type: 'price',
                    oldDealerPrice: offer.dealer_price + (Common.isObject(measurePrice) ? measurePrice.old : offer.measure_price),
                    dealerPrice: newOfferData.dealer_price + offer.measure_price,
                    oldClientPrice: offer.client_price + (Common.isObject(measurePrice) ? measurePrice.old : offer.measure_price),
                    clientPrice: newOfferData.client_price + offer.measure_price,
                }]);
            }

            offer = Common.extend(offer, {
                quantity: newOfferData.quantity,
                circuit: newOfferData.circuit,
                shutter_circuit: newOfferData.shutter_circuit,
                door_circuit: newOfferData.door_circuit,
                window_circuit: newOfferData.window_circuit,
                number_items: newOfferData.number_items,
                valuation: newOfferData.valuation,
                weight: newOfferData.weight,
                weight_positions_quantity: newOfferData.weight_positions_quantity,
                area: newOfferData.area,
                glazing_area: newOfferData.glazing_area,
                sequence,
                dealer_price_before_discount_position:
                    newOfferData.dealer_price_before_discount_position,
                dealer_price_before_discount: newOfferData.dealer_price_before_discount,
                dealer_price: newOfferData.dealer_price,
                dealer_client_price_before_discount_position:
                    newOfferData.dealer_client_price_before_discount_position,
                dealer_client_price_before_discount:
                    newOfferData.dealer_client_price_before_discount,
                dealer_client_price_before_discount_dealer:
                    newOfferData.dealer_client_price_before_discount_dealer,
                dealer_client_price: newOfferData.dealer_client_price,
                client_price_before_discount_position:
                    newOfferData.client_price_before_discount_position,
                client_price_before_discount: newOfferData.client_price_before_discount,
                client_price_before_discount_dealer: newOfferData.client_price_before_discount_dealer,
                client_price: newOfferData.client_price,
                summary: newOfferData.summary,
                group_discounts: offerGroupDiscounts,
                positions_groups_data: positionsGroupsData,
                changed_positions: OfferSummaryServiceStatic.saveOfferChanges(offer.changed_positions, posObj, action),
                time_limit: newOfferData.time_limit,
                transport_cost: newOfferData.transport_cost,
                transport_cost_type: newOfferData.transport_cost_type,
                transport_cost_type_id: newOfferData.transport_cost_type_id,
                point_of_service: newOfferData.point_of_service,
                unmatched_oversize_rules: newOfferData.unmatched_oversize_rules,
                transport_costs: newOfferData.transport_costs,
            });

            await this.offersService.update(offer.tmp_id, offer);
            const currentOfferId = this.stateService.getKey('offer_id');
            if (currentOfferId === offer.tmp_id) {
                this.stateService.setKey('offer', offer);
            }
            if (transportPosition) {
                await (this.databaseManager.get('Position') as IccDatabase).update(
                    transportPosition,
                    { internalId: transportPosition.tmp_id }
                );
                this.offersService.emitModifiedOffer();
                return offer;
            } else {
                this.offersService.emitModifiedOffer();
                return offer;
            }
        }
    }

    /**
     * Obliczanie kosztów transportu oferty
     * @param  {array} positions Pozycje oferty
     * @return {object}          Promise
     */
    async calculateTransportCost(positions, user) {
        const prices = await (this.databaseManager.get('Prices') as IccSimpleDatabase).get();
        prices.data = core.parseJson(prices.data);
        const market = prices.data.markets[user.market];
        return OfferSummaryServiceStatic.calculateTransportCost(positions, user, market, this.config().IccConfig);
    }


}
