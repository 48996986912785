import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@icc/common';
import { IccDrawMathService } from '@icc/draw';
import { Shape } from '@icc/window';
import { TranslateService as NgxTranslateService} from '@ngx-translate/core';

type Line = [{
    x: number;
    y: number;
}, {
    x: number;
    y: number;
}];

@Component({
    selector: 'icc-dimensions-legend',
    templateUrl: './dimensions-legend.component.html',
    styleUrls: ['./dimensions-legend.component.scss'],
})
export class DimensionsLegendComponent implements OnInit {
    @Input() shape: Shape['shape'] | null = null;
    @Input() variant: Shape['type'] | null = null;
    @Input() highlight: Exclude<keyof Shape, 'shape' | 'circuit' | 'arcType' | 'type'> | 'all' | null =
        'all';
    @Input() fieldPrefix = false;

    path = '';
    path2 = '';
    dimensions: {
        path: string;
        title: string,
        center: {
            x: number;
            y: number;
        },
        baseline: 'baseline' | 'middle' | 'hanging';
        textAnchor: 'start' | 'middle' | 'end';
    }[] = [];
    viewBox = '';
    strokeWidth = 2;

    paths: {
        [key in Shape['shape']]: {
            [variant: string]: Shape & {
                lines: Array<Exclude<keyof Shape, 'shape' | 'circuit' | 'arcType' | 'type'>>
            }
        }
    } = {
        rect: {
            default: {
                lines: ['width', 'height'],
                shape: 'rect',
                height: 250,
                circuit: 1000,
                width: 250,
                s1: 250,
                s2: 0,
                s3: 0,
                h1: 250,
                h2: 0,
                h3: 250,
            }
        },
        triangle: {
            default: {
                lines: ['h1', 's1', 's3'],
                shape: 'triangle',
                height: 250,
                s1: 250,
                s2: 0,
                s3: 250,
                h1: 250,
                h2: 0,
                h3: 0,
                type: 'F',
                circuit: 1000,
            },
            F: {
                lines: ['h1', 's1', 's3'],
                shape: 'triangle',
                height: 250,
                s1: 125,
                s2: 0,
                s3: 125,
                h1: 250,
                h2: 0,
                h3: 0,
                type: 'F',
                circuit: 1000,
            },
            L: {
                lines: ['h1', 's1'],
                shape: 'triangle',
                height: 250,
                s1: 250,
                s2: 0,
                s3: 0,
                h1: 250,
                h2: 0,
                h3: 0,
                type: 'F',
                circuit: 1000,
            },
            R: {
                lines: ['h1', 's3'],
                shape: 'triangle',
                height: 250,
                s1: 0,
                s2: 0,
                s3: 250,
                h1: 250,
                h2: 0,
                h3: 0,
                type: 'F',
                circuit: 1000,
            }
        },
        circle: {
            default: {
                lines: ['d'],
                shape: 'circle',
                d: 250,
                radius: 125,
                circuit: 1000,
            }
        },
        arc: {
            default: {
                lines: ['width', 'height'],
                shape: 'arc',
                circuit: 919,
                h1: 175,
                h2: 75,
                radius: 141.66,
                type: "F",
                width: 250
            },
            F: {
                lines: ['width', 'h1', 'h2'],
                shape: 'arc',
                circuit: 919,
                h1: 175,
                h2: 75,
                radius: 141.66,
                type: "F",
                width: 250
            },
            R: {
                lines: ['width', 'h1', 'h2'],
                shape: 'arc',
                circuit: 950,
                h1: 175,
                h2: 75,
                radius: 454.16,
                type: "R",
                width: 250
            },
            L: {
                lines: ['width', 'h1', 'h2'],
                shape: 'arc',
                circuit: 950,
                h1: 175,
                h2: 75,
                radius: 454.16,
                type: "L",
                width: 250
            }
        },
        poligon: {
            default: {
                lines: ['s1', 's2', 's3', 'h1', 'h2', 'h3'],
                circuit: 5263 / 6,
                h1: 1500 / 6,
                h2: 300 / 6,
                h3: 300 / 6,
                s1: 450 / 6,
                s2: 600 / 6,
                s3: 450 / 6,
                shape: "poligon",
                type: "DLC",
            },
            SLT: {
                lines: ['s1', 'h1', 'h3'],
                circuit: 5263 / 6,
                h1: 1500 / 6,
                h2: 0 / 6,
                h3: 300 / 6,
                s1: 1500 / 6,
                s2: 0 / 6,
                s3: 0 / 6,
                shape: "poligon",
                type: "SLT",
            },
            SRT: {
                lines: ['s3', 'h1', 'h2'],
                circuit: 5263 / 6,
                h1: 1500 / 6,
                h2: 300 / 6,
                h3: 0 / 6,
                s1: 0 / 6,
                s2: 0 / 6,
                s3: 1500 / 6,
                shape: "poligon",
                type: "SRT",
            },
            SLS: {
                lines: ['s1', 's2', 'h1'],
                circuit: 5263 / 6,
                h1: 1500 / 6,
                h2: 0 / 6,
                h3: 0 / 6,
                s1: 450 / 6,
                s2: 1050 / 6,
                s3: 0 / 6,
                shape: "poligon",
                type: "SLS",
            },
            SRS: {
                lines: ['s2', 's3', 'h1'],
                circuit: 5263 / 6,
                h1: 1500 / 6,
                h2: 0 / 6,
                h3: 0 / 6,
                s1: 0 / 6,
                s2: 1050 / 6,
                s3: 450 / 6,
                shape: "poligon",
                type: "SRS"
            },
            SLC: {
                lines: ['s1', 's2', 'h1', 'h3'],
                circuit: 5263 / 6,
                h1: 1500 / 6,
                h2: 0 / 6,
                h3: 300 / 6,
                s1: 450 / 6,
                s2: 1050 / 6,
                s3: 0 / 6,
                shape: "poligon",
                type: "SLC",
            },
            SRC: {
                lines: ['s2', 's3', 'h1', 'h2'],
                circuit: 5263 / 6,
                h1: 1500 / 6,
                h2: 300 / 6,
                h3: 0 / 6,
                s1: 0 / 6,
                s2: 1050 / 6,
                s3: 450 / 6,
                shape: "poligon",
                type: "SRC",
            },
            DLT: {
                lines: ['s1', 's3', 'h1', 'h2', 'h3'],
                circuit: 5263 / 6,
                h1: 1500 / 6,
                h2: 300 / 6,
                h3: 300 / 6,
                s1: 750 / 6,
                s2: 0 / 6,
                s3: 750 / 6,
                shape: "poligon",
                type: "DLT",
            },
            DLS: {
                lines: ['s1', 's2', 's3', 'h1'],
                circuit: 5263 / 6,
                h1: 1500 / 6,
                h2: 0 / 6,
                h3: 0 / 6,
                s1: 450 / 6,
                s2: 600 / 6,
                s3: 450 / 6,
                shape: "poligon",
                type: "DLS",
            },
            DLC: {
                lines: ['s1', 's2', 's3', 'h1', 'h2', 'h3'],
                circuit: 5263 / 6,
                h1: 1500 / 6,
                h2: 300 / 6,
                h3: 300 / 6,
                s1: 450 / 6,
                s2: 600 / 6,
                s3: 450 / 6,
                shape: "poligon",
                type: "DLC",
            },
        },
    };

    constructor(
        public translateService: TranslateService,
        public browserTranslateService: NgxTranslateService,
    ) {}

    ngOnInit() {
        const shape = this.paths[this.shape || 'rect'][this.variant || 'default'];
        if (shape) {
            if (this.fieldPrefix) {
                Object.keys(shape).forEach((key: keyof Shape) => {
                    if (shape[key] && typeof shape[key] === 'number') {
                        (shape as any)[key] = Number(shape[key]) / 10;
                    }
                })
            }
            let polygon = IccDrawMathService.getPolyFromShape(shape, { x: 0, y: 0 });
            const rect = IccDrawMathService.getRectFromPoly(polygon);
            let smallerPolygon = IccDrawMathService.getPolyShift(polygon, 10);
            polygon = [...polygon, polygon[0]];
            smallerPolygon = [...smallerPolygon, smallerPolygon[0]];

            this.path = this.getPath(polygon);
            this.path2 = this.getPath(smallerPolygon);
            this.dimensions = [];
            if (this.highlight) {
                shape.lines.forEach(line => {
                    this.setDimension(rect, line, shape, this.highlight === 'all' || this.highlight === line);
                });
            }

            if (this.fieldPrefix) {
                this.strokeWidth = 1;
            }

            this.viewBox = `${rect.x - this.strokeWidth / 2} ${rect.y  - this.strokeWidth / 2} ${rect.width + this.strokeWidth} ${rect.height + this.strokeWidth}`;
        }
    }

    private setDimension(
        rootRect: { x: number; y: number; width: number; height: number },
        field: Exclude<keyof Shape, 'shape' | 'circuit' | 'arcType' | 'type'>,
        shape: Shape,
        highlight: boolean = false
    ) {
        const { path, rect, center, textAnchor, name, baseline } = this.getDimensionPath(field, shape);
        const originalRect = {...rootRect};
        rootRect.x = Math.min(rect.x, originalRect.x);
        rootRect.y = Math.min(rect.y, originalRect.y);
        rootRect.width = Math.max(originalRect.x + originalRect.width, rect.x + rect.width) - rootRect.x;
        rootRect.height = Math.max(originalRect.y + originalRect.height, rect.y + rect.height) - rootRect.y;
        if(highlight) {
            this.browserTranslateService.get(`DIMENSION|Wymiar {name}`, { name: name() }).toPromise().then((text) => {
                this.dimensions.push({
                    path,
                    center,
                    baseline,
                    textAnchor,
                    title: text,
                });
            })
        }
    }

    private getDimensionPath(
        dimension: Exclude<keyof Shape, 'shape' | 'circuit' | 'arcType' | 'type'>,
        shape: Shape
    ): {
        path: string;
        center: {
            x: number;
            y: number;
        },
        textAnchor: 'start' | 'end' | 'middle';
        baseline: 'middle' | 'baseline' | 'hanging'
        name: () => string;
        rect: {
            x: number;
            y: number;
            width: number;
            height: number;
        }
    } {
        let factor = 1;
        if (this.fieldPrefix) {
            factor = 0.5;
        }
        switch (dimension) {
            case 'height': {
                const width = Number(shape.s1) + Number(shape.s2) +  Number(shape.s3) || Number(shape.width);
                const line: Line = [
                    { x: width + 10 * factor, y: 0 },
                    { x: width + 10 * factor, y: Number(shape.height) },
                ];
                const center = this.getTextPosition(line);
                return {
                    path: this.getPath(line),
                    center: {
                        x: center.x + 2,
                        y: center.y
                    },
                    textAnchor: 'start',
                    baseline: 'middle',
                    name: () => this.translateService.instant('DIMENSION|H'),
                    rect: {
                        x: width,
                        y: -1,
                        width: (this.fieldPrefix ? 15 : 25) * factor,
                        height: Number(shape.height) + 2,
                    },
                };
            }
            case 'width': {
                const line: Line = [{ x: 0, y: -10 * factor }, { x: Number(shape.width), y: -10 * factor }];
                const center = this.getTextPosition(line);
                return {
                    path: this.getPath(line),
                    center: {
                        x: center.x,
                        y: center.y - 2
                    },
                    name: () => this.translateService.instant('DIMENSION|S'),
                    textAnchor: 'middle',
                    baseline: 'baseline',
                    rect: {
                        x: -1,
                        y: (this.fieldPrefix ? -15 : -25 ) * factor,
                        width: Number(shape.width) + 1,
                        height: (this.fieldPrefix ? 15 : 25 ) * factor,
                    },
                };
            }
            case 'h1': {
                let width = Number(shape.s1) + Number(shape.s2) +  Number(shape.s3) || Number(shape.width);
                if (shape.shape === 'poligon' && this.highlight === 'all') {
                    width += 25;
                }
                const line: Line = [
                    { x: width + 10 * factor, y: shape.shape === 'poligon' ? 0 : Number(shape.h2) },
                    { x: width + 10 * factor, y: shape.shape === 'poligon' ? Number(shape.h1) : Number(shape.h2) + Number(shape.h1) },
                ];
                const center = this.getTextPosition(line);
                return {
                    path: this.getPath(line),
                    center: {
                        x: center.x + 2,
                        y: center.y
                    },
                    textAnchor: 'start',
                    baseline: 'middle',
                    name: () => this.translateService.instant('DIMENSION|H1'),
                    rect: {
                        x: width,
                        y: -1 + Number(shape.h2),
                        width: (this.fieldPrefix ? 15 : 35) * factor,
                        height: Number(shape.h1) + 2,
                    },
                };
            }
            case 'h2': {
                const width = Number(shape.s1) + Number(shape.s2) +  Number(shape.s3) || Number(shape.width);
                let line: Line = [
                    { x: width + 10 * factor, y: Number(shape.h1) - Number(shape.h2) },
                    { x: width + 10 * factor, y: Number(shape.h1) },
                ];
                if (shape.shape === 'arc') {
                    line = [
                        { x: width + 10 * factor, y: 0 },
                        { x: width + 10 * factor, y: Number(shape.h2) },
                    ];
                }
                const center = this.getTextPosition(line);
                return {
                    path: this.getPath(line),
                    center: {
                        x: center.x + 2,
                        y: center.y
                    },
                    textAnchor: 'start',
                    baseline: 'middle',
                    name: () => this.translateService.instant('DIMENSION|H2'),
                    rect: {
                        x: width,
                        y: -1 + line[0].y,
                        width: (this.fieldPrefix ? 15 : 35) * factor,
                        height: line[1].y - line[0].y + 2,
                    },
                };
            }
            case 'h3': {
                const width = Number(shape.s1) + Number(shape.s2) +  Number(shape.s3) || Number(shape.width);
                let line: Line = [
                    { x: -10 * factor, y: Number(shape.h1) - Number(shape.h3) },
                    { x: -10 * factor, y: Number(shape.h1) },
                ];
                const center = this.getTextPosition(line);
                return {
                    path: this.getPath(line),
                    center: {
                        x: center.x - 2,
                        y: center.y
                    },
                    textAnchor: 'end',
                    baseline: 'middle',
                    name: () => this.translateService.instant('DIMENSION|H3'),
                    rect: {
                        x: (this.fieldPrefix ? -15 : -35) * factor,
                        y: -1 + line[0].y,
                        width: (this.fieldPrefix ? 15 : 35) * factor,
                        height: line[1].y - line[0].y + 2,
                    },
                };
            }
            case 's1': {
                const height = Number(shape.h1) || Number(shape.height);
                const line: Line = [
                    { x: 0, y: height + 10 * factor },
                    { x: Number(shape.s1), y: height + 10 * factor }
                ];
                const center = this.getTextPosition(line);
                return {
                    path: this.getPath(line),
                    center: {
                        x: center.x,
                        y: center.y + 2
                    },
                    name: () => this.translateService.instant('DIMENSION|S1'),
                    textAnchor: 'middle',
                    baseline: 'hanging',
                    rect: {
                        x: -1,
                        y: height,
                        width: Number(shape.s1) + 1,
                        height: (this.fieldPrefix ? 15 : 25) * factor,
                    },
                };
            }
            case 's2': {
                const height = Number(shape.h1) || Number(shape.height);
                const line: Line = [
                    { x: Number(shape.s1), y: height + 10 * factor },
                    { x: Number(shape.s1) + Number(shape.s2), y: height + 10 * factor }
                ];
                const center = this.getTextPosition(line);
                return {
                    path: this.getPath(line),
                    center: {
                        x: center.x,
                        y: center.y + 2
                    },
                    name: () => this.translateService.instant('DIMENSION|S2'),
                    textAnchor: 'middle',
                    baseline: 'hanging',
                    rect: {
                        x: -1 + Number(shape.s1),
                        y: height,
                        width: Number(shape.s2) + 1,
                        height: (this.fieldPrefix ? 15 : 25) * factor,
                    },
                };
            }
            case 's3': {
                const height = Number(shape.h1) || Number(shape.height);
                const line: Line = [
                    { x: Number(shape.s1) + Number(shape.s2), y: height + 10 * factor },
                    { x: Number(shape.s1) + Number(shape.s2) + Number(shape.s3), y: height + 10 * factor }
                ];
                const center = this.getTextPosition(line);
                return {
                    path: this.getPath(line),
                    center: {
                        x: center.x,
                        y: center.y + 2
                    },
                    name: () => shape.shape === 'triangle' ? this.translateService.instant('DIMENSION|S2') : this.translateService.instant('DIMENSION|S3'),
                    textAnchor: 'middle',
                    baseline: 'hanging',
                    rect: {
                        x: -1 + Number(shape.s1) + Number(shape.s2),
                        y: height,
                        width: Number(shape.s3) + 1,
                        height: (this.fieldPrefix ? 15 : 25) * factor,
                    },
                };
            }
            case 'd': {
                const line: Line = [{ x: 0, y: -10 * factor }, { x: Number(shape.d), y: -10 * factor }];
                const center = this.getTextPosition(line);
                return {
                    path: this.getPath(line),
                    center: {
                        x: center.x,
                        y: center.y - 2
                    },
                    name: () => this.translateService.instant('DIMENSION|S'),
                    textAnchor: 'middle',
                    baseline: 'baseline',
                    rect: {
                        x: -1,
                        y: (this.fieldPrefix ? -15 : -25 ) * factor,
                        width: Number(shape.d) + 1,
                        height: (this.fieldPrefix ? 15 : 25 ) * factor,
                    },
                };
            }
        }
        return {
            path: '',
            center: {
                x: 0,
                y: 0
            },
            textAnchor: 'start',
            baseline: 'middle',
            name: () => '',
            rect: { x: 0, y: 0, height: 0, width: 0 },
        };
    }

    private getPath(polygon: any[]) {
        return polygon
            .map((o, i) => {
                const { x, y, cr, cw, onlyMove } = o;
                if (i === 0 || onlyMove) {
                    return `M ${x} ${y}`;
                } else if (cr) {
                    return `A ${cr} ${cr} 0 0 ${cw ? 1 : 0} ${x} ${y}`;
                } else {
                    return `L ${x} ${y}`;
                }
            })
            .join(' ');
    }

    private getTextPosition(line: Line) {
        const minX = Math.min(line[0].x, line[1].x);
        const maxX = Math.max(line[0].x, line[1].x);
        const minY = Math.min(line[0].y, line[1].y);
        const maxY = Math.max(line[0].y, line[1].y);
        return {
            x: (maxX - minX) / 2 + minX,
            y: (maxY - minY) / 2 + minY
        }
    }
}
