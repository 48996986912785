import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import { CustomPricesRecords } from '@icc/common/custom-price/CustomPrice';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import {Injectable, Inject} from '@angular/core';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';


/**
 * Fabryka dopłat za klamki.
 * @param {object} IccConfig IccConfig
 * @return {object} Fabryka.
 */
@Injectable()
export class PriceHandlesService {

    constructor(@Inject(APP_CONFIG) private config: AppConfigFactory) {}

    /**
     * Wylicza dopłatę za klamkę w danym kolorze.
     */
    suppHandle(PriceElems, NoPriceCauses, {sash, handleCustomPrice}: {sash, handleCustomPrice: CustomPricesRecords}, system): PriceSegment {
        if (sash.handle && Common.isObject(sash.handleColor) && Common.isObject(sash.handle.colors_prices)
            && Common.isDefined(sash.handle.colors_prices[sash.handleColor.id])
            && (sash.handle.handle_type === 'innerLever'
                || ['doubleLever', 'doubleKnob', 'leverKnob', 'pull'].indexOf(sash.handle.handle_type) > -1
                    && sash.handleOuter && Common.isObject(sash.handleOuterColor) && Common.isObject(sash.handleOuter.colors_prices)
                    && Common.isDefined(sash.handleOuter.colors_prices[sash.handleOuterColor.id])
            )
        ) {
            let price = 0;
            let priceInner = sash.handle.colors_prices[sash.handleColor.id];
            if (Common.isObject(handleCustomPrice) && handleCustomPrice[sash.handle.id]) {
                priceInner = handleCustomPrice[sash.handle.id].getPrice('WindowHandlesColor', sash.handleColor.id);
            }

            if (sash.handle.handle_type === 'innerLever'
                || ['doubleLever', 'doubleKnob', 'leverKnob', 'pull'].indexOf(sash.handle.handle_type) > -1
                    && ~~sash.handle.id === ~~sash.handleOuter.id
                    && ~~sash.handleColor.id === ~~sash.handleOuterColor.id
                    && !this.config().IccConfig.Configurators.separateHandles
            ) {
                if (!sash.handle.price_only_for_second_color) {
                    price = parseFloat(priceInner);
                }
            } else {
                let priceOuter = sash.handleOuter.colors_prices[sash.handleOuterColor.id];
                if (Common.isObject(handleCustomPrice) && handleCustomPrice[sash.handleOuter.id]) {
                    priceOuter = handleCustomPrice[sash.handleOuter.id].getPrice('WindowHandlesColor', sash.handleOuterColor.id, 'price');
                }
                if (this.config().IccConfig.Configurators.handlesSetHalfPrice) {
                    price = (parseFloat(priceInner) + parseFloat(priceOuter)) / 2;
                } else {
                    if (sash.handle.price_only_for_second_color || sash.handleOuter.price_only_for_second_color) {
                        price = parseFloat(priceInner) >= parseFloat(priceOuter) ? parseFloat(priceInner) : parseFloat(priceOuter);
                    } else {
                        price = parseFloat(priceInner) + parseFloat(priceOuter);
                    }
                }
            }

            price += parseFloat(sash.handle.price_for_assembly) || 0;

            if(Number(sash.handle.id) !== Number(system.default_window_handler_id) && system.price_for_change_default_handle) {
                price += Number(system.price_for_change_default_handle);
            }
            if(system.default_handler_price_in_raster) {
                price -= Number(system.default_handler_price);
            }
            if(!this.config().IccConfig.Configurators.sliding_door.outerHandles) {
                PriceElems.handles.push({
                    id            : sash.handle?.id,
                    idOuter       : sash.handleOuter?.id,
                    sashId        : sash.id,
                    sashIndex     : sash.index,
                    name          : sash.handle?.name,
                    nameOuter     : sash.handleOuter?.name,
                    price         : price,
                    colorName     : sash.handleColor?.name,
                    outerColorName: sash.handleOuterColor?.name,
                });
                return {
                    type: 'handles',
                    baseValue: price,
                    value: price,
                    valueType: 'value',
                    data: {
                        id            : sash.handle?.id,
                        idOuter       : sash.handleOuter?.id,
                        sashId        : sash.id,
                        sashIndex     : sash.index,
                        name          : sash.handle?.name,
                        nameOuter     : sash.handleOuter?.name,
                        colorName     : sash.handleColor?.name,
                        outerColorName: sash.handleOuterColor?.name
                    }
                };
            } else {
                PriceElems.handles.push({
                    id            : sash.handle.id,
                    sashId        : sash.id,
                    sashIndex     : sash.index,
                    name          : sash.handle.name,
                    price         : price,
                    colorName     : sash.handleColor.name,
                });
                return {
                    type: 'handles',
                    baseValue: price,
                    value: price,
                    valueType: 'value',
                    data: {
                        id            : sash.handle.id,
                        sashId        : sash.id,
                        sashIndex     : sash.index,
                        name          : sash.handle.name,
                        colorName     : sash.handleColor.name,
                    }
                };
            }
        } else {
            NoPriceCauses.push('no handle in sash');
            return {
                type: 'handles',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {
                    sashId: sash.id
                }
            };
        }
    }

    suppHandleOuter(PriceElems, NoPriceCauses, {sash, handleCustomPrice}: {sash, handleCustomPrice: CustomPricesRecords}, system): PriceSegment {
        if (sash.handleOuter && Common.isObject(sash.handleOuterColor) && Common.isObject(sash.handleOuter.colors_prices)
            && Common.isDefined(sash.handleOuter.colors_prices[sash.handleOuterColor.id])
            && this.config().IccConfig.Configurators.sliding_door.outerHandles
        ) {
            let price = 0;
            let priceOuter = sash.handleOuter.colors_prices[sash.handleOuterColor.id];
            if (Common.isObject(handleCustomPrice) && handleCustomPrice[sash.handleOuter.id]) {
                priceOuter = handleCustomPrice[sash.handleOuter.id].getPrice('WindowHandlesColor', sash.handleOuterColor.id, 'price');
            }
            price += parseFloat(priceOuter) || 0;


            PriceElems.handles.push({
                id: sash.handleOuter.id,
                sashId: sash.id,
                sashIndex: sash.index,
                name: sash.handleOuter.name,
                price,
                colorName: sash.handleOuterColor.name,
            });
            return {
                type: 'handlesOuter',
                baseValue: price,
                value: price,
                valueType: 'value',
                data: {
                    id            : sash.handleOuter.id,
                    sashId        : sash.id,
                    sashIndex     : sash.index,
                    name          : sash.handleOuter.name,
                    colorName     : sash.handleOuterColor.name,
                }
            };
        } else {
            PriceElems.handles.push({
                sashId: sash.id,
                sashIndex: sash.index,
                price: 0,
            });
            return {
                type: 'handlesOuter',
                baseValue: 0,
                value: 0,
                valueType: 'value',
                data: {
                    sashId        : sash.id,
                    sashIndex     : sash.index,
                }
            };
        }
    }

    /**
     * Doplicza dopłatę za klamki.
     */
    @PriceFunc({
        shortName: 'handles',
        data: {
            sashes           : 'conf.Sashes',
            fitting          : 'conf.Fitting',
            system           : 'conf.System',
            dividers         : 'conf.Mullions',
            handleCustomPrice: 'price.WindowAccessory',
            systemCustomPrice: 'price.WindowLine'
        }
    })
    suppHandles({PriceElems, NoPriceCauses}: PriceElemsData,
        {sashes, fitting, system, dividers, handleCustomPrice, systemCustomPrice}
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        sashes.filter(sash => ['F', 'FF', 'OFF', 'DS', 'ODS', 'DSC', 'DRP', 'DOP'].indexOf(sash.type.type) === -1).forEach(sash => {
            if (Common.isUndefined(sash.handle)
                || (Common.isArray(sash.handle.window_fittings_ids)
                && Common.isObject(fitting)
                && (
                    sash.handle.window_fittings_ids.indexOf(fitting.id) === -1
                    && !this.config().IccConfig.Configurators.selectHandleOnChangeFitting
                ))
            ) {
                NoPriceCauses.push('handle no match to fitting');
                priceSegments.push({
                    type     : 'handles',
                    baseValue: null,
                    value    : null,
                    valueType: 'value',
                    data     : {}
                });
            } else {
                priceSegments.push(this.suppHandle(PriceElems, NoPriceCauses, {sash, handleCustomPrice}, system));
                priceSegments.push(this.priceHandleHeight(sash, system, dividers, PriceElems, NoPriceCauses, systemCustomPrice));
                priceSegments.push(this.suppHandleOuter(PriceElems, NoPriceCauses, {sash, handleCustomPrice}, system));
            }
        });
        return priceSegments;
    }


    /**
     * Zwraca dopłatę za zmianę wysokości klamki.
     *
     * @return {number} Dopłata za zmianę wysokości klamki
     */
    priceHandleHeight(sash, system, dividers, PriceElems, NoPriceCauses, systemCustomPrice: CustomPricesRecords): PriceSegment {
        if (!sash.defaultHandleHeight && ((sash.type.handle_position !== 'T' && sash.type.type === 'K') || sash.type.type !== 'K')) {
            let divLeft  = <any>core.fIdO(dividers, sash.nearMullions.left);
            let divRight = <any>core.fIdO(dividers, sash.nearMullions.right);
            let price    = system.handle_change_price * 1;
            if (Common.isObject(systemCustomPrice) && systemCustomPrice[system.id]) {
                price = systemCustomPrice[system.id].getPrice('handle_change_price');
            }
            if (sash.handleHeightType === 'nonstandard') {
                price = NaN;
            }

            if (this.config().IccConfig.Configurators.price.doubleHandleDS
                && (sash.type.handle_position === 'L' && divLeft && divLeft.div && divLeft.div.type === 'false_mullion'
                || sash.type.handle_position === 'R' && divRight && divRight.div && divRight.div.type === 'false_mullion')
            ) {
                price += price;
            }

            PriceElems.handlesHeights.push({
                height   : sash.rHandleY,
                sashId   : sash.id,
                sashIndex: sash.index,
                price    : price
            });
            if (!isNaN(price)) {
                return {
                    type     : 'handlesHeights',
                    baseValue: price,
                    value    : price,
                    valueType: 'value',
                    data     : {
                        height   : sash.rHandleY,
                        sashId   : sash.id,
                        sashIndex: sash.index,
                    }
                };
            } else {
                if (sash.handleHeightType === 'nonstandard') {
                    NoPriceCauses.push('nonstandard handle height');
                } else {
                    NoPriceCauses.push('no handle height price');
                }
                return {
                    type     : 'handlesHeights',
                    baseValue: null,
                    value    : null,
                    valueType: 'value',
                    data     : {
                        height   : sash.rHandleY,
                        sashId   : sash.id,
                        sashIndex: sash.index,
                    }
                };
            }
        } else {
            PriceElems.handlesHeights.push({
                height   : sash.rHandleY,
                sashId   : sash.id,
                sashIndex: sash.index,
                price    : 0
            });
        }
        return {
            type     : 'handlesHeights',
            baseValue: 0,
            value    : 0,
            valueType: 'value',
            data     : {
                height   : sash.rHandleY,
                sashId   : sash.id,
                sashIndex: sash.index,
            }
        };
    }
}
