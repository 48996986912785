import { NgModule } from '@angular/core';

import { ReinforcementsModalService } from './reinforcements-modal.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [
        ReinforcementsModalService,
    ],
})
export class ReinforcementsModule {}
