import { core } from '../../../helpers';

/**
 * Przeniesienie kolorów rolet do kolorów
 */
export function changesInVersion10(oldConfiguration, dataRequiredToUpdate) {
    const configuration = core.copy(oldConfiguration);
    if (configuration.rollerShutter && configuration.rollerShutter.colors) {
        Object.keys(configuration.rollerShutter.colors).forEach(c => {
            if (
                configuration.rollerShutter.colors[c]
                && configuration.rollerShutter.colors[c] !== null
                && configuration.rollerShutter.colors[c].id
                    in dataRequiredToUpdate.rollerShutterColorsMap
            ) {
                configuration.rollerShutter.colors[c].id =
                    dataRequiredToUpdate.rollerShutterColorsMap[
                        configuration.rollerShutter.colors[c].id
                    ];
            }
        });
    }
    return configuration;
}
