import { Component, OnInit, Inject } from '@angular/core';
import { _, StepComponent, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { Profile } from '@icc/window';

@Component({
    selector: 'icc-profile-info',
    templateUrl: './profile-info.component.html',
    styleUrls: ['./profile-info.component.scss'],
})
export class ProfileInfoComponent extends StepComponent implements OnInit {
    public configurator = 'window';
    public stepId = 'sashInfo';
    public title = _('WINDOW|Informacja na temat produktu');
    
    button = false;
    profile: Profile | null = null;
    constructor(
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA) private pageData: {
            profile: Profile,
            button: boolean,
        }
    ) {
        super();
    }

    ngOnInit() {
        this.profile = this.pageData?.profile;
        this.button = this.pageData?.button;
    }

    close() {
        this.sharedFacade.closePage(true);
    }
}
