import { core } from '../../..//helpers';

/**
 * Przeniesienie profili rolet do profili
 */
export function changesInVersion6(oldConfiguration, dataRequiredToUpdate) {
    const configuration = core.copy(oldConfiguration);
    if (configuration.rollerShutter && configuration.rollerShutter.profile) {
        configuration.rollerShutter.profile.id =
            dataRequiredToUpdate.rollerShutterProfilesMap[configuration.rollerShutter.profile.id];
    }
    return configuration;
}
