import * as io from 'socket.io-client';
import { core } from '@icc/common/helpers';
import { Injectable, Inject } from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { TokenService } from '../authorization/token.service';
import { EventBusService } from '@icc/common/event-bus.service';

@Injectable()
export class SocketService {
    socket: SocketIOClient.Socket;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private tokenService: TokenService,
        EventBusService: EventBusService
    ) {
        'ngInject';

        this.socket = io(this.config().EnvConfig.remoteHost || window.location.origin, {
            path: '/api/socket.io',
            query: {
                worker: core.isWorker(),
                token: this.tokenService.getToken(),
                machine: config().machine,
            },
        });

        EventBusService.subscribeWithoutConfiguration('changedToken', data => {
            this.socket.emit('changedToken', data.value);
        });
    }
}
