import { core } from '../helpers';
import { Common } from '../Common';
import Position from './Position';
import { OfferDiscountsService } from './OfferDiscountsService';
import { PositionDetailedSummaryService } from '../PositionDetailedSummaryService';
import { currencyExchange, currencyExchangeReverse } from '@icc/helpers';

export class OfferTransportCostService {
    static splitTransportCost(positions, offer, IccConfig, dealer, allDiscounts, user) {
        if (offer.split_transport_cost) {
            positions = positions.map(p =>
                this.removeTransportCost(p, offer, IccConfig, dealer, allDiscounts)
            );
        }
        const offerPriceWithoutDiscount = positions
            .filter(p => p.confType !== 'additional' && !p.coupled_position_id)
            .reduce((sum, p) => sum + p.dealer_price * p.quantity, 0);
        positions = positions.map(p =>
            this.addTransportCost(
                p,
                offer,
                IccConfig,
                dealer,
                allDiscounts,
                offerPriceWithoutDiscount
            )
        );

        return this.recalculateOfferPrice(positions, offer, IccConfig, true, user);
    }

    static joinTransportCost(positions, offer, IccConfig, dealer, allDiscounts, user) {
        positions = positions.map(p =>
            this.removeTransportCost(p, offer, IccConfig, dealer, allDiscounts)
        );
        return this.recalculateOfferPrice(positions, offer, IccConfig, false, user);
    }

    static recalculateOfferPrice(positions, offer, IccConfig, split, user) {
        Common.extend(offer, {
            dealer_price_before_discount_position: 0,
            dealer_price_before_discount: 0,
            dealer_price: 0,
            split_transport_cost: split,

            summary: {
                client: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        glassPromotions: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        basePromotions: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                dealer: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        glassPromotions: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        basePromotions: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                promotions: {
                    raster: [],
                    glass: [],
                },
            },
        });

        positions
            .filter(p => p.confType !== 'additional' && !p.coupled_position_id)
            .forEach(p => {
                p.details = core.parseJson(p.details);
                offer.dealer_price_before_discount_position += core.roundPrice(
                    p.dealer_price_before_discount * p.quantity
                );
                offer.dealer_price_before_discount += core.roundPrice(p.dealer_price * p.quantity);
                offer.dealer_price +=
                    core.roundPrice(p.dealer_price * p.quantity)
                    - core.roundPrice(
                        (1 / 100)
                            * p.dealer_price
                            * p.quantity
                            * offer.dealer_discount_producer_special
                    );

                if (p.details.priceParts) {
                    p.details.pricePartsNoMargin = p.details.pricePartsNoMargin.filter(
                        part => part.valueType !== 'transport'
                    );
                    if (split) {
                        const transportPart = {
                            basePercent: null,
                            baseValue: currencyExchangeReverse(p.transport_cost, offer.currency),
                            percentId: null,
                            percentType: null,
                            value: currencyExchangeReverse(p.transport_cost, offer.currency),
                            valueId: 0,
                            valueType: 'transport',
                        };
                        p.details.pricePartsNoMargin.push(transportPart);
                    }
                }
                if (IccConfig.Offer.detailedSummary) {
                    offer.summary = PositionDetailedSummaryService.detailedSummary(
                        p,
                        IccConfig,
                        offer.currency,
                        offer.summary
                    );
                }
                p.details = JSON.stringify(p.details);
            });

        if (!split) {
            this.addTransportService(offer, IccConfig);
        }

        if (IccConfig.Offer.offerDiscountsMulti) {
            const discountsData = OfferDiscountsService.groupDiscounts(
                offer.group_discounts,
                offer.dealer_price_before_discount,
                offer.client_price_before_discount,
                user,
                offer,
                IccConfig
            );
            const offerGroupDiscounts = discountsData.discounts;
            offer.dealer_price = offerGroupDiscounts.length
                ? discountsData.dealerPrice
                : offer.dealer_price;
            offer.client_price = offerGroupDiscounts.length
                ? discountsData.clientPrice
                : offer.dealer_price;
        }

        return {
            positions,
            offer,
        };
    }

    static removeTransportCost(p, offer, IccConfig, dealer, allDiscounts) {
        if (p.confType === 'additional' || p.coupled_position_id) {
            return p;
        }
        const pos = new Position(
            Common.extend({}, p, {
                offer,
                transport_cost: null,
                dealer_price_before_discount: p.dealer_price_without_transport,
                dealer_price: null,
                recalculateDiscount: true,
            }),
            IccConfig,
            dealer,
            allDiscounts
        );
        pos.modified = pos.modified_tmp;
        delete pos.modified_tmp;
        delete pos.synced;
        return pos;
    }

    static addTransportCost(p, offer, IccConfig, dealer, allDiscounts, offerPriceWithoutDiscount) {
        if (p.confType === 'additional' || p.coupled_position_id) {
            return p;
        }

        let transportCost = (offer.transport_cost * p.dealer_price) / offerPriceWithoutDiscount;
        let groupDiscounts = p.group_discounts;
        if (!Common.isObject(groupDiscounts)) {
            groupDiscounts = core.parseJson(groupDiscounts);
        }
        const groupDiscount = groupDiscounts.find(el => el.dealer.type === 'system');
        const discount = groupDiscount ? groupDiscount.dealer.discount : 0;
        transportCost = (transportCost * 100) / (100 - discount);

        const pos = new Position(
            Common.extend({}, p, {
                offer,
                transport_cost: transportCost,
                dealer_price_without_transport: p.dealer_price_before_discount,
                dealer_price_before_discount: p.dealer_price_before_discount + transportCost,
                dealer_price: null,
                recalculateDiscount: true,
            }),
            IccConfig,
            dealer,
            allDiscounts
        );
        pos.modified = pos.modified_tmp;
        delete pos.modified_tmp;
        delete pos.synced;
        return pos;
    }

    static addTransportService(offer, IccConfig, modifyDealerPrice = true) {
        if (offer.transport_from_producent) {
            offer.summary = PositionDetailedSummaryService.detailedSummary(
                {
                    quantity: 1,
                    details: {
                        goodType: 'service',
                    },
                    confType: 'other',
                    client_price_before_discount: 0,
                    dealer_price_before_discount: offer.transport_cost,
                },
                IccConfig,
                offer.currency,
                offer.summary
            );
        }
        if (offer.transport_from_producent_to_client) {
            offer.summary = PositionDetailedSummaryService.detailedSummary(
                {
                    quantity: 1,
                    details: {
                        goodType: 'service',
                    },
                    confType: 'other',
                    client_price_before_discount: offer.transport_cost,
                    dealer_price_before_discount: offer.transport_cost,
                },
                IccConfig,
                offer.currency,
                offer.summary
            );
        }

        if (modifyDealerPrice) {
            offer.dealer_price_before_discount_position += offer.transport_cost;
            offer.dealer_price_before_discount += offer.transport_cost;
            offer.dealer_price +=
                offer.transport_cost
                - core.roundPrice(
                    (1 / 100) * offer.transport_cost * offer.dealer_discount_producer_special
                );

            if (offer.transport_from_producent_to_client) {
                offer.dealer_client_price_before_discount_position += offer.transport_cost;
                offer.dealer_client_price_before_discount += offer.transport_cost;
                offer.dealer_client_price += offer.transport_cost
                    - core.roundPrice(
                        (1 / 100)
                            * offer.transport_cost
                            * offer.client_discount_special
                    );

                offer.client_price_before_discount_position += offer.transport_cost;
                offer.client_price_before_discount += offer.transport_cost;
                offer.client_price +=
                    offer.transport_cost
                    - core.roundPrice(
                        (1 / 100) * offer.transport_cost * offer.client_discount_special
                    );
            }
        }
    }

    static splitClientTransportCost(positions, offer, IccConfig, dealer, allDiscounts) {
        const offerPriceWithoutDiscount = positions.reduce(
            (sum, p) => sum + p.client_price * p.quantity,
            0
        );
        positions = positions.map(p =>
            this.addClientTransportCost(
                p,
                offer,
                IccConfig,
                dealer,
                allDiscounts,
                offerPriceWithoutDiscount
            )
        );

        return this.recalculateOfferClientPrice(positions, offer, IccConfig, true);
    }

    static joinClientTransportCost(positions, offer, IccConfig, dealer, allDiscounts) {
        positions = positions.map(p =>
            this.removeClientTransportCost(p, offer, IccConfig, dealer, allDiscounts)
        );
        return this.recalculateOfferClientPrice(positions, offer, IccConfig, false);
    }

    static recalculateOfferClientPrice(positions, offer, IccConfig, split) {
        Common.extend(offer, {
            dealer_client_price_before_discount_position: 0,
            dealer_client_price_before_discount: 0,
            dealer_client_price: 0,

            client_price_before_discount_position: 0,
            client_price_before_discount: 0,
            client_price: 0,

            summary: {
                client: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        glassPromotions: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        basePromotions: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                dealer: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        glassPromotions: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        basePromotions: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                promotions: {
                    raster: [],
                    glass: [],
                },
            },
        });

        positions.forEach(p => {
            p.details = core.parseJson(p.details);

            if (!p.coupled_position_id) {
                if (p.confType !== 'additional') {
                    offer.dealer_client_price_before_discount_position += core.roundPrice(
                        p.client_price_before_discount * p.quantity
                    );
                    offer.dealer_client_price_before_discount += core.roundPrice(
                        p.client_price * p.quantity
                    );
                    offer.dealer_client_price +=
                        core.roundPrice(p.client_price * p.quantity)
                        - core.roundPrice(
                            (1 / 100) * p.client_price * p.quantity * offer.client_discount_special
                        );
                }

                offer.client_price_before_discount_position += core.roundPrice(
                    p.client_price_before_discount * p.quantity
                );
                offer.client_price_before_discount += core.roundPrice(p.client_price * p.quantity);
                offer.client_price +=
                    core.roundPrice(p.client_price * p.quantity)
                    - core.roundPrice(
                        (1 / 100) * p.client_price * p.quantity * offer.client_discount_special
                    );

                if (p.details.priceParts) {
                    p.details.priceParts = p.details.priceParts.filter(
                        part => part.valueType !== 'transport'
                    );
                    if (split) {
                        const transportPart = {
                            basePercent: null,
                            baseValue: currencyExchangeReverse(p.client_transport_cost, offer.currency),
                            percentId: null,
                            percentType: null,
                            value: currencyExchangeReverse(p.client_transport_cost, offer.currency),
                            valueId: 0,
                            valueType: 'transport',
                        };
                        p.details.priceParts.push(transportPart);
                    }
                }
                if (IccConfig.Offer.detailedSummary) {
                    offer.summary = PositionDetailedSummaryService.detailedSummary(
                        p,
                        IccConfig,
                        offer.currency,
                        offer.summary
                    );
                }
            }
            p.details = JSON.stringify(p.details);
        });

        if (offer.transport_cost) {
            this.addTransportService(offer, IccConfig, false);
        }

        if (!split) {
            this.addClientTransportService(offer, IccConfig);
        }

        return {
            positions,
            offer,
        };
    }

    static removeClientTransportCost(p, offer, IccConfig, dealer, allDiscounts) {
        const pos = new Position(
            Common.extend({}, p, {
                offer,
                client_transport_cost: null,
                client_price_before_discount: p.client_price_without_transport,
                client_price: null,
                recalculateClientDiscount: true,
            }),
            IccConfig,
            dealer,
            allDiscounts
        );
        pos.modified = pos.modified_tmp;
        delete pos.modified_tmp;
        delete pos.synced;
        return pos;
    }

    static addClientTransportCost(
        p,
        offer,
        IccConfig,
        dealer,
        allDiscounts,
        offerPriceWithoutDiscount
    ) {
        let transportCost =
            offerPriceWithoutDiscount > 0
                ? (offer.client_transport_cost * p.client_price) / offerPriceWithoutDiscount
                : offer.client_transport_cost;
        let groupDiscounts = p.group_discounts;
        if (!Common.isObject(groupDiscounts)) {
            groupDiscounts = core.parseJson(groupDiscounts);
        }
        const groupDiscount = groupDiscounts.find(el => el.client.type === 'system');
        const discount = groupDiscount ? groupDiscount.client.discount : 0;
        transportCost = (transportCost * 100) / (100 - discount);

        const pos = new Position(
            Common.extend({}, p, {
                offer,
                client_transport_cost: transportCost,
                client_price_without_transport: p.client_price_before_discount,
                client_price_before_discount: p.client_price_before_discount + transportCost,
                client_price: null,
                recalculateClientDiscount: true,
            }),
            IccConfig,
            dealer,
            allDiscounts
        );
        pos.modified = pos.modified_tmp;
        delete pos.modified_tmp;
        delete pos.synced;
        return pos;
    }

    static addClientTransportService(offer, IccConfig) {
        const priceBeforeDiscount = core.roundPrice(
            offer.client_transport_cost / (1 - offer.client_discount_position / 100)
        );
        offer.summary = PositionDetailedSummaryService.detailedSummary(
            {
                quantity: 1,
                details: {
                    goodType: 'service',
                },
                confType: 'additional',
                client_price_before_discount: priceBeforeDiscount,
            },
            IccConfig,
            offer.currency,
            offer.summary
        );

        offer.client_price_before_discount_position += priceBeforeDiscount;
        offer.client_price_before_discount += offer.client_transport_cost;
        offer.client_price +=
            offer.client_transport_cost
            - core.roundPrice(
                (1 / 100) * offer.client_transport_cost * offer.client_discount_special
            );
    }

    static belowLogisticMinimum(user, offer) {
        return (
            user
            && (isNaN(offer.dealer_price)
                || offer.dealer_price < Number(user.transport_logistic_min))
        );
    }

    static transportM2Cost(user, offer) {
        if (!user) {
            return 0;
        }
        const costM2 = offer.area * Number(user.transport_m2_cost);
        return currencyExchange(costM2 > Number(user.transport_min_cost) ? costM2 : Number(user.transport_min_cost), offer.currency);
    }

    static transportWeightCost(user, offer) {
        if (!user) {
            return 0;
        }
        const costWeight = offer.weight * Number(user.transport_weight_cost);
        return currencyExchange(costWeight > Number(user.transport_min_cost)
            ? costWeight
            : Number(user.transport_min_cost),
            offer.currency);
    }

    static transportTypesCost(
        offer,
        allPositions,
        transportCostTypes,
        transportCosts
    ) {
        const transportPrices = [];
        const positions = allPositions
            .filter(
                el =>
                    [
                        'additional',
                        'other',
                        'complementary_goods',
                        'accessory',
                    ].indexOf(el.confType) === -1
            );
        transportCosts = transportCosts.filter(e=>e.postCodes);
        const availableTransportCosts = transportCosts.filter(transportCost =>
            transportCost.country_id === offer.country_id
            && (!transportCost?.postCodes?.length || offer.postal_code && offer?.postal_code?.length && transportCost.postCodes.indexOf(offer.postal_code) > -1)
            && ((transportCost.order_content === 'quantity' && Number(offer.quantity) >= Number(transportCost.order_content_from || 0) && Number(offer.quantity) <= Number(transportCost.order_content_to || Infinity))
            || (transportCost.order_content === 'price' && Number(offer.dealer_price) >= Number(transportCost.order_content_from || 0) && Number(offer.dealer_price) <= Number(transportCost.order_content_to || Infinity))
            || (transportCost.order_content === 'conversion_weight' && Number(offer.weight) >= Number(transportCost.order_content_from || 0) && Number(offer.weight) <= Number(transportCost.order_content_to || Infinity)))
            && transportCost.type !== 'delivery_not_available'
        );
        const unmatchedBlockRules = [];
        availableTransportCosts.forEach(transportCost => {
            if (positions.every(position => {
                    const unmatchedRules = OfferTransportCostService.matchOversizedRules(position, transportCost, 'block') || [];
                    unmatchedRules.forEach(rule => {
                        if (unmatchedBlockRules.every(r => r !== rule.id)) {
                            unmatchedBlockRules.push(rule.id);
                        }
                    });
                    return unmatchedRules.length === 0;
                })
            ) {
                transportPrices.push({
                    transportCostTypeId: transportCost.transport_cost_type_id,
                    type: transportCost.type,
                    price: Number(transportCost.price),
                    transportCost
                });
            }
        });
        const transportTypesPrices = {};
        transportPrices.forEach(el => {
            if (!transportTypesPrices[el.transportCostTypeId]) {
                transportTypesPrices[el.transportCostTypeId] = {};
            }
            if (!transportTypesPrices[el.transportCostTypeId][el.type]) {
                transportTypesPrices[el.transportCostTypeId][el.type] = {
                    transportCostTypeId: el.transportCostTypeId,
                    price: null,
                    type: el.type,
                    transportCost: el.transportCost
                }
            }
            if (transportTypesPrices[el.transportCostTypeId][el.type].price === null || transportTypesPrices[el.transportCostTypeId][el.type].price > el.price){
                transportTypesPrices[el.transportCostTypeId][el.type] = el;
            }
        });

        let transportTypes = [];
        Object.keys(transportTypesPrices).forEach(transportType => {
            transportTypes.push(transportTypesPrices[transportType]['agreed_price'] || transportTypesPrices[transportType]['expected_price'] || transportTypesPrices[transportType]['on_request']);
        });
        const order = ['agreed_price', 'expected_price', 'on_request'];
        transportTypes = transportTypes.sort((a, b) => (order.indexOf(a.type) - order.indexOf(b.type)) || b.price - a.price);
        if (transportTypes.map(cost => cost.type).filter((value, index, self) => self.indexOf(value) === index).length > 1) {
            transportTypes = transportTypes.slice(0, 1);
        }
        transportTypes.forEach(type => {
            type.unmatchedRules = [];
            positions.forEach(position => {
                const unmatchedRules = OfferTransportCostService.matchOversizedRules(position, type.transportCost, 'warning') || [];
                unmatchedRules.forEach(rule => {
                    if (type.unmatchedRules.every(r => r !== rule.id)) {
                        type.unmatchedRules.push(rule.id);
                    }
                });
                return unmatchedRules.length === 0;
            });
        });


        return {
            transportTypes,
            unmatchedRules: transportTypes.length === 0 ? unmatchedBlockRules : []
        }
    }

    private static matchOversizedRules(position: any, transportCost: any, type: 'block' | 'warning') {
        if (!Common.isObject(position.configuration)) {
            position.configuration = core.parseJson(position.configuration);
        }

        let width = position.details.width || position.configuration.Width || 0;
        let height = position.details.height || position.configuration.Height || 0;
        let positionId = position.details.id;
        let positionCategoriesId = position.details.product_categories ?? [];
        let positionType = position.details.type ;
        let isStatic = positionType === 'static';

        if (position.details?.type === 'roller_shutter' || position.details?.type === 'external_blind') {
            width = position.details.rollerShutter.boxWidth || 0;
            height = position.details.rollerShutter.rollerHeight || 0;
        }

        if (position.details?.type === 'pleated_blind') {
            width = position.details.shape.width || 0;
            height = position.details.shape.height || 0;
        }

        if (position.details?.type === 'garage_door') {
            width = position.details.dimensions?.width || 0;
            height = position.details.dimensions?.height || 0;
        }

        if (position.details?.type === 'complementary_goods') {
            width = 0;
            height = 0;

            position.details?.windowSills.forEach(sill => {
                if (width < sill?.width) {
                    width = sill?.width;
                }
                if (height < sill?.length) {
                    height = sill?.length;
                }
            });

            position.details?.cassonettos?.forEach(cassonetto => {
                if (width < cassonetto?.width) {
                    width = cassonetto?.width;
                }
                if (height < cassonetto?.height) {
                    height = cassonetto?.height;
                }
            });

            position.details?.fillings?.forEach(filling => {
                if (width < filling?.width) {
                    width = filling?.width;
                }
                if (height < filling?.height) {
                    height = filling?.height;
                }
            });

            position.details?.profiles?.forEach(profile => {
                if (width < profile?.length) {
                    width = profile?.length;
                }
            });

            position.details?.accessories?.forEach(accessory => {
                if (width < accessory?.width) {
                    width = accessory?.width;
                }
                if (height < accessory?.height) {
                    height = accessory?.height;
                }
            });
        }

        const weight = !isNaN(position.details.parameters?.weight) ? position.details.parameters.weight : position.configuration.Weight;
        const area = Number(position.area);

        if(isStatic) {
            return transportCost.oversizedRules?.filter(rule => rule.type === type).filter(
                rule => {
                    let productsId = JSON.parse(rule.products_id);
                    let categoriesId = JSON.parse(rule.product_categories_id);
                    let hasProductsId = Array.isArray(productsId);
                    let hasProductsCategoriesId = Array.isArray(categoriesId);
                    let hasEqualProductId = hasProductsId ? productsId?.some(r => r == positionId) : true;
                    let hasEqualProductCategoryId = hasProductsCategoriesId ? categoriesId?.some(r => positionCategoriesId.indexOf(Number(r)) >= 0) : true

                    return !(hasEqualProductId && hasEqualProductCategoryId)
                }
            );
        } else {
            return transportCost.oversizedRules?.filter(rule => rule.type === type).filter(
                rule => {
                    let productsId = JSON.parse(rule.products_id);
                    let categoriesId = JSON.parse(rule.product_categories_id);
                    let productsTypes = JSON.parse(rule.product_types);
                    let hasProductsId = Array.isArray(productsId);
                    let hasProductsCategoriesId = Array.isArray(categoriesId);
                    let hasProductTypes = Array.isArray(productsTypes);
                    let hasEqualProductId = hasProductsId ? productsId?.some(r => r == positionId) : true;
                    let hasEqualProductCategoryId = hasProductsCategoriesId ? categoriesId?.some(r => positionCategoriesId.indexOf(Number(r)) >= 0) : true;
                    let hasEqualProductTypeId = hasProductTypes ? productsTypes?.some(r => r == positionType) : true

                    return !((Number(width) > Number(height)
                                ? Number(width) <= Number(rule.max_length_longer_side) && Number(height) <= Number(rule.max_length_short_side)
                                : Number(height) <= Number(rule.max_length_longer_side) && Number(width) <= Number(rule.max_length_short_side))
                            && Number(weight) <= Number(rule.max_weight)
                            && Number(area) <= Number(rule.max_area)
                            && ((hasEqualProductId && hasEqualProductCategoryId) || hasEqualProductTypeId)
                    )
                }
            );
        }
    }
}
