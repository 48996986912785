<icc-dimensions-legend [shape]="shape?.shape" [variant]="shape?.type" highlight="all"></icc-dimensions-legend>

<form [formGroup]="dimensionsForm">
  <ng-container *ngFor="let field of fields">
    <mat-form-field  *ngIf="field.field">
      <mat-label *ngIf="field.name == 'width'" translate>DIMENSION|Szerokość (S)</mat-label>
      <mat-label *ngIf="field.name == 'height'" translate>DIMENSION|Wysokość (H)</mat-label>
      <mat-label *ngIf="field.name == 'h1'" >H1</mat-label>
      <mat-label *ngIf="field.name == 'h2'" >H2</mat-label>
      <mat-label *ngIf="field.name == 'h3'" >H3</mat-label>
      <mat-label *ngIf="field.name == 's1'" >S1</mat-label>
      <mat-label *ngIf="field.name == 's2'" >S2</mat-label>
      <mat-label *ngIf="field.name == 's3'" >S3</mat-label>
      <mat-label *ngIf="field.name == 'diameter'">{{ 'DIMENSION|Średnica (S):' }}</mat-label>
      <mat-label *ngIf="field.name == 'perimeter'">{{ 'DIMENSION|Obwód:' }}</mat-label>
      <input matInput iccDimensionInput type="number" [formControlName]="field.field">
      <span matPrefix>
          <icc-dimensions-legend [shape]="shape?.shape" [variant]="shape?.type" [highlight]="field.highlight || field.field" [fieldPrefix]="true"></icc-dimensions-legend>
      </span>
      <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
    </mat-form-field>
    <span *ngIf="field.value">
      <p>{{field.value}}</p>
      <span>{{dimensionUnit.unitSymbol}}</span>
    </span>
  </ng-container>
</form>
