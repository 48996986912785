import $ from 'jquery';

/**
 * Kontroler obrazka modala dóbr komplementarnych
 * @param {Object} $uibModalInstance Instancja modalu
 * @param {Object} $timeout       timeout
 * @param {Object} good           Dobro
 */
export default function ModalComplementaryGoodsImageCtrl($uibModalInstance, $timeout, good, ScrollbarService) {
    'ngInject';

    var vm = this;

    vm.good    = good;
    vm.close   = close;
    vm.refresh = refresh;

    /**
     * Zamyka modala bez zmian
     */
    function close() {
        $uibModalInstance.close();
    }

    /**
     * Odswieza plugin do przewijania, by porawic dlugosc scolla
     */
    function refresh() {
        ScrollbarService.update();
    }

}
