import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WINDOW_FEATURE_KEY, WindowState } from './window.reducer';

// Lookup the 'Window' feature state managed by NgRx
const getWindowState = createFeatureSelector<WindowState>(WINDOW_FEATURE_KEY);

const getWindowConfiguration = createSelector(
    getWindowState,
    (state: WindowState) => {
        return state.configuration;
    }
);

export const windowQuery = {
    getWindowConfiguration,
};
