/**
 * Kontroler okna modalnego z materiałami poszycia markizy
 * @param {object}   $uibModalInstance     Modal
 * @param {object}   $timeout           Timeout
 * @param {array}    styles             Lista materiałów
 */
export default function ModalFabricStylesCtrl($uibModalInstance, $timeout, styles) {
    'ngInject';

    var vm = this;

    vm.styles   = styles;
    vm.style    = {};

    vm.selectStyle = selectStyle;
    vm.close       = close;

    function selectStyle(style) {
        vm.style = style;
        $uibModalInstance.close([vm.style]);
    }

    function close() {
        $uibModalInstance.close([vm.style]);
    }
}
