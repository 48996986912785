import { core } from '../helpers';
import { Common } from '../Common';
import { OfferDiscountsService } from './OfferDiscountsService';
import { PositionService } from './PositionService';
import format from 'date-fns/format';

/**
 * konstruktor pozycji oferty
 * @memberof PositionsFactory
 * @param {object} params Elementy potrzebne do utworzenia pozycji
 */
export default function Position(params, IccConfig, dealer, allDiscounts) {
    const tmpId = 'tmp_DealerOfferPosition_' + core.generateUUID();
    const dealerMargin = params.offer ? params.offer.dealer_margin * 1 : 0;
    const currency = core.parseJson(params?.offer?.currency) ?? {};

    const discounts = OfferDiscountsService.generateGroupDiscounts(
        params,
        IccConfig,
        dealer,
        allDiscounts.buyDiscounts,
        allDiscounts.saleDiscounts,
        dealerMargin
    );

    this.id = params.id || tmpId;
    this.tmp_id = params.tmp_id || tmpId;
    this.product_id = params.product_id;
    this.product_ext_id = params.product_ext_id;
    this.confType = params.confType || params.configuration.type || params.details.type;
    this.dealer_offer_id = params.dealer_offer_id || params.offer?.tmp_id;
    this.group_discounts = params.group_discounts || discounts.group_discounts;
    this.sync_date = core.stringJson(params.syncDate);

    this.transport_cost =  params.transport_cost || 0;
    this.client_transport_cost =  Number(params.client_transport_cost) || 0;
    this.dealer_price_without_transport =  params.dealer_price_without_transport || 0;
    this.client_price_without_transport =  params.client_price_without_transport || 0;


    if (this.confType === 'other' && !params.recalculateDiscount) {
        this.dealer_price = Common.isNumber(params.dealer_price)
            ? params.dealer_price
            : params.price_no_margin || params.price;
        this.dealer_price_before_discount = Common.isNumber(params.dealer_price_before_discount)
            ? params.dealer_price_before_discount
            : this.dealer_price + discounts.dealer_discount;
    } else {
        this.dealer_price_before_discount = Common.isNumber(params.dealer_price_before_discount)
            ? params.dealer_price_before_discount
            : params.price_no_margin || params.price;
        this.dealer_price = Common.isNumber(params.dealer_price)
            ? params.dealer_price
            : this.dealer_price_before_discount - discounts.dealer_discount;
    }

    this.dealer_price = this.dealer_price || 0;
    this.dealer_price_before_discount = this.dealer_price_before_discount || 0;

    if (this.confType === 'additional' && !params.recalculateClientDiscount) {
        this.client_price = Common.isNumber(params.client_price)
            ? params.client_price
            : params.price;
        this.client_price_before_discount = Common.isNumber(params.client_price_before_discount)
            ? params.client_price_before_discount
            : this.client_price + discounts.client_discount;
    } else if (this.confType === 'other') {
        this.client_price_before_discount = Common.isNumber(params.client_price_before_discount)
            ? params.client_price_before_discount
            : this.dealer_price_before_discount * (1 + dealerMargin / 100);
        this.client_price = Common.isNumber(params.client_price)
            ? params.client_price
            : this.client_price_before_discount - discounts.client_discount;
    } else {
        this.client_price_before_discount = Common.isNumber(params.client_price_before_discount)
            ? params.client_price_before_discount
            : params.price;
        this.client_price = Common.isNumber(params.client_price)
            ? params.client_price
            : this.client_price_before_discount - discounts.client_discount;
    }

    this.client_price = this.client_price || 0;
    this.client_price_before_discount =  this.client_price_before_discount || 0;

    if (IccConfig.Offer.offerDiscountToPosition && this.confType !== 'additional') {
        const groupDiscounts = OfferDiscountsService.groupDiscounts(
            allDiscounts.offerDiscounts,
            this.client_price_before_discount,
            this.client_price_before_discount,
            { access: null },
            params.offer,
            IccConfig,
            null,
            false,
            this.client_price_before_discount,
            this.client_price_before_discount,
        );
        this.client_price = groupDiscounts.clientPrice;
        const discount = core.round(
            ((this.client_price_before_discount - groupDiscounts.clientPrice)
                / (this.client_price_before_discount))
            * 100
        );
        const groupDiscountsUpdate = core.parseJson(this.group_discounts);
        if (groupDiscountsUpdate?.length > 0) {
            groupDiscountsUpdate[0].client.discount = discount;
            groupDiscountsUpdate[0].client.value = this.client_price_before_discount - groupDiscounts.clientPrice;
            groupDiscountsUpdate[0].client.name = groupDiscounts.discounts?.[0]?.name || '';
            this.group_discounts = core.stringJson(groupDiscountsUpdate);
        }
    }

    this.description = params.description || '';
    this.translated_description = params.translated_description || '';
    this.technique_description = params.technique_description || '';
    this.weight = params.weight || params.configuration.Weight || params.details.parameters?.weight || 0;
    this.file = params.file || null;
    this.image = params.image || null;
    this.created = params.created || format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    this.modified_tmp = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    this.standard = Common.isDefined(params.standard) ? params.standard : true;
    this.configuration = params.configuration;
    this.name = params.name || params.configuration.Name || params.details.name;
    this.quantity = params.quantity || params.configuration.Quantity|| params.details.quantity || 0;
    this.groupCode = params.groupCode;
    this.custom_title = params.custom_title || '';
    this.synced = false;
    this.valuated_price = params.valuated_price || null;

    this.materials_cost = params.materials_cost ? params.materials_cost || 0 : null;

    this.points = params.points || null;
    this.varnished_points = params.varnished_points || null;
    this.details = params.details;
    this.coupled_position_id = params.coupled_position_id;
    this.machine = params.machine || params.offer && params.offer.machine || core.generateUUID();
    this.confType = params.confType || params.details.type;
    this.uw = params.uw || params.details.frames?.[0]?.parameters?.uw || null;

    const dimensionUnit = params?.offer?.dimension_unit || params?.dimension_unit || 'mm';
    const numberOfSpacesAfterDecimalSeparator = params?.offer?.number_of_places_after_decimal_separator || params?.number_of_places_after_decimal_separator || 0;

    const dimensionUnits = {
        dimensionUnit,
        numberOfSpacesAfterDecimalSeparator,
    };

    const { area, glazingArea, circuit, doorCircuit, windowCircuit, shutterCircuit, size } = PositionService.getDimensions(this, IccConfig, dimensionUnits);

    this.area = area;
    this.glazing_area = glazingArea;
    this.circuit = circuit;
    this.shutter_circuit = shutterCircuit;
    this.door_circuit = doorCircuit;
    this.window_circuit = windowCircuit;
    this.size = size;

    this.show_details = params.show_details ?? true;
    this.position_price_edited = params.positionPriceEdited ?? false;
}
