import angular from 'angular';

/**
 * Fabryka akcesoriów markiz
 * @param {object} $rootScope               RootScope
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} ConfiguratorsDataService Fabryka z danymi do konfiguratorów
 * @param {object} CurConfService           Informacje o bieżącym konfiguratorze
 * @param {object} PriceService             Liczenie cen
 */
export default function AccessoryFactory($rootScope, ConfigurationsService, ConfiguratorsDataService, CurConfService, PriceService, EventBusService) {
    'ngInject';

    var factory = {
        accessories     : [],
        selAccessories  : [],
        loadedData      : false,
        selectAccessory : selectAccessory,
        setDefaultValues: setDefaultValues
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Funkcja inicjujaca
     */
    function init() {
        if (CurConfService.conf === 'awning') {
            setValues();
            factory.setDefaultValues();
            factory.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Ustawia wartość
     */
    function setValues() {
        factory.accessories = getAccessores();
    }

    /**
     * Pobiera akcesoria
     * @return {Object} Akcesoria
     */
    function getAccessores() {
        return ConfiguratorsDataService.data.awningAccessories;
    }

    /**
     * Wybór akcesoriów
     * @param  {Array} selAccessories  Wybrane akcesoria
     * @param  {Object} accessory      Akcesorium
     * @param  {Number} price          Cena
     * @return {Array}                 Wybrane akcesoria
     */
    function selectAccessory(selAccessories, accessory, price) {

        var acc = selAccessories;
        var i = 0;
        var j = 0;

        for (i = 0; i < selAccessories.length; i++) {
            selAccessories[i].selected = false;
        }

        selAccessories = [];

        for (i = 0; i < factory.accessories.length; i++) {
            if (accessory.id === factory.accessories[i].id && accessory.selected) {
                selAccessories.push(factory.accessories[i]);
            }
            for (j = 0; j < acc.length; j++) {
                if (acc[j].id === factory.accessories[i].id) {
                    selAccessories.push(factory.accessories[i]);
                }
            }
            if (accessory.id === factory.accessories[i].id && !accessory.selected) {
                selAccessories.pop(factory.accessories[i]);
            }
        }

        for (i = 0; i < selAccessories.length; i++) {
            selAccessories[i].selected = true;
            if (selAccessories[i].prices.length > 0 && ConfigurationsService.conf.Current.Width > 0) {
                selAccessories[i].price = '-1';
                for (j = 0; j < selAccessories[i].prices.length; j++) {
                    if (selAccessories[i].prices[j].min_width <= ConfigurationsService.conf.Current.Width
                        && ConfigurationsService.conf.Current.Width <= selAccessories[i].prices[j].max_width
                    ) {
                        selAccessories[i].price = selAccessories[i].prices[j].price;
                    }
                }
            }
        }

        factory.selAccessories = selAccessories;
        ConfigurationsService.conf.Current.Addons = selAccessories;
        ConfigurationsService.conf.Default.Addons = [];

        if (price) {
            PriceService.count();
        }

        return selAccessories;
    }

    /**
     * Ustaw wartości domyślne
     * @param {Object} reset Reset
     */
    function setDefaultValues(reset) {

        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }

        setValues();

        for (var i = 0; i < factory.accessories.length; i++) {
            factory.accessories[i].selected = false;
            factory.selAccessories = [];
        }

        ConfigurationsService.conf.Default.Addons = [];

    }

}
