import { FabricsComponent } from './fabrics/fabrics.component';
import { VariantsComponent } from './variants/variants.component';
import { DrivesComponent } from './drives/drives.component';
import { DimensionsComponent } from './dimensions/dimensions.component';
import { AccessoriesComponent } from '@icc/configurator/accessory';
import { StepComponentType } from '@icc/configurator/shared';
import { SystemsComponent } from './systems/systems.component';
import { ProductDetailsComponent } from '@icc/configurator/pages';


export const pleatedBlindStepsMap: {
    [stepId: string]: StepComponentType;
} = {
    systems: SystemsComponent,
    dimensions: DimensionsComponent,
    variants: VariantsComponent,
    fabrics: FabricsComponent,
    drives: DrivesComponent,
    accessory: AccessoriesComponent,
    details: ProductDetailsComponent
};
