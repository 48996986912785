import { Component, OnInit, Type, Inject } from '@angular/core';
import { _, PageComponent, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { Shape } from '@icc/window';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { BrowserShapeService } from '@icc/legacy/configurator/layout/shape.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { AppConfigFactory, APP_CONFIG } from '@icc/common';

interface IccShapeListItem extends iccListItem {
    shape: Shape['shape'];
    variant?: Shape['type'];
}

@Component({
    selector: 'icc-shapes',
    templateUrl: './shapes.component.html',
    styleUrls: ['./shapes.component.scss'],
})
export class ShapesComponent extends PageComponent implements OnInit {
    public title = _('PLEATEDBLIND|Kształt konstrukcji');
    public options = [];

    public selectedId = 'rect';

    shapes: IccShapeListItem[] = [];

    rectShapes: IccShapeListItem[] = [
        {
            id: 'rect',
            shape: 'rect',
        },
    ];

    arcShapes: IccShapeListItem[] = [
        {
            id: 'arc_F',
            shape: 'arc',
            variant: 'F',
        },
        {
            id: 'arc_L',
            shape: 'arc',
            variant: 'L',
        },
        {
            id: 'arc_R',
            shape: 'arc',
            variant: 'R',
        },
    ];

    circleShapes: IccShapeListItem[] = [
        {
            id: 'circle',
            shape: 'circle',
        },
    ];

    triangleShapes: IccShapeListItem[] = [
        {
            id: 'triangle_F',
            shape: 'triangle',
            variant: 'F',
        },
        {
            id: 'triangle_L',
            shape: 'triangle',
            variant: 'L',
        },
        {
            id: 'triangle_R',
            shape: 'triangle',
            variant: 'R',
        },
    ];

    polygonShapes: IccShapeListItem[] = [
        {
            id: 'poligon_SLT',
            shape: 'poligon',
            variant: 'SLT',
        },
        {
            id: 'poligon_SRT',
            shape: 'poligon',
            variant: 'SRT',
        },
        {
            id: 'poligon_SLS',
            shape: 'poligon',
            variant: 'SLS',
        },
        {
            id: 'poligon_SRS',
            shape: 'poligon',
            variant: 'SRS',
        },
        {
            id: 'poligon_SLC',
            shape: 'poligon',
            variant: 'SLC',
        },
        {
            id: 'poligon_SRC',
            shape: 'poligon',
            variant: 'SRC',
        },
        {
            id: 'poligon_DLT',
            shape: 'poligon',
            variant: 'DLT',
        },
        {
            id: 'poligon_DLS',
            shape: 'poligon',
            variant: 'DLS',
        },
        {
            id: 'poligon_DLC',
            shape: 'poligon',
            variant: 'DLC',
        },
    ];

    constructor(
        private sharedFacade: SharedFacade,
        private shapeService: BrowserShapeService,
        private configurationsService: ConfigurationsService<'pleated_blind'>,
        @Inject(ICC_PAGE_DATA) private pageData: any,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {
        super();
    }

    selectShape(item: IccShapeListItem) {
        this.pageData.setShape(item.shape, item.variant);
        this.sharedFacade.closePage();
    }

    ngOnInit() {
        this.shapes.push(...this.rectShapes);
        
        this.selectedId = this.pageData.selectedShape.shape;
        if (
            this.pageData.selectedShape.shape !== 'rect'
            && this.pageData.selectedShape.shape !== 'circle'
        ) {
            this.selectedId += '_' + this.pageData.selectedShape.type;
        }
        this.shapes.push(...this.arcShapes);
    
        this.shapes.push(...this.circleShapes);
    
        this.shapes.push(...this.triangleShapes);
    
        this.shapes.push(
            ...this.polygonShapes.filter(
                s =>
                    !s.variant
                    || s.variant.substr(0, 2) !== 'DL'
                    || (s.variant.substr(0, 2) === 'DL'
                        && !this.config().IccConfig.Configurators.poligonsDLDisable)
            )
        );
    }
}
