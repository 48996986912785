import * as angular from 'angular';
import {downgradeInjectable, downgradeComponent} from '@angular/upgrade/static';

import DimensionsCtrl from './DimensionsCtrl';
import { DimensionsService } from './dimensions.service';
import ModalDimensionChangeCtrl from './ModalDimensionChangeCtrl';
import { ConstructionLimitationService } from './construction-limitation.service';
import { ReinforcementsService } from '@icc/common/configurators/reinforcements.service';
import { MeasurementsComponent }   from './measurements.component';
import { MeasurementsService } from './measurements.service';
import { MountingMethodModalComponent } from './mounting-method-modal/mounting-method-modal.component';

export default angular.module('icc.configurator.steps.window.dimensions', [])
    .controller('DimensionsCtrl', DimensionsCtrl)
    .controller('ModalDimensionChangeCtrl', ModalDimensionChangeCtrl)
    .component('mountingMethodModal', MountingMethodModalComponent)
    .directive('measurements', downgradeComponent({
        component: MeasurementsComponent,
        inputs: [],
        outputs: []
    }))
    .service('DimensionsService', downgradeInjectable(DimensionsService))
    .factory('ConstructionLimitationService', downgradeInjectable(ConstructionLimitationService))
    .factory('ReinforcementsService', downgradeInjectable(ReinforcementsService))
    .factory('MeasurementsService', downgradeInjectable(MeasurementsService))
    .name;
