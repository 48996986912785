import {
    Component,
    ViewChild,
    forwardRef,
    EventEmitter,
    ElementRef,
    Input,
    Output,
    OnDestroy,
    AfterContentChecked,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UP_ARROW, LEFT_ARROW, DOWN_ARROW, RIGHT_ARROW } from '@angular/cdk/keycodes';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface IccSliderStep {
    id: number;
    label: string;
}

export const ICC_SLIDER_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SliderComponent),
    multi: true,
};

@Component({
    selector: 'icc-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    providers: [ICC_SLIDER_ACCESSOR],
    host: {
        '[attr.role]': '"slider"',
        '[attr.tabindex]': '"0"',
        '[attr.aria-disabled]': 'disabled',
        '[attr.aria-valuemax]': 'max',
        '[attr.aria-valuemin]': 'min',
        '[attr.aria-valuenow]': 'value',
        '[attr.aria-orientation]': 'vertical ? "vertical" : "horizontal"',
        '(keydown)': 'onSliderKeydown($event)',
    },
})
export class SliderComponent implements ControlValueAccessor, OnDestroy, AfterContentChecked {
    @ViewChild('thumbnail', { static: false }) thumbnail!: ElementRef<HTMLElement>;
    @ViewChild('slider', { static: false }) slider!: ElementRef<HTMLElement>;
    @Input() steps: IccSliderStep[];
    @Input() disabled!: boolean;
    @Input() min!: string;
    @Input() max!: string;
    @Input() reversed!: boolean;
    actualStep!: IccSliderStep;
    @Output() change: EventEmitter<IccSliderStep> = new EventEmitter();
    @Output() input: EventEmitter<IccSliderStep> = new EventEmitter();
    subscription!: Subscription;
    constructor() {}
    onChange = (change: any) => {};
    onTouched = (change: any) => {};

    writeValue(obj: IccSliderStep): void {
        this.actualStep = obj;
        this.changeThumbnailPositionById(this.getStepIndex(this.actualStep));
    }
    registerOnChange(fn: VoidFunction): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: VoidFunction): void {
        this.onTouched = fn;
    }
    onStepClick(step: IccSliderStep) {
        if (!this.disabled) {
            this.actualStep = step;
            this.changeThumbnailPositionById(this.getStepIndex(this.actualStep));
            this.onChange(step);
            this.onTouched(step);
            this.change.emit(this.actualStep);
            this.input.emit(this.actualStep);
        }
    }
    onSliderKeydown(ev: KeyboardEvent) {
        if (ev.keyCode === UP_ARROW || ev.keyCode === RIGHT_ARROW) {
            this.onPlusButtonClicked();
        } else if (ev.keyCode === DOWN_ARROW || ev.keyCode === LEFT_ARROW) {
            this.onMinusButtonClicked();
        }
    }
    onMinusButtonClicked() {
        if (this.getStepIndex(this.actualStep) > -1 && !this.disabled) {
            this.actualStep = this.steps[this.getStepIndex(this.actualStep) - 1];
            this.changeThumbnailPositionById(this.getStepIndex(this.actualStep));
            this.onChange(this.actualStep);
            this.onTouched(this.actualStep);
            this.change.emit(this.actualStep);
            this.input.emit(this.actualStep);
        }
    }
    isSliderFragmentActive(fragment: IccSliderStep) {
        if (this.steps.length < 2) {
            return false;
        }
        if (
            this.reversed === true
            && this.getStepIndex(fragment) > this.getStepIndex(this.actualStep)
        ) {
            return true;
        } else if (
            this.reversed !== true
            && this.getStepIndex(fragment) <= this.getStepIndex(this.actualStep)
        ) {
            return true;
        }
    }
    onPlusButtonClicked() {
        if (this.getStepIndex(this.actualStep) < this.steps.length - 1 && !this.disabled) {
            this.actualStep = this.steps[this.getStepIndex(this.actualStep) + 1];
            this.changeThumbnailPositionById(this.getStepIndex(this.actualStep));
            this.onChange(this.actualStep);
            this.onTouched(this.actualStep);
            this.change.emit(this.actualStep);
            this.input.emit(this.actualStep);
        }
    }
    ngOnInit() {
        if (this.reversed === true) {
            this.steps.reverse();
        }
        if (this.steps.length > 0) {
            this.actualStep = this.steps[0];
            this.max = this.steps[0].label;
            this.max = this.steps[this.steps.length - 1].label;
        }
        this.subscription = fromEvent(window, 'resize')
          .pipe(debounceTime(200))
          .subscribe(() => {
            this.changeThumbnailPositionById(this.getStepIndex(this.actualStep));
        });
    }

    onResize() {
        this.changeThumbnailPositionById(this.getStepIndex(this.actualStep));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.changeThumbnailPositionById(this.getStepIndex(this.actualStep));
    }

    ngAfterContentChecked() {
        this.changeThumbnailPositionById(this.getStepIndex(this.actualStep));
    }

    onFragmentClicked(ev: MouseEvent, fragment: IccSliderStep) {
        if (this.disabled !== true) {
            this.actualStep = fragment;
            this.changeThumbnailPositionById(this.getStepIndex(fragment));
            this.onChange(this.actualStep);
            this.onTouched(this.actualStep);
            this.change.emit(this.actualStep);
            this.input.emit(this.actualStep);
        }
    }
    changeThumbnailPosition(position: number) {
        this.thumbnail.nativeElement.style.left = `${position}px`;
    }
    changeThumbnailPositionById(id: number) {
        if (!this.slider || !this.slider.nativeElement) {
            return;
        }
        const ticks = this.slider.nativeElement.querySelectorAll('.fragment__tick');
        const tickRect = ticks[id]?.getBoundingClientRect();
        const thumbnailElementRect = this.thumbnail.nativeElement.getBoundingClientRect();
        if (tickRect && thumbnailElementRect) {
          let position =
            tickRect.left + tickRect.width / 2 - this.slider.nativeElement.getBoundingClientRect().left - thumbnailElementRect.width / 2;
          position = Math.max(0, Math.min(position, this.slider.nativeElement.offsetWidth - thumbnailElementRect.width));
          this.changeThumbnailPosition(position);
        }
    }
    detectThumbnailColision() {
        const elements: HTMLElement[] = Array.from(
            this.slider.nativeElement.querySelectorAll('.slider__fragment')
        );
        const sliderRect = this.slider.nativeElement.getBoundingClientRect();
        const fragments: number[] = elements.map((el: HTMLElement) => {
            return (
                el.getBoundingClientRect().left
                + el.getBoundingClientRect().width / 2
                - sliderRect.left
            );
        });
        const thumbnailElementRect = this.thumbnail.nativeElement.getBoundingClientRect();
        const goal = thumbnailElementRect.left + thumbnailElementRect.width / 2 - sliderRect.left;
        const closest = fragments.reduce((prev: number, curr: number) => {
            return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
        });
        this.actualStep = this.steps[fragments.indexOf(closest)];
    }
    onThumbnailPressed() {
        if (!this.disabled) {
            const ticks = Array.from(
                this.slider.nativeElement.querySelectorAll('.fragment__tick')
            ).map(el => el.getBoundingClientRect());
            this.thumbnail.nativeElement.style.transitionDuration = '0s';
            const mouseMove = (ev: MouseEvent) => {
                this.detectThumbnailColision();

                const sliderRect = this.slider.nativeElement.getBoundingClientRect();
                const thumbnailElementRect = this.thumbnail.nativeElement.getBoundingClientRect();
                if (
                    ev.clientX < ticks[ticks.length - 1].left + ticks[ticks.length - 1].width / 2
                    && ev.clientX > ticks[0].left + ticks[0].width / 2
                ) {
                    this.thumbnail.nativeElement.style.left = `${ev.clientX
                        - sliderRect.left
                        - thumbnailElementRect.width / 2}px`;
                }
            };
            document.addEventListener('mousemove', mouseMove);
            window.addEventListener(
                'mouseup',
                () => {
                    this.onChange(this.actualStep);
                    this.onTouched(this.actualStep);
                    this.change.emit(this.actualStep);
                    this.input.emit(this.actualStep);
                    this.changeThumbnailPositionById(this.getStepIndex(this.actualStep));
                    document.removeEventListener('mousemove', mouseMove);
                    this.thumbnail.nativeElement.style.transitionDuration = '0.25s';
                },
                { once: true }
            );
        }
    }
    onThumbnailTouched(ev: TouchEvent) {
        const ticks = Array.from(this.slider.nativeElement.querySelectorAll('.fragment__tick')).map(
            el => el.getBoundingClientRect()
        );
        this.thumbnail.nativeElement.style.transitionDuration = '0s';
        const touchMove = (ev: TouchEvent) => {
            this.detectThumbnailColision();
            const sliderRect = this.slider.nativeElement.getBoundingClientRect();
            const thumbnailElementRect = this.thumbnail.nativeElement.getBoundingClientRect();
            if (
                ev.touches[0].clientX
                    < ticks[ticks.length - 1].left + ticks[ticks.length - 1].width / 2
                && ev.touches[0].clientX > ticks[0].left + ticks[0].width / 2
            ) {
                this.thumbnail.nativeElement.style.left = `${ev.touches[0].clientX
                    - sliderRect.left
                    - thumbnailElementRect.width / 2}px`;
            }
        };
        document.addEventListener('touchmove', touchMove);
        window.addEventListener(
            'touchend',
            () => {
                this.onChange(this.actualStep);
                this.onTouched(this.actualStep);
                this.change.emit(this.actualStep);
                this.input.emit(this.actualStep);
                this.changeThumbnailPositionById(this.getStepIndex(this.actualStep));
                document.removeEventListener('touchmove', touchMove);
                this.thumbnail.nativeElement.style.transitionDuration = '0.25s';
            },
            { once: true }
        );
    }

    trackStepId(index: number, step: IccSliderStep) {
        return step.id;
    }

    private getStepIndex(step: IccSliderStep) {
        return this.steps.findIndex(s => s.id === step.id);
    }
}
