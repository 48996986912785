import angular from 'angular';

import OfferConfiguratorCtrl from './OfferConfiguratorCtrl';
import { ModalOfferAddFormCtrl } from './ModalOfferAddFormCtrl';
import ModalOfferConfModal from './ModalOfferConfModal';
import EditPositionFactory from './EditPositionFactory';
import ModalOfferHistoryCtrl from './ModalOfferHistoryCtrl';
import { ModalMontageGroupEdit } from './ModalMontageGroupEdit';
import { ModalPdfOptionsCtrl } from './pdf_options/ModalPdfOptionsCtrl';
import { PdfService } from './PdfService';
import { MontagesAndMeasureModalComponent } from './montages-and-measure-modal/montages-and-measure-modal.component';
import { PaymentsModalComponent } from './payments-modal/payments-modal.component';
import { AddPaymentModalComponent } from './add-payment-modal/add-payment-modal.component';
import {ModalClientEmailOptionsCtrl} from './ModalClientEmailOptionsCtrl';
import {ModalClientConfirmOfferCtrl} from './ModalClientConfirmOfferCtrl';
import ModalInfoCtrl from '../configurator/ModalInfoCtrl';

export default angular.module('icc.offer', [])
    .controller('OfferConfiguratorCtrl', OfferConfiguratorCtrl)
    .controller('ModalOfferAddFormCtrl', ModalOfferAddFormCtrl)
    .controller('ModalOfferConfModal', ModalOfferConfModal)
    .factory('EditPositionFactory', EditPositionFactory)
    .controller('ModalOfferHistoryCtrl', ModalOfferHistoryCtrl)
    .controller('ModalPdfOptionsCtrl', ModalPdfOptionsCtrl)
    .controller('ModalMontageGroupEdit', ModalMontageGroupEdit)
    .controller('ModalClientConfirmOfferCtrl', ModalClientConfirmOfferCtrl)
    .controller('ModalInfoCtrl', ModalInfoCtrl)
    .component('montagesAndMeasureModal', MontagesAndMeasureModalComponent)
    .component('paymentsModal', PaymentsModalComponent)
    .component('addPaymentModal', AddPaymentModalComponent)
    .service('PdfService', PdfService)
    .controller('ModalClientEmailOptionsCtrl', ModalClientEmailOptionsCtrl)
    .name;
