const SystemsModalTemplate = require('ngtemplate-loader!./systems-modal.component.html');
import SystemsModalController from './systems-modal.controller';

export const SystemsModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: SystemsModalTemplate,
  controller: SystemsModalController
};
