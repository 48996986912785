import { createSelector } from '@ngrx/store';
import { stepsMap } from '../step/steps';
import {
    sharedQuery,
    UiState,
    Step,
    StepIcon,
    ConfiguratorOptions,
    StepComponentType,
} from '@icc/configurator/shared';

const getAllSteps = createSelector(
    sharedQuery.getUiState,
    sharedQuery.getLoaded,
    (state: UiState, isLoaded) => {
        return isLoaded
            ? state.steps.map(step => {
                  const stepComponent = getStepComponent(step);

                  return Object.assign({}, step, {
                      stepName: stepComponent ? getName(stepComponent.stepName, state.options) : step.id,
                      stepIcon: stepComponent
                          ? stepComponent.stepIcon
                          : {
                                ligature: 'apps',
                            },
                      stepEnable: stepComponent
                          ? stepComponent.stepEnable
                          : (conf, options, data) => true,
                  });
              })
            : [];
    }
);

const getEnabledSteps = createSelector(
    getAllSteps,
    allSteps => allSteps.filter(step => step.enable && step.enableRx)
);

const getSelectedStepId = createSelector(
    sharedQuery.getUiState,
    (state: UiState) => state.selectedStep
);

const getSelectedStep = createSelector(
    getEnabledSteps,
    getSelectedStepId,
    (steps, id) => {
        const step = steps.find(it => it['id'] === id);
        const stepIndex = steps.findIndex(it => it['id'] === id);
        const result = step
            ? Object.assign({}, step, {
                  component: getStepComponent(step),
                  stepIndex: stepIndex,
              })
            : null;
        return result ? Object.assign({}, result) : undefined;
    }
);

const getVisitedSteps = createSelector(
    getAllSteps,
    steps => steps.filter(step => step.visited)
);

const getAllPages = createSelector(
    sharedQuery.getUiState,
    sharedQuery.getLoaded,
    (state, isLoaded) => {
        return isLoaded ? state.openedPages : [];
    }
);

const getAllFilterSheets = createSelector(
    sharedQuery.getUiState,
    sharedQuery.getLoaded,
    (state, isLoaded) => {
        return isLoaded ? state.openedFilterSheets : [];
    }
);

const getAllMessages = createSelector(
    sharedQuery.getUiState,
    sharedQuery.getLoaded,
    (state, isLoaded) => {
        return isLoaded ? state.messages : [];
    }
);

const getLastPage = createSelector(
    getAllPages,
    pages => {
        return pages && pages.length ? pages[pages.length - 1] : undefined;
    }
);

const getLastFilterSheet = createSelector(
    getAllFilterSheets,
    pages => {
        return pages && pages.length ? pages[pages.length - 1] : undefined;
    }
);

const getOpenedPage = createSelector(
    getLastPage,
    getSelectedStep,
    (page, step) => {
        return page
            ? { component: page.component, page: true, data: page.data }
            : step && {
                  component: step.component,
                  page: false,
              };
    }
);

const getOpenedFilterSheet = createSelector(
    getLastFilterSheet,
    (page) => {
        return page
            ? { component: page.component, page: true, data: page.data }
            : null;
    }
);

const getOptions = createSelector(
    sharedQuery.getUiState,
    (state: UiState) => state.options
);

const getOpenedMessage = createSelector(
    getAllMessages,
    messages => {
        const showed = (messages || []).filter(message => !message.dismissed);
        return showed.length ? showed[showed.length - 1] : undefined;
    }
);

function getStepComponent(step: Step): StepComponentType {
    return stepsMap[step.configurator] && stepsMap[step.configurator][step.id];
}

function getName(name: string | ((options: ConfiguratorOptions) => string), options: ConfiguratorOptions) {
    if (typeof name === 'function') {
        return name(options);
    }
    return name;
}

const getReturnButton = createSelector(
    sharedQuery.getUiState,
    (state: UiState) => state.returnButton
);

const getAdditionalReturnButtons = createSelector(
    sharedQuery.getUiState,
    (state: UiState) => state.additionalReturnButtons
);


export const configuratorQuery = {
    getAllSteps,
    getEnabledSteps,
    getSelectedStep,
    getAllPages,
    getLastPage,
    getOpenedPage,
    getOpenedFilterSheet,
    getOptions,
    getAllMessages,
    getLastMessage: getOpenedMessage,
    getVisitedSteps,
    getReturnButton,
    getAdditionalReturnButtons
};
