import angular from 'angular';

export default function LandingCtrl($rootScope, $http, $timeout, $routeParams, $filter, EnvConfig) {
    'ngInject';

    var vm            = this;
    vm.saveNote       = saveNote;
    vm.isUserActive   = isUserActive;
    vm.isUserDisabled = isUserDisabled;
    vm.noDealerData   = noDealerData;

    getData();

    /**
     * Pobieranie danych
     */
    function getData() {
        $http
            .get(`${EnvConfig.remoteHost || window.location.origin}/landing/landing/qr/` + $routeParams.id + '/' + $routeParams.id2 + '.json')
            .then((res) => {
                vm.offer               = res.data.offer;
                vm.offerSizesDetails   = res.data.offerSizesDetails;
                vm.tmpId               = $routeParams.id;
                vm.checksum            = $routeParams.id2;
                vm.offer.Offer.created = new Date(vm.offer.Offer.created);
            });
    }

    /**
     * Zapis notatki
     */
    function saveNote() {
        if (angular.isDefined(vm.comment) && vm.comment != '') {
            $http
                .post(`${EnvConfig.remoteHost || window.location.origin}/landing/landing/qr/${$routeParams.id}/${$routeParams.id2}.json`, {data: vm.comment})
                .then((res) => {
                    if (res.data.success) {
                        $rootScope.showInfo($filter('translate')('INTERFACE|Poprawnie zapisano.'));
                        vm.offer = res.data.offer;
                        vm.comment = '';
                    } else {
                        $rootScope.showInfo($filter('translate')('QR|Wystąpił błąd podczas zapisu, popraw formularz i spróbuj ponownie.'));
                    }
                });
        } else {
            $rootScope.showInfo($filter('translate')('QR|Uzupełnij notatkę.'));
        }
    }

    function isUserActive() {
        return vm.offer
            && vm.offer.Dealer
            && vm.offer.Dealer.User
            && vm.offer.Dealer.User.id
            && vm.offer.Dealer.User.active
            && (!vm.offer.Dealer.User.date_block
                || new Date(vm.offer.Dealer.User.date_block) >= new Date());
    }

    function isUserDisabled() {
        return vm.offer
            && vm.offer.Dealer
            && vm.offer.Dealer.User
            && (!vm.offer.Dealer.User.active
                || (vm.offer.Dealer.User.date_block
                    && new Date(vm.offer.Dealer.User.date_block) < new Date()));
    }

    function noDealerData() {
        return !vm.offer || !vm.offer.Dealer || !vm.offer.Dealer.User;
    }
}
