<icc-select-box [title]="'MOSQUITO|Typ' | translate"
                [description]="(selectedType$ | async)?.name"
                [imageUrl]="'/files/mosquitotype/' + ((selectedType$ | async)?.img || '')"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="changeType()"
></icc-select-box>

<div class="box-form mat-elevaton-z3" *ngIf="(selectedType$ | async)?.has_direction">
    <h4>{{'WINDOW|Wybierz stronę mocowania zawiasów:' | translate }}</h4>
    <mat-radio-group name="ver" [ngModel]="direction$ | async" (ngModelChange)="changeDirection($event)">
        <mat-radio-button value="left">{{ 'INTERFACE|Lewa' | translate }}</mat-radio-button>
        <mat-radio-button value="right">{{ 'INTERFACE|Prawa' | translate }}</mat-radio-button>
    </mat-radio-group>
</div>