import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewConfiguratorComponent } from './new-configurator/new-configurator.component';
import { ConfiguratorModule as NewConfiguratorModule } from '@icc/configurator';
import { InitService } from 'apps/configurator/src/app/init.service';

@NgModule({
    imports: [CommonModule, NewConfiguratorModule.forFeature()],
    declarations: [NewConfiguratorComponent],
    exports: [],
    providers: [],
    entryComponents: [NewConfiguratorComponent]
})
export class NewConfiguratorLazyModule {
    static rootComponent = NewConfiguratorComponent;

    constructor(private initService: InitService) {
    }
}
