import { Component, OnInit, Inject } from '@angular/core';
import { StepComponent, _ } from '@icc/configurator/shared';
import { ShutterFacade } from '../+state/shutter.facade';
import { ColorsService } from '@icc/legacy/configurator/steps/roller_shutter/colors.service';
import { APP_CONFIG, AppConfigFactory } from '@icc/common';

@Component({
    selector: 'icc-colors',
    templateUrl: './colors.component.html',
    styleUrls: ['./colors.component.scss'],
})
export class ColorsComponent extends StepComponent implements OnInit {
    static stepName = _('STEPS|Kolor');
    static stepIcon = {
        ligature: 'palette',
    };

    public configurator = 'roller_shutter';
    public stepId = 'rollercolor';
    public title = _('COLOR|Wybierz kolor:');

    colors$ = {
      boxInner: this.shutterFacade.boxInnerColor$,
      boxGuideOuter: this.shutterFacade.boxGuideOuterColor$,
      box: this.shutterFacade.boxColor$,
      guideRail: this.shutterFacade.guideRailColor$,
      profile: this.shutterFacade.profileColor$,
      endslat: this.shutterFacade.endslatColor$,
      revision: this.shutterFacade.revisionColor$,
      boxSideSurface: this.shutterFacade.boxSideSurfaceColor$,
    };

    colorsShow$ = {
      boxInner: this.shutterFacade.boxInnerColorShow$,
      boxGuideOuter: this.shutterFacade.boxGuideOuterColorShow$,
      box: this.shutterFacade.boxColorShow$,
      guideRail: this.shutterFacade.guideRailColorShow$,
      profile: this.shutterFacade.profileColorShow$,
      endslat: this.shutterFacade.endslatColorShow$,
      revision: this.shutterFacade.revisionColorShow$,
      boxSideSurface: this.shutterFacade.boxSideSurfaceColorShow$,
    };

    colorsTitle = {
      boxInner: _('ROLLERSHUTTER|Kolor skrzynki wewnątrz:'),
      boxGuideOuter: this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor ? _( 'ROLLERSHUTTER|Kolor skrzynki od zewnątrz:') : _('ROLLERSHUTTER|Kolor skrzynki od zewnątrz i prowadnic:'),
      box: _('ROLLERSHUTTER|Kolor skrzynki:'),
      guideRail: _('ROLLERSHUTTER|Kolor prowadnic:'),
      profile: _('ROLLERSHUTTER|Kolor pancerza:'),
      endslat: _('ROLLERSHUTTER|Kolor listwy końcowej:'),
      revision: _('ROLLERSHUTTER|Kolor klapy rewizyjnej:'),
      boxSideSurface: _('ROLLERSHUTTER|Kolor boczków skrzynki:'),
    };

    constructor(
      @Inject(APP_CONFIG) private config: AppConfigFactory,
      private shutterFacade: ShutterFacade,
      private colorsService: ColorsService,
    ) {
        super();
    }

    ngOnInit() {}

    openModalRollerColor(
        type:
            | 'boxInner'
            | 'boxGuideOuter'
            | 'box'
            | 'guideRail'
            | 'profile'
            | 'endslat'
            | 'revision'
            | 'boxSideSurface'
    ) {
      this.colorsService.openModalRollerColor(type)
    }
}
