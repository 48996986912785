import { isUndefined, isString } from '@icc/helpers';

/**
 * Funkcja Ug szyby
 */
export function GlassUgFilter() {
    return function (glass, ug) {
        const glassArr = [];
        Object.keys(glass).forEach(key => {
            glassArr.push(glass[key]);
        });
        if (isUndefined(ug) || ug === null || ug === '') {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {
                if (isString(ug) && ug[0] === '>') {
                    return parseFloat(ug.substr(1)) < parseFloat(gl.u);
                } else if (isString(ug) && ug[0] === '<') {
                    return parseFloat(ug.substr(1)) >= parseFloat(gl.u);
                } else {
                    return parseFloat(ug) >= parseFloat(gl.u);
                }

            });
        }
    };
}
