import { StepComponentType } from '@icc/configurator/shared';
import { TypesComponent } from './type/type.component';
import { SystemsComponent } from './system/systems.component';
import { SchemaComponent } from './schema/schema.component';
import { DimensionsComponent } from './dimensions/dimensions.component';
import { MontageComponent } from './montage/montage.component';
import { ColorsComponent } from './colors/colors.component';
import { DrivesComponent } from './drives/drives.component';
import { AccessoriesComponent } from '@icc/configurator/accessory';
import { ProductDetailsComponent } from '@icc/configurator/pages';

export const shutterStepsMap: {
    [stepId: string]: StepComponentType;
} = {
    rollertype: TypesComponent,
    system: SystemsComponent,
    rollerschema: SchemaComponent,
    rollerdimensions: DimensionsComponent,
    montage: MontageComponent,
    rollercolor: ColorsComponent,
    rollerdrive: DrivesComponent,
    accessories: AccessoriesComponent,
    details: ProductDetailsComponent
};
