/**
 * Funkcja modalu controlera nowego klienta
 * @param {object} $uibModalInstance uibModalInstance
 * @param {object} $http          http
 * @param {object} company        company
 */
export default function ModalNewClientCtrl($uibModalInstance, $http, company) {
    'ngInject';

    var vm           = this;
    vm.close         = close;
    vm.client        = {company: company || false};
    vm.addClient     = addClient;
    vm.changeCompany = changeCompany;

    /**
     * Funkcja dodawania klienta
     */
    function addClient() {
        $uibModalInstance.close(vm.client);
    }

    /**
     * Funkcja zmiany Firma/Klient indywidualny
     */
    function changeCompany() {
        clear();
    }

    /**
     * Czyszczenie wartości pól
     */
    function clear() {
        const isCompany = vm.client.company;
        vm.client = {};
        vm.client.company = isCompany;
    }

    /**
     * Funkcja zamykająca instancję obiektu
     */
    function close() {
        $uibModalInstance.close();
    }
}
