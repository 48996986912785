export class  MontagesAndMeasureModalController {

    resolve: {
        montage,
        measure,
        montageTypes,
        montageType
    };
    close: ({$value}) => any;
    dismiss: () => any;

    constructor() {}

    closeModal(result: {montage, measure, montageType}) {
        if (result) {
            this.close({$value: result});
        } else {
            this.close({$value: false});
        }
    }

    select(montage, measure, montageType) {
        this.closeModal({
            montage,
            measure,
            montageType
        });
    }
}
