/**
 * Fabryka elementów akcesoriów
 */
export default function ElementsAccessoriesFactory($rootScope) {
    'ngInject';

    /*
     * Obiekt przechowujący akcesoria dla poszczególnych typów skrzydeł. Tylko akcesoria które NIE POSIADAJĄ długości a jedynie liczbę w jakiej są potrzebne
     */
    var elements = {

        inEveryWindow: [
            {
                code: '80162105',
                quantity: 4,
                length: 0,
            },
        ],

        inNotMovableFrame: [
            {
                code: '80124236',
                quantity: 4,
                length: 0,
            },
            {
                code: '80124271',
                quantity: 4,
                length: 0,
            },
        ],

        inMovableFrame: [
            {
                code: '80124270',
                quantity: 4,
                length: 0,
            },
        ],

        inEverySash: [
            {
                code: '80453038',
                quantity: 2,
                length: 0,
            },
            {
                code: '80455011',
                quantity: 2,
                length: 0,
            },
        ],

        inFSash: [
            {
                code: '80453057',
                quantity: 4,
                length: 0,
            },
            {
                code: '85730131',
                quantity: 2,
                length: 0,
            }
        ],

        inDKSash: [
            //dokumentacji podana ilość 3 - 4 dla tego elementu
            {
                code: '85730131',
                quantity: 4,
                length: 0,
            },
            {
                code: '80124421',
                quantity: 4,
                length: 0,
            },
            {
                code: '80124349',
                quantity: 4,
                length: 0,
            },
            {
                code: '80162106',
                quantity: 4,
                length: 0,
            },
            {
                code: '80162107',
                quantity: 4,
                length: 0,
            },
            //dokumentacji podana ilość 5 - 6 dla tego elementu
            {
                code: '80453056',
                quantity: 6,
                length: 0,
            },
            {
                code: '87252202',
                quantity: 3,
                length: 0,
            }
        ],

        inPSKsash: [
            {
                code: '80124242',
                quantity: 4,
                length: 0,
            },
            {
                code: '80124347',
                quantity: 4,
                length: 0,
            },
        ],

        inStaticDividers: [
            {
                code: '80122105',
                quantity: 2,
                length: 0,
            },
            {
                code: '80122106',
                quantity: 2,
                length: 0,
            },
            {
                code: '80372507',
                quantity: 2,
                length: 0,
            },
            {
                code: '80372710',
                quantity: 2,
                length: 0,
            },
            {
                code: '80376014',
                quantity: 2,
                length: 0,
            },
            {
                code: '80376015',
                quantity: 2,
                length: 0,
            },
            {
                code: '120604',
                quantity: 1,
                length: 0,
            },
        ],

        inMovableDivider: [
            {
                code: '87222203',
                quantity: 2,
                length: 0,
            },
            //w dokumentacji podane, że 6 - 10 sztuk elementu
            {
                code: '87252509',
                quantity: 10,
                length: 0,
            },
            {
                code: '120831',
                quantity: 1,
                length: 0,
            },
            {
                code: '120832',
                quantity: 1,
                length: 0,
            },
        ],
    };

    var factory = {
        getElements: getElements,
    };

    return factory;

    /*
     * Ustawia elementy na pusty obiekt
     */
    function getElements(){

        return elements;
    }
}
