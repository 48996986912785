export interface Categories {
    category: {
        id: string;
        name: string;
    };
    subcategory: {
        id: string;
        name: string;
    };
}

export enum Category {
    allCategories = 'all-categories',
    allSubCategories = 'all-sub-categories',
}
