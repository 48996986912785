import 'angular-ui-bootstrap';
/**
 * Kontroler modala kolorów dodatku.
 *
 * @export
 * @class ModalPriceDetailsCtrl
 */
export class ModalPvcColorsCtrl {

    config = {
        type: 'window',
        Colors: this.sash.selectedColor || {
            frame: {
                outer: {},
                inner: {},
                alushell: {},
            },
            sash: {
                outer: {},
                inner: {},
                alushell: {},
            }
        },
        System: {
            type: this.sash.system_types[0]
        },
        Wood: this.sash.selectedWood || {},
        valid: {}
    };

    configDefault = {
        type: 'window',
        Colors: this.sash.selectedColor || {
            frame: {
                outer: {},
                inner: {},
                alushell: {},
            },
            sash: {
                outer: {},
                inner: {},
                alushell: {},
            }
        },
        System: {
            type: this.sash.system_types[0]
        },
        Wood: this.sash.selectedWood || {},
        valid: {}
    };

    hasCream = false;
    setColorType(type) {
        throw new Error('Deprecated!');
    }
    openModalWood() {
       throw new Error('Deprecated!');
    }

    filter = (group) => true;
    filterColors = (color) => true;

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private sash,
        private EventBusService,
        private novalidate,
    ) {
        'ngInject';
    }



    setDefaults() {
    }


    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    close() {
        this.$uibModalInstance.close({colors: this.config.Colors, wood: this.config.Wood});
    }
}
