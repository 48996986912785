export class  PaymentsModalController {
    payments;
    statuses;
    providers;
    paid;

    resolve: {
        offerId,
        currency,
        offer,
        price,
        dealer
    };
    close: ({$value}) => any;
    dismiss: () => any;

    constructor(
        private $http,
        private $uibModal,
        private $translate,
        private OffersService
    ) {
        this.statuses = {
            success: this.$translate.instant('OFFER|Zapłacone'),
            pending: this.$translate.instant('OFFER|Oczekujące'),
            canceled: this.$translate.instant('OFFER|Przerwane'),
            parly: this.$translate.instant('OFFER|Opłacone częściowo'),
            payment_review: this.$translate.instant('OFFER|Potrzebna weryfikacja'),
            chargeback: this.$translate.instant('OFFER|Chargeback'),
            returned: this.$translate.instant('OFFER|Zwrócone'),
            error: this.$translate.instant('OFFER|Błąd płatności')
        }

        this.providers = {
            bank_transfer: this.$translate.instant('OFFER|Przelew'),
            cash_on_delivery: this.$translate.instant('OFFER|Za pobraniem'),
            heidelpay: this.$translate.instant('OFFER|Unzer'),
            payu: this.$translate.instant('OFFER|PayU'),
        };
    }

    $onInit() {
        this.getPayments();
    }

    getPayments() {
        const offerId = this.resolve.offerId;
        this.paid = 0;
        return this.updatePayments().then(() => {
            return this.$http
                .get('/api/shop/payments/get/' + offerId)
                .then((res) => {
                    this.payments = res.data;
                    this.payments.forEach(el => {
                        const amount = el.amount * this.resolve.currency.value;
                        if (el.status === 'success') {
                            this.paid += amount;
                        } else if (el.status === 'returned') {
                            this.paid -= amount;
                        }
                    })
                });
        });
    }

    updatePayments() {
        const offerId = this.resolve.offerId;
        this.paid = 0;
        return new Promise((resolve) => {
            this.$http
            .get('/orders/get-payments-status')
            .then(() => resolve())
            .catch(() => resolve());
        });
    }

    addPayment() {
        this.$uibModal.open({
            component: 'addPaymentModal',
            resolve: {
                offerId: () => this.resolve.offerId,
                offer: () => this.resolve.offer,
                currency: () => this.resolve.currency,
                providers: () => this.availableProviders(),
            },
        })
        .result
        .then((data) => {
            if (!data) {
                return;
            }

            this.resolve.offer.mail_sent = null;
            this.resolve.offer.mail_message = {
                type: 'changed_shop_order',
                to: ['klient', 'dealer', 'dealerh'],
                title: this.$translate.instant('OFFER|Zmodyfikowano zamówienie w systemie IC COMPLEX'),
            };
            return this.OffersService.update(this.resolve.offer.tmp_id, this.resolve.offer)
                .then(() => this.getPayments());
        });
    }

    availableProviders() {
        const result = {};
        Object.entries(this.providers).forEach(([id, name]) => {
            if (this.resolve.dealer[`${id}_enabled`]) {
                result[id] = name;
            }
        });

        return result;
    }

    closeModal() {
        this.close({$value: false});
    }
}
