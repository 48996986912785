<div id="fillings-filters" *ngIf="mode === 'list'">
    <form [formGroup]="fillingFilters">
        <h3 *ngIf="(filtersLevels.securityLevel?.length > 1 && !isSliderDisabled) || config().IccConfig.Configurators.fillingsSelect.showGlazingsForSliderValues">{{ 'WINDOW|Bezpieczeństwo' | translate}}</h3>
        <icc-slider  *ngIf="(filtersLevels.securityLevel?.length > 1 && !isSliderDisabled) && !(config().IccConfig.Configurators.fillingsSelect.showGlazingsForSliderValues && filtersLevels.securityLevel?.length > 1)" [steps]="filtersLevels.securityLevel" formControlName="securityLevel"></icc-slider>
        <icc-slider  *ngIf="(config().IccConfig.Configurators.fillingsSelect.showGlazingsForSliderValues && filtersLevels.securityLevel?.length > 1)" [steps]="filtersLevels.securityLevel" formControlName="securityLevel" (change)="getGlazingsForSecurityPackage($event.id)"
            ></icc-slider>
        <p *ngIf="(filtersLevels.securityLevel?.length > 1 && !isSliderDisabled) || config().IccConfig.Configurators.fillingsSelect.showGlazingsForSliderValues">{{ securityLevelDescription }}</p>
        <h3 *ngIf="filtersLevels.glassType?.length > 1 || filtersLevels.glassType?.length === 1 && filtersLevels.glassTypeVariant.length > 1 || config().IccConfig.Configurators.fillingsSelect.showGlazingsForSliderValues">{{ 'WINDOW|Kolor szyby' | translate}}</h3>
        <div class="glass-type-select" *ngIf="filtersLevels.glassType?.length > 1">
            <div *ngFor="let type of filtersLevels.glassType" (click)="changeGlassType(type)" [class.selected]="fillingFilters.get('glassType')?.value == type?.id">
                <div class="item-imgTitle">
                    <img class="item-imgTitle-img" [src]="('/files/glasstype/' + type.image)" [alt]="type.name" defaultImg>
                    <p class="item-imgTitle-title">{{ type.name }}</p>
                </div>
            </div>
        </div>
        <div class="glass-type-select" *ngIf="filtersLevels.glassType?.length === 1 && filtersLevels.glassTypeVariant.length > 1 && !config().IccConfig.Configurators.fillingsSelect.showGlazingsForSliderValues">
            <div *ngFor="let type of filtersLevels.glassTypeVariant" (click)="changeGlassTypeVariant(type)" [class.selected]="fillingFilters.get('glassTypeVariant')?.value == type?.id">
                <div class="item-imgTitle">
                    <img class="item-imgTitle-img" [src]="('/files/glasstypevariant/' + type.image)" [alt]="type.name" defaultImg>
                    <p class="item-imgTitle-title">{{ type.name }}</p>
                </div>
            </div>
        </div>
        <div class="glass-type-select" *ngIf="config().IccConfig.Configurators.fillingsSelect.showGlazingsForSliderValues">
            <ng-container *ngFor="let type of filtersLevels.glassTypeVariant">
                <ng-container *ngFor="let glazing of availableGlazingColors">
                    <div *ngIf="type.id === glazing.filters.glassTypeVariant" (click)="changeGlassTypeVariant(type)" [class.selected]="fillingFilters.get('glassTypeVariant')?.value == type?.id">
                        <div class="item-imgTitle">
                            <img class="item-imgTitle-img" [src]="('/files/glasstypevariant/' + type.image)" [alt]="type.name" defaultImg>
                            <p class="item-imgTitle-title">{{ type.name }}</p>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <icc-select-box [title]="'WINDOW|Wariant szyby' | translate"
                [description]="selectedGlassTypeVariant?.name"
                [imageUrl]="'/files/glasstypevariant/' + (selectedGlassTypeVariant?.image || '')"
                [buttonText]="'INTERFACE|Zmień' | translate"
                *ngIf="getMatchingVariants().length > 0"
                (select)="selectGlassTypeVariant()"
        ></icc-select-box>
    </form>
</div>
<div *ngIf="selectedFilling && mode === 'list'" class="selected-summary mat-elevation-z4">
    <img [src]="'/files/filling/' + (selectedFilling?.image || '')" defaultImg>
    <p>{{ selectedFilling?.name }}</p>
</div>
<ng-container *ngIf="mode === 'extra'">
    <icc-select-box [title]="leftLightFillingBox?.title"
                    [description]="leftLightFillingBox?.name"
                    [imageUrl]="leftLightFillingBox?.img"
                    [buttonText]="'INTERFACE|Zmień' | translate"
                    [descriptionTemplate]="fillingBoxTemplate"
                    [descriptionContext]="{box: leftLightFillingBox}"
                    *ngIf="leftLightFillingBox.show"
                    (select)="openModalFilling('doorLeftLight')"
                    ></icc-select-box>
    <icc-select-box [title]="rightLightFillingBox?.title"
                    [description]="rightLightFillingBox?.name"
                    [imageUrl]="rightLightFillingBox?.img"
                    [buttonText]="'INTERFACE|Zmień' | translate"
                    [descriptionTemplate]="fillingBoxTemplate"
                    [descriptionContext]="{box: rightLightFillingBox}"
                    *ngIf="rightLightFillingBox.show"
                    (select)="openModalFilling('doorRightLight')"
                    ></icc-select-box>
    <icc-select-box [title]="topLightFillingBox?.title"
                    [description]="topLightFillingBox?.name"
                    [imageUrl]="topLightFillingBox?.img"
                    [buttonText]="'INTERFACE|Zmień' | translate"
                    [descriptionTemplate]="fillingBoxTemplate"
                    [descriptionContext]="{box: topLightFillingBox}"
                    *ngIf="topLightFillingBox.show"
                    (select)="openModalFilling('doorTopLight')"
                    ></icc-select-box>
</ng-container>
<ng-template #fillingBoxTemplate let-box="box">
    <p class="glassTypeDescription"
        *ngIf="box.oneGlazing && box.type != 'deco_panels'">
            {{box.name}}<br>
            U<sub>g</sub> = {{box.u}}
            <span *ngIf="box.bondedGlazing">
            <br>
            {{ 'WINDOW|Wklejane szyby' | translate }}
            </span>
            <span *ngIf="box.bondedGlazing && box.partlyBondedGlazing">
            <br>
            {{ 'WINDOW|Wklejane szyby w części kwater.' | translate }}
            </span>
        </p>
        <p class="glassTypeDescription" *ngIf="!box.oneGlazing">
            {{ 'WINDOW|Różne pakiety w poszczególnych kwaterach, szczegóły w podsumowaniu.' | translate }}
        </p>
</ng-template>
