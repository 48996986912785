import { Injectable } from '@angular/core';
import {TimeLimit, TimeLimitSegment} from './time-limit-functions';

@Injectable()
export class TimeLimitRollerShutterProfileService {

    /**
     * Pobiera termin realizacji profilu rolety.
     *
     * @param {object} roller   Roleta
     * @return {number} Termin realizacji profilu rolety.
     */
    @TimeLimit({
        shortName: 'rollerShutterProfile',
        data: {
            rollerShutter : 'conf.RollerShutter',
        }
    })
    rollerShutterProfileTimeLimit({timeLimits, rollerShutter}): TimeLimitSegment[] {
        if (rollerShutter == null || !rollerShutter.profile || !rollerShutter.profile.time_limit_id) {
            return null;
        }

        const timeLimitId = rollerShutter.profile.time_limit_id;

        return <TimeLimitSegment[]>[{
            type: 'rollerShutterProfile',
            value: timeLimits[timeLimitId],
            data: {
                id  : rollerShutter.profile.id,
                name: rollerShutter.profile.name,
            }
        }];
    }
}
