import { StepComponentType } from '@icc/configurator/shared';
import { TypesComponent } from './types/types.component';
import { DimensionsComponent } from './dimensions/dimensions.component';
import { MontagesComponent } from './montages/montages.component';
import { ColorsComponent } from './colors/colors.component';
import { VariantsComponent } from './variants/variants.component';
import { NetTypesComponent } from './net-types/net-types.component';
import { ProductDetailsComponent } from '@icc/configurator/pages';
import { AccessoriesComponent } from '@icc/configurator/accessory';

export const mosquitoScreenStepsMap: {
    [stepId: string]: StepComponentType;
} = {
    system: TypesComponent,
    netTypes: NetTypesComponent,
    types: VariantsComponent,
    dimensions: DimensionsComponent,
    montages: MontagesComponent,
    colors: ColorsComponent,
    details: ProductDetailsComponent,
    accessories: AccessoriesComponent,
};
