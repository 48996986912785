/**
 * Funkcja filtrów cen akcesoriów
 * @param {object} PriceAccessoryService      Fabryka podstawowych cen
 * @param {object} ConfigurationsService Fabryka konfiguracji
 */
export default function AccessoryPriceFilter(PriceBaseService, PriceAccessoryService, ConfigurationsService, CustomPricesService, DiscountsAndMultipliersService, StateService) {
    'ngInject';

    return function(accessory) {
        const offer = StateService.getCurrentOffer();
        const dealerId = offer ? offer.dealer_id : null;
        const customPrice = CustomPricesService.get(dealerId);
        let multipliers = offer && offer.multipliers ? offer.multipliers : DiscountsAndMultipliersService.getMultipliers();
        let multiplier = 1;
        multipliers = multipliers && multipliers[dealerId] || multipliers;
        if (multipliers) {
            multiplier = multipliers.Supplement && multipliers.Supplement[0] || 1;
            if (ConfigurationsService.conf.Current.type === 'accessory'
                || ConfigurationsService.conf.Current.type === 'complementary_goods'
            ) {
                multiplier = multipliers.Commodity && multipliers.Commodity[0] || 1;
            }
        }
        return PriceBaseService.addMarginMarketFactor(PriceAccessoryService.priceAccessory(
            accessory,
            ConfigurationsService.conf.Current.Colors || {},
            (customPrice || {}).WindowAccessory,
            ConfigurationsService.conf.Current.SideAccessories ? ConfigurationsService.conf.Current.SideAccessories.sizes : undefined,
            ConfigurationsService.conf.Current.Width,
            ConfigurationsService.conf.Current.Height,
            ConfigurationsService.conf.Current.type,
            multiplier
        ));
    };
}
