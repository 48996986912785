import { Common } from '@icc/common/Common';
import { AccessoriesService } from '../window/accessories/accessories.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { StepsService } from '@icc/helpers';
import { PriceService } from '@icc/price';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { WindowActiveConfiguration, core, APP_CONFIG, AppConfigFactory, EventBusService } from '@icc/common';
import { Inject, Injectable } from '@angular/core';
import { ColorsService } from './colors.service';
import { DrivesService } from './drives.service';
import { RollerDimensionsService } from './dimensions.service';
import { SchemasService } from './schemas.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { IccSystem } from '@icc/common/data-types';

/**
 * Fabryka typów
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} StepFactory              Fabryka kroków
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} DrivesService            Fabryka napedow
 * @param {object} PriceService             Liczenie cen
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} RollerColorsService      Fabryka kolorów rolet
 * @param {object} RollerDimensionsFactory  Fabryka rozmiarów rolet
 * @param {obejct} SchemasService           Fabryka schematów
 */
@Injectable()
export class TypesService {

    types: IccSystem[] = [];
    loadedData = false;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private accessoriesService: AccessoriesService,
        private configurationsService: ConfigurationsService<'roller_shutter' | 'external_blind' | 'window'>,
        private stepsService: StepsService,
        private priceService: PriceService,
        private configuratorsDataService: ConfiguratorsDataService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private drivesService: DrivesService,
        private colorsService: ColorsService,
        private rollerDimensionsService: RollerDimensionsService,
        private eventBusService: EventBusService,
        private schemasService: SchemasService,
    ) {

        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });

    }

    /**
     * Funkcja inicjalizujaca
     */
    private init() {
        if (
            this.currentConfiguratorService.conf !== 'window'
            && this.currentConfiguratorService.conf !== 'roller_shutter'
            && this.currentConfiguratorService.conf !== 'external_blind'
            && this.currentConfiguratorService.conf !== 'hs'
            && this.currentConfiguratorService.conf !== 'sliding_door'
            && this.currentConfiguratorService.conf !== 'folding_door'
        ) {
            return;
        }
        let defaultType;
        this.types = this.configuratorsDataService.data.shutterLines || [];
        if (this.currentConfiguratorService.conf === 'external_blind') {
            this.types = this.configuratorsDataService.data.externalBlindLines || [];
        }
        if (
            (!Common.isObject(this.configurationsService.conf.Current.RollerShutter.type)
                || Common.isUndefined(this.configurationsService.conf.Current.RollerShutter.type.type)
                || Common.isUndefined(
                    this.configurationsService.conf.Current.RollerShutter.type.static
                )
                || Common.isUndefined(this.configurationsService.conf.Current.RollerShutter.type.id))
            && this.types.length > 0
        ) {
            defaultType =
                this.types.filter(function filterTypes(e) {
                    return e.static === 'nadstawna';
                })[0] || this.types[0];
                this.selectRollerType(defaultType);
        }
        this.loadedData = true;
    }

    /**
     * Funkcja wyboru typu rolety
     * @param  {object} object   Obiekt
     * @param  {object} nextStep Nastepny krok
     */
    public selectRollerType(object, nextStep = false) {
        let type;
        if (object.static === 'podtynkowa') {
            type = 'I';
        } else if (object.static === 'natynkowa') {
            type = 'Z';
        } else if (object.static === 'żaluzja podtynkowa') {
            type = 'P';
        } else if (object.static === 'żaluzja adaptacyjna') {
            type = 'A';
        } else {
            type = 'N';
        }
        this.configurationsService.conf.Current.RollerShutter.type = {
            type,
            measureType: object.roller_measure_type,
            id: object.id,
            static: object.static,
            name: object.name,
            title_image: object.title_image,
            offerInfo: object.offer_info
        };
        this.configurationsService.conf.Default.RollerShutter.type = core.copy(
            this.configurationsService.conf.Current.RollerShutter.type
        );

        this.schemasService.setDefaultSystem();
        this.rollerDimensionsService.setBoundaryDimensionsFromPrices();
        this.drivesService.loadRetractor();
        this.priceService.count();
        this.accessoriesService.findAccessories();
    }
}
