import { Component, Inject, OnInit } from '@angular/core';
import { ConfiguratorOptions, ICC_PAGE_DATA, SharedFacade, StepComponent, _ } from '@icc/configurator/shared';

@Component({
  selector: 'icc-type-info',
  templateUrl: './type-info.component.html',
  styleUrls: ['./type-info.component.scss']
})
export class TypeInfoComponent extends StepComponent implements OnInit {
  imageUrl: string;
  public configurator: string = 'roller_shutter';
  public stepId: string;
  public title = (options?: ConfiguratorOptions) => options.type === 'external_blind'? _('ROLLERSHUTTER|Wybierz rodzaj żaluzji') : _('ROLLERSHUTTER|Wybierz rodzaj rolety');
  system: any;
  button: any;
  descriptionFieldName: any;
  imageFieldName: any;
  imageDirectory: any;
  showImage = true;

  constructor(
    private sharedFacade: SharedFacade,
    @Inject(ICC_PAGE_DATA) private pageData: {
      system: any,
      button: boolean,
      descriptionFieldName: string,
      imageFieldName: string,
      imageDirectory: string
      showImage: boolean;
  }
  ) {
    super();
  }

  ngOnInit() {
    this.system = this.pageData.system || {};
    this.button = this.pageData.button;
    this.descriptionFieldName = this.pageData.descriptionFieldName || 'content';
    this.imageFieldName = this.pageData.imageFieldName || 'imageUrl';
    this.imageDirectory = this.pageData.imageDirectory || 'rollershutterdrive';
    this.imageUrl = '/files/' + this.imageDirectory + '/' + this.system[this.imageFieldName];
    this.showImage = this.pageData.showImage ?? true;
}

  close() {
    this.sharedFacade.closePage(true);
  } 

}
