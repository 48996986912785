import { StepComponentType } from '@icc/configurator/shared';
import { SystemComponent } from './system/system.component';
import { DimensionsComponent } from './dimensions/dimensions.component';
import { MechanismComponent } from './mechanism/mechanism.component';
import { PanelComponent } from './panel/panel.component';
import { DriveComponent } from './drive/drive.component';
import { WicketDoorComponent } from './wicket-door/wicket-door.component';
import { ConstructionComponent } from './construction/construction.component';
import { AccessoriesComponent } from '@icc/configurator/accessory';
import { ProductDetailsComponent } from '@icc/configurator/pages';

export const garageDoorStepsMap: {
    [stepId: string]: StepComponentType;
} = {
    system: SystemComponent,
    dimensions: DimensionsComponent,
    mechanism: MechanismComponent,
    panel: PanelComponent,
    drive : DriveComponent,
    wicket_door: WicketDoorComponent,
    construction: ConstructionComponent,
    accessory: AccessoriesComponent,
    details: ProductDetailsComponent
};
