import { MechanismsService } from './../mechanisms.service';
import { GarageDoorFacade } from './../+state/garage-door.facade';
import { Component, OnInit } from '@angular/core';
import { _, StepComponent } from '@icc/configurator/shared';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GarageDoorDimensions } from '@icc/common/configurations/parts/garage_door';
import { UnitConverterService } from '@icc/configurator/ui';

@Component({
    selector: 'icc-mechanism',
    templateUrl: './mechanism.component.html',
    styleUrls: ['./mechanism.component.scss'],
})
export class MechanismComponent extends StepComponent implements OnInit {
    static stepName = _('STEPS|Mechanizm');
    static stepIcon = {
        ligature: 'build',
    };

    public configurator = 'garage_door';
    public stepId = 'mechanism';
    public title = _('GARAGE|Wybierz mechanizm');

    selectedMechanism$ = this.garageDoorFacade.selectedMechanism$;
    selectedGuidance$ = this.garageDoorFacade.selectedGuidance$;
    selectedDimensions$ = this.garageDoorFacade.selectedDimensions$;
    selectedDimensions;
    public dimensionsForm: FormGroup = this.fb.group({});
    dimensionUnit = this.unitConverterService.getUnit();
    
    constructor(
        private garageDoorFacade: GarageDoorFacade,
        private mechanismsService: MechanismsService,
        private unitConverterService: UnitConverterService,
        private fb: FormBuilder
    ) {
        super();
    }
    ngOnInit() {
        this.selectedDimensions$.subscribe(d => this.selectedDimensions = d);
        
        const group = this.fb.group({});
        group.addControl('guidanceLift', this.fb.control(this.selectedDimensions['guidanceLift'], {
            updateOn: 'blur',
        }));
        this.dimensionsForm = group;
        this.dimensionsForm.valueChanges.subscribe(value => {
            const newDimensions: GarageDoorDimensions = {
                ...this.selectedDimensions,
                ...value,
            };
            this.garageDoorFacade.setDimensions(newDimensions);
        });
    }

    selectMechanism() {
        this.mechanismsService.openMechanismModal();
    }

    selectGuidance() {
        this.mechanismsService.openGuidanceModal();
    }
}
