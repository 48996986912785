import { IccConfiguratorData } from './../../../../../common/src/lib/data-types/ConfiguratorsData';
import { Action } from '@ngrx/store';
import { ConfiguratorOptions, ReturnButtonVariant } from '../configurator-options';
import { ComponentType } from '@angular/cdk/overlay';
import { PageComponent } from '@icc/helpers';
import { Issue } from '@icc/helpers';
import { IWindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { ActiveConfiguration } from '@icc/common/configurations/ActiveConfiguration';
import { Message, PreviewData } from './shared.reducers';
import { ConfigurationType } from '../configurator-options';

export interface Step {
    id: string;
    configurator: string;
    enable: boolean;
    enableRx: boolean;
    visited: number;
}

export enum SharedActionTypes {
    LoadedConfiguratorOptions = '[Configurator] Loaded Configurator Options',
    ConfiguratorLoaded = '[Configurator] Configurator Loaded',
    SetConfiguratorLoaded = '[Configurator] Set Configurator Loaded',
    SwitchStep = '[Configurator] Switch Step',
    OpenPage = '[Configurator] Open Page',
    ClosePage = '[Configurator] Close Page',
    CloseAllPages = '[Configurator] Close All Pages',
    OpenFilterSheet = '[Configurator] Open Filter Sheet',
    CloseFilterSheet = '[Configurator] Close Filter Sheet',
    LoadConfiguratorFromStartState = '[Configurator] Load Configurator From Start State',
    LoadConfiguratorDefaultsFromStartState = '[Configurator] Load Configurator Defaults From Start State',
    LoadConfiguratorAllDefaults = '[Configurator] Load Configurator All Defaults',
    ConfigurationLoaded = '[Configurator] Configuration Loaded',
    ConfiguratorsDataLoaded = '[Configurator] Configurators Data Loaded',
    ConfigurationChanged = '[Configurator] Configuration Changed',
    OpenMessage = '[Configurator] Open Message',
    DismissMessage = '[Configurator] Dismiss Message',
    EnableStep = '[Configurator] Enable Step',
    DisableStep = '[Configurator] Disable Step',
    SwitchEnableStep = '[Configurator] Switch Enable Step',
    SwitchEnableRxSteps = '[Configurator] Switch Enable Rx Steps',
    EnableFullscreen = '[Configurator] Enable Fullscreen',
    DisableFullscreen = '[Configurator] Disable Fullscreen',
    ChangedSystemStepMode = '[System] Changed System Step Mode',
    ChangedLightsGlazingStepMode = '[Lights Glazing] Changed Lights Glazing Step Mode',
    DisableSavingButton = '[Configurator] Disable Saving Button',
    EnableSavingButton = '[Configurator] Enable Saving Button',
    ChangeReturnButton = '[Configurator] Change Return Button',
    EnableSearch = '[Configurator] Enable Search',
    DisableSearch = '[Configurator] Disable Search',
    StopSearch = '[Configurator] Stop Search',
    UpdateSearch = '[Configurator] Update Search',
    OpenModalConfigurator = '[Configurator] Open Modal Configurator',
    CloseModalConfigurator = '[Configurator] Close Modal Configurator',
    TogglePreview = '[Configurator] Toggle Preview',
}

export interface ActionWithMessages extends Action {
    payload: { $messages: Issue[] };
}

export class LoadedConfiguratorOptions implements Action {
    readonly type = SharedActionTypes.LoadedConfiguratorOptions;
    constructor(public payload: { options: ConfiguratorOptions; steps: Step[] }) {}
}

export class ConfiguratorLoaded implements Action {
    readonly type = SharedActionTypes.ConfiguratorLoaded;
    constructor(public payload: { options: ConfiguratorOptions; steps: Step[] }) {}
}
export class SetConfiguratorLoaded implements Action {
    readonly type = SharedActionTypes.SetConfiguratorLoaded;
}

export class ConfigurationChanged implements Action {
    readonly type = SharedActionTypes.ConfigurationChanged;
    constructor(public payload: { configuration: ActiveConfiguration }) {}
}

export class SwitchStep implements Action {
    readonly type = SharedActionTypes.SwitchStep;
    constructor(public payload: { nextStepId: string; currentStepId: string }) {}
}

export class OpenPage implements Action {
    readonly type = SharedActionTypes.OpenPage;
    constructor(
        public payload: {
            page: ComponentType<PageComponent>;
            id: string;
            data?: { [key: string]: string | Function | Array<string | Function> | Object };
        }
    ) {}
}

export class ClosePage implements Action {
    readonly type = SharedActionTypes.ClosePage;

    constructor(
        public payload?: {
            result: any;
        }
    ) {}
}

export class CloseAllPages implements Action {
    readonly type = SharedActionTypes.CloseAllPages;

    constructor() {}
}

export class OpenFilterSheet implements Action {
    readonly type = SharedActionTypes.OpenFilterSheet;
    constructor(
        public payload: {
            page: ComponentType<PageComponent>;
            id: string;
            data?: { [key: string]: string | Function | Array<string | Function> | Object };
        }
    ) {}
}

export class CloseFilterSheet implements Action {
    readonly type = SharedActionTypes.CloseFilterSheet;

    constructor(
        public payload?: {
            result: any;
        }
    ) {}
}

export class LoadConfiguratorFromStartState implements Action {
    readonly type = SharedActionTypes.LoadConfiguratorFromStartState;
    constructor(public payload: { startState: ActiveConfiguration | null }) {}
}

export class LoadConfiguratorDefaultsFromStartState implements Action {
    readonly type = SharedActionTypes.LoadConfiguratorDefaultsFromStartState;
    constructor(public payload: { startState: ActiveConfiguration | null }) {}
}

export class LoadConfiguratorAllDefaults implements Action {
    readonly type = SharedActionTypes.LoadConfiguratorAllDefaults;
}

export class ConfigurationLoaded implements Action {
    readonly type = SharedActionTypes.ConfigurationLoaded;

    constructor(public payload: { configuration: ActiveConfiguration }) {}
}

export class ConfiguratorsDataLoaded implements Action {
    readonly type = SharedActionTypes.ConfiguratorsDataLoaded;

    constructor(public payload: { data: IccConfiguratorData }) {}
}

export class OpenMessage implements Action {
    readonly type = SharedActionTypes.OpenMessage;

    constructor(public payload: Message) {}
}

export class DismissMessage implements Action {
    readonly type = SharedActionTypes.DismissMessage;

    constructor(public payload?: string) {}
}

export class EnableStep implements Action {
    readonly type = SharedActionTypes.EnableStep;

    constructor(public payload: Step['id']) {}
}

export class DisableStep implements Action {
    readonly type = SharedActionTypes.DisableStep;

    constructor(public payload: Step['id']) {}
}

export class SwitchEnableStep implements Action {
    readonly type = SharedActionTypes.SwitchEnableStep;

    constructor(public payload: Step['id']) {}
}

export class SwitchEnableRxSteps implements Action {
    readonly type = SharedActionTypes.SwitchEnableRxSteps;

    constructor(public payload: Record<Step['id'], boolean>) {}
}

export class EnableFullscreen implements Action {
    readonly type = SharedActionTypes.EnableFullscreen;
}
export class TogglePreview implements Action {
    readonly type = SharedActionTypes.TogglePreview;
    constructor(public payload: PreviewData) {}
}

export class DisableFullscreen implements Action {
    readonly type = SharedActionTypes.DisableFullscreen;
}

export class ChangedSystemStepMode implements Action {
    readonly type = SharedActionTypes.ChangedSystemStepMode;

    constructor(public payload: 'list' | 'details') {}
}

export class ChangedLightsGlazingStepMode implements Action {
    readonly type = SharedActionTypes.ChangedLightsGlazingStepMode;

    constructor(public payload: 'list' | 'extra') {}
}

export class DisableSavingButton implements Action {
    readonly type = SharedActionTypes.DisableSavingButton;
}

export class EnableSavingButton implements Action {
    readonly type = SharedActionTypes.EnableSavingButton;
}

export class ChangeReturnButton implements Action {
    readonly type = SharedActionTypes.ChangeReturnButton;

    constructor(public payload: ReturnButtonVariant) {}
}

export class EnableSearch implements Action {
    readonly type = SharedActionTypes.EnableSearch;
}

export class DisableSearch implements Action {
    readonly type = SharedActionTypes.DisableSearch;
}

export class StopSearch implements Action {
    readonly type = SharedActionTypes.StopSearch;
}

export class UpdateSearch implements Action {
    readonly type = SharedActionTypes.UpdateSearch;

    constructor(public payload: string) {}
}

export class OpenModalConfigurator implements Action {
    readonly type = SharedActionTypes.OpenModalConfigurator;
    constructor(
        public payload: {
            configuratorType: ConfigurationType;
            id: string;
            data?: { [key: string]: string | Function | Array<string | Function> | Object };
        }
    ) {}
}

export class CloseModalConfigurator implements Action {
    readonly type = SharedActionTypes.CloseModalConfigurator;

    constructor(
        public payload?: {
            result: any;
        }
    ) {}
}

export type SharedAction =
    | LoadedConfiguratorOptions
    | ConfiguratorLoaded
    | SetConfiguratorLoaded
    | ConfiguratorsDataLoaded
    | ConfigurationChanged
    | SwitchStep
    | OpenPage
    | ClosePage
    | CloseAllPages
    | OpenFilterSheet
    | CloseFilterSheet
    | OpenMessage
    | DismissMessage
    | LoadConfiguratorFromStartState
    | LoadConfiguratorDefaultsFromStartState
    | LoadConfiguratorAllDefaults
    | ConfigurationLoaded
    | EnableStep
    | DisableStep
    | SwitchEnableStep
    | SwitchEnableRxSteps
    | EnableFullscreen
    | DisableFullscreen
    | TogglePreview
    | ChangedSystemStepMode
    | ChangedLightsGlazingStepMode
    | DisableSavingButton
    | EnableSavingButton
    | ChangeReturnButton
    | EnableSearch
    | DisableSearch
    | StopSearch
    | UpdateSearch
    | OpenModalConfigurator
    | CloseModalConfigurator;

export const fromSharedActions = {
    LoadedConfiguratorOptions,
    ConfiguratorLoaded,
    SetConfiguratorLoaded,
    ConfiguratorsDataLoaded,
    ConfigurationChanged,
    SwitchStep,
    OpenPage,
    ClosePage,
    CloseAllPages,
    OpenFilterSheet,
    CloseFilterSheet,
    OpenMessage,
    DismissMessage,
    LoadConfiguratorFromStartState,
    LoadConfiguratorDefaultsFromStartState,
    LoadConfiguratorAllDefaults,
    ConfigurationLoaded,
    EnableStep,
    DisableStep,
    SwitchEnableStep,
    SwitchEnableRxSteps,
    EnableFullscreen,
    DisableFullscreen,
    ChangedSystemStepMode,
    ChangedLightsGlazingStepMode,
    DisableSavingButton,
    EnableSavingButton,
    ChangeReturnButton,
    EnableSearch,
    DisableSearch,
    StopSearch,
    UpdateSearch,
    TogglePreview,
    OpenModalConfigurator,
    CloseModalConfigurator
};
