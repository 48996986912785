import { StepComponent, SharedFacade, ICC_PAGE_DATA, _ } from '@icc/configurator/shared';
import { Component, OnInit, Inject } from '@angular/core';
import { EventBusService, AppConfigFactory, APP_CONFIG } from '@icc/common';

@Component({
  selector: 'icc-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss']
})
export class ModalInfoComponent extends StepComponent implements OnInit {

  public configurator = 'window';
  public stepId = 'itemInfo';
  public title = _('WINDOW|Informacja na temat produktu');
  public options = [];

  item: any | null = null;
  button = true;
  filesDir: string;
  itemImgAttributeName: string;
  itemImage: string;

  constructor(
      private sharedFacade: SharedFacade,
      @Inject(APP_CONFIG) private config: AppConfigFactory,
      @Inject(ICC_PAGE_DATA) private pageData: {
          item: any,
          button: boolean,
      }
  ) {
      super();
  }

  ngOnInit() {
      this.item = this.pageData.item;
      this.itemImage = this.item.imageUrl;
      this.button = this.pageData.button;
  }

  close() {
      this.sharedFacade.closePage(true);
  }
}
