import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { EventBusService } from '@icc/common/event-bus.service';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { PriceService } from '@icc/price/b2b';
import { BrowserShapeService } from './shape.service';
import {
    WindowActiveConfiguration,
    IWindowActiveConfiguration,
} from '@icc/common/configurations/WindowActiveConfiguration';
import { Common } from '@icc/common/Common';
import { AlignmentsService } from './alignments.service';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { Frame, CouplingActive } from '@icc/window';
import { ActiveMullion } from '@icc/common/layout/active-mullion';
import { WarrantyService } from '@icc/legacy/price/warranty.service';
import { ConstructionLimitationService } from '../steps/window/dimensions/construction-limitation.service';
import { LayoutService } from './layout.service';
import { SizesService } from 'libs/configurator/door/src/lib/sizes/sizes.service';
import { DimensionsService } from '../steps/window/dimensions/dimensions.service';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';

@Injectable()
export class ResizeService {
    minWidth: number = this.config().IccConfig.Configurators.minWidth;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private warrantyService: WarrantyService,
        private parametersService: ParametersService,
        private priceService: PriceService,
        private shapeService: BrowserShapeService,
        private alignmentsService: AlignmentsService,
        private constructionLimitationService: ConstructionLimitationService,
        private layoutService: LayoutService,
        private sizesService: SizesService,
        private dimensionsService: DimensionsService
    ) {
        this.eventBusService.subscribe('changedDoorSizes', data => {
            this.resizeConstruction(data.activeConfiguration as DoorActiveConfiguration);
        });
    }

    resizeConstruction(conf: DoorActiveConfiguration) {
        const shape = this.sizesService.getShapeFromDoorSizes(conf.doorSizes, conf);
        const oldWidth = conf.Width;
        const oldHeight = conf.Height;

        this.dimensionsService.setDimensions(shape);
        this.resizeLayout(conf.Width - oldWidth, conf.Height - oldHeight, conf);
        this.dimensionsService.validDoorModelDimensions(shape);
        conf.ChangedDimensions = false;
    }

    resizeDoorLayout(width: number, height: number, conf: DoorActiveConfiguration) {
        conf.Sashes.forEach(sash => {});
    }

    /**
     * Skaluje układ
     *
     * @param {any} widthDiff  Różnica w szerokości
     * @param {any} heightDiff Różnica w wysokości
     */
    resizeLayout(widthDiff: number, heightDiff: number, conf: WindowActiveConfiguration) {
        const sides: (keyof ActiveSash['nearAlignments'])[] = ['left', 'right', 'top', 'bottom'];
        const wDiff = Math.floor(widthDiff / 2);
        const hDiff = Math.floor(heightDiff / 2);
        const oldWidth = conf.Width - widthDiff;
        const oldHeight = conf.Height - heightDiff;
        const layout = conf.Layout;

        const canBeResized =
            sides.every(side =>
                conf.Sashes.filter(
                    sash => sash.nearAlignments[side] === -1 && sash.nearMullions[side] === -1
                ).every(
                    finded =>
                        (((side === 'left' || side === 'right')
                            && finded.rWidth + wDiff >= this.minWidth)
                            || ((side === 'top' || side === 'bottom')
                                && finded.rHeight + hDiff >= this.minWidth))
                        && (finded.intSashes.length === 0
                            || finded.intSashes
                                .filter(
                                    field =>
                                        field.nearAlignments[side] === -1
                                        && field.nearMullions[side] === -1
                                )
                                .every(
                                    field =>
                                        ((side === 'left' || side === 'right')
                                            && field.rWidth + wDiff >= this.minWidth)
                                        || ((side === 'top' || side === 'bottom')
                                            && field.rHeight + hDiff >= this.minWidth)
                                ))
                )
            )
            && !(
                conf.Sashes.length > 0
                && DoorActiveConfiguration.is(conf)
                && conf.System
                && conf.System.door_type
            );

        if (widthDiff !== 0 || heightDiff !== 0) {
            if (canBeResized) {
                if (Common.isDefined(layout.changed) && !layout.changed) {
                    this.layoutService.resetDimensions(conf.Layout);
                } else {
                    const resizedFrames = this.resizeFrames(
                        conf.Frames,
                        wDiff,
                        hDiff,
                        widthDiff,
                        heightDiff,
                        oldWidth,
                        oldHeight
                    );
                    const resizedSashes = this.resizeSashes({
                        frames: conf.Frames,
                        hDiff,
                        wDiff,
                        widthDiff,
                        heightDiff,
                        oldHeight,
                        oldWidth,
                        sashes: conf.Sashes,
                        conf,
                    });
                    conf.Frames = resizedFrames;
                    conf.Sashes = resizedSashes;
                    conf.Mullions = this.resizeMullions({
                        mullions: conf.Mullions,
                        frames: resizedFrames,
                        hDiff,
                        wDiff,
                        widthDiff,
                        heightDiff,
                        height: conf.Height,
                        width: conf.Width,
                        sashes: resizedSashes,
                    });
                    conf.couplings = this.resizeCouplings(
                        conf.couplings,
                        resizedFrames,
                        widthDiff,
                        heightDiff,
                        wDiff,
                        hDiff,
                        conf.Height,
                        conf.Width
                    );
                    conf.Layout = {
                        ...layout,
                        changed: true,
                    };

                    layout.changed = true;
                }
            } else {
                conf.EdgeSashes = {
                    left: [],
                    right: [],
                    top: [],
                    bottom: [],
                };
                conf.Sashes = [];
                conf.Frames = [];
                this.layoutService.prepareWindowSashesData(conf.Layout);
            }
            this.eventBusService.post({
                key: 'icc-redraw',
                value: {},
            });
            if (this.config().IccConfig.Configurators.dependencies) {
                this.eventBusService.post({ key: 'processDependencies', value: null });
            }
            this.shapeService.setShapes(conf);
            this.eventBusService.post({
                key: 'changedSashes',
                value: {},
            });
            this.constructionLimitationService.findReinforcement(conf);
            this.shapeService.setShapes(conf);
            this.priceService.count();
            this.warrantyService.check(conf);
            this.parametersService.count(conf);
        }
    }

    private resizeFrames(
        frames: Frame[],
        halfOfWidthDiff: number,
        halfOfHeightDiff: number,
        widthDiff: number,
        heightDiff: number,
        oldWidth: number,
        oldHeight: number
    ): Frame[] {
        return frames.map((frame: Readonly<Frame>) => {
            const resizedFrame = {
                ...frame,
            };
            if (
                halfOfWidthDiff !== 0
                || ((widthDiff % 2 === 1 || widthDiff % 2 === -1)
                    && frame.x + frame.width === oldWidth)
            ) {
                let cor = 0;
                if (
                    (widthDiff % 2 === 1 || widthDiff % 2 === -1)
                    && frame.x + frame.width === oldWidth
                ) {
                    cor = 1;
                }
                if (frame.x === 0 && frame.x + frame.width === oldWidth) {
                    resizedFrame.width += 2 * halfOfWidthDiff + cor;
                } else if (frame.x === 0) {
                    resizedFrame.width += halfOfWidthDiff;
                } else if (frame.x + frame.width === oldWidth) {
                    resizedFrame.width += halfOfWidthDiff + cor;
                    resizedFrame.x += halfOfWidthDiff;
                } else {
                    resizedFrame.x += halfOfWidthDiff;
                }
            }
            if (
                halfOfHeightDiff !== 0
                || ((heightDiff % 2 === 1 || heightDiff % 2 === -1)
                    && frame.y + frame.height === oldHeight)
            ) {
                let cor = 0;
                if (
                    (heightDiff % 2 === 1 || heightDiff % 2 === -1)
                    && frame.y + frame.height === oldHeight
                ) {
                    cor = 1;
                }

                if (frame.y === 0 && frame.y + frame.height === oldHeight) {
                    resizedFrame.height += 2 * halfOfHeightDiff + cor;
                } else if (frame.y === 0) {
                    resizedFrame.height += halfOfHeightDiff;
                } else if (frame.y + frame.height === oldHeight) {
                    resizedFrame.height += halfOfHeightDiff + cor;
                    resizedFrame.y += halfOfHeightDiff;
                } else {
                    resizedFrame.y += halfOfHeightDiff;
                }
            }
            return resizedFrame;
        });
    }

    private resizeSashes({
        frames,
        sashes,
        oldWidth,
        oldHeight,
        widthDiff,
        heightDiff,
        wDiff,
        hDiff,
        conf,
    }: {
        frames: ReadonlyArray<Frame>;
        sashes: ReadonlyArray<ActiveSash>;
        oldWidth: number;
        oldHeight: number;
        widthDiff: number;
        heightDiff: number;
        wDiff: number;
        hDiff: number;
        conf: IWindowActiveConfiguration;
    }): ActiveSash[] {
        let corW = 0;
        let corH = 0;
        return sashes.map(finded => {
            const resizedSash = {
                ...finded,
            };
            const frame = frames.find(f => f.id === finded.frameId);
            if (frame) {
                if (
                    wDiff !== 0
                    || ((widthDiff % 2 === 1 || widthDiff % 2 === -1)
                        && frame.x + finded.rx + finded.rWidth === oldWidth)
                ) {
                    corW = 0;
                    if (
                        (widthDiff % 2 === 1 || widthDiff % 2 === -1)
                        && frame.x + finded.rx + finded.rWidth === oldWidth
                    ) {
                        corW = 1;
                    }
                }
                if (
                    hDiff !== 0
                    || ((heightDiff % 2 === 1 || heightDiff % 2 === -1)
                        && frame.y + finded.ry + finded.rHeight === oldHeight)
                ) {
                    corH = 0;
                    if (
                        (heightDiff % 2 === 1 || heightDiff % 2 === -1)
                        && frame.y + finded.ry + finded.rHeight === oldHeight
                    ) {
                        corH = 1;
                    }
                }
                resizedSash.intSashes = this.resizeInternalFields(
                    finded,
                    frame,
                    wDiff,
                    hDiff,
                    corW,
                    corH,
                    oldWidth,
                    oldHeight,
                    conf
                );
                resizedSash.intMullions = this.resizeInternalMullions(
                    finded,
                    frame,
                    wDiff,
                    hDiff,
                    corW,
                    corH,
                    oldWidth,
                    oldHeight,
                    resizedSash.intSashes
                );
                if (
                    wDiff !== 0
                    || ((widthDiff % 2 === 1 || widthDiff % 2 === -1)
                        && frame.x + finded.rx + finded.rWidth === oldWidth)
                ) {
                    if (
                        frame.x + finded.rx === 0
                        && frame.x + finded.rx + finded.rWidth === oldWidth
                    ) {
                        resizedSash.rWidth += 2 * wDiff + corW;
                    } else if (frame.x + finded.rx === 0) {
                        resizedSash.rWidth += wDiff;
                    } else if (frame.x + finded.rx + finded.rWidth === oldWidth) {
                        resizedSash.rWidth += wDiff + corW;
                        if (frame.x === 0) {
                            resizedSash.rx += wDiff;
                        }
                    } else if (frame.x === 0) {
                        resizedSash.rx += wDiff;
                    }
                }
                if (
                    hDiff !== 0
                    || ((heightDiff % 2 === 1 || heightDiff % 2 === -1)
                        && frame.y + finded.ry + finded.rHeight === oldHeight)
                ) {
                    if (
                        frame.y + finded.ry === 0
                        && frame.y + finded.ry + finded.rHeight === oldHeight
                    ) {
                        resizedSash.rHeight += 2 * hDiff + corH;
                    } else if (frame.y + finded.ry === 0) {
                        resizedSash.rHeight += hDiff;
                    } else if (frame.y + finded.ry + finded.rHeight === oldHeight) {
                        resizedSash.rHeight += hDiff + corH;
                        if (frame.y === 0) {
                            resizedSash.ry += hDiff;
                        }
                    } else if (frame.y === 0) {
                        resizedSash.ry += hDiff;
                    }
                }
                ['left', 'right', 'top', 'bottom'].forEach(
                    (side: keyof ActiveSash['nearAlignments']) =>
                        this.alignmentsService.rearrangeAlignments(conf, side, finded)
                );
            }
            return resizedSash;
        });
    }

    private resizeMullions({
        mullions,
        frames,
        sashes,
        width,
        height,
        widthDiff,
        heightDiff,
        wDiff,
        hDiff,
    }: {
        mullions: ReadonlyArray<ActiveMullion>;
        frames: ReadonlyArray<Frame>;
        sashes: ReadonlyArray<ActiveSash>;
        width: number;
        height: number;
        widthDiff: number;
        heightDiff: number;
        wDiff: number;
        hDiff: number;
    }): ActiveMullion[] {
        const updatedMullions = mullions.map(div => {
            const resizedMullion: ActiveMullion = {
                ...div,
                multiAlignLeft: div.multiAlignLeft.map(s => sashes.find(sash => sash.id === s.id)),
                multiAlignRight: div.multiAlignRight.map(s =>
                    sashes.find(sash => sash.id === s.id)
                ),
                multiAlignTop: div.multiAlignTop.map(s => sashes.find(sash => sash.id === s.id)),
                multiAlignBottom: div.multiAlignBottom.map(s =>
                    sashes.find(sash => sash.id === s.id)
                ),
            };
            const frame = frames.find(f => f.id === div.frameId);
            if (div.direction === 'vertical') {
                if (resizedMullion.multiAlignLeft.length > 0 && frame) {
                    const leftSashes = resizedMullion.multiAlignLeft;
                    const firstSash = leftSashes[0];
                    resizedMullion.rx = firstSash.rx + firstSash.rWidth;
                    const findedL = leftSashes.filter(el => frame.y + el.ry === 0);
                    const findedR = leftSashes.filter(
                        el => frame.y + el.ry + el.rHeight === height
                    );
                    let cor = 0;
                    if ((heightDiff % 2 === 1 || heightDiff % 2 === -1) && findedR.length > 0) {
                        cor = 1;
                    }
                    if (findedL.length > 0 && findedR.length > 0) {
                        resizedMullion.rHeight += 2 * hDiff + cor;
                        resizedMullion.ry = findedL[0].ry;
                    } else if (findedL.length > 0) {
                        resizedMullion.rHeight += hDiff;
                        resizedMullion.ry = findedL[0].ry;
                    } else if (findedR.length > 0) {
                        resizedMullion.rHeight += hDiff + cor;
                        let minR = firstSash.ry;
                        for (let k = 0; k < leftSashes.length; k++) {
                            if (minR > leftSashes[k].ry) {
                                minR = leftSashes[k].ry;
                                break;
                            }
                        }
                        resizedMullion.ry = minR;
                    } else {
                        let minR = firstSash.ry;
                        for (let k = 0; k < leftSashes.length; k++) {
                            if (minR > leftSashes[k].ry) {
                                minR = leftSashes[k].ry;
                                break;
                            }
                        }
                        resizedMullion.ry = minR;
                    }
                }
            } else {
                if (resizedMullion.multiAlignTop.length > 0 && frame) {
                    const topSashes = resizedMullion.multiAlignTop;
                    const firstSash = topSashes[0];
                    resizedMullion.ry = firstSash.ry + firstSash.rHeight;
                    const findedT = topSashes.filter(el => frame.x + el.rx === 0);
                    const findedB = topSashes.filter(el => frame.x + el.rx + el.rWidth === width);
                    let cor = 0;
                    if ((widthDiff % 2 === 1 || widthDiff % 2 === -1) && findedB.length > 0) {
                        cor = 1;
                    }
                    if (findedT.length > 0 && findedB.length > 0) {
                        resizedMullion.rWidth += 2 * wDiff + cor;
                        resizedMullion.rx = findedT[0].rx;
                    } else if (findedT.length > 0) {
                        resizedMullion.rWidth += wDiff;
                        resizedMullion.rx = findedT[0].rx;
                    } else if (findedB.length > 0) {
                        resizedMullion.rWidth += wDiff + cor;
                        let minR = firstSash.rx;
                        for (let k = 0; k < topSashes.length; k++) {
                            if (minR > topSashes[k].rx) {
                                minR = topSashes[k].rx;
                                break;
                            }
                        }
                        resizedMullion.rx = minR;
                    } else {
                        let minR = firstSash.rx;
                        for (let k = 0; k < topSashes.length; k++) {
                            if (minR > topSashes[k].rx) {
                                minR = topSashes[k].rx;
                                break;
                            }
                        }
                        resizedMullion.rx = minR;
                    }
                }
            }
            return resizedMullion;
        });
        updatedMullions.forEach(mullion => {
            mullion.multiAlignLeftDiv = mullion.multiAlignLeftDiv.map(s =>
                updatedMullions.find(m => m.id === s.id)
            );
            mullion.multiAlignRightDiv = mullion.multiAlignRightDiv.map(s =>
                updatedMullions.find(m => m.id === s.id)
            );
            mullion.multiAlignTopDiv = mullion.multiAlignTopDiv.map(s =>
                updatedMullions.find(m => m.id === s.id)
            );
            mullion.multiAlignBottomDiv = mullion.multiAlignBottomDiv.map(s =>
                updatedMullions.find(m => m.id === s.id)
            );
        });
        return updatedMullions;
    }

    private resizeInternalFields(
        sash: Readonly<ActiveSash>,
        frame: Readonly<Frame>,
        wDiff: number,
        hDiff: number,
        corW: number,
        corH: number,
        oldWidth: number,
        oldHeight: number,
        conf: IWindowActiveConfiguration
    ) {
        const resizedFields: ActiveSash[] = sash.intSashes.map(field => {
            const finded = {
                ...field,
            };
            if (
                (wDiff !== 0 || corW > 0)
                && (frame.x + sash.rx === 0 || frame.x + sash.rx + sash.rWidth === oldWidth)
            ) {
                if (
                    frame.x + sash.rx + finded.rx === 0
                    && frame.x + sash.rx + finded.rx + finded.rWidth === oldWidth
                ) {
                    finded.rWidth += 2 * wDiff + corW;
                } else if (frame.x + sash.rx + finded.rx === 0) {
                    finded.rWidth += wDiff + corW;
                } else if (frame.x + sash.rx + finded.rx + finded.rWidth === oldWidth) {
                    finded.rWidth += wDiff + corW;
                    if (frame.x + sash.rx === 0) {
                        finded.rx += wDiff;
                    }
                } else if (frame.x + sash.rx === 0) {
                    finded.rx += wDiff;
                }
            }
            if (
                (hDiff !== 0 || corH > 0)
                && (frame.y + sash.ry === 0 || frame.y + sash.ry + sash.rHeight === oldHeight)
            ) {
                if (
                    frame.y + sash.ry + finded.ry === 0
                    && frame.y + sash.ry + finded.ry + finded.rHeight === oldHeight
                ) {
                    finded.rHeight += 2 * hDiff + corH;
                } else if (frame.y + sash.ry + finded.ry === 0) {
                    finded.rHeight += hDiff + corH;
                } else if (frame.y + sash.ry + finded.ry + finded.rHeight === oldHeight) {
                    finded.rHeight += hDiff + corH;
                    if (frame.y + sash.ry === 0) {
                        finded.ry += hDiff;
                    }
                } else if (frame.y + sash.ry === 0) {
                    finded.ry += hDiff;
                }
            }
            ['left', 'right', 'top', 'bottom'].forEach((side: keyof ActiveSash['nearAlignments']) =>
                this.alignmentsService.rearrangeAlignments(conf, side, finded)
            );
            return finded;
        });

        return resizedFields;
    }

    private resizeInternalMullions(
        sash: Readonly<ActiveSash>,
        frame: Readonly<Frame>,
        wDiff: number,
        hDiff: number,
        corW: number,
        corH: number,
        oldWidth: number,
        oldHeight: number,
        sashes
    ) {
        const resizedMulions: ActiveMullion[] = sash.intMullions.map(
            (div: Readonly<ActiveMullion>) => {
                const resizedMullion = {
                    ...div,
                    multiAlignLeft: div.multiAlignLeft.map(s => sashes.find(ss => ss.id === s.id)),
                    multiAlignRight: div.multiAlignRight.map(s =>
                        sashes.find(ss => ss.id === s.id)
                    ),
                    multiAlignTop: div.multiAlignTop.map(s => sashes.find(ss => ss.id === s.id)),
                    multiAlignBottom: div.multiAlignBottom.map(s =>
                        sashes.find(ss => ss.id === s.id)
                    ),
                };
                if (div.direction === 'vertical') {
                    if (resizedMullion.multiAlignLeft.length > 0) {
                        const leftSashes = resizedMullion.multiAlignLeft;
                        const firstSash = leftSashes[0];
                        resizedMullion.rx = firstSash.rx + firstSash.rWidth;
                        const findedL = leftSashes.filter(el => el.nearMullions.top === -1);
                        const findedR = leftSashes.filter(el => el.nearMullions.bottom === -1);
                        if (
                            findedL.length > 0
                            && findedR.length > 0
                            && frame.y + sash.ry === 0
                            && frame.y + sash.ry + sash.rHeight === oldHeight
                        ) {
                            resizedMullion.rHeight += 2 * hDiff + corH;
                            resizedMullion.ry = findedL[0].ry;
                        } else if (findedL.length > 0 && frame.y + sash.ry === 0) {
                            resizedMullion.rHeight += hDiff;
                            resizedMullion.ry = findedL[0].ry;
                        } else if (
                            findedR.length > 0
                            && frame.y + sash.ry + sash.rHeight === oldHeight
                        ) {
                            resizedMullion.ry += hDiff;
                            let minR = firstSash.ry;
                            for (let k = 0; k < leftSashes.length; k++) {
                                if (minR > leftSashes[k].ry) {
                                    minR = leftSashes[k].ry;
                                    break;
                                }
                            }
                            resizedMullion.ry = minR;
                        } else {
                            let minR = firstSash.ry;
                            for (let k = 0; k < leftSashes.length; k++) {
                                if (minR > leftSashes[k].ry) {
                                    minR = leftSashes[k].ry;
                                    break;
                                }
                            }
                            resizedMullion.ry = minR;
                        }
                    }
                } else {
                    if (resizedMullion.multiAlignTop.length > 0) {
                        const topSashes = resizedMullion.multiAlignTop;
                        const firstSash = topSashes[0];
                        resizedMullion.ry = firstSash.ry + firstSash.rHeight;
                        const findedT = topSashes.filter(el => el.nearMullions.left === -1);
                        const findedB = topSashes.filter(el => el.nearMullions.right === -1);
                        if (
                            findedT.length > 0
                            && findedB.length > 0
                            && frame.x + sash.rx === 0
                            && frame.x + sash.rx + sash.rWidth === oldWidth
                        ) {
                            resizedMullion.rWidth += 2 * wDiff + corW;
                            resizedMullion.rx = findedT[0].rx;
                        } else if (findedT.length > 0 && frame.x + sash.rx === 0) {
                            resizedMullion.rWidth += wDiff;
                            resizedMullion.rx = findedT[0].rx;
                        } else if (
                            findedB.length > 0
                            && frame.x + sash.rx + sash.rWidth === oldWidth
                        ) {
                            resizedMullion.rWidth += wDiff;
                            let minR = firstSash.rx;
                            for (let k = 0; k < topSashes.length; k++) {
                                if (minR > topSashes[k].rx) {
                                    minR = topSashes[k].rx;
                                    break;
                                }
                            }
                            resizedMullion.rx = minR;
                        } else {
                            let minR = firstSash.rx;
                            for (let k = 0; k < topSashes.length; k++) {
                                if (minR > topSashes[k].rx) {
                                    minR = topSashes[k].rx;
                                    break;
                                }
                            }
                            resizedMullion.rx = minR;
                        }
                    }
                }
                return resizedMullion;
            }
        );

        return resizedMulions;
    }

    private resizeCouplings(
        couplings: ReadonlyArray<CouplingActive>,
        frames: ReadonlyArray<Frame>,
        widthDiff: number,
        heightDiff: number,
        wDiff: number,
        hDiff: number,
        width: number,
        height: number
    ): CouplingActive[] {
        return couplings.map(coupling => {
            const resizedCoupling = {
                ...coupling,
            };

            if (coupling.direction === 'vertical') {
                if (resizedCoupling.framesId.length > 0) {
                    const firstFrame = frames.some(
                        f => resizedCoupling.framesId.some(fid => fid.id === f.id) && f.y === 0
                    );
                    const lastFrame = frames.some(
                        f =>
                            resizedCoupling.framesId.some(fid => fid.id === f.id)
                            && f.y + f.height === height
                    );

                    let cor = 0;
                    if ((heightDiff % 2 === 1 || heightDiff % 2 === -1) && lastFrame) {
                        cor = 1;
                    }

                    if (firstFrame) {
                        resizedCoupling.length += hDiff;
                    }
                    if (lastFrame) {
                        resizedCoupling.length += hDiff + cor;
                    }
                }
            } else {
                if (resizedCoupling.framesId.length > 0) {
                    const firstFrame = frames.some(
                        f => resizedCoupling.framesId.some(fid => fid.id === f.id) && f.x === 0
                    );
                    const lastFrame = frames.some(
                        f =>
                            resizedCoupling.framesId.some(fid => fid.id === f.id)
                            && f.x + f.width === width
                    );
                    let cor = 0;
                    if ((widthDiff % 2 === 1 || widthDiff % 2 === -1) && lastFrame) {
                        cor = 1;
                    }
                    if (firstFrame) {
                        resizedCoupling.length += wDiff;
                    }
                    if (lastFrame) {
                        resizedCoupling.length += wDiff + cor;
                    }
                }
            }
            return resizedCoupling;
        });
    }
}
