import { core } from "@icc/common/helpers";

export default class ColorsModalController {
    resolve: {
        colors;
    };
    close: ({ $value }) => any;
    dismiss: () => any;

    constructor(
        private ScrollbarService,
        private $translate,
        public IccConfig,
        public SystemsComparisonService,
    ) {

    }

    $onChanges() {
        this.ScrollbarService.update();
    }

    closeModal(save) {
        this.close({ $value: save });
    }

    async changeColor(colorId) {
        const color = this.resolve.colors[colorId];
        const conf = color.config.conf;
        const changedColor = await this.SystemsComparisonService.changeColor(color.config, true);
        if (
            conf.Colors.frame.alushell?.id !== changedColor.frame.alushell?.id ||
            conf.Colors.frame.core?.id !== changedColor.frame.core?.id ||
            conf.Colors.frame.inner?.id !== changedColor.frame.inner?.id ||
            conf.Colors.frame.outer?.id !== changedColor.frame.outer?.id ||
            conf.Colors.sash.alushell?.id !== changedColor.sash.alushell?.id ||
            conf.Colors.sash.core?.id !== changedColor.sash.core?.id ||
            conf.Colors.sash.inner?.id !== changedColor.sash.inner?.id ||
            conf.Colors.sash.outer?.id !== changedColor.sash.outer?.id ||
            color.config.pvcPanelColor?.inner?.id !== changedColor.pvcPanelColor.inner?.id ||
            color.config.pvcPanelColor?.outer?.id !== changedColor.pvcPanelColor.outer?.id
        ) {
            color.changedColor = core.copy(changedColor);
        }
    }

}
