import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepComponent, _ } from '@icc/configurator/shared';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { ColorsService } from '@icc/legacy/configurator/steps/awning/colors.service';
import { EventBusService, ConfigurationsService } from '@icc/common';
@Component({
    selector: 'icc-awning-colors',
    templateUrl: './awning-colors.component.html',
    styleUrls: ['./awning-colors.component.scss'],
})
export class AwningColorsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Kolor markizy');
    static stepIcon = {
        ligature: 'palette',
    };
    private subscriptions: any[] = [];
    public configurator = 'awning';
    public stepId = 'colors';
    public title = _('COLOR|Wybierz kolory markizy:');
    colors = [];
    colorId: number | null = null;


    colorsGroups: iccListTab[] = [];
    constructor(
        private colorsService: ColorsService, 
        private eventBusService: EventBusService, 
        private configurationsService: ConfigurationsService<'awning'>
        ) {
        super();
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }
    init() {
        const groups = this.colorsService;
        const colors = this.colorsService.colors;
        this.colors = colors.map(color => ({
            id: Number(color.id),
            title: color.name,
            description: color.code,
            color: '#' + color.color,
            imageUrl: '/files/awningconstructioncolour/' + color.color_img,
            tabs: (color.groups && color.groups.map(Number)) || [],
        }));
    }

    ngOnInit() {
        if (this.configurationsService.conf.Current) {
            this.colorId = Number(this.configurationsService.conf.Current.Colour.id);
        }
        if (this.colorsService.loadedData)
        {
            this.init();
        }
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    selectColor(item: iccListItem) {
        const color = this.colorsService.colors.find(s => Number(s.id) === item.id);
        this.colorsService.selectColor(color);
    }

}
