import angular from 'angular';

/**
 * Administrator nie powinien widzieć w konfiguratorze trzech ostatnich kroków i po to jest ten filtr ;)
 */
export default function UserAccessFilter() {
    'ngInject';

    return function(items, userAccess) {

        if (userAccess != 'Super Admin' && userAccess != 'admin' && userAccess != 'Admin') {
            return items;
        }

        var filtered = [];
        var i = 1;

        angular.forEach(items, function(item) {

            if(i <= 5) {
                filtered.push(item);
            }

            i++;
        });

        return filtered;
    };
}
