import angular from 'angular';

/**
 * Fabryka sposobu montażu do markiz
 * @param {object} $rootScope               RootScope
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} ConfiguratorsDataService Fabryka z danymi do konfiguratorów
 * @param {object} CurConfService           Informacje o bieżącym konfiguratorze
 * @param {object} StepFactory              StepFactory
 * @param {object} PriceService             Liczenie cen
 */
export default function AssemblyFactory($rootScope, ConfigurationsService, ConfiguratorsDataService, CurConfService, StepFactory, PriceService, EventBusService) {
    'ngInject';

    var factory = {
        mountings        : [],
        mountingsQuantity: [],
        mounting         : {},
        count            : 0,
        loadedData       : false,
        selectMounting   : selectMounting,
        setDefaultValues : setDefaultValues
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    $rootScope.$on('changedStep', function(event, newVal, oldVal) {
        if (newVal.code === 'assembly') {
            init();
        }
    });

    return factory;

    /**
     * funkcja inicjujaca
     */
    function init() {
        if (CurConfService.conf === 'awning') {
            setValues();
            if (angular.isUndefined(ConfigurationsService.conf.Current.Mounting.id)) {
                factory.setDefaultValues();
            }
            if (ConfigurationsService.conf.Current.MountingQuantity === 0) {
                factory.setDefaultValues();
            }
            factory.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Ustawianie wartości
     */
    function setValues() {
        factory.mountings = getMountings();
        factory.mountingsQuantity = getMountingsQuantity();
    }

    function getMountings() {
        var systemID = ConfigurationsService.conf.Current.SystemId;
        if (systemID > 0) {
            return ConfiguratorsDataService.data.awningLineMountings[systemID];
        } else {
            return [];
        }
    }

    /**
     * Ustawianie ilości montażu
     * @return {Array} Id systemu
     */
    function getMountingsQuantity() {
        var systemID = ConfigurationsService.conf.Current.SystemId;
        if (systemID > 0) {
            return ConfiguratorsDataService.data.awningLineMountingsQuantity[systemID];
        } else {
            return [];
        }
    }

    /**
     * Wybór montażu
     * @param  {Object} mounting Montaż
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    function selectMounting(mounting, nextStep, price) {

        factory.mounting = mounting;
        ConfigurationsService.conf.Current.Mounting = mounting;
        // ConfigurationsService.conf.Default.Mounting = mounting;

        var width = ConfigurationsService.conf.Current.Width;
        var i = 0;

        for (i = 0; i < factory.mountingsQuantity.length; i++) {
            if (factory.mountingsQuantity[i].min_width <= width && width <= factory.mountingsQuantity[i].max_width) {
                factory.count = factory.mountingsQuantity[i].quantity;
                ConfigurationsService.conf.Current.MountingQuantity = factory.mountingsQuantity[i].quantity;
                // ConfigurationsService.conf.Default.MountingQuantity = factory.mountingsQuantity[i].quantity;
            }
        }

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }
    }

    /**
     * Ustawianie wartości domyślnych
     * @param {Boolean} reset Reset
     */
    function setDefaultValues(reset) {

        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }

        setValues();

        if (angular.isUndefined(factory.mountings[0])) {
            return;
        }

        if (
            ConfigurationsService.conf.Current.MountingQuantity === 0 ||
            reset
        ) {
            factory.selectMounting(factory.mountings[0]);
        }
    }

}
