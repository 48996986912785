<div class="modal-header">
    <h3 class="modal-title">{{ 'WINDOW|Wybierz sposób montażu' | translate }}</h3>
    <span class="modal-close modal-close-x" (click)="discard()"><i class="fa fa-close"></i></span>
</div>
<div class="modal-body accesoryInput">
    <div class="measurements" style="padding: 30px;">
        <div class="row">
            <div class="col-xs-12">
                <div class="row marginBottom marginTop">
                    <div class="col-xs-2"></div>
                    <div class="col-xs-8" *ngIf="checkMeasurementsWarning().show" style="font-weight: bold; font-size:larger; color: red;">
                        {{ 'OFFER|Wypełnij pola pomiarowe w konstrukcjach: ' | translate }} {{ checkMeasurementsWarning().constructions }}
                        <br>
                    </div>
                </div>
                <div class="row marginBottom marginTop">
                    <div class="col-xs-4 text-uppercase">
                        <h5>{{ 'OFFER|Nr konstrukcji' | translate }}</h5>
                    </div>
                    <div class="col-xs-3">
                        <select class="form-control" [(ngModel)]="measurementNumber">
                            <option *ngFor="let item of measurements; index as i" [value]="i">{{i + 1}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-8">
                        <img [src]="'/files/mountingmethod/' + measurements[measurementNumber].mountingMethod.img">
                    </div>
                </div>
                <div class="row marginBottom marginTop">
                    <div class="col-xs-5">
                        <button class="button changeButton" (click)="openModalMountingMethod(measurements[measurementNumber])">{{ 'OFFER|Zmień sposób montażu' | translate }}</button>
                    </div>
                </div>
                <div class="row small marginBottom">
                    <div class="col-xs-2"></div>
                    <div class="col-xs-2" *ngIf="checkField('hightInside') || checkField('widthInside') || checkField('crossHightInside')">
                        {{ 'OFFER|Wym. wew.' | translate }}
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('hightOutside') || checkField('widthOutside') || checkField('crossHightOutside')">
                        {{ 'OFFER|Wym. zew.' | translate }}
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('hightWarmingLayer') || checkField('widthWarmingLayer')">
                        {{ 'OFFER|Wym. warstwy ociepl.' | translate }}
                    </div>
                </div>
                <div class="row marginBottom" style="margin-top: 10px;" *ngIf="checkField('widthInside') || checkField('widthOutside') || checkField('widthWarmingLayer')" >
                    <div class="col-xs-2">
                        {{ 'OFFER|Szerokość' | translate }}
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('widthInside')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].widthInside" id="widthInside"/>
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('widthOutside')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].widthOutside" id="widthOutside"/>
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('widthWarmingLayer')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].widthWarmingLayer" id="widthWarmingLayer"/>
                    </div>
                </div>
                <div class="row marginBottom" *ngIf="checkField('hightInside') || checkField('hightOutside') || checkField('hightWarmingLayer')" >
                    <div class="col-xs-2">
                        {{ 'OFFER|Wysokość' | translate }}
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('hightInside')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].hightInside" id="hightInside"/>
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('hightOutside')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].hightOutside" id="hightOutside"/>
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('hightWarmingLayer')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].hightWarmingLayer" id="hightWarmingLayer"/>
                    </div>
                </div>
                <div class="row marginBottom" *ngIf="checkField('crossHightInside') || checkField('crossHightOutside')" >
                    <div class="col-xs-2">
                        {{ 'OFFER|Wysokość wew-zew' | translate }}
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('crossHightInside')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].crossHightInside" id="crossHightInside"/>
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('crossHightOutside')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].crossHightOutside" id="crossHightOutside"/>
                    </div>
                </div>
                <div class="row marginBottom" *ngIf="checkField('fit') && checkField('enlargementTop') && checkField('enlargementLeft') && checkField('enlargementRight')">
                    <div class="col-xs-12">
                        <input type="radio" name="fit" id="fitFrame" [attr.checked]="getFit(measurementNumber) == 'frame' ? 'checked' : null">
                        <label for="fitFrame" (click)="changeFit($event, measurementNumber, 'frame')">{{ 'OFFER|Dopasowanie wym. ramy do światła otworu' | translate }}</label>
                        <input type="radio" name="fit" id="fitConstruction" [attr.checked]="getFit(measurementNumber) == 'construction' ? 'checked' : null">
                        <label for="fitConstruction" (click)="changeFit($event, measurementNumber, 'construction')">{{ 'OFFER|Dopasowanie wym. całej konstrukcji do otworu' | translate }}</label>
                    </div>
                </div>
                <div class="row small marginBottom">
                    <div class="col-xs-4"></div>
                    <div class="col-xs-2">
                        {{ 'OFFER|Odległość na parapet i/lub profile poszerzeń' | translate }}
                    </div>
                </div>
                <div class="row marginBottom" style="margin-top: 10px;" *ngIf="checkField('distanceOutBottom')">
                    <div class="col-xs-4">
                        {{ 'OFFER|Dół konstrukcji' | translate }}
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('distanceOutBottom')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].distanceOut['bottom']" id="distanceOut-bottom"/>
                    </div>
                </div>
                <div class="row small marginBottom">
                    <div class="col-xs-4"></div>
                    <div class="col-xs-2" *ngIf="checkField('enlargementTop') && checkField('enlargementLeft') && checkField('enlargementRight') && measurements[measurementNumber].fit === 'frame'">
                        {{ 'OFFER|Powiększenie konstrukcji' | translate }}
                    </div>
                    <div class="col-xs-2" *ngIf="!(checkField('enlargementTop') && checkField('enlargementLeft') && checkField('enlargementRight') && measurements[measurementNumber].fit === 'frame')">
                        {{ 'OFFER|Luzy montażowe' | translate }}
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('distanceInTop') || checkField('distanceInLeft') || checkField('distanceInRight')">
                        {{ 'OFFER|Rozmiar węgarków' | translate }}
                    </div>
                </div>
                <div class="row marginBottom" style="margin-top: 10px;" *ngIf="checkField('distanceOutTop') || checkField('distanceInTop')">
                    <div class="col-xs-4">
                        {{ 'OFFER|Góra konstrukcji' | translate }}
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('distanceOutTop') && !(checkField('enlargementTop') && checkField('enlargementLeft') && checkField('enlargementRight') && measurements[measurementNumber].fit === 'frame')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].distanceOut['top']" id="distanceOut-top"/>
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('enlargementTop') && measurements[measurementNumber].fit === 'frame'">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].enlargement['top']" id="enlargement-top"/>
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('distanceInTop')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].distanceIn['top']" id="distanceIn-top"/>
                    </div>
                    <div class="col-xs-2"></div>
                </div>
                <div class="row marginBottom" *ngIf="checkField('distanceOutLeft') || checkField('distanceInLeft')">
                    <div class="col-xs-4">
                        {{ 'OFFER|Lewa strona konstrukcji' | translate }}
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('distanceOutLeft') && !(checkField('enlargementTop') && checkField('enlargementLeft') && checkField('enlargementRight') && measurements[measurementNumber].fit === 'frame')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].distanceOut['left']" id="distanceOut-left"/>
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('enlargementLeft') && measurements[measurementNumber].fit === 'frame'">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].enlargement['left']" id="distanceIn-left"/>
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('distanceInLeft')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].distanceIn['left']" id="distanceIn-left"/>
                    </div>
                </div>
                <div class="row" *ngIf="checkField('distanceOutRight') || checkField('distanceInRight')">
                    <div class="col-xs-4">
                        {{ 'OFFER|Prawa strona konstrukcji' | translate }}
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('distanceOutRight') && !(checkField('enlargementTop') && checkField('enlargementLeft') && checkField('enlargementRight') && measurements[measurementNumber].fit === 'frame')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].distanceOut['right']" id="distanceOut-right"/>
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('enlargementRight') && measurements[measurementNumber].fit === 'frame'">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].enlargement['right']" id="distanceIn-right"/>
                    </div>
                    <div class="col-xs-2" *ngIf="checkField('distanceInRight')">
                        <input type="number" #measurementsinput min="0" step="1" [(ngModel)]="measurements[measurementNumber].distanceIn['right']" id="distanceIn-right"/>
                    </div>
                </div>
                <div class="row dimensionInfo">
                    <div class="col-xs-12 small">
                        {{ 'OFFER|Wszystkie wymiary w mm' | translate }}
                    </div>
                </div>
                <div class="row marginBottom">
                    <div class="col-xs-6">
                        {{ 'OFFER|Dodatkowe notatki' | translate }}
                        <textarea rows="5" [(ngModel)]="measurements[measurementNumber].notes" id="notes"></textarea>
                    </div>
                </div>
                <div class="row marginBottom marginTop">
                    <div class="col-xs-2"></div>
                    <div class="col-xs-8" *ngIf="checkMeasurementsWarning().show" style="font-weight: bold; font-size:larger; color: red;">
                        {{ 'OFFER|Wypełnij pola pomiarowe w konstrukcjach: ' | translate }} {{ checkMeasurementsWarning().constructions }}
                        <br>
                    </div>
                </div>
                <div class="row text-uppercase marginBottom">
                    <div class="col-xs-4">
                        <!-- <button class="button">{{ 'INTERFACE|Dodaj zdjęcia' | translate }}</button> -->
                        <span class="buttonFile fr">
                            <input type="file" multiple fileread class="buttonFile" (change)="addFiles($event)">
                            {{ 'INTERFACE|Dodaj zdjęcia' | translate }}
                        </span>
                    </div>
                    <div class="col-xs-8 text-uppercase" style="text-align: right">
                        <button class="button silverBtn" (click)="discard()">{{ 'INTERFACE|Anuluj' | translate }}</button>
                        <button class="button" (click)="saveMeasurements()">{{ 'INTERFACE|Zapisz pomiary' | translate }}</button>
                        <button class="button" (click)="saveAndCalcMeasurements()">{{ 'INTERFACE|Zapisz i przelicz' | translate }}</button>
                    </div>
                </div>
                <div class="row text-uppercase marginBottom">
                    <div class="col-xs-12">
                        <ng-container *ngFor="let attachment of measurements[measurementNumber].attachments; let i = index">
                            <div *ngIf="!attachment.deleted">
                                <a (click)="downloadFile(attachment)">{{attachment.name}}</a> <i (click)="removeFile(i)" class="fa fa-remove"></i>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
