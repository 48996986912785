import { Component,Inject, OnInit } from '@angular/core';
import {ICC_PAGE_DATA, SharedFacade, StepComponent, _ } from '@icc/configurator/shared';

@Component({
  selector: 'icc-type-info-hanger',
  templateUrl: './type-info-hanger.component.html',
  styleUrls: ['./type-info-hanger.component.scss']
})
export class TypeInfoHangerComponent extends StepComponent  implements OnInit {

  imageUrl: string;
  hanger: any;
  showImage = true;
  public configurator = 'roller_shutter';
  public stepId: string;
  public title = _('ROLLERSHUTTER|Opis wieszaka')

  constructor(
    private sharedFacade: SharedFacade,
    @Inject(ICC_PAGE_DATA) private pageData: {
      hanger: any,
  }
  ) {
    super();
  }

  ngOnInit() {
    this.hanger = this.pageData.hanger || {};
    this.imageUrl = '/files/rollershutterhanger/' + this.hanger.title_image;
    this.showImage = this.hanger.title_image ?? true;
}

  close() {
    this.sharedFacade.closePage(true);
  } 
}
