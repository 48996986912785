import { Action } from '@ngrx/store';
import { IWindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';

export enum WindowActionTypes {
    LoadWindow = '[Window] Load Window',
    LoadWindowFromStartState = '[Window] Load Window From Start State',
    LoadWindowDefaultsFromStartState = '[Window] Load Window Defaults From Start State',
    LoadWindowAllDefaults = '[Window] Load Window All Defaults',
    LoadWindowConfiguration = '[Window] Load Window Configuration',
    WindowLoaded = '[Window] Window Loaded',
    WindowLoadError = '[Window] Window Load Error',
    SaveWindowConfiguration = '[Window] Save Window Configuration',
    UpdateWindowConfiguration = '[Window] Update Window Configuration',
}

export class LoadWindow implements Action {
    readonly type = WindowActionTypes.LoadWindow;
}

export class WindowLoadError implements Action {
    readonly type = WindowActionTypes.WindowLoadError;
    constructor(public payload: any) {}
}

export class LoadWindowFromStartState implements Action {
    readonly type = WindowActionTypes.LoadWindowFromStartState;
    constructor(public payload: { startState: IWindowActiveConfiguration }) {}
}

export class LoadWindowDefaultsFromStartState implements Action {
    readonly type = WindowActionTypes.LoadWindowDefaultsFromStartState;
    constructor(public payload: { startState: IWindowActiveConfiguration }) {}
}

export class LoadWindowAllDefaults implements Action {
    readonly type = WindowActionTypes.LoadWindowAllDefaults;
}

export class LoadWindowConfiguration implements Action {
    readonly type = WindowActionTypes.LoadWindowConfiguration;
    constructor(public payload: { configuration: IWindowActiveConfiguration }) {}
}

export class WindowLoaded implements Action {
    readonly type = WindowActionTypes.WindowLoaded;
}

export class SaveWindowConfiguration implements Action {
    readonly type = WindowActionTypes.SaveWindowConfiguration;
}

export class UpdateWindowConfiguration implements Action {
    readonly type = WindowActionTypes.UpdateWindowConfiguration;
    constructor(public payload: { configuration: IWindowActiveConfiguration }) {}
}

export type WindowAction =
    | LoadWindow
    | WindowLoaded
    | WindowLoadError
    | LoadWindowFromStartState
    | LoadWindowDefaultsFromStartState
    | LoadWindowAllDefaults
    | LoadWindowConfiguration
    | UpdateWindowConfiguration;

export const fromWindowActions = {
    LoadWindow,
    WindowLoaded,
    WindowLoadError,
    LoadWindowFromStartState,
    LoadWindowDefaultsFromStartState,
    LoadWindowAllDefaults,
    LoadWindowConfiguration,
    UpdateWindowConfiguration,
};
