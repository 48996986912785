import { OnInit, Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { PageComponent } from './page.component';

@Injectable()
export class NoopPageComponent extends PageComponent {
    public title = '';
    constructor() {
        super();
    }
}
