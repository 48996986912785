import { AfterViewInit, Directive, ElementRef, Input, HostBinding } from '@angular/core';

@Directive({
    selector: 'img[defaultImg]',
    host: {
      '(error)':'setDefaultImg()',
      '[src]':'src'
     }
  })
export class DefaultImageDirective {
    @Input() src:string;
    @Input() defaultImg:string;

    setDefaultImg() {
      this.src = this.defaultImg || '/assets/noImg.jpg';
    }
}
