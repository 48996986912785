import {Injectable, Inject} from '@angular/core';
import { Common } from '@icc/common/Common';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import {LoadedConfiguratorsDataValue} from '@icc/common/configurators/configurators-data.service';
import {core, logger} from '@icc/common/helpers';
import {EventBusService} from '@icc/common/event-bus.service';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';
import { ModalService } from '@icc/helpers';
import { GlazingSpacerPageComponent } from 'libs/configurator/window/src/lib/glazing-spacer-page/glazing-spacer-page.component';

@Injectable()
export class WarmEdgesService {

    warmEdges = [];

    private allWarmEdges = [];

    constructor(
        private eventBusService: EventBusService,
        private modalService: ModalService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
    ) {
        eventBusService.subscribe<LoadedConfiguratorsDataValue>('loadedConfiguratorsData', data => {
            try {
                this.loadWarmEdges(data.value);
                if (data.activeConfiguration) {
                    this.loadMatchingWarmEdges(<WindowActiveConfiguration> data.activeConfiguration, <WindowActiveConfiguration> data.defaultConfiguration);
                }
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>('setSystem', (data) => {
            try {
                this.loadMatchingWarmEdges(<WindowActiveConfiguration> data.activeConfiguration, <WindowActiveConfiguration> data.defaultConfiguration);
                this.setDefaultWarmEdge(<WindowActiveConfiguration> data.activeConfiguration, <WindowActiveConfiguration> data.defaultConfiguration, data.value.newSystem, false, data.value.defaultSystem);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('loadedWarmEdges', (data) => {
            try {
                this.setDefaultWarmEdge(<WindowActiveConfiguration> data.activeConfiguration, <WindowActiveConfiguration> data.defaultConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setGlazingInSash', (data) => {
            try {
                this.checkWarmEdgeExistence(<WindowActiveConfiguration> data.activeConfiguration);
                this.loadMatchingWarmEdges(<WindowActiveConfiguration> data.activeConfiguration,  <WindowActiveConfiguration> data.defaultConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });
    }

    setWarmEdge(warmEdge, conf: WindowActiveConfiguration, defaultConf, isDefault = false) {
        if (!warmEdge) {
            return;
        }
        warmEdge = core.copy(warmEdge);
        warmEdge.isDefault = isDefault;
        conf.WarmEdge = warmEdge;
        defaultConf.WarmEdge = warmEdge;
        this.eventBusService.post({
            key  : 'setWarmEdge',
            value: {
                warmEdgeId: warmEdge.id,
            },
            conf,
            defaultConf
        });
    }

    setDefaultWarmEdge(conf: WindowActiveConfiguration, defaultConf, newSystem = false, setByDefaultConfigurationPerSystem = false, defaultSystem = false) {
        const defaultWarmEdge = this.getDefaultWarmEdge(conf, defaultConf, newSystem, setByDefaultConfigurationPerSystem, defaultSystem);
        this.setWarmEdge(defaultWarmEdge, conf, defaultConf, defaultWarmEdge ? defaultWarmEdge.isDefault : true);
    }

    getDefaultWarmEdge(conf: WindowActiveConfiguration, defaultConf, newSystem = false, setByDefaultConfigurationPerSystem = false, defaultSystem = false) {
        const noConfOrWarmEdge = !conf
            || !conf.WarmEdge?.id
            || !(core.fId(this.warmEdges, conf.WarmEdge.id))
            || conf.WarmEdge.isDefault
            || newSystem
            || setByDefaultConfigurationPerSystem;

        if (noConfOrWarmEdge
            && this.warmEdges !== null
            && this.warmEdges.length
        ) {
            const isWarmEdge = defaultConf?.WarmEdge?.id
                && Common.isDefined(core.fId(this.warmEdges, defaultConf.WarmEdge.id))
            const noDefaultMarketConfiguration = (!newSystem && !setByDefaultConfigurationPerSystem) || defaultSystem
            if (isWarmEdge && noDefaultMarketConfiguration) {
                const warmEdge = core.copy(defaultConf.WarmEdge);
                warmEdge.isDefault = Number(warmEdge.id) === Number(conf.System.default_window_warm_edge_id)
                    || Number(warmEdge.id) === Number(this.warmEdges[0].id);
                return warmEdge;
            } else {
                const warmEdge = core.copy(this.warmEdges.find(e => {
                    if (conf.System.default_market_configuration?.window_warm_edge_id) {
                        return Number(e.id) === Number(conf.System.default_market_configuration.window_warm_edge_id)
                    } else {
                        return Number(e.id) === Number(conf.System.default_window_warm_edge_id)
                    }
                }) || this.warmEdges[0]);

                warmEdge.isDefault = true;
                return warmEdge;
            }
        }
    }

    openModal(conf: WindowActiveConfiguration, defaultConf) {
        const modalInstance = this.modalService.open({
            templateUrl: 'modalWarmEdge.html',
            controller : 'ModalWarmEdgeCtrl as mwarmedge',
            pageComponent: GlazingSpacerPageComponent,
            resolve    : {
                windowWarmEdges: () => this.warmEdges,
                selected       : () => conf.WarmEdge
            }
        });

        modalInstance.result.then((selectedEdge) => {
            if (selectedEdge && selectedEdge.id) {
                this.setWarmEdge(selectedEdge, conf, defaultConf);
            }
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                });
            }
        });
    }

    loadWarmEdges(data) {
        this.allWarmEdges = data.windowWarmEdges || [];
    }

    loadMatchingWarmEdges(conf: WindowActiveConfiguration, defaultConf, event = true) {
        if (!conf || !conf.System || !conf.WarmEdge) {
            this.eventBusService.post({
                key  : 'loadedWarmEdges',
                value: this.warmEdges,
                conf,
                defaultConf
            });
            this.warmEdges = [];
            return;
        }

        const glazingCount = conf.Sashes && conf.Sashes.length
            ? conf.Sashes
                .reduce((sashes, sash) => sashes.concat([sash], sash.intSashes), [])
                .map(e => e.glazing ? ~~e.glazing.glazing_count : 0)
            : [];

        this.warmEdges = this.allWarmEdges.filter(warmEdge => {
            const systemTypes = warmEdge.system_types;
            if (Common.isArray(systemTypes)
                && Common.isArray(warmEdge.prices)
                && (warmEdge.prices.some(p => glazingCount.indexOf(p.count) > -1)
                    || glazingCount.every(c => c < 2))
            ) {
                if (Common.isObject(conf.System)) {
                    if (systemTypes.indexOf(conf.System.type) > -1 && warmEdge.prices.some(p => !p.window_line_id || Number(conf.System.id) === Number(p.window_line_id))
                    ) {
                        return true;
                    }
                } else {
                    if (systemTypes.indexOf('pvc') > -1) {
                        return true;
                    }
                }
            }
        });
        if (event) {
            this.eventBusService.post({
                key  : 'loadedWarmEdges',
                value: this.warmEdges,
                conf,
                defaultConf
            });
        }
    }

    checkWarmEdgeExistence(conf: WindowActiveConfiguration) {
        conf.HasWarmEdge = conf.Sashes
             .reduce((sashes, sash) => sashes.concat([sash], sash.intSashes), [])
             .some((sash) => Number(sash.glazing.glazing_count) > 1);
    }
}
