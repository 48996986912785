import angular from 'angular';

export default function SashHasMuntinsFilter() {
    'ngInject';

    return function(input) {
        if (typeof input == 'undefined') {
            return false;
        }
        return input.filter(function(sash) {
            if (sash.intSashes.length == 0) {
                return sash.muntins.length;
            } else {
                return sash.intSashes[0].muntins.length;
            }
        });
    };
}
