import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';
import { Injectable, Inject } from '@angular/core';
import {
    ConfigurationsService,
    ParametersService,
    ConfiguratorsDataService,
    EventBusService,
    TranslateService,
} from '@icc/common';
import { StepFactory } from '../../StepFactory';
import { PriceService } from '@icc/price';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { LayoutService } from '../../../layout/layout.service';
import { HandlesService } from '../../window/handles/handles.service';
import { SystemsService } from '../../window/systems/systems.service';
import { IssuesService, StepsService } from '@icc/helpers';
import { FillingsService } from '../../window/glazings/fillings.service';
import { IccFilling } from '@icc/common/data-types';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable()
export class ModelService {
    models: IccFilling[] = [];
    models$ = new ReplaySubject<IccFilling[]>();
    sashActiveModels: IccFilling[] = [];
    sashActiveModels$ = new ReplaySubject<IccFilling[]>();
    modelsGroups$ = new ReplaySubject<any[]>();
    loadedData = false;
    modelsGroups = [];
    currentModelGroup = null;

    constructor(
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
        private configurationsService: ConfigurationsService<'door'>,
        private translateService: TranslateService,
        private eventBusService: EventBusService,
        private configuratorsDataService: ConfiguratorsDataService,
        private stepsService: StepsService,
        private fillingsService: FillingsService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration(
            ['initializedConfigurator', 'loadedConfiguratorsData', 'changedSashes', 'loadedFillings', 'validatedProfiles'],
            () => {
                this.init();
            }
        );
    }

    private init() {
        this.models = this.getModels();
        this.sashActiveModels = this.getModels('doorActive');
        this.models$.next(this.models);
        this.sashActiveModels$.next(this.sashActiveModels);
        this.modelsGroups = this.getModelsGroups();
        this.modelsGroups$.next(this.modelsGroups);
        this.loadedData = true;
    }

    /**
     * Zwracanie modelu
     * @return {Object} Model drzwi
     */
    getModels(sashType?: 'doorActive') {
        const conf = this.configurationsService.conf.Current;
        let models = this.fillingsService
            .getMatchingFillingsForDoorSashes(conf, sashType)
            .filter(f => f.type === (conf.System.door_type ? 'door_panels' : 'deco_panels'));

       return this.fillingsService.getUniqueOrderedFillings(models);
    }

    /**
     * Zwracanie grup modeli
     * @return {Object} Grupy modeli
     */
    getModelsGroups() {
        return this.fillingsService.fillingsCategories;
    }

    /**
     * Wybór modelu
     * @param  {Object} model    Model
     * @param  {Boolean} nextStep [description]
     * @param  {Number} price    Cena
     */
    selectModel(model: IccFilling, sashType?) {
        return this.fillingsService.setDecorativePanelInDoorSashes(
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default,
            model,
            sashType
        );
    }
}
