export default function FocusDirective($timeout) {
    'ngInject';

    return {
        scope: {
            trigger: '@focus'
        },
        link: function (scope, element) {
            scope.$watch('trigger', function (value) {
                if (value === 'true') {
                    $timeout(function () {
                        element[0].focus();
                        element[0].select();

                    });
                }
            });
        }

    };
}
