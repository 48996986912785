import angular from 'angular';
import $ from 'jquery';

/**
 * Funkcja filtra dla kategorii akcesoriów
 */
export default function AccessoriesSubcatsFilter() {
    'ngInject';

    return function(cats, parentId) {
        var catsArr = [];
        angular.forEach(cats, function(obj) {
            catsArr.push(obj);
        });
        $('.scroll.ps-container').scroll();
        if (angular.isUndefined(parentId) || parentId === null) {
            return catsArr;
        } else {
            return catsArr.filter(function(gl) {
                return parseInt(gl.parent_id) == parseInt(parentId);
            });
        }
    };
}
