import { ConfiguratorsDataService, ConfigurationsService, EventBusService } from '@icc/common';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';

import { PriceService } from '@icc/price';

import { isUndefined, StepsService } from '@icc/helpers';
import { Injectable } from '@angular/core';
@Injectable()
export class AssemblyService {
    constructor(
        private configurationsService: ConfigurationsService<'awning'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private curConfService: CurrentConfiguratorService,
        private stepsService: StepsService,
        private priceService: PriceService,
        private eventBusService: EventBusService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribeWithoutConfiguration('changedStep', () => {
            this.init();
        });
    }

    mountings = [];
    mountingsQuantity = [];
    mounting = {};
    count = 0;
    loadedData = false;

    init() {
        if (this.curConfService.conf === 'awning') {
            this.setValues();
            if (isUndefined(this.configurationsService.conf.Current.Mounting.id)) {
                this.setDefaultValues();
            }
            if (this.configurationsService.conf.Current.MountingQuantity === 0) {
                this.setDefaultValues();
            }
            this.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Ustawianie wartości
     */
    setValues() {
        this.mountings = this.getMountings();
        this.mountingsQuantity = this.getMountingsQuantity();
    }

    getMountings() {
        let systemID = this.configurationsService.conf.Current.SystemId;
        if (systemID > 0) {
            return this.configuratorsDataService.data.awningLineMountings[systemID];
        } else {
            return [];
        }
    }

    /**
     * Ustawianie ilości montażu
     * @return {Array} Id systemu
     */
    getMountingsQuantity() {
        let systemID = this.configurationsService.conf.Current.SystemId;
        if (systemID > 0) {
            return this.configuratorsDataService.data.awningLineMountingsQuantity[systemID];
        } else {
            return [];
        }
    }

    selectMounting(mounting, nextStep?, price?) {
        this.mounting = mounting;
        this.configurationsService.conf.Current.Mounting = mounting;
        // ConfigurationsService.conf.Default.Mounting = mounting;

        let width = this.configurationsService.conf.Current.Width;
        let i = 0;

        for (i = 0; i < this.mountingsQuantity.length; i++) {
            if (
                this.mountingsQuantity[i].min_width <= width
                && width <= this.mountingsQuantity[i].max_width
            ) {
                this.count = this.mountingsQuantity[i].quantity;
                this.configurationsService.conf.Current.MountingQuantity = this.mountingsQuantity[
                    i
                ].quantity;
                // ConfigurationsService.conf.Default.MountingQuantity = this.mountingsQuantity[i].quantity;
            }
        }

        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
        if (price) {
            this.priceService.count();
        }
    }

    setDefaultValues(reset?) {
        if (isUndefined(this.configurationsService.conf)) {
            return;
        }

        this.setValues();

        if (!Array.isArray(this.mountings) || isUndefined(this.mountings[0])) {
            return;
        }

        if (this.configurationsService.conf.Current.MountingQuantity === 0 || reset) {
            this.selectMounting(this.mountings[0]);
        }
    }
}
