import { Component, OnInit } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';

@Component({
  selector: 'icc-mounting-method-page',
  templateUrl: './mounting-method-page.component.html',
  styleUrls: ['./mounting-method-page.component.scss']
})
export class MountingMethodPageComponent extends PageComponent implements OnInit {
  public title: string = _('WINDOW|Wybierz sposób montażu');
  public options = [];

  constructor() {
    super()
  }

  ngOnInit() {
  }

}
