import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';
import { FormGroup, FormControl } from '@angular/forms';
import { MeasurementsService } from '@icc/legacy/configurator/steps/window/dimensions/measurements.service';


@Component({
    selector: 'icc-dimensions-additional-data-page',
    templateUrl: './dimensions-additional-data-page.component.html',
    styleUrls: ['./dimensions-additional-data-page.component.scss'],
})
export class DimensionsAdditionalDataPageComponent extends PageComponent implements OnInit,OnDestroy {
    public title: string = _('WINDOW|Zdjęcia i notatki');

    currentMeasurement: number;

    notesForm = new FormControl('');
    filesForm = new FormControl('');
    attachments: any[] = [];

    constructor(
        @Inject(ICC_PAGE_DATA) private pageData: any,
        private measurementsService: MeasurementsService
    ) {
        super();
        this.currentMeasurement = this.pageData.currentMeasurement;
        this.loadAttachments();
    }

    addFiles($event) {
        this.measurementsService.addFiles($event, this.currentMeasurement).then(() => {
            this.loadAttachments();
        });
    }

    downloadFile(file: any) {
        this.measurementsService.downloadFile(file);
    }

    removeFile(index: number) {
        this.measurementsService.removeFile(index, this.currentMeasurement);
    }

    ngOnInit() {
        this.notesForm.setValue(this.measurementsService.measurements[this.currentMeasurement].notes);
    }

    ngOnDestroy() {
        this.measurementsService.measurements[this.currentMeasurement].notes = this.notesForm.value;
    }

    loadAttachments() {
        this.attachments = this.measurementsService.measurements[this.currentMeasurement]?.attachments || [];
    }
}
