const ConfiguratorPageModalTemplate = require('ngtemplate-loader!./configurator-page-modal.component.html');
import ConfiguratorPageModalController from './configurator-page-modal.controller';

export const ConfiguratorPageModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: ConfiguratorPageModalTemplate,
  controller: ConfiguratorPageModalController
};
