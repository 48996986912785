import { WeightunitPipe } from './weightunit.pipe';
import { MaterialModule } from 'libs/configurator/ui/src/lib/material/material.module';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedFacade } from './+state/shared.facade';
import { ConfiguratorFacade } from './+state/configurator.facade';
import { IssuesService } from './issues/issues.service';
import { IssuesService as AbstractIssuesService, CurrencyPipe } from '@icc/helpers';
import { ModalService } from '@icc/helpers';
import { ConfiguratorModalService } from './steps/modal.service';
import { DrawService } from '@icc/common/configurators/draw.service';
import { IccDrawModule } from '@icc/draw';
import { DrawResizeDirective } from './draw-resize/draw-resize.directive';
import { UnitmmPipe } from './unitmm.pipe';
import { UnitmPipe } from './unitm.pipe';
import { Unitm2Pipe } from './unitm2.pipe';
import { Unit2dPipe } from './unit2d.pipe';
import { FormatUnitPipe } from './format-unit.pipe';
import { HotkeyModule } from 'angular2-hotkeys';
import { PriceDetailsComponent } from './price-details/price-details.component';
import { CommonModule } from '@angular/common';
import { ProductFeaturesComponent } from './product-features/product-features.component';
import { DrawRefreshDirective } from './draw-resize/draw-refresh.directive';
import { DigitsOnlyDirective } from './directives/digits-only.directive';
import { FormsModule } from '@angular/forms';
@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        IccDrawModule,
        HotkeyModule,
        MaterialModule,
        FormsModule,
    ],
    exports: [
        TranslateModule,
        IccDrawModule,
        DrawResizeDirective,
        DrawRefreshDirective,
        CurrencyPipe,
        UnitmmPipe,
        UnitmPipe,
        Unitm2Pipe,
        Unit2dPipe,
        FormatUnitPipe,
        WeightunitPipe,
        ProductFeaturesComponent,
        DigitsOnlyDirective,
        FormsModule,
    ],
    declarations: [
        DrawResizeDirective,
        DrawRefreshDirective,
        CurrencyPipe,
        UnitmmPipe,
        UnitmPipe,
        Unitm2Pipe,
        Unit2dPipe,
        FormatUnitPipe,
        WeightunitPipe,
        PriceDetailsComponent,
        ProductFeaturesComponent,
        DigitsOnlyDirective,
    ],
    entryComponents: [
        PriceDetailsComponent,
        ProductFeaturesComponent,
    ],
    providers: [
        DrawService,
        SharedFacade,
        ConfiguratorFacade,
        IssuesService,
        { provide: AbstractIssuesService, useExisting: IssuesService },
        { provide: ModalService, useClass: ConfiguratorModalService },
    ],
})
export class SharedModule {}
