import * as angular from 'angular';

import ColorsCtrl from './ColorsCtrl';
import { ColorsService } from './colors.service';
import DimensionsCtrl from './DimensionsCtrl';
import { DimensionsService } from './dimensions.service';
import ModalColorCtrl from './ModalColorCtrl';
import { ModalMosquitoCtrl } from './ModalMosquitoCtrl';
import MontagesCtrl from './MontagesCtrl';
import { MontagesService } from './montages.service';
import SystemsCtrl from './SystemsCtrl';
import { SystemsService } from './systems.service';
import TypesCtrl from './TypesCtrl';
import { TypesService } from './types.service';
import { downgradeInjectable } from '@angular/upgrade/static';

export default angular.module('icc.configurator.steps.mosquito', [])
    .controller('ModalMosquitoCtrl', ModalMosquitoCtrl)
    .controller('ModalMosquitoColorCtrl', ModalColorCtrl)
    .controller('MosquitoColorsCtrl', ColorsCtrl)
    .controller('MosquitoDimensionsCtrl', DimensionsCtrl)
    .controller('MosquitoMontagesCtrl', MontagesCtrl)
    .controller('MosquitoSystemsCtrl', SystemsCtrl)
    .controller('MosquitoTypesCtrl', TypesCtrl)
    .factory('MosquitoColorsService', downgradeInjectable(ColorsService))
    .factory('MosquitoDimensionsService', downgradeInjectable(DimensionsService))
    .factory('MosquitoMontagesService', downgradeInjectable(MontagesService))
    .factory('MosquitoSystemsService', downgradeInjectable(SystemsService))
    .factory('MosquitoTypesService', downgradeInjectable(TypesService))
    .name;
