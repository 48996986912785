import { Reinforcement } from '@icc/window';

export default class ReinforcementsModalController {
    resolve: {
        reinforcements: Reinforcement[];
        selectedReinforcement: Reinforcement;
        currency;
    };
    close: ({ $value: Reinforcement }) => any;
    dismiss: () => any;

    constructor(
        private ScrollbarService,
    ) {}

    $onChanges(changes) {
        this.ScrollbarService.update();
    }

    selectReinforcement(reinforcement) {
        this.close({ $value: reinforcement });
    }

    closeModal() {
        this.close({ $value: false });
    }

    refresh() {
        this.ScrollbarService.update();
    }
}
ReinforcementsModalController.$inject = [
    'ScrollbarService',
];