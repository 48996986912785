import { Injectable } from '@angular/core';
import {TimeLimit, TimeLimitSegment} from './time-limit-functions';

@Injectable()
export class TimeLimitFillingService {

    /**
     * Pobiera terminy realizacji szyb.
     *
     * @param {object} sashes   Kwatery
     * @return {number} Terminy realizacji szyb.
     */
    @TimeLimit({
        shortName: 'filling',
        data: {
            sashes : 'conf.Sashes',
        }
    })
    fillingTimeLimit({timeLimits, sashes}): TimeLimitSegment[] {
        if (sashes == null || !sashes.length) {
            return null;
        }

        const segments = [];

        sashes.forEach(sash => {
            if (sash.oneGlazing) {
                segments.push(this.getTimeLimitFromFilling(timeLimits, sash));
            } else if (sash.intSashes && sash.intSashes.length) {
                sash.intSashes.forEach(intSash => segments.push(this.getTimeLimitFromFilling(timeLimits, intSash)));
            }
        });

        return segments;
    }

    getTimeLimitFromFilling(timeLimits, sash) {
        if (!sash.glazing || !sash.glazing.time_limit_id) {
            return;
        }

        const timeLimitId = sash.glazing.time_limit_id;

        return {
            type: 'filling',
            value: timeLimits[timeLimitId],
            data: {
                id  : sash.glazing.id,
                name: sash.glazing.name,
            }
        };
    }
}
