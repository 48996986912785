import { ModalInfoComponent } from './../modal-info/modal-info.component';
import { Component, OnInit, Inject } from '@angular/core';
import { _, StepComponent, SharedFacade, ICC_PAGE_DATA, ModalService } from '@icc/configurator/shared';
import { iccListItem, iccListTab } from '@icc/configurator/ui';

@Component({
    selector: 'icc-modal-list',
    templateUrl: './modal-list.component.html',
    styleUrls: ['./modal-list.component.scss'],
})
export class ModalListComponent extends StepComponent implements OnInit {
    public configurator = 'garage_door';
    public stepId = 'modalList';

    public options = [];
    public title = 'a';
    item: any[] = [];
    items: iccListItem[] = [];
    itemGroups: [];
    itemTemplate = 'itemColor';
    selectedItemId;
    modalListFilesDir: string;
    tabGroups: any[] = [];
    itemImgAttributeName: string;
    showItemInfo = false;
    searchable = false;

    constructor(
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            items: any[];
            selectedItemId: any;
            modalListTitle: string;
            modalListFilesDir: string;
            tabGroups?: any[];
            itemImgAttributeName?: string;
            showItemInfo?: boolean;
            itemTemplate?: string;
            searchable?: boolean;
        }
    ) {
        super();
        this.title = pageData.modalListTitle;
    }

    ngOnInit() {
        this.itemTemplate = this.pageData.itemTemplate ? this.pageData.itemTemplate : 'itemColor';
        this.itemImgAttributeName = this.pageData.itemImgAttributeName ? this.pageData.itemImgAttributeName : 'color_img';
        this.selectedItemId = Number(this.pageData.selectedItemId);
        this.modalListFilesDir = this.pageData.modalListFilesDir;
        this.showItemInfo = this.pageData.showItemInfo ? this.pageData.showItemInfo : false;
        this.searchable = this.pageData.searchable;

        if (this.pageData.tabGroups) {
            this.tabGroups = this.pageData.tabGroups.map<iccListTab>(group => ({
                id: Number(group.id),
                name: group.name,
            }));
        }
        this.items = this.pageData.items.map<iccListItem>(item => ({
            id: Number(item.id),
            title: item.name,
            description: item.code,
            color: '#' + item.color,
            imageUrl: this.modalListFilesDir + item[this.itemImgAttributeName],
            tabs: (item.groups && item.groups.map(Number)) || [],
            showInfo: item.content && this.showItemInfo,
            content: item.content
        }));
    }

    close() {
        this.sharedFacade.closePage();
    }

    selectItem(item: iccListItem) {
        const foundItem = this.pageData.items.find(i => Number(item.id) === Number(i.id));
        this.sharedFacade.closePage(foundItem);
    }

    showInfo(item) {
        if (item.content) {
            this.modalService
            .open({
                pageComponent: ModalInfoComponent,
                resolve: {
                    item,
                    button: true,
                },
            })
            .result.then(selected => {
                if (selected) {
                    this.selectItem(item);
                }
            });
        }
      }
}
