<icc-select-box
    [title]="'GARAGEDOOR|Układ przetłoczeń' | translate"
    [imageUrl]="'/files/garagedoorpanel/' + (selectedPanel$ | async)?.image"
    [description]="(selectedPanel$ | async)?.name"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="selectPanel()"
></icc-select-box>

<icc-select-box
    [title]="'GARAGEDOOR|Zewnętrzna Struktura powierzchni' | translate"
    [imageUrl]="'/files/garagedoorpanelstructure/' + (selectedPanelStructure$ | async)?.img"
    [description]="(selectedPanelStructure$ | async)?.name"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [colorSelect]="true"
    (select)="selectPanelStructure()"
></icc-select-box>

<icc-select-box
    *ngIf="!conf.Configurators.garage_door.onlyDefaultInnerPanelStructure"
    [title]="'GARAGEDOOR|Wewnętrzna Struktura powierzchni' | translate"
    [imageUrl]="'/files/garagedoorpanelstructure/' + (selectedPanelInternalStructure$ | async)?.img"
    [description]="(selectedPanelInternalStructure$ | async)?.name"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [colorSelect]="true"
    (select)="selectPanelInternalStructure()"
></icc-select-box>

<icc-select-box
    [title]="'COLOR|Kolor zewnętrzny' | translate"
    [description]="(selectedPanelColor$ | async)?.name"
    [color]="'#' + (selectedPanelColor$ | async)?.color"
    [imageUrl]="'/files/garagedoorcolor/' + (selectedPanelColor$ | async)?.color_img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [colorSelect]="true"
    (select)="selectPanelColor()"
></icc-select-box>


