import { Injectable } from '@angular/core';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { ConfigurationsService } from '@icc/common';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';

@Injectable({
    providedIn: 'root',
})
export class LippingColorsService {
    constructor(
        private configurationsService: ConfigurationsService<'window'>,
        private configuratorsDataService: ConfiguratorsDataService
    ) {}

    isOnlyDoubleSidePriceLevelAvailable(
        conf = this.configurationsService.conf.Current as DoorActiveConfiguration
    ) {
        if (
            !(
                Array.isArray(this.configuratorsDataService.data?.priceLevels) &&
                this.configuratorsDataService.data?.priceLevels.length
            )
        ) {
            return false;
        }
        const lippingPriceLevel = this.configuratorsDataService.data.priceLevels.find(
            (p) => p.id === Number(conf.System.lipping_price_level_id)
        );

        if (!lippingPriceLevel) {
            return false;
        }

        const isOnlyDoubleSidePriceLevel = lippingPriceLevel.price_levels.every(
            (priceLevel: { side: string }) => priceLevel.side === 'double'
        );

        return isOnlyDoubleSidePriceLevel;
    }
}
