/* eslint-disable max-statements */
import {logger, core} from '@icc/common/helpers';
import {IssueLevel} from '@icc/common/issues.service';
import { isUndefined, isArray, isObject, isDefined, IssuesService, ModalService } from '@icc/helpers';
import { Injectable, Inject } from '@angular/core';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { PriceService } from '@icc/price';
import { EventBusService } from '@icc/common/event-bus.service';
import { MuntinsLayoutService } from './muntins-layout.service';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { ValidationService } from '@icc/common/configurators/validation.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';
import { MuntinsLayoutEditPageComponent } from 'libs/configurator/window/src/lib/muntins-layout-edit-page/muntins-layout-edit-page.component';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { TranslateService } from '@icc/common';

@Injectable()
export class MuntinsService {
    loadedData = false;
    muntinsTypes = [];
    colorRals = []
    barColors = [];
    activeMoveTools = false;
    barPosition = {
        left      : 0,
        right     : 0,
        mmLeft    : 0,
        mmRight   : 0,
        showInputs: false
    };

    private configurators   = ['window', 'hs', 'door', 'folding_door', 'sliding_door'];
    private allMuntinsTypes = [];

    constructor (
        private configuratorsDataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService<'window'>,
        private currentConfiguratorService: CurrentConfiguratorService,
        private priceService: PriceService,
        private eventBusService: EventBusService,
        private muntinsLayoutService: MuntinsLayoutService,
        private parametersService: ParametersService,
        private validationService: ValidationService,
        private issuesService: IssuesService,
        private modalsService: ModalService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private translateService: TranslateService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration(['initializedConfigurator'], () => this.init());

        this.eventBusService.subscribe('setSystem', data => {
            this.checkMuntinsStatus(data.activeConfiguration as WindowActiveConfiguration);
        });

        this.eventBusService.subscribe('setGlazingInSash', () => {
            try {
                if (!this.isDuplexAvailable(
                    this.configurationsService.conf.Current.Sashes,
                    this.configurationsService.conf.Current.MuntinsData.type
                    && this.configurationsService.conf.Current.MuntinsData.type.duplex_availability
                )) {
                    this.configurationsService.conf.Current.MuntinsData.duplex = false;
                }
                this.removeIfNotAvailable(this.configurationsService.conf.Current);
            } catch (err) {
                logger.error(err);
            }
        });
    }

    /**
     * Funkcja inicjalizujaca
     */
    private init() {
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            return;
        }

        this.allMuntinsTypes      = this.configuratorsDataService.data.windowBarTypes || [];
        this.findMuntinsTypesBySystem();
        this.changeType(this.configurationsService.conf.Current);
        this.loadedData   = true;
    }

    /**
     * Funkcja sprawdzajaca czy aktualnie znalezione szprosy pasuja do systemu,
     * a nastepnie wyswietla stosowne komunikaty w przypadku zmiany statusu
     */
    checkMuntinsStatus(conf = this.configurationsService.conf.Current) {
        if (!(conf.System && conf.System.id) && !conf.System.door_type) return;
        this.findMuntinsTypesBySystem(conf);
        Object.assign(conf, this.validationService.valid(conf, 'muntins'));
        this.issuesService.unregister('invalid-muntins', conf);
        if (!conf.MuntinsData?.type || !this.muntinsTypes.find(el => el.id == conf.MuntinsData?.type.id)) {
            this.changeType(conf);
            if (conf.HasMuntins) {
                this.issuesService.simpleRegister('invalid-muntins', 'Typ szprosów został zmieniony', this.translateService.instant('WINDOW|Typ szprosów został zmieniony'), conf, {
                    level: IssueLevel.INFO,
                    blockStepsAfter: null,
                    blockAddToOffer: false,
                    noPrice: false,
                });
            }
        }
    }

    /**
     * Funkcja szukania typów szprosów
     */
    findMuntinsTypesBySystem(conf = this.configurationsService.conf.Current) {
        if (isUndefined(conf)) {
            return;
        }
        core.clear(this.muntinsTypes);
        for (let i = 0; i < this.allMuntinsTypes.length; i++) {
            const systemTypes = this.allMuntinsTypes[i].system_types;
            const windowLines = this.allMuntinsTypes[i].windowLines;

            if (isArray(systemTypes) && isArray(windowLines)) {
                if (isObject(conf.System)) {
                    if (systemTypes.indexOf(conf.System.type) > -1) {
                        if (windowLines.length) {
                            if (!(windowLines.indexOf(conf.System.id) > -1)) {
                                continue;
                            }
                        }

                        this.muntinsTypes.push(this.allMuntinsTypes[i]);
                    }
                } else {
                    if (systemTypes.indexOf('pvc') > -1) {
                        this.muntinsTypes.push(this.allMuntinsTypes[i]);
                    }
                }
            }

        }
    }

    /**
     * Funkcja szprosów
     * @param  {object}  conf Konfiguracja
     */
    hasMuntins(conf) {
        conf.HasMuntins = false;
        for (let i = 0; i < conf.Sashes.length; i++) {
            for (let j = conf.Sashes[i].intSashes.length - 1; j >= 0; j--) {
                const intSash = conf.Sashes[i].intSashes[j];
                if (intSash.muntins.length > 0) {
                    conf.HasMuntins = true;
                    break;
                }
            }
            if (conf.Sashes[i].intSashes.length === 0 && conf.Sashes[i].muntins.length > 0) {
                conf.HasMuntins = true;
                break;
            }
        }
        conf.MuntinsData.duplex = this.setDuplex(conf);
        return conf.HasMuntins;
    }

    /**
     * Funkcja zmiana typu
     * @param  {object} conf konfiguracja
     */
    changeType(conf) {
        if (isUndefined(conf) || conf.type == 'roller_shutter' || conf.type == 'external_blind') {
            return;
        }
        if (isDefined(conf.MuntinsData?.typeCode)) {
            conf.MuntinsData.type = this.muntinsTypes.filter(function(el) {
                return el.id == conf.MuntinsData.typeCode;
            })[0];

        }
        
        if (this.muntinsTypes.length && conf.MuntinsData.type === undefined) {
            conf.MuntinsData.type = this.muntinsTypes[0];
            conf.MuntinsData.typeCode = parseInt(conf.MuntinsData?.type?.id);
        } 

        if (isObject(conf.MuntinsData?.type) && conf.MuntinsData?.type.id) {
            const barWidths = core.objToArray(conf.MuntinsData.type.sizes) || [];
            const avBarWidths = barWidths.filter(function(el) {
                return parseInt(conf.MuntinsData.rWidth) === parseInt(el.size);
            });
            if (avBarWidths.length === 0) {
                if (barWidths[0]) {
                    conf.MuntinsData.rWidth = ~~barWidths[0].size;
                    conf.MuntinsData.sizeId = ~~barWidths[0].id;
                } else {
                    conf.MuntinsData.rWidth = 0;
                    conf.MuntinsData.sizeId = 0;
                }
            } else {
                conf.MuntinsData.rWidth = ~~avBarWidths[0].size;
                conf.MuntinsData.sizeId = ~~avBarWidths[0].id;
            }

            conf.MuntinsData.duplex = this.setDuplex(conf);

            this.eventBusService.post({key: 'setMuntinType', value: {}, conf});

            this.changeWidth(conf);
        }

        this.removeIfNotAvailable(conf);
        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
            conf
        });

        this.parametersService.count(conf);
        this.priceService.count();
    }

    /**
     * Funkcja zmiany szerokości
     * @param  {object} conf Konfiguracja
     */
    changeWidth(conf) {
        if (isDefined(conf.MuntinsData) && isObject(conf.MuntinsData.type)) {
            const size = core.fIdO<any>(conf.MuntinsData.type.sizes, conf.MuntinsData.sizeId);

            if (isObject(size)) {
                conf.MuntinsData.rWidth = ~~size.size;
                conf.MuntinsData.sizeId = ~~size.id;
            }
            conf.MuntinsData.width = parseInt(conf.MuntinsData.rWidth);

            this.priceService.count();
        }
        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
            conf
        });
    }

    /**
     * Sprawdza czy dla wybranych pakietów szybowych jest dostępny duplex wynikający z ograniczeń
     * @param {object} [sashes]     Lista skrzydeł
     * @param {number} [frameWidth] Minimalna szerokość ramki szybowej
     * @returns {boolean}
     */
    isDuplexAvailable(sashes: ActiveSash[] = [], frameWidth = 0) {
        return sashes.map(s => s.intSashes.length < 1
                        ? s.muntins.length ? [~~s.glazing.frame_width] : []
                        : s.intSashes.map(i => i.muntins.length ? ~~i.glazing.frame_width : null))
                     .reduce((prev, e) => prev.concat(e), [])
                     .every(e => !e || e >= ~~frameWidth);
    }

    /**
     * Ustawia duplex dla szprosów o ile jest dostępny
     * @param {any} conf
     * @returns
     */
    setDuplex(conf) {
        if (isObject(conf.MuntinsData?.type)) {
            return this.isDuplexAvailable(conf.Sashes, conf.MuntinsData.type.duplex_availability)
                    && (~~conf.MuntinsData.type.duplex === 1 || ~~conf.MuntinsData.type.duplex === 0 && conf.MuntinsData.duplex);
        }
        return false;
    }

    /**
     * Usuwa szprosy jeżeli nie są dostępne w danym układzie.
     * Kwatery zawierające panele wypełnieniowe nie mogą mieć szprosów wewnętrznych.
     * Szprosy naklejane są możliwe, ale tylko bez dupleksu.
     * W przypadku drzwi szprosy nie są dostępne.
     * @param  {object} conf Konfiguracja.
     * @return {void}
     */
    removeIfNotAvailable(conf = this.configurationsService.conf.Current) {
        if (!conf.MuntinsData || !conf.Sashes) return;
        
        if (!conf.MuntinsData.type) {
            conf.MuntinsData.color = null;
            conf.MuntinsData.colorOut = null;
            this.muntinsLayoutService.removeMuntinsInAllSashes(conf);
            return;
        }

        conf.Sashes
            .reduce((sashes, sash) => sashes.concat([sash], sash.intSashes), [])
            .map(sash => {
                sash.canHaveDuplex = !(conf.MuntinsData.type.type === 'sticked' && sash.glazing.type === 'pvc_panels');
                sash.muntins = this.muntinsLayoutService.isValidSash(conf, sash.id) ? sash.muntins : [];
            });
    }

    /**
     * Sprawdza czy skrzydła mają panele wypełnieniowe.
     * @param  {Object}  conf Konfiguracja.
     * @return {Boolean}      Czy wszystkie skrzydła mają panele wypełnieniowe?
     */
    hasPVCPanelsOnAllSashes(conf = this.configurationsService.conf.Current) {
        if (!conf.Sashes) {
            return true;
        }

        return conf.Sashes
            .reduce((sashes, sash) => sashes.concat([sash], sash.intSashes), [])
            .every(sash => ['pvc_panels', 'deco_panels', 'door_panels'].includes(sash.glazing.type));
    }


    openModalMuntinsManualLayout(conf: WindowActiveConfiguration) {
        const modalInstance = this.modalsService.open({
            templateUrl: 'modalMuntinsManualLayout.html',
            controller: 'ModalMuntinsManualLayoutCtrl as mmuntins',
            pageComponent: MuntinsLayoutEditPageComponent,
            windowClass: 'modal-bar',
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'glazingSashes',
                });
            }
        });

        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'muntinManualLayout',
            });
        }
    }

}
