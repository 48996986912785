import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ModalService, ICC_PAGE_DATA } from '@icc/helpers';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { SharedFacade, _ } from '@icc/configurator/shared';
import { TranslateService } from '@icc/common';
import { IccConstructColor } from '@icc/common/data-types';
import { SillOptionsPageComponent } from '../sill-options-page/sill-options-page.component';
import { CassonettoOptionsPageComponent } from '../cassonetto-options-page/cassonetto-options-page.component';

@Component({
    selector: 'icc-cassonettos-list-page',
    templateUrl: './cassonettos-list-page.component.html',
    styleUrls: ['./cassonettos-list-page.component.scss'],
})
export class CassonettosListPageComponent extends PageComponent implements OnInit {
    public title = _('GOODS|Kasonetki');
    public options = [];

    accessories: iccListItem[] = [];
    tabs: iccListTab[] = [];

    constructor(
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        private translateService: TranslateService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            cassonettos: any[];
            categories: any[];
            groups: any[];
            colors?: IccConstructColor[];
            productPricelists: any;
            noPrice: boolean;
        }
    ) {
        super();
    }

    ngOnInit() {
        this.accessories = this.pageData.cassonettos.map<iccListItem>(accessory => ({
            id: Number(accessory.id),
            title: accessory.name,
            imageUrl: `/files/cassonetto/${accessory.image}`,
            description: `${accessory.description}`,
            tabs: [Number(accessory.cassonetto_group_id)],
        }));
        this.tabs = this.pageData.groups.map<iccListTab>(group => ({
            id: Number(group.id),
            name: group.name,
        }));
    }

    selectAccessory(item: iccListItem) {
        const accessory = this.pageData.cassonettos.find(a => Number(a.id) === Number(item.id));
        if (accessory) {
            this.modalService
                .open({
                    pageComponent: CassonettoOptionsPageComponent,
                    resolve: {
                        accessory,
                        colors: this.pageData.colors,
                        productPricelists: this.pageData.productPricelists,
                    },
                })
                .result.then(result => {
                    this.sharedFacade.closePage(result);
                });
        }
    }
}
