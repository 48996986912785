import { Component, OnInit, Inject } from '@angular/core';
import { _, StepComponent, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { iccListTab, iccListItem } from '@icc/configurator/ui';
import { EventBusService, Common } from '@icc/common';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { IccSystem } from '@icc/common/data-types';
import { SystemsService } from '@icc/legacy/configurator/steps/window/systems/systems.service';

@Component({
    selector: 'icc-awning-systems-info',
    templateUrl: './awning-systems-info.component.html',
    styleUrls: ['./awning-systems-info.component.scss'],
})
export class AwningSystemsInfoComponent extends StepComponent implements OnInit {
    public configurator = 'window';
    public stepId = 'systemInfo';
    public title = _('COLOR|Informacja na temat produktu');
    public options = [];

    system: any = null;

    constructor(
        private eventBusService: EventBusService,
        private systemsService: SystemsService,
        private sharedFacade: SharedFacade,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        @Inject(ICC_PAGE_DATA) private pageData: {
            system: any
        }
    ) {
        super();
    }

    ngOnInit() {
        this.system = this.pageData.system;
    }

    close() {
        this.sharedFacade.closePage(true);
    }
}
