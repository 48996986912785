/**
 * Kontroler napędu markiz
 * @param {object} $scope                   Scope
 * @param {object} AwningDriveService      Fabryka napędu markiz
 */
export default function DriveCtrl($scope, AwningDriveService, EventBusService) {
    'ngInject';

    var vm = this;

    vm.subStep = 6;
    vm.driveSide = AwningDriveService.driveSide;
    vm.driveType = AwningDriveService.driveType;
    vm.crank = AwningDriveService.crank;
    vm.crankId = AwningDriveService.crankId;
    vm.motor = AwningDriveService.motor;
    vm.motorId = AwningDriveService.motorId;
    vm.cranks = AwningDriveService.cranks;
    vm.motors = AwningDriveService.motors;
    vm.motorOptions = AwningDriveService.motorOptions;
    vm.options = AwningDriveService.options;
    vm.allDrive = true;

    vm.selectSide = selectSide;
    vm.selectType = selectType;
    vm.selectCrank = selectCrank;
    vm.selectMotor = selectMotor;
    vm.selectOption = selectOption;

    if (AwningDriveService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    function init() {
        vm.driveSide = AwningDriveService.driveSide;
        vm.driveType = AwningDriveService.driveType;
        vm.cranks = AwningDriveService.cranks;
        vm.motors = AwningDriveService.motors;
        vm.crankId = AwningDriveService.crankId;
        vm.motorId = AwningDriveService.motorId;
        vm.crank = AwningDriveService.crank;
        vm.motor = AwningDriveService.motor;
        vm.motorOptions = AwningDriveService.motorOptions;
    }

    /**
     * Wybór strony
     * @param  {Object} side Strony
     */
    function selectSide(side) {
        vm.driveSide = side;
        AwningDriveService.selectSide(side);
    }

    /**
     * Wybór typu
     * @param  {Object} type Typ
     */
    function selectType(type) {
        vm.driveType = type;
        AwningDriveService.selectType(type, false, true);
    }

    /**
     * Wybór korby
     * @param  {Object} crank Korba
     */
    function selectCrank(crank) {
        vm.crank = crank;
        vm.crankId = crank.id;
        AwningDriveService.selectCrank(crank, false, true);
    }

    /**
     * Wybór silnika
     * @param  {Object} motor Silnik
     */
    function selectMotor(motor) {
        vm.motor = motor;
        vm.motorId = motor.id;
        vm.motorOptions = motor.options;

        vm.options = AwningDriveService.clearOptions(vm.options, true);

        AwningDriveService.selectMotor(motor, false, true);
    }

    /**
     * Wybór opcji
     * @param  {Object} option Opcja
     */
    function selectOption(option) {
        vm.options = AwningDriveService.selectOption(vm.options, option, true);
    }
}
