<i *ngIf="synced; else notSynced" [title]="infoSynced">
    <svg version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="24px" height="18px" viewBox="13 16 24 18" enable-background="new 13 16 24 18" xml:space="preserve">
        <path fill="currentColor" d="M33.515,22.428c-0.242-3.401-3.087-6.094-6.55-6.094c-2.138,0-4.092,1.014-5.325,2.736
            c-2.006,0.269-3.784,1.457-4.802,3.212c-2.12,0.758-3.559,3.513-3.559,5.741c0,2.764,2.203,5.977,5.043,5.977h13.356
            c2.84,0,5.043-3.213,5.043-5.977C36.721,25.925,35.438,23.344,33.515,22.428z M31.678,32H18.322c-1.61,0-3.043-2.224-3.043-3.977
            c0-1.542,1.125-3.651,2.438-3.916L18.2,24.01l0.217-0.443c0.727-1.483,2.201-2.46,3.847-2.549l0.528-0.028l0.274-0.452
            c0.836-1.38,2.293-2.205,3.898-2.205c2.518,0,4.566,2.047,4.562,4.637l-0.05,0.967l0.834,0.177c1.298,0.275,2.41,2.38,2.41,3.909
            C34.721,29.776,33.288,32,31.678,32z M28.913,22.382c-0.027-0.025-0.069-0.039-0.108-0.037c-0.038,0.002-0.079,0.022-0.104,0.049
            l-4.633,5.25l-2.564-2.274c-0.027-0.024-0.07-0.039-0.108-0.036c-0.039,0.002-0.079,0.022-0.104,0.049l-0.889,1.006
            c-0.024,0.028-0.039,0.07-0.036,0.109c0.002,0.039,0.021,0.08,0.049,0.104l3.684,3.255c0.027,0.024,0.07,0.039,0.109,0.036
            c0.038-0.002,0.078-0.021,0.104-0.049l5.621-6.361c0.023-0.028,0.037-0.07,0.035-0.109s-0.021-0.079-0.049-0.104L28.913,22.382z"/>
    </svg>
</i>
<ng-template #notSynced>
    <i [title]="infoNotSynced">
        <svg version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="24px" height="18px" viewBox="13 16 24 18" enable-background="new 13 16 24 18" xml:space="preserve">
            <path fill="currentColor" d="M33.515,22.428c-0.242-3.401-3.087-6.094-6.55-6.094c-2.138,0-4.092,1.014-5.325,2.736
                c-2.006,0.269-3.784,1.457-4.802,3.212c-2.12,0.758-3.559,3.513-3.559,5.741c0,2.764,2.203,5.977,5.043,5.977h13.356
                c2.84,0,5.043-3.213,5.043-5.977C36.721,25.925,35.438,23.344,33.515,22.428z M31.678,32H18.322c-1.61,0-3.043-2.224-3.043-3.977
                c0-1.542,1.125-3.651,2.438-3.916L18.2,24.01l0.217-0.443c0.727-1.483,2.201-2.46,3.847-2.549l0.528-0.028l0.274-0.452
                c0.836-1.38,2.293-2.205,3.898-2.205c2.518,0,4.566,2.047,4.562,4.637l-0.05,0.967l0.834,0.177c1.298,0.275,2.41,2.38,2.41,3.909
                C34.721,29.776,33.288,32,31.678,32z M28.051,22.627l-2.561,2.561l-2.561-2.561l-1.291,1.29l2.561,2.562l-2.561,2.561l1.291,1.29
                l2.561-2.561l2.561,2.561l1.292-1.29l-2.561-2.561l2.561-2.562L28.051,22.627z"/>
        </svg>
    </i>
</ng-template>
