<icc-select-box
    [title]="selectedHinge?.name"
    [description]="selectedHinge?.description"
    [imageUrl]="selectedHingeImg"
></icc-select-box>

<mat-card class="mat-elevation-2">
    <div>
        <p>{{ 'COLOR|Wybierz kolor:' | translate }}</p>
        <icc-list [items]="colors" itemTemplate="itemColor" [scrollable]="false" [selected]="selHingeColor" (select)="selectHingeColor($event)">
        </icc-list>
    </div>
</mat-card>

<button mat-raised-button color="primary" (click)="save()"
        >{{ 'INTERFACE|OK' | translate }}</button>
