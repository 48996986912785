<icc-select-box
    *ngIf="(selectedConfiguratorType$ | async) === 'external_blind' && isJoinedTypesAndSystemsStep"
    [title]="'ROLLERSHUTTER|Typ pancerza:' | translate"
    [description]="(profile$ | async)?.name"
    [imageUrl]="'/files/profile/' + ((profile$ | async)?.img || '')"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="changeProfile()"
></icc-select-box>

<icc-list
    *ngIf='isJoinedTypesAndSystemsStep'
    [items]="systems"
    itemTemplate="itemImgTitle"
    itemImgHeight="medium"
    (select)="selectSystem($event)"
    [selected]="(selectedSystem$ | async).id"
    (showInfo)="showInfo($event)"
></icc-list>

<icc-list
    *ngIf='!isJoinedTypesAndSystemsStep'
    [items]="types"
    itemTemplate="itemImgTitle"
    itemImgHeight="medium"
    (select)="selectType($event)"
    [selected]="selectedType$ | async"
    (showInfo)="showInfo($event)"
></icc-list> 
