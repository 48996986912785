<mat-card>
    <div class="card-header-container">
        <img class="accessory-box-img"
            [src]="'/files/profile/' + profile?.img"
            [alt]="profile?.name"
            defaultImg>
        <mat-card-header>
            <mat-card-title class="accessory-box-title">{{profile?.name}}
              <button mat-icon-button color="secondary" *ngIf="!isWysiwygEditorContentEmpty" (click)="clickShowInfoButton()" color="secondary">
                <mat-icon class="mat-18">info</mat-icon>
              </button>
            </mat-card-title>
            <mat-card-subtitle class="accessory-box-description"></mat-card-subtitle>
        </mat-card-header>
    </div>
  <mat-card-content>
      <form [formGroup]="form">
          <div *ngIf="profile?.type === 'extension' || profile?.type === 'sandwich' || profile?.type === 'coupling' && showColorsWhenCoupling || showColorsInComplementary">
                <span *ngIf="profile?.selectedWood?.name">{{ 'WINDOW|Rodzaj drewna' | translate }}:
                    <b
                    >{{ profile?.selectedWood?.name }}</b>
                    <br />
                </span>
                <span *ngIf="(profile?.selectedColor?.outer?.id === profile?.selectedColor?.inner?.id && profile?.selectedColor?.outer?.name)">
                    {{ 'COLOR|Kolor' | translate }}: <b>{{ profile?.selectedColor?.outer?.name }} <ng-container *ngIf="isWindowColor && !isDoor()">({{ 'COLOR|Kolor konstrukcji' | translate }})</ng-container></b>
                    <br />
                </span>
                <ng-container *ngIf="profile?.selectedColor?.outer?.id !== profile?.selectedColor?.inner?.id">
                    <span *ngIf="profile?.selectedColor?.outer?.name">
                        {{ 'COLOR|Kolor zewn.' | translate }}:
                        <b *ngIf="profile?.selectedColor?.outer?.name">
                            {{ profile?.selectedColor?.outer?.name }}
                            <ng-container *ngIf="isWindowColor && !isDoor()">({{ 'COLOR|Kolor konstrukcji' | translate }})</ng-container>
                        </b>
                        <br />
                    </span>
                    <span *ngIf="profile?.selectedColor?.inner?.name">
                        {{ 'COLOR|Kolor wewn.' | translate }}:
                        <b *ngIf="profile?.selectedColor?.inner?.name">
                            {{ profile?.selectedColor?.inner?.name }}
                            <ng-container *ngIf="isWindowColor && !isDoor()">({{ 'COLOR|Kolor konstrukcji' | translate }})</ng-container>
                        </b>
                        <br />
                    </span>
                </ng-container>
                <span
                    *ngIf="!profile?.selectedColor?.outer?.isCore &&
                        !profile?.selectedColor?.inner?.isCore &&
                        profile?.selectedColor?.core?.name &&
                        config().IccConfig.Configurators.showCoreColorInAdditionalProfilesAndAccessories
                        ">{{ 'WINDOW|Kolor bazy' | translate }}:
                    <b>{{ profile?.selectedColor?.core?.name }} <ng-container *ngIf="isWindowColor && !isDoor()">({{ 'COLOR|Kolor konstrukcji' | translate }})</ng-container></b>
                    <br />
                </span>
                <div class="options-buttons">
                    <button mat-stroked-button color="primary" *ngIf="anyColorButtonVisible"
                        (click)="openModalColors()">{{ 'INTERFACE|Zmień' | translate }}</button>
                    <button mat-stroked-button color="primary" *ngIf="whiteColorButtonVisible"
                        (click)="setWhiteColors()">{{ 'INTERFACE|Biały' | translate }}</button>
                    <button mat-stroked-button color="primary" *ngIf="windowColorButtonVisible"
                            (click)="setWindowColors()">{{ 'INTERFACE|Kolor konstrukcji' | translate }}</button>
                    <div *ngIf="(whiteColorButtonVisible || windowColorButtonVisible) && !noFreeExtensionColorSelection">
                        <button
                            class="button-dropdown-arrow"
                            mat-stroked-button
                            color="primary"
                            [matMenuTriggerFor]="menu"
                        >
                            <mat-icon>expand_more</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" xPosition="before" class="mat-menu-button-dropdown">
                            <button mat-stroked-button color="primary" (click)="openModalColors()">
                                {{ 'INTERFACE|Zmień' | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>


                <span
                      *ngIf="config().IccConfig.Configurators.extensionsReinforcements && profile?.type === 'extension'">{{ 'WINDOW|Wzmocnienie' | translate }}:
                    <b *ngIf="profile?.reinforcement?.name">{{ profile?.reinforcement?.name }}</b>
                    <b *ngIf="!profile?.reinforcement?.name">{{ 'INTERFACE|Brak' | translate }}</b>
                    <br />
                </span>
                <div class="options-buttons">
                    <button mat-stroked-button color="primary"
                        *ngIf="config().IccConfig.Configurators.extensionsReinforcements && profile?.type === 'extension'"
                        (click)="openModalReinforcements(profile)">{{ 'INTERFACE|Zmień' | translate }}</button>
                </div>
          </div>

          <mat-form-field *ngIf="optionsInComplementary">
              <input matInput type="number" min="1"
                     [placeholder]="'INTERFACE|Ilość' | translate"
                     formControlName="count">
          </mat-form-field>
          <mat-form-field *ngIf="optionsInComplementary">
              <input matInput iccDimensionInput type="number" min="0.01" step="0.01"
                     formControlName="currentLength"
                     [placeholder]="'DIMENSION|Długość' | translate">
              <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
          <mat-form-field *ngIf="profile?.type === 'sandwich'">
                <input matInput iccDimensionInput type="number" [placeholder]="'DIMENSION|Wysokość' | translate" [min]="profile?.minHeight" [max]="profile?.maxHeight" formControlName="width">
                <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
          <mat-form-field *ngIf="optionsInComplementary">
            <input matInput type="text" formControlName="comment"
                   [placeholder]="'INTERFACE|Komentarz' | translate">
          </mat-form-field>
          <h4 *ngIf="!hiddenPrice">{{ 'OFFER|Cena profilu' | translate }}:
            <b>
                <span
                        *ngIf="hasZeroPrice(profile?.price, profile?.type)">{{ 'CONFIGURATOR|W cenie' | translate }}<br></span>
                <span
                        *ngIf="hasNoPrice(profile?.price, profile?.type)">{{ 'CONFIGURATOR|Do wyceny' | translate }}<br></span>
                <span
                        *ngIf="!hasZeroPrice(profile?.price, profile?.type) && ! hasNoPrice(profile?.price, profile?.type)">
                    <span
                            *ngIf="profile?.price.price_field && (!config().IccConfig.Configurators.price.frameProfilesAlt || profile?.type != 'frame')">{{priceMarket(profile?.price.price_field) | currency: currency}}/{{ 'WINDOW|pole' | translate }}<br></span>
                    <span
                            *ngIf="profile?.price.price_length && (!config().IccConfig.Configurators.price.frameProfilesAlt || profile?.type != 'frame')">{{dimensionUnit.unitSymbol != 'mm' ? '~' : ''}}{{priceMarket(profile?.price.price_length) | unitm:0:0:3 | currency: currency}}/<span
                                [innerHTML]="2|formatUnit"></span><br></span>
                    <span
                            *ngIf="profile?.price.price_area">{{profile?.price.price_area | currency: currency}}/<span
                                [innerHTML]="1|formatUnit"></span><br></span>
                    <span
                            *ngIf="profile?.price.price_field != profile?.price.price_length &&  config().IccConfig.Configurators.price.frameProfilesAlt && profile?.type == 'frame'">{{dimensionUnit.unitSymbol != 'mm' ? '~' : ''}}{{priceMarket(profile?.price.price_length) | unitm:0:0:3 | currency: currency}}/<span
                                [innerHTML]="2|formatUnit"></span> <span
                                *ngIf="profile?.price.price_field">{{ 'INTERFACE|lub' | translate }}&nbsp;{{priceMarket(profile?.price.price_field) | unitm:0:0:3 | currency: currency}}/<span
                                    [innerHTML]="2|formatUnit"></span></span><br></span>
                    <span
                            *ngIf="profile?.price.price_length && profile?.price.price_field == profile?.price.price_length &&  config().IccConfig.Configurators.price.frameProfilesAlt && profile?.type == 'frame'">{{dimensionUnit.unitSymbol != 'mm' ? '~' : ''}}{{priceMarket(profile?.price.price_length) | unitm:0:0:3 | currency: currency}}/<span
                                [innerHTML]="2|formatUnit"></span><br></span>
                    <span
                            *ngIf="profile?.price.price_piece">{{profile?.price.price_piece | currency: currency}}/{{ 'DIMENSION|szt.' | translate }}<br></span>
                    <span
                            *ngIf="profile?.price.price_construction">{{profile?.price.price_construction | currency: currency}}/{{ 'DIMENSION|konstrukcja' | translate }}<br></span>
                    <span
                            *ngIf="profile?.price.price_percent">{{profile?.price.price_percent}}%</span>
                </span>
            </b>
        </h4>

        <h4 *ngIf="!hiddenPrice && profile?.reinforcement?.price">{{ 'OFFER|Cena wzmocnienia' | translate }}:
            <b><span>{{dimensionUnit.unitSymbol != 'mm' ? '~' : ''}}{{priceMarket(profile?.reinforcement?.price) | unitm:0:0:3 | currency: currency}}</span>/<span
                [innerHTML]="2|formatUnit"></span></b>
        </h4>
      </form>

    <section class="casing" *ngIf="profile?.type === 'frame' && casings?.length > 0">
        <mat-checkbox [hidden]="profile?.onlyWithCasingCover" (change)="toggleCasing($event)" value="isCasingAvailableForFrame"  [checked]="isCasingOptionsSelected" >{{ 'WINDOW|Dodaj obejmę' | translate  }}</mat-checkbox>
          <form [formGroup]="casingForm" *ngIf="profile?.onlyWithCasingCover|| isCasingOptionsSelected && casingForm">
            <mat-form-field>
                <mat-label>{{ 'WINDOW|Szerokość muru obejmy' | translate }} </mat-label>
                <input matInput formControlName="casingWallWidth" [value]="casingForm?.casingWallWidth" required>
                    <mat-hint>{{ 'WINDOW|Wartość powinna mieścić się w zakresie od' | translate }}
                        {{lowestInnerCasingWidth}} {{dimensionUnit.unitSymbol}} {{ 'WINDOW|do' | translate }}
                        {{highestInnerCasingWidth}} {{dimensionUnit.unitSymbol}} {{ 'WINDOW|ze skokiem co' | translate }}
                        {{CASING_GAP}} {{dimensionUnit.unitSymbol}}.
                    </mat-hint>
                    <mat-error *ngIf="casingForm?.get('casingWallWidth').hasError('gapError')">
                        {{ 'WINDOW|Wartość powinna mieścić się w zakresie od' | translate }}
                        {{lowestInnerCasingWidth}} {{dimensionUnit.unitSymbol}} {{ 'WINDOW|do' | translate }}
                        {{highestInnerCasingWidth}} {{dimensionUnit.unitSymbol}} {{ 'WINDOW|ze skokiem co' | translate }}
                        {{CASING_GAP}} {{dimensionUnit.unitSymbol}}.
                    </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'WINDOW|Wyłożenie na mur' | translate }} [{{dimensionUnit.unitSymbol}}]</mat-label>
                <mat-select [disabled]="casingWallOverlap.length === 1" formControlName="casingWallOverlap"  (selectionChange)="changeCasingBasedOnInnerWidthAndWallOverlap()" >
                    <mat-option  *ngFor="let overlap of casingWallOverlap" [value]="overlap" >
                    {{overlap}}
                </mat-option>
            </mat-select>

            </mat-form-field>
          </form>
      </section>

      <section class="portal" *ngIf="isPortalAvailable()">
        <mat-checkbox
                (change)="togglePortal($event)"
                [checked]="isPortalSelected || isDoorPortalFormOpen"
                *ngIf="(isPortalSelected === false || portalForm)">
                {{ 'WINDOW|Dodaj portal' | translate }}
        </mat-checkbox>

        <form [formGroup]="portalForm" *ngIf="isPortalSelected || isDoorPortalFormOpen">

              <div [class.wysiwygEditorContentInfo]="!isWysiwygEditorContentEmpty">
                <mat-form-field appearance="outline" style="width: 100%;">
                  <mat-label>{{ 'WINDOW|Szerokość muru portalu' | translate }} </mat-label>
                  <input matInput formControlName="wallThickness" [value]="portalForm?.wallThickness" required/>
                  <mat-hint
                      >{{ 'WINDOW|Wartość powinna mieścić się w zakresie od' | translate }}
                      {{ portalMinimumWallThickness }} {{ dimensionUnit.unitSymbol }}
                      {{ 'WINDOW|do' | translate }} {{ portalMaximumWallThickness }}
                      {{ dimensionUnit.unitSymbol }} {{ 'WINDOW|ze skokiem co' | translate }}
                      {{ portalGap }} {{ dimensionUnit.unitSymbol }}.
                  </mat-hint>
                  <mat-error *ngIf="portalForm?.get('wallThickness').hasError('gapError')">
                      {{ 'WINDOW|Wartość powinna mieścić się w zakresie od' | translate }}
                      {{ portalMinimumWallThickness }} {{ dimensionUnit.unitSymbol }}
                      {{ 'WINDOW|do' | translate }} {{ portalMaximumWallThickness }}
                      {{ dimensionUnit.unitSymbol }} {{ 'WINDOW|ze skokiem co' | translate }}
                      {{ portalGap }} {{ dimensionUnit.unitSymbol }}.
                  </mat-error>
                </mat-form-field>
              </div>

              <div [class.wysiwygEditorContentInfo]="!isWysiwygEditorContentEmpty">
                <mat-form-field appearance="outline" style="width: 100%;">
                  <mat-label>{{ 'WINDOW|Typ płyty' | translate }}</mat-label>
                  <mat-select [disabled]="portalPlateTypes.length === 1" formControlName="plateType">
                      <mat-option *ngFor="let type of portalPlateTypes" [value]="type">
                        {{type}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
              </div>

              <div [class.wysiwygEditorContentInfo]="!isWysiwygEditorContentEmpty">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>{{ 'WINDOW|Szerokość lewego portalu' | translate }} [{{dimensionUnit.unitSymbol}}]</mat-label>
                    <mat-select [disabled]="leftSideWidths.length === 1" formControlName="leftWidth">
                        <mat-option *ngFor="let size of leftSideWidths" [value]="size">
                          {{size}}
                        </mat-option>
                      </mat-select>
                </mat-form-field>
              </div>

              <div [class.wysiwygEditorContentInfo]="!isWysiwygEditorContentEmpty">
                <mat-form-field appearance="outline" style="width: 100%;">
                  <mat-label>{{ 'WINDOW|Szerokość prawego portalu' | translate }} [{{dimensionUnit.unitSymbol}}]</mat-label>
                  <mat-select [disabled]="rightSideWidths.length === 1" formControlName="rightWidth">
                      <mat-option *ngFor="let size of rightSideWidths" [value]="size">
                        {{size}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
              </div>

              <div [class.wysiwygEditorContentInfo]="!isWysiwygEditorContentEmpty">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>{{ 'WINDOW|Wysokość górnego portalu' | translate }} [{{dimensionUnit.unitSymbol}}]</mat-label>
                    <mat-select [disabled]="upperHeights.length === 1" formControlName="upperHeight">
                        <mat-option *ngFor="let size of upperHeights" [value]="size">
                          {{size}}
                        </mat-option>
                      </mat-select>
                </mat-form-field>
              </div>

            </form>
      </section>

  </mat-card-content>
  <mat-card-actions>
    <button
      [disabled]="
        (!casingForm?.valid && isCasingOptionsSelected) ||
        (profile?.onlyWithCasingCover && !casingForm?.valid) ||
        (!portalForm?.valid && this.availablePortals?.length > 0)
        "
      mat-raised-button color="primary"
      (click)="add()">{{ 'INTERFACE|Dodaj' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
