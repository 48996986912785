import { AwningAccessoriesComponent } from './awning-accessories/awning-accessories.component';
import { StepComponentType } from '@icc/configurator/shared';
import { AwningDimensionsComponent } from './awning-dimensions/awning-dimensions.component';
import { AwningColorsComponent } from './awning-colors/awning-colors.component';
import { AwningSheathingComponent } from './awning-sheathing/awning-sheathing.component';
import { AwningFrillComponent } from './awning-frill/awning-frill.component';
import { AwningAssemblyComponent } from './awning-assembly/awning-assembly.component';
import { AwningSystemsComponent } from './awning-systems/awning-systems.component';
import { AwningDriveComponent } from './awning-drive/awning-drive.component';

export const awningStepsMap: {
    [stepId: string]: StepComponentType;
} = {
    systems: AwningSystemsComponent,
    dimensions: AwningDimensionsComponent,
    colors: AwningColorsComponent,
    sheathing: AwningSheathingComponent,
    frill: AwningFrillComponent,
    drive : AwningDriveComponent,
    assembly: AwningAssemblyComponent,
    accessory: AwningAccessoriesComponent,
};
