import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { GarageDoorState } from './garage-door.reducer';
import { Store, select } from '@ngrx/store';
import { ConfiguratorsDataLoaded, SharedActionTypes, fromSharedActions } from '@icc/configurator/shared';
import { map, withLatestFrom } from 'rxjs/operators';
import { GarageDoorInitService } from './garage-door-init.service';
import { GarageDoorActionTypes } from './garage-door.actions';
import { garageDoorQuery } from './garage-door.selectors';

@Injectable()
export class GarageDoorEffects {
    @Effect()
    changedConfiguration$ = this.actions$.pipe(
        ofType(
            GarageDoorActionTypes.SetGarageDoorSystem,
            GarageDoorActionTypes.SetGarageDoorDimensions,
            GarageDoorActionTypes.SetGarageDoorMechanism,
            GarageDoorActionTypes.SetGarageDoorGuidance,
            GarageDoorActionTypes.SetGarageDoorPanelsData,
            GarageDoorActionTypes.SetGarageDoorGlazingsGlobalImposts,
            GarageDoorActionTypes.SetGarageDoorWindowsGlobalType,
            GarageDoorActionTypes.SetGarageDoorWindowsGlobalGlazing,
            GarageDoorActionTypes.SetGarageDoorGlazingPanelsGlobalGlazing,
            GarageDoorActionTypes.SetGarageDoorWindowFramesGlobalOuterColor,
            GarageDoorActionTypes.SetGarageDoorGlazingPanelsGlobalColor,
            GarageDoorActionTypes.SetGarageDoorVentilationGratesGlobalOuterColor,
            GarageDoorActionTypes.SetGarageDoorPanel,
            GarageDoorActionTypes.SetGarageDoorPanelInternal,
            GarageDoorActionTypes.SetGarageDoorPanelStructure,
            GarageDoorActionTypes.SetGarageDoorPanelInternalStructure,
            GarageDoorActionTypes.SetGarageDoorPlinthPanel,
            GarageDoorActionTypes.SetGarageDoorPlinthPanelStructure,
            GarageDoorActionTypes.SetGarageDoorPanelColor,
            GarageDoorActionTypes.SetGarageDoorPanelInternalColor,
            GarageDoorActionTypes.SetGarageDoorDrive,
            GarageDoorActionTypes.SetGarageDoorDriveType,
            GarageDoorActionTypes.SetGarageDoorDriveSide,
            GarageDoorActionTypes.SetGarageDoorWicketDoor,
            GarageDoorActionTypes.SetGarageDoorWicketDoorDirection,
            GarageDoorActionTypes.SetGarageDoorWicketDoorHandleColor,
            GarageDoorActionTypes.SetGarageDoorWicketDoorFrameProfilesColor,
        ),
        withLatestFrom(this.store.pipe(select(garageDoorQuery.getGarageDoorConfiguration))),
        map(([action, configuration]) => {
            return new fromSharedActions.ConfigurationChanged({
                configuration
            });
        })
    )

    @Effect({
        dispatch: false
    })
    configuratorsDataLoaded$ = this.actions$.pipe(
        ofType<ConfiguratorsDataLoaded>(SharedActionTypes.ConfiguratorsDataLoaded),
        map(action => action.payload),
        map(payload => {
            if (payload.data) {
                this.garageDoorInitService.init();
            }
        })
    );

    constructor(
        private actions$: Actions,
        private store: Store<GarageDoorState>,
        private garageDoorInitService: GarageDoorInitService
    ) {}
}
