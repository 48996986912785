import { Common } from '../Common';
import { IIccConfig } from '@icc/config';
import { core } from '../helpers';

export function base64Decoder(data, $, location?: string, changeImagePaths=false) {
    if (Common.isDefined(data) && location) {
        const str = $(decodeURIComponent(escape(data)));
        if (changeImagePaths) {
            str.find("pattern > image").each(function(el) {
                let attr = $(this).attr('href');
                attr = location + attr;
                $(this).attr('href', attr);
            });
        }
        str.find("rect[style*='fill:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("rect[style*='stroke:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("polygon[style*='fill:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("polygon[style*='stroke:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("circle[style*='fill:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("circle[style*='stroke:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("path[style*='fill:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("path[style*='stroke:url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/, location + '#');
            $(this).attr('style', attr);
        });
        str.find("line[style*='marker-start: url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('style', attr);
        });
        str.find("line[style*='marker: url']").each(function(el) {
            let attr = $(this).attr('style');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('style', attr);
        });
        str.find("*[mask*='url']").each(function(el) {
            let attr = $(this).attr('mask');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('mask', attr);
        });
        str.find("*[fill*='url']").each(function(el) {
            let attr = $(this).attr('fill');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('fill', attr);
        });
        str.find("*[stroke*='url']").each(function(el) {
            let attr = $(this).attr('stroke');
            attr = attr.replace(/https?:.*?#/g, location + '#');
            $(this).attr('stroke', attr);
        });
        return str[0].outerHTML || str.parent().html();
    } else {
        return data;
    }
}

export function svgImageFilter(data, IccConfig: IIccConfig, $, location?: string, keepUrl?: boolean, changeImagePaths=false, isPdf=false, conf=null, baseDimensionUnit = 'mm', targetDimensionUnit = 'mm') {
    data = base64Decoder(data, $, location, changeImagePaths);

    if (!keepUrl) {
        data = data.replace(/url\(\'(https?|icc-res)\:\/\/.*?\#/g, `url('#`);
        data = data.replace(/url\((https?|icc-res)\:\/\/.*?\#/g, `url(#`);
    }

    data = data.replace(/cdvfile:\/\/localhost\/persistent\/files/g, ``);

    data = data.replace(
        /class="windowSvg[a-zA-Z0-9-\s]*"/g,
        '$0 height="'
            + IccConfig.Drawing.pdf.height
            + '" width="'
            + IccConfig.Drawing.pdf.width
            + '"'
    );
    data = data.replace(/about:blank/g, '');

    data = data.replace(/<\/?icc-draw[^>]*>/g, '');
    data = data.replace(/_ngcontent-[\w-]*(="")?/g, '');
    data = data.replace(/_nghost-[\w-]*(="")?/g, '');

    if (IccConfig.Drawing.pdf.blackWhite) {
        data = data.replace(/url\(\'?#iccDraw\-muntins\-[a-zA-Z0-9-\s]*\'?\)/g, `#000`);
        data = data.replace(/url\(\'?#iccDraw\-[a-z]*\-[a-z0-9\-]{36}\'?\)/g, `#fff`);
        data = data.replace(/stroke\=\"\#bbb\"/g, `stroke="#000"`);
    }

    if (IccConfig.Drawing.pdf.muntins == 'black') {
        data = data.replace(/url\(\'?#iccDraw\-muntins\-[a-zA-Z0-9-\s]*\'?\)/g, `#000`);
    }

    data = data.replace(/href/g,'xlink:href');
    data = data.replace(/xlink:xlink:href/g,'xlink:href');
    data = data.replace(/class="preview/g,'class="smallWindowPreview smallWindowPreviewX');
    if(isPdf) {
        let containerWidht = 350;
        let containerHeight = 270.7;
        let baseFontSize = 20;
        if(conf.type === 'coupled_window') {
            containerWidht = 540;
            containerHeight = 412.23 ;
        }
        let viewBoxDimension = data.match(/viewBox="[+-]?\d+(\.\d+)? [+-]?\d+(\.\d+)? [+-]?\d+(\.\d+)? [+-]?\d+(\.\d+)?"/g);
        viewBoxDimension = viewBoxDimension[0].slice(9, -1);
        viewBoxDimension = viewBoxDimension.split(" ");
        let dimensionNodeScale = parseFloat(viewBoxDimension[2]) < parseFloat(viewBoxDimension[3]) ? viewBoxDimension[3] / containerHeight : viewBoxDimension[2] / containerWidht;
        var fontSizeDif = 0;
        data = data.replace(/class="DimensionNode([\s\S]*?)g>/g,function(match, contents, offset, input_string)  {
            match = match.replace(/font-size="\d+.\d+"/g,function(match, contents, offset, input_string) {
                let fontSize = match.slice(11, -1)
                fontSizeDif = (dimensionNodeScale * baseFontSize) - fontSize;
                return 'font-size="' + baseFontSize * dimensionNodeScale + '"';
            });
            var y = null;
            var x = null;
            match = match.replace(/x="\d+.\d+" y="\d+.\d+"/g,function(match, contents, offset, input_string) {
                let toChange = match.split(" ");
                let xToChange = toChange[0].slice(3, -1)
                let yToChange = toChange[1].slice(3, -1)
                y = y ? y : yToChange;
                x = x ? x : xToChange;
                yToChange = parseFloat(yToChange);
                if(y == yToChange && x == xToChange) {
                    return 'x="' + xToChange + '" y="' + (yToChange + fontSizeDif * 0.5) + '"';
                }
                return 'x="' + xToChange + '" y="' + (yToChange - fontSizeDif * 0.5)  + '"';
            });

            return match;
        });

        data = data.replace(/viewBox="[+-]?\d+(\.\d+)? [+-]?\d+(\.\d+)? [+-]?\d+(\.\d+)? [+-]?\d+(\.\d+)?"/g,function(match, contents, offset, input_string) {
            let vieBoxSize = match.slice(9, -1)
            vieBoxSize = vieBoxSize.split(" ");
            let yIndex =  vieBoxSize[1] * 2;
            let width = parseFloat(vieBoxSize[2]) + fontSizeDif;
            let height = parseFloat(vieBoxSize[3]) + fontSizeDif - yIndex;
            return 'viewBox="' + vieBoxSize[0] + " " + yIndex + " " +  width + " " + height + '"';
        });
    }

    if (baseDimensionUnit !== targetDimensionUnit) {
        const data$ = $(data);
        const dimensionTexts = data$.find('.DimensionNode').find('text');
        dimensionTexts.each(function() {
            if (targetDimensionUnit === 'inch' && baseDimensionUnit === 'mm') {
                $(this).text(core.formatNumber(parseFloat($(this).text()) / IccConfig.Configurators.inchToMilimeter, 2, 100));
            } else if (targetDimensionUnit === 'mm' && baseDimensionUnit === 'inch') {
                $(this).text(core.formatNumber(parseFloat($(this).text()) * IccConfig.Configurators.inchToMilimeter, 2, 100));
            }
        });
        data = data$[0].outerHTML || data$.parent().html();
    }

    return data;
}
