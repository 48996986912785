import {LicenseService} from './license.service';

export class LicenseCtrl {

    licenseContent = '';

    constructor($location, private LicenseService: LicenseService) {
        if (this.LicenseService.accepted()) {
            $location.path('/app/update');
        }
        this.loadLicenseContent();
    }

    async loadLicenseContent() {
        this.licenseContent = await this.LicenseService.getLicenseContent();
    }

    accept = this.LicenseService.accept.bind(this.LicenseService);

}