import angular from 'angular';

export default function OrdersPositionsElementsModalCtrl($uibModalInstance, ordersPositionsElements) {
    'ngInject';

    var vm = this;
    vm.ordersPositionsElements = angular.copy(ordersPositionsElements);

    vm.getIndex = getIndex;
    vm.deleteElementFromSashes = deleteElementFromSashes;
    vm.deleteElementFromDividers = deleteElementFromDividers;
    vm.deleteElementFromWindow = deleteElementFromWindow;
    vm.deleteElementFromFrame = deleteElementFromFrame;
    vm.dividersNotEmpty = dividersNotEmpty;
    vm.sashesNotEmpty = sashesNotEmpty;
    vm.windowNotEmpty = windowNotEmpty;
    vm.frameNotEmpty = frameNotEmpty;
    vm.positionNotEmpty = positionNotEmpty;
    vm.orderNotEmpty = orderNotEmpty;
    vm.ordersNotEmpty = ordersNotEmpty;
    vm.close = close;

    /**
     * Sprawdza czy w którymś z dividerów danej pozycji sa elementy
     *
     * @param {int} [orderId] [id zamówienia]
     * @param {int} [positionId] [id pozycji]
     */
    function dividersNotEmpty(orderId, positionId) {
        var toReturn = false;
        if (angular.isDefined(vm.ordersPositionsElements[orderId][positionId].dividers)) {
            angular.forEach(vm.ordersPositionsElements[orderId][positionId].dividers, function(dividersElements) {
                if (dividersElements.length > 0) {
                    toReturn = true;
                }
            });
        }

        return toReturn;
    }

    /**
     * Sprawdza czy w którymś ze skrzydeł danej pozycji sa elementy
     *
     * @param {int} [orderId] [id zamówienia]
     * @param {int} [positionId] [id pozycji]
     */
    function sashesNotEmpty(orderId, positionId) {
        var toReturn = false;
        if (angular.isDefined(vm.ordersPositionsElements[orderId][positionId].dividers)) {
            angular.forEach(vm.ordersPositionsElements[orderId][positionId].sashes, function(sashesElements) {
                if (sashesElements.length > 0) {
                    toReturn = true;
                }
            });
        }
        return toReturn;
    }

    /**
     * Sprawdza czy w ramie danej pozycji sa elementy
     *
     * @param {int} [orderId] [id zamówienia]
     * @param {int} [positionId] [id pozycji]
     */
    function frameNotEmpty(orderId, positionId) {
        return angular.isDefined(vm.ordersPositionsElements[orderId][positionId]['frame']) && vm.ordersPositionsElements[orderId][positionId]['frame'].length > 0 ? true : false;
    }

    /**
     * Sprawdza czy w polu 'cala konstrukcja' danej pozycji sa elementy
     *
     * @param {int} [orderId] [id zamówienia]
     * @param {int} [positionId] [id pozycji]
     */
    function windowNotEmpty(orderId, positionId) {
        return angular.isDefined(vm.ordersPositionsElements[orderId][positionId]['window']) && vm.ordersPositionsElements[orderId][positionId]['window'].length > 0 ? true : false;
    }

    /**
     * Sprawdza czy w danej pozycji sa elementy
     *
     * @param {int} [orderId] [id zamówienia]
     * @param {int} [positionId] [id pozycji]
     */
    function positionNotEmpty(orderId, positionId) {
        if (windowNotEmpty(orderId, positionId) || frameNotEmpty(orderId, positionId) || sashesNotEmpty(orderId, positionId) || dividersNotEmpty(orderId, positionId)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Funkcja sprawdzajca, czy w którejkolwiek pozycji danego zamówienia sa elementy
     *
     * @param {int} [varname] [description]
     */
    function orderNotEmpty(orderId) {
        var toReturn = false;

        angular.forEach(vm.ordersPositionsElements[orderId], function(position, positionId) {
            if (vm.positionNotEmpty(orderId, positionId)) {
                toReturn = true;
            }
        });

        return toReturn;
    }

    /**
     * Funkcja sprawdzajca, czy w którejkolwiek pozycji któregokolwiek zamówienia sa elementy
     *
     * @param {int} [varname] [description]
     */
    function ordersNotEmpty() {
        vm.toReturn = false;
        angular.forEach(vm.ordersPositionsElements, function(order, orderId) {
            angular.forEach(order, function(position, positionId) {
                if (vm.positionNotEmpty(orderId, positionId)) {
                    vm.toReturn = true;
                }
            });
        });

        return vm.toReturn;
    }

    /**
     * Dodaje kropkę do cyfry i zwraca string
     *
     * @param {int} [index]      indeks do którego ma dodana być kropka
     */
    function getIndex(index) {
        return String(index) + '.';
    }

    /**
     * Usuwa element ze skrzydeł
     *
     * @param {int} [orderId] [id zamówienia]
     * @param {int} [positionId] [id pozycji]
     * @param {int} [dividerId] [id skrzydła]
     * @param {int} [elementId] [id elementu]
     */
    function deleteElementFromSashes(orderId, positionId, sashId, elementId) {
    	vm.ordersPositionsElements[orderId][positionId]['sashes'][sashId].splice(elementId, 1);
    }

    /**
     * Usuwa element ze słupków/poprzeczek
     *
     * @param {int} [orderId] [id zamówienia]
     * @param {int} [positionId] [id pozycji]
     * @param {int} [dividerId] [id dividera]
     * @param {int} [elementId] [id elementu]
     */
    function deleteElementFromDividers(orderId, positionId, dividerId, elementId) {
        vm.ordersPositionsElements[orderId][positionId]['dividers'][dividerId].splice(elementId, 1);
    }

    /**
     * Usuwa element z konstrukcji całego okna
     *
     * @param {int} [orderId] [id zamówienia]
     * @param {int} [positionId] [id pozycji]
     * @param {int} [elementId] [id elementu]
     */
    function deleteElementFromWindow(orderId, positionId, elementId) {
        vm.ordersPositionsElements[orderId][positionId]['window'].splice(elementId, 1);
    }

    /**
     * Usuwa element z ramy
     *
     * @param {int} [orderId] [id zamówienia]
     * @param {int} [positionId] [id pozycji]
     * @param {int} [elementId] [id elementu]
     */
    function deleteElementFromFrame(orderId, positionId, elementId) {
        vm.ordersPositionsElements[orderId][positionId]['frame'].splice(elementId, 1);
    }

    /**
     * Zamykanie modala
     */
    function close() {
        $uibModalInstance.close();
    }
}
