<div id="sash-layout-draw" #parent>
    <icc-draw [details]="drawService.details"
        [options]="drawOptions"
        fitTo="width"
        iccDrawResize
        [drawWidth]="width$ | async"
        [drawHeight]="height$ | async"
        [parentElement]="parent">
    </icc-draw>
</div>
