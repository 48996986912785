import angular from 'angular';

/**
 * Fabryka kolorów markiz
 * @param {object} $rootScope               RootScope
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} ConfiguratorsDataService Fabryka z danymi do konfiguratorów
 * @param {object} CurConfService           Informacje o bieżym konfiguratorze
 * @param {object} StepFactory              StepFactory
 * @param {object} PriceService             Liczenie cen
 */
export default function ColorsFactory($rootScope, ConfigurationsService, ConfiguratorsDataService, CurConfService, StepFactory, PriceService, EventBusService) {
    'ngInject';

    var factory = {
        colors          : [],
        color           : {},
        loadedData      : false,
        selectColor     : selectColor,
        setDefaultValues: setDefaultValues
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    $rootScope.$on('changedStep', init);

    return factory;

    /**
     * Funkcja inicjujaca
     */
    function init() {
        if (CurConfService.conf === 'awning') {
            setValues();
            if (angular.isUndefined(ConfigurationsService.conf.Current.Colour.id)) {
                factory.setDefaultValues();
            }
            factory.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Ustawianie wartosci
     */
    function setValues() {
        factory.colors = getColors();
    }

    /**
     * Zwracanie kolorów
     * @return {Array} Kolory
     */
    function getColors() {
        var systemID = ConfigurationsService.conf.Current.SystemId;
        if (systemID > 0) {
            return ConfiguratorsDataService.data.awningColours[systemID];
        } else {
            return [];
        }
    }

    /**
     * Wybór koloru
     * @param  {Object} color    Kolor
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    function selectColor(color, nextStep, price) {
        ConfigurationsService.conf.Current.Colour = color;
        ConfigurationsService.conf.Default.Colour = color;

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }
    }

    /**
     * Ustawianie wartości domyślnych
     * @param {Boolean} reset Reset
     */
    function setDefaultValues(reset) {

        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }

        setValues();

        if (angular.isUndefined(factory.colors[0])) {
            return;
        }

        if (
            angular.isUndefined(ConfigurationsService.conf.Current.Colour.id) ||
            reset
        ) {
            factory.selectColor(factory.colors[0]);
        }
    }

}
