<icc-select-box
    [title]="'WINDOW|Parapet zewnętrzny' | translate"
    [description]="selectedSill.outer?.name"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="openModalSills('outer')"
></icc-select-box>

<icc-select-box
    [title]="'WINDOW|Parapet wewnętrzny' | translate"
    [description]="selectedSill.inner?.name"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="openModalSills('inner')"
></icc-select-box>


<button mat-raised-button color="primary" (click)="closeModal()"
        >{{ 'INTERFACE|OK' | translate }}</button>
