import * as angular from 'angular';

import ModelsCtrl from './ModelsCtrl';
import ModalModelInfoCtrl from './ModalModelInfoCtrl';
import { ModelService } from './model.service';
import { downgradeInjectable } from '@angular/upgrade/static';

export default angular.module('icc.configurator.steps.door.models', [])
    .controller('ModelsCtrl', ModelsCtrl)
    .factory('ModelService', downgradeInjectable(ModelService))
    .controller('ModalModelInfoCtrl', ModalModelInfoCtrl)
    .name;
