import { isUndefined } from '@icc/helpers';

/**
 * Funkcja ornamentu szyby
 */
export function GlassOrnamentFilter() {
    'ngInject';

    return function(glass, ornament) {
        const glassArr = [];
        Object.keys(glass).forEach(key => {
            glassArr.push(glass[key]);
        });
        if (isUndefined(ornament) || ornament === null || ornament === '') {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {
                return !!~~gl.ornament === !!~~ornament;
            });
        }
    };
}
