import { Component, OnInit, Inject } from '@angular/core';
import { _, SharedFacade, ICC_PAGE_DATA, ModalService, PageComponent } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';

@Component({
    selector: 'icc-modal-inserts',
    templateUrl: './modal-inserts.component.html',
    styleUrls: ['./modal-inserts.component.scss'],
})
export class ModalInsertsComponent extends PageComponent implements OnInit {
    public configurator = 'garage_door';
    public stepId = 'modalInserts';

    public options = [];
    public title = _('GARAGEDOOR|Wybierz typ wypełnienia');
    items: iccListItem[] = [];

    selectedItemId: number;

    constructor(
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            items: any[];
            selectedItemId: number;
        }
    ) {
        super();
    }

    ngOnInit() {
        this.selectedItemId = this.pageData.selectedItemId;
        this.items = this.pageData.items.map<iccListItem>(item => ({
            id: Number(item.id),
            title: item.name,
            description: item.code,
            color: '#' + item.color,
            imageUrl: '/files/garagedoorinsert/'+ item.img
        }));
    }

    close() {
        this.sharedFacade.closePage();
    }

    selectItem(item: iccListItem) {
        const foundItem = this.pageData.items.find(i => Number(item.id) === Number(i.id));
        this.sharedFacade.closePage(foundItem);
    }
}
