
<div class="garage-door-dimension-legend">
  <img src="/assets/garage_door/widok_od_wewnatrz.png" defaultImg>
  <img class="garage-section" src="/assets/garage_door/przekroj.png" defaultImg>
</div>

<form [formGroup]="dimensionsForm">
  <ng-container *ngFor="let field of fields">
    <mat-form-field  *ngIf="field.field">
      <mat-label *ngIf="field.name == 'width'" translate>DIMENSION|Szerokość (W)</mat-label>
      <mat-label *ngIf="field.name == 'height'" translate>DIMENSION|Wysokość (H)</mat-label>
      <mat-label *ngIf="field.name == 'lintelHeight'" translate>DIMENSION|Wysokość nadproża (N)</mat-label>
      <mat-label *ngIf="field.name == 'garageDepth'" translate>DIMENSION|Głębokość garażu (D)</mat-label>
      <mat-label *ngIf="field.name == 'leftJambWidth'" translate>DIMENSION|Szerokość ściany lewej (SL)</mat-label>
      <mat-label *ngIf="field.name == 'rightJambWidth'" translate>DIMENSION|Szerokość ściany prawej (SR)</mat-label>
      <input matInput #fieldValue iccDimensionInput step="5" type="number" [formControlName]="field.field">
      <mat-hint class="hint-danger input-validation-hint">
        <span *ngIf="(fieldValue.value % 5 != 0)">{{'DIMENSION|Wartość musi być podzielna przez' | translate}} 5<br></span>
        <span *ngIf="field.min && fieldValue.value < field.min">{{'DIMENSION|Wartość musi być większa lub równa' | translate}} {{field.min}}<br></span>
        <span *ngIf="field.max && fieldValue.value > field.max">{{'DIMENSION|Wartość musi być mniejsza lub równa' | translate}} {{field.max}}</span>
      </mat-hint>
      <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
    </mat-form-field>
  </ng-container>
</form>
