import angular from 'angular';
/**
 * HeaderCtrl
 */
export class HeaderCtrl {

    /**
     * Constructor
     * @param  {object} $scope             scope
     * @param  {object} $rootScope         rootScope
     * @param  {object} $location          location
     * @param  {object} NewPositionService Serwis nowej pozycji
     * @param  {object} DatabaseManager   Fabryka ustawień
     */
    constructor($scope, $rootScope, $location, NewPositionService, StateService, EventBusService, ClientsFactory) {
        'ngInject';
        this.NewPositionService = NewPositionService;
        this.$location = $location;
        this.$rootScope = $rootScope;

        this.offer = null;
        this.offers = [];
        this.client = null;
        this.key = '';
        this.usersOnOfferCount = 1;
        this.translationData = {
            count: 1
        };

        if (!$rootScope.header) {
            if (angular.isArray(StateService.state.offers)) {
                this.offer = StateService.state.offer;
                this.offers = StateService.state.offers;
            }
        }

        this.exportClients = ClientsFactory.exportClients.bind(ClientsFactory);
        this.importClients = ClientsFactory.importClients.bind(ClientsFactory);

        const subscriptions = [];
        subscriptions.push(EventBusService.subscribeWithoutConfiguration('changedState', data => {
            if (angular.isArray(data.value.offers)) {
                this.offer = data.value.offer;
                this.offers = data.value.offers;
                this.client = data.value.client;
                if (this.offer) {
                    this.key = this.offer.key;
                }
            }
        }));
        subscriptions.push(EventBusService.subscribeWithoutConfiguration('offerUsersChanged', data => {
            this.usersOnOfferCount = data.value;
            this.translationData.count = data.value;
        }));
        $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));
    }

    /**
     * Dodawanie nowej pozycji
     */
    addProduct() {
        this.NewPositionService.openModal(this.offer, () => {
            this.$location.url('/app/offers_view/' + this.offer.tmp_id);
        });
    }

}
