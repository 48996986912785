import angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import AccessoriesByNameOrCodeFilter from './AccessoriesByNameOrCodeFilter';
import AccessoriesCtrl from './AccessoriesCtrl';
import { AccessoriesService } from './accessories.service';
import AccessoriesFilter from './accessories.filter.ajs';
import AccessoriesSubcatsFilter from './AccessoriesSubcatsFilter';
import AccessoryPriceFilter from './AccessoryPriceFilter';
import AccessoryPriceOneFilter from './AccessoryPriceOneFilter';
import ColorNameFilter from './ColorNameFilter';
import CustomPriceFilter from './CustomPriceFilter';
import FormatUnitFilter from './FormatUnitFilter';
import ModalAccessoriesCtrl from './ModalAccessoriesCtrl';
import RangeFilter from './RangeFilter';
import PriceMarketFilter from './PriceMarketFilter';

export default angular
    .module('icc.configurator.steps.window.accessories', [])
    .controller('AccessoriesCtrl', AccessoriesCtrl)
    .controller('ModalAccessoriesCtrl', ModalAccessoriesCtrl)
    .factory('AccessoriesService', downgradeInjectable(AccessoriesService))
    .filter('accessories', AccessoriesFilter)
    .filter('accessoriesByNameOrCode', AccessoriesByNameOrCodeFilter)
    .filter('accessoryPrice', AccessoryPriceFilter)
    .filter('accessoryPriceOne', AccessoryPriceOneFilter)
    .filter('accessSubcats', AccessoriesSubcatsFilter)
    .filter('colorName', ColorNameFilter)
    .filter('customPrice', CustomPriceFilter)
    .filter('formatUnit', FormatUnitFilter)
    .filter('range', RangeFilter)
    .filter('priceMarket', PriceMarketFilter).name;
