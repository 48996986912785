import { core } from '@icc/common/Core';
import { Common } from '@icc/common/Common';
import { IIccConfig } from '@icc/config';
import { isDefined } from '../helpers';
import { AppConfig } from '@icc/common/config';

export function unitMMFormatter(
    data: any,
    config: IIccConfig,
    hasunit = 0,
    inmm = 0,
    dimensionUnit: 'mm' | 'inch' = 'mm',
    round: number | null = null,
    unitPrefix: string = '' 
) {
    const unit = {
        type: dimensionUnit === 'mm' ? 0 : 1,
        unit: dimensionUnit,
        separator: dimensionUnit === 'mm' ? ',' : '.',
    };

    if (Common.isString(data)) {
        data = +data;
    }
    if (!Common.isNumber(data) || isNaN(data) || !Common.isDefined(data)) {
        return 'NaN';
    }

    let unitext = '';
    if (hasunit) {
        if (unit.type === 0) {
            unitext = 'mm';
        } else {
            unitext = '″';
        }
    }
    unitext = unitPrefix + unitext;

    if (round == null) {
        round = 2;
    } else {
        data = core.formatNumber(data, round, 100);
    }

    // milimetry, zwróć po prostu - nie dodawaj nawet drugiego wymiaru, bo po co
    if (unit.type === 0) {
        return data + unitext;
        // do konwertowanych jednostek dodaj wymiar
    } else if (inmm) {
        return (
            core.formatNumber(
                data / config.Configurators.inchToMilimeter,
                round,
                100,
                ' ',
                unit.separator
            )
            + unitext
            + ' ('
            + data
            + 'mm)'
        );
    } else {
        return (
            core.formatNumber(
                data / config.Configurators.inchToMilimeter,
                round,
                100,
                ' ',
                unit.separator
            ) + unitext
        );
    }
}

/**
 * Milimetry na wymiary podane na rynku.
 * @param  {numeric} data Wymiary w milimetrach.
 * @return {numeric}      Wymiary rynkowe.
 */
export function milimetersToDim(data, user, config: IIccConfig, options: AppConfig) {
    const unitObj = getUnit(user, options);
    if (unitObj.type === 0) {
        return data;
    } else {
        return core.formatNumber(
            data / config.Configurators.inchToMilimeter,
            2,
            100,
            ' ',
            unitObj.separator
        );
    }
}

/**
 * Wymiary rynku na milimetry.
 * @param  {numeric} data Wymiary rynkowe.
 * @return {numeric}      Wymiary w milimetrach.
 */
export function dimToMilimeters(data, user, config: IIccConfig, options: AppConfig) {
    const unitObj = getUnit(user, options);
    if (unitObj.type === 0) {
        return data;
    } else {
        return Math.ceil(data * config.Configurators.inchToMilimeter);
    }
}

/**
 * Milimetry na wymiary podane na rynku - bez formatowania, wysypywało.
 * @param  {numeric} data Wymiary w milimetrach.
 * @return {numeric}      Wymiary rynkowe.
 */
export function milimetersToDimNoFormat(data, user, config: IIccConfig, options: AppConfig) {
    const unitObj = getUnit(user, options);
    if (unitObj.type === 0) {
        return data;
    } else {
        return Math.ceil((data / config.Configurators.inchToMilimeter) * 100) / 100;
    }
}

/**
 * Pobranie wymiaru w przypadku gdy go nie ma
 * @return {[type]} [description]
 */
export function getUnit(user, options: AppConfig) {
    const dimensionUnit = user?.dimension_unit ?? options?.dimensionUnit;
    if (dimensionUnit) {
        return {
            type: dimensionUnit === 'mm' ? 0 : 1,
            unit: dimensionUnit,
            separator: dimensionUnit === 'mm' ? ',' : '.',
            unitSymbol: dimensionUnit === 'mm' ? 'mm' : '″'
        };
    } else {
        return {
            type: 0,
            unit: 'mm',
            separator: ',',
            unitSymbol: 'mm'
        };
    }
}
