const ReinforcementsModalTemplate = require('ngtemplate-loader!./reinforcements-modal.component.html');
import ReinforcementsModalController from './reinforcements-modal.controller';

export const ReinforcementsModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: ReinforcementsModalTemplate,
  controller: ReinforcementsModalController
};
