import { ShutterAction, ShutterActionTypes } from './shutter.actions';
import { IRollerShutterActiveConfiguration } from '@icc/common/configurations/RollerShutterActiveConfiguration';
import { SharedActionTypes, SharedAction } from '@icc/configurator/shared';

export const SHUTTER_FEATURE_KEY = 'shutter';

export interface ShutterState {
    configuration: IRollerShutterActiveConfiguration | null;
}

export interface ShutterPartialState {
    readonly [SHUTTER_FEATURE_KEY]: ShutterState;
}

export const initialState: ShutterState = {
    configuration: null,
};

export function shutterReducer(
    state: ShutterState = initialState,
    action: ShutterAction
): ShutterState {
    switch (action.type) {
        case ShutterActionTypes.UpdateShutterConfiguration: {
            state = {
                ...state,
                configuration: {
                    ...(action.payload.configuration as IRollerShutterActiveConfiguration),
                },
            };
            break;
        }
    }
    return state;
}
