import 'angular-ui-bootstrap';
import {core} from '@icc/common/helpers';

/**
 * Kontroler modala kolorów dodatku.
 *
 * @export
 * @class ModalPriceDetailsCtrl
 */
export class ModalDecoPanelColorsCtrl {
    hideCore = true;

    config = {
        type: 'door',
        Colors: core.copy((this.dualColor ? this.sash.selectedColorSecond :  this.sash.selectedColor)) || {
            frame: {
                outer: {},
                inner: {},
                core: {},
                alushell: {},
            },
            sash: {
                outer: {},
                inner: {},
                core: {},
                alushell: {},
            }
        },
        System: {
            type: this.sash.system_types[0]
        },
        Wood: this.sash.selectedWood || {},
        valid: {}
    };

    configDefault = {
        type: 'door',
        Colors: core.copy((this.dualColor ? this.sash.selectedColorSecond : this.sash.selectedColor)) || {
            frame: {
                outer: {},
                inner: {},
                core: {},
                alushell: {},
            },
            sash: {
                outer: {},
                inner: {},
                core: {},
                alushell: {},
            }
        },
        System: {
            type: this.sash.system_types[0]
        },
        Wood: this.sash.selectedWood || {},
        valid: {}
    };

    hasCream = false;
    setColorType(type) {
        throw new Error('Deprecated!');
    }
    openModalWood() {
        throw new Error('Deprecated!');
    }

    filter = (group) => true;
    filterColors = (color) => true;

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private sash,
        private dualColor,
        private novalidate,
        private EventBusService,
    ) {
        'ngInject';
        this.filter = (group) => (typeof sash.color_groups_ids !== 'undefined') && sash.color_groups_ids.map(Number).indexOf(parseInt(group.id)) > -1;
        this.filterColors = (color) => color.groups && this.sash.color_groups_ids.map(Number).some(c => color.groups.map(Number).indexOf(~~c) > -1);
    }

    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    close() {
        this.$uibModalInstance.close({
                colors: this.config.Colors,
                wood: this.config.Wood
        });
    }

}
