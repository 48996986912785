import { Injectable, Inject } from '@angular/core';
import { EventBusService } from '@icc/common/event-bus.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { core, logger } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';
import { IssuesService, IssueLevel, ModalService } from '@icc/helpers';
import { ValidationService } from '@icc/common/configurators/validation.service';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { SealColorsDefaultsService } from '@icc/common/colors/seal-colors-defaults.service';
import { SealColorsPageComponent } from 'libs/configurator/window/src/lib/seal-colors-page/seal-colors-page.component';
import { ConfigurationsService, ConfiguratorsDataService} from '@icc/common';

@Injectable()
export class SealColorsService {
    allColors = [];
    colors = [];

    private loadedData = false;

    constructor(
        private eventBusService: EventBusService,
        private modalService: ModalService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private issuesService: IssuesService,
        private validationService: ValidationService,
        private sealColorsDefaultsService: SealColorsDefaultsService,
        private configurationsService: ConfigurationsService<'window'>,
        private configuratorsDataService: ConfiguratorsDataService
    ) {
        eventBusService.subscribe<LoadedConfiguratorsDataValue>('setConstructionColor', data => {
            if (
                WindowActiveConfiguration.is(data.activeConfiguration)
                && WindowActiveConfiguration.is(data.defaultConfiguration)
            ) {
                this.loadMatchingColors(data.activeConfiguration as WindowActiveConfiguration);
                this.setDefaultColor(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration,
                    data.user
                );
            }
        });

        eventBusService.subscribe<LoadedConfiguratorsDataValue>('loadedConfiguratorsData', data => {
            if (config().IccConfig.Configurators.sealColorSelect) {
                this.loadColors(data.value);
                if (
                    data.activeConfiguration
                    && WindowActiveConfiguration.is(data.activeConfiguration)
                    && WindowActiveConfiguration.is(data.defaultConfiguration)
                ) {
                    this.loadMatchingColors(data.activeConfiguration as WindowActiveConfiguration);
                    this.setDefaultColor(
                        data.activeConfiguration as WindowActiveConfiguration,
                        data.defaultConfiguration as WindowActiveConfiguration,
                        data.user
                    );
                }
            }
        });

        eventBusService.subscribe<LoadedConfiguratorsDataValue>('setSystem', data => {
            try {
                this.loadMatchingColors(data.activeConfiguration as WindowActiveConfiguration);
                this.setDefaultColor(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration,
                    data.user
                );
            } catch (err) {
                logger.error(err);
            }
        });
    }

    loadColors(data) {
        this.allColors = data.windowSealColors || [];
    }

    loadMatchingColors(conf: WindowActiveConfiguration) {
        if (this.allColors.length > 0 && conf.System.id) {
            this.colors = this.allColors.filter(
                color =>
                    color.window_lines_ids
                    && color.window_lines_ids.map(Number).indexOf(Number(conf.System.id)) > -1
            );
            if(this.config().IccConfig.Configurators.sealColorDependOnConstructionColor) {
                this.colors = this.getColors();
            }
            this.loadedData = true;
        }
    }

    setDefaultColor(conf: WindowActiveConfiguration, defaultConf, user) {
        if (!this.loadedData || !conf.System || !conf.System.id) {
            return;
        }
        if (this.colors.length === 0) {
            this.issuesService.registerDataProblem(
                'no-seal-colors',
                'Brak kolorów uszczelek!',
                conf,
                {
                    level: IssueLevel.FATAL,
                    extra: {
                        systemId: Number(conf.System.id)
                    }
                }
            );
            this.validationService.invalid(conf, 'sealColor');
            return;
        }
        this.issuesService.unregister('no-seal-colors', conf);
        const shouldBeChanged =
            !Common.isObject(conf.SealColor)
            || !conf.SealColor.id
            || conf.SealColor.isDefault
            || (conf.SealColor.window_lines_ids
                && conf.SealColor.window_lines_ids.map(Number).indexOf(Number(conf.System.id))
                    === -1)
            || (this.config().IccConfig.Configurators.sealColorDependOnConstructionColor && !this.checkSealColor(conf, conf.SealColor.id));
        if (shouldBeChanged) {
            const defaultColor = this.sealColorsDefaultsService.getDefaultColor(
                this.colors,
                conf,
                user,
                defaultConf,
                this.configuratorsDataService.data.windowColorsAll
            );
            this.setColor(defaultColor, true, conf, defaultConf);
        } else {
            Object.assign(conf, this.validationService.valid(conf, 'sealColor'));
            this.eventBusService.post({
                key: 'setSealColor',
                value: {
                    colorId: conf.SealColor.id,
                },
                conf,
                defaultConf,
            });
        }
    }
    setColor(color, isDefault = false, conf: WindowActiveConfiguration, defaultConf) {
        color = core.copy(color);
        color.isDefault = isDefault;
        conf.SealColor = core.copy(color);
        defaultConf.SealColor = color;
        Object.assign(conf, this.validationService.valid(conf, 'sealColor'));
        this.eventBusService.post({
            key: 'setSealColor',
            value: {
                colorId: color.id,
            },
            conf,
            defaultConf,
        });
    }
    openModal(conf: WindowActiveConfiguration, defaultConf) {
        this.modalService
            .open({
                templateUrl: 'modalSealColor.html',
                controller: 'ModalSealColorCtrl as msealcolor',
                pageComponent: SealColorsPageComponent,
                resolve: {
                    colors: () => this.colors,
                    selColor: () => conf.SealColor,
                },
            })
            .result.then(selectedColor => {
                if (selectedColor) {
                    this.setColor(selectedColor, false, conf, defaultConf);
                }
            });
    }

    getColors(conf = this.configurationsService.conf.Current) {
        return this.colors.filter(color => this.checkSealColor(conf, color.id))
    }

    checkSealColor(conf = this.configurationsService.conf.Current, sealColorId) {
        const colors = this.configuratorsDataService.data.windowColorsAll;
        const sashOuterColor = conf.Colors.sash.outer;
        const outerColor =  colors.find(c => Number(c.id) === Number(sashOuterColor?.id));

        if(!sealColorId) {
            return false;
        }
        if(outerColor && outerColor.sealColorsIds.indexOf(Number(sealColorId)) > -1) {
            return true;
        }
        return false;
    }

}
