import { Action } from '@ngrx/store';
import { IRollerShutterActiveConfiguration } from '@icc/common/configurations/RollerShutterActiveConfiguration';

export enum ShutterActionTypes {
    LoadShutter = '[Shutter] Load Shutter',
    LoadShutterFromStartState = '[Shutter] Load Shutter From Start State',
    LoadShutterDefaultsFromStartState = '[Shutter] Load Shutter Defaults From Start State',
    LoadShutterAllDefaults = '[Shutter] Load Shutter All Defaults',
    LoadShutterConfiguration = '[Shutter] Load Shutter Configuration',
    ShutterLoaded = '[Shutter] Shutter Loaded',
    ShutterLoadError = '[Shutter] Shutter Load Error',
    SaveShutterConfiguration = '[Shutter] Save Shutter Configuration',
    UpdateShutterConfiguration = '[Shutter] Update Shutter Configuration',
}

export class LoadShutter implements Action {
    readonly type = ShutterActionTypes.LoadShutter;
}

export class ShutterLoadError implements Action {
    readonly type = ShutterActionTypes.ShutterLoadError;
    constructor(public payload: any) {}
}

export class LoadShutterFromStartState implements Action {
    readonly type = ShutterActionTypes.LoadShutterFromStartState;
    constructor(public payload: { startState: IRollerShutterActiveConfiguration }) {}
}

export class LoadShutterDefaultsFromStartState implements Action {
    readonly type = ShutterActionTypes.LoadShutterDefaultsFromStartState;
    constructor(public payload: { startState: IRollerShutterActiveConfiguration }) {}
}

export class LoadShutterAllDefaults implements Action {
    readonly type = ShutterActionTypes.LoadShutterAllDefaults;
}

export class LoadShutterConfiguration implements Action {
    readonly type = ShutterActionTypes.LoadShutterConfiguration;
    constructor(public payload: { configuration: IRollerShutterActiveConfiguration }) {}
}

export class ShutterLoaded implements Action {
    readonly type = ShutterActionTypes.ShutterLoaded;
}

export class SaveShutterConfiguration implements Action {
    readonly type = ShutterActionTypes.SaveShutterConfiguration;
}

export class UpdateShutterConfiguration implements Action {
    readonly type = ShutterActionTypes.UpdateShutterConfiguration;
    constructor(public payload: { configuration: IRollerShutterActiveConfiguration }) {}
}

export type ShutterAction = LoadShutter
    | ShutterLoaded
    | ShutterLoadError
    | LoadShutterFromStartState
    | LoadShutterDefaultsFromStartState
    | LoadShutterAllDefaults
    | LoadShutterConfiguration
    | UpdateShutterConfiguration;

export const fromShutterActions = {
    LoadShutter,
    ShutterLoaded,
    ShutterLoadError,
    LoadShutterFromStartState,
    LoadShutterDefaultsFromStartState,
    LoadShutterAllDefaults,
    LoadShutterConfiguration,
};
