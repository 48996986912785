import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, _, isDefined, isObject } from '@icc/configurator/shared';
import {
    APP_CONFIG,
    AppConfigFactory,
    DrawService,
    ConfigurationsService,
    TranslateService,
    EventBusService,
} from '@icc/common';
import { ShutterFacade } from '../+state/shutter.facade';
import { RollerDimensionsService } from '@icc/legacy/configurator/steps/roller_shutter/dimensions.service';
import { Subscription } from 'rxjs';
import { ColorsService } from '@icc/legacy/configurator/steps/roller_shutter/colors.service';
import { Profile } from '@icc/window';
import { map } from 'rxjs/operators';
import { DrivesService } from '@icc/legacy/configurator/steps/roller_shutter/drives.service';

@Component({
    selector: 'icc-dimensions-options-page',
    templateUrl: './dimensions-options-page.component.html',
    styleUrls: ['./dimensions-options-page.component.scss'],
})
export class DimensionsOptionsPageComponent extends PageComponent implements OnInit {
    public title = _('INTERFACE|Zaawansowane');

    private subscriptions: Subscription[] = [];

    profiles: Profile[] = [];
    roundReels: any[] = [];
    boxSizes: any[] = [];
    profile$ = this.shutterFacade.profile$;
    roundReel: any = null;
    roundReel$ = this.shutterFacade.roundReel$.pipe(map(reel => reel.id));
    availableRoundReel = false;
    boxHeight$ = this.shutterFacade.boxHeight$;

    selectedConfiguratorType$ = this.shutterFacade.selectedConfiguratorType$;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private shutterFacade: ShutterFacade,
        public drawService: DrawService,
        private rollerDimensionsService: RollerDimensionsService,
        private colorsService: ColorsService,
        private configurationsService: ConfigurationsService<'roller_shutter' | 'external_blind'>,
        private translateService: TranslateService,
        private eventBusService: EventBusService,
        private drivesService: DrivesService,
    ) {
        super();
    }

    ngOnInit() {
        if (this.rollerDimensionsService.loadedData) {
            this.init();
        }
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }

    init() {
        this.profiles = this.rollerDimensionsService.getProfiles();
        this.roundReels = this.rollerDimensionsService.roundReels;
        this.boxSizes = this.rollerDimensionsService.boxSizes;
        this.boxSizes.sort((a,b)=>a-b);
        if (isObject(this.configurationsService.conf.Current.RollerShutter.roundReel)) {
            this.roundReel = this.configurationsService.conf.Current.RollerShutter.roundReel.id;
        } else {
            this.roundReel = isObject(this.rollerDimensionsService.roundReels[0])
                ? this.rollerDimensionsService.roundReels[0].id
                : null;
            this.rollerDimensionsService.changeRoundReel(this.roundReel);
        }
        this.availableRoundReel = this.config().IccConfig.Configurators.roller_shutter.roundReels && this.roundReels && this.roundReels.length > 1;
    }

    /**
     * Funkcja ustawia profil
     */
    changeProfile() {
        this.rollerDimensionsService.selectProfile();
    }

    /**
     * Funkcja ustawia rurę nawojową
     */
    changeRoundReel(roundReelId: number) {
        const roundReel = (this.roundReels || []).find(e => e.id === roundReelId);
        this.rollerDimensionsService.changeRoundReel(isDefined(roundReel) ? roundReel : null);
        this.drivesService.setDrive(this.configurationsService.conf.Current.RollerShutter.drive);
        this.rollerDimensionsService.updateDimensions();
    }

    onBoxHeightChange(boxHeight) {
        this.rollerDimensionsService.setBoxHeightChangedManually(true);
        this.rollerDimensionsService.onBoxHeightChange(boxHeight);
    }
}
