/**
 * Kontroler modala alternatyw
 * @param {Object} $uibModalInstance Instancja modalu
 * @param {Object} alts           Alternatywy
 */
export default function ModalAlternativesCtrl($uibModalInstance, alts) {
    'ngInject';

    var vm = this;
    vm.alts = alts;

    vm.close = function() {
        $uibModalInstance.close();
    };
}
