<icc-select-box [title]="'MOSQUITO|Rodzaj mocowania' | translate"
                [description]="(selectedMontage$ | async)?.name"
                [imageUrl]="'/files/mosquitomontage/' + ((selectedMontage$ | async)?.img || '')"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="changeMontage()"
                *ngIf="(selectedMontage$ |async)"
></icc-select-box>

<div class="box-form mat-elevation-z3" *ngIf="showProfilePositionSelection$ | async">
    <h4>{{ 'MOSQUITO|Wybierz z której strony ma się znajdować profil dodatkowy:' | translate }}</h4>
    <form [formGroup]="profilePositionForm">
        <mat-checkbox name="profile-position-up" formControlName="up">{{ 'INTERFACE|Góra'| translate }}</mat-checkbox>
        <mat-checkbox name="profile-position-down" formControlName="down">{{ 'INTERFACE|Dół'| translate }}</mat-checkbox>
        <mat-checkbox name="profile-position-left" formControlName="left">{{ 'INTERFACE|Lewa'| translate }}</mat-checkbox>
        <mat-checkbox name="profile-position-right" formControlName="right">{{ 'INTERFACE|Prawa'| translate }}</mat-checkbox>
    </form>
</div>

<div class="box-form mat-elevation-z3" *ngIf="showMuntinPositionSelection$ | async">
    <h4>{{ 'MOSQUITO|Wysokość szprosu' | translate }}</h4>
    <form [formGroup]="muntinPositionForm">
        <mat-radio-group formControlName="muntinType">
            <mat-radio-button value="standard">{{ 'CONFIGURATOR|Standardowa'| translate }}</mat-radio-button>
            <mat-radio-button value="custom">{{ 'CONFIGURATOR|Inna wysokość (mm):'| translate }}</mat-radio-button>
        </mat-radio-group>
        <mat-form-field *ngIf="muntinPositionForm.get('muntinType')?.value === 'custom'">
            <input matInput iccDimensionInput type="number" formControlName="position"/>
        </mat-form-field>
    </form>
</div>

<div class="box-form mat-elevation-z3" *ngIf="windowLines.length > 0">
    <h4>{{ 'MOSQUITO|Wybierz rozmiar uchwytów'| translate }}</h4>
    <form [formGroup]="holdersForm">
        <mat-radio-group formControlName="line">
            <mat-radio-button *ngFor="let line of windowLines" [value]="line.id">{{line.content}}</mat-radio-button>
            <mat-radio-button [value]="null">{{ 'CONFIGURATOR|Inny rozmiar'| translate }}</mat-radio-button>
        </mat-radio-group>
        <mat-form-field *ngIf="!holdersForm.get('line')?.value">
            <input matInput iccDimensionInput type="number" formControlName="width">
        </mat-form-field>
    </form>
</div>

<div class="box-form mat-elevation-z3" *ngIf="showThickSealSelection$ | async">
    <form [formGroup]="thickSealForm">
        <mat-slide-toggle formControlName="hasThickSeal">{{ 'MOSQUITO|Słupek niezlicowany'| translate }}</mat-slide-toggle>
        <label *ngIf="thickSealForm.get('hasThickSeal')?.value">{{ 'MOSQUITO|Grubość uszczelki'| translate }}</label>
        <mat-radio-group formControlName="sealThickness"  *ngIf="thickSealForm.get('hasThickSeal')?.value">
            <mat-radio-button [value]="10">10 {{dimensionUnit.unitSymbol}}</mat-radio-button>
            <mat-radio-button [value]="15">15 {{dimensionUnit.unitSymbol}}</mat-radio-button>
        </mat-radio-group>
    </form>
</div>