<icc-select-box
    [title]="'GARAGEDOOR|Rodzaj sprężyn' | translate"
    [description]="(selectedMechanism$ | async)?.name"
    [imageUrl]="'/files/garagedoormechanism/' + (selectedMechanism$ | async)?.img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="selectMechanism()"
></icc-select-box>

<icc-select-box
    [title]="'GARAGEDOOR|Prowadzenie' | translate"
    [description]="(selectedGuidance$ | async)?.name"
    [imageUrl]="'/files/garagedoorguide/' + (selectedGuidance$ | async)?.img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="selectGuidance()"
></icc-select-box>

<form class="dimensions-form" [formGroup]="dimensionsForm">
    <mat-form-field class="" *ngIf="(selectedGuidance$ | async)?.is_high_guide">
        <mat-label translate>DIMENSION|Prowadzenie od krawędzi nadproża (H)</mat-label>
        <input matInput #fieldValue iccDimensionInput step="5" type="number" formControlName="guidanceLift">
        <mat-hint class="hint-danger" *ngIf="(fieldValue.value % 5 != 0)">{{ 'DIMENSION|Wartość powinna być podzielna przez 5' | translate }}</mat-hint>
        <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
    </mat-form-field>
</form>