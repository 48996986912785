import $ from 'jquery';

/**
 * Kontroler okna modalnego z wyborem kolorow
 * @param {object}   $uibModalInstance     Modal
 * @param {object}   $timeout           Timeout
 * @param {function} colorsByTypeFilter Funkcja zwraca kolory wg typu
 * @param {array}    colors             Lista kolorow
 * @param {object}   colorGroups        Grupy kolorow
 * @param {object}   selColor           Wybrany kolor
 */
export default function ModalColorCtrl($uibModalInstance, $timeout, colorsByTypeFilter, colors, colorGroups, selColor, type, ScrollbarService) {
    'ngInject';

    var vm = this;

    vm.colors    = colors;
    vm.selColor  = selColor;
    vm.type      = type;
    vm.colorGroups = colorGroups;

    vm.standard = colorsByTypeFilter(vm.colors, 'standard').length > 0;
    vm.RAL      = colorsByTypeFilter(vm.colors, 'RAL').length > 0;
    vm.veneer   = colorsByTypeFilter(vm.colors, 'veneer').length > 0;
    vm.custom   = colorsByTypeFilter(vm.colors, 'custom').length > 0;
    vm.sRAL     = colorsByTypeFilter(vm.colors, 'sRAL').length > 0;

    vm.selectColor = selectColor;
    vm.close       = close;
    vm.refresh     = refresh;
    vm.colorType   = selColor ? selColor.group : null;

    vm.selectedGroup = 0;
    const selectedGroup = selColor.groups != null && selColor.groups.find(g => colorGroups.some( el => el.id == g));
    if (selectedGroup) {
        vm.selectedGroup = Number(selectedGroup);
    }

    /**
     * Wybiera kolor i zamyka modala
     * @param  {object} color Wybrany kolor
     */
    function selectColor(color) {
        $uibModalInstance.close({color, type: vm.type});
    }

    /**
     * Zamyka modala bez zmian
     */
    function close() {
        $uibModalInstance.close();
    }

    /**
     * Odswieza plugin do przewijania, by porawic dlugosc scolla
     */
    function refresh() {
        ScrollbarService.update();
    }

}
