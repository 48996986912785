import { core } from '../../../helpers';

/**
 * Szprosy - zmiana struktury danych - z dwóch osobnych tabel (horizontal, vertical)
 * do wspólnej listy punktów (start, end)
 */
export function changesInVersion6(oldConfiguration, dataRequiredToUpdate) {
    const configuration = core.copy(oldConfiguration);
    if (configuration.sashes && configuration.sashes.length) {
        configuration.sashes
            .reduce((sashes, sash) => sashes.concat([sash], sash.intSashes), [])
            .map(sash => muntinsConversion(sash));
    }
    return configuration;
}

export function muntinsConversion(sash) {
    const newMuntins = [];
    let newMuntin;

    [].concat(...sash.muntins.horizontal, ...sash.muntins.vertical).map(muntin => {
        newMuntin = {
            id: null,
            start: {
                x: muntin.direction === 'vertical' ? muntin.positionInSash : 0,
                y: muntin.direction === 'horizontal' ? muntin.positionInSash : 0,
            },
            end: {
                x: muntin.direction === 'vertical' ? muntin.positionInSash : sash.width,
                y: muntin.direction === 'horizontal' ? muntin.positionInSash : sash.height,
            },
        };

        if (Number.isInteger(muntin.startPinPoint)) {
            newMuntin.start[muntin.direction === 'horizontal' ? 'x' : 'y'] = sash.muntins[
                muntin.direction === 'horizontal' ? 'vertical' : 'horizontal'
            ].find(el => el.id === muntin.startPinPoint).positionInSash;
        }

        if (Number.isInteger(muntin.endPinPoint)) {
            newMuntin.end[muntin.direction === 'horizontal' ? 'x' : 'y'] = sash.muntins[
                muntin.direction === 'horizontal' ? 'vertical' : 'horizontal'
            ].find(el => el.id === muntin.endPinPoint).positionInSash;
        }

        newMuntins.push(newMuntin);
    });

    newMuntins.forEach((muntin, index) => (muntin.id = index));

    sash.muntins = newMuntins;
}
