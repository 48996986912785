export default class SystemsSelectModalController {
    selectedSystems = [];
    showDetails = false;
    detailsConfig;

    resolve: {
        systems;
        multiple;
    };
    close: ({ $value }) => any;
    dismiss: () => any;

    constructor(
        private ScrollbarService,
        public IccConfig,
    ) {}

    $onChanges() {
        this.ScrollbarService.update();
    }

    toggleSystem(system) {
        if (this.resolve.multiple) {
            const position = this.selectedSystems.indexOf(system.id);
            if (position > -1) {
                this.selectedSystems.splice(position, 1);
            } else {
                this.selectedSystems.push(system.id);
            }
        } else {
            this.close({ $value: system })
        }
    }

    calculateSystems() {
        this.close({ $value: this.selectedSystems });
    }

    closeModal() {
        this.close({ $value: false });
    }
}
