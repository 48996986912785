import format from 'date-fns/format';
import {core} from '@icc/common/helpers';

export default function PositionAttachmentsFactory($q, $rootScope, DatabaseManager, machine, EventBusService, OffersService) {
    'ngInject';

    var factory = {
        add,
        addMulti,
        update,
        remove,
        removeMulti,
        list,
        get,
        getForPosition,
        getForOffer,
        multiCopy
    };

    return factory;

    /**
     * Konstruktor zalacznika do pozycji
     * @param {object} params Domyślne parametry
     */
    function PositionAttachment(params) {
        var tmpId = 'tmp_PositionAttachment_' + core.generateUUID();

        this.id                       = params.id || tmpId;
        this.tmp_id                   = params.tmp_id || tmpId;
        this.dealer_offer_position_id = params.dealer_offer_position_id || params.position.tmp_id || params.attachment.position;
        this.dealer_offer_id          = params.dealer_offer_id || params.position.dealer_offer_id;
        this.name                     = params.name || params.attachment.name;
        this.data                     = params.data || params.attachment;
        this.type                     = params.type || params.attachment.type;
        this.position_number          = Number.isInteger(params.attachment.position_number) ? params.attachment.position_number : null;
        this.deleted                  = false;
        this.synced                   = false;
        this.created                  = params.created || format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    }

    /**
     * Dodawanie zalacznika
     * @param {object} params Domyslne parametry zalacznika
     */
    function add(params, emit = true) {
        var deferred = $q.defer();

        var newAttachment = new PositionAttachment(params);

        DatabaseManager.get('PositionAttachment').create(newAttachment).then((res) => {
            if (emit) {
                OffersService.emitModifiedOffer();
            }
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    /**
     * Dodawanie wielu zalacznikow
     * @param {array} attachments Tablica zalacznikow
     * @param {string} position   Id pozycji do ktorej dodawany jest zalacznik
     */
    function addMulti(attachments, position, emit = true) {
        var promises = attachments.map((att) => {
            return factory.add({
                attachment: att,
                position
            }, false);
        });
        return $q.all(promises).then((rows) => {
            if (emit) {
                OffersService.emitModifiedOffer();
            }
            return $q.resolve(rows);
        });
    }

    /**
     * Funkcja edycji obiektu
     * @param  {string}   _id      id
     * @param  {object}   object   obiekt
     * @param  {Function} callback funkcja
     * @return {object}            Obietnica
     */
    function update(_id, object, callback) {
        var deferred = $q.defer();
        DatabaseManager.get('PositionAttachment').update(object, {internalId: _id, machine}).then((res) => {
            OffersService.emitModifiedOffer();
            deferred.resolve(res, callback);
        });
        return deferred.promise;
    }

    /**
     * Funkcja usuwajca obiekt
     * @param  {string} _id     id
     * @param  {object} object  obiekt
     * @return {object}         Obietnica
     */
    function remove(_id, object) {
        var deferred = $q.defer();
        DatabaseManager.get('PositionAttachment').remove(object).then((res) => {
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    function removeMulti(attachments) {
        var deferred = $q.defer();
        DatabaseManager.get('PositionAttachment').removeMany(attachments).then((res) => {
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    /**
     * Funkcja listy załącznika
     * @return {object}     obietnica
     */
    function list() {
        var deferred = $q.defer();
        DatabaseManager.get('PositionAttachment').getAll().then((res) => {
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    /**
     * Funckja pobieania zalacznika
     * @param  {string} _id     Id
     * @return {object}         Obietnica
     */
    function get(_id) {
        var deferred = $q.defer();

        var EditPositionsAttachment = DatabaseManager.get('PositionAttachment');

        EditPositionsAttachment.get(_id).then(function afterGetPositionAttachment(res) {
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    /**
     * Funkcja pobierania zalacznikow dla pozycji
     * @param  {string} _id        Id
     * @param  {function} callback Funkcja po pobraniu
     * @param  {boolean}  getAtts  Czy pobierac zalaczniki
     * @return {object}            Obietnica
     */
    function getForPosition(_id, callback, getAtts) {
        var deferred = $q.defer();

        DatabaseManager.get('PositionAttachment').getAll('by_dealer_offer_position_id', {key: _id, attachments: getAtts}).then((res) => {
            deferred.resolve(res);
        }, (err) => {
            deferred.reject(err);
        });

        return deferred.promise;
    }

    /**
     * Funkcja pobierania zalacznikow dla calej oferty
     * @param  {string} _id        Id
     * @param  {boolean}  getAtts  Czy pobierac zalaczniki
     * @return {object}            Obietnica
     */
    function getForOffer(_id, getAtts) {
        var deferred = $q.defer();

        var EditPositionsAttachment = DatabaseManager.get('PositionAttachment');

        EditPositionsAttachment.getAll('by_dealer_offer_id', {key: _id, attachments: getAtts}).then(function successPositionsAttachmentsGet(res) {
            deferred.resolve(res);
        }, function errorPositionsAttachmentsGet(err) {
            deferred.reject(err);
        });

        return deferred.promise;
    }

    /**
     * Kopiuje wszystkie zalacnziki pozycji
     * @param  {string} source      Id oferty zrodlowej
     * @param  {string} destination Id oferty docelowej
     * @param  {object} relations   Powiazania poozycji
     * @return {object}             Angular Promise
     */
    function multiCopy(source, destination, relations) {
        var deferred = $q.defer();
        getForOffer(source, true).then((res) => {
            addMulti(res.map((e) => {
                return core.stringToBlob(e.doc._attachments[e.doc.name].data, e.doc.type, e.doc.name, relations[e.doc.dealer_offer_position_id]);
            }), {dealer_offer_id: destination}).then(() => {
                OffersService.emitModifiedOffer();
                deferred.resolve();
            });
        });
        return deferred.promise;
    }

}
