<ng-container>
  <form [formGroup]='clearanceInputsForm'>
    <div class='clearance_form'>
      <div class='clearance_form__column no_mobile'>
        <span class='no_mobile'></span>
        <p class='column__caption column__caption--left '>{{'WINDOW|Dół konstrukcji' | translate}}</p>
        <span class='no_mobile'></span>
        <p class='column__caption column__caption--left'>{{'WINDOW|Góra konstrukcji' | translate}}</p>
        <p class='column__caption column__caption--left'>{{'WINDOW|Lewa strona konstrukcji' | translate}}</p>
        <p class='column__caption column__caption--left'>{{'WINDOW|Prawa strona konstrukcji' | translate}}</p>
      </div>
      <div class='clearance_form__column'>
        <ng-container formGroupName='distanceOut'>
          <p class='column__caption'>{{'WINDOW|Odległość na parapet i/lub profile poszerzeń' | translate}}</p>
          <mat-form-field class='dimensions_inputs__form_field  dimensions_inputs__form_field--bottom'>
            <input formControlName='bottom' #measurementsinput matInput iccDimensionInput type='number' min='0' id="distanceOut-bottom">
            <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
          <ng-container *ngIf="!(checkField('enlargementTop') && checkField('enlargementRight') && checkField('enlargementLeft') && fit === 'frame')">
            <p class='column__caption'> {{'WINDOW|Luzy montażowe' | translate}}</p>
            <mat-form-field class='dimensions_inputs__form_field dimensions_inputs__form_field--top' *ngIf="checkField('distanceOutTop')">
              <input formControlName='top' #measurementsinput matInput iccDimensionInput type=' number' min='0' id="distanceOut-top">
              <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
            </mat-form-field>
            <mat-form-field class='dimensions_inputs__form_field dimensions_inputs__form_field--left' *ngIf="checkField('distanceOutLeft')">
              <input formControlName='left' #measurementsinput matInput iccDimensionInput type='number' min='0' id="distanceOut-left">
              <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
            </mat-form-field>
            <mat-form-field class='dimensions_inputs__form_field dimensions_inputs__form_field--right' *ngIf="checkField('distanceOutRight')">
              <input formControlName='right' #measurementsinput matInput iccDimensionInput type='number' min='0' id="distanceOut-right">
              <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
            </mat-form-field>
          </ng-container>
        </ng-container>
        <ng-container formGroupName='enlargement' *ngIf="checkField('enlargementTop') && checkField('enlargementRight') && checkField('enlargementLeft') && fit === 'frame'">
          <p class='column__caption'> {{'WINDOW|Powiększenie konstrukcji' | translate}}</p>
          <mat-form-field class='dimensions_inputs__form_field dimensions_inputs__form_field--top'>
            <input formControlName='top' #measurementsinput matInput iccDimensionInput type='number' min='0' id="enlargement-top">
            <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
          <mat-form-field class='dimensions_inputs__form_field dimensions_inputs__form_field--left'>
            <input formControlName='left' #measurementsinput matInput iccDimensionInput type='number' min='0' id="enlargement-left">
            <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
          <mat-form-field class='dimensions_inputs__form_field dimensions_inputs__form_field--right'>
            <input formControlName='right' #measurementsinput matInput iccDimensionInput type='number' min='0' id="enlargement-right">
            <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
        </ng-container>
      </div>
      <div formGroupName='distanceIn' *ngIf="checkField('distanceInTop') && checkField('distanceInRight') && checkField('distanceInLeft')" class='clearance_form__column'>
        <span class='no_mobile'></span>
        <span class='no_mobile'></span>
        <p class='column__caption'>{{'WINDOW|Rozmiary węgarków' | translate}}</p>
        <mat-form-field class='dimensions_inputs__form_field dimensions_inputs__form_field--top'>
          <input formControlName='top' #measurementsinput matInput iccDimensionInput type='number' min='0' id="distanceIn-top">
          <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
        </mat-form-field>
        <mat-form-field class='dimensions_inputs__form_field dimensions_inputs__form_field--left'>
          <input formControlName='left' #measurementsinput matInput iccDimensionInput type='number' min='0' id="distanceIn-left">
          <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
        </mat-form-field>
        <mat-form-field class='dimensions_inputs__form_field dimensions_inputs__form_field--right'>
          <input formControlName='right' #measurementsinput matInput iccDimensionInput type='number' min='0' id="distanceIn-right">
          <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
        </mat-form-field>
      </div>
    </div>
  </form>
</ng-container>
