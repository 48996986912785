import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
    SHUTTER_FEATURE_KEY,
    initialState as shutterInitialState,
    shutterReducer,
} from './+state/shutter.reducer';
import { ShutterEffects } from './+state/shutter.effects';
import { ShutterFacade } from './+state/shutter.facade';
import { SharedModule } from '@icc/configurator/shared';
import { TypesComponent } from './type/type.component';
import { SystemsComponent } from './system/systems.component';
import { SchemaComponent } from './schema/schema.component';
import { DimensionsComponent } from './dimensions/dimensions.component';
import { ColorsComponent } from './colors/colors.component';
import { DrivesComponent } from './drives/drives.component';
import { ColorsPageComponent } from './colors-page/colors-page.component';
import { DimensionsInfoComponent } from './dimensions-info/dimensions-info.component';
import { DrivesPageComponent } from './drives-page/drives-page.component';
import { DrivesAccessoriesPageComponent } from './drives-accessories-page/drives-accessories-page.component';
import { MontagesInfoPageComponent } from './montages-info-page/montages-info-page.component';
import { GuidesPageComponent } from './guides-page/guides-page.component';
import { SlatsPageComponent } from './slats-page/slats-page.component';
import { DimensionsOptionsPageComponent } from './dimensions-options-page/dimensions-options-page.component';
import { MontageComponent } from './montage/montage.component';
import { RollerShutterModule } from '@icc/legacy/configurator/steps/roller_shutter/roller-shutter.module';
import { SystemsPageComponent } from './systems-page/systems-page.component';
import { DriveManualsPageComponent } from './drive-manuals-page/drive-manuals-page.component';
import { DriveSidePageComponent } from './drive-side-page/drive-side-page.component';
import { ConfiguratorUiModule } from '@icc/configurator/ui';
import { ConfiguratorPagesModule } from '@icc/configurator/pages';
import { TypeInfoComponent } from './type-info/type-info.component';
import { TypeInfoHangerComponent } from './type-info-hanger/type-info-hanger.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ConfiguratorUiModule,
        ConfiguratorPagesModule,
        StoreModule.forFeature(SHUTTER_FEATURE_KEY, shutterReducer, {
            initialState: shutterInitialState,
        }),
        EffectsModule.forFeature([ShutterEffects]),

        RollerShutterModule,
    ],
    providers: [ShutterFacade],
    declarations: [
        TypesComponent,
        SystemsComponent,
        SchemaComponent,
        DimensionsComponent,
        ColorsComponent,
        DrivesComponent,
        ColorsPageComponent,
        DimensionsInfoComponent,
        DrivesPageComponent,
        DrivesAccessoriesPageComponent,
        MontagesInfoPageComponent,
        GuidesPageComponent,
        SlatsPageComponent,
        DimensionsOptionsPageComponent,
        MontageComponent,
        SystemsPageComponent,
        DriveManualsPageComponent,
        DriveSidePageComponent,
        TypeInfoComponent,
        TypeInfoHangerComponent,
    ],
    exports: [
        TypesComponent,
        SystemsComponent,
        SchemaComponent,
        DimensionsComponent,
        ColorsComponent,
        DrivesComponent,
        ColorsPageComponent,
        DimensionsInfoComponent,
        DrivesPageComponent,
        DrivesAccessoriesPageComponent,
        MontagesInfoPageComponent,
        GuidesPageComponent,
        SlatsPageComponent,
        DimensionsOptionsPageComponent,
        MontageComponent,
        SystemsPageComponent,
        DriveManualsPageComponent,
        DriveSidePageComponent,
    ],
    entryComponents: [
        TypesComponent,
        SystemsComponent,
        SchemaComponent,
        DimensionsComponent,
        ColorsComponent,
        DrivesComponent,
        ColorsPageComponent,
        DimensionsInfoComponent,
        DrivesPageComponent,
        DrivesAccessoriesPageComponent,
        MontagesInfoPageComponent,
        GuidesPageComponent,
        SlatsPageComponent,
        DimensionsOptionsPageComponent,
        MontageComponent,
        SystemsPageComponent,
        DriveManualsPageComponent,
        DriveSidePageComponent,
        TypeInfoComponent
    ],

})
export class ConfiguratorShutterModule {}
