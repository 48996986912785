import { StepFactory } from './StepFactory';
import { StepsService } from '@icc/helpers';

export function stepFactoryFactory(i: any) {
    return i.get('StepFactory');
}
export const stepFactoryProvider = {
    provide: StepsService,
    useFactory: stepFactoryFactory,
    deps: ['$injector'],
};

