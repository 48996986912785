export default class TaxRatesService {

    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
    }

    closeModal() {
        this.modalInstance.close();
    }

    openModal(systems_tax_rates, callback, taxes) {
        this.modalInstance = this.$uibModal.open({
            templateUrl: '/application/offer/offer_view/tax_rates.html',
            controller : 'ModalTaxRatesCtrl as $ctrl',
            resolve: {
                systems_tax_rates: () => systems_tax_rates,
                callback: () => callback,
                taxes: () => taxes
            }
        });

        this.modalInstance.closed.then(() => {

        });

        return this.modalInstance.result.then(() => {

        });
    }

}
