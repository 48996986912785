import angular from 'angular';
import $ from 'jquery';

export default function WinterGardenCtrl($scope, Core, $timeout, $filter, WinterGardenFactory, StepFactory, ConfigurationsService, EventBusService, ScrollbarService) {
    var vm = this;

    vm.refresh               = refresh;
    vm.winterGardens         = [];
    vm.selectGarden          = selectGarden;

    if (WinterGardenFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    $timeout(function() {
        $('.scroll.ps-container').scroll();
    }, 10);

    function init() {
        vm.winterGardens = WinterGardenFactory.winterGardens.data || [];
        if (!angular.isObject(ConfigurationsService.conf.Current.Garden)
            || angular.isUndefined(ConfigurationsService.conf.Current.Garden.id)
        ) {
            selectGarden(vm.winterGardens[0]);
        }
    }

    function refresh() {
        ScrollbarService.update();
    }

    function selectGarden(garden, nextStep) {
        if (!garden) {
            return;
        }

        garden.dimensions = {};
        garden.dimensions_data.map(function(e) {
            if (e.label != '|') {
                garden.dimensions[e.label] = ~~e.value;
            }
        });
        garden.facets_data.map(function(e) {
            if (!garden.handle_facet) {
                garden.handle_facet = e.name;
            }
        });
        ConfigurationsService.conf.Current.Garden = Core.copy(garden);
        ConfigurationsService.conf.Default.Garden = Core.copy(garden);

        ConfigurationsService.conf.Current.Name = $filter('translate')('WINTERGARDEN|Ogrody zimowe');

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
    }
}
