<icc-select-box [title]="'ROLLERSHUTTER|Sposób sterowania:' | translate"
                [description]="(drive$ | async)?.name"
                [imageUrl]="'/files/rollershutterdrive/' + ((drive$ | async)?.img || '')"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="openModalDrives()"
                *ngIf="!drivesRadio"
                [descriptionTemplate]="engineDescription"
                [descriptionContext]="engineDriveDescriptionContext"
></icc-select-box>

<div class="box-form mat-elevation-z3" *ngIf="drivesRadio">
    <h4>{{ 'ROLLERSHUTTER|Sposób sterowania:' | translate }}</h4>
    <mat-radio-group name="drive" [ngModel]="driveType$ | async" (ngModelChange)="changeDriveType($event)">
        <mat-radio-button *ngIf="(driveManuals$ | async)?.length" value="manual">{{ 'ROLLERSHUTTER|Sterowanie ręczne' | translate }}</mat-radio-button>
        <mat-radio-button *ngIf="showDriveElectrical" value="electrical">{{ 'ROLLERSHUTTER|Sterowanie elektryczne' | translate }}</mat-radio-button>
    </mat-radio-group>
    <p *ngIf="isShutterToSmallForElectrical">{{ 'ROLLERSHUTTER|Sterowanie elektryczne dotępne dla rolety o szerokości większej niż' | translate }} {{driveElectricalWidth}} <span>{{ dimensionUnit.unitSymbol }}</span></p>
</div>
<icc-select-box [title]="'ROLLERSHUTTER|Sposób sterowania:' | translate"
                [description]="(drive$ | async)?.name+' '+(drive$ | async)?.driveEngine?.name"
                [imageUrl]="'/files/rollershutterdrive/' + ((drive$ | async)?.img || '')"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="openModalElectricalDrives()"
                *ngIf="(driveType$ | async) == 'electrical' && drivesRadio"
                [descriptionTemplate]="engineDescription"
                [descriptionContext]="engineDriveDescriptionContext"
></icc-select-box>
<ng-template let-system let-engineName="engineName"  let-engineGroupsIndicators="engineGroupsIndicators" let-engineGroups="engineGroups" #engineDescription >
        <p>{{system}}</p>
        <ul *ngIf="engineGroups?.length > 1 || engineGroups[0]?.length > 1">
                    <li *ngFor='let engine of engineGroups; let i = index'>
                        <p>{{('OFFER|Kwatera' | translate) + ' ' + engineGroupsIndicators[i] + ': ' + (engineName[engine[0]]?.name ? engineName[engine[0]]?.name : ('ROLLERSHUTTER|Brak silnika' | translate) )}}</p>
                    </li>
        </ul>
        <p *ngIf="engineGroups?.length === 1 && engineGroups[0]?.length === 1 && engineName[0]?.name " class='engineName'>{{('ROLLERSHUTTER|Nazwa silnika' | translate) +': '+ engineName[0].name}}</p>
</ng-template>

<div class="box-form mat-elevation-z3" *ngIf="(showDriveOverride$ | async) && checkOverride('override') && checkOverride('none')">
    <mat-radio-group name="driveType" [ngModel]="driveOverride$ | async" (ngModelChange)="changeOverride($event)">
        <mat-radio-button value="none">{{ 'OFFER|Bez przeciążenia' | translate }}</mat-radio-button>
        <mat-radio-button value="override">{{ 'OFFER|Przeciążeniowy z detekcją przeszkody' | translate }}</mat-radio-button>
    </mat-radio-group>
</div>
<div class="box-form mat-elevation-z3" *ngIf="(drive$ | async)?.type !== 'manual'">
    <h4>{{ 'ROLLERSHUTTER|Dodatki do sterowania:' | translate }}</h4>
    <button mat-flat-button color="primary" (click)="addAccessoriesToDrive()">{{ 'INTERFACE|Dodaj' | translate }}</button>
    <div *ngFor="let item of (driveElements$ | async)" class="icc-list-item">
        <ng-container *ngTemplateOutlet="accessoryItem;context:{accessory: item}"></ng-container>
    </div>
</div>
<icc-select-box [title]="'ROLLERSHUTTER|Rodzaj sterowania:' | translate"
                [description]="(driveManual$ | async)?.name"
                [imageUrl]="'/files/rollershutterdrivesmanual/' + ((driveManual$ | async)?.image || '')"
                [buttonText]="'INTERFACE|Zmień' | translate"
                (select)="openModalDrivesManuals()"
                *ngIf="(drive$ | async)?.type === 'manual' && !drivesRadio"
></icc-select-box>
<div class="box-form mat-elevation-z3" *ngIf="(driveManuals$ | async)?.length && (drive$ | async)?.type === 'manual' && drivesRadio && (selectedConfiguratorType$ | async) !== 'external_blind'">
    <h4>{{ 'ROLLERSHUTTER|Rodzaj sterowania:' | translate }}</h4>
    <mat-radio-group name="driveManual" [ngModel]="(driveManualType$ | async)" (ngModelChange)="changeDriveManual($event)">
        <mat-radio-button *ngFor="let manual of (driveManuals$ | async)" [value]="manual.id">{{ manual.name }}</mat-radio-button>
    </mat-radio-group>
    <div *ngIf="['r', 'cr'].indexOf((driveManualType$ | async)) > -1">
        <h4>{{ 'ROLLERSHUTTER|Kolor zwijacza:' | translate }}</h4>
        <icc-list [items]="retractorColors" itemTemplate="itemColor" [scrollable]="false" [selected]="(driveManualTypeColor$ | async)" (select)="changeDriveManualColor($event)">
        </icc-list>
    </div>
</div>
<div class="box-form mat-elevation-z3" *ngIf="((selectedConfiguratorType$ | async) === 'external_blind' && (driveType$ | async) === 'manual')">
    <h4>{{ 'ROLLERSHUTTER|Korba:' | translate }}</h4>
    <mat-form-field *ngIf='!this.isBlindDataStatic'>
        <mat-label>{{ 'DIMENSION|Długość korby' | translate }}</mat-label>
        <input
          matInput
          iccDimensionInput
          type="number"
          [(ngModel)]="crankLength"
          (ngModelChange)="setCrankLength($event)"
        >
        <span matSuffix>{{ dimensionUnit.unitSymbol }}</span>
      </mat-form-field>
      <mat-form-field *ngIf='this.isBlindDataStatic'>
        <mat-label>{{ 'AWNING|Długość korby' | translate }}</mat-label>
        <mat-select [(ngModel)]="crankLength" (ngModelChange)="setCrankLength($event)">
            <mat-option *ngFor="let crankLength of staticCrankLengths"
                        [value]="crankLength">
                {{crankLength | translate}}
            </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf='!this.isBlindDataStatic'>
        <mat-label>{{ 'DIMENSION|Przesunięcie korby' | translate }}</mat-label>
        <input
          matInput
          iccDimensionInput
          type="number"
          [(ngModel)]="crankOffset"
          (ngModelChange)="setCrankOffset($event)"
        >
        <span matSuffix>{{ dimensionUnit.unitSymbol }}</span>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'AWNING|Nachylenie korby' | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedCrankSlope" (ngModelChange)="setCrankSlope($event)">
            <mat-option *ngFor="let crankSlope of (availableCrankSlopes$ | async)"
                        [value]="crankSlope">
                {{crankSlope}}
            </mat-option>
        </mat-select>
        <span matSuffix>{{this.isBlindDataStatic ? "°" : dimensionUnit.unitSymbol }}</span>
      </mat-form-field>
</div>

<div class="box-form mat-elevation-z3" *ngIf="(selectedConfiguratorType$ | async) === 'external_blind'">
    <h4>{{ 'ROLLERSHUTTER|Uchwyty:' | translate }}</h4>
    <mat-form-field *ngIf='!this.isBlindDataStatic'>
        <mat-label>{{ 'DIMENSION|Długość uchwytów' | translate }}</mat-label>
        <input
          matInput
          iccDimensionInput
          type="number"
          [(ngModel)]="mountingLength"
          (ngModelChange)="setMountingLength($event)"
        >
        <span matSuffix>{{ dimensionUnit.unitSymbol }}</span>
      </mat-form-field>
      <mat-form-field *ngIf='this.isBlindDataStatic'>
        <mat-label>{{ 'DIMENSION|Długość uchwytów' | translate }}</mat-label>
        <mat-select [(ngModel)]="mountingLength" (ngModelChange)="setMountingLength($event)">
            <mat-option *ngFor="let mountingLength of staticMountingLengths"
                        [value]="mountingLength">
                {{mountingLength}}
            </mat-option>
        </mat-select>
      </mat-form-field>
</div>

<div class="box-form mat-elevation-z3" *ngIf="openingLockAvailable$ | async">
    <div>
        <mat-slide-toggle name="openingLock" [disabled]="openingLockDisable$ | async" [ngModel]="openingLock$ | async" (ngModelChange)="changeOpeningLock($event)">{{ 'ROLLERSHUTTER|Blokada otwarcia' | translate }}</mat-slide-toggle>
        <button mat-flat-button (click)="showInfo()">
            {{getHangerName()}}
            <mat-icon class="mat-18">info</mat-icon>
        </button>
    </div>
</div>
<div class="box-form mat-elevation-z3">
    <button mat-flat-button color="primary" (click)="changeDriveSide()">{{ 'ROLLERSHUTTER|Strona sterowania' | translate }}</button>
    <h4 *ngIf="(driveOutputSides$ | async).length>0 && (selectedConfiguratorType$ | async) !== 'external_blind'" class='drive-output-side-heading'>{{ 'ROLLERSHUTTER|Wyjście sterowania' | translate }}</h4>
    <div class="drive-output-side" *ngIf="(selectedConfiguratorType$ | async) !== 'external_blind'">
        <ng-container *ngFor="let side of (driveOutputSides$ | async)">
            <div (click)="changeDriveOutputSide(side)" [class.selected]="(driveOutputSide$ | async) === side" class="mat-elevation-z3">
                <div class="img">
                    <img [src]="driveOutputSides[side]?.image">
                </div>
                <p><a>{{driveOutputSides[side]?.title | translate}}</a></p>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #accessoryItem let-item="accessory">
    <div class="item-imgTitleDescription">
        <img class="item-imgTitleDescription-img" [src]="'/files/windowaccessory/' + item.img" [alt]="item.name">
        <p class="item-imgTitleDescription-title"><span *ngIf="windowAccessoriesManufacturerCode">{{item.manufacturer_code}} </span>{{item.name}}</p>
        <span class="item-imgTitleDescription-description">
            <small>{{item.code}}</small>
            {{item.description}}
            <span *ngIf="item.price_type == 1 || item.price_type == 2">
                <span  *ngIf="item.price_type == 1">
                    {{ 'DIMENSION|Pow.' | translate }}:
                </span>
                <span *ngIf="item.price_type == 2">
                    {{ 'DIMENSION|Dł.' | translate }}:
                </span>
                <b>
                    <span *ngIf="item.price_type == 1" ng-bind-html="item.amount | unitm2:1"></span>
                    <span *ngIf="item.price_type == 2">{{item.amount|unitmm:1}}</span>
                </b>
            </span>
        </span>
        <p class="item-action">
            <button mat-raised-button color="primary" (click)="remove(item)">{{ 'INTERFACE|Usuń'| translate }}</button>
        </p>
    </div>
</ng-template>
