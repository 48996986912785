import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { ModalService } from '@icc/helpers';
import { _, StepComponent, PriceDetailsComponent, ProductFeaturesComponent } from '@icc/configurator/shared';
import { ConstructionParametersComponent } from '../construction-parameters/construction-parameters.component';
import {
    ConfigurationsService,
    APP_CONFIG,
    AppConfigFactory,
    UserService,
} from '@icc/common';

@Component({
    selector: 'icc-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent extends StepComponent implements OnInit {
    static stepName = _('INTERFACE|Szczegóły');
    static stepIcon = {
        ligature: 'remove_red_eye',
    };

    public configurator = 'window';
    public stepId = 'details';
    public title = _('INTERFACE|Szczegóły');
    public options = [
        {
            title: _('WINDOW|Parametry Uw/Sw'),
            component: ConstructionParametersComponent,
            componentData: {
                conf: () => this.configurationsService.conf.Current
            },
            show: () => this.showParametersModal
        },
        {
            title: _('OFFER|Porównanie linii'),
            callback: () => this.openSystemsComparision(),
            show: () => this.showSystemsComparision
        },
        {
            title: _('OFFER|Szczegóły wyceny'),
            component: PriceDetailsComponent,
            show: () => this.showPriceDetails
        },
        {
            title: _('OFFER|Cechy produktów'),
            component: ProductFeaturesComponent,
            show: () => this.showProductFeatures
        }
    ]

    details: any = {};
    priceElementsVisible = false;
    showParametersModal = false;
    showPriceDetails = false;
    showSystemsComparision = false;
    showProductFeatures = true;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private modalService: ModalService,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private userService: UserService
    ) {
        super();
    }

    ngOnInit() {
        const user = this.userService.get();
        this.showParametersModal = this.config().IccConfig.Configurators.showParametersModal;
        this.showPriceDetails = this.config().IccConfig.Configurators.showPriceDetails && user.access == 'producent' && !this.userService.b2c;
        if(this.config().preset === 'b2c') {
            this.showParametersModal = this.config().IccConfig.Configurators.showParametersModalB2C;
            this.showProductFeatures = this.config().IccConfig.Configurators.showProductFeaturesModalB2C;
        }
    }

    openPriceDetails() {
        this.modalService.open({
            pageComponent: PriceDetailsComponent,
        });
    }

    openSystemsComparision() {
    }

}
