export default function WinterGardenFactory($rootScope, CurConfService, ConfiguratorsDataService, EventBusService) {

    var winterGardens = [];
    var factory       = {
        loadedData   : false,
        winterGardens: winterGardens
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    function init() {
        if (CurConfService.conf != 'winter_garden') {
            return;
        }

        winterGardens.data = ConfiguratorsDataService.data.winterGardens;
        factory.loadedData = true;
    }
}
