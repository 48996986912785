import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { SharedFacade } from '@icc/configurator/shared';
import { WindowFacade } from '@icc/configurator/window';
import { iccListItem } from '@icc/configurator/ui';

@Component({
    selector: 'icc-profiles-shapes-page',
    templateUrl: './profiles-shapes-page.component.html',
    styleUrls: ['./profiles-shapes-page.component.scss'],
})
export class ProfilesShapesPageComponent extends PageComponent implements OnInit {
    public title = 'WINDOW|Wybierz kształt listwy';
    public options = [];

    shapes: iccListItem[] = [];
    public selected: any = null;

    constructor(
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            shapes: any[];
        }
    ) {
        super();
    }

    ngOnInit() {
        this.shapes = this.pageData.shapes.map((shape: any) => ({
            id: Number(shape.id),
            title: shape.name,
        }));
    }

    selectShape(item: iccListItem) {
        const shape = this.pageData.shapes.find(s => Number(s.id) === Number(item.id));
        this.selected = shape.id;
        this.sharedFacade.closePage(shape);
    }
}
