/**
 * Funkcja filtrów
 * @param  {object} $filter Filtry
 */
export default function FormatUnitFilter($filter, UserService) {
    'ngInject';

    const dimensionUnit = UserService.get().dimension_unit;
    const unit = dimensionUnit === 'mm' ? 0 : 1;

    return function(type) {
        const mbUnit = $filter('translate')('DIMENSION|mb');
        switch (parseInt(type)) {
            case 0:
                return $filter('translate')('DIMENSION|szt.');
            case 1:
                return unit === 0 ? 'm<sup>2</sup>' : 'inch<sup>2</sup>';
            case 2:
                return unit === 0 ? mbUnit : '″';
        }
    };
}
