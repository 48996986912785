import { Component, OnInit, Inject, OnDestroy, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { PageComponent, _, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { UnitConverterService } from '@icc/configurator/ui';
import { MeasurementsService } from '@icc/legacy/configurator/steps/window/dimensions/measurements.service';

@Component({
    selector: 'icc-mounting-clearance',
    templateUrl: './mounting-clearance.component.html',
    styleUrls: ['./mounting-clearance.component.scss'],
})
export class MountingClearanceComponent extends PageComponent implements OnInit, OnDestroy, AfterViewInit {
    public title = _('WINDOW|Luzy montażowe');
    currentMeasurement: number;
    selectedMountingMethod = true;
    fit: 'frame' | 'construction';
    @ViewChildren('measurementsinput') inputs?: QueryList<any>;
    dimensionUnit = this.unitConverterService.getUnit();
    clearanceInputsForm = new FormGroup({
        distanceOut: new FormGroup({
            top: new FormControl(''),
            bottom: new FormControl(''),
            left: new FormControl(''),
            right: new FormControl(''),
        }),
        enlargement: new FormGroup({
            top: new FormControl(''),
            left: new FormControl(''),
            right: new FormControl(''),
        }),
        distanceIn: new FormGroup({
            top: new FormControl(''),
            left: new FormControl(''),
            right: new FormControl(''),
        }),
    });

    callback() {
        console.log("callback");
    }

    constructor(
        @Inject(ICC_PAGE_DATA) private pageData: any,
        private unitConverterService: UnitConverterService,
        private measurementsService: MeasurementsService,
    ) {
        super();
        this.currentMeasurement = this.pageData.currentMeasurement;
        this.fit = this.pageData.fit;
    }

    checkField(field) {
        return this.pageData.checkField(field);
    }

    ngOnInit() {
        this.selectedMountingMethod = this.pageData.selectedMountingMethod;
        this.clearanceInputsForm.setValue({
            distanceIn: {...this.measurementsService.measurements[this.currentMeasurement].distanceIn},
            distanceOut: {...this.measurementsService.measurements[this.currentMeasurement].distanceOut},
            enlargement: {...this.measurementsService.measurements[this.currentMeasurement].enlargement},
        });
    }

    ngAfterViewInit() {
        this.measurementsService.getDevice(this.clearanceInputsForm.controls, this.inputs);
    }

    ngOnDestroy() {
        const measurements = this.clearanceInputsForm.value;
        this.measurementsService.measurements[this.currentMeasurement].distanceIn = measurements.distanceIn;
        this.measurementsService.measurements[this.currentMeasurement].distanceOut = measurements.distanceOut;
        this.measurementsService.measurements[this.currentMeasurement].enlargement = measurements.enlargement;
    }
}
