import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigurationsService, core, TranslateService } from '@icc/common';
import { PriceDictionaryService, PriceSegment } from '@icc/price/b2b';

@Component({
  selector: 'icc-construction-issues-modal',
  templateUrl: './construction-issues-modal.component.html',
  styleUrls: ['./construction-issues-modal.component.scss']
})
export class ConstructionIssuesModalComponent implements OnInit {
  segments: any = [];
  conf = this.configurationsService.conf.Current;
  constructor(
    public dialogRef: MatDialogRef<ConstructionIssuesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private configurationsService: ConfigurationsService<'window' | 'door'>,
    private priceDictionaryService: PriceDictionaryService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.segments = core.copy(this.conf.PriceSegments).filter(segment => segment).map(el=>{
      const translatedType = this.priceDictionaryService.locale(el.type);
      const translatedName = this.getTranslatedName(el);
      return {
        ...el,
        translatedType: translatedType,
        translatedName: translatedName,
        isValid: el.baseValue !== null && el.value !== null,
      };
    })
    this.segments = this.segments.sort((a: any)=>{
        if (a.baseValue===null){
            return -1;
        }
    })
  }

  getTranslatedName(el: PriceSegment){
    let translatedName = this.priceDictionaryService.locale(el.data?.name);
    if (el.type==='sashes' && el.data?.typesSymbol){
        translatedName = `${el.data.typesSymbol?.map((type: string) => type)} ${el.data.height}x${el.data.width}`
    }
    else if (el.type==='color'){
        switch (el.data.colorType){
            case "White":
                translatedName = ` ${this.conf.Colors.sash.core?.name} `;
                break;
            case "Inner":
                translatedName = `${this.translateService.instant('COLOR|Kolor wewnętrzny')}: ${this.conf.Colors.sash.inner?.name} `;
                break;
            case "Outer":
                translatedName = `${this.translateService.instant('COLOR|Kolor zewnętrzny')}: ${this.conf.Colors.sash.outer?.name} `;
                break;
            case "Bicolor":
                translatedName = `${this.translateService.instant('COLOR|Kolor zewnętrzny')}: ${this.conf.Colors.sash.outer?.name} `;
                translatedName += ` ${this.translateService.instant('COLOR|Kolor wewnętrzny')}: ${this.conf.Colors.sash.inner?.name} `;
                break;
        }
      }
      else if (el.type==='filling'
               || el.type==='fillingShape'
               || el.type==='handles'
               || el.type==='sashType'
      ){
        if (el.data.sashIndex!==undefined){
          translatedName =  `${translatedName} ${this.translateService.instant('WINDOW|Kwatera')}: ${el.data.sashIndex}`;
        } else if (el.data.sashId!==undefined){
          translatedName =  `${translatedName} ${this.translateService.instant('WINDOW|Kwatera')}: ${el.data.sashId+1}`;
        }
      }
      else if (el.type==='frame'){
        translatedName =  `Id ramy: ${el.data.frameId}`;
      }
      else if (el.type==='alushell'){
        if (el.data.frameId !== undefined){
          translatedName =  `${this.translateService.instant('WINDOW|Kwatera')}: ${el.data.frameId+1}`;
        } else if (el.data.sashId !== undefined){
          translatedName =  `${this.translateService.instant('WINDOW|Kwatera')}: ${el.data.sashId+1}`;
        }
      }
      else if (el.type==='sashFrames' || el.type==='warmEdge'){
        translatedName =  `${this.translateService.instant('WINDOW|Kwatera')}: ${el.data.sashId+1}`;
      }
      else if (el.type==='dividersCross' || el.type==='handlesHeights'){
        translatedName = `${this.translateService.instant('WINDOW|Kwatera')}: ${el.data.sashIndex}`;
      }
      else if (el.type==='system'){
        translatedName = `${translatedName} Typ: ${el.data.type}`;
      }
      else if (el.type==='systemMultiplier'){
        translatedName =`${this.translateService.instant('WINDOW|Mnożnik systemu')}`;
      }
      return translatedName;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
