export class Common {
    static isArray = Array.isArray;

    static hasOwnProperty = Object.prototype.hasOwnProperty;
    static isUndefined(value): value is undefined {
        return typeof value === 'undefined';
    }

    static isDefined<T>(value: T): value is NonNullable<T> | null {
        return typeof value !== 'undefined';
    }

    static isBoolean(value) {
        return typeof value === 'boolean';
    }

    static isString(value): value is string {
        return typeof value === 'string';
    }

    static isObject<U>(value: U): value is object & U;
    static isObject(value: any): value is object {
        return value !== null && typeof value === 'object';
    }

    static isBlankObject(value) {
        return value !== null && typeof value === 'object' && !Object.getPrototypeOf(value);
    }

    static isNumber(value) {
        return typeof value === 'number';
    }

    static isDate(value) {
        return toString.call(value) === '[object Date]';
    }

    static isFunction(value) {
        return typeof value === 'function';
    }

    static isRegExp(value) {
        return toString.call(value) === '[object RegExp]';
    }

    static isWindow(obj) {
        return obj && obj.window === obj;
    }

    static isElement(node) {
        return !!(
            node
            && (node.nodeName  // We are a direct element.
                || (node.prop && node.attr && node.find))
        ); // We have an on and find method part of jQuery API.
    }
    static isArrayLike(obj) {
        // `null`, `undefined` and `window` are not array-like
        if (obj == null || this.isWindow(obj)) {
            return false;
        }

        // arrays, strings and jQuery/jqLite objects are array like
        // * jqLite is either the jQuery or jqLite constructor function
        // * we have to check the existence of jqLite first as this method is called
        //   via the forEach method when constructing the jqLite object in the first place
        if (this.isArray(obj) || this.isString(obj)) {
            return true;
        }

        // Support: iOS 8.2 (not reproducible in simulator)
        // "length" in obj used to prevent JIT error (gh-11508)
        const length = 'length' in Object(obj) && obj.length;

        // NodeList objects (with `item` method) and
        // other objects with suitable length characteristics are array-like
        return (
            this.isNumber(length)
            && ((length >= 0 && (length - 1 in obj || obj instanceof Array))
                || typeof obj.item === 'function')
        );
    }

    static isTypedArray(value) {
        const TYPED_ARRAY_REGEXP = /^\[object (?:Uint8|Uint8Clamped|Uint16|Uint32|Int8|Int16|Int32|Float32|Float64)Array]$/;
        return (
            value && this.isNumber(value.length) && TYPED_ARRAY_REGEXP.test(toString.call(value))
        );
    }

    static isArrayBuffer(obj) {
        return toString.call(obj) === '[object ArrayBuffer]';
    }

    static isError(what) {
        var toString = Object.prototype.toString.call(what);
        return Common.isObject(what) &&
            toString === '[object Error]' ||
            toString === '[object Exception]' || // Firefox NS_ERROR_FAILURE Exceptions
            what instanceof Error;
    }

    static baseExtend(dst, objs, deep) {
        for (let i = 0, ii = objs.length; i < ii; ++i) {
            const obj = objs[i];
            if (!this.isObject(obj) && !this.isFunction(obj)) {
                continue;
            }
            const keys = Object.keys(obj);
            for (let j = 0, jj = keys.length; j < jj; j++) {
                const key = keys[j];
                const src = obj[key];

                if (deep && this.isObject(src)) {
                    if (this.isDate(src)) {
                        dst[key] = new Date(src.valueOf());
                    } else if (this.isRegExp(src)) {
                        dst[key] = new RegExp(src);
                    } else if (src.nodeName) {
                        dst[key] = src.cloneNode(true);
                    } else if (this.isElement(src)) {
                        dst[key] = src.clone();
                    } else {
                        if (!this.isObject(dst[key])) {
                            dst[key] = this.isArray(src) ? [] : {};
                        }
                        this.baseExtend(dst[key], [src], true);
                    }
                } else {
                    dst[key] = src;
                }
            }
        }

        return dst;
    }

    static extend(dst, ...src) {
        return this.baseExtend(dst, src, false);
    }

    static forEach<T, U extends ArrayLike<T> = T[]>(
        obj: U,
        iterator: (value: U[number], key: number, obj: U) => void,
        context?: any
    ): U;
    static forEach<T>(
        obj: { [index: string]: T },
        iterator: (value: T, key: string, obj: { [index: string]: T }) => void,
        context?: any
    ): { [index: string]: T };
    static forEach(
        obj: any,
        iterator: (value: any, key: any, obj: any) => void,
        context?: any
    ): any {
        let key, length;
        if (obj) {
            if (this.isFunction(obj)) {
                for (key in obj) {
                    // Need to check if hasOwnProperty exists,
                    // as on IE8 the result of querySelectorAll is an object without a hasOwnProperty function
                    if (
                        key !== 'prototype'
                        && key !== 'length'
                        && key !== 'name'
                        && (!obj.hasOwnProperty || obj.hasOwnProperty(key))
                    ) {
                        iterator.call(context, obj[key], key, obj);
                    }
                }
            } else if (this.isArray(obj) || this.isArrayLike(obj)) {
                const isPrimitive = typeof obj !== 'object';
                for (key = 0, length = obj.length; key < length; key++) {
                    if (isPrimitive || key in obj) {
                        iterator.call(context, obj[key], key, obj);
                    }
                }
            } else if (obj.forEach && obj.forEach !== this.forEach) {
                obj.forEach(iterator, context, obj);
            } else if (this.isBlankObject(obj)) {
                // createMap() fast path --- Safe to avoid hasOwnProperty check because prototype chain is empty
                for (key in obj) {
                    iterator.call(context, obj[key], key, obj);
                }
            } else if (typeof obj.hasOwnProperty === 'function') {
                // Slow path for objects inheriting Object.prototype, hasOwnProperty check needed
                for (key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        iterator.call(context, obj[key], key, obj);
                    }
                }
            } else {
                // Slow path for objects which do not have a method `hasOwnProperty`
                for (key in obj) {
                    if (this.hasOwnProperty.call(obj, key)) {
                        iterator.call(context, obj[key], key, obj);
                    }
                }
            }
        }
        return obj;
    }

    static equals(o1, o2) {
        if (o1 === o2) {
            return true;
        }
        if (o1 === null || o2 === null) {
            return false;
        }
        // eslint-disable-next-line no-self-compare
        if (o1 !== o1 && o2 !== o2) {
            return true;
        } // NaN === NaN
        let t1 = typeof o1,
            t2 = typeof o2,
            length,
            key,
            keySet;
        if (t1 === t2 && t1 === 'object') {
            if (this.isArray(o1)) {
                if (!this.isArray(o2)) {
                    return false;
                }
                if ((length = o1.length) === o2.length) {
                    for (key = 0; key < length; key++) {
                        if (!this.equals(o1[key], o2[key])) {
                            return false;
                        }
                    }
                    return true;
                }
            } else if (this.isDate(o1)) {
                if (!this.isDate(o2)) {
                    return false;
                }
                return this.equals(o1.getTime(), o2.getTime());
            } else if (this.isRegExp(o1)) {
                if (!this.isRegExp(o2)) {
                    return false;
                }
                return o1.toString() === o2.toString();
            } else {
                if (
                    this.isWindow(o1)
                    || this.isWindow(o2)
                    || this.isArray(o2)
                    || this.isDate(o2)
                    || this.isRegExp(o2)
                ) {
                    return false;
                }
                keySet = Object.create(null);
                for (key in o1) {
                    if (key.charAt(0) === '$' || this.isFunction(o1[key])) {
                        continue;
                    }
                    if (!this.equals(o1[key], o2[key])) {
                        return false;
                    }
                    keySet[key] = true;
                }
                for (key in o2) {
                    if (
                        !(key in keySet)
                        && key.charAt(0) !== '$'
                        && this.isDefined(o2[key])
                        && !this.isFunction(o2[key])
                    ) {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    }

    static deepCopy<T>(source: T, destination?: T, maxDepth?): T {
        this.stackSource = [];
        this.stackDest = [];
        maxDepth = this.isValidObjectMaxDepth(maxDepth) ? maxDepth : NaN;

        if (destination) {
            if (this.isTypedArray(destination) || this.isArrayBuffer(destination)) {
                throw Error("Can't copy! TypedArray destination cannot be mutated.");
            }
            if (source === destination) {
                throw Error("Can't copy! Source and destination are identical.");
            }

            // Empty the destination object
            if (this.isArray(destination)) {
                destination.length = 0;
            } else {
                this.forEach<any>(destination, function(value, key) {
                    if (key !== '$$hashKey') {
                        delete destination[key];
                    }
                });
            }

            this.stackSource.push(source);
            this.stackDest.push(destination);
            return this.copyRecurse(source, destination, maxDepth);
        }

        return this.copyElement(source, maxDepth);
    }

    static humanFileSize(size) {
        const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    static availableStorage() {
        const anyNav = navigator as any;
        if ('storage' in anyNav && 'estimate' in anyNav.storage) {
            return anyNav.storage.estimate();
        }
        return Promise.resolve(null);
    }

    static merge(...dst) {
        return this.baseExtend(dst, [].slice.call(arguments, 1), true);
    }
    private static stackSource = [];
    private static stackDest = [];

    private static isValidObjectMaxDepth(maxDepth) {
        return this.isNumber(maxDepth) && maxDepth > 0;
    }

    private static copyElement(source, maxDepth?) {
        // Simple values
        if (!this.isObject(source)) {
            return source;
        }

        // Already copied values
        const index = this.stackSource.indexOf(source);
        if (index !== -1) {
            return this.stackDest[index];
        }

        if (this.isWindow(source)) {
            throw Error("Can't copy! Making copies of Window or Scope instances is not supported.");
        }

        let needsRecurse = false;
        let destination = this.copyType(source);

        if (destination === undefined) {
            destination = this.isArray(source) ? [] : Object.create(Object.getPrototypeOf(source));
            needsRecurse = true;
        }

        this.stackSource.push(source);
        this.stackDest.push(destination);

        return needsRecurse ? this.copyRecurse(source, destination, maxDepth) : destination;
    }

    private static copyType(source) {
        switch (toString.call(source)) {
            case '[object Int8Array]':
            case '[object Int16Array]':
            case '[object Int32Array]':
            case '[object Float32Array]':
            case '[object Float64Array]':
            case '[object Uint8Array]':
            case '[object Uint8ClampedArray]':
            case '[object Uint16Array]':
            case '[object Uint32Array]':
                return new source.constructor(
                    this.copyElement(source.buffer),
                    source.byteOffset,
                    source.length
                );

            case '[object ArrayBuffer]':
                // Support: IE10
                if (!source.slice) {
                    // If we're in this case we know the environment supports ArrayBuffer
                    /* eslint-disable no-undef */
                    const copied = new ArrayBuffer(source.byteLength);
                    new Uint8Array(copied).set(new Uint8Array(source));
                    /* eslint-enable */
                    return copied;
                }
                return source.slice(0);

            case '[object Boolean]':
            case '[object Number]':
            case '[object String]':
            case '[object Date]':
                return new source.constructor(source.valueOf());

            case '[object RegExp]':
                const re = new RegExp(source.source, source.toString().match(/[^/]*$/)[0]);
                re.lastIndex = source.lastIndex;
                return re;

            case '[object Blob]':
                return new source.constructor([source], { type: source.type });
        }

        if (this.isFunction(source.cloneNode)) {
            return source.cloneNode(true);
        }
    }

    private static copyRecurse(source, destination, maxDepth) {
        maxDepth--;
        if (maxDepth < 0) {
            return '...';
        }
        let key;
        if (this.isArray(source)) {
            for (let i = 0, ii = source.length; i < ii; i++) {
                destination.push(this.copyElement(source[i], maxDepth));
            }
        } else if (this.isBlankObject(source)) {
            // createMap() fast path --- Safe to avoid hasOwnProperty check because prototype chain is empty
            for (key in source) {
                destination[key] = this.copyElement(source[key], maxDepth);
            }
        } else if (source && typeof source.hasOwnProperty === 'function') {
            // Slow path, which must rely on hasOwnProperty
            for (key in source) {
                if (source.hasOwnProperty(key)) {
                    destination[key] = this.copyElement(source[key], maxDepth);
                }
            }
        } else {
            // Slowest path --- hasOwnProperty can't be called as a method
            for (key in source) {
                if (this.hasOwnProperty.call(source, key)) {
                    destination[key] = this.copyElement(source[key], maxDepth);
                }
            }
        }
        return destination;
    }
}
