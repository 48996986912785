<icc-list
    [searchable]="true"
    [items]="handles"
    [itemTemplate]="itemHandle"
    [selected]="selectedHandleId"
    vertical="true"
    (select)="selectHandle($event)"></icc-list>

<ng-template #itemHandle let-item="iccListItem">
    <div class="item-imgTitleDescription">
        <p class="item-imgTitleDescription-title">{{ item.title }}</p>
        <div class="icc-list-item-img-container">
            <img
                class="item-imgTitleDescription-img"
                [src]="item.imageUrl"
                [alt]="item.title"
                defaultImg
            />
        </div>

        <p class="item-imgTitleDescription-description" [innerHTML]="item.description"></p>
        <p class="item-imgTitleDescription-colors">
            <span
                *ngFor="let color of item.colors"
                [ngStyle]="{
                    'background-color': '#' + color.color,
                    'background-image': 'url(/files/windowhandlescolor/' + color.colorImg + ')'
                }"
                [title]="color.name"
                [class.selected]="color.id === item.selectedColor"
            >
            </span>
        </p>
    </div>
</ng-template>
