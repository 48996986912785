import $ from 'jquery';

/**
 * Kontroler paneli garażowych
 * @param {object} $scope              Angular scope
 * @param {object} $rootScope          Angular rootScope
 * @param {object} $timeout            timeout
 * @param {object} GaragePanelsFactory Fabryka paneli bram
 * @param {object} StepFactory         Fabryka kroków
 */
export default function PanelsCtrl($scope, $rootScope, $timeout, GaragePanelsFactory, StepFactory, EventBusService, ScrollbarService) {
    'ngInject';

    var vm = this;
    vm.subStep          = 1;
    vm.valid            = GaragePanelsFactory.valid;
    vm.noStructures     = GaragePanelsFactory.noStructures;
    vm.setPanel         = GaragePanelsFactory.setPanel;
    vm.setStructure     = GaragePanelsFactory.setStructure;
    vm.refresh          = refresh;
    vm.garagePanels     = [];
    vm.garageStructures = [];

    if (GaragePanelsFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));
    $scope.$on('changedStep', function(event, newVal, oldVal) {
        if (newVal.code != 'fills' && newVal.code !== oldVal.code && oldVal.code == 'fills') {
            if (!vm.valid()) {
                StepFactory.selectStep('fills');
            }
        }
    });

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        vm.garagePanels     = GaragePanelsFactory.garagePanels;
        vm.garageStructures = GaragePanelsFactory.garageStructures;
    }

    /**
     * Funkcja odświeżajaca
     */
    function refresh() {
        ScrollbarService.update();
    }
}
