import 'angular-ui-bootstrap';

/**
 * Kontroler modala kolorów dodatku.
 *
 * @export
 * @class ModalPriceDetailsCtrl
 */
export class ModalPdfOptionsCtrl {

    options;
    dimensionUnits;
    defaultMarketCurrencyBlock;

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private IccConfig,
        private ProjectName,
        private LangService,
        public userGroup,
        public extendedPdf,
        public offer,
        private user,
        private $translate,
        ) {
        'ngInject';

        this.options = {
            word: false,
            docType: 'offer',
            priceType: 'all',
            extended: false,
            completion: false,
            hideLegalNotices: false,
            lang: this.LangService.getLang(),
            currency: offer.dealer_currency && user.dealer.currencies[offer.dealer_currency] ? offer.dealer_currency : user.market_currency,
            currencyExchangeRate: offer.dealer_currency_exchange_rate && offer.dealer_currency && user.dealer.currencies[offer.dealer_currency] ? offer.dealer_currency_exchange_rate : 1,
            showUw: true,
            showSw: true,
            showWeight: true,
            showCircuit: true,
            showArea: true,
            flipSymbols: user.flip_symbols,
            hideDimensions: false,
            dimensionUnit: 'default',
            hideClient: false,
            printMontageDescription: true
        };

        this.dimensionUnits = {
            'default': this.$translate.instant('USER|Domyślne'),
            'mm': this.$translate.instant('USER|Międzynarodowe (metry)'),
            'inch': this.$translate.instant('USER|Imperialne (cale)')
        }

        this.defaultMarketCurrencyBlock = user.defaultMarketCurrencyBlock;

        if (!offer.dealer_currency_exchange_rate) {
            this.restoreCurrency();
        }
    }

    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    print() {
        const pdfOptions = {
            orderPreview: this.options.docType === 'orderPreview',
            prices: !['no-prices', 'no-client'].includes(this.options.priceType),
            discounts: this.options.priceType === 'all',
            extended: this.options.extended,
            completion: this.options.completion,
            word: this.options.word,
            hideLegalNotices: this.options.hideLegalNotices,
            lang: this.options.lang,
            extendedDrawDimensions: this.options.docType === 'extendedDraw',
            client: this.options.priceType !== 'no-client' && !this.options.hideClient,
            currency: null,
            currencyExchangeRate: null,
            showUw: this.options.showUw,
            showSw: this.options.showSw,
            showWeight: this.options.showWeight,
            showCircuit: this.options.showCircuit,
            showArea: this.options.showArea,
            flipSymbols: this.options.flipSymbols,
            hideDimensions: this.options.hideDimensions,
            dimensionUnit: this.options.dimensionUnit,
            printMontageDescription: this.options.printMontageDescription
        };
        if (this.availableCurrencyChange) {
            pdfOptions.currency = this.options.currency;
            pdfOptions.currencyExchangeRate = this.options.currencyExchangeRate;
        }
        this.$uibModalInstance.close(pdfOptions);
    }


    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    close() {
        this.$uibModalInstance.close();
    }

    changedDocType() {
        if (this.options.docType === 'orderPreview') {
            this.options.priceType = 'no-discounts';
        } else {
            this.options.priceType = 'all';
        }
    }

    restoreCurrency() {
        if (this.availableCurrencyChange) {
            this.options.currencyExchangeRate = this.user.dealer.currencies[this.options.currency];
        }
    }

    get availableOrderPreview() {
        return (
            this.user.access === 'dealer'
            || (this.user.access === 'producent' && !IccConfig.PDF.producerOfferLikeOrder)
            || this.user.access === 'dealerh' && this.user.dealer_seller.see_prices
        )
        && this.IccConfig.Offer.orderPreview
        && !this.order;
    }

    get availableExtendedDraw() {
        return this.IccConfig.PDF.extendedDrawDimensions;
    }

    get availableCurrencyChange() {
        return this.IccConfig.PDF.enableCurrencyChange
            && !this.options.word
            && !this.order
            && this.options.docType !== 'orderPreview'
            && ['dealer', 'dealerh'].includes(this.user.access);
    }

    get showRestoreButton() {
        return this.availableCurrencyChange && this.options.currencyExchangeRate !== this.user.dealer.currencies[this.options.currency];
    }

    get order() {
        return Boolean(Number(this.offer.order));
    }
}
