import {
    ConfigurationsService,
    EventBusService,
    TranslateService,
    ConfiguratorsDataService,
} from '@icc/common';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { StepFactory } from '../StepFactory';
import { PriceService } from '@icc/price';
import { isUndefined, StepsService, IssuesService, IssueLevel } from '@icc/helpers';
import { AssemblyService } from './assembly.service';
import { Injectable } from '@angular/core';
@Injectable()
export class DimensionsService {
    constructor(
        private configurationsService: ConfigurationsService<'awning'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private curConfService: CurrentConfiguratorService,
        private stepsService: StepsService,
        private priceService: PriceService,
        private translateService: TranslateService,
        private issuesService: IssuesService,
        private assemblyService: AssemblyService,
        private eventBusService: EventBusService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribeWithoutConfiguration('changedStep', () => {
            this.init();
        });
    }
    outreaches = [];
    outreachesWidth = [];
    sizes = [];
    outreach = 0;
    width = 0;
    loadedData = false;

    /**
     * Funkcja inicjujaca
     */
    init() {
        if (this.curConfService.conf === 'awning') {
            this.setValues();
            if (
                isUndefined(this.configurationsService.conf.Current.Outreach)
                || isUndefined(this.configurationsService.conf.Current.Width)
            ) {
                this.setDefaultValues();
            } else if (
                this.configurationsService.conf.Current.Outreach === 0
                || this.configurationsService.conf.Current.Width === 0
            ) {
                this.setDefaultValues();
            } else {
                this.outreach = this.configurationsService.conf.Current.Outreach;
                this.width = this.configurationsService.conf.Current.Width;
            }

            this.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Ustawianie wartosci
     */
    setValues() {
        this.outreaches = this.getOutreaches();
        this.outreachesWidth = this.getOutreachesWidth();
        this.sizes = this.getSizes();
    }

    /**
     * Pobieranie zasięgu
     * @return {Array} Zasięti
     */
    getOutreaches() {
        const systemID = this.configurationsService.conf.Current.SystemId;
        const outreaches = []
        if (systemID > 0) {
            const reaches = Object(this.configuratorsDataService.data.awningOutreach[systemID]);
            // tslint:disable-next-line: forin
            for(const reach in reaches){
                outreaches.push(reach);
            }
        }
        return outreaches;
    }
    /**
     * Pobieranie szerokości zasięgów
     * @return {Array} Szerokości zasięgów
     */
    getOutreachesWidth() {
        const systemID = this.configurationsService.conf.Current.SystemId;

        if (systemID > 0) {
            return this.configuratorsDataService.data.awningOutreachWidth[systemID];
        } else return [];

    }

    /**
     * Pobieranie rozmiarów
     * @return {Array} Rozmiary
     */
    getSizes() {
        const systemID = this.configurationsService.conf.Current.SystemId;
        if (systemID > 0) {
            return this.configuratorsDataService.data.awningSizes[systemID];
        } else {
            return [];
        }
    }

    /**
     * Wybór rozmiaru
     * @param  {Object} outreach Zasięg
     * @param  {Number} width    Szerokość
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    selectSize(outreach, width, nextStep, price) {
        let sizes = this.sizes[outreach];
        let i = 0;

        this.configurationsService.conf.Current.Outreach = outreach;
        this.configurationsService.conf.Current.Width = width;

        // ConfigurationsService.conf.Default.Outreach = outreach;
        // ConfigurationsService.conf.Default.Width = width;

        for (i = 0; i < this.sizes.length; i++) {
            if (this.sizes[i].width_from <= width && width <= this.sizes[i].width_to) {
                this.configurationsService.conf.Current.SizePrice = this.sizes[i].price;
                // ConfigurationsService.conf.Default.SizePrice = sizes[i].price;
            }
        }

        this.assemblyService.setDefaultValues(true);

        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
        if (price) {
            this.priceService.count();
        }
    }

    /**
     * Ustawianie wartości domyślnych
     * @param {Bookean} reset Reset
     */
    setDefaultValues(reset?) {
        if (isUndefined(this.configurationsService.conf)) {
            return;
        }

        this.setValues();

        if (
            this.configurationsService.conf.Current.Outreach === 0
            || this.configurationsService.conf.Current.Width === 0
            || reset
        ) {
            let defO = 0;

            for (const outreach in this.outreaches) {
                if (defO === 0) {
                    defO = Number(outreach);
                }
            }
            if (defO === 0) {
                return;
            }
            this.outreach = defO;

            const key = Object.keys(this.outreachesWidth)[defO];
            const outreachWidths = this.outreachesWidth[key];
            this.width = outreachWidths.width_from;

            this.selectSize(this.outreach, this.width, false, true);
        }
    }

    /**
     * Walidacja
     * @param  {Number} width Szerokość
     * @return {Object}       Walidacja
     */
    valid(width) {
        if (width < 1) {
            this.issuesService.simpleRegister(
                'dimensions',
                'Podane wymiary są nieprawidłowe.',
                this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                this.configurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        this.issuesService.unregister('dimensions', this.configurationsService.conf.Current);
        return true;
    }
}
