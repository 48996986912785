import PerfectScrollbar from 'perfect-scrollbar';

export default function PerfectDirective($timeout, ScrollbarService) {
    'ngInject';

    return {
        restrict: 'A',
        link: function (scope, element, attributes, controller) {
            var perfTimeout = $timeout(() => {
                element[0].iccuid = Math.random();
                const scrollTop = attributes.perfectScrolltop !== 'false';
                const ps = new PerfectScrollbar(element[0]);
                ScrollbarService.addScrollbar(ps.element, ps);

                if (scrollTop) {
                    element[0].scrollTop = 0;
                }

                ps.update();
            }, 10);

            element.on('$destroy', () => {
                $timeout.cancel(perfTimeout);
                let ps = ScrollbarService.getScrollbar(element[0]);
                ScrollbarService.removeScrollbar(element[0]);
                if (ps) {
                    ps.destroy();
                    ps = null;
                }
            });
        }
    };
}
