/**
 * Kontroler rozmiarów siatek
 * @param {object} $rootScope                rootScope
 * @param {object} Core                      Core
 * @param {object} MosquitoDimensionsService Fabryka rozmiarów siatek
 * @param {object} ConfigurationsService     Fabryka konfiguracji
 */
export default function DimensionsCtrl($rootScope, $scope, Core, MosquitoDimensionsService, ConfigurationsService, EventBusService) {
    'ngInject';

    var vm = this;

    vm.init           = init;
    vm.boxSizes       = [];
    vm.subStep        = 1;
    vm.shapeData      = MosquitoDimensionsService.defaultDimensions;
    vm.valid          = MosquitoDimensionsService.valid.bind(MosquitoDimensionsService);

    const broadcastSubscriptions = [];
    broadcastSubscriptions.push($rootScope.$on('changedStep', function(event, newVal, oldVal) {
        if (ConfigurationsService.conf.conf === 'mosquito'
            && newVal.code != 'dimensions'
            && vm.valid(vm.shapeData)
            && newVal.code !== oldVal.code
            && oldVal.code == 'dimensions'
        ) {
            MosquitoDimensionsService.setDimensions(vm.shapeData);
        }
    }));

    if (MosquitoDimensionsService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration(['initializedConfigurator'], () => init()));
    $scope.$on('$destroy', () => {
        subscriptions.map(el => el.unsubscribe());
        broadcastSubscriptions.map(el => el());
    });

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        if (ConfigurationsService.conf.Current.type === 'mosquito') {
            vm.shapeData = Core.copy(ConfigurationsService.conf.Current.Shape);
        }
    }
}
