import { core } from '../../../helpers';

/**
 * Dużo różnych zmian - profile, wyrównania, poszerzenia, kształy, szprosy, akcesoria, etc.
 */
export function changesInVersion5(oldConfiguration, dataRequiredToUpdate) {
    const configuration = core.copy(oldConfiguration);
    configuration.usedProfiles = [];
    configuration.mullions = core.copy(configuration.dividers);
    configuration.mullions.forEach(divider => {
        divider.profileId = dataRequiredToUpdate.profilesIdMap.dividers[divider.dividerId];
        if (!core.fIdO(configuration.usedProfiles, divider.profileId)) {
            const dividerProfile = core.fIdO(dataRequiredToUpdate.profiles, divider.profileId);
            if (dividerProfile) {
                configuration.usedProfiles.push(dividerProfile);
            }
        }
        divider.perpendicularMullions = divider.adjacentDividers;
        if (divider.direction === 'horizontal') {
            const dividerAdjacentSashesTop = divider.adjacentSashes.top
                .map(as => core.fIdO<any>(configuration.sashes, as))
                .sort((as1, as2) => as1.y - as2.y);
            divider.perpendicularMullions.left = dividerAdjacentSashesTop[0].nearDividers.left;
            divider.perpendicularMullions.right =
                dividerAdjacentSashesTop[dividerAdjacentSashesTop.length - 1].nearDividers.right;
            divider.shift = dividerAdjacentSashesTop[0].x;
        } else {
            const dividerAdjacentSashesLeft = divider.adjacentSashes.left
                .map(as => core.fIdO<any>(configuration.sashes, as))
                .sort((as1, as2) => as1.y - as2.y);
            divider.perpendicularMullions.top = dividerAdjacentSashesLeft[0].nearDividers.top;
            divider.perpendicularMullions.bottom =
                dividerAdjacentSashesLeft[dividerAdjacentSashesLeft.length - 1].nearDividers.bottom;
            divider.shift = dividerAdjacentSashesLeft[0].y;
        }
        divider.parallelMullions = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        divider.perpendicularAlignments = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        divider.parallelAlignments = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        delete divider.type;
        delete divider.dividerId;
        delete divider.name;
        delete divider.innerWidth;
        delete divider.outerWidth;
    });
    delete configuration.dividers;
    delete configuration.sashFrame;

    const frame = configuration.frame;
    configuration.frame = [];
    let sides = [];
    switch (configuration.shape.shape) {
        case 'rect':
            sides = ['bottom', 'right', 'top', 'left'];
            break;
        case 'triangle':
            sides = ['bottom', 'right', 'left'];
            break;
        case 'circle':
            sides = ['top'];
            break;
        case 'poligon':
            sides = ['bottom'];
            if (configuration.shape.h2 > 0) {
                sides.push('right');
            }
            if (configuration.shape.h1 - configuration.shape.h2 > 0 && configuration.shape.s3 > 0) {
                sides.push('right');
            }
            if (configuration.shape.s2 > 0) {
                sides.push('top');
            }
            if (configuration.shape.h1 - configuration.shape.h3 > 0 && configuration.shape.s1 > 0) {
                sides.push('left');
            }
            if (configuration.shape.h3 > 0) {
                sides.push('left');
            }
            break;
        case 'arc':
            if (configuration.shape.h1 === 0) {
                if (configuration.shape.type === 'F') {
                    sides = ['bottom', 'top'];
                } else if (configuration.shape.type === 'R') {
                    sides = ['bottom', 'top', 'left'];
                } else {
                    sides = ['bottom', 'right', 'top'];
                }
            } else {
                sides = ['bottom', 'right', 'top', 'left'];
            }
            break;
    }
    const frameProfile = core.fIdO(
        dataRequiredToUpdate.profiles,
        dataRequiredToUpdate.profilesIdMap.frames[frame.id]
    );
    if (frameProfile) {
        configuration.usedProfiles.push(frameProfile);
    }
    for (let i = 0; i < sides.length; i++) {
        let finWidth = 0;
        if (configuration.reno != null) {
            finWidth = configuration.reno[sides[i] + 'Width'] || configuration.reno.allWidths;
        }
        configuration.frame.push({
            id: i,
            isDefault: frame.isDefault,
            profileId: dataRequiredToUpdate.profilesIdMap.frames[frame.id],
            jointAngle: 'E',
            weldFinishType: 'groove',
        });
    }

    configuration.sashes.forEach(sash => {
        sash.intMullions = core.copy(sash.intDividers);
        sash.intMullions.forEach(divider => {
            divider.profileId = dataRequiredToUpdate.profilesIdMap.dividers[divider.dividerId];
            divider.perpendicularMullions = divider.adjacentDividers;
            if (divider.direction === 'horizontal') {
                const dividerAdjacentSashesTop = divider.adjacentSashes.top
                    .map(as => core.fIdO<any>(sash.intSashes, as))
                    .sort((as1, as2) => as1.x - as2.x);
                divider.perpendicularMullions.left = dividerAdjacentSashesTop[0].nearDividers.left;
                divider.perpendicularMullions.right =
                    dividerAdjacentSashesTop[
                        dividerAdjacentSashesTop.length - 1
                    ].nearDividers.right;
                divider.shift = dividerAdjacentSashesTop[0].x;
            } else {
                const dividerAdjacentSashesLeft = divider.adjacentSashes.left
                    .map(as => core.fIdO<any>(sash.intSashes, as))
                    .sort((as1, as2) => as1.y - as2.y);
                divider.perpendicularMullions.top = dividerAdjacentSashesLeft[0].nearDividers.top;
                divider.perpendicularMullions.bottom =
                    dividerAdjacentSashesLeft[
                        dividerAdjacentSashesLeft.length - 1
                    ].nearDividers.bottom;
                divider.shift = dividerAdjacentSashesLeft[0].y;
            }
            divider.parallelMullions = {
                top: [],
                bottom: [],
                left: [],
                right: [],
            };
            divider.perpendicularAlignments = {
                top: [],
                bottom: [],
                left: [],
                right: [],
            };
            divider.parallelAlignments = {
                top: [],
                bottom: [],
                left: [],
                right: [],
            };
            delete divider.type;
            delete divider.dividerId;
            delete divider.name;
            delete divider.innerWidth;
            delete divider.outerWidth;
        });
        sash.intAlignments = [];
        sash.nearMullions = sash.nearDividers;
        sash.nearAlignments = sash.nearAlignments || {
            top: -1,
            bottom: -1,
            left: -1,
            right: -1,
        };
        const oldSashFrameId = sash.frame.id;
        const sashFrame = {
            id: 0,
            isDefault: sash.frame.isDefault,
            profileId: dataRequiredToUpdate.profilesIdMap.sash_frames[oldSashFrameId],
            jointAngle: 'E',
            weldFinishType: 'groove',
        };
        sash.frame = {
            top: sashFrame,
            bottom: sashFrame,
            left: sashFrame,
            right: sashFrame,
        };

        const sashMuntinsHorizontal = sash.muntins.vertical.map(muntin => ({
            ...muntin,
            direction: 'horizontal',
        }));
        const sashMuntinsVertical = sash.muntins.horizontal.map(muntin => ({
            ...muntin,
            direction: 'vertical',
        }));
        sash.muntins.horizontal = sashMuntinsHorizontal;
        sash.muntins.vertical = sashMuntinsVertical;

        sash.intSashes.forEach(intSash => {
            intSash.nearMullions = intSash.nearDividers;
            intSash.nearAlignments = intSash.nearAlignments || {
                top: -1,
                bottom: -1,
                left: -1,
                right: -1,
            };
            delete intSash.nearDividers;

            const intSashMuntinsHorizontal = intSash.muntins.vertical.map(muntin => ({
                ...muntin,
                direction: 'horizontal',
            }));
            const intSashMuntinsVertical = intSash.muntins.horizontal.map(muntin => ({
                ...muntin,
                direction: 'vertical',
            }));
            intSash.muntins.horizontal = intSashMuntinsHorizontal;
            intSash.muntins.vertical = intSashMuntinsVertical;
        });
        delete sash.nearDividers;
        if (
            !core.fIdO(
                configuration.usedProfiles,
                dataRequiredToUpdate.profilesIdMap.sash_frames[oldSashFrameId]
            )
        ) {
            const sashProfile = core.fIdO(
                dataRequiredToUpdate.profiles,
                dataRequiredToUpdate.profilesIdMap.sash_frames[oldSashFrameId]
            );
            if (sashProfile) {
                configuration.usedProfiles.push(sashProfile);
            }
        }
    });
    configuration.sideProfiles = { left: [], right: [], top: [], bottom: [] };
    const profileAccessoriesTypes = [
        'widening',
        'concealed_strip',
        'coupling',
        'alignment',
        'kicker',
        'other_profile',
    ];
    ['top', 'bottom', 'left', 'right'].forEach(side => {
        configuration.sideProfiles[side] = configuration.sideAccessories[side]
            .filter(accessory => profileAccessoriesTypes.indexOf(accessory.type) > -1)
            .map(accessory => {
                if (
                    !core.fIdO(
                        configuration.usedProfiles,
                        dataRequiredToUpdate.profilesIdMap.window_accessories[accessory.id]
                    )
                ) {
                    const accessoryProfile = core.fIdO(
                        dataRequiredToUpdate.profiles,
                        dataRequiredToUpdate.profilesIdMap.window_accessories[accessory.id]
                    );
                    if (accessoryProfile) {
                        configuration.usedProfiles.push(accessoryProfile);
                    }
                }
                return {
                    profileId: dataRequiredToUpdate.profilesIdMap.window_accessories[accessory.id],
                    shift: 0,
                    shiftPin: false,
                    length: accessory.amount,
                    lengthPin: false,
                    count: accessory.count,
                    color: accessory.color,
                    wood: accessory.wood,
                    comment: accessory.comment,
                };
            });
    });
    return configuration;
}
