import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    TemplateRef,
} from '@angular/core';

@Component({
    selector: 'icc-select-box',
    templateUrl: './select-box.component.html',
    styleUrls: ['./select-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectBoxComponent implements OnInit {
    constructor() {}

    @Input() disabled = false;
    @Input() disabledButton1 = false;
    @Input() disabledButton2 = false;
    @Input() disabledButton3 = false;
    @Input() disabledButtonExpandMore = false;
    @Input() title?: string;
    @Input() infoContent = false;
    @Input() description?: string;
    @Input() imageUrl = '';
    @Input() color?: string;
    @Input() buttonText?: string;
    @Input() buttonText2?: string;
    @Input() buttonText3?: string;
    @Input() buttonExpandMore?: string
    @Input() colorSelect = false;
    @Input() descriptionTemplate?: TemplateRef<any>;
    @Input() descriptionContext?: any;
    @Input() buttonCustomStyle?: boolean;
    @Output() select = new EventEmitter();
    @Output() select2 = new EventEmitter();
    @Output() select3 = new EventEmitter();
    @Output() selectExpandMore = new EventEmitter();
    @Output() showInfoContent = new EventEmitter();

    ngOnInit() {}
}
