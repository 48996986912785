import angular from 'angular';

/**
 * Kontroler okna modal systemu
 * @param {object} $scope         Angular Scope
 * @param {object} $uibModalInstance Okno modal
 * @param {object} systems         Napędy
 * @param {object} selectedSystem       Ustawianie napędu
 * @param {object} type           Typ
 */
export default function ModalSystemsCtrl($scope, $uibModalInstance, systems, selectedSystem, type) {
    'ngInject';

    var vm      = this;
    vm.systems   = systems;
    vm.selectedSystem = selectedSystem;
    vm.type     = type;

    vm.selectSystem = function(system) {
        $uibModalInstance.close(system);
    };

    vm.isSelected = function(system) {
        if (angular.isDefined(vm.selectedSystem) && vm.selectedSystem !== null && angular.isDefined(system) && system !== null) {
            if (system.id == vm.selectedSystem.id /* && "RAL"==$scope.data.selColor.colorType*/) {
                return true;
            }
        }
        return false;
    };

    vm.close = function() {
        $uibModalInstance.close();
    };
}
