import 'angular-ui-bootstrap';

/**
 * Kontroler modal'a wysyłki maila z ofertą do klienta.
 *
 * @export
 * @class ModalClientEmailOptionsCtrl
 */
export class ModalClientEmailOptionsCtrl {

    options;
    dimensionUnits;
    defaultMarketCurrencyBlock;

    // eslint-disable-next-line max-params
    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private IccConfig,
        private LangService,
        private $translate,
        public offer,
        public user,
        public client
    ) {
        'ngInject';

        this.options = {
            docType: 'offer',
            priceType: 'all',
            lang: this.LangService.getLang(),
            currency: offer.dealer_currency && user.dealer.currencies[offer.dealer_currency] ? offer.dealer_currency : user.market_currency,
            currencyExchangeRate: offer.dealer_currency_exchange_rate && offer.dealer_currency && user.dealer.currencies[offer.dealer_currency] ? offer.dealer_currency_exchange_rate : 1,
            flipSymbols: user.flip_symbols,
            hideDimensions: false,
            dimensionUnit: 'default',
            hideClient: false,
            email: client.email || '',
            pdfOptions: 'standard',
            printMontageDescription: true
        };

        this.dimensionUnits = {
            'default': this.$translate.instant('USER|Domyślne'),
            'mm': this.$translate.instant('USER|Międzynarodowe (metry)'),
            'inch': this.$translate.instant('USER|Imperialne (cale)')
        }

        this.defaultMarketCurrencyBlock = user.defaultMarketCurrencyBlock;

        if (!offer.dealer_currency_exchange_rate) {
            this.restoreCurrency();
        }
    }

    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    send() {
        const clientEmailOptions = {
            prices: this.options.priceType !== 'no-prices',
            discounts: this.options.priceType === 'all',
            lang: this.options.lang,
            extendedDrawDimensions: this.options.docType === 'extendedDraw',
            client: this.options.priceType !== 'no-client' && !this.options.hideClient,
            currency: null,
            currencyExchangeRate: null,
            hideDimensions: this.options.hideDimensions,
            dimensionUnit: this.options.dimensionUnit,
            email: this.options.email,
            pdfOptions: this.options.pdfOptions,
            printMontageDescription: this.options.printMontageDescription
        };
        if (this.availableCurrencyChange) {
            clientEmailOptions.currency = this.options.currency;
            clientEmailOptions.currencyExchangeRate = this.options.currencyExchangeRate;
        }
        this.$uibModalInstance.close(clientEmailOptions);
    }


    /**
     * Zamyka modal.
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    close() {
        this.$uibModalInstance.close();
    }


    restoreCurrency() {
        if (this.availableCurrencyChange) {
            this.options.currencyExchangeRate = this.user.dealer.currencies[this.options.currency];
        }
    }

    get availableCurrencyChange() {
        return this.IccConfig.PDF.enableCurrencyChange
            && !this.options.word
            && !this.order
            && this.options.docType !== 'orderPreview'
            && ['dealer', 'dealerh'].includes(this.user.access);
    }

    get showRestoreButton() {
        return this.availableCurrencyChange
            && this.options.currencyExchangeRate !== this.user.dealer.currencies[this.options.currency];
    }

    get order() {
        return Boolean(Number(this.offer.order));
    }
}
