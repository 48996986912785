<mat-card>
  <mat-card-header>
      <img mat-card-avatar class="accessory-box-img"
           [src]="'/files/cassonetto/' + accessory?.image"
           [alt]="accessory?.name"
           defaultImg>
      <mat-card-title class="accessory-box-title">{{accessory?.name}}
      </mat-card-title>
      <mat-card-subtitle class="accessory-box-description">
          {{accessory?.description}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
      <form [formGroup]="form">
          <!-- <a class="showModal" href=""
          ng-click="complementary_goods.openModalComplementaryGoodsImage(cassonetto)"><i
              class="fa fa-info-circle"></i>
          {{ 'CONFIGURATOR|zobacz opis wymiarów' | translate }}</a> -->
          <mat-form-field>
              <mat-label>{{ 'COLOR|Kolor:' | translate }}</mat-label>
              <mat-select formControlName="selectedColor">
                  <mat-option *ngFor="let color of accessory.colors"
                              [value]="color.window_color_id">
                      {{color.name}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field>
              <mat-label>L</mat-label>
              <input matInput iccDimensionInput formControlName="currentWidth" type="number">
              <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
          <mat-form-field>
              <mat-label>H</mat-label>
              <input matInput iccDimensionInput formControlName="currentHeight" type="number">
              <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
          <mat-form-field *ngIf="accessory.show_p1 == '1'">
              <mat-label>P1</mat-label>
              <input matInput iccDimensionInput formControlName="currentP1" type="number">
              <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
          <mat-form-field *ngIf="accessory.show_p2 == '1'">
              <mat-label>P2</mat-label>
              <input matInput iccDimensionInput formControlName="currentP2" type="number">
              <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
          <mat-form-field *ngIf="accessory.show_l1 == '1'">
              <mat-label>L1</mat-label>
              <input matInput iccDimensionInput formControlName="currentL1" type="number">
              <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
          <mat-form-field>
              <input matInput type="number" min="1"
                    [placeholder]="'INTERFACE|Ilość' | translate"
                    formControlName="count">
          </mat-form-field>
          <h4>{{ 'OFFER|Cena' | translate }}:
              {{ currentPrice$ | async | currency : currency }}</h4>
      </form>
  </mat-card-content>
  <mat-card-actions>
      <button mat-raised-button color="primary" (click)="add()"
              *ngIf="canAdd()">{{ 'INTERFACE|Dodaj' | translate }}</button>
  </mat-card-actions>


</mat-card>
