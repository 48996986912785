import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';
import { ModalService, StepsService, NoopPageComponent, isArray } from '@icc/configurator/shared';
import { TranslateService } from '@icc/common/translate.service';
import { Inject, Injectable } from '@angular/core';
import {
    APP_CONFIG,
    AppConfigFactory,
    ConfigurationsService,
    ConfiguratorsDataService,
    ParametersService,
    ProfilesService,
    EventBusService,
    WindowActiveConfiguration,
    UserService,
} from '@icc/common';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceService } from '@icc/price';
import { ConfiguratorsAvailabilityService } from '../../configurators-availability.service';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';
import { Profile } from '@icc/window';
import {
    IccAccessoryAccessory,
    IccConstructColor,
    IccFilling,
    IccWarmEdge,
    IccAccessoryColor,
} from '@icc/common/data-types';
import { AccessoriesService, filterAccessories } from '../window/accessories/accessories.service';
import { AccessoriesListPageComponent } from 'libs/configurator/accessory/src/lib/accessories-list-page/accessories-list-page.component';
import { SillsListPageComponent } from 'libs/configurator/accessory/src/lib/sills-list-page/sills-list-page.component';
import { CassonettosListPageComponent } from 'libs/configurator/accessory/src/lib/cassonettos-list-page/cassonettos-list-page.component';
import { FillingsListPageComponent } from 'libs/configurator/window/src/lib/fillings-list-page/fillings-list-page.component';
import { BehaviorSubject } from 'rxjs';
import { ProfilesListPageComponent } from 'libs/configurator/window/src/lib/profiles/profiles-list-page/profiles-list-page.component';
import { TimeLimitService } from '@icc/common/time-limit/time-limit.service';
import { ProfileColorsService } from 'libs/configurator/window/src/lib/profiles/profile-colors.service';
import { NewColorsService } from 'libs/configurator/window/src/lib/colors/new-colors.service';

@Injectable()
export class ComplementaryGoodsService {
    loadedData = false;
    loaded = {
        glass: false,
        windowsill: false,
        cassonetto: false,
        profile: false,
        accessory: false,
    };

    currentGood = { type: 'accessory' };
    glassData: {
        fillingsCategories: any[];
        glassTypes: IccFilling[];
        warmEdges: IccWarmEdge[];
        glassTypesSubcategories: any[];
        fillings: IccFilling[];
        glazingRestricts: any;
    } = {
        fillingsCategories: [],
        glassTypes: [],
        warmEdges: [],
        glassTypesSubcategories: [],
        fillings: [],
        glazingRestricts: [],
    };
    cassonettosData: {
        windowColorsAll: IccConstructColor[];
        productPricelists: any[];
        cassonettos: any[];
        cassonettoGroups: any[];
    } = {
        windowColorsAll: [],
        productPricelists: [],
        cassonettos: [],
        cassonettoGroups: [],
    };
    sillsData: {
        sills: any[];
    } = {
        sills: [],
    };
    accessoriesData: {
        accessories: IccAccessoryAccessory[];
        categories: any[];
        subcategories: any[];
        colors: IccAccessoryColor[];
        indexedColors: Record<IccAccessoryColor['id'], IccAccessoryColor>;
    } = {
        accessories: [],
        categories: [],
        subcategories: [],
        colors: [],
        indexedColors: {},
    };
    colorsAll: IccAccessoryColor[] = [];
    profilesData: {
        profilesTypes: {
            coupling: string;
            extension: string;
            other: string;
        };
        profiles: Profile[];
    } = {
        profilesTypes: {
            coupling: 'Łącznik',
            extension: 'Poszerzenie',
            other: 'Inny profil',
        },
        profiles: [],
    };

    constructor(
        private modalService: ModalService,
        private translateService: TranslateService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private configurationsService: ConfigurationsService<'complementary_goods'>,
        private currentConfiguratorService: CurrentConfiguratorService,
        private configuratorsDataService: ConfiguratorsDataService,
        private priceService: PriceService,
        private parametersService: ParametersService,
        private stepService: StepsService,
        private newColorsService: NewColorsService,
        private profilesService: ProfilesService,
        private eventBusService: EventBusService,
        private configuratorsAvailabilityService: ConfiguratorsAvailabilityService,
        private profilesPriceService: ProfilesPriceService,
        private userService: UserService,
        private timeLimitService: TimeLimitService,
        private accessoriesService: AccessoriesService,
        private profileColorsService: ProfileColorsService,
    ) // ScrollbarService,
    {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
    }

    /**
     * Funkcja inicjujaca
     */
    init() {
        const conf = this.configurationsService.conf.Current;
        if (conf.type === 'complementary_goods') {
            conf.Name = this.config().IccConfig.Configurators.complementary_goods.namedAccessory
                ? this.translateService.instant('ACCESSORY|Akcesoria')
                : this.translateService.instant('GOODS|Dobra komplementarne');
        }
        this.loadAccessories();
        this.loadedData = true;
        this.priceService.count();
    }

    loadProfiles() {
        this.profilesData.profilesTypes = {
            coupling: this.translateService.instant('WINDOW|Łącznik'),
            extension: this.translateService.instant('WINDOW|Poszerzenie'),
            other: this.translateService.instant('WINDOW|Inny profil'),
        };
        this.profilesData.profiles = core
            .copy(this.configuratorsDataService.data.profiles)
            .filter(el => Object.keys(this.profilesData.profilesTypes).indexOf(el.type) > -1)
            .map(el => {
                el.currentLength = 1000;
                this.setDefaultProfileColors(el);
                return el;
            });
    }

    loadAccessories() {
        this.colorsAll = this.configuratorsDataService.data.windowHandlesColors;
        this.accessoriesData.colors = this.configuratorsDataService.data.windowHandlesColors;
        this.accessoriesData.accessories = core
            .copy(this.configuratorsDataService.data.windowAccessories)
            .filter(el => {
                return el.access_conf;
            })
            .map(el => {
                el.colorOptions = 'white';
                if (el.price_source === 'confColors') {
                    this.setDefaultColors(el, this.configurationsService.conf.Current);
                }
                if (el.price_type === 2) {
                    el.currentLength = 1000;
                }
                if (el.price_type === 1) {
                    el.currentWidth = 1000;
                    el.currentHeight = 1000;
                }
                if (el.colors_ids && el.colors_ids.length > 0 && el.price_source === 'colors') {
                    el.selectedColor = el.colors_ids[0];
                }
                return el;
            });
        const filteredCategories = this.accessoriesService.filterWindowAccessoriesCategories(this.configuratorsDataService.data.windowAccessoriesCategories);
        this.accessoriesData.categories = filteredCategories.filter(
            (el) => filterAccessories(this.accessoriesData.accessories, el.id).length > 0
        );
        this.accessoriesData.subcategories = this.configuratorsDataService.data
            .windowAccessoriesSubCategories
            ? this.configuratorsDataService.data.windowAccessoriesSubCategories.filter((el) => {
                  return filterAccessories(this.accessoriesData.accessories, el.id).length > 0;
              })
            : [];
        const indexedColors = {};
        for (let i = 0; i < this.accessoriesData.colors.length; i++) {
            indexedColors[this.accessoriesData.colors[i].id] = this.accessoriesData.colors[i];
        }
        this.accessoriesData.indexedColors = indexedColors;
    }

    loadSills() {
        this.sillsData.sills = this.configuratorsDataService.data.windowSillsGoods;
    }

    loadCassonettos() {
        this.cassonettosData.windowColorsAll = this.configuratorsDataService.data.windowColorsAll;
        this.cassonettosData.productPricelists = this.configuratorsDataService.data.productPricelists;
        this.cassonettosData.cassonettos = core.copy(
            this.configuratorsDataService.data.cassonettos
        );
        this.cassonettosData.cassonettos = this.cassonettosData.cassonettos.map(cassonetto => {
            cassonetto.colors = this.setCassonettoColors(cassonetto);
            return cassonetto;
        });
        this.cassonettosData.cassonettoGroups = this.configuratorsDataService.data.cassonettoGroups;
    }

    loadGlazings() {
        this.glassData.fillingsCategories = this.configuratorsDataService.data.fillingsCategory;
        this.glassData.glassTypes = core.copy(
            this.configuratorsDataService.data.fillings.filter(el => el.type === 'glazing')
        );
        this.glassData.warmEdges = this.configuratorsDataService.data.windowWarmEdges;
        this.glassData.fillings = core.copy(this.configuratorsDataService.data.fillings);
        this.glassData.glazingRestricts = this.configuratorsDataService.data.glass4mmRestricts;
    }

    /**
     * Ustawia domyślne kolory dodatku
     *
     * @param {any} accessory Dodatek
     */
    setDefaultColors(accessory: IccAccessoryAccessory, conf) {
        const outerColor = this.newColorsService.getDefaultElementColor(
            conf,
            {
                element: 'accessory',
                side: 'outer',
                accessory,
            }
        );
        const outerConstrColor = this.newColorsService.getMatchingConstrColor(conf, outerColor, {}, {
            element: 'accessory',
            side: 'outer',
            accessory,
        });
        const innerColor = this.newColorsService.getDefaultElementColor(
            conf,
            {
                element: 'accessory',
                side: 'inner',
                accessory,
            }
        );
        const innerConstrColor = this.newColorsService.getMatchingConstrColor(conf, innerColor, {}, {
            element: 'accessory',
            side: 'inner',
            accessory,
        });
        accessory.selectedColor = {
            outer: outerConstrColor ? {
                ...outerConstrColor,
                isDefault: true,
            } : null,
            inner: innerConstrColor ? {
                ...innerConstrColor,
                isDefault: true,
            } : null,
        };
        const coreColor = this.newColorsService.getDefaultElementColor(
            conf,
            {
                element: 'accessory',
                side: 'core',
                accessory,
            }
        );
        accessory.selectedColor.core = coreColor ? {
            ...coreColor,
            isDefault: true,
        } : null;
        // accessory.selectedWood = core.copy(config.Wood);
    }

    /**
     * Ustawia domyślne kolory profilu
     *
     * @param {any} accessory Dodatek
     */
    setDefaultProfileColors(profile) {
        this.profileColorsService.setDefaultProfileColorSet(
            this.configurationsService.conf.Current as any,
            profile,
            profile.selectedColor || {}
        )
        profile.price = this.profilesPriceService.getProfilePrice(
            profile.id,
            profile.type,
            {
                id: profile.systems[0],
                type: 'pvc',
            },
            profile.selectedColor || {},
            this.configuratorsDataService.data.profilesPrices
        );
        this.calcProfilePrice(profile);
    }

    /**
     * Ustawia dostępne kolory dla kasonetki
     *
     * Funkcja analogiczna do funkcji z ComplementaryGoodsActiveConfiguration
     * @param {Object} cassonetto Kasonetka
     * @return {Array} Lista kolorów
     */
    public setCassonettoColors(cassonetto) {
        const colorGroupIds =
            cassonetto.pricelists && cassonetto.pricelists.map(a => a.window_color_group_id);
        return (
            (colorGroupIds
                && this.cassonettosData.windowColorsAll.filter(e =>
                    colorGroupIds.find(
                        search =>
                            e.groups
                            && typeof e.groups.find(
                                windowColorGroup => windowColorGroup === search
                            ) !== 'undefined'
                    )
                ))
            || []
        );
    }

    /**
     * Ustawia typ dobra
     * @param {Object} type Typ
     */
    public setGoodType(type) {
        this.currentGood.type = type;
        if (!this.stepService.hasStep('accessories')) {
            this.stepService.selectStep(type);
        }

        if (!this.loaded[type]) {
            switch (type) {
                case 'glass':
                    this.loadGlazings();
                    break;
                case 'windowsill':
                    this.loadSills();
                    break;
                case 'cassonetto':
                    this.loadCassonettos();
                    break;
                case 'profile':
                    this.loadProfiles();
                    break;
                case 'accessory':
                    this.loadAccessories();
                    break;
            }
            this.loaded[type] = true;
        }
    }

    /**
     * Typ jest właczony
     * @param  {Object}  type Typ
     * @return {Boolean}      Czy typ jest właczony
     */
    public isEnabledType(type) {
        return (
            this.config().IccConfig.Configurators.complementary_goods.types.includes(type)
            && this.configuratorsAvailabilityService.availableConfigs.complementaryGoodsConfigs[
                type
            ]
        );
    }

    /**
     * Dodaj
     * @param {Object} place     Miejsce
     * @param {Object} accessory Akcesorium
     * @param {Object} color     Kolor
     * @param {Object} $event    Zdarzenie
     * @param {Object} type      Typ
     */
    public add(accessory, color, type) {
        const inputCount = accessory && accessory.count;
        let inputAmountWidth = accessory.currentWidth;
        const inputAmountHeight = accessory.currentHeight;
        const colorOptions = accessory.colorOptions;

        if (type === 'accessory') {
            if (Number(accessory.price_type) === 2) {
                inputAmountWidth = accessory.currentLength;
            }
            accessory.sel_width = inputAmountWidth;
            accessory.sel_height = inputAmountHeight;
        }
        if (type === 'glass') {
            accessory.sel_width = inputAmountWidth;
            accessory.sel_height = inputAmountHeight;
            const warmEdge = this.glassData.warmEdges.find(
                w => Number(w.id) === Number(accessory.warmEdge)
            );
            accessory.warmEdgeName = warmEdge && warmEdge.name;
        }
        if (type === 'cassonetto') {
            accessory.sel_width = accessory.currentWidth;
            accessory.sel_height = accessory.currentHeight;
        }

        if (type === 'windowsill') {
            if (accessory.in_range || accessory.pricing_type !== 'standard') {
                inputAmountWidth = accessory.currentLength;
            }
            accessory.sel_width = accessory.currentWidth;
        }

        if (type === 'profile') {
            inputAmountWidth = accessory.currentLength;
        }

        let count = 0;
        if (Number(inputCount)) {
            count = Number(inputCount);
        }

        let amount = 0;
        let weight = 0;

        if (Number(accessory.price_type) === 1) {
            if (parseFloat(inputAmountWidth) && parseFloat(inputAmountHeight)) {
                amount = (parseFloat(inputAmountWidth) * parseFloat(inputAmountHeight)) / 1000000;
                weight =
                    ((Number(inputCount)
                        * parseFloat(inputAmountWidth)
                        * parseFloat(inputAmountHeight))
                        / 1000000)
                        * parseFloat(accessory.weight) || 0;
            }
        } else {
            if (parseFloat(inputAmountWidth) > 0) {
                amount = parseFloat(inputAmountWidth);
                weight = (parseFloat(inputAmountWidth) / 1000) * parseFloat(accessory.weight) || 0;
            } else {
                weight = parseFloat(accessory.weight) || 0;
            }
        }

        const ComplementaryGoodsArr = this.configurationsService.conf.Current.ComplementaryGoods[
            type
        ];

        let found = false;
        for (let i = 0; i < ComplementaryGoodsArr.length; i++) {
            if (type === "windowsill") {
                const windowSillSameLengthAndDifferentColor = this.getWindowSillWithTheSameLengthAndDifferentColor(ComplementaryGoodsArr[i], accessory);
                const windowSillSameLengthAndColor = this.getWindowSillWithTheSameLengthAndColor(ComplementaryGoodsArr[i], accessory);
                if ((windowSillSameLengthAndDifferentColor || windowSillSameLengthAndColor) &&
                    ComplementaryGoodsArr[i].accessory.selectedColor === color) {
                    ComplementaryGoodsArr[i].count += count;
                    ComplementaryGoodsArr[i].comment = accessory.comment || '';
                    found = true;
                }
            } else if (
                ComplementaryGoodsArr[i].accessory.id === accessory.id
                && ComplementaryGoodsArr[i].accessory.sel_width === accessory.sel_width
                && ComplementaryGoodsArr[i].accessory.sel_height === accessory.sel_height
                && ComplementaryGoodsArr[i].accessory.warmEdge === accessory.warmEdge
                && ComplementaryGoodsArr[i].accessory.currentColor === accessory.currentColor
                && ComplementaryGoodsArr[i].accessory.plugs === accessory.plugs
                && ComplementaryGoodsArr[i].accessory.currentLength === accessory.currentLength
                && ComplementaryGoodsArr[i].accessory.currentP1 === accessory.currentP1
                && ComplementaryGoodsArr[i].accessory.currentP2 === accessory.currentP2
                && ComplementaryGoodsArr[i].accessory.currentL1 === accessory.currentL1
                && (typeof accessory.selectedColor !== 'object'
                            || typeof ComplementaryGoodsArr[i].accessory.selectedColor !== 'object'
                            || accessory.selectedColor
                            && accessory.selectedColor.core
                            && accessory.selectedColor.inner
                            && accessory.selectedColor.outer
                            && ComplementaryGoodsArr[i].accessory.selectedColor
                            && ComplementaryGoodsArr[i].accessory.selectedColor.core
                            && ComplementaryGoodsArr[i].accessory.selectedColor.inner
                            && ComplementaryGoodsArr[i].accessory.selectedColor.outer
                            && ComplementaryGoodsArr[i].accessory.selectedColor.core.id == accessory.selectedColor.core.id
                            && ComplementaryGoodsArr[i].accessory.selectedColor.inner.id == accessory.selectedColor.inner.id
                            && ComplementaryGoodsArr[i].accessory.selectedColor.outer.id == accessory.selectedColor.outer.id)
                && ComplementaryGoodsArr[i].colorOptions === colorOptions
            ) {
                ComplementaryGoodsArr[i].count += count;
                ComplementaryGoodsArr[i].comment = accessory.comment || '';
                found = true;
            }
        }

        if (!found) {
            let colorName = '';
            const colorOptionsName = {
                white: this.translateService.instant('COLOR|Biały'),
                'color-white':
                    this.translateService.instant('COLOR|Kolor')
                    + '/'
                    + this.translateService.instant('COLOR|Biały'),
                'color-color':
                    this.translateService.instant('COLOR|Kolor')
                    + '/'
                    + this.translateService.instant('COLOR|Kolor'),
                ral: this.translateService.instant('COLOR|Biały'),
            };
            count = count || 1;

            if (Common.isDefined(this.accessoriesData.indexedColors[accessory.currentColor])) {
                colorName = this.accessoriesData.indexedColors[accessory.currentColor].name;
            } else if (
                Common.isDefined(this.accessoriesData.indexedColors[accessory.selectedColor])
            ) {
                colorName = this.accessoriesData.indexedColors[accessory.selectedColor].name;
            } else if (Common.isDefined(colorOptionsName[colorOptions])) {
                colorName = colorOptionsName[colorOptions];
            } else if (accessory.colors) {
                color = accessory.colors.find(
                    cassonettoColor => cassonettoColor.id === accessory.selectedColor
                );
                colorName = color.name;
            }
            accessory = core.copy(accessory);
            accessory.color = core.copy(color);
            ComplementaryGoodsArr.push({
                count,
                amount,
                accessory: core.copy(accessory),
                weight,
                colorOptions,
                color: Common.isObject(color) ? color.id : null,
                colorName,
                comment: accessory.comment,
            });
        }

        this.priceService.count();
        this.eventBusService.post({
            key: 'changedStep',
            value: {
                prevStep: {
                    i: this.stepService.getStepByCode(this.configurationsService.conf.stepCode),
                    code: this.configurationsService.conf.stepCode,
                },
                nextStep: {
                    i: this.stepService.getStepByCode(this.configurationsService.conf.stepCode),
                    code: this.configurationsService.conf.stepCode,
                },
            },
        });
        // this.parametersService.count(this.configurationsService.conf.Current);
    }

    private getWindowSillWithTheSameLengthAndDifferentColor(ComplementaryGoodsArr, accessory) {
        return (
            ComplementaryGoodsArr.accessory.id === accessory.id &&
            ComplementaryGoodsArr.accessory.currentLength === accessory.currentLength &&
            ComplementaryGoodsArr.accessory.selectedColor !== accessory.selectedColor
        );
    }

    private getWindowSillWithTheSameLengthAndColor(ComplementaryGoodsArr, accessory) {
        return (
            ComplementaryGoodsArr.accessory.id === accessory.id &&
            ComplementaryGoodsArr.accessory.currentLength === accessory.currentLength &&
            ComplementaryGoodsArr.accessory.selectedColor === accessory.selectedColor
        );
    }
    /**
     * Usuń
     * @param  {String} index Index
     * @param  {String} key   Klucz
     */
    public remove(access, key) {
        const index = this.configurationsService.conf.Current.ComplementaryGoods[key].indexOf(
            access
        );
        this.configurationsService.conf.Current.ComplementaryGoods[key].splice(index, 1);

        this.priceService.count();
        // ScrollbarService.update();
        // this.parametersService.count(this.configurationsService.conf.Current);
    }

    /**
     * Otwieranie obrazka modala dóbr komplementarych
     * @param  {Object} good Dobro
     */
    public openModalComplementaryGoodsImage(good) {
        const modalInstance = this.modalService.open({
            templateUrl: 'modalComplementaryGoodsImage.html',
            controller: 'ModalComplementaryGoodsImageCtrl as good',
            pageComponent: NoopPageComponent,
            resolve: {
                good() {
                    return good;
                },
            },
        });

        modalInstance.result.then(() => {});
    }

    openModalAccessories(callback?) {
        this.setGoodType('accessory');
        const forIt = 'configuration';
        const filteredCategories = this.accessoriesService.filterWindowAccessoriesCategories(this.configuratorsDataService.data.windowAccessoriesCategories);

        const modalAccessories = this.accessoriesData.accessories.filter(access => {
            const category = filteredCategories.filter(
                (el) => el.id === access.window_accessories_category_id
            );
            return access.access_conf || !category.show;
        });

        const categories = filteredCategories.filter(
            (el) => filterAccessories(modalAccessories, el.id).length > 0
        );

        const subcategoriesArr =
            this.accessoriesData.subcategories
            ? this.accessoriesData.subcategories.filter(
                el => filterAccessories(modalAccessories, el.parent_id, el.id).length > 0
            ) : [];

        const modalInstance = this.modalService.open({
            templateUrl: 'modalAccessories.html',
            controller: 'ModalAccessoriesCtrl as maccessory',
            pageComponent: AccessoriesListPageComponent,
            resolve: {
                hideTitle: () => this.config().filterAccessoriesByCategories,
                forIt: () => {
                    return forIt;
                },
                accessories: () => {
                    const accessories = modalAccessories.map(el => {
                        if (el.price_source === 'confColors') {
                            this.setDefaultColors(el, this.configurationsService.conf.Current);
                        }
                        if (isArray(el.colors_ids) && el.price_source === 'colors') {
                            const accessoryColor = this.colorsAll.find(
                                color =>
                                    el.colors_ids
                                    && el.colors_ids.some(id => Number(id) === Number(color.id))
                            );
                            if (accessoryColor) {
                                el.selectedColor = accessoryColor.id;
                            }
                        }
                        if (!el.blockAmountChange) {
                            el.amount = null;
                        }
                        return el;
                    });
                    return accessories;
                },
                categories: () => {
                    return categories;
                },
                subcategories: () => {
                    return subcategoriesArr.map(el => {
                        el.id *= 1;
                        el.parent_id *= 1;
                        return el;
                    });
                },
                sash: () => {
                    return null;
                },
                sashNum: () => {
                    return null;
                },
                colors: () => {
                    return this.colorsAll;
                },
                noPrice: () => {
                    return this.configurationsService.price.noPrice;
                },
            },
        });

        return modalInstance.result.then(
            (result: { accessory: IccAccessoryAccessory; color?: IccAccessoryColor }) => {
                if (callback && typeof callback === 'function') {
                    callback(result);
                } else {
                    if (result) {
                        result.accessory.values = {
                            count: result.accessory.count,
                        };
                        result.accessory.currentWidth = result.accessory.amount_width;
                        result.accessory.currentLength =
                            result.accessory.amount || result.accessory.amount_width;
                        result.accessory.currentHeight = result.accessory.amount_height;
                        this.add(result.accessory, result.color, 'accessory');
                    }
                    if (this.config().IccConfig.Configurators.dependencies) {
                        this.eventBusService.post({ key: 'processDependencies', value: null });
                        this.priceService.count();
                    }
                    this.eventBusService.post({
                        key: 'icc-redraw',
                        value: 'frame',
                    });
                    this.timeLimitService.count();
                }
            }
        );
    }

    openModalSills(callback?) {
        this.setGoodType('windowsill');

        const modalAccessories = this.sillsData.sills;

        const modalInstance = this.modalService.open({
            templateUrl: 'modalAccessories.html',
            controller: 'ModalAccessoriesCtrl as maccessory',
            pageComponent: SillsListPageComponent,
            resolve: {
                sills: () => {
                    return modalAccessories;
                },
                noPrice: () => {
                    return this.configurationsService.price.noPrice;
                },
            },
        });

        return modalInstance.result.then((result: { accessory: IccAccessoryAccessory }) => {
            if (callback && typeof callback === 'function') {
                callback(result);
            } else {
                if (result) {
                    this.add(result.accessory, result.accessory.selectedColor, 'windowsill');
                }
                if (this.config().IccConfig.Configurators.dependencies) {
                    this.eventBusService.post({ key: 'processDependencies', value: null });
                    this.priceService.count();
                }
                this.eventBusService.post({
                    key: 'icc-redraw',
                    value: 'frame',
                });
                this.timeLimitService.count();
            }
        });
    }

    openModalCassonettos(callback?) {
        this.setGoodType('cassonetto');

        const modalAccessories = this.cassonettosData.cassonettos;

        const modalInstance = this.modalService.open({
            templateUrl: 'modalAccessories.html',
            controller: 'ModalAccessoriesCtrl as maccessory',
            pageComponent: CassonettosListPageComponent,
            resolve: {
                cassonettos: () => {
                    return modalAccessories;
                },
                groups: () => {
                    return this.cassonettosData.cassonettoGroups;
                },
                colors: () => {
                    return this.cassonettosData.windowColorsAll;
                },
                productPricelists: () => {
                    return this.cassonettosData.productPricelists;
                },
                noPrice: () => {
                    return this.configurationsService.price.noPrice;
                },
            },
        });

        return modalInstance.result.then((result: { accessory: IccAccessoryAccessory }) => {
            if (callback && typeof callback === 'function') {
                callback(result);
            } else {
                if (result) {
                    this.add(result.accessory, result.accessory.selectedColor, 'cassonetto');
                }
                if (this.config().IccConfig.Configurators.dependencies) {
                    this.eventBusService.post({ key: 'processDependencies', value: null });
                    this.priceService.count();
                }
                this.eventBusService.post({
                    key: 'icc-redraw',
                    value: 'frame',
                });
                this.timeLimitService.count();
            }
        });
    }

    public openModalFilling() {
        this.setGoodType('glass');

        let matchingFillings = this.glassData.glassTypes;
        let selectedFilling: string | null = null;
        let bondedGlazing = false;
        let noDivInSash = true;
        const user = this.userService.get();
        const modalInstance = this.modalService.open({
            templateUrl: 'modalGlazing.html',
            controller: 'ModalGlazingCtrl as mglazing',
            pageComponent: FillingsListPageComponent,
            resolve: {
                complementary: () => true,
                fillings: () => matchingFillings,
                fillingsCategories: () => this.glassData.fillingsCategories,
                glassTypes: () => [],
                glassTypeVariants: () => [],
                modalData: () => {},
                selGlassType: () => null,
                selectFor: () => null,
                noDivInSash: () => noDivInSash,
                hasGlasses: () => false,
                bondedGlazing: () => bondedGlazing,
                b2c: () => !user || !user.access || user.access === 'klient',
                filters$: () =>
                    new BehaviorSubject<any>({
                        selectedFilling: null,
                        filters: {
                            thermalTransmittance: 0,
                            glassCount: 0,
                            securityLevelOutside: 0,
                            securityLevelInside: 0,
                            noiseProtection: 0,
                            glassType: '0',
                            glassTypeVariant: 0,
                        },
                        classicFilters: null,
                    }),
                addGlazingUnit: () => () => {},
                warmEdges: () => this.glassData.warmEdges,
            },
        });

        return modalInstance.result.then(result => {
            if (result) {
                this.add(result.glass, result.glass.selectedColor, 'glass');
            }
        });
    }

    async openModalProfile(): Promise<void | Profile> {
        this.setGoodType('profile');
        const modalInstance = this.modalService.open({
            component: 'profilesModal',
            pageComponent: ProfilesListPageComponent,
            resolve: {
                profiles: () => this.profilesData.profiles,
                profilesPrices: () => this.profilesService.profilesPrices,
                system: () => null,
                type: () => Object.keys(this.profilesData.profilesTypes),
                selectedProfile: () => null,
                currency: () => this.config().currency,
                color: () => null,
                wood: () => null,
            },
        });

        return modalInstance.result.then(result => {
            if (result) {
                this.calcProfilePrice(result);
                result.price = core.copy(result.complementaryPrice);
                this.add(result, result.selectedColor, 'profile');
            }
        });
    }

    public calcProfilePrice(profile) {
        let price = NaN;
        if (profile.price) {
            price =
                profile.price.price_piece
                + (profile.price.price_length * profile.currentLength) / 1000;
        }
        profile.complementaryPrice = price;
        return price;
    }
}
