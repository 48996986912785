import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import {
    StepComponent,
    _,
    ICC_PAGE_DATA,
    SharedFacade,
    ConfiguratorOptions,
} from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { Observable } from 'rxjs';
import { WindowFacade } from 'libs/configurator/window/src/lib/+state/window.facade';
import { map } from 'rxjs/operators';
import { LocksService } from '@icc/legacy/configurator/steps/door/locks/locks.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { HardwareService } from '../hardware/hardware.service';
import { ConfigurationsService, TranslateService } from '@icc/common';
interface LockSelectBox {
    imgPath: string;
    name: string;
}

@Component({
    selector: 'icc-locks',
    templateUrl: './locks.component.html',
    styleUrls: ['./locks.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocksComponent extends StepComponent implements OnInit {
    static stepName = _('STEPS|Zamki');
    static stepIcon = {
        ligature: 'lock',
    };
    public configurator = 'door';
    public stepId = 'locks';
    public title = _('WINDOW|Wybierz zamek');

    selectedLock$ = this.windowFacade.selectedLock$;

    lockSelectBox: LockSelectBox | null = null;
    locks: iccListItem[] = [];
    lockBoxes = [];
    static stepEnable = (conf: WindowActiveConfiguration, options: ConfiguratorOptions) =>
        conf.type === 'door' && (!conf.System || !Boolean(conf.System.door_type));

    constructor(
        private windowFacade: WindowFacade,
        private sharedFacade: SharedFacade,
        private locksService: LocksService,
        private hardwareService: HardwareService,
        private configurationsService: ConfigurationsService<'door'>,
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this.locks = this.locksService.locks.map((lock) => ({
            id: Number(lock.id),
            title: lock.name,
            imageUrl: '/files/windowaccessory/' + lock.img,
        }));
        this.getLockBoxes();
    }

    selectLock(item: iccListItem) {
        const lock = this.locksService.locks.find((l) => Number(l.id) === item.id);
        this.locksService.selectLock(lock);
    }

    renderMultipleLocks(conf = this.configurationsService.conf.Current) {
        if (conf.System?.default_market_configuration?.double_door_lock_id && this.lockBoxes.length > 1) {
            return true;
        } else {
            return false;
        }
    }

    getLockBoxes(conf = this.configurationsService.conf.Current) {
        this.lockBoxes = conf.Sashes.reduce((box, sash) => {
            if (sash.handle && Object.keys(sash.handle).length && sash.intSashes && Object.keys(sash.intSashes).length) {
                box.push({
                    title: `${this.translateService.instant('DOOR|Zamek w skrzydle')}: ${
                        sash.index
                    }`,
                    sash,
                    description: sash.lock?.name,
                    imageUrl: sash.lock?.img,
                    sashId: sash.id,
                    sashIndex: sash.index,
                    type: conf.HandleType,
                });
            }
            return box;
        }, []);
    }

    openLocksOptions(box, conf = this.configurationsService.conf.Current) {
        if (this.renderMultipleLocks(conf)) {
            this.hardwareService.openLockModalInSash(box);
        } else {
            this.hardwareService.openLockModal(conf);
        }
    }
}
