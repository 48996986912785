import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ACCESSORY_FEATURE_KEY, AccessoryState } from './accessory.reducer';

// Lookup the 'Accessory' feature state managed by NgRx
const getAccessoryState = createFeatureSelector<AccessoryState>(ACCESSORY_FEATURE_KEY);

const getAccessoryConfiguration = createSelector(
    getAccessoryState,
    (state: AccessoryState) => {
        return state.configuration;
    }
);

export const accessoryQuery = {
    getAccessoryConfiguration
};
