import { Component, OnInit } from '@angular/core';
import { PageComponent, _ } from '@icc/configurator/shared';

@Component({
  selector: 'icc-colors-list-page',
  templateUrl: './colors-list-page.component.html',
  styleUrls: ['./colors-list-page.component.scss']
})
export class ColorsListPageComponent extends PageComponent implements OnInit {
  public title = _('MOSQUITO|Kolor siatki');


  constructor() {
    super();
  }

  ngOnInit() {
  }

}
