import { Component, OnInit } from '@angular/core';
import { StepComponent, _ } from '@icc/configurator/shared';
import { Observable, BehaviorSubject } from 'rxjs';
import { MosquitoScreenFacade } from '../+state/mosquito-screen.facade';
import { ColorsService } from '@icc/legacy/configurator/steps/mosquito/colors.service';

@Component({
  selector: 'icc-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss']
})
export class ColorsComponent extends StepComponent implements OnInit {
  static stepName = _('STEPS|Kolor');
  static stepIcon = {
      ligature: 'palette',
  };

  public configurator = 'mosquito';
  public stepId = 'colors';
  public title = _('COLOR|Wybierz kolor:');

  colors$ = {
    frame: this.mosquitoScreenFacade.frameColor$,
    net: this.mosquitoScreenFacade.netColor$,
    panel: this.mosquitoScreenFacade.panelColor$,
  };

  colorsShow$ = {
    frame: new BehaviorSubject(true),
    net: new BehaviorSubject(true),
    panel: this.mosquitoScreenFacade.panelColorShow$,
  };

  colorsTitle = {
    frame: _('MOSQUITO|Kolor ramki moskitiery'),
    net: _('MOSQUITO|Kolor siatki'),
    panel: _('MOSQUITO|Kolor elementów z tworzywa'),
  };

  constructor(
    private mosquitoScreenFacade: MosquitoScreenFacade,
    private colorsService: ColorsService
  ) {
    super();
  }

  ngOnInit() {
  }

  openModalColor(
    type:
        | 'frame'
        | 'net'
        | 'panel'
  ) {
    this.colorsService.openModalColor(type)
  }

}
