<mat-card>
    <mat-card-header>
        <img mat-card-avatar class="accessory-box-img"
             [src]="'/files/filling/' + accessory?.image"
             [alt]="accessory?.name"
             defaultImg>
        <mat-card-title class="accessory-box-title">{{accessory?.name}}
        </mat-card-title>
        <mat-card-subtitle class="accessory-box-description">
            {{accessory?.description}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="form">
            <mat-form-field *ngIf="accessory.type === 'glazing'">
                <mat-label>{{ 'GOODS|Ciepła ramka:' | translate }}</mat-label>
                <mat-select formControlName="warmEdge">
                    <mat-option *ngFor="let warmEdge of warmEdges;"
                                [value]="warmEdge.id">
                        {{warmEdge.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'DIMENSION|Szerokość' | translate }}</mat-label>
                <input matInput iccDimensionInput formControlName="currentWidth" type="number">
                <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'DIMENSION|Wysokość' | translate }}</mat-label>
                <input matInput iccDimensionInput formControlName="currentHeight" type="number">
                <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="number" min="1"
                      [placeholder]="'INTERFACE|Ilość' | translate"
                      formControlName="count">
            </mat-form-field>
            <mat-form-field>
                <input matInput type="text" formControlName="comment"
                       [placeholder]="'INTERFACE|Komentarz' | translate">
            </mat-form-field>
            <h4>{{ 'OFFER|Cena' | translate }}:
                {{ currentPrice$ | async | currency : currency }}</h4>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="add()"
                *ngIf="canAdd()">{{ 'INTERFACE|Dodaj' | translate }}</button>
    </mat-card-actions>


</mat-card>
