import { ConfigurationsService, ConfiguratorsDataService, EventBusService } from '@icc/common';
import { PriceService } from '@icc/price';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { StepsService, isUndefined } from '@icc/helpers';
import { Injectable } from '@angular/core';
@Injectable()
export class ColorsService {
    constructor(
        private configurationsService: ConfigurationsService<'awning'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private curConfService: CurrentConfiguratorService,
        private stepsService: StepsService,
        private priceService: PriceService,
        private eventBusService: EventBusService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribeWithoutConfiguration('changedStep', () => {
            this.init();
        });
    }

    colors = [];
    color = {};
    loadedData = false;

    /**
     * Funkcja inicjujaca
     */
    init() {
        if (this.curConfService.conf === 'awning') {
            this.setValues();
            if (isUndefined(this.configurationsService.conf.Current.Colour.id)) {
                this.setDefaultValues();
            }
            this.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Ustawianie wartosci
     */
    setValues() {
        this.colors = this.getColors();
    }

    /**
     * Zwracanie kolorów
     * @return {Array} Kolory
     */
    getColors() {
        let systemID = this.configurationsService.conf.Current.SystemId;
        if (systemID > 0) {
            return this.configuratorsDataService.data.awningColours[systemID];
        } else {
            return [];
        }
    }

    /**
     * Wybór koloru
     * @param  {Object} color    Kolor
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    selectColor(color, nextStep?, price?) {
        this.configurationsService.conf.Current.Colour = color;
        this.configurationsService.conf.Default.Colour = color;

        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
        if (price) {
            this.priceService.count();
        }
    }

    /**
     * Ustawianie wartości domyślnych
     * @param {Boolean} reset Reset
     */
    setDefaultValues(reset?) {
        if (isUndefined(this.configurationsService.conf)) {
            return;
        }

        this.setValues();

        if (!Array.isArray(this.colors) || isUndefined(this.colors[0])) {
            return;
        }

        if (isUndefined(this.configurationsService.conf.Current.Colour.id) || reset) {
            this.selectColor(this.colors[0]);
        }
    }
}
