import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepComponent, _ } from '@icc/configurator/shared';
import { EventBusService, ConfigurationsService } from '@icc/common';
import { AssemblyService } from '@icc/legacy/configurator/steps/awning/assembly.service';
import { iccListItem } from '@icc/configurator/ui';

@Component({
    selector: 'icc-awning-assembly',
    templateUrl: './awning-assembly.component.html',
    styleUrls: ['./awning-assembly.component.scss'],
})
export class AwningAssemblyComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Rodzaj montażu');
    static stepIcon = {
        ligature: 'apps',
    };
    mountings: iccListItem[] = [];
    mountingId: any;
    private subscriptions: any[] = [];
    public configurator = 'awning';
    public stepId = 'assembly';
    public title = _('COLOR|Wybierz rodzaj montażu');

    constructor(
        private assemblyService: AssemblyService,
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService<'awning'>
    ) {
        super();
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }

    init() {
        this.mountings = this.assemblyService.mountings.map(el => ({
            id: Number(el.id),
            description: el.description,
            title: el.name,
            imageUrl: '/files/awningmounting/' + el.img,
        }));
    }
    
    ngOnInit() {
        if (this.configurationsService.conf.Current) {
            this.mountingId = Number(this.configurationsService.conf.Current.Mounting.id);
        }
        if (this.assemblyService.loadedData)
        {
            this.init();
        }
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    selectMounting(mounting){
        this.assemblyService.selectMounting(mounting);
        this.mountingId = mounting.id;
    }
}
