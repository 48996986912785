export class ModalNewPosition {
    constructor(
        $rootScope,
        $uibModalInstance,
        offer,
        EventBusService,
        ConfiguratorsAvailabilityService,
        fromCoupling,
        toCoupledWindow,
        CoupledWindowService
    ) {
        this.$rootScope = $rootScope;
        this.$uibModalInstance = $uibModalInstance;
        this.offer = offer;
        this.fromCoupling = fromCoupling;
        this.EventBusService = EventBusService;
        this.availableConfigs = ConfiguratorsAvailabilityService.availableConfigs;

        if (toCoupledWindow) {
            ConfiguratorsAvailabilityService.getFilteredConfiguratorsAvailability({
                windowLines: CoupledWindowService.couplingOptions.matchedWindowSystems,
                hsLines: CoupledWindowService.couplingOptions.matchedWindowSystems,
                doorLines: CoupledWindowService.couplingOptions.matchedWindowSystems,
                foldingDoorLines: CoupledWindowService.couplingOptions.matchedWindowSystems,
                slidingDoorLines: CoupledWindowService.couplingOptions.matchedWindowSystems,
            }).then(availableConfigs => (this.availableConfigs = availableConfigs));
        }
    }

    close() {
        this.$uibModalInstance.close();
    }

    goToConfigurator(code) {
        if(this.$rootScope.completelyNewPosition && !this.$rootScope.coupledPosition) {
            this.$rootScope.completelyNewPosition = true;
        }
        this.$uibModalInstance.close(code);
    }
}
