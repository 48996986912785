import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalService } from '@icc/helpers';
import { ProfilesColorsPageComponent } from './profiles-colors-page/profiles-colors-page.component';
import { Profile } from '@icc/window';
import { NewColorsService } from '../colors/new-colors.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { IccProfileSideColors } from '@icc/common/data-types';
import { ProfilesService } from '@icc/common/profiles.service';
import { Wood } from '@icc/common/configurations/parts/common';

@Injectable()
export class ProfileColorsService {
    constructor(
        private modalService: ModalService,
        private newColorsService: NewColorsService,
        private profilesService: ProfilesService
    ) {}

    async openProfileColorsModal(
        profile: Profile,
        selectedProfile: Profile,
        profilesPrices: any,
        selectedColors$: BehaviorSubject<any>,
        agreeButton = false
    ) {
        const modalInstance = this.modalService.open({
            controller: 'ModalProfileColorsCtrl as $ctrl',
            pageComponent: ProfilesColorsPageComponent,
            resolve: {
                profile: () => profile,
                profilesPrices: () => profilesPrices,
                selectedProfile: () => selectedProfile,
                selectedColors$: () => selectedColors$,
                agreeButton,
            },
        });
        return modalInstance.result;
    }

    setDefaultProfileColorSet(
        conf: WindowActiveConfiguration,
        profile: Profile,
        sideColors: IccProfileSideColors
    ): IccProfileSideColors {
        profile.selectedColor = { ...sideColors } ?? {
            outer: null,
            inner: null,
            core: null,
        };

        const matchingColorsOuter = this.newColorsService.getMatchingColorsFor(conf, {
            element: 'profile',
            side: 'outer',
            profile,
        }, true);

        const outerColorInMatching = matchingColorsOuter.find(color => color.id === sideColors?.outer?.colorId);

        const outerColor = this.newColorsService.getDefaultElementColor(conf, {
            element: 'profile',
            side: 'outer',
            profile,
        });

        const outerConstrColor = this.newColorsService.getMatchingConstrColor(
            conf,
            outerColorInMatching ?? outerColor,
            profile.selectedColor,
            {
                element: 'profile',
                side: 'outer',
                profile,
            }
        );

        profile.selectedColor.outer = outerConstrColor
            ? {
                ...outerConstrColor,
                isDefault: true,
            }
            : null;

        const matchingColorsInner = this.newColorsService.getMatchingColorsFor(conf, {
            element: 'profile',
            side: 'inner',
            profile,
        }, true);

        const innerColorInMatching = matchingColorsInner.find(color => color.id === sideColors?.inner?.colorId);

        const innerColor = this.newColorsService.getDefaultElementColor(conf, {
            element: 'profile',
            side: 'inner',
            profile,
        });

        const innerConstrColor = this.newColorsService.getMatchingConstrColor(
            conf,
            innerColorInMatching ?? innerColor,
            sideColors,
            {
                element: 'profile',
                side: 'inner',
                profile,
            }
        );
        profile.selectedColor.inner = innerConstrColor
            ? {
                    ...innerConstrColor,
                    isDefault: true,
                }
            : null;

        const matchingColorsCore = this.newColorsService.getMatchingColorsFor(conf, {
            element: 'profile',
            side: 'core',
            profile,
        }, true);

        const coreColorInMatching = matchingColorsCore.find(color => color.id === sideColors?.core?.id);

        const coreColor = this.newColorsService.getDefaultElementColor(conf, {
            element: 'profile',
            side: 'core',
            profile,
        });

        if(coreColorInMatching) {
            profile.selectedColor.core = {...coreColorInMatching, isDefault: true};
        } else if(coreColor) {
            profile.selectedColor.core = {...coreColor, isDefault: true};

        } else {
            profile.selectedColor.core = null;
        }

        return profile.selectedColor;
    }

    setProfileDefaultColors(
        conf: WindowActiveConfiguration,
        profile: Profile,
        selectedProfile: Profile | null,
        selectedColor: IccProfileSideColors,
        selectedWood: Wood | null
    ) {
        delete profile.selectedColor;
        delete profile.selectedWood;

        if (selectedProfile && selectedProfile.id === profile.id) {
            profile.selectedColor = selectedColor;
            profile.selectedWood = selectedWood;
        } else {
            profile.selectedColor = this.setDefaultProfileColorSet(conf, profile, selectedColor);
        }
    }
}
