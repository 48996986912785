import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MOSQUITOSCREEN_FEATURE_KEY, MosquitoScreenState } from './mosquito-screen.reducer';

// Lookup the 'MosquitoScreen' feature state managed by NgRx
const getMosquitoScreenState = createFeatureSelector<MosquitoScreenState>(
    MOSQUITOSCREEN_FEATURE_KEY
);

const getMosquitoScreenConfiguration = createSelector(
    getMosquitoScreenState,
    (state: MosquitoScreenState) => {
        return state.configuration;
    }
);

export const mosquitoScreenQuery = {
    getMosquitoScreenConfiguration,
};
