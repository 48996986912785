import { SharedAction, SharedActionTypes } from '@icc/configurator/shared';
import { PleatedBlindAction, PleatedBlindActionTypes } from './pleated-blind.actions';
import { IPleatedBlindActiveConfiguration } from '@icc/common/configurations/PleatedBlindActiveConfiguration';
import { Shape } from '@icc/window';

export const PLEATEDBLIND_FEATURE_KEY = 'pleated_blind';

export interface PleatedBlindState {
    configuration: IPleatedBlindActiveConfiguration | null;
}

export interface PleatedBlindPartialState {
    readonly [PLEATEDBLIND_FEATURE_KEY]: PleatedBlindState;
}

export const initialState: PleatedBlindState = {
    configuration: null,
};

export function pleatedBlindReducer(
    state: PleatedBlindState = initialState,
    action: PleatedBlindAction | SharedAction
): PleatedBlindState {
    switch (action.type) {
        case SharedActionTypes.ConfigurationLoaded: {
            state = {
                ...state,
                configuration: {
                    ...(action.payload.configuration as IPleatedBlindActiveConfiguration),
                },
            };
            break;
        }
        case PleatedBlindActionTypes.UpdatePleatedBlindConfiguration: {
            state = {
                ...state,
                configuration: {
                    ...action.payload.configuration,
                },
            };
            break;
        }
        default: {
            state = {
                ...state,
                configuration: pleatedBlindConfigurationReducer(state.configuration, action)
            }
        }
    }
    return state;
}


export function pleatedBlindConfigurationReducer(
    state: PleatedBlindState['configuration'],
    action: PleatedBlindAction | SharedAction
): PleatedBlindState['configuration'] {
    switch (action.type) {
        case PleatedBlindActionTypes.SetPleatedBlindSystem: {
            state = {
                ...state,
                System: action.payload
            }
            break;
        }
        case PleatedBlindActionTypes.SetPleatedBlindVariant: {
            state = {
                ...state,
                variant: action.payload
            }
            break;
        }
        case PleatedBlindActionTypes.SetPleatedBlindMainFabric: {
            state = {
                ...state,
                mainFabric: action.payload
            }
            break;
        }
        case PleatedBlindActionTypes.SetPleatedBlindAdditionalFabric: {
            state = {
                ...state,
                additionalFabric: action.payload
            }
            break;
        }
        case PleatedBlindActionTypes.SetPleatedBlindProfilesColor: {
            state = {
                ...state,
                profilesColor: action.payload
            }
            break;
        }
        case PleatedBlindActionTypes.SetPleatedBlindShape: {
            state = {
                ...state,
                Shape: action.payload as unknown as Shape
            }
            break;
        }
        case PleatedBlindActionTypes.SetPleatedBlindDrive: {
            state = {
                ...state,
                drive: action.payload
            }
            break;
        }
        case PleatedBlindActionTypes.SetPleatedBlindDriveSide: {
            state = {
                ...state,
                driveSide: action.payload
            }
            break;
        }
        case PleatedBlindActionTypes.SetPleatedBlindGuidanceType: {
            state = {
                ...state,
                guidanceType: action.payload
            }
            break;
        }
        case PleatedBlindActionTypes.SetPleatedBlindClosureType: {
            state = {
                ...state,
                closureType: action.payload
            }
        }

    }
    return state;
}