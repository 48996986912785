import { Injectable } from '@angular/core';
import { WindowActiveConfiguration, core } from '@icc/common';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { IccWood } from '@icc/common/data-types';
import { EventBusService } from '@icc/common/event-bus.service';
import { ModalService, isObject, isUndefined } from '@icc/configurator/shared';
import { WoodsPageComponent } from '../woods-page/woods-page.component';
import { NewColorsService } from '../colors/new-colors.service';
import { Wood } from '@icc/common/configurations/parts/common';

@Injectable({
    providedIn: 'root',
})
export class WoodService {
    private configurators = ['window', 'hs', 'door', 'folding_door', 'sliding_door'];

    woods: IccWood[] = [];
    loadedData = false;

    constructor(
        private modalService: ModalService,
        private eventBusService: EventBusService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private configurationsService: ConfigurationsService<'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private newColorsService: NewColorsService,
    ) {
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
    }

    init() {
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            return;
        }
        if (isUndefined(this.configurationsService.conf)) {
            return;
        }
        this.loadData();
    }

    loadData() {
        this.woods = this.configuratorsDataService.data.woodTypes ?? [];

        this.loadedData = true;
    }

    getDefaultWood(conf: WindowActiveConfiguration) {
        if (conf.System.type === 'wood') {
            if (this.woods?.length > 0) {
                return core.copy(this.woods[0]);
            }
        }
        return null;
    }

    validateAndFixWood(
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default
    ) {
        if (conf.System.type === 'wood') {
            if (
                !conf.Wood?.id || !this.woods.some(wood => +wood.id === Number(conf.Wood?.id))
            ) {
                const defaultWood = this.getDefaultWood(conf);
                if (defaultWood) {
                    this.setWood(defaultWood, conf, confDefault);
                }
            }
        } else {
            this.setWood(null, conf, confDefault);
        }
    }

    setWood(
        wood: IccWood | null,
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default,
    ) {
        const oldWoodId = conf.Wood?.id;
        conf.Wood = core.copy(wood);
        confDefault.Wood = core.copy(wood);

        if (conf.Wood?.id !== oldWoodId) {
            this.newColorsService.validate();
        }
    }

    async changeWood(
        conf = this.configurationsService.conf.Current,
        confDefault = this.configurationsService.conf.Default,
    ) {
        if (conf.System.type === 'wood') {
            const wood = await this.openWoodPicker(conf.Wood);
            if (wood) {
                this.setWood(wood, conf, confDefault);
            }
        } else {
            this.setWood(null, conf, confDefault);
        }
    }

    openWoodPicker(
        selectedWood?: IccWood | Wood | null,
    ): PromiseLike<IccWood | null> {
        const modalInstance = this.modalService.open<IccWood | null>({
            pageComponent: WoodsPageComponent,
            resolve: {
                woods: () => this.woods,
                selWood: () => selectedWood,
            },
        });

        return modalInstance.result;
    }

}
