import { Component, Inject, OnInit } from '@angular/core';
import { ICC_PAGE_DATA, PageComponent } from '@icc/helpers';
import { SharedFacade, _ } from '@icc/configurator/shared';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { iccListItem } from '@icc/configurator/ui';

@Component({
  selector: 'icc-filling-colors-page',
  templateUrl: './filling-colors-page.component.html',
  styleUrls: ['./filling-colors-page.component.scss']
})
export class FillingColorsPageComponent extends PageComponent implements OnInit {
  colors: iccListItem[] = [];
  public title = _('WINDOW|Wybierz kolor dodatku');
  public options = [];
  sash: any | null = null;
  colorGroups: any;
  selectedColor: any;

  constructor(
    @Inject(ICC_PAGE_DATA) private pageData: {sash: ActiveSash, novalidate: any, colors: any[], colorGroups: any[], selectedColorId: any},
    private sharedFacade: SharedFacade,
  ) {
    super();
  }
  ngOnInit() {
    const colors = this.pageData.colors;
    const colorGroups = this.pageData.colorGroups;
    this.selectedColor = Number(this.pageData.selectedColorId);
    this.colors = colors.map((color: { id: any; name: any; code: any; color: string; color_img: string; groups: any[]; }) => ({
        id: Number(color.id),
        title: color.name,
        description: color.code,
        color: '#' + color.color,
        imageUrl: '/files/color/' + color.color_img,
        tabs: (color.groups && color.groups.map(Number)) || [],
    }));

    this.colorGroups = colorGroups.map((group: { id: any; name: any; }) => ({
        id: Number(group.id),
        name: group.name,
    }));
  }

  selectFillingColor(item: iccListItem) {
    const { colors } = this.pageData;
    const color = colors.find(c => Number(c.id) === item.id);
    this.sharedFacade.closePage(color);
  }

}
