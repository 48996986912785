export default function icFooter() {
    'ngInject';

    var directive = {
        restrict   : 'EA',
        templateUrl: '/application/footer/footer.project.html'
    };

    return directive;
}
