import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemsComparisonService } from './systems-comparison.service';
import { ConfiguratorPageContainerComponent } from './configurator-page-container/configurator-page-container.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { APP_CONFIG, TranslateService } from '@icc/common';
import { ConfiguratorTranslateService, MyMissingTranslationHandler } from 'apps/configurator/src/app/translate.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'apps/configurator/src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { IccTranslateParser, SharedFacade, TranslateMessageFormatCompiler } from '@icc/configurator/shared';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient, SharedFacade, DomSanitizer, APP_CONFIG],
            },
            parser: {
                provide: TranslateParser,
                useClass: IccTranslateParser,
                deps: [APP_CONFIG],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MyMissingTranslationHandler
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler
            }
        }),
    ],
    declarations: [
        ConfiguratorPageContainerComponent,
    ],
    exports: [
        ConfiguratorPageContainerComponent,
    ],
    entryComponents: [
        ConfiguratorPageContainerComponent,
    ],
    providers: [
        SystemsComparisonService,
        ConfiguratorTranslateService,
        { provide: TranslateService, useExisting: ConfiguratorTranslateService },
    ],
})
export class SystemsComparisonModule {}
