import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { SharedFacade, isObject } from '@icc/configurator/shared';
import { map, mergeMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext, Inject } from '@angular/core';
import { AppConfigFactory, APP_CONFIG } from '@icc/common/config';

export class TranslateHttpLoader implements TranslateLoader {
    constructor(
        private http: HttpClient,
        public sharedFacade: SharedFacade,
        private domSanitizer: DomSanitizer,
        @Inject(APP_CONFIG) protected config: AppConfigFactory
    ) {}

    /**
     * Gets the translations from the server
     */
    public getTranslation(lang: string): Observable<Object> {
        return this.sharedFacade.languageAndProject$.pipe(
            mergeMap(options => {
                return this.http.get<Record<string, Record<string, string> | string>>(
                    `${(this.config().EnvConfig?.remoteHost || window.location.origin)}/translations/${lang}.${options.projectName}.json`
                );
            })
        );
    }

    public sanitize(values: Record<string, Record<string, string> | string>) {
        const newValues: Record<string, Record<string, string> | string> = {};
        for (const key in values) {
            const value = values[key];
            const sanitizedKey = key.replace(/\\/g, '').replace(/\\$/, '');
            if (isObject(value)) {
                const newValues2: Record<string, string> = {};
                for (const key2 in value) {
                    newValues2[key2.replace(/\\/g, '').replace(/\\$/, '')] = value[key2]
                        .replace(/\\/g, '')
                        .replace(/\\$/, '');
                }
                newValues[sanitizedKey] = newValues2;
            } else {
                newValues[sanitizedKey] = value.replace(/\\/g, '').replace(/\\$/, '');
            }
        }
        return newValues;
    }
}
