import { PriceFunc, PriceElemsData, PriceSegment, getConstrElems } from './Prices';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { Injectable, Inject } from '@angular/core';
import { ShapeService } from '@icc/common/shape.service';
import { Common } from '@icc/common/Common';

/**
 * Rodzaj kształtu
 */
type ShapeType = 'triangle' | 'poligon' | 'rect' | 'circle' | 'arc';

/**
 * Dopłata do
 */
type ShapeSupplementTarget = 'conf' | 'sash' | 'angle';


/**
 * Rodzaj dopłaty
 *
 * @enum {number}
 */
enum FactorType {
    /**
     * Kwotowa
     */
    VALUE = 0,
    /**
     * Procentowa
     */
    PERCENT = 1,
}

/**
 * Dopłata za kształt konstrukcji
 *
 * @interface ShapeSupplement
 */
interface ShapeSupplement {
    /**
     * Id dopłaty
     *
     * @type {number}
     * @memberOf ShapeSupplement
     */
    id?: number;
    /**
     * Rodzaj kształtu
     *
     * @type {ShapeType}
     * @memberOf ShapeSupplement
     */
    shape: ShapeType;
    /**
     * Rodzaj dopłaty
     *
     * @type {FactorType}
     * @memberOf ShapeSupplement
     */
    factor_type: FactorType;
    /**
     * Dopłata do
     *
     * @type {(ShapeSupplementTarget | null)}
     * @memberOf ShapeSupplement
     */
    target: ShapeSupplementTarget | null;

    /**
     * Dopłata do stałego szklenia w ramie
     */
    sash_f: boolean;
    /**
     * Dopłata do stałego szklenia w skrzydle
     */
    sash_ff: boolean;
    /**
     * Dopłata do skrzydła funkcyjnego
     */
    sash_func: boolean;
    /**
     * Wysokość dopłaty
     *
     * @type {number}
     * @memberOf ShapeSupplement
     */
    factor: number;
    /**
     * Wysokość dopłaty procentowej do wypełnienia
     *
     * @type {number}
     * @memberOf ShapeSupplement
     */
    factor_glazing: number;
    /**
     * Promień od
     *
     * @type {(number | null)}
     * @memberOf ShapeSupplement
     */
    radius_from: number | null;
    /**
     * Promień do
     *
     * @type {(number | null)}
     * @memberOf ShapeSupplement
     */
    radius_to: number | null;
    /**
     * Kąt od
     *
     * @type {(number | null)}
     * @memberOf ShapeSupplement
     */
    angle_from: number | null;
    /**
     * Kąt do
     *
     * @type {(number | null)}
     * @memberOf ShapeSupplement
     */
    angle_to: number | null;
    /**
     * Czas utworzenia
     *
     * @type {(string | null)}
     * @memberOf ShapeSupplement
     */
    created?: string | null;
    /**
     * Czas modyfikacji
     *
     * @type {(string | null)}
     * @memberOf ShapeSupplement
     */
    modified?: string | null;
    systems: number[];
    markets: number[];
    arc_type: 'radialArc' | 'arcFromTemplate' | null;
}

/**
 * Usługa dopłat za kształt konstrukcji
 *
 * @export
 * @class PriceShapeService
 */
@Injectable()
export class PriceShapeService {
    constructor(
        private shapeService: ShapeService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {}

    /**
     * Dopłata za kształt
     * @param  {Object} shape           Kształt
     * @param  {Object} supplements     Dopłaty
     * @param  {Object} [sash]          Skrzydło
     * @param  {Object} [sashShape]     Kształt skrzydła
     * @return {Object}                 Czynnik
     */
    getSuppShape(
        shape,
        system,
        supplements: ShapeSupplement[],
        sash?,
        sashShape?
    ): ShapeSupplement[] {
        if (sash && sashShape && Common.isDefined(sash.shape.valid) && !sash.shape.valid) {
            return [
                {
                    shape: sashShape,
                    factor_type: null,
                    target: 'sash',
                    sash_f: false,
                    sash_ff: false,
                    sash_func: false,
                    factor: NaN,
                    factor_glazing: 0,
                    radius_from: null,
                    radius_to: null,
                    angle_from: null,
                    angle_to: null,
                    systems: [],
                    markets: [],
                    arc_type: null,
                },
            ];
        }
        if (Common.isArray(supplements) && Common.isObject(shape)) {
            let shapeType = shape.shape;
            if (sashShape) {
                shapeType = sashShape;
            }
            const shapeSupps = supplements.filter(el => {
                if (el.radius_from === null || Number.isNaN(el.radius_from)) {
                    el.radius_from = 0;
                }
                if (el.radius_to === null || Number.isNaN(el.radius_to)) {
                    el.radius_to = Infinity;
                }
                if (el.angle_from === null || Number.isNaN(el.angle_from)) {
                    el.angle_from = 0;
                }
                if (el.angle_to === null || Number.isNaN(el.angle_to)) {
                    el.angle_to = Infinity;
                }

                let test =
                    Common.isArray(el.systems)
                    && el.systems.indexOf(Number(system.id)) > -1
                    && el.shape === shapeType
                    && ((el.shape !== 'arc' && el.shape !== 'circle')
                        || (el.shape === 'arc'
                            && shape.radius <= el.radius_to
                            && shape.radius >= el.radius_from
                            && (shape.shape !== 'arc'
                                || !this.config().IccConfig.Configurators.arcType
                                || el.arc_type === shape.arcType))
                        || (el.shape === 'circle'
                            && shape.d / 2 <= el.radius_to
                            && shape.d / 2 >= el.radius_from));
                if (sash && sashShape && sash.type != null) {
                    test =
                        test
                        && el.target === 'sash'
                        && ((el.sash_f
                            && (sash.type.type === 'F'
                                || sash.type.type === 'OFF'))
                            || (el.sash_ff
                                && sash.type.type === 'FF')
                            || (el.sash_func
                                && sash.type.type !== 'F'
                                && sash.type.type !== 'FF'
                                && sash.type.type !== 'OFF'));
                } else {
                    test = test && el.target !== 'sash';
                }
                return test;
            });
            return shapeSupps;
        }
        return [
            {
                shape: null,
                factor_type: null,
                target: 'conf',
                sash_f: false,
                sash_ff: false,
                sash_func: false,
                factor: 0,
                factor_glazing: 0,
                radius_from: null,
                radius_to: null,
                angle_from: null,
                angle_to: null,
                systems: [],
                markets: [],
                arc_type: null,
            },
        ];
    }

    getSashShapePercentSupps(sashes, shape, system, supplements: ShapeSupplement[]) {
        const sashShapePercentSupps: {
            shape: ShapeSupplement['shape'];
            sashF: boolean;
            sashFF: boolean;
            sashFunc: boolean;
            sashIds: number[];
        }[] = [];

        sashes.forEach(sash => {
            const shapeSupps = this.getSuppShape(
                shape,
                system,
                supplements,
                sash,
                sash.shape.shape
            ).filter(el => el.factor_type === FactorType.PERCENT);
            shapeSupps.forEach(supp => {
                const percentSupp = sashShapePercentSupps.find(
                    s => s.shape === supp.shape && s.sashF === supp.sash_f && s.sashFF === supp.sash_ff && s.sashFunc === supp.sash_func
                );
                if (percentSupp) {
                    percentSupp.sashIds.push(sash.id);
                } else {
                    sashShapePercentSupps.push({
                        shape: supp.shape,
                        sashF: supp.sash_f,
                        sashFF: supp.sash_ff,
                        sashFunc: supp.sash_func,
                        sashIds: [sash.id],
                    });
                }
            });
            if (shapeSupps.length === 0) {
                const percentSupp = sashShapePercentSupps.find(s => s.shape === null && s.sashF === false && s.sashFF === false && s.sashFunc === false);
                if (percentSupp) {
                    percentSupp.sashIds.push(sash.id);
                } else {
                    sashShapePercentSupps.push({
                        shape: null,
                        sashF: false,
                        sashFF: false,
                        sashFunc: false,
                        sashIds: [sash.id],
                    });
                }
            }
        });

        return sashShapePercentSupps;
    }

    /**
     * Dolicza dopłatę za kształt nieregularny.
     * @param  {number} price         Cena po dopłatach za kolor i system
     * @param  {object} PriceElems    Elmenty wyceny
     * @param  {object} NoPriceCauses Powody braku ceny
     * @param  {object} constrElems   Elementy konstrukcji
     * @param  {object} shape         Parametry kształtu
     * @param  {object} supplements   Dopłaty
     * @return {number}               Nowa cena
     */
    @PriceFunc({
        shortName: 'shape',
        data: {
            shape: 'conf.Shape',
            supplements: 'data.windowShapesFactors',
            sashes: 'conf.Sashes',
            system: 'conf.System',
        },
    })
    suppShape(
        { PriceStack, PriceElems, NoPriceCauses }: PriceElemsData,
        { shape, supplements, sashes, system }
    ): PriceSegment[] {
        let supp = NaN;
        const constrElems = getConstrElems(this.config().IccConfig, system, 'color');
        const priceSashes = PriceStack.filter(
            s => constrElems.indexOf('sashes') > -1 && s.type === 'sashes'
        );
        const priceSegments: PriceSegment[] = [];
        if (
            shape.shape !== 'rect'
            && system.type === 'wood'
            && !this.config().IccConfig.Configurators.price.woodShape
        ) {
            NoPriceCauses.push('shape');
            return [
                {
                    type: 'shape',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {
                        id: shape.id,
                        shape: shape.shape,
                    },
                },
            ] as PriceSegment[];
        }

        const shapeSupps = this.getSuppShape(shape, system, supplements);

        if (
            this.config().IccConfig.Configurators.arcType
            && shape.shape === 'arc'
            && shape.arcType === 'arcFromTemplate'
            && !shapeSupps.length
        ) {
            priceSegments.push({
                type: 'shape',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {
                    id: shape.id,
                    shape: shape.shape,
                },
            });
        }
        const angles = this.shapeService.getAngles(shape);
        shapeSupps.forEach(shapeSupp => {
            if (shapeSupp.target === 'conf') {
                switch (~~shapeSupp.factor_type) {
                    case FactorType.VALUE:
                        PriceElems.shape.push({
                            id: shape.id,
                            shape: shape.shape,
                            price: shapeSupp.factor,
                            priceType: shapeSupp.factor_type,
                        });
                        priceSegments.push({
                            type: 'shape',
                            baseValue: shapeSupp.factor,
                            value: shapeSupp.factor,
                            valueType: 'value',
                            data: {
                                id: shape.id,
                                shape: shape.shape,
                            },
                        });
                        break;
                    case FactorType.PERCENT:
                        supp = 1 + shapeSupp.factor / 100;
                        priceSegments.push({
                            type: 'shape',
                            baseValue: supp,
                            value: supp,
                            valueType: 'percent',
                            data: {
                                id: shape.id,
                                shape: shape.shape,
                            },
                        });
                        break;
                }
                return [];
            } else {
                const notStraightAnglesCount = angles.filter(angle => angle !== 90).length;
                let percentFactor = 0;
                angles.forEach(angle => {
                    if (shapeSupp.angle_from <= Math.round(angle) && shapeSupp.angle_to >= Math.round(angle) && angle !== 90) {
                        if (~~shapeSupp.factor_type === FactorType.PERCENT) {
                            percentFactor += shapeSupp.factor / 100;
                        } else {
                            PriceElems.shape.push({
                                id: shape.id,
                                shape: shape.shape,
                                price: shapeSupp.factor,
                                priceType: shapeSupp.factor_type,
                            });
                            priceSegments.push({
                                type: 'shape',
                                baseValue: shapeSupp.factor,
                                value: shapeSupp.factor,
                                valueType: 'value',
                                data: {
                                    id: shape.id,
                                    shape: shape.shape,
                                    angle,
                                },
                            });
                        }
                    }
                });
                if (percentFactor > 0) {
                    supp = 1 + percentFactor / 100;
                    priceSegments.push({
                        type: 'shape',
                        baseValue: supp,
                        value: supp,
                        valueType: 'percent',
                        data: {
                            id: shape.id,
                            shape: shape.shape,
                            angles: notStraightAnglesCount,
                        },
                    });
                }
            }
        });
        priceSashes.forEach(sashSegment => {
            if (sashSegment.data && sashSegment.data.sashIds) {
                const sash = sashes.find(s => sashSegment.data.sashIds.length > 0 && s.id === sashSegment.data.sashIds[0]);
                if (sash) {
                    priceSegments.push(
                        ...this.suppShapeSashPercent(
                            { PriceStack, PriceElems, NoPriceCauses },
                            { shape, system, supplements, sashSegment, sash }
                        )
                    );
                }
            }
        });
        sashes.forEach(sash => {
            priceSegments.push(
                ...this.suppShapeSashValue(
                    { PriceStack, PriceElems, NoPriceCauses },
                    { shape, system, supplements, sash }
                )
            );
        });

        return priceSegments;
    }

    suppShapeSashValue(
        { PriceElems, NoPriceCauses }: PriceElemsData,
        { shape, system, supplements, sash }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        const sashShape = sash.shape.shape;
        if (['PSK'].indexOf(sash.type.type) > -1 && shape.shape !== 'rect') {
            NoPriceCauses.push('shape');
            return <PriceSegment[]>[
                {
                    type: 'shape',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {
                        id: shape.id,
                        shape: shape.shape,
                    },
                },
            ];
        }

        const shapeSupps = this.getSuppShape(shape, system, supplements, sash, sashShape);
        shapeSupps.forEach(shapeSupp => {
            if (Number(shapeSupp.factor_type) === FactorType.VALUE) {
                PriceElems.shape.push({
                    id: shape.id,
                    sashId: sash.id,
                    shape: sashShape,
                    sashShape,
                    price: !isNaN(shapeSupp.factor) ? shapeSupp.factor : null,
                    priceType: shapeSupp.factor_type,
                });
                priceSegments.push({
                    type: 'shape',
                    baseValue: !isNaN(shapeSupp.factor) ? shapeSupp.factor : null,
                    value: !isNaN(shapeSupp.factor) ? shapeSupp.factor : null,
                    valueType: 'value',
                    data: {
                        id: shape.id,
                        shape: sashShape,
                        sashId: sash.id,
                        sashShape,
                    },
                });
            }
        });

        return priceSegments;
    }

    suppShapeSashPercent(
        { PriceElems, NoPriceCauses }: PriceElemsData,
        { shape, system, supplements, sashSegment, sash }
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        const sashShape = sash.shape.shape;
        const shapeSupps = this.getSuppShape(shape, system, supplements, sash, sashShape);
        shapeSupps.forEach(shapeSupp => {
            if (Number(shapeSupp.factor_type) === FactorType.PERCENT) {
                const supp = 1 + shapeSupp.factor / 100;
                priceSegments.push({
                    type: 'shape',
                    baseValue: supp,
                    value: supp,
                    valueType: 'percent',
                    data: {
                        id: shape.id,
                        shape: sashShape,
                        sashId: sash.id,
                        sashShape,
                    },
                    to: sashSegment.id,
                });
            }
        });

        return priceSegments;
    }
}
