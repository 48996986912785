import { Common } from '@icc/common/Common';
import { PriceSegment } from './PriceSegment';
import { PriceElemsData } from './price-elems-data';

/**
 * Opis funkcji liczącej cenę
 *
* @interface PriceFunctionDesc
 */
interface PriceFunctionDesc {
    /**
     * Informacje o danych, do przekazania w drugim parametrze do funkcji.
     *
     * @type {Object}
     * @memberOf PriceFunctionDesc
     */
    data: any;
    /**
     * Krótna nazwa (alias)
     *
     * @type {string}
     * @memberOf PriceFunctionDesc
     */
    shortName: string;
}

/**
 * Funkcja licząca cenę
 *
 * @export
 * @interface PriceFunction
 */
export interface PriceFunction {
    ({  }: PriceElemsData, data: Object): PriceSegment[];
    requiredData: Object;
    class: any;
}

/**
 * Funkcje pomocnicze do liczenia ceny
 *
 * @export
 * @class PriceFunctions
 */
export class PriceFunctions {
    /**
     * Mapa zawierająca pary alias - funkcja licząca cenę.
     *
     * @static
     *
     * @memberOf PriceFunctions
     */
    static map = new Map<string, PriceFunction>();

    /**
     * Aliasy funkcji dla profuktów z pomyłek
     *
     * @static
     *
     * @memberOf PriceFunctions
     */
    static mistakeProductFn = {
        window: [
            'accessories',
            'sashesHardware',
            'sideAccessories',
            'rollerShutter',
            'pleatedBlind',
            'mosquitoSash',
        ],
        door: ['accessories', 'sashesHardware', 'sideAccessories', 'rollerShutter', 'pleatedBlind', 'mosquitoSash'],
        hs: 'window',
        roller_shutter: [],
        external_blind: [],
        pleated_blind: [],
        accessory: [],
        garage_door: [],
        complementary_goods: [],
        mosquito: [],
        awning: ['awningAccessories'],
    };

    /**
     * Aliasy funkcji
     *
     * @static
     *
     * @memberOf PriceFunctions
     */
    static priceFn = {
        window: [
            'sashes',
            'dividers',
            'couplings',
            'system',
            'color',
            'fittingPercent',
            'fitting',
            'alushellPercent',
            'sealColor',
            'siliconeColor',
            'sashType',
            'oversize',
            'alushell',
            'constructionLimitations',
            'shape',
            'steel',
            'hinge',
            'lowThreshold',
            'internalDividers',
            'crossDividers',
            'frame',
            'sashFrame',
            'filling',
            'warmEdge',
            'handles',
            'muntins',
            'muntinsColors',
            'wood',
            'accessories',
            'sashesHardware',
            'sideAccessories',
            'rollerShutter',
            'pleatedBlind',
            'mosquitoSash',
            'wideSash',
            'dependencies',
            'sashDependencies',
            'sideProfiles',
            'sideProfileColors',
            'alignments',
            'handlePosition',
        ],
        door: [
            'sashes',
            'dividers',
            'couplings',
            'system',
            'alushellPercent',
            'fittingPercent',
            'fitting',
            'color',
            'sealColor',
            'siliconeColor',
            'hinge',
            'lock',
            'wood',
            'sashType',
            'oversize',
            'alushell',
            'constructionLimitations',
            'shape',
            'steel',
            'casing',
            'lowThreshold',
            'internalDividers',
            'crossDividers',
            'frame',
            'sashFrame',
            'filling',
            'warmEdge',
            'handles',
            'muntins',
            'muntinsColors',
            'panelType',
            'panelSize',
            'doorSize',
            'doorModel',
            'doorPortal',
            'outerDoor',
            'accessories',
            'sashesHardware',
            'sideAccessories',
            'doorHardware',
            'rollerShutter',
            'pleatedBlind',
            'mosquitoSash',
            'wideSash',
            'dependencies',
            'sashDependencies',
            'sideProfiles',
            'sideProfileColors',
            'alignments',
        ],
        folding_door: 'window',
        sliding_door: 'window',
        hs: 'window',
        roller_shutter: ['rollerShutter', 'accessories'],
        external_blind: ['rollerShutter', 'accessories'],
        pleated_blind: ['pleatedBlind'],
        accessory: ['accessories'],
        garage_door: ['garageSize', 'garageControl', 'accessories'],
        complementary_goods: ['complementaryGoods', 'cassonetto'],
        mosquito: ['mosquitoSize', 'mosquitoColor', 'mosquitoMontage', 'mosquitoProfile', 'accessories'],
        awning: [
            'awningSize',
            'awningLine',
            'awningFabric',
            'awningValance',
            'awningDrive',
            'awningMounting',
            'awningAccessories',
        ],
        coupled_window: ['coupledWindow', 'coupled-couplings', 'coupled-sideProfiles', 'sills'],
    };

    /**
     * Dane potrzebne do wywołania metody.
     *
     * @static
     * @param {{}} requiredData Dane wymagane do wywołania metody.
     *
     * @memberOf PriceFunctions
     */
    static Price(config: PriceFunctionDesc) {
        return function(target: any, propertyKey: string) {
            const x = target[propertyKey];
            x.requiredData = config.data;
            x.class = target.constructor;
            PriceFunctions.map.set(config.shortName, x);
        };
    }

    static getConstrElems(IccConfig, system, name) {
        if (system.type) {
            if (
                Common.isObject(IccConfig.Configurators.price[name])
                && Common.isArray(IccConfig.Configurators.price[name][system.type])
            ) {
                return IccConfig.Configurators.price[name][system.type] || [];
            }
        }
        if (Common.isArray(IccConfig.Configurators.price[name])) {
            if (Common.isObject(IccConfig.Configurators.price[name][0])) {
                return IccConfig.Configurators.price[name].map(el => el && el.type);
            }
            return IccConfig.Configurators.price[name];
        }
        return [];
    }

    static price(p: any, multiplier = 1) {
        return parseFloat(p) * multiplier;
    }
}
