import { NgModule } from '@angular/core';

import { DiscountsAndMultipliersService, PriceModule, PriceDetailsModule } from '@icc/price/b2b';
import { CustomPricesService } from '@icc/common/custom-price/custom-prices.service';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';
import { BrowserCustomPricesService } from './custom-prices.service';
import { BrowserDiscountsAndMultipliersService } from './discounts-and-multipliers.service';
import { WarrantyService } from './warranty.service';

@NgModule({
    imports: [PriceModule, PriceDetailsModule],
    exports: [PriceModule, PriceDetailsModule],
    declarations: [],
    providers: [
        { provide: CustomPricesService, useClass: BrowserCustomPricesService },
        ProfilesPriceService,
        BrowserDiscountsAndMultipliersService,
        {
            provide: DiscountsAndMultipliersService,
            useExisting: BrowserDiscountsAndMultipliersService,
        },
        WarrantyService,
    ],
})
export class ConfiguratorPriceModule {}
