import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanvasComponent } from './canvas/canvas.component';
import { ConfiguratorUiModule } from '@icc/configurator/ui';
import { SharedModule } from '@icc/configurator/shared';
import { ConfiguratorPagesModule } from '@icc/configurator/pages';
import { ProfilesModule } from '@icc/configurator/window';
import { SillsModalComponent } from './sills-modal/sills-modal.component';
import { CoupledWindowService } from '@icc/legacy/configurator/coupled/coupled-window.service';
export { SillsModalComponent }; 

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ConfiguratorUiModule,
        ConfiguratorPagesModule,
        ProfilesModule,
    ],
    declarations: [CanvasComponent, SillsModalComponent],
    exports: [CanvasComponent, SillsModalComponent],
    entryComponents: [
        CanvasComponent,
        SillsModalComponent
    ],
    providers: [
        CoupledWindowService
    ],
})
export class ConfiguratorCoupledWindowModule {}
