import { StateService } from '@icc/common/state.service';
import { DatabaseManagerToken, DatabaseManager } from '@icc/helpers/browser';
import { IccDatabase } from '@icc/helpers/browser';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';
import { Inject, Injectable } from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { EventBusService } from '@icc/common/event-bus.service';
import { OnlineStatusService } from '../sync/online-status.service';
import { SynchronizeService } from '../sync/synchronize.service';

@Injectable()
export class OffersService {

    constructor(
        private stateService: StateService,
        @Inject(DatabaseManagerToken) private DatabaseManager: DatabaseManager,
        private EventBusService: EventBusService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private onlineStatusService: OnlineStatusService,
        private synchronizeService: SynchronizeService,
    ) {}
    private emitModifiedHold = false;
    private syncOfferTimer = null;
    private emitRequests = 0;
    /**
     * Parsuje wartości oferty przed zwroceniem
     * @param  {object} offer Obiekt oferty
     * @return {object}       Obiekt sparsowany
     */
    static parseOfferValues(offer) {
        return Common.extend(offer, {
            currency: core.parseJson(offer.currency),
            sequence: core.parseJson(offer.sequence),
            summary: core.parseJson(offer.summary),
            montages: core.parseJson(offer.montages),
            key: core.parseJson(offer.key),
            group_discounts: core.parseJson(offer.group_discounts),
            history: core.parseJson(offer.history),
            positions_groups_data: core.parseJson(offer.positions_groups_data),
            changed_positions: core.parseJson(offer.changed_positions),
            dealer_discount_producer_special: parseFloat(offer.dealer_discount_producer_special),
            client_discount_special: parseFloat(offer.client_discount_special),
            valuation: ~~offer.valuation,
            dealer_price_before_discount_position: parseFloat(
                offer.dealer_price_before_discount_position
            ),
            dealer_price_before_discount: parseFloat(offer.dealer_price_before_discount),
            dealer_price: parseFloat(offer.dealer_price),
            dealer_client_price_before_discount_position: parseFloat(
                offer.dealer_client_price_before_discount_position
            ),
            dealer_client_price_before_discount: parseFloat(
                offer.dealer_client_price_before_discount
            ),
            dealer_client_price: parseFloat(offer.dealer_client_price),
            client_price_before_discount_position: parseFloat(
                offer.client_price_before_discount_position
            ),
            client_price_before_discount: parseFloat(offer.client_price_before_discount),
            client_price: parseFloat(offer.client_price),
            advance: parseFloat(offer.advance),
            dealer_tax_rate: offer.dealer_tax_rate === null ? null : ~~offer.dealer_tax_rate,
            positions_tax_rates : core.parseJson(offer.positions_tax_rates),
        });
    }

    /**
     * Pobranie konkretnej oferty
     * @param  {string} _id          Id oferty
     * @param  {boolean} forceOnline Czy zawsze pobierać online
     */
    get(tmpId, forceFromDB = false) {
        return new Promise<any>((resolve, reject) => {
            if (
                this.stateService.state.offer
                && this.stateService.state.offer.tmp_id === tmpId
                && !forceFromDB
            ) {
                resolve(OffersService.parseOfferValues(this.stateService.state.offer));
            } else {
                this.DatabaseManager.get('Offer')
                    .get(tmpId)
                    .then(res =>{
                        resolve(OffersService.parseOfferValues(res));
                    })
                    .catch(err => {
                        if (this.onlineStatusService.getStatus()) {
                            this.synchronizeService
                                .synchronizeOffers(true, 'down', [tmpId])
                                .then(e => {
                                    this.get(tmpId, forceFromDB)
                                        .then(value => {
                                            resolve(value);
                                        })
                                        .catch(err2 => {
                                            reject(err2);
                                        });
                                });
                        } else {
                            reject(err);
                        }
                    });
            }
        });
    }

    /**
     * Uaktualnianie oferty
     * @param  {string}  tmpId    Id oferty
     * @param  {object}  object Objekt oferty
     * @param  {boolean} online Wymuszenie online
     * @return {object}        Obietnica
     */
    update(tmpId, object) {
        return new Promise((resolve, reject) => {
            (this.DatabaseManager.get('Offer') as IccDatabase)
                .update(object, {
                    internalId: tmpId || object.tmp_id,
                    machine: this.config().machine,
                })
                .then(res => {
                    this.emitModifiedOffer();
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    }

    emitModifiedOffer() {
        this.emitRequests++;
        this.runSyncOfferTimer();
    }

    pauseEmitModifiedOffer() {
        this.emitModifiedHold = true;
    }

    resumeEmitModifiedOffer() {
        this.emitModifiedHold = false;
        this.runSyncOfferTimer(1000);
    }

    runSyncOfferTimer(time = 1500) {
        if (!this.syncOfferTimer) {
            this.syncModifiedOffer(time);
        }
    }

    syncModifiedOffer(time = 1500) {
        this.syncOfferTimer = setTimeout(() => {
            this.syncOfferTimer = null;
            if (this.emitRequests > 0 && !this.emitModifiedHold) {
                this.emitRequests = 0;
                this.EventBusService.post({
                    key: 'modifiedOffer',
                    value: null,
                });
            }
        }, time);
    }
}
