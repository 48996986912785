import { ActiveSash } from '@icc/common/layout/active-sash';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { core } from '@icc/common/helpers';
import { EventBusService } from '@icc/common/event-bus.service';
import { Injectable } from '@angular/core';

@Injectable()
export class SashesLayoutService {
    constructor(private eventBusService: EventBusService) {
        this.eventBusService.subscribe(['changedSashes', 'changedFrames'], data => {
            this.setIndex(data.activeConfiguration as WindowActiveConfiguration);
        });
    }

    /**
     * Tworzy skrzydło w kwaterze.
     *
     * @param {Sash} sash Kwatera
     * @return {Sash} Kwatera
     */
    createInternalSash(sash: ActiveSash, conf: WindowActiveConfiguration) {
        sash.intSashes = [];
        const newSash = new ActiveSash(this.getIdForNewSash(conf), {
            parentId: sash.id,
            rx: 0,
            ry: 0,
            rWidth: sash.rWidth,
            rHeight: sash.rHeight,
            divs: {
                left: -1,
                right: -1,
                top: -1,
                bottom: -1,
            },
        });
        sash.intSashes.push(newSash);
        sash.intEdgeSashes = {
            top: [newSash.id],
            bottom: [newSash.id],
            left: [newSash.id],
            right: [newSash.id],
        };
        return sash;
    }

    /**
     * Odbudowuje referencje
     */
    rebuildRefs(conf: WindowActiveConfiguration) {
        const sashes = conf.Sashes;
        const divS = conf.Mullions;

        sashes.forEach(sash => {
            this.rebuildRefsLayer(sash.intSashes, sash.intMullions);
        });

        this.rebuildRefsLayer(sashes, divS);
    }

    setIndex(conf: WindowActiveConfiguration) {
        const {
            Width: width,
            Height: height,
            Sashes: sashes,
            Mullions: dividers,
            Frames: frames,
            couplings: couplings,
            type,
        } = conf;
        this.setFrameIndexInRect(
            { x: 0, y: 0, width, height },
            { frames, couplings, sashes, dividers, type }
        );
    }

    getIdForNewSash(conf: WindowActiveConfiguration) {
        return (
            conf.Sashes.reduce((prev, sash) => {
                const intSashesId = sash.intSashes.reduce(
                    (intPrev, intSash) => (intSash.id > intPrev ? intSash.id : intPrev),
                    0
                );
                const maxId = intSashesId > sash.id ? intSashesId : sash.id;
                return maxId > prev ? maxId : prev;
            }, -1) + 1
        );
    }

    private rebuildRefsLayer(
        sashes: WindowActiveConfiguration['Sashes'],
        dividers: WindowActiveConfiguration['Mullions']
    ) {
        dividers.forEach(div => {
            ['Top', 'Left', 'Right', 'Bottom'].forEach(side => {
                div['multiAlign' + side] = div['multiAlign' + side]
                    .map(s => (s ? core.fIdO(sashes, s.id) : null))
                    .filter(s => s !== null);
                div['multiAlign' + side + 'Div'] = div['multiAlign' + side + 'Div']
                    .map(d => (d ? core.fIdO(dividers, d.id) : null))
                    .filter(d => d !== null);
            });
        });
    }

    /**
     * Generuje kod konstrukcji
     * @param  {Object} scanRect    scanRect
     * @param  {Object} conf        Konfiguracja
     * @param  {Object} level       Poziom
     * @return {Object}             Kod konstrukcji
     */
    private setIndexInRect(
        scanRect: { x: number; y: number; width: number; height: number },
        {
            sashes,
            dividers,
            type,
        }: {
            sashes: WindowActiveConfiguration['Sashes'];
            dividers: WindowActiveConfiguration['Mullions'];
            type: string;
        },
        index = { value: 0 }
    ) {
        let fullDividerDirection = '';
        if (sashes.length === 0) {
            return;
        }
        if (type === 'hs') {
            sashes.forEach(sash => {
                sash.index = ++index.value;
            });
        } else {
            let scanRegions = [];
            const fullDividers = dividers
                .map((divider, i) => {
                    if (
                        divider.rWidth + divider.rx <= scanRect.x + scanRect.width
                        && divider.rHeight + divider.ry <= scanRect.y + scanRect.height
                    ) {
                        if (
                            divider.rWidth === scanRect.width
                            && divider.rx >= scanRect.x
                            && divider.ry > scanRect.y
                        ) {
                            fullDividerDirection = 'horizontal';
                            return i;
                        }
                        if (
                            divider.rHeight === scanRect.height
                            && divider.rx > scanRect.x
                            && divider.ry >= scanRect.y
                        ) {
                            fullDividerDirection = 'vertical';
                            return i;
                        }
                    }
                    return null;
                })
                .filter(divider => divider !== null);
            if (fullDividerDirection === 'horizontal') {
                const steps = [scanRect.y];
                steps.push(...fullDividers.map(fullDivider => dividers[fullDivider].ry));
                steps.push(scanRect.height + scanRect.y);
                steps.sort((a, b) => a - b);

                scanRegions = steps
                    .filter((step, i) => i + 1 < steps.length)
                    .map((step, i) => ({
                        x: scanRect.x,
                        y: step,
                        width: scanRect.width,
                        height: steps[i + 1] - step,
                    }));
            } else {
                // vertical
                const steps = [scanRect.x];
                steps.push(...fullDividers.map(fullDivider => dividers[fullDivider].rx));
                steps.push(scanRect.width + scanRect.x);
                steps.sort((a, b) => a - b);

                scanRegions = steps
                    .filter((step, i) => i + 1 < steps.length)
                    .map((step, i) => ({
                        x: step,
                        y: scanRect.y,
                        height: scanRect.height,
                        width: steps[i + 1] - step,
                    }));
            }

            scanRegions.forEach(region => {
                const sashesinRegion = sashes.filter(
                    sash =>
                        sash.rx >= region.x
                        && sash.ry >= region.y
                        && sash.rx + sash.rWidth <= region.x + region.width
                        && sash.ry + sash.rHeight <= region.y + region.height
                );
                if (sashesinRegion.length === 1) {
                    sashesinRegion[0].index = ++index.value;
                } else if (sashesinRegion.length > 1) {
                    this.setIndexInRect(region, { sashes, dividers, type }, index);
                }
            });
        }
    }

    /**
     * Generuje kod konstrukcji
     * @param  {Object} scanRect    scanRect
     * @param  {Object} conf        Konfiguracja
     * @param  {Object} level       Poziom
     * @return {Object}             Kod konstrukcji
     */
    private setFrameIndexInRect(
        scanRect: { x: number; y: number; width: number; height: number },
        {
            frames,
            couplings,
            sashes,
            dividers,
            type,
        }: {
            frames: WindowActiveConfiguration['Frames'];
            couplings: WindowActiveConfiguration['couplings'];
            sashes: WindowActiveConfiguration['Sashes'];
            dividers: WindowActiveConfiguration['Mullions'];
            type: string;
        },
        index = { value: 0 },
        sashIndex = { value: 0 }
    ) {
        let fullDividerDirection = '';
        if (frames.length === 0) {
            return;
        }
        let scanRegions = [];
        const splitPoints: {
            position: number;
            start: boolean;
        }[] = [];
        let steps: number[][];
        for (const coupling of couplings) {
            const couplingPositionY = coupling.framesId
                .map(fId => frames.find(f => f.id === fId.id))
                .reduce(
                    (prev, cur) =>
                        coupling.direction === 'horizontal'
                            ? cur.y + cur.height > prev
                                ? cur.y + cur.height
                                : prev
                            : cur.y < prev
                            ? cur.y
                            : prev,
                    coupling.direction === 'horizontal' ? 0 : Number.MAX_VALUE
                );
            const couplingPositionX = coupling.framesId
                .map(fId => frames.find(f => f.id === fId.id))
                .reduce(
                    (prev, cur) =>
                        coupling.direction === 'vertical'
                            ? cur.x + cur.width > prev
                                ? cur.x + cur.width
                                : prev
                            : cur.x < prev
                            ? cur.x
                            : prev,
                    coupling.direction === 'vertical' ? 0 : Number.MAX_VALUE
                );
            const couplingWidth =
                coupling.direction === 'horizontal'
                    ? coupling.length
                    : coupling.width;
            const couplingHeight =
                coupling.direction === 'vertical'
                    ? coupling.length
                    : coupling.width;
            if (
                couplingWidth + couplingPositionX <= scanRect.x + scanRect.width
                && couplingHeight + couplingPositionY <= scanRect.y + scanRect.height
            ) {
                if (
                    coupling.direction === 'horizontal'
                    && coupling.length === scanRect.width
                    && couplingPositionX >= scanRect.x
                    && couplingPositionY > scanRect.y
                ) {
                    fullDividerDirection = 'horizontal';
                    splitPoints.push({
                        position: couplingPositionY,
                        start: false,
                    });
                    splitPoints.push({
                        position: couplingPositionY + coupling.width,
                        start: true,
                    });
                }
                if (
                    coupling.direction === 'vertical'
                    && coupling.length === scanRect.height
                    && couplingPositionX > scanRect.x
                    && couplingPositionY >= scanRect.y
                ) {
                    fullDividerDirection = 'vertical';
                    splitPoints.push({
                        position: couplingPositionX,
                        start: false,
                    });
                    splitPoints.push({
                        position: couplingPositionX + coupling.width,
                        start: true,
                    });
                }
            }
        }
        splitPoints.sort((a, b) => a.position - b.position);
        if (fullDividerDirection === 'horizontal') {
            steps = [[scanRect.y]];
            let k = 0;
            for (const position of splitPoints) {
                if (position.start) {
                    k++;
                    steps.push([]);
                }
                steps[k].push(position.position);
            }
            steps[k].push(scanRect.height + scanRect.y);

            scanRegions = [];
            for (const step of steps) {
                step.sort((a, b) => a - b);
                for (let i = 0; i < step.length - 1; i++) {
                    scanRegions.push({
                        x: scanRect.x,
                        y: step[i],
                        width: scanRect.width,
                        height: step[i + 1] - step[i],
                    });
                }
            }
        } else {
            // verical
            steps = [[scanRect.x]];
            let k = 0;
            for (const position of splitPoints) {
                if (position.start) {
                    k++;
                    steps.push([]);
                }
                steps[k].push(position.position);
            }
            steps[k].push(scanRect.width + scanRect.x);
            scanRegions = [];

            for (const step of steps) {
                step.sort((a, b) => a - b);
                for (let i = 0; i < step.length - 1; i++) {
                    scanRegions.push({
                        x: step[i],
                        y: scanRect.y,
                        height: scanRect.height,
                        width: step[i + 1] - step[i],
                    });
                }
            }
        }

        scanRegions.forEach(region => {
            const framesInRegion = frames.filter(
                frame =>
                    frame.x >= region.x
                    && frame.y >= region.y
                    && frame.x + frame.width <= region.x + region.width
                    && frame.y + frame.height <= region.y + region.height
            );
            if (framesInRegion.length === 1) {
                framesInRegion[0].index = ++index.value;
                this.setIndexInRect(
                    {
                        x: 0,
                        y: 0,
                        width: framesInRegion[0].width,
                        height: framesInRegion[0].height,
                    },
                    {
                        sashes: sashes.filter(sash => sash.frameId === framesInRegion[0].id),
                        dividers: dividers.filter(d => d.frameId === framesInRegion[0].id),
                        type,
                    },
                    sashIndex
                );
                sashes
                    .filter(sash => sash.frameId === framesInRegion[0].id)
                    .filter(sash => sash.intSashes.length)
                    .forEach(sash => {
                        this.setIndexInRect(
                            { x: 0, y: 0, width: sash.rWidth, height: sash.rHeight },
                            { sashes: sash.intSashes, dividers: sash.intMullions, type }
                        );
                    });
            } else if (framesInRegion.length > 1) {
                this.setFrameIndexInRect(
                    region,
                    { frames, couplings, sashes, dividers, type },
                    index,
                    sashIndex
                );
            }
        });
    }
}
