import { StepComponentType } from '@icc/configurator/shared';
import { ModelComponent } from './model/model.component'
import { LocksComponent } from './locks/locks.component';
import {
    DimensionsComponent,
    SystemComponent,
    LayoutComponent,
    ColorsComponent,
    GlazingsComponent,
    MuntinsComponent,
    FittingComponent,
    ConstructionComponent,
    ProductDetailsComponent,
    SystemPacketsComponent
} from '@icc/configurator/window';

import { AccessoriesComponent } from '@icc/configurator/accessory';
import { HardwareComponent } from './hardware/hardware.component';
import { SizesComponent } from './sizes/sizes.component';
import { LightsGlazingComponent } from './lights-glazing/lights-glazing.component';

export const doorStepsMap: {
    [stepId: string]: StepComponentType;
} = {
    models: ModelComponent,
    dimensions: DimensionsComponent,
    sizes: SizesComponent,
    sashes: LayoutComponent,
    construction: ConstructionComponent,
    system: SystemComponent,
    packets: SystemPacketsComponent,
    color: ColorsComponent,
    fillings: GlazingsComponent,
    lightsglazing: LightsGlazingComponent,
    muntins: MuntinsComponent,
    fitting: FittingComponent,
    hardware: HardwareComponent,
    locks: LocksComponent,
    accessories: AccessoriesComponent,
    details: ProductDetailsComponent
};
