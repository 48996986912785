import angular from 'angular';

/**
 * Fabryka falbany markiz
 * @param {object} $rootScope               RootScope
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} ConfiguratorsDataService Fabryka z danymi do konfiguratorów
 * @param {object} CurConfService           Informacje o bieżącym konfiguratorze
 * @param {object} StepFactory              StepFactory
 * @param {object} PriceService             Liczenie cen
 * @param {object} $filter                  Filtry angularowe
 */
export default function FrillFactory($rootScope, ConfigurationsService, ConfiguratorsDataService, CurConfService, StepFactory, PriceService, $filter, EventBusService) {
    'ngInject';

    var factory = {
        valances                : [],
        rollUpPrices            : [],
        inscription             : '',
        loadedData              : false,
        rollUpValance           : false,
        selectValance           : selectValance,
        setDefaultValues        : setDefaultValues,
        selectValanceInscription: selectValanceInscription,
        setRollUpValance        : setRollUpValance,
        emptyValance            : {id: 0,name: $filter('translate')('AWNING|brak falbany')}
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    $rootScope.$on('changedStep', init);

    return factory;

    /**
     * Funkcja inicjujaca
     */
    function init() {
        if (CurConfService.conf === 'awning') {
            setValues();
            if (angular.isUndefined(ConfigurationsService.conf.Current.Valance.id)) {
                factory.setDefaultValues();
            }
            factory.inscription = ConfigurationsService.conf.Current.ValanceInscription;
            factory.rollUpValance = ConfigurationsService.conf.Current.RollUpValance;
            factory.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Ustawianie wartości
     */
    function setValues() {
        factory.valances = getValances();
        factory.rollUpPrices = getRollupValancePrices();
    }

    /**
     * Zwracanie lambrekiny
     * @return {Array} Lambrekiny
     */
    function getValances() {
        var fabricID = ConfigurationsService.conf.Current.Fabric.id;
        if (fabricID > 0) {
            return ConfiguratorsDataService.data.awningValances[fabricID];
        } else {
            return [];
        }
    }

    /**
     * Zwracanie cen zwijania lambrekinów
     * @return {object} Ceny
     */
    function getRollupValancePrices() {
        return ConfiguratorsDataService.data.awningRollupValancePrices;
    }

    /**
     * Wybór lambrekinów
     * @param  {Object} valance  Lambrekin
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    function selectValance(valance, nextStep, price) {
        ConfigurationsService.conf.Current.Valance = valance;
        // ConfigurationsService.conf.Default.Valance = valance;

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }
    }

    /**
     * Wybór napisu lambrekinu
     * @param  {Object} inscription Napis
     * @param  {Boolean} nextStep    Następny krok
     * @param  {Number} price       Cena
     */
    function selectValanceInscription(inscription, nextStep, price) {
        ConfigurationsService.conf.Current.ValanceInscription = inscription;
        // ConfigurationsService.conf.Default.ValanceInscription = inscription;

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }
    }

    /**
     * Ustawianie zwijania lambrekinu
     * @param {[type]} rollUpValance [description]
     * @param {[type]} nextStep      [description]
     * @param {[type]} price         [description]
     */
    function setRollUpValance(rollUpValance, nextStep, price) {
        ConfigurationsService.conf.Current.RollUpValance = rollUpValance;
        // ConfigurationsService.conf.Default.RollUpValance = rollUpValance;

        ConfigurationsService.conf.Current.RollUpPrices = factory.rollUpPrices;
        // ConfigurationsService.conf.Default.RollUpPrices = factory.rollUpPrices;

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }
    }

    /**
     * Ustawianie wartości domyślnych
     * @param {Boolean} reset Reset
     */
    function setDefaultValues(reset) {

        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }

        setValues();

        if (
            angular.isUndefined(ConfigurationsService.conf.Current.Valance.id) ||
            reset
        ) {
            factory.selectValance(factory.emptyValance);
        }
    }

}
