import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepComponent, _, ModalService } from '@icc/configurator/shared';
import { DriveService } from '@icc/legacy/configurator/steps/awning/drive.service';
import { EventBusService, ConfigurationsService } from '@icc/common';

@Component({
    selector: 'icc-awning-drive',
    templateUrl: './awning-drive.component.html',
    styleUrls: ['./awning-drive.component.scss'],
})
export class AwningDriveComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Napęd');
    static stepIcon = {
        ligature: 'touch_app',
    };
    private subscriptions: any[] = [];
    public configurator = 'awning';
    public stepId = 'drive';
    public title = _('COLOR|Wybierz rodzaj napędu');


    form: FormGroup;

    cranks: any[] = [];
    motors: any[] = [];
    driveType: any = 'crank';


    constructor(
        private driveService: DriveService,
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService<'awning'>,
        private fb: FormBuilder,
    ) {
        super();
    }

    ngOnInit() {
        this.motors = this.driveService.getMotors().map(el => ({
            id: el.id,
            title: el.name,
        }));
        this.cranks = this.driveService.getCranks().map(el => ({
            id: el.id,
            title: el.name,
        }));

        this.form = this.fb.group({
            selectedDriveType: ['', [Validators.required]],
            selectedCrank: [''],
            selectedMotor: [''],
            selectedDriveSide: ['left']
        })


        this.form.get('selectedCrank').setValue("1", {emitEvent:false});
        this.form.get('selectedMotor').setValue("1", {emitEvent:false});
        this.subscriptions.push(
            this.form.get('selectedDriveType').valueChanges.subscribe((type) => {
                this.driveType = type;
                this.driveService.selectType(type);
            }),
            this.form.get('selectedCrank').valueChanges.subscribe((crankId) => {
                const crank = this.driveService.getCranks().find(c => c.id === crankId);
                this.driveService.selectCrank(crank);
            }),
            this.form.get('selectedMotor').valueChanges.subscribe((motorId) => {
                const motor = this.driveService.getMotors().find(m => m.id === motorId);
                this.driveService.selectMotor(motor);
            }),
            this.form.get('selectedDriveSide').valueChanges.subscribe((side) => {
                this.driveService.selectSide(side);
            })
        )

        if (this.configurationsService.conf.Current) {
            this.driveType = String(this.configurationsService.conf.Current.DriveType);
            this.form.get('selectedDriveType').setValue(String(this.configurationsService.conf.Current.DriveType), {emitEvent:false});
            this.form.get('selectedMotor').setValue(String(this.configurationsService.conf.Current.Motor.id), {emitEvent:false});
            this.form.get('selectedCrank').setValue(String(this.configurationsService.conf.Current.Crank.id), {emitEvent:false});
            this.form.get('selectedDriveSide').setValue(String(this.configurationsService.conf.Current.DriveSide), {emitEvent:false});
        }
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }
}
