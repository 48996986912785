/**
 * Kontroler rozmiaru markiz
 * @param {object} $scope                   Scope
 * @param {object} AwningDimensionsService  Fabryka kolorów do markiz
 */
export default function DimensionsCtrl($scope, AwningDimensionsService, EventBusService) {
    'ngInject';

    var vm = this;

    vm.subStep = 2;
    vm.outreaches = AwningDimensionsService.outreaches;
    vm.outreachesWidth = AwningDimensionsService.outreachesWidth;
    vm.outreach = AwningDimensionsService.outreach;
    vm.width = AwningDimensionsService.width;

    vm.selectSize = selectSize;
    vm.valid = AwningDimensionsService.valid.bind(AwningDimensionsService);
    vm.changeOutreach = changeOutreach;
    vm.changeWidth = changeWidth;

    $scope.$on('changedStep', function (event, newVal, oldVal) {
        if (newVal.code !== 'dimensions' && vm.valid(vm.width) && oldVal.code === 'dimensions') {
            selectSize(vm.outreach, vm.width);
        }
    });
    if (AwningDimensionsService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjujaca
     */
    function init() {
        vm.outreaches = AwningDimensionsService.outreaches;
        vm.outreachesWidth = AwningDimensionsService.outreachesWidth;
        vm.outreach = AwningDimensionsService.outreach;
        vm.width = AwningDimensionsService.width;
    }

    /**
     * Wybór rozmiatu
     * @param  {Object} outreach Zasięg
     * @param  {Number} width    Szerokość
     */
    function selectSize(outreach, width) {
        AwningDimensionsService.selectSize(outreach, width, false, true);
    }

    /**
     * Zmiana zasięgu
     */
    function changeOutreach() {
        if (vm.width < vm.outreachesWidth[vm.outreach].width_from) {
            vm.width = vm.outreachesWidth[vm.outreach].width_from;
            selectSize(vm.outreach, vm.width);
        }
    }

    /**
     * Zmiana szerokości
     */
    function changeWidth() {
        selectSize(vm.outreach, vm.width);
    }
}
