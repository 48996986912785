import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';

@Component({
  selector: 'icc-drive-manuals-page',
  templateUrl: './drive-manuals-page.component.html',
  styleUrls: ['./drive-manuals-page.component.scss']
})
export class DriveManualsPageComponent extends PageComponent implements OnInit {
  public title = _('ROLLERSHUTTER|Wybierz rodzaj sterowania');

  drives: iccListItem[] = [];
  selectedDriveId = 0;

  constructor(
    @Inject(ICC_PAGE_DATA) private pageData: {
      drives: any[],
      selDrive: any,
      b2b: boolean
    },
    private sharedFacade: SharedFacade
  ) {
    super();
  }

  ngOnInit() {
    this.drives = this.pageData.drives.map(drive => ({
      id: Number(drive.id),
      title: drive.name,
      description: '', //<span class="small" ng-if="((IccConfig.Configurators.modalPricesB2c && b2c) || (IccConfig.Configurators.modalPricesB2b && !b2c)) && drive.price">{{ 'CONFIGURATOR|Cena' | translate }} : {{drive.price | currency:currency}}<br></span>
      imageUrl: '/files/rollershutterdrivesmanual/' + drive.image,
    }));
    this.selectedDriveId = this.pageData.selDrive && Number(this.pageData.selDrive.id);
  }

  selectDrive(item: iccListItem) {
    const drive = this.pageData.drives.find(f => Number(f.id) === Number(item.id));
    if (drive) {
        this.sharedFacade.closePage(drive);
    }
  }


}
