<icc-list [searchable]="true" [items]="profiles" [itemTemplate]="itemProfile"
        [selected]="selectedProfileId"
        itemWidth="full"
        itemImgHeight="medium"
        [ignoreTemplateWidth]="true"
        (select)="selectProfile($event)"
        [tabs]="showTabs ? tabs : null"></icc-list>

<ng-template #itemProfile let-item="iccListItem">
    <div class="item-imgTitleDescription">
        <div class="icc-list-item-img-container">
            <img class="item-imgTitleDescription-img" [src]="item.imageUrl"
                [alt]="item.title"
                defaultImg>
        </div>
        <p class="item-imgTitleDescription-title">{{item.title}}
            <button mat-mini-fab class="item-imgTitleDescription-info-button mat-elevation-z0" *ngIf="isWysiwygEditorContentNotEmpty(item)" (click)="clickShowInfoButton(item)" color="secondary" [title]="item.description || ''">
                <mat-icon class="mat-18">info</mat-icon>
            </button>
        </p>
        <p class="item-imgTitleDescription-description">
            {{item.description}}
        </p>
        <p class="item-imgTitleDescription-color"
           *ngIf="item.type === 'extension' || item.type === 'sandwich' || item.type === 'coupling' && showColorsWhenCoupling">
           <span
                *ngIf="item.selectedWood?.name">{{ 'WINDOW|Rodzaj drewna' | translate }}:
                <b>{{ item.selectedWood?.name }}</b>
                <br />
            </span>

            <span
                *ngIf="item.selectedColor?.outer?.id === item.selectedColor?.inner?.id">{{ 'COLOR|Kolor' | translate }}:
                <b>{{ item.selectedColor?.outer?.name }}</b>
                <br />
            </span>
            <ng-container *ngIf="item.selectedColor?.outer?.id !== item.selectedColor?.inner?.id">
                <span *ngIf="item.selectedColor?.outer?.name">{{ 'COLOR|Kolor zewn.' | translate }}:
                    <b>{{ item.selectedColor?.outer?.name }}</b>
                    <br />
                </span>
                <span *ngIf="item.selectedColor?.inner?.name">{{ 'COLOR|Kolor wewn.' | translate }}:
                    <b>{{ item.selectedColor?.inner?.name }}</b>
                    <br />
                </span>
            </ng-container>

            <span
                  *ngIf="!item.selectedColor?.outer?.isCore &&
                     !item.selectedColor?.inner?.isCore &&
                     item.selectedColor?.core?.name &&
                     config().IccConfig.Configurators.showCoreColorInAdditionalProfilesAndAccessories
                    ">{{ 'WINDOW|Kolor bazy' | translate }}:
                <b>{{ item.selectedColor?.core?.name }}</b>
                <br />
            </span>

            <span *ngIf="config().IccConfig.Configurators.extensionsReinforcements && item.type === 'extension'">{{ 'WINDOW|Wzmocnienie' | translate }}:
                <b *ngIf="item.reinforcement?.name">{{ item.reinforcement?.name }}</b>
                <b *ngIf="!item.reinforcement?.name">{{ 'INTERFACE|Brak' | translate }}</b>
                <br />
            </span>
        </p>
        <p class="item-imgTitleDescription-price" *ngIf="!hiddenPrice">
            {{ 'OFFER|Cena' | translate }}:<br>
            <b>
                <span
                      *ngIf="hasZeroPrice(item.price, item.type) && (!config().IccConfig.Configurators.extensionsReinforcements || item.type !== 'extension' || !item.reinforcement || item.reinforcement.price === 0)">{{ 'CONFIGURATOR|W cenie' | translate }}<br></span>
                <span
                      *ngIf="hasNoPrice(item.price, item.type)">{{ 'CONFIGURATOR|Do wyceny' | translate }}<br></span>
                <span
                      *ngIf="!hasZeroPrice(item.price, item.type) && ! hasNoPrice(item.price, item.type)">
                    <span
                          *ngIf="item.price.price_field && (!config().IccConfig.Configurators.price.frameProfilesAlt || item.type != 'frame')">{{priceMarket(item.price.price_field) | currency: currency}}/{{ 'WINDOW|pole' | translate }}<br></span>
                    <span
                          *ngIf="item.price.price_length && (!config().IccConfig.Configurators.price.frameProfilesAlt || item.type != 'frame')">{{dimensionUnit.unitSymbol != 'mm' ? '~' : ''}}{{priceMarket(item.price.price_length) + (config().IccConfig.Configurators.extensionsReinforcements && item.reinforcement ? item.reinforcement.price : 0) | unitm:0:0:3 | currency: currency}}/<span
                              [innerHTML]="2|formatUnit"></span><br></span>
                    <span
                          *ngIf="item.price.price_area">{{item.price.price_area | currency: currency}}/<span
                              [innerHTML]="1|formatUnit"></span><br></span>
                    <span
                          *ngIf="item.price.price_field != item.price.price_length &&  config().IccConfig.Configurators.price.frameProfilesAlt && item.type == 'frame'">{{dimensionUnit.unitSymbol != 'mm' ? '~' : ''}}{{priceMarket(item.price.price_length) | unitm:0:0:3 | currency: currency}}/<span
                              [innerHTML]="2|formatUnit"></span> <span
                              *ngIf="item.price.price_field">{{ 'INTERFACE|lub' | translate }}&nbsp;{{priceMarket(item.price.price_field) | unitm:0:0:3 | currency: currency}}/<span
                                  [innerHTML]="2|formatUnit"></span></span><br></span>
                    <span
                          *ngIf="item.price.price_length && item.price.price_field == item.price.price_length &&  config().IccConfig.Configurators.price.frameProfilesAlt && item.type == 'frame'">{{dimensionUnit.unitSymbol != 'mm' ? '~' : ''}}{{priceMarket(item.price.price_length) | unitm:0:0:3 | currency: currency}}/<span
                              [innerHTML]="2|formatUnit"></span><br></span>
                    <span
                          *ngIf="item.price.price_piece">{{item.price.price_piece | currency: currency}}/{{ 'DIMENSION|szt.' | translate }}<br></span>
                    <span
                          *ngIf="item.price.price_construction">{{item.price.price_construction | currency: currency}}/{{ 'DIMENSION|konstrukcja' | translate }}<br></span>
                    <span
                          *ngIf="item.price.price_percent">{{item.price.price_percent}}%</span>
                </span>
            </b>
        </p>
    </div>
</ng-template>
