import angular from 'angular';

export default function EmptySrcDirective() {
    'ngInject';

    var emptySrc = {
        link: function postLink(scope, iElement, iAttrs) {
            iElement.bind('error', function() {
                angular.element(this).attr('src', iAttrs.emptySrc);
            });
        }
    };
    return emptySrc;
}
