import { TranslateService } from '@ngx-translate/core';
import { EventBusService } from '@icc/common/event-bus.service';
import { GarageDoorFacade } from './../+state/garage-door.facade';
import { Component, Inject, OnInit } from '@angular/core';
import { _, StepComponent } from '@icc/configurator/shared';
import {
    APP_CONFIG,
    AppConfigFactory,
    DrawService,
    ConfigurationsService,
} from '@icc/common';
import { InsertsService } from '../inserts.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { UnitConverterService } from '@icc/configurator/ui';

@Component({
    selector: 'icc-construction',
    templateUrl: './construction.component.html',
    styleUrls: ['./construction.component.scss'],
})
export class ConstructionComponent extends StepComponent implements OnInit {
    static stepName = _('GARAGEDOOR|Konstrukcja');
    static stepIcon = {
        ligature: 'view_compact',
    };

    public configurator = 'garage_door';
    public stepId = 'construction';
    title = _('GARAGEDOOR|Edycja paneli');
    fullscreen = true;

    drawOptions: any = null;
    drawButtons = {
        panels: false,
        dimensions: false,
        fullscreen: false,

        toggle: (
            type: 'panels'
                | 'dimensions'
                | 'fullscreen'
        ) => {
            if (type && typeof this.drawButtons[type] !== 'undefined') {
                this.drawButtons[type] = !this.drawButtons[type];
            }

            this.drawButtons.update();
        },

        update: () => {
            const conf = this.configurationsService.conf.Current;
            const defaultConf = this.configurationsService.conf.Default;
            if (conf) {
                this.drawOptions = Object.assign({}, this.drawService.options, {
                    dimensions: {
                        multiplier: 1,
                        type: this.drawService.options.dimensions.type,
                    },
                    panel: {
                        buttons: [
                            {
                                class: 'ico-plus',
                                title: this.translateService.instant(
                                    'INTERFACE|Dodaj wypełnienie'
                                ),
                                condition: (response: any) =>
                                    !this.insertsService.isPanelInsertGlazing(response.positionId)
                                    && !this.insertsService.anyInsertsInPanel(response.positionId),
                                onClick: (response: any) =>
                                    this.insertsService.openInsertsModal(response.positionId),
                            },
                            {
                                positions: ['left'],
                                class: 'ico-plus',
                                title: this.translateService.instant(
                                    'INTERFACE|Dodaj'
                                ),
                                condition: (response: any) =>
                                    !this.insertsService.isPanelInsertGlazing(response.positionId)
                                    && this.insertsService.anyInsertsInPanel(response.positionId),
                                onClick: (response: any) =>
                                    this.insertsService.addSameInsert(response.positionId),
                            },
                            {
                                positions: ['bottom'],
                                class: 'ico-equals',
                                title: this.translateService.instant(
                                    'INTERFACE|Wyśrodkuj wypełnienia'
                                ),
                                condition: (response: any) =>
                                    !this.insertsService.isPanelInsertGlazing(response.positionId)
                                    && this.insertsService.anyInsertsInPanel(response.positionId),
                                onClick: (response: any) =>
                                    this.insertsService.centerInsertsInPanelByIndex(response.positionId),
                            },
                            {
                                positions: ['right'],
                                class: 'ico-minus',
                                title: this.translateService.instant(
                                    'INTERFACE|Usuń'
                                ),
                                condition: (response: any) =>
                                    !this.insertsService.isPanelInsertGlazing(response.positionId)
                                    && this.insertsService.anyInsertsInPanel(response.positionId),
                                onClick: (response: any) =>
                                    this.insertsService.removeInsertAndCenterRemainingInserts(response.positionId),
                            },
                            {
                                positions: ['left'],
                                class: 'ico-plus',
                                title: this.translateService.instant(
                                    'INTERFACE|Dodaj impost'
                                ),
                                condition: (response: any) =>
                                    this.insertsService.isPanelInsertGlazing(response.positionId)
                                    && this.insertsService.isPanelFirstPanelWithGlazing(response.positionId),
                                onClick: (response: any) =>
                                    this.insertsService.incrementGlazingsGlobalImpostAmount(),
                            },
                            {
                                positions: ['right'],
                                class: 'ico-minus',
                                title: this.translateService.instant(
                                    'INTERFACE|Usuń impost'
                                ),
                                condition: (response: any) =>
                                    this.insertsService.isPanelInsertGlazing(response.positionId)
                                    && this.insertsService.isPanelFirstPanelWithGlazing(response.positionId)
                                    && (!this.config().IccConfig.Configurators.garage_door.glazingsGlobalImpostAmount
                                        || ((!this.insertsService.selectedWicketDoor
                                                && this.insertsService.getGlazingPanelsGlobalImpostAmount() > 0)
                                            || this.insertsService.getGlazingPanelsGlobalImpostAmount() > 2)),
                                onClick: (response: any) =>
                                    this.insertsService.decrementGlazingsGlobalImpostAmount(),
                            },
                            {
                                positions: ['right'],
                                class: 'ico-remove',
                                title: this.translateService.instant(
                                    'INTERFACE|Usuń oszklenie'
                                ),
                                condition: (response: any) =>
                                    this.insertsService.isPanelInsertGlazing(response.positionId),
                                onClick: (response: any) =>
                                    this.insertsService.removeAllInsertsInPanel(response.positionId),
                            },
                        ]
                    },
                    insert: {
                        buttons: [
                            {
                                class: 'ico-move',
                                title: 'Przesuń',
                                condition: (response: any) =>
                                    !this.insertsService.isPanelInsertGlazing(response.panelPositionId),
                                onMove: (response: any) => {
                                    this.insertsService.moveInsert(response.panelPositionId, response.positionId, response.difference);
                                }
                            }
                        ]
                    },
                    onInterfaceClick: () => {},
                });
                this.drawOptions.base.side = 'inner';
            }

            this.eventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
        },
    };

    selectedDimensions$ = this.garageDoorFacade.selectedDimensions$;
    selectedGlazingsGlobalImposts$ = this.garageDoorFacade.selectedGlazingsGlobalImposts$;
    selectedWindowsGlobalGlazing$ = this.garageDoorFacade.selectedWindowsGlobalGlazing$;
    selectedGlazingPanelsGlobalGlazing$ = this.garageDoorFacade.selectedGlazingPanelsGlobalGlazing$;

    windowGlazings;
    glazingPanelGlazings;

    constructionForm: FormGroup;

    dimensionUnit = this.unitConverterService.getUnit();

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        public drawService: DrawService,
        private fb: FormBuilder,
        private garageDoorFacade: GarageDoorFacade,
        private eventBusService: EventBusService,
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService,
        private insertsService: InsertsService,
        private unitConverterService: UnitConverterService
    ) {
        super();
    }

    ngOnInit() {
        const selectedGlobalWindow = this.insertsService.getGlobalWindow();
        this.windowGlazings = this.insertsService.getFilteredGlazings(selectedGlobalWindow);
        const selectedGlobalGlazing = this.insertsService.getGlobalGlazingPanel();
        this.glazingPanelGlazings = this.insertsService.getFilteredGlazings(selectedGlobalGlazing);
        this.drawButtons.update();

        const impostAmount = this.insertsService.getGlazingPanelsGlobalImpostAmount();

        this.constructionForm = this.fb.group({
            impostAmount: [impostAmount, [Validators.min(this.getMinImpostAmount()), Validators.max(this.getMaxImpostAmount())]],
        })

        this.selectedGlazingsGlobalImposts$.subscribe(a => {
            if (a && a.length)
                this.constructionForm.get("impostAmount").setValue(a.length);
        })
    }

    anyWindowsInGarageDoor() {
        return this.insertsService.anyWindowsInGarageDoor();
    }

    anyGlazingsInGarageDoor() {
        return this.insertsService.anyGlazingsInGarageDoor();
    }

    anyVentilationGratesInGarageDoor() {
        return this.insertsService.anyVentilationGratesInGarageDoor();
    }

    openGlobalWindowTypesModal() {
        this.insertsService.openGlobalWindowTypesModal();
    }

    openGlobalWindowFramesOuterColorModal() {
        this.insertsService.openGlobalWindowFramesOuterColorModal();
    }

    openGlobalGlazingPanelsColorModal() {
        this.insertsService.openGlobalGlazingPanelsColorModal();
    }

    openGlobalVentilationGratesOuterColor() {
        this.insertsService.openGlobalVentilationGratesOuterColorModal();
    }

    getMaxImpostAmount() {
        return this.insertsService.getMaxGlazingsImpostAmount();
    }

    getMinImpostAmount() {
        return this.insertsService.getMinImpostAmount();
    }

    setImposts(impostAmount) {
        this.insertsService.validateAndSetGlazingsGlobalImpostsByAmount(Number(impostAmount))
    }

    getImpostAmount() {
        this.insertsService.getGlazingPanelsGlobalImpostAmount();
    }

    setWindowsGlobalGlazing(selection) {
        this.insertsService.setWindowsGlobalGlazing(selection.value);
    }

    setGlazingPanelsGlobalGlazing(selection) {
        this.insertsService.setGlazingPanelsGlobalGlazing(selection.value)
    }

    get impostAmount() { return this.constructionForm.get('impostAmount'); }
}
