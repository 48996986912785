import { Injectable } from '@angular/core';
import { IccGlassTypeVariant } from '@icc/common/data-types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FillingsListPageStoreService {
    private selectedGlassTypeVariant$ = new BehaviorSubject<IccGlassTypeVariant | null>(null);

    setSelectedGlassTypeVariant$(value: IccGlassTypeVariant | null) {
        this.selectedGlassTypeVariant$.next(value);
    }

    get selectedGlassTypeVariant() {
        return this.selectedGlassTypeVariant$.getValue();
    }
}
