import { Component, OnInit } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';

@Component({
  selector: 'icc-slats-page',
  templateUrl: './slats-page.component.html',
  styleUrls: ['./slats-page.component.scss']
})
export class SlatsPageComponent extends PageComponent implements OnInit {
  public title = _('INTERFACE|Wybierz listwę końcową');

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
