import {NgModule}           from '@angular/core';
import {CommonModule}       from '@angular/common';
import {FormsModule}        from '@angular/forms';

import {TagsComponent}   from './tags.component';
// import {systemsByTagFilterProvider} from './ajs-upgraded-providers';
import { SystemsByTagPipe } from './systems-by-tag.pipe';
import { SystemsService } from './systems.service';

@NgModule({
  imports:      [ CommonModule, FormsModule ],
  declarations: [ TagsComponent, SystemsByTagPipe ],
  exports:      [ TagsComponent, SystemsByTagPipe ],
  providers:    [
    // systemsByTagFilterProvider,
    SystemsService,
  ],
  entryComponents: [
    TagsComponent
  ]
})
export class SystemsModule { }
