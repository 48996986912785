import {NgModule} from '@angular/core';

import {SystemsModule} from '@icc/legacy/configurator/steps/window/systems/systems.module';
import { ProfilesModule } from '@icc/configurator/window';
import {GlazingsModule} from '@icc/legacy/configurator/steps/window/glazings/glazings.module';
import {MuntinsModule} from '@icc/legacy/configurator/steps/window/muntins/muntins.module';
import {SystemsComparisonModule} from './systems-comparison/systems-comparison.module';
import {ColorsModule as CommonColorsModule} from '@icc/common/colors/colors.module';
import {DimensionsModule} from './dimensions/dimensions.module';
import {HandlesModule} from './handles/handles.module';
import { ColorsModule } from './colors/colors.module';
import { SashesModule } from './sashes/sashes.module';
import { AccessoriesModule } from './accessories/accessories.module';
import {ReinforcementsModule} from '@icc/legacy/configurator/steps/window/reinforcements/reinforcements.module';

@NgModule({
    imports: [
        SystemsModule,
        ProfilesModule,
        GlazingsModule,
        MuntinsModule,
        HandlesModule,
        DimensionsModule,
        CommonColorsModule,
        ColorsModule,
        SashesModule,
        SystemsComparisonModule,
        AccessoriesModule,
        ReinforcementsModule,
    ],
    exports: [],
    declarations: [],
    providers: [],
})
export class WindowModule { }
