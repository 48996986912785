const $ = require('imports-loader?this=>window!jquery');
import {logger} from '../helpers';
import {base64Decoder} from './svg-draw-parser';

export default function Base64DecoderFilter() {
    'ngInject';

    return function(data) {
        try {
            return base64Decoder(window.atob(data), $);
        } catch (err) {
            logger.error(err);
        }
    };
}
