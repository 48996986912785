import { Component, OnDestroy, OnInit } from '@angular/core';
import { StepComponent, _, ModalService } from '@icc/configurator/shared';
import { HardwareService } from './hardware.service';
import { Subscription } from 'rxjs';
import { DoorFacade } from '../+state/door.facade';
import { PlatesPageComponent } from '../plates-page/plates-page.component';
import { CylindersPageComponent } from '../cylinders-page/cylinders-page.component';
import { map } from 'rxjs/operators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { Common, ConfigurationsService } from '@icc/common';
import { LocksService } from '@icc/legacy/configurator/steps/door/locks/locks.service';
import { HandlesService } from '@icc/legacy/configurator/steps/window/handles/handles.service';
import { IccAccessoryAccessory } from '@icc/common/data-types';

interface HardwareSelectBox {
    img: string;
    name: string;
    color: any;
    colorId: string;
}

interface PlatesSelectBox {
    img: string;
    mainLockName: string;
    additionalLockName: string;
    color: any;
    colorId: string;
}

interface CylindersSelectBox {
    cylinders: {
        imagePath: string;
        mainLockName: string;
        additionalLockName: string;
        color: string;
        system: string;
        electronicLock: string | null;
    }
}

interface PullSelectBox {
    pullType: {
        name: string;
        color: string;
        colorId: string;
        imagePath: string;
    }
}

interface LocksSelectBox {
    lock: {
        name: string;
    }
}

interface ElectronicLockSelectBox {
    electronicLockName: string;
    electronicLockColor?: string;
}

@Component({
    selector: 'icc-hardware',
    templateUrl: './hardware.component.html',
    styleUrls: ['./hardware.component.scss'],
})
export class HardwareComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Osprzęt');
    static stepIcon = {
        ligature: 'lock',
    };
    public configurator = 'door';
    public stepId = 'hardware';
    public title = _('DOOR|Osprzęt');

    img = '';
    description = '';
    isPullColorRelatedToPlate: boolean;

    selectedHardwareType$ = this.doorFacade.selectedHardwareType$;
    hardwareTypeBoxSelect: HardwareSelectBox | null = null;
    platesBoxSelect: PlatesSelectBox | null = null
    cylindersBoxSelect: CylindersSelectBox | null = null;
    showWindowLeverBox$ = this.doorFacade.showHardwareTypeBox$;
    pullBoxSelect: PullSelectBox | null = null;
    lockBoxSelect: LocksSelectBox | null = null;
    hardwareSet$ = this.doorFacade.hardwareSet$;
    hardwareSetSelect$ = this.doorFacade.hardwareSet$.pipe(map(value => value || 'none'));
    hardwareSets$ = this.doorFacade.hardwareSets$;
    pull$ = this.doorFacade.pull$;
    lock$ = this.doorFacade.lock$;
    hinge$ = this.doorFacade.hinge$;
    hingeBoxSelect$ = this.doorFacade.hingeBoxSelect$;
    electronicLock$ = this.doorFacade.electronicLock$;
    electronicLockBoxSelect: ElectronicLockSelectBox | null = null;
    plateType$ = this.doorFacade.plateType$;
    plateMainLock$ = this.doorFacade.plateMainLock$;
    plateMainLockBoxSelect$ = this.doorFacade.plateMainLockBoxSelect$;
    plateAdditionalLock$ = this.doorFacade.plateAdditionalLock$;
    doorViewer$ = this.doorFacade.doorViewer$;
    doorHandleAdjustment$ = this.doorFacade.doorHandleAdjustment$;
    doorViewerPunching$ = this.doorFacade.doorViewerPunching$;
    decorativeHingeCoversColor$ = this.doorFacade.decorativeHingeCoversColor$;
    showDecorativeHingeCovers$ = this.doorFacade.showDecorativeHingeCovers$;
    showDoorHandleAdjustmentBox$ = this.doorFacade.showDoorHandleAdjustmentBox$;
    showDoorViewer$ = this.doorFacade.showDoorViewer$;
    showDoorViewerPunchingOption$ = this.doorFacade.showDoorViewerPunchingOption$;
    contextInfoInDoorViewer$ = this.doorFacade.getContextInfoInDoorViewer$;

    private subscriptions: Subscription[] = [];

    static stepEnable = (conf: WindowActiveConfiguration) =>
        Boolean(conf.System.door_type);

    constructor(
        private hardwareService: HardwareService,
        private doorFacade: DoorFacade,
        private modalService: ModalService,
        private configurationsService: ConfigurationsService<'door'>,
        private locksService: LocksService,
        private handlesService: HandlesService,
    ) {
        super();
    }

    ngOnInit() {

        this.subscriptions.push(this.doorFacade.hardwareTypeBoxSelect$.subscribe((box) => {
            this.hardwareTypeBoxSelect = box;
        }));

        this.subscriptions.push(this.doorFacade.platesBoxSelect$.subscribe((box) => {
            this.platesBoxSelect = box;
        }));

        this.subscriptions.push(this.doorFacade.cylindersBoxSelect$.subscribe((box) => {
            this.cylindersBoxSelect = box;
        }));

        this.subscriptions.push(this.doorFacade.pullsBoxSelect$.subscribe((box) => {
            this.pullBoxSelect = box;
            this.isPullColorRelatedToPlate = this.configurationsService.conf.Current.doorHardware.pullColorRelatedToPlate;
        }));

        this.subscriptions.push(this.doorFacade.locksBoxSelect$.subscribe((box) =>{
            this.lockBoxSelect = box;
        }))

        this.subscriptions.push(this.doorFacade.electronicLockBoxSelect$.subscribe((box) => {
            this.electronicLockBoxSelect = box;
        }))
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    changeTypeOptions() {
        this.hardwareService.openModalTypeOptions();
    }

    selectType() {
        this.hardwareService.openModalType();
    }

    openPlatesOptions() {
        this.modalService.open({
            pageComponent: PlatesPageComponent
        })
    }

    openPullsOptions() {
        this.hardwareService.openModalPullOptions();
    }

    changeLock() {
        this.hardwareService.openLockModal();
    }

    changePull() {
        this.hardwareService.openPullsModal();
    }

    openCylindersOptions() {
        this.modalService.open({
            pageComponent: CylindersPageComponent
        })
    }

    changeSet($event) {
        const value = $event.value === 'none' ? null : $event.value;
        this.hardwareService.setSet(value);
    }

    changeElectronicLock() {
        this.hardwareService.openElectronicLockModal();
    }

    changePlateType() {
        this.hardwareService.openPlateTypeModal();
    }

    changeDoorViewer() {
        this.hardwareService.openDoorViewerModal();
    }

    changeDoorHandleAdjustment() {
        this.hardwareService.openDoorHandleAdjustmentsModal();
    }

    changeDoorViewerPunching($event: MatSlideToggleChange) {
        this.hardwareService.changeDoorViewerPunching($event.checked);
    }

    changeDecorativeHingeCover() {
        this.hardwareService.openModalDecorativeHingeCoverOptions();
    }

    changeHinges() {
        this.handlesService.openModalHinge();
    }

    isPullButtonDisabled() {
        const pulls = this.hardwareService?.pulls;
        const pullsLength = pulls?.length;

        if(pullsLength > 1) {
            return false;
        }

        return true;
    }

    isPullButton2Disabled() {
        const pullId = this.configurationsService.conf.Current.doorHardware?.pull;
        const pulls = this.hardwareService?.pulls;
        const selectedPull = pulls.find(p => Number(p.id) === Number(pullId));
        const pullColorLength = selectedPull?.colors_ids?.length;

        if(!Common.isDefined(pullColorLength) || pullColorLength <= 1) {
            return true;
        }

        return false;
    }


    isDoorViewerButtonDisabled() {
        const doorViewers = this.hardwareService.getAvailableDoorViewers()
        const doorViewersLength = doorViewers?.length;

        if(doorViewersLength > 1 ) {
            return false;
        }

        return true;
    }

    isLockButtonDisabled() {
        const locks = this.locksService?.locks;
        if(locks?.length > 1) {
            return false;
        }

        return true;
    }

    isCylinderBoxVisible() {
        const cylindersBox = this.cylindersBoxSelect?.cylinders;
        const box = cylindersBox && Object.values(cylindersBox).filter(x => (x !== '' && x !== null));

        if(box?.length > 0) {
            return true;
        }

        return false;
    }

    isDecorativeHingeCoverButtonDisabled(conf = this.configurationsService.conf.Current) {
        const decoHingeCovers = this.hardwareService.decoHingeCovers;

        const hingeCoverId = conf.doorHardware && conf.doorHardware.decorativeHingeCovers;
        const cover = decoHingeCovers.find(f => Number(f.id) === Number(hingeCoverId));

        const colorLength = cover?.colors_ids?.length || 0;

        if(conf.doorHardware.decorativeHingeCoversColorRelatedToPlate || colorLength <= 1) {
            return true;
        }
        return false;
    }

    isHingeButtonDisabled(conf = this.configurationsService.conf.Current) {
        const hingeId = conf.Hinge?.id;

        const hinges = this.handlesService.findHingesBySystem(conf.System);
        const selectedHinge = hinges.find((h) => Number(h.id) === Number(hingeId));
        const hingeColorLength = selectedHinge.colors_ids?.length;
        if ((!hingeColorLength || hingeColorLength <= 1) && (!hinges || hinges.length <= 1)) {
            return true;
        }

        return false;
    }

    isPlatesButtonDisabled(conf = this.configurationsService.conf.Current) {
        const platesToMainLock = this.hardwareService.getPlatesToMainLock();
        const plateToMainLockId = conf.doorHardware && conf.doorHardware.plates.mainLock;
        const plateToMainLock = platesToMainLock.find(f => Number(f.id) === Number(plateToMainLockId));
        const colors = plateToMainLock?.colors_ids?.length;

        const platesToAdditionalLock = this.hardwareService.getPlatesToAdditionalLock();

        if ((!Common.isDefined(colors) || colors <= 1 ) && platesToMainLock?.length <= 1 && platesToAdditionalLock?.length <= 1) {
            return true;
        }

        return false;
    }
}

