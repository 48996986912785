export default function ProjectNumberPaddingFilter() {
    'ngInject';

    return function(input) {
        if (input < 10) {
            return '0' + input;
        } else {
            return input;
        }
    };
}
