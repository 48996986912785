import 'angular-ui-bootstrap';
import { core } from '@icc/common/helpers';

/**
 * Kontroler modal'a edycji montaży
 *
 * @export
 * @class ModalMontageGroupEdit
 */
export class ModalMontageGroupEdit {

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        public offer,
        public group,
    ) {
        'ngInject';


    }

    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    save() {
        this.$uibModalInstance.close(this.group);
    }

    /**
     * Zamyka modal.
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    close() {
        this.$uibModalInstance.close();
    }

    customMontageChange(position) {
        if (this.offer.dealer_status !== '2' && !this.offer.confirm) {
            position.if_modified = true;
            position.modified_own_montage_cost = position.own_montage_cost;
        }
    }

    customMontageRestore(position) {
        if (this.offer.dealer_status !== '2' && !this.offer.confirm) {
            position.if_modified = false;
            position.modified_own_montage_cost = 0;
            position.own_montage_cost = position.own_montage_cost_basic;
        }
    }

    round(num) {
        return core.round(num);
    }
}
