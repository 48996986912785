import * as angular from 'angular';

import Directives from './directives/directives';

import { DrawDataService } from './DrawDataService';
import DrawingCtrl from './DrawingCtrl';

export default angular
    .module('icc.configurator.draw', [Directives])
    .controller('DrawingCtrl', DrawingCtrl)
    .service('DrawDataService', DrawDataService).name;
