import { InjectionToken, NgModule } from '@angular/core';
import { CoupledWindowService } from './coupled-window.service';
import { EditingCoupledWindowService } from './editing-coupled-window.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        CoupledWindowService,
        EditingCoupledWindowService
    ],
})
export class CoupledWindowModule {}
