import { MosquitoScreenAction, MosquitoScreenActionTypes } from './mosquito-screen.actions';
import { IMosquitoActiveConfiguration } from '@icc/common/configurations/MosquitoActiveConfiguration';

export const MOSQUITOSCREEN_FEATURE_KEY = 'mosquitoScreen';

export interface MosquitoScreenState {
    configuration: IMosquitoActiveConfiguration | null;
}

export interface MosquitoScreenPartialState {
    readonly [MOSQUITOSCREEN_FEATURE_KEY]: MosquitoScreenState;
}

export const initialState: MosquitoScreenState = {
    configuration: null,
};

export function mosquitoScreenReducer(
    state: MosquitoScreenState = initialState,
    action: MosquitoScreenAction
): MosquitoScreenState {
    switch (action.type) {
        case MosquitoScreenActionTypes.UpdateMosquitoScreenConfiguration: {
            state = {
                ...state,
                configuration: {
                    ...action.payload.configuration,
                },
            };
            break;
        }
    }
    return state;
}
