import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AwningSystemsComponent } from './awning-systems/awning-systems.component';
// tslint:disable-next-line: nx-enforce-module-boundaries
import { SharedModule } from '@icc/configurator/shared';
import { AwningDimensionsComponent } from './awning-dimensions/awning-dimensions.component';
import { AwningColorsComponent } from './awning-colors/awning-colors.component';
import { AwningSheathingComponent } from './awning-sheathing/awning-sheathing.component';
import { AwningFrillComponent } from './awning-frill/awning-frill.component';
import { AwningAssemblyComponent } from './awning-assembly/awning-assembly.component';
import { SystemsService } from '@icc/legacy/configurator/steps/awning/systems.service';
import { DimensionsService } from '@icc/legacy/configurator/steps/awning/dimensions.service';
import { AssemblyService } from '@icc/legacy/configurator/steps/awning/assembly.service';
import { ColorsService } from '@icc/legacy/configurator/steps/awning/colors.service';
import { SheathingService } from '@icc/legacy/configurator/steps/awning/sheathing.service';
import { FrillService } from '@icc/legacy/configurator/steps/awning/frill.service';
import { DriveService } from '@icc/legacy/configurator/steps/awning/drive.service';
import { ReactiveFormsModule } from '@angular/forms';
import { AwningSystemsInfoComponent } from './awning-systems-info/awning-systems-info.component';
import { AwningDriveComponent } from './awning-drive/awning-drive.component';
import { AwningAccessoriesComponent } from './awning-accessories/awning-accessories.component';
import { AccessoryService } from '@icc/legacy/configurator/steps/awning/accessory.service';
import { AwningAddAccessoriesComponent } from './awning-add-accessories/awning-add-accessories.component';
import { AwningAccessoriesInfoComponent } from './awning-accessories-info/awning-accessories-info.component';
import { ConfiguratorUiModule } from '@icc/configurator/ui';
import { ConfiguratorPagesModule } from '@icc/configurator/pages';

@NgModule({
    imports: [CommonModule, SharedModule, ReactiveFormsModule, ConfiguratorUiModule, ConfiguratorPagesModule],
    declarations: [
        AwningDimensionsComponent,
        AwningColorsComponent,
        AwningSheathingComponent,
        AwningFrillComponent,
        AwningAssemblyComponent,
        AwningSystemsComponent,
        AwningSystemsInfoComponent,
        AwningDriveComponent,
        AwningAccessoriesComponent,
        AwningAddAccessoriesComponent,
        AwningAccessoriesInfoComponent,
    ],
    exports: [
        AwningDimensionsComponent,
        AwningColorsComponent,
        AwningSheathingComponent,
        AwningFrillComponent,
        AwningAssemblyComponent,
        AwningSystemsComponent,
        AwningSystemsInfoComponent,
        AwningDriveComponent,
        AwningAccessoriesComponent,
        AwningAddAccessoriesComponent,
        AwningAccessoriesInfoComponent
    ],
    entryComponents: [
        AwningDimensionsComponent,
        AwningColorsComponent,
        AwningSheathingComponent,
        AwningFrillComponent,
        AwningAssemblyComponent,
        AwningSystemsComponent,
        AwningSystemsInfoComponent,
        AwningDriveComponent,
        AwningAccessoriesComponent,
        AwningAddAccessoriesComponent,
        AwningAccessoriesInfoComponent
    ],
    providers: [
        SystemsService,
        DimensionsService,
        AssemblyService,
        ColorsService,
        SheathingService,
        FrillService,
        DriveService,
        AccessoryService
    ],
})
export class ConfiguratorAwningModule {}
