import { Injectable, Inject } from '@angular/core';
import { Reinforcement, Profile } from '@icc/window';
import { ReinforcementsService } from '@icc/common/configurators/reinforcements.service';
import { TranslateService } from '@icc/common/translate.service';
import { StateService } from '@icc/common/state.service';
import { ModalService } from '@icc/helpers';
import {APP_CONFIG, AppConfigFactory} from '@icc/common/config';
import { ReinforcementsListPageComponent } from './reinforcements-list-page/reinforcements-list-page.component';

@Injectable()
export class ReinforcementsModalService {
    constructor(
        private reinforcementsService: ReinforcementsService,
        private translateService: TranslateService,
        private stateService: StateService,
        private modalService: ModalService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
    ) {}

    async openReinforcementsModal(
        profileId: Profile['id'],
        selectedReinforcement: Reinforcement | null,
        currency?: any,
    ): Promise<Reinforcement | false> {
        const reinforcements = (this.reinforcementsService.reinforcements as Reinforcement[]).filter(el =>
            el.profiles && el.profiles.indexOf(Number(profileId)) > -1
        );
        reinforcements.unshift({
            id: null,
            name: this.translateService.instant('INTERFACE|Brak'),
            price: 0
        });
        const modalInstance = this.modalService.open({
            component: 'reinforcementsModal',
            pageComponent: ReinforcementsListPageComponent,
            resolve: {
                reinforcements: () => reinforcements,
                selectedReinforcement: () => selectedReinforcement,
                currency: () => currency || this.config().currency || this.stateService.state.offers[0].doc.currency,
            },
        });

        return modalInstance.result;
    }
}
