import {Glass, InterPaneSpace, GlazingUnitElement, FillingsProducer} from '@icc/window';
import * as $ from 'jquery';

export class  GlazingUnitModalController {

    resolve: {
        glasses: Glass[];
        interPaneSpaces: InterPaneSpace[],
        fillingsProducers: FillingsProducer[],
        glazingUnit: GlazingUnitElement[] | null,
        producerId: number,
    };
    close: ({$value}) => any;
    dismiss: () => any;

    glasses: Glass[];
    interPaneSpaces: InterPaneSpace[];
    glazingCount: number;
    edit: boolean = true;
    outerGlasses: Glass[];
    innerGlasses: Glass[];
    middleGlasses: Glass[];

    constructor(private $timeout, private ScrollbarService) {}

    $onChanges(changes) {
        this.ScrollbarService.update();

        if (this.resolve.producerId == null) {
            this.resolve.producerId = this.resolve.fillingsProducers[0].id;
        }

        if (this.resolve.glazingUnit == null) {
            this.edit = false;
            this.glazingCount = 2;
            this.changeGlazingUnit();
        } else {
            this.glazingCount = Math.ceil(this.resolve.glazingUnit.length / 2);
            this.filterGlasses();
        }
    }

    changeGlazingUnit() {
        this.filterGlasses();
        this.resolve.glazingUnit = [];
        let id = 0;
        let glass;
        for (let i = 1; i <= this.glazingCount; i++) {
            glass = {
                id: id++,
                type: 'glass',
                position: i == 1 ? 'outer' : (i == this.glazingCount ? 'inner' : 'middle')
            };
            glass.value = glass.position == 'outer' ? this.outerGlasses[0] : (glass.position == 'inner' ? this.innerGlasses[0] : this.middleGlasses[0]);
            this.resolve.glazingUnit.push(glass);

            if (i != this.glazingCount) {
                this.resolve.glazingUnit.push({
                    id: id++,
                    type: 'interPaneSpace',
                    value: this.interPaneSpaces[0].id
                });
            }
        }
    }

    filterGlasses() {
        this.glasses = this.resolve.glasses.filter(el => el.fillingsProducerId == this.resolve.producerId);
        this.interPaneSpaces = this.resolve.interPaneSpaces.filter(el => el.fillingsProducerId == this.resolve.producerId);
        this.outerGlasses = this.glasses.filter(el => (this.glazingCount == 2 && el.options.indexOf('outer') > -1) || (this.glazingCount > 2 && el.options.indexOf('outerMoreThan2') > -1));
        this.innerGlasses = this.glasses.filter(el => (this.glazingCount == 2 && el.options.indexOf('inner') > -1) || (this.glazingCount > 2 && el.options.indexOf('innerMoreThan2') > -1));
        this.middleGlasses = this.glasses.filter(el => el.options.indexOf('middle') > -1);
    }

    closeModal(save = false) {
        if (save) {
            this.close({$value: [this.resolve.glazingUnit, this.resolve.producerId]});
        } else {
            this.close({$value: false});
        }
    }
}
