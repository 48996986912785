export default function PopularLayoutsFactory($filter, $q, DatabaseManager, machine, UserService, Core) {
    'ngInject';

    var user = UserService.get();

    var factory = {
        addLayout,
        updateLayout,
        removeLayout,
        list,
        get
    };

    return factory;

    function Layout(param) {
        this.id              = param.id || null;
        this.tmp_id          = param.tmp_id || 'tmp_layout_' + Core.generateUUID();
        this.user_id         = user.id;
        this.configuration   = param.configuration || {};
        this.code            = param.code || '';
        this.counter         = typeof param.counter == 'undefined' ? 0 : param.counter;
        this.deleted         = false;
        this.synced          = false;
        this.image           = param.image || null;
    }

    function addLayout(param) {
        var deferred = $q.defer();
        var newLayout = new Layout({
            id           : param.id,
            tmp_id       : param.tmp_id,
            user_id      : user.id,
            configuration: param.configuration,
            code         : param.code,
            counter      : param.counter,
            image        : param.image
        });
        DatabaseManager.get('PopularLayout').create(newLayout).then((res) => {
            DatabaseManager.get('PopularLayout').compact();
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    function updateLayout(_id, object) {
        var deferred = $q.defer();
        DatabaseManager.get('PopularLayout').update(object, {internalId: _id, machine}).then((res) => {
            DatabaseManager.get('PopularLayout').compact();
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    function removeLayout(_id, object) {
        var deferred = $q.defer();
        DatabaseManager.get('PopularLayout').remove(object).then((res) => {
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    function list() {
        var deferred = $q.defer();
        DatabaseManager.get('PopularLayout').getAll().then((clients) => {
            deferred.resolve(clients);
        });
        return deferred.promise;
    }

    function get(_id) {
        var deferred = $q.defer();
        DatabaseManager.get('PopularLayout').get(_id).then((client) => {
            deferred.resolve(client);
        });
        return deferred.promise;
    }
}
