import { NgModule } from '@angular/core';
import { WindowSillsService } from './window-sills/window-sills.service';
import { ComplementaryGoodsService } from './complementary-goods.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [ComplementaryGoodsService, WindowSillsService],
})
export class ComplementaryGoodsModule {}
