import {NgModule}           from '@angular/core';
import {CommonModule}       from '@angular/common';
import { SashesService } from './sashes.service';
import { SashesTypesService } from './sashes-types.service';

@NgModule({
  imports:      [ CommonModule ],
  exports:      [ ],
  providers:    [
    SashesService,
    SashesTypesService,
  ]
})
export class SashesModule { }
