<icc-list
    [searchable]="true"
    [items]="models"
    [tabs]="modelCategories"
    [itemTemplate]="'itemImgTitle'"
    itemImgHeight="high"
    [selected]="selectedFilling"
    (select)="selectModel($event)"
    (showInfo)="showInfo($event)"
></icc-list>
