import { ProfilesService } from '@icc/common/profiles.service';
import {ProfilesPriceService} from '@icc/common/profiles-price.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import {Profile, ProfileType} from '@icc/window';

export class SlatsModalController {

    resolve: {
        profiles: Profile[],
        profilesPrices: any[],
        selectedProfile: Profile,
        conf: WindowActiveConfiguration,
        type: ProfileType,
        currency,
        rollerId
    };
    close: ({$value: Profile}) => any;
    dismiss: () => any;

    constructor(private ProfilesService: ProfilesService, private ProfilesPriceService: ProfilesPriceService, private $timeout, public IccConfig, private ScrollbarService) {
    }

    $onChanges(changes) {
        this.ScrollbarService.update();
    }

    getProfilePrice(profile) {
        const shutter = this.resolve.conf.RollerShutter.shutters.find(s => s.id === this.resolve.rollerId);
        if (profile && shutter) {
            const width = shutter.realWidth;
            let profilePrice = this.resolve.profilesPrices[profile.id];
            if (profilePrice && profilePrice.default && profilePrice.default['roller_slat']) {
                profilePrice = profilePrice.default['roller_slat']
                    .map((p, index) => {
                        p.id = index;
                        return p;
                    })
                    .filter(p => this.resolve.conf.RollerShutter.colors.endslat.groups && this.resolve.conf.RollerShutter.colors.endslat.groups.map(Number).indexOf(Number(p.colorGroup)) > -1);
                profilePrice = profilePrice.length ? profilePrice[0] : null;
            }

            if (profilePrice && profilePrice['price_length'] != null) {
                return width * profilePrice['price_length'] / 1000;
            }

            return false;
        }
    }

    selectProfile(profile) {
        this.close({$value: profile});
    }

    closeModal() {
        this.close({$value: false});
    }

    refresh() {
       this.ScrollbarService.update();
    }
}
