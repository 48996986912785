import {logger} from '@icc/legacy/app/helpers';

export function redirectOnChangedUser() {
    if (window.location.pathname === '/') {
        logger.error('Przekierowanie na / po refresh');
        window.location.href = '/';
    } else if (
        window.location.pathname.indexOf('/app/login') === -1
        && window.location.pathname.indexOf('/app/logout') === -1
    ) {
        logger.error('Przekierowanie na /app po refresh');
        if (window.cordova) {
            window.location.hash = '#!/app/sync';
        } else {
            window.location.href = '/app/sync';
        }
    }
}
