import { garageDoorQuery } from './garage-door.selectors';
import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { GarageDoorPartialState } from './garage-door.reducer';
import { ConfiguratorsDataService } from '@icc/common';
import { map, filter } from 'rxjs/operators';
import { SetGarageDoorSystem, SetGarageDoorMechanism, SetGarageDoorGuidance, SetGarageDoorPanel, SetGarageDoorPanelStructure, SetModalListType, SetGarageDoorDimensions, SetGarageDoorWicketDoorFrameProfilesColor, SetGarageDoorWicketDoorHandleColor, SetGarageDoorWicketDoorDirection, SetGarageDoorDriveType, SetGarageDoorDriveSide, SetGarageDoorDrive, SetGarageDoorPanelInternalColor, SetGarageDoorPanelColor, SetGarageDoorPlinthPanelStructure, SetGarageDoorPlinthPanel, SetGarageDoorPanelInternalStructure, SetGarageDoorPanelsData, SetGarageDoorWicketDoor, SetGarageDoorWindowsGlobalType, SetGarageDoorWindowFramesGlobalOuterColor, SetGarageDoorGlazingPanelsGlobalColor, SetGarageDoorVentilationGratesGlobalOuterColor, SetGarageDoorPanelInternal, SetGarageDoorWindowsGlobalGlazing, SetGarageDoorGlazingPanelsGlobalGlazing, SetGarageDoorGlazingsGlobalImposts } from './garage-door.actions';
import { PanelsData } from '@icc/common/configurations/parts/garage_door/PanelsData';

@Injectable()
export class GarageDoorFacade {
    availableDefaults$ = this.configuratorsDataService.data$.pipe(
        map(data => data.garageDoorDefaults)
    )
    availableSystems$ = this.configuratorsDataService.data$.pipe(
        map(data => data.garageDoorLines)
    );

    availablePanelsAmounts$ = this.configuratorsDataService.data$.pipe(
        map(data => data.garageDoorPanelsAmounts)
    );

    availableMechanisms$ = this.configuratorsDataService.data$.pipe(
        map(data => data.garageDoorMechanisms)
    );

    availableSizeRanges$ = this.configuratorsDataService.data$.pipe(
        map(data => data.sizeRanges)
    );

    availableGuidances$ = this.configuratorsDataService.data$.pipe(
        map(data => data.garageDoorGuides)
    );

    availablePanels$ = this.configuratorsDataService.data$.pipe(
        map(data => data.garageDoorPanels)
    );

    availablePanelStructures$ = this.configuratorsDataService.data$.pipe(
        map(data => data.garageDoorPanelStructures)
    );

    availableInserts$ = this.configuratorsDataService.data$.pipe(
        map(data => data.garageDoorInserts)
    )

    availableInsertGlazings$ = this.configuratorsDataService.data$.pipe(
        map(data => data.garageDoorInsertGlazings)
    )

    availableColors$ = this.configuratorsDataService.data$.pipe(
        map(data => data.garageDoorColors)
    );

    availableColorGroups$ = this.configuratorsDataService.data$.pipe(
        map(data => data.garageDoorColorGroups)
    );

    availableDrives$ = this.configuratorsDataService.data$.pipe(
        map(data => data.garageDoorDrives)
    )

    availableWicketDoors$ = this.configuratorsDataService.data$.pipe(
        map(data => data.garageDoorWicketDoors)
    )

    selectedSystem$ = this.store.pipe(
        select(garageDoorQuery.getSystem)
    )

    selectedDimensions$ = this.store.pipe(
        select(garageDoorQuery.getDimensions)
    )

    selectedMechanism$ = this.store.pipe(
        select(garageDoorQuery.getMechanism)
    )

    selectedGuidance$ = this.store.pipe(
        select(garageDoorQuery.getGuidance)
    )

    selectedPanelsData$ = this.store.pipe(
        select(garageDoorQuery.getPanelsData)
    )

    selectedGlazingsGlobalImposts$ = this.store.pipe(
        select(garageDoorQuery.getGlazingsGlobalImposts)
    )

    selectedWindowsGlobalType$ = this.store.pipe(
        select(garageDoorQuery.getWindowsGlobalType)
    )

    selectedWindowsGlobalGlazing$ = this.store.pipe(
        select(garageDoorQuery.getWindowsGlobalGlazing)
    )

    selectedGlazingPanelsGlobalGlazing$ = this.store.pipe(
        select(garageDoorQuery.getGlazingPanelsGlobalGlazing)
    )

    selectedWindowFramesGlobalOuterColor$ = this.store.pipe(
        select(garageDoorQuery.getWindowFramesGlobalOuterColor)
    )

    selectedGlazingPanelsGlobalColor$ = this.store.pipe(
        select(garageDoorQuery.getGlazingPanelsGlobalColor)
    )

    selectedVentilationGratesGlobalOuterColor$ = this.store.pipe(
        select(garageDoorQuery.getVentilationGratesGlobalOuterColor)
    )

    selectedPanel$ = this.store.pipe(
        select(garageDoorQuery.getPanel)
    )

    selectedPanelInternal$ = this.store.pipe(
        select(garageDoorQuery.getPanelInternal)
    )

    selectedPanelStructure$ = this.store.pipe(
        select(garageDoorQuery.getPanelStructure)
    )

    selectedPanelInternalStructure$ = this.store.pipe(
        select(garageDoorQuery.getPanelInternalStructure)
    )

    selectedPlinthPanel$ = this.store.pipe(
        select(garageDoorQuery.getPlinthPanel)
    )

    selectedPlinthPanelStructure$ = this.store.pipe(
        select(garageDoorQuery.getPlinthPanelStructure)
    )

    selectedPanelColor$ = this.store.pipe(
        select(garageDoorQuery.getPanelColor)
    )

    selectedPanelInternalColor$ = this.store.pipe(
        select(garageDoorQuery.getPanelInternalColor)
    )

    selectedDrive$ = this.store.pipe(
        select(garageDoorQuery.getDrive)
    )

    selectedDriveType$ = this.store.pipe(
        select(garageDoorQuery.getDriveType)
    )

    selectedDriveSide$ = this.store.pipe(
        select(garageDoorQuery.getDriveSide)
    )

    selectedWicketDoor$ = this.store.pipe(
        select(garageDoorQuery.getWicketDoor)
    )

    selectedWicketDoorDirection$ = this.store.pipe(
        select(garageDoorQuery.getWicketDoorDirection)
    )

    selectedWicketDoorHandleColor$ = this.store.pipe(
        select(garageDoorQuery.getWicketDoorHandleColor)
    )

    selectedWicketDoorFrameProfilesColor$ = this.store.pipe(
        select(garageDoorQuery.getWicketDoorFrameProfilesColor)
    )

    constructor(
        private store: Store<GarageDoorPartialState>,
        private configuratorsDataService: ConfiguratorsDataService,
    ) {}

    setModalListType(type: any) {
        this.store.dispatch(new SetModalListType(type));
    }

    setSystem(system: any) {
        this.store.dispatch(new SetGarageDoorSystem(system));
    }

    setDimensions(dimensions: any) {
        this.store.dispatch(new SetGarageDoorDimensions(dimensions));
    }

    setMechanism(mechanism: any) {
        this.store.dispatch(new SetGarageDoorMechanism(mechanism));
    }

    setGuidance(guidance: any) {
        this.store.dispatch(new SetGarageDoorGuidance(guidance));
    }

    setPanelsData(panelsData: PanelsData) {
        this.store.dispatch(new SetGarageDoorPanelsData(panelsData));
    }

    setGlazingsGlobalImposts(imposts: any) {
        this.store.dispatch(new SetGarageDoorGlazingsGlobalImposts(imposts));
    }

    setWindowsGlobalType(windowType: any) {
        this.store.dispatch(new SetGarageDoorWindowsGlobalType(windowType));
    }

    setWindowsGlobalGlazing(glazing: any) {
        this.store.dispatch(new SetGarageDoorWindowsGlobalGlazing(glazing));
    }

    setGlazingPanelsGlobalGlazing(glazing: any) {
        this.store.dispatch(new SetGarageDoorGlazingPanelsGlobalGlazing(glazing));
    }

    setWindowFramesGlobalOuterColor(color: any) {
        this.store.dispatch(new SetGarageDoorWindowFramesGlobalOuterColor(color));
    }

    setGlazingPanelsGlobalColor(color: any) {
        this.store.dispatch(new SetGarageDoorGlazingPanelsGlobalColor(color));
    }

    setVentilationGratesGlobalOuterColor(color: any) {
        this.store.dispatch(new SetGarageDoorVentilationGratesGlobalOuterColor(color));
    }

    setPanel(panel: any) {
        this.store.dispatch(new SetGarageDoorPanel(panel));
    }

    setPanelInternal(panel: any) {
        this.store.dispatch(new SetGarageDoorPanelInternal(panel));
    }

    setPanelStructure(panelStructure: any) {
        this.store.dispatch(new SetGarageDoorPanelStructure(panelStructure));
    }

    setPanelInternalStructure(panelInternalStructure: any) {
        this.store.dispatch(new SetGarageDoorPanelInternalStructure(panelInternalStructure));
    }

    setPlinthPanel(panel: any) {
        this.store.dispatch(new SetGarageDoorPlinthPanel(panel));
    }

    setPlinthPanelStructure(plinthPanelStructure: any) {
        this.store.dispatch(new SetGarageDoorPlinthPanelStructure(plinthPanelStructure));
    }

    setPanelColor(panelColor: any) {
        this.store.dispatch(new SetGarageDoorPanelColor(panelColor));
    }

    setPanelInternalColor(panelInternalColor:any) {
        this.store.dispatch(new SetGarageDoorPanelInternalColor(panelInternalColor));
    }

    setDrive(drive: any){
        this.store.dispatch(new SetGarageDoorDrive(drive))
    }

    setDriveType(type: any){
        this.store.dispatch(new SetGarageDoorDriveType(type))
    }

    setDriveSide(side: any) {
        this.store.dispatch(new SetGarageDoorDriveSide(side));
    }

    setWicketDoor(wicketDoor: any) {
        this.store.dispatch(new SetGarageDoorWicketDoor(wicketDoor));
    }

    setWicketDoorDirection(direction: any) {
        this.store.dispatch(new SetGarageDoorWicketDoorDirection(direction));
    }

    setWicketDoorHandleColor(color: any) {
        this.store.dispatch(new SetGarageDoorWicketDoorHandleColor(color));
    }

    setWicketDoorFrameProfilesColor(color: any) {
        this.store.dispatch(new SetGarageDoorWicketDoorFrameProfilesColor(color));
    }
}
