import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PLEATEDBLIND_FEATURE_KEY, PleatedBlindState } from './pleated-blind.reducer';
import { PleatedBlindActiveConfiguration } from '@icc/common/configurations/PleatedBlindActiveConfiguration';

// Lookup the 'Pleated Blind' feature state managed by NgRx
const getPleatedBlindState = createFeatureSelector<PleatedBlindState>(PLEATEDBLIND_FEATURE_KEY);

const getPleatedBlindConfiguration = createSelector(
    getPleatedBlindState,
    (state: PleatedBlindState) => {
        return state.configuration;
    }
);

const getSystem = createSelector(
    getPleatedBlindConfiguration,
    (configuration: PleatedBlindActiveConfiguration) => {
        return configuration.System
    }
);

const getVariant = createSelector(
    getPleatedBlindConfiguration,
    (configuration: PleatedBlindActiveConfiguration) => {
        return configuration.variant
    }
)

const getMainFabric = createSelector(
    getPleatedBlindConfiguration,
    (configuration: PleatedBlindActiveConfiguration) => {
        return configuration.mainFabric
    }
)

const getAdditionalFabric = createSelector(
    getPleatedBlindConfiguration,
    (configuration: PleatedBlindActiveConfiguration) => {
        return configuration.additionalFabric
    }
)

const getProfilesColor = createSelector(
    getPleatedBlindConfiguration,
    (configuration: PleatedBlindActiveConfiguration) => {
        return configuration.profilesColor
    }
)

const getShape = createSelector(
    getPleatedBlindConfiguration,
    (configuration: PleatedBlindActiveConfiguration) => {
        return configuration.Shape
    }
)

const getDrive = createSelector(
    getPleatedBlindConfiguration,
    (configuration: PleatedBlindActiveConfiguration) => {
        return configuration.drive
    }
)

const getDriveSide = createSelector(
    getPleatedBlindConfiguration,
    (configuration: PleatedBlindActiveConfiguration) => {
        return configuration.driveSide
    }
)

const getGuidanceType = createSelector(
    getPleatedBlindConfiguration,
    (configuration: PleatedBlindActiveConfiguration) => {
        return configuration.guidanceType
    }
)

const getClosureType = createSelector(
    getPleatedBlindConfiguration,
    (configuration: PleatedBlindActiveConfiguration) => {
        return configuration.closureType
    }
)

export const pleatedBlindQuery = {
    getPleatedBlindConfiguration,
    getSystem,
    getVariant,
    getMainFabric,
    getAdditionalFabric,
    getProfilesColor,
    getShape,
    getDrive,
    getDriveSide,
    getGuidanceType,
    getClosureType
};
