import { TranslateService } from '@icc/common/translate.service';
import { IssuesService } from './../../../shared/src/lib/issues/issues.service';
import { ModalListComponent } from './modal-list/modal-list.component';
import { ModalInsertsComponent } from './modal-inserts/modal-inserts.component';
import { GarageDoorDefaultsService } from './+state/garage-door-defaults.service';
import { GarageDoorFacade } from './+state/garage-door.facade';
import { Injectable, OnDestroy, Inject } from '@angular/core';
import { ModalService } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';
import {
    ConfigurationsService,
    EventBusService,
    core,
    APP_CONFIG,
    AppConfigFactory,
    IssueLevel,
} from '@icc/common';
import { Subscription } from 'rxjs';
import { PanelInsert } from '@icc/common/configurations/parts/garage_door/PanelInsert';
import { Panel, GarageDoorDimensions } from '@icc/common/configurations/parts/garage_door';
import { PanelsData } from '@icc/common/configurations/parts/garage_door/PanelsData';
import { GlazingImpost } from '@icc/common/configurations/parts/garage_door/GlazingImpost';

@Injectable()
export class InsertsService implements OnDestroy {
    private subscriptions: Subscription[] = [];

    allInserts = [];
    allColors = [];
    allInsertGlazings = [];
    selectedSystem;
    selectedPanelsData: PanelsData;
    selectedDimensions: GarageDoorDimensions;
    selectedWicketDoor;
    glazingsGlobalImposts: GlazingImpost[];
    selectedWindowsGlobalType;
    selectedWindowsGlobalGlazing;
    selectedGlazingPanelsGlobalGlazing;
    selectedWindowFramesGlobalOuterColor;
    selectedGlazingPanelsGlobalColor;
    selectedVentilationGratesGlobalOuterColor;

    conf;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private modalService: ModalService,
        private garageDoorFacade: GarageDoorFacade,
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService,
        private defaultsService: GarageDoorDefaultsService,
        private issuesService: IssuesService,
        private translateService: TranslateService
    ) {
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.conf = this.configurationsService.conf.Current;
            if (this.conf.type === 'garage_door') {
                this.subscriptions.push(
                    this.garageDoorFacade.availableInserts$.subscribe((i) => {
                        this.allInserts = i;
                    }),
                    this.garageDoorFacade.availableInsertGlazings$.subscribe((g) => {
                        this.allInsertGlazings = g;
                    }),
                    this.garageDoorFacade.availableColors$.subscribe((c) => {
                        this.allColors = c;
                    }),
                    this.garageDoorFacade.selectedPanelsData$.subscribe((d) => {
                        this.selectedPanelsData = d;
                        if (!(d && d.panels)) {
                            this.resetAllWindowsGlobalValues();
                            this.resetAllGlazingPanelsGlobalValues();
                            this.resetAllVentilationGratesGlobalValues();
                        }
                    }),
                    this.garageDoorFacade.selectedSystem$.subscribe((s) => {
                        this.selectedSystem = s;
                        this.validateGlazingsInWindows();
                        this.validateGlazingsInGlazingPanels();
                    }),
                    this.garageDoorFacade.selectedDimensions$.subscribe((d) => {
                        this.selectedDimensions = d;
                        this.removeAllInsertsInWicketDoorPanels();
                    }),
                    this.garageDoorFacade.selectedWicketDoor$.subscribe((w) => {
                        this.selectedWicketDoor = w;
                    }),
                    this.garageDoorFacade.selectedGlazingsGlobalImposts$.subscribe((amount) => {
                        this.glazingsGlobalImposts = amount;
                    }),
                    this.garageDoorFacade.selectedWindowsGlobalType$.subscribe((type) => {
                        this.selectedWindowsGlobalType = type;
                        this.validateGlazingsInWindows();
                    }),
                    this.garageDoorFacade.selectedWindowsGlobalGlazing$.subscribe((glazing) => {
                        this.selectedWindowsGlobalGlazing = glazing;
                    }),
                    this.garageDoorFacade.selectedGlazingPanelsGlobalGlazing$.subscribe(
                        (glazing) => {
                            this.selectedGlazingPanelsGlobalGlazing = glazing;
                        }
                    ),
                    this.garageDoorFacade.selectedWindowFramesGlobalOuterColor$.subscribe(
                        (color) => {
                            this.selectedWindowFramesGlobalOuterColor = color;
                            this.setAllInsertsColor('window', color, 'outer');
                        }
                    ),
                    this.garageDoorFacade.selectedGlazingPanelsGlobalColor$.subscribe((color) => {
                        this.selectedGlazingPanelsGlobalColor = color;
                        this.setAllInsertsColor('glazing', color);
                    }),
                    this.garageDoorFacade.selectedVentilationGratesGlobalOuterColor$.subscribe(
                        (color) => {
                            this.selectedVentilationGratesGlobalOuterColor = color;
                            this.setAllInsertsColor('ventilation_grate', color, 'outer');
                        }
                    )
                );
            }
        });
    }

    getFilteredInsertsForPanel(insertsType, panel: Panel): PanelInsert[] {
        return (
            this.allInserts.filter((i) => {
                return (
                    (!insertsType || i.type === insertsType) &&
                    !(panel.index === 1 && i.type === 'glazing') &&
                    (!(
                        this.config().IccConfig.Configurators.garage_door.windowsGlobalType &&
                        this.selectedWindowsGlobalType
                    ) ||
                        i.type !== 'window' ||
                        i.id === this.selectedWindowsGlobalType.id)
                );
            }) || []
        );
    }

    getFilteredWindowTypes() {
        return this.allInserts.filter((i) => i.type === 'window') || [];
    }

    getFilteredGlazings(insert: PanelInsert) {
        return (
            (insert &&
                this.selectedSystem &&
                this.allInsertGlazings.filter(
                    (g) =>
                        g.insert_type === insert.type &&
                        g.systems.includes(this.selectedSystem.id) &&
                        g.inserts.includes(String(insert.id))
                )) ||
            []
        );
    }

    getFilteredInsertColors(insert: PanelInsert) {
        return (
            (insert &&
                this.allColors.filter((c) => insert.colors && insert.colors.includes(c.id))) ||
            []
        );
    }

    openInsertsModal(panelIndex) {
        const panel = this.findPanelByIndex(panelIndex);
        const panelInsertsType = this.getPanelInsertsType(panel);
        const filteredInserts = this.getFilteredInsertsForPanel(panelInsertsType, panel);
        this.modalService
            .open({
                pageComponent: ModalInsertsComponent,
                resolve: {
                    items: filteredInserts,
                    modalListTitle: _('GARAGEDOOR|Wybierz typ wypełnienia'),
                    modalListFilesDir: '/files/garagedoorinsert/',
                    itemImgAttributeName: 'img',
                    itemTemplate: 'itemImgTitle',
                },
            })
            .result.then((selectedInsert) => {
                if (selectedInsert) {
                    this.addInsert(selectedInsert, panelIndex);
                    this.validateAndSetAllGlobalInsertColors();
                }
            });
    }

    openGlobalWindowTypesModal() {
        if (this.anyWindowsInGarageDoor()) {
            const currentWindowType = this.selectedWindowsGlobalType;
            const filteredWindowTypes = this.getFilteredWindowTypes();
            this.modalService
                .open({
                    pageComponent: ModalInsertsComponent,
                    resolve: {
                        selectedItemId: (currentWindowType && currentWindowType.id) || null,
                        items: filteredWindowTypes,
                        modalListTitle: _('GARAGEDOOR|Wybierz typ okien'),
                        modalListFilesDir: '/files/garagedoorinsert/',
                        itemImgAttributeName: 'img',
                        itemTemplate: 'itemImgTitle',
                    },
                })
                .result.then((selectedWindowType) => {
                    if (selectedWindowType) {
                        this.validateAndChangeAllWindowsType(selectedWindowType);
                    }
                });
        }
    }

    openGlobalWindowFramesOuterColorModal() {
        if (this.anyWindowsInGarageDoor()) {
            const currentWindowType = this.selectedWindowsGlobalType;
            const filteredColors = this.getFilteredInsertColors(currentWindowType);
            this.modalService
                .open({
                    pageComponent: ModalListComponent,
                    resolve: {
                        selectedItemId:
                            this.selectedWindowFramesGlobalOuterColor &&
                            this.selectedWindowFramesGlobalOuterColor.id,
                        items: filteredColors,
                        modalListTitle: _('GARAGEDOOR|Wybierz kolor ramek okien'),
                        modalListFilesDir: '/files/garagedoorcolor/',
                        itemImgAttributeName: 'color_img',
                    },
                })
                .result.then((selectedColor) => {
                    if (selectedColor) {
                        this.garageDoorFacade.setWindowFramesGlobalOuterColor(selectedColor);
                    }
                });
        }
    }

    openGlobalGlazingPanelsColorModal() {
        if (this.anyGlazingsInGarageDoor()) {
            const currentGlazing = this.getGlobalGlazingPanel();
            const filteredVentilationGrateColors = this.getFilteredInsertColors(currentGlazing);

            this.modalService
                .open({
                    pageComponent: ModalListComponent,
                    resolve: {
                        selectedItemId:
                            currentGlazing &&
                            currentGlazing.outerColor &&
                            currentGlazing.outerColor.id,
                        items: filteredVentilationGrateColors,
                        modalListTitle: _('GARAGEDOOR|Wybierz kolor paneli panoramicznych'),
                        modalListFilesDir: '/files/garagedoorcolor/',
                        itemImgAttributeName: 'color_img',
                    },
                })
                .result.then((selectedColor) => {
                    if (selectedColor) {
                        this.garageDoorFacade.setGlazingPanelsGlobalColor(selectedColor);
                    }
                });
        }
    }

    openGlobalVentilationGratesOuterColorModal() {
        if (this.anyVentilationGratesInGarageDoor()) {
            const currentVentilationGrate = this.getGlobalVentilationGrate();
            const filteredVentilationGrateColors =
                currentVentilationGrate && this.getFilteredInsertColors(currentVentilationGrate);

            this.modalService
                .open({
                    pageComponent: ModalListComponent,
                    resolve: {
                        selectedItemId:
                            currentVentilationGrate &&
                            currentVentilationGrate.outerColor &&
                            currentVentilationGrate.outerColor.id,
                        items: filteredVentilationGrateColors,
                        modalListTitle: _('GARAGEDOOR|Wybierz kolor kratek'),
                        modalListFilesDir: '/files/garagedoorcolor/',
                        itemImgAttributeName: 'color_img',
                    },
                })
                .result.then((selectedColor) => {
                    if (selectedColor) {
                        this.garageDoorFacade.setVentilationGratesGlobalOuterColor(selectedColor);
                    }
                });
        }
    }

    addInsert(insert, panelIndex, panelsData = this.getCopyOfSelectedPanelsData()) {
        const panel = this.findPanelByIndex(panelIndex, panelsData);
        const isPanelsHeightValid = this.validatePanelHeight(panel, insert);
        if (isPanelsHeightValid) {
            if (!(insert.type === 'glazing') && this.isWicketDoorInPanel(panelIndex)) {
                this.centerInsertsInPanel(panel);
            } 
            insert = this.setValuesOfNewInsert(panel, insert, this.isWicketDoorInPanel(panelIndex));
            if (!isNaN(insert.x) && insert.x >= 0 && insert.index) {
                panel.panelInsertsType = insert.type;
                panel.panelInserts.push(insert);
                if (this.centerInsertsInPanel(panel)) {
                    if (this.config().IccConfig.Configurators.garage_door.windowsGlobalType) {
                        switch (insert.type) {
                            case 'window':
                                if (!this.anyWindowsInGarageDoor()) {
                                    this.garageDoorFacade.setWindowsGlobalType(insert);
                                    this.setDefaultWindowFramesGlobalOuterColor(insert);
                                    const defaultGlazing = this.getDefaultWindowsGlobalGlazing(
                                        panelsData
                                    );
                                }
                                this.validateGlazingsInWindows(panelsData);
                                break;
                            case 'glazing':
                                if (!this.anyGlazingsInGarageDoor()) {
                                    if (this.selectedWicketDoor) {
                                        this.validateAndSetGlazingsGlobalImpostsByAmount(
                                            2,
                                            panelsData
                                        );
                                    } else {
                                        this.validateAndSetGlazingsGlobalImpostsByAmount(
                                            0,
                                            panelsData
                                        );
                                    }
                                    this.setDefaultGlazingPanelsGlobalColor(insert);
                                }
                                this.validateGlazingsInGlazingPanels(panelsData);
                                break;
                            case 'ventilation_grate':
                                if (!this.anyVentilationGratesInGarageDoor()) {
                                    this.setDefaultVentilationGratesGlobalOuterColor(insert);
                                }
                        }
                    }
                    this.garageDoorFacade.setPanelsData(panelsData);
                }
            }
        }
    }

    isWicketDoorInPanel(panelIndex): boolean {
        const wicketDoorPanelsIndexes = this.getWicketDoorPanelsIndexes();
        return wicketDoorPanelsIndexes.includes(panelIndex);
    }

    /**
     * @returns indexes of all panels that are within the selected wicket door's height.
     */
    getWicketDoorPanelsIndexes(wicketDoor = this.selectedWicketDoor): number[] {
        const panelsIndexes = [];
        if (wicketDoor && wicketDoor.id) {
            const wicketDoorY = wicketDoor.height + wicketDoor.threshold_height;
            const panelsData = this.getCopyOfSelectedPanelsData();

            let panelsHeight = 0;
            let i = 0;
            while (panelsHeight < wicketDoorY) {
                i++;
                const panel = panelsData.panels.find((p) => p.index == i);
                panelsHeight += Number(panel.panelHeight.height);
                panelsIndexes.push(i);
            }
        }

        return panelsIndexes;
    }

    removeInsertAndCenterRemainingInserts(
        panelIndex,
        panelsData = this.getCopyOfSelectedPanelsData()
    ) {
        const panel = this.findPanelByIndex(panelIndex, panelsData);
        const lastInsertIndex = panel.panelInserts[panel.panelInserts.length - 1].index;
        this.removeInsert(panelIndex, lastInsertIndex, panelsData);

        if (this.centerInsertsInPanel(panel)) {
            this.garageDoorFacade.setPanelsData(panelsData);
        }
    }

    centerInsertsInPanel(panel, wicketDoor = this.selectedWicketDoor) {
        let haveBeenCentered = false;
        if(panel.panelInsertsType === "glazing") return true;
        if (wicketDoor && this.getWicketDoorPanelsIndexes().includes(panel.index)) {
            haveBeenCentered = this.centerInsertsInPanelWithWicketDoor(panel, wicketDoor);
        } else {
            haveBeenCentered = this.centerInsertsInPanelWithoutWicketDoor(panel);
        }
        return haveBeenCentered;
    }

    centerInsertsInPanelWithoutWicketDoor(panel: Panel) {
        const insertsAmount = panel.panelInserts.length;
        if (insertsAmount > 0) {
            const insertWidth = this.getPanelInsertWidth(panel.panelInserts[0]);
            const centeredInsertsXPositions = this.getCenteredInsertsXPositions(
                Number(insertsAmount),
                insertWidth
            );
            const areInsertsPositionsValid = this.validateInsertsXPositions(
                centeredInsertsXPositions,
                insertWidth
            );

            if (areInsertsPositionsValid) {
                let i = 0;
                for (i; i < panel.panelInserts.length; i++) {
                    panel.panelInserts[i].x = centeredInsertsXPositions[i];
                }
                return true;
            }
        }
        return false;
    }

    centerInsertsInPanelWithWicketDoor(panel: Panel, wicketDoor = this.selectedWicketDoor) {
        const insertsAmount = panel.panelInserts && panel.panelInserts.length;
        if (insertsAmount > 0) {
            const insertType = panel.panelInserts[0].type;
            const insertsWidth = this.getPanelInsertWidth(panel.panelInserts[0]);
            const insertsSpacing = this.defaultsService.getDefaultPanelInsertsSpacing();
            const wicketDoorMiddlePosition = wicketDoor.x + wicketDoor.width / 2;

            const sections = this.getWicketDoorInsertsSections(wicketDoor, panel.index, insertType);

            // Check how many inserts can fit into each section
            let leftSectionInsertsAmount = 0;
            let midSectionInsertsAmount = 0;
            let rightSectionInsertsAmount = 0;

            let i = 0;
            for (i; i < insertsAmount; i++) {
                if ((i + 1) * insertsWidth + i * insertsSpacing <= sections.left.width) {
                    leftSectionInsertsAmount++;
                } else {
                    break;
                }
            }

            if (sections.middle && i < insertsAmount) {
                midSectionInsertsAmount++;
                i++;
            }

            for (i; i < insertsAmount; i++) {
                if ((i + 1) * insertsWidth + i * insertsSpacing <= sections.right.width) {
                    rightSectionInsertsAmount++;
                } else {
                    break;
                }
            }

            // Calculate X Positions of inserts for each section
            i = 0;
            for (let j = 0; j < leftSectionInsertsAmount; j++) {
                panel.panelInserts[i].x =
                    sections.left.startX + j * (insertsSpacing + insertsWidth);
                panel.panelInserts[i].index = i + 1;
                i++;
            }

            for (let j = 0; j < midSectionInsertsAmount; j++) {
                panel.panelInserts[i].x = wicketDoorMiddlePosition - insertsWidth / 2;
                panel.panelInserts[i].index = i + 1;
                i++;
            }

            for (let j = 0; j < rightSectionInsertsAmount; j++) {
                panel.panelInserts[i].x =
                    sections.right.startX + j * (insertsSpacing + insertsWidth);
                panel.panelInserts[i].index = i + 1;
                i++;
            }

            return true;
        }
        return false;
    }

    getWicketDoorInsertsSections(
        wicketDoor = this.selectedWicketDoor,
        panelIndex: number,
        insertType: 'window' | 'ventilation_grate' | 'glazing'
    ): { left; right; middle } {
        const sections = { left: null, middle: null, right: null };

        const wicketDoorMiddlePosition = wicketDoor.x + wicketDoor.width / 2;

        const leftSectionStartX = this.defaultsService.getDefaultPanelInsertsOffset();
        const leftSectionEndX =
            wicketDoorMiddlePosition - this.defaultsService.getMinWicketDoorDistanceFromWindow();
        const leftSectionWidth = leftSectionEndX - leftSectionStartX;
        sections.left = {
            startX: leftSectionStartX,
            endX: leftSectionEndX,
            width: leftSectionWidth,
        };

        if (panelIndex === 3 || insertType === 'ventilation_grate') {
            const middleSectionStartX = wicketDoor.x;
            const middleSectionEndX = wicketDoor.x + wicketDoor.width;
            const middleSectionWidth = middleSectionEndX - middleSectionStartX;
            sections.middle = {
                startX: middleSectionStartX,
                endX: middleSectionEndX,
                width: middleSectionWidth,
            };
        }

        const rightSectionStartX =
            wicketDoorMiddlePosition + this.defaultsService.getMinWicketDoorDistanceFromWindow();
        const rightSectionEndX =
            this.selectedDimensions.width - this.defaultsService.getDefaultPanelInsertsOffset();
        const rightSectionWidth = rightSectionEndX - rightSectionStartX;
        sections.right = {
            startX: rightSectionStartX,
            endX: rightSectionEndX,
            width: rightSectionWidth,
        };

        return sections;
    }

    centerInsertsInPanelByIndex(
        panelIndex,
        panelsData = this.getCopyOfSelectedPanelsData()
    ): boolean {
        const panel = this.findPanelByIndex(panelIndex, panelsData);
        if (this.centerInsertsInPanel(panel)) {
            this.garageDoorFacade.setPanelsData(panelsData);
            return true;
        }
        return false;
    }

    getCenteredInsertsXPositions(insertsAmount: number, insertsWidth: number) {
        const currentWidth = this.selectedDimensions.width;
        const allInsertsWidth = insertsAmount * insertsWidth;
        const width = currentWidth - allInsertsWidth;
        const equalSpacing = width / (insertsAmount + 1);

        const xPositions = [];
        let i = 1;
        for (i; i <= insertsAmount; i++) {
            // Round to 0.5
            const xPosition = Math.round((i * equalSpacing + (i - 1) * insertsWidth) * 2) / 2;
            xPositions.push(xPosition);
        }
        return xPositions;
    }

    validateAllPanelsInsertsXPositions(
        panelsData = this.getCopyOfSelectedPanelsData()
    ): { panelIndex: number; valid: boolean }[] {
        const panelsValidation = [];

        panelsData.panels.forEach((p) => {
            const panelValidation = { panelIndex: p.index, valid: false };
            let panelXPositions = [];
            p.panelInserts.forEach((i) => panelXPositions.push(i.x));

            panelValidation.valid =
                !p.panelInserts ||
                !(p.panelInserts.length > 0) ||
                this.validateInsertsXPositions(panelXPositions, p.panelInserts[0].width);

            // Delete one insert if positions are not valid (not enough space) and validate again
            if (!panelValidation.valid) {
                this.removeInsertAndCenterRemainingInserts(p.index, panelsData);
                panelXPositions = [];
                p.panelInserts.forEach((i) => panelXPositions.push(i.x));
                panelValidation.valid =
                    !p.panelInserts ||
                    !(p.panelInserts.length > 0) ||
                    this.validateInsertsXPositions(panelXPositions, p.panelInserts[0].width);
            }

            panelsValidation.push(panelValidation);
        });

        return panelsValidation;
    }

    /** Validates spacings and offsets of the given inserts positions */
    validateInsertsXPositions(xPositions: number[], insertWidth: number): boolean {
        const currentWidth = this.selectedDimensions.width;
        const minInsertsOffset = this.defaultsService.getDefaultPanelInsertsOffset();
        const minInsertsSpacing = this.defaultsService.getDefaultPanelInsertsSpacing();

        const lastInsertIndex = xPositions.length - 1;
        const isFirstInsertValid = xPositions[0] > minInsertsOffset;
        const isLastInsertValid =
            xPositions[lastInsertIndex] + insertWidth <= currentWidth - minInsertsOffset;
        let areMidInsertsValid = true;

        for (let i = 1; i < xPositions.length; i++) {
            if (!(xPositions[i] - (xPositions[i - 1] + insertWidth) >= minInsertsSpacing)) {
                areMidInsertsValid = false;
                break;
            }
        }
        return isFirstInsertValid && areMidInsertsValid && isLastInsertValid;
    }

    setValuesOfNewInsert(panel: Panel, insert: PanelInsert, wicketDoorInPanel = false) {
        insert.x = this.getNewInsertX(
            panel,
            insert.type,
            this.getPanelInsertWidth(insert),
            wicketDoorInPanel
        );
        insert.index = this.getNewInsertIndex(panel.panelInserts);
        insert.width = this.selectedDimensions.width;
        insert.height = this.selectedDimensions.height;
        const impostAmount =
            this.config().IccConfig.Configurators.garage_door.glazingsGlobalImpostAmount &&
            this.glazingsGlobalImposts
                ? this.glazingsGlobalImposts.length
                : 0;
        insert.imposts = this.getGlazingsGlobalImpostsByAmount(impostAmount);
        return insert;
    }

    addSameInsert(panelIndex) {
        const insert = this.getSamePanelInsert(panelIndex);
        if (insert) {
            this.addInsert(insert, panelIndex);
        }
    }

    anyInsertsInPanel(panelIndex): boolean {
        const panel = this.findPanelByIndex(panelIndex);
        return (
            panel &&
            panel.panelInserts &&
            panel.panelInserts.length &&
            panel.panelInserts.length > 0
        );
    }

    getSamePanelInsert(panelIndex) {
        const panel = this.findPanelByIndex(panelIndex);
        return this.anyInsertsInPanel(panelIndex) && panel.panelInserts[0];
    }

    moveInsert(panelIndex, insertIndex: number, difference: number) {
        const newPanelsData = this.getCopyOfSelectedPanelsData();
        const insert = this.findInsertByIndex(panelIndex, insertIndex, newPanelsData);
        insert.x -= difference;

        this.garageDoorFacade.setPanelsData(newPanelsData);
    }

    removeInsert(panelIndex, insertIndex: number, panelsData = this.getCopyOfSelectedPanelsData()) {
        if (panelIndex && insertIndex) {
            const panel = this.findPanelByIndex(panelIndex, panelsData);
            panel.panelInserts = panel.panelInserts.filter((i) => i.index !== insertIndex);
            panel.panelInserts = this.setInsertsNewIndexes(insertIndex, panel.panelInserts);
            if (panel.panelInserts.length === 0) {
                panel.panelInsertsType = null;
            }
            this.garageDoorFacade.setPanelsData(panelsData);
        }
    }

    removeAllInsertsInPanel(panelIndex, panelsData = this.getCopyOfSelectedPanelsData()) {
        const panel = panelsData.panels.find((p) => p.index === panelIndex);
        if (panel && panel.panelInserts.length > 0) {
            panel.panelInserts.forEach((i) => this.removeInsert(panelIndex, i.index, panelsData));
        }
    }

    setInsertsNewIndexes(deletedInsertIndex: number, inserts: PanelInsert[]): PanelInsert[] {
        if (inserts && inserts.length) {
            inserts.forEach((i) => {
                if (i.index > deletedInsertIndex) i.index--;
            });
        }
        return inserts;
    }

    findPanelByIndex(panelIndex, panelsData = this.getCopyOfSelectedPanelsData()): Panel {
        return (
            panelsData && panelsData.panels && panelsData.panels.find((p) => p.index === panelIndex)
        );
    }

    findInsertByIndex(
        panelIndex,
        insertIndex,
        panelsData = this.getCopyOfSelectedPanelsData()
    ): PanelInsert {
        const panel = this.findPanelByIndex(panelIndex, panelsData);
        return panel.panelInserts.find((i) => i.index === insertIndex);
    }

    getNewInsertX(panel: Panel, newInsertType, newInsertWidth, wicketDoorInPanel = false): number {
        if(newInsertType === 'glazing'){
            return 0;
        }else{
            const panelInserts = panel.panelInserts;
            const lastPanelInsert = panelInserts[panelInserts.length - 1];
            const newInsertXPosition = panelInserts.length === 0
                    ? this.defaultsService.getDefaultPanelInsertsOffset()
                    : lastPanelInsert.x +
                    this.defaultsService.getDefaultPanelInsertsSpacing() +
                    this.getPanelInsertWidth(lastPanelInsert);


            if (wicketDoorInPanel) {
                const minWicketDoorDistanceFromWindow = this.defaultsService.getMinWicketDoorDistanceFromWindow();
                const lastInsertWidth = this.getPanelInsertWidth(lastPanelInsert);
                const wicketDoor = core.copy(this.selectedWicketDoor);
                const wicketDoorMiddlePosition = wicketDoor.x + wicketDoor.width / 2;

                const isAnyInsertInMiddle = panelInserts.find(
                    (i) => i.x + this.getPanelInsertWidth(i) / 2 === wicketDoorMiddlePosition
                );
                if (
                    newInsertXPosition + newInsertWidth <=
                    wicketDoorMiddlePosition - minWicketDoorDistanceFromWindow
                ) {
                    // Left side of wicket door
                    return newInsertXPosition;
                } else {
                    // Middle or right side of wicket door
                    let x = wicketDoorMiddlePosition + minWicketDoorDistanceFromWindow;
                    if (lastPanelInsert && lastPanelInsert.x >= x) {
                        x =
                            lastPanelInsert.x +
                            lastInsertWidth +
                            this.defaultsService.getDefaultPanelInsertsSpacing();
                    }
                    return (panel.index === 3 || newInsertType === 'ventilation_grate') &&
                        !isAnyInsertInMiddle
                        ? wicketDoorMiddlePosition - newInsertWidth / 2
                        : this.selectedDimensions.width -
                            this.defaultsService.getDefaultPanelInsertsOffset() >=
                        x + newInsertWidth
                        ? x
                        : NaN;
                }
            } else {
                return newInsertXPosition;
            }
        }
    }

    getNewInsertIndex(panelInserts: PanelInsert[]): number {
        return (panelInserts.length > 0 && panelInserts[panelInserts.length - 1].index + 1) || 1;
    }

    hasSpaceForNewInsert(panel: Panel): boolean {
        let hasSpace = true;
        if (panel.panelInserts.length > 0) {
            const insertsSpacing = this.defaultsService.getDefaultPanelInsertsSpacing();
            const insertsOffset = this.defaultsService.getDefaultPanelInsertsOffset();
            const lastPanelInsert = panel.panelInserts[panel.panelInserts.length - 1];
            const lastPanelInsertWidth = this.getPanelInsertWidth(lastPanelInsert);

            const availableSpace =
                this.selectedDimensions.width - (lastPanelInsert.x + lastPanelInsertWidth);
            const neededSpace = lastPanelInsertWidth + insertsSpacing + insertsOffset;
            if (neededSpace >= availableSpace) {
                hasSpace = false;
            }
        }

        return hasSpace;
    }

    getPanelInsertWidth(insert: PanelInsert): number {
        return insert && Number(insert.shape === 'circle' ? insert.diameter : insert.width);
    }

    getPanelInsertsType(panel: Panel): string {
        return panel && panel.panelInsertsType;
    }

    getPanelInsertsAmount(panel: Panel): number {
        return panel.panelInserts.length;
    }

    validatePanelHeight(panel: Panel, insert?: PanelInsert): boolean {
        // Jeżeli wypełnienie jest szkleniem, to nie ma minimalnej wysokości
        return (
            panel.panelInsertsType === 'glazing' ||
            panel.panelHeight.height >= this.defaultsService.getMinPanelHeightForInserts() ||
            (insert && insert.height < panel.panelHeight.height)
        );
    }

    isPanelInsertGlazing(panelIndex): boolean {
        const panel = this.findPanelByIndex(panelIndex);
        return panel.panelInsertsType === 'glazing' && panel.panelInserts.length === 1;
    }

    incrementGlazingsGlobalImpostAmount(): boolean {
        const newImpostAmount = this.getGlazingPanelsGlobalImpostAmount() + 1;

        if (this.validateAndSetGlazingsGlobalImpostsByAmount(newImpostAmount)) {
            return true;
        }

        return false;
    }

    decrementGlazingsGlobalImpostAmount() {
        const newImpostAmount = this.getGlazingPanelsGlobalImpostAmount() - 1;

        if (this.validateAndSetGlazingsGlobalImpostsByAmount(newImpostAmount)) {
            return true;
        }

        return false;
    }

    addImpostToPanelGlazing(panelIndex) {
        const newPanelsData = this.getCopyOfSelectedPanelsData();
        const panel = this.findPanelByIndex(panelIndex, newPanelsData);
        const panelGlazing = this.getPanelGlazing(panel);

        if (panelGlazing.imposts.length < panelGlazing.max_impost_amount) {
            panelGlazing.imposts.length += 1;
        }
        this.garageDoorFacade.setPanelsData(newPanelsData);
    }

    removeImpostFromPanelGlazing(panelIndex) {
        const newPanelsData = this.getCopyOfSelectedPanelsData();
        const panel = this.findPanelByIndex(panelIndex, newPanelsData);
        const panelGlazing = this.getPanelGlazing(panel);

        panelGlazing.imposts.length -= 1;
        if (panelGlazing.imposts.length < 0) {
            panel.panelInsertsType = null;
            panel.panelInserts = [];
        }
        this.garageDoorFacade.setPanelsData(newPanelsData);
    }

    getPanelGlazing(panel: Panel): PanelInsert {
        return (
            panel &&
            panel.panelInserts &&
            panel.panelInsertsType === 'glazing' &&
            panel.panelInserts[0]
        );
    }

    getCopyOfSelectedPanelsData(): PanelsData {
        return core.copy(this.selectedPanelsData);
    }

    removeGarageDoorInsertsFromPanels(panelIndexes: number[]) {
        const newPanelsData = this.getCopyOfSelectedPanelsData();
        if (newPanelsData && newPanelsData.panels) {
            newPanelsData.panels.forEach((p) => {
                if (panelIndexes.includes(p.index)) {
                    p.panelInserts = [];
                    p.panelInsertsType = null;
                }
            });
            this.garageDoorFacade.setPanelsData(newPanelsData);
        }
    }

    removeAllGarageDoorInserts(type: 'all' | 'window' | 'glazing' | 'ventilation_grate' = 'all') {
        const newPanelsData = this.getCopyOfSelectedPanelsData();
        if (newPanelsData && newPanelsData.panels) {
            newPanelsData.panels.forEach((p) => {
                if (type === 'all') {
                    p.panelInserts = [];
                    p.panelInsertsType = null;
                } else if (type === p.panelInsertsType) {
                    p.panelInserts = [];
                    p.panelInsertsType = null;
                }
            });
            this.garageDoorFacade.setPanelsData(newPanelsData);
        }
    }

    setAllGlazingsImposts(
        imposts: GlazingImpost[],
        panelsData = this.getCopyOfSelectedPanelsData()
    ) {
        if (panelsData && panelsData.panels) {
            panelsData.panels.forEach((p) => {
                if (p.panelInsertsType === 'glazing' && p.panelInserts.length > 0) {
                    p.panelInserts.forEach((i) => {
                        i.imposts = imposts;
                    });
                }
            });
            this.garageDoorFacade.setPanelsData(panelsData);
        }
    }

    validateWindowsInPanelsData(panelsData = this.getCopyOfSelectedPanelsData()) {
        const anyWindowsInPanelsData = this.anyWindowsInGarageDoor(panelsData);
        if (!anyWindowsInPanelsData) {
            this.resetAllWindowsGlobalValues();
        } else {
            this.validateGlazingsInWindows(panelsData);
        }
    }

    validateGlazingsInPanelsData(panelsData = this.getCopyOfSelectedPanelsData()) {
        const anyGlazingsInPanelsData = this.anyGlazingsInGarageDoor(panelsData);
        if (!anyGlazingsInPanelsData) {
            this.resetAllGlazingPanelsGlobalValues();
        } else {
            this.validateGlazingsInGlazingPanels(panelsData);
        }
    }

    getDefaultWindowsGlobalGlazing(panelsData = this.getCopyOfSelectedPanelsData()) {
        const selectedWindow = this.getGlobalWindow(panelsData);
        const filteredGlazings = this.getFilteredGlazings(selectedWindow);
        const defaultGlazing = this.defaultsService.getDefaultWindowsGlazing();
        return filteredGlazings.find((g) => g.id === defaultGlazing.id) || filteredGlazings[0];
    }

    getDefaultGlazingPanelsGlobalGlazing(panelsData = this.getCopyOfSelectedPanelsData()) {
        const selectedGlazingPanel = this.getGlobalGlazingPanel(panelsData);
        const filteredGlazings = this.getFilteredGlazings(selectedGlazingPanel);
        const defaultGlazing = this.defaultsService.getDefaultGlazingPanelsGlazing();
        return filteredGlazings.find((g) => g.id === defaultGlazing.id) || filteredGlazings[0];
    }

    validateGlazingsInWindows(panelsData = this.getCopyOfSelectedPanelsData()) {
        if (this.anyWindowsInGarageDoor(panelsData)) {
            const selectedWindow = this.getGlobalWindow(panelsData);
            const filteredGlazings = this.getFilteredGlazings(selectedWindow);
            const isSelectedGlazingStillAvailable =
                this.selectedWindowsGlobalGlazing &&
                filteredGlazings.find((g) => g.id === this.selectedWindowsGlobalGlazing.id);
            if (!isSelectedGlazingStillAvailable || !selectedWindow.glazing) {
                const newGlazing = filteredGlazings[0];
                this.garageDoorFacade.setWindowsGlobalGlazing(newGlazing);
                if (!newGlazing) {
                    this.registerNoWindowGlazingIssue(this.conf);
                } else {
                    this.issuesService.unregister('no-garage-door-window-glazing', this.conf);
                }
            }
        }
    }

    validateGlazingsInGlazingPanels(panelsData = this.getCopyOfSelectedPanelsData()) {
        if (this.anyGlazingsInGarageDoor()) {
            const selectedGlazingPanel = this.getGlobalGlazingPanel(panelsData);
            const filteredGlazings = this.getFilteredGlazings(selectedGlazingPanel);
            const isSelectedGlazingStillAvailable =
                this.selectedGlazingPanelsGlobalGlazing &&
                filteredGlazings.find((g) => g.id === this.selectedGlazingPanelsGlobalGlazing.id);
            if (!isSelectedGlazingStillAvailable || !selectedGlazingPanel.glazing) {
                const newGlazing = filteredGlazings[0];
                this.garageDoorFacade.setGlazingPanelsGlobalGlazing(newGlazing);
                if (!newGlazing) {
                    this.registerNoGlazingPanelGlazingIssue(this.conf);
                } else {
                    this.issuesService.unregister(
                        'no-garage-door-glazing-panel-glazing',
                        this.conf
                    );
                }
            }
        }
    }

    validateAndChangeAllWindowsType(newWindowType: PanelInsert): boolean {
        const indexOfFirstPanelWithWindows = this.getIndexOfFirstPanelWithWindows();
        const firstPanelWithWindows =
            indexOfFirstPanelWithWindows && this.findPanelByIndex(indexOfFirstPanelWithWindows);
        const currentWindowType = firstPanelWithWindows && firstPanelWithWindows.panelInserts[0];

        if (currentWindowType && newWindowType && currentWindowType.id !== newWindowType.id) {
            const newPanelsData = this.getCopyOfSelectedPanelsData();

            this.setAllWindowsTypeInPanelsData(newPanelsData, currentWindowType, newWindowType);

            if (this.validateAllPanelsInsertsXPositions(newPanelsData).every((p) => p.valid)) {
                this.garageDoorFacade.setPanelsData(newPanelsData);
                this.garageDoorFacade.setWindowsGlobalType(newWindowType);
                return true;
            }
        }

        return false;
    }

    setAllWindowsTypeInPanelsData(
        panelsData: PanelsData,
        currentWindowType: PanelInsert,
        newWindowType: PanelInsert
    ): PanelsData {
        panelsData.panels.forEach((p) => {
            if (p.panelInsertsType === 'window' && p.panelInserts.length > 0) {
                for (let i = 0; i < p.panelInserts.length; i++) {
                    const currentWindowTypeWidth = this.getPanelInsertWidth(currentWindowType);
                    const newWindowTypeWidth = this.getPanelInsertWidth(newWindowType);
                    const xDifference = currentWindowTypeWidth / 2 - newWindowTypeWidth / 2;
                    const newXPosition = (Number(p.panelInserts[i].x) || 0) + xDifference;

                    newWindowType.x = newXPosition;
                    newWindowType.index = p.panelInserts[i].index;
                    p.panelInserts[i] = { ...newWindowType };
                }
            }
        });

        return panelsData;
    }

    setAllInsertsColor(insertType, newColor, side: 'inner' | 'outer' | 'all' = 'all') {
        const newPanelsData = this.getCopyOfSelectedPanelsData();
        if (newPanelsData && newPanelsData.panels) {
            newPanelsData.panels.forEach((p) => {
                if (p.panelInsertsType === insertType && p.panelInserts.length > 0) {
                    for (let i = 0; i < p.panelInserts.length; i++) {
                        if (['outer', 'all'].includes(side))
                            p.panelInserts[i].outerColor = newColor;
                        if (['inner', 'all'].includes(side)) {
                            p.panelInserts[i].innerColor = newColor;
                        }
                    }
                }
            });

            this.garageDoorFacade.setPanelsData(newPanelsData);
        }
    }

    getIndexOfFirstPanelWithWindows(panelsData = this.getCopyOfSelectedPanelsData()): number {
        const firstPanelWithWindows =
            panelsData &&
            panelsData.panels &&
            panelsData.panels.find(
                (p) => p.panelInsertsType === 'window' && p.panelInserts.length > 0
            );
        return firstPanelWithWindows && firstPanelWithWindows.index;
    }

    anyWindowsInGarageDoor(panelsData = this.getCopyOfSelectedPanelsData()): boolean {
        const firstPanelWithWindows =
            panelsData && this.getIndexOfFirstPanelWithWindows(panelsData);
        return firstPanelWithWindows ? true : false;
    }

    isPanelFirstPanelWithGlazing(panelIndex) {
        const firstPanelWithGlazingIndex = this.getIndexOfFirstPanelWithGlazing();

        return firstPanelWithGlazingIndex && firstPanelWithGlazingIndex === panelIndex;
    }

    getIndexOfFirstPanelWithGlazing(panelsData = this.getCopyOfSelectedPanelsData()): number {
        const firstPanelWithGlazing =
            panelsData &&
            panelsData.panels &&
            panelsData.panels.find(
                (p) => p.panelInsertsType === 'glazing' && p.panelInserts.length > 0
            );
        return firstPanelWithGlazing && firstPanelWithGlazing.index;
    }

    anyGlazingsInGarageDoor(panelsData = this.getCopyOfSelectedPanelsData()): boolean {
        const firstPanelWithGlazing =
            panelsData && this.getIndexOfFirstPanelWithGlazing(panelsData);
        return firstPanelWithGlazing ? true : false;
    }

    getIndexOfFirstPanelWithVentilationGrates(
        panelsData = this.getCopyOfSelectedPanelsData()
    ): number {
        const firstPanelWithVentilationGrates =
            panelsData &&
            panelsData.panels &&
            panelsData.panels.find(
                (p) => p.panelInsertsType === 'ventilation_grate' && p.panelInserts.length > 0
            );
        return firstPanelWithVentilationGrates && firstPanelWithVentilationGrates.index;
    }

    anyVentilationGratesInGarageDoor(panelsData = this.getCopyOfSelectedPanelsData()): boolean {
        const firstPanelWithVentilationGrates =
            panelsData && this.getIndexOfFirstPanelWithVentilationGrates(panelsData);
        return firstPanelWithVentilationGrates ? true : false;
    }

    getGlobalWindow(panelsData = this.getCopyOfSelectedPanelsData()): PanelInsert {
        const indexOfFirstPanelWithWindows = this.getIndexOfFirstPanelWithWindows(panelsData);
        const firstPanelWithWindows = this.findPanelByIndex(
            indexOfFirstPanelWithWindows,
            panelsData
        );
        return firstPanelWithWindows && firstPanelWithWindows.panelInserts[0];
    }

    getGlobalGlazingPanel(panelsData = this.getCopyOfSelectedPanelsData()): PanelInsert {
        const indexOfFirstPanelWithGlazing = this.getIndexOfFirstPanelWithGlazing(panelsData);
        const firstPanelWithGlazing = this.findPanelByIndex(
            indexOfFirstPanelWithGlazing,
            panelsData
        );
        return firstPanelWithGlazing && firstPanelWithGlazing.panelInserts[0];
    }

    getGlobalVentilationGrate(): PanelInsert {
        const indexOfFirstPanelWithVentilationGrates = this.getIndexOfFirstPanelWithVentilationGrates();
        const firstPanelWithVentilationGrates = this.findPanelByIndex(
            indexOfFirstPanelWithVentilationGrates
        );
        return firstPanelWithVentilationGrates && firstPanelWithVentilationGrates.panelInserts[0];
    }

    validateAndSetAllGlobalInsertColors() {
        this.setAllInsertsColor('window', this.selectedWindowFramesGlobalOuterColor, 'outer');
        this.setAllInsertsColor('glazing', this.selectedGlazingPanelsGlobalColor);
        this.setAllInsertsColor(
            'ventilation_grate',
            this.selectedVentilationGratesGlobalOuterColor,
            'outer'
        );
    }

    setDefaultWindowFramesGlobalOuterColor(insert: PanelInsert) {
        const filteredColors = this.getFilteredInsertColors(insert);
        const defaultColor = this.defaultsService.getDefaultWindowFrameOuterColor();
        const color =
            (defaultColor && filteredColors.find((c) => defaultColor.id == c.id)) ||
            filteredColors[0];
        this.garageDoorFacade.setWindowFramesGlobalOuterColor(color);
    }

    setDefaultGlazingPanelsGlobalColor(insert: PanelInsert) {
        const filteredColors = this.getFilteredInsertColors(insert);
        const defaultColor = this.defaultsService.getDefaultGlazingPanelsColor();
        const color =
            (defaultColor && filteredColors.find((c) => defaultColor.id == c.id)) ||
            filteredColors[0];
        this.garageDoorFacade.setGlazingPanelsGlobalColor(color);
    }

    setDefaultVentilationGratesGlobalOuterColor(insert: PanelInsert) {
        const filteredColors = this.getFilteredInsertColors(insert);
        const defaultColor = this.defaultsService.getDefaultVentilationGrateOuterColor();
        const color =
            (defaultColor && filteredColors.find((c) => defaultColor.id == c.id)) ||
            filteredColors[0];
        this.garageDoorFacade.setVentilationGratesGlobalOuterColor(color);
    }

    resetAllWindowsGlobalValues() {
        this.garageDoorFacade.setWindowsGlobalType(null);
        this.garageDoorFacade.setWindowsGlobalGlazing(null);
        this.garageDoorFacade.setWindowFramesGlobalOuterColor(null);
    }

    resetAllGlazingPanelsGlobalValues() {
        this.garageDoorFacade.setGlazingPanelsGlobalGlazing(null);
        this.garageDoorFacade.setGlazingsGlobalImposts(null);
        this.garageDoorFacade.setGlazingPanelsGlobalColor(null);
    }

    resetAllVentilationGratesGlobalValues() {
        this.garageDoorFacade.setVentilationGratesGlobalOuterColor(null);
    }

    getMaxImpostAmount(panelsData = this.getCopyOfSelectedPanelsData()): number {
        const glazing = this.getGlobalGlazingPanel(panelsData);
        return (glazing && glazing.max_impost_amount) || 0;
    }

    getGlazingPanelsGlobalImpostAmount(panelsData = this.getCopyOfSelectedPanelsData()): number {
        const glazing = this.getGlobalGlazingPanel(panelsData);
        return glazing && glazing.imposts && glazing.imposts.length;
    }

    getMinImpostAmount(): number {
        return this.selectedWicketDoor ? 2 : 0;
    }

    validateAndSetGlazingsGlobalImpostsByAmount(
        impostAmount = this.getGlazingPanelsGlobalImpostAmount(),
        panelsData = this.getCopyOfSelectedPanelsData()
    ): boolean {
        const maxImpostAmount = this.getMaxGlazingsImpostAmount();
        const minImpostAmount = this.getMinImpostAmount();

        if (
            this.getGlobalGlazingPanel(panelsData) &&
            impostAmount <= maxImpostAmount &&
            impostAmount >= minImpostAmount
        ) {
            const imposts = this.getGlazingsGlobalImpostsByAmount(impostAmount);
            this.garageDoorFacade.setGlazingsGlobalImposts(imposts);
            this.setAllGlazingsImposts(imposts, panelsData);
            return true;
        }
        return false;
    }

    createGlazingPanelSections(wicketDoor) {
        const impostsWidth = this.defaultsService.getDefaultGlazingPanelImpostWidth();
        const middleSection = {
            startX: wicketDoor.x-impostsWidth,
            width: wicketDoor.width + (impostsWidth*2),
            endX: wicketDoor.x + wicketDoor.width + impostsWidth
        }
        return {
            left: {
                startX: impostsWidth,
                width: middleSection.startX-impostsWidth,
                endX: middleSection.startX,
            },
            right: {
                startX: middleSection.endX,
                width: this.selectedDimensions.width- impostsWidth - (middleSection.endX),
                endX: this.selectedDimensions.width- impostsWidth,
            },
        };
    }

    assignImpostsToGlazingPanelSections(
        impostAmount: number,
        wicketDoor = this.selectedWicketDoor
    ) {
        const impostsInSections = {
            left: 0,
            right: 0,
        };
        let sectionKey: 'left' | 'right' = 'left';
        const maxGlazingsImpostAmountInSections = this.getMaxGlazingsImpostAmountInSections(
            wicketDoor
        );
        function setNewSectionKey(i = 1) {
            sectionKey = sectionKey==='left' ? 'right' : 'left';
            if (
                impostsInSections[sectionKey] >= maxGlazingsImpostAmountInSections[sectionKey] &&
                i <= 3
            ) {
                i ++;
                setNewSectionKey(i);
            }
        }
        for (let i = 1; i <= impostAmount; i++) {
            impostsInSections[sectionKey]++;
            setNewSectionKey();
        }
        return impostsInSections;
    }

    createImpostInSections(glazingPanelSections, impostsInGlazingPanelSections) {
        const impostsWidth = this.defaultsService.getDefaultGlazingPanelImpostWidth();
        const imposts = [];
        for (const sectionKey in glazingPanelSections) {
            if (Object.prototype.hasOwnProperty.call(glazingPanelSections, sectionKey)) {
                const section = glazingPanelSections[sectionKey];
                if (section) {
                    const impostAmountInSection = impostsInGlazingPanelSections[sectionKey];
                    const spacing = section.width / (impostAmountInSection + 1);
                    for (let i = 1; i <= impostAmountInSection; i++) {
                        const x = (section.startX + spacing * i) - Math.round(impostsWidth/2);
                        imposts.push({ x, width: impostsWidth, index: null });
                    }
                }
            }
        }
        return imposts;
    }

    getGlazingsGlobalImpostsByAmount(
        impostAmount: number,
        wicketDoor = this.selectedWicketDoor
    ): GlazingImpost[] {
        let imposts = [];
        const impostsWidth = this.defaultsService.getDefaultGlazingPanelImpostWidth();
        if (wicketDoor) {
            const glazingPanelSections = this.createGlazingPanelSections(wicketDoor);
            imposts.push({
                x: glazingPanelSections.left.endX,
                width: impostsWidth,
                index: null,
            });
            imposts.push({
                x: glazingPanelSections.right.startX-impostsWidth,
                width: impostsWidth,
                index: null,
            });
            const impostsInGlazingPanelSections = this.assignImpostsToGlazingPanelSections(
                impostAmount - 2,
                wicketDoor
            );
            imposts = imposts.concat(
                this.createImpostInSections(glazingPanelSections, impostsInGlazingPanelSections)
            );
            // Równe odległości między impostami globalnie - musimy wyciągnąć ilość wolnego miejsca, czyli od całej bramy odjąć szerokość furtki
            // Przewidzieć minimalną szerokość impostów do maksymalnej ilości impostów
            // LEFT GLAZING PANEL SECTION IMPOSTS
            // RIGHT GLAZING PANEL SECTION IMPOSTS
        } else {
            const impostXPosition =
                this.selectedDimensions.width / (impostAmount + 1);
            for (let i = 1; i <= impostAmount; i++) {
                imposts.push({
                    x: (impostXPosition * i)-(impostsWidth/2),
                    width: impostsWidth,
                    index: i,
                });
            }
        }

        return imposts;
    }

    getMaxGlazingsImpostAmount(wicketDoor = this.selectedWicketDoor): number {
        let maxGlazingsImpostAmount = 0;
        const minSpacing = this.defaultsService.getMinGlazingPanelImpostsSpacing();
        if (wicketDoor) {
            maxGlazingsImpostAmount = 2;
            const glazingPanelSections = this.createGlazingPanelSections(wicketDoor);
            for (const sectionKey in glazingPanelSections) {
                if (Object.prototype.hasOwnProperty.call(glazingPanelSections, sectionKey)) {
                    const section = glazingPanelSections[sectionKey];
                    maxGlazingsImpostAmount += Math.floor(section.width / minSpacing);
                }
            }
        } else {
            maxGlazingsImpostAmount = Math.floor(this.selectedDimensions.width / minSpacing);
        }
        return maxGlazingsImpostAmount;
    }

    getMaxGlazingsImpostAmountInSections(wicketDoor): { left: number; right: number } {
        if (wicketDoor) {
            const maxGlazingsImpostAmountInSections = {
                left: 0,
                right: 0,
            };
            const minSpacing = this.defaultsService.getMinGlazingPanelImpostsSpacing();
            const glazingPanelSections = this.createGlazingPanelSections(wicketDoor);
            for (const sectionKey in glazingPanelSections) {
                if (Object.prototype.hasOwnProperty.call(glazingPanelSections, sectionKey)) {
                    const section = glazingPanelSections[sectionKey];
                    maxGlazingsImpostAmountInSections[sectionKey] += Math.floor(
                        section.width / minSpacing
                    );
                }
            }
            return maxGlazingsImpostAmountInSections;
        } else {
            return null;
        }
    }

    setAllWindowsGlazings(glazing, panelsData = this.getCopyOfSelectedPanelsData()) {
        if (panelsData && panelsData.panels) {
            panelsData.panels.forEach((p) => {
                if (p.panelInsertsType === 'window') {
                    p.panelInserts.forEach((i) => {
                        i.glazing = glazing;
                    });
                }
            });
            this.garageDoorFacade.setPanelsData(panelsData);
        }
    }

    setAllGlazingPanelsGlazings(glazing, panelsData = this.getCopyOfSelectedPanelsData()) {
        if (panelsData && panelsData.panels) {
            panelsData.panels.forEach((p) => {
                if (p.panelInsertsType === 'glazing') {
                    p.panelInserts.forEach((i) => {
                        i.glazing = glazing;
                    });
                }
            });
            this.garageDoorFacade.setPanelsData(panelsData);
        }
    }

    setWindowsGlobalGlazing(glazingId) {
        const selectedGlobalWindow = this.getGlobalWindow();
        const filteredGlazings = this.getFilteredGlazings(selectedGlobalWindow);
        const glazing = filteredGlazings.find((g) => g.id === glazingId) || null;

        this.garageDoorFacade.setWindowsGlobalGlazing(glazing);
    }

    setGlazingPanelsGlobalGlazing(glazingId) {
        const selectedGlobalGlazing = this.getGlobalGlazingPanel();
        const filteredGlazings = this.getFilteredGlazings(selectedGlobalGlazing);
        const glazing = filteredGlazings.find((g) => g.id === glazingId);

        this.garageDoorFacade.setGlazingPanelsGlobalGlazing(glazing);
    }

    registerNoWindowGlazingIssue(conf) {
        this.issuesService.simpleRegister(
            'no-garage-door-window-glazing',
            'Brak pasujących oszkleń dla wybranych okien',
            this.translateService.instant(
                'CONFIGURATOR|Nie znaleziono odpowiednich oszkleń dla ustawionych okien'
            ),
            conf,
            {
                logLevel: IssueLevel.NONE,
            }
        );
    }

    registerNoGlazingPanelGlazingIssue(conf) {
        this.issuesService.simpleRegister(
            'no-garage-door-glazing-panel-glazing',
            'Brak pasujących oszkleń dla wybranych paneli panoramicznych',
            this.translateService.instant(
                'CONFIGURATOR|Nie znaleziono odpowiednich oszkleń dla ustawionych paneli panoramicznych'
            ),
            conf,
            {
                logLevel: IssueLevel.NONE,
            }
        );
    }

    removeAllInsertsInWicketDoorPanels() {
        this.getWicketDoorPanelsIndexes().forEach((i) => this.removeAllInsertsInPanel(i));
    }

    ngOnDestroy() {
        this.subscriptions.map((el) => el.unsubscribe());
    }
}
