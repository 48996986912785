import { Common } from '../Common';
import { core } from '../Core';
import { logger } from '../helpers';

export default function UnitM2Filter(UserService, IccConfig) {
    'ngInject';

    /**
     * Filtr do konwersji wymarów.
     * @param  {number} data           Liczba do konwersji
     * @param  {number} hasunit        1 lub 0 - czy do wymiaru dołączany ma być symbol wymiaru (mm, ″)
     * @param  {number} inmm           Czy do wymiaru innego niż w mm dołączyć informacje o wymiarze w jednostkach mm?
     * @param  {string} dimension_unit Docelowa jednostka, przy braku bierze domyślną jednostkę użytkownika / rynku
     * @return {string}                Skonwertowany wymiar.
     */
    return (data, hasunit = 0, inmm = 0, dimension_unit = '', round = 2) => {
        const Core = core;
        const dimensionUnit = dimension_unit || UserService.get().dimension_unit;
        const unit = {
            type: dimensionUnit === 'mm' ? 0 : 1,
            unit: dimensionUnit,
            separator: dimensionUnit === 'mm' ? ',' : '.',
        };
        const inchToMeter =
            (IccConfig.Configurators.inchToMilimeter
                * IccConfig.Configurators.inchToMilimeter)
            / (1000 * 1000);

        if (Common.isString(data)) {
            data = +data;
        }
        if (!Common.isNumber(data) || isNaN(data) || !Common.isDefined(data)) {
            return 'NaN';
        }

        let unitext = '';
        if (hasunit) {
            if (unit.type === 0) {
                unitext = 'm²';
            } else {
                unitext = 'inch²';
            }
        }

        // milimetry, zwróć po prostu - nie dodawaj nawet drugiego wymiaru, bo po co
        // w przypadku metrów kwadratowych zaokrąglaj wynik do dwóch miejsc po przecinku
        if (unit.type === 0) {
            return Core.formatNumber(data, round, 100, ' ', unit.separator) + ' ' + unitext;
            // do konwertowanych jednostek dodaj wymiar
        } else if (inmm) {
            return (
                Core.formatNumber(data / inchToMeter, round, 100, ' ', unit.separator)
                + ' '
                + unitext
                + ' ('
                + data
                + ' m²)'
            );
        } else {
            return (
                Core.formatNumber(data / inchToMeter, round, 100, ' ', unit.separator) + ' ' + unitext
            );
        }
    };
}
