import { DisableControlDirective } from './disable-control/disable-control.directive';
import { DefaultImageDirective } from './default-image/default-image.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { MaterialModule } from './material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { DimensionsLegendComponent } from './dimensions-legend/dimensions-legend.component';
import { ListComponent } from './list/list.component';
import { SelectBoxComponent } from './select-box/select-box.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TabsComponent } from './tabs/tabs.component';
import { TranslateModule } from '@ngx-translate/core';
import { IccDimensionValueAccessor } from './dimension-input/dimension-input';
import { DetailsComponent } from './details/details.component';
import { SliderComponent } from './slider/slider.component';
import { SharedModule } from '@icc/configurator/shared';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        VirtualScrollerModule,
        TranslateModule,
        SharedModule
    ],
    exports: [
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        VirtualScrollerModule,
        DimensionsLegendComponent,
        ListComponent,
        SelectBoxComponent,
        ConfirmDialogComponent,
        TabsComponent,
        IccDimensionValueAccessor,
        DetailsComponent,
        DefaultImageDirective,
        SliderComponent,
        DisableControlDirective
    ],
    declarations: [
        ButtonComponent,
        DimensionsLegendComponent,
        ListComponent,
        SelectBoxComponent,
        ConfirmDialogComponent,
        TabsComponent,
        IccDimensionValueAccessor,
        DetailsComponent,
        DefaultImageDirective,
        SliderComponent,
        DisableControlDirective,
    ],
    entryComponents: [ConfirmDialogComponent, TabsComponent]
})
export class ConfiguratorUiModule {}
