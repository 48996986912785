import { _ } from '../translations/translate-helper';

export const enum OfferStatus {
    DuringCreation = 99,
    New = 1,
    ForValuation = 2,
    Valued = 3,
    Canceled = 4,
    AcceptedForProcessing = 5,
    ToBeAnalysed = 6,
    Analysed = 7,
    PreparedForValuation = 8,
    Ordered = 9,
}

export const OfferStatuses = [
    OfferStatus.DuringCreation,
    OfferStatus.New,
    OfferStatus.ForValuation,
    OfferStatus.Valued,
    OfferStatus.Canceled,
    OfferStatus.AcceptedForProcessing,
    OfferStatus.ToBeAnalysed,
    OfferStatus.Analysed,
    OfferStatus.PreparedForValuation,
    OfferStatus.Ordered,
];
export const OfferStatusDict = {
    [OfferStatus.DuringCreation]: _('OFFER|W trakcie tworzenia'),
    [OfferStatus.New]: _('OFFER|Nowe'),
    [OfferStatus.ForValuation]: _('OFFER|W trakcie wyceny'),
    [OfferStatus.Valued]: _('OFFER|Wycenione'),
    [OfferStatus.Canceled]: _('OFFER|Anulowane'),
    [OfferStatus.AcceptedForProcessing]: _('OFFER|Przyjęta'),
    [OfferStatus.ToBeAnalysed]: _('OFFER|Do analizy'),
    [OfferStatus.Analysed]: _('OFFER|Przeanalizowane'),
    [OfferStatus.PreparedForValuation]: _('OFFER|Przygotowane do wyceny'),
    [OfferStatus.Ordered]: _('OFFER|Zamówione')
}

export const enum OrderStatus {
    New = 1,
    Accepted = 2,
    AwaitingAdvance = 5,
    AcceptedForProcessing = 3,
    Approved = 4,
    Completed = 6,
    Canceled = 8,
    ToBeConfirmedAfterRevision = 9,
    Revision = 10,
    Annexed = 11,
    Processing = 12,
}

export const OrderStatuses = [
    OrderStatus.New,
    OrderStatus.Accepted,
    OrderStatus.AwaitingAdvance,
    OrderStatus.AcceptedForProcessing,
    OrderStatus.Approved,
    OrderStatus.Completed,
    OrderStatus.Canceled,
    OrderStatus.ToBeConfirmedAfterRevision,
    OrderStatus.Revision,
    OrderStatus.Annexed,
    OrderStatus.Processing,
];

export const OrderStatusDict = {
    [OrderStatus.New]: _('OFFER|Nowe'),
    [OrderStatus.Accepted]: _('OFFER|Przyjęte (do weryfikacji)'),
    [OrderStatus.AwaitingAdvance]: _('OFFER|Oczekuje na zaliczkę'),
    [OrderStatus.AcceptedForProcessing]: _('OFFER|Przyjęte do realizacji'),
    [OrderStatus.Approved]: _('OFFER|Zatwierdzone'),
    [OrderStatus.Completed]: _('OFFER|Zrealizowane'),
    [OrderStatus.Canceled]: _('OFFER|Anulowane'),
    [OrderStatus.ToBeConfirmedAfterRevision]: _('OFFER|Do potwierdzenia po korekcie'),
    [OrderStatus.Revision]: _('OFFER|Korekta'),
    [OrderStatus.Annexed]: _('OFFER|Aneksowane'),
    [OrderStatus.Processing]: _('OFFER|W trakcie realizacji')
}

export const enum ShopOrderStatus {
    DuringCreation = 99,
    DuringCreationSummary = 0,
    New = 1,
    UnpaidMeasurement = 2,
    PaidMeasurement = 3,
    ApprovedMeasurement = 4,
    Unpaid = 5,
    Paid = 6,
    Completed = 7,
    Canceled = 8,
    ToBeConfirmedAfterRevision = 9,
    Revision = 10,
    Annexed = 11,
}

export const ShopOrderStatuses = [
    ShopOrderStatus.DuringCreation,
    ShopOrderStatus.DuringCreationSummary,
    ShopOrderStatus.New,
    ShopOrderStatus.UnpaidMeasurement,
    ShopOrderStatus.PaidMeasurement,
    ShopOrderStatus.ApprovedMeasurement,
    ShopOrderStatus.Unpaid,
    ShopOrderStatus.Paid,
    ShopOrderStatus.Completed,
    ShopOrderStatus.Canceled,
    ShopOrderStatus.ToBeConfirmedAfterRevision,
    ShopOrderStatus.Revision,
    ShopOrderStatus.Annexed,
];

export const ShopOrderStatusDict = {
    [ShopOrderStatus.DuringCreation]: _('OFFER|W trakcie tworzenia'),
    [ShopOrderStatus.DuringCreationSummary]: _('OFFER|W trakcie tworzenia'),
    [ShopOrderStatus.New]: _('OFFER|Nowe'),
    [ShopOrderStatus.UnpaidMeasurement]: _('OFFER|Zamówiony pomiar - nieopłacony'),
    [ShopOrderStatus.PaidMeasurement]: _('OFFER|Zamówiony pomiar - opłacony'),
    [ShopOrderStatus.ApprovedMeasurement]: _('OFFER|Pomiar potwierdzony'),
    [ShopOrderStatus.Unpaid]: _('OFFER|Zamówienie nieopłacone'),
    [ShopOrderStatus.Paid]: _('OFFER|Zamówienie opłacone'),
    [ShopOrderStatus.Completed]: _('OFFER|Zrealizowane'),
    [ShopOrderStatus.Canceled]: _('OFFER|Anulowane'),
    [ShopOrderStatus.ToBeConfirmedAfterRevision]: _('OFFER|Do potwierdzenia po korekcie'),
    [ShopOrderStatus.Revision]: _('OFFER|Korekta'),
    [ShopOrderStatus.Annexed]: _('OFFER|Aneksowane'),
};

export const enum OfferDealerStatus {
    InDealer = 0,
    New = 1,
    InClient = 2,
    Ordered = 3,
    Canceled = 4,
}