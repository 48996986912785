import {NgModule} from '@angular/core';
import {TranslatePipe} from './translate.pipe';

@NgModule({
    imports: [],
    exports: [TranslatePipe],
    declarations: [TranslatePipe],
    providers: [],
})
export class TranslatePipeModule { }
