import angular from 'angular';

import AccessoryCtrl from './AccessoryCtrl';
import AccessoryFactory from './AccessoryFactory';
import AssemblyCtrl from './AssemblyCtrl';
import AssemblyFactory from './AssemblyFactory';
import ColorsCtrl from './ColorsCtrl';
import ColorsFactory from './ColorsFactory';
import DimensionsCtrl from './DimensionsCtrl';
import DimensionsFactory from './DimensionsFactory';
import DriveCtrl from './DriveCtrl';
import DriveFactory from './DriveFactory';
import FrillCtrl from './FrillCtrl';
import FrillFactory from './FrillFactory';
import ModalFabricStylesCtrl from './ModalFabricStylesCtrl';
import SheathingCtrl from './SheathingCtrl';
import SheathingFactory from './SheathingFactory';
import SystemsCtrl from './SystemsCtrl';
import SystemsFactory from './SystemsFactory';

export default angular.module('icc.configurator.steps.awning', [])
    .controller('AwningAccessoryCtrl', AccessoryCtrl)
    .controller('AwningAssemblyCtrl', AssemblyCtrl)
    .controller('AwningColorsCtrl', ColorsCtrl)
    .controller('AwningDimensionsCtrl', DimensionsCtrl)
    .controller('AwningDriveCtrl', DriveCtrl)
    .controller('AwningFrillCtrl', FrillCtrl)
    .controller('AwningSheathingCtrl', SheathingCtrl)
    .controller('AwningSystemsCtrl', SystemsCtrl)
    .controller('ModalFabricStylesCtrl', ModalFabricStylesCtrl)
    .factory('AwningAccessoryFactory', AccessoryFactory)
    .factory('AwningAssemblyFactory', AssemblyFactory)
    .factory('AwningColorsFactory', ColorsFactory)
    .factory('AwningDimensionsFactory', DimensionsFactory)
    .factory('AwningDriveFactory', DriveFactory)
    .factory('AwningFrillFactory', FrillFactory)
    .factory('AwningSheathingFactory', SheathingFactory)
    .factory('AwningSystemsFactory', SystemsFactory)
    .name;
