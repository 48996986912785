import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, isObject, isString } from '@icc/helpers';
import {
    Common,
    ConfigurationsService,
    APP_CONFIG,
    AppConfigFactory,
    TranslateService,
} from '@icc/common';
import { PriceService, PriceSegment, PricePart } from '@icc/price';
import { core } from '@icc/common/Core';
import { PriceDictionaryService } from 'libs/price/src/lib/price-dictionary.service';
import { Subscription } from 'rxjs';
import { _ } from '../translation-helper';
import { SharedFacade } from '../+state/shared.facade';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/**
 * Construct a type with the properties of T except for those in type K.
 */
type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

interface PriceSegmentDetails extends Omit<PriceSegment, 'baseValue' | 'value'> {
    name: string;
    noPrice: boolean;
    type: string;
    baseValue: number | null | '—';
    value: number | null | '—';
}

interface PricePartDetails extends Omit<PricePart, 'value'> {
    name: string;
    type: string;
    valueData: any;
    percentData: any;
    noPrice: boolean;
    value: number | null | '—';
}

@Component({
    selector: 'icc-price-details',
    templateUrl: './price-details.component.html',
    styleUrls: ['./price-details.component.scss'],
})
export class PriceDetailsComponent extends PageComponent implements OnInit {
    public title = _('INTERFACE|Szczegóły wyceny');

    humanPriceElems = [];
    priceElemsString = JSON.stringify(this.configurationsService.conf.Current.PriceElems);
    isObject = Common.isObject;
    isArray = Common.isArray;
    priceDetails = this.config().IccConfig.Configurators.showPriceDetails;
    segments: PriceSegmentDetails[] = [];
    parts: PricePartDetails[] = [];
    page = 'segments';
    conf = this.configurationsService.conf.Current;
    currency: any = null;
    hiddenPrice = false;

    beforePromotions = false;
    hasAccess: boolean | null = null;
    password = '';
    incorrectPassword = false;

    public options = [
        {
            title: _('INTERFACE|Przed promocjami'),
            callback: () => {
                this.beforePromotions = true;
                this.updateSegments();
            },
            icon: {
                ligature: 'percent',
            },
            show: () => !this.beforePromotions,
        },
        {
            title: _('INTERFACE|Po promocjach'),
            callback: () => {
                this.beforePromotions = false;
                this.updateSegments();
            },
            icon: {
                ligature: 'percent',
            },
            show: () => this.beforePromotions,
        },
    ];

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        private priceService: PriceService,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private sharedFacade: SharedFacade,
        private translateService: TranslateService,
        private priceDictionaryService: PriceDictionaryService,
        private http: HttpClient,
    ) {
        super();
        this.conf = this.conf || this.configurationsService.conf.Current;
    }

    ngOnInit() {
        this.hiddenPrice = this.config().preset === 'b2c' && this.config().hidePricesInB2cConfigurator;
        this.humanPriceElems = JSON.parse(this.priceElemsString);

        this.subscriptions.push(this.sharedFacade.currency$.subscribe(currency => {
            this.currency = currency;
        }));

        if (this.config().EnvConfig?.configurator?.priceDetailsPassword) {
            if (sessionStorage.getItem('configuratorPriceDetails')) {
                this.checkAccess(sessionStorage.getItem('configuratorPriceDetails') ?? '');
            } else {
                this.hasAccess = false;
            }
        } else {
            this.hasAccess = true;
            this.updateSegments();
        }
    }

    async checkAccess(savedPassword?: string) {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = String(urlParams.get('key'));
        const result = await this.http
            .post<{status: 'ok' | 'error'}>(
                (this.config().EnvConfig?.remoteHost || window.location.origin) + '/api/configurator/check',
                {
                    password: savedPassword || core.sha1(this.password)
                },
                {
                    headers: new HttpHeaders({
                        key: myParam,
                    }),
                }
            )
            .toPromise();

        if (result.status === 'ok') {
            if (this.password) {
                sessionStorage.setItem('configuratorPriceDetails', core.sha1(this.password));
            }
            this.password = '';
            this.incorrectPassword = false;
            this.updateSegments();
            this.hasAccess = true;
        } else {
            this.hasAccess = false;
            this.password = '';
            this.incorrectPassword = true;
        }
    }

    updateSegments() {
        const collator = new Intl.Collator();
        const confPriceSegments = this.beforePromotions ? this.conf.PriceSegmentsBeforePromotions : this.conf.PriceSegments;
        this.segments = core
            .copy(confPriceSegments)
            .filter(seg => seg)
            .map(seg => {
                if (this.hiddenPrice) {
                    delete seg.data.panelPrice;
                    delete seg.data.glazingPrice;
                }
                const segmentDetails: PriceSegmentDetails = {
                    ...seg,
                    name: this.getSegmentName(seg),
                    noPrice: this.hiddenPrice,
                    type: this.priceDictionaryService.locale(seg.type),
                    data: this.priceDictionaryService.locale(seg.data),
                    to: this.priceDictionaryService.locale(seg.to),
                };

                if (Common.isArray(segmentDetails.to)) {
                    segmentDetails.to = segmentDetails.to.map(t => {
                        if (Common.isObject(t)) {
                            return this.describeObject(t);
                        }
                        return t;
                    });
                }
                if (isNaN(parseFloat(segmentDetails.baseValue as string))) {
                    segmentDetails.baseValue = '—';
                    segmentDetails.noPrice = true;
                }
                if (isNaN(parseFloat(segmentDetails.value as string))) {
                    segmentDetails.value = '—';
                    segmentDetails.noPrice = true;
                }
                return segmentDetails;
            })
            .sort((seg1, seg2) => {
                if (!seg1 || !seg2) {
                    return 0;
                }
                if (seg1.valueType === seg2.valueType) {
                    return collator.compare(seg1.type, seg2.type);
                } else if (seg1.valueType == 'value' && seg2.valueType == 'percent') {
                    return -1;
                } else if (seg1.valueType == 'value' && seg2.valueType == 'multiplier') {
                    return -1;
                } else if (seg2.valueType == 'value' && seg1.valueType == 'percent') {
                    return 1;
                } else if (seg2.valueType == 'value' && seg1.valueType == 'multiplier') {
                    return 1;
                } else if (seg2.valueType == 'percent' && seg1.valueType == 'multiplier') {
                    return 1;
                } else {
                    return 0;
                }
            });

        this.parts = core
            .copy(this.beforePromotions ? this.conf.PricePartsBeforePromotions : this.conf.PriceParts)
            .filter(part => part && part.value !== 0)
            .map(part => {
                const segValue = core.copy(
                    confPriceSegments.filter(seg => seg && seg.id === part.valueId)[0]
                );
                const segPercent = part.percentId
                    ? core.copy(
                        confPriceSegments.filter(seg => seg && seg.id === part.percentId)[0]
                    )
                    : null;
                let partValueType = `${this.priceDictionaryService.locale(segValue.type)}`;
                const partValueName = `#${this.getSegmentName(segValue) || partValueType}`;
                partValueType = '#' + partValueType;
                const percentSymbol = segPercent && segPercent.valueType === 'percent' ? '%' : '*';
                let partPercentType = segPercent ? `${this.priceDictionaryService.locale(segPercent.type)}` : '';
                const partPercentName = segPercent
                    ? `${percentSymbol}${this.getSegmentName(segPercent) || partPercentType}`
                    : '';
                partPercentType = percentSymbol + partPercentType;

                if (this.hiddenPrice) {
                    delete segValue.data.glazingPrice;
                    delete segValue.data.panelPrice;
                }

                const partDetails: PricePartDetails = {
                    ...part,
                    name: segPercent ? `${partValueName} × ${partPercentName}` : `${partValueName}`,
                    type: segPercent ? `${partValueType} × ${partPercentType}` : `${partValueType}`,
                    basePercent: segPercent && segPercent.valueType === 'percent'
                        ? this.percentFormat(part.basePercent) + '%'
                        : core.round10(part.basePercent, -4),
                    valueData: this.priceDictionaryService.locale(segValue.data),
                    percentData: segPercent ? this.priceDictionaryService.locale(segPercent.data) : {},
                    noPrice: this.hiddenPrice,
                };

                if (isNaN(Number(partDetails.value))) {
                    partDetails.value = '—';
                    partDetails.noPrice = true;
                }
                return partDetails;
            })
            .sort((part1, part2) => {
                if (!part1 || !part2) {
                    return 0;
                }
                const seg1Value = core.copy(
                    confPriceSegments.filter(seg => seg && seg.id === part1.valueId)[0]
                );
                const seg1Percent = part1.percentId
                    ? core.copy(
                        confPriceSegments.filter(
                            seg => seg && seg.id === part1.percentId
                        )[0]
                    )
                    : null;
                const seg2Value = core.copy(
                    confPriceSegments.filter(seg => seg && seg.id === part2.valueId)[0]
                );
                const seg2Percent = part2.percentId
                    ? core.copy(
                        confPriceSegments.filter(
                            seg => seg && seg.id === part2.percentId
                        )[0]
                    )
                    : null;
                if (seg1Value.id === seg2Value.id) {
                    if (seg1Percent == null) {
                        return -1;
                    } else if (seg2Percent == null) {
                        return 1;
                    } else if (seg1Percent.valueType === seg2Percent.valueType) {
                        return collator.compare(seg1Percent.type, seg2Percent.type);
                    } else if (seg1Percent.valueType == 'value'
                        && seg2Percent.valueType == 'percent') {
                        return -1;
                    } else if (seg1Percent.valueType == 'value'
                        && seg2Percent.valueType == 'multiplier') {
                        return -1;
                    } else if (seg2Percent.valueType == 'value'
                        && seg1Percent.valueType == 'percent') {
                        return 1;
                    } else if (seg2Percent.valueType == 'value'
                        && seg1Percent.valueType == 'multiplier') {
                        return 1;
                    } else if (seg2Percent.valueType == 'percent'
                        && seg1Percent.valueType == 'multiplier') {
                        return -1;
                    } else {
                        return 0;
                    }
                } else {
                    if (seg1Value.valueType === seg2Value.valueType) {
                        return collator.compare(seg1Value.type, seg2Value.type);
                    } else if (seg1Value.valueType == 'value' && seg2Value.valueType == 'percent') {
                        return -1;
                    } else if (seg1Value.valueType == 'value'
                        && seg2Value.valueType == 'multiplier') {
                        return -1;
                    } else if (seg2Value.valueType == 'value' && seg1Value.valueType == 'percent') {
                        return 1;
                    } else if (seg2Value.valueType == 'value'
                        && seg1Value.valueType == 'multiplier') {
                        return 1;
                    } else if (seg2Value.valueType == 'percent'
                        && seg1Value.valueType == 'multiplier') {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    getSegmentName(seg: PriceSegment) {
        let segmentName = null;
        if (Common.isDefined(seg.data.name)) {
            segmentName = seg.data.name;
        } else if (
            Common.isDefined(seg.data.width)
            && Common.isDefined(seg.data.height)
            && Common.isDefined(seg.data.typesSymbol)
        ) {
            segmentName =
                seg.data.typesSymbol.join(', ') + ', ' + seg.data.width + '×' + seg.data.height;
        } else if (Common.isDefined(seg.data.width) && Common.isDefined(seg.data.height)) {
            segmentName = seg.data.width + '×' + seg.data.height;
        } else if (Common.isDefined(seg.data.length)) {
            segmentName = seg.data.length;
        } else if (Common.isDefined(seg.data.shape)) {
            segmentName = seg.data.shape;
        }

        return segmentName;
    }



    describeObject(obj: any) {
        let description = '';
        let index = 0;
        for (const key in obj) {
            if (index > 0) {
                description += this.translateService.instant('INTERFACE|i');
            }
            description += ` ${key} ${this.translateService.instant('INTERFACE|jest')} ${
                obj[key]
            } `;
            index++;
        }
        return description;
    }

    /**
     * Zamienia ułamki na procenty
     *
     * @param {number} percent Ułamek
     * @returns {number} Procent
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    percentConvert(percent: string | number) {
        if (!isNaN(Number(percent))) {
            return core.round10(Number(percent) * 100 - 100, -3);
        }
        return percent;
    }

    percentFormat(percent: string | number | null) {
        if (!isNaN(Number(percent))) {
            return core.round10(Number(percent) * 100, -3);
        }
        return percent;
    }

    close() {
        this.sharedFacade.closePage();
    }
}
