import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Categories } from './categories.interface';

@Injectable({
    providedIn: 'root',
})
export class AccessoriesListPageStoreService {
    private selectedCategory$ = new BehaviorSubject<Categories>(null);
    selectedCategoryObs$ = this.selectedCategory$.asObservable();

    emitSelectedCategory(value: Categories) {
        this.selectedCategory$.next(value);
    }

    getCategories(id: number, parentCategories) {
        const categoryIds = [];
        let index = 0;
        let isIdAvailable = true;

        categoryIds[0] = id;
        do {
            const categoryId = categoryIds[index];
            if (!categoryIds[index]) {
                isIdAvailable = false;
            }
            if (isIdAvailable) {

                parentCategories.forEach((category: any) => {
                    if (Number(category.parent_id) === Number(categoryId)) {
                        categoryIds.push(category.id);
                    }
                });

                index += 1;
            }
        } while (isIdAvailable);

        return categoryIds;
    }
}
