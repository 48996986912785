export default function StringToNumberDirective() {
    'ngInject';

    return {
        require: 'ngModel',
        link   : (scope, element, attrs, ngModel) => {
            ngModel.$formatters.push(value => value * 1);
        }
    };
}
