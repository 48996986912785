import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';

import { CoupledWindowActiveConfiguration } from './CoupledWindowActiveConfiguration';
import { WindowConfiguration } from './WindowConfiguration';
import { CouplingActive, SideProfileActive, Profile, WindowParameters } from '@icc/window';
import { Accessory } from './parts/common';
import { RollerShutter } from './parts/roller_shutter';
import { WindowInCoupling, RollerShutterInCoupling } from './parts/coupled_window';
import { Sill } from './parts/complementary_goods';
import { changesInVersion2 } from './converters/coupled_window/to-version-2';
import { core } from '../Core';

export class CoupledWindowConfiguration implements Configuration {
    $version = 2;
    type: 'coupled_window' = 'coupled_window';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscountsInCurrency: number | null = null;
    priceBeforePromotions: number | null = null;
    priceGross: number | null = null;
    priceGrossAfterDiscounts: number | null = null;
    priceGrossBeforePromotions: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceSegmentsBeforePromotions: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    pricePartsBeforePromotions: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    title = '';
    description = '';
    attachments: any[] = [];
    deletedAttachments: any[] = [];
    valid: {} = {};
    parameters: WindowParameters = {
        weight: null,
    };
    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    height: number = 1500;
    width: number = 1500;
    windows: WindowInCoupling[] = [];
    couplings: CouplingActive[] = [];
    sideProfiles: SideProfileActive[] = [];
    sideAccessories: Accessory[] = [];
    rollerShutters: RollerShutterInCoupling[] = [];
    windowSills: Sill[] = [];

    usedProfiles: Profile[] = [];

    static converters = {
        2: changesInVersion2,
    };

    constructor(
        configuration?,
        dataRequiredToUpdate?: any,
        conversion = true,
        configuratorsDataService?,
        colorsDefaultsService?,
        private langCode = configuration?.langCode || 'pl',
    ) {
        if (CoupledWindowConfiguration.is(configuration) && conversion) {
            this.runConversion(
                configuration,
                Object.assign(dataRequiredToUpdate, {
                    langCode: this.langCode,
                })
            );
        } else {
            if (CoupledWindowActiveConfiguration.is(configuration)) {
                this.name = configuration.Name;
                this.price = configuration.Price;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.priceAfterDiscountsInCurrency = configuration.PriceAfterDiscountsInCurrency || configuration.Price;
                this.priceGross = configuration.PriceGross || configuration.Price;
                this.priceGrossAfterDiscounts =
                    configuration.PriceGrossAfterDiscounts || configuration.Price;
                this.quantity = configuration.Quantity;
                this.priceSegments = configuration.PriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.priceSegmentsBeforePromotions = configuration.PriceSegmentsBeforePromotions;
                this.priceParts = configuration.PriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.pricePartsBeforePromotions = configuration.PricePartsBeforePromotions;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = configuration.Title;
                this.description = configuration.Description;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;
                this.attributes = configuration.attributes;

                this.parameters = configuration.parameters;

                this.windows = configuration.windows;
                this.height = configuration.height;
                this.width = configuration.width;
                this.rollerShutters = configuration.rollerShutters;

                this.couplings = configuration.couplings;
                this.sideProfiles = configuration.sideProfiles;
                this.windowSills = configuration.windowSills;
                this.usedProfiles = configuration.usedProfiles;
            }
        }
    }

    public static is(configuration): configuration is CoupledWindowConfiguration {
        return configuration instanceof CoupledWindowConfiguration || configuration.$version;
    }

    protected runConversion(configuration, dataRequiredToUpdate: any) {
        let updatedConfiguration = core.copy(configuration);

        for (let version = configuration.$version + 1; version <= this.$version; version++) {
            updatedConfiguration.$version = version;
            if (CoupledWindowConfiguration.converters[version] && typeof CoupledWindowConfiguration.converters[version] === 'function') {
                updatedConfiguration = CoupledWindowConfiguration.converters[version](
                    updatedConfiguration,
                    dataRequiredToUpdate
                );
            }
        }

        (Object as any)
            .entries(updatedConfiguration)
            .forEach(([key, value]) => {
                this[key] = value;
            });
    }
}

export type ICoupledWindowConfiguration = Pick<CoupledWindowConfiguration, keyof CoupledWindowConfiguration>;
