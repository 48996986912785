<h2 class="modal-title" mat-dialog-title>
    <span>{{ 'INTERFACE|Przygotowanie wydruku' | translate }} <span *ngIf="configurationShortcode"> {{ 'INTERFACE|dla konfiguracji' | translate }} <b>{{configurationShortcode}}</b></span></span>
</h2>
<mat-dialog-content>
    <mat-checkbox [(ngModel)]="showDimensions" class="enable-client-info-checkbox">{{ 'INTERFACE|Prezentuj wymiary rysunku' | translate }}</mat-checkbox><br>
    <mat-checkbox *ngIf="!hiddenPrice" [(ngModel)]="clientInfoFormEnabled" class="enable-client-info-checkbox">{{ 'INTERFACE|Oferta z danymi klienta i rabatem' | translate }}</mat-checkbox>
    <mat-checkbox *ngIf="hiddenPrice" [(ngModel)]="clientInfoFormEnabled" class="enable-client-info-checkbox">{{ 'INTERFACE|Oferta z danymi klienta' | translate }}</mat-checkbox>
    <div [class.client-info-form--disabled]="!clientInfoFormEnabled" class="client-info-form">
        <mat-card class="mat-elevation-z4 offer-info-section">
            <mat-card-title>{{ 'INTERFACE|Dane oferty' | translate }}</mat-card-title>
            <mat-card-content class="offer-info-section__fields">
                <mat-form-field appearance="outline" style="max-width: 100%;">
                    <mat-label>{{ 'INTERFACE|Czas ważności' | translate }}</mat-label>
                    <input [(ngModel)]="offerDurationInDays" [disabled]="!clientInfoFormEnabled" matInput>
                    <mat-hint>{{ 'INTERFACE|Czas ważności oferty w dniach' | translate }}</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="comment-field">
                    <mat-label>{{ 'INTERFACE|Uwagi' | translate }}</mat-label>
                    <textarea name="comment" [(ngModel)]="comment" [disabled]="!clientInfoFormEnabled" class="comment-field__comment-textarea" matInput #commentInput rows="5"></textarea>
                    <mat-hint align="end">{{commentInput.value.length}} / 300</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="custom-construction-name"
                    *ngIf="config().IccConfig.Configurators.configurationQuantityWithButtonsAndCustomName">
                    <mat-label>{{ 'INTERFACE|Nazwa' | translate }}</mat-label>
                    <textarea class="custom-construction-name__textarea"
                        [(ngModel)]="customConstructionName"
                        [disabled]="!clientInfoFormEnabled"
                        matInput
                        #customConstructionNameInput
                        rows="2">
                    </textarea>
                    <mat-hint align="end">{{customConstructionNameInput.value.length}} / 300</mat-hint>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z4 client-info-section">
            <mat-card-title>{{ 'CLIENT|Dane klienta' | translate }}</mat-card-title>
            <mat-card-content class="client-info-section__fields">
                <mat-form-field appearance="outline" style="max-width: 100%;">
                    <mat-label>{{ 'CLIENT|Imię' | translate }}</mat-label>
                    <input [(ngModel)]="name" [disabled]="!clientInfoFormEnabled" matInput>
                </mat-form-field>
                <mat-form-field appearance="outline" style="max-width: 100%;">
                    <mat-label>{{ 'CLIENT|Nazwisko / Nazwa' | translate }}</mat-label>
                    <input [(ngModel)]="fullName" [disabled]="!clientInfoFormEnabled" matInput>
                </mat-form-field>
                <mat-form-field appearance="outline" style="max-width: 100%;">
                    <mat-label>{{ 'CLIENT|Numer telefonu' | translate }}</mat-label>
                    <input [(ngModel)]="phoneNumber" [disabled]="!clientInfoFormEnabled" matInput>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z4 address-info-section">
            <mat-card-title>{{ 'CLIENT|Adres inwestycji' | translate }}</mat-card-title>
            <mat-card-content class="address-info-section__fields">
                <mat-form-field appearance="outline" style="max-width: 100%;">
                    <mat-label>{{ 'CLIENT|Adres' | translate }}</mat-label>
                    <input [(ngModel)]="address" [disabled]="!clientInfoFormEnabled" matInput>
                </mat-form-field>
                <mat-form-field appearance="outline" style="max-width: 100%;">
                    <mat-label>{{ 'CLIENT|Miejscowość' | translate }}</mat-label>
                    <input [(ngModel)]="city" [disabled]="!clientInfoFormEnabled" matInput>
                </mat-form-field>
                <mat-form-field appearance="outline" style="max-width: 100%;">
                    <mat-label>{{ 'CLIENT|Kod pocztowy' | translate }}</mat-label>
                    <input [(ngModel)]="postCode" [disabled]="!clientInfoFormEnabled" matInput>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z4 price-info-section">
            <mat-card-title>{{ 'OFFER|Wycena' | translate }}</mat-card-title>
            <mat-card-content>
                <table class="mat-table price-info-section__table" >
                    <tr class="mat-header-row">
                        <th class="mat-header-cell"></th>
                        <th class="mat-header-cell" *ngIf="!hiddenPrice">
                            {{ 'OFFER|Cena netto' | translate }}
                        </th>
                        <th class="mat-header-cell">{{ 'OFFER|Ilość' | translate }}</th>
                        <th class="mat-header-cell" *ngIf="!hiddenPrice">
                            {{ 'OFFER|Wartość netto' | translate }}
                        </th>
                    </tr>
                    <tr class="mat-row">
                        <td class="mat-cell">{{positionName}}</td>
                        <td class="mat-cell" *ngIf="!hiddenPrice">
                            <mat-form-field appearance="outline" class="no-spin-buttons align-text-center">
                                <input
                                    [(ngModel)]="nettoPrice"
                                    type="number"
                                    [disabled]="!clientInfoFormEnabled"
                                    matInput
                                    (blur)='changedInputData("default")'>
                            </mat-form-field>
                        </td>
                        <td class="mat-cell">
                            <mat-form-field appearance="outline" class="align-text-center">
                                <input
                                    [(ngModel)]="quantity"
                                    min="0"
                                    type="number"
                                    [disabled]="!clientInfoFormEnabled"
                                    matInput
                                    (blur)='changedInputData("default")'>
                            </mat-form-field>
                        </td>
                        <td class="mat-cell align-text-right" *ngIf="!hiddenPrice">{{ getNettoPrice() }}</td>
                    </tr>
                    <tr class="mat-row" *ngIf="!hiddenPrice">
                        <td class="mat-cell">
                            {{ 'OFFER|Rabat' | translate }}</td>
                        <td class="mat-cell">
                        </td>
                        <td class="mat-cell">
                        </td>
                        <td class="mat-cell">
                            <mat-form-field appearance="outline" class="align-text-center">
                                <input [(ngModel)]="discountPercent" type="number" min="0" max="100" [disabled]="!clientInfoFormEnabled" matInput (input)='changedInputData("discountPercent")'>
                                <span matSuffix>%</span>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="align-text-center">
                                <input [(ngModel)]="discountCash" type="number" [disabled]="!clientInfoFormEnabled" matInput (input)='changedInputData("discountCash")'>
                                <span matSuffix>{{ null | currency: currency}}</span>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr class="mat-row" *ngIf="!hiddenPrice">
                        <td class="mat-cell">{{ 'OFFER|Usługi dodatkowe' | translate }}</td>
                        <td class="mat-cell">
                        </td>
                        <td class="mat-cell">
                        </td>
                        <td class="mat-cell">
                            <mat-form-field appearance="outline" class="no-spin-buttons align-text-center">
                                <input [(ngModel)]="additionalServicesPrice" class="no-spin-buttons" type="number" [disabled]="!clientInfoFormEnabled" matInput (blur)='changedInputData("default")'>
                                <span matSuffix>{{ null | currency: currency}}</span>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr class="mat-row" *ngIf="!hiddenPrice">
                        <td class="mat-cell">{{ 'OFFER|Razem netto' | translate }}</td>
                        <td class="mat-cell">
                        </td>
                        <td class="mat-cell">
                        </td>
                        <td class="mat-cell align-text-right">
                            <mat-form-field appearance="outline" class="no-spin-buttons align-text-center">
                                <input [ngModel]="fullNettoPrice" (ngModelChange)='fullNettoPrice=$event' class="no-spin-buttons" type="number" [disabled]="!clientInfoFormEnabled" matInput (input)='changedInputData("fullNettoPrice")'>
                                <span matSuffix>{{ null | currency: currency}}</span>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr class="mat-row" *ngIf="!hiddenPrice">
                        <td class="mat-cell">{{ 'OFFER|VAT' | translate }}</td>
                        <td class="mat-cell">
                            <mat-form-field appearance="outline" class="align-text-center">
                                <mat-select [(ngModel)]="selectedVat" [disabled]="!clientInfoFormEnabled" (selectionChange)='changedInputData("vat")'>
                                    <mat-option *ngFor="let vat of taxRates" [value]="vat.value">
                                        {{ vat.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td class="mat-cell">
                        </td>
                        <td class="mat-cell align-text-right">
                            {{ vatPrice | currency: currency : null : false : true }}
                        </td>
                    </tr>
                    <tr class="mat-row" *ngIf="!hiddenPrice">
                        <td class="mat-cell">{{ 'OFFER|Razem' | translate }}</td>
                        <td class="mat-cell"></td>
                        <td class="mat-cell"></td>
                        <td class="mat-cell align-text-right">
                            <mat-form-field appearance="outline" class="no-spin-buttons align-text-center">
                                <input class="no-spin-buttons"
                                type="number"
                                matInput
                                [ngModel]="fullPrice"
                                (ngModelChange)='fullPrice=$event'
                                [disabled]="!clientInfoFormEnabled"
                                (input)='changedInputData("fullPrice")'>
                                <span matSuffix>{{ null | currency: currency}}</span>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr class="mat-row" *ngIf="!hiddenPrice">
                        <td class="mat-cell align-text-right">
                            <button mat-raised-button (click)='resetToDefaults()'>{{ 'OFFER|Resetuj cenę' | translate }}</button>
                        </td>
                    </tr>
                </table>
            </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z4 details-info">
            <mat-card-title>{{ 'OFFER|Szczegóły' | translate }}</mat-card-title>
            <mat-card-content>
            <p><mat-checkbox (change)="toggleCheckbox('download-pdf', $event)" [checked]="isCheckboxChecked('download-pdf')">{{ 'INTERFACE|Pobierz PDF' | translate }}</mat-checkbox></p>
            <p><mat-checkbox (change)="toggleCheckbox('pdf-on-email', $event)">{{ 'INTERFACE|Wyślij PDF na email' | translate }}</mat-checkbox></p>
            <p class="details-info__validator"><mat-form-field><input matInput [(ngModel)]="validatorForAutomatedAttacks"></mat-form-field></p>
            <mat-form-field appearance="outline" style="max-width: 100%;">
                    <mat-label>{{ 'OFFER|Adres' | translate }}</mat-label>
                    <input class="details-info__input-email" [(ngModel)]="email" [disabled]="isFieldDisabled('email')" matInput>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close class="mat-elevation-z4">{{ 'INTERFACE|Anuluj' | translate }}</button>
    <button mat-raised-button
        color="primary"
        class="mat-elevation-z4 print-button"
        (click)="closeWithData()"
        [disabled]="isFieldDisabled('print-button')">
        {{ getButtonName() }}
    </button>
</mat-dialog-actions>
