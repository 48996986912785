import {EventBusService} from '@icc/common/event-bus.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import {ConfiguratorsDataService} from '@icc/common/configurators/configurators-data.service';
import {core, logger} from '@icc/common/helpers';
import {TimeLimitSegment, TimeLimitFunctions, TimeLimitFunction} from './time-limit-functions';
import { Inject, Injectable, Injector } from '@angular/core';
import { WindowActiveConfiguration } from '../configurations/WindowActiveConfiguration';
import { AppConfigFactory, APP_CONFIG } from '../config';
import { Common } from '../Common';

@Injectable()
export class TimeLimitService {

    private counter = 0;

    constructor(
        private injector: Injector,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private eventBusService: EventBusService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {
        this.eventBusService.subscribe([
            'changedBalcony',
            'changedFillings',
            'changedSashes',
            'changedStep',
            'putAlignmentInField',
            'putExtensionOnSide',
            'removedAlignmentInField',
            'removedAlignment',
            'removedExtensionFromSide',
            'setConstructionColor',
            'setExtensionProfile',
            'setFrameProfile',
            'setGlazingInSash',
            'setGlazingBeadInSash',
            'setMullionProfile',
            'setProfileSet',
            'setSashProfile',
            'setSealColor',
            'setShape',
            'setSystem',
            'setWarmEdge',
            'setLowThreshold',
            'unsetLowThreshold',
            'setMuntinColor'
        ], data => {
            try {
                if (data.activeConfiguration) {
                    this.count(data.activeConfiguration as WindowActiveConfiguration);
                }
            } catch (err) {
                logger.error(err);
            }
        });

        if (this.configurationsService.conf && this.configurationsService.conf.Current) {
            this.count();
        }
    }

    /**
     * Wylicza termin realizacji dla całej oferty.
     *
     * @param {any} positions dane pozycji
     * @returns {number} Termin realizacji
     *
     * @memberOf TimeLimitService
     */
    public getOfferTimeLimit(positions) {
        if (positions && positions.length) {
            return positions.map(p => core.parseJson(p.doc.configuration))
                .map(conf => conf.timeLimit)
                .filter(s => s)
                .reduce((max, s) => s > max ? s : max, 0);
        }
    }

    /**
     * Ustawia termin realizacji w konfiguracji.
     *
     * @param {any} config Konfiguracja
     * @returns {number} Termin realizacji
     *
     * @memberOf TimeLimitService
     */
    public count(config = this.configurationsService.conf.Current) {
        if (!this.config().IccConfig.Configurators.timeLimits) {
            return NaN;
        }

        const stack = this.buildStack(config);
        const timeLimit = this.setTimeLimit(stack, config);
        return timeLimit;
    }

    /**
     * Wyznacza schemat liczenia terminu realizacji.
     *
     * @param {any} config              Konfiguracja
     * @returns {TimeLimitSegment[]} Schemat liczenia terminu realizacji
     *
     * @memberOf TimeLimitService
     */
    private buildStack(config): TimeLimitSegment[] {
        const type                       = config.type;
        let functions                    = TimeLimitFunctions.timeLimitsFn[type] || [];
        if (Common.isString(functions)) {
            functions                    = TimeLimitFunctions.timeLimitsFn[functions] || [];
        }
        const timeLimitsStack: TimeLimitSegment[] = [];
        try {
            this.counter = 0;
            for (const funcName of functions) {
                const func     = TimeLimitFunctions.map.get(funcName);
                if (!func) {
                    continue;
                }
                const funcData = {
                    timeLimits: this.configuratorsDataService.data.timeLimits
                };
                const funcThis: any = this.injector.get(func.class);
                if (func && func.requiredData) {
                    for (const i in func.requiredData) {
                        const data = core.deepFind({
                            conf       : config,
                            data       : this.configuratorsDataService.data
                        }, func.requiredData[i]);
                        funcData[i] = data;
                    }
                }
                const funcBinded: TimeLimitFunction = func.bind(funcThis);
                const result = funcBinded(funcData);
                if (result && result.length) {
                    timeLimitsStack.push(...this.extendId(result.filter(s => s)));
                }
            }
        } catch (error) {
            logger.error(error);
        }
        return timeLimitsStack;
    }

    /**
     * Wylicza cenę ze schematu terminu realizacji
     *
     * @param {TimeLimitSegment[]} stack Schemat terminu realizacji
     * @returns {number} Wyliczony termin realizacji
     *
     * @memberOf TimeLimitService
     */
    private setTimeLimit(stack: TimeLimitSegment[], config) {
        const timeLimit = stack.reduce((max, s) => s.value > max ? s.value : max, 0);

        config.timeLimitsStack = stack;
        config.timeLimit = timeLimit;

        return timeLimit;
    }


    /**
     * Rozszerza segmenty o id.
     *
     * @param {TimeLimitSegment[]} data Schemat terminu realizacji
     * @returns {TimeLimitSegment[]} Schemat terminu realizacji z id.
     *
     * @memberOf TimeLimitService
     */
    private extendId(data: TimeLimitSegment[]) {
        if (data == null) {
            return [];
        }
        if (Common.isArray(data)) {
            data.forEach(d =>  {
                if (d) {
                    d.id = this.counter++;
                }
            });
        }
        return data;
    }
 }
