import { Component, OnInit, Inject } from '@angular/core';
import { _, StepComponent, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';

@Component({
    selector: 'icc-colors-page',
    templateUrl: './colors-page.component.html',
    styleUrls: ['./colors-page.component.scss'],
})
export class ColorsPageComponent extends StepComponent implements OnInit {
    public configurator = 'pleated_blinds';
    public stepId = 'systemInfo';
    public title = _('COLOR|Wybierz kolor');
    public options = [];

    system: any = null;
    colors: iccListItem[] = [];
    selectedColorId: Number = null;
    constructor(
        private sharedFacade: SharedFacade,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        @Inject(ICC_PAGE_DATA) private pageData: {
            colors: any[],
            selectedColorId: any
        }
    ) {
        super();
    }

    ngOnInit() {
      this.selectedColorId = Number(this.pageData.selectedColorId);
      this.colors = this.pageData.colors.map<iccListItem>(color => ({
          id: Number(color.id),
          title: color.name,
          description: color.code,
          color: '#' + color.color,
          imageUrl: '/files/windowcolor/' + color.color_img
        }));
    }

    close() {
        this.sharedFacade.closePage();
    }

    selectColor(item: iccListItem) {
        const [colorId, colorRal] = String(item.id).split('_');
        const color = this.pageData.colors.find(
            c => Number(colorId) === Number(c.id)
        );
        this.sharedFacade.closePage(color); 
    }
}
