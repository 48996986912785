import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { MosquitoScreenPartialState } from './mosquito-screen.reducer';
import { mosquitoScreenQuery } from './mosquito-screen.selectors';
import { LoadMosquitoScreen } from './mosquito-screen.actions';
import { SharedFacade } from '@icc/configurator/shared';
import { ConfiguratorsDataService, ConfigurationsService } from '@icc/common';
import { map } from 'rxjs/operators';

@Injectable()
export class MosquitoScreenFacade {
    selectedSystem$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.System)
    );

    selectedType$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Type)
    );

    netType$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.NetType)
    );

    direction$ = this.configurationsService.configuration$.pipe(map(conf => conf.Direction))
    shape$ = this.configurationsService.configuration$.pipe(map(conf => conf.Shape));

    frameColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Colors.frame)
    );

    netColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Colors.net)
    );

    panelColor$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Colors.panel)
    );

    panelColorShow$ = this.selectedType$.pipe(
        map((type) => type.extra_color)
    );

    montage$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.Montage)
    );

    profilePositions$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.ProfilePositions)
    );

    muntinType$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.MuntinType)
    );

    muntinPosition$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.MuntinPosition)
    );

    windowLine$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.WindowLine)
    );

    holdersWidth$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.HoldersWidth)
    );

    hasThickSeal$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.hasThickSeal)
    );

    sealThickness$ = this.configurationsService.configuration$.pipe(
        map(conf => conf.sealThickness)
    );

    constructor(private store: Store<MosquitoScreenPartialState>,
        private sharedFacade: SharedFacade,
        private dataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService<'mosquito'>) {}

    loadAll() {
        this.store.dispatch(new LoadMosquitoScreen());
    }
}
