import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { ConfiguratorsDataService, ConfigurationsService, APP_CONFIG, AppConfigFactory, EventBusService } from '@icc/common';
import { Inject, Injectable } from '@angular/core';
import { isUndefined, isObject, isDefined, isArray } from '@icc/helpers';

@Injectable()
export class WarrantyService {

    constructor (
        private configuratorsDataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService<'window'>,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService
        ) {
        'ngInject';
    }

    /**
     * Sprawdza czy konfiguracja jest na gwarancji
     *
     * @method     check
     * @param      {Object}  conf    Konfiguracja
     * @return     {bool}            Czy konfiguracja jest na gwarancji
     */
    check(conf: WindowActiveConfiguration) {
        if (isUndefined(conf)) {
            conf = this.configurationsService.conf.Current;
        }
        let priceMaps = this.getRestrictionsForSystem(this.configuratorsDataService.data.warrantyRestrictionsDepends, conf.System);
        let sash;
        conf.Warranty = true;
        for (let i = conf.Sashes.length - 1; i >= 0; i--) {
            sash = conf.Sashes[i];
            conf.Warranty = conf.Warranty && this.checkForSash(sash, priceMaps, conf.Balcony, this.configuratorsDataService.data.warrantyRestrictions);
            if (!conf.Warranty) {
                break;
            }
        }
        if (!conf.Warranty) {
            this.eventBusService.post({
                key: 'warranty',
                value: conf.Warranty
            });
        }
        return conf.Warranty;
    }

    /**
     * Zwraca ograniczenia gwarancyjne dla systemu.
     *
     * @method     getRestrictionsForSystem
     * @param      {object}  priceDepends  Macierz ograniczeń
     * @param      {object}  system        System
     * @return     {Object}  Ograniczenia gwarancyjne
     */
    getRestrictionsForSystem(priceDepends, system) {
        if (isObject(priceDepends)) {
            if (isDefined(priceDepends[system.id])) {
                return priceDepends[system.id];
            }
        }
        return {};
    }

    /**
     * Sprawdza gwarancję dla skrzydła
     *
     * @method     checkForSash
     * @param      {Sash}     sash       Skrzydło
     * @param      {object}   priceMaps  Macierz ograniczeń
     * @param      {bool}     balcony    Czy okno balkonowe
     * @param      {array}    matrixes   Tablica ograniczeń
     * @return     {boolean}  Czy skrzydło ma gwarancję
     */
    checkForSash(sash, priceMaps, balcony, matrixes) {
        if (this.config().IccConfig.Configurators.warrantyRestrictions) {
            if (isObject(priceMaps[sash.type.type])) {
                let priceData = priceMaps[sash.type.type];
                let priceId;
                if (isDefined(priceData.one)) {
                    priceId = priceData.one;
                } else {
                    if (priceData.height < priceData.over.from) {
                        priceId = priceData.win;
                    } else if (priceData.height >= priceData.over.from && priceData.height <= priceData.over.to) {
                        if (balcony) {
                            priceId = priceData.bal;
                        } else {
                            priceId = priceData.win;
                        }
                    } else {
                        priceId = priceData.bal;
                    }
                }
                if (isDefined(priceId) && priceId !== null) {
                    let matrix = matrixes[priceId];
                    if (isDefined(matrix) && matrix !== null) {
                        let priceField = matrix.data.filter((el) =>
                            el.height_from <= sash.rHeight && el.height_to >= sash.rHeight
                                && el.width_from <= sash.rWidth && el.width_to >= sash.rWidth
                        );
                        if (isArray(priceField) && priceField.length > 0) {
                            return parseFloat(priceField[0].price) > 0;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
}
