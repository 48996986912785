import { ConfigurationType } from '@icc/common/configurations/configurations.service';
import { Injectable } from '@angular/core';
import { EventBusService } from '@icc/common';
import { ConfiguratorsAvailabilityService } from './configurators-availability.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';

@Injectable({
    providedIn: 'root'
})
export class ConfiguratorCurrentConfiguratorService extends CurrentConfiguratorService {
    conf: ConfigurationType;
    constructor(
        private configuratorsAvailabilityService: ConfiguratorsAvailabilityService,
        private eventBusService: EventBusService
    ) {
        super();

        eventBusService.subscribeWithoutConfiguration<ConfigurationType>('changedConfiguratorLocal', (data) => {
            this.changeConfigurator(data.value)
        })
    }

    changeConfigurator(conf: ConfigurationType) {
        this.conf = conf;
        if (
            this.configuratorsAvailabilityService.availableConfigs[this.conf]
        ) {
            this.eventBusService.post({
                key: 'changedConfigurator',
                value: this.conf,
            });
        }
    }

    setConfigurator(config: ConfigurationType) {
        this.conf = config;
        if (this.configuratorsAvailabilityService.configs.indexOf(this.conf) > -1) {
            this.eventBusService.post({
                key: 'changedConfigurator',
                value: this.conf,
            });
        }
    }
}
