import { Common } from '@icc/common/Common';
import {
    ConfigurationsService,
    AppConfigFactory,
    APP_CONFIG,
    WindowActiveConfiguration,
    TranslateService,
} from '@icc/common';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { core } from '@icc/common/helpers';
import { Injectable, Inject } from '@angular/core';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { WindowFacade } from 'libs/configurator/window/src/lib/+state/window.facade';
import { map } from 'rxjs/operators';
import { IccFilling } from '@icc/common/data-types';
import { combineLatest } from 'rxjs';

@Injectable()
export class PanelOptionsFacade {
    loadedData = false;

    constructor(
        private translateService: TranslateService,
        private windowFacade: WindowFacade,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {}

    init() {
        this.loadedData = true;
    }

    panelGlazingBox(
        conf: DoorActiveConfiguration | WindowActiveConfiguration,
        filling: ActiveSash['panelGlazing']
    ) {
        let img = null;
        const title = this.translateService.instant('WINDOW|Szklenie:');
        let name = this.translateService.instant('INTERFACE|Brak');
        let u = this.translateService.instant('CONFIGURATOR|brak danych');

        if (filling) {
            img = '/files/filling/' + filling.image;

            name = filling.name;
            if (this.config().IccConfig.Configurators.showFillingsProducers) {
                name += ` (${filling.producer})`;
            }
            if (filling.u) {
                u = filling.u + ' W/m2*K';
            }
        }
        return {
            img,
            title,
            name,
            u,
        };
    }

}