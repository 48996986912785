<mat-card class="mat-elevation-2">
    <mat-checkbox
        class="disableMosquitoCheckbox"
        [(ngModel)]="mosquito"
        (change)="setMosquito($event.checked)"
        >{{ 'MOSQUITO|Czy chcesz siatkę' | translate }}</mat-checkbox
    >
</mat-card>
<mat-card class="mat-elevation-2" *ngIf="mosquito">
    <h4>{{ 'STEPS|Linia' | translate }}</h4>
    <div class="configuratorContentLines">
        <icc-list [items]="systems" itemTemplate="itemImgTitle" [scrollable]="false" [selected]="mosquito.System.id" (select)="setSystem($event)">
        </icc-list>
    </div>
    <h4>{{ 'DIMENSION|Rozmiar' | translate }}</h4>
    <div class="configuratorContentStepsDefaultRight mosquite">
        <mat-form-field>
            <mat-label>{{ 'DIMENSION|Szerokość (S)' | translate }}:</mat-label>
            <input matInput iccDimensionInput type="number" step="1" [(ngModel)]="mosquito.Width" (blur)="updateDimensions()">
            <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'DIMENSION|Wysokość (H)' | translate }}:</mat-label>
            <input matInput iccDimensionInput type="number" step="1" [(ngModel)]="mosquito.Height" (blur)="updateDimensions()">
            <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
        </mat-form-field>
    </div>
    <div class="configuratorContentStepsDefaultDesc">
        <i class='fa fa-info-circle'></i>
        <span>{{ 'MOSQUITO|Podane wymiary są wymiarami zewnętrznymi moskitiery (z uwzględnieniem profilu dodatkowego, jeśli będzie wybrany)' | translate }}</span>
    </div>
    <div *ngIf="types && types.length">
        <h4>{{ 'STEPS|Typ' | translate }}</h4>
        <div class="configuratorContentLines">
            <icc-list [items]="types" itemTemplate="itemImgTitle" [scrollable]="false" [selected]="mosquito.Type.id" (select)="setType($event)">
            </icc-list>
        </div>
        <div class="configuratorContentLines" *ngIf="mosquito.Type.has_direction">
            <strong>{{ 'WINDOW|Wybierz stronę mocowania zawiasów:' | translate }}</strong>
            <mat-radio-group [(ngModel)]="mosquito.Direction">
                <mat-radio-button value="left">{{ 'INTERFACE|Lewa' | translate }}</mat-radio-button>
                <mat-radio-button value="right">{{ 'INTERFACE|Prawa' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <h4>{{ 'STEPS|Kolor' | translate }}</h4>
    <div>
        <ng-container *ngFor="let type of ['frame', 'net', 'panel']">
            <div *ngIf="type !== 'panel' || mosquito.Type.extra_color">
                <icc-select-box
                    [title]="type === 'frame'
                        ? ('MOSQUITO|Kolor ramki moskitiery'| translate)
                        : type === 'net'
                        ? ('MOSQUITO|Kolor siatki'| translate)
                        : ('MOSQUITO|Kolor elementów z tworzywa'| translate)"
                    [description]="mosquito?.Colors[type].name"
                    [imageUrl]="mosquito?.Colors[type].color_img ? ('/files/windowcolor/' + mosquito?.Colors[type].color_img) : 'none'"
                    [color]="'#' + mosquito?.Colors[type].color"
                    [colorSelect]="true"
                    [buttonText]="'INTERFACE|Zmień' | translate"
                    (select)="openModalColor(type, mosquito?.Colors[type])"
                ></icc-select-box>
            </div>
        </ng-container>
    </div>
    <div *ngIf="(montages && montages.length)
        || mosquito.Type.profile_position
        || mosquito.Type.muntin_position
        || (windowLines[mosquito.System.id] && mosquitoHoldersWidth)"
    >
        <div class="configuratorContentLines" *ngIf="montages && montages.length">
            <h4>{{ 'STEPS|System montażu' | translate }}</h4>
            <icc-list [items]="montages" itemTemplate="itemImgTitle" [scrollable]="false" [selected]="mosquito.Montage.id" (select)="setMontage($event)">
            </icc-list>
        </div>
        <div *ngIf="mosquito.Type.profile_position" class="box-form">
            <strong>{{ 'MOSQUITO|Wybierz z której strony ma się znajdować profil dodatkowy:'| translate }}</strong>
            
            <mat-checkbox id="up_p" type="checkbox" name="profile_position[]" [(ngModel)]="mosquito.ProfilePositions.up">{{ 'INTERFACE|Góra'| translate }}</mat-checkbox>
            <mat-checkbox id="down_p" type="checkbox" name="profile_position[]" [(ngModel)]="mosquito.ProfilePositions.down">{{ 'INTERFACE|Dół'| translate }}</mat-checkbox>
            <mat-checkbox id="left_p" type="checkbox" name="profile_position[]" [(ngModel)]="mosquito.ProfilePositions.left">{{ 'INTERFACE|Lewa'| translate }}</mat-checkbox>
            <mat-checkbox id="right_p" type="checkbox" name="profile_position[]" [(ngModel)]="mosquito.ProfilePositions.right">{{ 'INTERFACE|Prawa'| translate }}</mat-checkbox>
        </div>
        <div *ngIf="mosquito.Type.muntin_position">
            <strong>{{ 'MOSQUITO|Wysokość szprosu'| translate }}</strong>
            <mat-radio-group [(ngModel)]="mosquito.MuntinType" (change)="mosquito.MuntinPosition = mosquito.Type.muntin_position">
                <mat-radio-button value="standard"><span>{{ 'CONFIGURATOR|Standardowa'| translate }}</span> ({{mosquito.Type.muntin_position | unitmm:1 }})</mat-radio-button>
                <mat-radio-button value="custom">{{ 'CONFIGURATOR|Inna wysokość'| translate }} ({{dimensionUnit.unitSymbol}}):</mat-radio-button>
            </mat-radio-group>
            <mat-form-field *ngIf="mosquito.MuntinType == 'custom'">
                <mat-label>{{ 'CONFIGURATOR|Inna wysokość' | translate }}:</mat-label>
                <input matInput iccDimensionInput type="number" step="1" name="muntin-position" [(ngModel)]="mosquito.MuntinPosition">
                <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
            </mat-form-field>
        </div>
    </div>
</mat-card>