import angular from 'angular';
import {logger} from '@icc/common/helpers';

/**
 * Fabryka alternatywnych szablonów
 * @param {Object} $uibModal                   Modal
 * @param {Object} Core                     Core
 * @param {Object} PriceService             Serwis cen
 * @param {Object} SystemsService           Fabryka systemów
 * @param {Object} LayoutService            Fabryka szblonów
 * @param {Object} ConfiguratorsDataService Fabryka konfiguratorów danych
 */
export default function AlternativeLayoutsFactory($uibModal, Core, PriceService, SystemsService, LayoutService, ConfiguratorsDataService) {
    'ngInject';

    var factory = {
        alternative: alternative,
        openModal  : openModal
    };
    return factory;

    /**
     * System dla szklenia
     * @param  {Array} systemIds  Numery systemu
     * @param  {Object} glazing   Szklenie
     * @return {Array}            Systemy
     */
    function systemsForGlazing(systemIds, glazing) {
        // var ranges;
        var systems = [];
        // systems.push(~~systemIds[i]);
        return systems;
    }

    /**
     * Znajduje kompatybilne systemy
     * @param  {Object} conf      Konfiguracja
     * @param  {Array} systemIds  Numery systemu
     * @return {Array}            Numery systemu
     */
    function findCompatibleSystems(conf, systemIds) {
        if (['window', 'hs', 'door', 'folding_door', 'sliding_door'].indexOf(conf.type) === -1) {
            return -1;
        }
        var i = 0;
        var j = 0;
        var index = systemIds.indexOf(~~conf.System.id);
        if (index > -1) {
            systemIds.splice(index, 1);
        }
        for (i = 0; i < conf.Sashes.length; i++) {
            var sash = conf.Sashes[i];
            if (sash.intSashes.length === 0) {
                systemIds = Core.intersection(systemIds, systemsForGlazing(systemIds, sash.glazing));
            } else {
                for (j = 0; j < sash.intSashes.length; j++) {
                    var intSash = sash.intSashes[j];
                    systemIds = Core.intersection(systemIds, systemsForGlazing(systemIds, intSash.glazing));
                }
            }
            logger.log('po szybach', systemIds);
            if (LayoutService.passiveSashTypes.indexOf(sash.type.type) === -1) {
                systemIds = Core.intersection(systemIds, sash.handle.window_lines_ids.map(Number));
            }
            logger.log('po klamkach', systemIds);
            for (j = 0; j < sash.hardware.length; j++) {
                var hw = sash.hardware[j].accessory;
                systemIds = Core.intersection(systemIds, (hw.window_lines_ids).map(Number));
            }
            logger.log('po dodatkach do skrzydeł', systemIds);
        }
        for (j = 0; j < conf.Accessories.length; j++) {
            var accessory = conf.Accessories[j].accessory;
            systemIds = Core.intersection(systemIds, (accessory.window_lines_ids).map(Number));
        }
        logger.log('po dodatkach do konstrukcji', systemIds);
        return systemIds;

    }

    /**
     * Liczy alternatywne ceny
     * @param  {Array} compatibleSystems Kompatybilne systemy
     * @param  {Object} systems           Systemy
     * @param  {Object} conf              Konfiguracja
     * @return {Array}                    Alternetywne ceny
     */
    function countAlternativePrices(compatibleSystems, systems, conf, quantity) {
        if (['window', 'hs', 'door', 'folding_door', 'sliding_door'].indexOf(conf.type) === -1) {
            return -1;
        }
        var alternativePrices = [];
        for (var i = 0; i < compatibleSystems.length; i++) {
            var compSystemId = compatibleSystems[i];
            var system = Core.fIdO(systems, compSystemId);
            var altConf = Core.copy(conf);

            altConf.System = system;

            var price = PriceService.count(altConf);
            if (angular.isNumber(price) && price > 0) {
                alternativePrices.push({system: system.name, systemId: system.id, price: price * quantity});
            }
        }
        logger.log(alternativePrices);
        return alternativePrices;
    }

    /**
     * Funkcja zwraca alternatywne ceny
     * @param  {Object} conf Konfiguracja
     * @param  {Object} type Typ
     * @return {Number}      Ilość alternatywnych cen
     */
    function alternative(conf, type) {
        if (['window', 'hs', 'door', 'folding_door', 'sliding_door'].indexOf(conf.type) === -1) {
            return -1;
        }
        var systems = SystemsService.getSystems() || [];
        type = conf.type;
        var systemIds = [];
        for (var i = 0; i < systems.length; i++) {
            var system = systems[i];
            systemIds.push(system.id * 1);
        }
        systemIds =  findCompatibleSystems(conf, systemIds);
        return countAlternativePrices(systemIds, systems, conf, type);
    }

    /**
     * Funkcja zwracajca alternatywne ceny
     * @param  {Array} confs  Konfiguracje
     * @param  {Object} type  Typ
     * @return {Array}        Alternatywne ceny
     */
    function alternativeMany(confs, type) {
        var systems = [];
        if (type.indexOf('WIN') > -1) {
            systems =  ConfiguratorsDataService.data.windowLines;
            type = 'window';
        } else if (type.indexOf('HS') > -1) {
            systems =  ConfiguratorsDataService.data.hsLines;
            type = 'hs';
        } else if (type.indexOf('FST') > -1) {
            systems =  ConfiguratorsDataService.data.foldingDoorLines;
            type = 'folding_door';
        } else if (type.indexOf('ST') > -1) {
            systems =  ConfiguratorsDataService.data.slidingDoorLines;
            type = 'sliding_door';
        } else if (type.indexOf('DOOR') > -1) {
            systems =  ConfiguratorsDataService.data.doorLines;
            type = 'door';
        }

        var i = 0;
        let systemIds = systems.map(system => system.id * 1);
        var altPricesAll = [];
        for (i = 0; i < confs.length; i++) {
            systemIds = Core.intersection(systemIds, findCompatibleSystems(confs[i].configuration, systemIds));
            const countedAlternativePrices = countAlternativePrices(systemIds, systems, confs[i].configuration, confs[i].quantity);
            systemIds = Core.intersection(systemIds, countedAlternativePrices.map(ap => parseInt(ap.systemId)));
            altPricesAll.push(countedAlternativePrices);
        }

        const altPrices = altPricesAll.reduce((prev, aps) => {
            aps.filter(ap => systemIds.indexOf(ap.systemId * 1) > -1).forEach(ap => {
                var x = prev.filter(el => el.system === ap.system);
                if (x.length > 0) {
                    x[0].price += ap.price;
                } else {
                    prev.push({system: ap.system, price: ap.price});
                }
            });
            return prev;
        }, []);
        logger.log(altPrices);
        return altPrices;

    }

    /**
     * Funkcja otwierajaca modal
     * @param  {Array} positions  Pozycje
     * @param  {String} key       Klucz
     * @return {Array}            Alternatywne ceny
     */
    function openModal(positions, key) {
        var confs = [];
        for (var i = 0; i < positions.length; i++) {
            confs.push(positions[i].doc);
        }
        $uibModal.open({
            templateUrl: 'modalAlternatives.html',
            controller : 'ModalAlternativesCtrl as malt',
            resolve    : {alts: () => alternativeMany(confs, key)}
        });
    }

}
