import { GlazingImpost } from './../../../../../common/src/lib/configurations/parts/garage_door/GlazingImpost';
import { PanelsAmount } from './../../../../../common/src/lib/configurations/parts/garage_door/PanelsAmount';
import { SharedAction, SharedActionTypes } from '@icc/configurator/shared';
import { GarageDoorAction, GarageDoorActionTypes } from './garage-door.actions';
import { IGarageDoorActiveConfiguration } from '@icc/common/configurations/GarageDoorActiveConfiguration';
import { GarageDoorDimensions } from '@icc/common/configurations/parts/garage_door';

export const GARAGEDOOR_FEATURE_KEY = 'garage_door';

export interface GarageDoorState {
    configuration: IGarageDoorActiveConfiguration | null;
}

export interface GarageDoorPartialState {
    readonly [GARAGEDOOR_FEATURE_KEY]: GarageDoorState;
}

export const initialState: GarageDoorState = {
    configuration: null,
};

export function garageDoorReducer(
    state: GarageDoorState = initialState,
    action: GarageDoorAction | SharedAction
): GarageDoorState {
    switch (action.type) {
        case SharedActionTypes.ConfigurationLoaded: {
            state = {
                ...state,
                configuration: {
                    ...(action.payload.configuration as IGarageDoorActiveConfiguration),
                },
            };
            break;
        }
        case GarageDoorActionTypes.UpdateGarageDoorConfiguration: {
            state = {
                ...state,
                configuration: {
                    ...action.payload.configuration,
                },
            };
            break;
        }
        default: {
            state = {
                ...state,
                configuration: garageDoorConfigurationReducer(state.configuration, action)
            }
        }
    }
    return state;
}


export function garageDoorConfigurationReducer(
    state: GarageDoorState['configuration'],
    action: GarageDoorAction | SharedAction
): GarageDoorState['configuration'] {
    switch (action.type) {
        case GarageDoorActionTypes.SetGarageDoorSystem: {
            state = {
                ...state,
                system: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorDimensions: {
            state = {
                ...state,
                dimensions: action.payload as unknown as GarageDoorDimensions
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorMechanism: {
            state = {
                ...state,
                mechanism: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorGuidance: {
            state = {
                ...state,
                guide: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorPanelsData: {
            state = {
                ...state,
                panelsData: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorGlazingsGlobalImposts: {
            state = {
                ...state,
                glazingsGlobalImposts: action.payload as unknown as GlazingImpost[]
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorWindowsGlobalType: {
            state = {
                ...state,
                windowsGlobalType: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorWindowsGlobalGlazing: {
            state = {
                ...state,
                windowsGlobalGlazing: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorGlazingPanelsGlobalGlazing: {
            state = {
                ...state,
                glazingPanelsGlobalGlazing: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorWindowFramesGlobalOuterColor: {
            state = {
                ...state,
                windowFramesGlobalOuterColor: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorGlazingPanelsGlobalColor: {
            state = {
                ...state,
                glazingPanelsGlobalColor: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorVentilationGratesGlobalOuterColor: {
            state = {
                ...state,
                ventilationGratesGlobalOuterColor: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorPanel: {
            state = {
                ...state,
                panel: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorPanelInternal: {
            state = {
                ...state,
                panelInternal: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorPanelStructure: {
            state = {
                ...state,
                panelStructure: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorPanelInternalStructure: {
            state = {
                ...state,
                panelInternalStructure: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorPlinthPanel: {
            state = {
                ...state,
                plinthPanel: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorPlinthPanelStructure: {
            state = {
                ...state,
                plinthPanelStructure: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorPanelColor: {
            state = {
                ...state,
                panelColor: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorPanelInternalColor: {
            state = {
                ...state,
                panelInternalColor: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorDrive: {
            state = {
                ...state,
                drive: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorDriveType: {
            state = {
                ...state,
                driveType: action.payload as undefined
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorDriveSide: {
            state = {
                ...state,
                driveSide: action.payload as undefined
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorWicketDoor: {
            state = {
                ...state,
                wicketDoor: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorWicketDoorDirection: {
            state = {
                ...state,
                wicketDoorDirection: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorWicketDoorHandleColor: {
            state = {
                ...state,
                wicketDoorHandleColor: action.payload
            }
            break;
        }
        case GarageDoorActionTypes.SetGarageDoorWicketDoorFrameProfilesColor: {
            state = {
                ...state,
                wicketDoorFrameProfilesColor: action.payload
            }
            break;
        }
    }
    return state;
}
