/**
 * Kontroler okno modal FrameDivider
 * @param {object} $uibModalInstance Okno modal
 * @param {object} type           Typ
 * @param {object} allItems       Wszystkie pozycje
 * @param {object} selItem        ustawienie pozycji
 */
export default function ModalFrameDividerCtrl($uibModalInstance, type, allItems, selItem) {
    'ngInject';

    var vm = this;
    vm.close      = close;
    vm.type       = type;
    vm.selectItem = selectItem;
    vm.allItems   = allItems;
    vm.selItem    = selItem;

    /**
     * Funkcja wybierajaca pozycje
     * @param  {object} item Pozycja
     */
    function selectItem(selectType, item) {
        if (selectType == 'div') {
            vm.selItem.div = item;
            vm.selItem.isDefault = false;
        } else if (selectType == 'frame') {
            vm.selItem.frame = item;
            vm.selItem.frame.isDefault = false;
        }
        $uibModalInstance.close();
    }

    /**
     * Funkcja zamykajaca okno modal
     */
    function close() {
        $uibModalInstance.close();
    }
}
