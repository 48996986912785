import angular from 'angular';
import format from 'date-fns/format';
import { parseDate } from '@icc/helpers';
import {
    OfferStatuses,
    OfferStatusDict,
    OrderStatuses,
    OrderStatusDict,
    ShopOrderStatuses,
    ShopOrderStatusDict,
} from '@icc/common/offers/offer-statuses';

export default function OffersCtrl($rootScope, $scope, $route, $http, $routeParams, $filter, Core, OffersFactory, OffersService, OfferViewFactory,
    UserService, PdfService, EventBusService, DatabaseManager, OnlineStatusService, ClientsFactory, isElectron, PositionsFactory
) {
    'ngInject';
    var vm = this;

    vm.isOrder         = $route.current.loadedTemplateUrl === '/application/offer/orders.html' ? true : false;
    vm.isShopOrder     = $route.current.$$route.originalPath.includes('shop_orders');
    vm.isShopOffer     = $route.current.$$route.originalPath.includes('shop_offers');
    vm.createOrder     = OfferViewFactory.confirmOrder;
    vm.createValuation = OfferViewFactory.createValuation;
    vm.openServicePage = OfferViewFactory.openServicePage;
    vm.ceil            = Math.ceil;
    vm.getOfferList    = getOfferList;
    vm.updateOfferList = updateOfferList;
    vm.statusChange    = statusChange;
    vm.dealerPrices    = false;
    vm.currentPage     = 1;
    vm.deleteOffer     = deleteOffer;
    vm.sortOffers      = sortOffers;
    vm.openfrom        = openFrom;
    vm.opento          = openTo;
    vm.dateOptions     = {startingDay: 1, showWeeks: false};
    vm.openedFrom      = false;
    vm.openedTo        = false;
    vm.dealerId        = angular.isDefined($routeParams.dealer) ? $routeParams.dealer : '';
    vm.openPdf         = openPdf;

    vm.totalRows    = 0;
    vm.orderBy      = 'doc.created';
    vm.orderReverse = true;
    vm.itemsPerPage = '10';
    vm.search       = '';
    vm.dateFrom     = '';
    vm.dateTo       = '';
    vm.archives     = false;
    vm.onlyMine     = true;
    vm.onlyOrdered  = false;
    vm.onlyParents  = false;
    vm.status       = null;
    vm.dealerStatus = null;

    vm.seller = UserService.getSeller();
    vm.dealerSeller = UserService.getDealerSeller();
    vm.offerStatuses = OfferStatuses;
    vm.offerStatusDict = OfferStatusDict;
    vm.orderStatuses = OrderStatuses;
    vm.orderStatusDict = OrderStatusDict;
    vm.shopOrderStatuses = ShopOrderStatuses;
    vm.shopOrderStatusDict = ShopOrderStatusDict;

    if (OfferViewFactory.filters && OfferViewFactory.loadFilters) {
        Object.assign(vm, OfferViewFactory.filters);
        OfferViewFactory.filters = null;
        OfferViewFactory.loadFilters = false;
    }

    let allFilteredOffers = [];
    updateOfferList();

    const unsubscribeRouteChange = $rootScope.$on('$routeChangeStart', (e, next, current) => {
        if (
            next.$$route.originalPath === '/app/offers_view/:id?' &&
            ['/app/offers/:dealer?', '/app/orders/:id?'].includes(current.$$route.originalPath)
        ) {
            saveFilters();
        }
    });

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('synced', data => data.value.elements.indexOf('Offer') > -1 && loadOffers()));
    $scope.$on('$destroy', () => {
        unsubscribeRouteChange();
        subscriptions.map(el => el.unsubscribe());
    });

    /**
     * Drukowanie oferty do PDF
     * @param  {string} offerId ID oferty
     * @param  {object} options Opcje
     */
    async function openPdf(offerId, options) {
        PdfService.openPdf(offerId, options, vm.isOrder);
    }

    /**
     * Wybor daty poczatkowej filtrowania
     * @param  {object} $event Event
     */
    function openFrom($event) {
        $event.preventDefault();
        $event.stopPropagation();
        vm.openedFrom = true;
    }

    /**
     * Wybor daty koncowej filtrowania
     * @param  {object} $event Event
     */
    function openTo($event) {
        $event.preventDefault();
        $event.stopPropagation();
        vm.openedTo = true;
    }

    /**
     * Zmiana statusu dealera oferty
     * @param  {string} id        Id oferty/zamwienia
     * @param  {string} offerdata Rekord oferty
     */
    function statusChange(id, offerdata) {
        var newOffer = offerdata;
        OffersService.update(id, newOffer).then(() => {
            $rootScope.showInfo($filter('translate')('OFFER|Status został zaktualizowany'), null, null, 5000);
        });
    }

    /**
     * Usuwanie pustych ofert
     * @param  {object} offerdata Dane usuwanej oferty
     */
    function deleteOffer(offerdata) {
        if (offerdata.synced === true && parseInt(offerdata.number_items) === 0) {
            if (confirm($filter('translate')('OFFER|Czy na pewno usunąć pustą ofertę') + '?')) {
                OffersFactory.remove(offerdata._id || offerdata.tmp_id, offerdata).then(function afterOfferRemove() {
                    loadOffers();
                });
            }
        }
    }

    /**
     * Pobieranie listy ofert
     */
    async function getOfferList() {
        if (!vm.onlyMine && OnlineStatusService.getStatus()) {
            loadOffersOnline();
        } else {
            const clients = await ClientsFactory.list();
            const begin = (vm.currentPage - 1) * Number(vm.itemsPerPage);
            const end = begin + Number(vm.itemsPerPage);
            vm.offerList = (allFilteredOffers || []).slice(begin, end).map(e => {
                e.doc.currency = Core.parseJson(e.doc.currency);
                if (!e.doc.companyname && !e.doc.clientname && !e.doc.clientsurname) {
                    const client = clients.find(el => el.doc.tmp_id == e.doc.client_id);
                    if (client) {
                        e.doc.clientDisplayName = (client.doc.name ? client.doc.name : '') + ' ' + (client.doc.surname ? client.doc.surname : '');
                    }
                }
                return e.doc;
            });
            $rootScope.loader = false;
            $scope.$apply();
        }
    }

    async function loadOffers() {
        const filters = getFilters();
        const clients = await ClientsFactory.list();
        let offers =  await DatabaseManager.get('Offer').getAll();
        offers = filterOffers(offers, filters, clients);
        vm.totalRows = offers.length;
        allFilteredOffers = offers;
        await getOfferList();
    }

    async function loadOffersOnline() {
        $rootScope.loader = true;
        const filters = getFilters();
        const clients = await ClientsFactory.list();
        const data = await $http.post(`${EnvConfig.remoteHost || window.location.origin}/api/sync/get_offers_list`, filters);
        vm.totalRows = data.data.totalRows;
        vm.offerList = data.data.offers.map(e => {
            e.currency = Core.parseJson(e.currency);
            e.status = e.status + '';
            e.dealer_status = e.dealer_status + '';
            if (!e.companyname && !e.clientname && !e.clientsurname) {
                const client = clients.find(el => el.tmp_id == e.client_id);
                if (client) {
                    e.clientDisplayName = (client.name ? client.name : '') + ' ' + (client.surname ? client.surname : '');
                }
            }
            return e;
        });
        $rootScope.loader = false;
        $scope.$apply();
    }

    function getFilters() {
        let dealerStatus;
        let status;
        if (($rootScope.user.access == 'klient' || !$rootScope.user.access) && vm.isOrder && !vm.dealerStatus) {
            dealerStatus = ['3', '5'];
        } else if (($rootScope.user.access == 'klient' || !$rootScope.user.access) && !vm.isOrder && !vm.dealerStatus) {
            dealerStatus = ['0', '1', '2', '4'];
        } else if (vm.dealerStatus) {
            dealerStatus = [vm.dealerStatus];
        } else {
            dealerStatus = ['0', '1', '2', '3', '5'];
            if (vm.status === '4' || vm.isShopOrder || vm.isShopOffer) {
                dealerStatus.push('4');
            }
        }

        if (vm.status) {
            status = [vm.status];
        } else if ($rootScope.user.access && $rootScope.user.access !== 'klient' && vm.isOrder) {
            status = ['0', '1', '2', '3', '4', '5', '8', '9', '10', '11', '12'];
        } else if ($rootScope.user.access && $rootScope.user.access !== 'klient' && vm.isShopOrder) {
            status = ['1', '2', '3', '4', '5', '6', '9', '10', '11'];
        } else if($rootScope.user.access && $rootScope.user.access !== 'klient' && vm.isShopOffer) {
            status = ['1'];
        } else {
            status = ['0', '1', '2', '3', '5'];
            if (vm.dealerStatus === '4') {
                status.push('4');
            }
        }

        return {
            search: vm.search,
            dateFrom: vm.dateFrom ? format(parseDate(vm.dateFrom), 'yyyy-MM-dd 00: 00: 00') : null,
            dateTo: vm.dateTo ? format(parseDate(vm.dateTo), 'yyyy-MM-dd 23: 59: 59') : null,
            status,
            dealerStatus,
            isOrder: vm.isOrder,
            isShopOrder: vm.isShopOrder,
            isShopOffer: vm.isShopOffer,
            onlyMine: vm.onlyMine,
            onlyOrdered: vm.onlyOrdered,
            onlyParents: vm.onlyParents,
            dealerId: ~~vm.dealerId,

            pageNumber: vm.currentPage,
            orderBy: vm.orderBy.substr(vm.orderBy.indexOf('.') + 1),
            itemsPerPage: vm.itemsPerPage,
            orderReverse: vm.orderReverse
        };
    }

    async function updateOfferList() {
        if (!vm.onlyMine && OnlineStatusService.getStatus()) {
            await loadOffersOnline();
        } else {
            $rootScope.loader = true;
            await loadOffers();
        }
    }

    /**
     *
     * @param  {array}  offers  Oferty
     * @param  {object} filters Filtry
     * @param  {array}  clients Lista klientów
     */
    function filterOffers(offers, filters, clients) {
        if (!offers || !offers.length) {
            return [];
        }
        offers = offers.filter(offer => {
            const client = clients.find(el => el.doc.tmp_id == offer.doc.client_id);
            let conditions = true;
            conditions = conditions && offer.doc.deleted == 0;
            conditions = conditions && offer.doc.order == (vm.isOrder ? '1' : '0');
            conditions = conditions && offer.doc.shop_order == (vm.isShopOrder || vm.isShopOffer ? '1' : '0');
            conditions = conditions && (vm.isShopOffer ? offer.doc.valuation == '1' : (vm.isShopOrder ? offer.doc.valuation == '0' : true));

            if (filters.search) {
                filters.search = filters.search.toLowerCase();
                let offerKey = '';
                if (angular.isString(offer.doc.key)) {
                    offerKey = offer.doc.key;
                } else if (angular.isArray(offer.doc.key)) {
                    offerKey = Core.stringJson(offer.doc.key);
                }
                conditions = conditions &&
                    (offerKey.toLowerCase().includes(filters.search) ||
                        offerKey.toLowerCase().replace(/["",]/g, '').includes(filters.search) ||
                            ($rootScope.user.market_offer_name && offer.doc.offer_name && offer.doc.offer_name.toLowerCase().includes(filters.search)) ||
                            ($rootScope.user.access == 'producent' && offer.doc.dealername && offer.doc.dealername.toLowerCase().includes(filters.search)) ||
                            ($rootScope.user.access == 'producent' && offer.doc.dealersurname && offer.doc.dealersurname.toLowerCase().includes(filters.search)) ||
                            ($rootScope.user.access == 'producent' && offer.doc.dealercompany && offer.doc.dealercompany.toLowerCase().includes(filters.search)) ||
                            ($rootScope.user.access == 'producent' && offer.doc.clientname && offer.doc.clientname.toLowerCase().includes(filters.search)) ||
                            ($rootScope.user.access == 'producent' && offer.doc.clientsurname && offer.doc.clientsurname.toLowerCase().includes(filters.search)) ||
                            (client &&
                                ((client.doc.name && client.doc.name.toLowerCase().includes(filters.search)) ||
                                    (client.doc.surname && client.doc.surname.toLowerCase().includes(filters.search))
                                ))
                            );
            }
            if (conditions && filters.dateFrom) {
                conditions = conditions && offer.doc.created >= filters.dateFrom;
            }
            if (conditions && filters.dateTo) {
                conditions = conditions && offer.doc.created <= filters.dateTo;
            }
            if (conditions && filters.status && !filters.onlyOrdered) {
                conditions = conditions && filters.status.some(el => Number(el) === Number(offer.doc.status));
            } else if (conditions && filters.onlyOrdered) {
                conditions = conditions && (vm.isShopOrder && offer.doc.ordered || offer.doc.status == 9);
            }
            if (conditions && filters.onlyParents) {
                conditions = conditions && offer.doc.parent_id === null;
            }
            if (conditions && filters.dealerStatus) {
                conditions = conditions && filters.dealerStatus.some(el => Number(el) === Number(offer.doc.dealer_status));
            }
            if (conditions && filters.dealerId) {
                conditions = conditions && offer.doc.dealer_id == filters.dealerId;
            }
            if (conditions && $rootScope.user.access == 'producent' && filters.onlyMine){
                conditions = conditions && offer.doc.seller_id == vm.seller.id;
            }
            if (conditions && $rootScope.user.access == 'dealerh' && !$rootScope.user.see_all_documents){
                conditions = conditions && offer.doc.dealer_seller_id == vm.dealerSeller.id;
            }
            return conditions;
        });
        offers.sort((a, b) => {
            if (filters.orderReverse && a.doc[filters.orderBy] < b.doc[filters.orderBy]) {
                return 1;
            } else if (!filters.orderReverse && a.doc[filters.orderBy] > b.doc[filters.orderBy]) {
                return 1;
            } else {
                return -1;
            }
        });

        return offers;
    }

    /**
     * Zmienia sortowanie ofert
     * @param  {string} field Pole po ktrym sortuje
     */
    function sortOffers(field) {
        if (vm.orderBy == field) {
            vm.orderReverse = !vm.orderReverse;
        } else {
            vm.orderBy = field;
            vm.orderReverse = false;
        }
        getOfferList();
    }

    /**
     * Zapisuje ustawienia listy
     */
    function saveFilters() {
        OfferViewFactory.filters = {
            currentPage: vm.currentPage,
            totalRows: vm.totalRows,
            orderBy: vm.orderBy,
            orderReverse: vm.orderReverse,
            itemsPerPage: vm.itemsPerPage,
            search: vm.search,
            dateFrom: vm.dateFrom,
            dateTo: vm.dateTo,
            archives: vm.archives,
            onlyMine: vm.onlyMine,
            onlyOrdered: vm.onlyOrdered,
            onlyParents: vm.onlyParents,
            status: vm.status,
            dealerStatus: vm.dealerStatus,
        };
    }
}
