import angular from 'angular';

export default function UniqueFilter() {
    'ngInject';

    return function(input) {
        if (typeof input == 'undefined') {
            return false;
        }
        var uniqueList = [];
        angular.forEach(input, function(v) {
            if (uniqueList.indexOf(v) === -1) {
                uniqueList.push(v);
            }
        });
        return uniqueList;
    };
}
