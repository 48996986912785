import { PriceFunc, PriceElemsData, PriceSegment } from './Prices';
import { CustomPricesRecords } from '@icc/common/custom-price/CustomPrice';
import { PriceAccessoryService } from './price-accessory.service';
import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';
import { IccAccessoryAccessory } from '@icc/common/data-types';
import { TranslateService } from '@icc/common/translate.service';

/**
 * Factory dopłat za okucie.
 *
 * @export
 * @param {object} Core Core
 * @return {object} Factory
 */
@Injectable()
export class PriceFittingService {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private priceAccessoryService: PriceAccessoryService,
        private translateService: TranslateService
    ) {}

    /**
     * Dolicza dopłatę procentową za okucie bezpieczne.
     *
     * @param {number} price         Wejsciowa cena
     * @param {object} PriceElems    Składowe wyceny
     * @param {array}  NoPriceCauses Powody braku wyceny
     * @param {array}  constrElems   Lista elementów konstrukcyjnych, do przeliczenia przez dopłatę.
     * @param {object} fitting       Okucie bezpieczne
     * @return {number} Cena z doliczoną dopłatą.
     */
    @PriceFunc({
        shortName: 'fittingPercent',
        data: {
            fitting: 'conf.Fitting',
            system: 'conf.System',
            customPrice: 'price.WindowFitting',
        },
    })
    suppFittingPercent(
        {  }: PriceElemsData,
        { fitting, system, customPrice }: { fitting; system; customPrice: CustomPricesRecords }
    ): PriceSegment[] {
        let factors = 1;
        if (Common.isObject(fitting) && ~~fitting.price_type === 1) {
            let fittingPrice;
            if (Common.isObject(customPrice) && customPrice[fitting.id]) {
                fittingPrice = customPrice[fitting.id].getPrice('price');
            } else {
                fittingPrice = parseFloat(fitting.price);
            }

            factors *= (100 + fittingPrice) / 100;

            return <PriceSegment[]>[
                {
                    type: 'fittingsPercent',
                    baseValue: factors,
                    value: factors,
                    valueType: 'percent',
                    data: {
                        id: fitting.id,
                        name: fitting.name,
                    },
                },
            ];
        }
        return [];
    }

    /**
     * Dolicza dopłatę kwotową za okucie bezpieczne.
     * @param  {number} price       Wejsciowa cena
     * @param  {object} PriceElems  Wycena
     * @param  {object} fitting     Okuсie bezpieczne
     * @return {number}             Cena z doliczonym okuciem
     */
    @PriceFunc({
        shortName: 'fitting',
        data: {
            fitting: 'conf.Fitting',
            sashes: 'conf.Sashes',
            customPrice: 'price.WindowFitting',
        },
    })
    suppFitting(
        {  }: PriceElemsData,
        { sashes, fitting, customPrice }: { sashes; fitting; customPrice: CustomPricesRecords }
    ): PriceSegment[] {
        const priceStack: PriceSegment[] = [];
        if (Common.isObject(fitting) && ~~fitting.price_type === 0) {
            for (let i = 0; i < sashes.length; i++) {
                if (
                    sashes[i].type.type !== 'F'
                    && sashes[i].type.type !== 'FF'
                    && sashes[i].type.type !== 'OFF'
                ) {
                    let fittingPrice;
                    if (Common.isObject(customPrice) && customPrice[fitting.id]) {
                        fittingPrice = customPrice[fitting.id].getPrice('price');
                    } else {
                        fittingPrice = parseFloat(fitting.price);
                    }
                    priceStack.push({
                        type: 'fittings',
                        baseValue: fittingPrice,
                        value: fittingPrice,
                        valueType: 'value',
                        data: {
                            id: fitting.id,
                            name: fitting.name,
                        },
                    });
                }
            }
        }
        return priceStack;
    }

    /**
     * Wylicza dopłatę za zawias w danym kolorze.
     * @param  {object} handle        Klamka
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Powody braku ceny
     * @param  {object} handleColor   Kolor klamki
     * @return {number}               Dopłata za klamkę
     */
    @PriceFunc({
        shortName: 'hinge',
        data: {
            hinge: 'conf.Hinge',
            hingeColor: 'conf.HingeColor',
            sashes: 'conf.Sashes',
            colors: 'conf.Colors',
            confType: 'conf.type',
        },
    })
    suppHinge({ NoPriceCauses }: PriceElemsData, { hinge, hingeColor, sashes, colors, confType }): PriceSegment[] {
        let supp = 0;
        if (hinge) {
            if (!hinge.show_colors && !hinge.no_price) {
                supp = Number(hinge.price_white || 0);
                supp += Number(hinge.price_for_assembly || 0);
                return <PriceSegment[]>[
                    {
                        type: 'hinge',
                        baseValue: !isNaN(supp) ? supp : null,
                        value: !isNaN(supp) ? supp : null,
                        valueType: 'value',
                        data: {
                            id: hinge.id,
                            name: hinge.name,
                            colorField: this.priceAccessoryService.getAccessoryPriceField(
                                colors,
                                confType
                            ),
                        },
                    },
                ];
            } else if (hinge.colors_prices && hingeColor && hinge.colors_prices[hingeColor.id]) {
                let price = parseFloat(hinge.colors_prices[hingeColor.id]);
                price += parseFloat(hinge.price_for_assembly) || 0;
                const count = sashes.reduce((prev, cur) => {
                    return (
                        prev + (['F', 'FF', 'OF'].indexOf(cur.type.type) === -1 ? 1 : 0)
                    );
                }, 0);
                return <PriceSegment[]>[
                    {
                        type: 'hinge',
                        baseValue: price * count,
                        value: price * count,
                        valueType: 'value',
                        data: {
                            id: hinge.id,
                            name: hinge?.name,
                            colorName: hingeColor?.name,
                            count,
                        },
                    },
                ];
            } else {
                if (this.config().IccConfig.Configurators.door.version === 2) {
                    NoPriceCauses.push('no hinge price');
                    return <PriceSegment[]>[
                        {
                            type: 'hinge',
                            baseValue: null,
                            value: null,
                            valueType: 'value',
                            data: {
                                id: hinge.id,
                                name: hinge?.name,
                                colorName: hingeColor?.name,
                            },
                        },
                    ];
                } else {
                    return [];
                }
            }
        } else {
            return [];
        }
    }

    /**
     * Dolicza dopłatę za zamek.
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {array}  accessories  Tablica z akcesoriami
     * @param  {object} colors       Kolory konstrukcji
     * @param  {string} type         Rodzaj konfiguracji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'lock',
        data: {
            lock: 'conf.Lock',
            system: 'conf.System',
            colors: 'conf.Colors',
            conf: 'conf.type',
            sashes: 'conf.Sashes',
        },
    })
    suppLock({  }: PriceElemsData, { lock, system, colors, conf, sashes }): PriceSegment[] {
        if (system?.default_market_configuration?.double_door_lock_id && sashes?.map(sash => sash.lock).length > 1) {
            const priceSegments = [];
            sashes.filter(sash => ['F', 'FF', 'OFF', 'DS', 'ODS', 'DSC', 'DRP', 'DOP'].indexOf(sash.type.type) === -1).forEach(sash => {
                let supp = 0;
                if (
                    this.config().IccConfig.Configurators.door.version < 2
                    || (this.config().IccConfig.Configurators.door.singleFix && !sash.lock.id)
                    || (system && system.door_type && !sash.lock?.id)
                ) {
                    return [];
                }

                const field = this.priceAccessoryService.getAccessoryPriceField(colors, conf);
                if (!sash.lock.show_colors && !sash.lock.no_price) {
                    supp = Number(sash.lock.price_white || 0)
                    supp += Number(sash.lock.price_for_assembly || 0)
                } else if (!sash.lock.no_price) {
                    supp = parseFloat(sash.lock[field]);
                    supp += parseFloat(sash.lock.price_for_assembly || 0);
                } else {
                    supp = NaN;
                }
                priceSegments.push(
                    {
                        type: 'lock',
                        baseValue: !isNaN(supp) ? supp : null,
                        value: !isNaN(supp) ? supp : null,
                        valueType: 'value',
                        data: {
                            id: sash.lock.id,
                            name: sash.lock.name,
                            colorField: this.priceAccessoryService.getAccessoryPriceField(colors, conf),
                        },
                    },
                );
            });
            return priceSegments;
        } else {
            let supp = 0;
            if (
                this.config().IccConfig.Configurators.door.version < 2
                || (this.config().IccConfig.Configurators.door.singleFix && !lock.id)
                || (system && system.door_type && !lock?.id)
            ) {
                return [];
            }

            let field = this.priceAccessoryService.getAccessoryPriceField(colors, conf);
            if (!lock.show_colors && !lock.no_price) {
                supp = Number(lock.price_white || 0)
                supp += Number(lock.price_for_assembly || 0)
            } else if (!lock.no_price) {
                supp = parseFloat(lock[field]);
                supp += parseFloat(lock.price_for_assembly || 0);
            } else {
                supp = NaN;
            }
            return <PriceSegment[]>[
                {
                    type: 'lock',
                    baseValue: !isNaN(supp) ? supp : null,
                    value: !isNaN(supp) ? supp : null,
                    valueType: 'value',
                    data: {
                        id: lock.id,
                        name: lock.name,
                        colorField: this.priceAccessoryService.getAccessoryPriceField(colors, conf),
                    },
                },
            ];
        }
    }

    @PriceFunc({
        shortName: 'doorHardware',
        data: {
            doorHardware: 'conf.doorHardware',
            colors: 'conf.Colors',
            colorsData: 'data.windowHandlesColors',
            doorHandleAdjustments: 'data.doorHandleAdjustments',
            hardwareTypes: 'data.hardwareTypes',
            pullType: 'data.pullType',
            electronicLocks: 'data.electronicLocks',
            plateTypes: 'data.plateTypes',
            doorViewers: 'data.doorViewers',
            plates: 'data.plates',
            cylinders: 'data.cylinders',
            decoHingeCovers: 'data.decoHingeCovers',
            conf: 'conf.type',
            doorType: 'conf.System.door_type',
        },
    })
    // eslint-disable-next-line max-statements
    suppDoorHardware(
        { NoPriceCauses }: PriceElemsData,
        {
            doorHardware,
            lock,
            colors,
            conf,
            colorsData,
            doorHandleAdjustments,
            hardwareTypes,
            pullType,
            electronicLocks,
            plateTypes,
            doorViewers,
            plates,
            cylinders,
            decoHingeCovers,
            doorType
        }
    ): PriceSegment[] {
        let supp = 0;
        const priceSegments: PriceSegment[] = [];
        if (!this.config().IccConfig.Configurators.door.advancedHardware || !doorType) {
            return [];
        }
        const field = this.priceAccessoryService.getAccessoryPriceField(colors, conf);

        const doorHandleAdjustment = core.fIdO<IccAccessoryAccessory>(
            doorHandleAdjustments,
            doorHardware.doorHandleAdjustment
        );
        if (doorHandleAdjustment) {
            if (!doorHandleAdjustment.show_colors && !doorHandleAdjustment.no_price) {
                supp = Number(doorHandleAdjustment.price_white || 0)
                supp += Number(doorHandleAdjustment.price_for_assembly || 0)
            } else if (!doorHandleAdjustment.no_price) {
                supp = parseFloat(doorHandleAdjustment[field]);
                supp += Number(doorHandleAdjustment.price_for_assembly || 0);
            } else {
                supp = NaN;
            }
            priceSegments.push({
                type: 'doorHandleAdjustment',
                baseValue: !isNaN(supp) ? supp : null,
                value: !isNaN(supp) ? supp : null,
                valueType: 'value',
                data: {
                    id: doorHandleAdjustment.id,
                    name: doorHandleAdjustment.name,
                    colorField: field,
                },
            });
        }
        const pullColor = core.fIdO<IccAccessoryAccessory>(colorsData, doorHardware.pullColor);
        const plateColor = core.fIdO<IccAccessoryAccessory>(colorsData, doorHardware.plates.color);
        const typeColor = core.fIdO<IccAccessoryAccessory>(colorsData, doorHardware.color);
        const colorId = plateColor && plateColor.id || typeColor && typeColor.id;
        const cylinderColor = core.fIdO<IccAccessoryAccessory>(colorsData, doorHardware.cylinders?.color);
        const cylinderColorId = cylinderColor?.id || colorId;
        const pull = core.fIdO<IccAccessoryAccessory>(pullType, doorHardware.pull);

        if (pull) {
            if (
                !pull.no_price && pull.show_colors
                && Common.isObject(pull.colors_prices)
                && Common.isDefined(pull.colors_prices[pullColor?.id])
            ) {
                supp = parseFloat(pull.colors_prices[pullColor?.id]);
                supp += Number(pull.price_for_assembly || 0);
            } else if (!pull.show_colors) {
                supp = Number(pull.price_white || 0)
                supp += Number(pull.price_for_assembly || 0)
            } else {
                supp = null;
            }
            priceSegments.push({
                type: 'pull',
                baseValue: !isNaN(supp) ? supp : null,
                value: !isNaN(supp) ? supp : null,
                valueType: 'value',
                data: {
                    id: pull.id,
                    name: pull.name,
                    colorField: field,
                },
            });
        }
        const electronicLock = core.fIdO<IccAccessoryAccessory>(
            electronicLocks,
            doorHardware.electronicLock?.id
        );

        let electronicLockColorPrice;
        if (electronicLock?.colors_ids !== null && electronicLock?.colors_prices) {
            const electronicLockColor = doorHardware.electronicLock.color;

            Object.entries(electronicLock?.colors_prices || {}).forEach(([key, value]) => {
                if (Number(key) === electronicLockColor) {
                    electronicLockColorPrice = value;
                }
            })
        }

        if (electronicLock) {
            if (!electronicLock.show_colors && !electronicLock.no_price){
                supp = Number(electronicLock.price_white || 0)
                supp += Number(electronicLock.price_for_assembly || 0)
            } else if (!electronicLock.no_price) {
                supp = parseFloat(electronicLockColorPrice);
                supp += Number(electronicLock.price_for_assembly || 0);
            } else {
                supp = NaN;
            }
            priceSegments.push({
                type: 'electronicLock',
                baseValue: !isNaN(supp) ? supp : null,
                value: !isNaN(supp) ? supp : null,
                valueType: 'value',
                data: {
                    id: electronicLock.id,
                    name: electronicLock.name,
                    colorField: field,
                },
            });
        }
        const plateType = core.fIdO<IccAccessoryAccessory>(plateTypes, doorHardware.plateType);
        if (plateType) {
            if (!plateType.show_colors && !plateType.no_price) {
                supp = Number(plateType.price_white || 0)
                supp += Number(plateType.price_for_assembly || 0)
            } else if (!plateType.no_price) {
                supp = parseFloat(plateType[field]);
                supp += Number(plateType.price_for_assembly || 0);
            } else{
                supp = NaN;
            }
            priceSegments.push({
                type: 'plateType',
                baseValue: !isNaN(supp) ? supp : null,
                value: !isNaN(supp) ? supp : null,
                valueType: 'value',
                data: {
                    id: plateType.id,
                    name: plateType.name,
                    colorField: field,
                },
            });
        }
        const doorViewer = core.fIdO<IccAccessoryAccessory>(doorViewers, doorHardware.doorViewer);
        if (doorViewer) {
            if (!doorViewer.show_colors && !doorViewer.no_price) {
                supp = Number(doorViewer.price_white || 0)
                supp += Number(doorViewer.price_for_assembly || 0)
            } else if (!doorViewer.no_price) {
                supp = parseFloat(doorViewer[field]);
                supp += Number(doorViewer.price_for_assembly || 0);
            } else {
                supp = NaN;
            }
            priceSegments.push({
                type: 'doorViewer',
                baseValue: !isNaN(supp) ? supp : null,
                value: !isNaN(supp) ? supp : null,
                valueType: 'value',
                data: {
                    id: doorViewer.id,
                    name: doorViewer.name,
                    colorField: field,
                },
            });
        }
        const additionalLockPlate = core.fIdO<IccAccessoryAccessory>(
            plates,
            doorHardware.plates.additionalLock
        );
        if (additionalLockPlate) {
            if (!additionalLockPlate.show_colors && !additionalLockPlate.no_price) {
                supp = Number(additionalLockPlate.price_white || 0)
                supp += Number(additionalLockPlate.price_for_assembly || 0)
            } else if (
                !additionalLockPlate.no_price
                && Common.isObject(additionalLockPlate.colors_prices)
                && Common.isDefined(additionalLockPlate.colors_prices[plateColor?.id])
            ) {
                supp = parseFloat(additionalLockPlate.colors_prices[plateColor?.id]);
                supp += Number(additionalLockPlate.price_for_assembly || 0);
            } else {
                supp = null;
            }
            priceSegments.push({
                type: 'additionalLockPlate',
                baseValue: !isNaN(supp) ? supp : null,
                value: !isNaN(supp) ? supp : null,
                valueType: 'value',
                data: {
                    id: additionalLockPlate.id,
                    name: additionalLockPlate.name,
                    colorField: field,
                },
            });
        }
        const mainLockCylinder = core.fIdO<IccAccessoryAccessory>(
            cylinders,
            doorHardware.cylinders.mainLock
        );
        if (mainLockCylinder) {
            if (!mainLockCylinder.show_colors && !mainLockCylinder.no_price) {
                supp = Number(mainLockCylinder.price_white || 0)
                supp += Number(mainLockCylinder.price_for_assembly || 0)
            } else if (
                !mainLockCylinder.no_price
                && Common.isObject(mainLockCylinder.colors_prices)
                && Common.isDefined(mainLockCylinder.colors_prices[cylinderColorId])
            ) {
                supp = parseFloat(mainLockCylinder.colors_prices[cylinderColorId]);
                supp += Number(mainLockCylinder.price_for_assembly || 0);
            } else {
                supp = null;
            }
            priceSegments.push({
                type: 'mainLockCylinder',
                baseValue: supp,
                value: supp,
                valueType: 'value',
                data: {
                    id: mainLockCylinder.id,
                    name: mainLockCylinder.name,
                    colorField: field,
                },
            });
        }

        const keyForMainLockCylinder = core.fIdO<IccAccessoryAccessory>(
            cylinders,
            doorHardware.cylinders.mainLock
        );
        if (keyForMainLockCylinder?.additional_key_for_the_cylinder) {
            if (
                !keyForMainLockCylinder.no_price
            ) {
                supp =
                    Number(doorHardware.cylinders.keyPriceForMainLock || 0) *
                    Number(doorHardware.cylinders.keyQuantityForMainLock || 0);
            } else {
                supp = null;
            }

            if (supp > 0) {
                priceSegments.push({
                    type: 'keyForMainLockCylinder',
                    baseValue: supp,
                    value: supp,
                    valueType: 'value',
                    data: {
                        name: this.translateService.instant(
                            'INTERFACE|Dodatkowe klucze do zamka głównego'
                        ),
                    },
                });
            }
        }

        const additionalLockCylinder = core.fIdO<IccAccessoryAccessory>(
            cylinders,
            doorHardware.cylinders.additionalLock
        );
        if (additionalLockCylinder) {
            if (!additionalLockCylinder.show_colors && !additionalLockCylinder.no_price) {
                supp = Number(additionalLockCylinder.price_white || 0)
                supp += Number(additionalLockCylinder.price_for_assembly || 0)
            } else if (
                !additionalLockCylinder.no_price
                && Common.isObject(additionalLockCylinder.colors_prices)
                && (Common.isDefined(additionalLockCylinder.colors_prices[cylinderColorId])
                    || Common.isDefined(additionalLockCylinder.colors_prices[colorId]))
            ) {
                supp = parseFloat(additionalLockCylinder.colors_prices[cylinderColorId]);
                supp += Number(additionalLockCylinder.price_for_assembly || 0);
            } else {
                supp = null;
            }
            priceSegments.push({
                type: 'additionalLockCylinder',
                baseValue: !isNaN(supp) ? supp : null,
                value: !isNaN(supp) ? supp : null,
                valueType: 'value',
                data: {
                    id: additionalLockCylinder.id,
                    name: additionalLockCylinder.name,
                    colorField: field,
                },
            });
        }

        const keyForAdditionalLockCylinder = core.fIdO<IccAccessoryAccessory>(
            cylinders,
            doorHardware.cylinders.additionalLock
        );
        if (keyForAdditionalLockCylinder?.additional_key_for_the_cylinder) {
            if (!keyForAdditionalLockCylinder.no_price) {
                supp =
                    Number(doorHardware.cylinders.keyPriceForAdditionalLock || 0) *
                    Number(doorHardware.cylinders.keyQuantityForAdditionalLock || 0);
            } else {
                supp = null;
            }

            if (supp > 0) {
                priceSegments.push({
                    type: 'keyForAdditionalLockCylinder',
                    baseValue: supp,
                    value: supp,
                    valueType: 'value',
                    data: {
                        name: this.translateService.instant(
                            'INTERFACE|Dodatkowe klucze do zamka dodatkowego'
                        ),
                    },
                });
            }
        }

        const type = core.fIdO<IccAccessoryAccessory>(hardwareTypes, doorHardware.type);

        if (Common.isObject(type)) {
            if (
                !type.no_price
                && Common.isObject(type.colors_prices)
                && Common.isDefined(type.colors_prices[colorId])
            ) {
                supp = parseFloat(type.colors_prices[colorId]);
                supp += Number(type.price_for_assembly || 0);
            } else {
                supp = null;
            }
            priceSegments.push({
                type: 'hardwareType',
                baseValue: supp,
                value: supp,
                valueType: 'value',
                data: {
                    id: type.id,
                    name: type.name,
                    colorName: typeColor && typeColor.name,
                },
            });
        }

        const mainLockPlate = core.fIdO<IccAccessoryAccessory>(
            plates,
            doorHardware.plates.mainLock
        );

        if (Common.isObject(mainLockPlate)) {
            if (!mainLockPlate.show_colors && !mainLockPlate.no_price) {
                supp = Number(mainLockPlate.price_white || 0);
                supp += Number(mainLockPlate.price_for_assembly || 0);
            } else if (
                !mainLockPlate.no_price
                && Common.isObject(mainLockPlate.colors_prices)
                && Common.isDefined(mainLockPlate.colors_prices[plateColor?.id])
            ) {
                supp = parseFloat(mainLockPlate.colors_prices[plateColor?.id]);
                supp += Number(mainLockPlate.price_for_assembly || 0);
            } else {
                supp = null;
            }
            priceSegments.push({
                type: 'mainLockPlate',
                baseValue: supp,
                value: supp,
                valueType: 'value',
                data: {
                    id: mainLockPlate.id,
                    name: mainLockPlate.name,
                    colorName: plateColor && plateColor.name,
                },
            });
        }

        const decorativeHingeCovers = core.fIdO<IccAccessoryAccessory>(
            decoHingeCovers,
            doorHardware.decorativeHingeCovers
        );
        const decorativeHingeCoversColor = core.fIdO<IccAccessoryAccessory>(colorsData, doorHardware.decorativeHingeCoversColor);
        if (Common.isObject(decorativeHingeCovers)) {
            if (
                Common.isObject(decorativeHingeCovers.colors_prices)
                && Common.isObject(decorativeHingeCoversColor)
                && Common.isDefined(decorativeHingeCovers.colors_prices[decorativeHingeCoversColor.id])
            ) {
                supp = parseFloat(decorativeHingeCovers.colors_prices[decorativeHingeCoversColor.id]);
                supp += Number(decorativeHingeCovers.price_for_assembly || 0);
            } else {
                supp = null;
            }
            priceSegments.push({
                type: 'decorativeHingeCovers',
                baseValue: supp,
                value: supp,
                valueType: 'value',
                data: {
                    id: decorativeHingeCovers.id,
                    name: decorativeHingeCovers.name,
                    colorName: decorativeHingeCoversColor && decorativeHingeCoversColor.name,
                },
            });
        }

        return priceSegments;
    }
}
