<form [formGroup]="filtersGroup" class="mat-elevation-z3">
    <div class="searchBox" >
          <mat-form-field *ngIf="systemTypes.length > 0">
              <mat-label>
                  {{ 'WINDOW|Typy systemów:' | translate }}
              </mat-label>
                <mat-select formControlName="type">
                    <mat-option value=''>{{'WINDOW|Dowolne' | translate}}</mat-option>
                    <mat-option *ngFor="let type of systemTypes" [value]="type.id">{{ type.name }}</mat-option>
                </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="systemGroups.length > 0">
            <mat-label>
                {{ 'WINDOW|Grupy systemów:' | translate }}
            </mat-label>
              <mat-select formControlName="groupId">
                    <mat-option value=''>{{'WINDOW|Dowolne' | translate}}</mat-option>
                    <mat-option *ngFor="let group of systemGroups" [value]="group.id">{{ group.name }}</mat-option>
              </mat-select>
            </mat-form-field>
    </div>
    <div class="searchBox" formArrayName="tags">
        <h4>{{ 'WINDOW|Tagi:' | translate }}</h4>
        <mat-checkbox *ngFor="let tag of systemTags; let i = index"  [formControlName]="i">
            {{ tag.name }}
        </mat-checkbox>
    </div>
    <button mat-raised-button color="primary" (click)="save()"
            >{{ 'INTERFACE|OK' | translate }}</button>
    </form>

