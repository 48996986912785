<icc-select-box
    [title]="'DOOR|Typ szyldu zamka głównego' | translate"
    [description]="(plateMainLock$ | async)?.name"
    [imageUrl]="(plateMainLockBoxSelect$ | async)?.imagePath"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="'INTERFACE|Opcje' | translate"
    (select)="selectPlateToMainLock()"
    (select2)="changePlateToMainLockOptions()"
    [descriptionTemplate]="typeBoxTemplate"
    [descriptionContext]="plateMainLockBoxSelect"
    [disabledButton1]="isPlatesToMainLockButtonDisabled()"
    [disabledButton2]="isPlatesToMainLockButton2Disabled()"
></icc-select-box>
<icc-select-box
    *ngIf="(plateAdditionalLock$ | async)"
    [title]="'DOOR|Typ szyldu zamka dodatkowego' | translate"
    [description]="(plateAdditionalLock$ | async)?.name"
    [imageUrl]="(plateAdditionalLockBoxSelect$ | async)?.imagePath"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="changePlateToAdditionalLock()"
    [disabledButton1]="isPlatesToAdditionalLockButtonDisabled()"
    ></icc-select-box>

<ng-template #typeBoxTemplate let-name="name" let-color="color" let-description="description">
    <p> <b>{{ 'DOOR|Typ osprzętu:' | translate }}</b> {{name}}</p>
    <p *ngIf="color">
        <b>{{ 'COLOR|Kolor:' | translate }}</b> {{color}}
    </p>
    <p ng-bind-html="description"></p>
</ng-template>
