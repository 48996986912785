import angular from 'angular';

import AccessoriesCtrl from './AccessoriesCtrl';
import AccessoriesFactory from './AccessoriesFactory';
import ColorsCtrl from './ColorsCtrl';
import ColorsFactory from './ColorsFactory';
import DimensionsCtrl from './DimensionsCtrl';
import DimensionsFactory from './DimensionsFactory';
import ModalColorCtrl from './ModalColorCtrl';
import PanelsCtrl from './PanelsCtrl';
import PanelsFactory from './PanelsFactory';
import SystemsCtrl from './SystemsCtrl';
import SystemsFactory from './SystemsFactory';

export default angular.module('icc.configurators.steps.garage', [])
    .controller('GarageAccessoriesCtrl', AccessoriesCtrl)
    .controller('GarageColorsCtrl', ColorsCtrl)
    .controller('GarageDimensionsCtrl', DimensionsCtrl)
    .controller('GaragePanelsCtrl', PanelsCtrl)
    .controller('GarageSystemsCtrl', SystemsCtrl)
    .controller('ModalColorCtrl', ModalColorCtrl)
    .factory('GarageAccessoriesFactory', AccessoriesFactory)
    .factory('GarageColorsFactory', ColorsFactory)
    .factory('GarageDimensionsFactory', DimensionsFactory)
    .factory('GaragePanelsFactory', PanelsFactory)
    .factory('GarageSystemsFactory', SystemsFactory)
    .name;
