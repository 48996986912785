import angular from 'angular';

export default function SelectIntFilter() {
    'ngInject';

    return function(elements) {
        angular.forEach(elements, function(el) {
            el.id = el.id * 1;
        });
        return elements;
    };
}
