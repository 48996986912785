import $ from 'jquery';

/**
 * Kontroler okna modal klamek
 * @param {object} $scope                Angular Scope
 * @param {object} $uibModalInstance        Okno modal
 * @param {object} $timeout              Timeout
 * @param {object} handles               Klamki
 * @param {object} selHandle             Ustawianie klamki
 * @param {object} handleY               Klamki Y
 * @param {object} sashHeight            Wysokość skrzydła
 * @param {object} handleHeights         Wysokość klamki
 * @param {object} showHeightSelect      Pokazanie selektu wyboru wysokości
 * @param {object} ConfigurationsService Fabryka konfiguracji
 * @param {object} handleCategories      Kategorie klamek
 * @param {object} handleSubcategories   Podkategorie klamek
 * @param {object} handleCatFilter       Filtr kategorii klamek
 * @param {object} handleData            Dane klamek
 * @param {object} handleColors          Kolory klamek
 * @param {object} selHandleColor        Utawianie koloru klamek
 * @param {object} b2c                   b2c
 * @param {object} sashType              Typ skrzydła
 * @param {object} position              Pozycja
 */

/**
     * Kontroler ciepłej ramki
     * @param {object} $scope             Angular scope
     * @param {object} $uibModalInstance  Okno modal
     * @param {object} windowFittings     Ciepła ramka
     * @param {object} selected           Wybór
     * @param {object} $timeout           Timeout
     */
export default function ModalFittingCtrl($scope, $uibModalInstance, windowFittings, selected, $timeout, ScrollbarService) {
    var vm = this;
    vm.windowFittings = windowFittings;
    vm.selected       = selected;
    vm.selectFitting  = selectFitting;
    vm.close          = close;
    vm.refresh        = refresh;

    /**
     * Funkcja wyboru okucia
     * @param  {object} fitting Okucie
     */
    function selectFitting(fitting) {
        $uibModalInstance.close(fitting);
    }

    /**
     * Funkcja zamykajaca okno modal
     */
    function close() {
        $uibModalInstance.close();
    }

    /**
     * Funkcja odświezajaca
     */
    function refresh() {
        ScrollbarService.update();
    }
}
