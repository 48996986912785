import angular from 'angular';
import { getDefaultShapeDimensions } from '@icc/window';

/**
 * Kontroler rozmiarów
 * @param {object} $scope                  Angular scope
 * @param {object} $filter                 Filtry
 * @param {object} $rootScope              Angular root scope
 * @param {object} $uibModal                  Dostarcza obsługę okien modalnych
 * @param {object} $timeout                Timeout
 * @param {object} PriceService            Liczenie cen
 * @param {object} ParametersService       Fabryka parametrów
 * @param {object} DimensionsService       Fabryka rozmiarów
 * @param {object} ConfigurationsService   Fabryka konfiguracji
 * @param {object} RollerDimensionsFactory Fabryka rozmiarów rolet
 * @param {object} StepFactory             Fabryka kroków
 */
export default function DimensionsCtrl(
    $scope,
    $filter,
    $rootScope,
    $uibModal,
    $timeout,
    PriceService,
    ParametersService,
    SystemsService,
    ConfiguratorsDataService,
    Core,
    AccessoriesService,
    DimensionsService,
    ConfigurationsService,
    RollerDimensionsFactory,
    SchemasService,
    StepFactory,
    IccConfig,
    RollerColorsService,
    ShapeService,
    ProfilesService,
    LayoutService,
    FillingValidationService,
    EventBusService,
    MosquitoDimensionsService,
    ScrollbarService,
    MeasurementsService,
    ResizeService
) {
    'ngInject';

    const vm = this;
    const configurators = ['window', 'hs', 'door', 'folding_door', 'sliding_door'];

    // Widok Wymiary
    vm.shape = 'rect';
    vm.shapeData = getDefaultShapeDimensions(
        'rect',
        ConfigurationsService.conf.Current.Width,
        ConfigurationsService.conf.Current.Height
    );
    vm.onChangeDimensions = onChangeDimensions;
    vm.dimensionsFields = {
        rect: [
            {
                name: 'width',
                field: 'width',
                show: () => true,
                focus: true,
            },
            {
                name: 'height',
                field: 'height',
                show: () => true,
            },
        ],
        triangle: [
            {
                name: 'h1',
                field: 'height',
                show: () => true,
                focus: true,
            },
            {
                name: 's1',
                field: 's1',
                show: () => ['F', 'L'].indexOf(vm.shapeData.type) >= 0 || vm.shapeData.s1 > 0,
                focus: true,
            },
            {
                name: 's2',
                field: 's3',
                show: () => ['F', 'R'].indexOf(vm.shapeData.type) >= 0 || vm.shapeData.s3 > 0,
                focus: true,
            },
        ],
        circle: [
            {
                name: 'diameter',
                field: 'd',
                show: () => true,
                focus: true,
            },
            {
                name: 'perimeter',
                value: () => Math.round(vm.shapeData.d * 3.14 * 100) / 100,
                show: () => true,
            },
        ],
        poligon: [
            {
                name: 's1',
                field: 's1',
                show: () =>
                    ['SLT', 'SLS', 'SLC', 'DLT', 'DLS', 'DLC'].indexOf(vm.shapeData.type) >= 0
                    || vm.shapeData.s1 > 0,
                focus: true,
            },
            {
                name: 's2',
                field: 's2',
                show: () =>
                    ['SLS', 'SRS', 'SLC', 'SRC', 'DLS', 'DLC'].indexOf(vm.shapeData.type) >= 0
                    || vm.shapeData.s2 > 0,
            },
            {
                name: 's3',
                field: 's3',
                show: () =>
                    ['SRT', 'SRS', 'SRC', 'DLT', 'DLS', 'DLC'].indexOf(vm.shapeData.type) >= 0
                    || vm.shapeData.s3 > 0,
            },
            {
                name: 'h1',
                field: 'h1',
                show: () => true,
            },
            {
                name: 'h2',
                field: 'h2',
                show: () =>
                    ['SRT', 'SRC', 'DLT', 'DLC'].indexOf(vm.shapeData.type) >= 0
                    || vm.shapeData.h2 > 0,
            },
            {
                name: 'h3',
                field: 'h3',
                show: () =>
                    ['SLT', 'SLC', 'DLT', 'DLC'].indexOf(vm.shapeData.type) >= 0
                    || vm.shapeData.h3 > 0,
            },
        ],
        arc: [
            {
                name: 'h1',
                field: 'h1',
                show: () => true,
                focus: true,
            },
            {
                name: 'h2',
                field: 'h2',
                show: () => true,
            },
            {
                name: 'width',
                field: 'width',
                show: () => true,
            },
        ],
    };

    /**
     * Funkcja zmiany rozmiaru
     */
    function onChangeDimensions() {
        if (
            vm.shapeData.shape == 'arc'
            && vm.shapeData.type == 'F'
            && vm.shapeData.h2 * 2 > vm.shapeData.width
        ) {
            vm.shapeData.h2 = Math.floor(vm.shapeData.width * 0.5);
        }
        if (vm.shapeData.shape == 'circle') {
            vm.shapeData.radius = vm.shapeData.d / 2;
        }

        DimensionsService.changedDimensions();
    }

    // Widok wybor kształtu
    vm.setShape = setShape;
    vm.addFile = addFile;
    vm.setShapeType = setShapeType;
    vm.attachment = ConfigurationsService.conf.Current.Attachments;
    vm.isAvailable = isAvailable;
    vm.onChangeArcType = onChangeArcType;

    // Widok wybór profili i płetw ramy, stali
    vm.onChangeRenoDimensions = onChangeRenoDimensions;
    vm.setSteel = setSteel;
    vm.frameSides = [];
    vm.isRenoFrames = false;
    vm.isMonoblockFrames = false;
    vm.isOneFrameProfile = false;
    vm.oneFinWidth = false;
    vm.oneMaxFinWidth = null;
    vm.onChangeOneFinWidth = onChangeOneFinWidth;

    /**
     * Wymuszenie przejścia na nowy krok, kiedy zmieniono ramę reno lub monoblok
     */
    function onChangeRenoDimensions(index) {
        if (
            IccConfig.Configurators.window.maxFinWidth
            && index != null
            && vm.frameSides[index].finWidth > vm.frameSides[index].maxFinWidth
        ) {
            vm.frameSides[index].finWidth = vm.frameSides[index].maxFinWidth;
        }

        DimensionsService.changedDimensions();
    }

    // Widok rolety
    vm.onBoxHeightChange = onBoxHeightChange;
    vm.setRoller = setRoller;
    vm.boxSizes = [];

    // Widok pomiarów na budowie
    vm.measurementsOnSite = MeasurementsService.measurementsOnSite;
    vm.showMeasurementsOnSite = () => MeasurementsService.openModalMeasurements(vm.shapeData);

    // Pozostała logika
    vm.valid = DimensionsService.valid.bind(DimensionsService);
    vm.b2c = !$rootScope.user || !$rootScope.user.access || $rootScope.user.access == 'klient';

    $scope.$on('changedStep', (event, newVal, oldVal) => {
        if (
            newVal.code != 'dimensions'
            && newVal.code !== oldVal.code
            && oldVal.code == 'dimensions'
        ) {
            if (
                vm.valid(vm.shapeData)
                && vm.frameSides.length
                && ConfigurationsService.conf.Current.Frames.length
            ) {
                resizeLayout();
                ProfilesService.setFrameFinWidths(
                    ConfigurationsService.conf.Current,
                    vm.frameSides.map(side => (side.type === 'reno' ? side.finWidth : null))
                );
                ProfilesService.setFrameFromIds(
                    ConfigurationsService.conf.Current,
                    vm.frameSides.map(side => (side.type === 'monoblock' ? side.id : null))
                );
                FillingValidationService.valid();
            } else {
                StepFactory.selectStep(oldVal.i);
            }
        }
    });

    if (DimensionsService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration(['initializedConfigurator'], () => init())
    );
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja skalujaca widok
     */
    function resizeLayout() {
        const oldWidth = ConfigurationsService.conf.Current.Width;
        const oldHeight = ConfigurationsService.conf.Current.Height;


        if (!IccConfig.Configurators.roller_shutter.extraDimensionsOptions) {
            SchemasService.setShuttersBySchema({
                width: vm.shapeData.width,
                height: vm.shapeData.height,
                nextStep: false,
            });
        }

        RollerDimensionsFactory.loadBoxHeights(vm.shapeData.height);
        RollerDimensionsFactory.updateDimensions();
        MosquitoDimensionsService.validateAndUpdateDimensions();

        DimensionsService.setDimensions(vm.shapeData);
        ResizeService.resizeLayout(
            ConfigurationsService.conf.Current.Width - oldWidth,
            ConfigurationsService.conf.Current.Height - oldHeight,
            ConfigurationsService.conf.Current
        );
        DimensionsService.validDoorModelDimensions(vm.shapeData);
        if (ConfigurationsService.conf.Current.hasRoller) {
            $rootScope.showInfo(
                $filter('translate')(
                    'ROLLERSHUTTER|Wymiary konstrukcji zostały zmienione. Sprawdź wymiary rolety.'
                ),
                null,
                null
            );
        }

        RollerDimensionsFactory.setDimensions();
        RollerDimensionsFactory.loadBoxHeights(vm.shapeData.height);
        RollerDimensionsFactory.updateDimensions();

        vm.boxSizes = RollerDimensionsFactory.boxSizes;
        ConfigurationsService.conf.Current.ChangedDimensions = false;
    }

    /**
     * Funkcja liczaca wysokośc
     */
    function onBoxHeightChange() {
        // DimensionsService.openModalDimensionChange(vm.shapeData);
        RollerDimensionsFactory.onBoxHeightChange();
    }

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        if (configurators.indexOf(ConfigurationsService.conf.Current.type) >= 0) {
            vm.shape = ConfigurationsService.conf.Current.Shape.shape;
            vm.shapeData = angular.copy(ConfigurationsService.conf.Current.Shape);
            vm.boxSizes = RollerDimensionsFactory.boxSizes;

            if (Number(ConfigurationsService.conf.Current.System.shortening_max) > 0) {
                vm.shorteningOptions = buildShorteningOptions();
            }
            setFrameSides();
        }
    }

    /**
     * Funkcja ustawiajaca kształt
     * @param {object} shape Kształt
     */
    function setShape(shape) {
        if ($rootScope.mistakeProductLoaded) {
            return;
        }
        vm.shape = shape;
        vm.shapeData = getDefaultShapeDimensions(
            'rect',
            ConfigurationsService.conf.Current.Width,
            ConfigurationsService.conf.Current.Height
        );
        if (IccConfig.Configurators.poligonsDLDisable && shape === 'poligon') {
            vm.shapeData.type = 'SRC';
            setShapeType();
        }
        ConfigurationsService.conf.Current.Shape = vm.shapeData;
        if (shape !== 'rect') {
            ConfigurationsService.conf.Current.hasRoller = false;
            PriceService.count();
            ParametersService.count(ConfigurationsService.conf.Current);
        }
        setFrameSidesOnShapeChange();
        LayoutService.resetLayout();
        refresh();
    }

    /**
     * Funkcja dodawania pliku
     */
    function addFile() {
        if (ConfigurationsService.conf.Current.Attachments.length && !vm.attachment.length) {
            $rootScope.showInfo(
                $filter('translate')('CONFIGURATOR|Załączniki zostały usunięte'),
                null
            );
        } else if (!ConfigurationsService.conf.Current.Attachments.length) {
            $rootScope.showInfo(
                $filter('translate')('CONFIGURATOR|Załączniki zostały dodane'),
                null
            );
        } else {
            $rootScope.showInfo(
                $filter('translate')('CONFIGURATOR|Załączniki zostały zmienione'),
                null
            );
        }
        ConfigurationsService.conf.Current.Attachments = vm.attachment;
    }

    /**
     * Funkcja ustawiania typu kształtu
     */
    function setShapeType() {
        vm.shapeData = getDefaultShapeDimensions(
            vm.shapeData.shape,
            ConfigurationsService.conf.Current.Width,
            ConfigurationsService.conf.Current.Height
        );
        ConfigurationsService.conf.Current.Shape = vm.shapeData;
        setFrameSidesOnShapeChange();
        LayoutService.resetLayout();
    }

    /**
     * Funkcja ustawiania rolet
     */
    function setRoller() {
        if (!IccConfig.Configurators.alwaysShowRollerStep) {
            StepFactory.switchEnable('rollershutter');
        }
        RollerColorsService.loadDefaultProfileColor();
        RollerDimensionsFactory.updateDimensions();

        AccessoriesService.removeAllFromSide('top');
        AccessoriesService.removeAllFromSide('left');
        AccessoriesService.removeAllFromSide('right');

        if (ConfigurationsService.conf.Current.System.type_frame == 'reno') {
            DimensionsService.changedDimensions();
        }

        ParametersService.count(ConfigurationsService.conf.Current);
    }

    /**
     * Funkcja ustawiania stali
     * @param {object} steel Stak
     */
    function setSteel(steel) {
        ConfigurationsService.conf.Default.Steel = ConfigurationsService.conf.Current.Steel;
        PriceService.count();
    }

    function onChangeArcType() {
        ConfigurationsService.conf.Current.Shape.arcType = vm.shapeData.arcType;
        PriceService.count();
    }

    function isAvailable(shape) {
        return ShapeService.isAvailable(shape, ConfigurationsService.conf.Current.System.id);
    }
    /**
     * Funkcja odpowiedzialna za oświezenie perfectscroll'a
     */
    function refresh() {
        ScrollbarService.update();
    }

    function setFrameSides() {
        if (
            !ConfigurationsService.conf.Current
            || !ConfigurationsService.conf.Current.Frames.length
        ) {
            return;
        }

        const conf = ConfigurationsService.conf.Current;
        const sides = ProfilesService.getFrameSidesOnEdge(conf);

        if (ProfilesService.loadedData) {
            vm.frameSides = sides.map((side, index) => {
                let profile = {};
                let type = 'std';
                let id = 0;
                let finWidth = null;
                let finWidths = [];
                let monoblockFrames = [];
                const frame = conf.Frames.find(f => f.id === side.frameEdges[0].frameId);
                const frameEdgeIndex = side.frameEdges[0].frameEdgeIndex;
                if (frame && frame.frame && frame.frame[frameEdgeIndex]) {
                    profile =
                        ProfilesService.getProfile(frame.frame[frameEdgeIndex].profileId) || {};
                    id = frame.frame[frameEdgeIndex].profileId;
                    finWidth =
                        frame.frame[frameEdgeIndex].finWidth != null
                            ? frame.frame[frameEdgeIndex].finWidth
                            : profile.finWidth;
                    finWidths = ProfilesService.getFinWidths(profile);
                    monoblockFrames = ProfilesService.getMonoblockFrames(side.sideSimple, conf);

                    if (profile.options && profile.options.indexOf('renovation_frame') > -1) {
                        type = 'reno';
                    } else if (conf.System && conf.System.type_frame === 'monoblock') {
                        type = 'monoblock';
                    }
                }

                vm.isRenoFrames = vm.isRenoFrames || type == 'reno';
                vm.isMonoblockFrames = vm.isMonoblockFrames || type == 'monoblock';

                return {
                    type,
                    profile,
                    side,
                    id,
                    finWidth,
                    finWidths,
                    monoblockFrames,
                    maxFinWidth: Math.max(...finWidths),
                };
            });
            let oneFinWidth = true;
            let oneMaxFinWidth = vm.frameSides[0].maxFinWidth;
            const finWidth = vm.frameSides[0].finWidth;
            for (const side of vm.frameSides) {
                if (side.maxFinWidth < oneMaxFinWidth) {
                    oneMaxFinWidth = side.maxFinWidth;
                }
                if (finWidth !== side.finWidth) {
                    oneFinWidth = false;
                }
            }
            vm.oneFinWidth = oneFinWidth;
            vm.oneMaxFinWidth = oneMaxFinWidth;
            vm.isOneFrameProfile =
                vm.frameSides.map(side => side.id).filter((v, i, a) => a.indexOf(v) === i).length
                === 1;
            // vm.oneFinWidth = vm.oneFinWidth && vm.isOneFrameProfile;
        }
    }

    function setFrameSidesOnShapeChange() {
        const sides = ProfilesService.getWindowSides({ Shape: vm.shapeData });

        if (sides.length != vm.frameSides.length) {
            vm.frameSides.length = sides.length;
            let startIndex = 0;
            if (vm.frameSides[1]) {
                startIndex = 1;
            }
            vm.frameSides.forEach((side, i) => {
                if (i > startIndex) {
                    vm.frameSides[i] = Core.copy(vm.frameSides[startIndex]);
                    delete vm.frameSides[i].$$hashKey;
                }
                vm.frameSides[i].side = sides[i];
            });
        }
        vm.isRenoFrames = vm.frameSides.some(side => side.type === 'reno');
        vm.isMonoblockFrames = vm.frameSides.some(side => side.type === 'monoblock');
    }

    function onChangeOneFinWidth() {
        if (
            IccConfig.Configurators.window.maxFinWidth
            && vm.frameSides[0].finWidth > vm.oneMaxFinWidth
        ) {
            vm.frameSides[0].finWidth = vm.oneMaxFinWidth;
        }
        vm.frameSides.forEach(side => {
            side.finWidth = vm.frameSides[0].finWidth;
        });
        onChangeRenoDimensions();
    }

    function showMeasurementsOnSite() {
        MeasurementsService.initMeasurements(ConfigurationsService.conf.Current);
    }

    function buildShorteningOptions() {
        let range = [];
        (ConfigurationsService.conf.Current.System.shortening || []).forEach(
            (s) => {
                let min = Number(s.min);
                const max = Number(s.max);
                const step = Number(s.step);
                if (max !== 0) {
                    while (step > 0 ? max >= min : max <= min) {
                        if (!range.includes(min)) {
                            range.push(min);
                        }
                        min += step;
                    }

                    if (range.indexOf(0) === -1) {
                        range.unshift(0);
                    }
                }
            }
        );
        return range.map(i => ({
            name: i,
            value: i,
        }));
    }
}
