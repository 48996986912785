<div class="select-box" [class.select-box--disabled]="disabled">
    <img
        class="select-box--img"
        [src]="imageUrl"
        [alt]="title"
        defaultImg
        *ngIf="!colorSelect && imageUrl"
    />
    <div
        *ngIf="colorSelect"
        class="select-box--color"
    >
        <span
            [style.backgroundColor]="color"
            [style.backgroundImage]="'url(' + imageUrl + ')'"
        ></span>
    </div>
    <p class="select-box--title">{{ title }}
        <button *ngIf="infoContent"
            class="item-imgTitleDescription-info-button mat-elevation-z0"
            mat-icon-button
            color="secondary"
            (click)="showInfoContent.emit()"
        >
            <mat-icon class="mat-18">info</mat-icon>
        </button>
    </p>
    <p class="select-box--description" [innerHtml]="description" *ngIf="!descriptionTemplate"></p>
    <div class="select-box--description" *ngIf="descriptionTemplate">
        <ng-container
            [ngTemplateOutlet]="descriptionTemplate"
            [ngTemplateOutletContext]="descriptionContext"
        ></ng-container>
    </div>
    <div class="select-box--button" [ngStyle]="buttonCustomStyle ? {'width' : 100 + '%' } : ''">
        <button
            [disabled]="disabled || disabledButton1"
            mat-raised-button
            color="primary"
            (click)="select.emit()"
            *ngIf="buttonText"
        >
            {{ buttonText | uppercase }}
        </button>
        <button
            [disabled]="disabled || disabledButton2"
            mat-raised-button
            color="primary"
            (click)="select2.emit()"
            *ngIf="buttonText2"
        >
            {{ buttonText2 | uppercase }}
        </button>
        <button
            [disabled]="disabled || disabledButton3"
            mat-raised-button
            color="primary"
            (click)="select3.emit()"
            *ngIf="buttonText3"
        >
            {{ buttonText3 | uppercase }}
        </button>
        <div *ngIf="buttonExpandMore">
            <button
                class="select-box--button-dropdown-arrow"
                color="primary"
                mat-raised-button
                [matMenuTriggerFor]="menu"
            >
                <mat-icon>expand_more</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
                <button mat-raised-button (click)="selectExpandMore.emit()" class="select-box--mat-menu-option">
                    {{ buttonExpandMore | uppercase }}
                </button>
            </mat-menu>
        </div>
    </div>
</div>
