<icc-select-box
    [title]="selectedItem?.name"
    [description]="selectedItem?.description"
    [imageUrl]="selectedItemImg"
></icc-select-box>

<mat-card class="mat-elevation-2" *ngIf="colors.length > 0">
    <div>
        <p>{{ 'COLOR|Wybierz kolor:' | translate }}</p>
        <icc-list [items]="colors" itemTemplate="itemColor" [scrollable]="false" [selected]="selColor" (select)="selectColor($event)">
        </icc-list>
    </div>
</mat-card>

<button type="button" mat-raised-button color="primary" (click)="save()">{{ 'INTERFACE|OK' | translate }}</button>
