import { Action } from '@ngrx/store';
import { ComponentType } from '@angular/cdk/portal';
import { PageComponent, ConfiguratorOptions, Issue, ReturnButton } from '@icc/configurator/shared';

export enum ConfiguratorActionTypes {
    LoadConfigurator = '[Configurator] Load Configurator',
    ChangeLanguage = '[Configurator] Change Language',
    ConfiguratorLoadError = '[Configurator] Configurator Load Error',
    SaveButtonClicked = '[Configurator] Save Button Clicked',
    StepChangedOnSave = '[Configurator] Step Changed On Save',
    SaveInformationDialog = '[Configurator] Save Information Dialog',
    SaveInformationDialogClosed = '[Configurator] Save Information Dialog Closed',
    SaveConfiguration = '[Configurator] Save Configuration',
    SaveConfigurationFailed = '[Configurator] Save Configuration Failed',
    SaveConfigurationRedirect = '[Configurator] Save Configuration Redirect',
    ExitConfigurator = '[Configurator] Exit Configurator',
    ExitConfiguratorHome = '[Configurator] Exit Configurator To Home',
    ResetConfigurator = '[Configurator] Reset Configurator',
    OpenPrintDialog = '[Configurator] Open print dialog',
    PrintDialogClosed = '[Configurator] Print dialog closed',
    CreateConfiguratorDealerOffer = '[Configurator] Create configurator dealer offer',
    ChangeCurrency = '[Configurator] Change Currency'
}

export class LoadConfigurator implements Action {
    readonly type = ConfiguratorActionTypes.LoadConfigurator;
    constructor(public payload: { options: ConfiguratorOptions }) {}
}

export class ChangeLanguage implements Action {
    readonly type = ConfiguratorActionTypes.ChangeLanguage;
    constructor(public payload: { languageCode: string }) {}
}

export class ResetConfigurator implements Action {
    readonly type = ConfiguratorActionTypes.ResetConfigurator;
}

export class ConfiguratorLoadError implements Action {
    readonly type = ConfiguratorActionTypes.ConfiguratorLoadError;
    constructor(public payload: any) {}
}

export class SaveButtonClicked implements Action {
    readonly type = ConfiguratorActionTypes.SaveButtonClicked;

    constructor(public payload?: { returnButton?: ReturnButton }) {}
}

export class StepChangedOnSave implements Action {
    readonly type = ConfiguratorActionTypes.StepChangedOnSave;

    constructor(public payload?: { returnButton?: ReturnButton }) {}
}

export class SaveConfiguration implements Action {
    readonly type = ConfiguratorActionTypes.SaveConfiguration;

    constructor(public payload?: {
        data?: any,
        returnButton?: ReturnButton
    }) {}
}

export class SaveConfigurationFailed implements Action {
    readonly type = ConfiguratorActionTypes.SaveConfigurationFailed;
}

export class SaveConfigurationRedirect implements Action {
    readonly type = ConfiguratorActionTypes.SaveConfigurationRedirect;

    constructor(public payload: {
        url: string
    }) {}
}

export class SaveInformationDialog implements Action {
    readonly type = ConfiguratorActionTypes.SaveInformationDialog;

    constructor(public payload: {
        issues: Issue[],
        isBlockedAddToOffer: boolean,
        message?: string
    }) {}
}

export class SaveInformationDialogClosed implements Action {
    readonly type = ConfiguratorActionTypes.SaveInformationDialogClosed;

    constructor(public payload: {
        action: any
    }) {}
}

export class OpenPrintDialog implements Action {
    readonly type = ConfiguratorActionTypes.OpenPrintDialog;

    constructor(public payload?: {
        returnButton?: ReturnButton,
        configurationShortcode?: string
    }) {}
}
export class CreateConfiguratorDealerOffer implements Action {
    readonly type = ConfiguratorActionTypes.CreateConfiguratorDealerOffer;
    constructor(public payload: {
        data: any,
        returnButton?: ReturnButton
    }) {}
}

export class PrintDialogClosed implements Action {
    readonly type = ConfiguratorActionTypes.PrintDialogClosed;
    constructor(public payload: {
        action: any
    }) {}
}

export class ExitConfigurator implements Action {
    readonly type = ConfiguratorActionTypes.ExitConfigurator;
}

export class ExitConfiguratorHome implements Action {
    readonly type = ConfiguratorActionTypes.ExitConfiguratorHome;
}

export class ChangeCurrency implements Action {
    readonly type = ConfiguratorActionTypes.ChangeCurrency;
    constructor(public payload: { currency: string }) {}
}

export type ConfiguratorAction = LoadConfigurator | ConfiguratorLoadError | SaveConfiguration | SaveInformationDialog | SaveInformationDialogClosed | ResetConfigurator | ChangeCurrency;

export const fromConfiguratorActions = {
    LoadConfigurator,
    ChangeLanguage,
    ConfiguratorLoadError,
    SaveButtonClicked,
    SaveConfiguration,
    SaveConfigurationRedirect,
    SaveInformationDialog,
    SaveInformationDialogClosed,
    ExitConfigurator,
    ExitConfiguratorHome,
    ResetConfigurator,
    OpenPrintDialog,
    PrintDialogClosed,
    CreateConfiguratorDealerOffer,
    ChangeCurrency
};
