import { Component, OnInit } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';
import { ConfigurationsService, DynamicElementsService } from '@icc/common';

@Component({
    selector: 'dimensions-info',
    templateUrl: './dimensions-info.component.html',
    styleUrls: ['./dimensions-info.component.scss'],
})
export class DimensionsInfoComponent extends PageComponent implements OnInit {
    public title = _('CONFIGURATOR|Jak mierzyć rolete?');

    dimensionsInfo = '';

    constructor(
        private dynamicElementsService: DynamicElementsService,
        private configurationsService: ConfigurationsService<'roller_shutter'>
    ) {
        super();
    }

    ngOnInit() {
        this.dimensionsInfo = this.dynamicElementsService.getElementContentBySlug(
            this.getRollerShutterSlug()
        );
    }

    getRollerShutterSlug(conf = this.configurationsService.conf.Current): string {
        if (!conf.RollerShutter?.type?.measureType) {
            return '';
        }

        const withBox = 'dimension-shutter-info';
        const withoutBox = 'dimension-shutter-info-without-box';

        switch (conf.RollerShutter.type.measureType) {
            case 'with_box':
                return withBox;
            case 'without_box':
                return withoutBox;
        }
    }
}
