<div class="dimensions-form box-form mat-elevation-z3">
    <form [formGroup]="dimensionsForm">
        <ng-container *ngFor="let field of fields">
            <mat-checkbox *ngIf="field.name == 'standardHeight' && field.type === 'checkbox'"
                [checked]="isStandardHeightSelected()"
                (change)="toggleStandardHeightField($event)"
                >{{ 'DIMENSION|Standardowa wysokość' | translate }}
            </mat-checkbox>
            <mat-form-field *ngIf="field.field && field.type === 'number'">
                <mat-label *ngIf="field.name == 'sashSize'" translate>DIMENSION|Rozmiar skrzydła</mat-label>
                <mat-label *ngIf="field.name == 'passiveSashSize'" translate>DIMENSION|Rozmiar skrzydła biernego</mat-label>
                <mat-label *ngIf="field.name == 'topLightSize'">{{ 'DIMENSION|Wysokość doświetla górnego:' | translate }}</mat-label>
                <mat-label *ngIf="field.name == 'leftLightSize'">{{ 'DIMENSION|Szerokość doświetla lewego:' | translate }}</mat-label>
                <mat-label *ngIf="field.name == 'rightLightSize'">{{ 'DIMENSION|Szerokość doświetla prawego:' | translate }}</mat-label>
                <mat-label *ngIf="field.name == 'customWidth'">{{ 'DIMENSION|Szerokość drzwi:' | translate }}</mat-label>
                <mat-label *ngIf="field.name == 'customHeight'">{{ 'DIMENSION|Wysokość drzwi:' | translate }}</mat-label>
                <input
                    matInput
                    iccDimensionInput
                    type="number"
                    [formControlName]="field.field"
                    [min]="getMin(field)"
                    [max]="getMax(field)"
                    [step]="field.step || 1"
                    [disabled]="field.disabled"
                />
                <span matSuffix>{{ dimensionUnit.unitSymbol }}</span>
                <mat-hint *ngIf="field.hintParams" >{{ 'DIMENSION|Wartość powinna mieścić się w zakresie od {min} do {max} ze skokiem co {step}' | translate: field.hintParams }}</mat-hint>
                <mat-error>
                    <span *ngIf="dimensionsForm.get(field.field).errors?.range">
                        {{ 'DIMENSION|Wartość powinna mieścić się w zakresie od {min} do {max} ze skokiem co {step}' | translate: dimensionsForm.get(field.field).errors?.range }}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="field.field && field.options.length > 0 && field.type === 'select'">
                <mat-label *ngIf="field.name == 'sashSize'" translate>DIMENSION|Rozmiar skrzydła</mat-label>
                <mat-label *ngIf="field.name == 'passiveSashSize'" translate>DIMENSION|Rozmiar skrzydła biernego</mat-label>
                <mat-label *ngIf="field.name == 'shortening'">{{ (seperateFrameAndSashShortening$ | async) ? ('DIMENSION|Skracanie skrzydła:' | translate) : ('DIMENSION|Skracanie:' | translate) }}</mat-label>
                <mat-label *ngIf="field.name == 'frameShortening'">{{ 'DIMENSION|Skracanie ościeżnicy:' | translate }}</mat-label>
                <mat-label *ngIf="field.name == 'topLightSize'">{{ 'DIMENSION|Wysokość doświetla górnego:' | translate }}</mat-label>
                <mat-label *ngIf="field.name == 'leftLightSize'">{{ 'DIMENSION|Szerokość doświetla lewego:' | translate }}</mat-label>
                <mat-label *ngIf="field.name == 'rightLightSize'">{{ 'DIMENSION|Szerokość doświetla prawego:' | translate }}</mat-label>
                <mat-select [formControlName]="field.field" [disabled]="field.disabled">
                    <ng-template ngFor let-option [ngForOf]="field.options">
                        <mat-option [value]="option.value">{{ option.name }}</mat-option>
                    </ng-template>
                </mat-select>
            </mat-form-field>
            <span *ngIf="field.value">
                <p>{{field.value}}</p>
                <span>{{ dimensionUnit.unitSymbol }}</span>
            </span>
        </ng-container>
    </form>
</div>
<icc-select-box
    [title]="('DOOR|Rodzaj ościeżnicy' | translate)"
    [infoContent]="isWysiwygEditorContentAvailable()"
    (showInfoContent)="clickShowInfoButton()"
    [description]="(getSelectedFrame())?.name || ('INTERFACE|Brak' | translate)"
    [imageUrl]="'/files/profile/' + ((getSelectedFrame())?.img || '')"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="changeFrame()"
    [disabledButton1]="isFrameButton1Disabled()"
></icc-select-box>
<icc-select-box
    [title]="('DOOR|Próg' | translate)"
    [description]="(selectedThreshold$ | async)?.name || ('INTERFACE|Brak' | translate)"
    [imageUrl]="'/files/profile/' + ((selectedThreshold$ | async)?.img || '')"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="changeThreshold()"
    [disabledButton1]="isThresholdButton1Disabled()"
></icc-select-box>
<div class="box-form mat-elevation-z3">
    <button [disabled]="!anyAvailableExtensions()" mat-flat-button color="primary" (click)="changeExtensions()">{{ 'DOOR|Ustaw poszerzenia' | translate }}</button>
</div>
