import { WicketDoorsService } from './wicket-doors.service';
import { InsertsService } from './inserts.service';
import { PanelsService } from './panels.service';
import { MechanismsService } from './mechanisms.service';
import { GarageDoorInitService } from './+state/garage-door-init.service';
import { GARAGEDOOR_FEATURE_KEY, garageDoorReducer, initialState as garageDoorInitialState } from './+state/garage-door.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { GarageDoorFacade } from './+state/garage-door.facade';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@icc/configurator/shared';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DimensionsComponent } from './dimensions/dimensions.component';
import { GarageDoorEffects } from './+state/garage-door.effects';
import { SystemComponent } from './system/system.component';
import { MechanismComponent } from './mechanism/mechanism.component';
import { PanelComponent } from './panel/panel.component';
import { DriveComponent } from './drive/drive.component';
import { WicketDoorComponent } from './wicket-door/wicket-door.component';
import { ConstructionComponent } from './construction/construction.component';
import { ModalListComponent } from './modal-list/modal-list.component';
import { WicketDoorColorComponent } from './wicket-door-color/wicket-door-color.component';
import { ModalInfoComponent } from './modal-info/modal-info.component';
import { PlinthPanelComponent } from './plinth-panel/plinth-panel.component';
import { GarageDoorDefaultsService } from './+state/garage-door-defaults.service';
import { DrivesService } from './drives.service';
import { ModalInsertsComponent } from './modal-inserts/modal-inserts.component';
import { ConfiguratorUiModule } from '@icc/configurator/ui';
import { ConfiguratorPagesModule } from '@icc/configurator/pages';



@NgModule({
    imports: [
        StoreModule.forFeature(GARAGEDOOR_FEATURE_KEY, garageDoorReducer, {
            initialState: garageDoorInitialState,
        }),
        EffectsModule.forFeature([GarageDoorEffects]),
        CommonModule,
        SharedModule,
        ConfiguratorUiModule,
        ConfiguratorPagesModule,
    ],
    providers: [GarageDoorFacade, GarageDoorInitService, GarageDoorDefaultsService, MechanismsService, PanelsService, DrivesService, InsertsService, WicketDoorsService],
    declarations: [
        SystemComponent,
        DimensionsComponent,
        MechanismComponent,
        PanelComponent,
        DriveComponent,
        WicketDoorComponent,
        ConstructionComponent,
        ModalListComponent,
        WicketDoorColorComponent,
        ModalInfoComponent,
        PlinthPanelComponent,
        ModalInsertsComponent,
    ],
    exports: [
        SystemComponent,
        DimensionsComponent,
        MechanismComponent,
        PanelComponent,
        DriveComponent,
        WicketDoorComponent,
        ConstructionComponent,
        ModalListComponent,
        WicketDoorColorComponent,
        ModalInfoComponent,
        PlinthPanelComponent,
        ModalInsertsComponent,
    ],
    entryComponents: [
        SystemComponent,
        DimensionsComponent,
        MechanismComponent,
        PanelComponent,
        DriveComponent,
        WicketDoorComponent,
        ConstructionComponent,
        ModalListComponent,
        WicketDoorColorComponent,
        ModalInfoComponent,
        PlinthPanelComponent,
        ModalInsertsComponent,
    ],
})
export class ConfiguratorGarageDoorModule {}
