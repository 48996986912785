import angular from 'angular';

export default function HandleSubCatsFilter() {
    'ngInject';

    return function(cats, categoryId) {
        var catArr = [];
        angular.forEach(cats, function(obj) {
            catArr.push(obj);
        });
        if (angular.isUndefined(categoryId) || categoryId == null) {
            return [];
        }
        if (parent !== null) {
            return catArr.filter(function(gl) {
                if (gl.parent_id == categoryId) {
                    return true;
                }
                return false;
            });
        }

    };
}
