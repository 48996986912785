const JoinPositionModalTemplate = require('ngtemplate-loader!./join-position-modal.component.html');
import { JoinPositionModalController } from './join-position-modal.controller';

export const JoinPositionModalComponent: ng.IComponentOptions = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
    templateUrl: JoinPositionModalTemplate,
    controller: JoinPositionModalController,
};
