export default function ModalSystemInfoCtrl($uibModalInstance, $sce, system, EventBusService) {
    'ngInject';

    var vm = this;
    vm.system = system;
    vm.close  = close;
    vm.trustedContent = $sce.trustAsHtml(vm.system.content);

    function close(sys) {
        $uibModalInstance.close(sys);
    }
}
