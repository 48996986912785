import { Action } from '@ngrx/store';
import { IMosquitoActiveConfiguration } from '@icc/common/configurations/MosquitoActiveConfiguration';

export enum MosquitoScreenActionTypes {
    LoadMosquitoScreen = '[MosquitoScreen] Load MosquitoScreen',
    LoadMosquitoScreenFromStartState = '[MosquitoScreen] Load MosquitoScreen From Start State',
    LoadMosquitoScreenDefaultsFromStartState = '[MosquitoScreen] Load MosquitoScreen Defaults From Start State',
    LoadMosquitoScreenAllDefaults = '[MosquitoScreen] Load MosquitoScreen All Defaults',
    LoadMosquitoScreenConfiguration = '[MosquitoScreen] Load MosquitoScreen Configuration',
    MosquitoScreenLoaded = '[MosquitoScreen] MosquitoScreen Loaded',
    MosquitoScreenLoadError = '[MosquitoScreen] MosquitoScreen Load Error',
    SaveMosquitoScreenConfiguration = '[MosquitoScreen] Save MosquitoScreen Configuration',
    UpdateMosquitoScreenConfiguration = '[MosquitoScreen] Update MosquitoScreen Configuration',
}

export class LoadMosquitoScreen implements Action {
    readonly type = MosquitoScreenActionTypes.LoadMosquitoScreen;
}

export class MosquitoScreenLoadError implements Action {
    readonly type = MosquitoScreenActionTypes.MosquitoScreenLoadError;
    constructor(public payload: any) {}
}

export class LoadMosquitoScreenFromStartState implements Action {
    readonly type = MosquitoScreenActionTypes.LoadMosquitoScreenFromStartState;
    constructor(public payload: { startState: IMosquitoActiveConfiguration }) {}
}

export class LoadMosquitoScreenDefaultsFromStartState implements Action {
    readonly type = MosquitoScreenActionTypes.LoadMosquitoScreenDefaultsFromStartState;
    constructor(public payload: { startState: IMosquitoActiveConfiguration }) {}
}

export class LoadMosquitoScreenAllDefaults implements Action {
    readonly type = MosquitoScreenActionTypes.LoadMosquitoScreenAllDefaults;
}

export class LoadMosquitoScreenConfiguration implements Action {
    readonly type = MosquitoScreenActionTypes.LoadMosquitoScreenConfiguration;
    constructor(public payload: { configuration: IMosquitoActiveConfiguration }) {}
}

export class MosquitoScreenLoaded implements Action {
    readonly type = MosquitoScreenActionTypes.MosquitoScreenLoaded;
}

export class SaveMosquitoScreenConfiguration implements Action {
    readonly type = MosquitoScreenActionTypes.SaveMosquitoScreenConfiguration;
}

export class UpdateMosquitoScreenConfiguration implements Action {
    readonly type = MosquitoScreenActionTypes.UpdateMosquitoScreenConfiguration;
    constructor(public payload: { configuration: IMosquitoActiveConfiguration }) {}
}

export type MosquitoScreenAction =
    | LoadMosquitoScreen
    | MosquitoScreenLoaded
    | MosquitoScreenLoadError
    | LoadMosquitoScreenFromStartState
    | LoadMosquitoScreenDefaultsFromStartState
    | LoadMosquitoScreenAllDefaults
    | LoadMosquitoScreenConfiguration
    | UpdateMosquitoScreenConfiguration;

export const fromMosquitoScreenActions = {
    LoadMosquitoScreen,
    MosquitoScreenLoaded,
    MosquitoScreenLoadError,
    LoadMosquitoScreenFromStartState,
    LoadMosquitoScreenDefaultsFromStartState,
    LoadMosquitoScreenAllDefaults,
    LoadMosquitoScreenConfiguration,
};
