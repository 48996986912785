<div class="product-elements">
    <div class="details-card mat-elevation-z1 data-for-offer" *ngIf='showOfferDataInputs'>
        <div class="details-card-header">{{'INTERFACE|Podstawowe dane' | translate}}</div>
        <div class="details-card-body">
            <ul>
                <li>
                    <span class="details-label">{{'INTERFACE|Nazwa' | translate}}</span>
                    <mat-form-field style="width: 85%;">
                        <input
                            matInput
                            (blur)='onNameChange($event.target.value)'
                            [value]='customConstructionName'
                            type="text"
                            min="1">
                    </mat-form-field>
                </li>
                <li *ngIf="config().preset === 'b2b' && !config().IccConfig.Configurators.configurationQuantityWithButtonsAndCustomName">
                    <span class="details-label">{{'CONFIGURATOR|Ilość' | translate}}</span>
                    <mat-form-field>
                        <input matInput (blur)='onQuantityChange($event.target.value)' type="number" min="1" [value]='quantity'>
                    </mat-form-field>
                </li>

                <li *ngIf="config().IccConfig.Configurators.configurationQuantityWithButtonsAndCustomName">
                    <span class="details-label">{{'CONFIGURATOR|Ilość' | translate}}</span>
                    <mat-form-field appearance="none">
                        <div class="details-quantity">
                            <button class="details-quantity__btn"
                                type="button"
                                mat-mini-fab
                                color=""
                                (click)="increaseQuantity()">+</button>
                            <input class="details-quantity__input"
                                type="text"
                                maxlength="3"
                                matInput
                                iccDigitsOnly
                                [allowLeadingZeroes]="false"
                                (blur)='onQuantityChange($event.target.value)'
                                [value]='quantity'>
                            <button class="details-quantity__btn"
                                type="button"
                                mat-mini-fab
                                color=""
                                (click)="decreaseQuantity()">-</button>
                        </div>
                    </mat-form-field>
                </li>

                <li *ngIf="!hiddenPrice">
                    <span class="details-label">{{'CONFIGURATOR|Cena (netto)' | translate}}</span> <span class="value">{{ price / quantity | currency:currency:null:false }} </span>
                </li>
                <li *ngIf="!hiddenPrice">
                    <span class="details-label">{{'CONFIGURATOR|Wartość (netto)' | translate}}</span> <span class="value">{{ price | currency:currency:null:false }}</span>
                </li>
            </ul>
        </div>
    </div>
    <div class="details-card mat-elevation-z1" *ngFor="let parent of detailsTree.$$children">
        <div class="details-card-header">{{parent.name}}</div>
        <div class="details-card-body">
            <ul>
                <li *ngFor="let el of parent.$$children">
                    <span class="details-label" *ngIf="el.name">{{el.name}}:</span> <span class="value" *ngIf="el.value">{{el.value}}</span>
                    <ul *ngIf="el.$$children && el.$$children.length > 0">
                        <li *ngFor="let el2 of el.$$children">
                            <span class="details-label" *ngIf="el2.name">{{el2.name}}:</span> <span class="value" *ngIf="el2.value">{{el2.value}}</span>
                            <ul *ngIf="el2.$$children && el2.$$children.length > 0">
                                <li *ngFor="let el3 of el2.$$children">
                                    <span class="details-label" *ngIf="el3.name">{{el3.name}}:</span> <span class="value" *ngIf="el3.value">{{el3.value}}</span>
                                    <ul *ngIf="el3.$$children && el3.$$children.length > 0">
                                        <li *ngFor="let el4 of el3.$$children">
                                            <span class="details-label" *ngIf="el4.name">{{el4.name}}:</span> <span class="value" *ngIf="el4.value">{{el4.value}}</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
