import angular from 'angular';

/**
 * @ngdoc filter
 * @name ICC.Configurators.colorsByGroup
 *
 * @description
 * Filtruje kolory wg grupy.
 */
export default function ColorsByGroupFilter() {
    'ngInject';

    /**
     * @func colorsByGroupFilter
     * @memberof colorsByGroup
     * @param  {object[]} colors Kolory
     * @param  {int}      group  Id grupy
     * @return {object[]}        Przefiltrowane kolory
     */
    return function(colors, group) {
        var colorsArr = [];
        angular.forEach(colors, function(obj) {
            colorsArr.push(obj);
        });
        return colorsArr.filter(function(color) {
            if (!angular.isUndefined(color.groups) && angular.isArray(color.groups)) {
                return color.groups.indexOf(group + '') != -1;
            } else {
                return false;
            }
        });
    };
}
