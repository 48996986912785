import { _ } from "../translations/translate-helper"

export const mountingLengths = [
    _("Halter A (55 - 73 mm)"),
    _("Halter B (73 - 103 mm)"),
    _("Halter C (103 - 155 mm)")
]
export const crankLengths = [
    _("ROLLERSHUTTER|min 50 cm długości"),
    _("ROLLERSHUTTER|1/2 wysokości żaluzji"),
    _("ROLLERSHUTTER|3/4 wysokości żaluzji")
]