import $ from 'jquery';

/**
 * @ngdoc controller
 * @name ICC.Configurators.ModalWoodCtrl
 *
 * @param {Service}  $uibModalInstance Angular $uibModalInstance
 * @param {Service}  $timeout       Angular $timeout
 * @param {object[]} woods          Rodzaje drewna
 * @param {object}   selWood        Wybrany rodzaj drewna
 *
 * @description
 *
 * Popup wyboru rodzaju drewna.
 */
export default function ModalWoodCtrl($uibModalInstance, $timeout, woods, selWood, ScrollbarService) {
    'ngInject';

    var vm = this;

    /**
     * Rodzaje drewna
     * @memberof ModalWoodCtrl
     * @type {object[]}
     */
    vm.woods       = woods;
    /**
     * Wybrany rodzaj drewna.
     * @memberof ModalWoodCtrl
     * @type {object}
     */
    vm.selWood     = selWood;

    vm.selectWood = selectWood;
    vm.isSelected  = isSelected;
    vm.close       = close;
    vm.refresh     = refresh;

    /**
     * Zamyka popup po wybraniu rodzaju drewna, zwaracając wybrany.
     * @memberof ModalWoodCtrl
     * @param  {object} wood Wybrany rodzaj drewna
     */
    function selectWood(wood) {
        $uibModalInstance.close(wood);
    }

    /**
     * Zwraca czy dany rodzaj drewna jest wybrany.
     * @memberof ModalWoodCtrl
     * @param  {objec}   color Rodzaj drewna
     * @return {Boolean}       Czy wybrany
     */
    function isSelected(color) {
        if (color.id == vm.selWood.id) {
            return true;
        }
        return false;
    }

    /**
     * Zamyka popup.
     * @memberof ModalWoodCtrl
     */
    function close() {
        $uibModalInstance.close();
    }

    /**
     * Odświeża scrollbar.
     * @memberof ModalWoodCtrl
     */
    function refresh() {
        ScrollbarService.update();
    }

}
