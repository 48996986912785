import angular from 'angular';

export default function StartCtrl($rootScope, $http, $timeout, OffersFactory, UserService, IccConfig, EnvConfig) {
    'ngInject';

    var vm = this;

    vm.documents            = [];
    vm.showClientPopup      = false;
    vm.clientAdditionalInfo = null;

    init();

    /**
     * Funkcja inicjalizująca
     */
    function init() {
        lastDocuments();
        checkShowPopup();

        if (IccConfig.Client.additionalInfo && angular.isObject($rootScope.user) && $rootScope.user.access == 'klient') {
            const client = UserService.getClient();
            vm.clientAdditionalInfo = client.additional_info || null;
        }
    }

    /**
     * Pobranie ostatnio zmienianych dokumentów
     */
    function lastDocuments() {
        if (angular.isDefined($rootScope.user)) {
            if ($rootScope.user.access === 'producent') {
                $http
                    .get((EnvConfig.remoteHost || window.location.origin) + '/dealer/sellers/orders.json')
                    .then(function(data) {
                        vm.documents = (data.data.orders || []).slice(0, 3);
                    });
            } else if ($rootScope.user.access === 'serwis') {
                $http
                    .get((EnvConfig.remoteHost || window.location.origin) + '/dealer/service_technicians/orders.json')
                    .then(function(data) {
                        vm.documents = (data.data.orders || []).slice(0, 3);
                    });
            } else {
                OffersFactory.last(3).then(function(offers) {
                    vm.documents = offers;
                });
            }
        } else {
            $timeout(function() {
                lastDocuments();
            }, 100);
        }
    }

    /**
     * Chowanie i pokazywanie okna w widoku klienta
     */
    function checkShowPopup() {
        if (sessionStorage.getItem('justLogged') == 'true') {
            vm.showClientPopup = true;
            sessionStorage.removeItem('justLogged');
        }
    }
}
