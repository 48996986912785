export default function SizeFilter() {
    'ngInject';

    return function(input, size) {
        if (size > 0) {
            var spl = input.split('-');
            if (spl.length == 2) {
                return spl[0] + '-' + size + '.png';
            } else {
                return input;
            }
        } else {
            return input;
        }
    };
}
