import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
    $uibModalProvider,
    $translateProvider,
    $injectorProvider,
    $sceProvider,
    currentConfiguratorServiceProvider,
    infoFactoryProvider,
} from './ajs-upgraded-providers';
import { BrowserConfigurationsService } from './configurations.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { ConfiguratorsAvailabilityService } from './configurators-availability.service';
import { DrawService } from '@icc/common/configurators/draw.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { BrowserIssuesService } from './issues.service';
import { IssuesService } from '@icc/helpers';
import { StepsModule } from '@icc/legacy/configurator/steps/steps.module';
import { LayoutModule } from '@icc/legacy/configurator/layout/layout.module';
import { DrawModule } from '@icc/legacy/configurator/draw/draw.module';
import { ValidationService } from '@icc/common/configurators/validation.service';
import { ColorsModule } from '@icc/common/colors/colors.module';
import { BrowserUserService } from '@icc/legacy/panel/user.service';
import { UserService } from '@icc/common/user.service';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { ResourceService } from '../sync/resource.service';
import { CoupledWindowModule } from './coupled/coupled-window.module';

@NgModule({
    imports: [
        CommonModule,
        StepsModule,
        LayoutModule,
        ColorsModule,
        DrawModule,
        CoupledWindowModule,
    ],
    declarations: [],
    exports: [],
    providers: [
        BrowserUserService,
        { provide: UserService, useExisting: BrowserUserService },
        ResourceService,
        EventBusService,
        BrowserConfigurationsService,
        { provide: ConfigurationsService, useExisting: BrowserConfigurationsService },
        $uibModalProvider,
        DrawService,
        $translateProvider,
        $sceProvider,
        infoFactoryProvider,
        { provide: IssuesService, useClass: BrowserIssuesService },
        currentConfiguratorServiceProvider,
        ParametersService,
        ValidationService,
        ConfiguratorsAvailabilityService,
    ],
})
export class ConfiguratorModule {}
