<mat-card>
    <mat-card-header>
        <img mat-card-avatar class="accessory-box-img"
             [src]="'/files/windowsill/' + accessory?.image"
             [alt]="accessory?.name"
             defaultImg>
        <mat-card-title class="accessory-box-title">{{accessory?.name}}
        </mat-card-title>
        <mat-card-subtitle class="accessory-box-description">
            {{accessory?.description}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="form">
            <mat-form-field *ngIf="accessory.pricing_data[0]?.colors.length > 0">
                <mat-label>{{ 'COLOR|Kolor:' | translate }}</mat-label>
                <mat-select formControlName="selectedColor">
                    <mat-option *ngFor="let color of accessory.colors_pricing;let id = index"
                                [value]="id">
                        {{color.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="accessory.plugs_data.length > 0">
                <mat-label>{{ 'GOODS|Zaślepki PCV:' | translate }}</mat-label>
                <mat-select formControlName="plugs">
                    <mat-option [value]="0">{{ 'INTERFACE|Brak' | translate}}</mat-option>
                    <mat-option [value]="1">{{ 'CONFIGURATOR|Standard' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="['raster', 'standard', 'm2'].includes(accessory.pricing_type)">
                <mat-label>{{ 'DIMENSION|Szerokość' | translate }}</mat-label>
                <input matInput iccDimensionInput *ngIf="accessory.in_range || accessory.pricing_type !== 'standard'" formControlName="currentWidth"
                            type="number"
                >
                <mat-select formControlName="currentWidth" *ngIf="!accessory.in_range && accessory.pricing_type === 'standard'">
                    <mat-option *ngFor="let width of getSillWidths(accessory)" [value]="width * 1">{{  width |unitmm:0:0 }}</mat-option>
                </mat-select>
                <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
            </mat-form-field>
            <mat-form-field *ngIf="accessory.max_length > 0">
                <mat-label>{{ 'DIMENSION|Długość' | translate }}</mat-label>
                <input matInput iccDimensionInput *ngIf="accessory.in_range || accessory.pricing_type !== 'standard'" formControlName="currentLength"
                            type="number"
                >
                <mat-select formControlName="currentLength" *ngIf="!accessory.in_range && accessory.pricing_type === 'standard'">
                    <mat-option *ngFor="let length of getSillLengths(accessory)" [value]="length * 1">{{  length |unitmm:0:0 }}</mat-option>
                </mat-select>
                <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
            </mat-form-field>
            <mat-checkbox *ngIf="['standard'].includes(accessory.pricing_type)" formControlName="in_range" (change)="setInRange(accessory)">{{ 'GOODS|Wymiary niestandardowe' | translate }}</mat-checkbox>

            <div class="accesoryBottom" *ngIf="accessory.addons_data.length > 0">
                <h5>{{ 'ACCESSORY|Dodatki' | translate }}</h5>
                <mat-checkbox  *ngFor="let addon of accessory.addons_data" ng-model="addon.selected">{{addon.name}}</mat-checkbox>
            </div>
            <mat-form-field>
                <input matInput type="number" min="1"
                      [placeholder]="'INTERFACE|Ilość' | translate"
                      formControlName="count">
            </mat-form-field>
            <mat-form-field>
                <input matInput type="text" formControlName="comment"
                       [placeholder]="'INTERFACE|Komentarz' | translate">
            </mat-form-field>
            <h4>{{ 'OFFER|Cena' | translate }}:
                {{ currentPrice$ | async | currency : currency }}</h4>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="add()"
                *ngIf="canAdd()">{{ 'INTERFACE|Dodaj' | translate }}</button>
    </mat-card-actions>


</mat-card>
