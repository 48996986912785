
<icc-select-box
[title]="'PLEATEDBLIND|Tkanina' | translate"
[description]="selectedMainFabric?.name + ' ' + '(' + selectedMainFabric?.color?.name + ')'"
[buttonText]="'INTERFACE|Zmień' | translate"
[buttonText2]="'INTERFACE|Opcje' | translate"
(select)="selectMainFabric()"
(select2)="changeMainFabricColor(selectedMainFabric)"
></icc-select-box>


<icc-select-box
*ngIf="(selectedVariant$ | async )?.double_fabric"
[title]="'PLEATEDBLIND|Tkanina dodatkowa' | translate"
[description]="selectedAdditionalFabric?.name + ' ' + '(' + selectedAdditionalFabric?.color?.name + ')'"
[buttonText]="'INTERFACE|Zmień' | translate"
[buttonText2]="'INTERFACE|Opcje' | translate"
(select)="selectAdditionalFabric()"
(select2)="changeAdditionalFabricColor(selectedAdditionalFabric)"
></icc-select-box>

<icc-select-box
        [title]="'COLOR|Kolor profili' | translate"
        [description]="(selectedProfilesColor$ | async)?.name"
        [color]="'#' + (selectedProfilesColor$ | async)?.color"
        [imageUrl]="'/files/windowcolor/' + (selectedProfilesColor$ | async)?.color_img"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [colorSelect]="true"
        (select)="changeProfilesColor()"
></icc-select-box>


