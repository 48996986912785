import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG } from '@icc/common/config';
import { core } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';
import { DatabaseManagerToken } from '@icc/helpers/browser';
import { EventBusService } from '@icc/common';

@Injectable()
export class MontagesDataService  {
    public loaded = false;
    private montages = [];
    private montageTypes = [];

    constructor(
        private eventBusService: EventBusService,
        @Inject(DatabaseManagerToken) private DatabaseManager,
    ) {
        this.load();

        this.eventBusService.subscribeWithoutConfiguration('syncedMontages', () => {
            this.reload();
        });
    }


    async reload() {
        this.montages = [];
        this.montageTypes = [];

        this.load();
    }

    async load() {
        const data: any = await this.DatabaseManager.get('Montages').get();
        if (data && data.data) {
            const parsedData = core.parseJson(data.data);
            if (Common.isObject(parsedData)) {
                this.montages = parsedData.montages || [];
                this.montageTypes = parsedData.montageTypes || [];
                this.loaded = true;
            }
        }
    }

    async getMontages() {
        if (this.montages && this.montages.length > 0) {
            return {
                montages: this.montages,
                montageTypes: this.montageTypes,
            };
        }
        await this.load();
        return {
            montages: this.montages, 
            montageTypes: this.montageTypes
        };
    }
}
