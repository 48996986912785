import { NgModule } from '@angular/core';
import { ReinforcementsService } from '@icc/common/configurators/reinforcements.service';
import { MeasurementsComponent } from './measurements.component';
import { MeasurementsService } from './measurements.service';
import { TranslatePipeModule } from '../../../../app/translate.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DimensionsService } from './dimensions.service';
import { ConstructionLimitationService } from './construction-limitation.service';

@NgModule({
    imports: [CommonModule, FormsModule, TranslatePipeModule],
    exports: [MeasurementsComponent],
    declarations: [MeasurementsComponent],
    entryComponents: [MeasurementsComponent],
    providers: [ReinforcementsService, MeasurementsService, ConstructionLimitationService, DimensionsService],
})
export class DimensionsModule {
    constructor() {}
}
