import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';
import { ProfilesService } from '@icc/common/profiles.service';
import { Injectable } from '@angular/core';

@Injectable()
export class OfferGroupService {
    constructor(private profilesService: ProfilesService) {}

    /**
     * dsa
     * @param  {string} positionsGroupsData Aktualne dane grup
     * @param  {object} sequence            Sequence
     * @param  {object} position            Pozycja
     * @return {object}                     Promise
     */
    updatePositionsGroupsData(positionsGroupsData, sequence, position) {
        if (IccConfig.Offer.groupsOfPositions) {
            positionsGroupsData = core.parseJson(positionsGroupsData);

            const groupCodes = [];
            const positionsGroupsCodes = Object.keys(positionsGroupsData);
            let i, key;

            for (i = 0; i < sequence.length; i++) {
                groupCodes[i] = Object.keys(sequence[i])[0];
            }

            for (key in positionsGroupsData) {
                if (groupCodes.indexOf(key) === -1) {
                    delete positionsGroupsData[key];
                }
            }

            if (Common.isObject(position)) {
                if (
                    positionsGroupsCodes.length === 0
                    || (Common.isDefined(position)
                        && positionsGroupsCodes.indexOf(position.doc.groupCode) === -1)
                ) {
                    const conf = position.doc.configuration;

                    positionsGroupsData[position.doc.groupCode] =  this.getPositionGroupData(position);
                }
            }

            positionsGroupsData = JSON.stringify(positionsGroupsData);
            return positionsGroupsData;
        } else {
            return positionsGroupsData;
        }
    }

    getPositionGroupData(position) {
        const conf = position.doc.configuration;

        const positionsGroupsData: any = {
            confType: position.doc.confType,
            Colors:
                Common.isDefined(conf.Colors) && conf.Colors !== null
                    ? conf.Colors
                    : null,
            ColorsSashExt:
                Common.isDefined(conf.ColorsSashExt) && conf.ColorsSashExt !== null
                    ? conf.ColorsSashExt
                    : null,
            HasAlushell:
                Common.isDefined(conf.HasAlushell) && conf.Colors !== null
                    ? conf.HasAlushell
                    : null,
            Wood: Common.isDefined(conf.Wood) && conf.Wood !== null ? conf.Wood : null,
            Fitting: {
                id:
                    Common.isObject(conf.Fitting)
                    && Common.isDefined(conf.Fitting.id)
                    && conf.Fitting.id !== null
                        ? conf.Fitting.id
                        : null,
                name:
                    Common.isObject(conf.Fitting)
                    && Common.isDefined(conf.Fitting.name)
                    && conf.Fitting.name !== null
                        ? conf.Fitting.name
                        : null,
            },
            Mullions: {},
            ProfileSet: Common.isDefined(conf.ProfileSet)
                ? core.copy(conf.ProfileSet)
                : null,
            FrameName:
                Common.isDefined(conf.ProfileSet)
                && Common.isDefined(conf.ProfileSet.frameTop)
                    ? this.profilesService.getProfile(conf.ProfileSet.frameTop)
                    : '',
            SashName:
                Common.isDefined(conf.ProfileSet)
                && Common.isDefined(conf.ProfileSet.sash)
                    ? this.profilesService.getProfile(conf.ProfileSet.sash)
                    : '',
            SashOutwardName:
                Common.isDefined(conf.ProfileSet)
                && Common.isDefined(conf.ProfileSet.sashOutward)
                    ? this.profilesService.getProfile(conf.ProfileSet.sashOutward)
                    : '',
            MullionName:
                Common.isDefined(conf.ProfileSet)
                && Common.isDefined(conf.ProfileSet.fixedMullion)
                    ? this.profilesService.getProfile(conf.ProfileSet.fixedMullion)
                    : '',
            Glazing: {
                id:
                    Common.isObject(conf.Sashes)
                    && Common.isDefined(conf.Sashes[0])
                    && conf.Sashes[0] !== null
                    && Common.isDefined(conf.Sashes[0].glazing)
                    && conf.Sashes[0].glazing !== null
                    && Common.isDefined(conf.Sashes[0].glazing.id)
                    && conf.Sashes[0].glazing.id !== null
                        ? conf.Sashes[0].glazing.id
                        : null,
                name:
                    Common.isObject(conf.Sashes)
                    && Common.isDefined(conf.Sashes[0])
                    && conf.Sashes[0] !== null
                    && Common.isDefined(conf.Sashes[0].glazing)
                    && conf.Sashes[0].glazing !== null
                    && Common.isDefined(conf.Sashes[0].glazing.name)
                    && conf.Sashes[0].glazing.name !== null
                        ? conf.Sashes[0].glazing.name
                        : null,
            },
            Handle: {
                id:
                    Common.isObject(conf.Handle)
                    && Common.isDefined(conf.Handle.id)
                    && conf.Handle.id !== null
                        ? conf.Handle.id
                        : null,
                name:
                    Common.isObject(conf.Handle)
                    && Common.isDefined(conf.Handle.name)
                    && conf.Handle.name !== null
                        ? conf.Handle.name
                        : null,
            },
            HandleColor: {
                id:
                    Common.isObject(conf.HandleColor)
                    && Common.isDefined(conf.HandleColor.id)
                    && conf.HandleColor.id !== null
                        ? conf.HandleColor.id
                        : null,
                name:
                    Common.isObject(conf.HandleColor)
                    && Common.isDefined(conf.HandleColor.name)
                    && conf.HandleColor.name !== null
                        ? conf.HandleColor.name
                        : null,
            },
            System: {
                id:
                    Common.isObject(conf.System)
                    && Common.isDefined(conf.System.id)
                    && conf.System.id !== null
                        ? conf.System.id
                        : null,
                name:
                    Common.isObject(conf.System)
                    && Common.isDefined(conf.System.name)
                    && conf.System.name !== null
                        ? conf.System.name
                        : null,
                short:
                    Common.isObject(conf.System)
                    && Common.isDefined(conf.System.short)
                    && conf.System.short !== null
                        ? conf.System.short
                        : null,
                type:
                    Common.isObject(conf.System)
                    && Common.isDefined(conf.System.type)
                    && conf.System.type !== null
                        ? conf.System.type
                        : null,
                title_image:
                    Common.isObject(conf.System)
                    && Common.isDefined(conf.System.title_image)
                    && conf.System.title_image !== null
                        ? conf.System.title_image
                        : null,
            },
            WarmEdge: {
                id:
                    Common.isObject(conf.WarmEdge)
                    && conf.WarmEdge?.id
                    && conf.WarmEdge.id !== null
                        ? conf.WarmEdge.id
                        : null,
                name:
                    Common.isObject(conf.WarmEdge)
                    && conf.WarmEdge?.name
                    && conf.WarmEdge.name !== null
                        ? conf.WarmEdge.name
                        : null,
            },
            RollerType:
                conf.type === 'roller_shutter' || conf.type === 'external_blind'
                    ? {
                            id:
                                Common.isObject(conf.RollerShutter.type)
                                && Common.isDefined(conf.RollerShutter.type.id)
                                && conf.RollerShutter.type.id !== null
                                    ? conf.RollerShutter.type.id
                                    : null,
                            name:
                                Common.isObject(conf.RollerShutter.type)
                                && Common.isDefined(conf.RollerShutter.type.name)
                                && conf.RollerShutter.type.name !== null
                                    ? conf.RollerShutter.type.name
                                    : null,
                        }
                    : null,
            RollerSystem:
                conf.type === 'roller_shutter' || conf.type === 'external_blind'
                    ? {
                            id:
                                Common.isObject(conf.RollerShutter.system)
                                && Common.isDefined(conf.RollerShutter.system.id)
                                && conf.RollerShutter.system.id !== null
                                    ? conf.RollerShutter.system.id
                                    : null,
                            name:
                                Common.isObject(conf.RollerShutter.system)
                                && Common.isDefined(conf.RollerShutter.system.name)
                                && conf.RollerShutter.system.name !== null
                                    ? conf.RollerShutter.system.name
                                    : null,
                            image:
                                Common.isObject(conf.RollerShutter.system)
                                && Common.isDefined(conf.RollerShutter.system.image)
                                && conf.RollerShutter.system.image !== null
                                    ? conf.RollerShutter.system.image
                                    : null,
                        }
                    : null,
            Name: conf.Name,
        };

        if (Common.isDefined(conf.Mullions) && Common.isObject(conf.Mullions)) {
            for (let i = 0; i < conf.Mullions.length; i++) {
                if (
                    !Common.isDefined(
                        positionsGroupsData.Mullions.static
                    )
                    && Common.isObject(conf.Mullions[i].div)
                    && conf.Mullions[i].type === 'static'
                ) {
                    positionsGroupsData.Mullions.static = {};
                    positionsGroupsData.Mullions.static.id =
                        conf.Mullions[i].div.id;
                    positionsGroupsData.Mullions.static.name =
                        conf.Mullions[i].div.name;
                }

                if (
                    !Common.isDefined(
                        positionsGroupsData.Mullions.movable
                    )
                    && Common.isObject(conf.Mullions[i].div)
                    && conf.Mullions[i].type === 'movable'
                ) {
                    positionsGroupsData.Mullions.movable = {};
                    positionsGroupsData.Mullions.movable.id =
                        conf.Mullions[i].div.id;
                    positionsGroupsData.Mullions.movable.name =
                        conf.Mullions[i].div.name;
                }
            }
        }
        return positionsGroupsData;
    }
}
