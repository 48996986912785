import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, Inject } from '@angular/core';
import { _, StepComponent, SharedFacade } from '@icc/configurator/shared';
import { AccessoryService } from '@icc/legacy/configurator/steps/awning/accessory.service';
import { EventBusService, ConfigurationsService, APP_CONFIG, AppConfigFactory } from '@icc/common';
import { ModalService, ICC_PAGE_DATA } from '@icc/helpers';
import { SystemsService } from '@icc/legacy/configurator/steps/window/systems/systems.service';
import { iccListItem } from '@icc/configurator/ui';
import { AwningSystemsInfoComponent } from '../..';
import { AwningAccessoriesInfoComponent } from '../awning-accessories-info/awning-accessories-info.component';

@Component({
  selector: 'icc-awning-add-accessories',
  templateUrl: './awning-add-accessories.component.html',
  styleUrls: ['./awning-add-accessories.component.scss']
})
export class AwningAddAccessoriesComponent extends StepComponent implements OnInit {
  public configurator = 'awning';
  public stepId = 'addAccessories';
  public title = _('AWNING|Dodaj akcesoria');
  public options = [];
  accessories : any;

  constructor(
      private sharedFacade: SharedFacade,
      private accessoriesService: AccessoryService,
      private modalService : ModalService,
      @Inject(APP_CONFIG) private config: AppConfigFactory,
  ) {
      super();
  }

  ngOnInit() {
    const accessories = this.accessoriesService.getAccessories();
    this.accessories = accessories.map(accessory => ({
      id: Number(accessory.id),
      title: accessory.name,
      imageUrl: '/files/awningaccessory/' + accessory.img,
  }));
  }

  showInfo(item: iccListItem) {
    const accessory = this.accessoriesService.accessories.find(s => Number(s.id) === item.id);
    this.modalService.open({
        pageComponent: AwningAccessoriesInfoComponent,
        resolve: {
          accessory: () => accessory,
        }
    }).result.then((result: boolean) => {
        if (result) {
            this.close(result);
        }
    });

  }

  close(accessory) {
    this.sharedFacade.closePage(accessory);
  }
}
