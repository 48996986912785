import {
    expandShape,
    getArcRadius,
    getDefaultShapeDimensions,
    getGlobalDimensions,
    getCircuit,
    Shape,
} from '@icc/window';
import { TranslateService } from '@icc/common/translate.service';
import { ConfigurationsService,  ConfiguratorsDataService, ParametersService, ShapeService, ValidationService, EventBusService, ProfilesService, UserService, WindowActiveConfiguration, Common, core, APP_CONFIG, AppConfigFactory, SizeRangeService } from '@icc/common';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { Injectable, Inject } from '@angular/core';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { PriceService } from '@icc/price';
import { StepsService, IssuesService, IssueLevel } from '@icc/helpers';
import { UnitConverterService } from '@icc/configurator/ui';
import { unitMMFormatter } from '@icc/configurator/shared';
import { PriceSashService } from 'libs/price/src/lib/price-sash.service';

@Injectable()
export class DimensionsService {

    loadedData = false;
    dimensionUnit = this.unitConverterService.getUnit();

    private configurators = ['window', 'hs', 'door', 'folding_door', 'sliding_door'];

    constructor (
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private issuesService: IssuesService,
        private parametersService: ParametersService,
        private shapeService: ShapeService,
        private validationService: ValidationService,
        private eventBusService: EventBusService,
        private profilesService: ProfilesService,
        private userService: UserService,
        private CurConfService: CurrentConfiguratorService,
        private priceService: PriceService,
        private StepFactory: StepsService,
        private sizeRangeService: SizeRangeService,
        private unitConverterService: UnitConverterService,
        private priceSashService: PriceSashService,
        // private RollerDimensionsFactory,

        private configuratorsDataService: ConfiguratorsDataService,
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        eventBusService.subscribe(['changedSashes', 'setFrameProfile'], data => {
            this.valid((data.activeConfiguration as WindowActiveConfiguration).Shape);
        });
    }

    /**
     * Funkcja inicjalizujaca
     */
    init() {
        if (this.configurators.indexOf(this.CurConfService.conf) === -1) {
            return;
        }
        const availableShapes = this.shapeService.getAvailableShapes(
            this.configurationsService.conf.Current.System.id
        );
        if (
            Common.isUndefined(this.configurationsService.conf.Current.Shape.shape)
            || availableShapes.indexOf(this.configurationsService.conf.Current.Shape.shape) === -1
        ) {
            this.setShape(availableShapes[0] || 'rect', null, true);
        }
        if (
            this.configurationsService.conf.Current.Width === null
            || this.configurationsService.conf.Current.Height === null
        ) {
            this.setGlobalDimensions();
        }
        if (this.configurationsService.conf.Current.hasRoller) {
            this.StepFactory.enable('rollershutter');
        }
        this.loadedData = true;
    }

    /**
     * Funkcja ustawiania kształtu
     * @param {object} shape Kształt
     * @param {object} conf  Konfiguracja
     * @param {object} def   Def
     */
    public setShape(shape, conf, def?) {
        if (Common.isUndefined(conf) || conf === null) {
            conf = this.configurationsService.conf.Current;
        }
        conf.Shape = getDefaultShapeDimensions('rect', conf.Width, conf.Height);
        if (def) {
            this.configurationsService.conf.Default.Shape = getDefaultShapeDimensions(
                'rect',
                conf.Width,
                conf.Height
            );
        }
        this.setGlobalDimensions();
        Object.assign(conf, this.validationService.valid(conf, 'shape'));
        this.eventBusService.post({
            key: 'setShape',
            value: {},
        });
    }

    /**
     * Funkcja ustawiania globalnego rozmiaru
     */
    setGlobalDimensions() {
        const { width, height } = getGlobalDimensions(this.configurationsService.conf.Current.Shape);
        this.configurationsService.conf.Current.Width = width;
        this.configurationsService.conf.Current.Height = height;
    }

    /**
     * Funkcja przeliczajaca obwód koła
     */
    recalculateCircuit() {
        this.configurationsService.conf.Current.Shape.circuit = getCircuit(
            this.configurationsService.conf.Current.Shape
        );
    }

    /**
     * Funkcja ustawiajace dane kształtu
     */
    public setShapeData() {
        this.configurationsService.conf.Current.Shape = expandShape(
            this.configurationsService.conf.Current.Shape
        );
    }

    /**
     * Funkcja ustawiajaca rozmiar
     * @param {object} dimensions Rozmiar
     */
    public setDimensions(dimensions, recalcParams = true) {
        this.configurationsService.conf.Current.Shape = core.copy(dimensions);
        this.setShapeData();
        this.setGlobalDimensions();
        Object.assign(this.configurationsService.conf.Current, this.validationService.valid(this.configurationsService.conf.Current, 'shape'));


        // odśwież wymiary akcesorii (#21491)
        const sideAccessories = this.configurationsService.conf.Current.SideAccessories;
        for (let x = 0; x < sideAccessories.bottom.length; ++x) {
            if (Number(sideAccessories.bottom[x].accessory.price_type) !== 1) {
                sideAccessories.bottom[x].amount =
                    dimensions.width + sideAccessories.sizes.left + sideAccessories.sizes.right;
            }
        }
        for (let x = 0; x < sideAccessories.top.length; ++x) {
            if (Number(sideAccessories.top[x].accessory.price_type) !== 1) {
                sideAccessories.top[x].amount = dimensions.width + sideAccessories.sizes.left + sideAccessories.sizes.right;
            }
        }
        for (let x = 0; x < sideAccessories.left.length; ++x) {
            if (Number(sideAccessories.left[x].accessory.price_type) !== 1) {
                sideAccessories.left[x].amount = dimensions.height;
            }
        }
        for (let x = 0; x < sideAccessories.right.length; ++x) {
            if (Number(sideAccessories.right[x].accessory.price_type) !== 1) {
                sideAccessories.right[x].amount = dimensions.height;
            }
        }

        // this.RollerDimensionsFactory.loadBoxHeights();
        if (recalcParams) {
            this.recalculateCircuit();
            this.priceService.count();
            this.parametersService.count(this.configurationsService.conf.Current);
        }
    }

    /**
     * Funkcja walidacji kształtu
     * @param  {object} shapeData Dane ksztłtu
     */
    public valid(shapeData) {
        const conf = this.configurationsService.conf.Current;
        if (shapeData.shape === 'rect' && (!shapeData.width || !shapeData.height)) {
            this.issuesService.simpleRegister(
                'no-window-dimensions',
                'Wymiary nie zostały określone.',
                this.translateService.instant('DIMENSION|Wymiary nie zostały określone.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        if (
            shapeData.shape === 'rect'
            && (shapeData.width < 250
                || shapeData.height < 25
                || ((conf.type !== 'door' || !conf.System.door_type)
                    && conf.Sashes.length
                    && conf.Sashes.some(
                        sash =>
                            (sash.rWidth / conf.Width) * shapeData.width < this.config().IccConfig.Configurators.minWidth
                            || (sash.rHeight / conf.Height) * shapeData.height < this.config().IccConfig.Configurators.minWidth
                    )))
        ) {
            this.issuesService.simpleRegister(
                'incorrect-window-dimensions',
                'Podane wymiary są nieprawidłowe.',
                this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        const arcFramesEdges = this.profilesService.getArcFramesEdges(conf);

        if (arcFramesEdges) {
            for (const edge of arcFramesEdges) {
                const arcProfile = this.profilesService.getProfile(edge.profileId);

                const coords =
                    shapeData.type === 'F'
                        ? [
                              { x: 0, y: shapeData.h2 },
                              { x: shapeData.width / 2, y: 0 },
                              { x: shapeData.width, y: shapeData.h2 },
                          ]
                        : [
                              { x: shapeData.width * -1, y: shapeData.h2 },
                              { x: 0, y: 0 },
                              { x: shapeData.width, y: shapeData.h2 },
                          ];

                const arcRadius = getArcRadius(coords);

                if (
                    arcProfile
                    && ((shapeData.shape === 'circle'
                        && shapeData.radius < Number(arcProfile.minBendRadius))
                        || (shapeData.shape === 'arc'
                            && arcRadius < Number(arcProfile.minBendRadius))
                        || (shapeData.shape === 'arc'
                            && Number(shapeData.h1) + Number(shapeData.h2) < 250))
                ) {
                    this.issuesService.simpleRegister(
                        'incorrect-window-dimensions',
                        'Podane wymiary są nieprawidłowe.',
                        this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                        conf,
                        {
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
            }
        }

        const angles = this.shapeService.getAngles(shapeData);
        const defaultProfile = this.profilesService.getProfile(conf.ProfileSet.frameSide);
        if (conf.drawData && conf.drawData.shape[0]) {
            const shapeDrawData = conf.drawData.shape[0];

            if (
                (shapeData.shape === 'triangle' || shapeData.shape === 'poligon')
                && angles.some((angle, index) => {
                    const angleEdges = shapeDrawData.angles[index] || shapeDrawData.angles[0];
                    const oneFrame = conf.Frames.find(f => angleEdges[0] && f.id === angleEdges[0].frameId);
                    const sideProfile = this.profilesService.getProfile(
                        (oneFrame && oneFrame.frame[angleEdges[0].frameEdgeIndex] || {
                            profileId: null
                        }).profileId
                    );
                    const nextFrame = conf.Frames.find(f => angleEdges[1] && f.id === angleEdges[1].frameId);
                    const sideProfileNext = this.profilesService.getProfile(
                        (nextFrame && nextFrame.frame[angleEdges[1].frameEdgeIndex] || {
                            profileId: null
                        }).profileId
                    );
                    return (
                        angle
                        < Math.max(
                            (sideProfile || defaultProfile).minAngle || 0,
                            (sideProfileNext || defaultProfile).minAngle || 0
                        )
                    );
                })
            ) {
                this.issuesService.simpleRegister(
                    'incorrect-window-dimensions',
                    'Podane wymiary są nieprawidłowe.',
                    this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                    conf,
                    {
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }
        }

        if (
            shapeData.shape == 'triangle'
            && (Number(shapeData.s1) + Number(shapeData.s3) < 250 || shapeData.height < 250)
        ) {
            this.issuesService.simpleRegister(
                'incorrect-window-dimensions',
                'Podane wymiary są nieprawidłowe.',
                this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        if (
            ['h1', 'h2', 'h3', 's1', 's2', 's3', 'height', 'width', 'radius', 'd'].some(
                field => Common.isDefined(shapeData[field]) && Number(shapeData[field]) < 0
            )
        ) {
            this.issuesService.simpleRegister(
                'incorrect-window-dimensions',
                'Podane wymiary są nieprawidłowe.',
                this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        this.issuesService.unregister('no-window-dimensions', conf);
        this.issuesService.unregister('incorrect-window-dimensions', conf);
        this.issuesService.unregister('incorrect-window-fins', conf);
        return true;
    }

    /**
     * Funkcja walidacji rozmiaru modelu drzwi
     * @param  {object} shapeData Dane kształtu
     */
    public validDoorModelDimensions(shapeData) {
        if (
            shapeData.shape === 'rect'
            && this.CurConfService.conf === 'door'
            && DoorActiveConfiguration.is(this.configurationsService.conf.Current)
            && this.configurationsService.conf.Current.Model
            && Number(this.configurationsService.conf.Current.Model.min_width) > 0
            && Number(this.configurationsService.conf.Current.Model.min_height) > 0
        ) {
            const sashes = this.configurationsService.conf.Current.Sashes;
            for (let i = sashes.length - 1; i >= 0; i--) {
                if (
                    sashes[i].rWidth < Number(this.configurationsService.conf.Current.Model.min_width)
                    || sashes[i].rHeight < Number(this.configurationsService.conf.Current.Model.min_height)
                ) {
                    this.issuesService.simpleRegister(
                        'incorrect-window-model-dimensions',
                        'Wartości przycięcia płetwy są niepoprawne',
                        this.translateService.instant(
                            'CONFIGURATOR|Wartości przycięcia płetwy są niepoprawne'
                        ),
                        this.configurationsService.conf.Current,
                        {
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
            }
        }
        this.issuesService.unregister(
            'incorrect-window-model-dimensions',
            this.configurationsService.conf.Current
        );
    }

    public changeMountingMethod(measurements, mountingMethod) {
        measurements.mountingMethod = mountingMethod;
    }

    public changedDimensions() {
        if (!this.userService.b2c || !this.configurationsService.conf.offerConfigurator) {
            this.configurationsService.conf.Current.ChangedDimensions = true;
        }
    }

    getMinDimensions(width: number, height: number, widthChange: boolean) {
        const windowLines = this.configuratorsDataService.data.windowLines?.map(line => ({id: line.id, name: line.name})) || [];
        const windowLinesId = windowLines.map((line: any) => Number(line.id));
        const windowPrices = Object.values(this.configuratorsDataService.data.windowPrices)
            .filter((windowPrice: any) => windowPrice.data)
            .filter((p: any) =>
                p.systems_profile_sets.some((a: any) => windowLinesId.includes(Number(a.window_line_id))))
            .map((windowPrice: any) => windowPrice.data)
            .reduce((result ,price) => result.concat(price), [])
            .map((price: any) => ({width_from: Number(price.width_from), width_to: Number(price.width_to), height_from: Number(price.height_from), height_to: Number(price.height_to)}));

        const prices = {
            width: windowPrices.filter((price: any) => price.width_from <= width && price.width_to >= width),
            height: windowPrices.filter((price: any) => price.height_from <= height && price.height_to >= height)
        };
        const firstType = {
            result: widthChange ? 'minWidth' : 'minHeight',
            price: widthChange ? 'width' : 'height',
            property: widthChange ? 'width_from' : 'height_from'
        };
        const secundType = {
            result: widthChange ? 'minHeight' : 'minWidth',
            price: widthChange ? 'height' : 'width',
            property: widthChange ? 'height_from' : 'width_from'};
        const result = {
            minWidth:  Number.MAX_SAFE_INTEGER,
            minHeight: Number.MAX_SAFE_INTEGER
        };
        if(prices[firstType.price].length > 0) {
            result[firstType.result] = windowPrices.reduce((prev: any, current: any) => (prev[firstType.property] < current[firstType.property]) ? prev : current)[firstType.property];
            result[secundType.result] = prices[firstType.price].reduce((prev: any, current: any) => (prev[secundType.property] < current[secundType.property]) ? prev : current)[secundType.property];
        } else if(prices[secundType.price].length > 0) {
            result[secundType.result] = windowPrices.reduce((prev: any, current: any) => (prev[secundType.property] < current[secundType.property]) ? prev : current)[secundType.property];
            result[firstType.result] = prices[secundType.price].reduce((prev: any, current: any) => (prev[firstType.property] < current[firstType.property]) ? prev : current)[firstType.property];
        } else if(windowPrices.length > 0) {
            result[firstType.result] = windowPrices.reduce((prev: any, current: any) => (prev[firstType.property] < current[firstType.property]) ? prev : current)[firstType.property];
            result[secundType.result] = windowPrices.reduce((prev: any, current: any) => (prev[secundType.property] < current[secundType.property]) ? prev : current)[secundType.property];
        }

        return result;
    }

    getDimensionMessageBaseOnPrice(price, width, height) {
        let dimensionMessage = '';
        const hasWidth = price.flat().filter((a: any) => Number(a.width_from) <= Number(width) && Number(a.width_to) >= Number(width));
        if(hasWidth.length > 0) {
            const minHeight = hasWidth.reduce((a: any,b: any) => Number(b.height_from) < Number(a.height_from) ? b : a).height_from;
            const maxHeight = hasWidth.reduce((a: any,b: any) => Number(b.height_to) > Number(a.height_to) ? b : a).height_to;

            dimensionMessage = Number(minHeight) > Number(height) ?
                this.translateService.instant("WINDOW|Przy szerokości konstrukcji {width}, minimalna wysokość to {minHeight}", {width: this.formatUnit(width), minHeight: this.formatUnit(minHeight)})
                : this.translateService.instant("WINDOW|Przy szerokości konstrukcji {width}, maksymalna wysokość to {maxHeight}", {width: this.formatUnit(width), maxHeight: this.formatUnit(maxHeight)});
        }

        const hasHeight = price.flat().filter((a: any) => Number(a.height_from) <= Number(height) && Number(a.height_to) >= Number(height));
        if(hasHeight.length > 0 && hasWidth.length === 0) {
            const minWidth = hasHeight.reduce((a: any,b: any) => Number(b.width_from) < Number(a.width_from) ? b : a).width_from;
            const maxWidth = hasHeight.reduce((a: any,b: any) => Number(b.width_to) > Number(a.width_to) ? b : a).width_to;

            dimensionMessage = Number(minWidth) > Number(width) ?
                this.translateService.instant("WINDOW|Przy wysokości konstrukcji {height}, minimalna szerokość to {minWidth}", {height: this.formatUnit(height), minWidth: this.formatUnit(minWidth)})
                : this.translateService.instant("WINDOW|Przy wysokości konstrukcji {height}, maksymalna szerokość to {maxWidth}", {height: this.formatUnit(height), maxWidth: this.formatUnit(maxWidth)});
        }

        if(hasHeight.length === 0 && hasWidth.length === 0 && price.length > 0 ) {
            const minWidth = price.flat().reduce((a: any,b: any) => Number(b.width_from) < Number(a.width_from) ? b : a).width_from;
            const maxWidth = price.flat().reduce((a: any,b: any) => Number(b.width_to) > Number(a.width_to) ? b : a).width_to;
            const minHeight = price.flat().reduce((a: any,b: any) => Number(b.height_from) < Number(a.height_from) ? b : a).height_from;
            const maxHeight = price.flat().reduce((a: any,b: any) => Number(b.height_to) > Number(a.height_to) ? b : a).height_to;
            if(Number(minWidth) > Number(width)) {
                dimensionMessage = this.translateService.instant("WINDOW|Minimalna szerokość konstrukcji to {minWidth}", {minWidth: this.formatUnit(minWidth)});
            } else {
                dimensionMessage = this.translateService.instant("WINDOW|Maksymalna szerokość konstrukcji to {maxWidth}", {maxWidth: this.formatUnit(maxWidth)});
            }
            if(Number(minHeight) > Number(height)) {
                dimensionMessage = dimensionMessage + " " + this.translateService.instant("WINDOW|Minimalna wysokość konstrukcji to {minHeight}", {minHeight: this.formatUnit(minHeight)});
            } else {
                dimensionMessage = dimensionMessage + " " + this.translateService.instant("WINDOW|Maksymalna wysokość konstrukcji to {maxHeight}", {maxHeight: this.formatUnit(maxHeight)});
            }
        }

        return dimensionMessage;
    }

      /**
     * Funkcja pobierająca zakres rozmiarów HS
     */
      getSizeRange(full = true, w: number | undefined = undefined, h: number | undefined = undefined) {
        const conf = this.configurationsService.conf.Current
        const price = this.getPriceData()
        let result: {
            minWidth: number,
            maxWidth: number,
            minHeight:number,
            maxHeight: number
        } = {minWidth: -1, maxWidth: -1, minHeight: -1, maxHeight: -1};
        const height =  h || conf.Height;
        const width = w || conf.Width;

        if(price) {
            let matchedPricesDataWidth = price;
            let matchedPricesDataHeight = price;
            if(!full) {
                matchedPricesDataWidth = price.filter(matchedPrice => matchedPrice.height_from <= height && matchedPrice.height_to >= height);
                matchedPricesDataHeight = price.filter(matchedPrice => matchedPrice.width_from <= width && matchedPrice.width_to >= width);
            }
            result = {
                maxWidth: matchedPricesDataWidth.length > 0 ? matchedPricesDataWidth.reduce((prev, current) => Number(prev.width_to) > Number(current.width_to) ? prev : current).width_to: -1,
                minWidth: matchedPricesDataWidth.length > 0 ? matchedPricesDataWidth.reduce((prev, current) => Number(prev.width_from) < Number(current.width_from) ? prev : current).width_from : -1,
                maxHeight: matchedPricesDataHeight.length > 0 ? matchedPricesDataHeight.reduce((prev, current) => Number(prev.height_to) > Number(current.height_to) ? prev : current).height_to : -1,
                minHeight: matchedPricesDataHeight.length > 0 ? matchedPricesDataHeight.reduce((prev, current) => Number(prev.height_from) < Number(current.height_from) ? prev : current).height_from : -1,
            };
        }
        return result;
    }

    private getPriceData() {
        const conf = this.configurationsService.conf.Current;
        let lineIdex = 'windowLines';
        switch(conf.type) {
            case 'sliding_door':
                lineIdex = 'slidingDoorLines'; break;
            case 'door': lineIdex = 'doorLines'; break;
            case 'hs': lineIdex = 'hsLines'; break;
            case 'folding_door': lineIdex = 'foldingDoorLines'; break;
        }

        const prices: any[] = Object.values(this.configuratorsDataService.data.windowPrices || {});
        const lineIds = this.configuratorsDataService.data[lineIdex]?.map(line => Number(line.id)) || [];
        const layoutsCode = this.getLayouts(conf, lineIds);

        const priceForLines = prices.filter((p: any) =>
            p.systems_profile_sets.some(system =>
                    lineIds.includes(Number(system.window_line_id))
                )
            )
            .filter(price => price.sashes_layouts.some(layout =>
                    layoutsCode.includes(layout.toString())
                )
            )
            .map((windowPrice: any) =>
                windowPrice.data
            )
            .reduce((result: any ,price: any) =>
                result.concat(price),
                []
            )
            .filter((price: any ) => price)
            .map((price: any) => ({
                width_from: Number(price.width_from),
                width_to: Number(price.width_to),
                height_from: Number(price.height_from),
                height_to: Number(price.height_to)
            }));

        return priceForLines;

    }

    getLayouts(conf: WindowActiveConfiguration, lineIds) {
        const layoutCodes = [];
        const types = {
            window: ['window'],
            sliding_door: ['hs', 'window'],
            door: ['door'],
            hs: ['hs'],
            folding_door: ['folding_door'],
        }
        types[conf.type].forEach(type => {
            layoutCodes.push(this.configuratorsDataService.layouts
                .filter(
                    layout =>
                        type.includes(layout.SashesLayout.type)
                        && layout.SashesLayoutsVariant.config
                )
                .filter(layout =>
                    !layout.SashesLayoutsVariant.not_systems.every(systemId =>
                            lineIds.includes(Number(systemId))
                        )
                )
                .filter(el => {
                    if (type === 'window' && conf.type === 'sliding_door') {
                        return el.Neighbours && (
                            el.Neighbours.middle.some(n => n.type === 'PSK')
                            || el.Neighbours.bottom.some(n => n.type === 'PSK')
                            || el.Neighbours.top.some(n => n.type === 'PSK')
                        ) || el.Sashes && el.Sashes.some(n => n.type === 'PSK');
                    }
                    return true;
                })
                .map(layout =>
                    layout.SashesLayoutsVariant.code
                )
                .reduce((layoutA: any, layoutB: any) => layoutB.concat(layoutA), [])
            );
        });

        return layoutCodes.reduce((layoutA: any, layoutB: any) => layoutB.concat(layoutA), []);
    }

    private formatUnit(value: number) {
        return unitMMFormatter(value, this.config().IccConfig, 1, 0, this.dimensionUnit.unit);
    }
}
