import { Pipe, PipeTransform, Inject } from '@angular/core';
import { TranslateService } from '@icc/common/translate.service';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { UserService } from '@icc/common/user.service';

@Pipe({
    name: 'formatUnit',
})
export class FormatUnitPipe implements PipeTransform {
    constructor(
        private userService: UserService,
        private translateService: TranslateService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {}

    transform(value: any, args?: any): any {
        const dimensionUnit = this.userService.get().dimension_unit ?? this.config().dimensionUnit;
        const unit = dimensionUnit === 'mm' ? 0 : 1;

        const mbUnit = this.translateService.instant('DIMENSION|mb');
        switch (Number(value)) {
            case 0:
                return this.translateService.instant('DIMENSION|szt.');
            case 1:
                return unit === 0 ? 'm<sup>2</sup>' : 'inch<sup>2</sup>';
            case 2:
                return unit === 0 ? mbUnit : '″';
        }
    }
}
