import angular from 'angular';

export default function NotEmptyFilter() {
    'ngInject';

    return function(obj, k) {
        return angular.isArray(obj) && obj.some(function(e) {
            return angular.isArray(e[k]) && e[k].length > 0;
        });
    };
}
