import { Injectable } from '@angular/core';
import { ActiveConfiguration } from './configurations/ActiveConfiguration';
import { logger } from './helpers';
import * as Sentry from '@sentry/browser';
import { IssueLevel, Issue, IssuesService as AbstractIssuesService } from '@icc/helpers';
import { Logger } from './Logger';
export { IssueLevel };

@Injectable()
export abstract class IssuesService extends AbstractIssuesService {
    protected modalOpened = true;
    protected validateFunctions: ((conf: ActiveConfiguration) => any)[] = [];

    abstract registerDataProblem(
        key: string,
        description: string,
        conf: ActiveConfiguration | null,
        options: {
            level?: IssueLevel;
            logLevel?: IssueLevel;
            showMessage?: boolean;
            blockAddToOffer?: boolean;
            blockStepsAfter?: string | null;
            noPrice?: boolean;
            tags?: {
                [id: string]: string;
            };
            extra?: any;
        }
    ): void;

    register(issue: Issue, conf: ActiveConfiguration) {
        try {
            if (!issue.description) {
                return;
            }
            if (issue.showMessage) {
                this.showMessage(issue);
            }
            if (conf.Issues.map(i => i.key).indexOf(issue.key) === -1) {
                conf.Issues.push(issue);
                const levels: {
                    [key in IssueLevel]: {
                        logger: Logger['debug'] | Logger['info'] | Logger['warn'] | Logger['err'] | null,
                        raven: 'fatal' | 'error' | 'warning' | 'info' | null
                    }
                } = {
                    [IssueLevel.NONE]: {
                        logger: null,
                        raven: null,
                    },
                    [IssueLevel.DEBUG]: {
                        logger: logger.debug.bind(logger),
                        raven: 'info',
                    },
                    [IssueLevel.INFO]: {
                        logger: logger.info.bind(logger),
                        raven: 'info',
                    },
                    [IssueLevel.WARN]: {
                        logger: logger.warn.bind(logger),
                        raven: 'warning',
                    },
                    [IssueLevel.ERROR]: {
                        logger: logger.err.bind(logger),
                        raven: 'error',
                    },
                    [IssueLevel.FATAL]: {
                        logger: logger.err.bind(logger),
                        raven: 'fatal',
                    },
                };
                if (levels[issue.level].logger) {
                    levels[issue.level].logger('ISSUE', issue.description);
                }
                if (levels[issue.logLevel].raven) {
                    setTimeout(() => {
                        Sentry.captureMessage(issue.description, {
                            level: levels[issue.logLevel].raven,
                            tags: issue.tags,
                            extra: issue.extra,
                        });
                    }, 100);
                }
            }
        } catch (error) {
            logger.error(error);
        }
    }



    simpleRegister(
        key: string,
        description: string,
        message: string,
        conf: ActiveConfiguration,
        options: {
            level?: IssueLevel;
            logLevel?: IssueLevel;
            showMessage?: boolean;
            blockAddToOffer?: boolean;
            blockStepsAfter?: string | null;
            noPrice?: boolean;
            tags?: {
                [id: string]: string;
            };
            extra?: any;
        } = {}
    ) {
        this.register(
            new Issue({
                key,
                description,
                message,
                level: options.level == null ? IssueLevel.WARN : options.level,
                logLevel: options.logLevel == null ? options.level : options.logLevel,
                showMessage: conf.systemComparison ? false : true,
                blockAddToOffer: true,
                blockStepsAfter: null,
                noPrice: true,
                tags: {},
                ...options,
            }),
            conf
        );

        return true;
    }

    unregister(key: string, conf: ActiveConfiguration) {
        conf.Issues = conf.Issues.filter(i => i.key !== key);
    }

    addValidateFunction(validateFunction: (conf: ActiveConfiguration) => any) {
        if (this.validateFunctions.indexOf(validateFunction) === -1) {
            this.validateFunctions.push(validateFunction);
        }
    }

    validateAll(conf: ActiveConfiguration) {
        this.modalOpened = false;
        for (let i = 0; i < this.validateFunctions.length; i++) {
            this.validateFunctions[i](conf);
        }
        this.modalOpened = true;
    }

    clearValidateFunctions() {
        this.validateFunctions = [];
    }
}
