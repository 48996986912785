/**
 * Kontroler modalu listy maili komentarza
 * @param {object} $uibModalInstance     Okno modal
 * @param {object} comment               Dane komentarza
 * @param {object} ScrollbarService      Serwis Scrollbara
 */
export default function ModalComplaintCommentEmailsCtrl($uibModalInstance, comment, ScrollbarService) {
    'ngInject';

    var vm                  = this;
    vm.close                = close;
    vm.refresh              = refresh;

    init();

    /**
     * Funkcja inicjująca
     */
    function init() {
        vm.comment    = comment;
    }

    /**
     * Funkcja zamykania okna modal
     */
    function close() {
        $uibModalInstance.close();
    }

    /**
     * Funkcja odświeżajaca
     */
    function refresh() {
        ScrollbarService.update();
    }
}
