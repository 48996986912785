import * as Sentry from '@sentry/browser';
import { logger } from '@icc/common/helpers';
import { ConfigurationType } from '@icc/common/configurations/configurations.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';

export class LegacyCurrentConfiguratorService extends CurrentConfiguratorService {
    conf: ConfigurationType = this.$location.path().split('/')[2];
    constructor(
        private $rootScope,
        private $location,
        private IccConfig,
        private EventBusService,
        private ConfiguratorsAvailabilityService
    ) {
        'ngInject';
        super();

        this.$rootScope.$on('$routeChangeStart', (e, next, current) => {
            this.changeConfigurator(current);
        });
    }

    changeConfigurator(currentRoute) {
        const conf = this.$location.path().split('/')[2];
        if (conf === 'new_configurator') {
            this.conf = this.$location.path().split('/')[3];
        } else {
            this.conf = conf;
        }
        if (
            this.ConfiguratorsAvailabilityService.availableConfigs[this.conf]
            && ((this.$rootScope.state.offers
                && this.$rootScope.state.offers[0]
                && this.$rootScope.state.offers[0].doc)
                || (this.$rootScope.user.access === 'Super Admin'
                    || this.$rootScope.user.access === 'Administratorzy'))
        ) {
            this.EventBusService.post({
                key: 'changedConfiguratorLocal',
                value: this.conf,
            });
            this.EventBusService.post({
                key: 'changedConfigurator',
                value: this.conf,
            });
        } else {
            if (this.ConfiguratorsAvailabilityService.configs.indexOf(this.conf) > -1) {
                if (this.$rootScope.state && this.$rootScope.state.offer_id) {
                    Sentry.captureMessage('Konfigurator nie działa ' + this.conf, {
                        level: 'error',
                        extra: {
                            availableConfigs: this.ConfiguratorsAvailabilityService
                                .availableConfigs,
                            stateOffers:
                                this.$rootScope.state.offers
                                && this.$rootScope.state.offers[0]
                                && this.$rootScope.state.offers[0].doc
                                && this.$rootScope.state.offers[0].doc.tmp_id,
                            userAccess: this.$rootScope.user.access,
                            state: this.$rootScope.state,
                        },
                    });
                    logger.err(
                        'Konfigurator nie działa ' + this.conf,
                        JSON.parse(
                            JSON.stringify({
                                availableConfigs: this.ConfiguratorsAvailabilityService
                                    .availableConfigs,
                                stateOffers:
                                    this.$rootScope.state.offers
                                    && this.$rootScope.state.offers[0]
                                    && this.$rootScope.state.offers[0].doc
                                    && this.$rootScope.state.offers[0].doc.tmp_id,
                                userAccess: this.$rootScope.user.access,
                                state: this.$rootScope.state,
                            })
                        )
                    );
                }
                if (currentRoute) {
                    this.$location.path(currentRoute.$$route.originalPath);
                } else {
                    this.$location.path('/app/start');
                }
            } else {
                this.$rootScope.coupledPosition = false;
                this.$rootScope.coupledPositionEdit = false;
            }
        }
    }

    setConfigurator(config: ConfigurationType) {
        this.conf = config;
        if (this.ConfiguratorsAvailabilityService.configs.indexOf(this.conf) > -1) {
            this.EventBusService.post({
                key: 'changedConfigurator',
                value: this.conf,
            });
        }
    }
}
