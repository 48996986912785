export default function FindIndexFilter() {
    'ngInject';

    return function(elemn, id, nameOfId) {
        if (typeof nameOfId === 'undefined') {
            nameOfId = 'id';
        }
        if (Array.isArray(elemn)) {
            for (var i = 0; i < elemn.length; i++) {
                if (elemn[i][nameOfId] == id) {
                    return i + 1;
                }
            }
        } else {
            return '';
        }
    };
}
