import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { SharedFacade, _ } from '@icc/configurator/shared';
import {
    APP_CONFIG,
    AppConfigFactory,
    EventBusService,
    ConfigurationsService,
    DrawService,
    TranslateService,
    core,
    Common,
    WindowActiveConfiguration,
} from '@icc/common';
import { ProfilesModalService } from '../profiles/profiles-modal.service';
import { FramesService } from '@icc/common/layout/frames.service';
import { ExtensionsService } from '@icc/legacy/configurator/layout/extensions.service';
import { BrowserFramesService } from '@icc/legacy/configurator/layout/frames.service';
import { EqualDivisionService } from '@icc/legacy/configurator/layout/equal-division.service';
import { ResizeSashService } from '@icc/legacy/configurator/layout/resize-sash.service';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { AlignmentsService } from '@icc/legacy/configurator/layout/alignments.service';
import { SashesService } from '@icc/legacy/configurator/steps/window/sashes/sashes.service';
import { SashesTypesService } from '@icc/legacy/configurator/steps/window/sashes/sashes-types.service';
import { RemoveSashService } from '@icc/legacy/configurator/layout/remove-sash.service';
import { SplitSashService } from '@icc/legacy/configurator/layout/split-sash.service';
import { BehaviorSubject } from 'rxjs';
import { WindowFacade } from '../+state/window.facade';
import { DrawResizeDirective } from 'libs/configurator/shared/src/lib/draw-resize/draw-resize.directive';
import { SashTypes } from '@icc/window';
import { ActiveMullion } from '@icc/common/layout/active-mullion';

@Component({
    selector: 'icc-sash-layout-page',
    templateUrl: './sash-layout-page.component.html',
    styleUrls: ['./sash-layout-page.component.scss'],
})
export class SashLayoutPageComponent extends PageComponent implements OnInit {
    title = _('WINDOW|Edycja konstrukcji');
    fullscreen = true;
    preview = false;
    changeIndividualProfiles = false;

    divisionOptions = [
        {
            name: '1/2',
            value: 1/2,
        },
        {
            name: '1/3',
            value: 1/3,
        },
        {
            name: '1/4',
            value: 1/4,
        },
        {
            name: '1/5',
            value: 1/5,
        },
        {
            name: '2/3',
            value: 2/3,
        },
        {
            name: '3/4',
            value: 3/4,
        },
        {
            name: '4/5',
            value: 4/5,
        },
    ];

    drawOptions: any = null;
    drawButtons = {
        alignments: true,
        dimensions: false,
        extensions: true,
        fullscreen: false,
        mullions: true,
        profiles: false,
        sashes: true,
        toggle: (
            type:
                | 'alignments'
                | 'dimensions'
                | 'extensions'
                | 'fullscreen'
                | 'mullions'
                | 'profiles'
                | 'sashes'
        ) => {
            if (type && typeof this.drawButtons[type] !== 'undefined') {
                this.drawButtons[type] = !this.drawButtons[type];
            }

            this.drawButtons.update();
        },

        update: () => {
            const conf = this.configurationsService.conf.Current;
            const defaultConf = this.configurationsService.conf.Default;
            if (conf) {
                this.drawOptions = Object.assign({}, this.drawService.options, {
                    dimensions: {
                        multiplier: 1,
                        type: this.drawButtons.fullscreen
                            ? 'extended'
                            : this.drawService.options.dimensions.type,
                    },

                    frame: {
                        actions:
                            ['window', 'door'].includes(conf.type)
                            || (conf.System.confType === 'hs'
                                && this.config().IccConfig.Configurators.hs.layoutEditing)
                                ? {
                                      onClick: (response: any) =>
                                          this.profilesModalService.changeProfile(conf, defaultConf, 'frame', {
                                              position: response.index,
                                              frame: response.frame,
                                          }),
                                      onHover: this.drawButtons.profiles
                                          ? 'single-hover'
                                          : 'group-hover',
                                  }
                                : {},
                    },
                    sashFrame: {
                        actions:
                            ['window', 'door'].includes(conf.type)
                            || (conf.System.confType === 'hs'
                                && this.config().IccConfig.Configurators.hs.layoutEditing)
                                ? {
                                      onClick: (response: any) =>
                                          this.profilesModalService.changeProfile(
                                              conf,
                                              defaultConf,
                                              'sashFrame',
                                              {
                                                  position: response.index,
                                                  sash: response.sash,
                                              }
                                          ),
                                      onHover: this.drawButtons.profiles
                                          ? 'single-hover'
                                          : 'group-hover',
                                  }
                                : {},
                    },
                    extensions: {
                        actions: {
                            onClick: (response: any) =>
                                ((response.extensions[response.index].side === 'bottom'
                                    && !conf.foundationProfile)
                                || response.extensions[response.index].side !== 'bottom')
                                && this.profilesModalService.changeProfile(conf, defaultConf, 'extension', {
                                    extension: response.extensions[response.index].extension,
                                    position: response.extensions[response.index].side,
                                }),
                            onHover: 'single-hover',
                        },
                        buttons: [
                            {
                                class: 'ico-sides',
                                positions: ['top', 'bottom', 'left', 'right'],
                                title: this.translateService.instant(
                                    'INTERFACE|Edytuj poszerzenia'
                                ),
                                condition: (response: any) =>
                                        (response.index === 'bottom'
                                            && !conf.foundationProfile)
                                        || response.index !== 'bottom',
                                buttons: [
                                    {
                                        class: 'ico-plus',
                                        condition: (response: any) =>
                                            (response.index === 'bottom'
                                                && conf.Shape.shape !== 'circle')
                                            || (response.index !== 'bottom'
                                                && conf.Shape.shape === 'rect'
                                                && !conf.hasRoller),
                                        onClick: (response: any) =>
                                            this.profilesModalService.selectExtensionAndPut(
                                                conf,
                                                response.index
                                            ),
                                        positions: ['bottom', 'left', 'right', 'top'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Dodaj poszerzenie'
                                        ),
                                    },
                                    {
                                        class: 'ico-remove',
                                        condition: (response: any) => {
                                            const frameEdges = this.framesService.getFrameProfilesIndexesOnTheEdge(
                                                response.index,
                                                conf
                                            );
                                            return (
                                                ((response.index === 'bottom'
                                                    && conf.Shape.shape !== 'circle')
                                                    || (response.index !== 'bottom'
                                                        && conf.Shape.shape === 'rect'
                                                        && !conf.hasRoller))
                                                && frameEdges
                                                && conf.SideProfiles.filter(sp =>
                                                    sp.framesId.some(f =>
                                                        frameEdges.some(
                                                            fE =>
                                                                f.id === fE.frameId
                                                                && f.edges.includes(
                                                                    fE.frameEdgeIndex
                                                                )
                                                        )
                                                    )
                                                ).length
                                            );
                                        },
                                        onClick: (response: any) =>
                                            this.extensionsService.removeExtensionFromSide(
                                                conf,
                                                response.index
                                            ),
                                        positions: ['bottom', 'left', 'right', 'top'],
                                        title: this.translateService.instant(
                                            'INTERFACE|Usuń poszerzenie'
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                    mullion: {
                        actions:
                            ['window', 'door'].includes(conf.type)
                            || (conf.System.confType === 'hs'
                                && this.config().IccConfig.Configurators.hs.layoutEditing)
                                ? {
                                      onClick: (response: any) =>
                                          this.profilesModalService.changeProfile(conf, defaultConf, 'mullion', {
                                              mullion: response.mullion,
                                          }),
                                      condition: (response: any) => response.mullion.profileId,
                                      onHover: 'group-hover',
                                      title: this.translateService.instant(
                                          'INTERFACE|Edytuj słupek'
                                      ),
                                  }
                                : {},
                        buttons:
                            ['window', 'door'].includes(conf.type)
                            || (conf.System.confType === 'hs'
                                && (this.config().IccConfig.Configurators.hs.layoutEditing || this.config().IccConfig.Configurators.hs.editMullion))
                            || (conf.type === 'sliding_door' && this.config().IccConfig.Configurators.hs.editMullion)
                                ? [
                                      {
                                          class: 'ico-move',
                                          title: this.translateService.instant(
                                              'INTERFACE|Edytuj słupek'
                                          ),
                                          buttons: [
                                              {
                                                  class: 'ico-equal-glass',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Równy podział po szkle'
                                                  ),
                                                  condition: (response: any) => !this.mullionDivisionCondition(conf, response),
                                                  onClick: this.onMullionDivisionClick(conf, 'glazing', 1/2),
                                              },
                                              {
                                                  class: 'ico-equal-axis',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Równy podział do osi'
                                                  ),
                                                  condition: (response: any) => !this.mullionDivisionCondition(conf, response),
                                                  onClick: this.onMullionDivisionClick(conf, 'axis', 1/2),
                                              },
                                              {
                                                  class: 'ico-equal-glass',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Równy podział po szkle'
                                                  ),
                                                  condition: (response: any) => this.mullionDivisionCondition(conf, response),
                                                  buttons: this.divisionOptions.map(
                                                      ({ name, value }) => ({
                                                          innerHtml: name,
                                                          title: name,
                                                          onClick: this.onMullionDivisionClick(conf, 'glazing', value),
                                                      })
                                                  ),
                                              },
                                              {
                                                  class: 'ico-equal-axis',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Równy podział do osi'
                                                  ),
                                                  condition: (response: any) => this.mullionDivisionCondition(conf, response),
                                                  buttons: this.divisionOptions.map(
                                                      ({ name, value }) => ({
                                                          innerHtml: name,
                                                          title: name,
                                                          onClick: this.onMullionDivisionClick(conf, 'axis', value),
                                                      })
                                                  ),
                                              },
                                              {
                                                  class: 'ico-move-submit',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Edytuj słupek'
                                                  ),
                                                  condition: (response: any) =>
                                                      response.mullion.profileId && (
                                                        ['window', 'door'].includes(conf.type)
                                                        || conf.System.confType === 'hs' && this.config().IccConfig.Configurators.hs.editMullionProfile
                                                      ),
                                                  onClick: (response: any) =>
                                                      this.profilesModalService.changeProfile(
                                                          conf,
                                                          defaultConf,
                                                          'mullion',
                                                          { mullion: response.mullion }
                                                      ),
                                              },
                                              {
                                                  class: 'ico-move',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Przesuń słupek'
                                                  ),
                                                  condition: (response: any) => this.canMoveMullion(conf, response),
                                                  onStartMove: (response: any) => {
                                                    const sash = response.sash
                                                          ? core.fIdO(conf.Sashes, response.sash.id)
                                                          : null;
                                                      const mullion = sash
                                                          ? core.fIdO(
                                                                sash.intMullions,
                                                                response.mullion.id
                                                            )
                                                          : core.fIdO(
                                                                conf.Mullions,
                                                                response.mullion.id
                                                            );
                                                    if(mullion) {
                                                        return {
                                                            min: this.getMinWidthPrev(conf, response.direction, mullion),
                                                            max: this.getMinWidthNext(conf, response.direction, mullion)
                                                        }
                                                    }

                                                    return {min: 0, max: 0}
                                                },
                                                  onMove: (response: any) => {
                                                      const sash = response.sash
                                                          ? core.fIdO(conf.Sashes, response.sash.id)
                                                          : null;
                                                      const mullion = sash
                                                          ? core.fIdO(
                                                                sash.intMullions,
                                                                response.mullion.id
                                                            )
                                                          : core.fIdO(
                                                                conf.Mullions,
                                                                response.mullion.id
                                                            );
                                                      if (mullion) {
                                                          const diff =
                                                              mullion[
                                                                  mullion.direction === 'vertical'
                                                                      ? 'rx'
                                                                      : 'ry'
                                                              ] - response.position;
                                                          this.resizeSashService.changeSashWidth(
                                                              mullion,
                                                              diff,
                                                              response.direction,
                                                              conf,
                                                              this.getMinWidthPrev(conf, response.direction, mullion),
                                                              this.getMinWidthNext(conf, response.direction, mullion),
                                                          );
                                                      }
                                                  },
                                              },
                                          ],
                                      },
                                  ]
                                : [],
                        minPosition: this.config().IccConfig.Configurators.minWidthGlass || 0,
                        maxPosition: null,
                    },
                    glazing: {
                        buttons: [
                            {
                                class: 'ico-gear',
                                title: this.translateService.instant('INTERFACE|Edytuj kwaterę'),
                                buttons: [
                                    {
                                        class: 'ico-split-h',
                                        title: this.translateService.instant(
                                            'INTERFACE|Podziel w poziomie'
                                        ),
                                        condition: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.parentId
                                                );
                                                return sash && this.canDivIntHorizontal(sash, conf);
                                            } else {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.id
                                                );
                                                return sash && this.canDivHorizontal(sash, conf);
                                            }
                                        },
                                        onClick: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.parentId
                                                );
                                                const intSash =
                                                    sash
                                                    && core.fIdO(sash.intSashes, response.sash.id);
                                                if (intSash && sash) {
                                                    this.splitSashService.splitInternalSash(
                                                        sash,
                                                        intSash,
                                                        'horizontal',
                                                        conf
                                                    );
                                                }
                                            } else {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.id
                                                );
                                                if (sash) {
                                                    this.splitSashService.splitSash(
                                                        sash,
                                                        'horizontal',
                                                        conf
                                                    );
                                                }
                                            }
                                        },
                                    },
                                    {
                                        class: 'ico-split-v',
                                        title: this.translateService.instant(
                                            'INTERFACE|Podziel w pionie'
                                        ),
                                        condition: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.parentId
                                                );
                                                return sash && this.canDivIntVertical(sash, conf);
                                            } else {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.id
                                                );
                                                return sash && this.canDivVertical(sash, conf);
                                            }
                                        },
                                        onClick: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.parentId
                                                );
                                                const intSash =
                                                    sash
                                                    && core.fIdO(sash.intSashes, response.sash.id);
                                                if (intSash && sash) {
                                                    this.splitSashService.splitInternalSash(
                                                        sash,
                                                        intSash,
                                                        'vertical',
                                                        conf
                                                    );
                                                }
                                            } else {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.id
                                                );
                                                if (sash) {
                                                    this.splitSashService.splitSash(
                                                        sash,
                                                        'vertical',
                                                        conf
                                                    );
                                                }
                                            }
                                        },
                                    },
                                    {
                                        class: 'ico-remove',
                                        title: this.translateService.instant(
                                            'INTERFACE|Usuń kwaterę'
                                        ),
                                        condition: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.parentId
                                                );
                                                return (
                                                    sash
                                                    && (['window', 'door'].includes(conf.type)
                                                        || (conf.System.confType === 'hs'
                                                            && this.config().IccConfig.Configurators
                                                                .hs.layoutEditing)
                                                        || sash.intSashes.length > 1)
                                                );
                                            } else {
                                                return conf.Sashes.length > 1
                                                    && (
                                                        this.config().IccConfig.Configurators.sliding_door.layoutEditing
                                                        && conf.type === 'sliding_door'
                                                        || conf.type !== 'sliding_door'
                                                    );
                                            }
                                        },
                                        onClick: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.parentId
                                                );
                                                if (sash) {
                                                    const intSash = core.fIdO(
                                                        sash.intSashes,
                                                        response.sash.id
                                                    );
                                                    if (intSash) {
                                                        this.removeSashService.removeSash(
                                                            intSash,
                                                            conf
                                                        );
                                                    }
                                                }
                                            } else {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.id
                                                );
                                                if (sash) {
                                                    this.removeSashService.removeSash(sash, conf);
                                                }
                                            }
                                            this.sashesTypesService.hideFittingStepIfFixedLayout();
                                        },
                                    },
                                    {
                                        class: 'ico-sash',
                                        title: this.translateService.instant(
                                            'INTERFACE|Funkcja kwatery'
                                        ),
                                        condition: (response: any) => {
                                            if (Common.isDefined(response.sash.parentId)) {
                                                return (
                                                    (conf.type !== 'hs'
                                                    || this.config().IccConfig.Configurators.hs
                                                        .layoutEditing)
                                                    && (conf.type !== 'sliding_door'
                                                    || this.config().IccConfig.Configurators.sliding_door.layoutEditing)
                                                );
                                            } else {
                                                const sash = core.fIdO(
                                                    conf.Sashes,
                                                    response.sash.id
                                                );
                                                return (
                                                    sash
                                                    && ((conf.type !== 'hs'
                                                        || this.config().IccConfig.Configurators.hs
                                                            .layoutEditing)
                                                        && (conf.type !== 'door'
                                                            || (sash.type
                                                                && sash.type.type !== 'F')
                                                            || sash.nearMullions.top !== -1
                                                            || sash.nearMullions.bottom === -1)
                                                        && (conf.type !== 'sliding_door'
                                                            || this.config().IccConfig.Configurators.sliding_door.layoutEditing)
                                                        )
                                                );
                                            }
                                        },
                                        onClick: (response: any) => {
                                            const sashId = Common.isDefined(response.sash.parentId)
                                                ? response.sash.parentId
                                                : response.sash.id;
                                            const sash = core.fIdO(conf.Sashes, sashId);
                                            this.sashesTypesService.openSashTypeModal(sash);
                                        },
                                    },
                                    {
                                        class: 'ico-sides',
                                        title: this.translateService.instant(
                                            'INTERFACE|Edytuj wyrównania'
                                        ),
                                        condition: (response: any) => {
                                            const sash = core.fIdO(
                                                conf.Sashes,
                                                response.sash.parentId
                                            );
                                            const field = sash
                                                ? core.fIdO(sash.intSashes, response.sash.id)
                                                : core.fIdO(conf.Sashes, response.sash.id);
                                            return this.alignmentsService.isAvailableAlignments(
                                                field,
                                                conf
                                            );
                                        },
                                        buttons: [
                                            {
                                                class: 'ico-plus',
                                                title: this.translateService.instant(
                                                    'INTERFACE|Dodaj wyrównanie'
                                                ),
                                                condition: (response: any) =>
                                                    (response.index === 'bottom'
                                                        && conf.Shape.shape !== 'circle')
                                                    || (response.index !== 'bottom'
                                                        && conf.Shape.shape === 'rect'
                                                        && !conf.hasRoller),
                                                onClick: (response: any) => {
                                                    const sash = core.fIdO(
                                                        conf.Sashes,
                                                        response.sash.parentId
                                                    );
                                                    const field = sash
                                                        ? core.fIdO(
                                                              sash.intSashes,
                                                              response.sash.id
                                                          )
                                                        : core.fIdO(conf.Sashes, response.sash.id);
                                                    this.profilesModalService.selectAlignmentAndPut(
                                                        field,
                                                        conf,
                                                        response.index
                                                    );
                                                },
                                                positions: ['bottom', 'left', 'right', 'top'],
                                            },
                                            {
                                                class: 'ico-remove',
                                                title: this.translateService.instant(
                                                    'INTERFACE|Usuń wyrównanie'
                                                ),
                                                condition: (response: any) =>
                                                    ((response.index === 'bottom'
                                                        && conf.Shape.shape !== 'circle')
                                                        || (response.index !== 'bottom'
                                                            && conf.Shape.shape === 'rect'
                                                            && !conf.hasRoller))
                                                    && response.sash.nearAlignments[response.index]
                                                        !== -1,
                                                onClick: (response: any) => {
                                                    const sash = core.fIdO(
                                                        conf.Sashes,
                                                        response.sash.parentId
                                                    );
                                                    const field = sash
                                                        ? core.fIdO(
                                                              sash.intSashes,
                                                              response.sash.id
                                                          )
                                                        : core.fIdO(conf.Sashes, response.sash.id);
                                                    this.alignmentsService.removeAlignmentInField(
                                                        field,
                                                        conf,
                                                        response.index
                                                    );
                                                },
                                                positions: ['bottom', 'left', 'right', 'top'],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    coupling: {
                        actions:
                            ['window', 'door'].includes(conf.type)
                            || (conf.System.confType === 'hs'
                                && this.config().IccConfig.Configurators.hs.layoutEditing)
                                ? {
                                      onClick: (response: any) =>
                                          this.profilesModalService.changeProfile(
                                              conf,
                                              defaultConf,
                                              'coupling',
                                              {
                                                  coupling: response.coupling,
                                              }
                                          ),
                                      condition: (response: any) => response.coupling.profileId,
                                      onHover: 'group-hover',
                                      title: this.translateService.instant(
                                          'INTERFACE|Edytuj łącznik'
                                      ),
                                  }
                                : {},
                        buttons:
                            ['window', 'door'].includes(conf.type)
                            || (conf.System.confType === 'hs'
                                && this.config().IccConfig.Configurators.hs.layoutEditing)
                                ? [
                                      {
                                          class: 'ico-move',
                                          title: this.translateService.instant(
                                              'INTERFACE|Edytuj łącznik'
                                          ),
                                          buttons: [
                                              {
                                                  class: 'ico-equal-glass',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Równy podział po szkle'
                                                  ),
                                                  condition: (response: any) => !this.couplingDivisionCondition(conf, response),
                                                  onClick: this.onCouplingDivisionClick(conf, 'glazing', 1/2),
                                              },
                                              {
                                                  class: 'ico-equal-axis',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Równy podział do osi'
                                                  ),
                                                  condition: (response: any) => !this.couplingDivisionCondition(conf, response),
                                                  onClick: this.onCouplingDivisionClick(conf, 'axis', 1/2),
                                              },
                                              {
                                                  class: 'ico-equal-glass',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Równy podział po szkle'
                                                  ),
                                                  condition: (response: any) => this.couplingDivisionCondition(conf, response),
                                                  buttons: this.divisionOptions.map(
                                                      ({ name, value }) => ({
                                                          innerHtml: name,
                                                          title: name,
                                                          onClick: this.onCouplingDivisionClick(conf, 'glazing', value),
                                                      })
                                                  ),
                                              },
                                              {
                                                  class: 'ico-equal-axis',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Równy podział do osi'
                                                  ),
                                                  condition: (response: any) => this.couplingDivisionCondition(conf, response),
                                                  buttons: this.divisionOptions.map(
                                                      ({ name, value }) => ({
                                                          innerHtml: name,
                                                          title: name,
                                                          onClick: this.onCouplingDivisionClick(conf, 'axis', value),
                                                      })
                                                  ),
                                              },
                                              {
                                                  class: 'ico-move-submit',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Edytuj łącznik'
                                                  ),
                                                  condition: (response: any) =>
                                                      response.coupling.profileId,
                                                  onClick: (response: any) =>
                                                      this.profilesModalService.changeProfile(
                                                          conf,
                                                          defaultConf,
                                                          'coupling',
                                                          { coupling: response.coupling }
                                                      ),
                                              },
                                              {
                                                  class: 'ico-move',
                                                  title: this.translateService.instant(
                                                      'INTERFACE|Przesuń łącznik'
                                                  ),
                                                  onMove: (response: any) => {
                                                      const coupling = conf.couplings.find(
                                                          c => c.id === response.coupling.id
                                                      );
                                                      if (coupling) {
                                                          this.framesService.moveCoupling(
                                                              coupling,
                                                              response.position,
                                                              conf
                                                          );
                                                      }
                                                  },
                                              },
                                          ],
                                      },
                                  ]
                                : [],
                        minPosition: this.config().IccConfig.Configurators.minWidth || 0,
                        maxPosition: null,
                    },
                    onInterfaceClick: () => {},
                });
                this.drawOptions.base.side = 'inner';
            }
            this.eventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
        },
    };

    width$ = this.windowFacade.width$;
    height$ = this.windowFacade.height$;

    @ViewChild(DrawResizeDirective) directive: any;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private sashesTypesService: SashesTypesService,
        private configurationsService: ConfigurationsService<'window'>,
        public drawService: DrawService,
        private profilesModalService: ProfilesModalService,
        private translateService: TranslateService,
        private framesService: BrowserFramesService,
        private extensionsService: ExtensionsService,
        private equalDivisionService: EqualDivisionService,
        private resizeSashService: ResizeSashService,
        private alignmentsService: AlignmentsService,
        private removeSashService: RemoveSashService,
        private splitSashService: SplitSashService,
        private windowFacade: WindowFacade,
        private sharedFacade: SharedFacade,
    ) {
        super();
        this.sharedFacade.preview$
        .subscribe(preview => {
            this.preview = preview.isPreviewEnabled;
            if (this.drawOptions && !this.preview){
                this.drawOptions.dimensions.type = 'outer';
            }
            this.eventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
        });
        this.eventBusService.subscribe(['dismissMessage'], () => {
            this.resizeDraw();
        });
    }

    ngOnInit() {
        this.changeIndividualProfiles = this.config().IccConfig.Configurators.changeIndividualProfiles
            && !(this.config().IccConfig.Configurators.hs.editMullion && this.configurationsService.conf.Current.type === 'sliding_door');
        this.drawButtons.update();
    }
    openPreview(){
        this.drawButtons.update();
        this.sharedFacade.togglePreview({
            isPreviewEnabled: !this.preview,
            drawData: this.drawOptions
        });
    }
    canDivVertical(sash: ActiveSash, conf: WindowActiveConfiguration | DoorActiveConfiguration) {
        var shape = conf.Shape;

        if (
            conf.type === 'folding_door'
            || (conf.System.confType === 'hs' && !this.config().IccConfig.Configurators.hs.layoutEditing)
            || (conf.type === 'sliding_door' && !this.config().IccConfig.Configurators.sliding_door.layoutEditing)
        ) {
            return false;
        }

        // koło - mogą być max 2 skrzydła
        if (
            (shape.shape == 'circle' && conf.Sashes.length == 2)
            || (DoorActiveConfiguration.is(conf)
                && ((this.config().IccConfig.Configurators.door.version == 1
                    && !conf.Model.div_vertical)
                    || (this.config().IccConfig.Configurators.door.version == 2
                        && sash.glazing.type == 'deco_panels')
                    || conf.System?.door_type
                        && sash.glazing.type === 'door_panels'
                )
                && sash.type
                && sash.type.type != 'F'
                && sash.type.type != 'K')
        ) {
            return false;
        }

        return true;
    }

    canDivHorizontal(sash: ActiveSash, conf: WindowActiveConfiguration) {
        var shape = conf.Shape;

        if (
            conf.type === 'folding_door'
            || (conf.System.confType === 'hs' && !this.config().IccConfig.Configurators.hs.layoutEditing)
            || (conf.type === 'sliding_door' && !this.config().IccConfig.Configurators.sliding_door.layoutEditing)
        ) {
            return false;
        }

        // koło - mogą być max 2 skrzydła
        if (
            (shape.shape == 'circle' && conf.Sashes.length == 2)
            || (DoorActiveConfiguration.is(conf)
                && ((this.config().IccConfig.Configurators.door.version == 1
                    && !conf.Model.div_horizontal)
                    || (this.config().IccConfig.Configurators.door.version == 2
                        && sash.glazing.type === 'deco_panels')
                    || conf.System?.door_type
                        && sash.glazing.type === 'door_panels'
                )
                && sash.type
                && sash.type.type != 'F'
                && sash.type.type != 'K')
        ) {
            return false;
        }

        return true;
    }

    canDivIntVertical(sash: ActiveSash, conf: WindowActiveConfiguration | DoorActiveConfiguration) {
        var shape = conf.Shape;

        if (
            conf.type === 'folding_door'
            || (conf.System.confType === 'hs' && !this.config().IccConfig.Configurators.hs.layoutEditing)
            || (conf.type === 'sliding_door' && !this.config().IccConfig.Configurators.sliding_door.layoutEditing)
        ) {
            return false;
        }

        if (
            shape.shape == 'circle'
            || (DoorActiveConfiguration.is(conf)
                && ((this.config().IccConfig.Configurators.door.version == 1
                    && !conf.Model.div_vertical)
                    || (this.config().IccConfig.Configurators.door.version == 2
                        && sash.glazing.type == 'deco_panels')
                    || conf.System?.door_type
                        && sash.glazing.type === 'door_panels'
                )
                && sash.type
                && sash.type.type != 'F'
                && sash.type.type != 'K')
        ) {
            return false;
        }

        return true;
    }

    canDivIntHorizontal(
        sash: ActiveSash,
        conf: WindowActiveConfiguration | DoorActiveConfiguration
    ) {
        var shape = conf.Shape;

        if (
            conf.type === 'folding_door'
            || (conf.System.confType === 'hs' && !this.config().IccConfig.Configurators.hs.layoutEditing)
            || (conf.type === 'sliding_door' && !this.config().IccConfig.Configurators.sliding_door.layoutEditing)
        ) {
            return false;
        }

        if (
            shape.shape == 'circle'
            || (DoorActiveConfiguration.is(conf)
                && ((this.config().IccConfig.Configurators.door.version == 1
                    && !conf.Model.div_horizontal)
                    || (this.config().IccConfig.Configurators.door.version == 2
                        && sash.glazing.type == 'deco_panels')
                    || conf.System?.door_type
                        && sash.glazing.type === 'door_panels'
                )
                && sash.type
                && sash.type.type != 'F'
                && sash.type.type != 'K')
        ) {
            return false;
        }

        return true;
    }

    private getSashMullion(conf: WindowActiveConfiguration | DoorActiveConfiguration, response: any) {
        const sash = response.sash
            ? core.fIdO(conf.Sashes, response.sash.id)
            : null;

        return sash
            ? core.fIdO(
                  sash.intMullions,
                  response.mullion.id
              )
            : core.fIdO(
                  conf.Mullions,
                  response.mullion.id
              );
    }

    private onMullionDivisionClick(
        conf: WindowActiveConfiguration | DoorActiveConfiguration,
        type: 'glazing' | 'axis',
        ratio: number
    ) {
        return (response: any) => {
            const mullion = this.getSashMullion(conf, response);
            if (mullion) {
                this.equalDivisionService.equalDivision(
                    mullion,
                    conf,
                    type,
                    ratio
                );
            }
        };
    }

    private mullionDivisionCondition(
        conf: WindowActiveConfiguration | DoorActiveConfiguration,
        response: any
    ) {
        if (!this.config().IccConfig.Configurators.divisionOptions) {
            return false;
        }

        const mullion = this.getSashMullion(conf, response);
        if (mullion) {
            const count = this.equalDivisionService.countDivisions(
                mullion,
                conf
            );

            return count <= 2;
        }

        return false;
    }

    private onCouplingDivisionClick(
        conf: WindowActiveConfiguration | DoorActiveConfiguration,
        type: 'glazing' | 'axis',
        ratio: number
    ) {
        return (response: any) => {
            const coupling = core.fIdO(
                conf.couplings,
                response.coupling.id
            );
            if (coupling) {
                this.equalDivisionService.equalDivision(
                    coupling,
                    conf,
                    type,
                    ratio
                );
            }
        };
    }

    private couplingDivisionCondition(
        conf: WindowActiveConfiguration | DoorActiveConfiguration,
        response: any
    ) {
        if (!this.config().IccConfig.Configurators.divisionOptions) {
            return false;
        }

        const coupling = core.fIdO(
            conf.couplings,
            response.coupling.id
        );
        if (coupling) {
            const count = this.equalDivisionService.countDivisions(
                coupling,
                conf
            );

            return count <= 2;
        }

        return false;
    }

    private resizeDraw() {
        this.directive?.ngOnChanges();
    }

    private canMoveMullion(
        conf: WindowActiveConfiguration | DoorActiveConfiguration,
        response: any
    ) {
        const isWindowOrDoor = ['window', 'door'].includes(conf.type);
        const isHs = conf.System.confType === 'hs';
        const isSlidingDoors = conf.type === 'sliding_door' && conf.System.confType ===  'window';
        if(isWindowOrDoor) {
            return true;
        }
        if (isHs) {
            if(!this.config().IccConfig.Configurators.hs.moveMullion) {
                return false;
            }
        }
        if(isSlidingDoors) {
            if(!this.config().IccConfig.Configurators.sliding_door.moveMullion) {
                return false;
            }
        }

        const slidingSash = SashTypes.SLIDING;
        const mullion = response.mullion;
        const leftSash = conf.Sashes.find(sash => Number(sash.nearMullions.left) === Number(mullion.id));
        const rightSash = conf.Sashes.find(sash => Number(sash.nearMullions.right) === Number(mullion.id));
        if(mullion.direction === 'vertical') {
            const isLeftAndIsSlide = leftSash?.type && slidingSash.indexOf(leftSash.type.type)  > -1;
            const isRightAndISlide = rightSash?.type && slidingSash.indexOf(rightSash.type.type)  > -1;
            const leftOpenToRight = isLeftAndIsSlide && leftSash?.type?.handle_position === 'R';
            const rightOpenToLeft = isRightAndISlide && rightSash?.type?.handle_position === 'L';

            const hsOpenUpToEachOther = (rightOpenToLeft && leftOpenToRight)
                || (isLeftAndIsSlide && rightOpenToLeft)
                || (isRightAndISlide && leftOpenToRight);
            if(hsOpenUpToEachOther) {
                return false;
            }
        }
        return true;
    }

    getMinWidthPrev(conf = this.configurationsService.conf.Current, direction: 'horizontal' | 'vertical', mullion: ActiveMullion) {
        const editMullion =  this.config().IccConfig.Configurators.hs.editMullion && conf.type === 'sliding_door' && direction === 'vertical';
        if(!editMullion) {
            return undefined;
        }
        return this.getAvailableMinimalSize(conf, mullion, 'prev');
    }

    getMinWidthNext(conf = this.configurationsService.conf.Current, direction: 'horizontal' | 'vertical', mullion: ActiveMullion) {
        const editMullion =  this.config().IccConfig.Configurators.hs.editMullion && conf.type === 'sliding_door' && direction === 'vertical';
        if(!editMullion) {
            return undefined;
        }
        return this.getAvailableMinimalSize(conf, mullion, 'next');

    }

    getAvailableMinimalSize(conf: WindowActiveConfiguration, mullion: ActiveMullion, side: 'prev' | 'next') {
        const slideSashesType = SashTypes.SLIDING;
        const leftSashes = mullion.multiAlignLeft;
        const rightSashes = mullion.multiAlignRight;
        const leftIsNotSlidSash = leftSashes.filter(sash => sash.type && slideSashesType.indexOf(sash.type.type) === -1).length > 0;
        const rightIsNotSlidSash = rightSashes.filter(sash => sash.type && slideSashesType.indexOf(sash.type.type) === -1).length > 0;

        if(side === 'prev') {
            if(leftIsNotSlidSash) {
                return this.getSlideSashesSize(conf, leftSashes, side) - this.config().IccConfig.Configurators.minWidthGlass;
            }  else {
                return this.getNotSlideSashesSize(conf, leftSashes, side) - this.config().IccConfig.Configurators.minWidthGlass;
            }
        } else {
            if(rightIsNotSlidSash) {
                return this.getSlideSashesSize(conf, rightSashes, side) - this.config().IccConfig.Configurators.minWidthGlass;
             }  else {
                return this.getNotSlideSashesSize(conf, rightSashes, side) - this.config().IccConfig.Configurators.minWidthGlass;
             }
        }
    }

    getSlideSashesSize(conf: WindowActiveConfiguration, sashes: ActiveSash[], side: 'prev' | 'next') {
        const slideSashesType = SashTypes.SLIDING;

        const notSlideSashes = sashes.filter(sash => sash.type && slideSashesType.indexOf(sash.type.type) === -1);
        const notSlideSashWidth = notSlideSashes.reduce((a, b) => a > b.rWidth && a !== 0 ? a : b.rWidth, 0);

        const leftMullionsId = notSlideSashes.map(notSlideSash => notSlideSash.nearMullions.left);
        const rightMullionsId = notSlideSashes.map(notSlideSash => notSlideSash.nearMullions.right);

        const leftSlideSashesOpenToRight = this.getSlideSashFromMullionsOpenToSide(conf, leftMullionsId, (mullion: ActiveMullion) => mullion.multiAlignLeft, 'L');
        const leftSlideSashesOpenToRightWidth = leftSlideSashesOpenToRight.reduce((a, b) => a > b.rWidth && a !== 0 ? a : b.rWidth, 0);

        const rightSlideSashesOpenToLeft = this.getSlideSashFromMullionsOpenToSide(conf, rightMullionsId, (mullion: ActiveMullion) => mullion.multiAlignRight, 'R');
        const rightSlideSashesOpenToLeftWidth = rightSlideSashesOpenToLeft.reduce((a, b) => a > b.rWidth && a !== 0 ? a : b.rWidth, 0);

        const leftSlideSashesOpenToLeft = this.getSlideSashFromMullionsOpenToSide(conf, leftMullionsId, (mullion: ActiveMullion) => mullion.multiAlignLeft, 'R');
        const leftSlideSashesOpenToLeftWidth = leftSlideSashesOpenToLeft.reduce((a, b) => a > b.rWidth && a !== 0 ? a : b.rWidth, 0);

        const rightSlideSashesOpenToRight = this.getSlideSashFromMullionsOpenToSide(conf, rightMullionsId, (mullion: ActiveMullion) => mullion.multiAlignRight, 'L');
        const rightSlideSashesOpenToRightWidth = rightSlideSashesOpenToRight.reduce((a, b) => a > b.rWidth && a !== 0 ? a : b.rWidth, 0);

        const widthOfNotSlideSashOnLeftSideToSlideSash = this.getNotSlideSashToSlideSashes(conf, leftSlideSashesOpenToLeft, 'left');
        const widthOfNotSlideSashOnRightSideToSlideSash = this.getNotSlideSashToSlideSashes(conf, rightSlideSashesOpenToRight, 'right');

        if(side === 'prev') {
            const maxDiffSlideSashToNotSlide = widthOfNotSlideSashOnRightSideToSlideSash !== 0
                ? notSlideSashWidth - (widthOfNotSlideSashOnRightSideToSlideSash - rightSlideSashesOpenToRightWidth)
                : 0;
            const maxWidthSashes = rightSlideSashesOpenToLeftWidth !== 0
                ? (notSlideSashWidth + rightSlideSashesOpenToLeftWidth) / 2
                : 0
            const minWidth = maxWidthSashes > leftSlideSashesOpenToRightWidth
                ? maxWidthSashes
                : leftSlideSashesOpenToRightWidth;
            return maxDiffSlideSashToNotSlide < minWidth
                ? minWidth
                : maxDiffSlideSashToNotSlide;
        } else {
            const maxDiffSlideSashToNotSlide = widthOfNotSlideSashOnLeftSideToSlideSash !== 0
                ? notSlideSashWidth - (widthOfNotSlideSashOnLeftSideToSlideSash - leftSlideSashesOpenToLeftWidth)
                : 0;
            const maxWidthSashes = leftSlideSashesOpenToRightWidth !== 0
                ? (notSlideSashWidth + leftSlideSashesOpenToRightWidth) / 2
                : 0;
            const minWidth =  maxWidthSashes > rightSlideSashesOpenToLeftWidth
                ? maxWidthSashes
                : rightSlideSashesOpenToLeftWidth;
            return maxDiffSlideSashToNotSlide < minWidth
                ? minWidth
                : maxDiffSlideSashToNotSlide;
        }
    }

    getNotSlideSashesSize(conf: WindowActiveConfiguration, sashes: ActiveSash[], side: 'prev' | 'next') {
        const slideSashesType = SashTypes.SLIDING;

        const slideSashes = sashes.filter(sash => sash.type && slideSashesType.indexOf(sash.type.type) > -1);
        const slideSashWidth = slideSashes.reduce((a, b) => a > b.rWidth && a !== 0 ? a : b.rWidth, 0);

        const leftMullionsId = slideSashes.map(sash => sash.nearMullions.left);
        const rightMullionsId =  slideSashes.map(sash => sash.nearMullions.right);

        const leftSlideSashesOpenToLeft = this.getSlideSashFromMullionsOpenToSide(conf, leftMullionsId, (mullion: ActiveMullion) => mullion.multiAlignLeft, 'R');
        const leftSlideSashesOpenToLeftWidth = leftSlideSashesOpenToLeft.reduce((a, b) => a > b.rWidth && a !== 0 ? a : b.rWidth, 0);

        const rightSlideSashesOpenToRight = this.getSlideSashFromMullionsOpenToSide(conf, rightMullionsId, (mullion: ActiveMullion) => mullion.multiAlignRight, 'L');
        const rightSlideSashesOpenToRightWidth = rightSlideSashesOpenToRight.reduce((a, b) => a > b.rWidth && a !== 0 ? a : b.rWidth, 0);

        const notSlideSashOnLeftSideToSlideSashWidth = this.getNotSlideSashToSlideSashes(conf, leftSlideSashesOpenToLeft, 'left');
        const notSlideSashOnRightSideToSlideSashWidth = this.getNotSlideSashToSlideSashes(conf, rightSlideSashesOpenToRight, 'right');

        if(side === 'prev') {
            const maxDiffSlideSashToNotSlide = notSlideSashOnRightSideToSlideSashWidth !== 0
                ? slideSashWidth - (notSlideSashOnRightSideToSlideSashWidth - rightSlideSashesOpenToRightWidth)
                : 0;
            return maxDiffSlideSashToNotSlide > 0
                ? maxDiffSlideSashToNotSlide
                : 0;
        } else {
            const maxDiffSlideSashToNotSlide = notSlideSashOnLeftSideToSlideSashWidth !== 0
                ? slideSashWidth - (notSlideSashOnLeftSideToSlideSashWidth - leftSlideSashesOpenToLeftWidth)
                : 0;
            return maxDiffSlideSashToNotSlide > 0
                ? maxDiffSlideSashToNotSlide
                : 0;
        }
    }

    getSlideSashFromMullionsOpenToSide(conf: WindowActiveConfiguration, mullionsId: number[], mullionToActiveSashMap:(mullion: ActiveMullion) => ActiveSash[] , handlePosition: 'R' | 'L') {
        const slideSashesType = SashTypes.SLIDING;
        return conf.Mullions.filter(mullion => mullionsId.indexOf(mullion.id) > -1)
            .map(mullionToActiveSashMap)
            .reduce((a, b) => a.concat(b), [])
            .filter((sash: ActiveSash) => sash.type && slideSashesType.indexOf(sash.type.type) > -1 && sash.type.handle_position === handlePosition);
    }

    getNotSlideSashToSlideSashes(conf: WindowActiveConfiguration, sashes: ActiveSash[], side: 'left' | 'right') {
        const slideSashesType = SashTypes.SLIDING;
        const mullionsId = sashes.map(sash => sash.nearMullions[side]);
        const notSlideSashMullionSide = side === 'left' ? 'right' : 'left';
        const notSlideSashes = conf.Sashes.filter(sash => sash.type && slideSashesType.indexOf(sash.type.type) === -1 && mullionsId.indexOf(sash.nearMullions[notSlideSashMullionSide]) > -1);

        return  notSlideSashes.reduce((a, b) => a > b.rWidth && a !== 0 ? a : b.rWidth, 0);
    }
}
