import angular from 'angular';
import { IssueLevel } from '@icc/helpers';

/**
 * Fabryka paneli bram garazowych
 * @param {object} $rootScope               RootScope
 * @param {object} $uibModal                   Dostarcza obsluge modalnych okien
 * @param {object} $location                Obsluga URL
 * @param {object} $filter                  Filtry angularowe
 * @param {object} ConfigurationsService    Konfigurowana pozycja
 * @param {object} ConfiguratorsDataService Dane do konfururatora
 * @param {object} CurConfService           Wlasciwosci obecnego konfiguratora
 * @param {object} GarageAccessoriesFactory Fabryka z akcesoriami
 * @param {object} Core                     Biblioteka podrecznych funkcji
 * @param {object} IssuesService            Dostarcza obsluge bledow
 * @param {object} PriceService             Liczenie cen
 */
export default function PanelsFactory($rootScope, $uibModal, $location, $filter, ConfigurationsService, ConfiguratorsDataService, // jshint ignore:line
                        CurConfService, GarageAccessoriesFactory, GarageColorsFactory, Core, IssuesService, PriceService, EventBusService) {
    'ngInject';

    var factory = {
        loadedData         : false,
        garagePanels       : [],
        garageStructures   : [],
        setPanel           : setPanel,
        setStructure       : setStructure,
        valid              : valid,
        noStructures       : noStructures,
        setDefaultPanel    : setDefaultPanel,
        setDefaultStructure: setDefaultStructure
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Domyslne ustawienia kroku
     */
    function init() {
        if (CurConfService.conf == 'garage_door') {
            factory.garagePanels     = ConfiguratorsDataService.data.garagePanels;
            factory.garageStructures = ConfiguratorsDataService.data.garagePanelStructures;
            factory.loadedData       = true;
        } else {
            return;
        }
    }

    /**
     * Ustawienie domyslnego panelu
     */
    function setDefaultPanel() {
        var i;
        var panel = {};

        for (i = 0; i < factory.garagePanels.length; i++) {
            if (factory.garagePanels[i].GaragePanel.window_line_id == ConfigurationsService.conf.Current.System.id) {
                panel = Core.copy(factory.garagePanels[i].GaragePanel);
                break;
            }
        }
        ConfigurationsService.conf.Current.Panel = panel;
        setDefaultStructure();
    }

    /**
     * Ustawienie domyślnej struktury powierzchni
     */
    function setDefaultStructure() {
        var i;
        var structure = {};
        for (i = 0; i < factory.garageStructures.length; i++) {
            if (factory.garageStructures[i].GaragePanelStructure.garage_panels.indexOf(ConfigurationsService.conf.Current.Panel.id) >= 0) {
                structure = Core.copy(factory.garageStructures[i].GaragePanelStructure);
                break;
            }
        }
        ConfigurationsService.conf.Current.Structure = structure;

    }

    /**
     * Ustawienie wybranego panelu
     * @param {object} newPanel Wybrany panel
     */
    function setPanel(newPanel) {
        ConfigurationsService.conf.Current.Panel     = Core.copy(newPanel);
        setDefaultStructure();
        GarageColorsFactory.loadColorsBySystem();
        GarageAccessoriesFactory.setDefaultValues(true);
        PriceService.count();
    }

    /**
     * Ustawienie wybranej struktury powierzchni
     * @param {object} newStructure Wybrana struktura
     */
    function setStructure(newStructure) {
        ConfigurationsService.conf.Current.Structure = Core.copy(newStructure);
        GarageColorsFactory.loadColorsBySystem();
        GarageAccessoriesFactory.setDefaultValues(true);
        PriceService.count();
    }

    /**
     * Ustawienie domyślnej struktury powierzchni
     */
    function valid() {
        if (!ConfigurationsService.conf.Current.Panel
            || angular.isUndefined(ConfigurationsService.conf.Current.Panel.id)
        ) {
            IssuesService.simpleRegister(
                'no-garage-panel-type',
                'Rodzaj panelu nie zostały wybrany.',
                $filter('translate')('GARAGE|Rodzaj panelu nie zostały wybrany.'),
                ConfigurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }
        if ((!ConfigurationsService.conf.Current.Structure
            || angular.isUndefined(ConfigurationsService.conf.Current.Structure.id))
            && !noStructures(factory.garageStructures, ConfigurationsService.conf.Current.Panel.id)
        ) {
            IssuesService.simpleRegister(
                'no-garage-panel-structure',
                'Rodzaj struktury nie został wybrany.',
                $filter('translate')('GARAGE|Rodzaj struktury nie został wybrany.'),
                ConfigurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }
        IssuesService.unregister('no-garage-panel-type', ConfigurationsService.conf.Current);
        IssuesService.unregister('no-garage-panel-structure', ConfigurationsService.conf.Current);
        IssuesService.unregister('no-garage-panel-structure', ConfigurationsService.conf.Current);
        return true;
    }

    /**
     * Sprawdza czy w wybranym panelu sa dostepne struktury
     * @param  {array}  structures Tablica struktur
     * @param  {number} panel      Id panelu
     * @return {boolean}           Prawda, gdy nie ma pasujacych struktur
     */
    function noStructures(structures, panel) {
        return structures.filter(function(e) {
            return (e.GaragePanelStructure.garage_panels.indexOf(panel)) >= 0;
        }).length === 0;
    }

}
