import {Injectable, Inject, Injector} from '@angular/core';
import {APP_CONFIG, AppConfig} from './config';
import {logger} from './helpers';
import { Common } from '@icc/common/Common';

declare var WorkerGlobalScope: any;

/**
 * Fabryka użytkowników
 * @param {object} $filter             filter
 * @param {object} $q                  q
 * @param {object} SynchronizeService  SynchronizeService
 * @param {String} USER_ID             id użytkownika
 */
@Injectable()
export abstract class UserService {

    protected user: any = null;

    /**
     * Funkcja do zwracania użytkownika
     * @return {Object} Użytkownik
     */
    get() {
        if (!this.user) {
            this.loadUser();
        }
        return this.user;
    }

    get loggedIn() {
        return Common.isDefined(this.user) && this.user !== null && this.user.id != null && localStorage.getItem('token');
    }

    get offerModuleAccess() {
        return Common.isDefined(this.user) && this.user !== null && (this.user.dealerOfferingModuleAccess || ['dealer', 'dealerh', 'dealerm'].indexOf(this.user.access) === -1);
    }

    get b2c() {
        const user = this.get();
        return !user || !user.access || user.access == 'klient';
    };

    /**
     * Ładuje dane użytkownika.
     */
    abstract loadUser();

    abstract setUser(user);

    abstract synchronizeUser(): Promise<any>;

}
