import { PleatedBlindFacade } from '../+state/pleated-blind.facade';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { StepComponent, _,} from '@icc/configurator/shared';
import { Subscription } from 'rxjs';
import { APP_CONFIG, AppConfigFactory, EventBusService } from '@icc/common';

@Component({
    selector: 'icc-variants',
    templateUrl: './variants.component.html',
    styleUrls: ['./variants.component.scss'],
})
export class VariantsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('CONFIGURATOR|Wariant');
    static stepIcon = {
        ligature: 'apps',
    };

    allVariants = [];
    variants = [];
    public configurator = 'pleated_blind';
    public stepId = 'montage';
    public title = _('CONFIGURATOR|Wariant');

    availableVariants$ = this.blindFacade.availableVariants$;
    selectedVariant$ = this.blindFacade.selectedVariant$;

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private eventBusService: EventBusService,
        private blindFacade : PleatedBlindFacade
    ) {
        super();
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
      this.subscriptions.map(el => el.unsubscribe());
    }

    init() {
        this.subscriptions.push(
            this.availableVariants$.subscribe(variants => {
                this.allVariants = variants;
                this.variants = variants.map(variant => ({
                    id: String(variant.id),
                    title: variant.name,
                    imageUrl: '/files/pleatedblindvariant/' + variant.img
                }));
            })
        )
    }

    selectVariant(selectedVariant) {
        const variant = this.allVariants.find(v => v.id == selectedVariant.id);
        this.blindFacade.setVariant(variant);
    }
}
