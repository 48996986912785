<icc-select-box
[title]="'PLEATEDBLIND|Sterowanie' | translate"
[description]="selectedDriveType?.name + ' ' + ((selectedDriveType?.color?.name) ? ('(' + selectedDriveType?.color?.name + ')') : '')"
[buttonText]="'INTERFACE|Zmień' | translate"
[buttonText2]="((selectedDriveType?.drive_type === 'static_handle' || selectedDriveType?.drive_type === 'movable_handle') ? 'INTERFACE|Opcje' : '') | translate"
(select)="selectDrive()"
(select2)="changeDriveColor(selectedDriveType)"
></icc-select-box>

<div class="box-form">
    <h4>{{ 'PLEATEDBLIND|Strona pracy:' | translate }}</h4>
    <mat-radio-group [ngModel]="driveSide" (ngModelChange)="setDriveSide($event)">
        <mat-radio-button value="left">{{ 'AWNING|od lewej' | translate }}</mat-radio-button>
        <mat-radio-button value="right">{{ 'AWNING|od prawej' | translate }}</mat-radio-button>
    </mat-radio-group>
</div>

<icc-select-box
[title]="'PLEATEDBLIND|Prowadzenie' | translate"
[description]="selectedGuidanceType?.name + ' ' + ((selectedGuidanceType?.color?.name) ? ('(' + selectedGuidanceType?.color?.name + ')') : '')"
[buttonText]="'INTERFACE|Zmień' | translate"
[buttonText2]="((selectedGuidanceType?.drive_type === 'fence') ? 'INTERFACE|Opcje' : '') | translate"
(select)="selectGuidanceType()"
(select2)="changeGuidanceColor(selectedGuidanceType)"
></icc-select-box>

<icc-select-box 
[title]="'Typ domykania'" 
[description]="(selectedClosureType$ | async)?.name"
[buttonText]="'Zmień'" 
(select)="selectClosureType()">
</icc-select-box>