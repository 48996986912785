<icc-tabs [tabs]="availableTabs" *ngIf="tabs && tabs.length && !searching" [activeTabIndex]="getSelectedTab()"
 (activated)="selectTab($event)" [class.icc-tabs_tab--hide]="tabs.length === 1 && hideSingleTab"></icc-tabs>

<div class="scroller-wrapper" #scroller (scroll)="onScroll()" *ngIf="scrollable" [class.scroller-wrapper--no-tabs]="!tabs || !tabs.length || !searching || tabs.length === 1 && hideSingleTab">
    <virtual-scroller #scroll [parentScroll]="scroller" [items]="filteredItems$ | async"
        [enableUnequalChildrenSizes]="true">
        <div class="icc-list" #container [ngClass]="getItemHeightAndWidthClass()">
            <div class="icc-list-item" *ngFor="let item of scroll.viewPortItems; trackBy: trackByFunction"
                [class.icc-list-item--selected]="isSelectedId(item.id)" [class.icc-list-item--disabled]="item.disabled" [matRippleDisabled]="item.disabled" (click)="onSelect(item)" matRipple
                [class.icc-list-item--color]="itemTemplate === 'itemColor'">
                <ng-container [ngTemplateOutlet]="itemTemplateDef" [ngTemplateOutletContext]="{ iccListItem: item }">
                </ng-container>
            </div>
        </div>
    </virtual-scroller>
</div>

<div class="icc-list" *ngIf="!scrollable" [class.no-scrollable]="!scrollable" [ngClass]="getItemHeightAndWidthClass()">
    <div class="icc-list-item" *ngFor="let item of (filteredItems$ | async)"
        [class.icc-list-item--selected]="isSelectedId(item.id)" [class.icc-list-item--disabled]="item.disabled" [matRippleDisabled]="item.disabled" (click)="onSelect(item)" matRipple
        [class.icc-list-item--color]="itemTemplate === 'itemColor'">
        <ng-container [ngTemplateOutlet]="itemTemplateDef" [ngTemplateOutletContext]="{ iccListItem: item }">
        </ng-container>
    </div>
</div>

<ng-template #itemColor let-item="iccListItem">
    <div class="item-color">
        <div
            class="item-color-fill"
            [style.backgroundColor]="item.color"
            [style.backgroundImage]="'url(' + item.imageUrl + ')'"
        ></div>
        <button mat-mini-fab class="item-color-info-button" *ngIf="item.showInfo" (click)="clickShowInfoButton(item)" color="secondary" [title]="item.description || ''">
            <mat-icon class="mat-18">info</mat-icon>
        </button>
        <button mat-mini-fab class="item-color-glazing-button" *ngIf="item.editGlazing" (click)="clickEditGlazingButton(item)" color="secondary" [title]="item.description">
            <mat-icon class="mat-18">build</mat-icon>
        </button>
        <p class="item-color-title">{{item.title}}</p>
    </div>
</ng-template>

<ng-template #itemImgTitleDescription let-item="iccListItem">
    <div class="item-imgTitleDescription" [ngClass]="vertical ? 'item-imgTitleDescription--vertical ' : ''">
        <p class="item-imgTitleDescription-title">{{item.title}}</p>
        <div class="icc-list-item-img-container">
            <img class="item-imgTitleDescription-img" [src]="item.imageUrl" [alt]="item.title" defaultImg>
        </div>
        <div [innerHTML] = "item.description" class="item-imgTitleDescription-description">{{item.description}}</div>
        <button mat-mini-fab class="item-imgTitleDescription-info-button mat-elevation-z0" *ngIf="item.showInfo " (click)="clickShowInfoButton(item)" color="secondary" [title]="item.description || ''">
            <mat-icon class="mat-18">info</mat-icon>
        </button>
        <button mat-mini-fab class="item-imgTitleDescription-glazing-button mat-elevation-z0" *ngIf="item.editGlazing" (click)="clickEditGlazingButton(item)" color="secondary" [title]="item.description">
            <mat-icon class="mat-18">build</mat-icon>
        </button>
    </div>
</ng-template>

<ng-template #mountingMethod let-item="iccListItem">
    <div class="item-imgTitleDescription">
        <div class="icc-list-item-img-container">
            <img class="item-imgTitleDescription-img" [src]="item.img" [alt]="item.name" defaultImg>
        </div>
        <p class="item-imgTitleDescription-title">{{item.name}}</p>
        <div [innerHTML] = "''" class="item-imgTitleDescription-description">{{item.description}}</div>
    </div>
</ng-template>

<ng-template #itemImgTitle let-item="iccListItem">
    <div *ngIf='item?.errorMessages && item?.errorMessages?.length ' class='errorMessage'>
        <p *ngFor='let message of item?.errorMessages'>
            {{message}}
        </p>
    </div>
    <div class="item-imgTitle" [ngClass]="item.disabled ? 'item-imgTitle-disable' : ''">
        <div class="icc-list-item-img-container">
            <img class="item-imgTitle-img" [src]="item.imageUrl" [alt]="item.title" defaultImg>
        </div>
        <p class="item-imgTitle-title">{{item.title}}</p>
        <button mat-mini-fab class="item-itemImgTitle-info-button mat-elevation-z0" *ngIf="item.showInfo " (click)="clickShowInfoButton(item)" color="secondary" [title]="item.description || ''">
            <mat-icon class="mat-18">info</mat-icon>
        </button>
        <button mat-mini-fab class="item-itemImgTitle-glazing-button" *ngIf="item.editGlazing" (click)="clickEditGlazingButton(item)" color="secondary" [title]="item.description">
            <mat-icon class="mat-18">build</mat-icon>
        </button>
    </div>
    <div class="item-info" *ngIf="item.infoBox && item.infoBox.show">
        <p class="item-info-options">{{item.infoBox.title}}</p>
        <p class="item-info-size">{{item.infoBox.size}}</p>
        <p class="item-info-size">{{item.infoBox.system}}</p>
    </div>
</ng-template>
<ng-template #itemOnlyTitle let-item="iccListItem">
    <div class="item-onlyTitle">
        <p class="item-onlyTitle-title">{{item.title}}</p>
    </div>
</ng-template>

