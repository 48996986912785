import { ComponentType } from '@angular/cdk/portal';
import { PageComponent, ModalService, isNotNullOrUndefined } from '@icc/helpers';
import { SharedFacade } from '../+state/shared.facade';
import { Store } from '@ngrx/store';
import { ConfiguratorState } from '../..';
import { sharedQuery } from '../+state/shared.selectors';
import { core } from '@icc/common/Core';
import { filter, map, tap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class ConfiguratorModalService extends ModalService {
    constructor(private sharedFacade: SharedFacade, private store: Store<ConfiguratorState>) {
        super();
    }

    open<T = any>(options: {
        /**
         * a path to a template representing modal's content
         */
        templateUrl?: string | (() => string);

        /**
         * a controller for a modal instance - it can initialize scope used by modal.
         * A controller can be injected with `$modalInstance`
         * If value is an array, it must be in Inline Array Annotation format for injection (strings followed by factory method)
         */
        controller?: string | Function | Array<string | Function>;

        /**
         * A string reference to the component to be rendered that is registered with Angular's compiler. If using a directive, the directive must have `restrict: 'E'` and a template or templateUrl set.
         *
         * It supports these bindings:
         *   - `close` - A method that can be used to close a modal, passing a result. The result must be passed in this format: `{$value: myResult}`
         *   - `dismiss` - A method that can be used to dismiss a modal, passing a result. The result must be passed in this format: `{$value: myRejectedResult}`
         *   - `modalInstance` - The modal instance. This is the same `$uibModalInstance` injectable found when using `controller`.
         *   - `resolve` - An object of the modal resolve values. See [UI Router resolves] for details.
         */
        component?: string;

        /**
         * members that will be resolved and passed to the controller as locals; it is equivalent of the `resolve` property for AngularJS routes
         * If property value is an array, it must be in Inline Array Annotation format for injection (strings followed by factory method)
         */
        resolve?: { [key: string]: string | Function | Array<string | Function> | Object };

        /**
         * additional CSS class(es) to be added to a modal window template
         */
        windowClass?: string;

        pageComponent: ComponentType<PageComponent>;
        /**
         * Otwieranie w panelu filtrów.
         */
        filters?: boolean;
    }): {
        result: PromiseLike<T>;
        closed: PromiseLike<void>;
        close: () => void;
    } {
        const id = core.generateUUID();
        const data: any = {};
        if (options.resolve) {
            Object.keys(options.resolve).forEach((key: any) => {
                if (options.resolve && options.resolve[key]) {
                    const value = options.resolve[key];
                    if (typeof value === 'function') {
                        data[key] = value();
                    } else {
                        data[key] = value;
                    }
                }
            });
        }

        if (options.filters) {
            this.sharedFacade.openFilterSheet(id, options.pageComponent, data);
        } else {
            this.sharedFacade.openPage(id, options.pageComponent, data);
        }

        const result = this.store
            .select(
                options.filters ? sharedQuery.getClosedFilterSheets : sharedQuery.getClosedPages
            )
            .pipe(
                map(pages => pages.find(page => page.id === id)),
                filter(isNotNullOrUndefined),
                map(page => page.result),
                take(1)
            )
            .toPromise();

        return {
            result,
            closed: result,
            close: () => this.sharedFacade.closePage(),
        };
    }
}
