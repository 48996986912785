import {
    ConfigurationsService,
    ConfiguratorsDataService,
    TranslateService,
    EventBusService,
} from '@icc/common';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { StepFactory } from '../StepFactory';
import { PriceService } from '@icc/price';

import { StepsService, isUndefined, IssuesService, IssueLevel } from '@icc/helpers';
import { ModalService } from '@icc/helpers';
import { Injectable } from '@angular/core';

import { ColorsPageComponent } from '@icc/configurator/window';
@Injectable()
export class SheathingService {
    fabrics = [];
    styles = [];
    fabric?: any;
    style = {};
    selectedStyles = [];
    loadedData = false;
    constructor(
        private configurationsService: ConfigurationsService<'awning'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private curConfService: CurrentConfiguratorService,
        private translateService: TranslateService,
        private stepsService: StepsService,
        private priceService: PriceService,
        private issuesService: IssuesService,
        private modalService: ModalService,
        private eventBusService: EventBusService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribeWithoutConfiguration('changedStep', () => {
            this.init();
        });
    }
    'ngInject';

    init() {
        if (this.curConfService.conf === 'awning') {
            this.setValues();
            if (isUndefined(this.configurationsService.conf.Current.Fabric.id)) {
                this.setDefaultValues();
            }
            this.loadedData = true;
        } else {
            return;
        }
    }

    setValues() {
        this.fabrics = this.getFabrics();
        this.styles = this.getStyles();
    }

    getFabrics() {
        return this.configuratorsDataService.data.awningFabrics;
    }

    getStyles(){
        const styles = [];
        this.fabrics.forEach(sheathing =>{
            styles.push(...sheathing.styles);
        })
        return styles;
    }

    selectFabric(fabric, nextStep, price) {
        this.fabric = fabric;

        this.configurationsService.conf.Current.Fabric = this.fabric;
        this.configurationsService.conf.Default.Fabric = this.fabric;
        this.configurationsService.conf.Current.FabricStyle = this.fabric.styles[0];
        this.configurationsService.conf.Default.FabricStyle = this.fabric.styles[0];

        if (isUndefined(this.selectedStyles[this.fabric.id])) {
            this.selectStyle(this.selectedStyles[this.fabric.id], false, true);
        } else {
            this.style = {};
        }

        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
        if (price) {
            this.priceService.count();
        }
    }

    selectStyle(style, nextStep?, price?) {
        this.style = style;
        this.selectedStyles[this.fabric.id] = style;
        this.configurationsService.conf.Current.FabricStyle = style;
        this.configurationsService.conf.Default.FabricStyle = style;

        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
        if (price) {
            this.priceService.count();
        }
    }

    /**
     * Ustawianie wartości domyślnych
     * @param {Boolean} reset Reset
     */
    setDefaultValues(reset?) {
        if (isUndefined(this.configurationsService.conf)) {
            return;
        }

        this.setValues();

        if (isUndefined(this.configurationsService.conf.Current.Fabric.id) || reset) {
            this.selectFabric(this.fabrics[0], false, true);
            this.selectStyle(this.fabrics[0].styles[0], false, true);
        }
    }

    /**
     * Styl otwartego modalu
     */
    openModalStyle() {
        var modalInstance = this.modalService.open({
            templateUrl: 'modalFabricStyles.html',
            controller: 'ModalFabricStylesCtrl as mFabricStyles',
            pageComponent: ColorsPageComponent,
            resolve: {
                styles: () => {
                    return this.fabric.styles;
                },
            },
        });

        modalInstance.result.then(selectedStyle => {
            this.selectStyle(selectedStyle[0], false, true);
        });
    }

    /**
     * Walidacja
     * @return {Object} Walidacja
     */
    valid() {
        if (!this.configurationsService.conf.Current.FabricStyle.id) {
            this.issuesService.simpleRegister(
                'sheathing',
                'Wybierz poszycie.',
                this.translateService.instant('AWNING|Wybierz poszycie.'),
                this.configurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        this.issuesService.unregister('sheathing', this.configurationsService.conf.Current);
        return true;
    }
}
