/**
 * Kontroler akcesoriów garażowych
 * @param {Object} $scope                   Angular scope
 * @param {Object} GarageAccessoriesFactory Fabryka akcesoriów garażowych
 * @param {Object} CurConfService           Bieżaca konfiguracja
 */
export default function AccessoriesCtrl($scope, GarageAccessoriesFactory, ConfigurationsService, CurConfService, EventBusService) { // jshint ignore:line
    'ngInject';

    var vm = this;

    vm.subStep       = 1;
    vm.controls      = [];
    vm.selControl    = null;
    vm.setControl    = setControl;
    vm.config        = ConfigurationsService;

    if (GarageAccessoriesFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjujaca
     * Ustawia domyślne wartości po uruchomieniu
     */
    function init() {
        vm.controls = GarageAccessoriesFactory.controls;
        if (angular.isDefined(ConfigurationsService.conf.Current.Control)) {
            vm.selControl = vm.selControl = ConfigurationsService.conf.Current.Control;
        }
        // if () {
        //     vm.selControl = vm.controls[0];
        // }
    }

    /**
     * Ustawia sterowanie
     */
    function setControl(control) {
        GarageAccessoriesFactory.setControl(control, true);
    }

}
