import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import {Injectable} from '@angular/core';

@Injectable()
export class PriceAwningService {

    constructor() {}

    /**
     * Wylicza cenę markiz zależną od rozmiru
     * @param  {object} conf          Konfiguracja
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Powody braku ceny
     * @return {number}               Cena po dopłatach
     */
    @PriceFunc({
        shortName: 'awningSize',
        data: {
            sizePrice: 'conf.SizePrice',
            width: 'conf.Width',
            outreach: 'conf.Outreach'
        }
    })
    awningPriceForSize({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {sizePrice, width, outreach}): PriceSegment[] {
        if (sizePrice > 0) {
            PriceElems.size = {
                width   : width,
                outreach: outreach,
                price   : parseFloat(sizePrice)
            };
            return [{
                type: 'size',
                baseValue: parseFloat(sizePrice),
                value: parseFloat(sizePrice),
                valueType: 'value',
                data: {
                    width   : width,
                    outreach: outreach,
                }
            }];
        }
        NoPriceCauses.push('no prices for size');

        return [{
            type: 'size',
            baseValue: null,
            value: null,
            valueType: 'value',
            data: {}
        }];
    }

    /**
     * Dolicza dopłatę zależną od linii markiz
     * @param  {number} price         Cena wejściowa
     * @param  {object} conf          Konfiguracja
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Powody braku ceny
     * @return {number}               Cena po dopłatach
     */
    @PriceFunc({
        shortName: 'awningLine',
        data: {
            system: 'conf.System'
        }
    })
    awningPriceForLine({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {system}): PriceSegment[] {
        PriceElems.line = {
            id          : system.id,
            name        : system.name,
            price_factor: parseFloat(system.price_factor),
            price_margin: parseFloat(system.price_margin),
        };

        return [{
            type: 'line',
            baseValue: system.price_factor,
            value: system.price_factor,
            valueType: 'percent',
            data: {
                id  : system.id,
                name: system.name,
            }
        }, {
            type: 'line',
            baseValue: 1 + (system.price_margin / 100),
            value: 1 + (system.price_margin / 100),
            valueType: 'percent',
            data: {
                id  : system.id,
                name: system.name,
            }
        }];
    }

    /**
     * Dolicza dopłatę zależną od poszycia markizy
     * @param  {number} price         Cena wejściowa
     * @param  {object} conf          Konfiguracja
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Powody braku ceny
     * @return {number}               Cena po dopłatach
     */
    @PriceFunc({
        shortName: 'awningFabric',
        data: {
            fabric: 'conf.Fabric'
        }
    })
    awningPriceForFabric({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {fabric}): PriceSegment[] {
        PriceElems.fabric = {
            id          : fabric.id,
            name        : fabric.name,
            price_factor: parseFloat(fabric.price_factor)
        };

        return [{
            type: 'fabric',
            baseValue: 1 + (fabric.price_factor / 100),
            value: 1 + (fabric.price_factor / 100),
            valueType: 'percent',
            data: {
                id  : fabric.id,
                name: fabric.name,
            }
        }];
    }

    /**
     * Dolicza dopłatę zależną od wybranej falbany markizy (plus falbana zwijana, plus napis na falbanie)
     * @param  {number} price         Cena wejściowa
     * @param  {object} conf          Konfiguracja
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Powody braku ceny
     * @return {number}               Cena po dopłatach
     */
    @PriceFunc({
        shortName: 'awningValance',
        data: {
            valance: 'conf.Valance',
            valanceInscription: 'conf.ValanceInscription',
            width: 'conf.Width',
            rollUpPrices: 'conf.RollUpPrices',
            rollUpValance: 'conf.RollUpValance'
        }
    })
    awningPriceForValance({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {valance, valanceInscription, width, rollUpPrices, rollUpValance}): PriceSegment[] {
        var i = 0;
        let price = 0;

        if (valance.price > 0) {
            price += (valance.price * (width / 100));
        } else {
            valance.price = 0;
        }
        valance.text_price = valance.text_price * 1;
        if (valanceInscription !==  '' && valance.text_price > 0 && valance.text == '1') {
            price += valance.text_price;
        }

        var rollUpPrice = null;

        if (rollUpValance) {
            for (i = 0; i < rollUpPrices.length; i++) {
                if (rollUpPrices[i].min_width <= width && width <= rollUpPrices[i].max_width) {
                    rollUpPrice = parseFloat(rollUpPrices[i].price);
                    price += rollUpPrice;
                }
            }

            if (rollUpPrice === null) {
                price = NaN;
                NoPriceCauses.push('no price for roll up valance');
            }
        }

        PriceElems.valance = {
            id         : valance.id,
            name       : valance.name,
            width      : (width / 100),
            inscription: valanceInscription,
            price_per_m: parseFloat(valance.price),
            price      : valance.price * (width / 100),
            text_price : parseFloat(valance.text_price),
            rollUpPrice: parseFloat(rollUpPrice)
        };
        return [{
            type: 'valance',
            baseValue: isNaN(price) ? null : price,
            value: isNaN(price) ? null : price,
            valueType: 'value',
            data: {
                id         : valance.id,
                name       : valance.name,
                width      : (width / 100),
                inscription: valanceInscription,
                price_per_m: parseFloat(valance.price),
                text_price : parseFloat(valance.text_price),
                rollUpPrice: parseFloat(rollUpPrice)
            }
        }];
    }

    /**
     * Dolicza dopłatę zależną od napędu markizy
     * @param  {number} price         Cena wejściowa
     * @param  {object} conf          Konfiguracja
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Powody braku ceny
     * @return {number}               Cena po dopłatach
     */
    @PriceFunc({
        shortName: 'awningDrive',
        data: {
            driveType: 'conf.DriveType',
            motor: 'conf.Motor',
            motorOptions: 'conf.MotorOptions'
        }
    })
    awningPriceForDrive({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {driveType, motor, motorOptions}): PriceSegment[] {
        if (driveType == 'motor') {
            var i = 0;
            let price = 0;
            if (motor.price > 0) {
                price += (motor.price * 1);
            }
            PriceElems.drive = {
                id         : motor.id,
                name       : motor.name,
                price      : parseFloat(motor.price),
                options    : []
            };

            if (motorOptions.length > 0) {
                for (i = 0; i < motorOptions.length; i++) {
                    if (motorOptions[i].price > 0) {
                        price += (motorOptions[i].price * 1);
                    } else {
                        motorOptions[i].price = 0;
                    }
                    PriceElems.drive.options.push({
                        name : motorOptions[i].name,
                        price: parseFloat(motorOptions[i].price)
                    });
                }
            }

            return [{
                type: 'drive',
                baseValue: price,
                value: price,
                valueType: 'value',
                data: {
                    name: motor.name,
                    id  : motor.id,
                }
            }];
        }

        return [{
            type: 'drive',
            baseValue: 0,
            value: 0,
            valueType: 'value',
            data: {}
        }];
    }

    /**
     * Dolicza dopłatę zależną od sposobu montażu markizy
     * @param  {number} price         Cena wejściowa
     * @param  {object} conf          Konfiguracja
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Powody braku ceny
     * @return {number}               Cena po dopłatach
     */
    @PriceFunc({
        shortName: 'awningMounting',
        data: {
            mounting: 'conf.Mounting',
            mountingQuantity: 'conf.MountingQuantity'
        }
    })
    awningPriceForMounting({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {mounting, mountingQuantity}): PriceSegment[] {
        PriceElems.mounting = {
            id         : mounting.id,
            name       : mounting.name,
            price_per_q: parseFloat(mounting.price),
            quantity   : mountingQuantity,
            price      : mounting.price * mountingQuantity
        };
        return [{
            type: 'mounting',
            baseValue: mounting.price * mountingQuantity,
            value: mounting.price * mountingQuantity,
            valueType: 'value',
            data: {
                id         : mounting.id,
                name       : mounting.name,
                price_per_q: parseFloat(mounting.price),
                quantity   : mountingQuantity
            }
        }];
    }

    /**
     * Dolicza dopłatę do wybranych akcesoriów markizy
     * @param  {number} price         Cena wejściowa
     * @param  {object} conf          Konfiguracja
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Powody braku ceny
     * @return {number}               Cena po dopłatach
     */
    @PriceFunc({
        shortName: 'awningAccessories',
        data: {
            addons: 'conf.Addons'
        }
    })
    awningPriceForAccessories({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {addons}): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        if (addons.length > 0) {
            for (let i = 0; i < addons.length; i++) {
                if (addons[i].price > 0) {
                    PriceElems.accessories.push({
                        id   : addons[i].id,
                        name : addons[i].name,
                        price: parseFloat(addons[i].price)
                    });
                    priceSegments.push({
                        type: 'accessories',
                        baseValue: parseFloat(addons[i].price),
                        value: parseFloat(addons[i].price),
                        valueType: 'value',
                        data: {
                            id  : addons[i].id,
                            name: addons[i].name,
                        }
                    });
                } else if (addons[i].price == '-1') {
                    NoPriceCauses.push('no prices for accessory');
                    priceSegments.push({
                        type: 'accessories',
                        baseValue: null,
                        value: null,
                        valueType: 'value',
                        data: {
                            id  : addons[i].id,
                            name: addons[i].name,
                        }
                    });
                }
            }
        }

        return priceSegments;
    }
}
