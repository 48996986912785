export default function ProfilesFilter() {
    'ngInject';

    return (profiles, type) => {
        if (type) {
            return profiles.filter(
                profile => profile.type === type);
        } else {
            return profiles;
        }
    };
}
