export default function PagFilter() {
    'ngInject';

    return function(input, current, limit) {
        if (typeof input == 'undefined') {
            return false;
        }
        if (typeof current == 'undefined') {
            current = 1;
        }
        if (typeof limit == 'undefined') {
            limit = 10;
        }
        var out = [], i, n;
        i = (current * limit) - limit;
        n = (current * limit);
        if (n > input.length) {
            n = input.length;
        }
        for (; i < n; i++) {
            out.push(input[i]);
        }

        return out;
    };
}
