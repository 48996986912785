import {
    WindowAction,
    WindowActionTypes,
    UpdateWindowConfiguration,
    fromWindowActions,
} from './window.actions';
import { SharedAction } from '@icc/configurator/shared';
import { IWindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';

export const WINDOW_FEATURE_KEY = 'window';

export interface WindowState {
    configuration: IWindowActiveConfiguration | null;
}

export interface WindowPartialState {
    readonly [WINDOW_FEATURE_KEY]: WindowState;
}

export const initialState: WindowState = {
    configuration: null,
};

export function windowReducer(
    state: WindowState = initialState,
    action: WindowAction | SharedAction
): WindowState {
    switch (action.type) {
        case WindowActionTypes.UpdateWindowConfiguration: {
            return {
                ...state,
                configuration: {
                    ...action.payload.configuration,
                },
            };
            break;
        }
    }
    return state;
}
