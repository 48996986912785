import { Component, OnInit, Type, Inject } from '@angular/core';
import { _, PageComponent, SharedFacade } from '@icc/configurator/shared';
import { WindowFacade } from '../+state/window.facade';
import { ICC_PAGE_DATA } from '@icc/helpers';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { IccColorGroup, IccConstructColor } from '@icc/common/data-types';

@Component({
    selector: 'icc-muntins-colors',
    templateUrl: './muntins-colors.component.html',
    styleUrls: ['./muntins-colors.component.scss'],
})
export class MuntinsColorsComponent extends PageComponent implements OnInit {
    public title = _('COLOR|Wybierz kolor:');
    public options = [];

    colors: iccListItem[] = [];
    colorGroups: iccListTab[] = [];
    selectedId: number | null = null;

    constructor(
        @Inject(ICC_PAGE_DATA) private pageData: {
            barWidth: number;
            colors: IccConstructColor[];
            colorGroups: IccColorGroup[];
            selColor: IccConstructColor;
            muntinType: any
        },
        private windowFacade: WindowFacade,
        private sharedFacade: SharedFacade,
    ) {
        super();
    }

    ngOnInit() {
        const { colors, colorGroups: groups } = this.pageData;
        this.colors = colors.map<iccListItem>(color => ({
            id: Number(color.id),
            title: color.name,
            description: color.code,
            color: '#' + color.color,
            imageUrl: '/files/color/' + color.color_img,
            tabs: (color.groups && color.groups.map(Number)) || [],
        }));

        this.colorGroups = groups.map<iccListTab>(group => ({
            id: Number(group.id),
            name: group.name,
        }));
        this.selectedId = Number(this.pageData.selColor.id);
    }

    selectMuntinColor(item: iccListItem) {
        const { colors } = this.pageData;
        const color = colors.find(c => Number(c.id) === item.id);
        this.sharedFacade.closePage(color);
    }
}
