import { Injectable } from '@angular/core';
import {TimeLimit, TimeLimitSegment} from './time-limit-functions';
import { TimeLimitProfileService } from './time-limit-profile.service';

@Injectable()
export class TimeLimitAccessoryService {

    constructor(
        private timeLimitProfileService: TimeLimitProfileService,
    ) {}

    /**
     * Pobiera terminy realizacji akcesoriów do całej kwatery.
     *
     * @param {object} sashes   Akcesoria
     * @return {number} Terminy realizacji akcesoriów.
     */
    @TimeLimit({
        shortName: 'accessory',
        data: {
            accessories : 'conf.Accessories',
        }
    })
    accessoryTimeLimit({timeLimits, accessories}): TimeLimitSegment[] {
        if (accessories == null || !accessories.length) {
            return null;
        }

        const segments = [];

        accessories.forEach(accessory => {
            segments.push(this.getTimeLimitFromAccessory(timeLimits, accessory, 'accessory'));
        });

        return segments;
    }

    /**
     * Pobiera terminy realizacji akcesoriów bocznych.
     *
     * @param {object} sashes   Akcesoria
     * @return {number} Terminy realizacji akcesoriów.
     */
    @TimeLimit({
        shortName: 'sideAccessory',
        data: {
            accessories : 'conf.SideAccessories',
        }
    })
    sideAccessoryTimeLimit({timeLimits, accessories}): TimeLimitSegment[] {
        const sides = [
            'bottom',
            'left',
            'right',
            'top',
        ];

        const segments = [];
        sides.forEach(side => {
            if (accessories[side] == null || !accessories[side].length) {
                return;
            }

            accessories[side].forEach(accessory => {
                segments.push(this.getTimeLimitFromAccessory(timeLimits, accessory, 'sideAccessory'));
            });
        });

        return segments;
    }

    /**
     * Pobiera terminy realizacji akcesoriów kwater.
     *
     * @param {object} sashes   Kwatery
     * @return {number} Terminy realizacji akcesoriów.
     */
    @TimeLimit({
        shortName: 'sashAccessory',
        data: {
            sashes : 'conf.Sashes',
        }
    })
    sashAccessoryTimeLimit({timeLimits, sashes}): TimeLimitSegment[] {
        if (sashes == null || !sashes.length) {
            return null;
        }

        const segments = [];

        sashes.forEach(sash => {
            if (sash.hardware == null || !sash.hardware.length) {
                return;
            }

            sash.hardware.forEach(accessory => {
                segments.push(this.getTimeLimitFromAccessory(timeLimits, accessory, 'sashAccessory'));
            });
        });

        return segments;
    }

    /**
     * Pobiera terminy realizacji dóbr komplementarnych.
     *
     * @param {object} accessories   Dobra komplementarne
     * @return {number} Terminy realizacji dóbr komplementarnych.
     */
    @TimeLimit({
        shortName: 'complementaryGood',
        data: {
            accessories : 'conf.ComplementaryGoods',
            timeLimitsProfiles: 'data.timeLimitsProfiles',
        }
    })
    complementaryGoodsTimeLimit({timeLimits, accessories, timeLimitsProfiles}): TimeLimitSegment[] {
        const types = [
            'accessory',
            'cassonetto',
            'glass',
            'windowsill',
        ];

        const segments = [];
        types.forEach(type => {
            if (accessories[type] == null || !accessories[type].length) {
                return;
            }

            accessories[type].forEach(accessory => {
                segments.push(this.getTimeLimitFromAccessory(timeLimits, accessory, 'complementaryGood'));
            });
        });


        if (accessories['profile'] != null && accessories['profile'].length) {
            accessories['profile'].forEach(accessory => {
                segments.push(this.timeLimitProfileService.getTimeLimitFromProfile(
                    timeLimits, timeLimitsProfiles, accessory.accessory, 'complementaryGood'));
            });
        }

        return segments;
    }

    getTimeLimitFromAccessory(timeLimits, accessory, type) {
        if (!accessory.accessory || !accessory.accessory.time_limit_id) {
            return;
        }

        const timeLimitId = accessory.accessory.time_limit_id;

        return {
            type,
            value: timeLimits[timeLimitId],
            data: {
                id  : accessory.accessory.id,
                name: accessory.accessory.name,
            }
        };
    }
}
