import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import {
    StepComponent,
    ModalService,
    SharedFacade,
    _,
    ConfiguratorOptions,
    StartedChangingStepValue,
    Issue,
} from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { map } from 'rxjs/operators';
import { EventBusService } from '@icc/common/event-bus.service';
import { SystemsService } from '@icc/legacy/configurator/steps/window/systems/systems.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { AppConfigFactory, APP_CONFIG } from '@icc/common/config';
import { WindowFacade } from '../+state/window.facade';
import { TranslateService, WindowActiveConfiguration } from '@icc/common';
import { SystemInfoComponent } from '../system-info/system-info.component';
import { IccSystem } from '@icc/common/data-types';
import {
    LoadedConfiguratorsDataValue,
    ConfiguratorsDataService,
} from '@icc/common/configurators/configurators-data.service';
import { HardwareService } from 'libs/configurator/door/src/lib/hardware/hardware.service';

@Component({
    selector: 'icc-system-packets',
    templateUrl: './system-packets.component.html',
    styleUrls: ['./system-packets.component.scss'],
})
export class SystemPacketsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Pakiety');
    static stepIcon = {
        ligature: 'star',
    };
    public configurator = 'window';
    public stepId = 'packets';
    public title = _('WINDOW|Zaznacz pakiety');

    packets: iccListItem[] = [];
    selectedPackets: number[] = [];

    matchingSystem?: IccSystem;

    isPacketChanged = false;

    private subscriptions: any[] = [];

    static stepEnable = (
        conf: WindowActiveConfiguration,
        options: ConfiguratorOptions,
        data: ConfiguratorsDataService['data']
    ) =>
        Boolean(
            conf.System
                && data.systemPackets
                && data.systemPackets.length > 0
                && data.windowLinesAll
                && data.windowLinesAll.some(s => Number(s.parent_id) === Number(conf.System.id) || Number(s.id) === Number(conf.System.parent_id))
                && (data.windowLinesAll.some(s => Number(s.parent_id) === Number(conf.System.id) && s?.packets?.length) || conf.System.parent_id)
        );

    constructor(
        private systemsService: SystemsService,
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService<'window' | 'sliding_door' | 'door'>,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private modalService: ModalService,
        private sharedFacade: SharedFacade,
        private windowFacade: WindowFacade,
        private translateService: TranslateService,
        private hardwareService: HardwareService
    ) {
        super();
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );

        this.subscriptions.push(
            this.eventBusService.subscribe<StartedChangingStepValue>('startedChangingStep', data => {
                if (
                    data.value.nextStep.code !== 'packets'
                    && data.value.nextStep.code !== data.value.prevStep.code
                    && data.value.prevStep.code === 'packets'
                ) {
                    if (this.selectPackets(this.selectedPackets, true)) {
                        this.select();
                    } else {
                        data.value.cancel([new Issue({
                            key: 'no-matching-system',
                            description: this.translateService.instant('WINDOW|Niedostępna kombinacja pakietów'),
                            blockAddToOffer: true,
                        })]);
                    }
                }
            })
        );
    }

    ngOnInit() {
        if (this.systemsService.loadedData) {
            this.init();
        }
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    init() {
        this.load();
        this.selectPackets(this.configurationsService.conf.Current.systemPackets || []);
    }

    selectPackets(ids: number[], showWarning = false) {
        if (this.configurationsService.conf.Edit && this.isPacketChanged) {
            this.hardwareService.isSystemOrPacketChangedInEditMode = true;
        } else {
            this.hardwareService.isSystemOrPacketChangedInEditMode = false;
        }

        this.selectedPackets = ids;
        this.matchingSystem = this.systemsService.getMatchingSystem(
            this.configurationsService.conf.Current.System,
            this.configurationsService.conf.Current,
            ids,
            showWarning
        );
        this.load();
        return this.matchingSystem;
    }

    showInfo(item: iccListItem) {
        const packet = this.systemsService.systemPackets.find(s => Number(s.id) === item.id);
        const modalService = this.modalService.open({
            pageComponent: SystemInfoComponent,
            resolve: {
                system: () => packet,
                button: () => false,
                descriptionFieldName: () => 'description',
                imageFieldName: () => 'image',
                imageDirectory: () => 'windowlinepacket'
            },
        });
    }

    select() {
        return this.systemsService.selectPackets(
            this.selectedPackets,
            this.configurationsService.conf.Current,
            this.configurationsService.conf.Default,
            true,
            true,
            true
        );
    }

    private load(packets = this.systemsService.systemPackets) {
        const availablePackets = this.systemsService.getMatchingPackets(
            this.configurationsService.conf.Current.System,
            this.configurationsService.conf.Current,
            []
        );
        this.packets = packets.filter(packet => availablePackets.includes(packet.id)).map(packet => {
            return {
                id: Number(packet.id),
                title: packet.name,
                imageUrl: '/files/windowlinepacket/' + packet.image,
                showInfo: true,
            };
        });
    }
}
