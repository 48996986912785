import { ConfiguratorsDataService } from '@icc/common';
import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { PleatedBlindPartialState } from './pleated-blind.reducer';
import { pleatedBlindQuery } from './pleated-blind.selectors';
import { 
    LoadPleatedBlind,
    SetPleatedBlindSystem,
    SetPleatedBlindVariant,
    SetPleatedBlindMainFabric,
    SetPleatedBlindAdditionalFabric,
    SetPleatedBlindProfilesColor,
    SetPleatedBlindShape,
    SetPleatedBlindDrive,
    SetPleatedBlindDriveSide,
    SetPleatedBlindGuidanceType,
    SetPleatedBlindClosureType } from './pleated-blind.actions';
import { map } from 'rxjs/operators';

@Injectable()
export class PleatedBlindFacade {
    availableSystems$ = this.configuratorsDataService.data$.pipe(
        map(data => data.pleatedBlindSystems)
    );

    availableFabrics$ = this.configuratorsDataService.data$.pipe(
        map(data => data.pleatedBlindFabrics)
    );

    availableVariants$ = this.configuratorsDataService.data$.pipe(
        map(data => data.pleatedBlindVariants)
    )

    availableDrives$ = this.configuratorsDataService.data$.pipe(
        map(data => data.pleatedBlindDrives)
    )

    availableGuidanceTypes$ = this.configuratorsDataService.data$.pipe(
        map(data => data.pleatedBlindGuidanceTypes)
    )

    availableClosureTypes$ = this.configuratorsDataService.data$.pipe(
        map(data => data.pleatedBlindClosureTypes)
    )

    availableColors$ = this.configuratorsDataService.data$.pipe(
        map(data => data.pleatedBlindAllColors)
    )

    selectedVariant$ = this.store.pipe(
        select(pleatedBlindQuery.getVariant)
    )

    selectedSystem$ = this.store.pipe(
        select(pleatedBlindQuery.getSystem)
    )

    selectedMainFabric$ = this.store.pipe(
        select(pleatedBlindQuery.getMainFabric)
    )

    selectedAdditionalFabric$ = this.store.pipe(
        select(pleatedBlindQuery.getAdditionalFabric)
    )

    selectedProfilesColor$ = this.store.pipe(
        select(pleatedBlindQuery.getProfilesColor)
    )

    selectedShape$ = this.store.pipe(
        select(pleatedBlindQuery.getShape)
    )

    selectedDriveSide$ = this.store.pipe(
        select(pleatedBlindQuery.getDriveSide)
    )

    selectedDrive$ = this.store.pipe(
        select(pleatedBlindQuery.getDrive)
    )

    selectedGuidanceType$ = this.store.pipe(
        select(pleatedBlindQuery.getGuidanceType)
    )

    selectedClosureType$ = this.store.pipe(
        select(pleatedBlindQuery.getClosureType)
    )

    constructor(
        private store: Store<PleatedBlindPartialState>,
        private configuratorsDataService: ConfiguratorsDataService,
    ) {}

    loadAll() {
        this.store.dispatch(new LoadPleatedBlind());
    }

    setSystem(system: any) {
        this.store.dispatch(new SetPleatedBlindSystem(system));
    }

    setVariant(variant: any) {
        this.store.dispatch(new SetPleatedBlindVariant(variant));
    }

    setMainFabric(fabric: any) {
        this.store.dispatch(new SetPleatedBlindMainFabric(fabric));
    }

    setAdditionalFabric(fabric: any) {
        this.store.dispatch(new SetPleatedBlindAdditionalFabric(fabric));
    }

    setProfilesColor(color: any) {
        this.store.dispatch(new SetPleatedBlindProfilesColor(color));
    }

    setShape(shape: any) {
        this.store.dispatch(new SetPleatedBlindShape(shape));
    }

    setDrive(drive: any) {
        this.store.dispatch(new SetPleatedBlindDrive(drive));
    }
    
    setDriveSide(driveSide: any) {
        this.store.dispatch(new SetPleatedBlindDriveSide(driveSide));
    }

    setGuidanceType(guidanceType: any) {
        this.store.dispatch(new SetPleatedBlindGuidanceType(guidanceType));
    }

    setClosureType(closureType: any) {
        this.store.dispatch(new SetPleatedBlindClosureType(closureType));
    }
}
