import { Injectable } from '@angular/core';
import { CouplingActive, Profile, SideProfileActive, SideProfile, Coupling } from '@icc/window';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { CoupledWindowActiveConfiguration } from '@icc/common/configurations/CoupledWindowActiveConfiguration';
import { WindowConfiguration } from '@icc/common/configurations/WindowConfiguration';

@Injectable()
export class CouplingsService {
    static getIdForNew(conf: WindowActiveConfiguration | CoupledWindowActiveConfiguration) {
        const couplingsId = conf.couplings.reduce((prev, el) => (el.id > prev ? el.id : prev), -1);
        return couplingsId + 1;
    }

    static getLastCouplingByFrameIndex(
        frameEdges: {
            frameId: number;
            frameEdgeIndex: number;
            positionId?: string;
        }[],
        conf: WindowActiveConfiguration | CoupledWindowActiveConfiguration | WindowConfiguration
    ) {
        const firstProfiles: (CouplingActive | Coupling)[] = (conf.couplings as (CouplingActive | Coupling)[]).filter(s =>
            s.framesId.some(f =>
                frameEdges.some(
                    fE =>
                        f.id === fE.frameId
                        && f.edges.includes(fE.frameEdgeIndex)
                        && f.positionId === fE.positionId
                )
            )
        );
        return this.getLastCoupling(firstProfiles, conf);
    }


    static getLastCoupling(
        firstProfiles: (CouplingActive | Coupling)[],
        conf: WindowActiveConfiguration | CoupledWindowActiveConfiguration | WindowConfiguration
    ) {
        const profiles = firstProfiles
            .map(s =>
                s.adjacentOtherSideProfileId.length > 0
                    ? this.getNextProfiles(
                        s,
                        conf.type === 'coupled_window' || WindowConfiguration.is(conf)
                            ? conf.sideProfiles
                            : conf.SideProfiles,
                        conf.couplings,
                        'coupling'
                      )
                    : s
            )
            .reduce<(CouplingActive | Coupling)[]>((acc, val) => acc.concat(val), []);
        return profiles[0];
    }

    static getNextProfiles(
        baseProfile: CouplingActive | Coupling | SideProfileActive | SideProfile,
        sideProfiles: (SideProfileActive | SideProfile)[],
        couplings: (CouplingActive | Coupling)[],
        type: 'coupling' | 'sideProfile'
    ): (CouplingActive | Coupling)[] {
        const nextProfiles: (CouplingActive | Coupling | SideProfileActive | SideProfile)[] = [
            ...sideProfiles.filter(s =>
                type === 'coupling'
                    ? baseProfile.adjacentOtherSideProfileId.includes(s.id)
                    : baseProfile.adjacentOtherCouplingId
                      && baseProfile.adjacentOtherCouplingId.includes(s.id)
            ),
            ...couplings.filter(s =>
                type === 'coupling'
                    ? baseProfile.adjacentOtherSideProfileId.includes(s.id)
                    : baseProfile.adjacentOtherCouplingId
                      && baseProfile.adjacentOtherCouplingId.includes(s.id)
            ),
        ];
        const allNextProfiles = nextProfiles
            .map(s =>
                s.adjacentOtherSideProfileId.length > 0
                    ? this.getNextProfiles(s, sideProfiles, couplings, 'sideProfile')
                    : s.adjacentOtherCouplingId.length > 0
                        ? this.getNextProfiles(s, sideProfiles, couplings, 'sideProfile')
                        : s as CouplingActive | Coupling
            )
            .reduce<(CouplingActive | Coupling)[]>((acc, val) => acc.concat(val), []);
        return allNextProfiles;
    }
}
