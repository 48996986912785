import $ from 'jquery';

/**
 * Kontroler ciepłej ramki
 * @param {object} $scope          Angular scope
 * @param {object} $uibModalInstance  Okno modal
 * @param {object} windowWarmEdges Ciepła ramka
 * @param {object} selected        Wybór
 * @param {object} $timeout        Timeout
 */
export default function ModalWarmEdgeCtrl($scope, $uibModalInstance, windowWarmEdges, selected, $timeout, EventBusService, ScrollbarService, customPriceFilter) {
    'ngInject';

    var vm = this;
    vm.windowWarmEdges = windowWarmEdges;
    vm.selected        = selected;
    vm.selectEdge      = selectEdge;
    vm.close           = close;
    vm.refresh         = refresh;
    vm.customPrice = (id, price) => customPriceFilter('WindowWarmEdge', id, 'prices', 0, 'price', price);

    /**
     * Funkcja wyboru ciepłej ramki
     * @param  {object} warmEdge Ciepła ramka
     */
    function selectEdge(warmEdge) {
        $uibModalInstance.close(warmEdge);
    }

    /**
     * Funkcja zamykajaca okno modal
     */
    function close() {
        $uibModalInstance.close();
    }

    /**
     * Funkcja odświezajaca
     */
    function refresh() {
        ScrollbarService.update();
    }
}
