<div class = "container">
    <h3>{{type?.name}}</h3>
    <div class= "lineInfo">
        <div class="image" *ngIf="showImage">
            <img src="/files/{{imageDirectory}}/{{type[imageFieldName]}}" alt="" defaultImg>
        </div>
        <div [innerHTML]="type.content" id="tinymce">
        </div>
    </div>
</div>
<button mat-raised-button color="primary" (click)="close()" *ngIf="button" matRipple>
    {{ 'INTERFACE|Wybierz' | translate }}
</button>

