<div id="mosquito-location-draw" #parent>
    <icc-draw [details]="drawService.details" [options]="drawOptions"
              fitTo="width" iccDrawResize
              [drawWidth]="width$ | async" [drawHeight]="height$ | async"
              [parentElement]="parent"></icc-draw>
</div>

<mat-card>
    <h4>{{ 'WINDOW|Wybrane siatki:' | translate }}</h4>

    <div *ngIf="mosquito$ | async">
        <b>{{ 'WINDOW|Siatka do konstrukcji' | translate }}:</b>
        <br>
        <ng-container *ngTemplateOutlet="mosquitoItem;context:{mosquito: (mosquito$ | async)}"></ng-container>
        <br>
    </div>

    <ng-container *ngFor="let sash of (sashes$ | async)">
        <div *ngIf="sash.mosquito" class="icc-list">
            <h4>{{ 'WINDOW|Siatka do kwatery' | translate }} {{sash.index}}:</h4>
            <ng-container *ngTemplateOutlet="mosquitoItem;context:{mosquito: sash.mosquito, sash: sash}"></ng-container>
        </div>
    </ng-container>
</mat-card>

<ng-template #mosquitoItem let-item="mosquito" let-sash="sash">
    <div class="item-imgTitleDescription">
        <p>{{ 'CONFIGURATOR|Linia' | translate }}: {{item.System.name}}</p>
        <p>{{ 'DIMENSION|Wymiary' | translate }}: {{ (item.Width |unitmm:1:0) + 'x' + (item.Height |unitmm:1:0)}}</p>
    </div>
</ng-template>
