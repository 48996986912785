import { Pseudolocalizer } from "@icc/helpers";

export function pseudolocaleInterpolation($interpolate, $translateSanitization) {
    'use strict';

    const $translateInterpolator = {
        setLocale,
        getInterpolationIdentifier,
        useSanitizeValueStrategy,
        interpolate
    };
    let $locale;
    let $identifier = 'default';

    function setLocale(locale) {
        $locale = locale;
    };

    function getInterpolationIdentifier() {
        return $identifier;
    };

    function useSanitizeValueStrategy(value) {
        $translateSanitization.useStrategy(value);
        return this;
    };

    function interpolate(
        value,
        interpolationParams,
        context,
        sanitizeStrategy,
        translationId
    ) {
        return Pseudolocalizer.trans(translationId);
    };

    return $translateInterpolator;
}
