import { Component, OnInit, ViewChildren, QueryList, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { PageComponent, SharedFacade, _ } from '@icc/configurator/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { MountingClearanceComponent } from '../mounting-clearance/mounting-clearance.component';
import { DimensionsAdditionalDataPageComponent } from '../dimensions-additional-data-page/dimensions-additional-data-page.component';
import { UnitConverterService } from '@icc/configurator/ui';
import { TranslateService } from '@icc/common/translate.service';
import { MeasurementsService } from '@icc/legacy/configurator/steps/window/dimensions/measurements.service';
import { InfoService, ICC_PAGE_DATA } from '@icc/helpers';
import { MountingMethod, Measurement } from '@icc/window';

@Component({
    selector: 'icc-measurements-page',
    templateUrl: './measurements-page.component.html',
    styleUrls: ['./measurements-page.component.scss'],
})
export class MeasurementsPageComponent extends PageComponent implements OnInit,AfterViewInit,OnDestroy {
    public title: string = _('WINDOW|Uzupełnij pomiary z budowy');

    mode = 'advanced';
    selectedMountingMethod: MountingMethod;
    areMeasurementInsideInputsVisible = true;
    dimensionInputsInitialValue = 0;
    currentMeasurement = 0;
    @ViewChildren('measurementsinput') inputs?: QueryList<any>;
    public options = [
        {
            title: _('WINDOW|Luzy montażowe'),
            component: MountingClearanceComponent,
            componentData: {
                mountingMethod: () => this.selectedMountingMethod,
                checkField: () => this.checkField.bind(this),
                fit: () => this.measurements.value.fit,
                currentMeasurement: () => this.currentMeasurement
            },
        },
        {
            title: _('WINDOW|Zdjęcia i notatki'),
            component: DimensionsAdditionalDataPageComponent,
            componentData: {
                currentMeasurement: () => this.currentMeasurement
            }
        },
    ];

    mounting = {
        mountingMethod: {}
    }

    measurements = new FormGroup({
        hightInside: new FormControl(),
        hightOutside: new FormControl(),
        widthInside: new FormControl(),
        widthOutside: new FormControl(),
        fit: new FormControl('frame'),
    });

    clearanceMeasurements = {};

    attachementsMeasurements = {}

    montageTypeOptions: Array<MountingMethod> = [];

    dimensionUnit = this.unitConverterService.getUnit();

    constructor(
        private unitConverterService: UnitConverterService,
        private translateService: TranslateService,
        private measurementsService: MeasurementsService,
        private infoService: InfoService,
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA) private pageData: {
            setDimensions: (shape: {
                width: number;
                height: number;
            }) => void,
            shape: {
                width: number,
                height: number
            }
        },
    ) {
        super();
        if (!this.measurementsService.measurements.length) {
            this.measurementsService.init(pageData.shape);
        }
        this.selectedMountingMethod = this.measurementsService.measurements[this.currentMeasurement].mountingMethod;
        this.mounting.mountingMethod = this.selectedMountingMethod
    }

    toggleList() {
        if (this.mode === 'advanced') {
            this.mode = 'list';
        } else if (this.mode === 'list') {
            this.mode = 'advanced';
        } else {
            throw new Error('error in switching between advanced and list mode');
        }
    }

    onChangeListItemClick(event: any) {
        this.selectedMountingMethod = this.measurementsService.mountingMethods.find(el => el.id === event.id) as MountingMethod;
        this.measurements.setValue({
            hightInside: this.dimensionInputsInitialValue,
            hightOutside: this.dimensionInputsInitialValue,
            widthInside: this.dimensionInputsInitialValue,
            widthOutside: this.dimensionInputsInitialValue,
            fit: 'frame'
        });
        this.mounting.mountingMethod = this.selectedMountingMethod;
        this.measurementsService.measurements[this.currentMeasurement].mountingMethod = this.selectedMountingMethod;
        this.toggleList();
    }

    ngAfterViewInit() {
        this.measurementsService.getDevice(this.measurements.controls, this.inputs);
    }

    ngOnInit() {
        if(this.measurementsService.measurements.length === 0) {
            this.measurementsService.initMeasurements();
        }
        this.clearanceMeasurements = {
            distanceOut: this.measurementsService.measurements[this.currentMeasurement].distanceOut,
            enlargement: this.measurementsService.measurements[this.currentMeasurement].enlargement,
            distanceIn: this.measurementsService.measurements[this.currentMeasurement].distanceIn
        }
        this.attachementsMeasurements = {
            notes: this.measurementsService.measurements[this.currentMeasurement].notes,
            attachments: this.measurementsService.measurements[this.currentMeasurement].attachments,
        }
        this.measurements.controls.hightInside.setValue(this.measurementsService.measurements[this.currentMeasurement].hightInside)
        this.measurements.controls.hightOutside.setValue(this.measurementsService.measurements[this.currentMeasurement].hightOutside)
        this.measurements.controls.widthInside.setValue(this.measurementsService.measurements[this.currentMeasurement].widthInside)
        this.measurements.controls.widthOutside.setValue(this.measurementsService.measurements[this.currentMeasurement].widthOutside)
        this.measurements.controls.fit.setValue(this.measurementsService.measurements[this.currentMeasurement].fit)
        this.montageTypeOptions = this.measurementsService.mountingMethods.map(el => ({
            ...el,
            img: '/files/mountingmethod/' + el.img
        }));
    }

    saveMeasurements() {
        this.measurementsService.measurements[this.currentMeasurement] = ({...this.measurements.value, ...this.clearanceMeasurements, ...this.attachementsMeasurements, ...this.mounting} as Measurement);
        if(this.measurementsService.checkMeasurementsValues(this.measurementsService.measurements)){
            if (this.measurementsService.saveMeasurements(this.measurementsService.measurements)) {
                this.pageData.setDimensions(this.measurementsService.shapeData);
                this.sharedFacade.closePage();
            }
        } else {
            this.infoService.openInfoModal(
                {
                    message: this.translateService.instant('INTERFACE|Pola luzów montażowych/powiększeń konstrukcji nie zostały wypełnione'),
                    title: this.translateService.instant('INTERFACE|Wypełnij pola luzów montażowych/powiększeń konstrukcji')
                }
            );
        }
    }

    checkField(field: 'hightInside' | 'widthInside' | 'hightOutside' | 'widthOutside' | 'fit' | 'distanceOutTop' | 'distanceInTop' | 'distanceOutBottom' | 'distanceInBottom' | 'distanceOutLeft' | 'distanceInLeft' | 'distanceOutRight' | 'distanceInRight' | 'hightWarmingLayer' |'widthWarmingLayer' | 'crossHightInside' | 'crossHightOutside') {
        return this.selectedMountingMethod.fields && this.selectedMountingMethod.fields.indexOf(field) > -1;
    }

    saveAndCalcMeasurements() {
        this.measurementsService.measurements[this.currentMeasurement] = ({...this.measurements.value, ...this.clearanceMeasurements, ...this.attachementsMeasurements, ...this.mounting} as Measurement);
        if(this.measurementsService.checkMeasurementsValues(this.measurementsService.measurements)){
            this.measurementsService.saveAndCalcMeasurements(this.measurementsService.measurements).then((shapeData: {width: number, height: number} | false) => {
                if (shapeData) {
                    this.pageData.setDimensions(shapeData);
                    this.sharedFacade.closePage();
                }
            });
        } else {
            this.infoService.openInfoModal(
                {
                    message: this.translateService.instant('INTERFACE|Pola luzów montażowych/powiększeń konstrukcji nie zostały wypełnione'),
                    title: this.translateService.instant('INTERFACE|Wypełnij pola luzów montażowych/powiększeń konstrukcji')
                }
            );
        }
    }

    discard() {
        this.measurementsService.discard();
    }

    onSave = () => {
        this.saveMeasurements();
    }

    onCalc = () => {
        this.saveAndCalcMeasurements();
    }

    ngOnDestroy() {
        this.measurementsService.measurements[this.currentMeasurement] = ({...this.measurements.value, ...this.clearanceMeasurements, ...this.attachementsMeasurements, ...this.mounting} as Measurement);
    }
}
