
<div class = "container">
  <h3>{{item.title}}</h3>
  <div class= "itemInfo">
      <div class="image">
          <img [src]="item.imageUrl" alt="" defaultImg>
      </div>
      <div class="itemDescription" [innerHTML]="item.content">
      </div>
  </div>
</div>
<button mat-raised-button color="primary" (click)="close()" *ngIf="button" matRipple>
  {{ 'INTERFACE|Wybierz' | translate }}
</button>

