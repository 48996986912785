import { core } from '@icc/common/helpers';
import { Injectable } from '@angular/core';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { isArray, isObject } from '@icc/helpers';
import { WindowActiveConfiguration } from '../configurations/WindowActiveConfiguration';
import { IccConstructColor } from '../data-types/Color';
import { BehaviorSubject } from 'rxjs';
import { Side } from '../data-types/ColorGroup';

/**
 * Usługa mapowania kolorów.
 *
 * @export
 * @class ColorMappingService
 */
@Injectable()
export class ColorMappingService {
    /**
     * Mapowanie kolorów
     *
     *
     * @memberOf ColorMappingService
     */
    colorMaps: any[] = [];

    private accessoryMappingColorIds$ = new BehaviorSubject<number[]>(null);

    /**
     * Czy załadowne dane.
     *
     *
     * @memberOf ColorMappingService
     */
    loadedData = false;

    /**
     * Creates an instance of ColorMappingService.
     *
     * @param {any} $rootScope
     * @param {any} configuratorsDataService
     *
     * @memberOf ColorMappingService
     */
    constructor(
        private configuratorsDataService: ConfiguratorsDataService,
        private eventBusService: EventBusService
    ) {
        'ngInject';

        if (configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('loadedConfiguratorsData', () =>
            this.init()
        );
    }

    /**
     * Inicjalizuje dane.
     *
     *
     * @memberOf ColorMappingService
     */
    init() {
        this.colorMaps = this.configuratorsDataService.data.colorMaps || [];
        this.loadedData = true;
    }

    /**
     * Zamienia typ na model Cake
     *
     * @param {string} type Typ
     * @returns {string} Model Cake
     *
     * @memberOf ColorMappingService
     */
    convertTypeToModel(type) {
        let model = 'Window.WindowColor';
        switch (type) {
            case 'window':
                model = 'Window.WindowColor';
                break;
            case 'ral':
                model = 'Window.WindowColor';
                break;
            case 'roller':
                model = 'RollerShutter.RollerShutterColor';
                break;
            case 'accessory':
                model = 'Window.WindowHandlesColor';
                break;
            default:
                model = 'Window.WindowColor';
        }
        return model;
    }

    /**
     * Zwraca kolory pasujące do przekazanego koloru.
     *
     * @param {any} sourceColorId   Id źródłowego koloru.
     * @param {any} sourceColorType Rodzaj źródłowego koloru.
     * @param {any} targetColorType Rodzaj zwracanego koloru.
     * @return {any[]} Kolory pasujące do przekazanego koloru.
     *
     * @memberOf ColorMappingService
     */
    getColors(sourceColorId: number | null | number[], sourceColorType: string, targetColorType: string): number[] {
        const sourceColorModel = this.convertTypeToModel(sourceColorType);
        const targetColorModel = this.convertTypeToModel(targetColorType);
        const matchedColorMaps = this.colorMaps.filter(
            c => c.source_model === sourceColorModel && (
                Array.isArray(sourceColorId) ?
                    sourceColorId.some(el => Number(el) === Number(c.source_id)) :
                    Number(c.source_id) === Number(sourceColorId)
            )
        );

        if (sourceColorType === 'accessory' && targetColorType === 'accessory') {
            if (matchedColorMaps.length > 0) {
                const mapping = core.parseJson(matchedColorMaps[0].target) || {};
                if (mapping && isArray(mapping[targetColorModel])) {
                    return mapping[targetColorModel].map(Number);
                }
            }
            return [];
        } else {
            let defaultMapping: number[] = [];
            if (sourceColorType === targetColorType && sourceColorId) {
                if (Array.isArray(sourceColorId)) {
                    defaultMapping = sourceColorId.map(Number);
                } else {
                    defaultMapping = [Number(sourceColorId)];
                }
            }
            if (matchedColorMaps.length > 0) {
                const mapping = core.parseJson(matchedColorMaps[0].target) || {};
                if (mapping && isArray(mapping[targetColorModel])) {
                    return defaultMapping.concat(mapping[targetColorModel].map(Number));
                }
            }
            return defaultMapping;
        }
    }

    getMappedColorsFromAccessory(colorId) {
        if (!colorId) {
            return;
        }

        const colors = this.getColors(Number(colorId), 'accessory', 'accessory');

        if (colors?.length) {
            this.accessoryMappingColorIds$.next(colors);
        }

        return colors;
    }

    get accessoryMappingColorIds() {
        return this.accessoryMappingColorIds$.getValue();
    }

    /**
     * Zwrca id koloru okna.
     *
     * @param {any} conf Konfiguracja.
     * @param {string} [side='outer'] Strona okna.
     * @param {string} [field='id'] Nazwa pola.
     * @returns Wartość pola koloru.
     *
     * @memberOf ColorMappingService
     */
    getWindowColorId(conf: WindowActiveConfiguration, side: 'outer' | 'inner' = 'outer', field: keyof Partial<IccConstructColor> = 'id') {
        if (
            ['window', 'hs', 'door', 'folding_door', 'sliding_door'].indexOf(conf.type) === -1
            || !isObject(conf.Colors)
            || !isObject(conf.Colors.frame)
        ) {
            return null;
        }
        if (side === 'inner') {
            if (conf.Colors.frame.inner && conf.Colors.frame.inner[field] != null) {
                return Number(conf.Colors.frame.inner[field]);
            }
        } else {
            if (conf.Colors.frame.alushell && conf.Colors.frame.alushell[field] != null) {
                if (field === 'id') {
                    return conf.Colors.frame.alushell.constructColors[conf.Colors.frame.core?.id ?? 0]
                        .find(el => el.sides.includes(Side.FA))
                        ?.id;
                } else {
                    return Number(conf.Colors.frame.alushell[field]);
                }
            } else if (conf.Colors.frame.outer && conf.Colors.frame.outer[field] != null) {
                return Number(conf.Colors.frame.outer[field]);
            }
        }
        return null;
    }
}
