import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { StepsService, isUndefined, ChangedStepValue, InfoService } from '@icc/helpers';
import { PriceService } from '@icc/price';
import { EventBusService } from '@icc/common/event-bus.service';
import { Injectable } from '@angular/core';
import { SheathingService } from './sheathing.service';
import { DimensionsService } from './dimensions.service';
import { ColorsService } from './colors.service';
import { FrillService } from './frill.service';
import { DriveService } from './drive.service';
import { AssemblyService } from './assembly.service';
import { Common, TranslateService } from '@icc/common';
@Injectable()
export class SystemsService {
    systems: any[] = [];
    loadedData = false;

    constructor(
        private configurationsService: ConfigurationsService<'awning'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private stepsService: StepsService,
        private priceService: PriceService,
        private eventBusService: EventBusService,
        private dimensionsService: DimensionsService,
        private colorsService: ColorsService,
        private sheathingService: SheathingService,
        private frillService: FrillService,
        private driveService: DriveService,
        private assemblyService: AssemblyService,
        private infoService: InfoService,
        private translateService: TranslateService
    ) {

        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribeWithoutConfiguration('changedStep', () => {
            this.init();
        });
    }

    /**
     * Funkcja inicjujaca
     */
    init() {
        if (this.currentConfiguratorService.conf === 'awning') {
            this.setValues();
            if (isUndefined(this.configurationsService.conf.Current.System.id)) {
                this.setDefaultValues();
            }
            this.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Ustawienie wartości
     */
    setValues() {
        this.systems = this.getSystems();
    }

    /**
     * Zwrócenie Systemów
     * @return {Object} Systemy
     */
    getSystems() {
        return this.configuratorsDataService.data.awningLines;
    }

    /**
     * Wybór systemów
     * @param  {Object} system   System
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    selectSystem(system, nextStep?, price?) {
        this.configurationsService.conf.Current.System = system;
        this.configurationsService.conf.Default.System = system;

        this.configurationsService.conf.Current.Name = system.name;
        this.configurationsService.conf.Default.Name = system.name;

        let reset = false;
        if (this.configurationsService.conf.Current.SystemId !== system.id) {
            reset = true;
        }

        this.configurationsService.conf.Current.SystemId = system.id;
        this.configurationsService.conf.Default.SystemId = system.id;

        this.dimensionsService.setDefaultValues(reset);
        this.colorsService.setDefaultValues(reset);
        this.sheathingService.setDefaultValues(reset);
        this.frillService.setDefaultValues(reset);
        this.driveService.setDefaultValues(reset);
        this.assemblyService.setDefaultValues(reset);

        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
        if (price) {
            this.priceService.count();
        }
    }

    setDefaultValues() {
        this.selectSystem(this.systems[0]);
    }
}
