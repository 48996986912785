import angular from 'angular';

import ComplaintsCtrl from './ComplaintsCtrl';
import ComplaintCtrl from './ComplaintCtrl';
import LandingCtrl from './LandingCtrl';
import ComplaintFactory from './ComplaintFactory';
import DefectFactory from './DefectFactory';
import ModalComplaintDefectCtrl from './ModalComplaintDefectCtrl';
import ModalComplaintDefectHistoryCtrl from './ModalComplaintDefectHistoryCtrl';
import ModalComplaintCommentEmailsCtrl from './ModalComplaintCommentEmailsCtrl';

export default angular.module('icc.complaint', [])
    .controller('ComplaintsCtrl', ComplaintsCtrl)
    .controller('ComplaintCtrl', ComplaintCtrl)
    .controller('LandingCtrl', LandingCtrl)
    .controller('ModalComplaintDefectCtrl', ModalComplaintDefectCtrl)
    .controller('ModalComplaintDefectHistoryCtrl', ModalComplaintDefectHistoryCtrl)
    .controller('ModalComplaintCommentEmailsCtrl', ModalComplaintCommentEmailsCtrl)
    .factory('ComplaintFactory', ComplaintFactory)
    .factory('DefectFactory', DefectFactory)
    .name;
