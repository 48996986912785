<form [formGroup]="form">
  <div class="box-form mat-elevation-z3">
    <h4>{{ 'AWNING|Strona pracy (patrzac od zewntatrz):' | translate }}</h4>
    <mat-radio-group formControlName="selectedDriveSide">
        <mat-radio-button value="left">{{ 'AWNING|od lewej' | translate }}</mat-radio-button>
        <mat-radio-button value="right">{{ 'AWNING|od prawej' | translate }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="box-form mat-elevation-z3">
    <h4>{{ 'AWNING|Sposób sterowania:' | translate }}</h4>
    <mat-radio-group formControlName="selectedDriveType">
        <mat-radio-button value="crank">{{ 'AWNING|Sterowanie ręczne' | translate }}</mat-radio-button>
        <mat-radio-button value="motor">{{ 'AWNING|Sterowanie elektryczne' | translate }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="box-form mat-elevation-z3">
    <h4>{{ 'AWNING|Typ sterowania:' | translate }}</h4>
    <mat-form-field *ngIf="driveType === 'crank'">
      <mat-label>{{ 'AWNING|Typ sterowania' }}</mat-label>
      <mat-select formControlName="selectedCrank">
        <mat-option *ngFor="let crank of cranks" [value]="crank.id">{{ crank.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="driveType === 'motor'">
      <mat-label>{{ 'AWNING|Typ sterowania' }}</mat-label>
      <mat-select formControlName="selectedMotor">
        <mat-option *ngFor="let motor of motors" [value]="motor.id">{{ motor.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>


