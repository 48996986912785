import { AccessoryAction, AccessoryActionTypes } from './accessory.actions';
import { IComplementaryGoodsActiveConfiguration } from '@icc/common/configurations/ComplementaryGoodsActiveConfiguration';

export const ACCESSORY_FEATURE_KEY = 'accessory';


export interface AccessoryState {
    configuration: IComplementaryGoodsActiveConfiguration | null;
}

export interface AccessoryPartialState {
    readonly [ACCESSORY_FEATURE_KEY]: AccessoryState;
}

export const initialState: AccessoryState = {
    configuration: null,
};

export function accessoryReducer(
    state: AccessoryState = initialState,
    action: AccessoryAction
): AccessoryState {
    switch (action.type) {
        case AccessoryActionTypes.UpdateAccessoryConfiguration: {
            state = {
                ...state,
                configuration: {
                    ...action.payload.configuration,
                },
            };
            break;
        }
    }
    return state;
}
