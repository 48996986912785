/**
 * Funkcja filtrów cen indywidualnych
 */
export default function CustomPriceFilter(CustomPricesService, StateService) {
    'ngInject';

    return function(type, id, field, relation, json, price) {
        const offer = StateService.getCurrentOffer();
        const dealerId = offer ? offer.dealer_id : null;
        const customPrice = CustomPricesService.get(dealerId);
        if (customPrice && customPrice[type] && customPrice[type][id]) {
            return parseFloat(customPrice[type][id].getPrice(field, relation, json)) || price;
        }
        return price;
    };
}
