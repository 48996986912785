import { Component, OnInit, Inject } from '@angular/core';
import { _, StepComponent, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';

@Component({
    selector: 'icc-modal-types',
    templateUrl: './modal-types.component.html',
    styleUrls: ['./modal-types.component.scss'],
})
export class ModalTypesComponent extends StepComponent implements OnInit {
    public configurator = 'pleated_blinds';
    public stepId = 'modal_types_page';
    public title = _('PLEATEDBLIND|Wybierz typ');
    public options = [];

    modalTypes: iccListItem[] = [];
    selectedModalTypeId: Number = null;
    constructor(
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA) private pageData: {
          modalTypes: any[]
          selectedModalTypeId: any
        }
    ) {
        super();
    }

    ngOnInit() {
      this.selectedModalTypeId = Number(this.pageData.selectedModalTypeId);
      this.modalTypes = this.pageData.modalTypes.map<iccListItem>(type => ({
          id: Number(type.id),
          title: type.name,
          description: type.code,
          imageUrl: '/files/pleatedblindclosuretype/',
        }));
    }

    close() {
        this.sharedFacade.closePage();
    }

    selectModalType(item: iccListItem) {
      const modalTypeId = String(item.id)
      const modalType = this.pageData.modalTypes.find(
          c => Number(modalTypeId) == Number(c.id)
      );
      if (modalType) {
          this.sharedFacade.closePage(modalType);
      }
    }
}
