import { Subscription } from 'rxjs';
import { GarageDoorFacade } from './../+state/garage-door.facade';
import { Component, OnInit } from '@angular/core';
import { _, StepComponent } from '@icc/configurator/shared';
import { DrivesService } from '../drives.service';

@Component({
  selector: 'icc-drive',
  templateUrl: './drive.component.html',
  styleUrls: ['./drive.component.scss']
})
export class DriveComponent extends StepComponent implements OnInit {
  static stepName = _('STEPS|Sterowanie');
  static stepIcon = {
      ligature: 'import_export',
  };

  public configurator = 'garage_door';
  public stepId = 'drive';
  public title = _('GARAGE|Wybierz sterowanie');

  private subscriptions: Subscription[] = [];
  allDrives = [];
  availableDrives$ = this.garageDoorFacade.availableDrives$;
  selectedDrive$ = this.garageDoorFacade.selectedDrive$;
  selectedDriveType$ = this.garageDoorFacade.selectedDriveType$;
  selectedDriveSide$ = this.garageDoorFacade.selectedDriveSide$;

  selectedDrive;
  
  constructor(
    private garageDoorFacade: GarageDoorFacade,
    private drivesService: DrivesService
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.availableDrives$.subscribe(drives => {
        this.allDrives = drives;
      }),
      this.selectedDrive$.subscribe(drive => {
        this.selectedDrive = drive;
      })
    )
  }

  selectDrive() {
    this.drivesService.openDrivesModal();
  }

  selectDriveSide(side) {
    this.garageDoorFacade.setDriveSide(side);
  }
}
