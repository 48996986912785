<ng-container class = "icc-container">
    <h3 >{{ 'WINDOW|Edycja pakietu szybowego' | translate }}</h3>
        <div *ngIf="!edit && resolve.fillingsProducers.length > 1">
            <label>{{ 'WINDOW|Producent' | translate }}</label>
            <mat-select
                [(ngModel)]="resolve.producerId"
                (ngModelChange)="changeGlazingUnit()"
            >
            <mat-option *ngFor ="let value of resolve.fillingsProducers">{{value.name}}</mat-option>
        </mat-select>
        </div>
        <div *ngIf="!edit">
            <label>{{ 'WINDOW|Ilość szyb' | translate }}</label>
            <mat-select
                [ngModel]="glazingCount"
                (ngModelChange)="changeGlazingUnit()"
            ></mat-select>
        </div>
        <h4>{{ 'WINDOW|Strona zewnętrzna' | translate }}</h4>
        <div *ngFor="let item of resolve.glazingUnit">

            <div class="windowPart" *ngIf="item.type == 'glass'">{{ 'WINDOW|Szyba' | translate }}</div>
            <div class="windowPart" *ngIf="item.type == 'interPaneSpace'">{{ 'WINDOW|Komora' | translate }}</div>
            <mat-form-field>
            <mat-select
                *ngIf="item.type == 'glass' && resolve.glazingUnit.length == 1"
                [(ngModel)]="item.value"
            >
            <mat-option *ngFor ="let glass of resolve.glasses" [value]="glass.id">{{glass.name}}</mat-option>
            </mat-select>

            <mat-select
                *ngIf="item.type == 'glass' && item.position == 'outer' && resolve.glazingUnit.length > 1"
                [(ngModel)]="item.value"
            >
            <mat-option *ngFor ="let glass of outerGlasses" [value]="glass.id">{{glass.name}}</mat-option>
            </mat-select>
            <mat-select
                *ngIf="item.type == 'glass' && item.position == 'inner'"
                [(ngModel)]="item.value"
            >
            <mat-option *ngFor ="let glass of innerGlasses" [value]="glass.id">{{glass.name}}</mat-option>
            </mat-select>
            <mat-select
                *ngIf="item.type == 'glass' && item.position == 'middle'"
                [(ngModel)]="item.value"
            >
            <mat-option *ngFor ="let glass of middleGlasses" [value]="glass.id">{{glass.name}}</mat-option>
            </mat-select>
            <mat-select
                *ngIf="item.type == 'interPaneSpace'"
                [(ngModel)]="item.value"
            >
            <mat-option *ngFor ="let glass of interPaneSpaces" [value]="glass.id">{{glass.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        </div>
        <h4>{{ 'WINDOW|Strona wewnętrzna' | translate }}</h4>
    <button mat-raised-button class="button" (click)="closeModal(true)" color="primary">{{ 'INTERFACE|OK' | translate }}</button>
</ng-container>
