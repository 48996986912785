import { Component, OnInit } from '@angular/core';
import { PageComponent, _, SharedFacade } from '@icc/configurator/shared';
import { ConfiguratorFacade } from 'libs/configurator/shared/src/lib/+state/configurator.facade';

@Component({
  selector: 'icc-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss']
})
export class NotificationsPanelComponent extends PageComponent implements OnInit {
  public title: string = _('INTERFACE|Powiadomienia');
  public options = [];

  messages$ = this.sharedFacade.messages$;
  issues$ = this.configuratorFacade.issues$;

  constructor(private sharedFacade: SharedFacade, private configuratorFacade: ConfiguratorFacade) {
    super();
   }

  ngOnInit() {
  }

}
