import { Component, OnInit, Inject } from '@angular/core';
import { isNotNullOrUndefined, PageComponent } from '@icc/helpers';
import { _, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { IccAccessoryAccessory, IccAccessoryColor } from '@icc/common/data-types';
import {
    ModalService,
} from '@icc/configurator/shared';
import { EventBusService } from '@icc/common';
import { ElectronicLockOptionsPageComponent } from '../electronic-lock-options-page/electronic-lock-options-page.component';

@Component({
  selector: 'icc-electronic-lock-page',
  templateUrl: './electronic-lock-page.component.html',
  styleUrls: ['./electronic-lock-page.component.scss']
})
export class ElectronicLockPageComponent extends PageComponent implements OnInit {
    public title = _('DOOR|Elektroniczne wkładki');
    public options = [];
    public selected: any = null;

    items: iccListItem[] = [];
    selectedItemId = 0;
    constructor(
        private sharedFacade: SharedFacade,
        private eventBusService: EventBusService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            title: string;
            items: IccAccessoryAccessory[];
            hardwareColors?: IccAccessoryColor[];
            selectedId: {
                id: number,
                color: number
            };
            selColor: IccAccessoryColor;
            canOpenOptions?: (item: IccAccessoryAccessory) => boolean;
        },
        private modalService: ModalService,
    ) {
        super();
        this.title = pageData.title;
        this.eventBusService.post({ key: 'processDependencies', value: null });
    }

    ngOnInit() {
        this.items = this.pageData.items.map(item => {
            const selectedColor = this.getSelectedColor(item);
            return {
                id: Number(item.id),
                title: item.name,
                description: item.description,
                colors: this.pageData.hardwareColors == null ? [] :
                    item.colors_ids
                    && item.colors_ids
                        .map(c => 
                            this.pageData.hardwareColors.find(color => Number(c) === Number(color.id))
                        )
                        .filter(isNotNullOrUndefined)
                        .map(color => ({
                            id: Number(color.id),
                            name: color.name,
                            color: color.color,
                            colorImg: color.color_img
                        })) || [],
                selectedColor,
                showColorSection: false,
            }
        });
        this.selectedItemId = Number(this.pageData.selectedId?.id);
    }

    selectItem(item: iccListItem) {
        const electronicLock = this.pageData.items.find(f => Number(f.id) === Number(item.id));

        if (
            electronicLock 
            && electronicLock.id 
            && electronicLock.colors_ids !== null
            && (
                typeof this.pageData.canOpenOptions !== 'function' 
                || this.pageData.canOpenOptions(electronicLock)
            )
        ) {
            this.modalService.open({
                pageComponent: ElectronicLockOptionsPageComponent,
                resolve:{
                    selectedItem: electronicLock,
                    hardwareColors: this.pageData.hardwareColors ? this.pageData.hardwareColors : [],
                    selColor: this.pageData.selColor,
                }
            }).result.then((result) => {
                this.sharedFacade.closePage(Object.assign(result, {
                    electronicLock
                }));
            });
        } else {
            this.sharedFacade.closePage({
                electronicLock
            });
        }
    }

    private getSelectedColor(item) {
        return (item.colors_ids
            && ((this.pageData.selColor
                && Number(
                    item.colors_ids.find(
                        id => Number(this.pageData.selColor.id) === Number(id)
                    )
                ))
                || Number(item.colors_ids[0])))
        || 0;
    }
}
