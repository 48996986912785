import { Component, OnInit } from '@angular/core';
import { PageComponent, ModalService } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { ConfigurationsService, ParametersService, core } from '@icc/common';
import { IccSashType } from '@icc/common/data-types';
import { WindowFacade } from '../+state/window.facade';

@Component({
    selector: 'icc-construction-parameters',
    templateUrl: './construction-parameters.component.html',
    styleUrls: ['./construction-parameters.component.scss'],
})
export class ConstructionParametersComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Parametry Uw/Sw');

    conf = this.configurationsService.conf.Current;
    muntins = 0;
    frames: {
        index: string;
        sashes: any[];
        uw: number | null;
        sw: number | null;
    }[] = [];

    constructor(
        private sharedFacade: SharedFacade,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private windowFacade: WindowFacade,
        private parametersService: ParametersService
    ) {
        super();

        this.conf = this.conf || this.configurationsService.conf.Current;

        this.conf.Frames.forEach(frame => {
            const frameData: {
                index: string;
                sashes: any[];
                uw: number | null;
                sw: number | null;
            } = {
                index: core.indexToLetter(frame.index),
                sashes: [],
                uw: frame.parameters.uw,
                sw: frame.parameters.sw
            };
            this.conf.Sashes.filter(sash => sash.frameId === frame.id).sort((a, b) => a.index - b.index).forEach(sash => {
                if (sash.intSashes.length > 0) {
                    sash.intSashes
                        .sort((a, b) => a.index - b.index)
                        .forEach(intSash => {
                            this.muntins += intSash.muntins.length;
    
                            frameData.sashes.push({
                                data: this.parametersService.getSashValues(this.conf, sash, intSash),
                                index: sash.index,
                                intIndex: intSash.index,
                                sash: intSash,
                            });
                        });
                } else {
                    this.muntins += sash.muntins.length;
    
                    frameData.sashes.push({
                        data: this.parametersService.getSashValues(this.conf, sash, sash),
                        index: sash.index,
                        sash: sash,
                    });
                }
            });
            this.frames.push(frameData);
        });
    }

    close() {
        this.sharedFacade.closePage();
    }

    ngOnInit() {}
}
