import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocksModule } from './locks/locks.module';
import { ModelsModule } from './models/models.module';

@NgModule({
    imports: [CommonModule, LocksModule, ModelsModule],
    providers: [],
})
export class DoorModule {
    constructor() {}
}
