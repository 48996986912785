import $ from 'jquery';
import angular from 'angular';

/**
 * Kontroler okna modal klamek
 * @param {object} $scope                Angular Scope
 * @param {object} $uibModalInstance     Okno modal
 * @param {object} $timeout              Timeout
 * @param {object} hinges                Zawiasy
 * @param {object} selHinge              Ustawianie zawiasów
 * @param {object} ConfigurationsService Fabryka konfiguracji
 * @param {object} hingeColors           Kolory zawiasów
 * @param {object} selHingeColor         Ustawianie koloru zawiasów
 */
export default function ModalComplaintDefectCtrl($rootScope,  $location, $http, $scope, $uibModalInstance, $timeout, $filter, DefectFactory, Core, IccConfig, defectsData, defectEdit, edit, ScrollbarService) {
    'ngInject';

    var vm                    = this;
    vm.defect                 = {attachments: []};
    vm.step                   = 1;
    vm.maxStep                = 4;
    vm.close                  = close;
    vm.refresh                = refresh;
    vm.nextStep               = nextStep;
    vm.prevStep               = prevStep;
    vm.selectProductType      = selectProductType;
    vm.selectMaterial         = selectMaterial;
    vm.selectSide             = selectSide;
    vm.selectLocation         = selectLocation;
    vm.selectArea             = selectArea;
    vm.selectDefectType       = selectDefectType;
    vm.selectNumberOfDefect   = selectNumberOfDefect;
    vm.selectDefectSize       = selectDefectSize;
    vm.addFile                = addFile;
    vm.removeFile             = removeFile;
    vm.downloadZipFiles       = downloadZipFiles;
    vm.showProviders          = false;
    vm.showCarers             = false;
    vm.showProvidersMl        = false;
    vm.showCarersMl           = false;
    vm.selectCostAllocation   = selectCostAllocation;
    vm.selectCostAllocationMl = selectCostAllocationMl;
    vm.customComplainModule   = IccConfig.Complaints.customComplainModule;
    vm.isDealer               = $rootScope.user.access === 'dealer';
    vm.addDefectFile          = addDefectFile;

    init();

    /**
     * Funkcja inicjująca
     */
    function init() {
        vm.productTypes     = defectsData.productTypes;
        vm.materials        = defectsData.materials;
        vm.locations        = defectsData.locations.filter(location => location.active || (defectEdit && defectEdit.complaints_location_id === location.id));
        vm.defectTypes      = defectsData.defectTypes;
        vm.defectSizes      = defectsData.defectSizes;
        vm.sides            = defectsData.sides;
        vm.areas            = defectsData.areas;
        vm.numberOfDefects  = Core.arrayOfObjectsToList(defectsData.numberOfDefects);
        vm.defectOwners     = defectsData.defectOwners;
        vm.defectOwnersList = Core.arrayOfObjectsToList(defectsData.defectOwners);
        vm.costAllocations  = Core.arrayOfObjectsToList(defectsData.costAllocations.filter(costAllocation =>  costAllocation.active || (defectEdit.complaints_cost_allocation_id && defectEdit.complaints_cost_allocation_id === costAllocation.id)));
        vm.providers        = defectsData.providers;
        vm.providersList    = Core.arrayOfObjectsToList(defectsData.providers);
        vm.carers           = defectsData.carers;
        vm.carersList       = Core.arrayOfObjectsToList(defectsData.carers);
        vm.edit             = edit;
        vm.defectEditing     = !!defectEdit;  
        if (defectEdit) {
            vm.defect = defectEdit;
            if ($rootScope.user.access == 'Super Admin' || $rootScope.user.access == 'Administratorzy' || $rootScope.user.access == 'complaint_admin') {
                if (vm.defect.complaints_cost_allocation_id === null && vm.defect.ml_complaints_cost_allocation_id !== null){
                    vm.defect.complaints_cost_allocation_id = vm.defect.ml_complaints_cost_allocation_id;
                }
                if (vm.defect.costs === null && vm.defect.ml_costs !== null){
                    vm.defect.costs = vm.defect.ml_costs;
                }
                if (vm.defect.description === null && vm.defect.ml_description !== null){
                    vm.defect.description = vm.defect.ml_description;
                }
                if (vm.defect.complaints_defect_owner_id === null && vm.defect.ml_complaints_defect_owner_id !== null){
                    vm.defect.complaints_defect_owner_id = vm.defect.ml_complaints_defect_owner_id;
                }
                if (vm.defect.complaints_provider_id === null && vm.defect.ml_complaints_provider_id !== null){
                    vm.defect.complaints_provider_id = vm.defect.ml_complaints_provider_id;
                }
                if (vm.defect.complaints_carer_id === null && vm.defect.ml_complaints_carer_id !== null){
                    vm.defect.complaints_carer_id = vm.defect.ml_complaints_carer_id;
                }
            }
            vm.defect.costs = vm.defect.costs !== null ? Number(vm.defect.costs) : vm.defect.costs;
            vm.defect.ml_costs = vm.defect.ml_costs !== null ? Number(vm.defect.ml_costs) : vm.defect.ml_costs;
            vm.defect.costs_from_s = vm.defect.costs_from_s !== null ? Number(vm.defect.costs_from_s) : vm.defect.costs_from_s;
            vm.defect.labor = vm.defect.labor !== null ? Number(vm.defect.labor) : vm.defect.labor;
            vm.defect.kilometers = vm.defect.kilometers !== null ? Number(vm.defect.kilometers) : vm.defect.kilometers;
            vm.defect.additional_materials = vm.defect.additional_materials !== null ? Number(vm.defect.additional_materials) : vm.defect.additional_materials;
            vm.defect.additional_discount = vm.defect.additional_discount !== null ? Number(vm.defect.additional_discount) : vm.defect.additional_discount;
            selectProductType(false);
            selectLocation(false);
            selectDefectType(false);
            selectCostAllocation(false);
            selectCostAllocationMl(false);
            showOther(0);
        }
        if (($rootScope.user.access == 'Super Admin' || $rootScope.user.access == 'Administratorzy' || $rootScope.user.access == 'complaint_admin') && IccConfig.Complaints.internalAttachments) {
            vm.maxStep = 7;
        } else if (($rootScope.user.access == 'Super Admin' || $rootScope.user.access == 'Administratorzy' || $rootScope.user.access == 'complaint_admin') && !IccConfig.Complaints.internalAttachments) {
            vm.maxStep = 6;
        } else if ($rootScope.user.access == 'market_leader') {
            vm.maxStep = 5;
        }
    }

    /**
     * Pokazywanie/ukrywanie pól do wprowadzania innych wartości
     * @param  {number} type Typ: 0-wszystko
     */
    function showOther(type) {
        if (type == 0 || type == 1) {
            var productType = defectsData.productTypes.filter((elem) => elem.id == vm.defect.complaints_product_type_id)[0] || null;
            if (productType && angular.isDefined(productType) && productType.show_other) {
                vm.showOtherProduct = true;
            } else {
                vm.showOtherProduct = false;
            }
        }
        if (type == 0 || type == 2) {
            var location = defectsData.locations.filter((elem) => elem.id == vm.defect.complaints_location_id)[0] || null;
            if (location && angular.isDefined(location) && location.show_other) {
                vm.showOtherLocation = true;
            } else {
                vm.showOtherLocation = false;
            }
        }
        if (type == 0 || type == 3) {
            var defectType = defectsData.defectTypes.filter((elem) => elem.id == vm.defect.complaints_defect_type_id)[0] || null;
            if (defectType && angular.isDefined(defectType) && defectType.show_other) {
                vm.showOtherDefectType = true;
            } else {
                vm.showOtherDefectType = false;
            }
        }
    }

    /**
     * Funkcja zamykania okna modal
     * @param {boolean} save  Czy zapisywać usterkę
     */
    function close(save) {
        if (save) {
            var check = canComplaint(vm.defect, defectsData.blockedConfigurations);
            if (check.canComplaint) {
                vm.defect.changed = true;
                $uibModalInstance.close({defect: vm.defect});
            } else {
                $rootScope.showInfo(check.message);
            }
        } else {
            $uibModalInstance.close(false);
        }
    }

    /**
     * Funkcja odświeżajaca
     */
    function refresh() {
        ScrollbarService.update();
    }

    /**
     * Następny krok
     */
    function nextStep() {
        vm.step++;
    }

    /**
     * Poprzedni krok
     */
    function prevStep() {
        vm.step--;
    }

    /**
     * Wybór typu produktu
     */
    function selectProductType(clear = true) {
        vm.locations = defectsData.locations.filter((elem) => elem.product_types_ids.indexOf(vm.defect.complaints_product_type_id) > -1 && (elem.active || (defectEdit &&  defectEdit.complaints_location_id === elem.id)));
        showOther(1);
        if (clear) {
            vm.defect.other_product = null;
            reset(1);
        }
    }

    /**
     * Wybór Materiału
     */
    function selectMaterial() {
        reset(2);
    }

    /**
     * Wybór Umiejscowienia
     */
    function selectLocation(clear = true) {
        if(vm.customComplainModule) {
            if(vm.defect.complaints_location_id) {
                vm.defectTypes = defectsData.defectTypes.filter((elem) =>
                    elem.locations_ids.indexOf(vm.defect.complaints_location_id) > -1);
                } else {
                vm.defectTypes = defectsData.defectTypes;
            }
        } else {
            vm.defectTypes = defectsData.defectTypes.filter((elem) =>
                elem.locations_ids.indexOf(vm.defect.complaints_location_id) > -1
                && elem.product_types_ids.indexOf(vm.defect.complaints_product_type_id) > -1
            );
        }
        vm.defectSizes = defectsData.defectSizes.filter((elem) => elem.locations_ids.indexOf(vm.defect.complaints_location_id) > -1);
        vm.sides = defectsData.sides.filter((elem) => elem.locations_ids.indexOf(vm.defect.complaints_location_id) > -1);
        showOther(2);
        if (clear) {
            reset(3);
            vm.defect.other_location = null;
        }
    }

    /**
     * Wybór Obszaru
     */
    function selectSide() {
        reset(4);
    }


    /**
     * Wybór Obszaru
     */
    function selectArea() {
        reset(5);
    }

    /**
     * Wybór Typu Wady
     */
    function selectDefectType(clear = true) {
        vm.defectSizes = defectsData.defectSizes.filter((elem) =>
            elem.locations_ids.indexOf(vm.defect.complaints_location_id) > -1
            && elem.defect_types_ids.indexOf(vm.defect.complaints_defect_type_id) > -1
        );
        vm.numberOfDefects = Core.arrayOfObjectsToList(defectsData.numberOfDefects.filter((elem) =>
            elem.defect_types_ids.indexOf(vm.defect.complaints_defect_type_id) > -1
        ));
        showOther(3);
        if (clear) {
            vm.defect.other_defect_type = null;
            reset(6);
        }
    }

    /**
     * Wybór Ilości Wad
     */
    function selectNumberOfDefect() {
        reset(7);
    }

    /**
     * Wybór Wilekości Wady
     */
    function selectDefectSize() {
        reset(8);
    }

    /**
     * Wybór przypisania kosztów
     * @param {boolean} clear  czyszczenie wybranych danych
     */
    function selectCostAllocation(clear = true) {
        if (clear) {
            vm.defect.complaints_provider_id = null;
            vm.defect.complaints_carer_id    = null;
        }
        if (vm.defect.complaints_cost_allocation_id != null) {
            const costAllocation             = defectsData.costAllocations[Core.fId(defectsData.costAllocations, vm.defect.complaints_cost_allocation_id)];
            vm.showProviders                 = costAllocation.providers_available && vm.providers.length;
            vm.showCarers                    = costAllocation.carers_available && vm.carers.length;
        }
    }

    /**
     * Wybór przypisania kosztów przez lidera rynku
     * @param {boolean} clear  czyszczenie wybranych danych
     */
    function selectCostAllocationMl(clear = true) {
        if (clear) {
            vm.defect.ml_complaints_provider_id = null;
            vm.defect.ml_complaints_carer_id    = null;
        }
        if (vm.defect.ml_complaints_cost_allocation_id != null) {
            const costAllocation                = defectsData.costAllocations[Core.fId(defectsData.costAllocations, vm.defect.ml_complaints_cost_allocation_id)];
            vm.showProvidersMl                  = costAllocation.providers_available && vm.providers.length;
            vm.showCarersMl                     = costAllocation.carers_available && vm.carers.length;
        }
    }

    /**
     * Dodawanie załączników
     * @param {File} files pliki
     */
    function addFile(files, type = 0) {
        DefectFactory.addFile(files, type, (attachments) => {
            vm.defect.attachments = vm.defect.attachments.concat(attachments.files);
        });
    }

    function addDefectFile(file, column) {
        DefectFactory.addDefectFile(file, (result) => {
            vm.defect[column] = result.file.basename;
        });
    }

    /**
     * Pobieranie załączników w formacie zip
     * @param {File} files pliki
     */
    function downloadZipFiles() {
        $http.post(`${EnvConfig.remoteHost || window.location.origin}/admin/dealer/files/downloadZippedDefectAttachments`,{params: {
            defectId: vm.defect.id
        }}).then((res) => {
            if (res.data){
                window.location = res.data;
           }
        });
    }
    /**
     * Usuwanie załączników
     * @param {File} file plik
     */
    function removeFile(file) {
        DefectFactory.removeFile(file, () => {
            vm.defect.attachments.splice(Core.fId(vm.defect.attachments, file.id), 1);
        });
    }

    /**
     * Resetowanie dalszych pól
     * @param {number} index Index pola od którego resetujemy
     */
    function reset(index) {
        var fields = [
            'complaints_product_type_id',
            'complaints_defect_material_id',
            'complaints_location_id',
            'complaints_defect_side_id',
            'complaints_defect_area_id',
            'complaints_defect_type_id',
            'complaints_number_of_defect_id',
            'complaints_defect_size_id'
        ];

        for (index; index < fields.length; index++) {
            vm.defect[fields[index]] = null;
        }
    }

    /**
     * Czy można złożyć reklamację na daną usterkę
     * @param  {object}  defect                 Usterka
     * @param  {array}   blockedConfigurations  Tablica
     * @return {boolean}                        Czy może dodać usterkę
     */
    function canComplaint(defect, blockedConfigurations) {
        var check = {
            canComplaint: true,
            message     : false
        };

        if (angular.isArray(blockedConfigurations)) {
            for (var i = 0; i < blockedConfigurations.length; i++) {
                if (
                    defect.complaints_product_type_id == blockedConfigurations[i].complaints_product_type_id
                    && defect.side == blockedConfigurations[i].side
                    && defect.complaints_location_id == blockedConfigurations[i].complaints_location_id
                    && defect.area == blockedConfigurations[i].area
                    && defect.complaints_defect_type_id == blockedConfigurations[i].complaints_defect_type_id
                    && defect.number_of_defects == blockedConfigurations[i].number_of_defects
                    && defect.complaints_defect_size_id == blockedConfigurations[i].complaints_defect_size_id
                ) {
                    check.canComplaint = false;
                    check.message = blockedConfigurations[i].message;
                    break;
                }
            }
        }
        return check;
    }
}
