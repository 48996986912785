import { Injectable, Inject } from '@angular/core';
import { milimetersToDimNoFormat, dimToMilimeters, getUnit } from '@icc/helpers';
import { UserService } from '@icc/common/user.service';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';

@Injectable({
    providedIn: 'root'
})
export class UnitConverterService {
    constructor(
        private userService: UserService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {

    }

    getUnit() {
        const user = this.userService.get();
        return getUnit(user, this.config());
    }

    milimetersToDimNoFormat(value: number) {
        const user = this.userService.get();
        return milimetersToDimNoFormat(value, user, this.config().IccConfig, this.config());
    }

    dimToMilimeters(value: any) {
        const user = this.userService.get();
        return dimToMilimeters(value, user, this.config().IccConfig, this.config());
    }
}