import { isArray } from "@icc/helpers";
import { format } from "date-fns";
import { core } from "../helpers";
import { OfferChange } from "./offer-history";

export class OfferHistoryService {
    static addHistoryEntry(offer, user, changes: OfferChange[] = [], note = '') {
        const change = {
            date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            user: user && user.name ? user.name + ' ' + user.surname : '',
            note: note || '',
            changes
        };
        if (offer.history && isArray(offer.history)) {
            offer.history.unshift(change);
        } else {
            offer.history = [change];
        }
    }

    static getChangedPositionsStats(offer) {
        const positions = [];
        const sequence = core.parseJson(offer.sequence);
        const changed = core.parseJson(offer.changed_positions || '{}');
        const removed = (changed['remove'] || []).length;
        const updated = [];
        const created = [];

        for (const group of sequence || []) {
            for (const [key, code] of (<any>Object).entries(group || {})) {
                positions.push(...(code || []));
            }
        }
        for (const item of changed['update'] || []) {
            const found = positions.indexOf(item);
            if (found > -1) {
                updated.push(found + 1);
            }
        }
        for (const item of changed['add'] || []) {
            const found = positions.indexOf(item);
            if (found > -1) {
                created.push(found + 1);
            }
        }
        return {created, updated, removed};
    }
}