<div id="packets-list">
    <icc-list
        [items]="packets"
        itemTemplate="itemImgTitle"
        itemImgHeight="medium"
        (selectMultiple)="selectPackets($event); isPacketChanged = !isPacketChanged"
        [selectedMultiple]="selectedPackets"
        (showInfo)="showInfo($event)"
        [multiple]="true"
        [selectBeforeCallback]="true"
    ></icc-list>
</div>
<div class="selected-summary mat-elevation-z4">
    <img [src]="'/files/windowline/' + (matchingSystem?.title_image || '')" defaultImg>
    <p>{{ matchingSystem?.name || ('WINDOW|Niedostępna kombinacja pakietów' | translate) }}</p>
</div>
