import { core } from "@icc/common/helpers";

export default class SystemsModalController {
    resolve: {
        systems;
    };
    close: ({ $value }) => any;
    dismiss: () => any;

    constructor(
        private ScrollbarService,
        private $translate,
        public IccConfig,
        public SystemsComparisonService,
    ) {

    }

    $onChanges() {
        this.ScrollbarService.update();
    }

    closeModal(save) {
        this.close({ $value: save });
    }

    async changeSystem(systemId) {
        const system = this.resolve.systems[systemId];
        const changedSystem = await this.SystemsComparisonService.openSystemsSelectModal(system.config.conf.System.id, system.config.conf.type, false, system.sashTypesIds);
        if (system.config.conf.System.id !== changedSystem.id) {
            system.changedSystem = core.copy(changedSystem);
        }
    }

}
