import angular from 'angular';

/**
 * Funkcja edycji klienta
 * @param {object} $uibModalInstance instancja modalu
 * @param {object} $timeout       timeout
 * @param {object} $http          http
 * @param {object} client         klient
 * @param {object} NgMap          NgMap
 */
export default function ModalEditClientCtrl($uibModalInstance, $timeout, $http, client, NgMap, EnvConfig) {
    'ngInject';

    var vm = this;
    vm.close = close;
    vm.client = client;
    vm.editClient = editClient;
    vm.adres = '';
    vm.position = !isNaN(parseFloat(client.latitude)) && parseFloat(client.latitude) !== 0
                    && !isNaN(parseFloat(client.longitude)) && parseFloat(client.longitude) !== 0 ? [client.latitude, client.longitude] : 'current';
    vm.search = search;
    vm.countries = {};
    vm.changedCountry = changedCountry;
    vm.regions = {};

    NgMap.getMap().then(function(map) {
        google.maps.event.trigger(map, 'resize');
    });

    var countries = JSON.parse(localStorage.getItem('countries'));
    if (countries) {
        vm.countries = countries;
    } else {
        $http.get(`${EnvConfig.remoteHost || window.location.origin}/files/countries.json`).then(function(el) {
            localStorage.setItem('countries', JSON.stringify(el.data));
            vm.countries = el.data;
        });
    }

    var regions = JSON.parse(localStorage.getItem('regions'));
    if (!regions) {
        $http.get(`${EnvConfig.remoteHost || window.location.origin}/files/regions.json`).then(function(el) {
            localStorage.setItem('regions', JSON.stringify(el.data));
            regions = el.data;
            if (client.region && client.country && regions) {
                if (angular.isDefined(regions[client.country])) {
                    vm.regions = regions[client.country];
                } else {
                    vm.regions = {};
                }
            }
        });
    } else {
        if (client.region && client.country && regions) {
            if (angular.isDefined(regions[client.country])) {
                vm.regions = regions[client.country];
            } else {
                vm.regions = {};
            }
        }
    }

    /**
     * funkcja zmieniajca państwo
     * @param  {string} country państwo
     */
    function changedCountry(country) {
        if (angular.isDefined(regions[country])) {
            vm.regions = regions[country];
        } else {
            vm.regions = {};
        }
    }

    /**
     * Funkcja edycji klienta
     */
    function editClient() {
        NgMap.getMap().then(function(map) {
            vm.client.latitude = map.markers[0].position.lat();
            vm.client.longitude = map.markers[0].position.lng();
            $uibModalInstance.close(vm.client);
        });
    }

    /**
     * Funkcja wyszukujca
     */
    function search() {
        NgMap.getMap().then(function(map) {
            NgMap.getGeoLocation(vm.adres).then(function(latlng) {
                map.markers[0].setPosition(latlng);
                map.setCenter(latlng);
            });
        });
    }

    /**
     * Funkcja zamykajca instancję modalu
     */
    function close() {
        $uibModalInstance.close();
    }
}
