import { PlinthPanelComponent } from './../plinth-panel/plinth-panel.component';
import { PanelsService } from './../panels.service';
import { GarageDoorFacade } from './../+state/garage-door.facade';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { _, StepComponent } from '@icc/configurator/shared';
import { APP_CONFIG, AppConfigFactory } from '@icc/common';


@Component({
    selector: 'icc-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss'],
})
export class PanelComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Panele');
    static stepIcon = {
        ligature: 'reorder',
    };

    public options = [
        {
            title: _('GARAGEDOOR|Panel cokołu'),
            component: PlinthPanelComponent,
            show: () => {
                return this.panelsService.selectedSystem && this.panelsService.selectedSystem.plinth_panel;
            }
        },
        {
            title: _('GARAGEDOOR|Kolor wewnętrzny'),
            callback: () => {
                this.selectPanelInternalColor()
            }
        },
    ];

    public configurator = 'garage_door';
    public stepId = 'panel';
    public title = _('GARAGE|Skonfiguruj panele');

    conf;

    selectedPanel$ = this.garageDoorFacade.selectedPanel$;
    selectedPanelStructure$ = this.garageDoorFacade.selectedPanelStructure$;
    selectedPanelInternalStructure$ = this.garageDoorFacade.selectedPanelInternalStructure$;
    selectedPanelColor$ = this.garageDoorFacade.selectedPanelColor$;
    selectedPanelInternalColor$ = this.garageDoorFacade.selectedPanelInternalColor$;

    constructor(
        private garageDoorFacade: GarageDoorFacade,
        private panelsService: PanelsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
    ) {
        super();
    }

    ngOnInit() {
        this.conf = this.config().IccConfig;
    }

    ngOnDestroy() {}

    selectPanel() {
        this.panelsService.openModalPanel();
    }

    selectPanelStructure() {
        this.panelsService.openModalPanelStructure();
    }

    selectPanelInternalStructure() {
        this.panelsService.openModalPanelInternalStructure();
    }

    selectPanelColor() {
        this.panelsService.openModalPanelColor();
    }

    selectPanelInternalColor() {
        this.panelsService.openModalPanelInternalColor();
    }
}
