<icc-select-box
    [title]="'WINDOW|Rodzaj drewna' | translate"
    [description]="(woodType$ | async).name"
    [imageUrl]="'/files/woodtype/' + (woodType$ | async).img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="openModalWood()"
    *ngIf="isWood"
></icc-select-box>

<section class="optionsContainer mat-elevation-z3" *ngIf="showOptions">
    <label class="options-title">{{ 'WINDOW|Zgrzew' | translate }}:</label>
    <mat-radio-group
        class="optionsGroup"
        [ngModel]="weldFinishType$ | async"
        (ngModelChange)="setWeldFinishType($event)"
    >
        <mat-radio-button value="V">
            {{ 'WINDOW|Zgrzew typu "V"' | translate }}
        </mat-radio-button>
        <mat-radio-button value="thin">
            {{ 'WINDOW|Zgrzew nitkowy' | translate }}
        </mat-radio-button>
    </mat-radio-group>
</section>
<section class="optionsContainer mat-elevation-z3" *ngIf="showOptions">
    <label class="options-title">{{ 'WINDOW|Odwodnienie' | translate }}:</label>
    <mat-radio-group
        class="optionsGroup"
        [ngModel]="drainagePosition$ | async"
        (ngModelChange)="setDrainage($event)"
    >
        <mat-radio-button value="front">
            {{ 'WINDOW|Od czoła' | translate }}
        </mat-radio-button>
        <mat-radio-button value="bottom">
            {{ 'WINDOW|Od dołu' | translate }}
        </mat-radio-button>
    </mat-radio-group>
</section>

<section class="alushellToggle mat-elevation-z3" *ngIf="withAlushell && !alushellActive">
    <mat-slide-toggle
        (ngModelChange)="changeAlushell($event)"
        [ngModel]="aluShell$ | async"
        >{{ 'WINDOW|Nakładka aluminiowa' | translate }}
    </mat-slide-toggle>
</section>

<section class="optionsContainer mat-elevation-z3" *ngIf="(aluShell$ | async) && extendedAlushell">
    <label class="options-title">{{ 'WINDOW|Rodzaj nakładki' | translate }}:</label>
    <mat-radio-group
        class="optionsGroup"
        [ngModel]="alushellType$ | async"
        (ngModelChange)="alushellType($event)"
    >
        <mat-radio-button value="brushed">
            {{ 'WINDOW|Aluminium szczotkowane' | translate }}
        </mat-radio-button>
        <mat-radio-button value="painted">
            {{ 'WINDOW|Aluminium lakierowane' | translate }}
        </mat-radio-button>
    </mat-radio-group>
</section>
