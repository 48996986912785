import { Component, OnInit } from '@angular/core';
import { StepComponent, _, ModalService } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TypesService } from '@icc/legacy/configurator/steps/mosquito/types.service';
import { EventBusService } from '@icc/common';
import { MosquitoScreenFacade } from '../+state/mosquito-screen.facade';
import { TypesListPageComponent } from '../types-list-page/types-list-page.component';

@Component({
    selector: 'icc-variants',
    templateUrl: './variants.component.html',
    styleUrls: ['./variants.component.scss'],
})
export class VariantsComponent extends StepComponent implements OnInit {
    static stepName = _('STEPS|Typ');
    static stepIcon = {
        ligature: 'view_column',
    };

    public configurator = 'mosquito';
    public stepId = 'types';
    public title = _('CONFIGURATOR|Wybierz typ');

    types: iccListItem[] = [];
    selectedType$ = this.mosquitoScreenFacade.selectedType$;
    direction$ = this.mosquitoScreenFacade.direction$;
    private subscriptions: Subscription[] = [];

    constructor(
        private typesService: TypesService,
        private eventBusService: EventBusService,
        private mosquitoScreenFacade: MosquitoScreenFacade,
        private modalService: ModalService
    ) {
        super();
        if (this.typesService.loadedData) {
            this.init();
        }
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    init() {
        this.types = this.typesService.types.map(type => ({
            id: type.id,
            title: type.name,
            tabs: [type.type],
            imageUrl: '/files/windowline/' + type.title_image,
        }));
    }

    selectType(type) {
        this.typesService.selectType(type, true);
    }

    changeType() {
        this.modalService.open({
            pageComponent: TypesListPageComponent,
        });
    }

    changeDirection(direction: 'left' | 'right') {
      this.typesService.changeDirection(direction);
    }
}
