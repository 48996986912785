import {logger} from '@icc/common/helpers';

export default function RedirectCtrl($rootScope, $routeParams, $location, $filter) {
    'ngInject';

    if ($routeParams.url) {
        sessionStorage.setItem('redirect', atob($routeParams.url));
        $rootScope.showInfo($filter('translate')('INTERFACE|Proszę czekać...'), null);
    } else {
        $rootScope.showInfo($filter('translate')('INTERFACE|Nie znaleziono takiej strony'), null);
    }
    logger.error('Przekierowanie na app');
    $location.url('/app/sync');

}
