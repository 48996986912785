import { Component, OnInit } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';
import { Subscription } from 'rxjs';
import { HardwareService } from '../hardware/hardware.service';
import { DoorFacade } from '../+state/door.facade';
import { ConfigurationsService } from '@icc/common';

interface SelectBox {
    imagePath: string;
    name: string;
    color: any;
    colorId: string;
}

@Component({
    selector: 'icc-plates-page',
    templateUrl: './plates-page.component.html',
    styleUrls: ['./plates-page.component.scss'],
})
export class PlatesPageComponent extends PageComponent implements OnInit {
    public title = _('DOOR|Szyldy');
    public options = [];

    plateMainLock$ = this.doorFacade.plateMainLock$;
    plateMainLockBoxSelect: SelectBox | null = null;
    plateAdditionalLock$ = this.doorFacade.plateAdditionalLock$;
    plateMainLockBoxSelect$ = this.doorFacade.plateMainLockBoxSelect$;
    plateAdditionalLockBoxSelect$ = this.doorFacade.plateAdditionalLockBoxSelect$;
    private subscriptions: Subscription[] = [];
    constructor(
        private hardwareService: HardwareService,
        private doorFacade: DoorFacade,
        private configurationsService: ConfigurationsService<'door'>,
    ) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(this.doorFacade.plateMainLockBoxSelect$.subscribe((box) => {
            this.plateMainLockBoxSelect = box;
        }))
    }

    changePlateToMainLockOptions() {
        this.hardwareService.openModalPlateOptions();
    }

    selectPlateToMainLock() {
        this.hardwareService.openPlateToMainLockModal();
    }

    changePlateToAdditionalLock() {
        this.hardwareService.openPlateToAdditionalLockModal();
    }

    isPlatesToMainLockButtonDisabled() {
        const platesToMainLock = this.hardwareService.getPlatesToMainLock();
        const length = platesToMainLock.length;

        if(length > 1) {
            return false;
        }

        return true;
    }

    isPlatesToMainLockButton2Disabled(conf = this.configurationsService.conf.Current) {
        const plateId = conf.doorHardware?.plates?.mainLock;
        const platesToMainLock = this.hardwareService.getPlatesToMainLock();
        const selectedPlate = platesToMainLock.find(p => Number(p.id) === Number(plateId));
        const plateColorsLength = selectedPlate.colors_ids?.length;

        if(plateColorsLength > 1 ) {
            return false;
        }

        return true;
    }

    isPlatesToAdditionalLockButtonDisabled() {
        const platesToAdditionalLock = this.hardwareService.getPlatesToAdditionalLock();
        const length = platesToAdditionalLock.length;
        
        if(length > 1 ) {
            return false;
        }

        return true;
    }
}
