import angular from 'angular';

import Accessories from './accessories/accessories.module.ajs';
import ColorsModule from './colors/colors.module.ajs';
import DimensionsModule from './dimensions/dimensions.module.ajs';
import GlazingsModule from './glazings/glazings.module.ajs';
import HandlesModule from './handles/handles.module.ajs';
import MuntinsModule from './muntins/muntins.module.ajs';
import SystemsComparisonModule from './systems-comparison/systems-comparison.module.ajs';
import SashesModule from './sashes/sashes.module.ajs';
import SystemsModule from './systems/systems.module.ajs';
import ProfilesModule from './profiles/profiles.module.ajs';
import ReinforcementsModule from './reinforcements/reinforcements.module.ajs';

import { MosquitoCtrl } from './MosquitoCtrl';
import RollerShutterCtrl from './RollerShutterCtrl';

export default angular.module('icc.configurator.steps.window', [
    Accessories,
    ColorsModule,
    DimensionsModule,
    GlazingsModule,
    HandlesModule,
    MuntinsModule,
    SashesModule,
    SystemsModule,
    SystemsComparisonModule,
    ProfilesModule,
    ReinforcementsModule,
])
    .controller('MosquitoCtrl', MosquitoCtrl)
    .controller('RollerShutterCtrl', RollerShutterCtrl)
    .name;
