import { Injectable, Inject } from '@angular/core';

import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';

import { EventBusService } from '@icc/common/event-bus.service';
import { IssuesService, IssueLevel, ModalService } from '@icc/helpers';
import { ValidationService } from '@icc/common/configurators/validation.service';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { MuntinsLayoutsComponent } from 'libs/configurator/window/src/lib/muntins-layouts/muntins-layouts.component';
import { MuntinsLayoutEditPageComponent } from 'libs/configurator/window/src/lib/muntins-layout-edit-page/muntins-layout-edit-page.component';
import { core, TranslateService } from '@icc/common';
import { ActiveSash } from '@icc/common/layout/active-sash';

@Injectable()
export class MuntinsLayoutService {
    minDistance = 100;

    constructor(
        private modalsService: ModalService,
        private eventBusService: EventBusService,
        private issuesService: IssuesService,
        private validationService: ValidationService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private translateService: TranslateService,
    ) {
        this.eventBusService.subscribe('changedSashes', data => {
            this.updateMuntinsInAllSashes(data.activeConfiguration as WindowActiveConfiguration);
        });
        this.eventBusService.subscribe(
            ['insertMuntins', 'removeMuntins', 'updateMuntins'],
            data => {
                this.validateMuntinsInAllSashes(
                    data.activeConfiguration as WindowActiveConfiguration
                );
            }
        );
    }

    isValidSash(conf: WindowActiveConfiguration, sashId: number) {
        const sash = this.getSash(conf, sashId);

        if (
            (conf?.MuntinsData?.type?.type !== 'sticked' && sash.glazing.type === 'pvc_panels')
            || sash.glazing.type === 'deco_panels'
            || sash.glazing.type === 'door_panels'
        ) {
            return false;
        }

        return true;
    }

    insertMuntin(conf: WindowActiveConfiguration, sashId: number, layout: any) {
        const sash = this.getSash(conf, sashId);

        this.insertMuntinsInSash(conf, sash, layout);
    }

    moveMuntin(
        conf: WindowActiveConfiguration,
        sashId: number,
        muntinId: number,
        position: number
    ) {
        const sash = this.getSash(conf, sashId);
        const data = this.getMuntinsData(conf, sash);

        const muntin = sash.muntins.find(o => o.id === muntinId);

        if (muntin.start.x === muntin.end.x) {
            muntin.start.x = muntin.end.x = position;
        } else if (muntin.start.y === muntin.end.y) {
            muntin.start.y = muntin.end.y = position;
        }

        this.updateMuntinsData(conf, sash, data);

        this.eventBusService.post({
            key: 'updateMuntins',
            value: { muntin, sash },
        });
    }

    removeMuntin(conf: WindowActiveConfiguration, sashId: number, muntinId: number) {
        const sash = this.getSash(conf, sashId);
        const parentSash = sash.parentId != null ? this.getSash(conf, sash.parentId) : sash;
        const index = sash.muntins.findIndex(o => o.id === muntinId);

        sash.muntins.splice(index, 1);
        this.updateMuntinsInSash(conf, sash, parentSash ?? sash);
    }

    updateMuntin(
        conf: WindowActiveConfiguration,
        sashId: number,
        muntinId: number,
        start: boolean,
        inside: boolean
    ) {
        const sash = this.getSash(conf, sashId);
        const rect = this.getRect(conf, sash);

        const muntin = sash.muntins.find(o => o.id === muntinId);
        const key = muntin.start.y === muntin.end.y ? 'x' : 'y';

        const nearMuntin = sash.muntins
            .filter(
                o =>
                    o.start[key] === o.end[key]
                    && (inside
                        ? o.start[key] > muntin.start[key] && o.end[key] < muntin.end[key]
                        : start
                        ? o.start[key] < muntin.start[key]
                        : o.end[key] > muntin.end[key])
            )
            .sort((a, b) =>
                start !== inside ? a.start[key] < b.start[key] : a.start[key] > b.start[key]
            )
            .shift();

        if (nearMuntin) {
            muntin[start ? 'start' : 'end'][key] = nearMuntin.start[key];
        } else if (start && !inside) {
            muntin.start[key] = rect[key];
        } else if (!start && !inside) {
            muntin.end[key] = rect[key] + rect[key === 'x' ? 'width' : 'height'];
        }

        this.eventBusService.post({
            key: 'updateMuntins',
            value: { muntin, sash },
        });
    }

    openModalMuntinsLayout(conf: WindowActiveConfiguration, sashId?: number) {
        const modalInstance = this.modalsService.open({
            templateUrl: 'modalMuntinsLayout.html',
            controller: 'ModalMuntinsLayoutCtrl as mmuntinslayout',
            pageComponent: MuntinsLayoutsComponent,
        });

        modalInstance.result.then(layout => {
            if (layout) {
                if (Number.isInteger(sashId)) {
                    const sash = this.getSash(conf, sashId);

                    this.removeMuntinsInSash(conf, sash);
                    this.insertMuntinsInSash(conf, sash, layout);
                } else {
                    this.removeMuntinsInAllSashes(conf);
                    this.insertMuntinsInAllSashes(conf, layout);
                }
            }
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'glazingSashes',
                });
            }
        });

        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'muntinBarLayout',
            });
        }
    }

    private getRect(conf: WindowActiveConfiguration, sash: any): any {
        let rect;

        if (this.config().IccConfig.Drawing.muntinsByGlazings) {
            /** pozycjonowanie względem wymiarów szyby */
            const sashData = conf.drawData.sash.find(o => o.sashId === sash.id);
            const glazingData = conf.drawData.filling.find(o => o.sashId === sash.id);
            const glazingBeadData = conf.drawData.glazingBead.find(o => o.sashId === sash.id);

            if (!sashData || !glazingData || !glazingBeadData) {
                return;
            }

            if (conf.Sashes.some(p => p?.type?.out_open)) {
                rect = {
                    ...(conf.MuntinsData.type === 'internal'
                        ? sashData.inner.rect
                        : glazingData.rect),
                };
                
                rect.x -= sashData.outer.rect.x;
                rect.y -= sashData.outer.rect.y;
            } else {
                rect = {
                    ...(conf.MuntinsData.type === 'internal'
                        ? glazingData.rect
                        : glazingBeadData.inner.rect),
                };

                rect.x -= sashData.outer.rect.x;
                rect.y -= sashData.outer.rect.y;
            }
        } else {
            /** pozycjonowanie względem wymiarów kwatery */
            rect = { x: 0, y: 0, width: sash.rWidth, height: sash.rHeight };
        }

        return rect;
    }

    private getSash(conf: WindowActiveConfiguration, sashId: number): any {
        return conf.Sashes.reduce((sashes, sash) => sashes.concat([sash], sash.intSashes), []).find(
            o => o.id === sashId
        );
    }

    private getMuntinsData(conf: WindowActiveConfiguration, sash: any): any {
        const muntinsH = sash.muntins.filter(muntin => muntin.start.y === muntin.end.y);
        const muntinsV = sash.muntins.filter(muntin => muntin.start.x === muntin.end.x);
        const muntinsX = sash.muntins.filter(
            muntin => muntin.start.x !== muntin.end.x && muntin.start.y !== muntin.end.y
        );

        const muntinsPinsH = muntinsH.map(muntin => {
            return {
                muntin,
                start: muntinsV.find(el => el.start.x === muntin.start.x),
                end: muntinsV.find(el => el.end.x === muntin.end.x),
            };
        });

        const muntinsPinsV = muntinsV.map(muntin => {
            return {
                muntin,
                start: muntinsH.find(el => el.start.y === muntin.start.y),
                end: muntinsH.find(el => el.end.y === muntin.end.y),
            };
        });

        const muntinsPinsX = muntinsX.map(muntin => {
            return {
                muntin,
                startH: muntinsH.find(el => el.start.y === muntin.start.y),
                startV: muntinsV.find(el => el.start.x === muntin.start.x),
                endH: muntinsH.find(el => el.end.y === muntin.end.y),
                endV: muntinsV.find(el => el.end.x === muntin.end.x),
            };
        });

        return { muntinsH, muntinsV, muntinsX, muntinsPinsH, muntinsPinsV, muntinsPinsX };
    }

    private updateMuntinsData(conf: WindowActiveConfiguration, sash: any, data: any) {
        const rect = this.getRect(conf, sash);

        data.muntinsPinsH.map(el => {
            el.muntin.start.x = el.start ? el.start.start.x : rect.x;
            el.muntin.end.x = el.end ? el.end.end.x : rect.x + rect.width;
        });

        data.muntinsPinsV.map(el => {
            el.muntin.start.y = el.start ? el.start.start.y : rect.y;
            el.muntin.end.y = el.end ? el.end.end.y : rect.y + rect.height;
        });

        data.muntinsPinsX.map(el => {
            const testX = el.muntin.start.x < el.muntin.end.x;
            const testY = el.muntin.start.y < el.muntin.end.y;

            el.muntin.start.x = el.startV
                ? el.startV.start.x
                : testX
                ? rect.x
                : rect.x + rect.width;
            el.muntin.start.y = el.startH
                ? el.startH.start.y
                : testY
                ? rect.y
                : rect.y + rect.height;
            el.muntin.end.x = el.endV ? el.endV.end.x : !testX ? rect.x : rect.x + rect.width;
            el.muntin.end.y = el.endH ? el.endH.end.y : !testY ? rect.y : rect.y + rect.height;
        });
    }

    private insertMuntinsFromStandardLayout(
        conf: WindowActiveConfiguration,
        sash: any,
        layout: any
    ) {
        const rect = this.getRect(conf, sash);

        for (let i = 1; i <= layout.h; i++) {
            sash.muntins.push({
                id: null,
                start: { x: rect.x, y: rect.y + rect.height / 2 },
                end: { x: rect.x + rect.width, y: rect.y + rect.height / 2 },
            });
        }

        for (let i = 1; i <= layout.v; i++) {
            sash.muntins.push({
                id: null,
                start: { x: rect.x + rect.width / 2, y: rect.y },
                end: { x: rect.x + rect.width / 2, y: rect.y + rect.height },
            });
        }
    }

    private insertMuntinsFromNonStandardLayout(
        conf: WindowActiveConfiguration,
        sash: any,
        layout: any
    ) {
        const rect = this.getRect(conf, sash);

        switch (layout.s) {
            case 0:
                sash.muntins.push({
                    id: 0,
                    start: { x: rect.x, y: rect.y + rect.height / 3 },
                    end: { x: rect.x + rect.width, y: rect.y + rect.height / 3 },
                });
                break;

            case 1:
                sash.muntins.push({
                    id: 0,
                    start: { x: rect.x, y: rect.y + rect.height / 3 },
                    end: { x: rect.x + rect.width, y: rect.y + rect.height / 3 },
                });
                sash.muntins.push({
                    id: 1,
                    start: { x: rect.x + rect.width / 2, y: rect.y },
                    end: { x: rect.x + rect.width / 2, y: rect.y + rect.height / 3 },
                });
                break;

            case 2:
                sash.muntins.push({
                    id: 0,
                    start: { x: rect.x, y: rect.y + rect.height / 3 },
                    end: { x: rect.x + rect.width, y: rect.y + rect.height / 3 },
                });
                sash.muntins.push({
                    id: 1,
                    start: { x: rect.x + rect.width / 2, y: rect.y + rect.height / 3 },
                    end: { x: rect.x + rect.width / 2, y: rect.y + rect.height },
                });
                break;
        }
    }

    insertMuntinsInAllSashes(conf: WindowActiveConfiguration, layout: any) {
        const pauseId = this.eventBusService.pause(['insertMuntins']);
        try {
            conf.Sashes.reduce(
                (sashes, sash) => sashes.concat(sash.intSashes.length ? sash.intSashes : [sash]),
                []
            ).map(sash => this.insertMuntinsInSash(conf, sash, layout));
        } finally {
            this.eventBusService.resume(['insertMuntins'], pauseId);
        }
    }

    private insertMuntinsInSash(conf: WindowActiveConfiguration, sash: any, layout: any) {
        if (this.isValidSash(conf, sash.id)) {
            if (layout && Number.isInteger(layout.s)) {
                this.insertMuntinsFromNonStandardLayout(conf, sash, layout);
                // this.updateMuntinsInSash(conf, sash);
            } else if (layout && (layout.h || layout.v)) {
                this.insertMuntinsFromStandardLayout(conf, sash, layout);
                this.updateMuntinsInSash(conf, sash);
            }
        }

        this.eventBusService.post({
            key: 'insertMuntins',
            value: { sash, layout },
        });
    }

    removeMuntinsInAllSashes(conf: WindowActiveConfiguration) {
        const pauseId = this.eventBusService.pause(['removeMuntins']);
        try {
            conf.Sashes.reduce(
                (sashes, sash) => sashes.concat(sash.intSashes.length ? sash.intSashes : [sash]),
                []
            ).map(sash => this.removeMuntinsInSash(conf, sash));
        } finally {
            this.eventBusService.resume(['removeMuntins'], pauseId);
        }
    }

    private removeMuntinsInSash(conf: WindowActiveConfiguration, sash: any) {
        sash.muntins = [];

        this.eventBusService.post({
            key: 'removeMuntins',
            value: { sash },
        });
    }

    private updateMuntinsInAllSashes(conf: WindowActiveConfiguration) {
        const pauseId = this.eventBusService.pause(['updateMuntins']);
        try {
            conf.Sashes.forEach(sash => {
                if (sash.intSashes.length) {
                    sash.intSashes.forEach(field => {
                        this.updateMuntinsInSash(conf, field, sash);
                    })
                } else {
                    this.updateMuntinsInSash(conf, sash, sash);
                }
            });
        } finally {
            this.eventBusService.resume(['updateMuntins'], pauseId);
        }
    }

    private updateMuntinsInSash(conf: WindowActiveConfiguration, field: ActiveSash, sash?: ActiveSash) {
        const data = this.getMuntinsData(conf, field);
        const rect = this.getRect(conf, field);
        if (sash && sash.intSashes.length === 1 && sash.muntins.length > 0 && field.muntins.length === 0) {
            field.muntins = core.copy(sash.muntins);
        }
        field.muntins.forEach((muntin, index) => (muntin.id = index));

        data.muntinsH.forEach(
            (muntin, index) =>
                (muntin.start.y = muntin.end.y =
                    rect.y + (rect.height * (index + 1)) / (data.muntinsH.length + 1))
        );
        data.muntinsV.forEach(
            (muntin, index) =>
                (muntin.start.x = muntin.end.x =
                    rect.x + (rect.width * (index + 1)) / (data.muntinsV.length + 1))
        );

        this.updateMuntinsData(conf, field, data);

        this.eventBusService.post({
            key: 'updateMuntins',
            value: { field },
        });
    }

    private validateMuntinsInAllSashes(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'muntins');

        conf.HasMuntins = false;

        if (this.validationService.isValidElements(conf, ['system'])) {
            const valid = conf.Sashes.reduce(
                (sashes, sash) => sashes.concat(sash.intSashes.length ? sash.intSashes : [sash]),
                []
            ).every(sash => this.validateMuntinsInSash(conf, sash));

            if (!valid) {
                Object.assign(conf, this.validationService.invalid(conf, 'muntins'));
                this.issuesService.simpleRegister(
                    'invalid-muntins',
                    'Niepoprawne szprosy',
                    this.translateService.instant('WINDOW|Niepoprawne szprosy'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        logLevel: IssueLevel.NONE,
                        blockStepsAfter: null,
                    }
                );
            } else {
                Object.assign(conf, this.validationService.valid(conf, 'muntins'));
                this.issuesService.unregister('invalid-muntins', conf);
            }
        }
    }

    private validateMuntinsInSash(conf: WindowActiveConfiguration, sash: any) {
        const data = this.getMuntinsData(conf, sash);

        conf.HasMuntins = conf.HasMuntins || sash.muntins.length > 0;

        const validMuntinsH = data.muntinsH.every(a =>
            data.muntinsH.every(b => a === b || Math.abs(b.start.y - a.start.y) > this.minDistance)
        );
        const validMuntinsV = data.muntinsV.every(a =>
            data.muntinsV.every(b => a === b || Math.abs(b.start.x - a.start.x) > this.minDistance)
        );

        return validMuntinsH && validMuntinsV;
    }
}
