import { svgImageFilter } from './svg-draw-parser';
const $ = require('imports-loader?this=>window!jquery');

export default function SvgImageFilter($sce) {
    'ngInject';

    return function(data, IccConfig, conf, isPdf=false, baseDimensionUnit = 'mm', targetDimensionUnit = 'mm') {
        data = svgImageFilter(window.atob(data), IccConfig, $, window.location.href, false, false, isPdf, conf, baseDimensionUnit, targetDimensionUnit);

        return $sce.trustAsHtml(data);
    };
}
