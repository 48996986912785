import { OfferStatusDict, OrderStatusDict, ShopOrderStatusDict } from '@icc/common/offers/offer-statuses';

export default function ModalOfferHistoryCtrl($uibModalInstance, $location, offers, currency, current, $translate, currencyFilter) {
    'ngInject';

    var vm = this;
    vm.offers    = offers;
    vm.current   = current;
    vm.close     = close;
    vm.showOrder = showOrder;
    vm.formatStatus = formatStatus;
    vm.formatMontageAndMeasure = formatMontageAndMeasure;
    vm.formatPrice = formatPrice;
    vm.formatPayments = formatPayments;
    vm.getUserName = getUserName;

    vm.newNote = '';

    /**
     * Zamyka okno modalne
     */
    function close() {
        $uibModalInstance.close();
    }

    /**
     * Przejdź do zamówienia i zamknij okno
     * @param  {string} id Id zamówienia
     */
    function showOrder(id) {
        $uibModalInstance.close();
        $location.url('/app/offers_view/' + id);
    }

    function getUserName(history) {
        return {
            user: history.user || $translate.instant('OFFER_HISTORY|Klient niezalogowany')
        }
    }

    function formatStatus(offer, entry) {
        const formatted = { ...entry };
        if (entry.status) {
            formatted.status = statusName(offer, Number(entry.status));
        }
        if (entry.oldStatus) {
            formatted.oldStatus = statusName(offer, Number(entry.oldStatus));
        }
        return formatted;
    }

    function statusName(offer, status) {
        if (offer.order == 0 && offer.shop_order == 0) {
            return $translate.instant(OfferStatusDict[status]);
        } else if (offer.shop_order == 1) {
            return $translate.instant(ShopOrderStatusDict[status]);
        } else {
            return $translate.instant(OrderStatusDict[status]);
        }
    }

    function formatPrice(entry) {
        return {
            oldClientPrice: currencyFilter(entry.oldClientPrice, currency),
            clientPrice: currencyFilter(entry.clientPrice, currency),
            oldDealerPrice: currencyFilter(entry.oldDealerPrice, currency),
            dealerPrice: currencyFilter(entry.dealerPrice, currency),
        }
    }

    function formatPayments(entry, noCalculate = false) {
        return {
            addedValue: currencyFilter(entry.addedValue, entry.currency, false, noCalculate === false ? null : true),
        };
    }

    function formatMontageAndMeasure(entry) {
        let montageAndMeasureOption;
        if (entry.montage && entry.measurement) {
            montageAndMeasureOption = $translate.instant('OFFER|Dostawa z montażem i z pomiarem');
        } else if (entry.measurement) {
            montageAndMeasureOption = $translate.instant('OFFER|Dostawa bez montażu i z pomiarem');
        } else if (entry.montage) {
            montageAndMeasureOption = $translate.instant('OFFER|Dostawa z montażem');
        } else {
            montageAndMeasureOption = $translate.instant('OFFER|Dostawa bez montażu i bez pomiaru');
        }
        return { montageAndMeasureOption };
    }
}
