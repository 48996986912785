import { ExternalBlindActiveConfiguration } from '@icc/common/configurations/ExternalBlindActiveConfiguration';
import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { StepComponent, _, SharedFacade, ModalService } from '@icc/configurator/shared';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { constructor } from 'angular';
import { ShutterFacade } from '../+state/shutter.facade';
import { DrivesService } from '@icc/legacy/configurator/steps/roller_shutter/drives.service';
import { map, withLatestFrom, tap } from 'rxjs/operators';
import { AccessoriesService } from '@icc/legacy/configurator/steps/window/accessories/accessories.service';
import { IccAccessoryAccessory, IccAccessory } from '@icc/common/data-types';
import { core, TranslateService, ConfigurationsService } from '@icc/common';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { iccListItem, UnitConverterService } from '@icc/configurator/ui';
import { DriveSidePageComponent } from '../drive-side-page/drive-side-page.component';
import { crankLengths, mountingLengths } from '@icc/common/configurators/driveEngineStatic';
import { DriveEngineService } from '@icc/legacy/configurator/steps/roller_shutter/drive-engine.service';
import { TypeInfoHangerComponent } from '../type-info-hanger/type-info-hanger.component';

// tslint:disable-next-line:class-name
interface colorListItem extends iccListItem {
    id: 'b' | 'r';
}

@Component({
    selector: 'icc-drives',
    templateUrl: './drives.component.html',
    styleUrls: ['./drives.component.scss'],
})
export class DrivesComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Napęd');
    static stepIcon = {
        ligature: 'touch_app',
    };

    public configurator = 'roller_shutter';
    public stepId = 'rollerdrive';
    public title = _('ROLLERSHUTTER|Wybierz napęd');

    currency: any = {};
    drive$ = this.shutterFacade.drive$;
    separateDrivesAboveWidthThreshold = this.config().IccConfig.Configurators.roller_shutter.separateDrivesAboveWidthThreshold;
    selectedConfiguratorType$ = this.shutterFacade.selectedConfiguratorType$;
    engineDriveDescriptionContext = {$implicit: '', engineName: '', engineGroups: [], engineGroupsIndicators: []};
    availableCrankSlopes$ = this.shutterFacade.availableCrankSlopes$;
    selectedCrankSlope: number | string = 45;

    mountingLength : number | string = 1;
    crankLength : number | string = 1;
    crankOffset = 1;
    isBlindDataStatic = this.config().IccConfig.Configurators.external_blind?.staticManualDriveData;
    drivesRadio = this.config().IccConfig.Configurators.roller_shutter.drivesRadio;
    showDriveElectrical = this.drivesService.showDriveElectrical();
    isShutterToSmallForElectrical = this.drivesService.shutterWidthToSmallForElectrical() && !this.showDriveElectrical;
    driveElectricalWidth = this.drivesService.getDriveElectricalMinWidth();
    driveType$ = this.drivesService.driveType$;
    driveOverride$ = this.shutterFacade.driveOverride$;
    staticMountingLengths = mountingLengths
    staticCrankLengths = crankLengths;
    showDriveOverride$ = this.drive$.pipe(
        map(
            drive =>
                (drive.type === 'electrical' || drive.type === 'remote')
                && this.config().IccConfig.Configurators.roller_shutter.overload
        )
    );
    driveElements$ = this.shutterFacade.driveElements$;
    driveManual$ = this.shutterFacade.driveManual$;

    windowAccessoriesManufacturerCode = this.config().IccConfig.Configurators
        .windowAccessoriesManufacturerCode;
    driveManuals$ = new BehaviorSubject([]);
    driveManualType$ = this.driveManual$.pipe(
        withLatestFrom(this.driveManuals$),
        map(([driveManual, driveManuals]) => {
            if (driveManuals.length > 0) {
                let manualType = driveManuals[0];
                if (driveManual) {
                    if (driveManual.type && driveManual.type.indexOf('cord_retractor') > -1) {
                        manualType = core.fIdO(driveManuals, 'cord_retractor', 'type');
                    } else if (driveManual.type && driveManual.type.indexOf('retractor') > -1) {
                        manualType = core.fIdO(driveManuals, 'retractor', 'type');
                    } else {
                        manualType = driveManual;
                    }
                }
                return manualType.id;
            }
        })
    );

    retractorColors: colorListItem[] = [
        {
            id: 'r',
            title: this.translateService.instant('COLOR|Biały'),
            color: '#FFFFFF',
        },
        {
            id: 'b',
            title: this.translateService.instant('COLOR|Brązowy'),
            color: '#8a6642',
        },
    ];

    openingLockAvailable$ = combineLatest(this.drive$, this.driveManual$, this.shutterFacade.selectedConfiguratorType$).pipe(
        map(
            ([drive, driveManual, configuratorType]) =>
                configuratorType === 'roller_shutter' 
                && this.config().IccConfig.Configurators.roller_shutter.openingLock 
                && this.drivesService.getHangers().length > 0
        )
    );

    openingLockDisable$ = combineLatest(this.drive$, this.driveManual$, this.shutterFacade.selectedConfiguratorType$).pipe(
        map(
            ([drive, driveManual, configuratorType]) =>
                configuratorType === 'roller_shutter' 
                && this.config().IccConfig.Configurators.roller_shutter.openingLock 
                && !(this.drivesService.getHangers().find(hanger => hanger && hanger.blockade)
                    && this.drivesService.getHangers().find(hanger => hanger && !hanger.blockade)
                )
        )
    );

    driveManualTypeColor$ = this.shutterFacade.driveManual$.pipe(
        map(driveManual => (driveManual && driveManual.type.includes('brown') ? 'b' : 'r'))
    );
    openingLock$ = this.shutterFacade.openingLock$;
    driveOutputSide$ = this.shutterFacade.driveOutputSide$;
    driveOutputSides$ = this.drivesService.driveOutputSides$;
    driveOutputSides = {
        'rear-up': {
            image: '/application/dist/web/img/layouts/default/output_rear-up.png',
            title: _('ROLLERSHUTTER|Tył - góra'),
        },
        'rear-down': {
            image: '/application/dist/web/img/layouts/default/output_rear-down.png',
            title: _('ROLLERSHUTTER|Tył - dół'),
        },
        'front-up': {
            image: '/application/dist/web/img/layouts/default/output_front-up.png',
            title: _('ROLLERSHUTTER|Przód - góra'),
        },
        'front-down': {
            image: '/application/dist/web/img/layouts/default/output_front-down.png',
            title: _('ROLLERSHUTTER|Przód - dół'),
        },
        side: {
            image: '/application/dist/web/img/layouts/default/output_side.png',
            title: _('ROLLERSHUTTER|Bok'),
        }
    }

    dimensionUnit = this.unitConverterService.getUnit();

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private shutterFacade: ShutterFacade,
        private sharedFacade: SharedFacade,
        private drivesService: DrivesService,
        private accessoriesService: AccessoriesService,
        private translateService: TranslateService,
        private modalService: ModalService,
        private configurationsService: ConfigurationsService<'roller_shutter' | 'external_blind' | 'window'>,
        private unitConverterService: UnitConverterService,
        private driveEngineService: DriveEngineService,
        
    ) {
        super();
    }

    ngOnInit() {
        if (this.configurationsService.conf.Current instanceof ExternalBlindActiveConfiguration) {
            const rollerShutter = this.configurationsService.conf.Current.RollerShutter;
            this.selectedCrankSlope = rollerShutter.crankSlope ? rollerShutter.crankSlope : 45;
            this.crankLength = rollerShutter.crankLength ? rollerShutter.crankLength : 1;
            this.crankOffset =  rollerShutter.crankOffset ? rollerShutter.crankOffset : 1;
            this.mountingLength =  rollerShutter.mountingLength ? rollerShutter.mountingLength : 1;        
        }
        if (this.isBlindDataStatic && this.configurationsService.conf.Current.type==='external_blind'){
            const rollerShutter = this.configurationsService.conf.Current.RollerShutter;
            this.mountingLength = this.staticMountingLengths[0];
            this.mountingLength = this.staticMountingLengths.includes(String(rollerShutter.mountingLength)) ? rollerShutter.mountingLength : this.staticMountingLengths[0];
            this.crankLength = this.staticCrankLengths.includes(String(rollerShutter.crankLength)) ? rollerShutter.crankLength : this.staticCrankLengths[0];
            this.selectedCrankSlope = rollerShutter.crankSlope ? rollerShutter.crankSlope : 45;
            this.setCrankLength(this.crankLength);
            this.setMountingLength(this.mountingLength);
        }
        this.subscriptions.push(
            this.sharedFacade.currency$.subscribe(currency => {
                this.currency = currency;
            }),
            this.drive$.subscribe(drive=>{
                this.updateDrive(drive);
            }),
        );

        let hasRetractor = false;
        let hasCordRetractor = false;
        this.driveManuals$.next(
            this.drivesService.driveManuals.reduce((prev, cur) => {
                if (!cur.type || cur.type === 'other') {
                    prev.push(cur);
                } else if (
                    cur.type.indexOf('cord_retractor') > -1
                    && !hasCordRetractor
                ) {
                    prev.push({
                        id: 'cr',
                        price: '0',
                        type: 'cord_retractor',
                        name: this.translateService.instant('ROLLERSHUTTER|Zwijacz sznurka'),
                    });
                    hasCordRetractor = true;
                } else if (
                    cur.type.indexOf('retractor') > -1
                    && !hasRetractor
                ) {
                    prev.push({
                        id: 'r',
                        price: '0',
                        type: 'retractor',
                        name: this.translateService.instant('ROLLERSHUTTER|Zwijacz taśmy'),
                    });
                    hasRetractor = true;
                }
                return prev;
            }, [])
        );        
    }

    updateDrive(drive){
        const separatedEngines=this.driveEngineService.separateEngines(drive?.driveEngine);
        this.engineDriveDescriptionContext.$implicit=drive?.name;
        this.engineDriveDescriptionContext.engineName=drive?.driveEngine;
        this.engineDriveDescriptionContext.engineGroupsIndicators=this.driveEngineService.separatedEnginesIndicators(separatedEngines);
        this.engineDriveDescriptionContext.engineGroups=separatedEngines || [];
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    setMountingLength(mountingLength) {
        this.drivesService.setMountingLength(mountingLength);
    }

    setCrankSlope(slope) {
        this.drivesService.setCrankSlope(slope);
    }

    setCrankLength(crankLength) {
        this.drivesService.setCrankLength(crankLength);
    }

    setCrankOffset(crankOffset) {
        this.drivesService.setCrankOffset(crankOffset);
    }

    openModalDrives() {
        this.drivesService.openModalDrives();
    }

    openModalElectricalDrives() {
        this.drivesService.openModalDrives(true);
    }

    changeDriveType(type: 'manual' | 'electrical') {
        this.drivesService.changeDriveType(type);
    }

    changeOverride(override: 'none' | 'override') {
        this.drivesService.changeOverrideHanger(override);
    }

    checkOverride(override: 'none' | 'override') {
        return this.drivesService.checkOverride(override);
    }

    addAccessoriesToDrive() {
        this.accessoriesService
            .openModalAccessories('drive', undefined, result => {
                this.drivesService.addAccessory(result.accessory);
            })
            .then(() => {});
    }

    remove(accessory: IccAccessoryAccessory) {
        this.drivesService.removeAccessory(accessory);
    }

    openModalDrivesManuals() {
        this.drivesService.openModalDrivesManuals();
    }

    changeDriveManual(manualId: string) {
        this.drivesService.changeDriveManual(manualId);
    }

    changeDriveManualColor(item: colorListItem) {
        this.drivesService.changeDriveManualColor(item.id);
    }

    changeOpeningLock(value: boolean) {
        this.drivesService.setHanger(value);
    }

    changeDriveSide() {
        this.modalService.open({
            pageComponent: DriveSidePageComponent,
        });
    }

    changeDriveOutputSide(side: 'rear-up' | 'rear-down' | 'front-up' | 'front-down' | 'side') {
        this.drivesService.changeDriveOutputSide(side);
    }
    
    getHangerName() {
        if(this.configurationsService.conf.Current.RollerShutter.hanger) {
            return this.configurationsService.conf.Current.RollerShutter.hanger.name;
        }
        return '';
    }

    showInfo() {
        const hanger = this.configurationsService.conf.Current.RollerShutter.hanger;
        const modalService = this.modalService
            .open({
                pageComponent: TypeInfoHangerComponent,
                resolve: {
                    hanger: () => hanger,
                },
            });
      }
}
