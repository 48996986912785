export class Pseudolocalizer {

    private static EXPANSION_CHARACTER = '~';

    private static accents = true;
    private static expansionFactor = 1.0;
    private static brackets = true;
    private static parseHTML = false;
    private static localizableHTMLAttributes: string[] = [];

    /**
     * Available options:
     *  * accents:
     *      type: boolean
     *      default: true
     *      description: replace ASCII characters of the translated string with accented versions or similar characters
     *      example: if true, "foo" => "ƒöö".
     *
     *  * expansionFactor:
     *      type: float
     *      default: 1
     *      validation: it must be greater than or equal to 1
     *      description: expand the translated string by the given factor with spaces and tildes
     *      example: if 2, "foo" => "~foo ~"
     *
     *  * brackets:
     *      type: boolean
     *      default: true
     *      description: wrap the translated string with brackets
     *      example: if true, "foo" => "[foo]"
     *
     *  * parseHtml:
     *      type: boolean
     *      default: false
     *      description: parse the translated string as HTML - looking for HTML tags has a performance impact but allows to preserve them from alterations - it also allows to compute the visible translated string length which is useful to correctly expand ot when it contains HTML
     *      warning: unclosed tags are unsupported, they will be fixed (closed) by the parser - eg, "foo <div>bar" => "foo <div>bar</div>"
     *
     *  * localizableHtmlAttributes:
     *      type: string[]
     *      default: []
     *      description: the list of HTML attributes whose values can be altered - it is only useful when the "parse_html" option is set to true
     *      example: if ["title"], and with the "accents" option set to true, "<a href="#" title="Go to your profile">Profile</a>" => "<a href="#" title="Ĝö ţö ýöûŕ þŕöƒîļé">Þŕöƒîļé</a>" - if "title" was not in the "localizable_html_attributes" list, the title attribute data would be left unchanged.
     */
    public static setConfig(options: {
        accents?: boolean,
        expansionFactor?: number,
        brackets?: boolean,
        parseHtml?: boolean,
        localizableHtmlAttributes?: string[]
    } = {}) {
        Pseudolocalizer.accents = options.accents ?? true;

        if (1.0 > (Pseudolocalizer.expansionFactor = options.expansionFactor ?? 1.0)) {
            throw new Error('The expansion factor must be greater than or equal to 1.');
        }

        Pseudolocalizer.brackets = options.brackets ?? true;

        Pseudolocalizer.parseHTML = options.parseHtml ?? false;
        if (Pseudolocalizer.parseHTML && !Pseudolocalizer.accents && 1.0 === Pseudolocalizer.expansionFactor) {
            Pseudolocalizer.parseHTML = false;
        }

        Pseudolocalizer.localizableHTMLAttributes = options.localizableHtmlAttributes ?? [];
    }

    /**
     * 
     */
    public static trans(message: string) {
        let trans = '';

        trans = Pseudolocalizer.addAccents(message);
        trans = Pseudolocalizer.expand(trans);
        trans = Pseudolocalizer.addBrackets(trans);

        return trans;
    }


    private static addAccents(text: string): string
    {
        return Pseudolocalizer.accents ? Pseudolocalizer.strtr(text, {
            ' ': ' ',
            '!': '¡',
            '"': '″',
            '#': '♯',
            '$': '€',
            '%': '‰',
            '&': '⅋',
            '\'': '´',
            '(': '{',
            ')': '}',
            '*': '⁎',
            '+': '⁺',
            ',': '،',
            '-': '‐',
            '.': '·',
            '/': '⁄',
            '0': '⓪',
            '1': '①',
            '2': '②',
            '3': '③',
            '4': '④',
            '5': '⑤',
            '6': '⑥',
            '7': '⑦',
            '8': '⑧',
            '9': '⑨',
            ':': '∶',
            ';': '⁏',
            '<': '≤',
            '=': '≂',
            '>': '≥',
            '?': '¿',
            '@': '՞',
            'A': 'Å',
            'B': 'Ɓ',
            'C': 'Ç',
            'D': 'Ð',
            'E': 'É',
            'F': 'Ƒ',
            'G': 'Ĝ',
            'H': 'Ĥ',
            'I': 'Î',
            'J': 'Ĵ',
            'K': 'Ķ',
            'L': 'Ļ',
            'M': 'Ṁ',
            'N': 'Ñ',
            'O': 'Ö',
            'P': 'Þ',
            'Q': 'Ǫ',
            'R': 'Ŕ',
            'S': 'Š',
            'T': 'Ţ',
            'U': 'Û',
            'V': 'Ṽ',
            'W': 'Ŵ',
            'X': 'Ẋ',
            'Y': 'Ý',
            'Z': 'Ž',
            '[': '⁅',
            '\\': '∖',
            ']': '⁆',
            '^': '˄',
            '_': '‿',
            '`': '‵',
            'a': 'å',
            'b': 'ƀ',
            'c': 'ç',
            'd': 'ð',
            'e': 'é',
            'f': 'ƒ',
            'g': 'ĝ',
            'h': 'ĥ',
            'i': 'î',
            'j': 'ĵ',
            'k': 'ķ',
            'l': 'ļ',
            'm': 'ɱ',
            'n': 'ñ',
            'o': 'ö',
            'p': 'þ',
            'q': 'ǫ',
            'r': 'ŕ',
            's': 'š',
            't': 'ţ',
            'u': 'û',
            'v': 'ṽ',
            'w': 'ŵ',
            'x': 'ẋ',
            'y': 'ý',
            'z': 'ž',
            '{': '(',
            '|': '¦',
            '}': ')',
            '~': '˞',
        }) : text;
    }

    private static strtr(text: string, dictionary: Record<string, string>) {
        let result = '';
        if (text) {
            for (let i = 0; i < text.length; i += 1) {
                result += dictionary[text[i]] ?? text[i];
            }
        }
        return result;
    }

    private static expand(trans: string)
    {
        if (1.0 >= Pseudolocalizer.expansionFactor) {
            return trans;
        }

        const visibleLength = trans.length
        let missingLength = (Math.ceil(visibleLength * Pseudolocalizer.expansionFactor)) - visibleLength;
        if (Pseudolocalizer.brackets) {
            missingLength -= 2;
        }

        if (0 >= missingLength) {
            return trans;
        }

        const words: Record<number, number> = {};
        let wordsCount = 0;
        for (const word of trans.split('/ +/')) {
            const wordLength = word.length;

            if (wordLength >= missingLength) {
                continue;
            }

            if (!words[wordLength]) {
                words[wordLength] = 0;
            }

            ++words[wordLength];
            ++wordsCount;
        }

        if (!words || Object.keys(words).length === 0) {
            trans += 1 === missingLength ? Pseudolocalizer.EXPANSION_CHARACTER : ' ' + (new Array(missingLength - 1).fill(Pseudolocalizer.EXPANSION_CHARACTER).join(''));

            return trans;
        }

        let longestWordLength = Math.max(...Object.keys(words).map(Number));

        while (true) {
            let r = Math.random() * 100 % wordsCount + 1;
            var length;

            for (const [key, count] of Object.entries(words).sort((a, b) => Number(a[0]) - Number(b[0]))) {
                r -= count;
                length = key;
                if (r <= 0) {
                    break;
                }
            }

            trans += ' '+ (new Array(length).fill(Pseudolocalizer.EXPANSION_CHARACTER).join(''));

            missingLength -= length + 1;

            if (0 === missingLength) {
                return trans;
            }

            while (longestWordLength >= missingLength) {
                wordsCount -= words[longestWordLength];
                delete words[longestWordLength];

                if (!words || Object.keys(words).length === 0) {
                    trans += 1 === missingLength ? Pseudolocalizer.EXPANSION_CHARACTER : ' ' + (new Array(missingLength - 1).fill(Pseudolocalizer.EXPANSION_CHARACTER).join(''));

                    return trans;
                }

                longestWordLength = Math.max(...Object.keys(words).map(Number));
            }
        }
    }

    private static addBrackets(trans: string)
    {
        if (!Pseudolocalizer.brackets) {
            return trans;
        }

        return '[' + trans + ']';
    }

}