import { ProfilesService } from '@icc/common/profiles.service';
import { ProfileSet, ProfileShape } from '@icc/window';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { ProfileSetsService } from '@icc/common/profile-sets.service';
import { core } from '@icc/common/helpers';
import { ProfilesModalService } from '@icc/configurator/window';

export class ProfileSetsModalController {
    resolve: {
        profileSets: ProfileSet[];
        profileShapes: ProfileShape[];
        selectedProfileSet: ProfileSet;
        defaultProfileSet: ProfileSet;
        conf: WindowActiveConfiguration;
    };
    close: ({ $value: ProfileSet }) => any;
    dismiss: () => any;

    selectedSetId;
    selectedSet = {
        frameTop: {
            id: null,
            name: '',
            img: '',
        },
        frameBottom: {
            id: null,
            name: '',
            img: '',
        },
        frameSide: {
            id: null,
            name: '',
            img: '',
        },
        sash: {
            id: null,
            name: '',
            img: '',
        },
        fixedMullion: {
            id: null,
            name: '',
            img: '',
        },
        falseMullion: {
            id: null,
            name: '',
            img: '',
        },
        threshold: {
            id: null,
            name: '',
            img: '',
        },
        sashOutward: {
            id: null,
            name: '',
            img: '',
        },
        centralHandleFalseMullion: {
            id: null,
            name: '',
            img: '',
        },
        doubleHandleFalseMullion: {
            id: null,
            name: '',
            img: '',
        },
        zMullion: {
            id: null,
            name: '',
            img: '',
        },
        glazingBeadShape: {
            id: null,
            name: '',
            img: '',
        },
    };

    profileTypes = [
        'frameTop',
        'frameBottom',
        'frameSide',
        'sash',
        'fixedMullion',
        'falseMullion',
        'threshold',
        'sashOutward',
        'centralHandleFalseMullion',
        'doubleHandleFalseMullion',
        'zMullion',
        'glazingBeadShape',
    ];

    constructor(
        private profilesService: ProfilesService,
        private profileSetsService: ProfileSetsService,
        private profilesModalService: ProfilesModalService,
        private $translate
    ) {}

    $onChanges(changes) {
        if (this.resolve.selectedProfileSet) {
            this.selectedSetId = this.resolve.selectedProfileSet.id;
            this.profileTypes.forEach(type => {
                let profile;
                if (type === 'glazingBeadShape') {
                    profile = this.profilesService.getProfileShape(
                        this.resolve.selectedProfileSet[type]
                    );
                } else {
                    profile = this.profilesService.getProfile(
                        this.resolve.selectedProfileSet[type]
                    );
                }
                if (profile) {
                    this.selectedSet[type] = {
                        id: profile.id,
                        name: profile.name,
                        img: profile.img,
                    };
                }
            });
        }
    }

    changeSet() {
        const matchedSet = this.profileSetsService.getProfileSet(this.selectedSetId);
        this.resolve.selectedProfileSet = core.copy(matchedSet);
        if (matchedSet) {
            this.resolve.selectedProfileSet.isDefault =
                matchedSet.id === this.resolve.defaultProfileSet.id
                && this.resolve.defaultProfileSet.id !== null;
            this.removeNotstandardSet();
            this.profileTypes.forEach(type => {
                let profile;
                if (type === 'glazingBeadShape') {
                    profile = this.profilesService.getProfileShape(matchedSet[type]);
                } else {
                    profile = this.profilesService.getProfile(matchedSet[type]);
                }
                if (profile) {
                    this.selectedSet[type] = {
                        id: profile.id,
                        name: profile.name,
                        img: profile.img,
                    };
                }
            });
        }
    }

    changeProfile(type, profileId) {
        const profileTypes = {
            frameTop: {
                type: 'frame',
                options: {
                    and: ['top'],
                },
            },
            frameBottom: {
                type: 'frame',
                options: {
                    and: ['bottom'],
                },
            },
            frameSide: {
                type: 'frame',
                options: {
                    and: ['side'],
                },
            },
            sash: {
                type: this.resolve.conf.System.door_type ? 'virtual_door_sash' : 'sash',
                options: {
                    not: ['outward_opening'],
                },
            },
            fixedMullion: {
                type: 'fixed_mullion',
                options: {
                    not: ['type_z'],
                },
            },
            falseMullion: {
                type: 'false_mullion',
                options: {
                    not: ['central_handle'],
                },
            },
            threshold: {
                type: 'threshold',
            },
            sashOutward: {
                type: this.resolve.conf.System.door_type ? 'virtual_door_sash' : 'sash',
                options: {
                    and: ['outward_opening'],
                },
            },
            centralHandleFalseMullion: {
                type: 'false_mullion',
                options: {
                    and: ['central_handle'],
                },
            },
            doubleHandleFalseMullion: {
                type: 'false_mullion',
                options: {
                    and: ['double_handle'],
                },
            },
            zMullion: {
                type: 'fixed_mullion',
                options: {
                    and: ['type_z'],
                },
            },
            glazingBeadShape: {
                type: 'glazingBeadShape',
            },
        };
        const shapes = this.profilesService.getProfileShapes();
        const shapesArray = Object.keys(shapes).map(i => shapes[i]);

        if (type === 'glazingBeadShape') {
            this.profilesService
                .openProfilesShapesModal(
                    this.profilesService.getProfileShape(profileId),
                    shapesArray
                )
                .then(selectedProfile => {
                    if (selectedProfile) {
                        this.selectedSet[type].id = selectedProfile.id;
                        this.selectedSet[type].name = selectedProfile.name;
                        this.selectedSet[type].img = selectedProfile.img;
                        this.resolve.selectedProfileSet.id = null;
                        this.resolve.selectedProfileSet.isDefault = false;
                        this.selectedSetId = null;
                        this.addNotstandardSet();
                    }
                });
        } else {
            this.profilesModalService
                .openProfilesModal(
                    this.resolve.conf,
                    profileTypes[type].type,
                    profileTypes[type].options,
                    this.profilesService.getProfile(profileId)
                )
                .then(selectedProfile => {
                    if (selectedProfile) {
                        this.selectedSet[type].id = selectedProfile.id;
                        this.selectedSet[type].name = selectedProfile.name;
                        this.selectedSet[type].img = selectedProfile.img;
                        this.resolve.selectedProfileSet.id = null;
                        this.resolve.selectedProfileSet.isDefault = false;
                        this.selectedSetId = null;
                        this.addNotstandardSet();
                    }
                });
        }
    }

    closeModal() {
        const newProfileSet: ProfileSet = {
            id: this.resolve.selectedProfileSet.id,
            name: this.resolve.selectedProfileSet.name,
            frameTop: null,
            frameBottom: null,
            frameSide: null,
            sash: null,
            fixedMullion: null,
            falseMullion: null,
            threshold: null,
            sashOutward: null,
            centralHandleFalseMullion: null,
            doubleHandleFalseMullion: null,
            zMullion: null,
            glazingBeadShape: null,
            systems: this.resolve.selectedProfileSet.systems,
            isDefault: this.resolve.selectedProfileSet.isDefault,
        };
        this.profileTypes.forEach(type => {
            newProfileSet[type] = this.selectedSet[type].id;
        });
        this.close({ $value: newProfileSet });
    }

    addNotstandardSet() {
        if (!this.resolve.profileSets.find(set => set.id === null)) {
            this.resolve.profileSets.push({
                id: null,
                name: this.$translate.instant('CONFIGURATOR|Niestandardowe'),
                frameTop: null,
                frameBottom: null,
                frameSide: null,
                sash: null,
                fixedMullion: null,
                falseMullion: null,
                threshold: null,
                sashOutward: null,
                centralHandleFalseMullion: null,
                doubleHandleFalseMullion: null,
                zMullion: null,
                glazingBeadShape: null,
                systems: [],
                isDefault: false,
            });
        }
    }

    removeNotstandardSet() {
        this.resolve.profileSets = this.resolve.profileSets.filter(set => set.id !== null);
    }
}
ProfileSetsModalController.$inject = [
    'ProfilesService',
    'ProfileSetsService',
    'ProfilesModalService',
    '$translate',
];
