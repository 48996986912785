import * as angular from 'angular';
import {downgradeInjectable} from '@angular/upgrade/static';

import { WarrantyService } from './warranty.service';
import { CurrencyInputDirective } from './CurrencyInputDirective';
import CustomPricesCtrl from './CustomPricesCtrl';
import { ModalPriceDetailsCtrl } from './details-modal/ModalPriceDetailsCtrl';
import { PriceService } from '@icc/price';
import {CustomPricesService} from '@icc/common/custom-price/custom-prices.service';

export default angular.module('icc.price', [])
    .controller('CustomPricesCtrl', CustomPricesCtrl)
    .controller('ModalPriceDetailsCtrl', ModalPriceDetailsCtrl)
    .directive('currency', () => new CurrencyInputDirective())
    .factory('CustomPricesService', downgradeInjectable(CustomPricesService))
    .factory('PriceService', downgradeInjectable(PriceService))
    .factory('WarrantyService', downgradeInjectable(WarrantyService))
    .name;
