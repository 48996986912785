import { MechanismsService } from './../mechanisms.service';
import { GarageDoorFacade } from './garage-door.facade';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { Injectable, OnDestroy, Inject } from '@angular/core';
import { PanelsService } from '../panels.service';
import { APP_CONFIG, AppConfigFactory } from '@icc/common';
import { GarageDoorDefaultsService } from './garage-door-defaults.service';
import { Subscription } from 'rxjs';

@Injectable()
export class GarageDoorInitService implements OnDestroy{
    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private currentConfiguratorService: CurrentConfiguratorService,
        private garageDoorFacade: GarageDoorFacade,
        private mechanismsService: MechanismsService,
        private panelsService: PanelsService,
        private defaultsService: GarageDoorDefaultsService
    ) {}

    init() {
        if (this.currentConfiguratorService.conf === 'garage_door') {

            const defaultSystem = this.defaultsService.getDefaultSystem();
            this.garageDoorFacade.setSystem(defaultSystem);

            this.defaultsService.setDefaultValues();
        } else {
            return;
        }
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }
}
