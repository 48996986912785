import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';

@Component({
    selector: 'icc-glazing-spacer-page',
    templateUrl: './glazing-spacer-page.component.html',
    styleUrls: ['./glazing-spacer-page.component.scss'],
})
export class GlazingSpacerPageComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Wybierz ramkę szyby');
    public options = [];

    glazingSpacers: iccListItem[] = [];
    selectedSpacerId: number | null = null;

    constructor(@Inject(ICC_PAGE_DATA) private pageData: any, private sharedFacade: SharedFacade) {
        super();
        this.glazingSpacers = this.pageData.windowWarmEdges.map(spacer => ({
            id: Number(spacer.id),
            title: spacer.name,
            imageUrl: `/files/windowwarmedge/${spacer.image}`,
            description: `${spacer.name}`,
        }));
        if (this.pageData.selected) {
            this.selectedSpacerId = Number(this.pageData.selected.id);
        }
    }

    ngOnInit() {}

    selectSpacer(item: iccListItem) {
        const spacer = this.pageData.windowWarmEdges.find(f => Number(f.id) === item.id);
        this.sharedFacade.closePage(spacer);
    }
}
