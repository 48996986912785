/**
 * Kontroler okna modal do zmiany rozmiarów
 * @param {object} $uibModalInstance Okno modal
 */
export default function ModalDimensionChangeCtrl($uibModalInstance) {
    'ngInject';

    var vm = this;

    vm.no = function() {
        $uibModalInstance.close();
    };

    vm.yes = function() {
        $uibModalInstance.close(true);
    };
}
