<icc-tabs *ngIf="mainTabs.length > 1" [tabs]="mainTabs" [activeTabIndex]="initTab" (activated)="changedTab($event)"></icc-tabs>
<div id="fillings-list" class='fillings-list' *ngIf="mode === 'list'">
    <icc-list [searchable]="true"
              [items]="fillings"
              [selected]="selectedFilling?.id"
              [itemTemplate]="'itemImgTitleDescription'"
              vertical="true"
              (select)="selectFilling($event)"
              (editGlazing)="openGlazingUnitModal($event)"
              ></icc-list>
</div>
<div id="fillings-filters" *ngIf="mode === 'filters'">
    <form [formGroup]="fillingFilters">
        <ng-container *ngIf="config().IccConfig.Configurators.fillingsSelect.showGlazingsForSliderValues && config().IccConfig.Configurators.fillingsSelect.securitySlider === 'general'">
            <h3 *ngIf="(filtersLevels.generalSecurityLevel?.length > 1 && !isSliderDisabled)">{{ 'WINDOW|Bezpieczeństwo' | translate}}</h3>
            <icc-slider  *ngIf="filtersLevels.generalSecurityLevel?.length > 1" [steps]="filtersLevels.generalSecurityLevel" formControlName="generalSecurityLevel" (change)="getGlazingsForSecurityPackage($event.id)"></icc-slider>
            <p *ngIf="filtersLevels.generalSecurityLevel?.length > 1 && !isSliderDisabled">{{ generalSecurityDescription$ | async }}</p>
        </ng-container>
        <icc-select-box
                class="glass-type-select-box"
                [title]="(selectedGlassType?.name || '') + (selectedGlassTypeVariant?.name ? ':' : '')"
                [description]="selectedGlassTypeVariant?.name || ''"
                [imageUrl]="(selectedGlassTypeVariant?.image || '') ? ('/files/glasstypevariant/' + (selectedGlassTypeVariant?.image || '')) : ('/files/glasstype/' + (selectedGlassType?.image || ''))"
                [buttonText]="'INTERFACE|Zmień' | translate"
                [buttonText2]="'INTERFACE|Opcje' | translate"
                *ngIf="(filtersLevels.glassType?.length > 1 || getMatchingVariants().length > 0 && filtersLevels.glassType?.length > 1) && config().IccConfig.Configurators.fillingsSelect.glassTypeSelectionAsFirst"
                (select)="selectGlassType()"
                (select2)="selectGlassTypeVariant()"
                [disabledButton1]="filtersLevels.glassType?.length <= 1"
                [disabledButton2]="getMatchingVariants().length <= 1 || filtersLevels.glassType?.length <= 1"
        ></icc-select-box>
        <ng-container *ngIf="config().IccConfig.Configurators.fillingsSelect.thermalTransmittanceSlider">
            <h3 *ngIf="filtersLevels.thermalTransmittance.length > 1">{{ 'WINDOW|Przenikalność cieplna (Uw)' | translate}}</h3>
            <icc-slider *ngIf="filtersLevels.thermalTransmittance.length > 1" [steps]="filtersLevels.thermalTransmittance" formControlName="thermalTransmittance" (change)="getGlazingsForSliderSettings()"></icc-slider>
            <p *ngIf="filtersLevels.thermalTransmittance.length > 1">{{ thermalTransmittanceDescription$ | async}}</p>
        </ng-container>
        <ng-container *ngIf="config().IccConfig.Configurators.fillingsSelect.glassCountSlider">
            <h3 *ngIf="filtersLevels.glassCount.length > 1 || config().IccConfig.Configurators.fillingsSelect.glassTypeSelectionAsFirst && filtersLevelsBeforeMatch.glassCount.length > 0">{{ 'WINDOW|Liczba szyb' | translate}}<span class="filling-filter-label" *ngIf="filtersLevels.glassCount.length === 1">: {{ glassCountLabelAlt$ | async}}</span></h3>
            <icc-slider *ngIf="filtersLevels.glassCount.length > 1" [steps]="filtersLevels.glassCount" formControlName="glassCount" (change)="getGlazingsForSliderSettings()"></icc-slider>
            <p *ngIf="filtersLevels.glassCount.length > 1">{{ glassCountDescription$ | async}}</p>
        </ng-container>
        <ng-container *ngIf="config().IccConfig.Configurators.fillingsSelect.securitySlider === 'detailed'">
            <h3 *ngIf="filtersLevels.securityLevelOutside.length > 1 || config().IccConfig.Configurators.fillingsSelect.glassTypeSelectionAsFirst && filtersLevelsBeforeMatch.securityLevelOutside.length > 0">{{ 'WINDOW|Bezpieczeństwo zewnętrzne' | translate}}<span class="filling-filter-label" *ngIf="filtersLevels.securityLevelOutside.length === 1">: {{ securityLevelOutsideLabelAlt$ | async}}</span></h3>
            <icc-slider *ngIf="filtersLevels.securityLevelOutside.length > 1" [steps]="filtersLevels.securityLevelOutside" formControlName="securityLevelOutside" (change)="getGlazingsForSliderSettings()"></icc-slider>
            <p *ngIf="filtersLevels.securityLevelOutside.length > 1">{{ securityLevelOutsideDescription$ | async}}</p>
            <h3 *ngIf="filtersLevels.securityLevelInside.length > 1 || config().IccConfig.Configurators.fillingsSelect.glassTypeSelectionAsFirst && filtersLevelsBeforeMatch.securityLevelInside.length > 0">{{ 'WINDOW|Bezpieczeństwo wewnętrzne' | translate}}<span class="filling-filter-label" *ngIf="filtersLevels.securityLevelInside.length === 1">: {{ securityLevelInsideLabelAlt$ | async}}</span></h3>
            <icc-slider *ngIf="filtersLevels.securityLevelInside.length > 1" [steps]="filtersLevels.securityLevelInside" formControlName="securityLevelInside" (change)="getGlazingsForSliderSettings()"></icc-slider>
            <p *ngIf="filtersLevels.securityLevelInside.length > 1">{{ securityLevelInsideDescription$ | async}}</p>
        </ng-container>
        <ng-container *ngIf="config().IccConfig.Configurators.fillingsSelect.noiseProtectionSlider">
            <h3 *ngIf="filtersLevels.noiseProtection.length > 1 || config().IccConfig.Configurators.fillingsSelect.glassTypeSelectionAsFirst && filtersLevelsBeforeMatch.noiseProtection.length > 0">{{ 'WINDOW|Ochrona przed hałasem' | translate}}<span class="filling-filter-label" *ngIf="filtersLevels.noiseProtection.length === 1">: {{ noiseProtectionLabelAlt$ | async}}</span></h3>
            <icc-slider *ngIf="filtersLevels.noiseProtection.length > 1" [steps]="filtersLevels.noiseProtection" formControlName="noiseProtection" (change)="getGlazingsForSliderSettings()"></icc-slider>
            <p *ngIf="filtersLevels.noiseProtection.length > 1">{{ noiseProtectionDescription$ | async}}</p>
        </ng-container>
        <h3 *ngIf="(filtersLevels.glassType.length > 1 || filtersLevels.glassType?.length === 1 && filtersLevels.glassTypeVariant.length > 1) && !config().IccConfig.Configurators.fillingsSelect.glassTypeSelectionAsFirst">{{ 'WINDOW|Szyby specjalne' | translate}} </h3>
        <div class="glass-type-select" *ngIf="filtersLevels.glassType?.length > 1 && !config().IccConfig.Configurators.fillingsSelect.glassTypeSelectionAsFirst">
            <div *ngFor="let type of filtersLevels.glassType" (click)="changeGlassType(type)" [class.selected]="fillingFilters.get('glassType')?.value === type?.id">
                <div class="item-imgTitle">
                    <div class="icc-list-item-img-container">
                        <img class="item-imgTitle-img" [src]="('/files/glasstype/' + type.image)" [alt]="type.name" defaultImg>
                    </div>
                    <p class="item-imgTitle-title">{{ type.name }}</p>
                </div>
            </div>
        </div>
        <div class="glass-type-select" *ngIf="filtersLevels.glassType?.length === 1 && filtersLevels.glassTypeVariant.length && !config().IccConfig.Configurators.fillingsSelect.showGlazingsForSliderValues && !config().IccConfig.Configurators.fillingsSelect.glassTypeSelectionAsFirst">
            <div *ngFor="let type of filtersLevels.glassTypeVariant" (click)="changeGlassTypeVariant(type)" [class.selected]="fillingFilters.get('glassTypeVariant')?.value === type?.id">
                <div class="item-imgTitle">
                    <div class="icc-list-item-img-container">
                        <img class="item-imgTitle-img" [src]="('/files/glasstypevariant/' + type.image)" [alt]="type.name" defaultImg>
                    </div>
                    <p class="item-imgTitle-title">{{ type.name }}</p>
                </div>
            </div>
        </div>
        <div class="glass-type-select" *ngIf="filtersLevels.glassType?.length === 1 && filtersLevels.glassTypeVariant.length > 1
            && config().IccConfig.Configurators.fillingsSelect.showGlazingsForSliderValues">
            <ng-container *ngFor="let type of filtersLevels.glassTypeVariant">
                <ng-container *ngFor="let glazing of availableGlazings">
                    <div *ngIf="type.id === glazing.filters.glassTypeVariant" (click)="changeGlassTypeVariant(type)" [class.selected]="fillingFilters.get('glassTypeVariant')?.value == glazing.filters?.glassTypeVariant">
                        <div class="item-imgTitle">
                            <div class="icc-list-item-img-container">
                                <img class="item-imgTitle-img" [src]="('/files/glasstypevariant/' + type.image)" [alt]="type.name" defaultImg>
                            </div>
                            <p class="item-imgTitle-title">{{ type.name }}</p>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <icc-select-box [title]="'WINDOW| Wariant szyby' | translate"
                [description]="selectedGlassTypeVariant?.name"
                [imageUrl]="'/files/glasstypevariant/' + (selectedGlassTypeVariant?.image || '')"
                [buttonText]="'INTERFACE|Zmień' | translate"
                *ngIf="getMatchingVariants().length > 0 && filtersLevels.glassType?.length > 1 && !config().IccConfig.Configurators.fillingsSelect.glassTypeSelectionAsFirst"
                (select)="selectGlassTypeVariant()"
        ></icc-select-box>
    </form>
</div>
<div *ngIf="matchedFilling && mode !== 'list'" class="selected-summary mat-elevation-z4">
    <img [src]="'/files/filling/' + (matchedFilling?.image || '')" defaultImg>
    <p>{{ matchedFilling?.name }}</p>
    <button mat-raised-button (click)="select($event)" color="primary">{{ 'INTERFACE|Wybierz' | translate}}</button>
</div>
