import { OnInit, Injectable, Type } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { ActiveConfiguration } from '@icc/common/configurations/ActiveConfiguration';
import { ComponentType } from '@angular/cdk/portal';
import { ConfiguratorOptions } from '../configurator-options';
import { IccConfiguratorData, IccPricesData } from '@icc/common/data-types';
export interface StepIcon {
    fontSet?: string,
    fontIcon?: string,
    ligature?: string
}

export type StepComponentType = ComponentType<StepComponent> & {
    stepName: string | ((options?: ConfiguratorOptions) => string);
    stepIcon: StepIcon;
    stepEnable: (conf: ActiveConfiguration, options: ConfiguratorOptions, data: IccConfiguratorData & IccPricesData) => boolean;
};

@Injectable()
export abstract class StepComponent extends PageComponent {

    public static stepIcon: StepIcon = {
        ligature: 'apps'
    };
    public static stepName: string | ((options?: ConfiguratorOptions) => string) = '';
    public abstract configurator: string;
    public abstract stepId: string;
    public static stepEnable: (conf: ActiveConfiguration, options: ConfiguratorOptions, data: IccConfiguratorData & IccPricesData) => boolean = (conf, options, data) => true;

    constructor() {
        super();
    }
}
