<div class="handles-choice mat-elevation-z3" *ngIf = "isDoorVersion2">
    <label>{{ 'WINDOW|Klamki, pochwyty'|translate | uppercase }}</label>
    <mat-radio-group [ngModel]="handleType$ | async" (ngModelChange)="setHandleType($event)">
        <mat-radio-button value="DoubleLever">{{'DOOR|Klamka dwustronna' | translate}}  </mat-radio-button>
        <mat-radio-button value="LeverPull" *ngIf="!noLeverPull">{{'DOOR|Klamka wewnętrzna/antaba na zewnątrz' | translate}}</mat-radio-button>
        <mat-radio-button value="DoublePull" *ngIf="!noDoublePull">{{'DOOR|Antaba obustronna' | translate}}</mat-radio-button>
    </mat-radio-group>
</div>
<div class="handles-choice mat-elevation-z3" *ngIf="(selectHandlerType$ | async) && (hasOuterHandles('DoubleLever') || hasOuterHandles('LeverPull'))">
    <label>{{ 'WINDOW|Klamki, uchwyty'|translate | uppercase }}</label>
    <mat-radio-group [ngModel]="handleType$ | async" (ngModelChange)="setHandleType($event)">
        <mat-radio-button value="DoubleLever" *ngIf="hasOuterHandles('DoubleLever')">{{'WINDOW|Klamka obustronna' | translate}}  </mat-radio-button>
        <mat-radio-button value="LeverPull" *ngIf="hasOuterHandles('LeverPull')">{{'WINDOW|Klamka-uchwyt' | translate}}</mat-radio-button>
        <mat-radio-button value="InnerLever">{{'WINDOW|Klamka wewnętrzna' | translate}}</mat-radio-button>
    </mat-radio-group>
</div>

<icc-select-box
    [title]="'WINDOW|Klamka' | translate"
    [description]="innerLeverBoxSelect?.name + ' (' + innerLeverBoxSelect?.color  + ')'"
    [imageUrl]="innerLeverBoxSelect?.img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="'INTERFACE|Opcje' | translate"
    (select)="selectHandle(innerLeverBoxSelect.selectType, 'innerLever')"
    (select2)="changeHandleOptions(innerLeverBoxSelect.selectType, 'innerLever')"
    *ngIf = "onlyInWindow && innerLeverBoxSelect.hasHandle"
    [descriptionTemplate]="handleBoxTemplate"
    [descriptionContext]="innerLeverBoxSelect"
></icc-select-box>

<icc-select-box
    [title]="'DOOR|Klamka zewnętrzna' | translate"
    [description]="outerLeverBoxSelect!.name"
    [imageUrl]="outerLeverBoxSelect!.img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="'INTERFACE|Opcje' | translate"
    (select) = "selectHandleDoor(outerLeverBoxSelect.selectType, 'outerLever')"
    (select2)="changeHandleOptions(outerLeverBoxSelect.selectType, 'outerLever')"
    *ngIf = "(showOuterHandles$ | async) && (selectHandlerType$ | async)"
    [descriptionTemplate]="outerHandleBoxTemplate"
    [descriptionContext]="outerLeverBoxSelect"
></icc-select-box>

<icc-select-box
    [title]="'WINDOW|Uchwyt' | translate"
    [description]="outerPullBoxSelect.name"
    [imageUrl]="outerPullBoxSelect.img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="'INTERFACE|Opcje' | translate"
    (select)="selectHandleDoor('default', 'outerPull')"
    (select2)="changeHandleOptions('default', 'outerPull')"
    *ngIf = "(showOuterPullBox$ | async) && isOuterHandleWindow"
    [descriptionTemplate]="handleBoxTemplate"
    [descriptionContext]="outerPullBoxSelect"
></icc-select-box>

<icc-select-box
    [title]="'WINDOW|Okucie' | translate"
    [description]="(selectedFitting$ | async)!.name"
    [imageUrl]="'/files/windowfitting/' + (selectedFitting$ | async).img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    (select)="selectFitting()"
    *ngIf = "onlyInWindow"
></icc-select-box>

<icc-select-box
    [title]="'DOOR|Klamka dwustronna' | translate"
    [description]="innerLeverBoxSelect!.name"
    [imageUrl]="innerLeverBoxSelect!.img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="'INTERFACE|Opcje' | translate"
    (select) = "selectHandleDoor(innerLeverBoxSelect.selectType, 'innerLever')"
    (select2)="changeHandleOptions(innerLeverBoxSelect.selectType, 'innerLever')"
    *ngIf = "(showDoubleLeverBox$ | async) && (isDoorVersion2)"
    [descriptionTemplate]="handleBoxTemplate"
    [descriptionContext]="innerLeverBoxSelect"
></icc-select-box>

<icc-select-box
    [title]="'DOOR|Klamka wewnętrzna' | translate"
    [description]="innerLeverBoxSelect!.name"
    [imageUrl]="innerLeverBoxSelect.img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="'INTERFACE|Opcje' | translate"
    (select)="selectHandleDoor(innerLeverBoxSelect.selectType, 'innerLever')"
    (select2)="changeHandleOptions(innerLeverBoxSelect.selectType, 'innerLever')"
    *ngIf = "(showInnerLeverBox$ | async) && (isDoorVersion2)"
    [descriptionTemplate]="handleBoxTemplate"
    [descriptionContext]="innerLeverBoxSelect"
></icc-select-box>

<icc-select-box
    [title]="'DOOR|Antaba wewnątrz' | translate"
    [description]="innerPullBoxSelect!.name"
    [imageUrl]="innerPullBoxSelect!.img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="'INTERFACE|Opcje' | translate"
    (select)="selectHandleDoor(innerPullBoxSelect.selectType, 'innerPull')"
    (select2)="changeHandleOptions(innerPullBoxSelect.selectType, 'innerPull')"
    *ngIf = "(showInnerPullBox$ | async) && (isDoorVersion2)"
    [descriptionTemplate]="handleBoxTemplate"
    [descriptionContext]="innerPullBoxSelect"
></icc-select-box>

<icc-select-box
    [title]="'DOOR|Antaba na zewnątrz' | translate"
    [description]="outerPullBoxSelect.name"
    [imageUrl]="outerPullBoxSelect.img"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="'INTERFACE|Opcje' | translate"
    (select)="selectHandleDoor(outerPullBoxSelect.selectType, 'outerPull')"
    (select2)="changeHandleOptions(outerPullBoxSelect.selectType, 'outerPull')"
    *ngIf = "(showOuterPullBox$ | async) && !isOuterHandleWindow"
    [descriptionTemplate]="handleBoxTemplate"
    [descriptionContext]="outerPullBoxSelect"
></icc-select-box>

<icc-select-box
    [title]="'DOOR|Zawias' | translate"
    [description]="(selectedHinge$ | async)!.name"
    [imageUrl]="getHingeImageUrl()"
    [buttonText]="'INTERFACE|Zmień' | translate"
    [buttonText2]="'INTERFACE|Opcje' | translate"
    (select)="selectHinge()"
    (select2)="changeHingeOptions()"
    *ngIf = "hinge"
    [descriptionTemplate]="hingeBoxTemplate"
    [descriptionContext]="hingeBoxSelect"
></icc-select-box>


<ng-template #handleBoxTemplate let-name="name" let-color="color" let-colorOuter="colorOuter" let-description="description" let-hasSecondColor="hasSecondColor">
    <p> <b>{{ 'CONFIGURATOR|Model:' | translate }}</b> {{name}}</p>
    <p *ngIf="!hasSecondColor">
        <b>{{ 'COLOR|Kolor:' | translate }}</b> {{color}}
    </p>
    <p *ngIf="hasSecondColor">
        <b>{{ 'COLOR|Kolor wewnętrzny:' | translate }}</b> {{color}}<br>
        <b>{{ 'COLOR|Kolor zewnętrzny:' | translate }}</b> {{colorOuter}}
    </p>
    <p ng-bind-html="description"></p>
</ng-template>

<ng-template #outerHandleBoxTemplate let-name="name" let-color="colorOuter" let-description="description">
    <p> <b>{{ 'CONFIGURATOR|Model:' | translate }}</b> {{name}}</p>
    <p>
        <b>{{ 'COLOR|Kolor:' | translate }}</b> {{color}}
    </p>
    <p ng-bind-html="description"></p>
</ng-template>

<ng-template #hingeBoxTemplate let-name="name" let-color="color" let-description="description" let-hasSecondColor="hasSecondColor">
    <p> <b>{{ 'CONFIGURATOR|Model:' | translate }}</b> {{name}}</p>
    <p *ngIf="!hasSecondColor">
        <b>{{ 'COLOR|Kolor:' | translate }}</b> {{color}}
    </p>
    <p *ngIf="hasSecondColor">
        <b>{{ 'COLOR|Kolor wewnętrzny:' | translate }}</b> {{color}}<br>
    </p>
    <p ng-bind-html="description"></p>
</ng-template>
