import {Injectable, Inject} from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;

@Injectable()
export class CuttingStockService {

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) { }

    fit(segments, stickLength): {waste: number; stickCount: number; } {
        if (this.config().IccConfig.Configurators.cuttingStockMethod === 'bestFitDecreasing') {
            return this.fitBestFitDecreasing(segments, stickLength);
        }
        return this.fitSimple(segments, stickLength);
    }

    fitSimple(segments, stickLength): {waste: number; stickCount: number; } {
        const a = this.sum(segments) / stickLength;
        const stickCount = Math.ceil(a);
        const waste = (stickCount - a) * stickLength;
        return {waste, stickCount};
    }

    fitBestFitDecreasing(segments, stickLength): {waste: number; stickCount: number; } {
        const items = segments.sort((a, b) => b - a).slice(0);
        const lags = [];
        for (let i = 0; i < items.length; i++) {
            let itemInserted = false;
            let minWaste = Number.MAX_VALUE;
            let j = 0;
            for (j = 0; j < lags.length; j++) {
                const sumLength = this.sum(lags[j]);
                if (sumLength + items[i] <= stickLength && stickLength - sumLength - items[i] < minWaste) {
                    minWaste = stickLength - sumLength - items[i];
                    itemInserted = true;
                    break;
                }
            }
            if (itemInserted) {
                lags[j].push(items[i]);
            }
            if (!itemInserted) {
                lags.push([items[i]]);
            }
        }
        const lagsWaste = lags.map(lag => stickLength - this.sum(lag));
        const waste = this.sum(lagsWaste);
        const stickCount = lags.length;
        return {waste, stickCount};
    }

    private sum = (arr) => arr.reduce((prev, cur) => prev + cur, 0);
}
