import { Component, OnInit } from '@angular/core';
import { StepComponent, _, SharedFacade } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { Subscription } from 'rxjs';
import { TypesService } from '@icc/legacy/configurator/steps/mosquito/types.service';
import { EventBusService } from '@icc/common';
import { MosquitoScreenFacade } from '../+state/mosquito-screen.facade';
import { map } from 'rxjs/operators';

@Component({
    selector: 'icc-net-types',
    templateUrl: './net-types.component.html',
    styleUrls: ['./net-types.component.scss'],
})
export class NetTypesComponent extends StepComponent implements OnInit {
    static stepName = _('STEPS|Siatka');
    static stepIcon = {
        ligature: 'grid_on',
    };

    public configurator = 'mosquito';
    public stepId = 'netTypes';
    public title = _('MOSQUITO|Wybierz rodzaj siatki:');

    types: iccListItem[] = [];
    selectedType$ = this.mosquitoScreenFacade.netType$.pipe(map(type => type && type.id));
    private subscriptions: Subscription[] = [];

    constructor(
        private typesService: TypesService,
        private eventBusService: EventBusService,
        private mosquitoScreenFacade: MosquitoScreenFacade
    ) {
        super();
        if (this.typesService.loadedData) {
            this.init();
        }
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    init() {
        this.types = this.typesService.getNetTypes().map(type => ({
            id: type.id,
            title: type.name,
            imageUrl: '/files/mosquitonettype/' + type.image,
        }));
    }

    selectType(item: iccListItem) {
        const type = this.typesService.getNetTypes().find(s => s.id === item.id);
        if (type) {
            this.typesService.setNetType(type);
        }
    }
}
