import * as angular from 'angular';

import { downgradeInjectable, downgradeComponent } from '@angular/upgrade/static';
import { NewConfiguratorCtrl } from './new-configurator.controller.ajs';
import { NewConfiguratorContainerComponent } from './new-configurator-container/new-configurator-container.component';

export default angular
    .module('icc.new-configurator', [])
    .controller('NewConfiguratorCtrl', NewConfiguratorCtrl)
    .directive(
        'iccConfigurator',
        downgradeComponent({ component: NewConfiguratorContainerComponent })
    ).name;
