import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@icc/common/translate.service';
import {
    TranslateService as NgxTranslateService,
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
} from '@ngx-translate/core';

@Injectable()
export class ConfiguratorTranslateService extends TranslateService {
    constructor(private translateService: NgxTranslateService) {
        super();
    }

    instant(
        translationId: string,
        interpolateParams?: any,
        interpolationId?: string,
        forceLanguage?: string,
        sanitizeStrategy?: string
    ): string {
        return this.translateService.instant(translationId, interpolateParams);
    }
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        const parts = params.key.split('|');
        return parts[1] || parts[0];
    }
}
