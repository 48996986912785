import angular from 'angular';

export default function CompareGroupPositionFilter() {
    'ngInject';

    return function(data, data2, objName) {
        if (typeof data == 'undefined') {
            return false;
        }
        if (typeof data2 == 'undefined') {
            return false;
        }
        if (typeof objName == 'undefined') {
            return angular.equals(data, data2);
        }
        if (typeof objName == 'string') {
            return angular.equals(data[objName], data2[objName]);
        }
    };
}
