import angular from 'angular';
import {ValidationService} from './validation.service.ajs';

/**
 * Fabryka systemow bram garazowych
 * @param {object} $rootScope               Angular rootScope
 * @param {Object} $sce                     Sce
 * @param {Object} ConfigurationsService    Fabryk konfiguracji
 * @param {Object} CurConfService           Bieżaca konfiguracja
 * @param {Object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {Object} PriceService             Liczenie cen
 */
export default function AccessoriesFactory($rootScope, $sce, $translate, Core, IssuesService, ConfigurationsService, CurConfService, ConfiguratorsDataService, PriceService, EventBusService) {// jshint ignore:line
    'ngInject';

    var factory = {
        controls        : [],
        loadedData      : false,
        setDefaultValues: setDefaultValues,
        setControl      : setControl
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Funkcja wywołana po zaladowaniu konfiguracji
     */
    function init() {
        if (CurConfService.conf == 'garage_door') {
            factory.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Pobranie systemow garazowych
     */
    function getControls() {
        if (angular.isObject(ConfiguratorsDataService.data.garageControls)
            && ConfigurationsService.conf.Current.Guide !== null
            && ConfiguratorsDataService.data.garageControls[ConfigurationsService.conf.Current.System.id]
            && angular.isArray(ConfiguratorsDataService.data.garageControls[ConfigurationsService.conf.Current.System.id][ConfigurationsService.conf.Current.Guide.symbol])
        ) {
            if (angular.isDefined(ConfiguratorsDataService.data.garageControls[ConfigurationsService.conf.Current.System.id])) {
                return ConfiguratorsDataService.data.garageControls
                    [ConfigurationsService.conf.Current.System.id][ConfigurationsService.conf.Current.Guide.symbol].filter(function(e) {
                        return e.max_height >= ConfigurationsService.conf.Current.Height
                            && e.min_height <= ConfigurationsService.conf.Current.Height;
                    });
            }
        }
        return [];
    }

    /**
     * Ustawienie domyslnych wartosci po uruchomieniu
     * @param  {boolean} noValid  Czy uruchamiac walidację
     */
    function setDefaultValues(noValid) {
        factory.controls = getControls();
        if (
            !ConfigurationsService.conf.Current.Control
            || !Core.fIdO(factory.controls, ConfigurationsService.conf.Current.Control.id)
        ) {
            if (factory.controls.length > 0) {
                factory.setControl(factory.controls[0], true, noValid);
            } else {
                factory.setControl(null, true, noValid);
            }
        }
    }

    /**
     * Wybor systemu
     * @param  {object}  control  Wybrany system sterowania
     * @param  {boolean} price    Czy przeliczyc ceny
     * @param  {boolean} noValid  Czy uruchamiac walidację
     */
    function setControl(control, price, noValid) {
        if (CurConfService.conf != 'garage_door') {
            return;
        }

        if (angular.isUndefined(control) || control === null) {
            ConfigurationsService.conf.Current.Control = null;
            ConfigurationsService.conf.Default.Control = null;
        } else {
            for (var x = 0; x < factory.controls.length; ++x) {
                if (~~factory.controls[x].id == ~~control.id) {
                    ConfigurationsService.conf.Current.Control = factory.controls[x];
                    ConfigurationsService.conf.Default.Control = factory.controls[x];
                    break;
                }
            }
        }

        if (!noValid) {
            ValidationService.valid(ConfigurationsService.conf.Current, IssuesService, ConfiguratorsDataService.data.garageGuides, $translate);
        }

        if (price) {
            PriceService.count();
        }
    }

}
