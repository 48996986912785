import { ComponentPortal, DomPortalOutlet, PortalInjector } from '@angular/cdk/portal';
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ViewContainerRef,
    Injector,
    Compiler,
    NgModuleFactory,
    NgModuleRef,
    Type,
    OnDestroy,
    ElementRef,
    ComponentFactoryResolver,
    ApplicationRef,
    ChangeDetectorRef,
    NgZone,
} from '@angular/core';
import { InitService } from 'apps/configurator/src/app/init.service';
import { NewConfiguratorLazyModule } from '../new-configurator-lazy.module';

type ModuleWithRoot = Type<any> & { rootComponent: Type<any> };

@Component({
    selector: 'icc-new-configurator-container',
    templateUrl: './new-configurator-container.component.html',
    styles: [],
})
export class NewConfiguratorContainerComponent implements OnInit, OnDestroy  {
    @Input() options: any;
    @ViewChild('container', { read: ElementRef }) container: | ElementRef
    | undefined;
    private outlet: DomPortalOutlet;

    constructor(
        private initService: InitService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone,
    ) {}

    ngOnInit() {}

    ngOnDestroy() {
        if (this.outlet) {
            this.outlet.detach();
        }
    }

    ngAfterViewInit(): void {
        if (this.container) {
            this.ngZone.run(() => {
                this.initService.loadOptions(this.options);
                this.outlet = new DomPortalOutlet(
                    this.container.nativeElement,
                    this.componentFactoryResolver,
                    this.applicationRef,
                    this.injector
                );

                this.outlet.detach();
                const injectionTokens = new WeakMap<any, any>();
                const injector = new PortalInjector(this.injector, injectionTokens);
                const stepPortal = new ComponentPortal(NewConfiguratorLazyModule.rootComponent, undefined, injector);
                const componentRef = this.outlet.attach(stepPortal);
                componentRef.instance.options = this.options;
                this.cdr.detectChanges();
            });
        }
    }
}
