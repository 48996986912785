import { Injectable, Inject } from '@angular/core';
import { IccDrawMathService, IccDrawSyncService } from '@icc/draw';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';

import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { UserService } from '@icc/common/user.service';
import { DrawData } from './draw-data';
import { logger } from '../helpers';
import { WindowActiveConfiguration } from '../configurations/WindowActiveConfiguration';

@Injectable()
export class DrawService {
    details: any = {};
    get options() {
        return {
            base: {
                ventilator: this.config().IccConfig.Drawing.ventilator,
                showSymbolsForWindow: this.config().IccConfig.Drawing.showSymbolsForWindow,
                showSymbolsForFullDoors: this.config().IccConfig.Drawing.showSymbolsForFullDoors
                    ||
                    (WindowActiveConfiguration.is(this.configurationsService?.conf?.Current) ?
                    Boolean(this.configurationsService.conf.Current?.System?.default_market_configuration?.double_door_lock_id) : false),
                showSymbolsForProfileDoors: this.config().IccConfig.Drawing.showSymbolsForProfileDoors,
                side: ((this.config().configuratorType === 'door'
                            && this.config().IccConfig.Configurators.door.drawingDefaultOuterView)
                        || (this.config().configuratorType === 'garage_door'
                            && this.config().IccConfig.Configurators.garage_door.drawingDefaultOuterView)
                        || (this.config().configuratorType !== 'door'
                            && this.config().IccConfig.Configurators.window.drawingDefaultOuterView))
                        ? 'outer' : 'inner'
            },
            colors: {
                glassStartColor: this.config().IccConfig.Drawing.glassStartColor,
                glassStopColor: this.config().IccConfig.Drawing.glassStopColor,
                glassGradient: this.config().IccConfig.Drawing.glassGradient,
                strokeContrastRatio: this.config().IccConfig.Drawing.strokeContrastRatio || 30,
            },
            dimensions: {
                multiplier: 1.5,
                ratio:
                    this.userService.get().dimension_unit === 'inch'
                        ? this.config().IccConfig.Configurators.inchToMilimeter
                        : 1,
                sumHeight: this.config().IccConfig.Drawing.sumHeight,
                type:
                    this.config().IccConfig.Drawing.dimensionType === 'allWithExpanders'
                        ? 'outer'
                        : 'inner',
            },
            muntinShadow: {
                drawShadow: true,
                shadow: {
                    width: 2,
                }
            },
            indexes: ((this.config().configuratorType === 'garage_door'
                        && this.config().IccConfig.Configurators.garage_door.showPanelIndexesOnDrawing
                        ) || (this.config().configuratorType === 'roller_shutter' && this.config().IccConfig.Configurators.roller_shutter.showPanelIndexesOnDrawing)
                        ? { multiplier: 3 }
                        : false)
                        || (WindowActiveConfiguration.is(this.configurationsService?.conf?.Current) ?
                        Boolean(this.configurationsService.conf.Current?.System?.default_market_configuration?.double_door_lock_id) : false),
            onInterfaceClick: () => {},
        };
    }

    data: any;
    node: any;

    constructor(
        private configurationsService: ConfigurationsService,
        private eventBusService: EventBusService,
        private userService: UserService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {
        const user = this.userService.get();
        this.eventBusService.subscribe(
            [
                'updatedCoupledWindow',
                'changedSashes',
                'changedFrames',
                'changedFillings',
                'setExtensionProfile',
                'setFrameProfile',
                'setSashProfile',
                'setMullionProfile',
                'putAlignmentInField',
                'removedAlignmentInField',
                'removedAlignment',
                'putExtensionOnSide',
                'removedExtensionFromSide',
                'setLowThreshold',
                'unsetLowThreshold',
                'insertMuntins',
                'removeMuntins',
                'updateMuntins',
                'setMuntinColor',
                'setConstructionColor',
                'icc-redraw',
                'changedFinWidths',
                'setGlazingInSash',
                'setFillingColor',
                'changedDoorHardware',
                'setThresholdColor',
                'setLippingColor',
                'configurationChanged',
                'changedConfigurator',
                'setDoorPortalColor',
            ],
            data => {
                try{
                    this.init();
                } catch (err){
                    console.error(err);
                }
            }
        );
    }

    get(type: string, params?: any): any {
        if (type && this.data && this.data[type] && this.data[type].length) {
            return (
                this.data[type].find(el =>
                    params
                        ? Object.keys(params).reduce(
                              (prev, curr) => prev && el[curr] === params[curr],
                              true
                          )
                        : true
                ) || null
            );
        } else {
            return null;
        }
    }

    getData(details: any): any[] {
        this.details = details;

        this.resetNodeData();
        this.parseNodeData();

        return { ...this.data };
    }

    init() {
        if (this.configurationsService.conf && this.configurationsService.conf.Current) {
            this.details = this.configurationsService.createSimpleConfiguration(
                this.configurationsService.conf.Current
            );
            this.details.timestamp = Date.now();

            this.resetNodeData();
            this.parseNodeData();
            this.configurationsService.conf.Current.drawData = { ...this.data };
        }
    }

    resetNodeData() {
        const options = {
            base: { ...this.options.base, side: null },
            dimensions: false,
        };

        try {
            this.node = IccDrawSyncService.syncDetails(this.details, options);
        } catch (err) {
            logger.error(err);
        }

        this.data = {
            base: [],
            alignment: [],
            coupling: [],
            extension: [],
            filling: [],
            frame: [],
            glazingBead: [],
            mullion: [],
            muntin: [],
            reno: [],
            renson: [],
            sash: [],
            sashFrame: [],
            shape: [],
        };
    }

    parseNodeData(node: any = this.node) {
        if (node && node.setDrawData) {
            node.setDrawData(this.data);

            if (node.children && node.children.length > 0) {
                node.children.map(child => this.parseNodeData(child));
            }
        }
    }
}
