/**
 * Funkcja zwracajca dyrektywę
 */
export default function IcIncludeDirective() {
    'ngInject';

    var directive = {
        restrict   : 'EA',
        templateUrl: function(elem, attr) {
            if (attr.pdf != null) {
                return '/common/templates/pdf/' + attr.src;
            }
            return attr.src;
        }
    };

    return directive;
}
