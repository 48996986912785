import { ActiveConfiguration } from '@icc/common/configurations/ActiveConfiguration';
import { RollerShutterActiveConfiguration } from '@icc/common/configurations/RollerShutterActiveConfiguration';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';
import { ExternalBlindConfiguration } from './ExternalBlindConfiguration';


export class ExternalBlindActiveConfiguration extends RollerShutterActiveConfiguration implements ActiveConfiguration{
    public static is(configuration): configuration is ExternalBlindActiveConfiguration {
        return configuration instanceof ExternalBlindActiveConfiguration || !configuration.$version;
    }
    type: 'external_blind' = 'external_blind';


    constructor(configuration?, configuratorsDataService?: ConfiguratorsDataService) {
        super(configuration, configuratorsDataService);
        if (configuration) {
            if (ExternalBlindActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (ExternalBlindConfiguration.is(configuration) && configuratorsDataService) {
                    this.RollerShutter.mountingLength = configuration.rollerShutter.mountingLength;
                    this.RollerShutter.crankSlope = configuration.rollerShutter.crankSlope;
                    this.RollerShutter.crankLength = configuration.rollerShutter.crankLength;
                    this.RollerShutter.crankOffset = configuration.rollerShutter.crankOffset;
                }
            }
        }
    }
}
