export default function CustomPricesCtrl($scope, $http, EnvConfig) {
    var vm = this;

    vm.price_base     = null;
    vm.price          = null;
    vm.price_proposed = null;

    vm.models    = [];
    vm.fields    = [];
    vm.records   = [];
    vm.relations = [];

    vm.getModels      = getModels;
    vm.getFields      = getFields;
    vm.getRecords     = getRecords;
    vm.getPrices      = getPrices;
    vm.getJsonDetails = getJsonDetails;
    vm.getJsonRows    = getJsonRows;

    getModels();

    /**
     * Pobiera listę modeli
     */
    function getModels() {
        $http.get((EnvConfig.remoteHost || window.location.origin) + '/custom_price/custom_prices/models_list.json').then((res) => {
            vm.models = res.data || [];
            if (!vm.model) {
                vm.model = Object.keys(vm.models)[0];
            }
            getFields();
        });
    }

    /**
     * Pobiera listę pól
     */
    function getFields() {
        $http
            .get((EnvConfig.remoteHost || window.location.origin) + '/custom_price/custom_prices/fields_list/' + vm.model + '.json')
            .then((res) => {
                vm.fields = res.data || [];
                if (!vm.field || Object.keys(vm.fields).indexOf(vm.field) < 0) {
                    vm.field = Object.keys(vm.fields)[0];
                }
                getRecords();
            });
    }

    /**
     * Pobiera rekordy
     */
    function getRecords() {
        $http
            .get((EnvConfig.remoteHost || window.location.origin) + '/custom_price/custom_prices/records_list/' + vm.model + '/' + vm.field + '.json')
            .then((res) => {
                vm.records   = res.data.records || [];
                vm.relations = res.data.relations || [];
                vm.jsons     = res.data.jsons || [];

                if (!vm.record || Object.keys(vm.records).indexOf(vm.record) < 0) {
                    vm.record   = Object.keys(vm.records)[0];
                }
                if (!vm.relation || Object.keys(vm.relations).indexOf(vm.relation) < 0) {
                    vm.relation = Object.keys(vm.relations)[0];
                }

                vm.showRelation = Object.keys(vm.relations).length > 0;

                getJsonDetails();
            });
    }

    /**
     * Pobiera pola cenowe jsona
     */
    function getJsonDetails() {
        if (vm.jsons.indexOf(vm.field) < 0) {
            getPrices();
            vm.showJson = false;
        } else {
            vm.showJson = true;
            $http
                .get((EnvConfig.remoteHost || window.location.origin) + '/custom_price/custom_prices/json_fields_list/' + vm.model + '/' + vm.field + '/' + vm.record + '.json')
                .then((res) => {
                    vm.jsonFields = res.data || [];
                    if (!vm.jsonField || Object.keys(vm.jsonFields).indexOf(vm.jsonField) < 0) {
                        vm.jsonField = Object.keys(vm.jsonFields)[0];
                    }
                    getJsonRows();
                });
        }
    }

    /**
     * Pobiera nazwy rzędów jsona
     */
    function getJsonRows() {
        $http
            .get((EnvConfig.remoteHost || window.location.origin) + '/custom_price/custom_prices/jsons_list/' + vm.model + '/' + vm.field + '/' + vm.record + '.json')
            .then((res) => {
                vm.jsonRows = res.data || [];
                if (!vm.jsonRow || Object.keys(vm.jsonRows).indexOf(vm.jsonRow) < 0) {
                    vm.jsonRow = '0';
                }
                getPrices();
            });
    }

    /**
     * Pobiera ceny pola
     */
    function getPrices() {
        $http
            .get((EnvConfig.remoteHost || window.location.origin) + '/custom_price/custom_prices/prices_list/' + vm.model + '/' + vm.field + '/' + vm.record + '/'
                + (vm.relation || vm.jsonRow || '-1') + '/' + (vm.jsonField || '-1') + '/' + vm.dealer + '.json')
            .then((res) => {
                vm.price_base     = parseFloat(res.data.price_base);
                vm.price          = parseFloat(res.data.price);
                vm.price_proposed = parseFloat(res.data.price_proposed);
            });
    }

}
