import { ExternalBlindActiveConfiguration } from '@icc/common/configurations/ExternalBlindActiveConfiguration';
import { RollerShutterConfiguration } from '@icc/common/configurations/RollerShutterConfiguration';



export class ExternalBlindConfiguration extends RollerShutterConfiguration {
    type: 'external_blind' = 'external_blind';

    constructor(
        configuration?,
        dataRequiredToUpdate?: any,
        conversion = true,
        configuratorsDataService?,
        colorsDefaultsService?
    ) {
        super(configuration, dataRequiredToUpdate, false, configuratorsDataService, colorsDefaultsService);

        if (ExternalBlindConfiguration.is(configuration) && conversion) {
            this.runConversion(configuration, dataRequiredToUpdate);
        } else if (ExternalBlindActiveConfiguration.is(configuration)) {
            this.rollerShutter.mountingLength = configuration.RollerShutter.mountingLength;
            this.rollerShutter.crankSlope = configuration.RollerShutter.crankSlope;
            this.rollerShutter.crankLength = configuration.RollerShutter.crankLength;
            this.rollerShutter.crankOffset = configuration.RollerShutter.crankOffset;
        }
    }

    public static is(configuration): configuration is ExternalBlindConfiguration {
        return (
            configuration instanceof ExternalBlindConfiguration
            || (configuration.$version && !configuration.active)
        );
    }
}
