import {
    Common,
    TranslateService,
    APP_CONFIG,
    AppConfigFactory,
    ConfigurationsService,
    ConfiguratorsDataService,
    ProfilesService,
    EventBusService,
    WindowActiveConfiguration,
} from '@icc/common';
import { Inject, Injectable } from '@angular/core';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { core } from '@icc/common/Core';
import { PriceService } from '@icc/price';
import { StepsService, IssuesService } from '@icc/helpers';
import { ActiveMullion } from '@icc/common/layout/active-mullion';
import { ActiveSash } from '@icc/common/layout/active-sash';

@Injectable()
export class DimensionsService {
    loadedData = false;
    defaultDimensions = {
        width: this.configurationsService.conf.Current.Width,
        height: this.configurationsService.conf.Current.Height,
        sashWidth: this.configurationsService.conf.Current.Width,
        sashHeight: this.configurationsService.conf.Current.Height,
        circuit:
            (this.configurationsService.conf.Current.Width
                + this.configurationsService.conf.Current.Height)
            * 2,
    };

    constructor(
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService<'mosquito' | 'window'>,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private configuratorsDataService: ConfiguratorsDataService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private priceService: PriceService,
        private issuesService: IssuesService,
        private stepsService: StepsService,
        private eventBusService: EventBusService,
        private profilesService: ProfilesService
    ) {
        'ngInject';

        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribe(
            ['changedSashes', 'setFrameProfile', 'setMullionProfile'],
            () => {
                this.validateAndUpdateDimensions();
            }
        );
    }

    private init() {
        if (this.currentConfiguratorService.conf === 'mosquito') {
            if (
                Common.isUndefined(this.configurationsService.conf.Current.Shape)
                || Common.equals(this.configurationsService.conf.Current.Shape, {})
            ) {
                this.setShapeData();
            }
            if (
                this.configurationsService.conf.Current.Width === null
                || this.configurationsService.conf.Current.Height === null
            ) {
                this.setGlobalDimensions();
            }
            if (
                Common.isArray(
                    this.configuratorsDataService.data.mosquitoTypes[
                        this.configurationsService.conf.Current.System.id
                    ]
                )
                && this.configuratorsDataService.data.mosquitoTypes[
                    this.configurationsService.conf.Current.System.id
                ].length
            ) {
                this.stepsService.enable('types');
            } else {
                this.stepsService.disable('types');
            }
            this.loadedData = true;
        }
    }

    /**
     * Wstawienie wymiarow konfiguracji
     */
    setGlobalDimensions() {
        this.configurationsService.conf.Current.Width = core.copy(
            Number(this.configurationsService.conf.Current.Shape.width)
        );
        this.configurationsService.conf.Current.Height = core.copy(
            Number(this.configurationsService.conf.Current.Shape.height)
        );
    }

    /**
     * Przeliczanie obwodu konstrukcji
     */
    recalculateCircuit() {
        this.configurationsService.conf.Current.Shape.circuit =
            (this.configurationsService.conf.Current.Width
                + this.configurationsService.conf.Current.Height)
            * 2;
    }

    /**
     * Ustawienie wymiarow w konfiguracji
     */
    setShapeData() {
        this.configurationsService.conf.Current.Shape.width = this.configurationsService.conf.Current.Width;
        this.configurationsService.conf.Current.Shape.height = this.configurationsService.conf.Current.Height;
    }

    /**
     * Ustawienie nowego wymiaru
     * @param {object} dimensions Obiekt z wymiarami
     */
    setDimensions(dimensions) {
        this.configurationsService.conf.Current.Shape = core.copy(dimensions);
        // setShapeData();
        this.setGlobalDimensions();
        this.priceService.count();
        this.recalculateCircuit();
    }

    /**
     * Walidacja wymiarow
     * @param  {object} shapeData Wymiary
     * @return {boolean}          Czy zwalidowano poprawnie
     */
    valid(shapeData) {
        if (!shapeData.width || !shapeData.height) {
            this.issuesService.simpleRegister(
                'no-mosquito-dimensions',
                'Podane wymiary są nieprawidłowe.',
                this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                this.configurationsService.conf.Current
            );
            return false;
        }
        if (shapeData.width < 250 || shapeData.height < 250) {
            this.issuesService.simpleRegister(
                'incorrect-mosquito-dimensions',
                'Podane wymiary są nieprawidłowe.',
                this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                this.configurationsService.conf.Current
            );
            return false;
        }
        this.issuesService.unregister(
            'no-mosquito-dimensions',
            this.configurationsService.conf.Current
        );
        this.issuesService.unregister(
            'incorrect-mosquito-dimensions',
            this.configurationsService.conf.Current
        );
        return true;
    }

    getFrameLightOuterWidth(conf: WindowActiveConfiguration, sash: ActiveSash) {
        const sashDimensions = conf.drawData.sash.find(s => s.sashId === sash.id);
        const frame = conf.Frames.find(f => f.id === sash.frameId);
        const frameSides = this.profilesService.getFrameSides(frame, conf);
        let width = 0;
        if (sashDimensions) {
            width = ['left', 'right'].reduce((val, side) => {
                let mullion: false | null | ActiveMullion = false;
                let nearSash: ActiveSash;
                if (sash.nearMullions[side] !== -1) {
                    mullion = conf.Mullions.find(m => m.id === sash.nearMullions[side]);
                    nearSash = mullion['multiAlign' + core.capitalize(side)][0];
                }
                if (
                    mullion
                    && mullion.type === 'false_mullion'
                    && ['F', 'FF', 'OFF'].indexOf(nearSash.type.type) === -1
                ) {
                    const falseMullionProfile = conf.UsedProfiles.find(
                        p => mullion && p.id === mullion.profileId
                    );
                    const nearSashDimensions = conf.drawData.sash.find(
                        s => s.sashId === nearSash.id
                    );
                    const profile = conf.UsedProfiles.find(
                        p =>
                            p.id
                            === (nearSash.nearMullions[side] !== -1
                                ? conf.Mullions.find(m => m.id === nearSash.nearMullions[side])
                                      .profileId
                                : frame.frame[frameSides.findIndex(s => s.side === side)].profileId)
                    );
                    const profileRatio = nearSash.nearMullions[side] !== -1 ? 2 : 1;
                    return (
                        val
                        + falseMullionProfile.width
                        + (profile
                            ? nearSashDimensions.inner.rect.width
                              + profile.width / profileRatio
                              - profile.widthOut / profileRatio
                            : 0)
                    );
                } else {
                    const profile = conf.UsedProfiles.find(
                        p =>
                            p.id
                            === (mullion
                                ? mullion.profileId
                                : frame.frame[frameSides.findIndex(s => s.side === side)].profileId)
                    );
                    const profileRatio = mullion ? 2 : 1;
                    return profile
                        ? val
                              + (profile.type === 'false_mullion'
                                  ? profile.width
                                  : profile.width / profileRatio - profile.widthOut / profileRatio)
                        : 0;
                }
            }, sashDimensions.inner.rect.width);
        }

        return (
            Math.floor(width)
            + this.config().IccConfig.Configurators.window.mosquitoAdditionalSize * 2
        );
    }

    getFrameLightOuterHeight(conf: WindowActiveConfiguration, sash: ActiveSash) {
        const sashDimensions = conf.drawData.sash.find(s => s.sashId === sash.id);
        const frame = conf.Frames.find(f => f.id === sash.frameId);
        const frameSides = this.profilesService.getFrameSides(frame, conf);
        let height = 0;
        if (sashDimensions) {
            height = ['top', 'bottom'].reduce((val, side) => {
                const profile = conf.UsedProfiles.find(
                    p =>
                        p.id
                        === (sash.nearMullions[side] !== -1
                            ? conf.Mullions.find(m => m.id === sash.nearMullions[side]).profileId
                            : frame.frame[frameSides.findIndex(s => s.side === side)].profileId)
                );
                const profileRatio = sash.nearMullions[side] !== -1 ? 2 : 1;
                return profile
                    ? val + profile.width / profileRatio - profile.widthOut / profileRatio
                    : 0;
            }, sashDimensions.inner.rect.height);
        }

        return (
            Math.floor(height)
            + this.config().IccConfig.Configurators.window.mosquitoAdditionalSize * 2
        );
    }

    validateAndUpdateDimensions() {
        const conf = this.configurationsService.conf.Current;
        if (conf.type !== 'mosquito') {
            conf.Sashes.forEach(sash => {
                if (sash.mosquito) {
                    if (['F', 'FF', 'OFF'].indexOf(sash.type.type) > -1) {
                        sash.mosquito = null;
                    } else {
                        sash.mosquito.Height =
                            this.getFrameLightOuterHeight(conf, sash) + sash.mosquito.HeightModify;
                        sash.mosquito.Width =
                            this.getFrameLightOuterWidth(conf, sash) + sash.mosquito.WidthModify;
                    }
                }
            });
        }
    }
}
