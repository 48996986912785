import { core } from '../app/helpers';
import { EventBusService } from '@icc/common/event-bus.service';
import { Injectable, InjectionToken, Inject, Optional } from '@angular/core';

export let TOKEN = new InjectionToken<{ token: string }>('token');
@Injectable()
export class TokenService {
    private token = null;

    constructor(
        private eventBusService: EventBusService,
        @Optional() @Inject(TOKEN) token: { token: string }
    ) {
        this.init(token);

        if (!core.isWorker() && !core.isNode()) {
            window.addEventListener('storage', (event) => {
                if (event.storageArea !== localStorage) {
                    return;
                }
                if (event.key === 'token' && event.newValue !== this.token) {
                    this.setToken(event.newValue);
                }
            });
        }
    }

    init(token?: { token: string }) {
        if (!core.isWorker() && !core.isNode()) {
            this.setToken(localStorage.getItem('token') || null);
        }
        if (core.isWorker() && token) {
            this.setToken(token.token);
        }
    }

    getToken() {
        return this.token || '';
    }

    setToken(token) {
        this.token = token;

        if (!core.isWorker() && !core.isNode()) {
            if (token == null) {
                localStorage.removeItem('token');
            } else {
                localStorage.setItem('token', token);
            }
        }

        this.eventBusService.post({
            key: 'changedToken',
            value: this.token,
        });
    }

    addToken(data: any = {}): any {
        return { ...data, token: this.getToken() };
    }
}
