import { Injectable } from "@angular/core";
import { ConfigurationsService } from "@icc/common";
import { IccAccessoryAccessory } from "@icc/common/data-types/Accessory";

@Injectable({providedIn: 'root'})
export class KeysService {

    constructor(
        private configurationsService: ConfigurationsService<'door'>
    ) {

    }

    setKeyQuantityForCylinder(quantity: number, selectedType, conf = this.configurationsService.conf.Current) {
        if(selectedType.type === 'cylinder' && selectedType.fits_the_lock) {
            conf.doorHardware.cylinders.keyQuantityForAdditionalLock = Number(quantity); 
        } else {
            conf.doorHardware.cylinders.keyQuantityForMainLock = Number(quantity);
        }
    }

    resetQuantityIfKeysAreDifferent(cylinder: IccAccessoryAccessory, conf = this.configurationsService.conf.Current) {
        if(conf.doorHardware.cylinders.mainLock !== Number(cylinder.id) && !cylinder.fits_the_lock){
           conf.doorHardware.cylinders.keyQuantityForMainLock = 0;
        } else if(conf.doorHardware.cylinders.additionalLock !== Number(cylinder.id) && cylinder.fits_the_lock){
            conf.doorHardware.cylinders.keyQuantityForAdditionalLock = 0;
        }
    }
}