/**
 * Popup z wyborem layoutu szprosów
 * @param {object} $scope         Angular scope
 * @param {object} $uibModalInstance Okno modal
 */
export default function ModalMuntinsLayoutCtrl($scope, $uibModalInstance) {
    'ngInject';

    var vm = this;

    vm.selectBarLayout = function(h, v) {
        $uibModalInstance.close({'v': v, 'h': h});
    };

    vm.selectSpecialBarLayout = function(id) {
        $uibModalInstance.close({'s': id});
    };

    vm.close = function() {
        $uibModalInstance.close();
    };
}
