<ng-container>
  <form>
    <mat-form-field appearance="fill">
      <mat-label>{{'CONFIGURATOR|Dodatkowe notatki:' | translate}}</mat-label>
      <textarea filled [formControl]='notesForm' matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
    <label for='imageInput'>{{'CONFIGURATOR|Wyślij zdjęcia:' | translate}}</label>
    <input (change)="addFiles($event)" [formControl]='filesForm' id='imageInput' type='file' value="wyślij zdjęcia" />
  </form>

</ng-container>

<div>
  <div>
      <ng-container *ngFor="let attachment of attachments; let i = index">
          <div *ngIf="!attachment.deleted">
              <a (click)="downloadFile(attachment)">{{attachment.name}}</a> <button (click)="removeFile(i)" mat-icon-button>
                <mat-icon>remove_circle</mat-icon>
            </button>
          </div>
      </ng-container>
  </div>
</div>