import angular from 'angular';
import $ from 'jquery';
import { logger } from '@icc/common/helpers';
import { currencyExchange } from '@icc/helpers';

export default function ModalOfferConfModal(
    stepCode,
    subStep,
    position,
    $uibModalInstance,
    $rootScope,
    $scope,
    $filter,
    $location,
    Core,
    DatabaseManager,
    SashesService,
    LayoutService,
    SashesLayoutService,
    PositionsFactory,
    StepFactory,
    ConfigurationsService,
    DrawService,
    IssuesService,
    PriceService,
    StateService,
    EventBusService,
    OffersService
) {
    'ngInject';

    var vm = this;

    position.configuration = angular.extend(position.configuration, {
        Quantity: position.quantity || position.configuration.Quantity,
        Title: position.custom_title || position.configuration.Title,
        Description: position.description || position.configuration.Description,
        _id: position._id,
        _rev: position._rev,
    });

    if (position._id) {
        ConfigurationsService.conf.Current = ConfigurationsService.createActiveConfiguration(
            position.configuration
        );
        ConfigurationsService.conf.Edit = ConfigurationsService.createActiveConfiguration(
            position.configuration
        );
    } else {
        ConfigurationsService.conf.Current = position.configuration;
        ConfigurationsService.conf.Edit = position.configuration;
    }
    ConfigurationsService.conf.offerConfigurator = true;

    EventBusService.setCurrentConfiguration(ConfigurationsService.conf);
    EventBusService.post({ key: 'icc-redraw', value: {} });
    EventBusService.post({ key: 'changedConfigurator', value: {} });

    vm.step = StepFactory.getStepByCode(stepCode);
    vm.stepCode = stepCode;
    vm.conf = position.configuration.type;
    vm.num = Core.num;
    vm.currentConfig = ConfigurationsService.conf.Current;
    vm.editConfig = ConfigurationsService.conf.Edit;
    vm.noPrice = { noPrice: true };
    vm.location = $location;
    vm.pos_count = 0;
    vm.b2c = !$rootScope.user || !$rootScope.user.access || $rootScope.user.access == 'klient';
    vm.adminThumb = ~~sessionStorage.getItem('adminThumb');
    vm.adminMistake = sessionStorage.getItem('adminMistake') == 'true';
    vm.close = closeModal;
    vm.save = save;
    vm.glazingSubStep = subStep;
    vm.drawService = DrawService;

    function closeModal(whereChanges) {
        $uibModalInstance.close(whereChanges);
    }

    var loadDataForMistakeProduct = $rootScope.$on('loadedProductMistake', () => {
        var _id = vm.currentConfig._id;
        var _rev = vm.currentConfig._rev;
        vm.currentConfig = ConfigurationsService.conf.Current;
        vm.currentConfig._id = _id;
        vm.currentConfig._rev = _rev;
    });

    $scope.$on('$destroy', () => {
        loadDataForMistakeProduct();
    });

    function save() {
        if (position.tmp_id) {
            $rootScope.mistakeProductLoaded = false;

            $rootScope.$broadcast(
                'changedStep',
                {
                    i: 1,
                    code: 'system',
                },
                {
                    i: vm.step,
                    code: vm.stepCode,
                }
            );

            if (
                vm.stepCode === 'dimensions'
                && ['window', 'hs', 'door', 'folding_door', 'sliding_door'].includes(vm.conf)
                && (!vm.currentConfig.Layout || !vm.currentConfig.Layout.id)
            ) {
                const defaultLayout = SashesService.layouts.find(
                    layout =>
                        layout.SashesLayout.type === vm.currentConfig.type
                        && layout.SashesLayout.shape_id === vm.currentConfig.Shape.shape
                        && layout.SashesLayoutsVariant.config
                );
                LayoutService.selectLayout(defaultLayout, true);
            }

            PriceService.count(vm.currentConfig);

            if (!IssuesService.isBlockedAddToOffer(vm.currentConfig)) {
                $rootScope.loader = true;

                OffersService.get(position.dealer_offer_id).then(doc => {
                    PositionsFactory.updateEdit(position.tmp_id, {
                        configuration: vm.currentConfig,
                        details: ConfigurationsService.createSimpleConfiguration(vm.currentConfig),
                        offer: doc,
                        image: Core.svgToPngData(
                            $('#configuratorContentSummary .smallWindowPreview')[0]
                        ),
                        price: currencyExchange(vm.currentConfig.Price, doc.currency),
                        price_no_margin: currencyExchange(vm.currentConfig.PriceNoMargin, doc.currency),
                        custom_title: vm.currentConfig.Title,
                        description: vm.currentConfig.Description,
                        standard:
                            !isNaN(vm.currentConfig.Price)
                            && vm.currentConfig.Price !== null
                            && Core.isEmpty(vm.currentConfig.NoPriceElems)
                                ? true
                                : false,
                    }).then(
                        () => {
                            delete ConfigurationsService.conf.Edit;
                            $rootScope.showInfo(
                                $filter('translate')(
                                    'CONFIGURATOR|Edycja konstrukcji została zapisana'
                                ),
                                null,
                                null
                            );
                            ConfigurationsService.conf.offerConfigurator = false;
                            $uibModalInstance.close(true);
                        },
                        e => {
                            logger.error(e);
                        }
                    );
                });
            } else {
                $rootScope.showInfo(
                    $filter('translate')('CONFIGURATOR|Niepoprawna konfiguracja'),
                    null,
                    null
                );
            }
        } else {
            $uibModalInstance.close(false);
        }
    }

    vm.noPrice.noPrice = StateService.state.offers
        ? StateService.state.offers[0].doc.no_price
        : null;

    if (vm.conf == 'window' || vm.conf == 'door' || vm.conf == 'hs' || vm.conf == 'folding_door' || vm.conf == 'sliding_door') {
        SashesLayoutService.rebuildRefs(ConfigurationsService.conf.Current);
    }

    $rootScope.loader = false;
}
