<icc-select-box
[title]="'GARAGEDOOR|Układ przetłoczeń panelu cokołu' | translate"
[imageUrl]="'/files/garagedoorpanel/' + (selectedPlinthPanel$ | async)?.image"
[description]="(selectedPlinthPanel$ | async)?.name"
[buttonText]="'INTERFACE|Zmień' | translate"
(select)="selectPlinthPanel()"
></icc-select-box>

<icc-select-box
[title]="'GARAGEDOOR|Struktura powierzchni panelu cokołu' | translate"
[imageUrl]="'/files/garagedoorpanelstructure/' + (selectedPlinthPanelStructure$ | async)?.img"
[description]="(selectedPlinthPanelStructure$ | async)?.name"
[buttonText]="'INTERFACE|Zmień' | translate"
(select)="selectPlinthPanelStructure()"
[colorSelect]="true"
></icc-select-box>


