import { Action } from '@ngrx/store';
import { IComplementaryGoodsActiveConfiguration } from '@icc/common/configurations/ComplementaryGoodsActiveConfiguration';

export enum AccessoryActionTypes {
    LoadAccessory = '[Accessory] Load Accessory',
    LoadAccessoryFromStartState = '[Accessory] Load Accessory From Start State',
    LoadAccessoryDefaultsFromStartState = '[Accessory] Load Accessory Defaults From Start State',
    LoadAccessoryAllDefaults = '[Accessory] Load Accessory All Defaults',
    LoadAccessoryConfiguration = '[Accessory] Load Accessory Configuration',
    AccessoryLoaded = '[Accessory] Accessory Loaded',
    AccessoryLoadError = '[Accessory] Accessory Load Error',
    SaveAccessoryConfiguration = '[Accessory] Save Accessory Configuration',
    UpdateAccessoryConfiguration = '[Accessory] Update Accessory Configuration',
}

export class LoadAccessory implements Action {
    readonly type = AccessoryActionTypes.LoadAccessory;
}

export class AccessoryLoadError implements Action {
    readonly type = AccessoryActionTypes.AccessoryLoadError;
    constructor(public payload: any) {}
}

export class LoadAccessoryFromStartState implements Action {
    readonly type = AccessoryActionTypes.LoadAccessoryFromStartState;
    constructor(public payload: { startState: IComplementaryGoodsActiveConfiguration }) {}
}

export class LoadAccessoryDefaultsFromStartState implements Action {
    readonly type = AccessoryActionTypes.LoadAccessoryDefaultsFromStartState;
    constructor(public payload: { startState: IComplementaryGoodsActiveConfiguration }) {}
}

export class LoadAccessoryAllDefaults implements Action {
    readonly type = AccessoryActionTypes.LoadAccessoryAllDefaults;
}

export class LoadAccessoryConfiguration implements Action {
    readonly type = AccessoryActionTypes.LoadAccessoryConfiguration;
    constructor(public payload: { configuration: IComplementaryGoodsActiveConfiguration }) {}
}

export class AccessoryLoaded implements Action {
    readonly type = AccessoryActionTypes.AccessoryLoaded;
}

export class SaveAccessoryConfiguration implements Action {
    readonly type = AccessoryActionTypes.SaveAccessoryConfiguration;
}

export class UpdateAccessoryConfiguration implements Action {
    readonly type = AccessoryActionTypes.UpdateAccessoryConfiguration;
    constructor(public payload: { configuration: IComplementaryGoodsActiveConfiguration }) {}
}

export type AccessoryAction =
    | LoadAccessory
    | AccessoryLoaded
    | AccessoryLoadError
    | AccessoryLoadError
    | LoadAccessoryFromStartState
    | LoadAccessoryDefaultsFromStartState
    | LoadAccessoryAllDefaults
    | LoadAccessoryConfiguration
    | UpdateAccessoryConfiguration;

export const fromAccessoryActions = {
    LoadAccessory,
    AccessoryLoaded,
    AccessoryLoadError,
    LoadAccessoryFromStartState,
    LoadAccessoryDefaultsFromStartState,
    LoadAccessoryAllDefaults,
    LoadAccessoryConfiguration,
};
