import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { PageComponent, ModalService, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService, ConfigurationsService, APP_CONFIG, AppConfigFactory, core } from '@icc/common';
import { PriceCassonettoService } from 'libs/price/src/lib/price-cassonetto.service';
import { WindowSillsService } from '@icc/legacy/configurator/steps/complementary_goods/window-sills/window-sills.service';
import { IccAccessoryAccessory, IccAccessoryColor, IccWarmEdge } from '@icc/common/data-types';
import { UnitConverterService } from '@icc/configurator/ui';
// import { FillingValidationService } from '@icc/legacy/configurator/steps/window/glazings/filling-validation.service';

@Component({
  selector: 'icc-filling-options-page',
  templateUrl: './filling-options-page.component.html',
  styleUrls: ['./filling-options-page.component.scss']
})
export class FillingOptionsPageComponent extends PageComponent implements OnInit, OnDestroy {
  public title: string = _('ACCESSORY|Opcje dodatku');

  accessory: any;
  form: FormGroup;
  focused = false;
  showCoreColorInAdditionalProfilesAndAccessories = this.config().IccConfig.Configurators
      .showCoreColorInAdditionalProfilesAndAccessories;
  currency: any = null;
  currentPrice$ = new BehaviorSubject<string | number>('');
  private subscriptions: Subscription[] = [];
  warmEdges: IccWarmEdge[] = [];

    dimensionUnit = this.unitConverterService.getUnit();

  constructor(
      private translateService: TranslateService,
      private priceCassonettoService: PriceCassonettoService,
    //   private fillingValidationService: FillingValidationService,
      private configurationsService: ConfigurationsService<'window'>,
      private sharedFacade: SharedFacade,
      private modalService: ModalService,
      private windowSillsService: WindowSillsService,
      private fb: FormBuilder,
      @Inject(APP_CONFIG) public config: AppConfigFactory,
      @Inject(ICC_PAGE_DATA)
      private pageData: {
          filling: IccAccessoryAccessory;
          warmEdges: IccWarmEdge[];
      },
      private unitConverterService: UnitConverterService
  ) {
      super();
  }

  ngOnInit() {
      this.accessory = this.pageData.filling;
      this.warmEdges = this.pageData.warmEdges;
      this.form = this.fb.group({
          count: [this.accessory.count || 1, Validators.min(1)],
          selectedColor: [this.accessory.selectedColor || '0', Validators.required],
          currentWidth: [0, [Validators.min(500), Validators.max(3000), Validators.required]],
          currentHeight: [0, [Validators.min(100), Validators.required]],
          warmEdge: [this.warmEdges && this.warmEdges[0] && this.warmEdges[0].id || 0],
          comment: '',
      });
      if (this.accessory.blockCountChange) {
          this.form.get('count').disable();
      }

      this.subscriptions.push(
          this.sharedFacade.currency$.subscribe(currency => {
              this.currency = currency;
          })
      );
      this.subscriptions.push(
          this.form.valueChanges.subscribe(value => {
              this.currentPrice$.next(this.getPrice());
          })
      );
  }

  ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
  }

  add() {
      if (this.form.valid) {
          Object.assign(this.accessory, this.form.value);
          if (!this.accessory.no_price) {
              this.calcGlass(this.accessory);
          }
          this.sharedFacade.closePage({
              accessory: this.accessory,
          });
      }
  }

  canAdd() {
      return true;
  }

  getPrice() {
      if (!this.accessory.no_price) {
          return this.calcGlass({ ...this.accessory, ...this.form.value });
      }
      return this.translateService.instant('CONFIGURATOR|Do wyceny');
  }

  calcGlass(glass: any) {
    // const validGlazing = this.fillingValidationService.checkGlass(
    //     glass,
    //     glass.currentWidth,
    //     glass.currentHeight,
    //     (glass.currentWidth * glass.currentHeight) / 1000000
    // );
    // if (validGlazing.valid) {
        const glazingCount = ~~glass.glazing_count;
        const warmEdge = this.pageData.warmEdges.find(
            w => Number(w.id) === Number(glass.warmEdge)
        );
        const warmEdgePrice =
            warmEdge
            && warmEdge.prices.find(el => !el.window_line_id && ~~el.count === glazingCount);

        let area = core.round10((glass.currentWidth * glass.currentHeight) / 1000000, -2);
        if (
            area < glass.minBillingArea
            && this.config().IccConfig.Configurators.price.warmEdge.minBillingArea
        ) {
            area = core.round10(glass.minBillingArea, -2);
        }

        glass.price =
            area
            * (glass.price_sq_m * 1
                + Number(this.config().IccConfig.Settings.glazingBasePrice)
                + (warmEdgePrice ? warmEdgePrice.price : 0));
    // } else {
    //     glass.price = NaN;
    // }
    return glass.price
}
}

