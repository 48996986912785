/**
 * Kontroler poszycia markiz
 * @param {object} $scope                   Scope
 * @param {object} AwningSheathingService  Fabryka poszycia markiz
 */
export default function SheathingCtrl($scope, AwningSheathingService, EventBusService) {
    'ngInject';

    var vm = this;

    vm.subStep = 4;
    vm.fabrics = [];
    vm.fabric = AwningSheathingService.fabric;
    vm.style = AwningSheathingService.style;
    vm.selectFabric = selectFabric;
    vm.openModalStyle = openModalStyle;
    vm.valid = AwningSheathingService.valid.bind(AwningSheathingService);

    $scope.$on('changedStep', (event, newVal, oldVal) => {
        if (newVal.code !== 'sheathing' && oldVal.code === 'sheathing') {
            vm.valid();
        }
    });

    if (AwningSheathingService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjujaca
     */
    function init() {
        vm.fabrics = AwningSheathingService.fabrics;
        vm.fabric = AwningSheathingService.fabric;
        vm.style = AwningSheathingService.style;
    }

    /**
     * Wybieranie matriału
     * @param  {Object} fabric Materiał
     */
    function selectFabric(fabric) {
        AwningSheathingService.selectFabric(fabric, false, true);
    }

    /**
     * Styl otwartego modalu
     */
    function openModalStyle() {
        AwningSheathingService.openModalStyle();
    }
}
