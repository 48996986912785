import { Wood } from '../common';
import { SimpleAccessory } from './SimpleAccessory';
import { BaseColor, Color, ColorId, ConstructionColor, ProfileSideColors } from './Colors';
import { HardwareVisualization } from '@icc/common/data-types';

/** Dodatek */

export class Accessory implements SimpleAccessory {
    /** Identyfikator */
    id: number;
    /** Nazwa */
    name: string;
    /** Źródło ceny */
    priceSource: 'table' | 'colors' | 'confColors';
    /** Typ dodatku */
    type: string;
    /** Kod producenta */
    manufacturerCode: string;
    /** Liczba sztuk */
    count: number;
    /** Pozycja */
    position: number;
    /** Ilość */
    amount: number;
    /** Szerokość [mm] */
    width: number;
    /** Wysokość [mm] */
    height: number;
    /** Długość [mm] */
    currentLength?: number;
    /** Komentarz */
    comment: string;
    /** Zestaw kolorystyczny */
    colorSet?: 'white' | 'color-white' | 'color-color' | 'ral';
    /** Kolorystyka */
    color?: {
        type?: string;
        accessory?: ColorId;
        profile?: ProfileSideColors;
    };
    /** Kategoria dodatku */
    category: {
        /** Identyfikator */
        id: number;
        /** Nazwa */
        name: string;
    };
    /** Rodzaj drewna */
    wood?: {
        id: Wood['id'];
        name: Wood['name'];
    };
    /** Masa */
    weight: number;
    /** Typ ceny */
    priceType: number;
    /** Link do obrazka */
    image: string | null;
    /** Kod do indeksu */
    index_no: string;
    colorOptions: any;
    fromDependency = false;
    changedColor = false;
    addedWithDependency = false;
    addedWithRemovableDependency = false;
    dependencyAssociationIds = [];
    visualization: HardwareVisualization | null;
    spaceId: number | null;
    configurationAccessoryToSash: boolean;

    constructor(
        accessory: any,
        configuratorsDataService?,
        dictionary?: {
            accessoryColors: Record<BaseColor['id'], BaseColor>;
            colors: Record<Color['id'], Color>;
            constrColors: Record<ConstructionColor['id'], ConstructionColor>;
        },
        langCode?: string
    ) {
        if (accessory && configuratorsDataService) {
            const accessoryData = configuratorsDataService.get('windowAccessories', accessory.id);
            if (accessoryData) {
                this.id = Number(accessoryData.id);
                this.name = accessoryData.name;
                this.priceSource = accessoryData.price_source;
                this.type = accessoryData.type;
                this.manufacturerCode = accessoryData.manufacturer_code;
                this.count = Number(accessory.count);
                this.amount = Number(accessory.amount);
                this.width = Number(accessoryData.width) || 0;
                this.height = Number(accessoryData.height) || 0;
                this.currentLength = Number(accessoryData.currentLength) || 0;
                this.comment = accessoryData.comment;
                this.weight = accessoryData.weight;
                this.fromDependency = accessory.accessory.fromDependency;
                this.addedWithDependency = accessory.accessory.addedWithDependency;
                this.addedWithRemovableDependency =
                    accessory.accessory.addedWithRemovableDependency;
                this.dependencyAssociationIds = accessory.accessory.dependencyAssociationIds;
                this.priceType = Number(accessoryData.price_type);
                this.index_no = accessoryData.index_no;
                this.spaceId = accessory.accessory.spaceId;
                this.configurationAccessoryToSash = accessory.configurationAccessoryToSash;
                const accessoryCategoryData = configuratorsDataService.get(
                    'windowAccessoriesCategories',
                    accessoryData.window_accessories_category_id
                );
                this.category = {
                    id: accessoryData.window_accessories_category_id,
                    name: accessoryCategoryData ? accessoryCategoryData.name : accessory.category,
                };
                this.image =
                    accessoryData.colors_imgs[accessory.accessory.selectedColor] !== '' &&
                    accessoryData.colors_imgs[accessory.accessory.selectedColor] != null
                        ? '/files/windowhandlescolorswindowaccessory/' +
                          accessoryData.colors_imgs[accessory.accessory.selectedColor]
                        : accessory.accessory.img
                        ? '/files/windowaccessory/' + accessory.accessory.img
                        : null;

                if (this.priceSource === 'table' && accessoryData.colorOptions) {
                    this.colorSet = accessoryData.colorOptions;
                }
                if (this.priceSource === 'colors' && accessoryData.color) {
                    if (dictionary) {
                        dictionary.accessoryColors[Number(accessory.color.id)] = {
                            id: Number(accessory.color.id),
                            name: {
                                [langCode]: accessory.color.name,
                            },
                            color: accessory.color.color,
                            code: accessory.color.code,
                            colorImg: accessory.color.color_img,
                        };
                    }
                    this.color = {
                        accessory: {
                            id: Number(accessory.color.id),
                            isDefault: false,
                        },
                    };
                }
                if (this.priceSource === 'confColors') {
                    this.color = {
                        profile: {
                            outer: null,
                            inner: null,
                            core: null,
                        },
                    };
                    ['outer', 'inner', 'core'].forEach((side) => {
                        if (accessoryData.selectedColor[side].id) {
                            this.color.profile[side] = {
                                id: Number(accessory.selectedColor[side].id),
                                name: accessory.selectedColor[side].name,
                                code: accessory.selectedColor[side].code,
                                RAL: Boolean(accessory.selectedColor[side].RAL),
                                isDefault: accessory.selectedColor[side].isDefault,
                            };
                        }
                    });
                    if (
                        accessoryData.material === 'wood' &&
                        accessoryData.selectedWood &&
                        accessoryData.selectedWood.id
                    ) {
                        this.wood = {
                            id: Number(accessoryData.selectedWood.id),
                            name: accessoryData.selectedWood.name,
                        };
                    }
                }
                this.changedColor = accessory.accessory.changedColor;
                this.visualization =
                    accessory.visualization ||
                    (accessoryData.visualizations &&
                        ((this.priceSource === 'colors' &&
                            accessoryData.color &&
                            accessoryData.visualizations[accessoryData.color.id]) ||
                            accessoryData.visualizations[-1]));
            }
        } else if (accessory) {
            this.id = Number(accessory.accessory.id);
            this.name = accessory.accessory.name;
            this.colorOptions = accessory.colorOptions;
            this.priceSource = accessory.accessory.price_source;
            this.type = accessory.accessory.type;
            this.manufacturerCode = accessory.accessory.manufacturer_code;
            this.count = Number(accessory.count);
            this.position = Number(accessory.position);
            this.amount = Number(accessory.amount);
            this.width = Number(accessory.width) || Number(accessory.accessory.currentWidth) || 0;
            this.height =
                Number(accessory.height) || Number(accessory.accessory.currentHeight) || 0;
            this.currentLength = Number(accessory.accessory.currentLength) || 0;
            this.comment = accessory.comment;
            this.weight = accessory.weight;
            this.priceType = Number(accessory.accessory.price_type);
            this.fromDependency = accessory.accessory.fromDependency;
            this.addedWithDependency = accessory.accessory.addedWithDependency;
            this.addedWithRemovableDependency = accessory.accessory.addedWithRemovableDependency;
            this.dependencyAssociationIds = accessory.accessory.dependencyAssociationIds;
            this.index_no = accessory.accessory.index_no;
            this.spaceId = accessory.accessory.spaceId;
            this.configurationAccessoryToSash = accessory.configurationAccessoryToSash;
            this.category = {
                id: accessory.accessory.window_accessories_category_id,
                name: accessory.category,
            };
            this.image =
                accessory.accessory.colors_imgs[accessory.selectedColor] !== '' &&
                accessory.accessory.colors_imgs[accessory.selectedColor] != null
                    ? '/files/windowhandlescolorswindowaccessory/' +
                      accessory.accessory.colors_imgs[accessory.selectedColor]
                    : accessory.accessory.img
                    ? '/files/windowaccessory/' + accessory.accessory.img
                    : null;

            if (this.priceSource === 'table' && accessory.colorOptions) {
                this.colorSet = accessory.colorOptions;
            }
            if (this.priceSource === 'colors' && accessory.accessory.color) {
                if (dictionary) {
                    dictionary.accessoryColors[Number(accessory.accessory.color.id)] = {
                        id: Number(accessory.accessory.color.id),
                        name: {
                            [langCode]: accessory.accessory.color.name,
                        },
                        color: accessory.accessory.color.color,
                        code: accessory.accessory.color.code,
                        colorImg: accessory.accessory.color.color_img,
                    };
                }
                this.color = {
                    accessory: {
                        id: Number(accessory.accessory.color.id),
                        isDefault: false,
                    },
                };
            }
            if (this.priceSource === 'confColors') {
                this.color = {
                    profile: {
                        outer: null,
                        inner: null,
                        core: null,
                    },
                };
                ['outer', 'inner', 'core'].forEach((side) => {
                    if (accessory.accessory.selectedColor[side].id) {
                        this.color.profile[side] = {
                            id: Number(accessory.accessory.selectedColor[side].id),
                            name: accessory.accessory.selectedColor[side].name,
                            code: accessory.accessory.selectedColor[side].code,
                            RAL: Boolean(accessory.accessory.selectedColor[side].RAL),
                            isDefault: accessory.accessory.selectedColor[side].isDefault,
                        };
                    }
                });
                if (
                    accessory.accessory.material === 'wood' &&
                    accessory.accessory.selectedWood &&
                    accessory.accessory.selectedWood.id
                ) {
                    this.wood = {
                        id: Number(accessory.accessory.selectedWood.id),
                        name: accessory.accessory.selectedWood.name,
                    };
                }
            }
            this.changedColor = accessory.accessory.changedColor;
            this.visualization =
                accessory.visualization ||
                (accessory.accessory.visualizations &&
                    ((this.priceSource === 'colors' &&
                        accessory.accessory.color &&
                        accessory.accessory.visualizations[accessory.accessory.color.id]) ||
                        accessory.accessory.visualizations[-1]));
        }
    }
}
