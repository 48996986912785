export function FocusInDirective($parse) {
    'ngInject';

    return {
        restrict: 'A',
        compile: function($element, attr) {
            var fn = $parse(attr['iccFocusin']);
            return function ngEventHandler(scope, element) {
                element.on('focusin', function(event) {
                    var callback = function() {
                        fn(scope, {$event: event});
                    };
                    scope.$evalAsync(callback);
                });
            };
        }
    };
}


export function FocusOutDirective($parse) {
    'ngInject';

    return {
        restrict: 'A',
        compile: function($element, attr) {
            var fn = $parse(attr['iccFocusout']);
            return function ngEventHandler(scope, element) {
                element.on('focusout', function(event) {
                    var callback = function() {
                        fn(scope, {$event: event, $element: element});
                    };
                    scope.$evalAsync(callback);
                });
            };
        }
    };
}
