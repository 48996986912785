import { Injectable, Inject } from '@angular/core';
import { Common } from '@icc/common/Common';
import { UserService, core, APP_CONFIG, AppConfigFactory, ConfigurationsService, ConfiguratorsDataService, EventBusService, ParametersService, TranslateService } from '@icc/common';
import { IssueLevel, IssuesService, ModalService } from '@icc/helpers';
import { PriceService } from '@icc/price';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { RollerDimensionsService } from './dimensions.service';
import { DrivesAccessoriesPageComponent } from 'libs/configurator/shutter/src/lib/drives-accessories-page/drives-accessories-page.component';
import { DrivesPageComponent } from 'libs/configurator/shutter/src/lib/drives-page/drives-page.component';
import { BehaviorSubject } from 'rxjs';
import { DriveManualsPageComponent } from 'libs/configurator/shutter/src/lib/drive-manuals-page/drive-manuals-page.component';
import { ExternalBlindActiveConfiguration } from '@icc/common/configurations/ExternalBlindActiveConfiguration';
import { TimeLimitService } from '@icc/common/time-limit/time-limit.service';
import { DriveEngineService } from './drive-engine.service';
import { Hanger } from '@icc/common/data-types/ConfiguratorsData';

/**
 * Service napędow
 */
@Injectable()
export class DrivesService {

    driveType = 'manual';
    driveType$ = new BehaviorSubject('manual');
    driveOutputSides$ = new BehaviorSubject<('rear-up' | 'rear-down' | 'front-up' | 'front-down' | 'side')[]>([]);
    drives       = [];
    driveManuals = [];
    accessories  = [];
    hangers: Hanger[] = [];
    loadedData = false;

    constructor(
        private userService: UserService,
        private modalService: ModalService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private configurationsService: ConfigurationsService<'roller_shutter' | 'external_blind' | 'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private priceService: PriceService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private rollerDimensionsService: RollerDimensionsService,
        private eventBusService: EventBusService,
        private timeLimitService: TimeLimitService,
        private parametersService: ParametersService,
        private driveEngineService: DriveEngineService,
        private issuesService: IssuesService,
        private translateService: TranslateService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribeWithoutConfiguration('changedShutter', () => {
            this.loadDrives();
            this.loadDriveManuals();
            if (!this.driveManuals.length) {
                this.changeDriveType('electrical')
            }
        });
    }

    /**
     * Funkcja inicjalizujaca
     */
    private init() {
        if (['window', 'roller_shutter', 'external_blind', 'hs', 'folding_door', 'sliding_door'].indexOf(this.currentConfiguratorService.conf) === -1) {
            return;
        }
        this.loadDrives();
        this.loadDriveManuals();
        this.loadHangers();
        if (Common.isArray(this.configuratorsDataService.data.rollerAccessories)) {
            this.accessories = this.configuratorsDataService.data.rollerAccessories;
        }
        if (Common.isUndefined(this.configurationsService.conf.Current.RollerShutter.driveType)
            && this.configurationsService.conf.Current.RollerShutter.shutters.length === 0) {
                this.loadDefaults();
        }
        if (this.configurationsService.conf.Current.RollerShutter.drive.type !== 'manual') {
            this.driveType = 'electrical';
            this.driveType$.next('electrical');
        }
        if (!this.configurationsService.conf.Current.RollerShutter.hanger && this.config().IccConfig.Configurators.roller_shutter.hangerLock) {
            this.setHanger();
            this.changeOverrideHanger();
        }
        this.loadedData = true;
    }

    /**
     * Ładuje wieszaki
     */
    public loadHangers() {
        if (Common.isArray(this.configuratorsDataService.data.rollerShutterHangers)) {
            this.hangers = this.configuratorsDataService.data.rollerShutterHangers;
        }
    }

    /**
     * Ładuje sterowania
     */
    public loadDrives() {
        const conf = this.configurationsService.conf.Current;
        const roller = conf.RollerShutter;
        if (Common.isArray(this.configuratorsDataService.data.rollerShutterDrives)) {
            this.drives = this.configuratorsDataService.data.rollerShutterDrives.filter(d =>
                d.type === 'manual'
                || !d.prices
                || !d.prices.length
                || d.prices.some(p => {
                    return (!p.from || roller.shutters.every(el => parseFloat(p.from) <= el.realWidth))
                        && (!p.height || roller.shutters.every(el => parseFloat(p.height) >= el.realHeight))
                        && (!p.to || roller.shutters.every(el =>
                            parseFloat(p.to) >= this.parametersService.getRollerWeight(roller, el)))
                        && (!p.reel || Number(p.reel) === Number(roller.roundReel?.id))

                }) && (
                    conf.RollerShutter.system?.id &&
                    Common.isArray(d.systems)
                    && d.systems.indexOf(conf.RollerShutter.system.id) > -1
                )
            );
            const manualDrives = this.drives.filter((el) => el.type === 'manual');
            if ((
                    !roller.drive
                    || this.drives.map(d => d.id).indexOf(roller.drive.id) === -1
                )
                && manualDrives.length > 0
            ) {
                roller.drive = manualDrives[0];
                this.updateDriveOutputSide();
                this.priceService.count();
                this.timeLimitService.count();
            }
        }
    }

    /**
     * Ładuje rodzaje sterowania ręcznego
     */
    public loadDriveManuals(conf = this.configurationsService.conf.Current) {
        if (Common.isArray(this.configuratorsDataService.data.rollerShutterDrivesManuals)) {
            if (conf.RollerShutter.system) {
                this.driveManuals = this.configuratorsDataService.data.rollerShutterDrivesManuals.filter(d =>
                    Common.isArray(d.systems)
                        && d.systems.indexOf(conf.RollerShutter.system.id) > -1
                        && (!d.max_area || ((conf.Width * conf.Height) / 1000000 <= Number(d.max_area)))
                );
            }
            if (conf.RollerShutter.drive
                && conf.RollerShutter.drive.type === 'manual'
                && (!conf.RollerShutter.driveManual
                    || this.driveManuals.map(d => Number(d.id)).indexOf(Number(conf.RollerShutter.driveManual.id)) === -1
                )
            ) {
                const defaultManual = this.driveManuals.filter(el =>
                    Number(el.id) === Number(conf.RollerShutter.system?.default_drive_manual_id));
                if (defaultManual.length > 0) {
                    conf.RollerShutter.driveManual = core.copy(defaultManual[0]);
                } else {
                    conf.RollerShutter.driveManual = core.copy(this.driveManuals[0]);
                }
                this.updateDriveOutputSide();
                this.priceService.count();
                this.timeLimitService.count();
            }
        }
    }

    /**
     * Funkcja ładujaca domyślne dane
     */
    private loadDefaults() {
        this.configurationsService.conf.Current.RollerShutter.driveType = 'none';
        this.configurationsService.conf.Current.RollerShutter.driveManual = null;
        this.configurationsService.conf.Current.RollerShutter.driveElements = [];
        this.configurationsService.conf.Current.RollerShutter.driveSide = 'R';
        this.configurationsService.conf.Current.RollerShutter.driveOutputSide = 'rear-up';
        this.configurationsService.conf.Current.RollerShutter.drive = {type: 'manual'};
        this.driveType = 'manual';
        this.driveType$.next('manual');
        if (Common.isArray(this.drives)) {
            const manualDrives = this.drives.filter(el => el.type === 'manual');
            if (Common.isDefined(manualDrives) && Common.isDefined(manualDrives[0])) {
                this.configurationsService.conf.Current.RollerShutter.drive = manualDrives[0];
            }
        }
        this.loadRetractor();
        this.updateDriveOutputSide();
    }

    /**
     * Funkcja ustawiajaca akcesoria
     * @param {object} selectedAccessory Wybór akcesoriów
     */
    private setAccessories(selectedAccessory) {
        if (Common.isUndefined(selectedAccessory)) {
            return;
        }
        this.configurationsService.conf.Current.RollerShutter.driveElements = [];
        for (let i = 0; i < selectedAccessory.length; i++) {
            const result = this.accessories.filter(e => Number(e.id) === Number(selectedAccessory[i]));
            if (result.length === 1) {
                this.configurationsService.conf.Current.RollerShutter.driveElements.push(result[0]);
            }
        }
        this.priceService.count();
        this.timeLimitService.count();
    }

    public addAccessory(accessory) {
        this.configurationsService.conf.Current.RollerShutter.driveElements.push(accessory);
        this.eventBusService.post({
            key: 'changedDrive',
            value: null,
        });
    }

    public removeAccessory(accessory) {
        this.configurationsService.conf.Current.RollerShutter.driveElements = this.configurationsService.conf.Current.RollerShutter.driveElements.filter(el => el.id !== accessory.id);
        this.eventBusService.post({
            key: 'changedDrive',
            value: null,
        });
    }

    /**
     * Funkcja ładowania zwijacza
     */
    public loadRetractor(conf = this.configurationsService.conf.Current) {
        if (
            conf.RollerShutter.drive.type === 'manual'
            && (!Common.isObject(conf.RollerShutter.driveManual)
                || this.driveManuals
                    .map(d => Number(d.id))
                    .indexOf(Number(conf.RollerShutter.driveManual.id)) === -1)
        ) {
            if (
                Common.isDefined(conf.RollerShutter.system)
                && Common.isDefined(this.driveManuals)
            ) {
                const defaultManual = this.driveManuals.filter(
                    el => Number(el.id) === Number(conf.RollerShutter.system.default_drive_manual_id)
                );
                conf.RollerShutter.driveManual = core.copy(defaultManual[0]);
            }
        }
        this.priceService.count();
        this.timeLimitService.count();
    }

    /**
     * Ustawia wieszaki ręcznie
     */
    public changeHanger(hangerId) {
        const hanger = this.hangers.find(o => o.id === hangerId);

        this.configurationsService.conf.Current.RollerShutter.hanger = core.copy(hanger);
        this.changeOpeningLock(hanger.blockade);
        this.eventBusService.post({
            key: 'changedDrive',
            value: null,
        });
    }

    /**
     * Ustawia wieszaki automatycznie
     */
    public changeOverrideHanger(override?: 'none' | 'override') {
        if (override) {
            this.configurationsService.conf.Current.RollerShutter.driveType = override;
        }
        const drive = this.configurationsService.conf.Current.RollerShutter.drive;
        if (drive
            && this.config().IccConfig.Configurators.roller_shutter.hangerLock
            && this.config().IccConfig.Configurators.roller_shutter.hangerLockLinkedOverloadLock
        ) {
            this.setHanger();
            this.rollerDimensionsService.loadBoxHeights();
            this.rollerDimensionsService.setBoundaryDimensionsFromPrices();
            this.priceService.count();
            this.eventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
        }
    }

    /**
     * Ustawia długość uchwytów
     *
     * @param {any} mountingLength długość uchwytów
     */
    public setMountingLength(mountingLength) {
        if(ExternalBlindActiveConfiguration.is(this.configurationsService.conf.Current)){
            this.configurationsService.conf.Current.RollerShutter.mountingLength = mountingLength;
        }
    }

    /**
     * Ustawia nachylenie korby
     *
     * @param {any} crankSlope nachylenie korby
     */
    public setCrankSlope(crankSlope) {
        if(ExternalBlindActiveConfiguration.is(this.configurationsService.conf.Current)){
            this.configurationsService.conf.Current.RollerShutter.crankSlope = crankSlope;
        }
    }

    /**
     * Ustawia długość korby
     *
     * @param {any} crankLength długość korby
     */
    public setCrankLength(crankLength) {
        if(ExternalBlindActiveConfiguration.is(this.configurationsService.conf.Current)){
            this.configurationsService.conf.Current.RollerShutter.crankLength = crankLength;
        }
    }

    /**
     * Ustawia przesunięcie korby
     *
     * @param {any} crankOffset przesunięcie korby
     */
    public setCrankOffset(crankOffset) {
        if(ExternalBlindActiveConfiguration.is(this.configurationsService.conf.Current)){
            this.configurationsService.conf.Current.RollerShutter.crankOffset = crankOffset;
        }
    }


    /**
     * Ustawia sterowanie
     *
     * @param {any} drive Sterowanie
     */
    public setDrive(drive) {
        if (!drive) {
            return;
        }

        this.configurationsService.conf.Current.RollerShutter.drive = core.copy(drive);
        this.configurationsService.conf.Current.RollerShutter.driveElements
            = (this.accessories || []).filter(el => el.roller_shutter_drives_id.includes(drive?.id) && el.default_drive);
        if (drive.type !== 'manual') {
            this.configurationsService.conf.Current.RollerShutter.driveManual = null;
            this.driveType = 'electrical';
            if (!this.checkOverride(this.configurationsService.conf.Current.RollerShutter.driveType)) {
                this.configurationsService.conf.Current.RollerShutter.driveType = this.checkOverride('none') ? 'none' : 'override';
            }

        }
        const driveEngine = this.driveEngineService.getDriveEngine(this.configurationsService.conf.Current.RollerShutter);
        if (driveEngine && driveEngine.length > 0 && !driveEngine.every(el=>el===undefined)){
            this.configurationsService.conf.Current.RollerShutter.drive.driveEngine=driveEngine;
            this.driveEngineService.separateEngines(driveEngine);
            this.registerNoDriveIssue(false,this.configurationsService.conf.Current);
        } else if ((driveEngine && driveEngine.length === 0 || !driveEngine || driveEngine.every(el=>el===undefined)) && drive.type!=='manual'){
            this.registerNoDriveIssue(true,this.configurationsService.conf.Current);
        }
        this.changeOverrideHanger();
        this.loadRetractor();
        this.updateDriveOutputSide();
        this.timeLimitService.count();
        if (drive?.type !== this.driveType){
            this.driveType$.next(drive?.type === 'manual' ? 'manual' : 'electrical');
        }
        this.eventBusService.post({
            key: 'changedDrive',
            value: null,
        });
    }

    getDefaultDrive() {
        this.loadDrives();
        const drive = this.drives.filter(item=>item.type===this.driveType)[0];
        if (drive?.type !== this.driveType){
            this.driveType$.next(drive?.type === 'manual' ? 'manual' : 'electrical');
        }
        this.setDrive(drive);
    }

    /**
     * Ustawia sterowanie po zmianie rodzaju sterowania.
     *
     * @param {string} type Rodzaj sterowania
     */
    public changeDriveType(type) {
        this.driveType = type;
        this.driveType$.next(type);
        if ((this.configurationsService.conf.Current.RollerShutter.drive.type === 'manual') !== (type === 'manual')) {
            if (Common.isArray(this.drives)) {
                const drives = this.drives.filter((el) => (el.type === 'manual') === (type === 'manual'));
                if (Common.isDefined(drives) && Common.isDefined(drives[0])) {
                    this.setDrive(drives[0]);
                    this.timeLimitService.count();
                }
            }
        }
        this.eventBusService.post({
            key: 'changedDrive',
            value: null,
        });
    }

    /**
     * Funkcja otwierania okna modal z napędami
     *
     * @param {bool} onlyElectrical Tylko elektryczne
     */
    public openModalDrives(onlyElectrical = false) {
        let filter: (el: any) => boolean = () => true;
        if (onlyElectrical) {
            filter = (el) => el.type !== 'manual';
        }
        const user = this.userService.get();
        const modalInstance = this.modalService.open({
            templateUrl: 'modalDrives.html',
            controller : 'ModalDrivesController',
            pageComponent: DrivesPageComponent,
            resolve    : {
                drives: () =>  {
                    return (this.drives || []).filter(filter);
                },
                selDrive: () => {
                    return this.configurationsService.conf.Current.RollerShutter.drive;
                },
                b2c    : () => (!user || !user.access || user.access === 'klient')
            } ,
        });
        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'drivers'
             });
        }

        modalInstance.result.then(selectedDrive => {
            if (Common.isDefined(selectedDrive)) {
                this.setDrive(selectedDrive);
            }
            this.rollerDimensionsService.setBoundaryDimensionsFromPrices();
            this.rollerDimensionsService.loadBoxHeights();
            this.priceService.count();
            this.timeLimitService.count();
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                });
            }
        });
    }

    /**
     * Okno modalne z wyborem dodatku do skrzydła
     *
     * @param {any} drive Sterowanie
     */
    public openModalDriveElements(drive) {
        const user = this.userService.get();
        const modalInstance = this.modalService.open({
            templateUrl: 'modalRollerElements.html',
            controller : 'ModalDriveElementController as $ctrl',
            pageComponent: DrivesAccessoriesPageComponent,
            resolve    : {
                sashAccessories: () => (this.accessories || []).filter((el) => el.roller_shutter_drives_id.includes(drive.id)),
                selected: () => this.configurationsService.conf.Current.RollerShutter.driveElements.map((el) => el.id),
                single: () => !this.config().IccConfig.Configurators.roller_shutter.manyElectricalDriveAccessory && drive.type === 'electrical',
                b2c   : () => (!user || !user.access || user.access === 'klient')
            },
        });

        modalInstance.result.then((selectedAccessory) => {
            this.setAccessories(selectedAccessory);
        });
    }

    /**
     * Funkcja otwierania okna modal z wyborem dodatkw do sterowania recznego
     */
    public openModalDrivesManuals() {
        const user = this.userService.get();
        const modalInstance = this.modalService.open({
            templateUrl: 'modalDrivesManual.html',
            controller : 'ModalDrivesManualController',
            pageComponent: DriveManualsPageComponent,
            resolve    : {
                drives: () => {
                    return (this.driveManuals || []);
                },
                selDrive: () => {
                    return this.configurationsService.conf.Current.RollerShutter.driveManual;
                },
                b2c    : () => (!user || !user.access || user.access === 'klient')
            }
        });
        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'driversManuals'
            });
        }

        modalInstance.result.then((selectedDrive) => {
            if (Common.isDefined(selectedDrive)) {
                this.configurationsService.conf.Current.RollerShutter.driveManual = core.copy(selectedDrive);
            }
            this.priceService.count();
            this.timeLimitService.count();
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                });
            }
        });
    }

    /**
     * Funkcja zmieniająca sterowanie ręczne na podstawie radio.
     *
     * @param {any} manualId id sterowania ręcznego
     */
    public changeDriveManual(manualId) {
        let manual = (this.driveManuals || []).filter(m => Number(m.id) === Number(manualId))[0];
        if (manualId === 'r') {
            manual = (this.driveManuals || []).filter(m => m.type === 'white_retractor')[0];
        } else if (manualId === 'cr') {
            manual = (this.driveManuals || []).filter(m => m.type === 'white_cord_retractor')[0];
        }
        if (manual) {
            this.configurationsService.conf.Current.RollerShutter.driveManual = core.copy(manual);
            this.updateDriveOutputSide();
            this.changeOverrideHanger();
            this.priceService.count();
            this.timeLimitService.count();
            this.eventBusService.post({
                key: 'changedDrive',
                value: null,
            });
        }
    }

    public registerNoDriveIssue(toRegister,conf) {
        if (toRegister){
            this.issuesService.simpleRegister(
                'no-guide-rails',
                'Nie znaleziono pasujących sterowań.',
                this.translateService.instant('CONFIGURATOR|Wybrane sterowanie jest nieprawidłowe.'),
                conf,
                {
                    logLevel: IssueLevel.NONE,
                    blockAddToOffer: true
                }
              );
        } else {
            this.issuesService.unregister('no-guide-rails',conf);
            this.priceService.count();
            this.eventBusService.post({
                key: 'changedDrive',
                value: null,
            });
        }
      }

    public changeDriveManualColor(colorId: 'r' | 'b') {
        if (this.configurationsService.conf.Current.RollerShutter.driveManual) {
            const type = this.configurationsService.conf.Current.RollerShutter.driveManual.type;
            let manual;
            if (type.indexOf('cord_retractor') > -1) {
                if (colorId === 'r') {
                    manual = (this.driveManuals || []).filter(m => m.type === 'white_cord_retractor')[0];
                } else if (colorId === 'b') {
                    manual = (this.driveManuals || []).filter(m => m.type === 'brown_cord_retractor')[0];
                }
            } else if (type.indexOf('retractor') > -1) {
                if (colorId === 'r') {
                    manual = (this.driveManuals || []).filter(m => m.type === 'white_retractor')[0];
                } else if (colorId === 'b') {
                    manual = (this.driveManuals || []).filter(m => m.type === 'brown_retractor')[0];
                }
            }
            if (manual) {
                this.changeDriveManual(manual.id);
            }
        }
    }

    /**
     * Czy dostępny wybór sterowania elektrycznego
     *
     * @returns {bool} Czy dostępny wybór sterowania elektrycznego
     */
    public showDriveElectrical() {
        const conf = this.configurationsService.conf.Current;
        return (this.drives || []).filter(d => d.type !== 'manual' && (
            Common.isArray(d.systems)
            && d.systems.indexOf(conf.RollerShutter.system.id) > -1
        )).length > 0;
    }

    public shutterWidthToSmallForElectrical(){
        const conf = this.configurationsService.conf.Current;
        const roller = conf.RollerShutter;
        let minShutterWidth = this.getDriveElectricalMinWidth();
        return roller.shutters.filter(e=>e.realWidth>minShutterWidth).length < 0;
    }

    /**
     * Zwraca minimalną szerokość dla sterowania elektrycznego
     *
     * @returns {number} Minimalna szerokość dla sterowania elektrycznego
     */
    public getDriveElectricalMinWidth() {
        const currentReelId = this.configurationsService.conf.Current.RollerShutter.roundReel.id;
        return (this.configuratorsDataService.data.rollerShutterDrives || []).filter(d => d.type !== 'manual').reduce((prev, cur) => {
            const minFrom = Common.isArray(cur.prices)
                ? cur.prices.reduce((p, c) => (c.reel === currentReelId || !c.reel) && (!p || parseFloat(c.from) < p) ? parseFloat(c.from) : p, Infinity)
                : 0;
            return !prev || minFrom < prev ? minFrom : prev;
        }, Infinity);
    }

    public checkOverride(override) {
        const roller = this.configurationsService.conf.Current.RollerShutter;
        return roller.drive.prices.some(p =>
            (!p.from || roller.shutters.every(el => parseFloat(p.from) <= el.realWidth))
            && (!p.height || roller.shutters.every(el => parseFloat(p.height) >= el.realHeight))
            && (override === 'override' && Boolean(Number(p.override)) || override === 'none' && !Boolean(Number(p.override)))
            && (!p.to || roller.shutters.every(el =>
                parseFloat(p.to) >= this.parametersService.getRollerWeight(roller, el)))
            && (!p.reel || Number(p.reel) === Number(roller.roundReel?.id))
        );
    }

    public changeOpeningLock(value: boolean) {
        this.configurationsService.conf.Current.RollerShutter.openingLock = value;
        this.eventBusService.post({
            key: 'changedDrive',
            value: null,
        });
    }

    public getDriveOutputSide() {
        const drive = this.configurationsService.conf.Current.RollerShutter.drive;
        const driveManual = this.configurationsService.conf.Current.RollerShutter.driveManual;
        if (drive) {
            if (drive.type === 'manual' && driveManual) {
                return driveManual.drive_output_sides;
            } else {
                return drive.drive_output_sides;
            }
        }
    }

    /**
     * Ustawia wieszaki automatycznie
     */
    public updateDriveOutputSide() {
        const sides = this.getDriveOutputSide();
        this.driveOutputSides$.next(sides);
        if (sides && !sides.includes(this.configurationsService.conf.Current.RollerShutter.driveOutputSide)) {
            this.changeDriveOutputSide(sides[0]);
        }
    }

    public changeDriveOutputSide(side: 'rear-up' | 'rear-down' | 'front-up' | 'front-down' | 'side') {
        this.configurationsService.conf.Current.RollerShutter.driveOutputSide = side;
        this.priceService.count();
        this.eventBusService.post({
            key: 'changedDrive',
            value: null,
        });
    }

    public getHangers(blockade?) {
        const drive = this.configurationsService.conf.Current.RollerShutter.drive;
        const driveManual = this.configurationsService.conf.Current.RollerShutter.driveManual || core.copy(this.driveManuals[0]);
        const hangerDriverType = drive.type === 'manual' ? 'drivesManual' : 'drives';
        const driveId = drive.type === 'manual' && driveManual ? driveManual.id : drive.id;
        const hangers = this.hangers.filter((hanger: Hanger) => hanger[hangerDriverType].includes(driveId));
        if(!Common.isUndefined(blockade)) {
            return hangers.filter(hanger => hanger && hanger.blockade === blockade)
        }
        return hangers
    }

    setHanger(blockade?) {
        const hanger = this.getHangers(blockade).shift();
        this.configurationsService.conf.Current.RollerShutter.hanger = hanger;
        if(hanger) {
            this.changeOpeningLock(hanger.blockade);
        }
    }
}
