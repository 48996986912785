export default class OptionsModalController {
    options;
    close: ({ $value }) => any;
    dismiss: () => any;

    constructor(
        private ScrollbarService,
        private $translate,
        public IccConfig,
    ) {
        this.options = {
            system: {
                name: this.$translate.instant('OFFER|Linii produktowej'),
                icon: 'apps',
            },
            color: {
                name: this.$translate.instant('OFFER|Kolorystyki'),
                icon: 'palette',
            },
            filling: {
                name: this.$translate.instant('OFFER|Szklenia'),
                class: 'icc-icons icc-icons-glazing',
            },
        }
    }

    $onChanges() {
        this.ScrollbarService.update();
    }

    closeModal() {
        this.close({ $value: false });
    }

    selectOption(option) {
        this.close({ $value: option });
    }

}
