import { Component, OnInit, Inject } from '@angular/core';
import { _, StepComponent, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';

@Component({
    selector: 'icc-muntins-info',
    templateUrl: './muntins-info.component.html',
    styleUrls: ['./muntins-info.component.scss'],
})
export class MuntinsInfoComponent extends StepComponent implements OnInit {
    public configurator = 'window';
    public stepId = 'muntins_types';
    public title = _('WINDOW|Informacja na temat produktu');
    public options = [];

    type: any = null;
    button = true;
    descriptionFieldName: string | null = null;
    imageFieldName: string | null = null;
    imageDirectory: string | null = null;
    showImage = true;

    constructor(
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA) private pageData: {
            type: any,
            button: boolean,
            descriptionFieldName: string,
            imageFieldName: string,
            imageDirectory: string
            showImage: boolean;
        }
    ) {
        super();
    }

    ngOnInit() {
        this.type = this.pageData.type || {};
        this.button = this.pageData.button;
        this.descriptionFieldName = this.pageData.descriptionFieldName || 'content';
        this.imageFieldName = this.pageData.imageFieldName || 'title_image';
        this.imageDirectory = this.pageData.imageDirectory || 'windowline';
        this.showImage = this.pageData.showImage ?? true;
    }

    close() {
        this.sharedFacade.closePage(true);
    }
}
