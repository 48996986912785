import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import {Injectable, Inject} from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { Common } from '@icc/common/Common';


@Injectable()
export class PriceOuterDoorService {

    constructor(@Inject(APP_CONFIG) private config: AppConfigFactory) {}

    /**
     * Dodawanie dopłaty do drzwi otwieranych na zewnątrz
     * @param  {Number} price         Cena
     * @param  {Array} PriceElems     Składowe ceny
     * @param  {Object} model         Model
     * @param  {Object} colors        Kolory
     * @param  {Number} sashesCount   Liczba skrzydeł
     * @param  {Object} NoPriceCauses Powody braku ceny
     * @return {Number}               Cena
     */
    @PriceFunc({
        shortName: 'outerDoor',
        data: {
            system: 'conf.System',
            sashes: 'conf.Sashes',
        }
    })
    suppOuterDoor({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {system, sashes}): PriceSegment[] {
        if (this.config().IccConfig.Configurators.outerDoorSurcharge
            && Common.isObject(system)
            && Common.isDefined(system['outer_door_surcharge'])
            && parseFloat(system['outer_door_surcharge']) > 0
            && sashes.some(sash => sash.type.type === 'DOA')
        ) {
            const sashesCount = sashes.filter(sash => sash.type.type !== 'F').length;
            const supp = parseFloat(system['outer_door_surcharge']) * sashesCount;
            return <PriceSegment[]>[{
                type: 'outerDoor',
                baseValue: supp,
                value: supp,
                valueType: 'value',
                data: {
                    id   : system.id,
                    name : system.name,
                    count: sashesCount,
                }
            }];
        }

        return [];
    }
}

