export default function DealerOnlineCtrl($scope, $http, $rootScope, $timeout, $filter, EnvConfig) {
	'ngInject';
    // edycja listy zamowien - filtry daty
    $scope.openFrom = function($event) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.isOpenFrom = true;
        $scope.isOpenTo   = false;
    };

    $scope.openTo = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.isOpenFrom = false;
        $scope.isOpenTo   = true;
    };

    $scope.Date = function(arg) {
        return new Date(arg);
    };

    $scope.dateOptions = {
        startingDay: 1,
        showWeeks  : false
    };

    $scope.updateTaxRate = function() {
        $http
            .post(`${EnvConfig.remoteHost || window.location.origin}/dealer/offers/change_tax_rate`, {
                tax_rate_id: $scope.selectedTaxRate,
                id         : $scope.id
            }).then(function() {
                $scope.showInfo($filter('translate')('OFFER|Stawka VAT została zaktualizowana.'), null, null, 5000);
            }).catch(function() {
                $scope.showInfo($filter('translate')('OFFER|Nastąpił błąd podczas zapisu!'), null, null, 5000);
            });
    };
}
