import {core} from '../helpers';
import { Common } from '../Common';
import { OfferKeyService } from './OfferKeyService';
import { OfferDiscountsService } from './OfferDiscountsService';
import format from 'date-fns/format';
import { IIccConfig } from '@icc/config';

/**
 * Konstruktor Oferty
 * @param {object} param          dane oferty
 * @param {object} client         dane klienta
 * @param {object} prices         ceny
 * @param {array}  offerDiscounts Rabaty
 */
export default function Offer({param, client, prices = {}, offerDiscounts = {}, user, dealer, dealerh, multipliers = {}, IccConfig}: {
    param: any, client: any, prices?: any, offerDiscounts?: any, user: any, dealer: any, dealerh: any, multipliers?: any, IccConfig: IIccConfig,
}) {
    if (Common.isUndefined(client)) {
        client = {};
    }

    this.id                    = null;
    this.tmp_id                = 'tmp_offer_' + core.generateUUID();
    this.name                  = param.name || ((user.name || '') + ' ' + (user.surname || ''));
    this.client_id             = client.tmp_id || null;

    this.lang                  = param.lang;
    this.dealer_id             = param.dealer_id || user.dealerid || null;
    this.dealer_seller_id      = param.dealer_seller_id || user.dealersellerid || null;
    this.seller_id             = param.seller_id || user.sellerid || null;
    this.seller_offer          = param.seller_offer || null;
    this.client_offer          = param.client_offer || 0;
    this.shop_order            = param.shop_order || 0;
    this.custom_title          = param.custom_title || '';
    this.description           = param.description || '';
    this.offer_name            = param.offer_name || '';
    this.assigned              = user.id || null;
    this.number_items          = param.number_items || 0;
    this.status                = param.status || '1';
    this.dealer_status         = param.dealer_status !== null && Common.isDefined(param.dealer_status) ? param.dealer_status : '1';
    this.order                 = param.order || 0;
    this.offer                 = param.offer || 0;
    this.b2c_offer_source      = param.b2c_offer_source;

    if (param.currency && Common.isString(param.currency)) {
        this.currency = JSON.parse(param.currency);
        this.currency.pricesInCurrency = param.overridePricesInCurrency != null ? param.overridePricesInCurrency : this.currency.pricesInCurrency;
    } else if (param.currency && Common.isObject(param.currency)) {
        this.currency = param.currency;
        this.currency.pricesInCurrency = param.overridePricesInCurrency != null ? param.overridePricesInCurrency : this.currency.pricesInCurrency;
    } else {
        this.currency = prices.currencies[prices.markets[user.market || 'P'].currency];
        this.currency.pricesInCurrency = param.overridePricesInCurrency != null ? param.overridePricesInCurrency : true;
    }
    this.currency = core.stringJson(this.currency);

    // this.tax_rate_id           = (user.access == 'klient' || !user.access) ? prices.markets[user.market || 'P'].tax_rate_id : 0;
    this.tax_rate_id           = (user.access === 'klient' || !user.access)
                                ? (Common.isArray(prices.taxRates) && Common.isDefined(prices.taxRates[0]) ? prices.taxRates[0].id : 0)
                                : param.tax_rate_id;
    this.market_id             = param.market_id || (prices && prices.markets && prices.markets[user.market || 'P'] && prices.markets[user.market || 'P'].id) || null;
    this.wrong_market_data     = param.wrong_market_data || false;
    this.market_factor         = param.market_factor || prices.markets[user.market || 'P'].factor || 1;
    this.created               = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    this.original_offer_date   = param.original_offer_date || null;
    // this.modified              = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    this.copied_offer          = param.copied_offer || null;
    this.circuit               = param.circuit || 0;
    this.shutter_circuit        = param.shutter_circuit || 0;
    this.door_circuit           = param.door_circuit || 0;
    this.window_circuit         = param.window_circuit || 0;
    this.glazing_area          = param.glazing_area || 0;
    this.weight                = param.weight || 0;
    this.weight_positions_quantity = param.weight_positions_quantity || 0;
    this.quantity              = param.quantity || 0;
    this.sequence              = param.sequence && typeof param.sequence === 'object'
                                ? core.stringJson(param.sequence)
                                : (param.sequence ? param.sequence : '[]');
    this.summary               = param.summary && typeof param.summary === 'object'
                                ? core.stringJson(param.summary)
                                : (param.summary ? param.summary : '{}');

    this.montages              = param.montages && typeof param.montages === 'object'
                                ? core.stringJson(param.montages)
                                : (param.montages ? param.montages : '{"montage" : false}');
    this.measure_price          = param.measure_price || 0;

    this.positions_groups_data = param.positions_groups_data && typeof param.positions_groups_data === 'object'
                                ? (param.positions_groups_data)
                                : core.parseJson(param.positions_groups_data ? param.positions_groups_data : '{}');
    this.parent_id             = param.parent_id || null;
    this.no_price              = Common.isDefined(param.no_price) ? param.no_price : ((user.access == 'klient' || !user.access) && prices.no_price);

    this.dimension_unit        = param.dimension_unit || null;
    this.number_of_places_after_decimal_separator = param.number_of_places_after_decimal_separator;
    this.weight_unit           = param.weight_unit || null;
    this.delivery_address      = param.delivery_address;
    this.dealer_advance        = param.dealer_advance;
    this.version               = param.version || 0;
    this.number                = param.number || 0;
    this.enova                 = param.enova || '0000';
    this.key                   = param.key && typeof param.key === 'object' && !param.version
                                ? core.stringJson(param.key)
                                : core.stringJson(OfferKeyService.keyGen({
                                    order: this.order,
                                    user,
                                    client,
                                    dealerId: this.dealer_id,
                                    number: param.ord_number,
                                    version: this.version,
                                    enova: this.enova,
                                    offerNumber: param.offers_number,
                                    offerVersion: param.offers_version,
                                    subOffer: !~~param.order && param.parent_id && param.version,
                                    originalOfferDate: param.original_offer_date,
                                    b2cOfferScheme: this.client_offer,
                                    def: {
                                        O_ALL   : param.offers_key,
                                        K_DEALER: param.dealer_code,
                                        K_CLIENT: param.client_code,
                                    },
                                    b2cOfferSource: param.b2c_offer_source,
                                    offlineNumber: false,
                                    customKeyValue: param.custom_key_value,
                                }).key);
    this.base_discounts        = core.stringJson(OfferDiscountsService.groupDiscounts(core.copy(param.group_discounts) || offerDiscounts || [], param.dealer_price_before_discount || 0, param.client_price_before_discount || 0, user, this, IccConfig, false, true).discounts);
    this.group_discounts       = this.base_discounts;
    multipliers = multipliers && multipliers[this.dealerId] || multipliers;
    this.multipliers           = param.multipliers || multipliers;
    this.changed_positions     = '{}';
    this.history               = param.history && param.history.length > 0 ? param.history : [{
                                    date  : this.created,
                                    user  : user?.name ? user.name + ' ' + user.surname : '',
                                    note  : '',
                                    changes: [{
                                        type: 'status',
                                        oldStatus: null,
                                        status: this.status,
                                    }]
                                }];

    /* suma cen sprzedaży dla klienta */
    this.client_montages_price_before_discount =  param.client_montages_price_before_discount || 0;
    this.client_price_before_discount_position =  param.client_price_before_discount_position || 0;
    this.client_price_before_discount =  param.client_price_before_discount || 0;
    this.client_price_before_discount_dealer = param.client_price_before_discount_dealer || 0;
    this.client_price =  param.client_price || 0;

    /* suma cen sprzedaży dla klienta */
    this.dealer_client_price_before_discount_position =  param.dealer_client_price_before_discount_position || 0;
    this.dealer_client_price_before_discount =  param.dealer_client_price_before_discount || 0;
    this.dealer_client_price_before_discount_dealer = param.dealer_client_price_before_discount_dealer || 0;
    this.dealer_client_price =  param.dealer_client_price || 0;

    /* suma cen zakupu dla klienta */
    this.dealer_producer_montages_price_before_discount =  param.dealer_producer_montages_price_before_discount || 0;
    this.dealer_montages_price_before_discount =  param.dealer_montages_price_before_discount || 0;
    this.dealer_price_before_discount_position =  param.dealer_price_before_discount_position || 0;
    this.dealer_price_before_discount =  param.dealer_price_before_discount || 0;
    this.dealer_price =  param.dealer_price || 0;

    this.transport_cost =  param.transport_cost || 0;
    this.client_transport_cost =  param.client_transport_cost || 0;
    this.payment_cost =  param.payment_cost || 0;

    this.time_limit                            = param.time_limit;
    this.transport_from_producent              = param.transport_from_producent || false;
    this.transport_from_producent_to_client    = param.transport_from_producent_to_client || false;
    this.split_transport_cost                  = param.split_transport_cost || false;
    this.client_split_transport_cost           = param.client_split_transport_cost || false;
    this.transport_cost_type_id                = param.transport_cost_type_id || null;
    this.transport_cost_type                   = param.transport_cost_type || null;
    this.point_of_service                      = param.point_of_service || null;
    this.unmatched_oversize_rules              = param.unmatched_oversize_rules || null;
    this.transport_costs                       = param.transport_costs || null;

    this.dealer_currency                       = param.dealer_currency || null;
	this.dealer_currency_exchange_rate         = param.dealer_currency_exchange_rate || null;

    this.machine = param.machine || core.generateUUID();
    this.positions_tax_rates                   = param.positions_tax_rates && typeof param.positions_tax_rates === 'object'
                                                ? (param.positions_tax_rates)
                                                : core.parseJson(param.positions_tax_rates ? param.positions_tax_rates : '{}');

    this.chance_id = param.chance_id || null;
    this.country_id = param.country_id || null;
    this.postal_code = param.postal_code || null;
    this.external_order_information = param.external_order_information && typeof param.external_order_information === 'object' ?
                                        core.stringJson(param.external_order_information) : '{}';

    this.confirm = param.confirm;

    OfferDiscountsService.setDefaultDiscounts(this, param, user, dealer, IccConfig, dealerh);

}


export function parseOffer(offer) {
    const decimalColumns = [
        'dealer_price_before_discount_position',
        'dealer_price_before_discount',
        'dealer_price',
        'client_price_before_discount_position',
        'client_price_before_discount',
        'client_price_before_discount_dealer',
        'client_price',
        'dealer_client_price_before_discount_position',
        'dealer_client_price_before_discount',
        'dealer_client_price_before_discount_dealer',
        'dealer_client_price',
        'dealer_producer_price_before_discount_position',
        'dealer_producer_price_before_discount',
        'dealer_producer_price',
        'client_producer_price_before_discount_position',
        'client_producer_price_before_discount',
        'client_producer_price',
        'dealer_discount_producer_special',
        'dealer_discount_producer',
        'client_discount_position',
        'client_discount_special',
        'dealer_margin',
        'market_factor',
        'advance',
        'weight',
        'area',
        'glazing_area',
        'form_budget',
        'transport_cost',
        'client_transport_cost',
        'measure_price',
        'dealer_montages_price_before_discount',
        'dealer_producer_montages_price_before_discount',
        'client_montages_price_before_discount',
        'external_order_information',
        'payment_cost'
    ];
    Object.keys(offer).filter(key => decimalColumns.includes(key)).forEach(key => {
        offer[key] = Number(offer[key]);
    });

    if (Common.isString(offer.multipliers)) {
        offer.multipliers = core.parseJson(offer.multipliers);
    }
    return offer;
}
