import * as angular from 'angular';
import 'angular-ui-bootstrap';

/**
 * Kontroler modala parametrów Uw/Sw
 *
 * @export
 * @class ModalParametersCtrl
 */
export class ModalParametersCtrl {

    muntins = 0;
    sashes  = [];

    /**
     * Konstruktor modala.
     */
    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private ConfigurationsService,
        private ParametersService,
        private conf,
    ) {
        'ngInject';

        // konfiguracja pozycji
        this.conf = this.conf || ConfigurationsService.conf.Current;

        // rozbicie na kwatery
        this.conf.Sashes.sort((a, b) => a.index > b.index).forEach((sash) => {
            if (sash.intSashes.length > 0) {
                sash.intSashes.sort((a, b) => a.index > b.index).forEach((intSash) => {
                    this.muntins += intSash.muntins.length;

                    this.sashes.push({
                        data: ParametersService.getSashValues(this.conf, sash, intSash),
                        index: sash.index,
                        intIndex: intSash.index,
                        sash: intSash,
                    });
                });
            } else {
                this.muntins += sash.muntins.length;

                this.sashes.push({
                    data: ParametersService.getSashValues(this.conf, sash, sash),
                    index: sash.index,
                    sash: sash,
                });
            }
        });
    }

    /**
     * Zamknięcie modala.
     */
    close() {
        this.$uibModalInstance.close();
    }
}
