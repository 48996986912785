import {NgModule} from '@angular/core';
import { RollerDimensionsService } from './dimensions.service';
import { ColorsService } from './colors.service';
import { DrivesService } from './drives.service';
import { SchemasService } from './schemas.service';
import { TypesService } from './types.service';
import { DriveEngineService } from './drive-engine.service';
import { DimensionsValidatorDirective } from './dimensions-validator.directive';


@NgModule({
    imports: [
    ],
    exports: [
        DimensionsValidatorDirective
    ],
    declarations: [
        DimensionsValidatorDirective
    ],
    providers: [
        RollerDimensionsService,
        ColorsService,
        DrivesService,
        SchemasService,
        TypesService,
        DriveEngineService,
    ],
})
export class RollerShutterModule { }
