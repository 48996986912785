import { Directive, HostListener, ElementRef, Input, AfterViewInit, ChangeDetectorRef, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { SharedFacade } from '../+state/shared.facade';
import { Subscription } from 'rxjs';
import { DrawService } from '@icc/common/configurators/draw.service';

@Directive({
  selector: '[iccDrawResize]'
})
export class DrawResizeDirective implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  @Input() drawWidth = 1000;
  @Input() drawHeight = 1000;
  @Input() parentElement?: HTMLElement;

  private subscriptions: Subscription[] = [];

  constructor(private el: ElementRef, private cdRef: ChangeDetectorRef, private sharedFacade: SharedFacade, private drawService: DrawService) { }

  ngOnInit() {
    this.subscriptions.push(this.sharedFacade.previewAnimationDone$.subscribe(() => {
      this.onResize();
      this.cdRef.detectChanges();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnChanges() {
    this.resize();
  }

  @HostListener('window:resize', ['$event'])
    onResize(event?: any) {
      this.resize();
    }

  public resize() {
    if (this.parentElement) {
      const maxWidth = this.parentElement.offsetWidth - 50;
      const maxHeight = this.parentElement.offsetHeight - 50;
      const windowWidth = this.drawWidth + 100;
      const windowHeight = this.drawHeight + 100;
      const width = (windowWidth / windowHeight < maxWidth / maxHeight ? maxHeight * windowWidth / windowHeight : maxWidth);
      this.el.nativeElement.style.width = width + 'px';
      this.drawService.details.timestamp = Date.now();
      this.cdRef.detectChanges();
    }
  }

    ngAfterViewInit() {
      this.onResize();
      this.cdRef.detectChanges();
    }

}
