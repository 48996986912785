import { GarageDoorDefaultsService } from './../+state/garage-door-defaults.service';
import { WicketDoorsService } from './../wicket-doors.service';
import { Subscription } from 'rxjs';
import { GarageDoorFacade } from './../+state/garage-door.facade';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { _, StepComponent } from '@icc/configurator/shared';
import { WicketDoorColorComponent } from '../wicket-door-color/wicket-door-color.component';
import { UnitConverterService } from '@icc/configurator/ui';

@Component({
  selector: 'icc-wicket-door',
  templateUrl: './wicket-door.component.html',
  styleUrls: ['./wicket-door.component.scss']
})
export class WicketDoorComponent extends StepComponent implements OnInit, OnDestroy {
  static stepName = _('STEPS|Furtka');
  static stepIcon = {
      ligature: 'transfer_within_a_station',
  };

  public options = [
    {
        title: _('GARAGEDOOR|Kolor furtki'),
        component: WicketDoorColorComponent,
        icon: {
            ligature: 'palette',
        },
        show: () => {
            return Boolean(this.wicketDoorsService.getSelectedWicketDoor());
        }
    }
  ];

  public configurator = 'garage_door';
  public stepId = 'wicket_door';
  public title = _('GARAGE|Wybierz furtkę');

  private subscriptions: Subscription[] = [];

  filteredWicketDoors = [];
  selectedWicketDoor;
  selectedWicketDoor$ = this.garageDoorFacade.selectedWicketDoor$;
  selectedWicketDoorDirection$ = this.garageDoorFacade.selectedWicketDoorDirection$;
  selectedWicketDoorFrameProfilesColor$ = this.garageDoorFacade.selectedWicketDoorFrameProfilesColor$;
  selectedWicketDoorHandleColor$ = this.garageDoorFacade.selectedWicketDoorHandleColor$;

  wicketDoorMiddleXPosition;
  wicketDoorDisabled = true;

  conf;

  dimensionUnit = this.unitConverterService.getUnit();

  constructor(
    private garageDoorFacade: GarageDoorFacade,
    private defaultsService: GarageDoorDefaultsService,
    protected wicketDoorsService: WicketDoorsService,
    private unitConverterService: UnitConverterService
  ) {
    super();
  }

  ngOnInit() {
    this.selectedWicketDoor$.subscribe(w => {
        this.wicketDoorMiddleXPosition = w && w.x + (w.width / 2);
    })
    this.wicketDoorDisabled = !this.wicketDoorsService.getSelectedWicketDoor();
    this.filteredWicketDoors = this.wicketDoorsService.getFilteredWicketDoors();

    if (this.filteredWicketDoors.length === 0) {
        this.wicketDoorsService.registerNoWicketDoorsIssue();
    }
  }

  ngOnDestroy() {
    this.wicketDoorsService.unregisterNoWicketDoorsIssue();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  selectWicketDoor(selection) {
    const wicketDoor = selection && selection.value
    this.wicketDoorsService.setWicketDoor(wicketDoor);
  }

  selectWicketDoorDirection(direction: 'left' | 'right') {
    this.wicketDoorsService.setWicketDoorDirection(direction);
  }

  moveWicketDoorLeft() {
    this.wicketDoorsService.moveWicketDoorLeft();
  }

  moveWicketDoorRight() {
      this.wicketDoorsService.moveWicketDoorRight();
  }

  canMoveWicketDoorLeft() {
      return this.wicketDoorsService.canMoveWicketDoorLeft();
  }

  canMoveWicketDoorRight() {
      return this.wicketDoorsService.canMoveWicketDoorRight();
  }

  disableAndUnsetWicketDoor(disable: boolean) {
    if (!disable) {
        this.wicketDoorsService.setDefaultWicketDoorValues();
    } else {
        this.wicketDoorsService.disableAndUnsetWicketDoor();
    }
  }

  getDefaultWicketDoorShift(): number {
    return this.defaultsService.getWicketDoorShift();
  }

  getMinWicketDoorOffset(): number {
      return this.defaultsService.getMinWicketDoorOffset();
  }
}
