export default function ModalConfirmCtrl($uibModalInstance, title, question, options) {
    'ngInject';

    var vm = this;
    vm.close = close;
    vm.title = title;
    vm.question = question;
    vm.options = options;

    /**
     * Zamyka okno modalne
     * @param  {boolean} result Potwierdzona operacja lub nie
     */
    function close(result) {
        $uibModalInstance.close(result);
    }
}
