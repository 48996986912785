import { core } from '@icc/common/Core';
import { IIccConfig } from '@icc/config';

export function weightUnitFormatter (value: number | string,  weightUnit: string, round: number,  config: IIccConfig) {
    let weight = Number(value);
    let separator = ',';

    if(weightUnit === 'lb') {
        weight = weight * config.Configurators.kgToPounds;
        separator = '.';
    }

    if (round === 0) {
        weight = core.formatNumber(weight, round, 100);
    } else if (round > 0) {
        weight = core.formatNumber(weight, round, 100, ' ', separator);
    }

    return weight + ` ${weightUnit}`;
}
