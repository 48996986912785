import angular from 'angular';

/**
 * Fabryka elementów
 * @param {Object} $rootScope                 rootScope
 * @param {Object} $http                      http
 * @param {Object} ConfigurationsService      ConfigurationsService
 * @param {Object} ElementsAccessoriesFactory ElementsAccessoriesFactory
 * @param {Object} Core                       Core
 */
export default function ElementsFactory($rootScope, $http, ConfigurationsService, ElementsAccessoriesFactory, Core, EnvConfig) {
    'ngInject';

    var conf = ConfigurationsService.conf === undefined ? {} : ConfigurationsService.conf.Current;
    /**
     * Obiekt przechowujcy elementy dla pojedynczej konfiguracji
     *
     * window - elementy dla całej konstrukcji
     * dividers - elementy dla słupków i poprzeczek. osobna tablica dla każdego słupka / poprzeczki
     * sashes - elementy dla skrzydeł. osobna tablica dla każdego skrzydła
     * frame - elementy dla ramy
     */
    var elements = {};

    /**
     * Obiekt przechowujcy liste materiałów
     *
     */
    var materialsList = {};

    /**
     * Obiekt przechowujcy elementy dla zamówień
     *
     * id zamówienia -> id pozycji -> elementy dla danej pozycji
     */
    var factory = {
        ordersPositionsElements: {},
        calculateElements: calculateElements,
        getElements: getElements,
        calculateElementsForOrder: calculateElementsForOrder,
        calculateElementsForSelectedOrders: calculateElementsForSelectedOrders,
        calculateElementsForFrame: calculateElementsForFrame,
        calculateElementsForDividers: calculateElementsForDividers,
        calculateElementsForSashes: calculateElementsForSashes,
        currentWindowHasPSKSashes: currentWindowHasPSKSashes,
        calculateElementsForWindow: calculateElementsForWindow,
        initOrdersPositionsElements: initOrdersPositionsElements,
        initElements: initElements
    };

    return factory;
    /*
     * Inicjuje elementy dla pojedynczej konfiguracji
     */
    function initElements() {
        elements = {
            window: [],
            dividers: [],
            sashes: [],
            frame: [],
        };
        angular.forEach(conf.Sashes, function(sash, key) {
            elements.sashes.push([]);
        });
        angular.forEach(conf.Mullions, function(divider, key) {
            elements.dividers.push([]);
        });
    }


    /*
     * Inicjuje tablice elementow dla poszczegolnych pozycji
     */
    function initOrdersPositionsElements() {

        factory.ordersPositionsElements = {};
    }

    /**
     * Zamień wszystkie
     * @param  {String} str     Ciag znaków
     * @param  {Object} find    Znalezione
     * @param  {Object} replace Zamienion
     * @return {Object}         Zamieniony cig znaków
     */
    function replaceAll(str, find, replace) {
      return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    }

    /**
     * Ucieczka wyrażenia regularnego
     * @param  {String} str Ciag znaków
     * @return {String}     Ciag znaków
     */
    function escapeRegExp(str) {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    }

    /**
     * Zwraca true jeśli aktualna konfiguracja zawiera skrzydła PSK i false w przeciwnym wypadku
     *
     * @returns boolean
     */
    function currentWindowHasPSKSashes() {
        angular.forEach(conf.Sashes, function(sash, key) {
            if (sash.type.type == 'PSK') {
                return true;
            }
        });
        return false;
    }

    /**
    * Oblicza długość elementu na podstawie wzoru
    * @param  {String} formula Formula
    * @return {Object}         Formula
    */
    function calculateFormula(formula) {
        // Wysokość konstrukcji
        if (formula.search('{conf.Height}') != -1) {

            formula = replaceAll(formula, '{conf.Height}', conf.Height);
        }

        // Szerokość konstrukcji
        if (formula.search('{conf.Width}') != -1) {

            formula = replaceAll(formula, '{conf.Width}', conf.Width);
        }

        // Szerokość listwy przyszybowej w skrzydle
        if (formula.search('{System.width_glazing_bar_sash}') != -1) {

            formula = replaceAll(formula, '{System.width_glazing_bar_sash}', Number(conf.System.width_glazing_bar_sash));
        }

        // Szerokość listwy przyszybowej w skrzydle
        if (formula.search('{System.width_glazing_bar_fixed_glazing}') != -1) {

            formula = replaceAll(formula, '{System.width_glazing_bar_fixed_glazing}', Number(conf.System.width_glazing_bar_fixed_glazing));
        }

        // Szerokość ramy skrzydła
        if (formula.search('{System.width_sash_frame_inside}') != -1) {

            formula = replaceAll(formula, '{System.width_sash_frame_inside}', Number(conf.System.width_sash_frame_inside));
        }

        // Naddatek do listew przyszybowych
        if (formula.search('{System.allowance_glazing_beads}') != -1) {

            formula = replaceAll(formula, '{System.allowance_glazing_beads}', Number(conf.System.allowance_glazing_beads));
        }

        return eval(formula);
    }

   /**
    * Oblicza długość elementu dla skrzydeł
    * @param  {String} formula Formula
    * @param  {Object} sash    Skrzydlo
    * @return {String}         Formula
    */
    function calculateFormulaForSashes(formula, sash) {

        var length;
        var leftSide;
        var rightSide;

        // Szerokość szklenia
        if (formula.search('{sash.glazingSizes.width}') != -1) {

            formula = replaceAll(formula, '{sash.glazingSizes.width}', sash.glazingSizes.width);
        }

        // Wysokość szklenia
        if (formula.search('{sash.glazingSizes.height}') != -1) {

            formula = replaceAll(formula, '{sash.glazingSizes.height}', sash.glazingSizes.height);
        }

        // Szerokość całego skrzydła
        if (formula.search('{sash.rWidth}') != -1) {

            formula = replaceAll(formula, '{sash.rWidth}', sash.rWidth);
        }

        // Wysokość całego skrzydła
        if (formula.search('{sash.rHeight}') != -1) {

            formula = replaceAll(formula, '{sash.rHeight}', sash.rHeight);
        }

        // Szerokość skrzydła wewnątrz
        if (formula.search('{sash.widthInside}') != -1) {

            length = sash.rWidth;

            if (sash.nearMullions.right == -1) {
                length = length - Number(conf.System.width_window_frame_inside) + Number(conf.System.width_common_part_frame_sash);
            } else {
                if (sash.type.symbol == 'DS(L)') {
                    length = length - Number(conf.System.width_between_sashes_movable_divider_inside) / 2;
                } else {
                    rightSide = conf.Mullions.filter(function(e) {
                        return e.id == sash.nearMullions.right;
                    });
                    rightSide = angular.isDefined(rightSide[0]) && angular.isDefined(rightSide[0].multiAlignLeft) && angular.isDefined(rightSide[0].multiAlignLeft[0])
                              ? rightSide[0].multiAlignLeft[0] : {type: {}};

                    if (rightSide.type.type == 'DS' || rightSide.type.type == 'DSC') {
                        length = length - Number(conf.System.width_between_sashes_movable_divider_inside) / 2;
                    } else {
                        length = length - Number(conf.System.width_window_frame_inside) / 2 + Number(conf.System.width_common_part_frame_sash);
                    }
                }
            }
            if (sash.nearMullions.left == -1) {
                length = length - Number(conf.System.width_window_frame_inside) + Number(conf.System.width_common_part_frame_sash);
            } else {
                if (sash.type.symbol == 'DS(R)') {
                    length = length - Number(conf.System.width_between_sashes_movable_divider_inside) / 2;
                } else {
                    leftSide = conf.Mullions.filter(function(e) {
                        return e.id == sash.nearMullions.left;
                    });
                    leftSide = angular.isDefined(leftSide[0]) && angular.isDefined(leftSide[0].multiAlignLeft) && angular.isDefined(leftSide[0].multiAlignLeft[0])
                             ? leftSide[0].multiAlignLeft[0] : {type: {}};

                    if (leftSide.type.type == 'DS' || leftSide.type.type == 'DSC') {
                        length = length - Number(conf.System.width_between_sashes_movable_divider_inside) / 2;
                    } else {
                        length = length - Number(conf.System.width_window_frame_inside) / 2 + Number(conf.System.width_common_part_frame_sash);
                    }
                }
            }

            formula = replaceAll(formula, '{sash.widthInside}', length);
        }

        // Szerokość skrzydła zewnątrz
        if (formula.search('{sash.widthOutside}') != -1) {

            length = sash.rWidth;

            if (sash.nearMullions.right == -1) {
                length = length - Number(conf.System.width_window_frame_outside) - Number(conf.System.width_margin_to_drip);
            } else {
                if (sash.type.symbol == 'DS(L)') {
                    length = length - Number(conf.System.width_movable_divider_outside) / 2 - Number(conf.System.width_margin_to_drip);
                } else {
                    rightSide = conf.Mullions.filter(function(e) {
                        return e.id == sash.nearMullions.right;
                    });
                    rightSide = angular.isDefined(rightSide[0]) && angular.isDefined(rightSide[0].multiAlignLeft) && angular.isDefined(rightSide[0].multiAlignLeft[0])
                              ? rightSide[0].multiAlignLeft[0] : {type: {}};

                    if (rightSide.type.type == 'DS' || rightSide.type.type == 'DSC') {
                        length = length - Number(conf.System.width_movable_divider_outside) / 2 - Number(conf.System.width_margin_to_drip);
                    } else {
                        length = length - Number(conf.System.width_divider_outside) / 2 - Number(conf.System.width_margin_to_drip);
                    }
                }
            }
            if (sash.nearMullions.left == -1) {
                length = length - Number(conf.System.width_window_frame_outside) - Number(conf.System.width_margin_to_drip);
            } else {
                if (sash.type.symbol == 'DS(R)') {
                    length = length - Number(conf.System.width_movable_divider_outside) / 2 - Number(conf.System.width_margin_to_drip);
                } else {
                    leftSide = conf.Mullions.filter(function(e) {
                        return e.id == sash.nearMullions.left;
                    });
                    leftSide = angular.isDefined(leftSide[0]) && angular.isDefined(leftSide[0].multiAlignLeft) && angular.isDefined(leftSide[0].multiAlignLeft[0])
                             ? leftSide[0].multiAlignLeft[0] : {type: {}};

                    if (leftSide.type.type == 'DS' || leftSide.type.type == 'DSC') {
                        length = length - Number(conf.System.width_movable_divider_outside) / 2 - Number(conf.System.width_margin_to_drip);
                    } else {
                        length = length - Number(conf.System.width_divider_outside) / 2 - Number(conf.System.width_margin_to_drip);
                    }
                }
            }

            formula = replaceAll(formula, '{sash.widthOutside}', length);
        }

        // Wysokość skrzydła wewnątrz
        if (formula.search('{sash.heightInside}') != -1) {

            length = sash.rHeight;

            if (sash.nearMullions.top == -1) {
                length = length - Number(conf.System.width_window_frame_inside) + Number(conf.System.width_common_part_frame_sash);
            } else {
                length = length - Number(conf.System.width_window_frame_inside) / 2 + Number(conf.System.width_common_part_frame_sash);
            }
            if (sash.nearMullions.bottom == -1) {
                length = length - Number(conf.System.width_window_frame_inside) + Number(conf.System.width_common_part_frame_sash);
            } else {
                length = length - Number(conf.System.width_window_frame_inside) / 2 + Number(conf.System.width_common_part_frame_sash);
            }

            formula = replaceAll(formula, '{sash.heightInside}', length);
        }

        // Wysokość skrzydła zewnątrz
        if (formula.search('{sash.heightOutside}') != -1) {

            length = sash.rHeight;

            if (sash.nearMullions.top == -1) {
                length = length - Number(conf.System.width_window_frame_outside) - Number(conf.System.width_margin_to_drip);
            } else {
                length = length - Number(conf.System.width_divider_outside) / 2 - Number(conf.System.width_margin_to_drip);
            }
            if (sash.nearMullions.bottom == -1) {
                length = length - Number(conf.System.width_window_frame_outside) - Number(conf.System.width_margin_to_drip);
            } else {
                length = length - Number(conf.System.width_divider_outside) / 2 - Number(conf.System.width_margin_to_drip);
            }

            formula = replaceAll(formula, '{sash.heightOutside}', length);
        }

        // Szerokość stałego szklenia(bez ramy)
        if (formula.search('{sash.widthFixedGlazing}') != -1) {

            length = sash.rWidth;

            if (sash.nearMullions.right == -1) {
                length = length - Number(conf.System.width_window_frame_inside);
            } else {
                length = length - Number(conf.System.width_window_frame_inside) / 2;
            }
            if (sash.nearMullions.left == -1) {
                length = length - Number(conf.System.width_window_frame_inside);
            } else {
                length = length - Number(conf.System.width_window_frame_inside) / 2;
            }

            formula = replaceAll(formula, '{sash.widthFixedGlazing}', length);
        }

        // Wysokość stałego szklenia(bez ramy)
        if (formula.search('{sash.heightFixedGlazing}') != -1) {

            length = sash.rHeight;

            if (sash.nearMullions.top == -1) {
                length = length - Number(conf.System.width_window_frame_inside);
            } else {
                length = length - Number(conf.System.width_window_frame_inside) / 2;
            }
            if (sash.nearMullions.bottom == -1) {
                length = length - Number(conf.System.width_window_frame_inside);
            } else {
                length = length - Number(conf.System.width_window_frame_inside) / 2;
            }

            formula = replaceAll(formula, '{sash.heightFixedGlazing}', length);
        }

        return formula;
    }

    /**
    * Oblicza długość elementu dla słupków
    * @param  {String} formula Formula
    * @param  {Object} divider Podzial
    * @return {String}         Formula
    */
    function calculateFormulaForDividers(formula, divider) {
        if (formula.search('{divider.rHeight}') != -1) {
            var elementLength = divider.rHeight;

            //Czy divider jest przymocowany do ramy z górnej strony
            var dividerFixedToTopFrame = false;
            //Czy divider jest przymocowany do ramy z dolnej strony
            var dividerFixedToBottomFrame = false;
            angular.forEach(divider.multiAlignLeft, function(sash, sashKey) {
                if (sash.nearMullions.top == -1) {
                    dividerFixedToTopFrame = true;
                }
                if (sash.nearMullions.bottom == -1) {
                    dividerFixedToBottomFrame = true;
                }
            });
            angular.forEach(divider.multiAlignRight, function(sash, sashKey) {
                if (sash.nearMullions.top == -1) {
                    dividerFixedToTopFrame = true;
                }
                if (sash.nearMullions.bottom == -1) {
                    dividerFixedToBottomFrame = true;
                }
            });
            if (dividerFixedToTopFrame && dividerFixedToBottomFrame) {
                elementLength = elementLength - conf.System.width_window_frame_inside * 2;
            } else if (dividerFixedToTopFrame || dividerFixedToBottomFrame) {
                elementLength = elementLength - conf.System.width_window_frame_inside - conf.System.width_pane_frame_to_pane_frame / 2;
            } else if (!dividerFixedToTopFrame && !dividerFixedToBottomFrame) {
                elementLength = elementLength - conf.System.width_pane_frame_to_pane_frame;
            }

            formula = replaceAll(formula, '{divider.rHeight}', elementLength);
        }
        if (formula.search('{divider.rWidth}') != -1) {
            var elementLength = divider.rWidth;

            //Czy divider jest przymocowany do ramy z lewej strony
            var dividerFixedToLeftFrame = false;
            //Czy divider jest przymocowany do ramy z prawej strony
            var dividerFixedToRightFrame = false;
            angular.forEach(divider.multiAlignTop, function(sash, sashKey) {
                if (sash.nearMullions.left == -1) {
                    dividerFixedToLeftFrame = true;
                }
                if (sash.nearMullions.right == -1) {
                    dividerFixedToRightFrame = true;
                }
            });
            angular.forEach(divider.multiAlignBottom, function(sash, sashKey) {
                if (sash.nearMullions.left == -1) {
                    dividerFixedToLeftFrame = true;
                }
                if (sash.nearMullions.right == -1) {
                    dividerFixedToRightFrame = true;
                }
            });
            if (dividerFixedToLeftFrame && dividerFixedToRightFrame) {
                elementLength = elementLength - conf.System.width_window_frame_inside * 2;
            } else if (dividerFixedToLeftFrame || dividerFixedToRightFrame) {
                elementLength = elementLength - conf.System.width_window_frame_inside - conf.System.width_pane_frame_to_pane_frame / 2;
            } else if (!dividerFixedToLeftFrame && !dividerFixedToRightFrame) {
                elementLength = elementLength - conf.System.width_pane_frame_to_pane_frame;
            }

            formula = replaceAll(formula, '{divider.rWidth}', elementLength);
        }
        return formula;
    }
    /*
     * Ustawia elementy dla całego okna
     */
    function calculateElementsForWindow() {
        if (angular.isDefined(materialsList[conf.System.id]) && angular.isDefined(materialsList[conf.System.id].construction)) {
            angular.forEach(materialsList[conf.System.id].construction, function(row, key) {
                var length = row.has_length ? calculateFormula(row.formula) : 0;
                var element = {
                    code: row.code,
                    quantity: Number(row.quantity),
                    length: length,
                    price: length ? Core.round(Math.ceil(length) / 1000 * Number(row.price)) : Core.round(Number(row.price) * Number(row.quantity))
                };
                elements.window.push(element);
            });
        }
    }
    /*
     * Ustawia elementy dla ramy
     */
    function calculateElementsForFrame() {
        if (angular.isDefined(materialsList[conf.System.id]) && angular.isDefined(materialsList[conf.System.id].frame)) {
            angular.forEach(materialsList[conf.System.id].frame, function(row, key) {
                var length = row.has_length ? calculateFormula(row.formula) : 0;
                var element = {
                    code: row.code,
                    quantity: Number(row.quantity),
                    length: length,
                    price: length ? Core.round(Math.ceil(length) / 1000 * Number(row.price)) : Core.round(Number(row.price) * Number(row.quantity))
                };
                elements.frame.push(element);
            });
        }
    }
    /*
     * Ustawia elementy dla dividerów dzielących okno na sashe
     */
    function calculateElementsForDividers() {
        if (angular.isDefined(materialsList[conf.System.id])) {
            angular.forEach(conf.Mullions, function(divider, key) {
                if (divider.direction == 'vertical') {
                    if (
                        (divider.multiAlignLeft.length == 1 && divider.multiAlignRight.length == 1)
                        && (
                            ((divider.multiAlignLeft[0].type.type == 'DS' || divider.multiAlignLeft[0].type.type == 'DSC') && divider.multiAlignLeft[0].type.handle_position == 'R')
                            || ((divider.multiAlignRight[0].type.type == 'DS' || divider.multiAlignRight[0].type.type == 'DSC') && divider.multiAlignLeft[0].type.handle_position == 'L')
                        )
                    ) {
                        // Ruchomy słupek
                        if (angular.isDefined(materialsList[conf.System.id].movable_divider)) {
                            angular.forEach(materialsList[conf.System.id].movable_divider, function(row) {
                                var length = row.has_length ? calculateFormula(calculateFormulaForDividers(row.formula, divider)) : 0;
                                var element = {
                                    code: row.code,
                                    quantity: Number(row.quantity),
                                    length: length,
                                    price: length ? Core.round(Math.ceil(length) / 1000 * Number(row.price)) : Core.round(Number(row.price) * Number(row.quantity))
                                };
                                elements.dividers[key].push(element);
                            });
                        }
                    } else {
                        // Słupek
                        if (angular.isDefined(materialsList[conf.System.id].divider_vertical)) {
                            angular.forEach(materialsList[conf.System.id].divider_vertical, function(row) {
                                var length = row.has_length ? calculateFormula(calculateFormulaForDividers(row.formula, divider)) : 0;
                                var element = {
                                    code: row.code,
                                    quantity: Number(row.quantity),
                                    length: length,
                                    price: length ? Core.round(Math.ceil(length) / 1000 * Number(row.price)) : Core.round(Number(row.price) * Number(row.quantity))
                                };
                                elements.dividers[key].push(element);
                            });
                        }
                    }
                }
                // Poprzeczka
                if (divider.direction == 'horizontal') {
                    if (angular.isDefined(materialsList[conf.System.id].divider_horizontal)) {
                        angular.forEach(materialsList[conf.System.id].divider_horizontal, function(row) {
                            var length = row.has_length ? calculateFormula(calculateFormulaForDividers(row.formula, divider)) : 0;
                            var element = {
                                code: row.code,
                                quantity: Number(row.quantity),
                                length: length,
                                price: length ? Core.round(Math.ceil(length) / 1000 * Number(row.price)) : Core.round(Number(row.price) * Number(row.quantity))
                            };
                            elements.dividers[key].push(element);
                        });
                    }
                }
            });
        }
    }

    /*
     * Ustawia elementy dla sashy
     */
    function calculateElementsForSashes() {

        angular.forEach(conf.Sashes, function(sash, key) {
            if(angular.isDefined(materialsList) && angular.isDefined(materialsList[conf.System.id] && angular.isDefined(materialsList[conf.System.id]['sash_' + sash.type.type])
                && angular.isArray(materialsList[conf.System.id]['sash_' + sash.type.type]) && materialsList[conf.System.id]['sash_' + sash.type.type].length > 0)){

                angular.forEach(materialsList[conf.System.id]['sash_' + sash.type.type], function(row) {
                    var length = row.has_length ? calculateFormula(calculateFormulaForSashes(row.formula, sash)) : 0;
                    var element = {
                        code: row.code,
                        quantity: Number(row.quantity),
                        length: length,
                        price: length ? Core.round(Math.ceil(length) / 1000 * Number(row.price)) : Core.round(Number(row.price) * Number(row.quantity))
                    };
                    elements.sashes[key].push(element);
                });
            }
        });
    }

    /**
     * Inicjalizuje pusta tablice elementow, oblicza elementy dla pojedynczej wczytanej do fabryki konfiguracji (zmienna conf)
     * Zapisuje obliczone elementy w zmiennej elements
     */
    function calculateElements() {

        //liczymy elementy konstrukcyjne tylko dla okien
        if(conf.type != 'window'){
            return false;
        }

        initElements();
        calculateElementsForWindow();
        calculateElementsForFrame();
        calculateElementsForDividers();
        calculateElementsForSashes();
    }

    /**
     * Oblicza elementy dla wczytanej do fabryki konfiguracji (zmienna conf)
     * Zapisuje w zmiennej elements i je zwraca
     */
    function getElements() {

        calculateElements();

        return elements;
    }

    /**
     * Przelicza elementy dla pojedynczej pozycji danego zamówienia
     * Dodaje przeliczone elementy do zmiennej ordersPositionsElements
     *
     * @param int orderId                   id zamówienia
     * @param int positionId                id pozycji
     * @param {} positionConfiguration      obiekt z konfiguracja pozycji
     */
    function calculateElementsForOrdersPosition(orderId, positionId, positionConfiguration) {

        if(!(orderId in factory.ordersPositionsElements)){

            factory.ordersPositionsElements[orderId] = {};
        }

        if(!(positionId in factory.ordersPositionsElements[orderId])){

            factory.ordersPositionsElements[orderId][positionId] = {};
        }

        conf = positionConfiguration;

        calculateElements();
        factory.ordersPositionsElements[orderId][positionId] = elements;
    }

    /**
     * Oblicza elementy dla zamówienia o podanym ID
     * Ustawia je w zmiennej ordersPositionsElements
     *
     * @param int orderId      id zamówienia
     */
    function calculateElementsForOrder(orderId) {

        initOrdersPositionsElements();

        $http.post(`${EnvConfig.remoteHost || window.location.origin}/dealer/offers/get_offer_positions_configurations`, {orderId : orderId}).then(function(res) {

            $http.get(`${EnvConfig.remoteHost || window.location.origin}/material_order/materials_to_constructions/get_materials_list.json`).then(function(res2) {

                materialsList = res2.data.materialsList;

                angular.forEach(res.data, function(positionConfiguration, positionId) {

                    let configuration = JSON.parse(positionConfiguration);

                    if (typeof configurations !== 'object' || typeof configurations !== 'array'){

                        configuration = JSON.parse(configuration);
                    }

                    calculateElementsForOrdersPosition(orderId, positionId, configuration);
                });
            });

        }, 'json');
    }

    /**
     * Oblicza elementy dla ofert o podanych ID
     * Ustawia elementy w zmiennej ordersPositionsElements
     *
     * @param array ordersIds    tablica ID ofert
     */
    function calculateElementsForSelectedOrders(ordersIds){

        initOrdersPositionsElements();

        $http.post(`${EnvConfig.remoteHost || window.location.origin}/dealer/offers/get_offers_positions_configurations`, {orderIds : JSON.stringify(ordersIds)}).then(function(res) {

            $http.get(`${EnvConfig.remoteHost || window.location.origin}/material_order/materials_to_constructions/get_materials_list.json`).then(function(res2) {

                materialsList = res2.data.materialsList;

                angular.forEach(res.data, function(offerPositionsConfigurations, orderId) {

                    angular.forEach(offerPositionsConfigurations, function(positionConfiguration, positionId) {

                        let configuration = JSON.parse(positionConfiguration);

                        if (typeof configurations !== 'object' || typeof configurations !== 'array'){

                            configuration = JSON.parse(configuration);
                        }

                        calculateElementsForOrdersPosition(orderId, positionId, configuration);
                    });
                });
            });
        }, 'json');
    }
}
