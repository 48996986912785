'use strict';

/**
 * Przekazywanie prawidłowej wartości przez jQuery.val()
 * Obecnie tylko wymiary (dyrektywa 'icc-dimension-input')
 * W przyszłości również inne, np. waluty
 */
(function($) {
    const originalVal = $.fn.val;

    $.fn.val = function(value) {
        const element = this.get(0);

        if (!angular.isUndefined(element) && element.hasAttribute('icc-dimension-input')) {
            const model = angular.element(element).data('$ngModelController');

            if (!angular.isUndefined(model)) {
                if (!angular.isUndefined(value)) {
                    model.$setViewValue(value);
                }

                return model.$modelValue;
            }
        }

        return originalVal.apply(this, arguments);
    };
})(jQuery);
