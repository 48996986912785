import { Issue, IssueLevel } from './issue';
import { ActiveConfiguration } from '@icc/common/configurations/ActiveConfiguration';

export interface RegisterDataProblemOptions {
    level?: IssueLevel;
    logLevel?: IssueLevel;
    showMessage?: boolean;
    blockAddToOffer?: boolean;
    blockStepsAfter?: string | null;
    noPrice?: boolean;
    tags?: {
        [id: string]: string;
    };
    extra?: any;
}

export interface SimpleRegisterOptions {
    level?: IssueLevel;
    logLevel?: IssueLevel;
    showMessage?: boolean;
    blockAddToOffer?: boolean;
    blockStepsAfter?: string | null;
    noPrice?: boolean;
    tags?: {
        [id: string]: string;
    };
    extra?: any;
}

export abstract class IssuesService {
    abstract registerDataProblem(
        key: string,
        description: string,
        conf: ActiveConfiguration | null,
        options?: {
            level?: IssueLevel;
            showMessage?: boolean;
            blockAddToOffer?: boolean;
            blockStepsAfter?: string | null;
            noPrice?: boolean;
            tags?: {
                [id: string]: string;
            };
            extra?: any;
        }
    ): void;

    abstract showMessage(issue: Issue): void;
    abstract register(issue: Issue, conf: ActiveConfiguration | null): void;
    abstract unregister(issue: Issue | string, conf: ActiveConfiguration | null): void;
    abstract simpleRegister(
        key: string,
        description: string,
        message: string,
        conf: ActiveConfiguration | null,
        options?: {
            level?: IssueLevel;
            logLevel?: IssueLevel;
            showMessage?: boolean;
            blockAddToOffer?: boolean;
            blockStepsAfter?: string | null;
            noPrice?: boolean;
            tags?: {
                [id: string]: string;
            };
            extra?: any;
        }
    ): boolean;
    abstract addValidateFunction(validateFunction: (conf: ActiveConfiguration) => any): void;
    abstract validateAll(conf: ActiveConfiguration): void;

    isBlockedAddToOffer(conf: ActiveConfiguration) {
        return conf.Issues.some(issue => issue.blockAddToOffer);
    }
}
