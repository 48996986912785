import { Injectable } from '@angular/core';
import {TimeLimit, TimeLimitSegment} from './time-limit-functions';

@Injectable()
export class TimeLimitFittingService {

    /**
     * Pobiera termin realizacji okucia.
     *
     * @param {object} fitting   Okucie
     * @return {number} Termin realizacji okucia.
     */
    @TimeLimit({
        shortName: 'fitting',
        data: {
            fitting : 'conf.Fitting',
        }
    })
    fittingTimeLimit({timeLimits, fitting}): TimeLimitSegment[] {
        if (fitting == null || !fitting.id || !fitting.time_limit_id) {
            return null;
        }

        const timeLimitId = fitting.time_limit_id;

        return <TimeLimitSegment[]>[{
            type: 'fitting',
            value: timeLimits[timeLimitId],
            data: {
                id  : fitting.id,
                name: fitting.name,
            }
        }];
    }
}
