import { PleatedBlindInitService } from './+state/pleated-blind-init.service';
import { PLEATEDBLIND_FEATURE_KEY, pleatedBlindReducer, initialState as pleatedBlindInitialState } from './+state/pleated-blind.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PleatedBlindFacade } from './+state/pleated-blind.facade';
import { SharedModule } from '@icc/configurator/shared';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemsComponent } from './systems/systems.component';
import { DimensionsComponent } from './dimensions/dimensions.component';
import { VariantsComponent } from './variants/variants.component';
import { FabricsComponent } from './fabrics/fabrics.component';
import { DrivesComponent } from './drives/drives.component';
import { PleatedBlindEffects } from './+state/pleated-blind.effects';
import { ColorsPageComponent } from './colors-page/colors-page.component';
import { FabricsPageComponent } from './fabrics-page/fabrics-page.component';
import { ShapesComponent } from './shapes/shapes.component';
import { ModalTypesComponent } from './modal-types/modal-types.component';
import { ConfiguratorUiModule } from '@icc/configurator/ui';
import { ConfiguratorPagesModule } from '@icc/configurator/pages';

@NgModule({
    imports: [
        StoreModule.forFeature(PLEATEDBLIND_FEATURE_KEY, pleatedBlindReducer, {
            initialState: pleatedBlindInitialState,
        }),
        EffectsModule.forFeature([PleatedBlindEffects]),

        CommonModule,
        SharedModule,
        ConfiguratorUiModule,
        ConfiguratorPagesModule,
    ],
    providers: [PleatedBlindFacade, PleatedBlindInitService],
    declarations: [SystemsComponent, DimensionsComponent, VariantsComponent, FabricsComponent, DrivesComponent, ColorsPageComponent, FabricsPageComponent, ShapesComponent, ModalTypesComponent],
    exports: [SystemsComponent, DimensionsComponent, VariantsComponent, FabricsComponent, DrivesComponent],
    entryComponents: [SystemsComponent, DimensionsComponent, VariantsComponent, FabricsComponent, DrivesComponent, ColorsPageComponent, FabricsPageComponent, ShapesComponent, ModalTypesComponent],
})
export class ConfiguratorPleatedBlindModule {}
