import { Component, OnInit, Inject } from '@angular/core';
import { ConfigurationsService } from '@icc/common';
import { RollerShutterActiveConfiguration } from '@icc/common/configurations/RollerShutterActiveConfiguration';
import { PageComponent, _, ICC_PAGE_DATA, SharedFacade, ModalService } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { DriveEngineService } from '@icc/legacy/configurator/steps/roller_shutter/drive-engine.service';
import { TypeInfoComponent } from '../type-info/type-info.component';

@Component({
  selector: 'icc-drives-page',
  templateUrl: './drives-page.component.html',
  styleUrls: ['./drives-page.component.scss']
})
export class DrivesPageComponent extends PageComponent implements OnInit {
  public title = _('ROLLERSHUTTER|Wybierz rodzaj sterowania');

  drives: iccListItem[] = [];
  selectedDriveId = 0;

  constructor(
    @Inject(ICC_PAGE_DATA) private pageData: {
      drives: any[],
      selDrive: any,
      b2b: boolean
    },
    private modalService: ModalService,
    private sharedFacade: SharedFacade,
    private configurationsService: ConfigurationsService,
  ) {
    super();
  }
  
  ngOnInit() {
    this.loadDrives();
  }

  loadDrives(conf = this.configurationsService.conf.Current as RollerShutterActiveConfiguration){
    this.drives = this.pageData.drives.filter(drive=>{
      if (drive.prices && drive.prices.length > 0 || drive.type === "manual"){
        let availablePrices = [];
        if (drive.type !== "manual"){
          availablePrices = drive?.prices.filter(price=>{
            return Number(price.height) >= conf.Height + conf.RollerShutter.realBoxHeight && Number(price.from) <= conf.Width
          })
        }
        return availablePrices?.length > 0 || drive.type === "manual"
      }
    }).map(drive => ({
      id: Number(drive.id),
      title: drive.name,
      content: drive.description,
      imageUrl: '/files/rollershutterdrive/' + drive.img,
      img: drive.img,
      showInfo: true
    }));
    this.selectedDriveId = this.pageData.selDrive && Number(this.pageData.selDrive.id);
  }

  showInfo(item: iccListItem) {
    const system =  this.drives.filter(drive=>drive.id===item.id)[0];
    const modalService = this.modalService
        .open({
            pageComponent: TypeInfoComponent,
            resolve: {
                system: () => system,
                button: () => true,
                imageFieldName: () => 'img',
            },
        })
        .result.then((result: boolean) => {
            if (result) {
                this.selectDrive(item);
            }
        });
}

  selectDrive(item: iccListItem) {
    const drive = this.pageData.drives.find(f => Number(f.id) === Number(item.id));
    if (drive) {
        this.sharedFacade.closePage(drive);
    }
  }

}
