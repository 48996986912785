import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG, AppConfigFactory, ConfigurationsService } from '@icc/common';
import { ICC_PAGE_DATA, PageComponent } from '@icc/helpers';
import { iccListItem } from '@icc/configurator/ui';
import { IccAccessoryColor, IccAccessoryAccessory } from '@icc/common/data-types';
import { _, SharedFacade } from '@icc/configurator/shared';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';

@Component({
  selector: 'icc-electronic-lock-options-page',
  templateUrl: './electronic-lock-options-page.component.html',
  styleUrls: ['./electronic-lock-options-page.component.scss']
})
export class ElectronicLockOptionsPageComponent extends PageComponent implements OnInit {
    public title: string = _('WINDOW|Opcje elektronicznej wkładki');
    selectedItem: IccAccessoryAccessory | null = null;
    colors: iccListItem[] = [];
    selColor = '0';
    defaultColor = false;

    hiddenPrice = false;

    selectedItemImg = '';

    currency = null;

    constructor(
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            title: string;
            selectedItem: IccAccessoryAccessory;
            selColor: IccAccessoryColor;
            hardwareColors?: IccAccessoryColor[];
        },
        private configurationsService: ConfigurationsService<'door'>,
        private colorsMappingService: ColorMappingService,
    ) {
        super();
        this.title = pageData.title;
    }

    ngOnInit() {
        this.hiddenPrice = this.config().preset === 'b2c' && this.config().hidePricesInB2cConfigurator;
        
        this.sharedFacade.currency$.subscribe((currency) => {
            this.currency = currency;
        });
        this.selectedItem = this.pageData.selectedItem;
        this.selColor = this.pageData.selColor && this.pageData.selColor.id;
        this.defaultColor = Boolean(this.pageData.selColor && this.pageData.selColor.isDefault);
        this.colors = !this.pageData.hardwareColors ? [] : this.pageData.hardwareColors
            .filter(
                color =>
                    this.selectedItem
                    && this.selectedItem.colors_ids
                    && this.selectedItem.colors_ids.some(id => Number(id) === Number(color.id))
            )
            .map(color => ({
                id: color.id,
                title: color.name,
                color: '#' + color.color,
                imageUrl: '/files/windowhandlescolor/' + color.color_img,
            }));
        this.setDefaultColors();
        this.selectedItemImg = this.getImageUrl(this.selectedItem, this.selColor);
    }

    private setDefaultColors() {
        if (((this.pageData.selectedItem && this.pageData.selectedItem.colors_ids) || []).map(String).indexOf(this.selColor) === -1) {
            const electronicLockColorsIds = ((this.pageData.selectedItem && this.pageData.selectedItem.colors_ids) || []).map(Number);
            const windowColorId = this.colorsMappingService.getWindowColorId(
                this.configurationsService.conf.Current
            );
            const matchedColors = this.colorsMappingService.getColors(
                Number(windowColorId),
                'window',
                'accessory'
            );
            const colors = matchedColors
                .map(m => electronicLockColorsIds.filter(c => c === m)[0])
                .filter(m => m);
            if (colors.length) {
                this.selColor = String(colors[0]);
            } else {
                this.selColor = String(electronicLockColorsIds[0]);
            }
            this.defaultColor = true;
        }
    }

    private getImageUrl(hardware: IccAccessoryAccessory, selectedColor: string): string {
        return hardware && (hardware.colors_imgs && hardware.colors_imgs[selectedColor]
            ? '/files/windowhandlescolorswindowaccessory/' + hardware.colors_imgs[selectedColor]
            : '/files/windowaccessory/' + hardware.img);
      }

    selectColor(colorItem: iccListItem) {
        const item = this.pageData.selectedItem;
        const color = this.pageData.hardwareColors.find(c => c.id === colorItem.id);
        if (item && color) {
            this.selColor = color.id;
            this.defaultColor = false;
            this.selectedItemImg = this.getImageUrl(item, this.selColor);
        }
    }

    save() {
        this.sharedFacade.closePage({
            item: this.pageData.selectedItem,
            color: this.selColor,
            defaultColor: this.defaultColor,
        });
    }
}
