import 'angular-ui-bootstrap';

/**
 * Kontroler okna modal elementów napędu
 * @param {object} $scope          Angular Scope
 * @param {object} $uibModalInstance  Okno modal
 * @param {object} filterFilter    Filtry
 * @param {object} sashAccessories Akcesoria
 * @param {array}  selected        Akcesoria
 */
export class ModalDriveElementCtrl {

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private sashAccessories,
        private selected,
        private single: boolean,
        private b2c: boolean,
        private $scope
    ) {
        'ngInject';
        this.sashAccessories.filter(a => this.selected.indexOf(a.id) > -1).map(a => a.selected = true);
        this.changedSelection(this.sashAccessories.filter(a => a.selected === true)[0]);
    }

    selectAccessory(sashAccessory) {
        this.$uibModalInstance.close(sashAccessory);
    }

    changedSelection(accessory?) {
        if (this.single) {
            if (accessory) {
                this.sashAccessories.filter(a => a && a.id != accessory.id && a.selected === true).map(a => a.selected = false);
            }
        }
        this.selected = this.sashAccessories.filter(a => a.selected === true).map(a => a.id);
    }

    close() {
        this.$uibModalInstance.close(this.selected);
    }
}
