import { NgModule } from '@angular/core';

import { PriceDetailsService } from './price-details.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        PriceDetailsService
    ],
})
export class PriceDetailsModule {}
