export default function IcHeaderDirective() {
    'ngInject';

    var directive = {
        restrict   : 'EA',
        templateUrl: '/application/header/header.project.html'
    };

    return directive;
}
