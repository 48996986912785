import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { IccAccessoryColor } from '@icc/common/data-types';
import {
    isNotNullOrUndefined,
    ModalService,
} from '@icc/configurator/shared';
import { HingesOptionsPageComponent } from '../../hinges-options-page/hinges-options-page.component';
import { IccHinge } from '@icc/common/data-types/Hinge';
import { ConfigurationsService, ConfiguratorsDataService } from '@icc/common';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';

@Component({
    selector: 'icc-hinges-page',
    templateUrl: './hinges-page.component.html',
    styleUrls: ['./hinges-page.component.scss'],
})
export class HingesPageComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Wybierz zawiasy');
    public options = [];

    hinges: iccListItem[] = [];
    selectedHingeId = 0;
    constructor(
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            hinges: any[];
            hingeColors: IccAccessoryColor[];
            selHinge: IccHinge;
            selHingeColor: IccAccessoryColor;
        },
        private modalService: ModalService,
        private configurationsService: ConfigurationsService<'door'>,
        private configuratorsDataService: ConfiguratorsDataService,
    ) {
        super();
    }

    ngOnInit() {
        this.hinges = this.pageData.hinges.map(hinge => {
            const selectedColor =
                (hinge.colors_ids
                    && ((this.pageData.selHingeColor
                        && Number(
                            hinge.colors_ids.find(
                                id => Number(this.pageData.selHingeColor.id) === Number(id)
                            )
                        ))
                        || Number(hinge.colors_ids[0])))
                || 0;
            return {
                id: Number(hinge.id),
                title: hinge.name,
                description: hinge.description,
                imageUrl: this.getImageUrl(hinge, selectedColor),
                colors:
                    hinge.colors_ids
                    && hinge.colors_ids
                        .map(c =>
                            this.pageData.hingeColors.find(color => Number(c) === Number(color.id))
                        )
                        .filter(isNotNullOrUndefined)
                        .map(color => ({
                            id: Number(color.id),
                            name: color.name,
                            color: color.color,
                            colorImg: color.color_img,
                        })) || [],
                selectedColor,
                showColorSelection: false,
            };
        });

        this.selectedHingeId = Number(this.pageData.selHinge.id);

        const dependencies = this.configuratorsDataService.data.dependencies || [];
        const blockedAccessories = this.configurationsService.conf.Current.Dependencies?.filter(
            (p) => p.type === 'blockade_to_configuration'
        );
        if (blockedAccessories && dependencies && this.hinges.length) {
            this.markAccessoryAsDisabledBasedOnDependencyBlockingToConfiguration(
                blockedAccessories,
                dependencies
            );
        }
    }

    private getImageUrl(hinge: IccHinge, selectedColor: number): string {
        return hinge.colors_imgs && hinge.colors_imgs[selectedColor]
            ? '/files/windowhandlescolorswindowaccessory/' + hinge.colors_imgs[selectedColor]
            : '/files/windowaccessory/' + hinge.img;
    }

    selectHinge(item: iccListItem) {
        const hinge = this.pageData.hinges.find(f => Number(f.id) === Number(item.id));
        if (hinge?.colors_ids?.length > 0) {
            this.modalService.open({
                pageComponent: HingesOptionsPageComponent,
                resolve: {
                    selectedHinge: hinge,
                    hingeColors: this.pageData.hingeColors,
                    selHingeColor: this.pageData.selHingeColor,
                }
            }).result.then((result) => {
                this.sharedFacade.closePage(Object.assign(result, {
                    hinge,
                    category: null,
                    subcategory: null
                }));
            });
        } else {
            this.sharedFacade.closePage({
                hinge,
            });
        }
    }


    markAccessoryAsDisabledBasedOnDependencyBlockingToConfiguration(
        blockedAccessories: any[],
        dependencies: any[]
    ) {
        dependencies.forEach((dependency) => {
            blockedAccessories.forEach((blocked) => {
                if (dependency && blocked && Number(dependency.id) === Number(String(blocked.id).split('-')[0])) {
                    dependency.conditions.forEach((condition: { type: string; value: any; }) => {
                        if (condition.type === 'accessory') {
                            const accessory = this.hinges.find(
                                (p) => p.id === Number(condition.value)
                            );
                            if (accessory && condition.value) {
                                if ("blockedSashLayoutVariant" in dependency) {
                                    const checkSashLayoutVariant = this.checkSashLayoutVariant(dependency.conditions?.find(k => k.type === 'sashLayoutVariant')?.value, this.configurationsService.conf.Current)
                                    if (checkSashLayoutVariant) {
                                        this.disableAccessory(condition);
                                    }
                                } else if ("blockedExtension" in dependency) {
                                    const checkExtension = this.checkExtension(dependency.conditions?.find(k => k.type === 'extension')?.value, this.configurationsService.conf.Current)
                                    if (checkExtension) {
                                        this.disableAccessory(condition);
                                    }
                                } else {
                                    this.disableAccessory(condition);
                                }
                            }
                        }
                    });
                }
            });
        });
    }

    checkSashLayoutVariant(dependencyValue: { sashesLayoutsVariantsOptions, sashesLayoutsVariantsTypes }, conf: DoorActiveConfiguration) {
        const ALL_SASHES_LAYOUTS_VARIANTS = "ALL_SASHES_LAYOUTS_VARIANTS";
        const layoutsIds = dependencyValue?.sashesLayoutsVariantsTypes?.map(k => k.id);

        if (!layoutsIds.length) {
            return false;
        }
        const selectedAllSashesVariants = layoutsIds.includes(ALL_SASHES_LAYOUTS_VARIANTS);
        const isCurrentLayoutBlocked = dependencyValue.sashesLayoutsVariantsTypes.some((k) => k.id === conf.Layout.id);

        if (dependencyValue?.sashesLayoutsVariantsOptions?.includes('from_hinge_side')) {
            const handlePosition = conf.Sashes?.find((k) => k.type?.handle_position)?.type?.handle_position;

            switch (handlePosition) {
                case 'L': {
                    return (selectedAllSashesVariants && conf.OwnedSashesTypes.doorRightLight) ||
                        isCurrentLayoutBlocked
                }
                case 'R': {
                    return (selectedAllSashesVariants && conf.OwnedSashesTypes.doorLeftLight) ||
                        isCurrentLayoutBlocked
                }
                default: return false;
            }
        } else {
            return (
                isCurrentLayoutBlocked ||
                (selectedAllSashesVariants ? (
                    conf.OwnedSashesTypes.doorRightLight ||
                    conf.OwnedSashesTypes.doorLeftLight ||
                    conf.OwnedSashesTypes.doorTopLight) : false)
            )
        }
    }

    checkExtension(dependencyValue: { extensionOptions, extensionTypes }, conf: DoorActiveConfiguration) {
        const ALL_EXTENSIONS = "ALL_EXTENSIONS";
        const extensionIds = dependencyValue.extensionTypes.map(k => k.id);

        if (!extensionIds.length) {
            return;
        }

        if (dependencyValue?.extensionOptions?.includes('from_hinge_side')) {
            const handlePosition = conf.Sashes?.find((k) => k.type?.handle_position)?.type?.handle_position;
            switch (handlePosition) {
                case 'L': {
                    const sideProfiles = conf.SideProfiles.filter(k => k.side === 'right');
                    return (
                        (extensionIds.includes(ALL_EXTENSIONS) && sideProfiles.length) ||
                        sideProfiles.some((sp) => extensionIds.includes(sp.profileId))
                    );
                }
                case 'R': {
                    const sideProfiles = conf.SideProfiles.filter(k => k.side === 'left');
                    return (
                        (extensionIds.includes(ALL_EXTENSIONS) && sideProfiles.length) ||
                        sideProfiles.some((sp) => extensionIds.includes(sp.profileId))
                    );
                }
                default: return false
            }
        } else {
            return (
                dependencyValue.extensionTypes.some((k) => conf.SideProfiles.some(sp => sp.profileId === k.id)) ||
                extensionIds.includes(ALL_EXTENSIONS) && conf.SideProfiles.length
            );
        }
    }

    disableAccessory(condition: { type?: string; value: any; }) {
        const hinge = this.hinges.find((p) => p.id === Number(condition.value));
        if (hinge) {
            hinge.disabled = true;
        }
    }
}
