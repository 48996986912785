import angular from 'angular';
import $ from 'jquery';

/**
 * Dobra komplementarnet
 * @param {Object} $scope                    scope
 * @param {Object} $timeout                  timeout
 * @param {Object} Core                      Core
 * @param {Object} ComplementaryGoodsService ComplementaryGoodsService
 */
export default function ComplementaryGoodsCtrl(
    $scope,
    $timeout,
    $filter,
    $translate,
    Core,
    ComplementaryGoodsService,
    glassOrnamentFilter,
    InfoService,
    IccConfig,
    PriceCassonettoService,
    FillingValidationService,
    EventBusService,
    ScrollbarService
) {
    'ngInject';

    var vm = this;

    vm.currentGoodType = '';
    vm.colors = [];
    vm.refresh = refresh;
    vm.add = add;
    vm.openModalComplementaryGoodsImage =
        ComplementaryGoodsService.openModalComplementaryGoodsImage.bind(ComplementaryGoodsService);
    vm.remove = ComplementaryGoodsService.remove.bind(ComplementaryGoodsService);
    vm.setGoodType = setGoodType;
    vm.isEnabledType = ComplementaryGoodsService.isEnabledType.bind(ComplementaryGoodsService);
    vm.glassData = [];
    vm.cassonettosData = [];
    vm.sillsData = [];
    vm.accessoriesData = [];
    vm.UgFilter = UgFilter;
    vm.calcGlass = calcGlass;
    vm.calcCassonetto = calcCassonetto;
    vm.calcAccessory = calcAccessory;
    vm.parseInt = parseInt;
    vm.changedFilters = changedFilters;
    vm.openPreview = openPreview;
    vm.filters = {};
    vm.glassTypes = [];
    vm.areOrnaments = false;
    vm.dictionary = {
        COUNT: 'INTERFACE|Ilość',
        WOOD_TYPE: 'WINDOW|Rodzaj drewna',
        COLOR_OUTER: 'COLOR|Kolor zewn.',
        COLOR_INNER: 'COLOR|Kolor wewn.',
        COLOR_CORE: 'WINDOW|Kolor bazy',
        PRICE: 'OFFER|Cena',
        NO_PRICE: 'CONFIGURATOR|Do wyceny',
        LENGTH: 'DIMENSION|Długość',
        WIDTH: 'DIMENSION|Szerokość',
        HEIGHT: 'DIMENSION|Wysokość',
        COLOR: 'COLOR|Kolor',
        COLOR_WHITE: 'COLOR|Biały',
        COLOR_RAL: 'COLOR|RAL',
        ADD: 'INTERFACE|Dodaj',
        COLORS: 'INTERFACE|Kolory',
    };
    vm.addMoreItems = addMoreItems;
    vm.accessories = [];
    vm.hasNoPrice = hasNoPrice;
    vm.hasZeroPrice = hasZeroPrice;
    vm.calcProfile = calcProfile;
    vm.hasPrice = hasPrice;

    vm.displayedCount = 25;

    var loaded = {
        glass: false,
        windowsill: false,
        cassonetto: false,
        profile: false,
        accessory: false,
    };

    if (ComplementaryGoodsService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init())
    );
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    $timeout(() => {
        $('.scroll.ps-container').scroll();
    }, 10);

    /**
     * Funkcja inicjujaca
     */
    function init() {
        vm.colors = ComplementaryGoodsService.colorsAll;
        vm.currentGood = ComplementaryGoodsService.currentGood;
        Object.keys(vm.dictionary).forEach(
            key => (vm.dictionary[key] = $translate.instant(vm.dictionary[key]))
        );

        setGoodType('accessory');
    }

    /**
     * Odświeżanie
     */
    function refresh() {
        ScrollbarService.update();
    }

    function changedFilters(filters) {
        vm.filters = filters;
        refresh();
    }

    /**
     * Dodawanie
     * @param {Object} accessory Akcesorium
     * @param {Object} color     Kolro
     * @param {Object} $event    Zdarzenie
     * @param {Object} type      Typ
     */
    function add(accessory, color, $event, type) {
        ComplementaryGoodsService.add(
            accessory,
            Core.fIdO(vm.colors, color),
            type
        );
        accessory.values.count = '1';
        refresh();
    }

    /**
     * Obliczanie ceny szyby
     * @param  {Object} glass Szyba
     */
    function calcGlass(glass) {
        const validGlazing = FillingValidationService.checkGlass(
            glass,
            glass.currentWidth,
            glass.currentHeight,
            (glass.currentWidth * glass.currentHeight) / 1000000
        );
        if (validGlazing.valid) {
            const glazingCount = ~~glass.glazing_count;
            const warmEdge = vm.glassData.warmEdges.find(
                w => Number(w.id) === Number(glass.warmEdge)
            );
            const warmEdgePrice =
                warmEdge
                && warmEdge.prices.find(el => !el.window_line_id && ~~el.count === glazingCount);
            glass.price =
                Core.round10((glass.currentWidth * glass.currentHeight) / 1000000, -2)
                * (glass.price_sq_m * 1
                    + IccConfig.Settings.glazingBasePrice
                    + (warmEdgePrice ? warmEdgePrice.price : 0));
        } else {
            glass.price = NaN;
        }
    }

    /**
     * Oblicza cenę akcesorium
     * @param  {Object} accessory Akcesorium
     */
    function calcAccessory(accessory) {
        if (
            accessory.colors_imgs
            && accessory.colors_imgs.length
            && accessory.selectedColor
            && accessory.colors_imgs[accessory.selectedColor]
        ) {
            accessory.img = accessory.colors_imgs[accessory.selectedColor];
        }

        var price = 0;

        var basePrice = accessory.price_white;

        if (!accessory.currentLength) {
            accessory.currentLength = 1000;
        }
        if (accessory.colors_ids) {
            if (!accessory.currentColor) {
                accessory.currentColor = accessory.colors_ids[0];
            }
            basePrice = accessory.colors_prices[accessory.currentColor * 1];
        }

        switch (accessory.price_type) {
            case '0':
                price = basePrice;
                break;
            case '1':
                price = basePrice * (accessory.currentWidth * accessory.currentHeight);
                break;
            case '2':
                price = basePrice * (accessory.currentLength / 1000);
                // price = accessory.price_white;
                break;
        }

        accessory.price = price;
    }

    /**
     * Obliczanie ceny kasonetki
     * @param  {Object} cassonetto Kasonetka
     * @param  {Number} width      Szerokość
     */
    function calcCassonetto(cassonetto) {
        PriceCassonettoService.cassonettoCalculatePrice(
            { accessory: cassonetto, count: 1 },
            vm.cassonettosData.windowColorsAll,
            vm.cassonettosData.productPricelists
        );

        return cassonetto.price;
    }

    /**
     * Filtr UG
     * @param {Object} el El
     * @return {Boolean}      Czy to filtr ug
     */
    function UgFilter(el) {
        if (
            angular.isUndefined(vm.selectedUg)
            || vm.selectedUg === null
            || vm.selectedUg === ''
            || parseFloat(el.u) <= parseFloat(vm.selectedUg)
        ) {
            if (
                angular.isUndefined(vm.selectedRw)
                || vm.selectedRw === null
                || vm.selectedRw === ''
                || parseInt(el.rw) >= parseInt(vm.selectedRw)
            ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Funkcja otwierajaca podgląd
     * @param  {object} accessory Klamka
     */
    function openPreview(access) {
        const imageSrc = '/files/windowaccessory/' + access.img;
        InfoService.openInfoModal({
            template: 'imagePreview.html',
            title: $translate.instant('INTERFACE|Podgląd'),
            data: { imageSrc },
        });
    }

    function addMoreItems() {
        let items;
        switch (vm.currentGood.type) {
            case 'glass':
                items = vm.glassData.glassTypes;
                break;
            case 'cassonetto':
                items = vm.cassonettosData.cassonettos;
                break;
            case 'profile':
                items = vm.profilesData.profiles;
                break;
            case 'accessory':
                items = vm.accessories;
                break;
        }
        if (vm.displayedCount + 5 < items.length) {
            vm.displayedCount += 5;
        } else {
            vm.displayedCount = vm.accessories.length;
            vm.displayedCount = items.length;
        }
    }

    function setGoodType(type) {
        vm.displayedCount = 25;
        ComplementaryGoodsService.setGoodType(type);

        if (ComplementaryGoodsService.loaded[type] && !loaded[type]) {
            switch (type) {
                case 'glass':
                    vm.glassData = ComplementaryGoodsService.glassData;
                    vm.glassData.glassTypes = vm.glassData.glassTypes.map(el => {
                        el.currentWidth = 1000;
                        el.currentHeight = 1000;
                        el.warmEdge = String(vm.glassData.warmEdges[0].id);
                        calcGlass(el);
                        return el;
                    });
                    vm.areOrnaments = glassOrnamentFilter(vm.glassData.glassTypes, true).length > 0;
                    break;
                case 'cassonetto':
                    vm.cassonettosData = ComplementaryGoodsService.cassonettosData;
                    for (const cassonetto of vm.cassonettosData.cassonettos) {
                        cassonetto.currentWidth = cassonetto.currentWidth || 1000;
                        cassonetto.currentHeight = cassonetto.currentHeight || 300;

                        cassonetto.currentP1 =
                            cassonetto.currentP1 || cassonetto.show_p1
                                ? parseFloat(cassonetto.min_p1_mm) || 0
                                : undefined;
                        cassonetto.currentP2 =
                            cassonetto.currentP2 || cassonetto.show_p2
                                ? parseFloat(cassonetto.min_p2_mm) || 0
                                : undefined;
                        cassonetto.currentL1 =
                            cassonetto.currentL1 || cassonetto.show_l1
                                ? parseFloat(cassonetto.min_l1_mm) || 0
                                : undefined;

                        calcCassonetto(cassonetto);
                    }
                    break;
                case 'profile':
                    vm.profilesData = ComplementaryGoodsService.profilesData;
                    break;
                case 'accessory':
                    vm.accessoriesData = ComplementaryGoodsService.accessoriesData;
                    vm.accessories = vm.accessoriesData.accessories;
                    break;
            }
            loaded[type] = true;
        }
    }

    function hasZeroPrice(price) {
        return price && [price.price_length, price.price_piece].every(p => p === 0);
    }

    function hasNoPrice(price) {
        return (
            !price
            || [price.price_length, price.price_piece].some(p => isNaN(Number(p)) || p === null)
        );
    }

    function calcProfile(profile) {
        ComplementaryGoodsService.calcProfilePrice(profile);
    }

    function hasPrice(price) {
        return price != null && !isNaN(price);
    }
}
