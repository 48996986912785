import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * W zakresie od min-max sprawdza czy liczba mieści się w zakresie multiplikacji przeskoku.
 * @example W zakresie Od 100 do 150 z przeskokiem 10 sprawdzi czy wartość na wejściu jest
 * liczbą spośród [100, 110, 120+n... 150]
 * 
 * @param min Zakres szerokości muru
 * @param max Zakres szerokości muru
 * @param gap Przeskok w obejmie
 */
export function gapValidator(min: number, max: number, gap: number): ValidatorFn{
    return (control: AbstractControl) :  ValidationErrors | null => {
        const value = Number(control.value);
        let multiply = 1;
        let isValueInRange = false;
        let result = max;

        if(!value) {
            return null;
        }

        if(value >= min && value <= max) {
            isValueInRange = true;
        }

        const array = [];

        while (isValueInRange) {
            if (max - gap * multiply >= min || result >= min) {
                array.push(result);
                result = max - gap * multiply;
                multiply = multiply + 1;
            } else {
                isValueInRange = false;
            }
        }
    
        const isValueIncluded = array.includes(value);

        return isValueIncluded ? null : { gapError: true };
    }
}