import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ModalService, ICC_PAGE_DATA, isObject } from '@icc/helpers';
import { Reinforcement } from '@icc/window';
import { SharedFacade } from '@icc/configurator/shared';
import { PriceBaseService } from '@icc/price/b2b';

@Component({
  selector: 'icc-reinforcements-list-page',
  templateUrl: './reinforcements-list-page.component.html',
  styleUrls: ['./reinforcements-list-page.component.scss']
})
export class ReinforcementsListPageComponent extends PageComponent  implements OnInit {
  public title = 'WINDOW|Wybierz wzmocnienie';
  public options = [];
  public reinforcements: Reinforcement[] = [];
  public selectedReinforcement: Reinforcement | null = null;
  public currency: any;

  constructor(
    private sharedFacade: SharedFacade,
    private priceBaseService: PriceBaseService,
    @Inject(ICC_PAGE_DATA)
    private pageData: {
        reinforcements: Reinforcement[];
        selectedReinforcement: Reinforcement;
        currency: any;
    },
  ) {
    super();
  }

  ngOnInit() {
    this.reinforcements = this.pageData.reinforcements;
    this.selectedReinforcement = this.pageData.selectedReinforcement;
    this.currency = this.pageData.currency;
  }

  selectReinforcement(reinforcement: Reinforcement) {
    this.sharedFacade.closePage(reinforcement);
  }

  /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
  close() {
    this.sharedFacade.closePage({ colors: this.pageData.selectedReinforcement});
  }

  priceMarket(price: number) {
    return this.priceBaseService.addMarginMarketFactor(price);
  }

}
