import { Inject, Injectable } from '@angular/core';
import { TranslateParser } from '@ngx-translate/core';
import { isDefined, Pseudolocalizer } from '@icc/helpers';
import { AppConfigFactory, APP_CONFIG } from '@icc/common';

@Injectable()
export class IccTranslateParser extends TranslateParser {
  templateMatcher: RegExp = /{{\s?([^{}\s]*)\s?}}/g;

  constructor(@Inject(APP_CONFIG) protected config: AppConfigFactory ) {
    super();
  }

  public interpolate(expr: string | Function, params?: any): string {
    let result: string;

    if (typeof expr === 'string') {
      result = this.interpolateString(expr, params);
    } else if (typeof expr === 'function') {
      result = this.interpolateFunction(expr, params);
    } else {
      // this should not happen, but an unrelated TranslateService test depends on it
      result = expr as string;
    }

    return result;
  }

  getValue(target: any, key: string, delimiter = '|'): any {
    const keys = key.split(delimiter);
    key = '';
    let childKey = '';
    do {
      key += keys.shift();
      childKey = '';
      if (isDefined(target) && isDefined(target[key]) && (typeof target[key] === 'object' || !keys.length)) {
        target = target[key];
        if (typeof target === 'function') {
          childKey = key;
        }
        key = '';
      } else if (!keys.length) {
        target = undefined;
        childKey = key;
      } else {
        key += '.';
      }
    } while (keys.length);

    if (this.config()?.EnvConfig?.pseudolocalization?.enable) {
      Pseudolocalizer.setConfig(this.config().EnvConfig.pseudolocalization);
      target = function (d) { return Pseudolocalizer.trans(childKey) ; };
    } 
    return target;
  }

  private interpolateFunction(fn: Function, params?: any) {
    return fn(params);
  }

  private interpolateString(expr: string, params?: any) {
    if (!params) {
      return expr;
    }

    return expr.replace(this.templateMatcher, (substring: string, b: string) => {
      const r = this.getValue(params, b, '.');
      return isDefined(r) ? r : substring;
    });
  }
}
