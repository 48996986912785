<form [formGroup]="constructionForm" class="option-buttons">
    <h4 [class.disabled-header]="!anyWindowsInGarageDoor()" class="buttons-group-label">{{ 'GARAGEDOOR|Okna' | translate }}</h4>
    <div class="windows-buttons">
        <button
            class="windows-type-btn"
            mat-raised-button
            color="primary"
            [disabled]="!anyWindowsInGarageDoor()"
            (click)="openGlobalWindowTypesModal()"
        >
            {{ 'GARAGEDOOR|Zmień typ okien' | translate }}
        </button>

        <div class="input-control">
            <mat-form-field class="windows-glazing-type">
                <mat-label>{{ 'GARAGEDOOR|Typ oszklenia okien' | translate }}</mat-label>
                <mat-select (selectionChange)="setWindowsGlobalGlazing($event)" [value]="(selectedWindowsGlobalGlazing$ | async)?.id" [disabled]="!anyWindowsInGarageDoor()">
                    <mat-option *ngFor="let glazing of windowGlazings" [value]="glazing.id">
                        {{glazing.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <button
            class="windows-color-btn"
            mat-raised-button
            color="primary"
            [disabled]="!anyWindowsInGarageDoor()"
            (click)="openGlobalWindowFramesOuterColorModal()"
        >
            {{ 'GARAGEDOOR|Zmień zewnętrzny kolor ramek okien' | translate }}
        </button>
    </div>

    <h4 [class.disabled-header]="!anyGlazingsInGarageDoor()" class="buttons-group-label">{{ 'GARAGEDOOR|Panele panoramiczne' | translate }}</h4>
    <div class="glazings-buttons">
        <div class="input-control">
            <mat-form-field class="glazing-panels-glazing-type">
                <mat-label>{{ 'GARAGEDOOR|Typ oszklenia paneli panoramicznych' | translate }}</mat-label>
                <mat-select (selectionChange)="setGlazingPanelsGlobalGlazing($event)" [value]="(selectedGlazingPanelsGlobalGlazing$ | async)?.id" [disabled]="!anyGlazingsInGarageDoor()">
                    <mat-option *ngFor="let glazing of glazingPanelGlazings" [value]="glazing.id">
                        {{glazing.name}} {{glazing.thickness}}<span>{{dimensionUnit.unitSymbol}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="input-control">
            <mat-form-field>
                <mat-label>{{ 'GARAGEDOOR|Ilość impostów' | translate }}</mat-label>
                <input
                    type="number"
                    class="form-control"
                    formControlName="impostAmount"
                    [disableControl]="!anyGlazingsInGarageDoor()"
                    matInput
                    (change)="setImposts(impostAmount.value)"
                />
                <mat-hint
                [class.text-danger]="impostAmount.errors?.min || impostAmount.errors?.max"
                *ngIf="anyGlazingsInGarageDoor()"
                >{{ "GARAGEDOOR|Liczba całkowita z przedziału" | translate }}
                    <b>{{ getMinImpostAmount() }}</b> - <b>{{ getMaxImpostAmount() }}</b></mat-hint>
            </mat-form-field>
        </div>
        <button
            class="glazings-color-btn"
            mat-raised-button
            color="primary"
            [disabled]="!anyGlazingsInGarageDoor()"
            (click)="openGlobalGlazingPanelsColorModal()"
        >
            {{ 'GARAGEDOOR|Zmień kolor paneli panoramicznych' | translate }}
        </button>
    </div>

    <h4 [class.disabled-header]="!anyVentilationGratesInGarageDoor()" class="buttons-group-label">{{ 'GARAGEDOOR|Kratki wentylacyjne' | translate }}</h4>
    <div class="ventilation-grates-buttons">
        <button
            class="ventilation-grates-color-btn"
            mat-raised-button
            color="primary"
            [disabled]="!anyVentilationGratesInGarageDoor()"
            (click)="openGlobalVentilationGratesOuterColor()"
        >
            {{ 'GARAGEDOOR|Zmień zewnętrzny kolor kratek' | translate }}
        </button>
    </div>
</form>

<div id="garage-layout-draw" #parent>
    <icc-draw
        [details]="drawService.details"
        [options]="drawOptions"
        fitTo="width"
        iccDrawResize
        [drawWidth]="(selectedDimensions$ | async)?.width"
        [drawHeight]="(selectedDimensions$ | async)?.height"
        [parentElement]="parent"
    ></icc-draw>
</div>
