import { Common } from './Common';
import { core } from './helpers';
import { Injectable, Injector, InjectionToken, Optional, Inject } from '@angular/core';
import { EventBusService } from '@icc/common/event-bus.service';
import { UserService } from '@icc/common/user.service';

export let START_STATE = new InjectionToken<{ startState: string }>('startState');

@Injectable()
export class StateService {
    public state;
    public stateToSave;

    constructor(
        private userService: UserService,
        private eventBusService: EventBusService,
        @Optional() @Inject(START_STATE) startState: { startState: string }
    ) {
        this.init(startState);
    }

    init(startState?: { startState: string }) {
        let state = {};
        if (!core.isWorker() && !core.isNode()) {
            state = localStorage.getItem(this.getStateName()) || {};
        }
        if (core.isWorker() && startState) {
            state = startState.startState;
        }
        this.setState(core.parseJson(state));
    }

    onStateChanged(offerId, offerTmpId) {
        this.save();
        this.eventBusService.post({
            key: 'changedState',
            value: this.state,
        });
        if (!offerId || offerId !== this.state.offer_id) {
            this.eventBusService.post({
                key: 'changedOffer',
                value: this.state && this.state.offer_id,
            });
        }
        if (
            this.state.offer
            && this.state.offer.tmp_id
            && (!offerTmpId || offerTmpId !== this.state.offer.tmp_id)
        ) {
            this.eventBusService.post({
                key: 'changedOfferObject',
                value: this.state,
            });
        }
    }

    getStateName() {
        const user: any = this.userService.get() || {};
        return `state_${user.marketId}_${user.id || ''}`;
    }

    getState() {
        return this.state;
    }

    setState(newState) {
        let offerId = null;
        let offerTmpId = null;
        if (this.state && this.state.offer_id) {
            offerId = this.state.offer_id;
        }
        if (this.state && this.state.offer && this.state.offer.tmp_id) {
            offerTmpId = this.state.offer.tmp_id;
        }
        this.state = newState;
        this.stateToSave = {};
        Object.keys(this.state).forEach(key => {
            if (key === 'positions') {
                this.stateToSave.positions = null;
            } else {
                this.stateToSave[key] = core.copy(this.state[key]);
            }
        });
        this.onStateChanged(offerId, offerTmpId);
    }

    getKey(key) {
        return this.state[key];
    }

    setKey(key, newValue) {
        let offerId = null;
        let offerTmpId = null;
        if (this.state && this.state.offer_id) {
            offerId = this.state.offer_id;
        }
        if (this.state && this.state.offer && this.state.offer.tmp_id) {
            offerTmpId = this.state.offer.tmp_id;
        }
        this.state[key] = newValue;
        if (key !== 'positions') {
            this.stateToSave[key] = newValue;
        }
        this.onStateChanged(offerId, offerTmpId);
    }

    getCurrentOffer() {
        if (
            Common.isObject(this.state)
            && Common.isArray(this.state.offers)
            && this.state.offers.length
            && Common.isObject(this.state.offers[0].doc)
        ) {
            return this.state.offers[0].doc;
        }
        return null;
    }

    save() {
        if (!core.isWorker() && !core.isNode()) {
            localStorage.setItem(this.getStateName(), core.stringJson(this.stateToSave));
        }
    }
}
