import { Injectable } from '@angular/core';
import { ConfiguratorsDataService } from './configurators/configurators-data.service';
import { core } from './helpers';

interface DynamicElement {
  basic_edit?: boolean,
  content?: string,
  created?: string,
  id?: string,
  locale?: string,
  markets?: string[],
  markets_b2c?: string[],
  modified?: string,
  name: string,
  slug: string,
}

@Injectable({
  providedIn: 'root'
})
export class DynamicElementsService {
  public dynamicElements: DynamicElement[];
  constructor(
    private configuratorsDataService: ConfiguratorsDataService
  ) { 
    this.dynamicElements = this.getDynamicElements();
  }

  getDynamicElements(): DynamicElement[] { 
    const dynamicElements = core.copy(this.configuratorsDataService.data.dynamicElements);
    return dynamicElements;
  }

  getElementContentBySlug(slug: string){
    if (slug) {
      return this.getElementBySlug(slug)?.content;
    }
  }
  
  getElementBySlug(slug: string){
    if (slug && Object.keys(this.dynamicElements)?.length) {
        return this.dynamicElements.find((el) => el.slug === slug);
    }
  }
} 
