const priceDetailsModalTemplate = require('!raw-loader!@icc/legacy/price/details-modal/details-modal.html');
import { Injectable} from '@angular/core';
import { ConfigurationType } from '@icc/common/configurations/configurations.service';
import { ModalService, NoopPageComponent } from '@icc/helpers';

/**
 * Usługa liczenia ceny
 *
 * @export
 * @class PriceDetailsService
 */
@Injectable()
export class PriceDetailsService {

    /**
     * Creates an instance of PriceDetailsService.
     *
     * @param {ng.ui.bootstrap.IModalService} $uibModal $uibModal
     * @param {any} ConfigurationsService ConfigurationsService
     * @param {PriceBaseService} priceBaseService PriceBaseService
     *
     * @memberOf PriceDetailsService
     */
    constructor(
        private modalService: ModalService
    ) {
        'ngInject';
    }

    /**
     * Otwiera modal szczegółow wyceny
     *
     * @param {any} config
     *
     * @memberOf PriceDetailsService
     */
    showPriceDetailsModal(config: ConfigurationType) {
        this.modalService.open({
            template: priceDetailsModalTemplate,
            controller: 'ModalPriceDetailsCtrl as $ctrl',
            pageComponent: NoopPageComponent,
            resolve: {
                conf: () => config,
            },
        });
    }
}
