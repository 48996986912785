import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';

@Component({
  selector: 'icc-silicone-colors-page',
  templateUrl: './silicone-colors-page.component.html',
  styleUrls: ['./silicone-colors-page.component.scss']
})
export class SiliconeColorsPageComponent extends PageComponent implements OnInit {
  public title = _('WINDOW|Wybierz kolor silikonu');
  public options = [];

  colors: iccListItem[] = [];
  selectedId: number | null = null;

  constructor(@Inject(ICC_PAGE_DATA) private pageData: any, private sharedFacade: SharedFacade) {
    super();
  }

  ngOnInit() {
    const allColors =  [...this.pageData.colors];
    this.colors = allColors.map(color => ({
        id: Number(color.id),
        title: color.name,
        color: '#' + color.color,
        imageUrl: `/files/windowsiliconecolor/${color.color_img}`,
        description: `${color.name}`,
    }));
    if (this.pageData.selColor) {
        this.selectedId = Number(this.pageData.selColor.id);
    }
  }

  select(item: iccListItem) {
    const color = this.pageData.colors.find(f => Number(f.id) === Number(item.id));
    this.sharedFacade.closePage(color);
  }

}
