import { isUndefined } from '@icc/helpers';

/**
 * Funkcja kategorii szyby
 */
export function GlassCatFilter() {
    return function(glass, categoryId, subcatId) {
        const glassArr = [];
        Object.keys(glass).forEach(key => {
            glassArr.push(glass[key]);
        });
        if (isUndefined(categoryId) || categoryId === null || categoryId === '') {
            return glass;
        } else {
            return glassArr.filter(function(gl) {
                if (String(gl.fillings_parent_id) === String(categoryId) || String(gl.fillings_category_id) === String(categoryId)) {
                    if (isUndefined(subcatId) || subcatId === null || subcatId === '') {
                        return true;
                    } else if (String(gl.fillings_category_id) === String(subcatId)) {
                        return true;
                    }
                }
                return false;
            });
        }
    };
}
