export default function GetSvgDataFilter() {
    'ngInject';

    return function(input, part) {
        var spl = input.split('|');
        if (part == 1) {
            return spl[0].substr(1, spl[0].length - 1);
        } else {
            return spl[1];
        }
    };
}

