import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';

@Component({
  selector: 'icc-montages-info-page',
  templateUrl: './montages-info-page.component.html',
  styleUrls: ['./montages-info-page.component.scss']
})
export class MontagesInfoPageComponent extends PageComponent implements OnInit {

  title = _('ROLLERSHUTTER|Informacja o montażu');

  constructor(
    @Inject(ICC_PAGE_DATA) public pageData: {
      details: any
    }
  ) {
    super();
  }

  ngOnInit() {
  }

}
