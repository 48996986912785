import angular from 'angular';
import $ from 'jquery';

/**
 * Kontroler modeli
 * @param {Object} $scope         scope
 * @param {Object} $uibModal         modal
 * @param {Object} $timeout       timeout
 * @param {Object} filterFilter   filterFilter
 * @param {Object} ModelService  ModelService
 * @param {Object} CurConfService CurConfService
 */
export default function ModelsCtrl($scope, $uibModal, $timeout, filterFilter, ModelService, EventBusService, IccConfig, ScrollbarService) { // jshint ignore:line
    'ngInject';

    var vm = this;

    vm.models             = [];
    vm.modelsGroups       = [];
    vm.refresh            = refresh;
    vm.openModalModelInfo = openModalModelInfo;
    vm.selectModel        = selectModel;
    vm.selectModelGroup   = selectModelGroup;
    vm.currentModelGroup  = null;

    if (ModelService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjujaca
     */
    function init() {
        vm.models            = ModelService.models;
        vm.modelsGroups      = ModelService.modelsGroups;
        vm.currentModelGroup = ModelService.currentModelGroup;
        ModelService.selectModelGroup(vm.currentModelGroup);
    }
    /**
     * Funkcja odświeżajaca
     */
    function refresh() {
        ScrollbarService.update();
    }

    /**
     * Wybieranie modelu
     * @param  {Object} model Model
     */
    function selectModel(model) {
        ModelService.selectModel(model, true, 10);
    }

    /**
     * Wybór grupy modeli
     * @param  {Object} group Grupa
     */
    function selectModelGroup(group) {
        ModelService.selectModelGroup(group);
        vm.currentModelGroup = group;
        vm.refresh();
    }

    /**
     * Otwieranie informacji o modelu
     * @param  {Object} model Model
     */
    function openModalModelInfo(model) {
        var modalInstance = $uibModal.open({
            templateUrl: 'modalSystemInfo.html',
            controller : 'ModalSystemInfoCtrl as msystem',
            resolve    : {
                system: function() {
                    return model;
                }
            }
        });

        modalInstance.result.then(function(system) {
            if (angular.isDefined(system)) {
                vm.selectSystem(system);
            }
        });

        modalInstance.closed.then(() => {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                });
            }
        });
    }

}
