import { PleatedBlindFacade } from './../+state/pleated-blind.facade';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepComponent, _ } from '@icc/configurator/shared';
import { EventBusService } from '@icc/common';
import { iccListItem } from '@icc/configurator/ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'icc-systems',
  templateUrl: './systems.component.html',
  styleUrls: ['./systems.component.scss']
})
export class SystemsComponent extends StepComponent implements OnInit, OnDestroy {
  static stepName = _('PLEATEDBLIND|Przeznaczenie');
  static stepIcon = {
    ligature: 'table_chart',
};

  public configurator = 'pleated_blind';
  public stepId = 'system';
  public title = _('PLEATEDBLIND|Przeznaczenie');

  systems = [];
  allSystems = [];

  selectedSystem$ = this.blindFacade.selectedSystem$;
  private subscriptions: Subscription[] = [];

  availableSystems$ = this.blindFacade.availableSystems$;

  constructor(
      private eventBusService: EventBusService,
      private blindFacade: PleatedBlindFacade,
  ) {
    super();
    this.subscriptions.push(
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
            this.init()
        )
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.map(el => el.unsubscribe());
  }

  private init() {
    this.subscriptions.push(
      this.availableSystems$.subscribe(systems => {
        this.allSystems = systems;
      })
    )
    this.systems = this.allSystems.map<iccListItem>(system => ({
      id: String(system.id),
      title: system.name,
      imageUrl: '/files/pleatedblindsystem/' + system.img
    }));    
  }

  selectSystem(selectedSystem) {
    const system = this.allSystems.find(s => s.id == selectedSystem.id)
    this.blindFacade.setSystem(system);
  }
}
