export default function PluralFilter($filter) {
    'ngInject';

    return function(n1, n2, n3, n) {
        if (typeof n1 === 'undefined') {
            return false;
        } else if (typeof n2 === 'undefined') {
            return false;
        } else if (typeof n3 === 'undefined') {
            return false;
        } else {
            var plur = (n == 1) ? n1 : (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) ? n2 : n3;
            return $filter('translate')(plur);
        }
    };
}
