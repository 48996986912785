import angular from 'angular';

export default function DefectFactory($rootScope, $http, $uibModal, EnvConfig) {
    'ngInject';

    const factory = {
        addFile,
        removeFile,
        openModalComplaintDefect,
        openModalComplaintDefectHistory,
        removeDefect,
        addDefectFile
    };

    return factory;

    /**
     * Dodawanie załączników
     * @param {File}     files      Pliki
     * @param {function} callback   Callback
     */
    function addFile(files, type, callback) {
        var formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('data[files][' + i + ']', files[i]);
        }
        formData.append('data[type]', type);
        $http
            .post(`${EnvConfig.remoteHost || window.location.origin}/complaint/complaints_defects/add_attachment.json`, formData, {
                transformRequest: angular.identity,
                headers         : {'Content-Type': undefined}
            })
            .then((res) => {
                callback(res.data);
            });
    }

    function addDefectFile(file, callback) {
        const formData = new FormData();
        formData.append('data[file]', file);
        $http
            .post(`${EnvConfig.remoteHost || window.location.origin}/complaint/complaints_defects/add_defect_file.json`, formData, {
                transformRequest: angular.identity,
                headers         : {'Content-Type': undefined}
            })
            .then((res) => {
                callback(res.data);
            });
    }

    /**
     * Zapisywanie usterki
     * @param {object}   defect     Usterka
     * @param {function} callback   Callback
     */
    function save(defect, callback) {
        if (defect != false) {
            $http
                .post(`${EnvConfig.remoteHost || window.location.origin}/complaint/complaints_defects/add.json`, defect)
                .then((res) => {
                    callback(res.data.defect);
                });
        }
    }

    /**
     * Usuwanie załączników
     * @param {File}     file       plik
     * @param {function} callback   Callback
     */
    function removeFile(file, callback) {
        $http
            .post(`${EnvConfig.remoteHost || window.location.origin}/complaint/complaints_defects/remove_attachment`, {id: file.id})
            .then(() => {
                callback();
            });
    }

    /**
     * Usuwanie usterki
     * @param {object}     defect   Usterka
     * @param {function} callback   Callback
     */
    function removeDefect(defect, callback) {
        $http
            .post(`${EnvConfig.remoteHost || window.location.origin}/complaint/complaints_defects/remove`, {id: defect.id})
            .then(() => {
                callback();
            });
    }

    /**
     * Modal z usterkami
     * @param {object}   defectsData  Dane do usterek
     * @param {object}   defectEdit   Usterka
     * @param {boolean}  edit         Możliwa edycja
     * @param {function} callback     Callback
     */
    function openModalComplaintDefect(defectsData, defectEdit, edit, callback) {
        const modalInstance = $uibModal.open({
            templateUrl: 'modalComplaintDefect.html',
            controller : 'ModalComplaintDefectCtrl as mdefect',
            resolve    : {
                defectsData,
                defectEdit,
                edit
            }
        });

        modalInstance.result.then(selectedData => save(selectedData, callback));
    }

    /**
     * Modal z historią usterki
     * @param {object}   defectsData  Dane do usterek
     * @param {object}   defect   Usterka
     */
    function openModalComplaintDefectHistory(defectsData, defect) {
        const modalInstance = $uibModal.open({
            templateUrl: 'modalComplaintDefectHistory.html',
            controller : 'ModalComplaintDefectHistoryCtrl as mdefect',
            resolve    : {
                defectsData,
                defect
            }
        });

        modalInstance.result.then();
    }
}
