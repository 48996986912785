import {core} from '../helpers';

export default function FileSizeFilter() {
    'ngInject';

    const units = [
        'B',
        'kB',
        'MB',
        'GB',
        'TB',
        'PB'
    ];

    /**
     * Filtr do konwersji wymarów.
     * @param  {number} data           Liczba do konwersji
     * @param  {number} hasunit        1 lub 0 - czy do wymiaru dołączany ma być symbol wymiaru (mm, ″)
     * @param  {number} inmm           Czy do wymiaru innego niż w mm dołączyć informacje o wymiarze w jednostkach mm?
     * @param  {number} mb             Metr bieżący - 1 lub 2 - cena za metr bieżący?
     * @param  {string} dimension_unit Docelowa jednostka, przy braku bierze domyślną jednostkę użytkownika / rynku
     * @return {string}                Skonwertowany wymiar.
     */
    return (size, precision = 0) => {
        if (isNaN(parseFloat(size)) || !isFinite(size)) {
            return '?';
        }

        let unit = 0;

        while (size >= 1024 ) {
            size /= 1024;
            unit++;
        }

        return core.round10(size, precision) + ' ' + units[unit];
    };
}
