import { NgModule } from '@angular/core';
import { drawDataServiceProvider } from './ajs-upgraded-providers';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [drawDataServiceProvider],
})
export class DrawModule {}
