import { NgModule } from '@angular/core';
import { stepFactoryProvider } from './ajs-upgraded-providers';
import { WindowModule } from '@icc/legacy/configurator/steps/window/window.module';
import { ComplementaryGoodsModule } from '@icc/legacy/configurator/steps/complementary_goods/complementary-goods.module';
import { ModalService } from '@icc/helpers';
import { LegacyModalService } from './modal.service';
import { DoorModule } from './door/door.module';
import { RollerShutterModule } from './roller_shutter/roller-shutter.module';
import { MosquitoModule } from './mosquito/mosquito.module';

@NgModule({
    imports: [WindowModule, ComplementaryGoodsModule, DoorModule, RollerShutterModule, MosquitoModule],
    exports: [],
    declarations: [],
    providers: [
        stepFactoryProvider,
        {provide: ModalService, useClass: LegacyModalService}
    ],
})
export class StepsModule {}
