import { UnitConverterService } from '@icc/configurator/ui';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepComponent, _ } from '@icc/configurator/shared';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { DimensionsService } from '@icc/legacy/configurator/steps/awning/dimensions.service';
import { ConfigurationsService } from '@icc/common';


@Component({
    selector: 'icc-awning-dimensions',
    templateUrl: './awning-dimensions.component.html',
    styleUrls: ['./awning-dimensions.component.scss'],
})
export class AwningDimensionsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Wymiary markizy');
    static stepIcon = {
        ligature: 'settings_overscan',
    };
    
    form: FormGroup;
    private subscriptions: any[] = [];
    outreachesWidths: any = [];
    outreaches : any = [];
    outreachWidthFrom : number = 1;
    outreachWidthTo : number = 1;
    public configurator = 'awning';
    public stepId = 'dimensions';
    public title = _('COLOR|Podaj wymiary markizy:');

    dimensionUnit = this.unitConverterService.getUnit();

    constructor(
        private dimensionService:DimensionsService, 
        private configurationsService:ConfigurationsService<'awning'>,
        private fb: FormBuilder,
        private unitConverterService: UnitConverterService
        ) {
        super();
    }

    ngOnInit() {
        this.outreaches = this.dimensionService.getOutreaches();
        this.outreachesWidths = this.dimensionService.getOutreachesWidth();

        const outreachWidth = this.outreachesWidths[String(this.outreaches[0])];
        this.outreachWidthFrom = outreachWidth.width_from;
        this.outreachWidthTo = outreachWidth.width_to;

        this.form = this.fb.group({
            selectedOutreach: [this.outreaches[0], Validators.required],
            currentWidth: [
                1,
                [Validators.min(this.outreachWidthFrom),
                Validators.max(this.outreachWidthTo),
                Validators.required],
            ],
        });

        this.subscriptions.push(
            this.form.get('selectedOutreach').valueChanges.subscribe((outreach) => {
                const outreachWidth = this.outreachesWidths[String(outreach)];
                this.outreachWidthFrom = outreachWidth.width_from;
                this.outreachWidthTo = outreachWidth.width_to;

                const widthControl = this.form.get('currentWidth')
                widthControl.setValidators([Validators.min(this.outreachWidthFrom), Validators.max(this.outreachWidthTo)])
                widthControl.updateValueAndValidity();
            }),
            this.form.valueChanges.subscribe((c)=>{
                const outreach = this.form.get('selectedOutreach').value;
                const width = this.form.get('currentWidth').value;

                this.dimensionService.selectSize(outreach, width, null, null);
            })
        );

        if (this.configurationsService.conf.Current) {
            const outreach = this.outreaches.find(o => o === this.configurationsService.conf.Current.Outreach) || this.outreaches[0];
            const width = this.configurationsService.conf.Current.Width;

            const outreachWidth = this.outreachesWidths[String(outreach)];
            this.outreachWidthFrom = outreachWidth.width_from;
            this.outreachWidthTo = outreachWidth.width_to;

            const widthControl = this.form.get('currentWidth');
            const outreachControl = this.form.get('selectedOutreach');
            outreachControl.setValue(outreach, {emitEvent:false});
            widthControl.setValue(width, {emitEvent:false});
            widthControl.setValidators([Validators.min(this.outreachWidthFrom), Validators.max(this.outreachWidthTo)])
            widthControl.updateValueAndValidity();
        }
    }

    get currentWidth() { return this.form.get('currentWidth'); }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }
}
