<button mat-flat-button color="primary" (click)="addAccessories()">{{ 'INTERFACE|Dodaj akcesoria' | translate }}</button>

<div *ngIf="accessories?.length > 0" class="icc-list">
  <h4>{{ 'AWNING|Akcesoria' | translate }}</h4>
  <div *ngFor="let accessory of accessories" class="icc-list-item">
    <img [src]="accessory?.imageUrl" [alt]="accessory?.name" defaultImg>
    <div class="info-content">
        <ng-container>{{ accessory?.title }}</ng-container>
        <p class="item-action">
          <button mat-raised-button color="primary" (click)="removeAccessory(accessory)">{{ 'INTERFACE|Usuń'| translate }}</button>
        </p>
     </div>
  </div>
</div>