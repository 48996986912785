import { core } from "@icc/common/helpers";

export default class SystemsComparisonDetailsModalController {
    resolve: {
        config;
        currentConf;
        currency;
        onOffer;
        drawOptions;
        allowEdit;
        showPrevButton;
        showNextButton;
    };
    close: ({ $value }) => any;
    dismiss: () => any;

    constructor(
        private $rootScope,
        private ScrollbarService,
        private SystemsComparisonService,
        private InfoService,
        private $translate,
        public IccConfig,
    ) {}

    $onChanges() {
        this.ScrollbarService.update();
    }

    isPrice(price) {
        return !isNaN(price);
    }

    closeModal() {
        this.close({ $value: { config: false } });
    }

    prevStep() {
        this.close({ $value: { prevStep: true } });
    }

    nextPosition() {
        this.close({ $value: { nextPosition: true } });
    }

    finish() {
        this.close({ $value: { nextPosition: true, finish: true } });
    }

    changeSystem() {
        this.InfoService.confirmModal(
            this.$translate.instant('OFFER|Potwierdzenie zmiany systemu'),
            this.$translate.instant('OFFER|Czy na pewno chcesz zmienić system?'),
            [
                {
                    name: this.$translate.instant('INTERFACE|Tak'),
                    callback: () => {
                        this.close({ $value: { config: this.resolve.config } })
                    }
                },
                {
                    name:this.$translate.instant('INTERFACE|Nie'),
                    callback: () => {},
                    accent: true,
                },
            ]
        );
    }

    async changeColor() {
        await this.SystemsComparisonService.changeColor(this.resolve.config);
        this.resolve.config.defaultConf.Colors = core.copy(this.resolve.config.conf.Colors);
        this.SystemsComparisonService.getDetails(this.resolve.config);
    }

    changeFilling(sashId = null, intSashId = null) {
        this.SystemsComparisonService.changeFilling(
            this.resolve.config,
            sashId,
            intSashId
        );
    }

    edit(config) {
        this.close({ $value: { config, edit: true } });
    }
}
