import angular from 'angular';

/**
 * Kontroler klamek
 * @param {object} $scope         Angular Scope
 * @param {object} HandlesService Fabryka klamek
 * @param {object} StepFactory    Fabryka krokow
 */
export default function HandlesCtrl($scope, Core, HandlesService, FittingService, StepFactory, ConfigurationsService, IccConfig, systemsByTagFilter, DrawService, EventBusService, GtmService, ScrollbarService, $filter) {
    'ngInject';

    const vm = this;
    let conf = ConfigurationsService.conf.Current;
    vm.subStep = 1;
    vm.isMatch;
    vm.openModalHandle       = openModalHandle;
    vm.openModalHinge        = HandlesService.openModalHinge.bind(HandlesService);
    vm.selectFitting         = HandlesService.selectFitting.bind(HandlesService);
    vm.selectHandleColor     = HandlesService.setHandleColor.bind(HandlesService);
    vm.setHandleType         = HandlesService.setHandleType.bind(HandlesService);
    vm.fittings              = [];
    vm.colors                = [];
    vm.selectedFittings      = [];
    vm.tags                  = [];
    vm.hasHandles            = true;
    vm.isLastStep            = false;
    vm.isDoorVersion2        = IccConfig.Configurators.door.version == 2 && conf.type == 'door';
    vm.showInnerLeverBox     = () =>
        conf.HandleType == 'DoubleLever'
        || conf.HandleType == 'LeverPull'
        || conf.HandleType == 'InnerLever';
    vm.showInnerPullBox      = () => conf.HandleType == 'DoublePull';
    vm.showOuterPullBox      = () => conf.HandleType == 'DoublePull' || conf.HandleType == 'LeverPull';
    vm.showWindowLeverBox    = () => conf.OwnedSashesTypes.window && conf.Sashes.some(el => !['F', 'FF', 'OFF', 'DRA', 'DRP', 'DOA', 'DOP'].includes(el.type.type)) && conf.HasHandle && vm.isDoorVersion2;
    vm.selectedTag           = selectedTag;

    vm.drawOptions = angular.merge({}, DrawService.options, {
        sashFrame: {
            buttons: [
                {
                    condition: response => ['F', 'FF', 'OFF', 'DS', 'DSC', 'ODS', 'DRP', 'DOP', 'FD', 'FDO'].indexOf(response.sash.type.type) === -1,
                    innerHtml: `<img src="/application/dist/web/img/icons/handler.png">`,
                    title: $filter('translate')('INTERFACE|Wybierz klamkę'),
                    onClick: response => {
                        const sash = Core.fIdO(ConfigurationsService.conf.Current.Sashes, response.sash.id);
                        openModalHandle(sash, 'innerLever');
                    },
                },
            ],
        },
        onInterfaceClick: () => {
            $scope.$digest();
        }
    });

    vm.innerLeverBox = () => {
        return FittingService.innerLeverBox(conf);

    };

    vm.innerPullBox = () => {
        return FittingService.innerPullBox(conf);
    };

    vm.outerPullBox = () => {
        return FittingService.outerPullBox(conf);

    };

    vm.windowLeverBox = () => {
        return FittingService.windowLeverBox(conf);

    };

    vm.goToSubstep = subStep => {
        vm.subStep = subStep;
        if (IccConfig.GTM.allowTags) {
            EventBusService.post({
                key: 'googleTags',
                value: $scope.config.stepCode + vm.subStep
            });
        }
        if (IccConfig.Configurators.tutorialAvailable) {
            if (subStep == 2) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'fittingSub'
                });
            } else if (subStep == 1) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                });
            }
        }

    };

    if (HandlesService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('refreshHandlesColors', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjalizacyjna
     */
    function init() {
        var lastStep = StepFactory.getLastStep();

        vm.fittings   = HandlesService.fittings;
        vm.colors     = HandlesService.handleColors;
        vm.isLastStep = lastStep.code === 'fitting';
        vm.hasHandles = HandlesService.handles.length > 1;
        vm.tags       = HandlesService.tags.filter(tag => systemsByTagFilter(vm.fittings, [tag.id]).length > 0);
    }

    function openModalHandle(sash, place) {
        if (place == 'innerLever' && (sash === 'window' || sash && sash.type && sash.type.type != 'DRA' && sash.type.type != 'DOA')) {
            HandlesService.openModalHandle(sash, place, 'InnerLever');
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'modalHandleSash'
                });
            }
        } else {
            HandlesService.openModalHandle(sash, place, ConfigurationsService.conf.Current.HandleType);
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'modalHandle'
                });
            }
        }
    }

    function selectedTag(fittings) {
        vm.selectedFittings = fittings;
        ScrollbarService.update();
    }
}
