import * as angular from 'angular';

import LocksCtrl from './LocksCtrl';
import { LocksService } from './locks.service';
import { downgradeInjectable } from '@angular/upgrade/static';

export default angular.module('icc.configurator.steps.door.locks', [])
    .controller('LocksCtrl', LocksCtrl)
    .factory('LocksService', downgradeInjectable(LocksService))
    .name;
