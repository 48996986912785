import {
    ConfigurationsService,
    ConfigurationImpl,
    ActiveConfigurationImpl,
} from '@icc/common/configurations/configurations.service';
import { StateService } from '@icc/common/state.service';
import { ConfiguratorOptions, ConfigurationType, currencyExchange } from '@icc/configurator/shared';
import { IIccConfig } from '@icc/config';
import { LangService } from '../app/lang.service';
import {
    UserService,
    EventBusService,
    core,
    logger,
    Common,
    ConfiguratorsDataService,
    WindowActiveConfiguration,
    OfferSequenceService,
    WindowConfiguration,
} from '@icc/common';
import { BrowserConfigurationsService } from '../configurator/configurations.service';
import { RootScope } from '../app/RootScope';
import { CoupledWindowService } from '../configurator/coupled/coupled-window.service';
import { OffersService } from '../panel/offers.service';
import { RollerShutterActiveConfiguration } from '@icc/common/configurations/RollerShutterActiveConfiguration';
import { from, ReplaySubject } from 'rxjs';
import { NewPositionService } from '../panel/NewPositionService';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { CoupledWindowActiveConfiguration } from '@icc/common/configurations/CoupledWindowActiveConfiguration';
import { RollerShutterConfiguration } from '@icc/common/configurations/RollerShutterConfiguration';
import { LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { ComplementaryGoodsActiveConfiguration } from '@icc/common/configurations/ComplementaryGoodsActiveConfiguration';
import { SystemsComparisonService } from '../configurator/steps/window/systems-comparison/systems-comparison.service';

type ConfiguratorMode =
    | 'COMPLETELY_NEW'
    | 'NEW_NEXT'
    | 'NEW_FIRST_OFFER'
    | 'NEW_GROUP'
    | 'EDIT'
    | 'NEW_FROM_GROUP'
    | 'SIMILAR_FROM_GROUP'
    | 'COUPLED_WINDOW';

export class NewConfiguratorCtrl {
    options: Partial<ConfiguratorOptions> = {};
    private lastPositionGroupCode: null | string = null;
    private newPosition: any = null;

    constructor(
        private configurationsService: BrowserConfigurationsService,
        private $location: ng.ILocationService,
        private stateService: StateService,
        private IccConfig: IIccConfig,
        private EnvConfig: any,
        private LangService: LangService,
        private ProjectName: string,
        private Elements: string,
        private userService: UserService,
        private $rootScope: RootScope,
        private eventBusService: EventBusService,
        private coupledWindowService: CoupledWindowService,
        private offersService: OffersService,
        private PositionsFactory,
        private PositionAttachmentsFactory,
        private configuratorsDataService: ConfiguratorsDataService,
        private $translate: ng.translate.ITranslateService,
        private newPositionService: NewPositionService,
        private currentConfigurationService: CurrentConfiguratorService,
        machine: string,
        private offersGroupFactory,
        private $sce: ng.ISCEService,
        private systemsComparisonService: SystemsComparisonService,
        private theme,
        private $scope,
    ) {
        (window as any).__zone_symbol__BLACK_LISTED_EVENTS.length = 0;
        const user = this.userService.get();
        this.configurationsService.getOrInitConfiguratorConfigurations(currentConfigurationService.conf);
        this.options.returnCallback = data => {
            $location.url('/app/offers_view/' + stateService.state.offer_id);
        };
        this.options.config = IccConfig;
        this.options.env = EnvConfig;

        this.options.type = currentConfigurationService.conf;
        this.options.preset = user && user.access && user.access !== 'client' ? 'b2b' : 'b2c';
        this.options.userLevel = (user && user.access) || 'client';
        this.options.userName = (user && user.name) || '';
        this.options.marketId = (user && user.marketId) || '';
        this.options.user = user || null;
        this.options.machine = machine || '';
        this.options.languageCode = LangService.getLang();
        this.options.projectName = ProjectName;
        this.options.dimensionUnit = user && user.dimension_unit;
        this.options.numberOfPlacesAfterDecimalSeparator = user && user.number_of_places_after_decimal_separator;
        this.options.filterAccessoriesByCategories = user && user.filter_accessories_by_categories;
        this.options.weightUnit = user && user.weight_unit;
        this.options.userData = user;
        this.options.currency = core.copy(stateService.state.offer && stateService.state.offer.currency);
        this.options.currency.pricesInCurrency = false;
        this.options.theme = theme || IccConfig.Theme;
        this.options.logo =
            IccConfig.Dealer.changeHeaderLogo && user.access === 'dealer' && user.dealer.logo
                ? '/files/dealer/' + user.dealer.logo
                : IccConfig.DealerSeller.changeHeaderLogo
                  && user.access === 'dealerh'
                  && user.dealer_seller.logo
                ? '/files/dealerseller/' + user.dealer_seller.logo
                : $rootScope.themeLogo
                ? $rootScope.themeLogo
                : IccConfig.Theme.logo;
        this.init();
        this.options.actions = (!this.configurationsService.editedPosition[this.options.type] || this.configurationsService.copiedPosition) ? [
            {
                title: this.$translate.instant('INTERFACE|OK'),
                show: (conf, isBlocked) => isBlocked,
                callback: data => {},
            },
            {
                title: this.$translate.instant('OFFER|Konfiguruj nową pozycję'),
                show: (conf, isBlocked) =>
                    IccConfig.Configurators.similarPosition
                    && !(isBlocked || IccConfig.Configurators.newPositionChangeConfigurator),
                callback: data => {
                    this.newPositionFromGroup(true);
                },
                resetConfigurator: true
            },
            {
                title: this.$translate.instant('OFFER|Konfiguruj nową pozycję'),
                show: (conf, isBlocked) =>
                    !IccConfig.Configurators.similarPosition
                    && !(isBlocked || IccConfig.Configurators.newPositionChangeConfigurator),
                callback: data => {
                    this.newPositionFromGroup();
                },
                resetConfigurator: true
            },
            {
                title: this.$translate.instant('OFFER|Konfiguruj podobną pozycję'),
                show: (conf, isBlocked) => IccConfig.Configurators.similarPosition && !isBlocked,
                callback: data => {
                    this.newPositionFromGroup();
                    this.gtmSimilarPosition();
                },
                resetConfigurator: true
            },
            {
                title: this.$translate.instant('OFFER|Konfiguruj podobną pozycję'),
                show: (conf, isBlocked) => !IccConfig.Configurators.similarPosition && !isBlocked,
                callback: data => {
                    if (CoupledWindowActiveConfiguration.is(this.configurationsService.conf.Current)) {
                        this.$rootScope.loader = true;
                        const conf = <CoupledWindowActiveConfiguration>this.configurationsService.conf.Current;
                        const offerId = this.stateService.state.offer_id;
                        if (offerId) {
                            this.offersService.get(offerId).then(offer => {
                                this.PositionsFactory.copyPositionsFromScope(offer, offer.tmp_id, [
                                    ...conf.windows.map(window => window.positionId),
                                    ...conf.rollerShutters.map(rollerShutter => rollerShutter.positionId),
                                ]).then((relations: Record<string, string>) => {
                                    conf.windows = conf.windows.map(window => {
                                        window.positionId = relations[window.positionId];
                                        return window;
                                    });
                                    conf.rollerShutters = conf.rollerShutters.map(
                                        rollerShutter => {
                                            rollerShutter.positionId = relations[rollerShutter.positionId];
                                            return rollerShutter;
                                        }
                                    );
                                    conf.couplings = conf.couplings.map(
                                        coupling => {
                                            coupling.framesId.forEach(rel => {
                                                rel.positionId = relations[rel.positionId];
                                            });
                                            coupling.otherFramesId.forEach(rel => {
                                                rel.positionId = relations[rel.positionId];
                                            });
                                            return coupling;
                                        }
                                    );
                                    conf.sideProfiles = conf.sideProfiles.map(
                                        sideprofile => {
                                            sideprofile.framesId.forEach(rel => {
                                                rel.positionId = relations[rel.positionId];
                                            });
                                            return sideprofile;
                                        }
                                    );
                                    conf.windowSills = conf.windowSills.map(
                                        sill => {
                                            sill.framesId.forEach(rel => {
                                                rel.positionId = relations[rel.positionId];
                                            });
                                            return sill;
                                        }
                                    );
                                    this.coupledWindowService.currentCoupledWindow = conf;
                                    this.eventBusService.post({
                                        key: 'icc-redraw',
                                        value: null,
                                    });
                                    this.$rootScope.loader = false;
                                });
                            });
                        }
                    }
                    this.gtmSimilarPosition();
                }
            },
            {
                title: this.$translate.instant('OFFER|Konfiguruj nową pozycję'),
                show: (conf, isBlocked) =>
                    !(isBlocked || !IccConfig.Configurators.newPositionChangeConfigurator),
                callback: data => {
                    this.addProduct();
                },
                resetConfigurator: true
            },
            {
                title: this.$translate.instant('OFFER|Przejdź do oferty'),
                show: (conf, isBlocked) => !isBlocked,
                callback: data => {
                    this.saveRedirect();
                },
                resetConfigurator: true
            },
            {
                title: this.$translate.instant('OFFER|Utwórz konstrukcję złożoną'),
                show: (conf, isBlocked) =>
                    !isBlocked
                    && IccConfig.Configurators.coupledFrames
                    && WindowActiveConfiguration.is(conf)
                    && conf.Shape.shape === 'rect'
                    && user.access
                    && user.access !== 'klient',
                callback: data => {
                    this.createCoupledWindow();
                },
                resetConfigurator: true
            },
        ] : [
            {
                title: this.$translate.instant('INTERFACE|OK'),
                show: (conf, isBlocked) => isBlocked,
                callback: data => {},
            },
        ];
        this.options.notifyCallback = config => {
            return from(this.save(config.activeConfiguration, config.configuration, config.defaultConfiguration, config.image, false, true));
        };
        this.options.embeddedInB2B = true;

        const elements = this.parseElements(Elements);

        this.options.returnButton = (
            conf: ActiveConfigurationImpl,
            options: ConfiguratorOptions,
            data: LoadedConfiguratorsDataValue
        ) => {
            if (
                (!WindowActiveConfiguration.is(conf)
                    && !ComplementaryGoodsActiveConfiguration.is(conf)
                    && (user.access == 'Super Admin' || user.access == 'Administratorzy'))
            ) {
                return 'ADD';
            } else if (
                this.isAddButton(conf, IccConfig, user, options)
                && !this.systemsComparisonService.edit.value
                && elements['offer_add_button']
            ) {
                return 'ADD_TO_OFFER';
            } else if (
                options.editMode && this.configurationsService.conf.Edit &&
                (options.mode === 'loadStartState' && (!this.configurationsService.copiedPosition || this.$rootScope.coupledPositionEdit)
                    && user.access != 'Super Admin'
                    && user.access != 'Administratorzy'
                    && ((WindowActiveConfiguration.is(conf)
                        && conf.Sashes.length > 0
                        && !conf.ChangedDimensions)
                        || conf.type == 'roller_shutter'
                        || conf.type == 'external_blind'
                        || conf.type == 'winter_garden'
                        || (conf.type == 'complementary_goods'
                            && (conf.ComplementaryGoods.glass.length
                                || conf.ComplementaryGoods.windowsill.length
                                || conf.ComplementaryGoods.cassonetto.length
                                || conf.ComplementaryGoods.accessory.length
                                || conf.ComplementaryGoods.profile.length))
                        || (conf.type == 'awning' && conf.FabricStyle.id)
                        || conf.type == 'garage_door'
                        || conf.type == 'mosquito'
                        || conf.type === 'coupled_window'
                        || (conf.type !== 'awning'
                            && conf.type != 'complementary_goods'
                            && conf.type != 'pleated_blind'
                            && conf.Accessories.length))
                    && !this.systemsComparisonService.edit.value)
                || this.systemsComparisonService.edit.value
            ) {
                return 'SAVE';
            }
            return 'ADD';
        };

        this.options.init$ = new ReplaySubject();

        $rootScope.$on('$locationChangeSuccess', (event, newUrl, oldUrl) => {
            if (this.configurationsService.conf.Edit) {
                if (
                    newUrl.indexOf('/app/new_configurator/window') === -1
                    && newUrl.indexOf('/app/new_configurator/door') === -1
                    && newUrl.indexOf('/app/new_configurator/hs') === -1
                    && newUrl.indexOf('/app/new_configurator/folding_door') === -1
                    && newUrl.indexOf('/app/new_configurator/roller_shutter') === -1
                    && newUrl.indexOf('/app/new_configurator/sliding_door') === -1
                    && newUrl.indexOf('/app/new_configurator/complementary_goods') === -1
                ) {
                    delete this.configurationsService.conf.Edit;
                }
            }
            if(newUrl.indexOf('/app/offers_view/') === -1
                && newUrl.indexOf('/app/new_configurator/window') === -1
                && newUrl.indexOf('/app/new_configurator/door') === -1
                && newUrl.indexOf('/app/new_configurator/hs') === -1
                && newUrl.indexOf('/app/new_configurator/folding_door') === -1
                && newUrl.indexOf('/app/new_configurator/roller_shutter') === -1
                && newUrl.indexOf('/app/new_configurator/sliding_door') === -1
                && newUrl.indexOf('/app/new_configurator/complementary_goods') === -1
            ) {
                this.configurationsService.conf.Current = this.configurationsService.createActiveConfiguration();
                this.configurationsService.conf.Default = this.configurationsService.createActiveConfiguration();
            }
        });
        $scope.$on('$destroy', () => {
            this.systemsComparisonService.edit.value = false;
        });
    }


    async init() {
        if (
            Common.isDefined(this.$rootScope.completelyNewPosition)
            && this.$rootScope.completelyNewPosition === true
            && this.configurationsService.conf.Current.type !== 'coupled_window'
        ) {
            await this.loadConfigurator('COMPLETELY_NEW');
        } else {
            if (
                Common.isDefined(this.configurationsService.conf.Edit)
                && typeof this.configurationsService.conf.Edit === 'object'
                && Common.isDefined(this.configurationsService.conf.Edit.type)
            ) {
                await this.loadConfigurator('EDIT');
                if (IccConfig.Configurators.dependencies) {
                    this.eventBusService.post({ key: 'processDependencies', value: null });
                }
            } else if (this.configurationsService.conf.Current.type === 'coupled_window') {
                await this.loadConfigurator('COUPLED_WINDOW');
            } else if (
                (WindowActiveConfiguration.is(this.configurationsService.conf.Current)
                    && (Common.isDefined(this.configurationsService.conf.Current.System)
                        && Common.isDefined(this.configurationsService.conf.Current.System.id)))
                || ((WindowActiveConfiguration.is(this.configurationsService.conf.Current)
                    || RollerShutterActiveConfiguration.is(
                        this.configurationsService.conf.Current
                    ))
                    && (Common.isDefined(this.configurationsService.conf.Current.RollerShutter)
                        && Common.isDefined(
                            this.configurationsService.conf.Current.RollerShutter.system
                        )
                        && Common.isDefined(
                            this.configurationsService.conf.Current.RollerShutter.system.id
                        )
                        && Common.isUndefined(this.configurationsService.conf.addPositionToGroup)))
            ) {
                let posCount = {};
                if (this.stateService.state && Common.isObject(this.stateService.state.offer)) {
                    posCount = OfferSequenceService.getPositionsCountByTypeFromSequence(
                        this.stateService.state.offer.sequence
                    );
                }
                if(this.currentConfigurationService.conf !== 'door' || this.IccConfig.Offer.similarPositionForDoor) {
                    await this.newSimilarPosition(posCount && !posCount[this.currentConfigurationService.conf]);
                } else {
                    await this.newSimilarPosition(
                        posCount &&
                            !(
                                Object.keys(posCount).every((p) => p === 'window') &&
                                this.currentConfigurationService.conf === 'door'
                            )
                    );
                }

            } else {
                await this.loadConfigurator('NEW_FIRST_OFFER');
            }
            if (this.IccConfig.GTM.allowTags) {
                this.eventBusService.post({
                    key: 'googleTags',
                    value: this.currentConfigurationService.conf + '.',
                });
            }
        }
    }

    /**
     * Tworzenie nowej konstrukjcji podobnej do ostatnio dodanej
     */
    async newSimilarPosition(newOffer = false) {
        this.$rootScope.completelyNewPosition = false;
        if (
            !newOffer
            && ((WindowActiveConfiguration.is(this.configurationsService.conf.Current)
                && this.configurationsService.conf.Current.System
                && this.configurationsService.conf.Current.System.id)
                || this.$rootScope.coupledPosition)
        ) {
            await this.loadConfigurator('NEW_NEXT');
        } else if (
            Common.isDefined(this.configurationsService.conf.addPositionToGroup)
            && this.configurationsService.conf.addPositionToGroup
        ) {
            await this.loadConfigurator('NEW_GROUP');
            this.configurationsService.conf.addPositionToGroup = false;
        } else {
            await this.loadConfigurator('NEW_FIRST_OFFER');
        }
    }

    /**
     * Tworzenie nowej konstrukjcji podobnej do ostatnio dodanej
     */
    newPositionFromGroup(firstStep = false) {
        this.$rootScope.completelyNewPosition = false;
        if (firstStep) {
            this.loadConfigurator('SIMILAR_FROM_GROUP');
        } else {
            this.loadConfigurator('NEW_FROM_GROUP');
        }
    }

    async loadConfigurator(mode: ConfiguratorMode) {
        let configurationMode: 'NEW' | 'NEW_SIMILAR' | 'EDIT' | 'GROUP' | 'COUPLED' = 'NEW_SIMILAR';
        let stepsMode: 'FIRST' | 'DEFAULT';
        let stepsFireEvent: boolean = true;
        switch (mode) {
            case 'COMPLETELY_NEW':
                configurationMode = 'NEW';
                stepsMode = 'FIRST';
                stepsFireEvent = false;
                break;
            case 'NEW_NEXT':
                configurationMode = 'NEW_SIMILAR';
                stepsMode = 'DEFAULT';
                stepsFireEvent = false;
                break;
            case 'NEW_FIRST_OFFER':
                configurationMode = 'NEW';
                stepsMode = 'FIRST';
                stepsFireEvent = false;
                break;
            case 'NEW_GROUP':
                configurationMode = 'NEW_SIMILAR';
                stepsMode = 'DEFAULT';
                stepsFireEvent = false;
                break;
            case 'NEW_FROM_GROUP':
                configurationMode = 'GROUP';
                stepsMode = 'DEFAULT';
                stepsFireEvent = false;
                break;
            case 'SIMILAR_FROM_GROUP':
                configurationMode = 'GROUP';
                stepsMode = 'FIRST';
                stepsFireEvent = false;
                break;
            case 'EDIT':
                configurationMode = 'EDIT';
                stepsMode = 'DEFAULT';
                stepsFireEvent = false;
                break;
            case 'COUPLED_WINDOW':
                configurationMode = 'COUPLED';
                stepsMode = 'DEFAULT';
                stepsFireEvent = false;
                break;
        }
        this.options.startStep = stepsMode;
        this.options.configuratorDataLoaded = this.configuratorsDataService.loaded;
        await this.loadConfigurations(configurationMode);
        this.options.init$.next();
        if (this.$rootScope.$$phase) {
            this.$rootScope.$apply();
        }
    }

    async loadConfigurations(mode: 'NEW' | 'NEW_SIMILAR' | 'EDIT' | 'GROUP' | 'COUPLED') {
        if (mode !== 'NEW_SIMILAR') {
            let sourceConfiguration;

            if (mode === 'EDIT') {
                sourceConfiguration = this.configurationsService.conf.Edit;
                if (
                    sourceConfiguration.type === 'coupled_window'
                    && this.coupledWindowService.editing
                    && (!sourceConfiguration || sourceConfiguration.windows.length === 0)
                ) {
                    sourceConfiguration = this.coupledWindowService.currentCoupledWindow;
                }
            } else if (mode === 'COUPLED') {
                sourceConfiguration = this.coupledWindowService.currentCoupledWindow;
                if (this.coupledWindowService.editing) {
                    this.configurationsService.conf.Edit = sourceConfiguration;
                    this.configurationsService.editedPosition[sourceConfiguration.type] = {
                        _id: this.coupledWindowService.currentCoupledWindowId,
                    };
                }
            }

            if (sourceConfiguration) {
                this.options.editedPositionPrices = {
                    clientPrice: sourceConfiguration?.clientPrice,
                    dealerPrice: sourceConfiguration?.dealerPrice
                }
                this.options.startState = this.configurationsService.createSimpleConfiguration(
                    sourceConfiguration,
                    {
                        colors: this.configuratorsDataService.data.colors,
                        windowColorsAll: this.configuratorsDataService.data.windowColorsAll,
                    }
                );
                this.options.defaultState = this.configurationsService.createActiveConfiguration(
                    sourceConfiguration
                );
            } else {
                this.options.defaultState = null;
                this.configurationsService.conf.Default =  this.configurationsService.createActiveConfiguration();
            }
        }
        if (mode === 'GROUP') {
            const offer = await this.offersService.get(this.stateService.state.offer_id);
            if (Common.isDefined(offer.positions_groups_data[this.lastPositionGroupCode])) {
                this.offersGroupFactory.addPositionToGroupConf(
                    this.configurationsService.conf.conf,
                    offer.positions_groups_data[this.lastPositionGroupCode]
                );
            }
        }

        if (mode === 'NEW_SIMILAR') {
            const sourceConfiguration = this.configurationsService.conf.Default;
            this.options.startState = null;
            this.options.defaultState = sourceConfiguration;
        }

        this.options.mode = this.configurationsService.conf.Edit
            ? 'loadStartState'
            : 'loadAllDefaults';

        if (
            (mode === 'COUPLED' || mode === 'EDIT')
            && this.options.startState?.type === 'coupled_window'
            && CoupledWindowActiveConfiguration.is(this.options.startState)
        ) {
            this.coupledWindowService.setCoupledWindow(
                this.options.startState,
                mode === 'EDIT',
                this.configurationsService.editedPosition[this.options.startState.type]?._id
            );
        }
        this.options.measurementsAttachments = this.configurationsService.conf.measurementsAttachments;
    }

    /**
     * Funkcja zapisuje tworzon lub edytowan konstrukcję jako pozycja w ofercie
     * @param  {boolean} redirect  Przekierowanie
     * @param  {boolean} savePopup Popup
     */
    save(configuration: ActiveConfigurationImpl, details: ConfigurationImpl, defaultConfiguration: ActiveConfigurationImpl, image: Element, redirect = false, savePopUp) {
        if (this.systemsComparisonService.edit.value) {
            this.systemsComparisonService.saveConfig(true, configuration);
            return;
        }
        localStorage.setItem('been', 'true');
        const timeout = setTimeout(
            () =>
                logger.error(
                    new Error(
                        `Zapisywanie pozycji trwało dłużej niż ${
                            IccConfig.Raven.savePositionTimeout
                        }s.`
                    )
                ),
            IccConfig.Raven.savePositionTimeout * 1000
        );
        const isStandard =
            !isNaN(configuration.Price) && configuration.Price !== null ? true : false;

        const saveInfo = {
            error: true,
            message: '',
        };
        this.configurationsService.conf.Current = configuration;
        const confFactory = this.configurationsService.getOrInitConfiguratorConfigurations(
            configuration.type,
           configuration
        );
        confFactory.Current = configuration;
        confFactory.Default = defaultConfiguration;
        confFactory.Edit = this.configurationsService.conf.Edit;
        confFactory.copiedPosition = this.configurationsService.conf.copiedPosition;
        confFactory.addPositionToGroup = this.configurationsService.conf.addPositionToGroup;
        this.configurationsService.conf = confFactory;
        this.eventBusService.setCurrentConfiguration(this.configurationsService.conf);

        const offerId = this.stateService.state.offer_id;
        return new Promise((resolve, reject) => {
            if (offerId) {
                this.offersService.get(offerId).then(doc => {
                    delete this.configurationsService.conf.addPositionToGroup;
                    localStorage.removeItem('colors');
                    let newPosition;
                    if (~~doc.order && !isStandard) {
                        this.$rootScope.showInfo(
                            this.$translate.instant(
                                'OFFER|Pozycje bez wyceny można dodać tylko do oferty, nie podczas edycji zamówienia.'
                            )
                        );
                    } else if (
                        !this.configurationsService.conf
                        || !this.configurationsService.editedPosition[configuration.type]?._id
                        || (this.configurationsService.copiedPosition && !this.$rootScope.coupledPositionEdit)
                    ) {
                        this.PositionsFactory.add({
                            configuration: configuration,
                            details,
                            offer: doc,
                            image: core.svgToPngData(image),
                            price: currencyExchange(configuration.Price, doc.currency),
                            price_no_margin: currencyExchange(configuration.PriceNoMargin, doc.currency),
                            custom_title: configuration.Title,
                            description: configuration.Description,
                            standard: isStandard,
                        })
                            .then(pos => {
                                newPosition = pos;
                                this.newPosition = pos;
                                this.lastPositionGroupCode = pos.groupCode;
                                return this.PositionAttachmentsFactory.addMulti(
                                    configuration.Attachments || [],
                                    pos
                                );
                            })
                            .then(() => {
                                if (
                                    this.configurationsService.conf
                                    && (this.configurationsService.copiedPosition
                                        || this.configurationsService.editedPosition[configuration.type]?._id)
                                ) {
                                    delete this.configurationsService.conf.Edit;
                                    delete this.configurationsService.conf.copiedPosition;
                                    this.configurationsService.editedPosition[configuration.type] = null;
                                    this.configurationsService.copiedPosition = false;
                                    if(this.IccConfig.Offer.completelyNewPosition) {
                                        this.configurationsService.conf.Default = this.configurationsService.createActiveConfiguration();
                                    }
                                }
                                if (
                                    !this.$rootScope.coupledPosition
                                    || configuration.type === 'coupled_window'
                                ) {
                                    if (savePopUp) {
                                        if (IccConfig.GTM.allowTags) {
                                            this.eventBusService.post({
                                                key: 'googleTags',
                                                value: 'savePopUp',
                                            });
                                        }
                                    }
                                    this.saveCoupledWindows(
                                        configuration,
                                        newPosition,
                                        doc
                                    ).then(() => {
                                        if (
                                            redirect
                                            || !IccConfig.Configurators.showAfterAddingWindow
                                        ) {
                                            this.eventBusService.setCurrentConfiguration(this.configurationsService.conf);
                                            resolve(saveInfo);
                                            this.saveRedirect();
                                        } else {
                                            saveInfo.message = this.$translate.instant(
                                                'OFFER|Konstrukcja została dodana do oferty'
                                            );
                                            saveInfo.error = false;
                                            this.eventBusService.setCurrentConfiguration(this.configurationsService.conf);
                                            resolve(saveInfo);
                                        }
                                    });
                                } else {
                                    if (
                                        WindowConfiguration.is(details)
                                        || RollerShutterConfiguration.is(details)
                                    ) {
                                        this.coupledWindowService.addNewElement(
                                            newPosition.tmp_id,
                                            details,
                                            newPosition.groupCode
                                        );
                                        this.$location.url('/app/new_configurator/coupled_window');
                                    }
                                }
                                clearTimeout(timeout);
                            });
                    } else {
                        delete this.configurationsService.conf.addPositionToGroup;
                        const details = this.configurationsService.createSimpleConfiguration(
                            configuration
                        );

                        this.PositionsFactory.updateEdit(this.configurationsService.editedPosition[configuration.type]?._id ? this.configurationsService.editedPosition[configuration.type]?._id : null, {
                            configuration: configuration,
                            details,
                            offer: doc,
                            image: core.svgToPngData(image),
                            price: currencyExchange(configuration.Price, doc.currency),
                            price_no_margin: currencyExchange(configuration.PriceNoMargin, doc.currency),
                            custom_title: configuration.Title,
                            description: configuration.Description,
                            standard: isStandard,
                            valuated_price: null,
                        })
                            .then(pos => {
                                newPosition = pos;
                                this.newPosition = pos;
                                return this.PositionAttachmentsFactory.addMulti(
                                    configuration.Attachments || [],
                                    pos
                                );
                            })
                            .then(() => {
                                return this.PositionAttachmentsFactory.removeMulti(
                                    (WindowActiveConfiguration.is(configuration) && configuration.DeletedAttachments) || []
                                );
                            })
                            .then(
                                () => {
                                    this.$rootScope.coupledPositionEdit = false;
                                    delete this.configurationsService.conf.Edit;
                                    this.configurationsService.editedPosition[configuration.type] = null;
                                    if(this.IccConfig.Offer.completelyNewPosition) {
                                        this.configurationsService.conf.Default = this.configurationsService.createActiveConfiguration();
                                    }

                                    if (
                                        !this.$rootScope.coupledPosition
                                        || configuration.type === 'coupled_window'
                                    ) {
                                        this.saveCoupledWindows(
                                            configuration,
                                            newPosition,
                                            doc
                                        ).then(() => {
                                            this.newSimilarPosition().then(() => {
                                                if (
                                                    Common.isDefined(redirect)
                                                    || !IccConfig.Configurators
                                                        .showAfterAddingWindow
                                                ) {
                                                    this.eventBusService.setCurrentConfiguration(this.configurationsService.conf);
                                                    this.saveRedirect();
                                                    resolve(saveInfo);
                                                } else {
                                                    saveInfo.message = this.$translate.instant(
                                                        'OFFER|Edycja konstrukcji została zapisana'
                                                    );
                                                    saveInfo.error = false;
                                                    this.eventBusService.setCurrentConfiguration(this.configurationsService.conf);
                                                    resolve(saveInfo);
                                                }
                                                clearTimeout(timeout);
                                            });
                                        });
                                    } else {
                                        if (
                                            WindowConfiguration.is(details)
                                            || RollerShutterConfiguration.is(details)
                                        ) {
                                            this.coupledWindowService.updateElement(
                                                newPosition.tmp_id,
                                                details,
                                                newPosition.groupCode
                                            );
                                            clearTimeout(timeout);
                                            this.$location.url('/app/new_configurator/coupled_window');
                                        }
                                    }
                                },
                                e => {
                                    logger.error(e);
                                    reject(saveInfo);
                                }
                            );
                    }
                });
            } else {
                if (!this.stateService.state.offer_id) {
                    saveInfo.message = this.$translate.instant('OFFER|Nie wybrano ofery');
                }
                savePopUp = savePopUp;
                saveInfo.error = true;
                resolve(saveInfo);
                clearTimeout(timeout);
            }
        });
    }

    /*
     * Przekierowanie do oferty po dodaniu pozycji
     */
    saveRedirect() {
        if (IccConfig.GTM.allowTags) {
            this.eventBusService.post({
                key: 'googleTags',
                value: 'saveRedirect',
            });
        }
        (window as any).__zone_symbol__BLACK_LISTED_EVENTS.push(...(window as any).__icc_black_listed_events);
        this.$location.url('/app/offers_view/' + this.stateService.state.offer_id);
    }

    /**
     * Dodawanie nowej pozycji
     */
    addProduct() {
        const offerId = this.stateService.state.offer_id;
        this.offersService.get(offerId).then(offer => {
            this.newPositionService.openModal(
                offer,
                () => {
                    this.$location.url('/app/offers_view/' + offerId);
                },
                () => {
                    this.newSimilarPosition();
                }
            );
        });
    }

    /**
     * Wysłanie do gtm info o tworzeniu podobnej pozycji
     */
    gtmSimilarPosition() {
        this.$rootScope.completelyNewPosition = false;
        if (this.IccConfig.GTM.allowTags) {
            this.eventBusService.post({
                key: 'googleTags',
                value: 'similarPosition',
            });
        }
        if (this.IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'getStepImg',
            });
        }
    }

    createCoupledWindow() {
        const offerId = this.stateService.state.offer_id;
        if (offerId) {
            this.offersService.get(offerId).then(offer => {
                this.coupledWindowService.addCoupledWindow(this.newPosition, offer);
            });
        }
    }

    async saveCoupledWindows(conf: ActiveConfigurationImpl, coupledPosition: any, offer) {
        if (conf.type === 'coupled_window') {
            const changes: {
                positionIds: string[];
                changes: any
            }[] = [];
            if (conf.windows.length > 0) {
                changes.push({
                    positionIds: conf.windows.map(window => window.positionId),
                    changes: {
                        coupled_position_id: coupledPosition.tmp_id,
                        quantity: coupledPosition.quantity,
                    }
                });
            }

            if (conf.rollerShutters.length > 0) {
                changes.push({
                    positionIds: conf.rollerShutters.map(rollerShutter => rollerShutter.positionId),
                    changes: {
                        coupled_position_id: coupledPosition.tmp_id,
                        quantity: coupledPosition.quantity,
                    }
                });
            }

            if (conf.detachedPositions.length > 0) {
                changes.push({
                    positionIds: conf.detachedPositions,
                    changes: { coupled_position_id: null }
                });

            }
            if (changes.length > 0) {
                await this.PositionsFactory.updateMany(
                    changes,
                    offer
                );
            }

            if (conf.removedPositions.length > 0) {
                await this.PositionsFactory.removeMany(conf.removedPositions, offer);
            }


            this.coupledWindowService.editing = false;
        }
    }

    /**
     * Tworzy html z tresci elementow dodatkowych
     * @param  {object} source Object z elementami dodatkowymi
     * @return {object}        Poprawiny objekt
     */
    parseElements(source) {
        var elements = {};
        for (var i in source) {
            if (typeof source[i].content === 'string') {
                elements[i] = Common.extend(source[i], {
                    content: this.$sce.trustAsHtml(source[i].content),
                });
            }
        }
        return elements;
    }

    isAddButton(
        conf: ActiveConfigurationImpl,
        IccConfig: IIccConfig,
        user: any,
        options: ConfiguratorOptions
    ) {
        if (!Common.isObject(conf)) {
            return false;
        }

        if (conf.type == 'awning') {
            return conf.FabricStyle.id > 0;
        } else {
            return (
                user.access != 'Super Admin'
                && user.access != 'Administratorzy'
                && options.mode !== 'loadStartState'
                && ((WindowActiveConfiguration.is(conf)
                    && Common.isArray(conf.Sashes)
                    && conf.Sashes.length > 0
                    && !conf.ChangedDimensions)
                    || ((conf.type == 'roller_shutter' || conf.type == 'external_blind')
                        && conf.RollerShutter.shutters.length > 0)
                    || conf.type == 'garage_door'
                    || conf.type == 'mosquito'
                    || conf.type == 'coupled_window'
                    || conf.type == 'winter_garden'
                    || (conf.type == 'accessory'
                        && Common.isArray(conf.Accessories)
                        && conf.Accessories.length)
                    || (conf.type != 'complementary_goods'
                        && conf.type != 'pleated_blind'
                        && Common.isArray(conf.Accessories)
                        && conf.Accessories.length)
                    || (conf.type == 'complementary_goods'
                        && conf.ComplementaryGoods
                        && (conf.ComplementaryGoods.glass.length
                            || conf.ComplementaryGoods.windowsill.length
                            || conf.ComplementaryGoods.cassonetto.length
                            || conf.ComplementaryGoods.accessory.length
                            || conf.ComplementaryGoods.profile.length)))
            );
        }
    }
}
NewConfiguratorCtrl.$inject = [
    'ConfigurationsService',
    '$location',
    'StateService',
    'IccConfig',
    'EnvConfig',
    'LangService',
    'ProjectName',
    'Elements',
    'UserService',
    '$rootScope',
    'EventBusService',
    'CoupledWindowService',
    'OffersService',
    'PositionsFactory',
    'PositionAttachmentsFactory',
    'ConfiguratorsDataService',
    '$translate',
    'NewPositionService',
    'CurConfService',
    'machine',
    'OffersGroupFactory',
    '$sce',
    'SystemsComparisonService',
    'theme',
    '$scope'
];
