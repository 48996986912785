import {Injectable, Inject} from '@angular/core';
import {APP_CONFIG, AppConfigFactory} from '@icc/common/config';;
import { UserService } from '@icc/common/user.service';
import { EventBusService } from '@icc/common';

/**
 * Fabryka użytkowników
 */
@Injectable()
export class ConfiguratorUserService extends UserService {

    constructor(@Inject(APP_CONFIG) private config: AppConfigFactory, private eventBusService: EventBusService) {
        super();
        this.loadUser();
    }

    setUser(user: any) {
        this.user = user;
        this.eventBusService.setCurrentUser(this.user);
    }

    /**
     * Ładuje dane użytkownika.
     */
    loadUser() {
        this.user = {
            marketDefaults: {
                defaultSealColor: 1
            }
        };
        this.eventBusService.setCurrentUser(this.user);
    }

    async synchronizeUser() {}
}
