import { PriceFunc, PriceElemsData, PriceSegment } from './Prices';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { Injectable, Inject } from '@angular/core';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { UserService } from '@icc/common/user.service';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/Core';

@Injectable()
export class PriceRollerService {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private userService: UserService,
        private parametersService: ParametersService
    ) {}

    /**
     * Dopłata rolety
     * @param  {Number} price         Cena
     * @param  {Object} PriceElems    Cena elementów
     * @param  {Object} NoPriceCauses Powody braku ceny
     * @param  {Object} conf          Konfiguracja
     * @param  {Object} commonRail    Wspólna szyna
     * @return {Bool}                 Wspólna szyna
     */
    @PriceFunc({
        shortName: 'rollerShutter',
        data: {
            rollerShutter: 'conf.RollerShutter',
            hasRoller: 'conf.hasRoller',
            type: 'conf.type',
            consolesPrices: 'data.rollerShutterConsolesPrices',
            boxSizePrices: 'data.rollerShutterBoxSizesPrices',
            rollerPricesData: 'data.rollerPricesData',
            productPricelists: 'data.productPricelists',
            profilesPrices: 'data.profilesPrices',
            profiles: 'data.rollerShutterProfiles',
            markets: 'data.markets',
            colors: 'conf.Colors',
            cWidth: 'conf.Width',
            cHeight: 'conf.Height',
            accessories: 'data.windowAccessories',
            system: 'conf.System',
        },
    })
    suppRollerShutter(
        { PriceStack, PriceElems, NoPriceCauses, NoPriceElems }: PriceElemsData,
        {
            rollerShutter,
            hasRoller,
            type,
            consolesPrices,
            boxSizePrices,
            rollerPricesData,
            productPricelists,
            profilesPrices,
            profiles,
            markets,
            colors,
            cWidth,
            cHeight,
            accessories,
            system,
        }
    ): PriceSegment[] {
        let supp = 0;
        const market = this.userService.get().market;
        let rollerFactor = 1;
        let i = 0;
        const j: any = 0;
        const priceSegments: PriceSegment[] = [];
        const commonRail =
            (type === 'roller_shutter' || 'external_blind'
                ? this.config().IccConfig.Configurators.price.commonRail.rollerShutter
                : this.config().IccConfig.Configurators.price.commonRail.window) || false;

        if (!Common.isUndefined(rollerShutter) && (hasRoller || type === 'roller_shutter' || type === 'external_blind')) {
            const roller = rollerShutter;
            const rollerPricesFiltered = this.getPricesForRoller(
                rollerShutter,
                rollerPricesData,
                type,
                colors,
                false
            );
            if (
                Common.isObject(markets)
                && Common.isDefined(markets[market])
                && Common.isDefined(markets[market].factor_roller)
                && core.num(markets[market].factor_roller) > 0
            ) {
                rollerFactor = core.num(markets[market].factor_roller);
            }

            const shutterParts = [];

            if (rollerPricesFiltered.length > 0) {
                if (commonRail) {
                    let width = 0;
                    let height = 0;
                    let shuttersIds = [];
                    for (i = rollerShutter.shutters.length - 1; i >= 0; i--) {
                        const shutter = rollerShutter.shutters[i];

                        const hasMosquito = shutter.mosquito;
                        const rollerPricesMatched = rollerPricesFiltered.filter(
                            r =>
                                (parseInt(r.with_mosquito) === 1 && hasMosquito)
                                || (parseInt(r.with_mosquito) === 0 && !hasMosquito)
                                || parseInt(r.with_mosquito) === 2
                        );

                        const rollerPrice = rollerPricesMatched[0];

                        if (shutter.commonRail && Number(rollerPrice.price_method) === 1) {
                            width += shutter.realWidth;
                            if (this.config().IccConfig.Configurators.price.shutterHeightWithBox) {
                                height = shutter.realHeight + roller.realBoxHeight;
                            } else {
                                height = shutter.realHeight;
                            }
                            shuttersIds.push(shutter.id);
                        } else {
                            width += shutter.realWidth;
                            if (height === 0) {
                                if (
                                    this.config().IccConfig.Configurators.price.shutterHeightWithBox
                                ) {
                                    height = shutter.realHeight + roller.realBoxHeight;
                                } else {
                                    height = shutter.realHeight;
                                }
                            }

                            shuttersIds.push(shutter.id);

                            shutterParts.push({
                                shuttersIds: core.copy(shuttersIds),
                                width,
                                height,
                            });
                            if (rollerPricesMatched.length > 0) {
                                shuttersIds = [];
                                priceSegments.push(
                                    this.priceShutter(
                                        rollerPrice,
                                        height,
                                        width,
                                        roller,
                                        profiles,
                                        PriceElems,
                                        NoPriceCauses,
                                        rollerFactor
                                    )
                                );
                                width = 0;
                                height = 0;
                            } else {
                                priceSegments.push({
                                    type: 'rollerBoxes',
                                    baseValue: null,
                                    value: null,
                                    valueType: 'value',
                                    data: {},
                                });
                                NoPriceCauses.push('no roller shutter prices');
                                break;
                            }
                        }

                        if (
                            parseInt(rollerPrice.with_mosquito) === 2
                            && rollerPrice.product_pricelist_id > 0
                            && hasMosquito
                        ) {
                            if (
                                productPricelists.productPricelists
                                && rollerPrice.product_pricelist_id
                                    in productPricelists.productPricelists
                            ) {
                                const pricelist =
                                    productPricelists.productPricelists[
                                        rollerPrice.product_pricelist_id
                                    ];
                                if ('data' in pricelist) {
                                    const data = pricelist.data.find(d => {
                                        const shutterWidth = shutter.realWidth;
                                        const shutterHeight = this.config().IccConfig.Configurators
                                            .price.shutterHeightWithBox
                                            ? shutter.realHeight + roller.realBoxHeight
                                            : shutter.realHeight;
                                        return (
                                            shutterWidth >= parseInt(d.width_from)
                                            && shutterWidth <= parseInt(d.width_to)
                                            && (shutterHeight >= parseInt(d.height_from)
                                                && shutterHeight <= parseInt(d.height_to))
                                        );
                                    });

                                    if (data) {
                                        priceSegments.push(
                                            this.addProductPricelist(
                                                parseFloat(data.price),
                                                shutter.id,
                                                roller.type.id
                                            )
                                        );
                                    } else {
                                        priceSegments.push(
                                            this.addProductPricelist(
                                                null,
                                                shutter.id,
                                                roller.type.id
                                            )
                                        );
                                    }
                                }
                            }
                        }
                    }
                } else {
                    let height = 0;
                    if (this.config().IccConfig.Configurators.price.shutterHeightWithBox) {
                        height = rollerShutter.realRollerHeight + roller.realBoxHeight;
                    } else {
                        height = rollerShutter.realRollerHeight;
                    }
                    const hasMosquito =
                        Common.isArray(rollerShutter.shutters)
                        && rollerShutter.shutters.some(e => e.mosquito === true);
                    const rollerPricesMatched = rollerPricesFiltered.filter(
                        r =>
                            (parseInt(r.with_mosquito) === 1 && hasMosquito)
                            || (parseInt(r.with_mosquito) === 0 && !hasMosquito)
                            || parseInt(r.with_mosquito) === 2
                    );

                    let width = rollerShutter.realBoxWidth || cWidth;

                    shutterParts.push({
                        shuttersIds: rollerShutter.shutters.map(el => el.id),
                        width,
                        height,
                    });

                    if (rollerPricesMatched.length > 0) {
                        const rollerPrice = rollerPricesMatched[0];
                        priceSegments.push(
                            this.priceShutter(
                                rollerPrice,
                                height,
                                width,
                                roller,
                                profiles,
                                PriceElems,
                                NoPriceCauses,
                                rollerFactor
                            )
                        );

                        if (
                            parseInt(rollerPrice.with_mosquito) === 2
                            && rollerPrice.product_pricelist_id > 0
                            && hasMosquito
                        ) {
                            if (
                                productPricelists.productPricelists
                                && rollerPrice.product_pricelist_id
                                    in productPricelists.productPricelists
                            ) {
                                const pricelist =
                                    productPricelists.productPricelists[
                                        rollerPrice.product_pricelist_id
                                    ];
                                if (rollerShutter.shutters.length) {
                                    rollerShutter.shutters
                                        .filter(s => s && s.mosquito)
                                        .map(s => {
                                            const data = pricelist.data.find(d => {
                                                const shutterWidth = s.realWidth;
                                                const shutterHeight = this.config().IccConfig
                                                    .Configurators.price.shutterHeightWithBox
                                                    ? s.realHeight + roller.realBoxHeight
                                                    : s.realHeight;
                                                return (
                                                    shutterWidth >= parseInt(d.width_from)
                                                    && shutterWidth <= parseInt(d.width_to)
                                                    && (shutterHeight >= parseInt(d.height_from)
                                                        && shutterHeight <= parseInt(d.height_to))
                                                );
                                            });

                                            if (data) {
                                                priceSegments.push(
                                                    this.addProductPricelist(
                                                        parseFloat(data.price),
                                                        s.id,
                                                        roller.type.id
                                                    )
                                                );
                                            } else {
                                                priceSegments.push(
                                                    this.addProductPricelist(
                                                        null,
                                                        s.id,
                                                        roller.type.id
                                                    )
                                                );
                                            }
                                        });
                                }
                            }
                        }
                    } else {
                        priceSegments.push({
                            type: 'rollerBoxes',
                            baseValue: null,
                            value: null,
                            valueType: 'value',
                            data: {},
                        });
                        NoPriceCauses.push('no roller shutter prices');
                    }
                }
            } else {
                priceSegments.push({
                    type: 'rollerBoxes',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {},
                });
                NoPriceCauses.push('no roller shutter prices');
            }
            supp += priceSegments.reduce((prev, cur) => prev + parseFloat(cur.baseValue as any), 0);
            /**
             * Dopłaty za grupe koloru
             */
            const elemsColoring = {
                N: ['profile', 'endslat'],
                Z: ['box', 'endslat', 'guideRail', 'profile'],
                I: ['guideRail', 'profile', 'revision'],
            };
            if (!roller.inInsulation) {
                elemsColoring.N.push('boxInner', 'boxGuideOuter');
            }
            if (this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor) {
                elemsColoring.N.push('guideRail');
            }
            if (
                this.config().IccConfig.Configurators.roller_shutter.additionalColors
                && roller.system.available_revision_color
            ) {
                elemsColoring.N.push('revision');
            }
            if (
                this.config().IccConfig.Configurators.roller_shutter.additionalColors
                && this.config().IccConfig.Configurators.roller_shutter.boxSideSurfaceColor
            ) {
                elemsColoring.N.push('boxSideSurface');
            }
            if (
                this.config().IccConfig.Configurators.roller_shutter.revisionColorInZ
                && roller.system.available_revision_color
            ) {
                elemsColoring.Z.push('revision');
            }
            if (this.config().IccConfig.Configurators.roller_shutter.endSlatColor) {
                elemsColoring.I.push('endslat');
            }
            const colorSupp = 0;
            let priceVal = 0;
            const profileId = Common.isObject(roller.profile) ? roller.profile.id : null;

            priceSegments.push({
                type: 'rollerColorsPercent',
                baseValue: 1,
                value: 1,
                valueType: 'percent',
                data: {},
            });
            if (elemsColoring[roller.type.type]) {
                for (i = 0; i < elemsColoring[roller.type.type].length; i++) {
                    if (
                        roller.inInsulation
                        && elemsColoring[roller.type.type][i].indexOf('box') > -1
                    ) {
                        continue;
                    }
                    // dopłata % do elementu
                    if (
                        Common.isDefined(
                            roller.colors[elemsColoring[roller.type.type][i] + 'Charge']
                        )
                        && roller.colors[elemsColoring[roller.type.type][i] + 'Charge'] != null
                        && roller.colors[elemsColoring[roller.type.type][i] + 'Charge'] !== 0
                    ) {
                        priceVal =
                            1
                            + parseFloat(
                                roller.colors[elemsColoring[roller.type.type][i] + 'Charge']
                            )
                                / 100;
                        const priceValSupp =
                            (supp
                                * parseFloat(
                                    roller.colors[elemsColoring[roller.type.type][i] + 'Charge']
                                ))
                            / 100;

                        PriceElems.rollers.colors.push({
                            profile_id: profileId,
                            price: priceValSupp,
                            chargeType: elemsColoring[roller.type.type][i] + 'Charge',
                            charge: roller.colors[elemsColoring[roller.type.type][i] + 'Charge'],
                        });
                        priceSegments.push({
                            type: 'rollerColor',
                            baseValue: priceVal,
                            value: priceVal,
                            valueType: 'percent',
                            data: {
                                profile_id: profileId,
                                chargeType: elemsColoring[roller.type.type][i] + 'Charge',
                                charge:
                                    roller.colors[elemsColoring[roller.type.type][i] + 'Charge'],
                            },
                        });
                    }

                    // dopłata za m2 do elementu
                    if (
                        Common.isDefined(
                            roller.colors[elemsColoring[roller.type.type][i] + 'ChargeM2']
                        )
                        && roller.colors[elemsColoring[roller.type.type][i] + 'ChargeM2'] !== 0
                    ) {
                        let rollerWidth = cWidth;
                        let rollerHeight = cHeight;
                        if (this.config().IccConfig.Configurators.roller_shutter.realDimensions) {
                            if (this.config().IccConfig.Configurators.price.shutterHeightWithBox) {
                                rollerHeight += roller.realBoxHeight;
                            }
                            rollerWidth = this.realWidthForPrice(
                                roller,
                                profiles,
                                rollerWidth,
                                rollerShutter.shutters.map(el => el.id)
                            );
                            rollerHeight = this.realHeightForPrice(
                                roller.realBoxHeight,
                                rollerHeight
                            );
                        }

                        priceVal =
                            ((parseFloat(
                                roller.colors[elemsColoring[roller.type.type][i] + 'ChargeM2']
                            )
                                * rollerWidth
                                * rollerHeight)
                                / 1000000)
                            * rollerFactor;

                        PriceElems.rollers.colors.push({
                            profile_id: profileId,
                            price: priceVal,
                            chargeType: elemsColoring[roller.type.type][i] + 'ChargeM2',
                            charge: roller.colors[elemsColoring[roller.type.type][i] + 'ChargeM2'],
                        });
                        priceSegments.push({
                            type: 'rollerColor',
                            baseValue: priceVal,
                            value: priceVal,
                            valueType: 'value',
                            data: {
                                profile_id: profileId,
                                chargeType: elemsColoring[roller.type.type][i] + 'ChargeM2',
                                charge:
                                    roller.colors[elemsColoring[roller.type.type][i] + 'ChargeM2'],
                                typeId: roller.type.id,
                            },
                        });
                    }

                    // dopłata za szerokość do elementu
                    if (
                        Common.isDefined(
                            roller.colors[elemsColoring[roller.type.type][i] + 'ChargeW']
                        )
                        && roller.colors[elemsColoring[roller.type.type][i] + 'ChargeW'] !== 0
                    ) {
                        priceVal =
                            ((parseFloat(
                                roller.colors[elemsColoring[roller.type.type][i] + 'ChargeW']
                            )
                                * cWidth)
                                / 1000)
                            * rollerFactor;

                        PriceElems.rollers.colors.push({
                            profile_id: profileId,
                            price: priceVal,
                            chargeType: elemsColoring[roller.type.type][i] + 'ChargeW',
                            charge: roller.colors[elemsColoring[roller.type.type][i] + 'ChargeW'],
                        });
                        priceSegments.push({
                            type: 'rollerColors',
                            baseValue: priceVal,
                            value: priceVal,
                            valueType: 'value',
                            data: {
                                profile_id: profileId,
                                chargeType: elemsColoring[roller.type.type][i] + 'ChargeW',
                                charge:
                                    roller.colors[elemsColoring[roller.type.type][i] + 'ChargeW'],
                                typeId: roller.type.id,
                            },
                        });
                    }

                    // dopłata za wysokość do elementu
                    if (
                        Common.isDefined(
                            roller.colors[elemsColoring[roller.type.type][i] + 'ChargeH']
                        )
                        && roller.colors[elemsColoring[roller.type.type][i] + 'ChargeH'] !== 0
                    ) {
                        priceVal =
                            ((parseFloat(
                                roller.colors[elemsColoring[roller.type.type][i] + 'ChargeH']
                            )
                                * cHeight)
                                / 1000)
                            * rollerFactor;
                        PriceElems.rollers.colors.push({
                            profile_id: profileId,
                            price: priceVal,
                            chargeType: elemsColoring[roller.type.type][i] + 'ChargeH',
                            charge: roller.colors[elemsColoring[roller.type.type][i] + 'ChargeH'],
                        });
                        priceSegments.push({
                            type: 'rollerColors',
                            baseValue: priceVal,
                            value: priceVal,
                            valueType: 'value',
                            data: {
                                profile_id: profileId,
                                chargeType: elemsColoring[roller.type.type][i] + 'ChargeH',
                                charge:
                                    roller.colors[elemsColoring[roller.type.type][i] + 'ChargeH'],
                                typeId: roller.type.id,
                            },
                        });
                    }
                }
            }
            supp += colorSupp;

            if (Common.isDefined(roller.drive)) {
                if (this.config().IccConfig.Configurators.roller_shutter.separateDrivesAboveWidthThreshold) {
                    /*
                     * Dopłata za napęd
                     */
                    priceSegments.push(
                        ...this.suppDrive2(
                            roller,
                            PriceElems,
                            NoPriceCauses,
                            NoPriceElems,
                            rollerFactor
                        ),
                        ...this.suppHanger(roller)
                    );
                } else {
                    /*
                     * Dopłata za napęd
                     */
                    priceSegments.push(
                        ...this.suppDrive(
                            roller,
                            PriceElems,
                            NoPriceCauses,
                            NoPriceElems,
                            rollerFactor
                        ),
                        ...this.suppHanger(roller)
                    );
                }
                /*
                 * Dopłata za elementy sterowania
                 */
                priceSegments.push(
                    ...this.suppDriveElems(
                        supp,
                        roller,
                        shutterParts,
                        PriceElems,
                        NoPriceCauses,
                        rollerFactor
                    )
                );
            }
            if (roller.hasAssembly && type !== 'roller_shutter' && type !== 'external_blind') {
                /*
                 * Dopłata za montaż
                 */
                priceSegments.push(this.suppAssembly(PriceElems, rollerFactor, roller, system));
            }

            if (roller.realBoxHeight !== roller.originalBoxHeight) {
                /*
                 Doplata za zmiane wysokosci skrzynki
                 */
                priceSegments.push(
                    this.suppBoxSize(
                        supp,
                        roller.realBoxHeight,
                        roller.realBoxWidth,
                        roller.originalBoxHeight,
                        roller.system,
                        roller.colors,
                        PriceElems,
                        NoPriceCauses,
                        boxSizePrices,
                        rollerFactor,
                        roller.boxHeightLevel,
                        roller.type
                    )
                );
            }

            /*
             Doplata za konsole statyczne
             */
            priceSegments.push(
                this.suppConsole(
                    supp,
                    cHeight,
                    cWidth,
                    roller.system,
                    PriceElems,
                    NoPriceCauses,
                    consolesPrices,
                    rollerFactor,
                    roller.type
                )
            );

            /*
             Doplata za listwę podtynkową zewnętrzną
             */
            priceSegments.push(
                this.suppConcealedStrip(
                    supp,
                    cWidth,
                    roller.inInsulation,
                    PriceElems,
                    NoPriceCauses,
                    accessories,
                    rollerFactor,
                    roller.type
                )
            );

            /*
             Doplata za pancerz
             */
            priceSegments.push(this.suppProfile(roller.profile));
            /*
             Doplata za podział pancerza
             */
            if (roller.schemaId !== '2b' && roller.schemaId !== '3') {
                priceSegments.push(this.suppSlatDivision(roller));
            }

            /**
             * Dopłata za prowadnice
             */
            if (this.config().IccConfig.Configurators.roller_shutter.guides) {
                if (rollerShutter.guideRails && rollerShutter.guideRails.length) {
                    rollerShutter.guideRails.forEach(g => {
                        const profile = profiles.find(p => p.id === g.id);
                        const shutter = rollerShutter.shutters.find(s => s.id === g.rollerId);
                        if (profile && shutter) {
                            const height = shutter.realHeight;
                            let profilePrice = profilesPrices[g.id];
                            if (
                                profilePrice
                                && profilePrice.default
                                && profilePrice.default.roller_guide
                            ) {
                                profilePrice = profilePrice.default.roller_guide
                                    .map((p, index) => {
                                        p.id = index;
                                        return p;
                                    })
                                    .filter(
                                        p =>
                                            rollerShutter.colors.guideRail.groups
                                            && rollerShutter.colors.guideRail.groups
                                                .map(Number)
                                                .indexOf(Number(p.colorGroup)) > -1
                                    );
                                profilePrice = profilePrice.length ? profilePrice[0] : null;
                            }

                            if (profilePrice && profilePrice.price_length != null) {
                                priceSegments.push(
                                    this.guideRail(
                                        (height * profilePrice.price_length) / 1000,
                                        g.rollerId,
                                        g.side,
                                        g.id,
                                        rollerShutter.type.id
                                    )
                                );
                            } else {
                                priceSegments.push(
                                    this.guideRail(
                                        null,
                                        g.rollerId,
                                        g.side,
                                        g.id,
                                        rollerShutter.type.id
                                    )
                                );
                            }
                        } else {
                            priceSegments.push(
                                this.guideRail(
                                    null,
                                    g.rollerId,
                                    g.side,
                                    g.id,
                                    rollerShutter.type.id
                                )
                            );
                        }
                    });
                } else {
                    priceSegments.push(
                        this.guideRail(null, null, null, null, rollerShutter.type.id)
                    );
                }
            }

            /**
             * Dopłata za listwe koncowa
             */
            if (this.config().IccConfig.Configurators.roller_shutter.slats) {
                if (rollerShutter.slats && rollerShutter.slats.length) {
                    rollerShutter.slats.forEach(s => {
                        const profile = profiles.find(p => p.id === s.id);
                        const shutter = rollerShutter.shutters.find(
                            shutt => shutt.id === s.rollerId
                        );
                        if (profile && shutter) {
                            const width = shutter.realWidth;
                            let profilePrice = profilesPrices[s.id];
                            if (
                                profilePrice
                                && profilePrice.default
                                && profilePrice.default.roller_slat
                            ) {
                                profilePrice = profilePrice.default.roller_slat
                                    .map((p, index) => {
                                        p.id = index;
                                        return p;
                                    })
                                    .filter(
                                        p =>
                                            rollerShutter.colors.endslat.groups
                                            && rollerShutter.colors.endslat.groups
                                                .map(Number)
                                                .indexOf(Number(p.colorGroup)) > -1
                                    );
                                profilePrice = profilePrice.length ? profilePrice[0] : null;
                            }

                            if (profilePrice && profilePrice.price_length != null) {
                                priceSegments.push(
                                    this.slat(
                                        (width * profilePrice.price_length) / 1000,
                                        s.rollerId,
                                        s.id,
                                        rollerShutter.type.id
                                    )
                                );
                            } else {
                                priceSegments.push(
                                    this.slat(null, s.rollerId, s.id, rollerShutter.type.id)
                                );
                            }
                        }
                    });
                } else {
                    priceSegments.push(this.slat(null, null, null, rollerShutter.type.id));
                }
            }
        }

        return priceSegments;
    }

    /**
     * Dopłata za dobra komplementarne
     */
    addProductPricelist(price, shutterId, typeId): PriceSegment {
        return {
            type: 'rollerShutterMosquito',
            baseValue: price,
            value: price,
            valueType: 'value',
            data: {
                shutterId,
                typeId,
            },
        };
    }

    /**
     * Dopłata za prowadnice
     */
    guideRail(price, shutterId, side, profileId, typeId): PriceSegment {
        return {
            type: 'rollerShutterGuideRail',
            baseValue: price,
            value: price,
            valueType: 'value',
            data: {
                shutterId,
                side,
                profileId,
                typeId,
            },
        };
    }

    /**
     * Dopłata za listwe koncowa
     */
    slat(price, shutterId, profileId, typeId): PriceSegment {
        return {
            type: 'rollerShutterSlat',
            baseValue: price,
            value: price,
            valueType: 'value',
            data: {
                shutterId,
                profileId,
                typeId,
            },
        };
    }

    /**
     * Dopłata za sterowanie
     * @param  {number} price         Cena poczatkowa
     * @param  {object} roller        Konfiguracja rolety
     * @param  {number} height        Wsyokość rolety
     * @param  {number} width         Szerokość rolety
     * @param  {object} PriceElems    Elementy wyceny
     * @param  {array} NoPriceCauses  Powody braku ceny
     * @param  {array} NoPriceElems   Elementy niewycenione
     * @param  {number} rollerFactor  Wspolczynnik rynku dla rolet
     * @return {number}               Nowa cena
     */
    suppDrive(
        roller,
        PriceElems,
        NoPriceCauses,
        NoPriceElems,
        rollerFactor
    ): PriceSegment[] {
        const priceSegments = [];
        let width = 0;
        let height = 0;
        let weight = 0;
        let drivePrice = 0;
        let pricesList = [];
        for (let i = roller.shutters.length - 1; i >= 0; i--) {
            const shutter = roller.shutters[i];
            weight += this.parametersService.getRollerWeight(roller, shutter);

            if (
                shutter.commonRail
                && (roller.system.common_guide_drive === 'one'
                    || (roller.system.common_guide_drive === 'one_same_width'
                        && (!roller.shutters[i - 1]
                            || roller.shutters[i - 1].realWidth === shutter.realWidth)
                        && (!roller.shutters[i + 1]
                            || roller.shutters[i + 1].realWidth === shutter.realWidth)))
            ) {
                width += shutter.realWidth;
                weight += this.parametersService.getRollerWeight(roller, shutter);
                height = shutter.realHeight;
            } else {
                width += shutter.realWidth;
                weight += this.parametersService.getRollerWeight(roller, shutter);
                height = shutter.realHeight;

                if (roller.drive.type === 'manual') {
                    drivePrice = parseFloat(roller.drive.price) * rollerFactor;
                } else {
                    pricesList = roller.drive.prices.filter(function filterDrivePrices(
                        e
                    ) {
                        return (
                            weight <= Number(e.to.replace(',', '.'))
                            && (e.reel === roller.roundReel?.id || e.reel === '')
                            && ((roller.driveType === 'override' && ~~e.override)
                                || (roller.driveType !== 'override' && !~~e.override))
                            && width >= Number(e.from.replace(',', '.'))
                            && height <= Number(e.height.replace(',', '.'))
                        );
                    });
                    drivePrice = pricesList.length
                        ? parseFloat(pricesList[0].price) * rollerFactor
                        : NaN;
                    width = 0;
                    height = 0;
                    weight = 0;
                }

                if (isNaN(drivePrice)) {
                    NoPriceCauses.push('no set price for roller drive');

                    if (Common.isUndefined(NoPriceElems.rollers)) {
                        NoPriceElems.rollers = {};
                    }
                    NoPriceElems.rollers.drive = {
                        id: roller.drive.id,
                        name: roller.drive.name,
                        price: NaN,
                        type: roller.drive.type,
                        cause: 'no price',
                    };
                }
                priceSegments.push({
                    type: 'rollerDrive',
                    baseValue: isNaN(drivePrice) ? null : drivePrice,
                    value: isNaN(drivePrice) ? null : drivePrice,
                    valueType: 'value',
                    data: {
                        id: roller.drive.id,
                        name: roller.drive.name,
                        type: roller.drive.type,
                        typeId: roller.type.id,
                    },
                });
            }
        }
        PriceElems.rollers.drive = {
            id: roller.drive.id,
            name: roller.drive.name,
            price: priceSegments.reduce((prev, cur) => prev + cur.value, 0),
            type: roller.drive.type,
        };

        return priceSegments;
    }

    suppHanger(roller): PriceSegment[]{
        const priceSegments = [];
        if (roller.hanger && roller?.hanger.price){
            priceSegments.push({
                type: 'rollerDriveHanger',
                baseValue: isNaN(roller.hanger.price) ? null : roller.hanger.price,
                value: isNaN(roller.hanger.price) ? null : roller.hanger.price,
                valueType: 'value',
                data: {
                    id: roller.hanger.id,
                    name: roller.hanger.name_cms,
                },
            })
        }
        return priceSegments;
    }

    suppDrive2(
        roller,
        PriceElems,
        NoPriceCauses,
        NoPriceElems,
        rollerFactor
    ): PriceSegment[] {
        const priceSegments: PriceSegment[]  = [];
        roller.drive.prices = core.objToArray(roller.drive.prices || []);
        roller.drive.prices.sort(function sortDrivePrices(a, b) {
            return (
                parseFloat(a.price.replace(',', '.')) - parseFloat(b.price.replace(',', '.'))
            );
        });
        let width = 0;
        let height = 0;
        let weight = 0;
        roller.shutters.forEach((shutter, index) => {
            const shutterWeight = this.parametersService.getRollerWeight(roller, shutter);
            let drivePrice = 0;
            let pricesList = [];

            weight += shutterWeight;
            width += shutter.realWidth;
            if (this.config().IccConfig.Configurators.price.shutterHeightWithBox) {
                height = shutter.realHeight + roller.realBoxHeight;
            } else {
                height = shutter.realHeight;
            }
            const nextWidth = roller.shutters.length > index + 1 ? roller.shutters[index + 1].realWidth : null;
            if (!nextWidth || !roller.drive.one_drive_width_threshold || roller.drive.one_drive_width_threshold == 0 || Math.abs((nextWidth - shutter.realWidth)) / nextWidth > Number(roller.drive.one_drive_width_threshold) / 100 || roller.drive.type === 'manual') {
                if (roller.drive.type === 'manual') {
                    drivePrice = parseFloat(roller.drive.price) * rollerFactor;
                } else {
                    pricesList = roller.drive.prices.filter(function filterDrivePrices(e) {
                        return (
                            weight <= Number(e.to.replace(',', '.'))
                            && (e.reel === roller.roundReel?.id || e.reel === '')
                            && ((roller.driveType === 'override' && ~~e.override)
                                || (roller.driveType !== 'override' && !~~e.override))
                            && width >= Number(e.from.replace(',', '.'))
                            && height <= Number(e.height.replace(',', '.'))
                        );
                    });
                    drivePrice = pricesList.length
                        ? parseFloat(pricesList[0].price) * rollerFactor
                        : NaN;
                }

                if (isNaN(drivePrice)) {
                    NoPriceCauses.push('no set price for roller drive');

                    if (Common.isUndefined(NoPriceElems.rollers)) {
                        NoPriceElems.rollers = {};
                    }
                    NoPriceElems.rollers.drive = {
                        id: roller.drive.id,
                        name: roller.drive.name,
                        price: NaN,
                        type: roller.drive.type,
                        cause: 'no price',
                    };
                }
                priceSegments.push({
                    type: 'rollerDrive',
                    baseValue: isNaN(drivePrice) ? null : drivePrice,
                    value: isNaN(drivePrice) ? null : drivePrice,
                    valueType: 'value',
                    data: {
                        id: roller.drive.id,
                        name: roller.drive.name,
                        type: roller.drive.type,
                        typeId: roller.type.id,
                    },
                });
                weight = 0;
                width = 0;
                height = 0;
            }
        });

        return priceSegments;
    }

    /**
     * Dopłata za elementy sterowania
     * @param  {number} price         Cena poczatkowa
     * @param  {object} roller        Konfiguracja rolety
     * @param  {object} PriceElems    Elementy wyceny
     * @param  {array} NoPriceCauses  Powody braku ceny
     * @param  {number} rollerFactor  Wspolczynnik rynku dla rolet
     * @return {number}               Nowa cena
     */
    suppDriveElems(
        price,
        roller,
        shutterParts,
        PriceElems,
        NoPriceCauses,
        rollerFactor
    ): PriceSegment[] {
        const priceSegments: PriceSegment[] = [];
        if (roller.drive.type === 'manual') {
            if (Common.isDefined(roller.driveManual) && roller.driveManual !== null) {
                PriceElems.rollers.driveManual = {
                    id: roller.driveManual.id,
                    name: roller.driveManual.name,
                    price:
                        parseFloat(roller.driveManual.price) * rollerFactor * shutterParts.length,
                };
                priceSegments.push({
                    type: 'rollerDriveManual',
                    baseValue: isNaN(parseFloat(roller.driveManual.price))
                        ? null
                        : parseFloat(roller.driveManual.price) * rollerFactor * shutterParts.length,
                    value: isNaN(parseFloat(roller.driveManual.price))
                        ? null
                        : parseFloat(roller.driveManual.price) * rollerFactor * shutterParts.length,
                    valueType: 'value',
                    data: {
                        id: roller.driveManual.id,
                        name: roller.driveManual.name,
                        type: roller.drive.type,
                        typeId: roller.type.id,
                    },
                });
            }
        } else {
            if (Common.isDefined(roller.driveElements)) {
                for (let n = 0; n < roller.driveElements.length; n++) {
                    PriceElems.rollers.driveElems.push({
                        id: roller.driveElements[n].id,
                        name: roller.driveElements[n].name,
                        price: parseFloat(roller.driveElements[n].price_white) * rollerFactor,
                    });
                    priceSegments.push({
                        type: 'rollerDriveElems',
                        baseValue: isNaN(parseFloat(roller.driveElements[n].price_white))
                            ? null
                            : parseFloat(roller.driveElements[n].price_white) * rollerFactor,
                        value: isNaN(parseFloat(roller.driveElements[n].price_white))
                            ? null
                            : parseFloat(roller.driveElements[n].price_white) * rollerFactor,
                        valueType: 'value',
                        data: {
                            id: roller.driveElements[n].id,
                            name: roller.driveElements[n].name,
                            type: roller.drive.type,
                            typeId: roller.type.id,
                        },
                    });
                }
            }
        }

        return priceSegments;
    }

    /**
     * Dopłata za montaż rolety
     * @param  {number} price         Cena poczatkowa
     * @param  {object} roller        Konfiguracja rolety
     * @param  {object} PriceElems    Elementy wyceny
     * @param  {number} rollerFactor  Wspolczynnik rynku dla rolet
     * @return {number}               Nowa cena
     */
    suppAssembly(PriceElems, rollerFactor, roller, system): PriceSegment {
        let supp = this.config().IccConfig.Configurators.price.rollerAssemblyPrice;
        if (this.config().IccConfig.Configurators.roller_shutter.systemAssemblyToWindow && parseFloat(system['roller_assembly_price']) > 0) {
            supp = parseFloat(system['roller_assembly_price']);
        }
        if (!isNaN(rollerFactor)) {
            supp *= rollerFactor;
        }
        PriceElems.rollers.assembly = {
            price: supp,
        };
        return {
            type: 'rollerAssembly',
            baseValue: supp,
            value: supp,
            valueType: 'value',
            data: {
                typeId: roller.type.id,
            },
        };
    }

    /**
     * Wyszukuje cennik dla rolet
     * @param  {object} roller Konfiguracja rolety
     * @return {array}         Lista pasujacych cennikow
     */
    getPricesForRoller(roller, rollerPricesData, type, colors, oneMosquito = true, areaLimit=true) {
        let color;
        if (
            type === 'roller_shutter' || type === 'external_blind'
            || this.config().IccConfig.Configurators.extendedRollerInWindow === 'full'
        ) {
            color = this.getColorTypeForRollers(
                roller.colors.boxInner,
                roller.colors.boxGuideOuter
            );
        } else {
            color = this.getColorTypeForRollers(
                colors.frame.inner?.id ? colors.frame.inner : colors.frame.core,
                colors.frame.outer?.id ? colors.frame.outer : colors.frame.core
            );
        }

        return (rollerPricesData || []).filter(el => {
            let check = true;
            if (Common.isDefined(roller.system) && roller.system !== null) {
                check = check && el.systems && !!el.systems[roller.system.id];
            }
            if (Common.isDefined(roller.profile) && roller.profile !== null) {
                check = check && el.profiles && !!el.profiles[roller.profile.id];
            }
            if (
                Common.isObject(roller.roundReel)
                && roller.roundReel !== null
                && this.config().IccConfig.Configurators.roller_shutter.roundReels
            ) {
                check = check && (el.round_reel_id === roller.roundReel.id || !~~el.round_reel_id);
            }
            if (Common.isArray(roller.shutters) && oneMosquito) {
                const hasMosquito = roller.shutters.some(e => e.mosquito === true);
                check =
                    check
                    && ((parseInt(el.with_mosquito) === 1 && hasMosquito)
                        || (!~~parseInt(el.with_mosquito) && !hasMosquito)
                        || parseInt(el.with_mosquito) === 2);
            }
            if (Common.isDefined(roller.drive) && roller.drive !== null) {
                check = check && (!el.drives || !Object.keys(el.drives).length || !!el.drives[roller.drive.id]);
            }
            if (Common.isDefined(roller.driveManual) && roller.driveManual !== null && this.config().IccConfig.Configurators.roller_shutter.configuratorDimensionalRestriction) {
                check = check && (!el.drives_manuals || !Object.keys(el.drives_manuals).length || !!el.drives_manuals[roller.driveManual.id]);
            }
            if(areaLimit && Common.isDefined(el.max_area) && el.max_area !== null && this.config().IccConfig.Configurators.roller_shutter.configuratorDimensionalRestriction) {
                let rollerHeight = roller.realRollerHeight;
                if (this.config().IccConfig.Configurators.price.shutterHeightWithBox) {
                    rollerHeight += roller.realBoxHeight;
                }
                const area = (rollerHeight * roller.realBoxWidth) / 1000000;
                check = check && el.max_area >= area;
            }
            if (
                Common.isDefined(roller.hanger)
                && roller.hanger !== null
                && this.config().IccConfig.Configurators.roller_shutter.hangerLock
            ) {
                check =
                    check
                    && (el.roller_shutter_hanger_id === roller.hanger.id
                        || !~~el.roller_shutter_hanger_id);
            }
            if (Common.isDefined(color) && color !== null) {
                check = check && (el.color === color || !el.color);
            }
            return check;
        }).sort((a, b) => Number(b.roller_shutter_hanger_id) - Number(a.roller_shutter_hanger_id));
    }

    /**
     * Dolicza dopłatę za zmianę wysokości skrzynki
     * @param  {number} price          Cena
     * @param  {number} height         Wysokość skrzynki
     * @param  {number} originalHeight Domyślna wysokość skrzynki
     * @param  {object} system         System rolety
     * @param  {array}  colors         Kolory
     * @param  {array}  PriceElems     Składowe ceny
     * @param  {array}  NoPriceCauses  Komunikaty nieliczenia cen
     * @param  {array}  prices         Dopłaty za wysokoci skrzynek
     * @param  {number} rollerFactor   Wspolczynnik rynku dla rolet
     * @return {number}                Nowa cena
     */
    suppBoxSize(
        price,
        height,
        width,
        originalHeight,
        system,
        colors,
        PriceElems,
        NoPriceCauses,
        prices,
        rollerFactor,
        boxHeightLevel,
        type
    ): PriceSegment {
        const color = this.getColorTypeForRollers(colors.boxInner, colors.boxGuideOuter);
        let supp = 0;
        if (
            Common.isDefined(system.id)
            && Common.isDefined(prices[system.id])
            && Common.isDefined(prices[system.id][height])
            && (Common.isDefined(prices[system.id][height][color])
                || Common.isDefined(prices[system.id][height]['']))
        ) {
            supp = Common.isDefined(prices[system.id][height][color])
                ? prices[system.id][height][color]
                : prices[system.id][height][''];
            if (this.config().IccConfig.Configurators.price.rollerBoxSizeMultiply) {
                supp = supp * boxHeightLevel;
            }
            if (this.config().IccConfig.Configurators.price.rollerBoxSizeType === 'value') {
                if (this.config().IccConfig.Configurators.price.rollerSuppBoxSizeForLength) {
                    supp = (supp * width) / 1000;
                }

                return {
                    type: 'rollerBoxSize',
                    baseValue: supp * rollerFactor,
                    value: supp * rollerFactor,
                    valueType: 'value',
                    data: {
                        system: system.id,
                        height,
                        typeId: type.id,
                    },
                };
            } else {
                return {
                    type: 'rollerBoxSize',
                    baseValue: (Number(supp) + 100) / 100,
                    value: (Number(supp) + 100) / 100,
                    valueType: 'percent',
                    data: {
                        system: system.id,
                        height,
                        typeId: type.id,
                    },
                };
            }
        } else {
            NoPriceCauses.push('no price for height, box and system');
            return {
                type: 'rollerBoxSize',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {
                    system: system.id,
                    height,
                    typeId: type.id,
                },
            };
        }
    }

    /**
     * Dodawanie ceny za konsolę statyczną
     * @param  {number} price         Cena przed dopłatą
     * @param  {number} height        Wysokość rolety
     * @param  {number} width         Szerokość rolety
     * @param  {object} system        Wybrany system rolet
     * @param  {object} PriceElems    Elementy sakladowe wyceny
     * @param  {array}  NoPriceCauses Komunikaty nieliczenia cen
     * @param  {object} prices        rollerShutterConsolesPrices
     * @param  {number} rollerFactor  Wspolczynnik rynku dla rolet
     * @return {number}               Cena po doplacie
     */
    suppConsole(
        price,
        height,
        width,
        system,
        PriceElems,
        NoPriceCauses,
        prices,
        rollerFactor,
        type
    ): PriceSegment {
        if (
            Common.isDefined(system)
            && Common.isDefined(system.id)
            && Common.isArray(prices[system.id])
        ) {
            const consolePrice = prices[system.id].filter(function filterPrices(el) {
                return (
                    el.height_min <= height
                    && el.height_max >= height
                    && el.width_min <= width
                    && el.width_max >= width
                );
            });

            if (Common.isArray(consolePrice) && Common.isDefined(consolePrice[0])) {
                PriceElems.rollers.console = {
                    amount: consolePrice[0].amount,
                    height,
                    width,
                    price: consolePrice[0].price * consolePrice[0].amount * rollerFactor,
                };
                price += consolePrice[0].price * consolePrice[0].amount * rollerFactor;
                return {
                    type: 'rollerConsole',
                    baseValue: consolePrice[0].price * consolePrice[0].amount * rollerFactor,
                    value: consolePrice[0].price * consolePrice[0].amount * rollerFactor,
                    valueType: 'value',
                    data: {
                        amount: consolePrice[0].amount,
                        height,
                        width,
                        typeId: type.id,
                    },
                };
            }
        }
    }

    /**
     * Dodawanie ceny za listwę podtynkową zewnętrzną.
     * @param  {number} price         Cena przed dopłatą
     * @param  {number} width         Szerokość rolety
     * @param  {bool}   inInsulation  Pod ocieplenie
     * @param  {object} PriceElems    Elementy sakladowe wyceny
     * @param  {array}  NoPriceCauses Komunikaty nieliczenia cen
     * @param  {object} accessories   rollerShutterConsolesPrices
     * @param  {number} rollerFactor  Wspolczynnik rynku dla rolet
     * @return {number}               Cena po doplacie
     */
    suppConcealedStrip(
        price,
        width,
        inInsulation,
        PriceElems,
        NoPriceCauses,
        accessories,
        rollerFactor,
        type
    ): PriceSegment {
        if (inInsulation) {
            const concealedStrip = accessories.filter(a => a.type === 'concealed_strip')[0];
            if (concealedStrip && concealedStrip.price_white) {
                PriceElems.rollers.concealedStrip = {
                    width,
                    price: ((concealedStrip.price_white * width) / 1000) * rollerFactor,
                };
                price += ((concealedStrip.price_white * width) / 1000) * rollerFactor;
                return {
                    type: 'rollerConcealedStrip',
                    baseValue: ((concealedStrip.price_white * width) / 1000) * rollerFactor,
                    value: ((concealedStrip.price_white * width) / 1000) * rollerFactor,
                    valueType: 'value',
                    data: {
                        width,
                        typeId: type.id,
                    },
                };
            }
        }
    }

    /**
     * Dodawanie ceny za listwę podtynkową zewnętrzną.
     * @param  {number} price         Cena przed dopłatą
     * @param  {number} width         Szerokość rolety
     * @param  {bool}   inInsulation  Pod ocieplenie
     * @param  {object} PriceElems    Elementy sakladowe wyceny
     * @param  {array}  NoPriceCauses Komunikaty nieliczenia cen
     * @param  {object} accessories   rollerShutterConsolesPrices
     * @param  {number} rollerFactor  Wspolczynnik rynku dla rolet
     * @return {number}               Cena po doplacie
     */
    suppProfile(rollerProfile): PriceSegment {
        return {
            type: 'rollerProfile',
            baseValue: 1 + parseFloat(rollerProfile.price_percent) / 100,
            value: 1 + parseFloat(rollerProfile.price_percent) / 100,
            valueType: 'percent',
            data: {
                profile: rollerProfile.id,
            },
        };
    }

    /**
     * Dodawanie ceny za listwę podtynkową zewnętrzną.
     * @param  {number} price         Cena przed dopłatą
     * @param  {number} width         Szerokość rolety
     * @param  {bool}   inInsulation  Pod ocieplenie
     * @param  {object} PriceElems    Elementy sakladowe wyceny
     * @param  {array}  NoPriceCauses Komunikaty nieliczenia cen
     * @param  {object} accessories   rollerShutterConsolesPrices
     * @param  {number} rollerFactor  Wspolczynnik rynku dla rolet
     * @return {number}               Cena po doplacie
     */
    suppSlatDivision(roller): PriceSegment {
        return {
            type: 'rollerSlatDivision',
            baseValue: (roller.shutters.length - 1) * roller.system.slat_division_surcharge,
            value: (roller.shutters.length - 1) * roller.system.slat_division_surcharge,
            valueType: 'value',
            data: {
                systemId: roller.system.id,
                divisionCount: roller.shutters.length - 1,
                typeId: roller.type.id,
            },
        };
    }

    /**
     * Cena pojedynczej rolety
     * @param  {Number} rPrice        Cennik
     * @param  {Number} height        Wysokość
     * @param  {Number} width         Szerokość
     * @param  {Object} roller        Roleta
     * @param  {Array}  PriceElems    Elementy składowe wyceny
     * @param  {Object} NoPriceCauses Powody braku ceny
     * @param  {number} rollerFactor  Wspolczynnik rynku dla rolet
     * @return {Number}               Cena
     */
    priceShutter(
        rPrice,
        height,
        width,
        roller,
        profiles,
        PriceElems,
        NoPriceCauses,
        rollerFactor
    ): PriceSegment {
        let price = 0;
        const system = roller.system;
        const profile = roller.profile;

        const rollerPrice = rPrice.data.filter(function filterRollerPrices(el) {
            return (
                el.height_from <= height
                && el.height_to >= height
                && el.width_from <= width
                && el.width_to >= width
            );
        });

        if (
            Common.isDefined(system)
            && Common.isDefined(profile)
            && Common.isDefined(rollerPrice)
            && Common.isDefined(rollerPrice[0])
            && Common.isDefined(rollerPrice[0].price)
        ) {
            // cena za metr kw.
            if (parseFloat(rPrice.price_m2) > 0) {
                let area = (width * height) / 1000000;

                if (area < parseFloat(rPrice.min_area_price)) {
                    area = parseFloat(rPrice.min_area_price);
                }

                price = parseFloat(rPrice.price_m2) * area * rollerFactor;
                PriceElems.rollers.boxes.push({
                    system: system.name,
                    profile,
                    priceId: rPrice.id,
                    color: rPrice.color,
                    price,
                    height,
                    width,
                });
                return {
                    type: 'rollerBoxes',
                    baseValue: price,
                    value: price,
                    valueType: 'value',
                    data: {
                        system: system.name,
                        profile,
                        priceId: rPrice.id,
                        type: roller.type.id,
                        color: rPrice.color,
                        height,
                        width,
                        typeId: roller.type.id,
                    },
                };
                // cena z rastra
            } else {
                price = parseFloat(rollerPrice[0].price) * rollerFactor;
                PriceElems.rollers.boxes.push({
                    system: system.name,
                    profile,
                    priceId: rPrice.id,
                    color: rPrice.color,
                    price,
                    height,
                    width,
                });
                return {
                    type: 'rollerBoxes',
                    baseValue: price,
                    value: price,
                    valueType: 'value',
                    data: {
                        system: system.name,
                        profile,
                        priceId: rPrice.id,
                        type: roller.type.id,
                        color: rPrice.color,
                        height,
                        width,
                        typeId: roller.type.id,
                    },
                };
            }
        } else {
            NoPriceCauses.push('no roller shutter prices');
            return {
                type: 'rollerBoxes',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {
                    priceId: rPrice.id,
                    type: roller.type.id,
                    color: rPrice.color,
                    height,
                    width,
                    typeId: roller.type.id,
                },
            };
        }
    }

    /**
     * Zwraca schemat koloru rolety w postaci opcja/opcja
     * @param  {object} color1 Kolor 1
     * @param  {object} color2 Kolor 2
     * @return {string}        Gotowy schemat
     */
    getColorTypeForRollers(color1, color2) {
        if (
            Common.isUndefined(color1)
            || Common.isUndefined(color2)
            || color1 === null
            || color2 === null
            || (Common.isUndefined(color1.color_img) && Common.isUndefined(color1.color))
            || (Common.isUndefined(color2.color_img) && Common.isUndefined(color2.color))
        ) {
            return 'b/b';
        }

        const colorTypes = [];

        if (Common.isString(color1.color_img) && color1.color_img.length && color1.type !== 'white') {
            colorTypes[0] = 'o';
        } else if (color1.color.toLowerCase().substr(color1.color.length - 6) === 'ffffff' || color1.type === 'white') {
            colorTypes[0] = 'b';
        } else {
            colorTypes[0] = 'r';
        }

        if (Common.isString(color2.color_img) && color2.color_img.length && color2.type !== 'white') {
            colorTypes[1] = 'o';
        } else if (color2.color.toLowerCase().substr(color2.color.length - 6) === 'ffffff' || color2.type === 'white') {
            colorTypes[1] = 'b';
        } else {
            colorTypes[1] = 'r';
        }

        return colorTypes.sort().join('/');
    }

    realWidthForPrice(roller, profiles, width, shuttersIds) {
        const shutters = roller.shutters.filter(el => shuttersIds.indexOf(el.id) > -1);
        const leftGuideRail = roller.guideRails.find(el => el.leftOf === shutters[0].id);
        const rightGuideRail = roller.guideRails.find(
            el => el.rightOf === shutters[shutters.length - 1].id
        );
        if (leftGuideRail && rightGuideRail) {
            const leftGuideRailProfile = profiles.find(el => el.id === leftGuideRail.id);
            const rightGuideRailProfile = profiles.find(el => el.id === rightGuideRail.id);
            if (leftGuideRailProfile && rightGuideRailProfile) {
                width -=
                    leftGuideRailProfile.widthOut
                    - leftGuideRailProfile.depth
                    + rightGuideRailProfile.widthOut
                    - rightGuideRailProfile.depth;
            }
        }

        return width;
    }
    realHeightForPrice(boxHeight, height) {
        height +=
            (boxHeight / 2)
            * (this.config().IccConfig.Configurators.price.shutterHeightWithBox ? -1 : 1);

        return height;
    }
}
