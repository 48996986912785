import { isUndefined, isDefined } from '@icc/helpers';

/**
 * Funkcja bezpiecznej szyby
 */
export function GlassSecurityFilter() {
    return function(glass, security, extend) {
        const glassArr = [];
        Object.keys(glass).forEach(key => {
            glassArr.push(glass[key]);
        });
        if (isUndefined(security) || security === null || security === '') {
            return glassArr;
        } else {
            if (extend === 'extend') {
                return glassArr.filter(function(gl) {
                    switch (Number(security)) {
                        case 1:
                            if (isDefined(gl.glass_security_inner)) {
                                return Number(gl.glass_security_inner) === 0 && Number(gl.glass_security_outer) === 0;
                            }
                            break;
                        case 2:
                            if (isDefined(gl.glass_security_inner) && isDefined(gl.glass_security_outer)) {
                                return Number(gl.glass_security_inner) > 0 || Number(gl.glass_security_outer) > 0;
                            }
                            break;
                        case 3:
                            if (isDefined(gl.glass_security_inner) && isDefined(gl.glass_security_outer)) {
                                return (Number(gl.glass_security_inner) === 3 && Number(gl.glass_security_outer) === 3)
                                    || (Number(gl.glass_security_inner) > 3 || Number(gl.glass_security_outer) > 3)
                                        && (Number(gl.glass_security_inner) < 6 || Number(gl.glass_security_outer) < 6);
                            }
                            break;
                        case 4:
                            if (isDefined(gl.glass_security_inner) && isDefined(gl.glass_security_outer)) {
                                return Number(gl.glass_security_inner) === 2 || Number(gl.glass_security_outer) === 2;
                            }
                            break;
                        case 5:
                            if (isDefined(gl.glass_security_inner) && isDefined(gl.glass_security_outer)) {
                                return Number(gl.glass_security_inner) === 5 || Number(gl.glass_security_outer) === 5;
                            }
                            break;
                    }
                    return false;
                });
            } else if (extend === 'extend2') {
                return glassArr.filter(function(gl) {
                    switch (Number(security)) {
                        case 1:
                            if (isDefined(gl.glass_security_inner)) {
                                return Number(gl.glass_security_inner) === 0 && Number(gl.glass_security_outer) === 0;
                            }
                            break;
                        case 4:
                            if (isDefined(gl.glass_security_inner) && isDefined(gl.glass_security_outer)) {
                                return Number(gl.glass_security_inner) === 2 || Number(gl.glass_security_outer) === 2;
                            }
                            break;
                        case 6:
                            if (isDefined(gl.glass_security_inner) && isDefined(gl.glass_security_outer)) {
                                return (Number(gl.glass_security_inner) === 6 || Number(gl.glass_security_outer) === 6);
                            }
                            break;
                        case 7:
                            if (isDefined(gl.glass_security_inner) && isDefined(gl.glass_security_outer)) {
                                return Number(gl.glass_security_inner) === 7 || Number(gl.glass_security_outer) === 7;
                            }
                            break;
                        case 2:
                            if (isDefined(gl.glass_security_inner) && isDefined(gl.glass_security_outer)) {
                                return Number(gl.glass_security_inner) === 1 || Number(gl.glass_security_outer) === 1;
                            }
                            break;
                    }
                    return false;
                });
            } else {
                return glassArr.filter(function(gl) {
                    switch (Number(security)) {
                        case 1:
                            if (isDefined(gl.glass_security_inner)) {
                                return Number(gl.glass_security_inner) === 0 && Number(gl.glass_security_outer) === 0;
                            }
                            break;
                        case 2:
                            if (isDefined(gl.glass_security_inner)) {
                                return Number(gl.glass_security_inner) > 0;
                            }
                            break;
                        case 3:
                            if (isDefined(gl.glass_security_inner) && isDefined(gl.glass_security_outer)) {
                                return Number(gl.glass_security_inner) > 0 && Number(gl.glass_security_outer) > 0;
                            }
                            break;
                        case 4:
                            if (isDefined(gl.glass_security_inner) && isDefined(gl.glass_security_outer)) {
                                return Number(gl.glass_security_inner) > 0 && Number(gl.glass_security_outer) >= 4 && Number(gl.glass_security_outer) < 6;
                            }
                            break;
                        case 5:
                            if (isDefined(gl.glass_security_inner) && isDefined(gl.glass_security_outer)) {
                                return Number(gl.glass_security_inner) === 5 || Number(gl.glass_security_outer) === 5;
                            }
                            break;
                    }
                    return false;
                });
            }
        }
    };
}
