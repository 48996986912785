import { isUndefined } from '@icc/helpers';

export function GlassThicknessFilter() {
    return function(glass, thickness) {
        const glassArr = [];
        Object.keys(glass).forEach(key => {
            glassArr.push(glass[key]);
        });
        if (isUndefined(thickness) || thickness === null || thickness === '') {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {
                return Number(thickness) === Number(gl.thickness_mm);
            });
        }
    };
}
