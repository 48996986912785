declare var WorkerGlobalScope: any;
declare var process: any;
declare var global: any;

export function isWorker() {
    return typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope;
}

export function isNode() {
    return (
        typeof global !== 'undefined'
        && typeof process === 'object'
        && typeof process.versions === 'object'
        && typeof process.versions.node !== 'undefined'
    );
}
