import { ComponentPortal, DomPortalOutlet, PortalInjector } from '@angular/cdk/portal';
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    Injector,
    Type,
    OnDestroy,
    ElementRef,
    ComponentFactoryResolver,
    ApplicationRef,
    ChangeDetectorRef,
    NgZone,
    Output,
    EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { ConfiguratorOptions, SharedFacade, _ } from '@icc/configurator/shared';
import { ICC_PAGE_DATA, InfoService } from '@icc/helpers';
import { Store } from '@ngrx/store';
import { ConfiguratorStepsService } from 'apps/configurator/src/app/step/steps.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';


@Component({
    selector: 'icc-configurator-page-container',
    templateUrl: './configurator-page-container.component.html',
    styles: [],
})
export class ConfiguratorPageContainerComponent implements OnInit, OnDestroy  {
    @Input() options: any;
    @Output() titleEvent = new EventEmitter<string>();
    @ViewChild('container', { read: ElementRef }) pageContainer: | ElementRef
    | undefined;

    private pageOutlet: DomPortalOutlet | undefined;
    private filtersOutlet: DomPortalOutlet | undefined;

    currentStepId = 0;
    step: any = undefined;
    project = 'icc';
    logo = '';
    stepTitle = '';
    pageTitle: string | null = null;

    stepDescriptionEnabled = false;
    stepMessage = '';
    stepsDescriptions: any = {};

    activeSearch = false;

    searching = false;

    openedPage$ = this.stepsService.openedPage$;

    pageMenuOptions: {
        title: string | Observable<string>;
        component?: Type<any>;
        componentData?: any;
        callback?: () => void;
        icon?: {
            fontSet?: string;
            fontIcon?: string;
            ligature?: string;
        };
        show?: () => boolean;
    }[] = [];

    pageToolbarOptions: {
        title: string | Observable<string>;
        component?: Type<any>;
        componentData?: any;
        callback?: () => void;
        icon?: {
            fontSet?: string;
            fontIcon?: string;
            ligature?: string;
        };
        show?: () => boolean;
    }[] = [];

    configuratorOptions?: ConfiguratorOptions;
    searchControl = new FormControl('');
    @ViewChild('snav') sidenav: MatSidenav | undefined;

    private unsubscribe$ = new Subject<void>();

    private pageOptions: {
        title: string | Observable<string>;
        component?: Type<any>;
        componentData?: any;
        callback?: () => void;
        icon?: {
            fontSet?: string;
            fontIcon?: string;
            ligature?: string;
        };
        show?: () => boolean;
    }[] = [];

    private searchOption = {
        title: _('INTERFACE|Wyszukaj'),
        callback: () => {
            this.searching = true;
        },
        show: () => this.activeSearch,
        icon: {
            ligature: 'search',
        },
    };

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private stepsService: ConfiguratorStepsService,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone,
        private store: Store,
        private infoService: InfoService,
        private sharedFacade: SharedFacade,
    ) {
        this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((value: string) => {
            this.ngZone.run(() => {
                this.sharedFacade.updateSearch(value);
            });
        });
        this.sharedFacade.activeSearch$.pipe(takeUntil(this.unsubscribe$)).subscribe(active => {
            this.activeSearch = active;
            if (!this.activeSearch) {
                this.searching = false;
            }
        });
    }

    ngOnInit() {}

    ngOnDestroy(): void {
        if (this.pageOutlet) {
            this.pageOutlet.detach();
        }
        if (this.filtersOutlet) {
            this.filtersOutlet.detach();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngAfterViewInit(): void {
        if (this.pageContainer) {
            this.ngZone.run(() => {
                this.pageOutlet = new DomPortalOutlet(
                    this.pageContainer.nativeElement,
                    this.componentFactoryResolver,
                    this.applicationRef,
                    this.injector
                );

                // eslint-disable-next-line max-statements
                this.openedPage$.pipe(takeUntil(this.unsubscribe$)).subscribe(step => {
                    if (step && step.component && this.pageOutlet) {
                        this.step = step;
                        this.pageOutlet.detach();
                        const injectionTokens = new WeakMap<any, any>([[ICC_PAGE_DATA, step.data]]);
                        const injector = new PortalInjector(this.injector, injectionTokens);
                        const stepPortal = new ComponentPortal(step.component, undefined, injector);
                        const componentRef = this.pageOutlet.attach(stepPortal);

                        if (step.page) {
                            this.pageTitle = this.getName(componentRef.instance.title, this.configuratorOptions);
                        } else {
                            this.stepTitle = this.getName(componentRef.instance.title, this.configuratorOptions);
                            this.pageTitle = null;
                        }
                        this.titleEvent.emit(this.pageTitle);

                        if(this.stepMessage != '') {
                            this.infoService.dismissWarning(this.stepMessage)
                        }
                        this.stepDescriptionEnabled = false;
                        this.stepMessage = '';
                        const stepId = componentRef.instance.stepId;
                        if(stepId && this.stepsDescriptions && this.stepsDescriptions[stepId]) {
                            this.stepDescriptionEnabled = true;
                            this.stepMessage = this.stepsDescriptions[stepId];
                        }

                        this.pageOptions = [
                            this.searchOption,
                            ...componentRef.instance.options,
                        ];


                        this.stopSearching();
                        this.cdr.reattach();
                        this.cdr.detectChanges();
                    }
                });
            });
        }

    }

    private getName(name: string | ((options?: ConfiguratorOptions) => string), options?: ConfiguratorOptions) {
        if (typeof name === 'function' ) {
            return name(options);
        }
        return name;
    }

    stopSearching() {
        this.ngZone.run(() => {
            this.searchControl.setValue('');
            this.searching = false;
        });
    }

    menuItemHandler(option: {
        title: string;
        component?: Type<any>;
        callback?: () => void;
        componentData?: any;
    }) {
        this.ngZone.run(() => {
            if (option.component) {
                this.stepsService.openPage(option.component, option.componentData || {});
            } else if (option.callback) {
                option.callback();
            }
        });
    }


    getPageToolbarOptions() {
        const options = this.pageOptions.filter(o => (!o.show || o.show()) && o.icon);

        if (options.findIndex(el => el.title === 'WINDOW|Filtry') > -1) {
            options.splice(options.findIndex(el => el.title === 'WINDOW|Filtry'), 1);
        }
        if (options.length <= 2) {
            this.pageToolbarOptions = options;
        } else {
            this.pageToolbarOptions = options.slice(0, 2);
        }
        return this.pageToolbarOptions;
    }
}
