import * as angular from 'angular';

import { ColorsCtrl } from './ColorsCtrl';
import { ColorsService } from './colors.service';
import DimensionsCtrl from './DimensionsCtrl';
import { RollerDimensionsService } from './dimensions.service';
import DrivesCtrl from './DrivesCtrl';
import { DrivesService } from './drives.service';
import ModalColorCtrl from './ModalColorCtrl';
import { ModalDriveElementCtrl } from './ModalDriveElementCtrl';
import ModalDrivesCtrl from './ModalDrivesCtrl';
import ModalDrivesManualCtrl from './ModalDrivesManualCtrl';
import ModalMontagesInfoCtrl from './ModalMontagesInfoCtrl';
import ModalSystemsCtrl from './ModalSystemsCtrl';
import {GuidesModalComponent} from './guides-modal/guides-modal.component';
import {SlatsModalComponent} from './slats-modal/slats-modal.component';
import SchemasCtrl from './SchemasCtrl';
import { SchemasService } from './schemas.service';
import TypesCtrl from './TypesCtrl';
import { TypesService } from './types.service';
import { downgradeInjectable } from '@angular/upgrade/static';

export default angular.module('icc.configurator.steps.roller_shutter', [])
    .controller('DrivesCtrl', DrivesCtrl)
    .controller('ModalDriveElementController', ModalDriveElementCtrl)
    .controller('ModalDrivesController', ModalDrivesCtrl)
    .controller('ModalDrivesManualController', ModalDrivesManualCtrl)
    .controller('ModalMontagesInfoCtrl', ModalMontagesInfoCtrl)
    .controller('ModalRollerColorCtrl', ModalColorCtrl)
    .controller('ModalSystemsCtrl', ModalSystemsCtrl)
    .component('modalGuidesSelection', GuidesModalComponent)
    .component('modalSlatsSelection', SlatsModalComponent)
    .controller('RollerColorsCtrl', ColorsCtrl)
    .controller('RollerDimensionsCtrl', DimensionsCtrl)
    .controller('SchemasCtrl', SchemasCtrl)
    .controller('TypesCtrl', TypesCtrl)
    .factory('DrivesService', downgradeInjectable(DrivesService))
    .factory('RollerColorsService', downgradeInjectable(ColorsService))
    .factory('RollerDimensionsFactory', downgradeInjectable(RollerDimensionsService))
    .factory('SchemasService', downgradeInjectable(SchemasService))
    .factory('TypesService', downgradeInjectable(TypesService))
    .name;
