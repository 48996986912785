export default function ComplaintsCtrl($rootScope, $scope, $http, EnvConfig) {
    'ngInject';

    var vm               = this;
    vm.ceil              = Math.ceil;
    vm.totalRows         = 0;
    vm.orderBy           = 'created';
    vm.orderReverse      = true;
    vm.itemsPerPage      = 10;
    vm.currentPage       = 1;
    vm.getComplaintsList = getComplaintsList;
    vm.sortOffers        = sortOffers;
    vm.status            = '-1';
    vm.showAssemblyTeamNumber = $rootScope.user.access && $rootScope.user.access === 'dealer';
    getComplaintsList();

    /**
     * Pobieranie listy ofert
     */
    function getComplaintsList() {
        if ($rootScope.user.access) {
            $rootScope.loader = true;

            $http
                .post(`${EnvConfig.remoteHost || window.location.origin}/complaint/complaints/index.json`, {
                    filters: {
                        pageNumber              : vm.currentPage,
                        orderBy                 : vm.orderBy,
                        search                  : vm.search,
                        status                  : vm.status,
                        itemsPerPage            : vm.itemsPerPage,
                        orderReverse            : vm.orderReverse,
                        assemblyTeamNumberSearch: vm.assemblyTeamNumberSearch
                    }
                })
                .then((res) => {
                    vm.complaints = res.data.complaints;
                    vm.statuses = res.data.statuses;
                    vm.totalRows = res.data.complaints && res.data.complaints[0] ? res.data.complaints[0].total : 1;
                    $rootScope.loader = false;
                });
        } else {
            window.location = '/' + $scope.curLang;
        }
    }

    /**
     * Zmienia sortowanie ofert
     * @param  {string} field Pole po którym sortuje
     */
    function sortOffers(field) {
        if (vm.orderBy == field) {
            vm.orderReverse = !vm.orderReverse;
        } else {
            vm.orderBy = field;
            vm.orderReverse = false;
        }
        getComplaintsList();
    }
}
