import { PanelsData } from '@icc/common/configurations/parts/garage_door/PanelsData';
import { PanelsAmount } from './../../../../common/src/lib/configurations/parts/garage_door/PanelsAmount';
import { TranslateService } from '@ngx-translate/core';
import { EventBusService } from './../../../../common/src/lib/event-bus.service';
import { ModalService, _, IssuesService } from '@icc/configurator/shared';
import { GarageDoorFacade } from './+state/garage-door.facade';
import { Injectable, Inject, OnDestroy } from '@angular/core';
import { ModalListComponent } from './modal-list/modal-list.component';
import { AppConfigFactory, APP_CONFIG, ConfigurationsService, IssueLevel, core } from '@icc/common';
import { GarageDoorDefaultsService } from './+state/garage-door-defaults.service';
import { Subscription } from 'rxjs';
import { Panel } from '@icc/common/configurations/parts/garage_door';

@Injectable()
export class PanelsService implements OnDestroy {
    private subscriptions: Subscription[] = [];

    allPanelsAmounts: any[] = [];
    allPanels: any[] = [];
    allPanelStructures: any[] = [];
    allColors: any[] = [];
    allColorGroups: any[] = [];
    colorGroupsOuter: any[] = [];
    colorGroupsInner: any[] = [];
    selectedSystem;
    selectedDimension;
    selectedPanelsData;
    selectedPanel;
    selectedPanelStructure;
    selectedPanelInternalStructure;
    selectedPlinthPanel;
    selectedPlinthPanelStructure;
    selectedPanelColor;
    selectedPanelInternalColor;

    constructor(
        private garageDoorFacade: GarageDoorFacade,
        private modalService: ModalService,
        private eventBusService: EventBusService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private issuesService: IssuesService,
        private configurationsService: ConfigurationsService,
        private translateService: TranslateService,
        private defaultsService: GarageDoorDefaultsService
    ) {
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            const conf = this.configurationsService.conf.Current;
            if (conf.type === 'garage_door') {
                this.subscriptions.push(
                    this.garageDoorFacade.availablePanelsAmounts$.subscribe(amounts => {
                        this.allPanelsAmounts = amounts;
                    }),
                    this.garageDoorFacade.availablePanels$.subscribe(panel => {
                        this.allPanels = panel;
                    }),
                    this.garageDoorFacade.availablePanelStructures$.subscribe(panelStructures => {
                        this.allPanelStructures = panelStructures;
                    }),
                    this.garageDoorFacade.availableColors$.subscribe(colors => {
                        this.allColors = colors;
                    }),
                    this.garageDoorFacade.availableColorGroups$.subscribe(groups => {
                        this.allColorGroups = groups;
                        if (groups) {
                            this.colorGroupsOuter = groups.filter(g => g.sides.includes('outer'))
                            this.colorGroupsInner = groups.filter(g => g.sides.includes('inner'))
                        }
                    }),
                    this.garageDoorFacade.selectedSystem$.subscribe(system => {
                        this.selectedSystem = system;
                        this.validateAndSetDefaultPanels();
                        this.validateAndSetDefaultPlinthPanel();
                    }),
                    this.garageDoorFacade.selectedDimensions$.subscribe(d => {
                        this.selectedDimension = d;
                        this.validateAndSetDefaultPanelsData();
                    }),
                    this.garageDoorFacade.selectedPanelsData$.subscribe(data => {
                        if (data && data.panels && data.panelsAmount) {
                            this.issuesService.unregister('no-garage-door-panels-data', conf);
                        } else {
                            this.registerNoPanelsDataIssue(conf);
                        }

                        this.selectedPanelsData = data;
                    }),
                    this.garageDoorFacade.selectedPanel$.subscribe(panel => {
                        if (panel) {
                            this.issuesService.unregister('no-garage-door-panel', conf);
                        } else {
                            this.registerNoPanelIssue(conf);
                        }

                        this.selectedPanel = panel;
                        this.validateAndSetDefaultPanelsData();
                        this.validateAndSetDefaultPanelStructure();
                    }),
                    this.garageDoorFacade.selectedPanelStructure$.subscribe(panelStructure => {
                        if (panelStructure) {
                            this.issuesService.unregister('no-garage-door-panel-structure', conf);
                        } else {
                            this.registerNoPanelStructureIssue(conf);
                        }
                        this.selectedPanelStructure = panelStructure;
                        this.validateAndSetDefaultPanelsData();
                        this.validatePanelColorsAndSetDefaults();
                    }),
                    this.garageDoorFacade.selectedPanelInternalStructure$.subscribe(panelInternalStructure => {
                        this.selectedPanelInternalStructure = panelInternalStructure;
                    }),
                    this.garageDoorFacade.selectedPlinthPanel$.subscribe(plinthPanel => {
                        this.selectedPlinthPanel = plinthPanel;
                        this.validateAndSetDefaultPlinthPanelStructure();
                    }),
                    this.garageDoorFacade.selectedPlinthPanelStructure$.subscribe(plinthPanelStructure => {
                        this.selectedPlinthPanelStructure = plinthPanelStructure;
                    }),
                    this.garageDoorFacade.selectedPanelColor$.subscribe(color => {
                        this.selectedPanelColor = color;
                    }),
                    this.garageDoorFacade.selectedPanelInternalColor$.subscribe(color => {
                        this.selectedPanelInternalColor = color;
                    })
                );
            }
        });
    }

    getFilteredPanelsAmountsBySelectedPanel() {
        const panelsAmount = core.copy(this.allPanelsAmounts.find(panelsAmount => {
            return (this.selectedPanel
                    && this.selectedPanelStructure
                    && this.selectedDimension
                    && this.selectedDimension.width
                    && this.selectedDimension.height)
                        && (this.selectedPanel.id == panelsAmount.garage_door_panel_id)
                        && (this.selectedPanelStructure.id == panelsAmount.garage_door_panel_structure_id)
                        && (Number(panelsAmount.height_from) <= Number(this.selectedDimension.height) && Number(panelsAmount.height_to) >= Number(this.selectedDimension.height))
        }));

        return panelsAmount;
    }

    getDefaultPanelsData() {
        const panelsAmount = this.getFilteredPanelsAmountsBySelectedPanel();

        if (panelsAmount && panelsAmount.panels) {
            panelsAmount.panels = this.cutFirstPanel(panelsAmount);
            if (panelsAmount.panels) {
                const panelsData = this.parsePanelsAmountToPanelsData(panelsAmount)
                const arePanelsValid = this.validatePanelsHeight(panelsData.panels);
                return arePanelsValid && panelsData;
            }
        }

        return null;
    }

    validatePanelsHeight(panels: Panel[]): boolean {
        return this.getPanelsHeight(panels) === this.selectedDimension.height;
    }

    getPanelsHeight(panels: Panel[]): number {
        let panelsHeight = 0;
        if (panels && panels.length) {
            panels.forEach(p => panelsHeight += Number(p.panelHeight.height));
        }
        return panelsHeight
    }

    cutFirstPanel(panelsAmount) {
        const panels = panelsAmount.panels;

        const currentHeight = this.selectedDimension.height;
        let allPanelsHeight = 0;
        panels.forEach(p => {
            allPanelsHeight += Number(p.height);
        })

        if (allPanelsHeight > currentHeight) {
            const cutHeight = allPanelsHeight - currentHeight;
            const newFirstPanelHeight = panels[0].height - cutHeight;
            if (newFirstPanelHeight >= panels[0].min_height) {
                panels[0].height = newFirstPanelHeight;
            } else {
                return null;
            }
        }
        return panels;
    }

    getFilteredPanels() {
        return (
            this.allPanels.filter(p => {
                return (
                    this.selectedSystem
                    && this.selectedSystem.id
                    && p.systems.includes(this.selectedSystem.id)
                );
            }) || []
        );
    }

    getFilteredPanelStructures() {
        return (
            this.allPanelStructures.filter(s => {
                return (
                    this.selectedPanel
                    && this.selectedPanel.id
                    && s.garage_door_panels.includes(this.selectedPanel.id)
                );
            }) || []
        );
    }

    getFilteredPanelInternalStructures() {
        return this.getFilteredPanelStructures();
    }

    getFilteredPlinthPanels() {
        return this.getFilteredPanels();
    }

    getFilteredPlinthPanelStructures() {
        return (
            this.allPanelStructures.filter(s => {
                return (
                    this.selectedPlinthPanel
                    && this.selectedPlinthPanel.id
                    && s.garage_door_panels.includes(this.selectedPlinthPanel.id)
                );
            }) || []
        );
    }

    getFilteredPanelColors() {
        // Filter by groups and selectedPanelStructure
        const allColors = core.copy(this.allColors);
        const colorsOuter = allColors.filter(c => {
            return this.selectedPanelStructure
            && this.selectedPanelStructure.id
            && this.selectedPanelStructure.colors.includes(c.id)
            && c.groups.filter(g => {
                    return this.colorGroupsOuter.find(groupOuter => groupOuter.id == g);
            })
        })
        return colorsOuter;
    }

    getFilteredPanelInternalColors() {
         // Filter by groups and is_ral
         const allColors = core.copy(this.allColors);
         const colorsInner = allColors.filter(c => {
             return c.is_ral
                    && c.groups.filter(g => {
                        return this.colorGroupsInner.find(groupInner => groupInner.id == g);
                    })
         })
        return colorsInner
    }

    openModalPanel() {
        const filteredPanels = this.getFilteredPanels();
        this.modalService
            .open({
                pageComponent: ModalListComponent,
                resolve: {
                    items: filteredPanels,
                    selectedItemId: this.selectedPanel.id,
                    modalListTitle: _('GARAGEDOOR|Wybierz panel'),
                    modalListFilesDir: '/files/garagedoorpanel/',
                    itemImgAttributeName: 'image',
                    showItemInfo: true,
                    itemTemplate: 'itemImgTitle',
                },
            })
            .result.then(selectedPanel => {
                if (selectedPanel) {
                    this.garageDoorFacade.setPanel(selectedPanel);
                }
            });
    }

    openModalPanelStructure() {
        const panelStructures = this.getFilteredPanelStructures();
        this.modalService
            .open({
                pageComponent: ModalListComponent,
                resolve: {
                    items: panelStructures,
                    selectedItemId: this.selectedPanelStructure.id,
                    modalListTitle: _('GARAGEDOOR|Wybierz zewnętrzną strukturę powierzchni'),
                    modalListFilesDir: '/files/garagedoorpanelstructure/',
                    itemImgAttributeName: 'img'
                },
            })
            .result.then(selectedPanelStructure => {
                if (selectedPanelStructure) {
                    this.garageDoorFacade.setPanelStructure(selectedPanelStructure);
                }
            });
    }

    openModalPanelInternalStructure() {
        const panelStructures = this.getFilteredPanelInternalStructures();
        this.modalService
            .open({
                pageComponent: ModalListComponent,
                resolve: {
                    items: panelStructures,
                    selectedItemId: this.selectedPanelInternalStructure.id,
                    modalListTitle: _('GARAGEDOOR|Wybierz wewnętrzną strukturę powierzchni'),
                    modalListFilesDir: '/files/garagedoorpanelstructure/',
                    itemImgAttributeName: 'img'
                },
            })
            .result.then(selectedPanelInternalStructure => {
                if (selectedPanelInternalStructure) {
                    this.garageDoorFacade.setPanelInternalStructure(selectedPanelInternalStructure);
                }
            });
    }

    openModalPlinthPanelList() {
        const plinthPanels = this.getFilteredPlinthPanels();
        this.modalService
            .open({
                pageComponent: ModalListComponent,
                resolve: {
                    items: plinthPanels,
                    selectedItemId: this.selectedPlinthPanel.id,
                    modalListTitle: _('GARAGEDOOR|Wybierz panel cokołu'),
                    modalListFilesDir: '/files/garagedoorpanel/',
                    itemImgAttributeName: 'image',
                    itemTemplate: 'itemImgTitle',
                },
            })
            .result.then(selectedPlinthPanel => {
                if (selectedPlinthPanel) {
                    this.garageDoorFacade.setPlinthPanel(selectedPlinthPanel);
                }
            });
    }

    openModalPlinthPanelStructureList() {
        const plinthPanelStructures = this.getFilteredPlinthPanelStructures();
        this.modalService
            .open({
                pageComponent: ModalListComponent,
                resolve: {
                    items: plinthPanelStructures,
                    selectedItemId: this.selectedPlinthPanelStructure.id,
                    modalListTitle: _('GARAGEDOOR|Wybierz strukturę panelu cokołu'),
                    modalListFilesDir: '/files/garagedoorpanelstructure/',
                    itemImgAttributeName: 'img'
                },
            })
            .result.then(selectedPlinthPanelStructure => {
                if (selectedPlinthPanelStructure) {
                    this.garageDoorFacade.setPlinthPanelStructure(selectedPlinthPanelStructure);
                }
            });
    }

    openModalPanelColor() {
        const filteredPanelColors = this.getFilteredPanelColors();
        this.modalService
            .open({
                pageComponent: ModalListComponent,
                resolve: {
                    items: filteredPanelColors,
                    selectedItemId: this.selectedPanelColor.id,
                    modalListTitle: _('GARAGEDOOR|Wybierz kolor zewnętrzny'),
                    modalListFilesDir: '/files/garagedoorcolor/',
                    tabGroups: this.colorGroupsOuter,
                    itemImgAttributeName: 'color_img',
                    searchable: true
                },
            })
            .result.then(selectedPanelColor => {
                if (selectedPanelColor) {
                    this.garageDoorFacade.setPanelColor(selectedPanelColor);
                }
            });
    }

    openModalPanelInternalColor() {
        const filteredPanelInternalColors = this.getFilteredPanelInternalColors();
        this.modalService
            .open({
                pageComponent: ModalListComponent,
                resolve: {
                    items: filteredPanelInternalColors,
                    selectedItemId: this.selectedPanelInternalColor.id,
                    modalListTitle: _('GARAGEDOOR|Wybierz kolor wewnętrzny'),
                    modalListFilesDir: '/files/garagedoorcolor/',
                    tabGroups: this.colorGroupsInner,
                    itemImgAttributeName: 'color_img',
                    searchable: true
                },
            })
            .result.then(selectedPanelInternalColor => {
                if (selectedPanelInternalColor) {
                    this.garageDoorFacade.setPanelInternalColor(selectedPanelInternalColor);
                }
            });
    }

    validateAndSetDefaultPanelsData() {
        const defaultPanelsData = this.getDefaultPanelsData();
        const isSelectedSamePanelsAmount = defaultPanelsData
                                            && this.selectedPanelsData
                                            && this.selectedPanelsData.panelsAmount
                                            && this.selectedPanelsData.panelsAmount.id === defaultPanelsData.panelsAmount.id
                                            && this.getPanelsHeight(this.selectedPanelsData.panels) == this.getPanelsHeight(defaultPanelsData.panels);

        if (!isSelectedSamePanelsAmount) {
            this.garageDoorFacade.setPanelsData(defaultPanelsData);
        }
    }

    validateAndSetDefaultPanels() {
        let defaultPanel;
        const filteredPanels = this.getFilteredPanels();
        let isSelectedPanelAvailable = false;
        if (this.selectedPanel) {
            isSelectedPanelAvailable = filteredPanels.find(s => s.id == this.selectedPanel.id);
        }
        if (!isSelectedPanelAvailable) {
            defaultPanel = filteredPanels.find(p => p.id === this.defaultsService.getDefaultPanel().id) || filteredPanels[0];
            this.garageDoorFacade.setPanel(defaultPanel);
        }
    }

    validateAndSetDefaultPanelStructure() {
        let defaultPanelStructure;
        const filteredPanelStructures = this.getFilteredPanelStructures();
        let isSelectedPanelStructureAvailable = false;
        if (this.selectedPanelStructure) {
            isSelectedPanelStructureAvailable = filteredPanelStructures.find(
                s => s.id == this.selectedPanelStructure.id
            );
        }
        if (!isSelectedPanelStructureAvailable) {
            defaultPanelStructure = filteredPanelStructures.find(s => s.id === this.defaultsService.getDefaultPanelStructure().id)
                                    || filteredPanelStructures[0];
            this.garageDoorFacade.setPanelStructure(defaultPanelStructure);
        }
    }

    validateAndSetDefaultPanelColor() {
        let defaultPanelColor;
        const filteredPanelColors = this.getFilteredPanelColors();
        let isSelectedPanelColorAvailable = false;
        if (this.selectedPanelColor) {
            isSelectedPanelColorAvailable = filteredPanelColors.find(
                s => s.id == this.selectedPanelColor.id
            );
        }
        if (!isSelectedPanelColorAvailable) {
            defaultPanelColor = filteredPanelColors.find(c => c.id === this.defaultsService.getDefaultPanelColor().id)
                                || filteredPanelColors[0];;
            this.garageDoorFacade.setPanelColor(defaultPanelColor);
        }
    }

    validateAndSetDefaultPanelInternalColor() {
        let defaultPanelInternalColor;
        const filteredPanelInternalColors = this.getFilteredPanelInternalColors();
        let isSelectedPanelInternalColorAvailable = false;
        if (this.selectedPanelInternalColor) {
            isSelectedPanelInternalColorAvailable = filteredPanelInternalColors.find(
                s => s.id == this.selectedPanelInternalColor.id
            );
        }
        if (!isSelectedPanelInternalColorAvailable) {
            defaultPanelInternalColor = filteredPanelInternalColors.find(c => c.id === this.defaultsService.getDefaultPanelInternalColor().id)
                                        || filteredPanelInternalColors[0];
            this.garageDoorFacade.setPanelInternalColor(defaultPanelInternalColor);
        }
    }

    validateAndSetDefaultPlinthPanel() {
        if (
            this.config().IccConfig.Configurators.garage_door.differentPlinthPanel
            && this.selectedSystem
            && this.selectedSystem.plinth_panel
        ) {
            let defaultPlinthPanel;
            const filteredPlinthPanels = this.getFilteredPlinthPanels();
            let isSelectedPlinthPanelAvailable = false;
            if (this.selectedPlinthPanel) {
                isSelectedPlinthPanelAvailable = filteredPlinthPanels.find(
                    s => s.id == this.selectedPlinthPanel.id
                );
            }
            if (!isSelectedPlinthPanelAvailable) {
                defaultPlinthPanel = filteredPlinthPanels[0] || null;
                this.garageDoorFacade.setPlinthPanel(defaultPlinthPanel);
            }
        }
    }

    validateAndSetDefaultPlinthPanelStructure() {
        if (
            this.config().IccConfig.Configurators.garage_door.differentPlinthPanel
            && this.selectedSystem
            && this.selectedSystem.plinth_panel
        ) {
            let defaultPlinthPanelStructure;
            const filteredPlinthPanelStructures = this.getFilteredPlinthPanelStructures();
            let isSelectedPlinthPanelStructureAvailable = false;
            if (this.selectedPlinthPanelStructure) {
                isSelectedPlinthPanelStructureAvailable = filteredPlinthPanelStructures.find(
                    s => s.id == this.selectedPlinthPanelStructure.id
                );
            }
            if (!isSelectedPlinthPanelStructureAvailable) {
                defaultPlinthPanelStructure = filteredPlinthPanelStructures[0] || null;
                this.garageDoorFacade.setPlinthPanelStructure(defaultPlinthPanelStructure);
            }
        }
    }

    validatePanelColorsAndSetDefaults() {
        this.validateAndSetDefaultPanelColor();
        this.validateAndSetDefaultPanelInternalColor();
    }

    registerNoPanelsDataIssue(conf) {
        this.issuesService.simpleRegister(
            'no-garage-door-panels-data',
            'Nie udało się wyliczyć ilości paneli dla danej wysokości',
            this.translateService.instant(
                'CONFIGURATOR|Nie znaleziono odpowiedniej ilości paneli dla ustawionej wysokości'
            ),
            conf,
            {
                logLevel: IssueLevel.NONE,
            }
        );
    }

    registerNoPanelIssue(conf) {
        this.issuesService.simpleRegister(
            'no-garage-door-panel',
            'Nie znaleziono pasujących układów przetłoczeń paneli.',
            this.translateService.instant(
                'CONFIGURATOR|Wybrane układy przetłoczeń paneli są nieprawidłowe.'
            ),
            conf,
            {
                logLevel: IssueLevel.NONE,
            }
        );
    }

    registerNoPanelStructureIssue(conf) {
        this.issuesService.simpleRegister(
            'no-garage-door-panel-structure',
            'Nie znaleziono pasujących struktur paneli.',
            this.translateService.instant(
                'CONFIGURATOR|Wybrana struktura powierzchni paneli jest nieprawidłowa.'
            ),
            conf,
            {
                logLevel: IssueLevel.NONE,
            }
        );
    }

    parsePanelsAmountToPanelsData(panelsAmount): PanelsData {
        let panelsData: PanelsData;

        const panelsAmountParsed: PanelsAmount = {
            id: panelsAmount.id,
            garage_door_panel_id: panelsAmount.garage_door_panel_id,
            garage_door_panel_structure_id: panelsAmount.garage_door_panel_structure_id,
            height_from: panelsAmount.height_from,
            height_to: panelsAmount.height_to
        }

        let panelsParsed = [];
        let j = panelsAmount.panels.length;
        for (let i = 0; i < panelsAmount.panels.length; i++) {
            const panel = {
                index: j,
                panelInserts: [],
                panelInsertsType: null,
                panelHeight: panelsAmount.panels[i],
                insertsSpacing: this.defaultsService.getDefaultPanelInsertsSpacing(),
                insertsOffset: this.defaultsService.getDefaultPanelInsertsOffset()
            }
            panelsParsed.push(panel)
            j--;
        }
        panelsData = {
            panelsAmount: panelsAmountParsed,
            panels: panelsParsed
        };

        return panelsData;
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }
}
