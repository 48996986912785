/**
 * Kontroler montażu siatek
 * @param {object} $scope                  Angular Scope
 * @param {object} $uibModal                  Dostarcza obsługę okien modalnych
 * @param {object} filterFilter            Filtry
 * @param {object} MosquitoMontagesService Fabryka montażu siatek
 * @param {object} CurConfService          Bieżaca konfiguracja
 */
export default function MontagesCtrl($scope, $uibModal, filterFilter, MosquitoMontagesService, CurConfService, EventBusService) { // jshint ignore:line
    'ngInject';

    var vm = this;

    vm.subStep                   = 1;
    vm.montages                  = [];
    vm.selectMontage             = selectMontage;
    vm.profilePositions          = MosquitoMontagesService.profilePositions;
    vm.setDefaultMountinPosition = MosquitoMontagesService.setDefaultMountinPosition.bind(MosquitoMontagesService);
    vm.setLine                   = MosquitoMontagesService.setLine.bind(MosquitoMontagesService);
    vm.selectProfile             = MosquitoMontagesService.selectProfile.bind(MosquitoMontagesService);
    vm.windowLines               = {};

    if (MosquitoMontagesService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        vm.montages    = MosquitoMontagesService.montages;
        vm.windowLines = MosquitoMontagesService.windowLines;
    }

    /**
     * Funkcja wyboru montażu
     * @param  {object} montage Montaz
     */
    function selectMontage(montage) {
        MosquitoMontagesService.selectMontage(montage, true);
    }

}
