export class OfferPriceService {

    getPriceType(offer, orderPreview, userType, showPrice = true) {
        if (!showPrice || userType === 'technolog') {
            return false;
        }

        if (offer.no_price && (!userType || ['', 'klient'].indexOf(userType) > -1)) {
            return 'valuation';
        }

        if (!~~offer.order && !orderPreview && (['dealer', 'dealerh', 'klient'].indexOf(userType) > -1 || !userType || userType === 'producent' && !offer.dealer_id)) {
            return 'client';
        }
        return 'dealer';
    }

    equalPrices(price1, price2) {
        return Math.abs(price1 - price2) < 0.01;
    }
}
