import { unitMMFormatter } from '@icc/helpers';

export default function UnitMMFilter(UserService, IccConfig) {
    'ngInject';

    /**
     * Filtr do konwersji wymarów.
     * @param  {number} data           Liczba do konwersji
     * @param  {number} hasunit        1 lub 0 - czy do wymiaru dołączany ma być symbol wymiaru (mm, ″)
     * @param  {number} inmm           Czy do wymiaru innego niż w mm dołączyć informacje o wymiarze w jednostkach mm?
     * @param  {string} dimension_unit Docelowa jednostka, przy braku bierze domyślną jednostkę użytkownika / rynku
     * @param  {number} round          Ilość miejsc po przecinku
     * @return {string}                Skonwertowany wymiar.
     */
    return (data, hasunit = 0, inmm = 0, dimension_unit = '', round = null) => {
        return unitMMFormatter(data, IccConfig, hasunit, inmm, dimension_unit || UserService.get().dimension_unit, round);
    };
}
