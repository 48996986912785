import {Component, Input, Output, Inject, EventEmitter} from '@angular/core';

@Component({
  selector: 'sync-icon',
  templateUrl: './sync-icon.component.html'
})
export class SyncIconComponent {
    @Input() synced;

    get infoSynced() {
        return this.$translate.instant('SYNCHRONIZE|Zsynchronizowane');
    }

    get infoNotSynced() {
        return this.$translate.instant('SYNCHRONIZE|Niezsynchronizowane');
    }

    constructor(@Inject('$translate') private $translate) {
    }
}
