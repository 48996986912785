import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepComponent, _ } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { TypesService } from '@icc/legacy/configurator/steps/mosquito/types.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { Subscription } from 'rxjs';
import { MosquitoScreenFacade } from '../+state/mosquito-screen.facade';
import { map } from 'rxjs/operators';
import { SystemsService } from '@icc/legacy/configurator/steps/mosquito/systems.service';

@Component({
    selector: 'icc-types',
    templateUrl: './types.component.html',
    styleUrls: ['./types.component.scss'],
})
export class TypesComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Linia');
    static stepIcon = {
        ligature: 'apps',
    };

    public configurator = 'mosquito';
    public stepId = 'system';
    public title = _('CONFIGURATOR|Wybierz rodzaj');

    systems: iccListItem[] = [];
    selectedSystem$ = this.mosquitoScreenFacade.selectedSystem$.pipe(map(type => type.id));
    private subscriptions: Subscription[] = [];

    constructor(
      private systemsService: SystemsService,
      private eventBusService: EventBusService,
      private mosquitoScreenFacade: MosquitoScreenFacade
    ) {
        super();
        if (this.systemsService.loadedData) {
            this.init();
        }
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    init() {
        this.systems = this.systemsService.systems.map(type => ({
            id: type.id,
            title: type.name,
            tabs: [type.type],
            imageUrl: '/files/windowline/' + type.title_image,
        }));
    }

    selectSystem(item: iccListItem) {
      const system = this.systemsService.systems.find(s => s.id === item.id);
      if (system) {
        this.systemsService.selectSystem(system, true, true);
      }
    }
}
