export default class ConfiguratorPageModalController {
    resolve: {
        type;
    };
    close: ({ $value }) => any;
    dismiss: () => any;
    searchInput = '';

    subscribers = [];

    title = 'Modal';

    constructor(
        private $rootScope,
        private ScrollbarService,
        private InfoService,
        private $translate,
        public IccConfig,
    ) {

    }

    $onInit() {
    }

    $onChanges() {
        this.ScrollbarService.update();
    }

    $onDestroy() {
        this.subscribers.forEach(el => el.unsubscribe());
    }

    setTitle(title) {
        this.title = title;
    }

    closeModal() {
        this.close({ $value: { config: false } });
    }

}
