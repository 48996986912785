import { Common } from '@icc/common/Common';

import { StateService } from '@icc/common/state.service';
import { EventBusService } from '@icc/common/event-bus.service';

import { Injectable, Inject } from '@angular/core';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;

import {
    ConfigurationsService,
    ConfigurationType,
    Configurator,
} from '@icc/common/configurations/configurations.service';
import { TranslateService } from '@icc/common/translate.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';

@Injectable()
export class BrowserConfigurationsService<
    T extends ConfigurationType = ConfigurationType
> extends ConfigurationsService<T> {
    conf: Configurator<T>;

    constructor(
        private currentConfigurationService: CurrentConfiguratorService,
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
        protected translateService: TranslateService,
        private stateService: StateService,
        private eventBusService: EventBusService
    ) {
        super(translateService, config);
        this.init();

        this.eventBusService.subscribeWithoutConfiguration(
            ['changedConfigurator', 'initializedConfigurator'],
            () => {
                this.init();
            }
        );

        this.eventBusService.subscribe(
            [
                'changedBalcony',
                'changedFillings',
                'changedSashes',
                'changedStep',
                'putAlignmentInField',
                'putExtensionOnSide',
                'removedAlignmentInField',
                'removedAlignment',
                'removedExtensionFromSide',
                'setConstructionColor',
                'setExtensionProfile',
                'setFrameProfile',
                'setGlazingInSash',
                'setGlazingBeadInSash',
                'setMullionProfile',
                'setProfileSet',
                'setProfileColor',
                'setSashProfile',
                'setSealColor',
                'setSiliconeColor',
                'setShape',
                'setSystem',
                'setWarmEdge',
                'setLowThreshold',
                'unsetLowThreshold',
                'insertMuntins',
                'removeMuntins',
                'updateMuntins',
                'setMuntinColor',
                'updatedPrice',
                'changedHandleType',
                'changedAlushell',
                'changedOptions',
                'montageOptionsChanged',
                'changedDrive',
                'changedDoorHardware',
                'setFillingColor',
                'setDoorPortal',
                'setDoorPortalColor',
                'updatedCoupledWindow',
                'setColorsMode',
                'setAccessoryColor',
            ],
            data => {
                this.updatedConfiguration();
            }
        );
    }

    /**
     * Uruchamianie wybranego konfiguratora
     */
     init(
        startState?:
            | InstanceType<ConfigurationsService['configurations'][T]['active']>
            | InstanceType<ConfigurationsService['configurations'][T]['simple']>,
        edit = undefined,
        editedPositionPrices = null
    ) {
        const currentState = this.stateService.state;
        this.price.noPrice =
            currentState
            && currentState.offers
            && currentState.offers[0]
            && currentState.offers[0].doc
                ? currentState.offers[0].doc.no_price
                : null;

        this.conf = this.getOrInitConfiguratorConfigurations(
            this.currentConfigurationService.conf || 'window',
            startState,
            edit,
            editedPositionPrices
        ) as Configurator<T>;
        this.conf$.next(this.conf);
        this.eventBusService.setCurrentConfiguration(this.conf);

        if (Common.isObject(this.config().IccConfig.Configurators[this.currentConfigurationService.conf])) {
            this.version =
                this.config().IccConfig.Configurators[this.currentConfigurationService.conf].version || 1;
        }
    }

    getOrInitConfiguratorConfigurations<U extends ConfigurationType>(
        type: U,
        startState?:
            | InstanceType<ConfigurationsService['configurations'][U]['active']>
            | InstanceType<ConfigurationsService['configurations'][U]['simple']>
            | boolean,
        edit = undefined,
        editedPositionPrices = null
    ) {
        if (!this.configurators[type] || !this.configurators[type].Current) {
            this.initConfiguratorConfigurations(type, startState, edit, editedPositionPrices);
        } else if (startState) {
            this.loadConfiguratorConfiguration(type, startState, edit, editedPositionPrices);
        }
        return this.configurators[type] as Configurator<U>;
    }

    initConfiguratorConfigurations<U extends ConfigurationType>(
        type: U,
        startState?:
            | InstanceType<ConfigurationsService['configurations'][U]['active']>
            | InstanceType<ConfigurationsService['configurations'][U]['simple']>
            | boolean,
        edit = undefined,
        editedPositionPrices = null
    ) {
        const activeConfiguration = this.getActiveConfiguration(type);
        if (activeConfiguration) {
            this.configurators[type] = {
                Configuration: activeConfiguration,
                Current: this.setDefaults(new activeConfiguration(startState), !startState),
                Edit: edit,
                Default: this.setDefaults(new activeConfiguration(startState), !startState),
                conf: type,
                copiedPosition: false,
                measurementsAttachments: [],
                offerConfigurator: false
            };
        }
    }

    loadConfiguratorConfiguration<U extends ConfigurationType>(
        type: U,
        startState?:
            | InstanceType<ConfigurationsService['configurations'][U]['active']>
            | InstanceType<ConfigurationsService['configurations'][U]['simple']>
            | boolean,
        edit = false,
        editedPositionPrices = null
    ) {
        const activeConfiguration = this.getActiveConfiguration(type);
        if (activeConfiguration) {
            this.configurators[type].Configuration = activeConfiguration;
            this.configurators[type].Current = this.setDefaults(new activeConfiguration(startState), !startState);
            if (edit) {
                this.configurators[type].Default = this.setDefaults(new activeConfiguration(startState), !startState);
            }
            this.configurators[type].editedPositionPrices = editedPositionPrices;
            this.configurators[type].Edit = edit;
        }
    }
}
