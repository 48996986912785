export default function ModalWarningCtrl($uibModalInstance, message, step) {
    'ngInject';

    var vm = this;
    vm.message = message;
    vm.step = step;

    vm.close = close;

    function close() {
        $uibModalInstance.close();
    }
}
