import { ConfigurationsService, ConfiguratorsDataService, EventBusService } from '@icc/common';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';

import { isUndefined } from 'libs/helpers/src/lib/helpers';
import { PriceService } from '@icc/price';
import { Injectable } from '@angular/core';
import { StepsService } from '@icc/helpers';

@Injectable()
export class DriveService {
    driveType = '';
    driveSide = '';
    cranks = [];
    motors = [];
    crank = {};
    motor = {};
    crankId = 0;
    motorId = 0;
    motorOptions = [];
    options = [];
    loadedData = false;

    constructor(
        private configurationsService: ConfigurationsService<'awning'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private curConfService: CurrentConfiguratorService,
        private stepsService: StepsService,
        private priceService: PriceService,
        private eventBusService: EventBusService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribeWithoutConfiguration('changedStep', () => {
            this.init();
        });
    }

    /**
     * Funkcja inicjujaca
     */
    init() {
        if (this.curConfService.conf === 'awning') {
            this.setValues();
            if (isUndefined(this.configurationsService.conf.Current.DriveType)) {
                this.setDefaultValues();
            }

            this.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Ustawianie wartości
     */
    setValues() {
        this.cranks = this.getCranks();
        this.motors = this.getMotors();
    }

    /**
     * Zwracanie korby
     * @return {Object} Korba
     */
    getCranks() {
        return this.configuratorsDataService.data.awningCranks;
    }

    /**
     * Zwracanie silnika
     * @return {Object} Silnik
     */
    getMotors() {
        return this.configuratorsDataService.data.awningMotors;
    }

    /**
     * Wybór strony
     * @param  {Object} side Strona
     */
    selectSide(side) {
        this.driveSide = side;

        this.configurationsService.conf.Current.DriveSide = side;
        // ConfigurationsService.conf.Default.DriveSide = side;
    }

    /**
     * Wybór typu
     * @param  {Object} type     Typ
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    selectType(type, nextStep?, price?) {
        this.driveType = type;
        this.configurationsService.conf.Current.DriveType = type;
        // ConfigurationsService.conf.Default.DriveType = type;

        if (type === 'crank') {
            this.configurationsService.conf.Current.Motor = {};
            // ConfigurationsService.conf.Default.Motor = {};

            this.selectCrank(this.crank);
        } else if (type === 'motor') {
            this.configurationsService.conf.Current.Crank = {};
            // ConfigurationsService.conf.Default.Crank = {};

            this.selectMotor(this.motor);
        }

        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
        if (price) {
            this.priceService.count();
        }
    }

    /**
     * Wybór korby
     * @param  {Object} crank    Korba
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    selectCrank(crank, nextStep?, price?) {
        this.crank = crank;
        this.crankId = crank.id;

        this.configurationsService.conf.Current.Crank = crank;
        // ConfigurationsService.conf.Default.Crank = crank;

        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
        if (price) {
            this.priceService.count();
        }
    }

    /**
     * Wybór silnika
     * @param  {Object} motor    Silnik
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    selectMotor(motor, nextStep?, price?) {
        this.motor = motor;
        this.motorId = motor.id;
        this.motorOptions = motor.options;

        this.configurationsService.conf.Current.Motor = motor;
        // ConfigurationsService.conf.Default.Motor = motor;

        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
        if (price) {
            this.priceService.count();
        }
    }

    /**
     * Wybór opcji
     * @param  {Array} options Opcje
     * @param  {Object} option  Opcja
     * @param  {Number} price   Cena
     * @return {Array}         Opcje
     */
    selectOption(options, option, price) {
        var opt = options;
        var i = 0;
        var j = 0;

        for (i = 0; i < options.length; i++) {
            options[i].selected = false;
        }

        options = [];

        for (i = 0; i < this.motorOptions.length; i++) {
            if (option.id === this.motorOptions[i].id && option.selected) {
                options.push(this.motorOptions[i]);
            }
            for (j = 0; j < opt.length; j++) {
                if (opt[j].id === this.motorOptions[i].id) {
                    options.push(this.motorOptions[i]);
                }
            }
            if (option.id === this.motorOptions[i].id && !option.selected) {
                options.pop(this.motorOptions[i]);
            }
        }

        for (i = 0; i < options.length; i++) {
            options[i].selected = true;
        }

        this.options = options;
        this.configurationsService.conf.Current.MotorOptions = options;
        // ConfigurationsService.conf.Default.MotorOptions = options;

        if (price) {
            this.priceService.count();
        }

        return options;
    }

    /**
     * Wyczyść opcje
     * @param  {Array} options  Opcje
     * @param  {Number} price   Cena
     * @return {Array}          Opcje
     */
    clearOptions(options, price?) {
        var i = 0;

        for (i = 0; i < options.length; i++) {
            options[i].selected = false;
        }
        options = [];

        this.options = options;
        this.configurationsService.conf.Current.MotorOptions = options;
        // ConfigurationsService.conf.Default.MotorOptions = options;

        if (price) {
            this.priceService.count();
        }

        return options;
    }

    /**
     * Ustawia wartości domyślne
     * @param {Boolean} reset Reset
     */
    setDefaultValues(reset?) {
        if (isUndefined(this.configurationsService.conf)) {
            return;
        }

        this.setValues();

        if (reset) {
            this.selectSide('left');
            this.selectType('crank');

            this.selectCrank(this.cranks[0]);
            this.selectMotor(this.motors[0]);
        }
    }
}
