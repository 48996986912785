import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepComponent, _ } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { FrillService } from '@icc/legacy/configurator/steps/awning/frill.service';
import { EventBusService, ConfigurationsService } from '@icc/common';
@Component({
    selector: 'icc-awning-frill',
    templateUrl: './awning-frill.component.html',
    styleUrls: ['./awning-frill.component.scss'],
})
export class AwningFrillComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Rodzaj falbany');
    static stepIcon = {
        ligature: 'waves',
    };
    public mode = 'basic' || 'list';
    public configurator = 'awning';
    public stepId = 'frill';
    public title = _('COLOR|Wybierz rodzaj falbany');
    public frills: iccListItem[] = [];
    
    selectedFrill;

    subscriptions: any[] = [];

    constructor(
        private frillService: FrillService, 
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService<'awning'>
        ) {
        super();
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }


    init() {
        this.mode = 'basic';
        this.frills = this.frillService.getValances().map(valance => ({
            id: Number(valance.id),
            description: valance.description,
            title: valance.name,
            imageUrl: '/files/awningvalance/' + valance.img
        }));
        this.selectedFrill = this.frillService.getValances()[0];

    }
    
    ngOnInit() { 
        if (this.configurationsService.conf.Current) {
            this.selectedFrill = this.configurationsService.conf.Current.Valance;
        }
        if (this.frillService.loadedData)
        {
            this.init();
        }
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    onFrillChange(){
        this.mode = 'list';
    }

    selectFrill(frill) {
        const valance = this.frillService.getValances().find(f => f.id == frill.id)
        this.frillService.selectValance(valance);
        this.selectedFrill = valance;
        this.mode = 'basic'
    }
}
