export abstract class InfoService {
    abstract openInfoModal({
        template,
        message,
        title,
        footer,
        data,
        ownTemplateUrl,
        issue
    }: {
        template?: string;
        message: string;
        title: string;
        footer?: boolean;
        data?: any;
        ownTemplateUrl?: string;
        issue?: boolean;
    }): PromiseLike<void>;

    abstract openConfirmModal(
        title: string,
        question: string,
        options: {
            name: string;
            callback: () => void;
            accent?: boolean;
        }[]
    );
    abstract dismissInfoModal();
    abstract openWarning(message: string, issue?: boolean);
    abstract dismissWarning(message: string);
    abstract hideInfo();
    abstract showInfo(
        text: string,
        style?: string | null,
        url?: string | (() => void) | null,
        timeout?: number
    );
}
