import { weightUnitFormatter } from '@icc/helpers';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { AppConfigFactory, APP_CONFIG } from '@icc/common/config';

@Pipe({
    name: 'weightunit',
})
export class WeightunitPipe implements PipeTransform {
    constructor(@Inject(APP_CONFIG) private config: AppConfigFactory) {}

    transform(value: number | string, weightUnit: string, round: number) {
        return weightUnitFormatter(
            value,
            weightUnit,
            round,
            this.config().IccConfig
        );
    }
}
