import angular from 'angular';

/**
 * Funkcja Glass Thickness
 */
export default function DecoPanelTypeFilter() {
    'ngInject';

    return function(glass, type) {
        var glassArr = [];

        angular.forEach(glass, function(obj) {
            glassArr.push(obj);
        });
        if (angular.isUndefined(type) || type === null || type === '') {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {

                return type == gl.panel_type;
            });
        }
    };
}