import { SashTypes } from '@icc/window';
import { isUndefined, ModalService, IssuesService, isObject, isDefined, isArray, IssueLevel } from '@icc/helpers';
import { core, ConfigurationsService, ConfiguratorsDataService, APP_CONFIG, AppConfigFactory, ProfilesService, EventBusService, TranslateService, UserService, WindowActiveConfiguration } from '@icc/common';
import { Injectable, Inject, Optional } from '@angular/core';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceService } from '@icc/price';
import { SashTypesService } from '@icc/legacy/configurator/layout/sash-types.service';
import { SashesTypesService } from '@icc/legacy/configurator/steps/window/sashes/sashes-types.service';
import { IccSashType } from '@icc/common/data-types';
import { SashLayoutPageComponent } from 'libs/configurator/window/src/lib/sash-layout-page/sash-layout-page.component';
import { SashTypesPageComponent } from 'libs/configurator/window/src/lib/sash-types-page/sash-types-page.component';

@Injectable()
export class SashesService {
    private sashTypes: IccSashType[] = [];
    sills: any[] = [];
    layouts: any[] = [];
    loadedData = false;

    constructor(
        private modalService: ModalService,
        @Optional() @Inject('$rootScope') private $rootScope: any,
        private configurationsService: ConfigurationsService<'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private priceService: PriceService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private profilesService: ProfilesService,
        private eventBusService: EventBusService,
        private issuesService: IssuesService,
        private translateService: TranslateService,
        private sashTypesService: SashTypesService,
        private sashesTypesService: SashesTypesService,
        private userService: UserService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribe('changedSashes', data => this.validateSashes(data.activeConfiguration));
        if ($rootScope) {
            $rootScope.$on('openSashTypeModalOne', () => {
                this.sashesTypesService.openSashTypeModalOne();
            });
        }
    }

    /**
     * Funkcja inicjalizujaca
     */
    init() {
        if (
            this.currentConfiguratorService.conf === 'accessory'
            || this.currentConfiguratorService.conf === 'roller_shutter'
            || this.currentConfiguratorService.conf === 'external_blind'
            || this.currentConfiguratorService.conf === 'winter_garden'
        ) {
            return;
        }
        this.sashTypes = this.configuratorsDataService.data.sashTypes;

        this.sills = this.configuratorsDataService.data.windowSills;
        this.layouts = this.configuratorsDataService.layouts;
        this.loadedData = true;
    }

    /**
     * Funkcja otwierania okna modal skzydeł
     */
    openSashModal() {
        if (this.$rootScope) {
            this.$rootScope.hideDraw = true;
        }
        const modalInstance = this.modalService.open({
            templateUrl: 'modalSashLayout.html',
            controller: 'ModalSashCtrl as msash',
            pageComponent: SashLayoutPageComponent,
            windowClass: 'modal-sash',
        });
        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'sashesModal',
            });
        }

        modalInstance.result.then(() => {
            if (this.config().IccConfig.Configurators.dependencies) {
                this.eventBusService.post({ key: 'processDependencies', value: null });
            }

            // przerysuj rysunek
            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'editSash',
                });
            }
        });
    }

    /**
     * Funkcja dodawania parapetu (listwy?)
     */
    addSill() {
        if (this.configurationsService.conf.Current.HasSill) {
            this.configurationsService.conf.Current.Sill = core.copy(this.sills[0]);
        }
        this.priceService.count();
    }

    /**
     * Ustawianie parapetu
     */
    setSill() {
        const newSill = core.copy(
            core.fIdO(this.sills, this.configurationsService.conf.Current.Sill.id)
        );
        this.configurationsService.conf.Current.Sill = core.copy(newSill);
        this.priceService.count();
    }

    /**
     * Funkcja sprawdzająca poprawność skrzydeł
     * @param  {object} conf Konfiguracja
     */
    validateSashes(conf) {
        if (
            !conf.Sashes.filter(sash => sash.type.type === 'F').every(
                sash =>
                    (sash.nearMullions.left === -1
                        || !conf.Mullions.find(
                            mullion => mullion.id === sash.nearMullions.left
                        ).multiAlignLeft.some(
                            el =>
                                el.type.out_open
                                && (!this.config().IccConfig.Configurators.allowFixAndOutOpenSash
                                    || [...SashTypes.OUTWARD_OPENING, 'OFF'].indexOf(el.type.type) === -1)
                        ))
                    && (sash.nearMullions.right === -1
                        || !conf.Mullions.find(
                            mullion => mullion.id === sash.nearMullions.right
                        ).multiAlignRight.some(
                            el =>
                                el.type.out_open
                                && (!this.config().IccConfig.Configurators.allowFixAndOutOpenSash
                                    || [...SashTypes.OUTWARD_OPENING, 'OFF'].indexOf(el.type.type) === -1)
                        ))
                    && (sash.nearMullions.top === -1
                        || !conf.Mullions.find(
                            mullion => mullion.id === sash.nearMullions.top
                        ).multiAlignTop.some(
                            el =>
                                el.type.out_open
                                && (!this.config().IccConfig.Configurators.allowFixAndOutOpenSash
                                    || [...SashTypes.OUTWARD_OPENING, 'OFF'].indexOf(el.type.type) === -1)
                        ))
                    && (sash.nearMullions.bottom === -1
                        || !conf.Mullions.find(
                            mullion => mullion.id === sash.nearMullions.bottom
                        ).multiAlignBottom.some(
                            el =>
                                el.type.out_open
                                && (!this.config().IccConfig.Configurators.allowFixAndOutOpenSash
                                    || [...SashTypes.OUTWARD_OPENING, 'OFF'].indexOf(el.type.type) === -1)
                        ))
            )
        ) {
            this.issuesService.simpleRegister(
                'incorrect-sashes',
                'Konstrukcję należy skonfigurować w dwóch ramach.',
                this.translateService.instant('WINDOW|Konstrukcję należy skonfigurować w dwóch ramach.'),
                conf,
                {
                    logLevel: IssueLevel.NONE,
                }
            );
        } else {
            this.issuesService.unregister('incorrect-sashes', conf);
        }
    }  
}
