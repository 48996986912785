import { isUndefined } from '@icc/helpers';

/**
 * Funkcja Glass Often
 */
export function GlassOftenFilter() {
    'ngInject';

    return function(glass, often) {
        const glassArr = [];
        Object.keys(glass).forEach(key => {
            glassArr.push(glass[key]);
        });
        if (isUndefined(often) || often === null || often === '' || !~~often) {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {
                return !!~~gl.selected === !!~~often;
            });
        }
    };
}
