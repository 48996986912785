import angular from 'angular';
import $ from 'jquery';

/**
 * @ngdoc controller
 * @name ICC.Configurators.ModalColorPVCCtrl
 *
 * @param {Service}  $uibModalInstance  Angular $uibModalInstance
 * @param {Service}  $timeout        Angular $timeout
 * @param {Service}  $rootScope      Angular $rootScope
 * @param {object[]} colors          Kolory
 * @param {object[]} colorsRAL       Kolory RAL
 * @param {object[]} colorGroups     Grupy kolorów
 * @param {object}   selectedColor   Wybrany kolor
 * @param {bool}     transparentWood Czy lakierowanie transparentne
 * @param {bool}     colorsSashExt   Czy inny kolor skrzydła i ramy
 * @param {string}   type            Rodzaj koloru
 * @description
 *
 * Popup wyboru kolorów.
 */
export default function ModalColorPVCCtrl($rootScope, $uibModalInstance, $timeout, colors, colorGroups,
    selectedColor, transparentWood, colorsSashExt, type, ScrollbarService
) {
    'ngInject';

    var vm = this;

    const colorGroupsWOBrushedAlushell = colorGroups.filter(group => !group.brushed_alu_group);


    /**
     * Kolory
     * @memberof ModalColorPVCCtrl
     * @type {object[]}
     */
    vm.colors          = colors;

    /**
     * Wybrana grupa
     * @memberof ModalColorPVCCtrl
     * @type {string|int}
     */

    vm.selectedGroup = 0;
    const selectedGroup = selectedColor.groups != null && selectedColor.groups.find(g => colorGroups.includes(g));
    if (selectedGroup) {
        vm.selectedGroup = Number(selectedGroup);
    } else if (vm.colorsSashExt) {
        vm.selectedGroup = 'none';
    } else if (colorGroupsWOBrushedAlushell.length > 0) {
        vm.selectedGroup = Number(colorGroupsWOBrushedAlushell[0].id);
        if (selectedColor.groups) {
            const colorGroup = selectedColor.groups.find(id => colorGroupsWOBrushedAlushell.some(group => Number(group.id) === Number(id)));
            if (colorGroup) {
                vm.selectedGroup = Number(colorGroup);
            }
        }
    }
    /**
     * Wybrany kolor
     * @memberof ModalColorPVCCtrl
     * @type {object}
     */
    vm.selectedColor   = selectedColor;
    /**
     * Czy malowanie transparentne
     * @memberof ModalColorPVCCtrl
     * @type {bool}
     */
    vm.transparentWood = transparentWood;
    /**
     * Grupy kolorów
     * @memberof ModalColorPVCCtrl
     * @type {object[]}
     */
    vm.colorGroups     = colorGroupsWOBrushedAlushell;
    /**
     * Czy inny kolor skrzydła i ramy
     * @memberof ModalColorPVCCtrl
     * @type {bool}
     */
    vm.colorsSashExt   = colorsSashExt;
    /**
     * Rodzaj koloru.
     * @memberof ModalColorPVCCtrl
     * @type {string}
     */
    vm.type            = type;

    vm.selectColor = selectColor;
    vm.isSelected  = isSelected;
    vm.close       = close;
    vm.refresh     = refresh;

    /**
     * Zamyka popup po wybraniu koloru, zwaracając wybrany.
     * @memberof ModalColorPVCCtrl
     * @param  {object} color Wybrany kolor
     * @param  {bool}   RAL   Czy wybrany kolor jest kolorem RAL
     */
    function selectColor(color, RAL) {
        $uibModalInstance.close({
            color: color,
            group: vm.selectedGroup,
            RAL  : RAL
        });
    }

    /**
     * Zwraca czy dany kolor jest wybrany.
     * @memberof ModalColorPVCCtrl
     * @param  {object} color Kolor
     * @param  {bool}   RAL   Czy wybrany kolor jest kolorem RAL
     */
    function isSelected(color, RAL) {
        if (angular.isDefined(color.id) && angular.isDefined(vm.selectedColor) && angular.isDefined(vm.selectedColor.id) && color.id == vm.selectedColor.id && RAL == color.RAL) {
            return true;
        }
        if (angular.isUndefined(angular.isDefined(vm.selectedColor) && angular.isDefined(vm.selectedColor.id) && vm.selectedColor.id) && color == 'none') {
            return true;
        }
        return false;
    }

    /**
     * Zamyka popup.
     * @memberof ModalColorPVCCtrl
     */
    function close() {
        $uibModalInstance.close();
    }

    /**
     * Odświeża scrollbar.
     * @memberof ModalColorPVCCtrl
     */
    function refresh() {
        ScrollbarService.update();
    }

}
