import { SharedModule } from '@icc/configurator/shared';
import { ConfiguratorUiModule } from '@icc/configurator/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListPageComponent } from './list-page/list-page.component';
import { ModalInfoComponent } from './modal-info/modal-info.component';
import { IccDrawModule } from '@icc/draw';
import { HotkeyModule } from 'angular2-hotkeys';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ClientInfoDialogComponent } from './client-info-dialog/client-info-dialog.component';
import { MatTableModule } from '@angular/material/table';


@NgModule({
    imports: [MatTableModule, CommonModule, TranslateModule, ConfiguratorUiModule, SharedModule, IccDrawModule, HotkeyModule],
    exports: [ListPageComponent, ProductDetailsComponent, ClientInfoDialogComponent, ModalInfoComponent],
    declarations: [ListPageComponent, ProductDetailsComponent, ClientInfoDialogComponent, ModalInfoComponent],
    entryComponents: [ListPageComponent, ProductDetailsComponent, ClientInfoDialogComponent, ModalInfoComponent]
})
export class ConfiguratorPagesModule {}
