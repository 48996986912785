import * as angular from 'angular';

import Databases from './Databases/databases';
import Infrastructure from './Infrastructure/infrastructure';

import { OnlineStatusService } from './online-status.service';
import { ResourceService } from './resource.service';
import { SynchronizeService } from './synchronize.service';
import SynchronizeCtrl from './SynchronizeCtrl';
import {downgradeInjectable, downgradeComponent} from '@angular/upgrade/static';
import UpdateCtrl from './UpdateCtrl';
import {SyncIconComponent} from '@icc/legacy/synchronize/icon/sync-icon.component';

export default angular.module('icc.sync', [Databases, Infrastructure])
    .factory('OnlineStatusService', downgradeInjectable(OnlineStatusService))
    .factory('ResourceService', downgradeInjectable(ResourceService))
    .factory('SynchronizeService', downgradeInjectable(SynchronizeService))
    .controller('SynchronizeCtrl', SynchronizeCtrl)
    .controller('UpdateCtrl', UpdateCtrl)
    .directive('syncIcon', downgradeComponent({
        component: SyncIconComponent,
        inputs: ['synced'],
        outputs: []
    }))
    .value('syncInterval1', 3000)
    .value('syncInterval2', 7000)
    .value('syncInterval3', 11000)
    .name;
