import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, Inject } from '@angular/core';
import { StepComponent, _, SharedFacade } from '@icc/configurator/shared';
import { EventBusService, APP_CONFIG, AppConfigFactory } from '@icc/common';
import { SystemsService } from '@icc/legacy/configurator/steps/window/systems/systems.service';
import { ICC_PAGE_DATA } from '@icc/helpers';
import { IccSystem } from '@icc/common/data-types';

@Component({
  selector: 'icc-awning-accessories-info',
  templateUrl: './awning-accessories-info.component.html',
  styleUrls: ['./awning-accessories-info.component.scss']
})
export class AwningAccessoriesInfoComponent extends StepComponent implements OnInit {
  public configurator = 'awning';
  public stepId = 'accessoriesInfo';
  public title = _('AWNING|Informacje na temat akcesorii');
  public options = [];

  accessory: any | null = null;

  constructor(
      private eventBusService: EventBusService,
      private systemsService: SystemsService,
      private sharedFacade: SharedFacade,
      @Inject(APP_CONFIG) private config: AppConfigFactory,
      @Inject(ICC_PAGE_DATA) private pageData: {
          accessory: any,
      }
  ) {
      super();
  }

  ngOnInit() {
      this.accessory = this.pageData.accessory;
  }

  close() {
      this.sharedFacade.closePage(this.accessory);
  }
}
