import angular from 'angular';

/**
 * @ngdoc filter
 * @name ICC.Configurators.colorsByNameOrCode
 *
 * @description
 * Wyszukuje kolory zgodne z podaną nazwą lub kodem.
 */
export default function ColorsByNameOrCodeFilter() {
    'ngInject';

    /**
     * @func colorsByNameOrCodeFilter
     * @memberof colorsByNameOrCode
     * @param  {object[]} colors Kolory
     * @param  {string}   name   Nazwa
     * @param  {string}   code   Kod
     * @return {object[]}        Przefiltrowane kolory
     */
    return function(colors, name = '', code = '') {
        var nameRegex = new RegExp(name, 'ig');
        var codeRegex = new RegExp(code, 'ig');

        return colors.filter((color) =>
            angular.isString(color.name) && color.name.match(nameRegex)
            || angular.isString(color.code) && color.code.match(codeRegex)
        );
    };
}
