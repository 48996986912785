import { NgModule } from '@angular/core';
import { HandleHeightService } from '@icc/common/configurators/handle-height.service';
import { HandlesService } from './handles.service';
import { FittingService } from './fitting.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [HandleHeightService, HandlesService, FittingService],
})
export class HandlesModule {
    constructor() {}
}
