import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepComponent, _, StepsService, StartedChangingStepValue } from '@icc/configurator/shared';
import { FormBuilder } from '@angular/forms';
import { MosquitoScreenFacade } from '../+state/mosquito-screen.facade';
import { Subscription } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { EventBusService } from '@icc/common';
import { DimensionsService } from '@icc/legacy/configurator/steps/mosquito/dimensions.service';
import { UnitConverterService } from '@icc/configurator/ui';

@Component({
    selector: 'icc-dimensions',
    templateUrl: './dimensions.component.html',
    styleUrls: ['./dimensions.component.scss'],
})
export class DimensionsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('DIMENSION|Rozmiar');
    static stepIcon = {
        ligature: 'settings_overscan',
    };

    public configurator = 'mosquito';
    public stepId = 'dimensions';
    public title = _('DIMENSION|Uzupełnij wymiary');

    dimensionsForm = this.fb.group({
        width: [0],
        sashWidth: [0],
        height: [0],
        sashHeight: [0]
    });

    private subscriptions: Subscription[] = [];
    hasSashDimensions$ = this.mosquitoScreenFacade.selectedType$.pipe(
        map(type => type.has_sash_dimensions)
    );

    dimensionUnit = this.unitConverterService.getUnit();

    constructor(
        private fb: FormBuilder,
        private mosquitoScreenFacade: MosquitoScreenFacade,
        private eventBusService: EventBusService,
        private dimensionsService: DimensionsService,
        private stepsService: StepsService,
        private unitConverterService: UnitConverterService
    ) {
        super();
        if (this.dimensionsService.loadedData) {
          this.init();
        }

        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration(['initializedConfigurator'], () =>
                this.init()
            )
        );
        this.subscriptions.push(
          this.eventBusService.subscribe<StartedChangingStepValue>('startedChangingStep', data => {
              if (
                  data.value.nextStep.code !== 'dimensions'
                  && data.value.nextStep.code !== data.value.prevStep.code
                  && data.value.prevStep.code === 'dimensions'
              ) {
                  if (this.dimensionsService.valid(this.dimensionsForm.value)) {
                      this.dimensionsService.setDimensions(this.dimensionsForm.value);
                  } else {
                      this.stepsService.selectStep(data.value.prevStep.i);
                  }
              }
          })
      );
    }

    ngOnInit() {

    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    init() {
        this.mosquitoScreenFacade.shape$.pipe(take(1)).subscribe(shape => {
            this.dimensionsForm.patchValue(shape);
        });
    }
}
