/**
 * Kontroler szklenia
 * @param {object} $scope                Angular scope
 * @param {object} $window               Okna
 * @param {object} $uibModal             Dostarcza obsługę okien modalnych
 * @param {object} $rootScope            Angular root scope
 * @param {object} flashMessage          Flash Message
 * @param {object} $timeout              Timeout
 * @param {object} OffersGroupFactory    Fabryka grup oferty
 * @param {object} glassCatFilter        Filtr kategorii szkła
 * @param {object} glassOrnamentFilter   Filtr ornamentów
 */
export default function OffersGroupCtrl($scope, $window, $uibModal, $rootScope, flashMessage, $timeout, // jshint ignore:line
                    OffersGroupFactory) {
    'ngInject';

    var vm                = this;
    vm.openModalGlazing   = OffersGroupFactory.openModalGlazing;
    vm.openModalWarmEdge  = OffersGroupFactory.openModalWarmEdge;
    vm.openModalHandle    = OffersGroupFactory.openModalHandle;
    vm.openModalFitting   = OffersGroupFactory.openModalFitting;
    vm.addPositionToGroup = OffersGroupFactory.addPositionToGroup;
}
