import { Accessory } from '../common';
import { ConfiguratorsDataService } from '../../../configurators/configurators-data.service';
import { HardwareVisualization } from '@icc/common/data-types';

export class ActiveAccessory {
    count = 0;
    amount = 0;
    position = 0;
    width = 0;
    height = 0;
    weight = 0;
    accessory: any = {};
    pos = 0;
    side: any = null;
    color: any = null;
    category = '';
    colorOptions: string = null;
    comment: string = null;
    index_no = '';
    visualization: HardwareVisualization | null = null;

    constructor(
        accessory: Accessory,
        place = null,
        configuratorsDataService?: ConfiguratorsDataService
    ) {
        this.count = accessory.count;
        this.position = accessory.position;
        this.amount = accessory.amount;
        this.width = accessory.width;
        this.height = accessory.height;
        this.weight = accessory.weight;
        this.accessory = configuratorsDataService.get('windowAccessories', accessory.id);
        this.accessory.currentLength = accessory.currentLength;
        this.accessory.currentWidth = accessory.width;
        this.accessory.currentHeight = accessory.height;
        this.accessory.fromDependency = accessory.fromDependency;
        this.accessory.changedColor = accessory.changedColor;
        this.accessory.addedWithDependency = accessory.addedWithDependency;
        this.accessory.addedWithRemovableDependency = accessory.addedWithRemovableDependency;
        this.accessory.dependencyAssociationIds = accessory.dependencyAssociationIds;
        this.accessory.spaceId = accessory.spaceId;
        this.pos = 0;
        this.side = place;
        this.color = null;
        this.category = accessory.category.name;
        this.colorOptions = accessory.colorSet;
        this.comment = accessory.comment;
        this.index_no = accessory.index_no;
        this.visualization = accessory.visualization;
        if (this.accessory.visualizations[this.visualization?.colorId]) {
            this.accessory.visualizations[this.visualization?.colorId].space = this.visualization.space;
        }
        if (accessory.priceSource === 'table') {
            this.accessory.colorOptions = accessory.colorSet;
        }
        if (accessory.priceSource === 'colors' && accessory.color) {
            this.color = accessory.color.accessory.id;
            // Potrzebujemy id koloru jako string żeby był poprawie wybrany w selekcie
            this.accessory.selectedColor = accessory.color.accessory.id + '';
            this.accessory.color = configuratorsDataService.get(
                'windowHandlesColors',
                accessory.color.accessory.id
            );
            this.accessory.color.isDefault = false;
        }
        if (accessory.priceSource === 'confColors' && accessory.color) {
            this.accessory.selectedColor = {
                frame: {
                    outer: {},
                    inner: {},
                    core: {},
                },
                sash: {
                    outer: {},
                    inner: {},
                    core: {},
                },
            };
            ['outer', 'inner', 'core'].forEach((side) => {
                if (accessory.color.profile[side] && accessory.color.profile[side].id) {
                    this.accessory.selectedColor[side] = configuratorsDataService.get(
                        side === 'core' ? 'colors' : 'windowColorsAll',
                        accessory.color.profile[side].id
                    );
                    if (this.accessory.selectedColor[side]) {
                        this.accessory.selectedColor[side].isDefault =
                            accessory.color.profile[side]?.isDefault;
                    }
                }
            });
            if (accessory.wood) {
                this.accessory.selectedWood = configuratorsDataService.get(
                    'woodTypes',
                    accessory.wood?.id
                );
            }
        }
    }
}
