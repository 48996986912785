const AddPaymentModalTemplate = require('ngtemplate-loader!./add-payment-modal.component.html');
import { AddPaymentModalController } from './add-payment-modal.controller';

export const AddPaymentModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: AddPaymentModalTemplate,
  controller: AddPaymentModalController
};
