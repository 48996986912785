import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@icc/common/translate.service';
import {
    TranslateService as NgxTranslateService,
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
} from '@ngx-translate/core';

@Injectable()
export class BrowserTranslateService extends TranslateService {
    constructor(private translateService: NgxTranslateService) {
        super();
    }

    instant(
        translationId: string,
        interpolateParams?: any,
        interpolationId?: string,
        forceLanguage?: string,
        sanitizeStrategy?: string
    ): string {
        return this.translateService.instant(translationId, interpolateParams);
    }

    get(
        translationId: string,
        interpolateParams?: any
    ): Promise<string> {
        return new Promise((resolve) => {
            this.translateService.get(translationId, interpolateParams)
                .subscribe((translation: string) => {
                    resolve(translation);
                });
        });
    }
}
