import angular from 'angular';

/**
 * Fabryka napędu markiz
 * @param {object} $rootScope               RootScope
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} ConfiguratorsDataService Fabryka z danymi do konfiguratorów
 * @param {object} CurConfService           Informacje o bieżącym konfiguratorze
 * @param {object} StepFactory              StepFactory
 * @param {object} PriceService             Liczenie cen
 */
export default function DriveFactory($rootScope, ConfigurationsService, ConfiguratorsDataService, CurConfService, StepFactory, PriceService, EventBusService) {
    'ngInject';

    var factory = {
        driveType       : '',
        driveSide       : '',
        cranks          : [],
        motors          : [],
        crank           : {},
        motor           : {},
        crankId         : 0,
        motorId         : 0,
        motorOptions    : [],
        options         : [],
        loadedData      : false,
        selectSide      : selectSide,
        selectType      : selectType,
        selectCrank     : selectCrank,
        selectMotor     : selectMotor,
        selectOption    : selectOption,
        clearOptions    : clearOptions,
        setDefaultValues: setDefaultValues
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    $rootScope.$on('changedStep', init);

    return factory;

    /**
     * Funkcja inicjujaca
     */
    function init() {
        if (CurConfService.conf === 'awning') {
            setValues();
            if (angular.isUndefined(ConfigurationsService.conf.Current.DriveType)) {
                factory.setDefaultValues();
            }
            if (ConfigurationsService.conf.Current.DriveType === '') {
                factory.setDefaultValues();
            }
            factory.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Ustawianie wartości
     */
    function setValues() {
        factory.cranks = getCranks();
        factory.motors = getMotors();
    }

    /**
     * Zwracanie korby
     * @return {Object} Korba
     */
    function getCranks() {
        return ConfiguratorsDataService.data.awningCranks;
    }

    /**
     * Zwracanie silnika
     * @return {Object} Silnik
     */
    function getMotors() {
        return ConfiguratorsDataService.data.awningMotors;
    }

    /**
     * Wybór strony
     * @param  {Object} side Strona
     */
    function selectSide(side) {

        factory.driveSide = side;

        ConfigurationsService.conf.Current.DriveSide = side;
        // ConfigurationsService.conf.Default.DriveSide = side;
    }

    /**
     * Wybór typu
     * @param  {Object} type     Typ
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    function selectType(type, nextStep, price) {

        factory.driveType = type;

        ConfigurationsService.conf.Current.DriveType = type;
        // ConfigurationsService.conf.Default.DriveType = type;

        if (type === 'crank') {

            ConfigurationsService.conf.Current.Motor = {};
            // ConfigurationsService.conf.Default.Motor = {};

            selectCrank(factory.crank);
        } else if (type === 'motor') {

            ConfigurationsService.conf.Current.Crank = {};
            // ConfigurationsService.conf.Default.Crank = {};

            selectMotor(factory.motor);
        }

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }
    }

    /**
     * Wybór korby
     * @param  {Object} crank    Korba
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    function selectCrank(crank, nextStep, price) {

        factory.crank = crank;
        factory.crankId = crank.id;

        ConfigurationsService.conf.Current.Crank = crank;
        // ConfigurationsService.conf.Default.Crank = crank;

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }
    }

    /**
     * Wybór silnika
     * @param  {Object} motor    Silnik
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    function selectMotor(motor, nextStep, price) {

        factory.motor = motor;
        factory.motorId = motor.id;
        factory.motorOptions = motor.options;

        ConfigurationsService.conf.Current.Motor = motor;
        // ConfigurationsService.conf.Default.Motor = motor;

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }
    }

    /**
     * Wybór opcji
     * @param  {Array} options Opcje
     * @param  {Object} option  Opcja
     * @param  {Number} price   Cena
     * @return {Array}         Opcje
     */
    function selectOption(options, option, price) {

        var opt = options;
        var i = 0;
        var j = 0;

        for (i = 0; i < options.length; i++) {
            options[i].selected = false;
        }

        options = [];

        for (i = 0; i < factory.motorOptions.length; i++) {
            if (option.id === factory.motorOptions[i].id && option.selected) {
                options.push(factory.motorOptions[i]);
            }
            for (j = 0; j < opt.length; j++) {
                if (opt[j].id === factory.motorOptions[i].id) {
                    options.push(factory.motorOptions[i]);
                }
            }
            if (option.id === factory.motorOptions[i].id && !option.selected) {
                options.pop(factory.motorOptions[i]);
            }
        }

        for (i = 0; i < options.length; i++) {
            options[i].selected = true;
        }

        factory.options = options;
        ConfigurationsService.conf.Current.MotorOptions = options;
        // ConfigurationsService.conf.Default.MotorOptions = options;

        if (price) {
            PriceService.count();
        }

        return options;
    }

    /**
     * Wyczyść opcje
     * @param  {Array} options  Opcje
     * @param  {Number} price   Cena
     * @return {Array}          Opcje
     */
    function clearOptions(options, price) {

        var i = 0;

        for (i = 0; i < options.length; i++) {
            options[i].selected = false;
        }
        options = [];

        factory.options = options;
        ConfigurationsService.conf.Current.MotorOptions = options;
        // ConfigurationsService.conf.Default.MotorOptions = options;

        if (price) {
            PriceService.count();
        }

        return options;
    }

    /**
     * Ustawia wartości domyślne
     * @param {Boolean} reset Reset
     */
    function setDefaultValues(reset) {

        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }

        setValues();

        if (
            ConfigurationsService.conf.Current.DriveType === '' ||
            reset
        ) {
            factory.selectSide('left');
            factory.selectType('crank');

            factory.selectCrank(factory.cranks[0]);
            factory.selectMotor(factory.motors[0]);
        }
    }

}
