<ng-container *ngIf="mode ==='list'">
  <icc-list itemImgHeight="medium" itemTemplate="mountingMethod" [items]='montageTypeOptions'
    (select)="onChangeListItemClick($event)">
  </icc-list>
</ng-container>
<ng-container *ngIf="mode ==='advanced'">
  <form [formGroup]='measurements' class="measurements_form__container">
    <icc-select-box [title]="selectedMountingMethod.name"
      [description]="''" [imageUrl]="'/files/mountingmethod/' + selectedMountingMethod.img" [buttonText]="'INTERFACE|Zmień' | translate"
      (select)="toggleList()"></icc-select-box>
    <ng-container>
      <div class='dimension_inputs'>
        <div class='dimensions_inputs__column dimensions_inputs__column--caption_left'>
          <span class='space'></span>
          <p>{{'WINDOW|Szerokość:' | translate}} </p>
          <p>{{'WINDOW|Wysokość:' | translate}}</p>
        </div>
        <div class='dimensions_inputs__column' *ngIf='checkField("widthOutside") || checkField("hightOutside")'>
          <p class='column__caption'>{{'WINDOW|Wymiary zewnętrzne' | translate}}</p>
          <mat-form-field class='dimensions_inputs__form_field dimensions_inputs__form_field--width'>
            <svg matPrefix width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="2" y="2" width="166" height="166" fill="white" stroke="black" stroke-width="4" />
              <rect x="32" y="32" width="106" height="106" fill="white" stroke="black" stroke-width="4"
                stroke-linejoin="bevel" stroke-dasharray="20 20" />
              <path
                d="M38.2322 83.2322C37.2559 84.2085 37.2559 85.7915 38.2322 86.7678L54.1421 102.678C55.1184 103.654 56.7014 103.654 57.6777 102.678C58.654 101.701 58.654 100.118 57.6777 99.1421L43.5355 85L57.6777 70.8579C58.654 69.8816 58.654 68.2986 57.6777 67.3223C56.7014 66.346 55.1184 66.346 54.1421 67.3223L38.2322 83.2322ZM132.768 86.7678C133.744 85.7915 133.744 84.2085 132.768 83.2322L116.858 67.3223C115.882 66.346 114.299 66.346 113.322 67.3223C112.346 68.2986 112.346 69.8816 113.322 70.8579L127.464 85L113.322 99.1421C112.346 100.118 112.346 101.701 113.322 102.678C114.299 103.654 115.882 103.654 116.858 102.678L132.768 86.7678ZM40 87.5H131V82.5H40V87.5Z"
                fill="#F50707" />
            </svg>


            <input formControlName='widthOutside' #measurementsinput matInput iccDimensionInput type='number' min='0' id="widthOutside">
            <span matSuffix iccDimensionInput>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
          <mat-form-field class='dimensions_inputs__form_field  dimensions_inputs__form_field--height'>
            <svg matPrefix width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="2" y="2" width="166" height="166" fill="white" stroke="black" stroke-width="4" />
              <rect x="32" y="32" width="106" height="106" fill="white" stroke="black" stroke-width="4"
                stroke-linejoin="bevel" stroke-dasharray="20 20" />
              <path
                d="M87.0975 38.2091C86.1111 37.2429 84.5283 37.2593 83.5621 38.2457L67.818 54.3197C66.8519 55.306 66.8683 56.8889 67.8547 57.855C68.841 58.8212 70.4239 58.8047 71.39 57.8184L85.3848 43.5304L99.6728 57.5252C100.659 58.4913 102.242 58.4749 103.208 57.4885C104.174 56.5022 104.158 54.9193 103.171 53.9532L87.0975 38.2091ZM84.542 132.776C85.5284 133.742 87.1113 133.726 88.0774 132.74L103.822 116.666C104.788 115.679 104.771 114.096 103.785 113.13C102.799 112.164 101.216 112.18 100.25 113.167L86.2547 127.455L71.9668 113.46C70.9804 112.494 69.3976 112.51 68.4314 113.497C67.4653 114.483 67.4817 116.066 68.4681 117.032L84.542 132.776ZM82.8483 40.021L83.7915 131.016L88.7913 130.964L87.848 39.9691L82.8483 40.021Z"
                fill="#F50707" />
            </svg>



            <input formControlName='hightOutside' #measurementsinput matInput iccDimensionInput type='number' min='0' id="hightOutside">
            <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
        </div>
        <div class='dimensions_inputs__column' *ngIf='checkField("widthInside") || checkField("hightInside")'>
          <p class='column__caption'>{{'WINDOW|Wymiary wewnętrzne' | translate}}</p>
          <mat-form-field class='dimensions_inputs__form_field dimensions_inputs__form_field--width'>
            <svg matPrefix width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="2" y="2" width="166" height="166" fill="white" stroke="black" stroke-width="4" />
              <rect x="32" y="32" width="106" height="106" fill="white" stroke="black" stroke-width="4"
                stroke-linejoin="bevel" stroke-dasharray="20 20" />
              <path
                d="M164.276 86.2678C165.252 85.2915 165.252 83.7085 164.276 82.7322L148.366 66.8223C147.39 65.846 145.807 65.846 144.831 66.8223C143.854 67.7986 143.854 69.3816 144.831 70.3579L158.973 84.5L144.831 98.6421C143.854 99.6184 143.854 101.201 144.831 102.178C145.807 103.154 147.39 103.154 148.366 102.178L164.276 86.2678ZM5.74053 82.7322C4.76422 83.7086 4.76422 85.2915 5.74053 86.2678L21.6504 102.178C22.6267 103.154 24.2097 103.154 25.186 102.178C26.1623 101.201 26.1623 99.6185 25.186 98.6421L11.0438 84.5L25.186 70.3579C26.1623 69.3816 26.1623 67.7987 25.186 66.8223C24.2096 65.846 22.6267 65.846 21.6504 66.8223L5.74053 82.7322ZM162.508 82L7.50829 82L7.50829 87L162.508 87L162.508 82Z"
                fill="#F50707" />
            </svg>

            <input formControlName='widthInside' #measurementsinput matInput iccDimensionInput type='number' min='0' id="widthInside">
            <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
          <mat-form-field class='dimensions_inputs__form_field dimensions_inputs__form_field--height'>
            <svg matPrefix width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="2" y="2" width="166" height="166" fill="white" stroke="black" stroke-width="4" />
              <rect x="32" y="32" width="106" height="106" fill="white" stroke="black" stroke-width="4"
                stroke-linejoin="bevel" stroke-dasharray="20 20" />
              <path
                d="M83.2322 163.768C84.2085 164.744 85.7915 164.744 86.7678 163.768L102.678 147.858C103.654 146.882 103.654 145.299 102.678 144.322C101.701 143.346 100.118 143.346 99.1421 144.322L85 158.464L70.8579 144.322C69.8816 143.346 68.2986 143.346 67.3223 144.322C66.346 145.299 66.346 146.882 67.3223 147.858L83.2322 163.768ZM86.7678 6.23224C85.7914 5.25592 84.2085 5.25592 83.2322 6.23224L67.3223 22.1421C66.346 23.1185 66.346 24.7014 67.3223 25.6777C68.2986 26.654 69.8815 26.654 70.8579 25.6777L85 11.5355L99.1421 25.6777C100.118 26.654 101.701 26.654 102.678 25.6777C103.654 24.7014 103.654 23.1185 102.678 22.1421L86.7678 6.23224ZM87.5 162L87.5 8L82.5 8L82.5 162L87.5 162Z"
                fill="#F50707" />
            </svg>

            <input formControlName='hightInside' #measurementsinput matInput iccDimensionInput type='number' min='0' id="hightInside">
            <span matSuffix>{{dimensionUnit.unitSymbol}}</span>
          </mat-form-field>
        </div>
      </div>

      <mat-radio-group class='radio_group' formControlName="fit">
        <mat-radio-button type="radio" value="frame" name="isMatchedWithOpeningWidth">{{'WINDOW|Dopasowanie wym. ramy do światła otworu' | translate}}</mat-radio-button>
        <mat-radio-button type="radio" value="construction" name="isMatchedWithOpeningWidth"> {{'WINDOW|Dopasowanie wym. całej konstrukcji do otworu' | translate}}</mat-radio-button>
      </mat-radio-group>
    </ng-container>
    <button (click)="onSave()" class='save_button' mat-raised-button color='primary'>{{'WINDOW|Zapisz pomiary' | translate}}</button>
    <button (click)="onCalc()" class='save_button' mat-raised-button color='primary'>{{'WINDOW|Przelicz i zapisz pomiary' | translate}}</button>
  </form>

</ng-container>
