export default function ConvertToNumberDirective() {
    'ngInject';

    return {
        require: 'ngModel',
        link   : function(scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function(val) {
                return val * 1;
            });
            ngModel.$formatters.push(function(val) {
                return '' + val;
            });
        }
    };
}
