import { GarageDoorDefaultsService } from './+state/garage-door-defaults.service';
import { TranslateService } from '@ngx-translate/core';
import { EventBusService } from './../../../../common/src/lib/event-bus.service';
import { GarageDoorFacade } from './+state/garage-door.facade';
import { Injectable } from '@angular/core';
import { ModalService, IssueLevel } from '@icc/helpers';
import { ModalListComponent } from './modal-list/modal-list.component';
import { _, IssuesService } from '@icc/configurator/shared';
import { core } from '@icc/common/helpers';
import { ConfigurationsService } from '@icc/common';
import { Subscription } from 'rxjs';

@Injectable()
export class MechanismsService {
  private subscriptions: Subscription[] = [];

  private allMechanisms: any[] = [];
  private allGuidances: any[] = [];
  private allSizeRanges: any[] = [];

  private selectedMechanism;
  private selectedGuidance;
  private selectedDimensions;

  constructor(
      private modalService: ModalService,
      private garageDoorFacade: GarageDoorFacade,
      private eventBusService: EventBusService,
      private issuesService: IssuesService,
      private configurationsService: ConfigurationsService,
      private translateService: TranslateService,
      private defaultsService: GarageDoorDefaultsService
  ) {
    this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
      const conf = this.configurationsService.conf.Current;
      if (conf.type === 'garage_door') {
        this.subscriptions.push(
          this.garageDoorFacade.availableMechanisms$.subscribe(mechanisms => {
            this.allMechanisms = mechanisms;
          }),
          this.garageDoorFacade.availableSizeRanges$.subscribe(sizeRanges => {
            this.allSizeRanges = sizeRanges;
          }),
          this.garageDoorFacade.availableGuidances$.subscribe(guidances => {
            this.allGuidances = guidances;
          }),
          this.garageDoorFacade.selectedGuidance$.subscribe(g => {
            if (g) {
              this.issuesService.unregister('no-garage-door-guidance', conf);
            } else {
              this.registerNoGuidanceIssue(conf);
            }
            this.selectedGuidance = g;
          }),
          this.garageDoorFacade.selectedDimensions$.subscribe(d => {
            this.selectedDimensions = d;
            this.validateAndSetDefaultMechanisms();
            this.validateAndSetDefaultGuidance();
          }),
          this.garageDoorFacade.selectedMechanism$.subscribe(m => {
            if (m) {
              this.issuesService.unregister('no-garage-door-mechanism', conf);
            } else {
              this.registerNoMechanismIssue(conf);
            }
            this.selectedMechanism = m;
            this.validateAndSetDefaultGuidance();
          })
        );
      }
    });
  }

  getFilteredMechanisms() {
    return this.allMechanisms.filter(mechanism => this.filterMechanism(mechanism)) || [];
  }

  filterMechanism(mechanism) {
    let sizeRange;
    let isInDimensions = false;
    if (
      this.selectedDimensions
      && this.selectedDimensions.width
      && this.selectedDimensions.height
      && mechanism
      && mechanism.sizes
      && mechanism.sizes.length > 0
    ) {
      mechanism.sizes.forEach(s => {
        if (s.size_range_id == -1) isInDimensions = true;
        sizeRange = this.allSizeRanges.find(sizeRange => sizeRange.id == s.size_range_id);
        if (sizeRange && core.pointInPolygon(sizeRange.sizes, this.selectedDimensions.width, this.selectedDimensions.height)) {
          isInDimensions = true;
        }
      })
    }
    return isInDimensions;
  }

  getFilteredGuidances() {
    return this.allGuidances.filter(g =>  {
        return (this.selectedMechanism
                && this.selectedMechanism.id
                && this.selectedDimensions
                && this.selectedDimensions.width
                && this.selectedDimensions.height)
              && (g.garage_door_mechanisms && g.garage_door_mechanisms.includes(this.selectedMechanism.id))
              && (!g.min_lintel_height || g.min_lintel_height <= this.selectedDimensions.lintelHeight)
      }
    );
  }

  openMechanismModal() {
    const filteredMechanisms = this.getFilteredMechanisms();
    this.modalService
      .open({
          pageComponent: ModalListComponent,
          resolve: {
              items: filteredMechanisms,
              selectedItemId: this.selectedMechanism.id,
              modalListTitle: _('GARAGEDOOR|Wybierz rodzaj sprężyn'),
              modalListFilesDir: '/files/garagedoormechanism/',
              itemImgAttributeName: 'img',
              showItemInfo: true,
              itemTemplate: 'itemImgTitle'
          },
      })
      .result.then(selectedMechanism => {
          if (selectedMechanism) {
              this.garageDoorFacade.setMechanism(selectedMechanism);
          }
      });
  }

  openGuidanceModal() {
    const filteredGuidances = this.getFilteredGuidances();
    this.modalService
      .open({
          pageComponent: ModalListComponent,
          resolve: {
              items: filteredGuidances,
              selectedItemId: this.selectedGuidance.id,
              modalListTitle: _('GARAGEDOOR|Wybierz prowadzenie'),
              modalListFilesDir: '/files/garagedoorguide/',
              itemImgAttributeName: 'img',
              itemTemplate: 'itemImgTitle'
          },
      })
      .result.then(selectedGuidance => {
          if (selectedGuidance) {
              this.garageDoorFacade.setGuidance(selectedGuidance);
          }
      });
  }

  validateAndSetDefaultMechanisms() {
    let defaultMechanism;
      const filteredMechanisms = this.getFilteredMechanisms();
      let isSelectedMechanismStillAvailable = false;
      if (this.selectedMechanism) {
        isSelectedMechanismStillAvailable = filteredMechanisms.find(g => g.id == this.selectedMechanism.id);
      }
      if (!isSelectedMechanismStillAvailable) {
        defaultMechanism = filteredMechanisms.find(m => m.id == this.defaultsService.getDefaultMechanism().id) || filteredMechanisms[0];
        this.garageDoorFacade.setMechanism(defaultMechanism);
      }
  }

  validateAndSetDefaultGuidance() {
    let defaultGuidance;
      const filteredGuidances = this.getFilteredGuidances();
      let isSelectedGuidanceStillAvailable = false;
      if (this.selectedGuidance) {
        isSelectedGuidanceStillAvailable = filteredGuidances.find(g => g.id == this.selectedGuidance.id);
      }
      if (!isSelectedGuidanceStillAvailable) {
        defaultGuidance = filteredGuidances.find(g => g.id == this.defaultsService.getDefaultGuidance().id) || filteredGuidances[0];
        this.garageDoorFacade.setGuidance(defaultGuidance);
      }

  }

  registerNoMechanismIssue(conf) {
    this.issuesService.simpleRegister(
      'no-garage-door-mechanism',
      'Nie znaleziono pasujących sprężyn.',
      this.translateService.instant('CONFIGURATOR|Wybrany rodzaj sprężyn jest nieprawidłowy.'),
      conf,
      {
          logLevel: IssueLevel.NONE
      }
    );
  }

  registerNoGuidanceIssue(conf) {
    this.issuesService.simpleRegister(
      'no-garage-door-guidance',
      'Nie znaleziono pasujących prowadzeń.',
      this.translateService.instant('CONFIGURATOR|Wybrane prowadzenie jest nieprawidłowe.'),
      conf,
      {
          logLevel: IssueLevel.NONE
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.map(el => el.unsubscribe());
  }
}
