import { Common } from '@icc/common/Common';
import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import {Injectable} from '@angular/core';

@Injectable()
export class PriceDependenciesService {
    constructor() {}

    /**
     * Wylicza dopłatę z ograniczeń
     *
     * @param {number} price         Wejsciowa cena
     * @param {object} PriceElems    Składowe wyceny
     * @param {array}  NoPriceCauses Powody braku wyceny
     * @param {array}  constrElems   Lista elementów konstrukcyjnych, do przeliczenia przez dopłatę.
     * @param {object} sealColor     Okucie bezpieczne
     * @return {number} Cena z doliczoną dopłatą.
     */
    @PriceFunc({
        shortName: 'dependencies',
        data: {
            dependencies  : 'conf.Dependencies'
        }
    })
    suppDependencies({}: PriceElemsData,
        {dependencies}: {dependencies}
    ): PriceSegment[] {
        let priceSegments: PriceSegment[] = [];
        if (Common.isArray(dependencies) && dependencies.length) {
            dependencies.forEach(elem => {
                switch (elem.type) {
                    case 'valuation':
                        priceSegments.push({
                            type: 'dependencies',
                            baseValue: null,
                            value: null,
                            valueType: 'value',
                            data: {
                                id: elem.id
                            }
                        }); break;
                    case 'construction':
                        priceSegments.push({
                            type: 'dependencies',
                            baseValue: elem.value,
                            value: elem.value,
                            valueType: 'value',
                            data: {
                                id: elem.id
                            }
                        }); break;
                }
            });
        }

        return priceSegments;
    }

    /**
     * Wylicza dopłatę do skrzydeł z ograniczeń
     *
     * @param {number} price         Wejsciowa cena
     * @param {object} PriceElems    Składowe wyceny
     * @param {array}  NoPriceCauses Powody braku wyceny
     * @param {array}  constrElems   Lista elementów konstrukcyjnych, do przeliczenia przez dopłatę.
     * @param {object} sealColor     Okucie bezpieczne
     * @return {number} Cena z doliczoną dopłatą.
     */
    @PriceFunc({
        shortName: 'sashDependencies',
        data: {
            dependencies  : 'conf.Dependencies'
        }
    })
    suppSashDependencies({}: PriceElemsData,
        {dependencies}: {dependencies}
    ): PriceSegment[] {
        let priceSegments: PriceSegment[] = [];
        if (Common.isArray(dependencies) && dependencies.length) {
            dependencies.forEach(elem => {
                if (elem.type === 'sash') {
                    priceSegments.push({
                        type: 'sashDependencies',
                        baseValue: elem.value,
                        value: elem.value,
                        valueType: 'value',
                        data: {
                            id: elem.id,
                            sashId: elem.sashId
                        }
                    });
                }
            });
        }

        return priceSegments;
    }
}
