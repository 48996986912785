import { TranslateService } from '@icc/common/translate.service';
import { InsertsService } from './inserts.service';
import { GarageDoorDefaultsService } from './+state/garage-door-defaults.service';
import { GarageDoorFacade } from './+state/garage-door.facade';
import { Injectable, OnDestroy } from '@angular/core';
import { ModalService, IssueLevel } from '@icc/helpers';
import { ModalListComponent } from './modal-list/modal-list.component';
import { _, IssuesService } from '@icc/configurator/shared';
import { ConfigurationsService, EventBusService, core } from '@icc/common';
import { Subscription } from 'rxjs';

@Injectable()
export class WicketDoorsService implements OnDestroy {
    private subscriptions: Subscription[] = [];

    private allWicketDoors = [];
    private allColors = [];
    private selectedPanel;
    private selectedDimensions;
    private selectedMechanism;
    private selectedWicketDoor;
    private selectedWicketDoorDirection: 'left' | 'right';
    private selectedWicketDoorFrameProfilesColor;
    private selectedWicketDoorHandleColor;

    private conf;

    constructor(
        private modalService: ModalService,
        private garageDoorFacade: GarageDoorFacade,
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService,
        private defaultsService: GarageDoorDefaultsService,
        private insertsService: InsertsService,
        private issuesService: IssuesService,
        private translateService: TranslateService
    ) {
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.conf = this.configurationsService.conf.Current;
            if (this.conf.type === 'garage_door') {
                this.subscriptions.push(
                    this.garageDoorFacade.availableWicketDoors$.subscribe(w => {
                        this.allWicketDoors = w;
                    }),
                    this.garageDoorFacade.selectedPanel$.subscribe(p => {
                        this.selectedPanel = p;
                        this.validateSelectedWicketDoor();
                    }),
                    this.garageDoorFacade.selectedDimensions$.subscribe(d => {
                        this.selectedDimensions = d;
                        this.validateSelectedWicketDoor();
                    }),
                    this.garageDoorFacade.selectedMechanism$.subscribe(m => {
                        this.selectedMechanism = m;
                        this.validateSelectedWicketDoor();
                    }),
                    this.garageDoorFacade.selectedWicketDoor$.subscribe(w => {
                        this.selectedWicketDoor = w;
                        this.validateAndSetDefaultFrameProfilesColor();
                        this.validateAndSetDefaultHandleColor();
                    }),
                    this.garageDoorFacade.selectedWicketDoorDirection$.subscribe(d => {
                        this.selectedWicketDoorDirection = d;
                    }),
                    this.garageDoorFacade.selectedWicketDoorFrameProfilesColor$.subscribe(c => {
                        this.selectedWicketDoorFrameProfilesColor = c;
                    }),
                    this.garageDoorFacade.selectedWicketDoorHandleColor$.subscribe(c => {
                        this.selectedWicketDoorHandleColor = c;
                    }),
                    this.garageDoorFacade.availableColors$.subscribe(c => {
                        this.allColors = c;
                    })
                );
            }
        });
    }

    getDefaultWicketDoorX(wicketDoor): number {
        return this.selectedDimensions.width / 2 - wicketDoor.width / 2;
    }

    getWicketDoorAvailableXPositions(wicketDoor = this.selectedWicketDoor): number[] {
        let availableXPositions = [];
        const defaultShift = this.defaultsService.getWicketDoorShift();
        const minOffset = this.defaultsService.getMinWicketDoorOffset();
        let x = this.getDefaultWicketDoorX(wicketDoor);
        availableXPositions.push(x);

        while ((x + (wicketDoor.width / 2)) > minOffset) {
            x -= defaultShift;
            if (this.validateWicketDoorXPosition(x, wicketDoor)) {
                availableXPositions.push(x);
            }
        }
        x = this.getDefaultWicketDoorX(wicketDoor);
        while ((x + (wicketDoor.width / 2)) < this.selectedDimensions.width - minOffset) {
            x += defaultShift;
            if (this.validateWicketDoorXPosition(x, wicketDoor)) {
                availableXPositions.push(x);
            }
        }
        return availableXPositions.sort((a, b) => a - b);
    }

    moveWicketDoorLeft() {
        const wicketDoor = core.copy(this.selectedWicketDoor);
        const availableXPositions = this.getWicketDoorAvailableXPositions(wicketDoor);
        const currentXIndex = availableXPositions.indexOf(wicketDoor.x);
        if (currentXIndex > 0) {
            wicketDoor.x = availableXPositions[currentXIndex - 1];
            this.garageDoorFacade.setWicketDoor(wicketDoor);
            // Usuń wszystkie wypełnienia furtek
            this.insertsService.removeAllInsertsInWicketDoorPanels();
        }
    }

    moveWicketDoorRight() {
        const wicketDoor = core.copy(this.selectedWicketDoor);
        const availableXPositions = this.getWicketDoorAvailableXPositions(wicketDoor);
        const currentXIndex = availableXPositions.indexOf(wicketDoor.x);
        if (currentXIndex < availableXPositions.length - 1) {
            wicketDoor.x = availableXPositions[currentXIndex + 1];
            this.garageDoorFacade.setWicketDoor(wicketDoor);
            // Usuń wszystkie wypełnienia furtek
            this.insertsService.removeAllInsertsInWicketDoorPanels()
        }
    }

    canMoveWicketDoorLeft(): boolean {
        let canMoveLeft = false;
        const wicketDoor = core.copy(this.selectedWicketDoor);
        const availableXPositions = this.getWicketDoorAvailableXPositions(wicketDoor);
        const currentXIndex = availableXPositions.indexOf(wicketDoor.x);
        if (currentXIndex > 0) {
            canMoveLeft = availableXPositions[currentXIndex - 1] ? true : false;
        }
        return canMoveLeft;
    }

    canMoveWicketDoorRight(): boolean {
        let canMoveRight = false;
        const wicketDoor = core.copy(this.selectedWicketDoor);
        const availableXPositions = this.getWicketDoorAvailableXPositions(wicketDoor);
        const currentXIndex = availableXPositions.indexOf(wicketDoor.x);
        if (currentXIndex < availableXPositions.length - 1) {
            canMoveRight = availableXPositions[currentXIndex + 1] ? true : false;
        }
        return canMoveRight;
    }

    validateWicketDoorXPosition(
        x = this.selectedWicketDoor.x,
        wicketDoor = this.selectedWicketDoor
    ): boolean {
        const minOffset = this.defaultsService.getMinWicketDoorOffset();
        return (
            x + wicketDoor.width / 2 > minOffset
            && x + wicketDoor.width / 2 < this.selectedDimensions.width - minOffset
        );
    }

    getWicketDoorMiddle(wicketDoor = this.selectedWicketDoor): number {
        return wicketDoor.x + wicketDoor.width / 2;
    }

    getFilteredWicketDoors() {
        return (
            this.allWicketDoors.filter(w => {
                return (
                    w.panels
                    && w.panels.includes(this.selectedPanel.id)
                    && this.selectedDimensions.width >= w.min_garage_door_width
                    && this.selectedDimensions.width <= w.max_garage_door_width
                    && this.selectedMechanism.id == w.garage_door_mechanism_id
                    && w.heights_data
                    && w.heights_data.find(
                        h =>
                            h.min_garage_door_height
                            <= this.selectedDimensions.height
                            <= h.max_garage_door_height
                    )
                );
            }) || []
        );
    }

    getFilteredFrameProfilesColors() {
        return this.allColors.filter(c => {
            return this.selectedWicketDoor && this.selectedWicketDoor.frame_profiles_colors.includes(c.id)
        }) || [];
    }

    getFilteredHandleColors() {
        return this.allColors.filter(c => {
            return this.selectedWicketDoor && this.selectedWicketDoor.handle_colors.includes(c.id)
        }) || [];
    }

    getSelectedWicketDoor() {
        return this.selectedWicketDoor;
    }

    openWicketDoorFrameProfilesColorsModal() {
        const filteredFrameProfilesColors = this.getFilteredFrameProfilesColors();
        this.modalService
            .open({
                pageComponent: ModalListComponent,
                resolve: {
                    items: filteredFrameProfilesColors,
                    selectedItemId: this.selectedWicketDoorFrameProfilesColor.id,
                    modalListTitle: _('GARAGEDOOR|Wybierz kolor profili obramowania'),
                    modalListFilesDir: '/files/garagedoorcolor/',
                    itemImgAttributeName: 'color_img',
                    searchable: true
                },
            })
            .result.then(selectedColor => {
                if (selectedColor) {
                    this.garageDoorFacade.setWicketDoorFrameProfilesColor(selectedColor);
                }
            });
    }

    openWicketDoorHandleColorsModal() {
        const filteredHandleColors = this.getFilteredHandleColors();
        this.modalService
            .open({
                pageComponent: ModalListComponent,
                resolve: {
                    items: filteredHandleColors,
                    selectedItemId: this.selectedWicketDoorHandleColor.id,
                    modalListTitle: _('GARAGEDOOR|Wybierz kolor klamki'),
                    modalListFilesDir: '/files/garagedoorcolor/',
                    itemImgAttributeName: 'color_img',
                },
            })
            .result.then(selectedColor => {
                if (selectedColor) {
                    this.garageDoorFacade.setWicketDoorHandleColor(selectedColor);
                }
            });
    }

    validateSelectedWicketDoor() {
        if (this.selectedWicketDoor) {
            const filteredWicketDoors = this.getFilteredWicketDoors();
            const isSelectedWicketDoorStillAvailable = filteredWicketDoors.find(w => {
                return (
                    w.id == this.selectedWicketDoor.id
                    && this.getWicketDoorHeight(w) === this.selectedWicketDoor.height
                );
            });
            if (!isSelectedWicketDoorStillAvailable) {
                this.disableAndUnsetWicketDoor();
            } else {
                const availableXPositions = this.getWicketDoorAvailableXPositions(this.selectedWicketDoor);
                if (!availableXPositions.includes(this.selectedWicketDoor.x)) {
                    const wicketDoor = core.copy(this.selectedWicketDoor);
                    wicketDoor.x = this.getDefaultWicketDoorX(wicketDoor);
                    this.garageDoorFacade.setWicketDoor(wicketDoor);
                }
            }
        }
    }

    setWicketDoor(wicketDoorId) {
        const filteredWicketDoors = this.getFilteredWicketDoors();
        const wicketDoor = filteredWicketDoors.find(w => w.id == wicketDoorId);
        wicketDoor.height = this.getWicketDoorHeight(wicketDoor);
        wicketDoor.x = this.getDefaultWicketDoorX(wicketDoor);
        if (wicketDoor.height && wicketDoor.x) {
            this.garageDoorFacade.setWicketDoor(wicketDoor);
        }
    }

    setWicketDoorDirection(direction: 'left' | 'right' = 'right') {
        this.garageDoorFacade.setWicketDoorDirection(direction);
    }

    setDefaultWicketDoorValues() {
        const defaultWicketDoor = this.defaultsService.getDefaultWicketDoor();
        const defaultWicketDoorSide = this.defaultsService.getDefaultWicketDoorSide();
        const defaultWicketDoorFrameProfilesColor = this.defaultsService.getDefaultWicketDoorFrameProfilesColor();
        const defaultWicketDoorHandleColor = this.defaultsService.getDefaultWicketDoorHandleColor();

        const filteredWicketDoors = this.getFilteredWicketDoors();
        const filteredWicketDoorFrameProfilesColors = this.getFilteredFrameProfilesColors();
        const filteredWicketDoorHandleColors = this.getFilteredHandleColors();

        const wicketDoor = filteredWicketDoors.find(w => w.id == defaultWicketDoor.id) || filteredWicketDoors[0];
        const wicketDoorFrameProfilesColor = filteredWicketDoorFrameProfilesColors.find(c => c.id == defaultWicketDoorFrameProfilesColor.id)
                                             || filteredWicketDoorFrameProfilesColors[0];
        const wicketDoorHandleColor = filteredWicketDoorHandleColors.find(c => c.id == defaultWicketDoorHandleColor.id)
                                      || filteredWicketDoorHandleColors[0];

        wicketDoor.height = this.getWicketDoorHeight(wicketDoor);
        wicketDoor.x = this.getDefaultWicketDoorX(wicketDoor);
        if (wicketDoor.height && wicketDoor.x) {
            const wicketDoorPanelIndexes = this.insertsService.getWicketDoorPanelsIndexes(wicketDoor);

            // Remove all the inserts that are on the panels with wicket door
            this.insertsService.removeGarageDoorInsertsFromPanels(wicketDoorPanelIndexes);
            this.garageDoorFacade.setWicketDoor(wicketDoor);
            this.garageDoorFacade.setWicketDoorDirection(defaultWicketDoorSide);
            this.insertsService.validateAndSetGlazingsGlobalImpostsByAmount();
        }
    }

    getWicketDoorHeight(wicketDoor) {
        const heightsData =
            wicketDoor
            && wicketDoor.heights_data
            && wicketDoor.heights_data.find(h => {
                return (
                    h.min_garage_door_height <= this.selectedDimensions.height
                    && h.max_garage_door_height >= this.selectedDimensions.height
                );
            });
        return heightsData && heightsData.wicket_door_height;
    }

    getWicketDoorYPosition(wicketDoor = this.selectedWicketDoor): number {
        return wicketDoor.threshold_height + wicketDoor.height;
    }

    disableAndUnsetWicketDoor() {
        this.garageDoorFacade.setWicketDoor(null);
        this.garageDoorFacade.setWicketDoorDirection(null);
        this.garageDoorFacade.setWicketDoorFrameProfilesColor(null);
        this.garageDoorFacade.setWicketDoorHandleColor(null);
        this.insertsService.validateAndSetGlazingsGlobalImpostsByAmount();
    }

    validateAndSetDefaultFrameProfilesColor() {
        const filteredFrameProfilesColors = this.getFilteredFrameProfilesColors();
        let isSelectedFrameProfilesColorAvailable = false;
        if (this.selectedWicketDoorFrameProfilesColor) {
            isSelectedFrameProfilesColorAvailable = filteredFrameProfilesColors.find(
                s => s.id == this.selectedWicketDoorFrameProfilesColor.id
            );
        }
        if (!isSelectedFrameProfilesColorAvailable) {
            const defaultFrameProfilesColor = filteredFrameProfilesColors.find(c => c.id === this.defaultsService.getDefaultWicketDoorFrameProfilesColor().id)
                                              || filteredFrameProfilesColors[0];
            this.garageDoorFacade.setWicketDoorFrameProfilesColor(defaultFrameProfilesColor);
        }
    }

    validateAndSetDefaultHandleColor() {
        const filteredHandleColors = this.getFilteredHandleColors();
        let isSelectedHandleColorAvailable = false;
        if (this.selectedWicketDoorHandleColor) {
            isSelectedHandleColorAvailable = filteredHandleColors.find(
                s => s.id == this.selectedWicketDoorHandleColor.id
            );
        }
        if (!isSelectedHandleColorAvailable) {
            const defaultHandleColor = filteredHandleColors.find(c => c.id === this.defaultsService.getDefaultWicketDoorHandleColor().id)
                                              || filteredHandleColors[0];
            this.garageDoorFacade.setWicketDoorHandleColor(defaultHandleColor);
        }
    }

    registerNoWicketDoorsIssue(conf=this.conf) {
        this.issuesService.simpleRegister(
            'no-garage-door-wicket-doors',
            'Brak odpowiednich furtek',
            this.translateService.instant(
                'CONFIGURATOR|Brak odpowiednich furtek dla wybranych wymiarów bramy i rodzaju sprężyn'
            ),
            conf,
            {
                logLevel: IssueLevel.NONE,
            }
        );
    }

    unregisterNoWicketDoorsIssue(conf=this.conf) {
        this.issuesService.unregister('no-garage-door-wicket-doors', conf)
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }
}
