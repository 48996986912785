import { CustomPrice, CustomPrices, CustomPricesDealers } from './CustomPrice';
import { Injectable } from '@angular/core';

/**
 * Usługa indywidualnych cen.
 *
 * @export
 * @class CustomPricesService
 */
@Injectable()
export abstract class CustomPricesService {
    /**
     * Indywidualne ceny dealera.
     *
     * @private
     * @type {CustomPrices}
     * @memberOf CustomPricesService
     */
    protected customPrices: CustomPrices;

    /**
     * Indywidualne ceny dealerów.
     *
     * @private
     * @type {CustomPricesDealers}
     * @memberOf CustomPricesService
     */
    protected customPricesDealers: CustomPricesDealers;

    /**
     * Ładuje indywidaulne ceny z bazy.
     *
     * @async
     * @memberOf CustomPricesService
     */
    abstract load();

    /**
     * Parsuje tablicę rekordów.
     *
     * @private
     * @param {any} records Rekordy.
     *
     * @memberOf CustomPricesService
     */
    parseRecords(records) {
        for (const i in records) {
            records[i] = new CustomPrice(records[i]);
        }
    }

    /**
     * Zwraca indywidaulne ceny danego dealera.
     *
     * @param {any} dealerId   Id dealera
     * @returns {CustomPrices} Indywidualne ceny dealera.
     *
     * @memberOf CustomPricesService
     */
    get(dealerId: number) {
        return (
            (this.customPricesDealers
                && (this.customPricesDealers[dealerId] || this.customPricesDealers[0]))
            || this.customPrices
        );
    }
}
