import { Issue } from './issues';

export interface ChangedStepValue {
    prevStep: {
        i: number;
        code: string;
    };
    nextStep: {
        i: number;
        code: string;
    };
}

export interface StartedChangingStepValue {
    prevStep: {
        i: number;
        code: string;
    };
    nextStep: {
        i: number;
        code: string;
    };
    cancel: (issues?: Issue[]) => void;
}

export abstract class StepsService {
    hasStep(code: string) {
        return this.getStepByCode(code) > -1;
    }

    /**
     * Zwraca indeks kroku na podstawie jego kodu.
     * @param  {string} code Kod kroku.
     * @return {number} Indeks kroku.
     */
    abstract getStepByCode(code: string): number;

    /**
     * Zwraca indeks obecnego kroku.
     * @return {number} Indeks kroku.
     */
    abstract getStep(): number;

    /**
     * Ustawia wybrany krok, na podstwie jego numeru lub nazwy.
     * @param  {number} newStep Indeks kroku lub jego nazwa.
     * @param  {bool} noEvent Jeśli true funkcja nie wywołuje zdarzenia changedStep.
     */
    abstract selectStep(newStep: number | string, noEvent?: boolean);

    abstract goToNextStep(validation?: boolean);

    abstract enable(step: number | string);

    abstract disable(step: number | string);

    abstract switchEnable(step: number | string);

    abstract isVisited(step: number | string): boolean;

    abstract getStepVisits(step: number | string): number;

    abstract getLastStep(): {
        step: number
    };
}
