import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    ConfiguratorState,
    CONFIGURATOR_KEY,
    UiState,
    ConfiguratorRootState,
} from './shared.reducers';

const getConfiguratorRootState = createFeatureSelector<ConfiguratorRootState>(CONFIGURATOR_KEY);
const getConfiguratorState = createSelector(
    getConfiguratorRootState,
    state => state.configurator
);

const getUiState = createSelector(
    getConfiguratorState,
    (state: ConfiguratorState) => state.uiState
);

const getLoaded = createSelector(
    getUiState,
    (state: UiState) => state.loaded
);
const getError = createSelector(
    getUiState,
    (state: UiState) => state.error
);

const getOptions = createSelector(
    getUiState,
    state => state.options
);

const getConfig = createSelector(
    getOptions,
    options => options.config
);

const getCurrency = createSelector(
    getOptions,
    options => options.currency
);
const getHomeButtonName = createSelector(
    getOptions,
    options => options.homeButtonText
)
const getMessages = createSelector(
    getUiState,
    uiState => uiState.messages.filter(m => m.issue)
);

const getLanguageAndProject = createSelector(
    getOptions,
    options => ({
        language: options.languageCode,
        projectName: options.projectName,
        theme: options.theme,
        logo: options.logo,
        domain: options.domain,
    })
);

const getClosedPages = createSelector(
    getUiState,
    uiState => uiState.closedPages
);

const getClosedFilterSheets = createSelector(
    getUiState,
    uiState => uiState.closedFilterSheets
);

const getFullscreen = createSelector(
    getUiState,
    uiState => uiState.fullscreen
);

const getPreview = createSelector(
    getUiState,
    uiState => uiState.previewData
);

const getSystemStepMode = createSelector(
    getUiState,
    uiState => uiState.systemStepMode
);

const getLightsGlazingStepMode = createSelector(
    getUiState,
    uiState => uiState.lightsGlazingStepMode
);

const getSavingButton = createSelector(
    getUiState,
    uiState => uiState.savingButton
);

const getReturnButton = createSelector(
    getUiState,
    uiState => uiState.returnButton
);

const getAdditionalReturnButtons = createSelector(
    getUiState,
    uiState => uiState.additionalReturnButtons
);

const getActiveSearch = createSelector(
    getUiState,
    uiState => uiState.activeSearch
);

const getSearchValue = createSelector(
    getUiState,
    uiState => uiState.searchValue
);

const getIsModalConfigurator = createSelector(
    getUiState,
    uiState => uiState.parentConfigurationType != null
);

const getStepsDescriptions = createSelector(
    getOptions,
    options => options.stepsDescriptions
);

const getCurrencies = createSelector(
    getOptions,
    options => options.currencies
);

export const sharedQuery = {
    getConfiguratorState,
    getUiState,
    getOptions,
    getConfig,
    getLoaded,
    getError,
    getMessages,
    getLanguageAndProject,
    getClosedPages,
    getClosedFilterSheets,
    getCurrency,
    getFullscreen,
    getSystemStepMode,
    getLightsGlazingStepMode,
    getSavingButton,
    getReturnButton,
    getAdditionalReturnButtons,
    getActiveSearch,
    getPreview,
    getSearchValue,
    getIsModalConfigurator,
    getStepsDescriptions,
    getCurrencies
};
