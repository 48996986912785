import { ModalTypesComponent } from './../modal-types/modal-types.component';
import { PleatedBlindFacade } from './../+state/pleated-blind.facade';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepComponent, _, ModalService } from '@icc/configurator/shared';
import { ColorsPageComponent } from '../colors-page/colors-page.component';

@Component({
    selector: 'icc-drive',
    templateUrl: './drives.component.html',
    styleUrls: ['./drives.component.scss'],
})
export class DrivesComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS| Napęd');
    static stepIcon = {
        ligature: 'touch_app',
    };
    private subscriptions: any[] = [];
    public configurator = 'awning';
    public stepId = 'drive';
    public title = _('COLOR|Wybierz rodzaj napędu');


    selectedDriveType;
    selectedGuidanceType;
    selectedClosureType;
    selectedDriveType$ = this.blindFacade.selectedDrive$;
    selectedDriveSide$ = this.blindFacade.selectedDriveSide$;
    selectedGuidanceType$ = this.blindFacade.selectedGuidanceType$;
    selectedClosureType$ = this.blindFacade.selectedClosureType$;

    drives = []
    guidanceTypes = [];
    closureTypes = [];
    colors = [];

    availableDrives$ = this.blindFacade.availableDrives$;
    availableGuidanceTypes$ = this.blindFacade.availableGuidanceTypes$;
    availableClosureTypes$  = this.blindFacade.availableClosureTypes$;
    availableColors$ = this.blindFacade.availableColors$;
    
    driveSide;
    constructor(
        private blindFacade: PleatedBlindFacade,
        private modalService: ModalService
    ) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(
            this.availableDrives$.subscribe(drives => {
                this.drives = drives;
            }),
    
            this.availableGuidanceTypes$.subscribe(types => {
                this.guidanceTypes = types;
            }),
    
            this.availableClosureTypes$.subscribe(types => {
                this.closureTypes = types;
            }),
    
            this.selectedDriveType$.subscribe(type => {
                this.selectedDriveType = type;
            }),
    
            this.selectedGuidanceType$.subscribe(type => {
                this.selectedGuidanceType = type;
            }),

            this.selectedClosureType$.subscribe(closure => {
                this.selectedClosureType = closure
            }),
            
            this.selectedDriveSide$.subscribe(side => {
                this.driveSide = side;
            }),
    
            this.availableColors$.subscribe(colors => {
                this.colors = colors;
            }),
        )
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    selectDrive() {
        this.modalService.open({
            pageComponent: ModalTypesComponent,
            resolve: {
                modalTypes: this.drives,
                selectedModalTypeId: this.selectedDriveType.id
            }
        }).result.then(result => {
            this.setDrive(result);
            if (result.drive_type === 'static_handle' || result.drive_type === 'movable_handle') {
                this.changeDriveColor(result);
            }
        })
    }

    selectGuidanceType() {
        this.modalService.open({
            pageComponent: ModalTypesComponent,
            resolve: {
                modalTypes: this.guidanceTypes,
                selectedModalTypeId: this.selectedGuidanceType.id
            }
        }).result.then(result => {
            this.setGuidanceType(result);
            if (result.guidance_type === 'fence') {
                this.changeGuidanceColor(result);
            }
        })
    }

    selectClosureType() {
        this.modalService.open({
            pageComponent: ModalTypesComponent,
            resolve: {
                modalTypes: this.closureTypes,
                selectedModalTypeId: this.selectedClosureType.id
            }
        }).result.then(result => {
            this.setClosureType(result);
        })
    }

    setDrive(drive) {
        this.blindFacade.setDrive(drive)
    }

    setDriveSide(side) {
        this.blindFacade.setDriveSide(side);
    }

    setGuidanceType(guidanceType) {
        this.blindFacade.setGuidanceType(guidanceType);
    }

    setClosureType(closureType) {
        this.blindFacade.setGuidanceType(closureType);
    }

    changeDriveColor(drive) {
        this.modalService.open({
            pageComponent: ColorsPageComponent,
            resolve    : {
            colors: () => this.colors,
            selectedColorId: this.selectedDriveType.color.id
            }
        }).result.then(result => {
            drive = {...drive, color: result}
            this.setDrive(drive)
        })
    }

    changeGuidanceColor(guidance) {
        this.modalService.open({
            pageComponent: ColorsPageComponent,
            resolve    : {
            colors: () => this.colors,
            selectedColorId: this.selectedGuidanceType.color.id
            }
        }).result.then(result => {
            guidance = {...guidance, color: result}
            this.setGuidanceType(guidance)
        })
    }
}
