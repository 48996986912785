import angular from 'angular';

export class CurrencyInputDirective {
    constructor() {
        'ngInject';

        this.restrict = 'A';
        this.require = 'ngModel';
        this.scope = {currency: '<'};
    }

    link(scope, element, attr, ngModel) {
        let rate = 1;
        if (angular.isDefined(scope.currency)
            && (angular.isDefined(scope.currency.value)
                || typeof scope.currency === 'string')) {
            if (typeof scope.currency === 'string') {
                try {
                    const o = JSON.parse(scope.currency);
                    if (o && typeof o === 'object' && o !== null) {
                        rate = !o.pricesInCurrency ? parseFloat(o.value) : 1;
                    }
                } catch (e) {
                    rate = 1;
                }
            } else if (scope.currency && scope.currency !== null) {
                rate = !scope.currency.pricesInCurrency ? parseFloat(scope.currency.value) : 1;
            }
        }

        ngModel.$formatters.push((value) => {
            value = Math.round(100 * (parseFloat(value) || 0) / rate) / 100;
            return value;
        });

        ngModel.$parsers.push((value) => {
            value = Math.round(100 * (parseFloat(value) || 0) * rate) / 100;
            return value;
        });
    }
}
