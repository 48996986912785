import { Injectable } from '@angular/core';
import {TimeLimit, TimeLimitSegment} from './time-limit-functions';

@Injectable()
export class TimeLimitHingeService {

    /**
     * Pobiera termin realizacji zawiasu.
     *
     * @param {object} hinge   Zawias
     * @return {number} Termin realizacji zawiasu.
     */
    @TimeLimit({
        shortName: 'hinge',
        data: {
            hinge : 'conf.Hinge',
        }
    })
    hingeTimeLimit({timeLimits, hinge}): TimeLimitSegment[] {
        if (hinge == null || !hinge.id || !hinge.time_limit_id) {
            return null;
        }

        const timeLimitId = hinge.time_limit_id;

        return <TimeLimitSegment[]>[{
            type: 'hinge',
            value: timeLimits[timeLimitId],
            data: {
                id  : hinge.id,
                name: hinge.name,
            }
        }];
    }
}
