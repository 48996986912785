import { core } from '../../../helpers';
import { MosquitoConfiguration } from '../../MosquitoConfiguration';
import { ColorIdLegacy, ColorLegacy } from '../../legacy/parts/common/Colors';

/**
 * Przeniesienie profili rolet do profili
 */
export function changesInVersion6(
    oldConfiguration: MosquitoConfiguration,
    dataRequiredToUpdate: {
        windowColorRalsMap: Record<number | string, number>;
    }
) {
    const configuration = core.copy(oldConfiguration);
    if (configuration.colors) {
        Object.keys(configuration.colors).forEach((c: keyof MosquitoConfiguration['colors']) => {
            if (
                configuration.colors[c]
                && configuration.colors[c] != null
                && configuration.colors[c].id in dataRequiredToUpdate.windowColorRalsMap
            ) {
                updateColorId(configuration.colors[c]);
            }
        });
    }

    return configuration;

    function updateColorId(color: ColorLegacy | ColorIdLegacy) {
        if (color.RAL) {
            color.id = dataRequiredToUpdate.windowColorRalsMap[color.id] || color.id;
        }
    }
}
