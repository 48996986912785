import {DatabaseManagerToken} from '@icc/helpers/browser';

export function databaseManagerFactory(i: any) {
  return i.get('DatabaseManager');
}
export const databaseManagerProvider = {
  provide: DatabaseManagerToken,
  useFactory: databaseManagerFactory,
  deps: ['$injector']
};

export function positionsFactoryFactory(i: any) {
  return i.get('PositionsFactory');
}
export const positionsFactoryProvider = {
  provide: 'PositionsFactory',
  useFactory: positionsFactoryFactory,
  deps: ['$injector']
};
