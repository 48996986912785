import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypesComponent } from './types/types.component';
import { DimensionsComponent } from './dimensions/dimensions.component';
import { VariantsComponent } from './variants/variants.component';
import { ColorsComponent } from './colors/colors.component';
import { MontagesComponent } from './montages/montages.component';
import { TypesListPageComponent } from './types-list-page/types-list-page.component';
import { ColorsListPageComponent } from './colors-list-page/colors-list-page.component';
import { MontagesListPageComponent } from './montages-list-page/montages-list-page.component';
import { NetTypesComponent } from './net-types/net-types.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
    MOSQUITOSCREEN_FEATURE_KEY,
    initialState as mosquitoScreenInitialState,
    mosquitoScreenReducer,
} from './+state/mosquito-screen.reducer';
import { MosquitoScreenEffects } from './+state/mosquito-screen.effects';
import { MosquitoScreenFacade } from './+state/mosquito-screen.facade';
import { SharedModule } from '@icc/configurator/shared';
import { MosquitoModule } from '@icc/legacy/configurator/steps/mosquito/mosquito.module';
import { ConfiguratorUiModule } from '@icc/configurator/ui';
import { ConfiguratorPagesModule } from '@icc/configurator/pages';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ConfiguratorUiModule,
        ConfiguratorPagesModule,
        StoreModule.forFeature(MOSQUITOSCREEN_FEATURE_KEY, mosquitoScreenReducer, {
            initialState: mosquitoScreenInitialState,
        }),
        EffectsModule.forFeature([MosquitoScreenEffects]),
        MosquitoModule
    ],
    declarations: [
        TypesComponent,
        DimensionsComponent,
        VariantsComponent,
        ColorsComponent,
        MontagesComponent,
        TypesListPageComponent,
        ColorsListPageComponent,
        MontagesListPageComponent,
        NetTypesComponent,
    ],
    exports: [
        TypesComponent,
        DimensionsComponent,
        VariantsComponent,
        ColorsComponent,
        MontagesComponent,
        TypesListPageComponent,
        ColorsListPageComponent,
        MontagesListPageComponent,
        NetTypesComponent,
    ],
    entryComponents: [
        TypesComponent,
        DimensionsComponent,
        VariantsComponent,
        ColorsComponent,
        MontagesComponent,
        TypesListPageComponent,
        ColorsListPageComponent,
        MontagesListPageComponent,
        NetTypesComponent,
    ],
    providers: [MosquitoScreenFacade],
})
export class ConfiguratorMosquitoScreenModule {}
export { ColorsListPageComponent };
