import {Injectable, Inject} from '@angular/core';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { Common } from '../Common';
import { WindowActiveConfiguration } from '../configurations/WindowActiveConfiguration';

@Injectable()
export class SealColorsDefaultsService {


    constructor(@Inject(APP_CONFIG) private config: AppConfigFactory)
    {}

    getDefaultColor(colors = [], conf: WindowActiveConfiguration, user, defaultConf?, colorsAll?) {
        let matchingColors = [];

        // Dopasowanie do systemu i koloru konstrukcji
        if (Common.isObject(conf.System)
            && Common.isDefined(conf.System.id)
            && Common.isObject(conf.Colors.sash)
            && Common.isObject(conf.Colors.sash.inner)
            && Common.isObject(conf.Colors.sash.outer)
            && Common.isObject(conf.Colors.sash.core)
            && (Common.isDefined(conf.Colors.sash.inner.id)
                || Common.isDefined(conf.Colors.sash.outer.id)
                || Common.isDefined(conf.Colors.sash.core.id)
            )
        ) {
            matchingColors = (colors || []).filter((el) => {
                if (Common.isArray(el.mapping)) {
                    for (let i = 0; i < el.mapping.length; i++) {
                        if (el.mapping[i].system === conf.System.id
                            && ((
                                    Common.isDefined(conf.Colors.sash.inner.id)
                                    && Number(el.mapping[i].color) === Number(conf.Colors.sash.inner.id)
                                )
                                || (
                                    Common.isDefined(conf.Colors.sash.outer.id)
                                    && Number(el.mapping[i].color) === Number(conf.Colors.sash.outer.id)
                                )
                                || (
                                    Common.isUndefined(conf.Colors.sash.inner.id)
                                    && Common.isUndefined(conf.Colors.sash.outer.id)
                                    && Common.isDefined(conf.Colors.sash.core?.id)
                                    && Number(el.mapping[i].color) === Number(conf.Colors.sash.core.id)
                                )
                            )
                        ) {
                            return true;
                        }
                    }
                }
                return false;
            });
        }

        if(this.config().IccConfig.Configurators.sealColorDependOnConstructionColor && colorsAll) {
            const defaultSealColorForConstructionColor = this.getDefaultColorDependOnConstruction(colorsAll, conf, colors);
            if(defaultSealColorForConstructionColor) {
                return defaultSealColorForConstructionColor;
            }
        }

        // Wartość domyślna zależna od ustawień rynku
        if (matchingColors.length === 0) {
            const defaultSealColor = Number(user.marketDefaults.defaultSealColor) || 0;

            matchingColors = colors.filter(el => Number(el.id) === defaultSealColor);
        }

        if (defaultConf
            && Common.isDefined(defaultConf.SealColor)
            && Common.isDefined(defaultConf.SealColor.id)
            && !defaultConf.SealColor.isDefault
        ) {
            return defaultConf.SealColor;
        } else {
            return matchingColors[0] || colors[0];
        }
    }

    getDefaultColorDependOnConstruction(colorsAll, conf, sealColors) {
        const sashOuterColor = conf.Colors.sash.outer;
        const outerColor = sashOuterColor && colorsAll.find(c => Number(c.id) === Number(sashOuterColor?.id));

        if(outerColor && outerColor.defaultSealColorId && sealColors.some(c => Number(c.id) === outerColor.defaultSealColorId)) {
            return sealColors.find(c => Number(c.id) === outerColor.defaultSealColorId);
        }
        return {};
    }
}
