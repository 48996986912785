export default function Routes(
    $routeProvider,
    $locationProvider,
    IccConfig,
    EnvConfig,
    isPhonegap,
    isElectron
) {
    'ngInject';
    /*
                loggedIn: true, // default: true
                loggedOutB2C: true, // default: true
                loggedOutB2B: false, // default: false
                online: true, // default: true
                offline: true, // default: true
                licenseUnaccepted: false, // default: false
                dataUnsynced: false, // default: false
                inactiveOffer: true, // default: true
                inNative: true, //default: true
                outNative: true, //default: true
                admin: false, //default: false,
                offerModuleDenied: false, //default:false
                hasLoginInShop: true, //default: true
        */
    $routeProvider
        .when('/admin', { redirectTo: '/admin' })
        .when('/app/demo/:id?', {
            templateUrl: '/application/offer/offer_view.project.html',
            availableWhen: {
                loggedOutB2B: true,
                offline: false,
                licenseUnaccepted: true,
                dataUnsynced: true,
                dataStale: true,
                inNative: false,
            },
        })
        .when('/app/accessory', {
            templateUrl: '/application/configurator/steps/accessories/accessory.html',
            availableWhen: {
                inactiveOffer: false,
            },
        })
        .when('/app/awning', {
            templateUrl: '/application/configurator/steps/awning/awning.html',
            availableWhen: {
                inactiveOffer: false,
            },
        })
        .when('/app/complementary_goods', {
            templateUrl:
                '/application/configurator/steps/complementary_goods/complementary_goods.html',
            availableWhen: {
                inactiveOffer: false,
            },
        })
        .when('/app/door', {
            templateUrl: '/application/configurator/steps/door/door.html',
            availableWhen: {
                inactiveOffer: false,
            },
        })
        .when('/app/garage_door', {
            templateUrl: '/application/configurator/steps/garage/garage.html',
            availableWhen: {
                inactiveOffer: false,
            },
        })
        .when('/app/folding_door', {
            templateUrl: '/application/configurator/steps/hs/hs.html',
            availableWhen: {
                inactiveOffer: false,
            },
        })
        .when('/app/sliding_door', {
            templateUrl: '/application/configurator/steps/hs/hs.html',
            availableWhen: {
                inactiveOffer: false,
            },
        })
        .when('/app/hs', {
            templateUrl: '/application/configurator/steps/hs/hs.html',
            availableWhen: {
                inactiveOffer: false,
            },
        })
        .when('/app/mosquito', {
            templateUrl: '/application/configurator/steps/mosquito/mosquito.html',
            availableWhen: {
                inactiveOffer: false,
            },
        })
        .when('/app/coupled_window', {
            templateUrl: '/application/configurator/steps/coupled_window/coupled_window.html',
            availableWhen: {
                inactiveOffer: false,
            },
        })
        .when('/app/new_offer', {
            templateUrl: '/application/new_offer/new_offer.project.html',
            availableWhen: {},
        })
        .when('/app/clients', {
            templateUrl: '/application/new_offer/new_offer.project.html',
            availableWhen: {},
        })
        .when('/app/offers/:dealer?', {
            templateUrl: '/application/offer/offer_list.html',
            availableWhen: {},
        })
        .when('/app/shop_orders/:dealer?', {
            templateUrl: '/application/offer/shop_orders.html',
            availableWhen: {},
        })
        .when('/app/shop_offers/:dealer?', {
            templateUrl: '/application/offer/shop_orders.html',
            availableWhen: {},
        })
        .when('/app/offers_view/:id?', {
            templateUrl: '/application/offer/offer_view.project.html',
            availableWhen: {},
        })
        .when('/app/client_offers_view/client/:id?', {
            templateUrl: '/application/offer/offer_view.project.html',
            availableWhen: {
                loggedOutB2B: true,
                loggedIn: false,
            },
        })
        .when('/app/client_offers_view/dealer/:id?', {
            templateUrl: '/application/offer/offer_view.project.html',
            availableWhen: {
                loggedOutB2B: false
            },
        })
        .when('/app/orders/:id?', {
            templateUrl: '/application/offer/orders.html',
            availableWhen: {
                loggedOutB2C: false,
            },
        })
        .when('/app/roller_shutter', {
            templateUrl: '/application/configurator/steps/roller_shutter/roller_shutter.html',
            availableWhen: {
                inactiveOffer: false,
            },
        })
        .when('/app/start', {
            templateUrl: '/application/start/start.project.html',
            availableWhen: {
                loggedOutB2C: false,
                offerModuleDenied: true,
            },
        })
        .when('/app/window', {
            templateUrl: '/application/configurator/steps/window/window.html',
            availableWhen: {
                inactiveOffer: false
            },
        })
        .when('/app/winter_garden', {
            templateUrl: '/application/configurator/steps/winter_garden/winter_garden.html',
            availableWhen: {
                inactiveOffer: false,
            },
        })
        .when('/app/new_configurator/:configurator', {
            templateUrl: '/application/new-configurator/new-configurator.html',
            availableWhen: {
                inactiveOffer: false,
            },
        })
        .when('/app/complaints/:id?/:id2?', {
            templateUrl: '/application/complaint/complaints_list.project.html',
            availableWhen: {
                loggedOutB2C: false,
                offline: false,
                dataUnsynced: true,
                dataStale: true,
                offerModuleDenied: true,
            },
        })
        .when('/app/complaint/:id?/:id2?', {
            templateUrl: '/application/complaint/complaint.project.html',
            availableWhen: {
                loggedOutB2C: false,
                offline: false,
                dataUnsynced: true,
                dataStale: true,
                admin: true,
                offerModuleDenied: true,
            },
        })
        .when('/app/print_complaint/:id?/:id2?', {
            templateUrl: '/application/complaint/print_complaint.project.html',
            availableWhen: {
                loggedOutB2C: false,
                offline: false,
                dataUnsynced: true,
                dataStale: true,
                offerModuleDenied: true,
                admin: true,
            },
        })
        .when('/app/landing/:id?/:id2?', {
            templateUrl: '/application/complaint/landing.project.html',
            availableWhen: {
                loggedOutB2C: false,
                offline: false,
                dataUnsynced: true,
                dataStale: true,
                offerModuleDenied: true,
            },
        })
        .when('/app/redirect/:url?', {
            templateUrl: '/application/start/redirect.html',
            availableWhen: {
                loggedOutB2B: true,
                licenseUnaccepted: true,
                dataUnsynced: true,
                dataStale: true,
                offerModuleDenied: true,
            },
        })
        .when('/app/sync', {
            templateUrl: '/application/synchronize/synchronize.project.html',
            availableWhen: {
                offline: false,
                dataUnsynced: true,
                offerModuleDenied: true,
            },
        })
        .when('/app/login', {
            templateUrl: '/application/authorization/login.html',
            availableWhen: {
                loggedIn: false,
                loggedOutB2B: true,
                licenseUnaccepted: true,
                offline: false,
                dataUnsynced: true,
                dataStale: true,
                offerModuleDenied: true,
                hasLoginInShop: false,
            },
        })
        .when('/app/logout', {
            template: '',
            controller: 'LogoutCtrl',
            availableWhen: {
                loggedIn: true,
                loggedOutB2B: true,
                loggedOutB2C: true,
                licenseUnaccepted: true,
                offline: true,
                dataUnsynced: true,
                dataStale: true,
                offerModuleDenied: true,
                admin: true,
            },
        })
        .when('/app/license', {
            templateUrl: '/application/license/license.html',
            availableWhen: {
                loggedOutB2C: false,
                licenseUnaccepted: true,
                dataUnsynced: true,
                outNative: IccConfig.Dealer.licenseInBrowser,
                offline: false,
                dataStale: true,
                offerModuleDenied: true,
            },
        })
        .when('/app/update', {
            templateUrl: '/application/synchronize/update.html',
            availableWhen: {
                offline: false,
                dataUnsynced: true,
                dataStale: true,
                outNative: false,
                offerModuleDenied: true,
            },
        })
        .when('/app/offline', {
            templateUrl: '/application/app/offline.html',
            availableWhen: {
                loggedOutB2B: true,
                online: false,
                licenseUnaccepted: true,
                dataUnsynced: true,
                dataStale: true,
                offerModuleDenied: true,
            },
        })
        .when('/app', { redirectTo: '/' })
        .when('/:lang', {
            redirectTo: params => {
                window.location.href = '/' + params.lang;
            },
        })
        .when('/', {
            availableWhen: (() => {
                if (
                    IccConfig.Visualizer.name && !isElectron
                ) {
                    return {
                        loggedOutB2B: true,
                        licenseUnaccepted: true,
                        dataUnsynced: true,
                        dataStale: true,
                        offerModuleDenied: true,
                    };
                }
            })(),
            resolveRedirectTo: (
                UserService,
                OnlineStatusService,
                LicenseService,
                isElectron,
                isNative,
                StateService,
                SynchronizeService,
                LangService
            ) => {
                if (
                    IccConfig.Visualizer.name && !isElectron
                ) {
                    window.location.href = '/' + LangService.getLang();
                } else {
                    return OnlineStatusService.checkOnline().then(online => {
                        const synced = SynchronizeService.synced;
                        const licenseAccepted = LicenseService.accepted();
                        const loggedIn = UserService.loggedIn;
                        const user = UserService.get();
                        const b2cActive = IccConfig.Offer.B2C.active && !isElectron && (!IccConfig.Offer.B2C.activeDependsOnDomain || user.domainIsStore);
                        if (!loggedIn && !b2cActive) {
                            return online ? '/app/login' : '/app/offline';
                        } else if (!licenseAccepted && isNative) {
                            return online ? '/app/license' : '/app/offline';
                        } else if (isNative) {
                            return online ? '/app/update' : synced ? '/app/start' : '/app/offline';
                        } else if (!synced || b2cActive) {
                            return online ? '/app/sync' : '/app/offline';
                        } else {
                            return '/app/start';
                        }
                    });
                }
            },
        })
        .otherwise({
            resolveRedirectTo: (
                UserService,
                OnlineStatusService,
                LicenseService,
                isElectron,
                isNative,
                StateService,
                SynchronizeService
            ) => {
                return OnlineStatusService.checkOnline().then(online => {
                    const synced = SynchronizeService.synced;
                    const licenseAccepted = LicenseService.accepted();
                    const loggedIn = UserService.loggedIn;
                    const user = UserService.get();
                    const isAdmin = user.access === 'Super Admin' || user.access === 'Administratorzy';
                    const b2cActive = IccConfig.Offer.B2C.active && !isElectron && (!IccConfig.Offer.B2C.activeDependsOnDomain || user.domainIsStore);
                    if (!loggedIn && !b2cActive) {
                        return online ? '/app/login' : '/app/offline';
                    } else if (isAdmin) {
                        window.location.href = '/admin';
                    } else if (!licenseAccepted && isNative) {
                        return online ? '/app/license' : '/app/offline';
                    } else if (isNative) {
                        return online ? '/app/update' : synced ? '/app/start' : '/app/offline';
                    } else if (!synced || b2cActive) {
                        return online ? '/app/sync' : '/app/offline';
                    } else {
                        return '/app/start';
                    }
                });
            },
        });
    $routeProvider.eagerInstantiationEnabled(false);
    $locationProvider.hashPrefix(!isPhonegap ? '' : '!');

    $locationProvider.html5Mode({
        enabled: !isPhonegap,
        requireBase: false,
    });
}
