/**
 * Kontroler koloru markiz
 * @param {object} $scope                   Scope
 * @param {object} AwningColorsService      Fabryka kolorów do markiz
 */
export default function ColorsCtrl($scope, AwningColorsService, EventBusService) {
    'ngInject';

    var vm = this;

    vm.subStep     = 3;
    vm.colors      = [];
    vm.color       = {};
    vm.selectColor = selectColor;

    if (AwningColorsService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjujaca
     */
    function init() {
        vm.colors = AwningColorsService.colors;
        vm.color = AwningColorsService.color;
    }

    /**
     * Wybór koloru
     * @param  {Object} color Kolor
     */
    function selectColor(color) {
        AwningColorsService.selectColor(color, true, true);
    }
}
