export class GtmService {
    tags = {
        window: {
            system: '/okna-linia',
            dimensions: '/okna-rozmiar',
            sashes: '/okna-uklad',
            color: '/okna-kolor',
            glazing: '/okna-szyby',
            glazing1: '/okna-szyby',
            glazing2: '/okna-szprosy',
            fitting: '/okna-klamki',
            fitting1: '/okna-klamki',
            fitting2: '/okna-okucia',
            rollershutter: '/okna-roleta',
            accessories: '/okna-dodatki'
        },
        hs: {
            system: '/hs-linia',
            dimensions: '/hs-rozmiar',
            sashes: '/hs-uklad',
            color: '/hs-kolor',
            glazing: '/hs-szyby',
            glazing1: '/hs-szyby',
            glazing2: '/hs-szprosy',
            fitting: '/hs-klamki',
            fitting1: '/hs-klamki',
            fitting2: '/hs-okucia',
            rollershutter: '/hs-roleta',
            accessories: '/hs-dodatki'
        },
        door: {
            system: '/drzwi-linia',
            dimensions: '/drzwi-rozmiar',
            sashes: '/drzwi-uklad',
            color: '/drzwi-kolor',
            fillings: '/drzwi-wypelnienie',
            fitting: '/drzwi-klamki',
            locks: '/drzwi-zamki',
            accessories: '/drzwi-dodatki'
        },
        roller_shutter: {
            rollertype: '/roleta-rodzaj',
            rollerschema: '/roleta-schemat',
            rollerdimensions: '/roleta-rozmiar',
            rollercolor: '/roleta-kolor',
            rollerdrive: '/roleta-naped'
        },
        actions: {
            postCodes: '/kod-pocztowy',
            addOffer: '/wybor-produktu',
            savePopUp: '/dodaj-oferte',
            saveRedirect: '/przejdz-do-koszyka',
            sendInquiry: '/zapytanie-dealer',
            newPosition: '/potwierdzenie-kon-nowa-pozycje',
            similarPosition: '/potwierdzenie-kon-podob-pozycje',
            redirectToStoreCart: '/potwierdzenie-przejdz-do-koszyka',
        }
    };
    constructor(
        private EventBusService,
        private UserService,
        private IccConfig
    ) {
        'ngInject';
        const market = this.UserService.get().market;

        if (this.IccConfig.GTM.allowTags) {
            setTimeout(() => {
                if (typeof ga !== 'undefined') {
                    this.EventBusService.subscribe('changedStep', data => {
                        if (this.tags[data.activeConfiguration.type] && this.tags[data.activeConfiguration.type][data.value.nextStep.code]) {
                            ga('send', 'pageview', this.tags[data.activeConfiguration.type][data.value.nextStep.code] + '-' + market.toLowerCase());
                        }
                    });
                    this.EventBusService.subscribeWithoutConfiguration('googleTags', data => {
                        if (data.activeConfiguration && this.tags[data.activeConfiguration.type] && this.tags[data.activeConfiguration.type][data.value]) {
                            ga('send', 'pageview', this.tags[data.activeConfiguration.type][data.value] + '-' + market.toLowerCase());
                        } else if (this.tags.actions && this.tags.actions[data.value]) {
                            ga('send', 'pageview', this.tags.actions[data.value] + '-' + market.toLowerCase());
                        } else {
                            let firstSend = data.value.split('.');
                            if (this.tags[firstSend[0]] && this.tags[firstSend[0]][firstSend[1]]) {
                                ga('send', 'pageview', this.tags[firstSend[0]][firstSend[1]] + '-' + market.toLowerCase());
                            }
                        }
                    });
                }
            }, 700);
        }
    }
}



