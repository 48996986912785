/**
 * Kontroler okna modal typów skrzydeł
 * @param {object} $uibModalInstance Okno modal
 * @param {object} sashTypes      Typ skrzydła
 * @param {object} sash           Skrzydło
 */
export default function ModalSashTypeCtrl($uibModalInstance, profiles, sashTypes, sash, ConfigurationsService) {
    'ngInject';

    const availableSashTypes = ConfigurationsService.conf.Current.System.available_sash_types;

    const innerProfiles = profiles.some(el => !el.options.includes('outward_opening'));
    const outerProfiles = profiles.some(el => el.options.includes('outward_opening'));

    var vm = this;
    vm.sashTypes = sashTypes.map(e => {
        e.order = ~~e.order;
        return e;
    }).filter(e => {
        if (availableSashTypes && availableSashTypes.length) {
            return availableSashTypes.some(t => t == e.id);
        } else if (innerProfiles && outerProfiles) {
            return true;
        } else if (innerProfiles) {
            return !e.out_open;
        } else if (outerProfiles) {
            return e.out_open;
        } else {
            return false;
        }
    });
    vm.sash = sash;

    vm.selectSashType = function(sashType) {
        $uibModalInstance.close(sashType);
    };

    vm.close = function() {
        $uibModalInstance.close();
    };
}
