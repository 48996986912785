import angular from 'angular';

/**
 * Kontroler okna modal dla szprosów
 * @param {object} $rootScope            Angular root scope
 * @param {object} $scope                Angular scope
 * @param {object} $uibModalInstance        Okno modal
 * @param {object} ConfigurationsService Fabryka konfiguracji
 * @param {object} CurConfService        Bieżaca konfiguracja
 * @param {object} Core                  Core
 * @param {object} MuntinsService        Fabryka szprosow
 */
export default function ModalMuntinsManualLayoutCtrl($rootScope, $scope, $uibModalInstance, $filter, IccConfig, ConfigurationsService, // jshint ignore:line
                                      CurConfService, Core, MuntinsService, MuntinsLayoutService, DrawService, InfoService, EventBusService) {
    'ngInject';

    var vm = this;
    $rootScope.hideDraw = true;
    vm.config = ConfigurationsService.conf.Current;
    vm.num = Core.num;
    vm.conf = CurConfService.conf;

    const subscription = EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        vm.conf = CurConfService.conf;
    });

    $scope.$on('$destroy', () => subscription.unsubscribe());

    vm.openInfo    = openInfo;
    vm.drawService = DrawService;
    vm.drawOptions = null;
    vm.drawButtons = {
        fullscreen: false,
        moveShift : true,

        toggle: (type) => {
            if (type && typeof vm.drawButtons[type] !== 'undefined') {
                vm.drawButtons[type] = !vm.drawButtons[type];
            }

            vm.drawButtons.update();
        },

        update: () => {
            vm.drawOptions = angular.merge({}, DrawService.options, {
                dimensions: {
                    type: 'extended',
                },
                interface: {
                    moveShift: vm.drawButtons.moveShift,
                },

                glazing: {
                    buttons: [
                        {
                            class: 'ico-split-h',
                            title: $filter('translate')('INTERFACE|Dodaj szpros poziomy'),
                            condition: response => MuntinsLayoutService.isValidSash(vm.config, response.sash.id),
                            onClick: response => MuntinsLayoutService.insertMuntin(vm.config, response.sash.id, {v: 0, h: 1}),
                        },
                        {
                            class: 'ico-split-v',
                            title: $filter('translate')('INTERFACE|Dodaj szpros pionowy'),
                            condition: response => MuntinsLayoutService.isValidSash(vm.config, response.sash.id),
                            onClick: response => MuntinsLayoutService.insertMuntin(vm.config, response.sash.id, {v: 1, h: 0}),
                        },
                        {
                            class: 'ico-gear',
                            title: $filter('translate')('INTERFACE|Wybierz gotowy układ szprosów'),
                            condition: response => MuntinsLayoutService.isValidSash(vm.config, response.sash.id),
                            onClick: response => MuntinsLayoutService.openModalMuntinsLayout(vm.config, response.sash.id),
                        },
                    ],
                },
                muntins: {
                    buttons: [
                        {
                            condition: response => response.muntin.start.y === response.muntin.end.y,
                            positions: ['rect'],
                            title: $filter('translate')('INTERFACE|Edytuj szpros poziomy'),
                            buttons: [
                                {
                                    class: 'ico-remove',
                                    title: $filter('translate')('INTERFACE|Usuń szpros poziomy'),
                                    onClick: response => MuntinsLayoutService.removeMuntin(vm.config, response.sash.id, response.muntin.id),
                                    positions: ['top'],
                                },
                                {
                                    class: 'ico-move',
                                    title: $filter('translate')('INTERFACE|Przesuń szpros poziomy'),
                                    onMove: response => MuntinsLayoutService.moveMuntin(vm.config, response.sash.id, response.muntin.id, response.position),
                                    positions: ['top'],
                                },
                            ],
                        },
                        {
                            condition: response => response.muntin.start.x === response.muntin.end.x,
                            positions: ['rect'],
                            title: $filter('translate')('INTERFACE|Edytuj szpros pionowy'),
                            buttons: [
                                {
                                    class: 'ico-arrow-down',
                                    condition: response => response.sash.muntins.some(o => o.start.y === o.end.y && o.start.y > response.muntin.start.y && o.end.y < response.muntin.end.y),
                                    title: $filter('translate')('INTERFACE|Skróć szpros pionowy'),
                                    onClick: response => MuntinsLayoutService.updateMuntin(vm.config, response.sash.id, response.muntin.id, true, true),
                                    positions: ['top'],
                                },
                                {
                                    class: 'ico-arrow-up',
                                    condition: response => response.sash.muntins.some(o => o.start.y === o.end.y && o.start.y > response.muntin.start.y && o.end.y < response.muntin.end.y),
                                    title: $filter('translate')('INTERFACE|Skróć szpros pionowy'),
                                    onClick: response => MuntinsLayoutService.updateMuntin(vm.config, response.sash.id, response.muntin.id, false, true),
                                    positions: ['bottom'],
                                },
                                {
                                    class: 'ico-arrow-up',
                                    // condition: response => response.sash.muntins.some(o => o.start.y < response.muntin.start.y),
                                    title: $filter('translate')('INTERFACE|Wydłuż szpros pionowy'),
                                    onClick: response => MuntinsLayoutService.updateMuntin(vm.config, response.sash.id, response.muntin.id, true, false),
                                    positions: ['top'],
                                },
                                {
                                    class: 'ico-arrow-down',
                                    // condition: response => response.sash.muntins.some(o => o.end.y > response.muntin.end.y),
                                    title: $filter('translate')('INTERFACE|Wydłuż szpros pionowy'),
                                    onClick: response => MuntinsLayoutService.updateMuntin(vm.config, response.sash.id, response.muntin.id, false, false),
                                    positions: ['bottom'],
                                },
                                {
                                    class: 'ico-remove',
                                    title: $filter('translate')('INTERFACE|Usuń szpros pionowy'),
                                    onClick: response => MuntinsLayoutService.removeMuntin(vm.config, response.sash.id, response.muntin.id),
                                    positions: ['left'],
                                },
                                {
                                    class: 'ico-move',
                                    title: $filter('translate')('INTERFACE|Przesuń szpros pionowy'),
                                    onMove: response => MuntinsLayoutService.moveMuntin(vm.config, response.sash.id, response.muntin.id, response.position),
                                    positions: ['left'],
                                },
                            ],
                        },
                    ],
                },
                onInterfaceClick: () => {
                    $scope.$digest();
                }
            });
            EventBusService.post({
                key: 'icc-redraw',
                value: null
            });
        },
    };
    vm.drawButtons.update();

    vm.close = function() {
        $uibModalInstance.close();
    };

    function openInfo(youtubeAdrress) {
        youtubeAdrress += '?rel=0';
        InfoService.openInfoModal({title: $filter('translate')('INTERFACE|Pomoc - edycja szprosów'), message: `<iframe width="855" height="500" src="${youtubeAdrress}" frameborder="0" allowfullscreen></iframe>`});
    }
}
