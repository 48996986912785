import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { GARAGEDOOR_FEATURE_KEY, GarageDoorState } from './garage-door.reducer';
import { GarageDoorActiveConfiguration } from '@icc/common/configurations/GarageDoorActiveConfiguration';

// Lookup the 'GarageDoor' feature state managed by NgRx
const getGarageDoorState = createFeatureSelector<GarageDoorState>(GARAGEDOOR_FEATURE_KEY);

const getGarageDoorConfiguration = createSelector(
    getGarageDoorState,
    (state: GarageDoorState) => {
        return state.configuration;
    }
);

const getSystem = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.system
    }
);

const getDimensions = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.dimensions
    }
);

const getMechanism = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.mechanism
    }
);

const getGuidance = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.guide
    }
);

const getPanelsData = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.panelsData
    }
);

const getGlazingsGlobalImposts = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.glazingsGlobalImposts
    }
);

const getWindowsGlobalType = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.windowsGlobalType
    }
);

const getWindowsGlobalGlazing = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.windowsGlobalGlazing
    }
);

const getGlazingPanelsGlobalGlazing = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.glazingPanelsGlobalGlazing
    }
);

const getWindowFramesGlobalOuterColor = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.windowFramesGlobalOuterColor
    }
);

const getGlazingPanelsGlobalColor = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.glazingPanelsGlobalColor
    }
);

const getVentilationGratesGlobalOuterColor = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.ventilationGratesGlobalOuterColor
    }
);

const getPanel = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.panel
    }
);

const getPanelInternal = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.panelInternal
    }
);

const getPanelStructure: MemoizedSelector<object, any> = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.panelStructure
    }
);

const getPanelInternalStructure: MemoizedSelector<object, any> = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
    return configuration.panelInternalStructure
    }
);

const getPlinthPanel = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.plinthPanel
    }
);

const getPlinthPanelStructure = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.plinthPanelStructure
    }
);

const getPanelColor = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.panelColor
    }
);

const getPanelInternalColor = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.panelInternalColor
    }
);

const getDrive = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.drive
    }
);

const getDriveType = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.driveType
    }
);

const getDriveSide = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.driveSide
    }
);

const getWicketDoor = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.wicketDoor
    }
);

const getWicketDoorDirection = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.wicketDoorDirection
    }
);

const getWicketDoorHandleColor = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.wicketDoorHandleColor
    }
);

const getWicketDoorFrameProfilesColor = createSelector(
    getGarageDoorConfiguration,
    (configuration: GarageDoorActiveConfiguration) => {
        return configuration.wicketDoorFrameProfilesColor
    }
);

export const garageDoorQuery = {
    getGarageDoorConfiguration,
    getSystem,
    getDimensions,
    getMechanism,
    getGuidance,
    getPanelsData,
    getGlazingsGlobalImposts,
    getWindowsGlobalType,
    getWindowsGlobalGlazing,
    getGlazingPanelsGlobalGlazing,
    getWindowFramesGlobalOuterColor,
    getGlazingPanelsGlobalColor,
    getVentilationGratesGlobalOuterColor,
    getPanel,
    getPanelInternal,
    getPanelStructure,
    getPlinthPanel,
    getPlinthPanelStructure,
    getPanelInternalStructure,
    getPanelColor,
    getPanelInternalColor,
    getDrive,
    getDriveType,
    getDriveSide,
    getWicketDoor,
    getWicketDoorDirection,
    getWicketDoorHandleColor,
    getWicketDoorFrameProfilesColor
};
