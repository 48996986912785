import $ from 'jquery';

/**
 * Kontroler okna modal dla kolorów silikonów
 * @param {object} $scope         Angular scope
 * @param {object} $uibModalInstance Okno modal
 * @param {object} $timeout       Timeout
 * @param {object} colors         Kolory
 * @param {object} selColor       Kolor uszczelki
 */
export default function ModalSiliconeColorCtrl($scope, $uibModalInstance, $timeout, colors, // jshint ignore:line
                            selColor, ScrollbarService) {
    'ngInject';

    var vm = this;
    vm.colors = colors;
    vm.selColor = selColor;

    vm.selectColor = function(color) {
        $uibModalInstance.close(color);
    };

    vm.isSelected = function(color) {
        if (color.id == vm.selColor.id) {
            return true;
        }
        return false;
    };

    vm.close = function() {
        $uibModalInstance.close();
    };

    vm.refresh = function() {
        ScrollbarService.update();
    };
}
