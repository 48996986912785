import angular from 'angular';

/**
 * @ngdoc filter
 * @name ICC.Configurators.accessoriesByNameOrCode
 *
 * @description
 * Wyszukuje kolory zgodne z podaną nazwą lub kodem.
 */
export default function AccessoriesByNameOrCodeFilter() {
    'ngInject';

    /**
     * @func accessoriesByNameOrCodeFilter
     * @memberof accessoriesByNameOrCode
     * @param  {object[]} accessories   Akcesoria
     * @param  {string}   name          Nazwa
     * @param  {string}   code          Kod
     * @return {object[]}               Przefiltrowane akcesoria
     */
    return function(accessories, name, code) {
        var accessoriesArr = [];
        angular.forEach(accessories, function(obj) {
            accessoriesArr.push(obj);
        });
        name = name || '';
        code = code || '';
        name = name.toLowerCase();
        code = code.toLowerCase();

        return accessoriesArr.filter(function(access) {
            return angular.isDefined(access.name) && access.name !== null && access.name.toLowerCase().indexOf(name) !== -1
                || angular.isDefined(access.manufacturer_code) && access.manufacturer_code !== null && access.manufacturer_code.toLowerCase().indexOf(code) !== -1;
        });
    };
}
