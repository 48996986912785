<form [formGroup]="filtersGroup" class="mat-elevation-z3">
<div class="searchBox" *ngIf="type === 'simple'">
  <mat-form-field>
      <mat-label>
          {{ 'WINDOW|Akustyka:' | translate }}
      </mat-label>
        <mat-select formControlName="selectedRw">
            <mat-option value=''>{{'INTERFACE|Dowolna' | translate}}</mat-option>
            <mat-option value='<30'>{{ 'WINDOW|Standardowa' | translate }}</mat-option>
            <mat-option value=30>≥30 dB</mat-option>
            <mat-option value=35>≥35 dB</mat-option>
            <mat-option value=40>≥40 dB</mat-option>
            <mat-option value=45>≥45 dB</mat-option>
        </mat-select>
  </mat-form-field>
  <mat-form-field>
      <mat-label>
          {{ 'WINDOW|Termika:' | translate }}
      </mat-label>
        <mat-select formControlName="selectedUg">
            <mat-option value=''>{{'INTERFACE|Dowolna' | translate}}</mat-option>
            <mat-option value='>1.0' >{{ 'WINDOW|Standardowa' | translate }}</mat-option>
            <mat-option value=1.0>U<sub>g</sub>{{'WINDOW| ≤1.0 W/m²K, dobra' | translate}}</mat-option>
            <mat-option value=0.8>U<sub>g</sub>{{'WINDOW| ≤0.8 W/m²K, podwyższona' | translate}}</mat-option>
            <mat-option value=0.6>U<sub>g</sub>{{'WINDOW| ≤0.6 W/m²K, najlepsza' | translate}}</mat-option>
        </mat-select>
  </mat-form-field>
  <mat-form-field>
      <mat-label>
          {{ 'WINDOW|Bezpieczeństwo:' | translate }}
      </mat-label>
        <mat-select formControlName="selectedSecurity">
            <mat-option value=''>{{ 'WINDOW|Dowolne' | translate }}</mat-option>
            <mat-option value=1>{{'WINDOW|Szyba standardowa' | translate}}</mat-option>
            <mat-option value=2>{{'WINDOW|Szyba bezpieczna wewnątrz' | translate}}</mat-option>
            <mat-option value=3>{{'WINDOW|Szyba bezpieczna obustronnie' | translate}}</mat-option>
            <mat-option value=4>{{'WINDOW|Szyba antywłamaniowa na zewn. + bezpieczna wewn.' | translate}}</mat-option>
            <mat-option value=5>{{'WINDOW|Zgodne ze standardem RC2' | translate}}</mat-option>
        </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="areOrnaments">
      <mat-label>
          {{ 'WINDOW|Ornamenty:' | translate }}
      </mat-label>
        <mat-select formControlName="selectedOrnament">
            <mat-option value=''>{{'WINDOW|Dowolne' | translate}}</mat-option>
            <mat-option value=0>{{'WINDOW|Bez ornamentów' | translate}}</mat-option>
            <mat-option value=1>{{'WINDOW|Z ornamentami' | translate}}</mat-option>
        </mat-select>
  </mat-form-field>
</div>
<div class="searchBox extend" *ngIf="type === 'extend'">
      <mat-form-field>
          <mat-label>
              {{ 'WINDOW|Akustyka:' | translate }}
          </mat-label>
            <mat-select formControlName="selectedRw">
                <mat-option value=''>{{'INTERFACE|Dowolna' | translate}}</mat-option>
                <mat-option value=30>≥30 dB</mat-option>
                <mat-option value=35>≥35 dB</mat-option>
                <mat-option value=40>≥40 dB</mat-option>
                <mat-option value=45>≥45 dB</mat-option>
            </mat-select>
      </mat-form-field>
      <mat-form-field>
          <mat-label>
              {{ 'WINDOW|Termika:' | translate }}
          </mat-label>
            <mat-select formControlName="selectedUg">
                <mat-option value=''>{{'INTERFACE|Dowolna' | translate}}</mat-option>
                <mat-option value=1.0>U<sub>g</sub>{{'WINDOW| ≤1.0 W/m²K, dobra' | translate}}</mat-option>
                <mat-option value=0.8>U<sub>g</sub>{{'WINDOW| ≤0.8 W/m²K, podwyższona' | translate}}</mat-option>
                <mat-option value=0.6>U<sub>g</sub>{{'WINDOW| ≤0.6 W/m²K, najlepsza' | translate}}</mat-option>
            </mat-select>
      </mat-form-field>
      <mat-form-field>
          <mat-label>
              {{ 'WINDOW|Bezpieczeństwo:' | translate }}
          </mat-label>
            <mat-select formControlName="selectedSecurity">
                <mat-option value=''>{{'CONFIGURATOR|Dowolna' | translate}}</mat-option>
                <mat-option value=1>{{'WINDOW|Standardowa' | translate}}</mat-option>
                <mat-option value=2>{{'WINDOW|Bezpieczna' | translate}}</mat-option>
                <mat-option value=3>{{'WINDOW|Antywłamaniowa' | translate}}</mat-option>
                <mat-option value=4>{{'WINDOW|Hartowana' | translate}}</mat-option>
                <mat-option value=5>{{'WINDOW|Zgodna z RC2' | translate}}</mat-option>
            </mat-select>
      </mat-form-field>
      <mat-form-field  *ngIf="areOrnaments">
          <mat-label>
              {{ 'WINDOW|Ornamenty:' | translate }}
          </mat-label>
            <mat-select formControlName="selectedOrnament">
                <mat-option value=''>{{'WINDOW|Dowolne' | translate}}</mat-option>
                <mat-option value=0>{{'WINDOW|Bez ornamentów' | translate}}</mat-option>
                <mat-option value=1>{{'WINDOW|Z ornamentami' | translate}}</mat-option>
            </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="glassTypes.length > 0">
          <mat-label>
              {{ 'WINDOW|Szyby kolorowe:' | translate }}
          </mat-label>
            <mat-select formControlName="selectedColored">
                  <mat-option value=''>{{'WINDOW|Dowolne' | translate}}</mat-option>
                  <mat-option *ngFor="let color of glassTypes" [value]="color.id">{{ color.name }}</mat-option>
            </mat-select>
      </mat-form-field>
       <mat-slide-toggle formControlName="selectedOften">{{ 'WINDOW|Najcześciej wybierane' | translate }}</mat-slide-toggle>
</div>
<div class="searchBox" *ngIf="type === 'extend2'">
      <mat-form-field>
          <mat-label>
              {{ 'WINDOW|Akustyka:' | translate }}
          </mat-label>
            <mat-select formControlName="selectedRw">
                <mat-option value=''>{{'INTERFACE|Dowolna' | translate}}</mat-option>
                <mat-option value=30>≥30 dB</mat-option>
                <mat-option value=35>≥35 dB</mat-option>
                <mat-option value=40>≥40 dB</mat-option>
                <mat-option value=45>≥45 dB</mat-option>
            </mat-select>
      </mat-form-field>
      <mat-form-field>
          <mat-label>
              {{ 'WINDOW|Termika:' | translate }}
          </mat-label>
            <mat-select formControlName="selectedUg">
                <mat-option value=''>{{'INTERFACE|Dowolna' | translate}}</mat-option>
                <mat-option value=1.0>U<sub>g</sub>{{'WINDOW| ≤1.0 W/m²K, dobra' | translate}}</mat-option>
                <mat-option value=0.8>U<sub>g</sub>{{'WINDOW| ≤0.8 W/m²K, podwyższona' | translate}}</mat-option>
                <mat-option value=0.6>U<sub>g</sub>{{'WINDOW| ≤0.6 W/m²K, najlepsza' | translate}}</mat-option>
            </mat-select>
      </mat-form-field>
      <mat-form-field>
          <mat-label>
              {{ 'WINDOW|Bezpieczeństwo:' | translate }}
          </mat-label>
            <mat-select formControlName="selectedSecurity">
                <mat-option value=''>{{'CONFIGURATOR|Dowolne' | translate}}</mat-option>
                <mat-option value=1>{{'WINDOW|Zwykłe' | translate}}</mat-option>
                <mat-option value=4>{{'WINDOW|Hartowane' | translate}}</mat-option>
                <mat-option value=6>{{'WINDOW|Laminowane 33,1' | translate}}</mat-option>
                <mat-option value=7>{{'WINDOW|Laminowane 44,2' | translate}}</mat-option>
                <mat-option value=2>{{'WINDOW|Inne laminowane' | translate}}</mat-option>
            </mat-select>
      </mat-form-field>
      <mat-form-field  *ngIf="areOrnaments">
          <mat-label>
              {{ 'WINDOW|Ornamenty:' | translate }}
          </mat-label>
            <mat-select formControlName="selectedOrnament">
                <mat-option value=''>{{'WINDOW|Dowolne' | translate}}</mat-option>
                <mat-option value=0>{{'WINDOW|Bez ornamentów' | translate}}</mat-option>
                <mat-option value=1>{{'WINDOW|Z ornamentami' | translate}}</mat-option>
            </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="glassTypes.length > 0">
        <mat-label>
            {{ 'WINDOW|Szyby nieprzezierne:' | translate }}
        </mat-label>
          <mat-select formControlName="selectedColored">
                <mat-option value=''>{{'WINDOW|Dowolne' | translate}}</mat-option>
                <mat-option *ngFor="let color of glassTypes" [value]="color.id">{{ color.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-slide-toggle formControlName="selectedOften">{{ 'WINDOW|Najcześciej wybierane' | translate }}</mat-slide-toggle>
</div>
<div class="searchBox radios">
  <mat-radio-group formControlName="glazingCount">
      <mat-radio-button value="">{{'OFFER|Wszystkie' | translate}}</mat-radio-button>
      <mat-radio-button value="2">{{'WINDOW|Dwuszybowe' | translate}}</mat-radio-button>
      <mat-radio-button value="3">{{'WINDOW|Trzyszybowe'| translate}}</mat-radio-button>
  </mat-radio-group>
</div>
<button mat-raised-button color="primary" (click)="save()"
        >{{ 'INTERFACE|OK' | translate }}</button>
</form>

