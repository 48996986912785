import * as PouchDBCore from 'pouchdb-core';
import * as PouchDBAdapterIDB from 'pouchdb-adapter-idb';
import * as PouchDBAdapterWebSQL from 'pouchdb-adapter-websql';
import * as PouchDBMapReduce from 'pouchdb-mapreduce';
import { logger, core } from '@icc/legacy/app/helpers';
import { Common } from '@icc/common/Common';

export type PouchDBConfiguration =
    | PouchDB.Configuration.DatabaseConfiguration
    | PouchDB.AdapterWebSql.Configuration
    | PouchDB.IdbAdapter.IdbAdapterConfiguration;

declare var self: any;

window.addEventListener('error', function(event: ErrorEvent) {
    if (event && event.error && event.error.name === 'NotFoundError') {
        clearIDB(true);
    }
});

function PouchDBICC(adapter): any {
    let name, func, addToPreferredAdapters;
    const x = {
        adapter(adapterName, adapterFunction, adapterAddToPreferredAdapters) {
            name = adapterName;
            func = adapterFunction;
            addToPreferredAdapters = adapterAddToPreferredAdapters;
        },
    };
    adapter(x);
    return function(PDB) {
        func.valid = () => typeof indexedDB !== 'undefined' && typeof IDBKeyRange !== 'undefined';
        PDB.adapter(name, func, addToPreferredAdapters);
    };
}

export const PouchDB = PouchDBCore.plugin(PouchDBICC(PouchDBAdapterIDB))
    .plugin(PouchDBAdapterWebSQL)
    .plugin(PouchDBMapReduce);

/**
 * Przeciążenie kontruktora PouchDB, zapamiętanie nazw baz
 * @param {string} name Nazwa bazy
 */
export function PouchDBNew<T>(name: string, user: any, config?: PouchDBConfiguration, localDbVersion?: number) {
    if (!user) {
        throw new Error('Brak użytkownika! ' + name);
    }

    if (!core.isWorker() && !localDbVersion) {
        localDbVersion = Number(localStorage.getItem(`localDbVersion`) || '0');
        localStorage.setItem(`localDbVersion`, String(localDbVersion));
    }

    const dbName = `${user.marketId}_${user.id || ''}_${name}_${user.user_language}_${localDbVersion}`;
    if (core.isWorker()) {
        self.postMessage({ subject: 'iDB', name: dbName });
    } else {
        const iDBs = JSON.parse(window.localStorage.getItem('iDBs') || '[]');
        if (iDBs.indexOf(dbName) < 0) {
            iDBs.push(dbName);
            window.localStorage.setItem('iDBs', JSON.stringify(iDBs));
        }
    }
    config = Common.extend({}, config, {
        adapter: 'idb',
    });
    logger.debug('Tworzenie bazy danych', dbName, config);
    return new PouchDB<T>(dbName, config);
}

/**
 * Czyszczenie wszystkich baz
 */
export async function clearIDB(redirect = false) {
    const dbVersion = localStorage.getItem(`localDbVersion`) || '0';
    localStorage.setItem(`localDbVersion`, String(Number(dbVersion) + 1));
    const iDBs = JSON.parse(window.localStorage.getItem('iDBs') || '[]');
    for (let i = 0; i < iDBs.length; i++) {
        window.indexedDB.deleteDatabase('_pouch_' + iDBs[i]);
    }
    window.localStorage.removeItem(iDBs);

    for (const k in window.localStorage) {
        if (k.substring(0, 7) === '_pouch_' || k.substring(0, 6) === 'state_') {
            window.indexedDB.deleteDatabase(k);
            window.localStorage.removeItem(k);
        }
    }
    if (redirect) {
        logger.error(new Error('Przekierowanie na app po naprawie bazy.'));
        if (window.cordova) {
            window.location.hash = '#!/app/sync';
        } else {
            window.location.pathname = '/app/sync';
        }
    }
}
