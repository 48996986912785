import * as angular from 'angular';
import {downgradeInjectable} from '@angular/upgrade/static';

import MuntinsCtrl from './MuntinsCtrl';
import ModalMuntinsLayoutCtrl from './ModalMuntinsLayoutCtrl';
import ModalMuntinsManualLayoutCtrl from './ModalMuntinsManualLayoutCtrl';
import ModalMuntinsColorCtrl from './ModalMuntinsColorCtrl';

import { MuntinsService } from './muntins.service';
import {MuntinsLayoutService} from './muntins-layout.service';

export default angular.module('icc.configurator.steps.window.muntins', [])
    .controller('MuntinsCtrl', MuntinsCtrl)
    .controller('ModalMuntinsLayoutCtrl', ModalMuntinsLayoutCtrl)
    .controller('ModalMuntinsManualLayoutCtrl', ModalMuntinsManualLayoutCtrl)
    .controller('ModalMuntinsColorCtrl', ModalMuntinsColorCtrl)
    .factory('MuntinsService', downgradeInjectable(MuntinsService))
    .factory('MuntinsLayoutService', downgradeInjectable(MuntinsLayoutService))
    .name;
