import { Injectable } from '@angular/core';
import { TinyColor } from '@ctrl/tinycolor';
import { ColorPalette, Theme } from '@icc/config';
import { isArray } from './helpers';

interface ThemeColor {
    name: string;
    hex: string;
    rgb: string;
    darkContrast: boolean;
    contrast: string;
}

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private primaryPalette: ThemeColor[] = [];
    private accentPalette: ThemeColor[] = [];
    private warnPalette: ThemeColor[] = [];
    private backgroundColor = '';
    private surfaceColor = '';
    private textColor = '';
    private textOnSurfaceColor = '';

    setTheme(theme: Theme) {
        if (theme.primaryPalette) {
            this.primaryPalette = this.convertPalette(theme.primaryPalette);
        } else if (theme.primaryColor) {
            this.primaryPalette = this.computeColors(theme.primaryColor);
        }
        if (theme.accentPalette) {
            this.accentPalette = this.convertPalette(theme.accentPalette);
        } else if (theme.accentColor) {
            this.accentPalette = this.computeColors(theme.accentColor);
        }
        if (theme.warnPalette) {
            this.warnPalette = this.convertPalette(theme.warnPalette);
        } else if (theme.accentColor) {
            this.warnPalette = this.computeColors(theme.warnColor);
        }
        this.backgroundColor = theme.backgroundColor;
        this.surfaceColor = theme.surfaceColor;
        this.textColor = theme.textColor;
        this.textOnSurfaceColor = theme.textOnSurfaceColor;
        this.setCSSVariables();
        this.setFavicon(theme.favicon);
    }

    private setCSSVariables() {
        for (const color of this.primaryPalette) {
            const key1 = `--icc-theme-primary-${color.name}`;
            const value1 = color.hex;
            const key2 = `--icc-theme-primary-contrast-${color.name}`;
            const value2 = color.contrast || (color.darkContrast ? 'rgba(black, 0.87)' : 'white');
            const key3 = `--icc-theme-primary-${color.name}-values`;
            const value3 = color.rgb;
            document.documentElement.style.setProperty(key1, value1);
            document.documentElement.style.setProperty(key2, value2);
            document.documentElement.style.setProperty(key3, value3);
            if (color.name === '500') {
                document.documentElement.style.setProperty('--mdc-theme-primary', value1);
                document.documentElement.style.setProperty('--mdc-theme-on-primary', value2);
            }
        }

        for (const color of this.accentPalette) {
            const key1 = `--icc-theme-accent-${color.name}`;
            const value1 = color.hex;
            const key2 = `--icc-theme-accent-contrast-${color.name}`;
            const value2 = color.contrast || (color.darkContrast ? 'rgba(black, 0.87)' : 'white');
            const key3 = `--icc-theme-accent-${color.name}-values`;
            const value3 = color.rgb;
            document.documentElement.style.setProperty(key1, value1);
            document.documentElement.style.setProperty(key2, value2);
            document.documentElement.style.setProperty(key3, value3);
            if (color.name === '500') {
                document.documentElement.style.setProperty('--mdc-theme-secondary', value1);
                document.documentElement.style.setProperty('--mdc-theme-on-secondary', value2);
                const themeColorMeta = document.getElementById('addressBarColor');
                if (themeColorMeta) {
                    themeColorMeta.setAttribute('content', value1);
                }
            }
        }

        for (const color of this.warnPalette) {
            const key1 = `--icc-theme-warn-${color.name}`;
            const value1 = color.hex;
            const key2 = `--icc-theme-warn-contrast-${color.name}`;
            const value2 = color.contrast || (color.darkContrast ? 'rgba(black, 0.87)' : 'white');
            const key3 = `--icc-theme-warn-${color.name}-values`;
            const value3 = color.rgb;
            document.documentElement.style.setProperty(key1, value1);
            document.documentElement.style.setProperty(key2, value2);
            document.documentElement.style.setProperty(key3, value3);
        }
        if (this.backgroundColor) {
            document.documentElement.style.setProperty('--icc-theme-background', this.backgroundColor);
            document.documentElement.style.setProperty('--mdc-theme-background', this.backgroundColor);
        }
        if (this.surfaceColor) {
            document.documentElement.style.setProperty('--icc-theme-surface', this.surfaceColor);
            document.documentElement.style.setProperty('--mdc-theme-surface', this.surfaceColor);
        }
        if (this.textColor) {
            document.documentElement.style.setProperty('--icc-theme-text', this.textColor);
        }
        if (this.textOnSurfaceColor) {
            document.documentElement.style.setProperty('--icc-theme-text-on-surface', this.textOnSurfaceColor);
            document.documentElement.style.setProperty('--mdc-theme-on-surface', this.textOnSurfaceColor);
        }
    }

    private setFavicon(favicon: string) {
        const faviconElement = document.getElementById('appFavicon');
        if (faviconElement) {
            faviconElement.setAttribute('href', favicon);
        }
    }

    private computeColors(hex: string) {
        const baseLight = new TinyColor('#ffffff');
        const baseDark = this.multiply(new TinyColor(hex).toRgb(), new TinyColor(hex).toRgb());
        const baseTriad = new TinyColor(hex).tetrad();
        return [
            this.getColorObject(baseLight.mix(hex, 12), '50'),
            this.getColorObject(baseLight.mix(hex, 30), '100'),
            this.getColorObject(baseLight.mix(hex, 50), '200'),
            this.getColorObject(baseLight.mix(hex, 70), '300'),
            this.getColorObject(baseLight.mix(hex, 85), '400'),
            this.getColorObject(baseLight.mix(hex, 100), '500'),
            this.getColorObject(baseDark.mix(hex, 87), '600'),
            this.getColorObject(baseDark.mix(hex, 70), '700'),
            this.getColorObject(baseDark.mix(hex, 54), '800'),
            this.getColorObject(baseDark.mix(hex, 25), '900'),
            this.getColorObject(
                baseDark
                    .mix(baseTriad[4], 15)
                    .saturate(80)
                    .lighten(65),
                'A100'
            ),
            this.getColorObject(
                baseDark
                    .mix(baseTriad[4], 15)
                    .saturate(80)
                    .lighten(55),
                'A200'
            ),
            this.getColorObject(
                baseDark
                    .mix(baseTriad[4], 15)
                    .saturate(100)
                    .lighten(45),
                'A400'
            ),
            this.getColorObject(
                baseDark
                    .mix(baseTriad[4], 15)
                    .saturate(100)
                    .lighten(40),
                'A700'
            ),
        ];
    }

    private multiply(
        rgb1: { r: number; g: number; b: number },
        rgb2: { r: number; g: number; b: number }
    ) {
        rgb1.b = Math.floor((rgb1.b * rgb2.b) / 255);
        rgb1.g = Math.floor((rgb1.g * rgb2.g) / 255);
        rgb1.r = Math.floor((rgb1.r * rgb2.r) / 255);
        return new TinyColor('rgb ' + rgb1.r + ' ' + rgb1.g + ' ' + rgb1.b);
    }

    private getColorObject(value: TinyColor, name: string) {
        const c = new TinyColor(value);
        return {
            name: name,
            hex: c.toHexString(),
            darkContrast: c.isLight(),
            contrast: c.isLight() ? 'rgba(black, 0.87)' : 'white',
            rgb: `${c.r},${c.g},${c.b}`
        };
    }

    private convertPalette(palette: ColorPalette | ThemeColor[]): ThemeColor[] {
        if (isArray(palette)) {
            return palette;
        }
        const theme: ThemeColor[] = [];
        Object.keys(palette).forEach((name) => {
            const key = name as keyof ColorPalette;
            if (key !== 'contrast') {
                const c = new TinyColor(palette[key]);
                theme.push({
                    name,
                    hex: palette[key],
                    rgb: `${c.r},${c.g},${c.b}`,
                    darkContrast: new TinyColor(palette.contrast[key]).isDark(),
                    contrast: palette.contrast[key]
                })
            }
        });
        return theme;
    }
}
