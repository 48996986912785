
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA, ModalService } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { IccAccessoryAccessory, IccAccessoryColor } from '@icc/common/data-types';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService, ConfigurationsService, APP_CONFIG, AppConfigFactory } from '@icc/common';
import { PriceAccessoryService } from 'libs/price/src/lib/price-accessory.service';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { WindowSillsService } from '@icc/legacy/configurator/steps/complementary_goods/window-sills/window-sills.service';
import { UnitConverterService } from '@icc/configurator/ui';

@Component({
    selector: 'icc-sill-options-page',
    templateUrl: './sill-options-page.component.html',
    styleUrls: ['./sill-options-page.component.scss'],
})
export class SillOptionsPageComponent extends PageComponent implements OnInit, OnDestroy {
    public title: string = _('ACCESSORY|Opcje dodatku');

    accessory: any;
    form: FormGroup;
    focused = false;
    showCoreColorInAdditionalProfilesAndAccessories = this.config().IccConfig.Configurators
        .showCoreColorInAdditionalProfilesAndAccessories;
    currency: any = null;
    currentPrice$ = new BehaviorSubject<string | number>('');
    private subscriptions: Subscription[] = [];

    dimensionUnit = this.unitConverterService.getUnit();

    constructor(
        private translateService: TranslateService,
        private priceAccessoryService: PriceAccessoryService,
        private configurationsService: ConfigurationsService<'window'>,
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        private windowSillsService: WindowSillsService,
        private fb: FormBuilder,
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            accessory: IccAccessoryAccessory;
            forIt: 'configuration' | 'sash' | 'side';
            sash?: ActiveSash;
            colors: IccAccessoryColor[];
        },
        private unitConverterService: UnitConverterService
    ) {
        super();
    }

    ngOnInit() {
        this.accessory = this.pageData.accessory;
        this.form = this.fb.group({
            count: [this.accessory.count || 1, Validators.min(1)],
            selectedColor: [this.accessory.selectedColor || '0', Validators.required],
            plugs: 0,
            currentWidth: [
                0,
                [Validators.min(this.accessory.min_width),
                Validators.max(this.accessory.max_width),
                requiredWhenValidator(this.accessory.min_width, this.accessory.max_width)],
            ],
            currentLength: [
                0,
                [Validators.min(this.accessory.min_length),
                Validators.max(this.accessory.max_length),
                requiredWhenValidator(this.accessory.min_length, this.accessory.max_length)],
            ],
            in_range: false,
            comment: '',
        });

        if (this.accessory.blockCountChange) {
            this.form.get('count').disable();
        }

        this.subscriptions.push(
            this.sharedFacade.currency$.subscribe(currency => {
                this.currency = currency;
            })
        );
        this.subscriptions.push(
            this.form.valueChanges.subscribe(value => {
                this.currentPrice$.next(this.getPrice());
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    add() {
        if (this.form.valid) {
            Object.assign(this.accessory, this.form.value);
            if (!this.accessory.no_price) {
                this.windowSillsService.calcSill(this.accessory);
            }
            this.sharedFacade.closePage({
                accessory: this.accessory,
            });
        }
    }

    canAdd() {
        return true;
    }

    getPrice() {
        if (!this.accessory.no_price) {
            return this.windowSillsService.calcSill({ ...this.accessory, ...this.form.value });
        }
        return this.translateService.instant('CONFIGURATOR|Do wyceny');
    }

    getAccessoryColors(accessory: IccAccessoryAccessory) {
        return (this.pageData.colors || []).filter(
            c => accessory.colors_ids && accessory.colors_ids.map(Number).includes(Number(c.id))
        );
    }

    getSillWidths(sill) {
        return this.windowSillsService.getSillWidths(sill);
    }

    getSillLengths(sill) {
        return this.windowSillsService.getSillLengths(sill);
    }

    setInRange(sill) {
        this.windowSillsService.setInRange(sill);
    }
}

function requiredWhenValidator(minValue: string | number, maxValue: string | number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        const requiredWidth = ((minValue > 0) || (maxValue > 0)) ? true : false
        return !(control.value) && requiredWidth ? {'required': true} : null
    };
}
