import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _ } from '@icc/configurator/shared';
import {
    APP_CONFIG,
    AppConfigFactory,
    DrawService,
    ConfigurationsService,
    TranslateService,
    EventBusService,
} from '@icc/common';
import { ShutterFacade } from '../+state/shutter.facade';
import { RollerDimensionsService } from '@icc/legacy/configurator/steps/roller_shutter/dimensions.service';

@Component({
    selector: 'icc-drive-side-page',
    templateUrl: './drive-side-page.component.html',
    styleUrls: ['./drive-side-page.component.scss'],
})
export class DriveSidePageComponent extends PageComponent implements OnInit {
    public title: string = _('ROLLERSHUTTER|Strona sterowania');
    fullscreen = true;

    drawOptions: any = null;
    drawButtons = {
        alignments: true,
        dimensions: false,
        extensions: true,
        fullscreen: false,
        mullions: true,
        profiles: false,
        sashes: true,

        toggle: (
            type:
                | 'alignments'
                | 'dimensions'
                | 'extensions'
                | 'fullscreen'
                | 'mullions'
                | 'profiles'
                | 'sashes'
        ) => {
            if (type && typeof this.drawButtons[type] !== 'undefined') {
                this.drawButtons[type] = !this.drawButtons[type];
            }

            this.drawButtons.update();
        },

        update: () => {
            const conf = this.configurationsService.conf.Current;
            const shutters = this.configurationsService.conf.Current.RollerShutter.shutters;
            if (conf) {
                this.drawOptions = Object.assign({}, this.drawService.options, {
                    shutter: {
                        buttons: [
                            {
                                class: 'ico-move-vertical',
                                condition: response => !response.shutter.commonRail,
                                onClick: response => this.toggleControlSide(response.shutter.id),
                                positions: ['top'],
                                title: this.translateService.instant(
                                    'INTERFACE|Zmień stronę sterowania'
                                ),
                            },
                        ],
                    },
                    indexes: {
                        multiplier: 3,
                    },
                    extendedRollerInWindow: this.config().IccConfig.Configurators
                        .extendedRollerInWindow,
                    onInterfaceClick: () => {},
                });
                this.drawOptions.base.side = 'inner';
            }

            this.eventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
        },
    };

    width$ = this.shutterFacade.width$;
    height$ = this.shutterFacade.fullHeight$;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private shutterFacade: ShutterFacade,
        public drawService: DrawService,
        private rollerDimensionsService: RollerDimensionsService,
        private configurationsService: ConfigurationsService<'roller_shutter' | 'external_blind'>,
        private translateService: TranslateService,
        private eventBusService: EventBusService
    ) {
        super();
    }

    ngOnInit() {
      this.drawButtons.update();
    }

    toggleControlSide(shutterId) {
        const shutter = this.configurationsService.conf.Current.RollerShutter.shutters.find(
            o => o.id === shutterId
        );

        shutter.handling = shutter.handling === 'l' ? 'r' : 'l';

        this.rollerDimensionsService.updateDimensions();
    }
}
