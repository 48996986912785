import { Pipe, PipeTransform, Inject } from '@angular/core';
import { unitM2Formatter } from '@icc/helpers';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { UserService } from '@icc/common/user.service';

@Pipe({
  name: 'unitm2'
})
export class Unitm2Pipe implements PipeTransform {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfigFactory,
    private userService: UserService
  ) {}

  transform(value: any, hasunit = 0, inmm = 0, mb = 0, dimension_unit = '', unitPrefix:string = ''): any {
    return unitM2Formatter(
      value,
      this.config().IccConfig,
      hasunit,
      inmm,
      mb,
      dimension_unit || this.userService.get().dimension_unit || this.config().dimensionUnit,
      unitPrefix
    );
  }

}
