import format from 'date-fns/format';
import { parseDate } from '@icc/helpers';

export default function DateFormatFilter() {
    'ngInject';

    return function(dateTime) {
        const result = dateTime ? format(parseDate(dateTime), 'yyyy.MM.dd   HH:mm') : '';
        return result;
    };
}
