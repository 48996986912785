/**
 * Kontroler do szprosów
 * @param {object} $scope                Angular scope
 * @param {object} $rootScope            Angular Root Scope
 * @param {object} MuntinsService        Fabryka szprosów
 * @param {object} ConfigurationsService Fabryka konfiguracji
 */
export default function MuntinsCtrl($scope, $rootScope, MuntinsService, MuntinsLayoutService, ConfigurationsService, PriceService, EventBusService) {
    'ngInject';

    var vm = this;

    vm.types = MuntinsService.muntinsTypes;
    vm.changeType                   = changeType;
    vm.changeWidth                  = changeWidth;
    vm.openModalColor               = openModalColor;
    vm.openModalMuntinsLayout       = openModalMuntinsLayout;
    vm.openModalMuntinsManualLayout = openModalMuntinsManualLayout;
    vm.isDuplexAvailable            = isDuplexAvailable;
    vm.changeDuplex                 = changeDuplex;
    vm.hasPVCPanelsOnAllSashes      = hasPVCPanelsOnAllSashes;

    if (MuntinsService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    function init() {
        vm.types = MuntinsService.muntinsTypes;
    }

    function changeType() {
        MuntinsService.changeType(ConfigurationsService.conf.Current);
    }

    function openModalColor(side) {
    }

    function changeWidth(size) {
        MuntinsService.changeWidth(ConfigurationsService.conf.Current, size);
    }

    function openModalMuntinsLayout() {
        MuntinsLayoutService.openModalMuntinsLayout(ConfigurationsService.conf.Current);
    }

    function openModalMuntinsManualLayout() {
        MuntinsService.openModalMuntinsManualLayout(ConfigurationsService.conf.Current);
    }

    function changeDuplex() {
        PriceService.count();
    }

    /**
     * Sprawdza, czy dla wybranego typu szprosu dostępny jest duplex
     * @returns {boolean}
     */
    function isDuplexAvailable() {
        return MuntinsService.isDuplexAvailable(
            ConfigurationsService.conf.Current.Sashes,
            ConfigurationsService.conf.Current.MuntinsData.type.duplex_availability
        );
    }

    /**
     * Sprawdza czy panele są ustawione we wszystkich skrzydłach.
     * @return {Boolean} Czy panele wypełnieniowe są ustawione na wszystkich skrzydłach?
     */
    function hasPVCPanelsOnAllSashes() {
        return MuntinsService.hasPVCPanelsOnAllSashes(
            ConfigurationsService.conf.Current
        );
    }
}
