const MountingMethodModalTemplate = require('ngtemplate-loader!./mounting-method-modal.component.html');
import { GlazingUnitModalController } from './mounting-method-modal.controller';

export const MountingMethodModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: MountingMethodModalTemplate,
  controller: GlazingUnitModalController
};
