import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { PageComponent, ModalService, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService, ConfigurationsService, APP_CONFIG, AppConfigFactory } from '@icc/common';
import { PriceAccessoryService } from 'libs/price/src/lib/price-accessory.service';
import { WindowSillsService } from '@icc/legacy/configurator/steps/complementary_goods/window-sills/window-sills.service';
import { IccAccessoryAccessory, IccAccessoryColor } from '@icc/common/data-types';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { PriceCassonettoService } from 'libs/price/src/lib/price-cassonetto.service';
import { UnitConverterService } from '@icc/configurator/ui';

@Component({
    selector: 'icc-cassonetto-options-page',
    templateUrl: './cassonetto-options-page.component.html',
    styleUrls: ['./cassonetto-options-page.component.scss'],
})
export class CassonettoOptionsPageComponent extends PageComponent implements OnInit, OnDestroy {
    public title: string = _('ACCESSORY|Opcje dodatku');

    accessory: any;
    form: FormGroup;
    focused = false;
    showCoreColorInAdditionalProfilesAndAccessories = this.config().IccConfig.Configurators
        .showCoreColorInAdditionalProfilesAndAccessories;
    currency: any = null;
    currentPrice$ = new BehaviorSubject<string | number>('');
    private subscriptions: Subscription[] = [];
    dimensionUnit = this.unitConverterService.getUnit();
    
    constructor(
        private translateService: TranslateService,
        private priceCassonettoService: PriceCassonettoService,
        private configurationsService: ConfigurationsService<'window'>,
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        private windowSillsService: WindowSillsService,
        private fb: FormBuilder,
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            accessory: IccAccessoryAccessory;
            colors: IccAccessoryColor[];
            productPricelists: any;
        },
        private unitConverterService: UnitConverterService
    ) {
        super();
    }

    ngOnInit() {
        this.accessory = this.pageData.accessory;
        this.form = this.fb.group({
            count: [this.accessory.count || 1, Validators.min(1)],
            selectedColor: [this.accessory.selectedColor || '0', Validators.required],
            currentWidth: [0, [Validators.min(500), Validators.max(3000)]],
            currentHeight: [0, [Validators.min(100)]],
            currentP1: [
                0,
                [
                    Validators.min(this.accessory.min_p1_mm),
                    Validators.max(this.accessory.max_p1_mm),
                ],
            ],
            currentP2: [
                0,
                [
                    Validators.min(this.accessory.min_p2_mm),
                    Validators.max(this.accessory.max_p2_mm),
                ],
            ],
            currentL1: [
                0,
                [
                    Validators.min(this.accessory.min_l1_mm),
                    Validators.max(this.accessory.max_l2_mm),
                ],
            ],
        });
        if (this.accessory.blockCountChange) {
            this.form.get('count').disable();
        }

        this.subscriptions.push(
            this.sharedFacade.currency$.subscribe(currency => {
                this.currency = currency;
            })
        );
        this.subscriptions.push(
            this.form.valueChanges.subscribe(value => {
                this.currentPrice$.next(this.getPrice());
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    add() {
        if (this.form.valid) {
            Object.assign(this.accessory, this.form.value);
            if (!this.accessory.no_price) {
                this.calcCassonettoPrice(this.accessory);
            }
            this.sharedFacade.closePage({
                accessory: this.accessory,
            });
        }
    }

    canAdd() {
        return true;
    }

    getPrice() {
        if (!this.accessory.no_price) {
            return this.calcCassonettoPrice({ ...this.accessory, ...this.form.value });
        }
        return this.translateService.instant('CONFIGURATOR|Do wyceny');
    }

    calcCassonettoPrice(cassonetto) {
      this.priceCassonettoService.cassonettoCalculatePrice(
          { accessory: cassonetto, count: 1 },
          this.pageData.colors,
          this.pageData.productPricelists
      );

      return cassonetto.price;
    }
}
