import { Component, Inject, OnInit } from '@angular/core';
import { ConfigurationsService } from '@icc/common';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { IccAccessoryAccessory, IccSill } from '@icc/common/data-types';
import { SharedFacade, _ } from '@icc/configurator/shared';
import { ICC_PAGE_DATA, ModalService, PageComponent } from '@icc/helpers';
import { SillsListPageComponent } from 'libs/configurator/accessory/src/lib/sills-list-page/sills-list-page.component';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    selector: 'icc-sills-modal',
    templateUrl: './sills-modal.component.html',
    styleUrls: ['./sills-modal.component.scss'],
})
export class SillsModalComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Parapety');

    selectedSill = {
        outer: null,
        inner: null,
    };

    private subscriptions: Subscription[] = [];

    constructor(
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        private configuratorsDataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService<'complementary_goods'>,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            selectedOuterSill: IccSill;
            selectedInnerSill: IccSill;
            selectedSill$: BehaviorSubject<{
                outer: IccSill;
                inner: IccSill;
            }>;
        }
    ) {
        super();
    }

    ngOnInit(): void {
        this.selectedSill.outer = this.pageData.selectedOuterSill;
        this.selectedSill.inner = this.pageData.selectedInnerSill;

        this.subscriptions.push(this.pageData.selectedSill$.subscribe(selectedSill => {
            this.selectedSill = selectedSill;
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    closeModal() {
        this.sharedFacade.closePage(this.selectedSill);
    }

    openModalSills(side: 'outer' | 'inner') {
        const modalInstance = this.modalService.open({
            pageComponent: SillsListPageComponent,
            resolve: {
                sills: () => {
                    return this.configuratorsDataService.data.windowSillsGoods;
                },
                noPrice: () => {
                    return this.configurationsService.price.noPrice;
                },
            },
        });

        return modalInstance.result.then((result: { accessory: IccAccessoryAccessory }) => {
            if (result) {
                this.selectedSill[side] = result.accessory;
                this.pageData.selectedSill$.next(this.selectedSill);
            }
        });
    }
}
