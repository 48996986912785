import { isUndefined } from '@icc/helpers';

/**
 * Funkcja koloru szyb
 */
export function GlassTypeFilter() {
    return function(glass, colorId) {
        const glassArr = [];
        Object.keys(glass).forEach(key => {
            glassArr.push(glass[key]);
        });
        if (isUndefined(colorId) || colorId === null || colorId === '') {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {
                return (
                    ((!colorId || Number(colorId) === 0)
                        && (!gl.glass_types || gl.glass_types.length === 0))
                    || (colorId && gl.glass_types.some(gt => gt.glass_type_id === colorId))
                );
            });
        }
    };
}
