import * as angular from 'angular';
import {downgradeInjectable} from '@angular/upgrade/static';

import { AdditionalsFactory } from './AdditionalsFactory';
import ClientsFactory from './ClientsFactory';
import { HeaderCtrl } from './HeaderCtrl';
import ModalEditClientCtrl from './ModalEditClientCtrl';
import ModalNewClientCtrl from './ModalNewClientCtrl';
import NewOfferCtrl from './NewOfferCtrl';
import OfferAttachmentsFactory from './OfferAttachmentsFactory';
import OffersCtrl from './OffersCtrl';
import OffersFactory from './OffersFactory';
import OffersViewCtrl from './OffersViewCtrl';
import OfferViewFactory from './OfferViewFactory';
import OrdersCtrl from './OrdersCtrl';
import PositionAttachmentsFactory from './PositionAttachmentsFactory';
import PositionsFactory from './PositionsFactory';
import { BrowserUserService } from './user.service';
import OffersGroupFactory from './OffersGroupFactory';
import OffersGroupCtrl from './OffersGroupCtrl';
import DealerOnlineCtrl from './DealerOnlineCtrl';
import { NewPositionService } from './NewPositionService';
import { ModalNewPosition } from './ModalNewPositionCtrl';
import { OfferPriceService } from '@icc/common/offers/OfferPriceService';
import TaxRatesService from './TaxRatesService';
import ModalTaxRatesCtrl from './ModalTaxRatesCtrl';
import {ColorsPositionService} from './colors-position.service';
import { ManyPositionsService } from './many-positions.service';
import { OffersService } from './offers.service';
import {OfferSummaryService} from './offer-summary.service';
import { OfferGroupService } from './offer-group.service';
import { MontagesService } from '@icc/common/offers/montages.service';
import { MeasurementPricesService } from '@icc/common/offers/measurement-prices.service';
import { MontagesDataService } from './montages-data.service';

export default angular.module('icc.panel', [])
    .controller('HeaderCtrl', HeaderCtrl)
    .controller('ModalEditClientCtrl', ModalEditClientCtrl)
    .controller('ModalNewClientCtrl', ModalNewClientCtrl)
    .controller('ModalNewPositionCtrl', ModalNewPosition)
    .controller('ModalTaxRatesCtrl', ModalTaxRatesCtrl)
    .controller('NewOfferCtrl', NewOfferCtrl)
    .controller('OffersCtrl', OffersCtrl)
    .controller('OffersViewCtrl', OffersViewCtrl)
    .controller('OrdersCtrl', OrdersCtrl)
    .controller('OffersGroupCtrl', OffersGroupCtrl)
    .controller('DealerOnlineCtrl', DealerOnlineCtrl)
    .service('AdditionalsFactory', AdditionalsFactory)
    .factory('ClientsFactory', ClientsFactory)
    .factory('OfferAttachmentsFactory', OfferAttachmentsFactory)
    .factory('OffersFactory', OffersFactory)
    .factory('OfferViewFactory', OfferViewFactory)
    .factory('PositionAttachmentsFactory', PositionAttachmentsFactory)
    .factory('PositionsFactory', PositionsFactory)
    .factory('UserService', downgradeInjectable(BrowserUserService))
    .factory('OffersGroupFactory', OffersGroupFactory)
    .service('NewPositionService', NewPositionService)
    .service('TaxRatesService', TaxRatesService)
    .service('OfferPriceService', OfferPriceService)
    .service('ManyPositionsService', downgradeInjectable(ManyPositionsService))
    .factory('ColorsPositionService', downgradeInjectable(ColorsPositionService))
    .factory('OffersService', downgradeInjectable(OffersService))
    .factory('OfferSummaryService', downgradeInjectable(OfferSummaryService))
    .factory('OfferGroupService', downgradeInjectable(OfferGroupService))
    .factory('MontagesService', downgradeInjectable(MontagesService))
    .factory('MontagesDataService', downgradeInjectable(MontagesDataService))
    .factory('MeasurementPricesService', downgradeInjectable(MeasurementPricesService))
    .name;
