import angular from 'angular';

/**
 * Funkcja filtrów typów skrzydeł
 * @param  {object} $rootScope            Angular root scope
 * @param  {object} Core                  Core
 * @param  {object} ConfigurationsService Fabryka konfiguracji
 * @param  {object} CurConfService        Bieżaca konfiguracja
 */
export default function SashTypeFilter(SashesTypesService) {
    'ngInject';

    return function(sashTypes, sash) {
        var typesArr = [];
        angular.forEach(sashTypes, function(obj) {
            typesArr.push(obj);
        });


        return SashesTypesService.getMatchingSashTypes(typesArr, sash);
    };
}
