const ColorsModalTemplate = require('ngtemplate-loader!./colors-modal.component.html');
import ColorsModalController from './colors-modal.controller';

export const ColorsModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: ColorsModalTemplate,
  controller: ColorsModalController
};
