import { Component, OnInit, Inject } from '@angular/core';
import { _, PageComponent, ICC_PAGE_DATA, SharedFacade } from '@icc/configurator/shared';
import { IccSashType } from '@icc/common/data-types';
import { iccListItem } from '@icc/configurator/ui';
import { Profile } from '@icc/window';

@Component({
    selector: 'icc-sash-types-page',
    templateUrl: './sash-types-page.component.html',
    styleUrls: ['./sash-types-page.component.scss'],
})
export class SashTypesPageComponent extends PageComponent implements OnInit {
    title = _('WINDOW|Wybierz funkcję kwatery:');
    sashTypes: iccListItem[] = [];

    constructor(
        @Inject(ICC_PAGE_DATA)
        private data: {
            sashTypes: IccSashType[];
            availableSashTypes: string[];
            profiles: Profile[];
        },
        private sharedFacade: SharedFacade
    ) {
        super();
    }

    ngOnInit() {
        const innerProfiles = this.data.profiles.some(
            el => !el.options.includes('outward_opening')
        );
        const outerProfiles = this.data.profiles.some(el => el.options.includes('outward_opening'));
        this.sashTypes = this.data.sashTypes
            .filter(e => {
                let available = true;
                if (this.data.availableSashTypes && this.data.availableSashTypes.length) {
                    available = available && this.data.availableSashTypes.some(t => t == e.id);
                }

                if (innerProfiles && outerProfiles) {
                    return available;
                } else if (innerProfiles) {
                    return available && !e.out_open;
                } else if (outerProfiles) {
                    return available && e.out_open;
                } else {
                    return false;
                }
            })
            .map<iccListItem>(type => ({
                id: Number(type.id),
                title: type.name,
                imageUrl: '/files/sashtype/' + type.img,
            }));
    }

    selectSashType(item: iccListItem) {
      const sashType = this.data.sashTypes.find(s => Number(s.id) === item.id)
      this.sharedFacade.closePage(sashType)
    }
}
