import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ComponentType } from '@angular/cdk/portal';
import { PageComponent, fromSharedActions, Step, StepIcon, ConfiguratorPartialState } from '@icc/configurator/shared';
import { configuratorQuery } from '../+state/root.selectors';
import { Issue, StepsService } from '@icc/helpers';
import { core } from '@icc/common/helpers';
import { EventBusService, ConfigurationsService } from '@icc/common';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Injectable()
export class ConfiguratorStepsService extends StepsService {
    steps$ = this.store.pipe(select(configuratorQuery.getEnabledSteps));
    selectedStep$ = this.store.pipe(select(configuratorQuery.getSelectedStep));
    openedPage$ = this.store.pipe(
        select(configuratorQuery.getOpenedPage),
        distinctUntilChanged((x, y) => x === y || x != null && y != null && x.component === y.component)
    );
    openedFilterSheet$ = this.store.pipe(
        select(configuratorQuery.getOpenedFilterSheet),
        distinctUntilChanged((x, y) => x === y || x != null && y != null && x.component === y.component)
    );

    private steps: (Step & {
        stepName: string;
        stepIcon: StepIcon;
    })[] = [];
    private selectedStepId = '';

    public visitedSteps: Step[] = [];

    constructor(
        private store: Store<ConfiguratorPartialState>,
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService
    ) {
        super();
        this.steps$.subscribe(steps => this.steps = steps);
        this.selectedStep$.subscribe(step => {
            if (step) {
                this.selectedStepId = step.id
            }
        });
    }

    switchStep(stepId: string) {
        if (stepId !== this.selectedStepId) {
            this.store.dispatch(new fromSharedActions.SwitchStep({ nextStepId: stepId, currentStepId: this.selectedStepId}));
        }
    }

    goToNextStep() {
        const enabledSteps = this.steps;
        const currentIndex = enabledSteps.findIndex(step => step.id === this.selectedStepId);
        this.selectStep(enabledSteps[(currentIndex + enabledSteps.length + 1) % enabledSteps.length].id);
    }

    goToPreviousStep() {
        const enabledSteps = this.steps;
        const currentIndex = enabledSteps.findIndex(step => step.id === this.selectedStepId);
        this.selectStep(enabledSteps[(currentIndex + enabledSteps.length - 1) % enabledSteps.length].id);
    }

    openPage(page: ComponentType<PageComponent>, data: any = {}) {
        if (data) {
            Object.keys(data).forEach((key: any) => {
                if (data && data[key]) {
                    const value = data[key];
                    if (typeof value === 'function') {
                        data[key] = value();
                    } else {
                        data[key] = value;
                    }
                }
            })
        }
        this.store.dispatch(new fromSharedActions.OpenPage({
            data: data,
            id: core.generateUUID(),
            page
        }));
    }

    closePage() {
        this.store.dispatch(new fromSharedActions.ClosePage());
    }

    getStepByCode(code: string): number {
        return this.steps.findIndex(step => step.id === code);
    }
    selectStep(newStep: string | number, noEvent?: boolean, onSave?: boolean) {
        if (typeof newStep === 'number') {
            newStep = this.steps[newStep].id;
        }
        const currentIndex = this.steps.findIndex(step => step.id === this.selectedStepId);
        const currentStep = this.selectedStepId;
        const nextIndex = onSave ? 99999 : this.steps.findIndex(step => step.id === newStep);
        let cancelled = false;
        let issues: Issue[] = [];
        if (!noEvent) {
            this.eventBusService.post({
                key: 'startedChangingStep',
                value: {
                    prevStep: {
                        i: currentIndex,
                        code: currentStep,
                    },
                    nextStep: {
                        i: nextIndex,
                        code: newStep,
                    },
                    cancel: (cancelIssues: Issue[] = []) => {
                        cancelled = true;
                        issues = cancelIssues;
                    }
                },
            });
        }
        if (cancelled) {
            return issues;
        }
        if (!onSave) {
            this.switchStep(newStep)
        }
        if (!noEvent) {
            this.eventBusService.post({
                key: 'changedStep',
                value: {
                    prevStep: {
                        i: currentIndex,
                        code: currentStep,
                    },
                    nextStep: {
                        i: nextIndex,
                        code: newStep,
                    },
                },
            });
        }
    }
    enable(step: string | number) {
        if (typeof step === 'number') {
            step = this.steps[step].id;
        }
        this.store.dispatch(new fromSharedActions.EnableStep(step));
    }
    disable(step: string | number) {
        if (typeof step === 'number') {
            step = this.steps[step].id;
        }
        this.store.dispatch(new fromSharedActions.DisableStep(step));
    }
    switchEnable(step: string | number) {
        if (typeof step === 'number') {
            step = this.steps[step].id;
        }
        this.store.dispatch(new fromSharedActions.SwitchEnableStep(step));
    }
    getLastStep() {
        return {
            step: this.steps.length - 1
        }
    }

    getStep() {
        return this.getStepByCode(this.selectedStepId);
    }

    isVisited(step: string | number) {
        if (typeof step === 'number') {
            step = this.steps[step].id;
        }
        return this.steps.some(s => s.id === step && s.visited > 0);
    }

    getStepVisits(step: string | number) {
        if (typeof step === 'number') {
            step = this.steps[step].id;
        }
        const stepObject = this.steps.find(s => s.id === step);
        return stepObject ? stepObject.visited : 0;
    }
}
