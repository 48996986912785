import { Injectable } from '@angular/core';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { ActiveMullion } from '@icc/common/layout/active-mullion';
import { ActiveSash } from '@icc/common/layout/active-sash';

/**
 * Fabryka podziału szablonu
 * @param {Object} $rootScope               rootScope
 * @param {Object} ConfiguratorsDataService ConfiguratorsDataService
 * @param {Object} ConfigurationsService    ConfigurationsService
 */
@Injectable()
export class MullionsLayoutService {
    expandMullions(fromArray: ActiveMullion[], toSash: ActiveSash, direction: 'width' | 'height') {
        fromArray.forEach(exDiv => {
            if (direction === 'height') {
                exDiv.rHeight = Math.floor(exDiv.rHeight + toSash.rHeight);
            } else if (direction === 'width') {
                exDiv.rWidth = Math.floor(exDiv.rWidth + toSash.rWidth);
            }
        });
    }

    getIdForNew(conf: WindowActiveConfiguration) {
        const intDividersId = conf.Sashes.reduce((prev, sash) => {
            const maxId = sash.intMullions.reduce(
                (intPrev, intDivider) => (intDivider.id > intPrev ? intDivider.id : intPrev),
                0
            );
            return maxId > prev ? maxId : prev;
        }, -1);
        const dividersId = conf.Mullions.reduce(
            (prev, divider) => (divider.id > prev ? divider.id : prev),
            -1
        );
        return Math.max(dividersId, intDividersId) + 1;
    }
}
