<div class="slider_container" [ngClass]="{'slider_container--disabled': disabled === true,'slider_container--reversed':reversed === true}">
  <div class="steps_row">
    <div>
        <div *ngFor='let step of steps; trackBy: trackStepId;'>
            <button [attr.aria-disabled]="disabled===true ? true : false"  class="steps_row__step" (click)='onStepClick(step)'
                [ngClass]="{'steps_row__step--active': step.id===actualStep?.id}" tabindex="-1">{{step.label}}</button>
        </div>
    </div>
  </div>
  <div class="slider_row">
    <div #slider role='slider' class="slider" (keydown)="onSliderKeydown($event)">
      <div #thumbnail (mousedown)='onThumbnailPressed()' (touchstart)='onThumbnailTouched($event)'
        class="slider__thumbnail noselect" [class.slider__thumbnail--one-selection]="steps.length === 1">
      </div>
      <div *ngFor='let fragment of steps; trackBy: trackStepId; let i = index' [attr.aria-label]='fragment.label'  class="slider__fragment"
        [ngClass]="{'slider__fragment--active': isSliderFragmentActive(fragment)}" (click)='onFragmentClicked($event,fragment)'>
        <div class="fragment__tick"></div>
        <div *ngIf='i > 0 || steps.length === 1' class="fragment__line" [class.fragment__line--one-selection]="steps.length === 1"></div>
      </div>
    </div>
  </div>
</div>
