import angular from 'angular';
import {ValidationService} from './validation.service.ajs';

/**
 * Fabryka wymiarow bram garazowych
 * @param {object} $rootScope               RootScope
 * @param {object} $filter                  Filtry
 * @param {object} $injector                Injector
 * @param {object} Core                     Biblioteko podrecznych funkcji
 * @param {object} ConfigurationsService    Dostarcza biezaca konfiguracje
 * @param {object} CurConfService           Onformacje o wybranym konfiguratorze
 * @param {object} IssuesService            Walidacja konfiguracji
 * @param {object} ConfiguratorsDataService Dane do konfiguratorow
 * @param {object} PriceService             Modul liczenia ceny
 */
export default function DimensionsFactory($rootScope, $filter, $injector, $translate, Core, ConfigurationsService,
    CurConfService, IssuesService, ConfiguratorsDataService, PriceService, EventBusService, IccConfig, GarageAccessoriesFactory) {
    'ngInject';

    var factory = {
        defaultDimensions: {
            width  : 2500,
            height : 2000,
            circuit: 9000,
            w1     : 90,
            w2     : 90,
            n      : 210,
            e      : 3000,
            shape  : 'rect'
        },
        defaultSizes       : {},
        loadedData         : false,
        setGlobalDimensions: setGlobalDimensions,
        recalculateCircuit : recalculateCircuit,
        setShapeData       : setShapeData,
        valid              : valid,
        setDimensions      : setDimensions,
        changeDimension    : changeDimension,
        getGuidesBySymbol  : getGuidesBySymbol,
        changedGuide,
        garageGuides       : [],
        garagePrices       : []
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    $rootScope.$on('resetData', init);
    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Poczatkowe ustawienia
     */
    function init() {
        if (CurConfService.conf == 'garage_door') {
            IssuesService.addValidateFunction(valid);

            if (angular.isUndefined(ConfigurationsService.conf.Current.Shape) || angular.equals(ConfigurationsService.conf.Current.Shape, {})) {
                setShapeData();
            }
            if (ConfigurationsService.conf.Current.Width === null || ConfigurationsService.conf.Current.Height === null) {
                setGlobalDimensions();
            }
            factory.garageGuides = ConfiguratorsDataService.data.garageGuides;
            factory.defaultSizes = ConfiguratorsDataService.data.garageDefaultSizes;
            for (var n in factory.defaultSizes) {
                factory.defaultSizes[n] = factory.defaultSizes[n].map(function(e) {
                    return ~~e;
                });
            }
            factory.loadedData   = true;
            setGuide(ConfigurationsService.conf.Current.Shape, true);
        }
    }

    /**
     * Ustawia prowadzenie wg wymiarow
     * @param  {object} dimension Wymiary
     */
    function changeDimension(dimension) {
        if (!IccConfig.Configurators.garage_door.guideSelection) {
            setGuide(dimension);
        }
        setDimensions(dimension);
    }

    function setGuide(dimension, init = false) {
        let maxHeight = 0;
        ConfiguratorsDataService.data.garageGuides.forEach(guide => {
            guide.limits.forEach(limit => {
                const height = Number(limit.value);
                if ((ConfigurationsService.conf.Current.Control && limit.control === ConfigurationsService.conf.Current.Control.id || init) && height <= dimension.n && height > maxHeight) {
                    ConfigurationsService.conf.Current.Guide = guide;
                    maxHeight = height;
                }
            });
        });
        changedGuide();
    }

    function getGuidesBySymbol() {
        if (angular.isArray(factory.garageGuides)) {
            return factory.garageGuides.reduce((prev, guide) => {
                prev[guide.symbol] = guide;
                return prev;
            }, {});
        }
        return {};
    }

    function changedGuide() {
        GarageAccessoriesFactory.setDefaultValues(true);
        PriceService.count();
    }

    /**
     * Wstawienie wyumiarow konfiguracji
     */
    function setGlobalDimensions() {
        ConfigurationsService.conf.Current.Width  = angular.copy(parseInt(ConfigurationsService.conf.Current.Shape.width));
        ConfigurationsService.conf.Current.Height = angular.copy(parseInt(ConfigurationsService.conf.Current.Shape.height));
    }

    /**
     * Przeliczanie obwodu konstrukcji
     */
    function recalculateCircuit() {
        ConfigurationsService.conf.Current.Shape.circuit
            = (ConfigurationsService.conf.Current.Width + ConfigurationsService.conf.Current.Height) * 2;
    }

    /**
     * Ustawienie wymiarow w konfiguracji
     */
    function setShapeData() {
        ConfigurationsService.conf.Current.Shape.width   = ConfigurationsService.conf.Current.Width;
        ConfigurationsService.conf.Current.Shape.height  = ConfigurationsService.conf.Current.Height;
        ConfigurationsService.conf.Current.Shape.w1      = 90;
        ConfigurationsService.conf.Current.Shape.w2      = 90;
        ConfigurationsService.conf.Current.Shape.n       = 210;
        ConfigurationsService.conf.Current.Shape.e       = 3000;
        ConfigurationsService.conf.Current.Shape.shape   = 'rect';
        ConfigurationsService.conf.Current.Shape.circuit = (ConfigurationsService.conf.Current.Width + ConfigurationsService.conf.Current.Height) * 2;
    }

    /**
     * Ustawienie nowego wymiaru
     * @param {object} dimensions Obiekt z wymiarami
     */
    function setDimensions(dimensions) {
        ConfigurationsService.conf.Current.Shape = Core.copy(dimensions);
        setGlobalDimensions();
        GarageAccessoriesFactory.setDefaultValues(true);
        PriceService.count();
        recalculateCircuit();
    }

    /**
     * Walidacja wymiarow
     * @param  {object} shapeData Wymiary
     * @return {boolean}          Czy zwalidowano poprawnie
     */
    function valid(shapeData) {
        return ValidationService.valid(ConfigurationsService.conf.Current, IssuesService, factory.garageGuides, $translate, shapeData);
    }
}
