import { PleatedBlindConfiguration } from './PleatedBlindConfiguration';
import { PleatedBlindActiveConfiguration } from './PleatedBlindActiveConfiguration';
import { ExternalBlindConfiguration } from './ExternalBlindConfiguration';
import { ExternalBlindActiveConfiguration } from './ExternalBlindActiveConfiguration';
import { Common } from '@icc/common/Common';

import { ActiveConfiguration } from './ActiveConfiguration';
import { Configuration } from './Configuration';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';

import { AccessoriesActiveConfiguration } from './AccessoriesActiveConfiguration';
import { AccessoriesConfiguration } from './AccessoriesConfiguration';
import { AwningActiveConfiguration } from './AwningActiveConfiguration';
import { AwningConfiguration } from './AwningConfiguration';
import { ComplementaryGoodsActiveConfiguration } from './ComplementaryGoodsActiveConfiguration';
import { ComplementaryGoodsConfiguration } from './ComplementaryGoodsConfiguration';
import { CoupledWindowActiveConfiguration } from './CoupledWindowActiveConfiguration';
import { CoupledWindowConfiguration } from './CoupledWindowConfiguration';
import { DoorActiveConfiguration } from './DoorActiveConfiguration';
import { DoorConfiguration } from './DoorConfiguration';
import { FoldingDoorActiveConfiguration } from './FoldingDoorActiveConfiguration';
import { FoldingDoorConfiguration } from './FoldingDoorConfiguration';
import { SlidingDoorActiveConfiguration } from './SlidingDoorActiveConfiguration';
import { SlidingDoorConfiguration } from './SlidingDoorConfiguration';
import { GarageDoorActiveConfiguration } from './GarageDoorActiveConfiguration';
import { GarageDoorConfiguration } from './GarageDoorConfiguration';
import { HSActiveConfiguration } from './HSActiveConfiguration';
import { HSConfiguration } from './HSConfiguration';
import { MosquitoActiveConfiguration } from './MosquitoActiveConfiguration';
import { MosquitoConfiguration } from './MosquitoConfiguration';
import { RollerShutterActiveConfiguration } from './RollerShutterActiveConfiguration';
import { RollerShutterConfiguration } from './RollerShutterConfiguration';
import { WindowActiveConfiguration } from './WindowActiveConfiguration';
import { WindowConfiguration } from './WindowConfiguration';
import { WinterGardenActiveConfiguration } from './WinterGardenActiveConfiguration';
import { WinterGardenConfiguration } from './WinterGardenConfiguration';
import { ColorsDefaultsService } from '../colors/colors-defaults.service';
import { TranslateService } from '../translate.service';

import { logger, core } from '../helpers';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '../config';
import { Injectable, Inject, Injector } from '@angular/core';
import { WindowSmallConfiguration } from './WindowSmallConfiguration';
import { BehaviorSubject } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { isNotNullOrUndefined } from '@icc/helpers';
import { getDefaultShapeDimensions } from '@icc/window';

interface Configurations {
    accessory: {
        active: typeof AccessoriesActiveConfiguration;
        simple: typeof AccessoriesConfiguration;
    };
    awning: {
        active: typeof AwningActiveConfiguration;
        simple: typeof AwningConfiguration;
    };
    complementary_goods: {
        active: typeof ComplementaryGoodsActiveConfiguration;
        simple: typeof ComplementaryGoodsConfiguration;
    };
    coupled_window: {
        active: typeof CoupledWindowActiveConfiguration;
        simple: typeof CoupledWindowConfiguration;
    };
    door: {
        active: typeof DoorActiveConfiguration;
        simple: typeof DoorConfiguration;
    };
    folding_door: {
        active: typeof FoldingDoorActiveConfiguration;
        simple: typeof FoldingDoorConfiguration;
    };
    sliding_door: {
        active: typeof SlidingDoorActiveConfiguration;
        simple: typeof SlidingDoorConfiguration;
    };
    garage_door: {
        active: typeof GarageDoorActiveConfiguration;
        simple: typeof GarageDoorConfiguration;
    };
    hs: {
        active: typeof HSActiveConfiguration;
        simple: typeof HSConfiguration;
    };
    mosquito: {
        active: typeof MosquitoActiveConfiguration;
        simple: typeof MosquitoConfiguration;
    };
    roller_shutter: {
        active: typeof RollerShutterActiveConfiguration;
        simple: typeof RollerShutterConfiguration;
    };
    pleated_blind: {
        active: typeof PleatedBlindActiveConfiguration;
        simple: typeof PleatedBlindConfiguration;
    }
    external_blind: {
        active: typeof ExternalBlindActiveConfiguration;
        simple: typeof ExternalBlindConfiguration;
    };
    window: {
        active: typeof WindowActiveConfiguration;
        simple: typeof WindowConfiguration;
    };
    winter_garden: {
        active: typeof WinterGardenActiveConfiguration;
        simple: typeof WinterGardenConfiguration;
    };
}

export type ConfigurationType = keyof Configurations;

export interface Configurator<T extends ConfigurationType = ConfigurationType> {
    Configuration: Configurations[T]['active'];
    Current: Configurations[T]['active']['prototype'];
    Edit: Configurations[T]['active']['prototype'] & {
        _id: string,
        clientPrice?: number,
        dealerPrice?: number,
    } | boolean | undefined;
    Default: Configurations[T]['active']['prototype'];
    conf: T;
    copiedPosition: boolean;
    measurementsAttachments: any[];
    offerConfigurator: boolean;
    addPositionToGroup?: boolean;
    stepCode?: string;
    editedPositionPrices?: {
        clientPrice: number;
        dealerPrice: number;
    }
}

export type ActiveConfigurationImpl = Configurations[ConfigurationType]['active']['prototype'];
export type ConfigurationImpl = Configurations[ConfigurationType]['simple']['prototype'];

@Injectable()
export abstract class ConfigurationsService<T extends ConfigurationType = ConfigurationType> {
    abstract conf: Configurator<T>;
    conf$: BehaviorSubject<Partial<Configurator<T>>> = new BehaviorSubject<
        Partial<Configurator<T>>
    >({});
    configuration$ = this.conf$.pipe(
        filter(isNotNullOrUndefined),
        map(conf => conf.Current),
        filter(isNotNullOrUndefined)
    );
    price: any = { noPrice: null };
    version = 1;
    configurators: Partial<Record<ConfigurationType, Configurator>> = {};
    systemsComparisonEdit = false;

    editedPosition: Partial<Record<ConfigurationType, {
        _id: string,
        clientPrice?: number,
        dealerPrice?: number,
    } | null>> = {};
    copiedPosition = false;

    configurations: Configurations = {
        accessory: {
            active: AccessoriesActiveConfiguration,
            simple: AccessoriesConfiguration,
        },
        awning: {
            active: AwningActiveConfiguration,
            simple: AwningConfiguration,
        },
        complementary_goods: {
            active: ComplementaryGoodsActiveConfiguration,
            simple: ComplementaryGoodsConfiguration,
        },
        coupled_window: {
            active: CoupledWindowActiveConfiguration,
            simple: CoupledWindowConfiguration,
        },
        door: {
            active: DoorActiveConfiguration,
            simple: DoorConfiguration,
        },
        folding_door: {
            active: FoldingDoorActiveConfiguration,
            simple: FoldingDoorConfiguration,
        },
        sliding_door: {
            active: SlidingDoorActiveConfiguration,
            simple: SlidingDoorConfiguration,
        },
        garage_door: {
            active: GarageDoorActiveConfiguration,
            simple: GarageDoorConfiguration,
        },
        hs: {
            active: HSActiveConfiguration,
            simple: HSConfiguration,
        },
        mosquito: {
            active: MosquitoActiveConfiguration,
            simple: MosquitoConfiguration,
        },
        pleated_blind: {
            active: PleatedBlindActiveConfiguration,
            simple: PleatedBlindConfiguration
        },
        roller_shutter: {
            active: RollerShutterActiveConfiguration,
            simple: RollerShutterConfiguration,
        },
        external_blind: {
            active: ExternalBlindActiveConfiguration,
            simple: ExternalBlindConfiguration,
        },
        window: {
            active: WindowActiveConfiguration,
            simple: WindowConfiguration,
        },
        winter_garden: {
            active: WinterGardenActiveConfiguration,
            simple: WinterGardenConfiguration,
        },
    };
    constructor(
        protected translateService: TranslateService,
        @Inject(APP_CONFIG) protected config: AppConfigFactory
    ) {}

    init(
        startState?:
            | InstanceType<ConfigurationsService['configurations'][T]['active']>
            | InstanceType<ConfigurationsService['configurations'][T]['simple']>,
        edit = false,
        editedPositionPrices = null,
    ) {}

    getSimpleConfiguration<K extends ConfigurationType>(type: K): Configurations[K]['simple'] {
        const configurations = this.configurations[type];
        if (configurations) {
            return configurations.simple;
        }
        return null;
    }

    getActiveConfiguration<K extends ConfigurationType>(type: K): Configurations[K]['active'] {
        const configurations = this.configurations[type];
        if (configurations) {
            return configurations.active;
        }
        return null;
    }

    createSimpleConfiguration(
        configuration: ActiveConfiguration | Configuration | WindowSmallConfiguration,
        dataRequiredForUpdate: any = {},
        configuratorsDataService?: ConfiguratorsDataService,
        colorsDefaultsService?: ColorsDefaultsService,
        langCode: string = this.config().CurLang,
        config = this.config()
    ) {
        const simpleConfiguration = this.getSimpleConfiguration(configuration.type);
        if (simpleConfiguration) {
            return new simpleConfiguration(
                configuration,
                dataRequiredForUpdate,
                true,
                configuratorsDataService,
                colorsDefaultsService,
                langCode,
                config
            );
        }
        return null;
    }

    createActiveConfiguration(
        configuration?: ActiveConfiguration | Configuration | null,
        configuratorsDataService?: ConfiguratorsDataService,
        langCode?: string,
        config = this.config(),
    ) {
        if (configuration) {
            const activeConfiguration = this.getActiveConfiguration(configuration.type);
            if (configuration) {
                return new activeConfiguration(configuration, configuratorsDataService, langCode, config);
            }
        } else {
            const activeConfiguration = this.getActiveConfiguration(this.conf.conf);
            if (activeConfiguration) {
                return this.setDefaults(new activeConfiguration());
            }
        }
        return null;
    }

    setDefaults(configuration: Configurations[ConfigurationType]['active']['prototype'], newConfiguration = true) {
        switch (configuration.type) {
            case 'window':
                if (newConfiguration) {
                    configuration.Width = this.config().IccConfig.Configurators?.window?.dimensions?.width || 1500;
                    configuration.Height = this.config().IccConfig.Configurators?.window?.dimensions?.height || 1500;
                    configuration.Shape = getDefaultShapeDimensions('rect', configuration.Width, configuration.Height);
                }
                configuration.Steel = this.config().IccConfig.Configurators.steelSelect
                    ? 'Opened'
                    : null;
                configuration.DrawId = 'draw_' + core.generateUUID();
                configuration.RollerShutter.hasAssembly = this.config().IccConfig.Configurators.roller_shutter.defaultAssemblyToWindow;
                break;
            case 'hs':
                if (newConfiguration) {
                    configuration.Width = this.config().IccConfig.Configurators?.hs?.dimensions?.width || 2000;
                    configuration.Height = this.config().IccConfig.Configurators?.hs?.dimensions?.height || 2000;
                    configuration.Shape = getDefaultShapeDimensions('rect', configuration.Width, configuration.Height);
                }
                configuration.Steel = this.config().IccConfig.Configurators.steelSelect
                    ? 'Opened'
                    : null;
                configuration.DrawId = 'draw_' + core.generateUUID();
                configuration.RollerShutter.hasAssembly = this.config().IccConfig.Configurators.roller_shutter.defaultAssemblyToWindow;
                break;
            case 'sliding_door':
                if (newConfiguration) {
                    configuration.Width = this.config().IccConfig.Configurators?.sliding_door?.dimensions?.width || 2000;
                    configuration.Height = this.config().IccConfig.Configurators?.sliding_door?.dimensions?.height || 2000;
                    configuration.Shape = getDefaultShapeDimensions('rect', configuration.Width, configuration.Height);
                }
                configuration.Steel = this.config().IccConfig.Configurators.steelSelect
                    ? 'Opened'
                    : null;
                configuration.DrawId = 'draw_' + core.generateUUID();
                configuration.RollerShutter.hasAssembly = this.config().IccConfig.Configurators.roller_shutter.defaultAssemblyToWindow;
                break;
            case 'door':
                if (newConfiguration) {
                    configuration.Width = this.config().IccConfig.Configurators?.door?.dimensions?.width || 1000;
                    configuration.Height = this.config().IccConfig.Configurators?.door?.dimensions?.height || 2000;
                    configuration.Shape = getDefaultShapeDimensions('rect', configuration.Width, configuration.Height);
                    configuration.Quantity = this.config().quantity || 1;
                    configuration.Title = this.config().userDescription || '';
                }
                configuration.RollerShutter.hasAssembly = this.config().IccConfig.Configurators.roller_shutter.defaultAssemblyToWindow;
                break;
            case 'accessory':
                configuration.Name = this.translateService.instant('ACCESSORY|Akcesoria');
                break;
            case 'complementary_goods':
                configuration.Name = this.config().IccConfig.Configurators?.complementary_goods
                    .namedAccessory
                    ? this.translateService.instant('ACCESSORY|Akcesoria')
                    : this.translateService.instant('GOODS|Dobra komplementarne');
                break;
            case 'winter_garden':
                configuration.Name = this.translateService.instant('WINTERGARDEN|Ogrody zimowe');
                break;
            case 'coupled_window':
                configuration.Name = this.translateService.instant('WINDOW|Konstrukcje złożone');
                break;
            case 'roller_shutter':
                if (newConfiguration) {
                    configuration.Width = this.config().IccConfig.Configurators?.roller_shutter?.dimensions?.width || 1500;
                    configuration.Height = this.config().IccConfig.Configurators?.roller_shutter?.dimensions?.height || 1500;
                }

                if (configuration?.RollerShutter?.Accessories) {
                    configuration.Accessories = [...configuration?.RollerShutter?.Accessories];
                }

                break;
        }
        return configuration;
    }

    updatedConfiguration() {
        this.conf$.next(this.conf);
    }

    setConstructionName(name: string){
        this.conf.Current.Title=name;
        this.updatedConfiguration();
    }

    getConstructionName() {
        return this.conf.Current.Title || '';
    }

    setQuantity(quantity = 1) {
        if (!this.conf?.Current) {
            return;
        }

        if (quantity > 0) {
            this.conf.Current.Quantity = quantity;
            this.updatedConfiguration();
        } else {
            this.conf.Current.Quantity = 1;
        }
    }

    getQuantity() {
        return this.conf.Current.Quantity;
    }

    abstract getOrInitConfiguratorConfigurations<U extends keyof Configurations>(
        type: U,
        startState?:
            | InstanceType<ConfigurationsService['configurations'][U]['active']>
            | InstanceType<ConfigurationsService['configurations'][U]['simple']>
    ): Configurator<U>;
}
