import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewConfiguratorContainerComponent } from './new-configurator-container/new-configurator-container.component';
import { NewConfiguratorLazyModule } from './new-configurator-lazy.module';

@NgModule({
    imports: [
        CommonModule, 
        NewConfiguratorLazyModule
    ],
    declarations: [NewConfiguratorContainerComponent],
    exports: [NewConfiguratorLazyModule],
    providers: [],
    entryComponents: [NewConfiguratorContainerComponent]
})
export class NewConfiguratorModule {}
