import angular from 'angular';

/**
 * Fabryka linii markiz
 * @param {object} $rootScope               RootScope
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} ConfiguratorsDataService Fabryka z danymi do konfiguratorów
 * @param {object} CurConfService           Informacje o bieżącym konfiguratorze
 * @param {object} StepFactory              StepFactory
 * @param {object} PriceService             Liczenie cen
 * @param {object} AwningDimensionsFactory  Fabryka rozmiarów markiz
 * @param {object} AwningColorsFactory      Fabryka kolorów markiz
 * @param {object} AwningSheathingFactory   Fabryka poszycia markiz
 * @param {object} AwningFrillFactory       Fabryka falbany markiz
 * @param {object} AwningDriveFactory       Fabryka napędów markiz
 * @param {object} AwningAssemblyFactory    Fabryka sposobu montażu markiz
 */
export default function SystemsFactory($rootScope, ConfigurationsService, ConfiguratorsDataService, CurConfService, StepFactory, PriceService,
                            AwningDimensionsFactory, AwningColorsFactory, AwningSheathingFactory, AwningFrillFactory, AwningDriveFactory, AwningAssemblyFactory, EventBusService) {
    'ngInject';

    var factory = {
        systems         : [],
        loadedData      : false,
        selectSystem    : selectSystem,
        setDefaultValues: setDefaultValues
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    $rootScope.$on('changedStep', init);

    return factory;

    /**
     * Funkcja inicjujaca
     */
    function init() {
        if (CurConfService.conf === 'awning') {
            setValues();
            if (angular.isUndefined(ConfigurationsService.conf.Current.System.id)) {
                factory.setDefaultValues();
            }
            factory.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Ustawienie wartości
     */
    function setValues() {
        factory.systems = getSystems();
    }

    /**
     * Zwrócenie Systemów
     * @return {Object} Systemy
     */
    function getSystems() {
        return ConfiguratorsDataService.data.awningLines;
    }

    /**
     * Wybór systemów
     * @param  {Object} system   System
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    function selectSystem(system, nextStep, price) {
        ConfigurationsService.conf.Current.System = system;
        ConfigurationsService.conf.Default.System = system;

        ConfigurationsService.conf.Current.Name = system.name;
        ConfigurationsService.conf.Default.Name = system.name;

        var reset = false;
        if (ConfigurationsService.conf.Current.SystemId !== system.id) {
            reset = true;
        }

        ConfigurationsService.conf.Current.SystemId = system.id;
        ConfigurationsService.conf.Default.SystemId = system.id;

        AwningDimensionsFactory.setDefaultValues(reset);
        AwningColorsFactory.setDefaultValues(reset);
        AwningSheathingFactory.setDefaultValues(reset);
        AwningFrillFactory.setDefaultValues(reset);
        AwningDriveFactory.setDefaultValues(reset);
        AwningAssemblyFactory.setDefaultValues(reset);

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }
    }

    function setDefaultValues() {
        factory.selectSystem(factory.systems[0]);
    }

}
