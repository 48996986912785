import {DatabaseConfiguration, DatabaseManager} from '@icc/helpers/browser';
import { Inject, Injectable, InjectionToken } from "@angular/core";

export const LOCAL_DB_VERSION = new InjectionToken<number>('LOCAL_DB_VERSION');

@Injectable()
export class ConfiguratorDatabaseManager extends DatabaseManager {
    protected databasesConfigurations: DatabaseConfiguration[] = [
        {
            name: 'Configurators',
            simple: true
        },
        {
            name: 'CustomPrices',
            simple: true
        },
        {
            name: 'DiscountPerProduct',
            simple: true
        },
        {
            name: 'DiscountsAndMultipliers',
            simple: true
        },
        {
            name: 'Layouts',
            simple: true
        },
        {
            name: 'Prices',
            simple: true
        },
        {
            name: 'Settings',
            simple: true
        },
        {
            name: 'Translations',
            simple: true
        }
    ];

    constructor(@Inject(LOCAL_DB_VERSION) localDbVersion: number) {
        super(localDbVersion);
    }
}
