import { ComponentType } from '@angular/cdk/portal';
import { Injectable, InjectionToken } from '@angular/core';
import { PageComponent } from './page.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ICC_PAGE_DATA = new InjectionToken<any>('IccPageData');

export interface ModalOptions {
    /**
     * a template representing modal's content
     */
    template?: string;

    /**
     * a path to a template representing modal's content
     */
    templateUrl?: string | (() => string);

    /**
     * a controller for a modal instance - it can initialize scope used by modal.
     * A controller can be injected with `$modalInstance`
     * If value is an array, it must be in Inline Array Annotation format for injection (strings followed by factory method)
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    controller?: string | Function | Array<string | Function>;

    /**
     * A string reference to the component to be rendered that is registered with Angular's compiler. If using a directive, the directive must have `restrict: 'E'` and a template or templateUrl set.
     *
     * It supports these bindings:
     *   - `close` - A method that can be used to close a modal, passing a result. The result must be passed in this format: `{$value: myResult}`
     *   - `dismiss` - A method that can be used to dismiss a modal, passing a result. The result must be passed in this format: `{$value: myRejectedResult}`
     *   - `modalInstance` - The modal instance. This is the same `$uibModalInstance` injectable found when using `controller`.
     *   - `resolve` - An object of the modal resolve values. See [UI Router resolves] for details.
     */
    component?: string;

    /**
     * members that will be resolved and passed to the controller as locals; it is equivalent of the `resolve` property for AngularJS routes
     * If property value is an array, it must be in Inline Array Annotation format for injection (strings followed by factory method)
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    resolve?: { [key: string]: string | Function | Array<string | Function> | Object | undefined | null };


    /**
     * additional CSS class(es) to be added to a modal window template
     */
    windowClass?: string;


    pageComponent?: ComponentType<PageComponent>;
    /**
     * Otwieranie w panelu filtrów.
     */
    filters?: boolean;
}

export interface ModalInstance<T> {
    result: PromiseLike<T>,
    closed: PromiseLike<void>,
    close: () => void
}

@Injectable()
export abstract class ModalService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    abstract open<T = any>(options: ModalOptions): ModalInstance<T>;
}
