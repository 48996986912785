import angular from 'angular';

/**
 * Kontroler modalu rolet
 * @param {object} $uibModalInstance Modal Instance
 * @param {object} colors         Kolory
 * @param {object} groupColor     Grupy kolorów
 * @param {object} selColor       Sel Color
 * @param {object} chargeColors   Zmiana koloru
 */
export default function ModalColorCtrl($uibModalInstance, colors, groupColor, selColor, chargeColors, EventBusService, ScrollbarService) {
    'ngInject';

    var vm = this;

    vm.colors       = colors;
    vm.chargeColors = chargeColors;
    vm.groupColor   = groupColor;
    vm.selColor     = selColor;
    vm.refresh      = refresh;

    vm.selectColor = function(color) {
        $uibModalInstance.close([color, vm.groupColor, vm.typeCharge]);
    };

    vm.isSelected = function(color) {
        if (angular.isDefined(vm.selColor) && vm.selColor !== null
             && angular.isDefined(color) && color !== null) {
            if (color.id == vm.selColor.id /* && "RAL"==vm.selColor.colorType*/) {
                return true;
            }
        }
        return false;
    };

    vm.close = function() {
        $uibModalInstance.close();
    };

    /**
     * Odświeża scrollbar.
     * @memberof ModalColorPVCCtrl
     */
    function refresh() {
        ScrollbarService.update();
    }
}
