import { Component, OnInit, Inject } from '@angular/core';
import { _, SharedFacade, PageComponent, ICC_PAGE_DATA, ModalService } from '@icc/configurator/shared';
import { iccListTab, iccListItem } from '@icc/configurator/ui';
import { WindowFacade } from '../+state/window.facade';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HandlesService } from '@icc/legacy/configurator/steps/window/handles/handles.service';
import { ModalInfoComponent } from '@icc/configurator/pages';

@Component({
    selector: 'icc-change-fitting-page',
    templateUrl: './change-fitting-page.component.html',
    styleUrls: ['./change-fitting-page.component.scss'],
})
export class ChangeFittingPageComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Okucie');
    public options = [];
    fittings: iccListItem[] = [];
    selectedFittingId = 0

    constructor(
        private windowFacade: WindowFacade,
        private handlesService: HandlesService,
        private sharedFacade: SharedFacade,
        private modalService: ModalService,
        @Inject(ICC_PAGE_DATA) private pageData: {
            selectedFittingId: any
        }
    ) {
        super();
    }

    ngOnInit() {
      this.selectedFittingId = Number(this.pageData.selectedFittingId);
        this.fittings = this.handlesService.fittings.map(fitting => ({
            id: Number(fitting.id),
            title: fitting.name,
            description: fitting.description,
            imageUrl: '/files/windowfitting/' + fitting.img,
            showInfo: true,
            content: fitting.description,
        }));
    }

    selectFitting(item: iccListItem) {
        const fitting = this.handlesService.fittings.find(f => Number(f.id) === Number(item.id));
        if (fitting) {
            this.handlesService.selectFitting(fitting);
            this.sharedFacade.closePage();
        }
    }

    showInfo(item: iccListItem) {
        this.modalService
            .open({
                pageComponent: ModalInfoComponent,
                resolve: {
                    item: () => item,
                    button: () => true,
                },
            })
            .result.then((result: boolean) => {
                if (result) {
                    this.selectFitting(item);
                }
            });
    }
}
