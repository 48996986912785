import { PanelsService } from './../panels.service';
import { GarageDoorFacade } from './../+state/garage-door.facade';
import { Component, OnInit } from '@angular/core';
import { StepComponent, _ } from '@icc/configurator/shared';

@Component({
  selector: 'icc-plinth-panel',
  templateUrl: './plinth-panel.component.html',
  styleUrls: ['./plinth-panel.component.scss']
})
export class PlinthPanelComponent extends StepComponent implements OnInit {

  public configurator = 'garage_door';
  public stepId = 'plinth_panel';
  public title = _('GARAGE|Skonfiguruj panel cokołu');


  selectedPlinthPanel$ = this.garageDoorFacade.selectedPlinthPanel$;
  selectedPlinthPanelStructure$ = this.garageDoorFacade.selectedPlinthPanelStructure$;

  constructor(
    private garageDoorFacade: GarageDoorFacade,
    private panelsService: PanelsService
  ) { 
    super();
  }

  ngOnInit() {}

  selectPlinthPanel() {
    this.panelsService.openModalPlinthPanelList();
  }

  selectPlinthPanelStructure() {
    this.panelsService.openModalPlinthPanelStructureList();
  }
}
