export default function CustomListSearchFilter() {
    'ngInject';

    return function(data, dateFrom, dateTo, status, seller, dealerstatus, archives, onlyOrdered, dealerId) {

        var archivedStatus  = [4];
        var defaultStatuses = [0, 1, 2, 3];

        return (data || []).filter(function filterOffer(e) {
            var checked = true;

            checked = checked && (!onlyOrdered || e.doc.status == 9);
            checked = checked && (onlyOrdered || (!status ? defaultStatuses.indexOf(~~e.doc.status) > -1 : e.doc.status == status));
            checked = checked && (!dealerstatus || e.doc.dealer_status == dealerstatus);
            checked = checked && (!seller || e.doc.dealer_seller_id == seller);
            checked = checked && (!dateFrom || new Date(e.doc.created.substr(0, 10) + ' 00:00:00') >= dateFrom);
            checked = checked && (!dateTo || new Date(e.doc.created.substr(0, 10) + ' 00:00:00') <= dateTo);
            checked = checked && (!archives ? archivedStatus.indexOf(~~e.doc.dealer_status) == -1 : archivedStatus.indexOf(~~e.doc.dealer_status) > -1);
            checked = checked && (!dealerId || e.doc.dealer_id == dealerId);

            return checked;
        });

    };
}
