const GlazingUnitModalTemplate = require('ngtemplate-loader!./glazing-unit-modal.component.html');
import { GlazingUnitModalController } from './glazing-unit-modal.controller';

export const GlazingUnitModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: GlazingUnitModalTemplate,
  controller: GlazingUnitModalController
};
