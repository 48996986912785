import * as angular from 'angular';
import 'angular-ui-bootstrap';

import { MosquitoActiveConfiguration } from '@icc/common/configurations/MosquitoActiveConfiguration';

/**
 * Kontroler modala siatek
*/
export class ModalMosquitoCtrl {

    colors;
    montages;
    mosquito;
    systems;
    types;
    windowLines;

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private ConfigurationsService,
        private MosquitoColorsService,
        private MosquitoMontagesService,
        private MosquitoSystemsService,
        private MosquitoTypesService,
        private MosquitoDimensionsService,
        private conf,
        private sash,
    ) {
        'ngInject';

        this.conf = this.conf || this.ConfigurationsService.conf.Current;
        this.sash = this.sash ? this.conf.Sashes.find(s => s.id === this.sash.id) : null;

        this.mosquito = (this.sash || this.conf).mosquito;

        if (this.mosquito) {
            this.getWindowLines();
            this.getSystems();
            this.getColors();
            this.getTypes();
            this.getMontages();
        }
    }

    getColors() {
        if (this.mosquito.System && this.mosquito.Type.id) {
            this.MosquitoColorsService.loadColorsBySystem(this.mosquito.System.id, this.mosquito.Type.id);
        }
    }

    setColors() {
        this.mosquito.Colors = {
            frame: {},
            panel: angular.copy(this.MosquitoColorsService.panelColors[0]),
            net  : angular.copy(this.MosquitoColorsService.netColors[0])
        };
        this.MosquitoColorsService.setColorSide(this.mosquito.Colors, 'frame', this.MosquitoColorsService.frameColors[0]);
    }

    getMontages() {
        this.montages = this.MosquitoMontagesService.getMontages(this.mosquito);
    }

    setMontage(montage) {
        this.mosquito.Montage = montage;
    }

    getMosquito() {
        return new MosquitoActiveConfiguration({
            Width: this.sash ? this.MosquitoDimensionsService.getFrameLightOuterWidth(this.conf, this.sash) : this.conf.Width,
            Height: this.sash ? this.MosquitoDimensionsService.getFrameLightOuterHeight(this.conf, this.sash) : this.conf.Height,
        });
    }

    setMosquito(set) {
        this.mosquito = (this.sash || this.conf).mosquito = set ? this.getMosquito() : null;

        if (this.mosquito) {
            this.getWindowLines();
            this.getSystems();
            this.setSystem(this.systems[0]);
        }
    }

    getSystems() {
        this.systems = this.MosquitoSystemsService.getSystems();
        this.systems = this.systems.filter(system => angular.isDefined(this.windowLines[system.id]) && this.windowLines[system.id].some(el => el.window_line_id == this.conf.System.id));
    }

    setSystem(system) {
        this.mosquito.System = system;
        this.mosquito.Name = system ? system.name : '';

        this.getTypes();
        this.setType(this.types[0] || {});

        this.getColors();
        this.setColors();
    }

    getTypes() {
        this.types = this.MosquitoTypesService.getTypes(this.mosquito);
    }

    setType(type) {
        this.mosquito.Type = type;

        if (type && type.has_direction && this.mosquito.Direction === null) {
            this.mosquito.Direction = 'left';
        } else if (type && !type.has_direction) {
            this.mosquito.Direction = null;
        }

        this.getMontages();
        this.setMontage(this.montages[0]);

        this.getWindowLines();
        this.setWindowLine(this.windowLines[0]);
        this.getColors();
        this.setColors();
    }

    getWindowLines() {
        this.windowLines = this.MosquitoMontagesService.getWindowLines();
    }

    setWindowLine(line) {
        this.mosquito.WindowLine = line;
    }

    openModalColor(type, color) {
        this.MosquitoColorsService.openModalColor(type, color, this.mosquito.Colors);
    }

    close() {
        this.$uibModalInstance.close();
    }

    updateDimensions() {
        this.mosquito.HeightModify = this.mosquito.Height - this.MosquitoDimensionsService.getFrameLightOuterHeight(this.conf, this.sash);
        this.mosquito.WidthModify = this.mosquito.Width - this.MosquitoDimensionsService.getFrameLightOuterWidth(this.conf, this.sash);
    }

}
