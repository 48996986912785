import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { SharedFacade, _ } from '@icc/configurator/shared';
import { BehaviorSubject, SubscriptionLike } from 'rxjs';
import { Profile } from '@icc/window';
import {
    WindowActiveConfiguration,
    core,
    ConfigurationsService,
} from '@icc/common';
import { ColorControl, ColorControlDef } from '../../colors/color-control';
import { NewColorsService } from '../../colors/new-colors.service';
import { ColoredElements, ProfileColoredSides, ColoredSides } from '../../colors/colored-elements';
import { WoodService } from '../../construction/wood.service';
import { IccColor, IccProfileSideColors } from '@icc/common/data-types';

@Component({
    selector: 'icc-profiles-colors-page',
    templateUrl: './profiles-colors-page.component.html',
    styleUrls: ['./profiles-colors-page.component.scss'],
})
export class ProfilesColorsPageComponent extends PageComponent implements OnInit, OnDestroy {
    public title = _('WINDOW|Wybierz kolor profilu');
    public options = [];

    showCore = false;
    agreeButton = false;

    controls: ColorControl[] = [];
    controlsDef: ColorControlDef[] = [
        {
            title: _('COLOR|Kolor zewnętrzny'),
            element: 'profile',
            side: 'outer',
        },
        {
            title: _('COLOR|Kolor wewnętrzny '),
            element: 'profile',
            side: 'inner',
            whiteSwitch: true,
        },
        {
            title: _('WINDOW|Kolor bazy'),
            element: 'profile',
            side: 'core',
        },
    ];

    private subscriptions: SubscriptionLike[] = [];

    constructor(
        private sharedFacade: SharedFacade,
        private newColorsService: NewColorsService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            profile: Profile;
            selectedColors$: BehaviorSubject<IccProfileSideColors | null | undefined>;
            selectedProfile?: Profile;
            agreeButton?: boolean;
        },
        private configurationsService: ConfigurationsService<'window'>,
        private woodService: WoodService
    ) {
        super();
        this.updateControls(this.configurationsService.conf.Current);
    }

    ngOnInit() {
        this.agreeButton = this.pageData.agreeButton || false;

        this.subscriptions.push(
            this.configurationsService.configuration$.subscribe((conf) => {
                this.updateControls(conf);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    updateControls(conf = this.configurationsService.conf.Current) {
        const controls: ColorControl[] = [];
        for (const controlDef of this.controlsDef) {
            controlDef.profile = this.pageData.profile;

            if (this.shouldSkipControl(conf, controlDef)) {
                continue;
            }

            const colorValue = this.newColorsService.getElementColor(conf, controlDef);
            const availableColors = this.newColorsService.getMatchingColorsFor(conf, controlDef);
            const switchVisibility = this.getSwitchVisibility(availableColors, controlDef, conf);
            const control = this.createControl(controlDef, switchVisibility, colorValue);
            controls.push(control);
        }
        this.controls = controls;
    }

    shouldSkipControl(conf = this.configurationsService.conf.Current, controlDef: ColorControlDef) {
        if (controlDef.hide && controlDef.hide()) {
            return true;
        }
        if (controlDef.side === 'core' && !this.hasCoreColorSelect(conf, controlDef)) {
            return true;
        }
        return false;
    }

    getControlColorValues(conf: WindowActiveConfiguration, controlDef: ColorControlDef) {
        const colorValue = this.newColorsService.getElementColor(conf, controlDef);

        let constrColorValue = null;

        if (
            controlDef.element === 'profile' &&
            (controlDef.side === 'outer' || controlDef.side === 'inner')
        ) {
            constrColorValue = this.newColorsService.getElementConstrColor(
                conf,
                controlDef.element,
                controlDef.side,
                controlDef.profile
            );
        }
        return { colorValue, constrColorValue };
    }

    getSwitchVisibility(
        availableColors: IccColor[],
        controlDef: ColorControlDef,
        conf: WindowActiveConfiguration
    ) {
        let isWhiteSwitchVisible = false;
        let isWhiteSwitchDisabled = true;
        let isBilateralSwitchVisible = false;
        let isMoreColorsSwitchVisible = false;

        const disabled = !availableColors?.length || availableColors?.length <= 1;

        let material = 'pvc';
        if (WindowActiveConfiguration.is(conf)) {
            material = conf.System.type;
        }
        if (controlDef.side === 'inner' && material === 'pvc' && !conf.HasAlushell) {
            const switchVisibility = this.isWhiteSwitchVisible(controlDef, conf, availableColors);
            isWhiteSwitchVisible = switchVisibility.whiteSwitchVisible ?? false;
            isBilateralSwitchVisible = switchVisibility.bilateralSwitchVisible ?? false;
            isMoreColorsSwitchVisible = !disabled;

            isWhiteSwitchDisabled = this.isWhiteColorButtonDisabled(
                controlDef.element,
                controlDef.side,
                controlDef.profile,
                conf
            );
        }
        return {
            isWhiteSwitchVisible,
            isBilateralSwitchVisible,
            isMoreColorsSwitchVisible,
            isWhiteSwitchDisabled,
            disabled,
        };
    }

    createControl(
        controlDef: ColorControlDef,
        switchVisibility: {
            isWhiteSwitchVisible: boolean;
            isBilateralSwitchVisible: boolean;
            isMoreColorsSwitchVisible: boolean;
            isWhiteSwitchDisabled: boolean;
            disabled: boolean;
        },
        colorValue: IccColor | null,
    ) {
        return {
            title: typeof controlDef.title === 'function' ? controlDef.title() : controlDef.title,
            element: controlDef.element,
            side: controlDef.side,
            profile: this.pageData.profile,
            alt: controlDef.alt,
            whiteSwitchVisible: switchVisibility.isWhiteSwitchVisible,
            bilateralSwitchVisible: switchVisibility.isBilateralSwitchVisible,
            moreColorsSwitchVisible: switchVisibility.isMoreColorsSwitchVisible,

            whiteSwitchDisabled: switchVisibility.isWhiteSwitchDisabled,
            disabled: switchVisibility.disabled,
            name: colorValue?.name || '',
            color: colorValue?.color || '',
            colorImg: '/files/color/' + (colorValue?.colorImg || ''),
        };
    }

    isWhiteSwitchVisible(
        colorControl: ColorControlDef,
        conf = this.configurationsService.conf.Current,
        availableColors: IccColor[] = []
    ) {
        if (colorControl.side !== 'inner') {
            return {
                whiteSwitchVisible: false,
                bilateralSwitchVisible: false,
            };
        }
        let material = 'pvc';
        if (WindowActiveConfiguration.is(conf)) {
            material = conf.System.type;
        }
        if (material !== 'pvc') {
            return  {
                whiteSwitchVisible: false,
                bilateralSwitchVisible: false
            };
        }
        const outerColor = this.newColorsService.getElementColor(conf, {
            element: colorControl.element,
            side: 'outer',
            profile: colorControl.profile,
            alt: colorControl.alt,
        });
        const innerColor = this.newColorsService.getElementColor(conf, {
            element: colorControl.element,
            side: 'inner',
            profile: colorControl.profile,
            alt: colorControl.alt,
        });

        const availableWhiteColor = this.newColorsService.getWhiteElementColor(conf, colorControl);

        if (!availableWhiteColor) {
            return {
                whiteSwitchVisible: false,
                bilateralSwitchVisible:
                    outerColor?.id !== innerColor?.id &&
                    availableColors?.some((c) => c.id === innerColor?.id),
            };
        }

        if (colorControl.element !== 'profile') {
            return {
                whiteSwitchVisible:
                    (outerColor?.id === innerColor?.id &&
                        innerColor?.coreColors.every(
                            (c) => !innerColor.constructColors[c].some((el) => el.type === 'white')
                        )) ||
                    outerColor?.coreColors.some((c) =>
                        outerColor.constructColors[c].some((el) => el.type === 'white')
                    ),
                bilateralSwitchVisible:
                    (outerColor?.id !== innerColor?.id &&
                        innerColor?.coreColors.some((c) =>
                            innerColor.constructColors[c].some((el) => el.type === 'white')
                        )) ||
                    (outerColor?.coreColors.some((c) =>
                        outerColor.constructColors[c].some((el) => el.type === 'white')
                    ) &&
                        availableColors?.some((c) => c.id === outerColor?.id)),
            };
        } else {
            const outerConstrColor = this.newColorsService.getElementConstrColor(
                conf,
                colorControl.element,
                'outer',
                colorControl.profile
            );
            const innerConstrColor = this.newColorsService.getElementConstrColor(
                conf,
                colorControl.element,
                'inner',
                colorControl.profile
            );

            // Wyświetlamy przycisk jeśli:
            // 1. Kolor zewnętrzny jest inny niż wewnętrzny
            // 2. Kolor zewnętrzny jest biały - po to, żeby użytkownik był świadomy, że może zmienić kolor
            // 3. Kolor wewnętrzny nie jest biały - po to, żeby użytkownik mógł zmienić kolor
            return {
                whiteSwitchVisible:
                    (outerColor?.id === innerColor?.id && innerConstrColor?.type !== 'white') ||
                    outerConstrColor?.type === 'white',
                bilateralSwitchVisible:
                    outerColor?.id !== innerColor?.id &&
                    (innerConstrColor?.type === 'white' || outerConstrColor?.type === 'white') &&
                    availableColors?.some((c) => c.id === outerColor?.id),
            };
        }
    }

    isWhiteColorButtonDisabled(
        element: ColoredElements,
        side?: ProfileColoredSides | ColoredSides,
        profile?: Profile,
        conf = this.configurationsService.conf.Current
    ) {
        if (element === 'profile' && side === 'inner') {
            const innerConstrColor = this.newColorsService.getElementConstrColor(
                conf,
                element,
                'inner',
                profile
            );
            return innerConstrColor?.type === 'white';
        }

        return false;
    }

    hasCoreColorSelect(
        conf = this.configurationsService.conf.Current,
        controlDef: ColorControlDef
    ) {
        let material = 'pvc';
        if (WindowActiveConfiguration.is(conf)) {
            material = conf.System.type;
        }
        if (material !== 'pvc') {
            return false;
        }
        if (WindowActiveConfiguration.is(conf) && conf.System?.door_type) {
            return false;
        }
        const outerColor = this.newColorsService.getElementColor(conf, {
            element: controlDef.element,
            side: 'outer',
            profile: controlDef.profile,
            alt: controlDef.alt,
        });

        const innerColor = this.newColorsService.getElementColor(conf, {
            element: controlDef.element,
            side: 'inner',
            profile: controlDef.profile,
            alt: controlDef.alt,
        });

        return !outerColor?.isCore && !innerColor?.isCore;
    }

    async openModalWood() {
        const wood = await this.woodService.openWoodPicker(this.pageData.profile.selectedWood);
        if (wood) {
            this.pageData.profile.selectedWood = core.copy(wood);
        }
    }

    openModalColor(colorControl: ColorControl) {
        this.newColorsService.openModalColorPicker(
            this.configurationsService.conf.Current,
            colorControl
        );
    }

    setBilateralColor(colorControl: ColorControl) {
        if (colorControl.side === 'inner') {
            this.newColorsService.setBilateralColor(
                this.configurationsService.conf.Current,
                colorControl
            );
        }
    }

    setWhiteColor(colorControl: ColorControl) {
        this.newColorsService.setWhiteColor(this.configurationsService.conf.Current, colorControl);
    }

    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    close() {
        this.pageData.selectedColors$.next(this.pageData.profile.selectedColor);
        this.sharedFacade.closePage({
            colors: this.pageData.profile.selectedColor,
            wood: this.pageData.profile.selectedWood,
        });
    }
}
