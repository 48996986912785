import { Component, OnInit, OnDestroy, Inject, NgZone } from '@angular/core';
import { _, StepComponent, isObject } from '@icc/configurator/shared';
import { WindowFacade } from '../+state/window.facade';
import { SubscriptionLike } from 'rxjs';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { FillingsFacade } from '../glazings/fillings.facade';
import {
    APP_CONFIG,
    AppConfigFactory,
    TranslateService,
    WindowActiveConfiguration,
    ProfilesService,
} from '@icc/common';
import { LippingColorsService } from '../profiles/lipping-colors.service';
import { IccColor } from '@icc/common/data-types';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { ColoredElements, ColoredSashes, ColoredSides, ProfileColoredSides } from './colored-elements';
import { NewColorsService } from './new-colors.service';
import { ColorControl, ColorControlDef } from './color-control';
import { ProfileColorsService } from '../profiles/profile-colors.service';

@Component({
    selector: 'icc-colors',
    templateUrl: './colors.component.html',
    styleUrls: ['./colors.component.scss'],
})
export class ColorsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Kolor');
    static stepIcon = {
        ligature: 'palette',
    };

    public configurator = 'window';
    public stepId = 'colors';
    public title = _('COLOR|Wybierz kolor:');
    public options = [
        {
            title: _('WINDOW|Kolory podstawowe'),
            callback: () => {
                this.mode = 'basic';
                this.newColorsService.setExtendedMode(false);
            },
            icon: {
                ligature: 'invert_colors',
            },
            show: () => this.mode === 'extended',
            hover: 'active',
        },
        {
            title: _('COLOR|Kolory zaawansowane'),
            callback: () => {
                this.mode = 'extended';
                this.newColorsService.setExtendedMode(true);
            },
            icon: {
                ligature: 'invert_colors',
            },
            show: () => this.mode === 'basic' && this.availableColorsSashExt(),
        },
    ];

    onlyDoubleSidedFrameColor = false;
    differentDoorColors = false;

    controls: ColorControl[] = [];
    controlsDef: Record<
        ColorsComponent['mode'],
        Record<'window' | 'profileDoor' | 'panelDoor', ColorControlDef[]>
    > = {
        basic: {
            window: [
                {
                    title: _('COLOR|Kolor zewnętrzny panelu'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'window',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny panelu'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'window',
                },
                {
                    title: _('COLOR|Kolor zewnętrzny'),
                    element: 'sash',
                    side: 'outer',
                },
                {
                    title: _('COLOR|Kolor nakładki aluminiowej'),
                    element: 'sash',
                    side: 'alushell',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny '),
                    element: 'sash',
                    side: 'inner',
                    whiteSwitch: true,
                },
                {
                    title: _('WINDOW|Kolor bazy'),
                    element: 'sash',
                    side: 'core',
                },
                {
                    title: _('COLOR|Kolor progu'),
                    element: 'threshold',
                },
            ],
            profileDoor: [
                {
                    title: _('COLOR|Kolor zewnętrzny skrzydła aktywnego'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'activeDoorSash',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny skrzydła aktywnego'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'activeDoorSash',
                },
                {
                    title: _('COLOR|Kolor zewnętrzny aplikacji skrzydła aktywnego'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'activeDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor wewnętrzny aplikacji skrzydła aktywnego'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'activeDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor zewnętrzny skrzydła biernego'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'passiveDoorSash',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny skrzydła biernego'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'passiveDoorSash',
                },
                {
                    title: _('COLOR|Kolor zewnętrzny aplikacji skrzydła biernego'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'passiveDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor wewnętrzny aplikacji skrzydła biernego'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'passiveDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor zewnętrzny panelu doświetla'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'window',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny panelu doświetla'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'window',
                },
                {
                    title: _('COLOR|Kolor zewnętrzny aplikacji panelu doświetla'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'window',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor wewnętrzny aplikacji panelu doświetla'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'window',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor zewnętrzny'),
                    element: 'sash',
                    side: 'outer',
                },
                {
                    title: _('COLOR|Kolor nakładki aluminiowej'),
                    element: 'sash',
                    side: 'alushell',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny '),
                    element: 'sash',
                    side: 'inner',
                    whiteSwitch: true,
                },
                {
                    title: _('WINDOW|Kolor bazy'),
                    element: 'sash',
                    side: 'core',
                },
                {
                    title: () =>
                        this.onlyDoubleSidedFrameColor
                            ? _('COLOR|Kolor ościeżnicy')
                            : _('COLOR|Kolor zewnętrzny ościeżnicy'),
                    element: 'frame',
                    side: 'outer',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny ościeżnicy'),
                    element: 'frame',
                    side: 'inner',
                    hide: () => this.onlyDoubleSidedFrameColor,
                },
                {
                    title: _('COLOR|Kolor progu'),
                    element: 'threshold',
                },
            ],
            panelDoor: [
                {
                    title: _('COLOR|Kolor drzwi'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'activeDoorSash',
                },
                {
                    title: _('COLOR|Kolor aplikacji drzwi'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'activeDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor skrzydła biernego'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'passiveDoorSash',
                },
                {
                    title: _('COLOR|Kolor aplikacji skrzydła biernego'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'passiveDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor panelu doświetla'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'window',
                },
                {
                    title: _('COLOR|Kolor aplikacji panelu doświetla'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'window',
                    alt: true,
                },
                {
                    title: () =>
                        !this.hasFrameInnerColorSelect()
                            ? _('COLOR|Kolor ościeżnicy')
                            : _('COLOR|Kolor zewnętrzny ościeżnicy'),
                    element: 'frame',
                    side: 'outer',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny ościeżnicy'),
                    element: 'frame',
                    side: 'inner',
                },
                {
                    title: _('COLOR|Kolor progu'),
                    element: 'threshold',
                },
                {
                    title: () =>
                        !this.hasLippingInnerColor()
                            ? _('COLOR|Kolor listwy mocującej')
                            : _('COLOR|Kolor zewnętrzny listwy mocującej'),
                    element: 'lipping',
                    side: 'outer',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny listwy mocującej'),
                    element: 'lipping',
                    side: 'inner',
                },
                {
                    title: _('DOOR|Kolor portalu'),
                    element: 'doorPortal',
                },
            ],
        },
        extended: {
            window: [
                {
                    title: _('COLOR|Kolor zewnętrzny panelu'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'window',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny panelu'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'window',
                },
                {
                    title: _('COLOR|Kolor zewnętrzny skrzydła'),
                    element: 'sash',
                    side: 'outer',
                },
                {
                    title: _('COLOR|Kolor nakładki aluminiowej skrzydła'),
                    element: 'sash',
                    side: 'alushell',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny skrzydła'),
                    element: 'sash',
                    side: 'inner',
                    whiteSwitch: true,
                },
                {
                    title: _('WINDOW|Kolor bazy skrzydła'),
                    element: 'sash',
                    side: 'core',
                },
                {
                    title: _('COLOR|Kolor zewnętrzny ościeżnicy'),
                    element: 'frame',
                    side: 'outer',
                },
                {
                    title: _('COLOR|Kolor nakładki aluminiowej ościeżnicy'),
                    element: 'frame',
                    side: 'alushell',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny ościeżnicy'),
                    element: 'frame',
                    side: 'inner',
                    whiteSwitch: true,
                },
                {
                    title: _('WINDOW|Kolor bazy ościeżnicy'),
                    element: 'frame',
                    side: 'core',
                },
                {
                    title: _('COLOR|Kolor progu'),
                    element: 'threshold',
                },
            ],
            profileDoor: [
                {
                    title: _('COLOR|Kolor zewnętrzny skrzydła aktywnego'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'activeDoorSash',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny skrzydła aktywnego'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'activeDoorSash',
                },
                {
                    title: _('COLOR|Kolor zewnętrzny aplikacji skrzydła aktywnego'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'activeDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor wewnętrzny aplikacji skrzydła aktywnego'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'activeDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor zewnętrzny skrzydła biernego'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'passiveDoorSash',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny skrzydła biernego'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'passiveDoorSash',
                },
                {
                    title: _('COLOR|Kolor zewnętrzny aplikacji skrzydła biernego'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'passiveDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor wewnętrzny aplikacji skrzydła biernego'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'passiveDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor zewnętrzny panelu doświetla'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'window',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny panelu doświetla'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'window',
                },
                {
                    title: _('COLOR|Kolor zewnętrzny aplikacji panelu doświetla'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'window',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor wewnętrzny aplikacji panelu doświetla'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'window',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor zewnętrzny profilu skrzydła'),
                    element: 'sash',
                    side: 'outer',
                },
                {
                    title: _('COLOR|Kolor nakładki aluminiowej profilu skrzydła'),
                    element: 'sash',
                    side: 'alushell',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny profilu skrzydła'),
                    element: 'sash',
                    side: 'inner',
                    whiteSwitch: true,
                },
                {
                    title: _('WINDOW|Kolor bazy profilu skrzydła'),
                    element: 'sash',
                    side: 'core',
                },
                {
                    title: () =>
                        this.onlyDoubleSidedFrameColor
                            ? _('COLOR|Kolor ościeżnicy')
                            : _('COLOR|Kolor zewnętrzny ościeżnicy'),
                    element: 'frame',
                    side: 'outer',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny ościeżnicy'),
                    element: 'frame',
                    side: 'inner',
                },
                {
                    title: _('COLOR|Kolor progu'),
                    element: 'threshold',
                },
            ],
            panelDoor: [
                {
                    title: _('COLOR|Kolor zewnętrzny drzwi'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'activeDoorSash',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny drzwi'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'activeDoorSash',
                },
                {
                    title: _('COLOR|Kolor zewnętrzny aplikacji drzwi'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'activeDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor wewnętrzny aplikacji drzwi'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'activeDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor zewnętrzny skrzydła biernego'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'passiveDoorSash',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny skrzydła biernego'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'passiveDoorSash',
                },
                {
                    title: _('COLOR|Kolor zewnętrzny aplikacji skrzydła biernego'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'passiveDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor wewnętrzny aplikacji skrzydła biernego'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'passiveDoorSash',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor zewnętrzny panelu doświetla'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'window',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny panelu doświetla'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'window',
                },
                {
                    title: _('COLOR|Kolor zewnętrzny aplikacji panelu doświetla'),
                    element: 'filling',
                    side: 'outer',
                    sash: 'window',
                    alt: true,
                },
                {
                    title: _('COLOR|Kolor wewnętrzny aplikacji panelu doświetla'),
                    element: 'filling',
                    side: 'inner',
                    sash: 'window',
                    alt: true,
                },
                {
                    title: () =>
                        this.onlyDoubleSidedFrameColor
                            ? _('COLOR|Kolor ościeżnicy')
                            : _('COLOR|Kolor zewnętrzny ościeżnicy'),
                    element: 'frame',
                    side: 'outer',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny ościeżnicy'),
                    element: 'frame',
                    side: 'inner',
                },
                {
                    title: _('COLOR|Kolor progu'),
                    element: 'threshold',
                },
                {
                    title: () =>
                        !this.hasLippingInnerColor()
                            ? _('COLOR|Kolor listwy mocującej')
                            : _('COLOR|Kolor zewnętrzny listwy mocującej'),
                    element: 'lipping',
                    side: 'outer',
                },
                {
                    title: _('COLOR|Kolor wewnętrzny listwy mocującej'),
                    element: 'lipping',
                    side: 'inner',
                },
                {
                    title: _('DOOR|Kolor portalu'),
                    element: 'doorPortal',
                },
            ],
        },
    };

    mode: 'basic' | 'extended' = 'basic';
    private subscriptions: SubscriptionLike[] = [];

    // eslint-disable-next-line max-params
    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private windowFacade: WindowFacade,
        private fillingsFacade: FillingsFacade,
        private newColorsService: NewColorsService,
        private configurationsService: ConfigurationsService<'window'>,
        private translateService: TranslateService,
        private lippingColorsService: LippingColorsService,
        private profilesService: ProfilesService,
        private profileColorsService: ProfileColorsService,
        private ngZone: NgZone,
    ) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(
            this.windowFacade.colorSashExt$.subscribe((colorSashExt) => {
                if (colorSashExt && this.mode !== 'extended') {
                    this.mode = 'extended';
                } else if (!colorSashExt && this.mode === 'extended') {
                    this.mode = 'basic';
                }
            })
        );

        this.subscriptions.push(
            this.fillingsFacade.hasDifferentColors$.subscribe((differentColors) => {
                this.differentDoorColors =
                    Boolean(differentColors) &&
                    this.configurationsService.conf.Current.type === 'door' &&
                    this.configurationsService.conf.Current.System.door_type;
                if (this.differentDoorColors) {
                    this.mode = 'extended';
                }
            })
        );

        this.subscriptions.push(
            this.configurationsService.configuration$.subscribe((conf) => {
                this.onlyDoubleSidedFrameColor = this.newColorsService.onlyDoubleSidedFrameColor(
                    conf
                );
                this.updateControls(conf);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    updateControls(conf = this.configurationsService.conf.Current) {
        const confType = this.getConfType(conf);
        const controls: ColorControl[] = [];
        for (const controlDef of this.controlsDef[this.mode][confType]) {
            if (this.shouldSkipControl(conf, controlDef)) {
                continue;
            }

            const colorValue = this.newColorsService.getElementColor(conf, controlDef);
            const availableColors = this.newColorsService.getMatchingColorsFor(conf, controlDef);
            const switchVisibility = this.getSwitchVisibility(availableColors, controlDef, conf);
            if(controlDef.side === 'core' && !availableColors.length) {
                continue;
            }
            const control = this.createControl(controlDef, switchVisibility, colorValue);
            controls.push(control);
        }
        this.controls = controls;
    }

    getConfType(conf = this.configurationsService.conf.Current) {
        let confType: 'window' | 'profileDoor' | 'panelDoor' =
            conf.type === 'door' ? 'profileDoor' : 'window';
        if (conf.System.door_type) {
            confType = 'panelDoor';
        }
        return confType;
    }

    shouldSkipControl(conf = this.configurationsService.conf.Current, controlDef: ColorControlDef) {
        if (controlDef.hide && controlDef.hide()) {
            return true;
        }
        if (controlDef.side === 'alushell' && (!conf.HasAlushell || conf.System?.type === 'alu')) {
            return true;
        }
        if (conf.HasAlushell && controlDef.side === 'outer') {
            return true;
        }

        if (conf.type === 'door' && controlDef.element === 'sash' && this.hasDecoFillingElement(conf) && this.mode === 'basic') {
            return true;
        }

        if (conf.type === 'door' && controlDef.element === 'frame' && !this.hasDecoFillingElement(conf) && this.mode === 'basic') {
            return true;
        }

        if (controlDef.element === 'frame' && !this.hasFrameColorSelect(conf)) {
            return true;
        }

        if (
            controlDef.element === 'frame' &&
            controlDef.side === 'inner' &&
            !this.hasFrameInnerColorSelect(conf)
        ) {
            return true;
        }

        if (controlDef.element === 'threshold' && !this.hasThresholdColorSelect(conf)) {
            return true;
        }
        if (controlDef.side === 'core' && !this.hasCoreColorSelect(conf, controlDef)) {
            return true;
        }
        if (controlDef.element === 'lipping' && !conf.System.available_lipping_color) {
            return true;
        }
        if (
            controlDef.element === 'lipping' &&
            controlDef.side === 'inner' &&
            !this.hasLippingInnerColor(conf)
        ) {
            return true;
        }
        if (controlDef.element === 'doorPortal' && !conf.doorPortal?.id) {
            return true;
        }

        if (controlDef.element === 'filling' && !this.hasFillingElement(conf)) {
            return true;
        }
        if (this.shouldSkipFilling(conf, controlDef)) {
            return true;
        }

        return false;
    }

    shouldSkipFilling(conf: WindowActiveConfiguration, controlDef: ColorControlDef) {
        if (controlDef.element === 'filling') {
            if (controlDef.sash === 'activeDoorSash' && !this.hasDoorActiveSash(conf)) {
                return true;
            }

            if (controlDef.sash === 'passiveDoorSash' && !this.hasDoorPassiveSash(conf)) {
                return true;
            }

            if (controlDef.sash === 'window' && !this.hasWindowSashWithPanel(conf)) {
                return true;
            }

            const activeSash = isObject(controlDef.sash)
                ? controlDef.sash
                : this.newColorsService.getColoredSash(conf, controlDef.sash ?? 'activeDoorSash');

            if (!activeSash) {
                return true;
            }

            if (controlDef.side === 'inner' && !this.hasInnerFillingColor(conf, activeSash)) {
                return true;
            }

            if (
                controlDef.alt &&
                !this.hasAltFillingColor(conf, activeSash, controlDef.side ?? 'outer')
            ) {
                return true;
            }
        }
        return false;
    }

    getSwitchVisibility(
        availableColors: IccColor[],
        controlDef: ColorControlDef,
        conf: WindowActiveConfiguration
    ) {
        let isWhiteSwitchVisible = false;
        let isWhiteSwitchDisabled = true;
        let isBilateralSwitchVisible = false;
        let isMoreColorsSwitchVisible = false;
        const disabled = !availableColors?.length || availableColors?.length <= 1;
        if (controlDef.side === 'inner' && conf.System.type === 'pvc' && !conf.HasAlushell) {
            const switchVisibility = this.isWhiteSwitchVisible(controlDef, conf, availableColors);
            isWhiteSwitchVisible = switchVisibility.whiteSwitchVisible ?? false;
            isBilateralSwitchVisible = switchVisibility.bilateralSwitchVisible ?? false;
            isMoreColorsSwitchVisible = !disabled;

            isWhiteSwitchDisabled = this.isWhiteColorButtonDisabled(
                controlDef.element,
                controlDef.side,
                controlDef.sash,
                controlDef.alt,
                conf
            );
        }
        return {
            isWhiteSwitchVisible,
            isBilateralSwitchVisible,
            isMoreColorsSwitchVisible,
            isWhiteSwitchDisabled,
            disabled,
        };
    }

    createControl(
        controlDef: ColorControlDef,
        switchVisibility: {
            isWhiteSwitchVisible: boolean;
            isBilateralSwitchVisible: boolean;
            isMoreColorsSwitchVisible: boolean;
            isWhiteSwitchDisabled: boolean;
            disabled: boolean;
        },
        colorValue: IccColor | null
    ) {
        return {
            title: typeof controlDef.title === 'function' ? controlDef.title() : controlDef.title,
            element: controlDef.element,
            side: controlDef.side,
            sash: controlDef.sash,
            profile: controlDef.profile,
            accessory: controlDef.accessory,
            alt: controlDef.alt,
            whiteSwitchVisible: switchVisibility.isWhiteSwitchVisible,
            bilateralSwitchVisible: switchVisibility.isBilateralSwitchVisible,
            moreColorsSwitchVisible: switchVisibility.isMoreColorsSwitchVisible,

            whiteSwitchDisabled: switchVisibility.isWhiteSwitchDisabled,
            disabled: switchVisibility.disabled,
            name: colorValue?.name || '',
            color: colorValue?.color || '',
            colorImg: '/files/color/' + (colorValue?.colorImg || ''),
        };
    }

    isWhiteSwitchVisible(
        colorControl: ColorControlDef,
        conf = this.configurationsService.conf.Current,
        availableColors: IccColor[] = []
    ) {
        if (colorControl.side !== 'inner') {
            return {
                whiteSwitchVisible: false,
                bilateralSwitchVisible: false,
            };
        }
        if (conf.System.type !== 'pvc') {
            return {
                whiteSwitchVisible: false,
                bilateralSwitchVisible: false,
            };
        }
        const outerColor = this.newColorsService.getElementColor(conf, {
            element: colorControl.element,
            side: 'outer',
            sash: colorControl.sash,
            alt: colorControl.alt,
        });
        const innerColor = this.newColorsService.getElementColor(conf, {
            element: colorControl.element,
            side: 'inner',
            sash: colorControl.sash,
            alt: colorControl.alt,
        });

        const availableWhiteColor = this.newColorsService.getWhiteElementColor(conf, colorControl);

        if (!availableWhiteColor) {
            return {
                whiteSwitchVisible: false,
                bilateralSwitchVisible:
                    outerColor?.id !== innerColor?.id &&
                    availableColors?.some((c) => c.id === innerColor?.id),
            };
        }

        if (colorControl.element !== 'frame' && colorControl.element !== 'sash') {
            // Wyświetlamy przycisk jeśli:
            // 1. Kolor zewnętrzny jest inny niż wewnętrzny
            // 2. Kolor zewnętrzny jest biały - po to, żeby użytkownik był świadomy, że może zmienić kolor
            // 3. Kolor wewnętrzny nie jest biały - po to, żeby użytkownik mógł zmienić kolor
            return {
                whiteSwitchVisible:
                    (outerColor?.id === innerColor?.id &&
                        innerColor?.coreColors.every(
                            (c) => !innerColor.constructColors[c].some((el) => el.type === 'white')
                        )) ||
                    outerColor?.coreColors.some((c) =>
                        outerColor.constructColors[c].some((el) => el.type === 'white')
                    ),
                bilateralSwitchVisible:
                    (outerColor?.id !== innerColor?.id &&
                        (innerColor?.coreColors.some((c) =>
                            innerColor.constructColors[c].some((el) => el.type === 'white')
                        ) ||
                    outerColor?.coreColors.some((c) =>
                        outerColor.constructColors[c].some((el) => el.type === 'white')
                    )) &&
                        availableColors?.some((c) => c.id === outerColor?.id)),
            };
        } else {
            const outerConstrColor = this.newColorsService.getElementConstrColor(
                conf,
                colorControl.element,
                'outer'
            );
            const innerConstrColor = this.newColorsService.getElementConstrColor(
                conf,
                colorControl.element,
                'inner'
            );

            // Wyświetlamy przycisk jeśli:
            // 1. Kolor zewnętrzny jest inny niż wewnętrzny
            // 2. Kolor zewnętrzny jest biały - po to, żeby użytkownik był świadomy, że może zmienić kolor
            // 3. Kolor wewnętrzny nie jest biały - po to, żeby użytkownik mógł zmienić kolor
            return {
                whiteSwitchVisible:
                    (outerColor?.id === innerColor?.id && innerConstrColor?.type !== 'white') ||
                    outerConstrColor?.type === 'white',
                bilateralSwitchVisible:
                    outerColor?.id !== innerColor?.id &&
                    (innerConstrColor?.type === 'white' || outerConstrColor?.type === 'white') &&
                    availableColors?.some((c) => c.id === outerColor?.id),
            };
        }
    }

    isWhiteColorButtonDisabled(
        element: ColoredElements,
        side?: ProfileColoredSides | ColoredSides,
        sash?: ColoredSashes,
        alt?: boolean,
        conf = this.configurationsService.conf.Current
    ) {
        if (side === 'inner') {
            if ((element === 'frame' || element === 'sash')) {
                const innerConstrColor = this.newColorsService.getElementConstrColor(
                    conf,
                    element,
                    'inner'
                );
                return innerConstrColor?.type === 'white';
            } else {
                const innerColor = this.newColorsService.getElementColor(conf, {
                    element,
                    sash,
                    alt,
                    side: 'inner',
                });
                return innerColor?.coreColors.some((c) =>
                    innerColor.constructColors[c].some((el) => el.type === 'white')
                ) || false;
            }
        }

        return false;
    }

    openModalColor(colorControl: ColorControl) {
        this.ngZone.run(() => {
            this.newColorsService.openModalColorPicker(
            this.configurationsService.conf.Current,
            colorControl
        )
            });
    }

    setBilateralColor(colorControl: ColorControl) {
        this.ngZone.run(() => {
            if (colorControl.side === 'inner') {
                this.newColorsService.setBilateralColor(
                    this.configurationsService.conf.Current,
                    colorControl
                );
            }
        });
    }

    setWhiteColor(colorControl: ColorControl) {
        this.ngZone.run(() => {
            this.newColorsService.setWhiteColor(this.configurationsService.conf.Current, colorControl);
        });
    }

    availableColorsSashExt() {
        const hasInnerFillingColor =  this.configurationsService.conf.Current.Sashes.some(sash => this.hasInnerFillingColor(this.configurationsService.conf.Current, sash));
        const hasFrameInnerColorSelect = this.hasFrameInnerColorSelect(this.configurationsService.conf.Current, true);
        return (
            (this.configurationsService.conf.Current.System.different_sash_color &&
                this.config().IccConfig.Configurators.selectSashFrameColor &&
                !this.configurationsService.conf.Current.System.door_type) ||
            (this.configurationsService.conf.Current.type === 'door' &&
                this.configurationsService.conf.Current.System.door_type &&
                (hasInnerFillingColor || hasFrameInnerColorSelect))
        );
    }

    hasCoreColorSelect(
        conf = this.configurationsService.conf.Current,
        controlDef: ColorControlDef
    ) {
        if (conf.System?.type !== 'pvc') {
            return false;
        }
        if (conf.System?.door_type) {
            return false;
        }
        const outerColor = this.newColorsService.getElementColor(conf, {
            element: controlDef.element,
            side: 'outer',
            sash: controlDef.sash,
            alt: controlDef.alt,
        });

        const innerColor = this.newColorsService.getElementColor(conf, {
            element: controlDef.element,
            side: 'inner',
            sash: controlDef.sash,
            alt: controlDef.alt,
        });

        return !outerColor?.isCore && !innerColor?.isCore;
    }

    hasFillingElement(conf = this.configurationsService.conf.Current) {
        return conf.Sashes.some(
            (s) =>
                s.glazing?.type &&
                ['door_panels', 'deco_panels', 'pvc_panels'].includes(s.glazing.type)
        );
    }

    hasDecoFillingElement(conf = this.configurationsService.conf.Current) {
        return conf.Sashes.some(
            (s) =>
                s.glazing?.type &&
                ['door_panels', 'deco_panels'].includes(s.glazing.type)
        );
    }

    hasDoorActiveSash(conf = this.configurationsService.conf.Current) {
        return conf.Sashes.some(
            (s) =>
                (s.type?.type === 'DRA' || s.type?.type === 'DOA') &&
                s.glazing?.type &&
                ['door_panels', 'deco_panels', 'pvc_panels'].includes(s.glazing.type)
        );
    }

    hasDoorPassiveSash(conf = this.configurationsService.conf.Current) {
        return conf.Sashes.some(
            (s) =>
                (s.type?.type === 'DRP' || s.type?.type === 'DOP') &&
                s.glazing?.type &&
                ['door_panels', 'deco_panels', 'pvc_panels'].includes(s.glazing.type)
        );
    }

    hasWindowSashWithPanel(conf = this.configurationsService.conf.Current) {
        return conf.Sashes.some(
            (s) =>
                s.type?.type !== 'DRA' &&
                s.type?.type !== 'DRP' &&
                s.type?.type !== 'DOA' &&
                s.type?.type !== 'DOP' &&
                s.glazing?.type &&
                ['door_panels', 'deco_panels', 'pvc_panels'].includes(s.glazing.type)
        );
    }

    hasInnerFillingColor(conf = this.configurationsService.conf.Current, sash: ActiveSash) {
        return (
            !this.newColorsService.isDoubleSidedColorAvailable(conf) ||
            (conf.System.door_type && sash?.panelType === 'Mixed')
        );
    }

    hasAltFillingColor(
        conf = this.configurationsService.conf.Current,
        sash: ActiveSash,
        side: ColoredSides | ProfileColoredSides
    ) {
        if (side === 'inner') {
            return (
                sash.panelInner?.type === 'door_panels' &&
                sash.panelInner.available_second_inside_color
            );
        }
        return (
            sash.glazing &&
            sash.glazing.type === (conf.System.door_type ? 'door_panels' : 'deco_panels') &&
            sash.glazing.available_second_color
        );
    }

    hasFrameColorSelect(conf = this.configurationsService.conf.Current) {
        if (conf.type !== 'door') {
            return true;
        }
        return !this.hasDecoFillingElement(conf) || conf.System.separate_frame_and_sash_color;
    }

    hasFrameInnerColorSelect(conf = this.configurationsService.conf.Current, skipMode = false) {
        if (conf.type !== 'door') {
            return true;
        }

        const outerColor = this.newColorsService.getElementColor(conf, {
            element: 'frame',
            side: 'outer',
        });

        const innerColor = this.newColorsService.getElementColor(conf, {
            element: 'frame',
            side: 'inner',
        });


        return (
            !this.hasDecoFillingElement(conf) ||
            (conf.System.separate_frame_and_sash_color &&
                !this.newColorsService.onlyDoubleSidedFrameColor(conf) &&
                (!conf.System.door_type ||
                    (conf.System.door_type &&
                    (this.mode === 'extended' || skipMode || outerColor?.id !== innerColor?.id)))) ||
            conf.HasAlushell
        );
    }

    hasLippingInnerColor(conf = this.configurationsService.conf.Current) {
        if (conf.System.lipping_color_based_on_frame_color) {
            return (
                conf.System.available_lipping_in_bicolor &&
                (!conf.System.door_type || (conf.System.door_type && this.mode === 'extended'))
            );
        }

        if (conf.System.lipping_price_level_id && this.mode === 'extended') {
            return !this.lippingColorsService.isOnlyDoubleSidePriceLevelAvailable();
        }
    }

    hasThresholdColorSelect(conf: WindowActiveConfiguration) {
        const profile = this.profilesService.getProfile(
            this.profilesService.getUsedThresholdId(conf)
        );
        return profile && profile.priceLevelId !== null;
    }
}
