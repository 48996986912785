import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorsService } from './colors.service';
import { DimensionsService } from './dimensions.service';
import { MontagesService } from './montages.service';
import { SystemsService } from './systems.service';
import { TypesService } from './types.service';

@NgModule({
    declarations: [],
    imports: [ CommonModule ],
    exports: [
    ],
    providers: [
        ColorsService,
        DimensionsService,
        MontagesService,
        SystemsService,
        TypesService,
    ],
})
export class MosquitoModule {}
