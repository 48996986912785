/**
 * Funkcja zwracajca dyrektywę
 */
export default function IcChildScopeDirective() {
    'ngInject';

    var directive = {
        restrict   : 'A',
        scope: true,
    };

    return directive;
}
