import angular from 'angular';

export default function HandleCatFilter() {
    'ngInject';

    return function(glass, categoryId, subcatId) {
        var glassArr = [];
        angular.forEach(glass, function(obj) {
            glassArr.push(obj);
        });
        if (angular.isUndefined(categoryId) || categoryId == null) {
            return glass;
        } else {
            return glassArr.filter(function(gl) {
                if (gl.window_accessories_parent_id == categoryId) {
                    if (angular.isUndefined(subcatId) || subcatId == null) {
                        return true;
                    } else if (gl.window_accessories_category_id == subcatId) {
                        return true;
                    }
                }
                return false;
            });
        }
    };
}
