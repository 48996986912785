import { GarageDoorInitService } from './garage-door-init.service';
import { GarageDoorFacade } from './garage-door.facade';
import { Injectable, OnDestroy, Inject } from '@angular/core';
import { APP_CONFIG, AppConfigFactory, ConfigurationsService, EventBusService } from '@icc/common';
import { GarageDoorDimensions } from '@icc/common/configurations/parts/garage_door';

@Injectable()
export class GarageDoorDefaultsService implements OnDestroy{
    private subscriptions: any[] = [];
    allDefaultValues;
    selectedDefaultValues;
    systems = [];
    defaultDimensions: GarageDoorDimensions = {
        width: 2500,
        height: 2150,
        lintelHeight: 150,
        garageDepth: null,
        leftJambWidth: 150,
        rightJambWidth: 150,
        guidanceLift: 0
    };
    mechanisms = [];
    guidances = [];
    panels = [];
    panelStructures = [];
    drives = [];
    wicketDoors = [];
    colors = [];
    colorGroups = [];
    insertGlazings = [];

    selectedSystem;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private garageDoorFacade: GarageDoorFacade,
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService,
    ) {
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            const conf = this.configurationsService.conf.Current;
            if (conf.type === 'garage_door') {
                this.subscriptions.push(
                    this.garageDoorFacade.availableDefaults$.subscribe(selectedDefaultValues => {
                        this.allDefaultValues = selectedDefaultValues;
                    }),
                    this.garageDoorFacade.availableSystems$.subscribe(systems => {
                        this.systems = systems;
                    }),
                    this.garageDoorFacade.availableMechanisms$.subscribe(mechanisms => {
                        this.mechanisms = mechanisms;
                    }),
                    this.garageDoorFacade.availableGuidances$.subscribe(guidances => {
                        this.guidances = guidances;
                    }),
                    this.garageDoorFacade.availablePanels$.subscribe(panels => {
                        this.panels = panels;
                    }),
                    this.garageDoorFacade.availablePanelStructures$.subscribe(panelStructures => {
                        this.panelStructures = panelStructures;
                    }),
                    this.garageDoorFacade.availableDrives$.subscribe(drives => {
                        this.drives = drives;
                    }),
                    this.garageDoorFacade.availableWicketDoors$.subscribe(wicketDoors => {
                        this.wicketDoors = wicketDoors;
                    }),
                    this.garageDoorFacade.availableColors$.subscribe(colors => {
                        this.colors = colors;
                    }),
                    this.garageDoorFacade.availableInsertGlazings$.subscribe(glazings => {
                        this.insertGlazings = glazings;
                    }),
                    this.garageDoorFacade.selectedSystem$.subscribe(system => {
                        this.selectedSystem = system;
                        const defaults = this.getMatchingDefaultValuesForSystem();
                        this.selectedDefaultValues = defaults;
                    })
                );
            }
        });
    }

    getMatchingDefaultValuesForSystem() {
        return this.allDefaultValues
                && (this.allDefaultValues.find(d => this.selectedSystem && this.selectedSystem.id == d.window_line_id)
                    || this.allDefaultValues.find(d => d.window_line_id == -1));
    }

    getMinWidth() {
        return this.selectedDefaultValues && Number(this.selectedDefaultValues.min_width);
    }

    getMaxWidth() {
        return this.selectedDefaultValues && Number(this.selectedDefaultValues.max_width);
    }

    getMinHeight() {
        return this.selectedDefaultValues && Number(this.selectedDefaultValues.min_height);
    }

    getMaxHeight() {
        return this.selectedDefaultValues && Number(this.selectedDefaultValues.max_height);
    }

    getMinLintelHeight() {
        return this.selectedDefaultValues && Number(this.selectedDefaultValues.min_lintel_height);
    }

    getMaxLintelHeight() {
        return this.selectedDefaultValues && Number(this.selectedDefaultValues.max_lintel_height);
    }

    getDefaultSystem() {
        return this.systems[0];
    }

    getDefaultDimensions() {
        let dimensions = this.defaultDimensions;
        if (this.selectedDefaultValues) {
            if (this.selectedDefaultValues.width)
                dimensions.width = Number(this.selectedDefaultValues.width);
            if (this.selectedDefaultValues.height)
                dimensions.height = Number(this.selectedDefaultValues.height);
            if (this.selectedDefaultValues.lintel_height)
                dimensions.lintelHeight = Number(this.selectedDefaultValues.lintel_height);
        }
        return dimensions;
    }

    getDefaultMechanism() {
        return (this.selectedDefaultValues && this.mechanisms.find(m => m.id == this.selectedDefaultValues.garage_door_mechanism_id)) || this.mechanisms[0];
    }

    getDefaultGuidance() {
        return (this.selectedDefaultValues && this.guidances.find(g => g.id == this.selectedDefaultValues.garage_door_guide_id)) || this.guidances[0];
    }

    getDefaultPanel() {
        return (this.selectedDefaultValues && this.panels.find(p => p.id == this.selectedDefaultValues.garage_door_panel_id)) || this.panels[0];
    }

    getDefaultPanelInternal() {
        return (this.selectedDefaultValues && this.panels.find(p => p.id == this.selectedDefaultValues.garage_door_panel_internal_id)) || this.panels[0];
    }

    getDefaultPanelStructure() {
        return (this.selectedDefaultValues && this.panelStructures.find(s => s.id == this.selectedDefaultValues.garage_door_panel_structure_id_outer)) || this.panelStructures[0];
    }

    getDefaultPanelInternalStructure() {
        return (this.selectedDefaultValues && this.panelStructures.find(s => s.id == this.selectedDefaultValues.garage_door_panel_structure_id_inner)) || this.panelStructures[0];
    }

    getDefaultPanelColor() {
        return (this.selectedDefaultValues && this.colors.find(c => c.id == this.selectedDefaultValues.garage_door_color_id_outer)) || this.colors[0];
    }

    getDefaultPanelInternalColor() {
        return (this.selectedDefaultValues && this.colors.find(c => c.id == this.selectedDefaultValues.garage_door_color_id_inner)) || this.colors[0];
    }

    getDefaultPanelInsertsOffset(): number {
        return Number(this.selectedDefaultValues && this.selectedDefaultValues.min_inserts_offset) || 250;
    }

    getDefaultPanelInsertsSpacing(): number {
        return Number(this.selectedDefaultValues && this.selectedDefaultValues.min_inserts_spacing) || 250;
    }

    getDefaultWindowsGlazing() {
        const windowGlazings = this.insertGlazings.filter(g => g.insert_type === 'window')
        return windowGlazings && windowGlazings[0];
    }

    getDefaultGlazingPanelsGlazing() {
        const glazingPanelGlazings = this.insertGlazings.filter(g => g.insert_type === 'glazing')
        return glazingPanelGlazings && glazingPanelGlazings[0];
    }

    getDefaultGlazingPanelImpostWidth() {
        return 35;
    }

    getMinGlazingPanelImpostsSpacing(){
        return 250;
    }

    getMinPanelHeightForInserts(): number {
        return 400;
    }

    getDefaultDrive() {
        return (this.selectedDefaultValues && this.drives.find(d => d.id == this.selectedDefaultValues.garage_door_control_id)) || this.drives[0];
    }

    getDefaultWicketDoor() {
        return this.wicketDoors[0];
    }

    getDefaultWicketDoorSide() {
        return 'right';
    }

    getDefaultWicketDoorHandleColor() {
        return this.colors[0];
    }

    getDefaultWicketDoorFrameProfilesColor() {
        return this.colors[0];
    }

    getMinWicketDoorOffset(): number {
        return this.selectedDefaultValues && Number(this.selectedDefaultValues.min_wicket_door_offset) || 978;
    }

    getWicketDoorShift(): number {
        return this.selectedDefaultValues && Number(this.selectedDefaultValues.wicket_door_shift) || 333;
    }

    getMinWicketDoorDistanceFromWindow(): number {
        return this.config().IccConfig.Configurators.garage_door.minWicketDoorDistanceFromWindow;
    }

    getDefaultWindowFrameOuterColor() {
        return (this.selectedDefaultValues && this.colors.find(c => c.id == this.selectedDefaultValues.window_frame_outer_color_id)) || this.colors[0];
    }

    getDefaultGlazingPanelsColor() {
        return (this.selectedDefaultValues && this.colors.find(c => c.id == this.selectedDefaultValues.glazing_panels_color_id)) || this.colors[0];
    }

    getDefaultVentilationGrateOuterColor() {
        return (this.selectedDefaultValues && this.colors.find(c => c.id == this.selectedDefaultValues.ventilation_grate_outer_color_id)) || this.colors[0];
    }

    setDefaultValues() {
        const dimensions = this.getDefaultDimensions();
        this.garageDoorFacade.setDimensions(dimensions);

        const defaultMechanism = this.getDefaultMechanism();
        this.garageDoorFacade.setMechanism(defaultMechanism);

        const defaultGuidance = this.getDefaultGuidance();
        this.garageDoorFacade.setGuidance(defaultGuidance);

        const defaultPanel = this.getDefaultPanel();
        this.garageDoorFacade.setPanel(defaultPanel);

        const defaultPanelInternal = this.getDefaultPanelInternal();
        this.garageDoorFacade.setPanelInternal(defaultPanelInternal);

        const defaultPanelStructure = this.getDefaultPanelStructure();
        this.garageDoorFacade.setPanelInternalStructure(defaultPanelStructure);

        const defaultPanelInternalStructure = this.getDefaultPanelInternalStructure();
        this.garageDoorFacade.setPanelInternalStructure(defaultPanelInternalStructure);

        const defaultPanelColor = this.getDefaultPanelColor();
        this.garageDoorFacade.setPanelColor(defaultPanelColor);

        const defaultPanelInternalColor = this.getDefaultPanelInternalColor();
        this.garageDoorFacade.setPanelInternalColor(defaultPanelInternalColor);

        const defaultDrive = this.getDefaultDrive();
        this.garageDoorFacade.setDrive(defaultDrive);

        this.garageDoorFacade.setDriveType('manual');
        this.garageDoorFacade.setDriveSide('left');
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }
}
