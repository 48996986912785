// JQuery
require('expose-loader?$!jquery');
require('expose-loader?jQuery!jquery');
require('@icc/legacy-libs/jquery.override');
require('jquery-ui');
require('jquery-ui-touch-punch');
require('jquery.cookie');

// AngularJS
import 'angular';
import * as angularRoute from 'angular-route';
import * as angularResource from 'angular-resource';
import * as angularCookies from 'angular-cookies';
import * as angularSanitize from 'angular-sanitize';
import * as angularTouch from 'angular-touch';
import 'angular-bind-notifier';

// AngularJS UI
import * as angularUiBootstrap from 'angular-ui-bootstrap';
import uiSelect from 'ui-select';

// AngularJS-translate MessageFormat
const dynamicLocale = require('angular-dynamic-locale');
import 'messageformat';
import '@icc/legacy-libs/angular-translate';
import 'angular-translate-loader-static-files';
import 'angular-translate-interpolation-messageformat';
import 'angular-translate-handler-log';

import * as ngMap from '@icc/legacy-libs/ng-map.js';
import 'angular-hotkeys';
import 'angular-carousel';
require('angular-hotkeys/build/hotkeys.min.css');
require('angular-carousel/dist/angular-carousel.min.css');
import 'angular-local-storage';
import '@icc/legacy-libs/angular-inarray-filter';
import '@icc/legacy-libs/angular-file-model';
import '@icc/legacy-libs/svgDirs';
import '@icc/legacy-libs/extra/flashMessage';
import 'angularjs-slider';
import ngInfiniteScroll from 'ng-infinite-scroll';
require('bootstrap');

import PerfectScrollbar from 'perfect-scrollbar';

const vendorModules = [
    angularRoute,
    'ngLocale',
    dynamicLocale,
    angularCookies,
    angularResource,
    angularSanitize,
    angularTouch,
    angularUiBootstrap,
    'angular.bind.notifier',
    'angular-carousel',
    'cfp.hotkeys',
    'filters-inArrayFilter',
    'flashMessage',
    'file-model',
    'pascalprecht.translate',
    ngMap,
    'ngSVGAttributes',
    'rzModule',
    uiSelect,
    ngInfiniteScroll,
];

export {vendorModules};
