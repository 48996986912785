import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
    ACCESSORY_FEATURE_KEY,
    initialState as accessoryInitialState,
    accessoryReducer,
} from './+state/accessory.reducer';
import { AccessoryEffects } from './+state/accessory.effects';
import { AccessoryFacade } from './+state/accessory.facade';
import { AccessoriesComponent } from './accessories/accessories.component';
import { SharedModule } from '@icc/configurator/shared';
import { ComplementaryGoodsModule } from '@icc/legacy/configurator/steps/complementary_goods/complementary-goods.module';
import { AccessoriesModule } from '@icc/legacy/configurator/steps/window/accessories/accessories.module';
import { AccessoriesListPageComponent } from './accessories-list-page/accessories-list-page.component';
import { SillsListPageComponent } from './sills-list-page/sills-list-page.component';
import { SillOptionsPageComponent } from './sill-options-page/sill-options-page.component';
import { AccessoriesOptionsPageComponent } from './accessories-options-page/accessories-options-page.component';
import { AccessoriesColorsPageComponent } from './accessories-colors-page/accessories-colors-page.component';
import { CassonettoOptionsPageComponent } from './cassonetto-options-page/cassonetto-options-page.component';
import { CassonettosListPageComponent } from './cassonettos-list-page/cassonettos-list-page.component';
import { ConfiguratorUiModule } from '@icc/configurator/ui';
import { ConfiguratorPagesModule } from '@icc/configurator/pages';

@NgModule({
    declarations: [
        AccessoriesComponent,
        AccessoriesListPageComponent,
        AccessoriesOptionsPageComponent,
        AccessoriesColorsPageComponent,
        SillsListPageComponent,
        SillOptionsPageComponent,
        CassonettoOptionsPageComponent,
        CassonettosListPageComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        ConfiguratorUiModule,
        ConfiguratorPagesModule,
        StoreModule.forFeature(ACCESSORY_FEATURE_KEY, accessoryReducer, {
            initialState: accessoryInitialState,
        }),
        EffectsModule.forFeature([AccessoryEffects]),

        ComplementaryGoodsModule,
        AccessoriesModule
    ],
    providers: [AccessoryFacade],
    exports: [SillsListPageComponent, SillOptionsPageComponent, CassonettoOptionsPageComponent, CassonettosListPageComponent],
    entryComponents: [
        AccessoriesComponent,
        AccessoriesListPageComponent,
        AccessoriesOptionsPageComponent,
        AccessoriesColorsPageComponent,
        SillsListPageComponent,
        SillOptionsPageComponent,
        CassonettoOptionsPageComponent,
        CassonettosListPageComponent,
    ],
})
export class ConfiguratorAccessoryModule {}

export {AccessoriesComponent, AccessoriesListPageComponent, AccessoriesOptionsPageComponent, AccessoriesColorsPageComponent}
