const MoveElementModalTemplate = require('ngtemplate-loader!./move-element-modal.component.html');
import { MoveElementModalController } from './move-element-modal.controller';

export const MoveElementModalComponent: ng.IComponentOptions = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
    templateUrl: MoveElementModalTemplate,
    controller: MoveElementModalController,
};
