import { ModalService, IssuesService, IssueLevel } from '@icc/helpers';
import {
    TranslateService,
    APP_CONFIG,
    AppConfigFactory,
    Common,
    ConfiguratorsDataService,
    ConfigurationsService,
    EventBusService,
} from '@icc/common';
import { Inject, Injectable } from '@angular/core';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { core } from '@icc/common/Core';
import { PriceService } from '@icc/price';
import { ColorsPageComponent } from 'libs/configurator/window/src/lib/colors-page/colors-page.component';
import { IccConstructColor } from '@icc/common/data-types';
// import { ColorsListPageComponent } from 'libs/configurator/window/src/lib/colors-list-page/colors-list-page.component';
// import { ColorsListPageComponent } from '@icc/configurator/mosquito-screen/';

@Injectable()
export class ColorsService {
    loadedData = false;
    netColors = [];
    panelColors = [];
    frameColors: IccConstructColor[] = [];
    frameColorsGroups: string[] = [];
    private defaultColors = {};

    private configurators = ['mosquito'];
    constructor(
        private modalService: ModalService,
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService<'mosquito' | 'window'>,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private configuratorsDataService: ConfiguratorsDataService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private priceService: PriceService,
        private issuesService: IssuesService,
        private eventBusService: EventBusService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
    }

    /**
     * Funkcja uruhamiana po zaladowaniu konfiguratora
     */
    private init() {
        this.issuesService.addValidateFunction(this.validate.bind(this));

        this.netColors = [
            {
                id: 2,
                color: '000000',
                name: this.translateService.instant('COLOR|Czarny'),
                code: 'black',
                RAL: false
            },
        ];

        if (this.config().IccConfig.Configurators.greyColorInMosquito) {
            this.netColors.unshift({
                id: 1,
                color: 'A9A9A9',
                name: this.translateService.instant('COLOR|Szary'),
                code: 'gray',
                RAL: false
            });
        }

        this.panelColors = [
            {
                id: 1,
                color: 'Ffffff',
                name: this.translateService.instant('COLOR|Biały'),
                code: 'white',
                RAL: false
            },
            {
                id: 2,
                color: '654321',
                name: this.translateService.instant('COLOR|Brązowy'),
                code: 'brown',
                RAL: false
            },
        ];
        this.defaultColors = {
            frame: {},
            panel: this.panelColors[0],
            net: this.netColors[0],
        };

        if (this.currentConfiguratorService.conf === 'mosquito' && this.configurationsService.conf.Current.type === 'mosquito') {
            if (
                Common.isUndefined(this.configurationsService.conf.Current.Colors.frame)
                && Common.isUndefined(this.configurationsService.conf.Current.Colors.panel)
                && Common.isUndefined(this.configurationsService.conf.Current.Colors.net)
            ) {
                this.loadDefaultData();
            }

            this.loadColorsBySystem(
                this.configurationsService.conf.Current.System.id,
                this.configurationsService.conf.Current.Type.id
            );
        }

        this.loadedData = true;
    }

    /**
     * Kolory dla danego systemu
     * @param {number} systemId Id wybranego systemu
     */
    loadColorsBySystem(systemId, typeId) {
        if (Common.isUndefined(this.configurationsService.conf) || Common.isUndefined(systemId)) {
            return;
        }
        this.frameColors =
            core.copy(this.configuratorsDataService.data.windowColorsAll.filter(el => el.systems?.includes(Number(systemId)))) || [];

        this.frameColorsGroups = this.getColorsGroups(this.frameColors, systemId, typeId) || [];

        this.frameColors =
            this.frameColors.filter(color =>
                color.groups?.some(el => this.frameColorsGroups.map(Number).indexOf(+el) > -1)
            ) || [];

        this.setDefaultColors();
    }

    private getColorsGroups(allColors: IccConstructColor[], systemId, typeId) {
        // pobierz grupy kolorów z dostępnych kolorów
        const groups = [];
        for (let x = 0; x < allColors.length; ++x) {
            if (Common.isUndefined(allColors[x].groups) || allColors[x].groups === null) {
                continue;
            }
            for (let y = 0; y < allColors[x].groups.length; ++y) {
                if (groups.indexOf(Number(allColors[x].groups[y])) < 0) {
                    groups.push(Number(allColors[x].groups[y]));
                }
            }
        }

        // pobierz grupy kolorów dla danej linii (sprawdź czy grupa kolorów dla linii nie jest pusta)
        const colorGroups = this.configuratorsDataService.data.windowColorGroups
            .filter(el => {
                const val =
                    Common.isArray(el.systems)
                    && Common.isArray(groups)
                    && groups.indexOf(Number(el.id)) > -1
                    && el.systems.indexOf(systemId) > -1
                    && el.target.indexOf('show') > -1
                    && ((Common.isArray(el.mosquitoTypes)
                        && el.mosquitoTypes.length
                        && el.mosquitoTypes.indexOf(typeId) > -1)
                        || (!el.mosquitoTypes && !typeId));
                return val;
            })
            .map(el => el.id);
        return colorGroups;
    }

    /**
     * ustawienie domyślnego koloru
     */
    private setDefaultColors() {
        const conf = this.configurationsService.conf.Current;
        if (conf.type !== 'mosquito') {
            return;
        }
        if (
            Common.isArray(this.frameColors)
            && Common.isDefined(conf.System.id)
        ) {
            const allColors = this.frameColors;
            const whites = allColors.filter(function(el) {
                return el.color.toLowerCase() === 'ffffff';
            });
            let colorF;

            // ustawianie domyślnego koloru
            // biały - pierwszy kolor - pierwszy RAL
            if (
                Common.isObject(conf.Colors.frame)
                && Common.isDefined(conf.Colors.frame.id)
                && allColors.some(
                    color => color.id === conf.Colors.frame.id
                )
            ) {
                colorF = conf.Colors.frame;
            } else if (Common.isDefined(whites) && Common.isDefined(whites[0])) {
                colorF = whites[0];
            } else if (Common.isDefined(this.frameColors[0])) {
                colorF = this.frameColors[0];
            }

            this.setColorSide(this.defaultColors, 'frame', colorF);
        }

        if (
            this.configurators.indexOf(this.currentConfiguratorService.conf) !== -1
            && Common.isDefined(conf.Colors)
            && Common.isDefined(conf.Colors.frame)
            && Common.isUndefined(
                core.fId(this.frameColors, conf.Colors.frame.id)
            )
        ) {
            this.loadDefaultData();
        }
    }

    /**
     * Ustawianie domyslnego zestawu kolorow
     */
    loadDefaultData() {
        this.configurationsService.conf.Current.Colors = core.copy(this.defaultColors);
        this.configurationsService.conf.Default.Colors = core.copy(this.defaultColors);

        this.priceService.count();
    }

    /**
     * Pzzypisanie skladowych koloru w wybranym elemencie
     * @param {object} object Kolor elementu
     * @param {object} color  Nowy kolor
     */
    setColorSide(object, element, color) {
        if (Common.isDefined(color)) {
            object[element] = core.copy(color);

            this.priceService.count();
        }
    }

    /**
     * Wczytanie kolorow z konfiguracji
     * @param {object} color   Wybrany kolor
     * @param {string} group   Typ koloru
     * @param {string} element Element kolorowany
     * @param {object} object  Miejsce wystpienia elementu
     */
    setColor(color, element, object?) {
        let objectd;

        if (!Common.isDefined(object)) {
            object = this.configurationsService.conf.Current.Colors;
            objectd = this.configurationsService.conf.Default.Colors;
            this.setColorSide(objectd, element, color);
        }

        this.setColorSide(object, element, color);
    }

    /**
     * Otwiera okno modalne z wyborem kolorow
     * @param  {string} type Typ koloru
     */
    openModalColor(type: 'panel' | 'net' | 'frame', sash?) {
        let selectedColor = this.configurationsService.conf.Current.Colors[type];
        if (sash) {
            selectedColor = sash[type];
        }
        const modalInstance = this.modalService.open({
            templateUrl: 'modalMosquitoColor.html',
            controller: 'ModalMosquitoColorCtrl as gColor',
            pageComponent: ColorsPageComponent,
            resolve: {
                colors: () => {
                    if (type === 'net') {
                        return this.netColors;
                    } else if (type === 'panel') {
                        return this.panelColors;
                    } else {
                        return this.frameColors;
                    }
                },
                selColor: () => {
                    return selectedColor;
                },
                selectedColor: () => selectedColor,
                type: () => {
                    return type;
                },
                colorGroups: () => {
                    if (type === 'net') {
                        return [];
                    } else if (type === 'panel') {
                        return [];
                    } else {
                        return this.configuratorsDataService.data.windowColorGroups.filter(
                            el =>
                                this.frameColorsGroups.indexOf(el.id) > -1
                                && el.target.indexOf('show') > -1
                        );
                    }
                }
            },
        });

        modalInstance.result.then(selectedColor => {
            if (selectedColor) {
                this.setColor(selectedColor.color, type, sash);
            }
        });
    }

    /**
     * Sprawdza, czy został wybrany jakiś kolor
     */
    validate() {
        const conf = this.configurationsService.conf.Current;
        if (conf.type !== 'mosquito') {
            return;
        }
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) >= 0) {
            if (!conf.Colors.net || conf.Colors.net.name === null) {
                this.issuesService.simpleRegister(
                    'no-mosquito-colors',
                    'Kolor siatki nie został określony.',
                    this.translateService.instant('MOSQUITO|Kolor siatki nie został określony.'),
                    conf,
                    {
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }
        }

        this.issuesService.unregister(
            'no-mosquito-colors',
            conf
        );
        return true;
    }
}
