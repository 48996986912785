<mat-toolbar id="step-toolbar" class="mat-elevation-z2">
    <button mat-icon-button (click)="snav.toggle()" *ngIf="!pageTitle">
        <mat-icon>menu</mat-icon>
    </button>
    <div class="wrapper">
        <div id="search" *ngIf="searching">
            <mat-form-field class="search-input" floatLabel="never">
                <mat-label translate>INTERFACE|Wyszukaj</mat-label>
                <input matInput type="search" [formControl]="searchControl" #searchInput>
                <button matPrefix mat-button mat-icon-button>
                    <mat-icon>search</mat-icon>
                </button>
                <button matSuffix mat-button mat-icon-button (click)="stopSearching()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="spacer" *ngIf="!searching"></div>
        <div class="toolbarIcons" *ngIf="!searching">
            <ng-container *ngFor="let option of getPageToolbarOptions()">
                <button class="mat-focus-indicator mat-button mat-button-base" (click)="menuItemHandler(option)" [ngClass]="option.hover === 'active' ? 'hover' : ''" [title]="option.title | translate">
                    <span class="mat-button-wrapper">
                        <mat-icon [fontSet]="option.icon?.fontSet" [fontIcon]="option.icon?.fontIcon" matListIcon>
                            {{option.icon?.ligature || 'bubble_chart'}}
                        </mat-icon>
                        <!-- <mat-icon role="img" class="mat-icon notranslate mat-list-icon material-icons mat-icon-no-color" aria-hidden="true"> {{option.icon?.ligature || 'bubble_chart'}} </mat-icon> -->
                        <span translate class="ng-tns-c402-0 ng-star-inserted" *ngIf="!(accessories?.categories?.length > 1)">{{ option.title }}</span>
                     </span>
                    <div class="mat-ripple mat-button-ripple"></div>
                    <div class="mat-button-focus-overlay"></div>
                </button>

            </ng-container>
            <button mat-icon-button [matMenuTriggerFor]="pageMenu" *ngIf="pageMenuOptions.length">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<div class="scroll">
    <span #container></span>
</div>
