import { ModalInfoComponent } from './../modal-info/modal-info.component';
import { GarageDoorFacade } from './../+state/garage-door.facade';
import { StepComponent, _, ModalService } from '@icc/configurator/shared';
import { Component, OnInit } from '@angular/core';
import { EventBusService } from '@icc/common';
import { iccListItem } from '@icc/configurator/ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'icc-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent extends StepComponent implements OnInit {
  static stepName = _('STEPS|Linia produktów');
  static stepIcon = {
      ligature: 'apps',
  };

  public configurator = 'garage_door';
  public stepId = 'system';
  public title = _('GARAGE|Wybierz system');
    
  private subscriptions: Subscription[] = [];

  selectedSystem$ = this.garageDoorFacade.selectedSystem$;
  availableSystems$ = this.garageDoorFacade.availableSystems$;
  allSystems = [];
  systems = [];
  constructor(
    private eventBusService: EventBusService,
    private garageDoorFacade: GarageDoorFacade,
    private modalService: ModalService
  ) {
    super();
    this.subscriptions.push(
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
            this.init()
        )
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.map(el => el.unsubscribe());
  }

  private init() {
    this.subscriptions.push(
      this.availableSystems$.subscribe(systems => {
        this.allSystems = systems;
      })
    )
    this.systems = this.allSystems.map<iccListItem>(system => ({
      id: String(system.id),
      title: system.name,
      imageUrl: '/files/windowline/' + system.title_image,
      showInfo: system.content ? true : false,
      content: system.content
    }));    
  }

  selectSystem(system) {
    const foundSystem = this.allSystems.find(s => system.id === s.id);
    this.garageDoorFacade.setSystem(foundSystem);
  }

  showInfo(system) {
    if (system.content) {
      this.modalService
      .open({
          pageComponent: ModalInfoComponent,
          resolve: {
              item: system,
              button: true
          },
      })
      .result.then(selected => {
          if (selected) {
              this.selectSystem(system);
          }
      });
    }
  }
}
