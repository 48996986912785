import { Component, OnInit } from '@angular/core';
import { StepComponent, _, ModalService } from '@icc/configurator/shared';
import { MontagesService } from '@icc/legacy/configurator/steps/mosquito/montages.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { MosquitoScreenFacade } from '../+state/mosquito-screen.facade';
import { MontagesListPageComponent } from '../montages-list-page/montages-list-page.component';
import { map, take, first, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { UnitConverterService } from '@icc/configurator/ui';

@Component({
    selector: 'icc-montages',
    templateUrl: './montages.component.html',
    styleUrls: ['./montages.component.scss'],
})
export class MontagesComponent extends StepComponent implements OnInit {
    static stepName = _('STEPS|System montażu');
    static stepIcon = {
        ligature: 'border_outer',
    };

    public configurator = 'mosquito';
    public stepId = 'dimensions';
    public title = _('CONFIGURATOR|Wybierz rodzaj mocowania');

    montages: any[] = [];
    selectedMontage$ = this.mosquitoScreenFacade.montage$;
    showProfilePositionSelection$ = this.mosquitoScreenFacade.selectedType$.pipe(
        map(type => type.profile_position)
    );
    showMuntinPositionSelection$ = this.mosquitoScreenFacade.selectedType$.pipe(
        map(type => type.muntin_position)
    );
    showThickSealSelection$ = this.mosquitoScreenFacade.selectedType$.pipe(
        map(type => type.has_thick_seal)
    );
    profilePositionForm = this.fb.group({
        up: [false],
        down: [false],
        left: [false],
        right: [false],
    });

    muntinPositionForm = this.fb.group({
        muntinType: ['standard'],
        position: [0],
    });

    holdersForm = this.fb.group({
        line: [null],
        width: [0],
    });

    thickSealForm = this.fb.group({
        hasThickSeal: [false],
        sealThickness: [null],
    });

    windowLines: {
        id: string;
        content: string;
    }[] = [];

    private unsubscribe$ = new Subject<void>();
    dimensionUnit = this.unitConverterService.getUnit();

    constructor(
        private fb: FormBuilder,
        private montagesService: MontagesService,
        private eventBusService: EventBusService,
        private mosquitoScreenFacade: MosquitoScreenFacade,
        private modalService: ModalService,
        private unitConverterService: UnitConverterService
    ) {
        super();
    }

    ngOnInit() {
        this.windowLines = this.montagesService.getLines();
        this.mosquitoScreenFacade.profilePositions$.pipe(first()).subscribe(positions => {
            this.profilePositionForm.patchValue(positions);
        });
        this.mosquitoScreenFacade.muntinType$.pipe(first()).subscribe(type => {
            this.muntinPositionForm.patchValue({
                muntinType: type,
            });
        });
        this.mosquitoScreenFacade.muntinPosition$.pipe(
            distinctUntilChanged(),
            takeUntil(this.unsubscribe$)
        ).subscribe(position => {
            this.muntinPositionForm.patchValue({
                position,
            });
        });
        this.mosquitoScreenFacade.windowLine$.pipe(map(line => line && line.id || null), take(1)).subscribe(line => {
            this.holdersForm.patchValue({
                line: line,
            });
        });
        this.mosquitoScreenFacade.holdersWidth$.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(holdersWidth => {
            this.holdersForm.patchValue({
                width: holdersWidth || 0,
            });
        });
        this.mosquitoScreenFacade.hasThickSeal$.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(hasThickSeal => {
            this.thickSealForm.patchValue({
                hasThickSeal: hasThickSeal || false,
            });
        });
        this.mosquitoScreenFacade.sealThickness$.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(sealThickness => {
            this.thickSealForm.patchValue({
                sealThickness: sealThickness || 10,
            });
        });
        this.profilePositionForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(position => {
            this.montagesService.setProfilePositions(position);
        });
        this.muntinPositionForm.controls.muntinType.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(type => {
            this.montagesService.setMuntinType(type);
        });
        this.muntinPositionForm.controls.position.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(position => {
            this.montagesService.setMuntinPosition(position);
        });
        this.holdersForm.controls.line.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(lineId => {
            const line = this.windowLines.find(l => l.id === lineId) || null;
            this.montagesService.setLine(line);
        });
        this.holdersForm.controls.width.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(width => {
            this.montagesService.setHoldersWidth(width);
        });
        this.thickSealForm.controls.hasThickSeal.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(hasThickSeal => {
            this.montagesService.setHasThickSeal(hasThickSeal);
        });
        this.thickSealForm.controls.sealThickness.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(sealThickness => {
            this.montagesService.setSealThickness(sealThickness);
        });
    }

    ngOnDestroy(){
        this.unsubscribe$.next();
    }

    changeMontage() {
        this.modalService.open({
            pageComponent: MontagesListPageComponent,
        });
    }
}
