<ng-container>
  <mat-card class="mat-elevation-z3">
        <mat-card-title>{{ accessory?.name }}</mat-card-title>
          <div mat-card-avatar class="system-box-img">
                 <img [src]="'/files/awningaccessory/' + accessory?.img" [alt]="accessory?.name" defaultImg>
          </div>
          <div class="descr" [innerHTML]="accessory?.description" id="tinymce"></div>
        <button mat-raised-button color="primary" (click)="close()">
            {{ 'INTERFACE|Dodaj' | translate }}
        </button>
  </mat-card>
</ng-container>
