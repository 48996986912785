import { ConfigurationsService, ConfiguratorsDataService, EventBusService } from '@icc/common';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceService } from '@icc/price';
import { isUndefined } from '@icc/helpers';
import { Injectable } from '@angular/core';
@Injectable()
export class AccessoryService {
    constructor(
        private configurationsService: ConfigurationsService<'awning'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private curConfService: CurrentConfiguratorService,
        private priceService: PriceService,
        private eventBusService: EventBusService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
    }

    accessories = [];
    selAccessories = [];
    loadedData = false;

    /**
     * Funkcja inicjujaca
     */
    init() {
        if (this.curConfService.conf === 'awning') {
            this.setValues();
            this.setDefaultValues();
            this.loadedData = true;
        } else {
            return;
        }
    }

    setValues() {
        this.accessories = this.getAccessories();
    }

    getAccessories() {
        return this.configuratorsDataService.data.awningAccessories;
    }

    selectAccessory(selAccessory, accessory?, price?) {
        this.configurationsService.conf.Current.Addons.indexOf(selAccessory) === -1 ? this.configurationsService.conf.Current.Addons.push(selAccessory) : console.log("This item already exists");
        this.configurationsService.conf.Default.Addons = [];
        if (price) {
            this.priceService.count();
        }
        return selAccessory;
    }

    removeAccessory(accessory) {
        const accessoryIndex = this.configurationsService.conf.Current.Addons.indexOf(accessory);
        if (accessoryIndex !== -1) {
            this.configurationsService.conf.Current.Addons.splice(accessoryIndex, 1)
        }
    }

    /**
     * Ustaw wartości domyślne
     * @param {Object} reset Reset
     */
    setDefaultValues(reset?) {
        if (isUndefined(this.configurationsService.conf)) {
            return;
        }

        this.setValues();

        for (let i = 0; i < this.accessories.length; i++) {
            this.accessories[i].selected = false;
            this.selAccessories = [];
        }

        this.configurationsService.conf.Default.Addons = [];
    }
}
