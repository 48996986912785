import { Injectable } from '@angular/core';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { Frame } from '@icc/window';
import { ResizeSashService } from './resize-sash.service';
import { AlignmentsService } from './alignments.service';
import { core } from '@icc/common/helpers';

@Injectable()
export class ResizeFrameService {
    constructor(
        private resizeSashService: ResizeSashService,
        private alignmentsService: AlignmentsService
    ) {}

    resizeOneSideFrame(
        diff: number,
        frame: Frame,
        conf: WindowActiveConfiguration,
        [side, side2, sizeField, frameSizeField, coordField]: [
            'right' | 'bottom',
            'bottom' | 'right',
            'rWidth' | 'rHeight',
            'width' | 'height',
            'rx' | 'ry'
        ]
    ) {
        const sashes = conf.Sashes.filter(
            sash => sash.frameId === frame.id && sash.nearMullions[side] === -1
        );
        const mullions = conf.Mullions.filter(
            mullion =>
                mullion.frameId === frame.id
                && mullion[coordField] + mullion[sizeField] === frame[frameSizeField]
        );

        frame[frameSizeField] -= diff;
        sashes.forEach(sash => {
            this.resizeSashService.resizeOneSideSash(
                sash,
                diff,
                [side, side2, sizeField, coordField],
                conf
            );
            this.alignmentsService.resizeAlignments(conf, core.opposite(side2), side, sash, -diff);
            this.alignmentsService.resizeAlignments(conf, side2, side, sash, -diff);
            this.alignmentsService.rearrangeAlignments(conf, side, sash);
        });
        mullions.forEach(mullion => {
            mullion[sizeField] -= diff;
        });
    }

    resizeOtherSideFrame(
        diff: number,
        frame: Frame,
        conf: WindowActiveConfiguration,
        [
            side,
            side2,
            sizeField,
            frameSizeField,
            coordField,
            frameCoordField,
            orientation,
            multiAlignField,
        ]: [
            'left' | 'top',
            'bottom' | 'right',
            'rWidth' | 'rHeight',
            'width' | 'height',
            'rx' | 'ry',
            'x' | 'y',
            'vertical' | 'horizontal',
            'multiAlignLeft' | 'multiAlignTop'
        ]
    ) {
        frame[frameCoordField] += diff;
        frame[frameSizeField] -= diff;

        const sashes = conf.Sashes.filter(sash => sash.frameId === frame.id);
        const mullions = conf.Mullions.filter(mullion => mullion.frameId === frame.id);

        sashes.forEach(sash => {
            this.resizeSashService.resizeOtherSideSashField(sash, -diff, mullions, [
                side,
                side2,
                sizeField,
                coordField,
            ]);

            for (const field of sash.intSashes) {
                this.resizeSashService.resizeOtherSideSashField(field, -diff, sash.intMullions, [
                    side,
                    side2,
                    sizeField,
                    coordField,
                ]);
                this.alignmentsService.resizeAlignments(
                    conf,
                    core.opposite(side2),
                    side,
                    field,
                    -diff
                );
                this.alignmentsService.resizeAlignments(conf, side2, side, field, -diff);
                this.alignmentsService.rearrangeAlignments(conf, side, field);
                this.alignmentsService.rearrangeAlignments(conf, core.opposite(side), field);
            }
            for (const mullion of sash.intMullions) {
                this.resizeSashService.resizeOtherSideSashMullion(mullion, -diff, [
                    orientation,
                    multiAlignField,
                    side,
                    coordField,
                ]);
            }
            this.alignmentsService.resizeAlignments(conf, core.opposite(side2), side, sash, -diff);
            this.alignmentsService.resizeAlignments(conf, side2, side, sash, -diff);
            this.alignmentsService.rearrangeAlignments(conf, side, sash);
            this.alignmentsService.rearrangeAlignments(conf, core.opposite(side), sash);
        });

        mullions.forEach(mullion => {
            this.resizeSashService.resizeOtherSideSashMullion(mullion, -diff, [
                orientation,
                multiAlignField,
                side,
                coordField,
            ]);
        });
    }
}
