import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageComponent } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MontagesService } from '@icc/legacy/configurator/steps/mosquito/montages.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { MosquitoScreenFacade } from '../+state/mosquito-screen.facade';

@Component({
    selector: 'icc-montages-list-page',
    templateUrl: './montages-list-page.component.html',
    styleUrls: ['./montages-list-page.component.scss'],
})
export class MontagesListPageComponent extends PageComponent implements OnInit, OnDestroy {
    public title = _('CONFIGURATOR|Wybierz rodzaj mocowania');

    montages: iccListItem[] = [];
    selectedMontage$ = this.mosquitoScreenFacade.montage$.pipe(map(type => type && type.id));
    private subscriptions: Subscription[] = [];

    constructor(
        private montagesService: MontagesService,
        private eventBusService: EventBusService,
        private mosquitoScreenFacade: MosquitoScreenFacade,
        private sharedFacade: SharedFacade
    ) {
        super();
        if (this.montagesService.loadedData) {
            this.init();
        }
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    init() {
        this.montages = this.montagesService.montages.map(type => ({
            id: type.id,
            title: type.name,
            tabs: [type.type],
            imageUrl: '/files/mosquitomontage/' + type.img,
        }));
    }

    selectMontage(item: iccListItem) {
        const type = this.montagesService.montages.find(s => s.id === item.id);
        if (type) {
            this.montagesService.selectMontage(type, true);
            this.sharedFacade.closePage();
        }
    }
}
