import { Injectable, Inject } from '@angular/core';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';
import { DiscountsAndMultipliersService } from '@icc/price/b2b';
import { TranslateService } from '../translate.service';
import { APP_CONFIG, AppConfigFactory } from '../config';

interface MeasurementPrice {
    id: string;
    code: string;
    price: string;
    supplement: string;
}

@Injectable()
export class MeasurementPricesService {
    private measurementPrices: MeasurementPrice[] = [];

    constructor(
        private configuratorsDataService: ConfiguratorsDataService,
        private discountsAndMultipliersService: DiscountsAndMultipliersService,
        private translateService: TranslateService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {
        this.configuratorsDataService.dataAsync().then(data => {
            this.measurementPrices = data.measurementPrices || [];
        });
    }

    async getPrices() {
        if (this.measurementPrices && this.measurementPrices.length > 0) {
            return this.measurementPrices;
        }
        const data = await this.configuratorsDataService.dataAsync();
        this.measurementPrices = data.measurementPrices || [];
        return this.measurementPrices;
    }
    
    async getMeasurement(postCode: string) {
        const prices = await this.getPrices();
        return prices.find(price => price.code === postCode);
    }
}
