import $ from 'jquery';

/**
 * Kontroler okna modalnego z wyborem kolorow
 * @param {object}   $uibModalInstance     Modal
 * @param {object}   $timeout           Timeout
 * @param {function} colorsByTypeFilter Funkcja zwraca kolory wg typu
 * @param {array}    colors             Lista kolorow
 * @param {object}   colorGroups         Grupy kolorow
 * @param {object}   selColor           Wybrany kolor
 */
export default function ModalColorCtrl($uibModalInstance, $timeout, colorsByTypeFilter, colors, colorGroups, selColor, colorsRAL, type, ScrollbarService) {
    'ngInject';

    var vm = this;

    vm.colors    = colors;
    vm.colorsRAL = colorsRAL;
    vm.selColor  = selColor;
    vm.type      = type;

    vm.white    = colorsByTypeFilter(vm.colors, 'white').length > 0;
    vm.RAL      = colorsByTypeFilter(vm.colors, 'RAL').length > 0;
    vm.window   = colorsByTypeFilter(vm.colors, 'window').length > 0;
    vm.srenolit = colorsByTypeFilter(vm.colors, 'srenolit').length > 0;
    vm.scolor   = colorsByTypeFilter(vm.colors, 'scolor').length > 0;
    vm.sRAL     = colorsByTypeFilter(vm.colors, 'sRAL').length > 0;
    vm.standard = colorsByTypeFilter(vm.colors, 'standard').length > 0;

    vm.selectColor = selectColor;
    vm.close       = close;
    vm.refresh     = refresh;
    vm.colorType   = selColor ? selColor.group : null;

    /**
     * Wybiera kolor i zamyka modala
     * @param  {object} color Wybrany kolor
     */
    function selectColor(color) {
        $uibModalInstance.close([color, vm.colorType, vm.type]);
    }

    /**
     * Zamyka modala bez zmian
     */
    function close() {
        $uibModalInstance.close();
    }

    /**
     * Odswieza plugin do przewijania, by porawic dlugosc scolla
     */
    function refresh() {
        ScrollbarService.update();
    }

}
