import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfigFactory, ConfigurationsService, ConfiguratorsDataService, TranslateService, EventBusService } from '@icc/common';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { isDefined, isObject, ModalService } from '@icc/helpers';
import { NewColorsService } from '../colors/new-colors.service';

@Injectable()
export class AlushellService {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private modalService: ModalService,
        private configurationsService: ConfigurationsService<'window'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private translateService: TranslateService,
        private currentConfiguratorService: CurrentConfiguratorService,
        private eventBusService: EventBusService,
        private newColorsService: NewColorsService,
    ) {

    }

    /**
     * Definiuje na podstawie cennika, że dla wybranego systemu (nie) jest dostepna nakładka aluminiowa.
     * @private
     * @return {Boolean} Dostępność nakładki
     */
    hasSystemAlushell(
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        const winLineId = conf.System.id;
        if (
            (isObject(this.configuratorsDataService.data.pricesDepends)
                && isObject(this.configuratorsDataService.data.pricesDepends[winLineId + '|n']))
            || (isDefined(conf.System.alushell_factor) && conf.System.alushell_factor !== null)
        ) {
            const prices = this.configuratorsDataService.data.pricesDepends[winLineId + '|n'];
            if (
                isDefined(prices)
                || (isDefined(conf.System.alushell_factor) && conf.System.alushell_factor !== null)
            ) {
                conf.System.alushell = true;
                defaultConf.System.alushell = true;
            }

            if (
                this.config().IccConfig.Configurators.alushell
                && conf.System.alushell
                && conf.System.alushell_active
            ) {
                this.setAlushell(true, conf);
            }

            const brushedAluGroups = this.newColorsService.getBrushedAlushellColorGroups();
            if (!this.config().IccConfig.Configurators.alushellExt || !brushedAluGroups.length) {
                this.setAlushellType('painted', conf);
            }
        } else {
            conf.System.alushell = false;
            defaultConf.System.alushell = false;

            if (conf.HasAlushell) {
                this.setAlushell(false, conf);
            }
        }
    }

    setAlushell(value: boolean, conf = this.configurationsService.conf.Current) {
        conf.HasAlushell = value;
        if (value) {
            this.newColorsService.validateElementColor(
                conf,
                {
                    element: 'sash',
                    side: 'alushell',
                }
            );
        } else {
            this.newColorsService.resetWindowColors(conf);
        }
        this.eventBusService.post({
            key: 'changedAlushell',
            value,
        });
    }

    setAlushellType(value: 'painted' | 'brushed', conf = this.configurationsService.conf.Current) {
        conf.AlushellType = value;
        this.newColorsService.validateElementColor(
            conf,
            {
                element: 'sash',
                side: 'alushell',
            }
        );
        this.eventBusService.post({
            key: 'changedAlushell',
            value,
        });
    }
}
