import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { _, StepComponent, ModalService, ConfiguratorOptions } from '@icc/configurator/shared';
import { ChangeFittingPageComponent } from '../change-fitting-page/change-fitting-page.component';
import { WindowFacade } from '../+state/window.facade';
import { HandlesService } from '@icc/legacy/configurator/steps/window/handles/handles.service';
import { EditHandlesPageComponent } from '../edit-handles-page/edit-handles-page.component';
import { ConfigurationsService, APP_CONFIG, AppConfigFactory, WindowActiveConfiguration } from '@icc/common';
import { withLatestFrom } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IccColor, IccConstructColor, IccHinge } from '@icc/common/data-types';

interface HandleSelectBox {
    img: string;
    name: any;
    color: any;
    colorOuter: any;
    colorId: any;
    colorOuterId: any;
    selectType: string;
    oneHandle: boolean;
    varHandle: boolean;
    hasHandle: boolean;
    hasSecondColor: boolean;
}

interface HingeSelectBox {
    img: string;
    name: any;
    color: any;
    colorId: any;
    selectType: string;
    hasSecondColor: boolean;
}

@Component({
    selector: 'icc-fitting',
    templateUrl: './fitting.component.html',
    styleUrls: ['./fitting.component.scss'],
})
export class FittingComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Okucia i klamki');
    static stepIcon = {
        fontSet: 'icc-icons',
        fontIcon: 'icc-icons-handle',
    };

    public configurator = 'window';
    public stepId = 'fitting';
    public title = _('WINDOW|Okucia i klamki');
    public options = [
        {
            title: this.config().IccConfig.Configurators.editHandlePageTitleDefault  ? _('WINDOW|Klamka') : _('WINDOW|Zmiana wysokości klamki'),
            component: EditHandlesPageComponent
        },
    ];

    selectedHandle$ = this.windowFacade.selectedHandle$.pipe(
        withLatestFrom(this.windowFacade.selectedHandleColor$)
    );
    selectedFitting$ = this.windowFacade.selectedFitting$;
    selectedHinge$ = this.windowFacade.selectedHinge$;
    selectedHingeColor$ = this.windowFacade.hingeColor$;
    selectedHinge: IccHinge | null = null;
    selectedHingeColor: IccColor | null = null;

    innerLeverBoxSelect: HandleSelectBox | null = null;
    outerLeverBoxSelect: HandleSelectBox | null = null;
    innerPullBoxSelect: HandleSelectBox | null = null;
    outerPullBoxSelect: HandleSelectBox | null = null;
    windowLeverBoxSelect: HandleSelectBox | null = null;

    hingeBoxSelect: HingeSelectBox | null = null;


    showDoubleLeverBox$ = this.windowFacade.showDoubleLeverBox$;
    showInnerLeverBox$ = this.windowFacade.showInnerLeverBox$;
    showInnerPullBox$ = this.windowFacade.showInnerPullBox$;
    showOuterPullBox$ = this.windowFacade.showOuterPullBox$;
    showWindowLeverBox$ = this.windowFacade.showWindowLeverBox$;
    showOuterHandles$ = this.windowFacade.showOuterHandles$
    selectHandlerType$ = this.windowFacade.selectHandlerType$;

    isDoorVersion2 =
        this.config().IccConfig.Configurators.door.version === 2
        && this.configurationsService.conf.Current.type === 'door';

    hinge =
        this.isDoorVersion2
        || (this.configurationsService.conf.Current.System.confType === 'window'
            && this.handlesService.isMatch.ref);
    onlyInWindow = this.configurationsService.conf.Current.type !== 'door';
    handleType$ = this.windowFacade.handleType$;
    noLeverPull = this.config().IccConfig.Configurators.door.noLeverPull;
    noDoublePull = this.config().IccConfig.Configurators.door.noDoublePull;
    isOuterHandleWindow = this.config().IccConfig.Configurators.sliding_door.outerHandles;

    private subscriptions: Subscription[] = [];

    static stepEnable = (conf: WindowActiveConfiguration, options: ConfiguratorOptions) =>
        (conf.type !== 'door' || (!conf.System || !conf.System.door_type))
        && conf.SashesType !=='FixSash' && conf.SashesType !== 'Fix';

    constructor(
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
        private windowFacade: WindowFacade,
        private modalService: ModalService,
        private handlesService: HandlesService,
        private configurationsService: ConfigurationsService<'door' | 'sliding_door' | 'window'>
    ) {
        super();
    }
    ngOnInit() {
        this.subscriptions.push(
            this.windowFacade.innerLeverBoxSelect$.subscribe((box) => {
                this.innerLeverBoxSelect = box;
            }),
            this.windowFacade.outerLeverBoxSelect$.subscribe((box) => {
                this.outerLeverBoxSelect = box;
            }),
            this.windowFacade.innerPullBoxSelect$.subscribe((box) => {
                this.innerPullBoxSelect = box;
            }),
            this.windowFacade.outerPullBoxSelect$.subscribe((box) => {
                this.outerPullBoxSelect = box;
            }),
            this.windowFacade.windowLeverBoxSelect$.subscribe((box) => {
                this.windowLeverBoxSelect = box;
            }),
            this.windowFacade.hingeBoxSelect$.subscribe((box) => {
                this.hingeBoxSelect = box;
            }),
            this.selectedHinge$.subscribe((hinge) => {
                this.selectedHinge = hinge;
            }),
            this.selectedHingeColor$.subscribe((color) => {
                this.selectedHingeColor = color;
            })
        );
    }
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    selectFitting() {
        this.modalService.open({
            pageComponent: ChangeFittingPageComponent,
            resolve: {
                selectedFittingId: () => this.configurationsService.conf.Current.Fitting.id
            }
        });
    }

    selectHandle() {
        this.handlesService.openModalHandle('window', 'innerLever', 'innerLever');
    }

    selectHandleDoor(sash: string, place: string) {
        this.handlesService.openModalHandle(sash || 'door', place || 'double', this.configurationsService.conf.Current.HandleType);
    }

    setHandleType(type: any, sash: any) {
        this.configurationsService.conf.Current.HandleType = type;
        if(this.config().IccConfig.Configurators.sliding_door.outerHandles) {
            this.configurationsService.conf.Default.HandleType = type;
        }
        this.handlesService.setHandleType(type, sash);
    }

    changeHandleOptions(sash: string, place: string) {
        this.handlesService.openModalHandleOptions(sash || 'window', place || 'innerLever', this.configurationsService.conf.Current.HandleType);
    }

    changeHingeOptions(sash: string, place: string) {
        this.handlesService.openModalHingeOptions(sash || 'window', place || 'innerLever', this.configurationsService.conf.Current.HandleType);
    }

    selectHinge() {
        this.handlesService.openModalHinge();
    }

    getHingeImageUrl(hinge: IccHinge | null = this.selectedHinge, selectedColor: IccColor | null = this.selectedHingeColor): string {
        if (hinge && selectedColor) {
            return hinge.colors_imgs && selectedColor.id && hinge.colors_imgs[selectedColor.id]
                ? '/files/windowhandlescolorswindowaccessory/' + hinge.colors_imgs[selectedColor.id]
                : '/files/windowaccessory/' + hinge.img;
        }

        return '';
    }

    hasOuterHandles(type: any) {
        return this.handlesService.hasOuterHandles(type);
    }
}
