import 'angular-ui-bootstrap';
import { core } from '@icc/common/helpers';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { EventBusService } from '@icc/common/event-bus.service';

export class ModalProfileColorsCtrl {
    config = {
        type: 'window',
        Colors:
            this.selectedProfile
            && this.selectedProfile.selectedColor
                ? this.selectedProfile.selectedColor
                : {
                      frame: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                      sash: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                  },
        System: {
            type: 'pvc',
        },
        Wood:
            this.selectedProfile && this.selectedProfile.selectedWood
                ? this.selectedProfile.selectedWood
                : {},
        valid: {},
    };

    configDefault = {
        type: 'window',
        Colors:
            this.selectedProfile
            && this.selectedProfile.selectedColor
                ? this.selectedProfile.selectedColor
                : {
                      frame: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                      sash: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                  },
        System: {
            type: 'pvc',
        },
        Wood:
            this.selectedProfile && this.selectedProfile.selectedWood
                ? this.selectedProfile.selectedWood
                : {},
        valid: {},
    };

    hasCream = false;

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private profile,
        private profilesPrices,
        private selectedProfile,
        private configurationsService: ConfigurationsService,
        private eventBusService: EventBusService
    ) {
    }

    setColorType(type) {
        throw new Error('Deprecated!');
    }

    openModalWood() {
        throw new Error('Deprecated!');
    }

    filter = group => true;
    filterColors = color => true;


    close() {
        this.$uibModalInstance.close({ colors: this.config.Colors, wood: this.config.Wood });
    }
}
ModalProfileColorsCtrl.$inject = [
    '$uibModalInstance',
    'profile',
    'profilesPrices',
    'selectedProfile',
    'ConfigurationsService',
    'EventBusService',
];
