<ng-container *ngTemplateOutlet="colorBoxTemplate;context:{type: 'frame'}"></ng-container>
<ng-container *ngTemplateOutlet="colorBoxTemplate;context:{type: 'net'}"></ng-container>
<ng-container *ngTemplateOutlet="colorBoxTemplate;context:{type: 'panel'}"></ng-container>

<ng-template #colorBoxTemplate let-type="type">
  <ng-container *ngTemplateOutlet="colorBox;context:{
    color: (colors$[type] | async),
    type: type,
    show: (colorsShow$[type] | async),
    title: colorsTitle[type] || ''
   }"></ng-container>
</ng-template>

<ng-template #colorBox let-color="color" let-type="type" let-show="show" let-title="title" >
    <icc-select-box
      [title]="title | translate"
      [description]="color?.name"
      [color]="'#' + (color?.color || '')"
      [imageUrl]="'/files/color/' + (color?.color_img || '')"
      [buttonText]="'INTERFACE|Zmień' | translate"
      [colorSelect]="true"
      *ngIf="show"
      (select)="openModalColor(type)"
  ></icc-select-box>
</ng-template>
