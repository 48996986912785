import { NgModule } from '@angular/core';
import { ColorsPositionService } from './colors-position.service';
import { CuttingStockService } from './cutting-stock.service';
import { OffersService } from './offers.service';
import { OfferSummaryService } from './offer-summary.service';
import { OfferGroupService } from './offer-group.service';
import { ManyPositionsService } from './many-positions.service';
import { offersFactoryProvider } from './ajs-upgraded-providers';
import { editPositionFactoryProvider } from '@icc/legacy/offer/ajs-upgraded-providers';
import { MontagesService } from '@icc/common/offers/montages.service';
import { MeasurementPricesService } from '@icc/common/offers/measurement-prices.service';
import { MontagesDataService } from './montages-data.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        ColorsPositionService,
        ManyPositionsService,
        CuttingStockService,
        OffersService,
        OfferSummaryService,
        OfferGroupService,
        MontagesService,
        MontagesDataService,
        MeasurementPricesService,
        editPositionFactoryProvider,
        offersFactoryProvider,
    ],
})
export class PanelModule {}
