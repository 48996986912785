<icc-select-box
[title]="'GARAGEDOOR|Sterowanie' | translate"
[imageUrl]="'/files/garagedoorcontrol/' + (selectedDrive$ | async)?.img"
[description]="(selectedDrive$ | async)?.name"
[buttonText]="'INTERFACE|Zmień' | translate"
(select)="selectDrive()"
></icc-select-box>

<div class="box-form">
  <h4>{{ 'GARAGEDOOR|Strona zamontowania napędu/przekładni:' | translate }}</h4>
  <mat-radio-group [ngModel]="(selectedDriveSide$ | async)" (ngModelChange)="selectDriveSide($event)">
      <mat-radio-button value="left">{{ 'GARAGEDOOR|Po lewej stronie' | translate }}</mat-radio-button>
      <mat-radio-button value="right">{{ 'GARAGEDOOR|Po prawej stronie' | translate }}</mat-radio-button>
  </mat-radio-group>
</div>
