import {logger} from '../helpers';

export default function SumByPropertyFilter() {
    'ngInject';

    return function(data, property) {
        if (typeof data == 'undefined') {
            return false;
        }
        var sum = 0;
        for (var key in data) {
            sum = sum + (data[key][property] * 1);
        }
        if (isNaN(sum)) {
            logger.log('Filter SumByProperty: Podana cena ma wartość ' + sum);
        }
        return isNaN(sum) ? 0 : sum;
    };
}
