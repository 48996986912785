import { WindowInCoupling } from '@icc/common/configurations/parts/coupled_window';
import { core } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';
import { DrawService } from '@icc/common/configurators/draw.service';

export class JoinPositionModalController {
    resolve: { windows: WindowInCoupling[] };
    close: ({ $value: any }) => any;
    dismiss: () => any;

    drawOptions = Common.merge({}, this.drawService.options, {
        indexes: { multiplier: 3 },
    });

    constructor(private drawService: DrawService) {}

    indexLetter(index: number) {
        return core.indexToLetter(index);
    }

    selectWindow(window: WindowInCoupling) {
        this.close({ $value: window });
    }

    closeModal() {
        this.close({ $value: null });
    }
}
JoinPositionModalController.$inject = ['DrawService'];
