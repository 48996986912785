import { PriceFunc, PriceElemsData, PriceSegment } from './Prices';
import {APP_CONFIG, AppConfig, AppConfigFactory} from '@icc/common/config';;
import { Injectable, Inject } from '@angular/core';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { UserService } from '@icc/common/user.service';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/Core';

@Injectable()
export class PricePleatedBlindService {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private userService: UserService,
        private parametersService: ParametersService
    ) {}

    /**
     * Dopłata plisy
     * @param  {Number} price         Cena
     * @param  {Object} PriceElems    Cena elementów
     * @param  {Object} NoPriceCauses Powody braku ceny
     * @param  {Object} conf          Konfiguracja
     * @param  {Object} commonRail    Wspólna szyna
     * @return {Bool}                 Wspólna szyna
     */
    @PriceFunc({
        shortName: 'pleatedBlind',
        data: {
            pleatedBlind: 'conf',
            type: 'conf.type',
            pleatedBlindPricesData: 'data.pleatedBlindPricesData',
            productPricelists: 'data.productPricelists',
            markets: 'data.markets',
            accessories: 'data.windowAccessories',
            system: 'conf.System',
            shape: 'conf.Shape',
        },
    })
    suppPleatedBlind(
        { PriceStack, PriceElems, NoPriceCauses, NoPriceElems }: PriceElemsData,
        {
            pleatedBlind,
            type,
            pleatedBlindPricesData,
            productPricelists,
            markets,
            accessories,
            system,
            shape
        }
    ): PriceSegment[] {
        let supp = 0;
        const market = this.userService.get().market;
        let pleatedBlindFactor = 1;
        const priceSegments: PriceSegment[] = [];
        const width = shape.width;
        const height = shape.height;
        

        if (!Common.isUndefined(pleatedBlind) && (type === 'pleated_blind') && !Common.isUndefined(pleatedBlindPricesData)) {
            const pleatedBlindPricesFiltered = this.getPricesForPleatedBlind(
                pleatedBlind,
                pleatedBlindPricesData
            );
            if (
                Common.isObject(markets)
                && Common.isDefined(markets[market])
                && Common.isDefined(markets[market].factor_pleated_blind)
                && core.num(markets[market].factor_pleated_blind) > 0
            ) {
                pleatedBlindFactor = core.num(markets[market].factor_pleated_blind);
            }


            supp += priceSegments.reduce((prev, cur) => prev + parseFloat(cur.baseValue as any), 0);
           

            priceSegments.push(
                this.pricePleatedBlind(pleatedBlindPricesFiltered[0], height, width, pleatedBlind, PriceElems, NoPriceCauses, pleatedBlindFactor)
            );
            
        }
        return priceSegments;
    }

   /**
     * Cena pojedynczej plisy
     * @param  {Number} rPrice        Cennik
     * @param  {Number} height        Wysokość
     * @param  {Number} width         Szerokość
     * @param  {Object} pleatedBlind        Plisa
     * @param  {Array}  PriceElems    Elementy składowe wyceny
     * @param  {Object} NoPriceCauses Powody braku ceny
     * @param  {number} pleatedBlindFactor  Wspolczynnik rynku dla plis
     * @return {Number}               Cena
     */
    pricePleatedBlind(
        rPrice,
        height,
        width,
        pleatedBlind,
        PriceElems,
        NoPriceCauses,
        pleatedBlindFactor
    ): PriceSegment {
        let price = 0;
        const system = pleatedBlind.System;

        const pleatedBlindPrice = rPrice.data.filter(function filterPleatedBlindPrices(el) {
            return (
                el.height_from <= height
                && el.height_to >= height
                && el.width_from <= width
                && el.width_to >= width
            );
        });

        if (
            Common.isDefined(system)
            && Common.isDefined(pleatedBlindPrice)
            && Common.isDefined(pleatedBlindPrice[0])
            && Common.isDefined(pleatedBlindPrice[0].price)
        ) {
            // cena za metr kw.
            if (parseFloat(rPrice.price_m2) > 0) {
                let area = (width * height) / 1000000;

                if (area < parseFloat(rPrice.min_area_price)) {
                    area = parseFloat(rPrice.min_area_price);
                }

                
                price = parseFloat(rPrice.price_m2) * area * pleatedBlindFactor;
                if (pleatedBlind.variant.double_fabric) price *= 2;
                PriceElems.pleatedBlind.changedSize = {
                    system: system.id,
                    priceId: rPrice.id,
                    price,
                    height,
                    width,
                };
                return {
                    type: 'pleatedBlind',
                    baseValue: price,
                    value: price,
                    valueType: 'value',
                    data: {
                        system: system.id,
                        priceId: rPrice.id,
                        height,
                        width,
                    },
                };
                // cena z rastra
            } else {
                price = parseFloat(pleatedBlindPrice[0].price) * pleatedBlindFactor;
                if (pleatedBlind.variant.double_fabric) price *= 2;
                PriceElems.pleatedBlind.changedSize = {
                    system: system.id,
                    priceId: rPrice.id,
                    price,
                    height,
                    width,
                };
                return {
                    type: 'pleatedBlind',
                    baseValue: price,
                    value: price,
                    valueType: 'value',
                    data: {
                        system: system.id,
                        priceId: rPrice.id,
                        height,
                        width,
                    },
                };
            }
        } else {
            NoPriceCauses.push('no pleated blind prices');
            return {
                type: 'pleatedBlind',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {
                    priceId: rPrice.id,
                    height,
                    width,
                },
            };
        }
    }

    /**
     * Wyszukuje cennik dla plis
     * @param  {object} pleatedBlind Konfiguracja plisy
     * @return {array}         Lista pasujacych cennikow
     */
    getPricesForPleatedBlind(pleatedBlind, pleatedBlindPricesData) {
        return (pleatedBlindPricesData || []).filter(el => {
            let check = true;
            if (Common.isDefined(pleatedBlind.System) && pleatedBlind.System !== null) {
                check = check && el.systems && !!el.systems[pleatedBlind.System.id];
            }
            if (Common.isDefined(pleatedBlind.drive) && pleatedBlind.drive !== null) {
                check =
                    check
                    && (el.pleated_blind_drive_id === pleatedBlind.drive.id
                        || !~~el.pleated_blind_drive_id);
            }
            return check;
        }).sort((a, b) => Number(b.pleated_blind_drive_id) - Number(a.pleated_blind_drive_id));
    }
}
