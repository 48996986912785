import * as angular from 'angular';
import { DatabaseManager } from '@icc/helpers/browser';
import {IccSimpleDatabase} from '@icc/helpers/browser';
import {core} from '@icc/legacy/app/helpers';

export class AdditionalsFactory {

    constructor(private DatabaseManager: DatabaseManager) {
        'ngInject';
    }

    /**
     * Pobiera dodatkowe elementy
     * @param  {Function} callback Funkcja po pobraniu
     * @return {object}            Angular Promise
     */
    async get() {
        const res = await (<IccSimpleDatabase>this.DatabaseManager.get('Additional')).get();
        let data = core.parseJson(res && res.data ? res.data : '{}');
        if (angular.isObject(data)) {
            data = data.additionals || [];
            return data;
        }
        return [];
    }
}
