import { Shape } from './types';

export function expandShape(shape: Shape) {
    shape = { ...shape };
    if (shape.shape === 'rect') {
        shape.shortening = shape.shortening;
        shape.s1 = shape.width;
        shape.h1 = shape.height;
        shape.h3 = shape.height;

        shape.s2 = 0;
        shape.s3 = 0;
        shape.h2 = 0;
    }

    if (shape.shape === 'triangle') {
        shape.s1 = shape.s1;
        shape.s2 = 0;

        shape.h1 = shape.height;
        shape.h2 = 0;
        shape.h3 = 0;
    }

    if (shape.shape === 'arc') {
        let coords;
        if (shape.type === 'F') {
            coords = [
                {
                    x: 0,
                    y: shape.h2,
                },
                {
                    x: Number(shape.width) / 2,
                    y: 0,
                },
                {
                    x: shape.width,
                    y: shape.h2,
                },
            ];
            shape.radius = getArcRadius(coords);
        }

        if (shape.type === 'R' || shape.type === 'L') {
            if (shape.width >= shape.h2) {
                coords = [
                    {
                        x: Number(shape.width) * -1,
                        y: shape.h2,
                    },
                    {
                        x: 0,
                        y: 0,
                    },
                    {
                        x: shape.width,
                        y: shape.h2,
                    },
                ];
            } else {
                coords = [
                    {
                        x: 0,
                        y: shape.h2 * -1,
                    },
                    {
                        x: shape.width,
                        y: 0,
                    },
                    {
                        x: 0,
                        y: shape.h2,
                    },
                ];
            }
            shape.radius = getArcRadius(coords);
        }
    }
    if (shape.shape === 'circle') {
        shape.radius = Number(shape.d) / 2;
    }
    shape.circuit = getCircuit(shape);
    return shape;
}

export function getDefaultShapeDimensions(
    shape: Shape['shape'],
    width: number,
    height: number,
    variant?: Shape['type']
) {
    const defaultDimensions: { [key in Shape['shape']]: Shape } = {
        rect: {
            shape: 'rect',
            width: width,
            height: height,
            circuit: (width + height) * 2,
            s1: width,
            h1: height,
            h3: height,
            s2: 0,
            s3: 0,
            h2: 0,
            shortening: 0,
        },
        triangle: {
            shape: 'triangle',
            width: width,
            height: height,
            s1: width * 0.5,
            s3: width * 0.5,

            circuit: Math.round(
                Math.sqrt(height * height + width * 0.5 * width * 0.5)
                    + Math.sqrt(height * height + width * 0.5 * width * 0.5)
                    + width
            ),
            type: variant || 'F',
        },
        circle: {
            shape: 'circle',
            d: height,
            radius: height / 2,
            circuit: Math.round(width * 3.1416),
        },
        poligon: {
            shape: 'poligon',
            width: width,
            height: height,
            s1: width * 0.3,
            s2: width * 0.4,
            s3: width * 0.3,
            h1: height,
            h2: height * 0.2,
            h3: height * 0.2,
            circuit: Math.round(
                Math.sqrt(
                    width * 0.3 * width * 0.3 + (height - height * 0.2) * (height - height * 0.2)
                )
                    + Math.sqrt(
                        width * 0.3 * width * 0.3
                            + (height - height * 0.2) * (height - height * 0.2)
                    )
                    + height * 0.2
                    + height * 0.2
                    + width
            ),
            type: variant || 'DLC',
        },
        arc: {
            shape: 'arc',
            h1: height * 0.7,
            h2: height * 0.3,
            width: width,
            height: height,
            circuit: Math.round(
                (3.1416
                    * ((3 / 2) * (height * 0.3 + width / 2)
                        - Math.sqrt((height * 0.3 * width) / 2)))
                    / 2
                    + height * 0.7 * 2
                    + width
            ),
            arcType: 'radialArc',
            type: variant || 'F',
        },
    };
    setShapeVariant(defaultDimensions[shape], width, height);
    return defaultDimensions[shape];
}

/**
 * Funkcja getArcRadius
 * @param  {object} coords Koordynaty
 */
export function getArcRadius(coords: { x: number; y: number }[]) {
    const aa = coords[0].x;
    const bb = coords[0].y;
    const cc = coords[1].x;
    const dd = coords[1].y;
    const ee = coords[2].x;
    const ff = coords[2].y;

    const linchk = cc * ff + aa * dd + ee * bb - aa * ff - cc * bb - ee * dd;
    if (linchk === 0) {
        const slope = Math.round(100000 * ((bb - ff) / (aa - ee))) / 100000;
        const yint = Math.round(100000 * (bb - (aa * (bb - ff)) / (aa - ee))) / 100000;
        return null;
    } else if (linchk !== 0) {
        const hh =
            ((dd - ff) * (aa * aa + bb * bb - cc * cc - dd * dd)
                - (dd - bb) * (ee * ee + ff * ff - cc * cc - dd * dd))
            / (2 * linchk);
        const kk =
            ((ee - cc) * (aa * aa + bb * bb - cc * cc - dd * dd)
                - (aa - cc) * (ee * ee + ff * ff - cc * cc - dd * dd))
            / (2 * linchk);
        const rr = Math.sqrt((aa - hh) * (aa - hh) + (bb - kk) * (bb - kk));
        return rr;
    }
}

/**
 * Funkcja ustawiania wariantu kształtu
 */
function setShapeVariant(shape: Shape, width: number, height: number) {
    switch (shape.shape) {
        case 'triangle':
            switch (shape.type) {
                case 'F':
                    shape.s1 = width / 2;
                    shape.s3 = width - shape.s1;
                    break;
                case 'L':
                    shape.s1 = width;
                    shape.s3 = 0;
                    break;
                case 'R':
                    shape.s1 = 0;
                    shape.s3 = width;
                    break;
            }
            break;

        case 'poligon':
            switch (shape.type) {
                case 'SLT':
                    shape.s1 = width;
                    shape.s2 = 0;
                    shape.s3 = 0;

                    shape.h1 = height;
                    shape.h2 = 0;
                    shape.h3 = height * 0.2;
                    break;
                case 'SRT':
                    shape.s1 = 0;
                    shape.s2 = 0;
                    shape.s3 = width;

                    shape.h1 = height;
                    shape.h2 = height * 0.2;
                    shape.h3 = 0;
                    break;
                case 'SLS':
                    shape.s1 = width * 0.3;
                    shape.s2 = width - shape.s1;
                    shape.s3 = 0;

                    shape.h1 = height;
                    shape.h2 = 0;
                    shape.h3 = 0;
                    break;
                case 'SRS':
                    shape.s1 = 0;
                    shape.s3 = width * 0.3;
                    shape.s2 = width - shape.s3;

                    shape.h1 = height;
                    shape.h2 = 0;
                    shape.h3 = 0;
                    break;
                case 'SLC':
                    shape.s1 = width * 0.3;
                    shape.s2 = width - shape.s1;
                    shape.s3 = 0;

                    shape.h1 = height;
                    shape.h2 = 0;
                    shape.h3 = height * 0.2;
                    break;
                case 'SRC':
                    shape.s1 = 0;
                    shape.s3 = width * 0.3;
                    shape.s2 = width - shape.s3;

                    shape.h1 = height;
                    shape.h2 = height * 0.2;
                    shape.h3 = 0;
                    break;
                case 'DLT':
                    shape.s1 = width * 0.5;
                    shape.s2 = 0;
                    shape.s3 = width * 0.5;

                    shape.h1 = height;
                    shape.h2 = height * 0.2;
                    shape.h3 = height * 0.2;
                    break;
                case 'DLS':
                    shape.s1 = width * 0.3;
                    shape.s2 = width - width * 0.6;
                    shape.s3 = width * 0.3;

                    shape.h1 = height;
                    shape.h2 = 0;
                    shape.h3 = 0;
                    break;
                case 'DLC':
                    shape.s1 = width * 0.3;
                    shape.s2 = width - width * 0.6;
                    shape.s3 = width * 0.3;

                    shape.h1 = height;
                    shape.h2 = height * 0.2;
                    shape.h3 = height * 0.2;
                    break;
            }
            break;
    }
    return shape;
}

/**
 * Funkcja ustawiania globalnego rozmiaru
 */
export function getGlobalDimensions(shape: Shape) {
    switch (shape.shape) {
        case 'rect':
            return {
                width: Number(shape.width),
                height: Number(shape.height),
            };
        case 'triangle':
            return {
                width: Number(shape.s1) + Number(shape.s3),
                height: Number(shape.height),
            };
        case 'circle':
            return {
                width: Number(shape.d),
                height: Number(shape.d),
            };
        case 'poligon':
            return {
                width: Number(shape.s1) + Number(shape.s2) + Number(shape.s3),
                height: [shape.h1, shape.h2, shape.h3]
                    .filter((h: number) => h > 0)
                    .reduce((max: number, h: number) => h > max ? h : max, 0)
            };
        case 'arc':
            return {
                width: Number(shape.width),
                height: Number(shape.h1) + Number(shape.h2),
            };
    }
}

/**
 * Funkcja przeliczajaca obwód koła
 */
export function getCircuit(shape: Shape) {
    switch (shape.shape) {
        case 'rect':
            return (shape.width + shape.height) * 2;
        case 'triangle':
            return Math.round(
                Math.sqrt(shape.height * shape.height + shape.s1 * shape.s1)
                    + Math.sqrt(shape.height * shape.height + shape.s3 * shape.s3)
                    + shape.s1
                    + shape.s3
            );
        case 'circle':
            return Math.round(shape.d * 3.1416);
        case 'poligon':
            return Math.round(
                Math.sqrt(Math.pow(shape.s1, 2) + Math.pow(shape.h1 - shape.h3, 2))
                    + Math.sqrt(Math.pow(shape.s3, 2) + Math.pow(shape.h1 - shape.h2, 2))
                    + shape.h3
                    + shape.h2
                    + shape.s2
                    + shape.s1
                    + shape.s2
                    + shape.s3
            );
        case 'arc':
            return shape.type === 'F'
                ? Math.round(
                      (3.1416
                          * ((3 / 2) * (shape.h2 + shape.width / 2)
                              - Math.sqrt((shape.h2 * shape.width) / 2)))
                          / 2
                          + shape.h1
                          + shape.h1
                          + shape.width
                  )
                : Math.round(
                      (3.1416
                          * ((3 / 2) * (shape.h2 + shape.width)
                              - Math.sqrt(shape.h2 * shape.width)))
                          / 4
                          + shape.h2
                          + shape.h1
                          + shape.h1
                          + shape.width
                  );
    }
}
