import angular from 'angular';

/**
 * Kontroler akcesoriów
 * @param {object} $scope                 Angular Scope
 * @param {object} $timeout               Timeout
 * @param {object} AccessoriesService     Fabryka akcesoriów
 * @param {object} MosquitoSystemsService Fabryka systemów siatek
 */
export default function AccessoriesCtrl(
    $scope,
    $timeout,
    Core,
    AccessoriesService,
    ConfigurationsService,
    DrawService,
    $filter,
    EventBusService,
    ScrollbarService
) {
    'ngInject';

    var vm = this;

    const conf = ConfigurationsService.conf.Current;

    vm.colors = [];
    vm.refresh = refresh;
    vm.openModalAccessories = () => {
        AccessoriesService.openModalAccessories().then(() => {
            ScrollbarService.update();
        });
    };
    vm.remove = () => {
        AccessoriesService.remove();
        ScrollbarService.update();
    };
    vm.getCountHoles = AccessoriesService.getCountHoles.bind(AccessoriesService);
    vm.placeDowelHole = AccessoriesService.placeDowelHole.bind(AccessoriesService);
    vm.hasDowelHole = hasDowelHole;
    vm.hasVentilatorHole = hasVentilatorHole;
    vm.countUp = AccessoriesService.countUp.bind(AccessoriesService);
    vm.countDown = AccessoriesService.countDown.bind(AccessoriesService);
    vm.countPrice = AccessoriesService.countPrice.bind(AccessoriesService);
    vm.subStep = 1;
    vm.goToSubstep = goToSubstep;

    vm.drawOptions = angular.merge({}, DrawService.options, {
        shape: {
            buttons: [
                {
                    class: 'ico-plus',
                    condition: response =>
                        (response.index === 'bottom' && conf.Shape.shape !== 'circle')
                        || (response.index !== 'bottom'
                            && !conf.hasRoller
                            && conf.Shape.shape === 'rect'),
                    title: $filter('translate')('INTERFACE|Wybierz akcesoria dla boku'),
                    onClick: response =>
                        AccessoriesService.openModalAccessories(response.index).then(() => {
                            ScrollbarService.update();
                        }),
                    positions: ['bottom', 'left', 'right', 'top'],
                },
            ],
        },
        sash: {
            buttons: [
                {
                    class: 'ico-plus',
                    positions: ['center'],
                    title: $filter('translate')('INTERFACE|Wybierz akcesoria dla kwatery'),
                    condition: response => typeof response.sash.parentId === 'undefined',
                    onClick: response => {
                        const sash = Core.fIdO(conf.Sashes, response.sash.id);
                        AccessoriesService.openModalAccessories(sash, sash.index).then(() => {
                            ScrollbarService.update();
                        });
                    },
                },
            ],
        },
        onInterfaceClick: () => {
            $scope.$digest();
        },
    });

    if (AccessoriesService.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init())
    );
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    ScrollbarService.update();

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        vm.colors = AccessoriesService.colorsAll;
    }

    /**
     * Funkcja odświeżajaca
     */
    function refresh() {
        ScrollbarService.update();
    }

    /**
     * Funkcja sprawdzajaca dyble
     * @param  {object}  accessories Akcesoria
     */
    function hasDowelHole(accessories) {
        return accessories.some(function(el) {
            return el.accessory.type == 'dowel_hole';
        });
    }

    /**
     * Funkcja sprawdzajaca otwór pod nawiewnik
     * @param  {object}  accessories Akcesoria
     */
    function hasVentilatorHole(accessories) {
        return accessories.some(function(el) {
            return el.accessory.type == 'ventilator_hole';
        });
    }

    function goToSubstep(subStep) {
        vm.subStep = subStep;
    }
}
