import { Injectable } from '@angular/core';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { HardwareVisualization } from '@icc/common/data-types';
import { FillingAccessorySpace } from '@icc/common/data-types/Filling';

@Injectable()
export class AccessoriesSpaceService {

    constructor(
        private configurationsService: ConfigurationsService<
            'window' | 'roller_shutter' | 'external_blind' | 'garage_door' | 'door' | 'accessory' | 'mosquito'
        >,
    ) { }

    getAccessorySpaces(accessory, list = false) {
        const spaces = [];
        const conf = this.configurationsService.conf.Current;
        const visualizations = accessory.visualizations && Object.values(accessory.visualizations) || [];
        const visualizationsInSpace = visualizations.filter((visualization: HardwareVisualization) => visualization.type === 'space');

        if (conf.type === 'door' && visualizationsInSpace.length !== 0) {
            const activeSash = conf?.Sashes.find(sash => ['DRA', 'DRP', 'DOA', 'DOP'].indexOf(sash.type.type) > -1);
            const sizeId = conf.doorSizes.sashSizeId;
            let fillingAccessorySpaces = Object.values(activeSash?.glazing.fillingAccessorySpaces || {});
            const fillingSpacesTags = fillingAccessorySpaces.map((space: any) => space.tag);
            const accessoriesInSpaces = conf.Accessories.filter((a: any) => a.accessory.spaceId && a.accessory.id !== accessory.id );
            const accessoriesSpacesId = this.getAccessorySpaceId(conf, accessory);
            if (activeSash && fillingAccessorySpaces && fillingAccessorySpaces.length > 0 && sizeId && (accessoriesInSpaces.length < fillingSpacesTags.length || list)) {
                fillingAccessorySpaces = fillingAccessorySpaces.filter((f: FillingAccessorySpace) => sizeId && f.sizes.includes(sizeId) && !accessoriesSpacesId.includes(f.id));
                if (fillingAccessorySpaces.length === 0) {
                    return spaces
                }

                let visualization;
                for (const space of fillingAccessorySpaces) {
                    visualization = visualizationsInSpace.find((v: HardwareVisualization) =>
                        v.opening.width <= space.opening.width
                        && v.opening.height <= space.opening.height
                        && v.internal.width <= space.internal.width
                        && v.internal.height <= space.internal.height
                        && v.external.width <= space.external.width
                        && v.external.height <= space.external.height
                        && (v.defaultTag === space.tag || v.tags.includes(space.tag))
                    );
                    if (visualization) {
                        spaces.push(space);
                    }
                }
            }
        }
        return spaces;
    }

    checkAccessorySpace(accessory, space) {
        const conf = this.configurationsService.conf.Current;
        const accessories = conf.Accessories.filter((a: any) => a.accessory.id !== accessory.id);
        for (const a of accessories) {
            const colorId = a.accessory.price_source === 'color' && a.color
                ? a.accessory.color.id
                : '-1';
            const visualization: HardwareVisualization = a.accessory.visualizations[colorId];
            if (visualization && visualization.space && visualization.space.id === space.id) {
                const accessorySpaces = this.getAccessorySpaces(a.accessory, true).filter((s: any) => s.id !== space.id);
                if (accessorySpaces.length !== 0) {
                    visualization.space = accessorySpaces[0];
                    a.accessory.spaceId = accessorySpaces[0].id;

                }
            }
        }
    }

    getAccessorySpaceId(conf, accessory) {
        return conf.Accessories.filter((a: any) => {
            const colorId = a.accessory.price_source === 'color' && accessory.color
                ? accessory.accessory.color.id
                : '-1';
            const v: HardwareVisualization = a.accessory.visualizations[colorId];
            if (!v) {
                return false;
            }
            const tags = Array.from(new Set([...v.tags, v.defaultTag]));
            return v && v.type === 'space' && tags.length === 1;
        }).map((a: any) => {
            const colorId = a.accessory.price_source === 'color' && accessory.color
                ? accessory.accessory.color.id
                : '-1';
            const v: HardwareVisualization = a.accessory.visualizations[colorId];
            return v.space.id;
        });
    }
}
