import { Injectable } from '@angular/core';
import { ProfilesService } from '../profiles.service';
import {TimeLimit, TimeLimitSegment} from './time-limit-functions';

@Injectable()
export class TimeLimitProfileService {

    constructor(
        private profilesService: ProfilesService,
    ) {
    }

    /**
     * Pobiera terminy realizacji profili.
     *
     * @param {object} profiles   Profile
     * @return {number} Terminy realizacji profili.
     */
    @TimeLimit({
        shortName: 'profile',
        data: {
            profiles : 'conf.UsedProfiles',
            timeLimitsProfiles: 'data.timeLimitsProfiles',
            system: 'conf.System',
            colors: 'conf.Colors',
        }
    })
    profileTimeLimit({timeLimits, profiles, timeLimitsProfiles, system, colors}): TimeLimitSegment[] {
        if (profiles == null || !profiles.length) {
            return null;
        }

        const segments = [];

        profiles.forEach(profile => {
            segments.push(this.getTimeLimitFromProfile(timeLimits, timeLimitsProfiles, profile, 'profile', system, colors));
        });

        return segments;
    }

    getTimeLimitFromProfile(timeLimits, timeLimitsProfiles, profile, type, system?, colors?) {
        if (profile == null || !profile.id || !timeLimitsProfiles || !timeLimitsProfiles[profile.priceLevelId]) {
            return;
        }

        const timeLimitsForPriceLevel = timeLimitsProfiles[profile.priceLevelId];
        const timeLimitsForProfile = timeLimitsForPriceLevel[profile.id] || timeLimitsForPriceLevel['-1'];
        let timeLimitId = timeLimitsForProfile[0];
        if (system != null && colors != null) {
            const colorGroups = this.profilesService.getProfileColorGroups(system, colors);
            if (colorGroups) {
                timeLimitId = timeLimitsForProfile
                    .find(tlProfile => tlProfile.side === colorGroups.side
                        && (colorGroups.colorGroups.includes(~~tlProfile.colorGroup)
                        || tlProfile.colorGroup == null && !colorGroups.colorGroups.length)
                        && (colorGroups.colorGroupsOut.includes(~~tlProfile.colorGroupOut)
                        || tlProfile.colorGroupOut == null && !colorGroups.colorGroupsOut.length)
                    );
            }
        }

        if (!timeLimitId) {
            return;
        }

        return {
            type,
            value: timeLimits[timeLimitId.timeLimit],
            data: {
                id  : profile.id,
                name: profile.name,
            }
        };
    }
}
