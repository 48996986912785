import $ from 'jquery';

/**
 * Kontroler modalu historii usterki
 * @param {object} $uibModalInstance     Okno modal
 * @param {object} $timeout              Timeout
 * @param {object} defectsData           Dane usterek
 * @param {object} defect                Usterka
 */
export default function ModalComplaintDefectHistoryCtrl($uibModalInstance, $timeout, Core, defectsData, defect, ScrollbarService) {
    'ngInject';

    var vm                  = this;
    vm.close                = close;
    vm.refresh              = refresh;

    init();

    /**
     * Funkcja inicjująca
     */
    function init() {
        vm.productTypes    = Core.arrayOfObjectsToList(defectsData.productTypes);
        vm.locations       = Core.arrayOfObjectsToList(defectsData.locations);
        vm.defectTypes     = Core.arrayOfObjectsToList(defectsData.defectTypes);
        vm.defectSizes     = Core.arrayOfObjectsToList(defectsData.defectSizes);
        vm.materials       = Core.arrayOfObjectsToList(defectsData.materials);
        vm.sides           = Core.arrayOfObjectsToList(defectsData.sides);
        vm.areas           = Core.arrayOfObjectsToList(defectsData.areas);
        vm.numberOfDefects = Core.arrayOfObjectsToList(defectsData.numberOfDefects);
        vm.defectOwners    = Core.arrayOfObjectsToList(defectsData.defectOwners);
        vm.costAllocations = Core.arrayOfObjectsToList(defectsData.costAllocations);
        vm.providers       = Core.arrayOfObjectsToList(defectsData.providers);
        vm.carers          = Core.arrayOfObjectsToList(defectsData.carers);
        vm.defect          = defect;
    }

    /**
     * Funkcja zamykania okna modal
     */
    function close() {
        $uibModalInstance.close();
    }

    /**
     * Funkcja odświeżajaca
     */
    function refresh() {
        ScrollbarService.update();
    }
}
