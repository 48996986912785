import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { GlazingBeadProfile, Profile } from '@icc/window';

@Component({
    selector: 'icc-glazing-bead-page',
    templateUrl: './glazing-bead-page.component.html',
    styleUrls: ['./glazing-bead-page.component.scss'],
})
export class GlazingBeadPageComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Wybierz listwę przyszybową');
    public options = [];

    glazingBeads: iccListItem[] = [];
    selectedBeadId: number | null = null;
    constructor(@Inject(ICC_PAGE_DATA) private pageData: {
        beads: Profile[],
        selectBead: GlazingBeadProfile
    }, private sharedFacade: SharedFacade) {
        super();
        this.glazingBeads = this.pageData.beads.map(bead => ({
            id: Number(bead.id),
            title: bead.name,
            imageUrl: `/files/profile/${bead.img}`,
            description: `${bead.name}`,
        }));
        if (this.pageData.selectBead.profileId) {
            this.selectedBeadId = Number(this.pageData.selectBead.profileId);
        }
    }

    ngOnInit() {}

    selectBead(item: iccListItem) {
        const bead = this.pageData.beads.find(f => Number(f.id) === item.id);
        this.sharedFacade.closePage({
            glazingBead: bead,
        });
    }
}
