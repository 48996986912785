import { Component, OnInit, Inject } from '@angular/core';
import { ModalService } from '@icc/helpers';
import {
    APP_CONFIG,
    AppConfigFactory,
    UserService,
} from '@icc/common';
import { PriceDetailsComponent, ProductFeaturesComponent, _, StepComponent } from '@icc/configurator/shared';

@Component({
    selector: 'icc-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent extends StepComponent implements OnInit {
    static stepName = _('INTERFACE|Szczegóły');
    static stepIcon = {
        ligature: 'info',
    };

    public configurator = 'window';
    public stepId = 'details';
    public title = _('INTERFACE|Szczegóły');
    public options = [
        {
            title: _('OFFER|Szczegóły wyceny'),
            component: PriceDetailsComponent,
            show: () => this.showPriceDetails
        },
        {
            title: _('OFFER|Cechy produktów'),
            component: ProductFeaturesComponent,
            show: () => this.showProductFeatures
        }
    ]

    details: any = {};
    priceElementsVisible = false;
    showPriceDetails = false;
    showProductFeatures = true;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private modalService: ModalService,
        private userService: UserService
    ) {
        super();
    }

    ngOnInit() {
        const user = this.userService.get();
        this.showPriceDetails = this.config().IccConfig.Configurators.showPriceDetails && user.access == 'producent' && !this.userService.b2c;
         if(this.config().preset === 'b2c') {
            this.showProductFeatures = this.config().IccConfig.Configurators.showProductFeaturesModalB2C;
        }
    }

    openPriceDetails() {
        this.modalService.open({
            pageComponent: PriceDetailsComponent,
        });
    }

}
