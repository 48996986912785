import { garageDoorStepsMap } from '@icc/configurator/garage-door';
import { pleatedBlindStepsMap } from '@icc/configurator/pleated-blind';
import { StepComponentType } from '@icc/configurator/shared';
import { windowStepsMap } from '@icc/configurator/window';
import { doorStepsMap } from '@icc/configurator/door';
import { shutterStepsMap } from '@icc/configurator/shutter';
import { mosquitoScreenStepsMap } from '@icc/configurator/mosquito-screen';
import { accessoryStepsMap } from '@icc/configurator/accessory';
import { awningStepsMap } from '@icc/configurator/awning';
import { coupledWindowStepsMap } from '@icc/configurator/coupled-window';

export const stepsMap: {
    [configurator: string]: {
        [stepId: string]: StepComponentType;
    };
} = {
    window: windowStepsMap,
    hs: windowStepsMap,
    folding_door: windowStepsMap,
    sliding_door: windowStepsMap,
    door: doorStepsMap,
    garage_door: garageDoorStepsMap,
    roller_shutter: shutterStepsMap,
    external_blind: shutterStepsMap,
    mosquito: mosquitoScreenStepsMap,
    complementary_goods: accessoryStepsMap,
    awning: awningStepsMap,
    pleated_blind: pleatedBlindStepsMap,
    coupled_window: coupledWindowStepsMap
};
