import { core } from '../../../helpers';

/**
 * Rolety - zmiana wymiarów rolet w oknach - zapisywanie w posczególnych roletkach
 * różnicy przycięcia względem wymiarów konstrukcji
 */
export function changesInVersion8(oldConfiguration, dataRequiredToUpdate) {
    const configuration = core.copy(oldConfiguration);
    if (configuration.rollerShutter && configuration.rollerShutter.shutters.length) {
        const railsHeightModify = configuration.rollerShutter.railsHeightModify;
        let maxHeight = 0;
        configuration.rollerShutter.shutters.forEach(shutter => {
            if (railsHeightModify) {
                shutter.railsHeightModify = railsHeightModify;
                shutter.height = configuration.height + railsHeightModify;
            } else {
                shutter.railsHeightModify = shutter.height - configuration.height;
            }
            if (shutter.height > maxHeight) {
                maxHeight = shutter.height;
            }
        });
        configuration.rollerShutter.rollerHeight = maxHeight;
    }
    return configuration;
}
