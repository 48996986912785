import { isUndefined } from '@icc/helpers';

/**
 * Funkcja podkategorii szyby
 */
export function GlassSubCatsFilter() {
    'ngInject';

    return function(cats, categoryId) {
        const catArr = [];
        Object.keys(cats).forEach(key => {
            catArr.push(cats[key]);
        });
        if (isUndefined(categoryId) || categoryId === null || categoryId === '') {
            return [];
        }
        return catArr.filter(function(gl) {
            if (Number(gl.parent_id) === Number(categoryId)) {
                return true;
            }
            return false;
        });
    };
}
