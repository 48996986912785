import * as angular from 'angular';
import * as $ from 'jquery';
import { filterAccessories } from './accessories.service';

/**
 * Funkcja filtra akcesoriów
 */
export default function AccessoriesFilter() {
    'ngInject';

    return (accessories, categoryId, subcatId) => {
        const accessoriesArr = [];
        angular.forEach(accessories, obj => {
            accessoriesArr.push(obj);
        });
        $('.scroll.ps-container').scroll();
        return filterAccessories(accessoriesArr, categoryId, subcatId);
    };
}
