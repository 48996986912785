import { PleatedBlindFacade } from './pleated-blind.facade';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { isUndefined } from '@icc/helpers';
import { Injectable, OnDestroy } from '@angular/core';
import { EventBusService } from '@icc/common';

@Injectable()
export class PleatedBlindInitService implements OnDestroy{
    systems = [];
    variants = [];
    fabrics = [];
    colors = [];
    drives = [];
    guidanceTypes = [];
    closureTypes = [];

    private subscriptions: any[] = [];

    constructor(
        private currentConfiguratorService: CurrentConfiguratorService,
        private blindFacade: PleatedBlindFacade,
    ) {}

    /**
     * Funkcja inicjujaca
     */
    init() {
        if (this.currentConfiguratorService.conf === 'pleated_blind') {
            this.initializeValues();

            this.blindFacade.setSystem(this.systems[0])
            this.blindFacade.setVariant(this.variants[0])
            this.setDefaultFabrics(this.fabrics[0]);
            this.blindFacade.setProfilesColor(this.colors[0])
            this.setDefaultDrive(this.drives[0]);
            this.blindFacade.setDriveSide('left');
            this.setDefaultGuidance(this.guidanceTypes[0]);
            this.blindFacade.setClosureType(this.closureTypes[0]);
        } else {
            return;
        }
    }

    /**
     * Ustawienie wartości
     */
    initializeValues() {
        this.subscriptions.push(
            this.blindFacade.availableSystems$.subscribe(systems => {
                this.systems = systems;
            }),

            this.blindFacade.availableVariants$.subscribe(variants => {
                this.variants = variants;
            }),

            this.blindFacade.availableFabrics$.subscribe(fabrics => {
                this.fabrics = fabrics;
            }),

            this.blindFacade.availableColors$.subscribe(colors => {
                this.colors = colors;
            }),

            this.blindFacade.availableDrives$.subscribe(drives => {
                this.drives = drives;
            }),

            this.blindFacade.availableGuidanceTypes$.subscribe(guidanceTypes => {
                this.guidanceTypes = guidanceTypes;
            }),

            this.blindFacade.availableClosureTypes$.subscribe(closureTypes => {
                this.closureTypes = closureTypes;
            }),
        )
    }

    setDefaultFabrics(fabric) {
        fabric = {...fabric, color: this.colors[0]}
        this.blindFacade.setMainFabric(fabric)
        if (this.variants[0].double_fabric) {
            this.blindFacade.setAdditionalFabric(fabric)
        }
    }

    setDefaultDrive(drive) {
        if (drive.drive_type === 'static_handle' || drive.drive_type === 'movable_handle') {
            drive = {...drive, color: this.colors[0] }
        } 
        this.blindFacade.setDrive(drive);
    }

    setDefaultGuidance(guidance) {
        if (this.drives[0].guidance_type === 'fence') {
            guidance = {...guidance, color: this.colors[0] }
        }
        this.blindFacade.setGuidanceType(guidance);
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }
}
