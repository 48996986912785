import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { downgradeInjectable } from '@angular/upgrade/static';

import { ProfileSetsService } from '@icc/common/profile-sets.service';
import { ProfilesService } from '@icc/common/profiles.service';
import ProfilesFilter from './ProfilesFilter';
import { ProfileSetsModalComponent } from './profile-sets-modal/profile-sets-modal.component';
import { ProfilesModalComponent } from './profiles-modal/profiles-modal.component';
import { ProfilesShapesModalComponent } from './profiles-shapes-modal/profiles-shapes-modal.component';
import { ProfilesPriceService } from '@icc/common/profiles-price.service';;
import { ProfilesModalService, ProfileColorsService } from '@icc/configurator/window';

export default angular
    .module('icc.configurator.steps.window.profiles', [])
    .factory('ProfileSetsService', downgradeInjectable(ProfileSetsService))
    .factory('ProfilesService', downgradeInjectable(ProfilesService))
    .factory('ProfilesPriceService', downgradeInjectable(ProfilesPriceService))
    .factory('ProfilesModalService', downgradeInjectable(ProfilesModalService))
    .factory('ProfileColorsService', downgradeInjectable(ProfileColorsService))
    .component('profileSetsModal', ProfileSetsModalComponent)
    .component('profilesModal', ProfilesModalComponent)
    .component('profilesShapesModal', ProfilesShapesModalComponent)
    .filter('profiles', ProfilesFilter).name;
