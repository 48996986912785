<h2 class="modal-title" mat-dialog-title>
    <span *ngIf="!data.issues.length">{{ 'CONFIGURATOR|POTWIERDZENIE!' | translate }}</span>
    <span *ngIf="data.issues.length">{{ 'CONFIGURATOR|WYSTĄPIŁY BŁĘDY!' | translate }}</span>
</h2>
<mat-dialog-content>
    <h3 class="summary-message" *ngIf="data.shopMessage && !data.issues.length">{{'OFFER|Konstrukcja została dodana do koszyka' | translate}}</h3>
    <h3 class="summary-message" *ngIf="!data.shopMessage && data.message">{{data.message}}</h3>
    <h6 class="summary-txt" *ngIf="!data.issues.length">{{ 'CONFIGURATOR|Wybierz jedną z poniższych opcji' | translate}}:</h6>
    <h6 class="summary-txt" *ngIf="data.issues.length">{{ 'CONFIGURATOR| Błędy podczas konfiguracji pozycji' | translate}}:</h6>
    <div style="font-size: smaller; text-align: left; margin-bottom: 50px;">
        <span *ngFor="let issue of data.issues" [innerHTML]="(issue.message| translate) + '<br>'"></span>
    </div>
    <div id='obraz'></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button *ngFor="let option of data.actions" [mat-dialog-close]="option">{{ option.title }}</button>
</mat-dialog-actions>
