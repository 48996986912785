import { Component, Inject, OnInit } from '@angular/core';
import {
    AppConfigFactory,
    APP_CONFIG,
    core,
    TranslateService,
    WindowActiveConfiguration,
} from '@icc/common';
import { MosquitoActiveConfiguration } from '@icc/common/configurations/MosquitoActiveConfiguration';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { SharedFacade } from '@icc/configurator/shared';
import { ICC_PAGE_DATA, isDefined, PageComponent } from '@icc/helpers';
import { WindowFacade } from '../+state/window.facade';
import { ColorsService as MosquitoColorsService } from '@icc/legacy/configurator/steps/mosquito/colors.service';
import { MontagesService as MosquitoMontagesService } from '@icc/legacy/configurator/steps/mosquito/montages.service';
import { DimensionsService as MosquitoDimensionsService } from '@icc/legacy/configurator/steps/mosquito/dimensions.service';
import { TypesService as MosquitoTypesService } from '@icc/legacy/configurator/steps/mosquito/types.service';
import { SystemsService as MosquitoSystemsService } from '@icc/legacy/configurator/steps/mosquito/systems.service';
import { UnitConverterService, iccListItem } from '@icc/configurator/ui';

@Component({
    selector: 'icc-sash-mosquito',
    templateUrl: './sash-mosquito.component.html',
    styleUrls: ['./sash-mosquito.component.scss'],
})
export class SashMosquitoComponent extends PageComponent implements OnInit {
    public title = this.translateService.instant('MOSQUITO|Dodawanie siatki do');
    public options = [];

    mosquito: MosquitoActiveConfiguration | null = null;
    montages: iccListItem[] = [];
    systems: iccListItem[] = [];
    types: iccListItem[] = [];
    windowLines;

    dimensionUnit = this.unitConverterService.getUnit();
    mosquitoHoldersWidth = this.config().IccConfig.Configurators.window.mosquitoHoldersWidth;

    // eslint-disable-next-line max-params
    constructor(
        private windowFacade: WindowFacade,
        private sharedFacade: SharedFacade,
        private translateService: TranslateService,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            conf: WindowActiveConfiguration;
            sash: ActiveSash;
        },
        private mosquitoColorsService: MosquitoColorsService,
        private mosquitoMontagesService: MosquitoMontagesService,
        private mosquitoDimensionsService: MosquitoDimensionsService,
        private mosquitoTypesService: MosquitoTypesService,
        private mosquitoSystemsService: MosquitoSystemsService,
        private unitConverterService: UnitConverterService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {
        super();
        let subtitle = '';
        if (!this.pageData.sash) {
            subtitle = this.translateService.instant('ACCESSORY|całej konstrukcji');
        } else {
            subtitle =
                this.translateService.instant('ACCESSORY|kwatery ID:') + ' ' + pageData.sash.index;
        }

        this.title = this.translateService.instant('MOSQUITO|Dodawanie siatki do') + ' ' + subtitle;

        this.mosquito = (this.pageData.sash || this.pageData.conf).mosquito;
    }

    ngOnInit(): void {
        if (this.mosquito) {
            this.getWindowLines();
            this.getSystems();
            this.getColors();
            this.getTypes();
            this.getMontages();
        }
    }

    setMosquito(set: boolean) {
        this.mosquito = (this.pageData.sash || this.pageData.conf).mosquito = set
            ? this.getMosquito()
            : null;

        if (this.mosquito) {
            this.getWindowLines();
            this.getSystems();
            if (this.systems && this.systems.length > 0) {
                this.setSystem(this.systems[0]);
            }
        }
    }

    getColors() {
        if (this.mosquito?.System && this.mosquito.Type.id) {
            this.mosquitoColorsService.loadColorsBySystem(
                this.mosquito.System.id,
                this.mosquito.Type.id
            );
        }
    }

    setColors() {
        if (this.mosquito) {
            this.mosquito.Colors = {
                frame: undefined,
                panel: core.copy(this.mosquitoColorsService.panelColors[0]),
                net: core.copy(this.mosquitoColorsService.netColors[0]),
            };
            this.mosquitoColorsService.setColorSide(
                this.mosquito.Colors,
                'frame',
                this.mosquitoColorsService.frameColors[0]
            );
        }
    }

    getMontages() {
        this.montages = this.mosquitoMontagesService.getMontages(
            this.mosquito || this.pageData.conf
        ).map((montage) => ({
            id: montage.id,
            title: montage.name,
            imageUrl: '/files/mosquitomontage/' + montage.img,
        }));

        
    }

    setMontage(item: iccListItem) {
        if (this.mosquito) {
            const montages = this.mosquitoMontagesService.getMontages(
                this.mosquito || this.pageData.conf
            );
            const montage = montages.find(m => m.id === item.id);
            this.mosquito.Montage = montage;
        }
    }

    getMosquito() {
        return new MosquitoActiveConfiguration({
            Width: this.pageData.sash
                ? this.mosquitoDimensionsService.getFrameLightOuterWidth(
                      this.pageData.conf,
                      this.pageData.sash
                  )
                : this.pageData.conf.Width,
            Height: this.pageData.sash
                ? this.mosquitoDimensionsService.getFrameLightOuterHeight(
                      this.pageData.conf,
                      this.pageData.sash
                  )
                : this.pageData.conf.Height,
        });
    }

    getSystems() {
        const systems = this.mosquitoSystemsService.getSystems();
        this.systems = systems
            ?.filter(
                (system) =>
                    isDefined(this.windowLines[system.id]) &&
                    this.windowLines[system.id].some(
                        (el) => el.window_line_id == this.pageData.conf.System.id
                    )
            )
            .map((system) => ({
                id: system.id,
                title: system.name,
                imageUrl: '/files/windowline/' + system.title_image,
            }));
    }

    setSystem(item: iccListItem) {
        if (this.mosquito) {
            const systems = this.mosquitoSystemsService.getSystems();
            const system = systems?.find((s) => s.id === item.id);
            this.mosquito.System = system;
            this.mosquito.Name = system ? system.name : '';

            this.getTypes();
            this.setType(this.types[0]);

            this.getColors();
            this.setColors();
        }
    }

    getTypes() {
        this.types = this.mosquitoTypesService
            .getTypes(this.mosquito || this.pageData.conf)
            .map((type) => ({
                id: type.id,
                title: type.name,
                imageUrl: '/files/mosquitotype/' + type.img,
            }));
    }

    setType(item: iccListItem) {
        if (this.mosquito) {
            const types = this.mosquitoTypesService.getTypes(this.mosquito || this.pageData.conf);
            const type = types?.find((t) => t.id === item.id);
            this.mosquito.Type = type;

            if (type && type.has_direction && this.mosquito.Direction === null) {
                this.mosquito.Direction = 'left';
            } else if (type && !type.has_direction) {
                this.mosquito.Direction = null;
            }

            this.getMontages();
            this.setMontage(this.montages[0]);

            this.getWindowLines();
            this.setWindowLine(this.windowLines[0]);
            this.getColors();
            this.setColors();
        }
    }

    getWindowLines() {
        this.windowLines = this.mosquitoMontagesService.getWindowLines();
    }

    setWindowLine(line) {
        if (this.mosquito) {
            this.mosquito.WindowLine = line;
        }
    }

    openModalColor(type, color) {
        if (this.mosquito) {
            this.mosquitoColorsService.openModalColor(type, this.mosquito.Colors);
        }
    }

    close() {
        this.sharedFacade.closePage();
    }

    updateDimensions() {
        if (this.mosquito) {
            this.mosquito.HeightModify =
                this.mosquito.Height -
                this.mosquitoDimensionsService.getFrameLightOuterHeight(
                    this.pageData.conf,
                    this.pageData.sash
                );
            this.mosquito.WidthModify =
                this.mosquito.Width -
                this.mosquitoDimensionsService.getFrameLightOuterWidth(
                    this.pageData.conf,
                    this.pageData.sash
                );
        }
    }
}
