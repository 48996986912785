/**
 * Skrzydła aktywne
 */
const ACTIVE = ['K', 'D', 'DK', 'PSK', 'HS', 'DRA', 'OKL', 'OD', 'S', 'SU', 'SD', 'SWF', 'DOA'];
/**
 * Skrzydła bierne
 */
const PASSIVE = ['DS', 'DRP', 'DSC', 'ODS', 'DOP', 'DSH', 'ODSH'];
/**
 * Skrzydła otwierane do wewnątrz
 */
const INWARD_OPENING = ['K', 'D', 'DK', 'DS', 'DSH', 'PSK', 'HS', 'DRA', 'DRP', 'S'];
/**
 * Skrzydła otwierane do zewnątrz
 */
const OUTWARD_OPENING = ['OKL', 'OD', 'SWF', 'ODS', 'ODSH', 'DOA', 'DOP'];
/**
 * Skrzydła stałe
 */
const FIX = ['F', 'FIX'];
/**
 * Skrzydła przesuwne
 */
const SLIDING = ['PSK', 'HS', 'S', 'SU', 'SD'];
/**
 * Skrzydła harmonijkowe
 */
const FOLDING = [];
/**
 * Skrzydła rozwierne
 */
const TURN_HUNG = ['D', 'DK', 'DS', 'DSH', 'DSC', 'DRA', 'DRP', 'OD', 'ODS', 'ODSH', 'DOA', 'DOP'];
/**
 * Skrzydła uchylne
 */
const TILT = ['K'];

export {ACTIVE, PASSIVE, INWARD_OPENING, OUTWARD_OPENING, FIX, SLIDING, FOLDING, TURN_HUNG, TILT};
