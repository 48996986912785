<div class='modal__header'>
    <h2 mat-dialog-title>{{ 'CONFIGURATOR|Przyczyny braku wyceny' | translate }}</h2>
    <mat-icon (click)='onNoClick()'>close</mat-icon>
</div>
<mat-dialog-content class="mat-typography">
    <mat-list>
        <mat-list-item  *ngFor="let seg of segments">
            <span>{{seg.id}}. </span>
            <b>
                <span *ngIf="seg.translatedType">{{seg.translatedType}}</span>
                <span *ngIf="seg.translatedName">: {{seg.translatedName}}</span>
            </b>
            <mat-icon class='segment --valid' *ngIf='seg.baseValue!==null'>check</mat-icon>
            <mat-icon class='segment --invalid' *ngIf='seg.baseValue===null'>close</mat-icon>   
        </mat-list-item>
    </mat-list>
</mat-dialog-content>