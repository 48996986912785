import angular from 'angular';

export default function HederOfferFilter() {
    'ngInject';

    return function(data, parentId) {
        if (typeof data == 'undefined') {
            return false;
        }
        var out = [];
        if (typeof parentId == 'undefined') {
            angular.forEach(data, function(v) {
                if (!!!v.doc.parentId) {
                    out.push(v);
                }
            });
        } else {
            angular.forEach(data, function(v) {
                if (v.doc.parentId === parentId) {
                    out.push(v);
                }
            });
        }
        return out;
    };
}
