import { WicketDoorsService } from './../wicket-doors.service';
import { Subscription } from 'rxjs';
import { GarageDoorFacade } from './../+state/garage-door.facade';
import { Component } from '@angular/core';
import { _, StepComponent } from '@icc/configurator/shared';

@Component({
    selector: 'icc-wicket-door-color',
    templateUrl: './wicket-door-color.component.html',
    styleUrls: ['./wicket-door-color.component.scss'],
})
export class WicketDoorColorComponent extends StepComponent {
    static stepName = _('STEPS|Kolor furtki');
    static stepIcon = {
        ligature: 'palette',
    };

    public configurator = 'garage_door';
    public stepId = 'wicket_door_color';
    public title = _('GARAGE|Wybierz kolor furtki');

    selectedWicketDoorHandleColor$ = this.garageDoorFacade.selectedWicketDoorHandleColor$;
    selectedWicketDoorFrameProfilesColor$ = this.garageDoorFacade.selectedWicketDoorFrameProfilesColor$;

    constructor(
        private garageDoorFacade: GarageDoorFacade,
        private wicketDoorsService: WicketDoorsService
    ) {
        super();
    }

    selectWicketDoorFrameProfilesColor() {
        this.wicketDoorsService.openWicketDoorFrameProfilesColorsModal();
    }

    selectWicketDoorHandleColor() {
        this.wicketDoorsService.openWicketDoorHandleColorsModal();
    }
}
