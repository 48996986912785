import { Injectable } from '@angular/core';
import {TimeLimit, TimeLimitSegment} from './time-limit-functions';

@Injectable()
export class TimeLimitSystemService {

    /**
     * Pobiera termin realizacji systemu.
     *
     * @param {object} system   Linia produktu
     * @return {number} Termin realizacji systemu.
     */
    @TimeLimit({
        shortName: 'system',
        data: {
            system : 'conf.System',
        }
    })
    systemTimeLimit({timeLimits, system}): TimeLimitSegment[] {
        if (system == null || !system.id || !system.time_limit_id) {
            return null;
        }

        const timeLimitId = system.time_limit_id;

        return <TimeLimitSegment[]>[{
            type: 'system',
            value: timeLimits[timeLimitId],
            data: {
                id  : system.id,
                name: system.name,
            }
        }];
    }
}
