import { PleatedBlindConfiguration } from './PleatedBlindConfiguration';
import { ActiveConfiguration } from './ActiveConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { TimeLimitSegment } from '@icc/common/time-limit/time-limit-functions';
import { ActiveAccessory } from './parts/common';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';
import { Shape } from '@icc/window';

export class PleatedBlindActiveConfiguration implements ActiveConfiguration {
    type: 'pleated_blind' = 'pleated_blind';
    Name: string = '';
    Price: number = 0;
    PriceNoMargin: number = 0;
    PriceAfterDiscountsInCurrency: number = 0;
    PriceBeforePromotions: number = 0;
    PriceGross: number = 0;
    PriceGrossAfterDiscounts: number = 0;
    PriceGrossBeforePromotions: number = 0;
    Quantity: number = 1;
    Issues: any[] = [];
    PriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    PriceSegmentsBeforePromotions: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    PricePartsBeforePromotions: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    Discount: number = 0;
    timeLimit: number = 0;
    timeLimitsStack: TimeLimitSegment[] = [];
    Title = '';
    Description = '';
    Attachments = [];
    valid: {} = {};
    PriceValid = false;

    mainFabric;
    additionalFabric;
    profilesColor;
    System;
    variant;
    drive;
    driveSide: any = 'left';
    guidanceType;
    closureType;
    accessories: any[] = [];
    Shape: Shape = {
        shape: 'rect',
        width: 1500,
        height: 1500,
        circuit: 6000,
        s1: 1500,
        s2: 0,
        s3: 0,
        h1: 1500,
        h2: 0,
        h3: 1500,
    };
    weight: number = 0;

    drawData: any;

    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    constructor(configuration?, private configuratorsDataService?: ConfiguratorsDataService) {
        if (configuration) {
            if (PleatedBlindActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (PleatedBlindConfiguration.is(configuration) && configuratorsDataService) {
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.PriceAfterDiscountsInCurrency = configuration.priceAfterDiscountsInCurrency;
                    this.PriceGross = configuration.priceGross;
                    this.PriceGrossAfterDiscounts = configuration.priceGrossAfterDiscounts;
                    this.Quantity = configuration.quantity;
                    this.PriceSegments = configuration.priceSegments;
                    this.PriceSegmentsNoMargin = configuration.priceSegmentsNoMargin;
                    this.PriceSegmentsBeforePromotions = configuration.priceSegmentsBeforePromotions;
                    this.PriceParts = configuration.priceParts;
                    this.PricePartsNoMargin = configuration.pricePartsNoMargin;
                    this.PricePartsBeforePromotions = configuration.pricePartsBeforePromotions;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = configuration.title;
                    this.Description = configuration.description;
                    this.valid = configuration.valid;
                    this.timeLimit = configuration.timeLimit;
                    this.timeLimitsStack = configuration.timeLimitsStack;
                    this.attributes = configuration.attributes;

                    this.Shape = configuration.shape;
                    this.System = configuration.System;
                    this.drive = configuration.drive;
                    this.driveSide = configuration.driveSide;
                    this.guidanceType = configuration.guidanceType;
                    this.variant = configuration.variant;
                    this.mainFabric = configuration.mainFabric;
                    this.additionalFabric = configuration.additionalFabric;
                    this.profilesColor = configuration.profilesColor;
                    this.closureType = configuration.closureType;
                    this.accessories = configuration.accessories
                        .filter(accessory =>
                            configuratorsDataService.get('windowAccessories', accessory.id)
                        )
                        .map(
                            accessory =>
                                new ActiveAccessory(accessory, null, this.configuratorsDataService)
                        );

                }
            }
        }
        this.configuratorsDataService = null;
    }

    public static is(configuration): configuration is PleatedBlindActiveConfiguration {
        return configuration instanceof PleatedBlindActiveConfiguration || !configuration.$version;
    }
}

export type IPleatedBlindActiveConfiguration = Pick<
    PleatedBlindActiveConfiguration,
    keyof PleatedBlindActiveConfiguration
>;
