import { PleatedBlindFacade } from './../+state/pleated-blind.facade';
import { isNotNullOrUndefined } from '@icc/helpers';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { StepComponent, _ } from '@icc/configurator/shared';
import { Subscription } from 'rxjs';

import { Shape } from '@icc/window';
import { filter } from 'rxjs/operators';
import { ShapesComponent } from '../shapes/shapes.component';
import { UnitConverterService } from '@icc/configurator/ui';

@Component({
    selector: 'icc-dimension',
    templateUrl: './dimensions.component.html',
    styleUrls: ['./dimensions.component.scss'],
})
export class DimensionsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('CONFIGURATOR|Wymiary');
    static stepIcon = {
        ligature: 'settings_overscan',
    };

    public options = [
        {
            title: _('PLEATEDBLIND|Kształt konstrukcji'),
            component: ShapesComponent,
            icon: {
                ligature: 'category',
            },
            componentData: {
                setShape: () => this.setShape.bind(this),
                selectedShape: () => this.shape,
            },
        }
    ];

    public configurator = 'pleated_blind';
    public stepId = 'montage';
    public title = _('CONFIGURATOR|Wymiary');

    private subscriptions: Subscription[] = [];

    public dimensionsForm: FormGroup = this.fb.group({});
    public shape: Partial<Shape> | null = null;

    public fields: {
        name: string;
        field?: keyof Shape;
        highlight?: keyof Shape;
        value?: number;
    }[] = [];

    private dimensionsFields: {
        [key in Shape['shape']]: {
            name: string;
            field?: keyof Shape;
            highlight?: keyof Shape;
            show: (shape?: Partial<Shape>) => boolean;
            value?: (shape?: Partial<Shape>) => number;
            focus?: boolean;
        }[]
    } = {
        rect: [
            {
                name: 'width',
                field: 'width',
                show: () => true,
                focus: true,
            },
            {
                name: 'height',
                field: 'height',
                show: () => true,
            },
        ],
        triangle: [
            {
                name: 'h1',
                field: 'height',
                highlight: 'h1',
                show: () => true,
                focus: true,
            },
            {
                name: 's1',
                field: 's1',
                show: (shape: Shape) =>
                    (shape.type && ['F', 'L'].indexOf(shape.type) >= 0) || Number(shape.s1) > 0,
                focus: true,
            },
            {
                name: 's2',
                field: 's3',
                show: (shape: Shape) =>
                    (shape.type && ['F', 'R'].indexOf(shape.type) >= 0) || Number(shape.s3) > 0,
                focus: true,
            },
        ],
        circle: [
            {
                name: 'diameter',
                field: 'd',
                show: () => true,
                focus: true,
            },
            {
                name: 'perimeter',
                value: (shape: Shape) => Math.round(Number(shape.d) * 3.14 * 100) / 100,
                show: () => true,
            },
        ],
        poligon: [
            {
                name: 's1',
                field: 's1',
                show: (shape: Shape) =>
                    (shape.type
                        && ['SLT', 'SLS', 'SLC', 'DLT', 'DLS', 'DLC'].indexOf(shape.type) >= 0)
                    || Number(shape.s1) > 0,
                focus: true,
            },
            {
                name: 's2',
                field: 's2',
                show: (shape: Shape) =>
                    (shape.type
                        && ['SLS', 'SRS', 'SLC', 'SRC', 'DLS', 'DLC'].indexOf(shape.type) >= 0)
                    || Number(shape.s2) > 0,
            },
            {
                name: 's3',
                field: 's3',
                show: (shape: Shape) =>
                    (shape.type
                        && ['SRT', 'SRS', 'SRC', 'DLT', 'DLS', 'DLC'].indexOf(shape.type) >= 0)
                    || Number(shape.s3) > 0,
            },
            {
                name: 'h1',
                field: 'h1',
                show: () => true,
            },
            {
                name: 'h2',
                field: 'h2',
                show: (shape: Shape) =>
                    (shape.type && ['SRT', 'SRC', 'DLT', 'DLC'].indexOf(shape.type) >= 0)
                    || Number(shape.h2) > 0,
            },
            {
                name: 'h3',
                field: 'h3',
                show: (shape: Shape) =>
                    (shape.type && ['SLT', 'SLC', 'DLT', 'DLC'].indexOf(shape.type) >= 0)
                    || Number(shape.h3) > 0,
            },
        ],
        arc: [
            {
                name: 'h1',
                field: 'h1',
                show: () => true,
                focus: true,
            },
            {
                name: 'h2',
                field: 'h2',
                show: () => true,
            },
            {
                name: 'width',
                field: 'width',
                show: () => true,
            },
        ],
    };

    selectedShape$ = this.pleatedBlindFacade.selectedShape$;

    dimensionUnit = this.unitConverterService.getUnit();

    constructor(
        private fb: FormBuilder,
        private pleatedBlindFacade: PleatedBlindFacade,
        private unitConverterService: UnitConverterService
    ) {
        super();

        this.selectedShape$
            .pipe(
                filter(isNotNullOrUndefined),
            )
            .subscribe(shape => {
                this.shape = shape;
                const fields = this.dimensionsFields[shape.shape]
                    .filter(f => f.show(shape))
                    .map(f => ({
                        name: f.name,
                        field: f.field,
                        highlight: f.highlight || f.field,
                    }));
                const group = this.fb.group({});
                fields.forEach(field => {
                    if (field.field) {
                        group.addControl(
                            field.field,
                            this.fb.control(shape[field.field], {
                                updateOn: 'blur',
                            })
                        );
                    }
                });
                this.dimensionsForm = group;
                this.dimensionsForm.valueChanges.subscribe(value => {
                    const newShape: Shape = {
                        ...shape,
                        ...value,
                    };
                    this.setTempShapeDimensions(newShape);
                });
                this.fields = fields;
            });
    }

    ngOnInit() {}

    ngOnDestroy() {
      this.subscriptions.map(el => el.unsubscribe());
    }

    setTempShapeDimensions(newShape: Shape) {
        this.shape = newShape;
        if (this.shape.shape === 'arc' && Number(this.shape.h2) * 2 > Number(this.shape.width)) {
            this.shape.h2 = Math.floor(Number(this.shape.width) * 0.5);
        }
        if (this.shape.shape === 'circle') {
            this.shape.radius = Number(this.shape.d) / 2;
        }
        this.pleatedBlindFacade.setShape(this.shape as Shape);
    }

    /**
     * Funkcja ustawiajaca kształt
     * @param {object} shape Kształt
     */
    setShape(shape: Shape['shape'], variant?: Shape['type']) {
        const newShape = {...this.shape, shape: shape, type: variant}
        this.pleatedBlindFacade.setShape(newShape as Shape);
    }
}
