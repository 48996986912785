import angular from 'angular';

/**
 * Konstruktor conktrolera zamówień
 * @param {object} $rootScope          rootScope
 * @param {object} $scope              scope
 * @param {object} $uibModal              modal
 * @param {object} ElementsFactory     ElementsFactory
 */
export default function OrdersCtrl(
        $rootScope,
        $scope,
        $uibModal,
        ElementsFactory
        ) {
    'ngInject';
    var vm = this;

    // tablica ID zaznaczonych zamowien
    vm.selectedOrdersIds = [];

    vm.setSelectedOrdersIds             = setSelectedOrdersIds;
    vm.calculateMaterialsForOrder       = calculateMaterialsForOrder;
    vm.calculateMaterialsForOrders      = calculateMaterialsForOrders;
    vm.clearConfigurationElements       = clearConfigurationElements;
    vm.showOffersPositionsElementsModal = showOffersPositionsElementsModal;

    // gdy pojawi się rozbicie na elementy zaznaczonych ofert, pokazujemy modala
    $scope.$watch(function() {
        return ElementsFactory.ordersPositionsElements;
    }, function() {
        if (!angular.equals({}, ElementsFactory.ordersPositionsElements)) {
            vm.showOffersPositionsElementsModal();
        }
    }, true);

    /**
     * Ustawia w zmiennej tablicowej 'selectedOffersIds' id zaznaczonych ofert
     */
    function setSelectedOrdersIds() {
        vm.selectedOrdersIds = [];
        var selectedCheckboxes = document.querySelectorAll('.materials-order:checked');

        angular.forEach(selectedCheckboxes, function(value, key) {

            vm.selectedOrdersIds.push(value.id);
        });
    }

    /**
     * Po przeliczeniu zaznaczonych zamówień na elementy, pokazywanie modala i ukrywanie loadera
     */
    function showOffersPositionsElementsModal() {
        $rootScope.loader = false;

        var modalInstance = $uibModal.open({
            templateUrl: 'ordersPositionsElementsModal.html',
            controller : 'OrdersPositionsElementsModalCtrl as ordersPositionsElements',
            windowClass: 'alert-dimensions',
            resolve    : {
                ordersPositionsElements: function() {
                    return ElementsFactory.ordersPositionsElements;
                }
            }
        });

        modalInstance.result.then(function(layout) {
            clearConfigurationElements();
        });
    }

    /**
     * Oblicza materiały potrzebne dla pozycji konkretnego zamówienia
     * @param  {string} orderId Id zamówienia
     */
    function calculateMaterialsForOrder(orderId) {
        $rootScope.loader = true;
        ElementsFactory.calculateElementsForOrder(orderId);
    }

    /**
     * Oblicza materiały potrzebne dla pozycji zaznaczonych zamówień
     */
    function calculateMaterialsForOrders() {
        $rootScope.loader = true;
        ElementsFactory.calculateElementsForSelectedOrders(vm.selectedOrdersIds);
    }

    /**
     * Czyści elementy konfiguracji
     */
    function clearConfigurationElements() {
        ElementsFactory.initOrdersPositionsElements();
    }
}