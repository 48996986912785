import { Injectable, Inject } from '@angular/core';

import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { map, withLatestFrom } from 'rxjs/operators';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { currencyExchange } from '@icc/helpers';
import { SharedFacade } from './shared.facade';

@Injectable()
export class ConfiguratorFacade {
    constructor(
        private configurationsService: ConfigurationsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private sharedFacade: SharedFacade,
    ) {}

    price$ = this.configurationsService.configuration$.pipe(
        withLatestFrom(this.sharedFacade.currency$),
        map(([conf, currency]) => ({
            showPrice: (
                    WindowActiveConfiguration.is(conf)
                    && conf.Sashes.length > 0
                    && !conf.ChangedDimensions
                    && (this.config().preset === 'b2c' ? !this.config().hidePricesInB2cConfigurator : true)
                )
                || (
                    (this.config().preset === 'b2c' ? !this.config().hidePricesInB2cConfigurator : true)
                    &&
                    (conf.type === 'roller_shutter'
                    || conf.type === 'external_blind')
                    && conf.RollerShutter.shutters.length > 0
                )
                || (
                    (this.config().preset === 'b2c' ? !this.config().hidePricesInB2cConfigurator : true)
                    && (conf.type === 'accessory'
                    || conf.type === 'garage_door'
                    || conf.type === 'awning'
                    || conf.type === 'complementary_goods'
                    || conf.type === 'mosquito'
                    || conf.type === 'coupled_window')
                ),
            price:
                this.config().preset === 'b2c'
                && this.config().IccConfig.Configurators.grossPriceInSummaryB2C
                    ? conf.PriceGross
                    : conf.Price,
            priceBeforePromotions:
                this.config().preset === 'b2c'
                && this.config().IccConfig.Configurators.grossPriceInSummaryB2C
                    ? conf.PriceGrossBeforePromotions
                    : conf.PriceBeforePromotions,
            priceAfterDiscountsInCurrency:
                this.config().preset === 'b2c'
                && this.config().IccConfig.Configurators.grossPriceInSummaryB2C
                    ? conf.PriceGrossAfterDiscounts
                    : conf.PriceAfterDiscountsInCurrency,
            discount: conf.Discount,
            promotion: Math.abs(conf.PriceBeforePromotions - conf.Price) > 2000 * Number.EPSILON ? (
                    this.config().preset === 'b2c'
                        && this.config().IccConfig.Configurators.grossPriceInSummaryB2C ?
                        currencyExchange(conf.PriceGrossBeforePromotions, currency) - conf.PriceGrossAfterDiscounts
                        : currencyExchange(conf.PriceBeforePromotions, currency, true) - conf.PriceAfterDiscountsInCurrency
                ) : 0,
            hasGrossPrice:
                this.config().preset === 'b2c'
                && this.config().IccConfig.Configurators.grossPriceInSummaryB2C,
        }))
    );
    issues$ = this.configurationsService.configuration$.pipe(map(conf => conf.Issues));
}
