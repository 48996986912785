import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { ParserService } from '@icc/common/ParserService';
import { PositionsDetailsService } from '@icc/common/PositionsDetailsService';
import { MatTreeNestedDataSource, MatTree } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { StateService } from '@icc/common/state.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { TranslateService } from '@icc/common/translate.service';
import { UserService } from '@icc/common/user.service';
import { ConfiguratorFacade } from 'libs/configurator/shared/src/lib/+state/configurator.facade';
import { SharedFacade } from '@icc/configurator/shared';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'icc-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
    @Input() children: any;
    @ViewChild('tree') tree!: MatTree<any>;

    details = {};
    priceElementsVisible = false;
    showParametersModal = false;
    showPriceDetails = false;
    currency = {};
    price = {};
    hiddenPrice = false;
    showSystemsComparision = false;
    treeControl = new NestedTreeControl<any>(node => node.children);
    dataSource = new MatTreeNestedDataSource<any>();
    detailsTree: any = [];
    private unsubscribe$ = new Subject<void>();
    price$ = this.configuratorFacade.price$;
    customConstructionName: string | undefined;
    quantity = this.configurationsService.getQuantity();
    showOfferDataInputs: boolean;
    returnButton = '';
    constructor(
        @Inject(APP_CONFIG) public config: AppConfigFactory,
        private stateService: StateService,
        private configurationsService: ConfigurationsService,
        private translateService: TranslateService,
        private userService: UserService,
        private configuratorFacade: ConfiguratorFacade,
        private sharedFacade: SharedFacade,
    ) {
        this.sharedFacade.currency$.pipe(takeUntil(this.unsubscribe$)).subscribe(currency => {
            this.currency = currency;
        });

        this.configurationsService.conf$.subscribe(data=>{
            this.customConstructionName = data.Current?.Title;
            this.quantity = data.Current?.Quantity || 1;
        });
        this.configuratorFacade.price$.pipe(takeUntil(this.unsubscribe$)).subscribe(price => {
            this.price = this.quantity ? price.priceAfterDiscountsInCurrency*this.quantity : price.priceAfterDiscountsInCurrency;
        });

        this.sharedFacade.returnButton$.pipe(takeUntil(this.unsubscribe$)).subscribe(button => {
            this.returnButton = button;
        });

        this.showOfferDataInputs =
            (this.config().preset === 'b2b' &&
                !this.config().IccConfig.Configurators
                    .configurationQuantityWithButtonsAndCustomName) ||
            this.config().IccConfig.Configurators.configurationQuantityWithButtonsAndCustomName;
    }

    onNameChange(value: string){
        this.configurationsService.setConstructionName(value);
    }

    onQuantityChange(value: number){
        if (Array.from(value.toString()).every(p => p === '0')) {
            this.configurationsService.setQuantity(1)
        }
        this.configurationsService.setQuantity(value);
    }

    increaseQuantity() {
        let quantity = Number(this.configurationsService.getQuantity());
        this.configurationsService.setQuantity(quantity += 1);
    }

    decreaseQuantity() {
        let quantity = Number(this.configurationsService.getQuantity());
        this.configurationsService.setQuantity(quantity -= 1);
    }

    ngOnInit() {
        this.hiddenPrice = this.config().preset === 'b2c' && this.config().hidePricesInB2cConfigurator;

        const user = this.userService.get();
        this.loadDetails(user);
    }

    loadDetails(user) {
        const positions: any = new PositionsDetailsService(
            this.config().IccConfig,
            [
                {
                    configuration: this.configurationsService.conf.Current,
                    details: this.configurationsService.createSimpleConfiguration(
                        this.configurationsService.conf.Current
                    ),
                    summary: {
                        dealer: {
                            components: {},
                        },
                        client: {
                            components: {},
                        },
                    },
                    group_discounts: {},
                    confType: this.configurationsService.conf.Current.type
                },
            ],
            this.stateService.getCurrentOffer(),
            user.access,
            true,
            user.access,
            this.config().CurLang,
            this.translateService.instant.bind(this.translateService),
            false,
            true,
            false,
            false,
            null,
            false,
            null,
            false,
            typeof this.config === 'function' ? this.config() : this.config
        );
        positions[0].detailsForView.allWidth = true;
        this.detailsTree = positions[0].detailsTree;
        const data = this.convertToTreeData(JSON.parse(JSON.stringify(this.detailsTree)));
        this.dataSource.data = data;
        this.treeControl.dataNodes = data;
        this.treeControl.expandAll();
    }

    hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

    private convertToTreeData(tree: any) {
        if (tree.$$children) {
            tree.children = tree.$$children;
            tree.children.forEach(node => {
                this.convertToTreeData(node);
            });
            delete tree.$$children;
        }
        return tree.children;
    }

    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
