const PositionsGroupsModalTemplate = require('ngtemplate-loader!./positions-groups-modal.component.html');
import PositionsGroupsModalController from './positions-groups-modal.controller';

export const PositionsGroupsModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: PositionsGroupsModalTemplate,
  controller: PositionsGroupsModalController
};
