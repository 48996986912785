<div class="nav-tabs" [class.hide-arrows]="isArrowHidden()"
    (wheel)="incrementScroll($event.deltaY > 0 ? 100:-100, $event)" *ngIf="tabs && tabs.length">
    <div class="tabs__arrow" (click)="incrementScroll(-100)" matRipple>
        <i class="material-icons">
            arrow_left
        </i>
    </div>
    <mdc-tab-bar class="nav-tabbar" [activeTabIndex]="activeTabIndex" (activated)="selectTab($event)"
        useAutomaticActivation #tabbar>
        <mdc-tab-scroller #tabsscroller>
            <mdc-tab *ngFor="let tab of tabs" class="nav-tab" [label]="tab.name" [icon]="tab.icon"> </mdc-tab>
        </mdc-tab-scroller>
    </mdc-tab-bar>
    <div class="tabs__arrow" (click)="incrementScroll(100)" matRipple>
        <i class="material-icons">
            arrow_right
        </i>
    </div>
</div>
