<icc-dimensions-legend *ngIf='shape?.shape' [shape]="shape?.shape" [variant]="shape?.type" highlight="all"></icc-dimensions-legend>
<form [formGroup]="dimensionsForm">
  <ng-container *ngFor="let field of fields">
    <span 
      class="dimension" 
      [ngClass]="{'valid': sizeRange.widthFullValid, 'invalid': !sizeRange.widthFullValid}" 
      *ngIf="showDimensionsRestrictionSlidingDoors && field.name == 'width' && sizeRangeFull['minWidth'] !== -1 && sizeRangeFull['maxWidth'] !== -1"
      >
        {{'WINDOW|Pełny zakres rozmiarów' | translate}} {{sizeRangeFull['minWidthMM']}} - {{sizeRangeFull['maxWidthMM']}}
    </span>
    <span 
      class="dimension" 
      [ngClass]="{'valid': sizeRange.heightFullValid, 'invalid': !sizeRange.heightFullValid}" 
      *ngIf="showDimensionsRestrictionSlidingDoors && field.name == 'height'  && sizeRangeFull['minHeight'] !== -1 && sizeRangeFull['maxHeight'] !== -1"
      >
        {{'WINDOW|Pełny zakres rozmiarów' | translate}} {{sizeRangeFull['minHeightMM']}} - {{sizeRangeFull['maxHeightMM']}}
    </span>
    <mat-form-field [ngClass]="{
      'sliding-door-input': showDimensionsRestrictionSlidingDoors && sizeRange.width != '', 'dimension-input' : showDimensionsRestriction}" *ngIf="field.field && field.type === 'number'">
      <mat-label [ngClass]="{'input--invalid': showDimensionsRestrictionSlidingDoors && !sizeRange.widthValid}" *ngIf="field.name == 'width'" translate>DIMENSION|Szerokość (S)</mat-label>
      <mat-label [ngClass]="{'input--invalid': showDimensionsRestrictionSlidingDoors && !sizeRange.heightValid}" *ngIf="field.name == 'height'" translate>DIMENSION|Wysokość (H)</mat-label>
      <mat-label *ngIf="field.name == 'h1'" translate>DIMENSION|H1</mat-label>
      <mat-label *ngIf="field.name == 'h2'" translate>DIMENSION|H2</mat-label>
      <mat-label *ngIf="field.name == 'h3'" translate>DIMENSION|H3</mat-label>
      <mat-label *ngIf="field.name == 's1'" translate>DIMENSION|S1</mat-label>
      <mat-label *ngIf="field.name == 's2'" translate>DIMENSION|S2</mat-label>
      <mat-label *ngIf="field.name == 's3'" translate>DIMENSION|S3</mat-label>
      <mat-label *ngIf="field.name == 'diameter'" translate>DIMENSION|Średnica (S):</mat-label>
      <mat-label *ngIf="field.name == 'perimeter'" translate>DIMENSION|Obwód:</mat-label>
      <input
        [ngClass]="{'input--invalid': showDimensionsRestrictionSlidingDoors && ((!sizeRange.widthValid && field.name == 'width') || (!sizeRange.heightValid && field.name == 'height'))}" 
        matInput 
        iccDimensionInput 
        type="number" 
        ngModelChange="changeDimensions()" 
        [formControlName]="field.field"
      >
      <mat-hint *ngIf="showDimensionsRestriction && field.name == 'width'">{{ 'DIMENSION|Zakres rozmiarów' | translate }} ({{dimensionsRestriction.minWidth}} - {{dimensionsRestriction.maxWidth}})</mat-hint>
      <mat-hint *ngIf="showDimensionsRestriction && field.name == 'height'">{{ 'DIMENSION|Zakres rozmiarów' | translate }} ({{dimensionsRestriction.minHeight}} - {{dimensionsRestriction.maxHeight}})</mat-hint>
      <mat-hint [ngClass]="{'input--invalid': showDimensionsRestrictionSlidingDoors && !sizeRange.widthValid}" *ngIf="showDimensionsRestrictionSlidingDoors && field.name == 'width'"> {{sizeRange.width}}</mat-hint>
      <mat-hint [ngClass]="{'input--invalid': showDimensionsRestrictionSlidingDoors && !sizeRange.heightValid}" *ngIf="showDimensionsRestrictionSlidingDoors && field.name == 'height'">{{sizeRange.height}}</mat-hint>
      <span matPrefix>
          <icc-dimensions-legend [shape]="shape?.shape" [variant]="shape?.type" [highlight]="field.highlight || field.field" [fieldPrefix]="true"></icc-dimensions-legend>
      </span>
      <span matSuffix>{{ dimensionUnit.unitSymbol }}</span>
    </mat-form-field>
    <mat-form-field *ngIf="field.field && field.options.length > 0 && field.type === 'select'">
      <mat-label *ngIf="field.name == 'shortening'">{{ 'DIMENSION|Skracanie:' | translate }}</mat-label>
      <mat-select [formControlName]="field.field">
        <ng-template ngFor let-option [ngForOf]="field.options">
          <mat-option [value]="option">{{ option }}</mat-option>
        </ng-template>
      </mat-select>
    </mat-form-field>
    <span *ngIf="field.value">
      <p>{{field.value}}</p>
      <span>{{ dimensionUnit.unitSymbol }}</span>
    </span>
  </ng-container>
  <mat-radio-group *ngIf="shape?.shape === 'arc'">
    <mat-radio-button value="radialArc">{{'DIMENSION|Łuk promieniowy'}}</mat-radio-button>
    <mat-radio-button value="arcFromTemplate" >{{'DIMENSION|Łuk z szablonu'}}</mat-radio-button>
  </mat-radio-group>
</form>
<form [formGroup]="foundationForm" class='foundation-form' *ngIf="isFoundationProfileAvailable()">
 <mat-slide-toggle class='foundation-form__slide' formControlName="isFoundationProfile">
    {{'WINDOW|Podwalina' | translate}}
  </mat-slide-toggle>
  <mat-form-field *ngIf="foundationProfiles.length > 1 && foundationForm.controls.isFoundationProfile?.value">
    <mat-label>{{'WINDOW|Wybierz profil' | translate}}</mat-label>
    <mat-select formControlName="foundationProfile">
        <mat-option *ngFor="let profile of foundationProfiles" [value]="profile.id">{{ profile.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="foundationForm.controls.isFoundationProfile?.value">
    <mat-label translate>DIMENSION|Wysokość profilu</mat-label>
    <input matInput iccDimensionInput type="number" formControlName="foundationProfileHeight">
    <span matSuffix>{{ dimensionUnit.unitSymbol }}</span>
  </mat-form-field>
  <p *ngIf="foundationForm.controls.isFoundationProfile?.value && foundationActualProfile">{{'DIMENSION|Wartość powinna mieścić się w zakresie od {minHeight} do {maxHeight}' | translate : {minHeight: foundationActualProfile.minHeight | unitmm:1, maxHeight: foundationActualProfile.maxHeight | unitmm:1} }}</p>
</form>
<div class='shutter-form' *ngIf="isRollerShutterAvailable()">
  <button mat-button color="primary"
    *ngIf="!(hasRoller$ | async) && isRollerShutterAvailableToWindowSize()"
    (click)="openRollerShutterConfigurator()"
  >
    {{ 'ROLLERSHUTTER|Dodaj roletę' | translate }}
  </button>
  <span *ngIf="!isRollerShutterAvailableToWindowSize() && !(hasRoller$ | async)">{{'ROLLERSHUTTER|Skonfiguruj układ rolet w osobnej pozycji' | translate }}</span>
  <button mat-button color="primary"
    *ngIf="hasRoller$ | async"
    (click)="openRollerShutterConfigurator()"
  >
    {{ 'ROLLERSHUTTER|Edytuj roletę' | translate }}
  </button>
  <button mat-button color="primary"
    *ngIf="hasRoller$ | async"
    (click)="removeRollerShutter()"
  >
    {{ 'ROLLERSHUTTER|Usuń roletę' | translate }}
  </button>
</div>
