import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Issue } from '@icc/helpers';

@Component({
  selector: 'icc-save-information',
  templateUrl: './save-information.component.html',
  styleUrls: ['./save-information.component.scss']
})
export class SaveInformationComponent implements OnInit {

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: {
        issues: Issue[],
        actions: [],
        message: string;
        shopMessage: boolean;
      },
      private cdr: ChangeDetectorRef
  ) {
    this.cdr.markForCheck();
  }

  ngOnInit() {}

}
