import { core } from "@icc/common/helpers";

export default class FillingsModalController {
    resolve: {
        fillings;
    };
    close: ({ $value }) => any;
    dismiss: () => any;

    constructor(
        private ScrollbarService,
        private $translate,
        public IccConfig,
        public SystemsComparisonService,
    ) {

    }

    $onChanges() {
        this.ScrollbarService.update();
    }

    closeModal(save) {
        this.close({ $value: save });
    }

    async changeFilling(fillingId) {
        const filling = this.resolve.fillings[fillingId];
        await this.SystemsComparisonService.changeFilling(filling.config, filling.sashId, filling.intSashId, false);
        if (
            (filling.intSashId
            ? filling.config.conf.Sashes[filling.sashId].intSashes[filling.intSashIndex].glazing
            : filling.config.conf.Sashes[filling.sashId].glazing).id !== fillingId
        ) {
            filling.changedFilling = filling.intSashId
                ? core.copy(filling.config.conf.Sashes[filling.sashId].intSashes[filling.intSashIndex].glazing)
                : core.copy(filling.config.conf.Sashes[filling.sashId].glazing);
        }
    }

}
