import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepComponent, _, ModalService, SharedFacade } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { SystemsService } from '@icc/legacy/configurator/steps/awning/systems.service';
import { EventBusService, ConfigurationsService } from '@icc/common';
import { SystemInfoComponent } from 'libs/configurator/window/src/lib/system-info/system-info.component';
import { AwningSystemsInfoComponent } from '../awning-systems-info/awning-systems-info.component';

@Component({
    selector: 'icc-awning-systems',
    templateUrl: './awning-systems.component.html',
    styleUrls: ['./awning-systems.component.scss'],
})
export class AwningSystemsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Rodzaj markizy');
    static stepIcon = {
        ligature: 'table_chart',
    };

    public configurator = 'awning';
    public stepId = 'sheathing';
    public title = _('COLOR|Wybierz rodzaj markizy');
    public system;
    systems: iccListItem[] = [];
    private subscriptions: any[] = [];

    systemId : number | null = null;

    constructor(
        private systemsService: SystemsService,
        private eventBusService: EventBusService,
        private modalService: ModalService,
        private configurationsService: ConfigurationsService<'awning'>,
        private sharedFacade: SharedFacade,) {
        super();
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }

    init() {
        this.systems = this.systemsService.systems.map(system => ({
            id: Number(system.id),
            description: system.description,
            title: system.name,
            imageUrl: '/files/awninglinephoto/' + (Array.isArray(system.photos) && system.photos.length && system.photos[0].img),
            showInfo: true,
        }));
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    selectSystem(item: iccListItem) {
        const system = this.systemsService.systems.find(s => Number(s.id) === item.id);
        this.systemsService.selectSystem(system);
    }

    showInfo(item: iccListItem) {
        const system = this.systemsService.systems.find(s => Number(s.id) === item.id);
        const modalService = this.modalService.open({
            pageComponent: AwningSystemsInfoComponent,
            resolve    : {system: () => system}
        }).result.then((result: boolean) => {
            if (result) {
                this.selectSystem(item);
            }
        });

    }

    ngOnInit() {
        if (this.configurationsService.conf.Current) {
            this.systemId = Number(this.configurationsService.conf.Current.System.id);
        }
        if (this.systemsService.loadedData)
        {
            this.init();
        }

    }
}
