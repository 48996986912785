/**
 * Kontroler systemów garażowych
 * @param {object} $scope               Angular scope
 * @param {object} $uibModal               Dostarcza obsługę okien modalnych
 * @param {object} filterFilter         Filtry
 * @param {object} GarageSystemsFactory Fabryka systemów bram
 * @param {object} CurConfService       Bieżaca konfiguracja
 */
export default function SystemsCtrl($scope, $uibModal, filterFilter, GarageSystemsFactory, CurConfService, EventBusService) { // jshint ignore:line
    'ngInject';

    var vm = this;

    vm.subStep     = 1;
    vm.systems     = [];
    vm.allSystems  = true;
    vm.systemType  = {};

    vm.selectSystem        = selectSystem;
    vm.selectType          = selectType;

    if (GarageSystemsFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        vm.systems = GarageSystemsFactory.systems;
    }

    /**
     * Funkcja wyboru systemu
     * @param  {object} system System
     */
    function selectSystem(system) {
        GarageSystemsFactory.selectSystem(system, vm.systemType, true, true);
    }

    /**
     * Funkcja wyboru typu
     * @param  {object} type Typ
     */
    function selectType(type) {
        vm.systemType = type;
    }

}
