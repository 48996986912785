import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({ selector: '[iccDigitsOnly]' })
export class DigitsOnlyDirective {
    @Input() allowLeadingZeroes = false;

    private readonly integerUnsigned = /^[0-9]*$/;

    constructor(private hostElement: ElementRef) {}

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
        const typedKey = event.key;
        const cursorPosition = this.hostElement.nativeElement.selectionStart;

        if (!this.allowLeadingZeroes && cursorPosition === 0 && typedKey === '0') {
            event.preventDefault();
            return;
        }

        const allowedKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete'];
        if (allowedKeys.indexOf(typedKey) !== -1) {
            return;
        }

        const isNumber = this.integerUnsigned.test(typedKey);
        if (!isNumber) {
            event.preventDefault();
        }
    }
}
