<mat-card>
  <mat-card-content>
    <form [formGroup]="form">
      <h3>{{ 'AWNING|Wymiary:' | translate }}</h3>
      <mat-form-field>
        <mat-label>{{ 'AWNING|Wysięg' | translate }}</mat-label>
        <mat-select formControlName="selectedOutreach">
            <mat-option *ngFor="let outreach of outreaches"
                        [value]="outreach">
                {{outreach}}
            </mat-option>
        </mat-select>
        <span matSuffix>{{ dimensionUnit.unitSymbol }}</span>

      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'DIMENSION|Szerokość' | translate }}</mat-label>
        <input 
          matInput 
          iccDimensionInput 
          type="number"
          formControlName="currentWidth"
        >
        <span matSuffix>{{ dimensionUnit.unitSymbol }}</span>
        <mat-hint [class.danger-hint]="currentWidth.errors" align="start">{{ 'AWNING|Dostępne szerokości dla danego wysięgu' + ': ' }} <strong>{{outreachWidthFrom  + dimensionUnit.unitSymbol + ' - ' + outreachWidthTo + dimensionUnit.unitSymbol}} </strong></mat-hint>
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>
