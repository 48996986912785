import { BrowserProfilesService } from '@icc/configurator/window';
import {Shape} from '@icc/window';
import {core} from '@icc/common/helpers';
import * as $ from 'jquery';

export class  ProfilesShapesModalController {

    resolve: {
        shapesArray: Shape[],
        selectedShape: Shape,
    };
    close: ({$value: Shape}) => any;
    dismiss: () => any;

    constructor(private ProfilesService: BrowserProfilesService, private $timeout, public IccConfig, private ScrollbarService) {
    }

    $onChanges(changes) {
        this.ScrollbarService.update();
    }


    selectShape(shape) {
        this.close({$value: shape});
    }

    closeModal() {
        this.close({$value: false});
    }

    refresh() {
       this.ScrollbarService.update();
    }
}
