import { isUndefined, isString } from '@icc/helpers';

/**
 * Funkcja Rw szyby
 */
export function GlassRwFilter() {
    return function(glass, rw) {
        const glassArr = [];
        Object.keys(glass).forEach(key => {
            glassArr.push(glass[key]);
        });
        if (isUndefined(rw) || rw === null || rw === '') {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {
                if (isString(rw) && rw[0] === '<') {
                    return parseFloat(rw.substr(1)) > parseFloat(gl.rw);
                } else if (isString(rw) && rw[0] === '>') {
                    return parseFloat(rw.substr(1)) <= parseFloat(gl.rw);
                } else {
                    return parseFloat(rw) <= parseFloat(gl.rw);
                }
            });
        }
    };
}
