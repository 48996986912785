import { core } from '@icc/common/helpers';
import { IWindowConfiguration } from '../../WindowConfiguration';
import { DoorConfiguration } from '../../DoorConfiguration';
import { isNumber } from '@icc/helpers';

export function changesInVersion14(
    oldConfiguration: any,
    dataRequiredToUpdate: {
        langCode: string;
        windowColorRalsMap: Record<number | string, number>;
    }
): IWindowConfiguration {
    const configuration: IWindowConfiguration = {
        ...core.copy(oldConfiguration),
    };

    if (configuration.type === 'door') {
        (configuration as DoorConfiguration).doorHardware = {
            ...oldConfiguration.doorHardware,
            electronicLock: oldConfiguration.doorHardware.electronicLock != null && isNumber(oldConfiguration.doorHardware.electronicLock) ? {
                id: oldConfiguration.doorHardware.electronicLock,
                color: null,
            } : {
                id: null,
                color: null,
            }
        };
    }

    return configuration;
}


