
<div class = "container">
    <h3>{{hanger?.name}}</h3>
    <div class= "lineInfo">
        <div class="image" *ngIf="showImage">
            <img src="{{imageUrl}}" alt="" defaultImg>
        </div>
        <div [innerHTML]="hanger.description" id="tinymce">
        </div>
    </div>
</div>
