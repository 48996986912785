export default function FocusDirective() {
    'ngInject';

    return {
        restrict: 'A',
        link: function (scope, element) {
            element.on('click', () => {
                element[0].focus();
            });
        }
    };

}