import { forwardRef, Directive, Renderer2, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { milimetersToDimNoFormat } from '@icc/helpers';
import { UnitConverterService } from './unit-converter.service';

export const ICC_DIMENSION_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IccDimensionValueAccessor),
  multi: true
};


@Directive({
  selector: 'input[iccDimensionInput][type=number][formControlName],input[iccDimensionInput][type=number][formControl],input[iccDimensionInput][type=number][ngModel]',
  host: {
    '(change)': 'onChange($event.target.value)',
    '(input)': 'onChange($event.target.value)',
    '(blur)': 'onTouched()'
  },
  providers: [
    ICC_DIMENSION_VALUE_ACCESSOR
  ]
})
export class IccDimensionValueAccessor implements ControlValueAccessor  {
  /**
   * @description
   * The registered callback function called when a change or input event occurs on the input
   * element.
   */
  onChange = (_: any) => {};

  /**
   * @description
   * The registered callback function called when a blur event occurs on the input element.
   */
  onTouched = () => {};

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef, private unitConverterService: UnitConverterService) {

  }

  /**
   * Sets the "value" property on the input element.
   *
   * @param value The checked value
   */
  writeValue(value: number): void {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = value == null ? '' : this.unitConverterService.milimetersToDimNoFormat(value);
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', normalizedValue);
  }

  /**
   * @description
   * Registers a function called when the control value changes.
   *
   * @param fn The callback function
   */
  registerOnChange(fn: (_: number|null) => void): void {
    this.onChange = (value) => { fn(value == '' ? null : this.unitConverterService.dimToMilimeters(parseFloat(value))); };
  }

  /**
   * @description
   * Registers a function called when the control is touched.
   *
   * @param fn The callback function
   */
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

  /**
   * Sets the "disabled" property on the input element.
   *
   * @param isDisabled The disabled value
   */
  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }

}