import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, delay } from 'rxjs/operators';
import { ConfiguratorOptions, ConfigurationType, SharedFacade } from '@icc/configurator/shared';
import { of, Subject, Subscription } from 'rxjs';
import { Step } from '@icc/config';
import { DatabaseManagerToken } from '@icc/helpers/browser';
import { ConfiguratorDatabaseManager } from './database-manager';
import { AppConfig } from '@icc/common';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from "@sentry/tracing";
import { Currency } from '@icc/common/data-types/PricesData';

@Injectable({
    providedIn: 'root',
})
export class InitService {

    private subscriptions: Subscription[] = [];
    currency: Currency | undefined;

    constructor(
        private http: HttpClient,
        private sharedFacade: SharedFacade,
    ) {
         this.subscriptions.push(
            this.sharedFacade.currency$.subscribe((currency: Currency) => {
                this.currency = currency;
            })
        );
    }
    options: ConfiguratorOptions | undefined;

    loaded$ = new Subject();

    loadOptions(options: ConfiguratorOptions) {
        if (options) {
            this.options = options;
        }

        if (this.options) {
            const type = this.options.type;
            return of({
                options: this.options,
                steps: this.parseSteps(this.options.config.Configurators[type][this.getStepsFromPreset(this.options.preset)], type),
            });
        } else {
            return this.requestOptions();
        }
    }

    loadOptionsFromServer() {
        return new Promise(resolve => {
            this.requestOptions()
                .toPromise()
                .then(data => {
                    this.options = data.options;
                    this.setSentry();
                    resolve();
                });
        });
    }

    setSentry() {
        if (this.options.config.Settings.sentryDSN) {
            Sentry
                .init({
                    dsn: this.options.config.Settings.sentryDSN,
                    release: this.options.config.Version,
                    integrations: [
                        new BrowserTracing({
                            tracingOrigins: ["localhost", location.host],
                            routingInstrumentation: Sentry.routingInstrumentation,
                        }),
                    ],
                    tracesSampleRate: 1.0,
                });
        }

    }

    private requestOptions() {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = String(urlParams.get('key'));
        const confType = urlParams.get('type') || 'window';
        const confMarketId = urlParams.get('marketId') || '1';
        const confLang = urlParams.get('lang') || 'pl';
        const localStorageLanguageCode = localStorage.getItem('languageCode') || '';
        const localStorageCurrencyCode = localStorage.getItem('currencyCode') || '';
        const params: {
            type: string,
            marketId: string,
            lang: string,
            localStorageLanguageCode: string,
            localStorageCurrencyCode: string,
        } = {
            type: confType,
            marketId: confMarketId,
            lang: confLang,
            localStorageLanguageCode,
            localStorageCurrencyCode
        };
        return this.http
            .get<ConfiguratorOptions>('/api/configurator/options', {
                headers: new HttpHeaders({
                    key: myParam,
                }),
                params
            })
            .pipe(
                map(o => ({
                    options: o,
                    steps: this.parseSteps(o.config.Configurators[o.type][this.getStepsFromPreset(o.preset)], o.type),
                }))
            );
    }

    parseSteps(steps: (string | Step)[], type: ConfigurationType) {
        return steps.map(stepName => ({
            id: typeof stepName === 'string' ? stepName : stepName.code,
            configurator: type,
            enable: true,
            enableRx: true,
            visited: 0
        }));
    }

    getAppConfig(): Partial<AppConfig> {
        if (this.options) {
            this.options.config.Drawing.showSymbolsForWindow = this?.options?.theme?.show_open_symbols_window && this.options.config.Drawing?.showSymbols;
            this.options.config.Drawing.showSymbolsForFullDoors = this?.options?.theme?.show_open_symbols_full_doors && this.options.config.Drawing?.showSymbols;
            this.options.config.Drawing.showSymbolsForProfileDoors = this?.options?.theme?.show_open_symbols_profile_doors && this.options.config.Drawing?.showSymbols;
            this.options.config.Drawing.strokeContrastRatio = this?.options?.theme?.theme_stroke_contrast || 30;
            this.loaded$.next(true)
            return {
                IccConfig: this.options.config,
                EnvConfig: this.options.env,
                CurLang: this.options.languageCode || 'en',
                Langs: [this.options.languageCode || 'en'],
                ProjectName: this.options.projectName,
                currency: this.currency ? (this.options?.currencies?.find((c: Currency) => c.currency === this.currency?.currency) ?? this.options.currency)  : this.options.currency,
                currencies: this.options.currencies,
                hidePricesInB2cConfigurator: this.options.hidePricesInB2cConfigurator,
                dimensionUnit: this.options.dimensionUnit,
                weightUnit: this.options.weightUnit,
                filterAccessoriesByCategories: this.options.filterAccessoriesByCategories,
                preset: this.options.preset,
                configuratorType: this.options.type,
                embeddedInB2B: this.options.embeddedInB2B,
                multipliers: this.options.multipliers,
                quantity: this.options.quantity,
                userDescription: this.options.userDescription,
                numberOfPlacesAfterDecimalSeparator: this.options.numberOfPlacesAfterDecimalSeparator,
                languageCodes: this.options.languageCodes,
                browserLanguage: this.options.browserLanguage,
                maxLengthShortSide: this.options.maxLengthShortSide,
                maxLengthLongerSide: this.options.maxLengthLongerSide
            };
        }
        return {};
    }

    getStepsFromPreset(preset: ConfiguratorOptions['preset']): 'stepsB2C' | 'stepsB2B' {
        switch (preset) {
            case 'b2c':
                return 'stepsB2C';
            case 'b2b':
            default:
                return 'stepsB2B';
        }
    }
}
