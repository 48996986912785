import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SHUTTER_FEATURE_KEY, ShutterState } from './shutter.reducer';

// Lookup the 'Shutter' feature state managed by NgRx
const getShutterState = createFeatureSelector<ShutterState>(SHUTTER_FEATURE_KEY);

const getShutterConfiguration = createSelector(
    getShutterState,
    (state: ShutterState) => {
        return state.configuration;
    }
);

export const shutterQuery = {
    getShutterConfiguration
};
