import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MdcTabBarModule } from '@angular-mdc/web/tab-bar';
import { MdcTabScrollerModule } from '@angular-mdc/web/tab-scroller';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        MatMenuModule,
        MatFormFieldModule,
        MatRadioModule,
        MatInputModule,
        MatTabsModule,
        MatSelectModule,
        ScrollingModule,
        MatCardModule,
        MatRippleModule,
        MatSlideToggleModule,
        MatDialogModule,
        MdcTabBarModule,
        MdcTabScrollerModule,
        MatSliderModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        MatExpansionModule,
        MatTreeModule,
        MatProgressBarModule
    ],
    exports: [
        MatToolbarModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        MatMenuModule,
        MatFormFieldModule,
        MatRadioModule,
        MatInputModule,
        MatTabsModule,
        MatSelectModule,
        ScrollingModule,
        MatCardModule,
        MatRippleModule,
        MatSlideToggleModule,
        MatDialogModule,
        MdcTabBarModule,
        MdcTabScrollerModule,
        MatSliderModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        MatExpansionModule,
        MatTreeModule,
        MatProgressBarModule
    ],
})
export class MaterialModule {}
