import { core } from '../../../helpers';

/**
 * Usunięcie korekty szerokości kwater
 */
export function  changesInVersion7(oldConfiguration, dataRequiredToUpdate) {
    if (oldConfiguration.type === 'hs') {
        const configuration = core.copy(oldConfiguration);
        let leftMullion;
        let rightMullion;

        configuration.sashes.map(sash => {
            leftMullion = configuration.mullions.find(o => o.id === sash.nearMullions.left);
            rightMullion = configuration.mullions.find(o => o.id === sash.nearMullions.right);

            sash.x = leftMullion ? leftMullion.position : 0;
            sash.width = (rightMullion ? rightMullion.position : configuration.width) - sash.x;

            sash.intSashes.map(intSash => {
                leftMullion = sash.intMullions.find(o => o.id === intSash.nearMullions.left);
                rightMullion = sash.intMullions.find(o => o.id === intSash.nearMullions.right);

                intSash.x = leftMullion ? leftMullion.position : 0;
                intSash.width = (rightMullion ? rightMullion.position : sash.width) - intSash.x;
            });
        });
        return configuration;
    } else {
        return oldConfiguration;
    }
}
