export interface OfferOptions {
    orderPreview: boolean;
    completion: boolean;
    prices: boolean;
    lang: string;
    currency?: string;
    currencyExchangeRate?: number;
    extended: boolean;
    discounts: boolean;
    hideLegalNotices?: boolean;
    word?: boolean;
    id?: string;
    user?: string;
    extendedDrawDimensions?: boolean;
    notValuatedPositionsList?: string;
    project?: string;
    client?: boolean;
    measurements?: boolean;
    showUw?: boolean;
    showSw?: boolean;
    showWeight?: boolean;
    showCircuit?: boolean;
    showArea?: boolean;
    flipSymbols?: boolean;
    hideDimensions?: boolean;
    priceType?: false | 'client' | 'dealer' | 'valuation';
    dimensionUnit?: 'default' | 'mm' | 'inch';
    printMontageDescription?: boolean;
}


export const offerOptions: OfferOptions = {
    completion: true,
    user: USER_ID,
    orderPreview: false,
    prices: true,
    extended: false,
    discounts: true,
    project: ProjectName,
    lang: LANG_CODE,
    client: true,
    showUw: true,
    showSw: true,
    showWeight: true,
    showCircuit: true,
    showArea: true,
    flipSymbols: false,
};
