export default function RoundIntegerDirective() {
    'ngInject';
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attr, ngModel) {
            element.bind("blur",function(e){
                ngModel.$parsers.push((data)=>{
                    element.val(Math.round(data));
                    return Math.round(data);
                })
            })
        }
    };
}