import {DatabaseConfiguration, DatabaseManager, IDatabase} from '@icc/helpers/browser';

export class B2BDatabaseManager extends DatabaseManager {
    protected databasesConfigurations: DatabaseConfiguration[] = [
        {
            name: 'Additional',
            simple: true,
        },
        {
            name: 'TransportCosts',
            simple: true,
        },
        {
            name: 'Client',
            indexes: {
                by_seller_id: 'seller_id',
                by_dealer_seller_id: 'dealer_seller_id',
            },
            fieldsToSync: [
                'created',
                'dealer_id',
                'deleted',
                'id',
                'machine',
                'modified',
                'modified_tmp',
                'reference',
                'revision',
                'seller_id',
                'dealer_seller_id',
                'synced',
                'tmp_id',
                'consent_to_process_personal_data'
            ]
        },
        {
            name: 'Configurators',
            simple: true
        },
        {
            name: 'CustomPrices',
            simple: true
        },
        {
            name: 'DiscountPerProduct',
            simple: true
        },
        {
            name: 'DiscountsAndMultipliers',
            simple: true
        },
        {
            name: 'Montages',
            simple: true
        },
        {
            name: 'Layouts',
            simple: true
        },
        {
            name: 'Offer',
            indexes: {
                by_dealer_offer_id: 'parent_id',
                by_dealer_offer_status: 'dealer_status',
                list: ['String(doc.order)', 'Number(doc.status)', 'Number(doc.dealer_status)', 'Boolean(doc.deleted)', 'String(doc.parent_id)'],
                list_seller: ['String(doc.order)', 'Number(doc.status)', 'Number(doc.dealer_status)', 'Boolean(doc.deleted)', 'doc.seller_id', 'String(doc.parent_id)'],
                list_seller_dealer: ['String(doc.order)', 'Number(doc.status)', 'Number(doc.dealer_status)', 'Boolean(doc.deleted)', 'doc.seller_id', 'doc.dealer_id', 'String(doc.parent_id)'],
                list_dealer: ['String(doc.order)', 'Number(doc.status)', 'Number(doc.dealer_status)', 'Boolean(doc.deleted)', 'doc.dealer_id', 'String(doc.parent_id)'],
            }
        },
        {
            name: 'OfferAttachment',
            indexes: {
                by_dealer_offer_id: 'dealer_offer_id',
            },
            attachments: true,
        },
        {
            name: 'PopularLayout'
        },
        {
            name: 'Position',
            indexes: {
                by_dealer_offer_id: 'dealer_offer_id',
            }
        },
        {
            name: 'PositionAttachment',
            indexes: {
                by_dealer_offer_position_id: 'dealer_offer_position_id',
                by_dealer_offer_id: 'dealer_offer_id',
            },
            attachments: true,
        },
        {
            name: 'Prices',
            simple: true
        },
        {
            name: 'Settings',
            simple: true
        },
        {
            name: 'Translations',
            simple: true
        },
        {
            name: 'Users',
            simple: true
        },
        {
            name: 'Synchronization',
        },
    ];


    constructor(localDbVersion: number) {
        super(localDbVersion);
    }
    
    async refreshIndexesAll() {
        for (const database of this.databasesStore) {
            await database.refreshIndexes();
        }
    }

    get(name: string): IDatabase {
        const findedDatabases = this.databasesStore.filter(database => database.name === name);
        if (findedDatabases.length > 0) {
            return findedDatabases[0];
        }
    }
}
