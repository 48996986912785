import PerfectScrollbar from 'perfect-scrollbar';

export class ScrollbarService {
    scrollbars = new Map<number, PerfectScrollbar>();

    constructor(private $timeout: ng.ITimeoutService) {}

    addScrollbar(element, ps: PerfectScrollbar) {
        this.scrollbars.set(element.iccuid, ps);
    }

    getScrollbar(element) {
        return this.scrollbars.get(element.iccuid);
    }

    removeScrollbar(element) {
        this.scrollbars.delete(element.iccuid);
    }

    update() {
        this.$timeout(() => this.scrollbars.forEach(ps => {
            if (ps) {
                if ((ps as any).element.hasAttribute('scrolltobottom')) {
                    (ps as any).element.scrollTop = (ps as any).element.scrollHeight;
                }
                ps.update();
            }
        }), 10);

    }
}
ScrollbarService.$inject = ['$timeout'];
