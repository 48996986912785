import { NgModule } from '@angular/core';
import { LocksService } from './locks.service';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    providers: [LocksService],
})
export class LocksModule {
    constructor() {}
}
