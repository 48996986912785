export default function CheckIsLightColorFilter() {
    'ngInject';

    return function (input) {
        var result = /^#?([a - f\d]{2})([a - f\d]{2})([a - f\d]{2})$/i.exec(input);
        if (result) {
            if (parseInt(result[1], 16) > 200 && parseInt(result[2], 16) > 200 && parseInt(result[3], 16) > 200) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
}
