import * as angular from 'angular';

import SashesCtrl from './SashesCtrl';
import ModalSashTypeCtrl from './ModalSashTypeCtrl';
import ModalSashCtrl from './ModalSashCtrl';
import ModalFrameDividerCtrl from './ModalFrameDividerCtrl';
import { SashesService } from './sashes.service';
import SashTypeFilter from './SashTypeFilter';
import { downgradeInjectable } from '@angular/upgrade/static';
import { SashesTypesService } from './sashes-types.service';

export default angular.module('icc.configurator.steps.window.sashes', [])
    .controller('SashesCtrl', SashesCtrl)
    .controller('ModalSashTypeCtrl', ModalSashTypeCtrl)
    .controller('ModalSashCtrl', ModalSashCtrl)
    .controller('ModalFrameDividerCtrl', ModalFrameDividerCtrl)
    .factory('SashesService', downgradeInjectable(SashesService))
    .factory('SashesTypesService', downgradeInjectable(SashesTypesService))
    .filter('sashTypeFilter', SashTypeFilter)
    .name;
