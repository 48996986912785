import angular from 'angular';

export default function RatioDimenFilter(DrawDataService) {
    'ngInject';

    return function(path) {
        var pathElems = (path || '').split(/(?=[LMC])/);
        var pathDimen = '';
        pathElems.map(function(el) {
            var point = el.match(/([LMC])\s*(\d+\.?\d*)\s*\,\s*(\d+\.?\d*)/);
            if (angular.isArray(point) && point.length == 4) {
                pathDimen += point[1] + ' ' + point[2] * DrawDataService.ratioDimen + ', ' + point[3] * DrawDataService.ratioDimen;
            }
        });
        return pathDimen;
    };
}
