import { NgModule } from '@angular/core';
import { MullionsService } from '@icc/common/profiles/mullions.service';
import { AlignmentsService } from './alignments.service';
import { ExtensionsService } from './extensions.service';
import { ThresholdsService } from './thresholds.service';
import { BrowserShapeService } from './shape.service';
import { ShapeService } from '@icc/common/shape.service';
import { RemoveSashService } from './remove-sash.service';
import { MullionsLayoutService } from './mullions-layout.service';
import { SashTypesService } from './sash-types.service';
import { SplitSashService } from './split-sash.service';
import { ResizeService } from './resize.service';
import { ResizeSashService } from './resize-sash.service';
import { SashesLayoutService } from './sashes-layout.service';
import { BrowserFramesService } from './frames.service';
import { FramesService } from '@icc/common/layout/frames.service';
import { ResizeFrameService } from './resize-frame.service';
import { SplitFrameService } from './split-frame.service';
import { EqualDivisionService } from './equal-division.service';
import { FixSashThresholdService } from './fix-sash-threshold.service';
import { LayoutService } from './layout.service';
import { ConstructionCodeService } from '@icc/common/layout/construction-code.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        MullionsService,
        AlignmentsService,
        ExtensionsService,
        BrowserShapeService,
        { provide: ShapeService, useExisting: BrowserShapeService },
        BrowserFramesService,
        { provide: FramesService, useExisting: BrowserFramesService },
        ThresholdsService,
        RemoveSashService,
        MullionsLayoutService,
        SashesLayoutService,
        SashTypesService,
        SplitSashService,
        LayoutService,
        ResizeService,
        ResizeSashService,
        ResizeFrameService,
        SplitFrameService,
        EqualDivisionService,
        FixSashThresholdService,
        ConstructionCodeService,
    ],
})
export class LayoutModule {}
