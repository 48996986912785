<div class="wicket-door-step-component">
    <mat-checkbox
    class="disable-wicket-door-checkbox"
    [(ngModel)]="wicketDoorDisabled"
    (change)="disableAndUnsetWicketDoor($event.checked)"
    [disabled]="filteredWicketDoors?.length < 1"
    >{{ 'GARAGEDOOR|Bez furtki' | translate }}</mat-checkbox>

    <mat-form-field>
        <mat-label [class.disabled-text]="wicketDoorDisabled">{{ 'GARAGEDOOR|Typ furtki' | translate }}</mat-label>
        <mat-select [disabled]="wicketDoorDisabled" (selectionChange)="selectWicketDoor($event)" [value]="(selectedWicketDoor$ | async)?.id">
            <mat-option *ngFor="let wicketDoor of filteredWicketDoors || []" trackBy="wicketDoor.id" [value]="wicketDoor.id">
                {{ wicketDoor.name }} {{ (wicketDoor.threshold_height | unitmm:1:0) }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="box-form centered-items" [class.box-form__position]="!wicketDoorDisabled">
        <h4 [class.disabled-text]="wicketDoorDisabled">{{ 'GARAGEDOOR|Pozycja furtki:' | translate }}</h4>
        <div class="wicket-door-position">
            <button (click)="moveWicketDoorLeft()" [disabled]="wicketDoorDisabled || !canMoveWicketDoorLeft()" mat-raised-button color="primary"><mat-icon>arrow_back</mat-icon></button>
            <span [class.disabled-text]="wicketDoorDisabled" class="wicket-door-position-text">{{ wicketDoorMiddleXPosition }}</span>
            <button (click)="moveWicketDoorRight()" [disabled]="wicketDoorDisabled || !canMoveWicketDoorRight()" mat-raised-button color="primary"><mat-icon>arrow_forward</mat-icon></button>
        </div>
        <span *ngIf="!wicketDoorDisabled" class="wicketdoor-position-hint">
            <p>{{ 'GARAGEDOOR|Wartość przesunięcia furtki' | translate}}: <b>{{getDefaultWicketDoorShift()}}{{dimensionUnit.unitSymbol}}</b></p>
            <p>{{ 'GARAGEDOOR|Minimalna odległość środka furtki od krawędzi płaszcza bramy' | translate}}: <b>{{getMinWicketDoorOffset()}}{{dimensionUnit.unitSymbol}}</b></p>
        </span>
    </div>

    <div class="box-form">
      <h4 [class.disabled-text]="wicketDoorDisabled">{{ 'GARAGEDOOR|Kierunek otwierania:' | translate }}</h4>
      <mat-radio-group [disabled]="wicketDoorDisabled" [ngModel]="(selectedWicketDoorDirection$ | async)" (ngModelChange)="selectWicketDoorDirection($event)">
          <mat-radio-button value="left">{{ 'GARAGEDOOR|W lewo' | translate }}</mat-radio-button>
          <mat-radio-button value="right">{{ 'GARAGEDOOR|W prawo' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>
</div>
