import { Component, OnInit, Inject } from '@angular/core';
import { IccGlassType, IccGlassTypeVariant } from '@icc/common/data-types';
import {
    PageComponent,
    _,
    ICC_PAGE_DATA,
    SharedFacade,
    ModalService,
} from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { GlassTypeVariantsPageComponent } from '../glass-type-variants-page/glass-type-variants-page.component';

@Component({
    selector: 'icc-glass-types-page',
    templateUrl: './glass-types-page.component.html',
    styleUrls: ['./glass-types-page.component.scss'],
})
export class GlassTypesPageComponent extends PageComponent implements OnInit {
    public title: string = _('WINDOW|Rodzaje szyb');

    glassTypes: iccListItem[] = [];
    selectedType: number | null = null;

    constructor(
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            glassTypes: IccGlassType[];
            glassTypeVariants: IccGlassTypeVariant[];
            selectedType: number;
            selectedVariant: number;
        },
        private sharedFacade: SharedFacade,
        private modalService: ModalService
    ) {
        super();
    }

    ngOnInit() {
        this.glassTypes = this.pageData.glassTypes.map<iccListItem>((type) => ({
            id: Number(type.id),
            title: type.name,
            imageUrl: `/files/glasstype/${type.image}`,
            description: `${type.name}`,
        }));
        this.selectedType = Number(this.pageData.selectedType);
    }

    selectType(item: iccListItem) {
        const matchingVariants = this.getMatchingVariants(item?.id);
        if (matchingVariants.length === 0) {
            this.sharedFacade.closePage({
                glassTypeId: item.id,
                glassTypeVariantId: 0,
            });
            return;
        }
        if (matchingVariants.length === 1) {
            this.sharedFacade.closePage({
                glassTypeId: item.id,
                glassTypeVariantId: matchingVariants[0]?.id || 0,
            });
            return;
        }
        this.modalService
            .open({
                pageComponent: GlassTypeVariantsPageComponent,
                resolve: {
                    glassTypeVariants: matchingVariants,
                    selectedVariant: this.pageData.selectedVariant,
                },
            })
            .result.then((result) => {
                this.sharedFacade.closePage({
                    ...result,
                    glassTypeId: item.id,
                });
            });
    }

    getMatchingVariants(glassTypeId?: number | string) {
        return (this.pageData.glassTypeVariants || []).filter(
            (variant) =>
                Number(variant.glass_type_id) === Number(glassTypeId)
        );
    }
}
