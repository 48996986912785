const MontagesAndMeasureModalTemplate = require('ngtemplate-loader!./montages-and-measure-modal.component.html');
import { MontagesAndMeasureModalController } from './montages-and-measure-modal.controller';

export const MontagesAndMeasureModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: MontagesAndMeasureModalTemplate,
  controller: MontagesAndMeasureModalController
};
