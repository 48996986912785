/**
 * Funkcja filtrów pojedynczych cen akcesoriów
 * @param {object} PriceBaseService      Fabryak podstawowych
 * @param {object} PriceAccessoryService PriceAccessoryService
 * @param {object} ConfigurationsService Fabryka konfiguracji
 * @param {object} CustomPricesService   Usługa indywidaulnych cen
 * @return {function} Filter
 */
export default function AccessoryPriceOneFilter(PriceBaseService, PriceAccessoryService, ConfigurationsService, CustomPricesService, DiscountsAndMultipliersService, StateService) {
    'ngInject';

    return (accessory, color) => {
        const offer = StateService.getCurrentOffer();
        const dealerId = offer ? offer.dealer_id : null;
        const customPrice = CustomPricesService.get(dealerId);
        let multipliers = offer && offer.multipliers ? offer.multipliers : DiscountsAndMultipliersService.getMultipliers();
        let multiplier = 1;
        multipliers = multipliers && multipliers[dealerId] || multipliers;
        if (multipliers) {
            multiplier = multipliers.Supplement && multipliers.Supplement[0] || 1;
            if (ConfigurationsService.conf.Current.type === 'accessory'
                || ConfigurationsService.conf.Current.type === 'complementary_goods'
            ) {
                multiplier = multipliers.Commodity && multipliers.Commodity[0] || 1;
            }
        }
        return PriceBaseService.addMarginMarketFactor(PriceAccessoryService.priceAccessoryOne(
            accessory,
            ConfigurationsService.conf.Current.Colors || null,
            (customPrice || {}).WindowAccessory,
            ConfigurationsService.conf.Current.type, color, multiplier
        ));
    };
}
