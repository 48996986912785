
<div class = "container">
    <h3>{{system?.title}}</h3>
    <div class= "lineInfo">
        <div class="image" *ngIf="showImage">
            <img src="{{imageUrl}}" alt="" defaultImg>
        </div>
        <div [innerHTML]="system[descriptionFieldName]" id="tinymce">
        </div>
    </div>
</div>
<button mat-raised-button color="primary" (click)="close()" *ngIf="button" matRipple>
    {{ 'INTERFACE|Wybierz' | translate }}
</button>
