import { logger, core } from '../helpers';
import { Common } from '../Common';
import { OfferGroupCodeService } from './OfferGroupCodeService';

export class OfferSequenceService {
    /**
     * uaktualnienie struktury pozycji w ofercie
     * @param  {object} sequence Obecna struktura
     * @param  {object} posObj   Pozycja
     * @param  {string} action   Rodzaj akcji na pozycji w ofercie
     * @return {object}          Promise
     */
    static updateOfferSequence(sequence, positions, action, IccConfig) {
        let codeInSeq;
        let group;
        sequence = core.parseJson(sequence);

        positions.forEach(position => {
            if (action === 'add') {
                codeInSeq = false;
                for (let k = 0; k < sequence.length; k++) {
                    if (Common.isDefined(sequence[k][position.groupCode])) {
                        sequence[k][position.groupCode].push(position.id);
                        codeInSeq = true;
                    }
                }
                if (codeInSeq === false) {
                    group = {};
                    group[position.groupCode] = [position.id];
                    sequence.push(group);
                }
            } else if (action === 'remove') {
                for (let k = 0; k < sequence.length; k++) {
                    for (const gCode in sequence[k]) {
                        if (sequence[k].hasOwnProperty(gCode)) {
                            const len = sequence[k][gCode].length;
                            for (let i = 0; i < len; i++) {
                                if (sequence[k][gCode][i] === position.id) {
                                    sequence[k][gCode].splice(i, 1);
                                    if (sequence[k][gCode].length === 0) {
                                        sequence.splice(k, 1);
                                    }
                                }
                            }
                        }
                    }
                }
            } else if (action === 'update') {
                let addAtNewPlace = false;
                for (let k = 0; k < sequence.length; k++) {
                    for (let i in sequence[k]) {
                        if (sequence[k].hasOwnProperty(i)) {
                            const posIndex = sequence[k][i].indexOf(position.id);
                            if (posIndex >= 0 && position.groupCode !== i) {
                                addAtNewPlace = true;
                                sequence[k][i].splice(posIndex, 1);
                                if (sequence[k][i].length === 0) {
                                    sequence.splice(k, 1);
                                }
                            }
                        }
                    }
                }
                if (addAtNewPlace === true) {
                    codeInSeq = false;
                    for (let k = 0; k < sequence.length; k++) {
                        if (Common.isDefined(sequence[k][position.groupCode])) {
                            sequence[k][position.groupCode].push(position.id);
                            codeInSeq = true;
                        }
                    }
                    if (codeInSeq === false) {
                        group = {};
                        group[position.groupCode] = [position.id];
                        sequence.push(group);
                    }
                }
            }
        });
        return OfferSequenceService.orderedSequence(sequence, IccConfig);
    }

    /**
     * Funkcja sortuje grupy w odpowiedniej kolejności
     * @param  {object} sequence Grupa pozycji przed sortowaniem
     * @return {object}          Grupa posortowana
     */
    static orderedSequence(sequence, IccConfig) {
        if (IccConfig.Offer.notSortedGroups) {
            return sequence.sort((a, b) => {
                const groupCodeA = Object.keys(a)[0].split('_')[0];
                const groupCodeB = Object.keys(b)[0].split('_')[0];

                if (
                    !isNaN(Number(groupCodeA))
                    && !isNaN(Number(groupCodeB))
                    && Number(groupCodeA) !== Number(groupCodeB)
                ) {
                    return Number(groupCodeA) - Number(groupCodeB);
                } else if (groupCodeA !== groupCodeB) {
                    return groupCodeA > groupCodeB ? 1 : -1;
                }
                return 0;
            });
        } else {
            return sequence.sort((a, b) => {
                const groupCodeA = Object.keys(a)[0].split('_');
                const groupCodeB = Object.keys(b)[0].split('_');
                const groupCodeLength = Math.min(groupCodeA.length, groupCodeB.length);
                for (let i = 0; i < groupCodeLength; i++) {
                    if (
                        !isNaN(Number(groupCodeA[i]))
                        && !isNaN(Number(groupCodeB[i]))
                        && Number(groupCodeA[i]) !== Number(groupCodeB[i])
                    ) {
                        return Number(groupCodeA[i]) - Number(groupCodeB[i]);
                    } else if (groupCodeA[i] !== groupCodeB[i]) {
                        return groupCodeA[i] > groupCodeB[i] ? 1 : -1;
                    }
                }
                return 0;
            });
        }
    }

    /**
     * Tworzy liste id pozycji na podstaiwe sequence
     * @param  {object} sequence Objekt ze struktura pozycji
     * @return {array}           Lista z id
     */
    static keysFromSequence(sequence) {
        let searched = [];
        if (sequence) {
            const parsedSequence = core.parseJson(sequence);
            for (let i = 0; i < parsedSequence.length; i++) {
                for (const j in parsedSequence[i]) {
                    searched = searched.concat(parsedSequence[i][j]);
                }
            }
        }
        return searched;
    }

    static getPositionsCountByTypeFromSequence(sequence) {
        const positionsCount: any = {};
        const parsedSequence = core.parseJson(sequence);
        for (let i = 0; i < parsedSequence.length; i++) {
            for (const j in parsedSequence[i]) {
                const type = OfferGroupCodeService.getPositionTypeFromGroupCode(j);
                positionsCount[type] = parsedSequence[i][j].length;
            }
        }
        return positionsCount;
    }
}
