import { Component, OnInit, Inject } from '@angular/core';
import { PageComponent, ICC_PAGE_DATA } from '@icc/helpers';
import { _, SharedFacade } from '@icc/configurator/shared';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { IccColorGroup, IccColor, IccConstructColor } from '@icc/common/data-types';

@Component({
    selector: 'icc-colors-page',
    templateUrl: './colors-page.component.html',
    styleUrls: ['./colors-page.component.scss'],
})
export class ColorsPageComponent extends PageComponent implements OnInit {
    public title = _('COLOR|Wybierz kolor');

    colors: iccListItem[] = [];
    colorGroups: iccListTab[] = [];
    selectedColorId = this.pageData.selectedColor
        ? this.pageData.selectedColor.id
        : null;
    selectedGroup: number | 'none' | undefined = 0;

    constructor(
        private sharedFacade: SharedFacade,
        @Inject(ICC_PAGE_DATA)
        private pageData: {
            colors: IccColor[] | IccConstructColor[];
            colorGroups: IccColorGroup[];
            selectedColor: IccColor;
        }
    ) {
        super();
    }

    ngOnInit() {
        this.colors = (this.pageData.colors as any[]).map<iccListItem>(color => ({
            id: Number(color.id),
            title: color.name,
            description: color.code,
            color: '#' + color.color,
            imageUrl: '/files/color/' + ('colorImg' in color ? color.colorImg : color.color_img),
            tabs: ('colorGroups' in color && color.colorGroups && color.colorGroups.map(Number)) || ('groups' in color && color.groups && color.groups.map(Number)) || [],
        }));

        this.colorGroups = this.pageData.colorGroups.map<iccListTab>(group => ({
            id: Number(group.id),
            name: group.name,
        }));

        const selectedGroup =
            this.pageData.selectedColor?.colorGroups != null
            && this.pageData.selectedColor?.colorGroups.find(g =>
                this.colorGroups.map(gg => gg.id).includes(Number(g))
            );
        if (selectedGroup) {
            this.selectedGroup = Number(selectedGroup);
        } else if (this.pageData.colorGroups.length > 0) {
            this.selectedGroup = Number(this.pageData.colorGroups[0].id);
            if (this.pageData.selectedColor?.colorGroups) {
                const colorGroup = this.pageData.selectedColor.colorGroups.find(id =>
                    this.pageData.colorGroups.some(group => Number(group.id) === Number(id))
                );
                if (colorGroup) {
                    this.selectedGroup = Number(colorGroup);
                }
            }
        }
    }

    selectColor(item: iccListItem) {
        const color = (this.pageData.colors as any[]).find(
            c => Number(item.id) === Number(c.id)
        );
        if (color) {
            this.sharedFacade.closePage({
                color,
                group: this.selectedGroup,
            });
        }
    }
}
