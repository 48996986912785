/* eslint-disable max-statements */
import { Inject, Injectable } from '@angular/core';
import { ConfiguratorsDataService, ProfilesService, EventBusService, core, ConfigurationsService, WindowActiveConfiguration, SizeRangeService, APP_CONFIG, AppConfigFactory } from '@icc/common';
import { DoorSizes, Shape, expandShape, getDefaultShapeDimensions, Layout } from '@icc/window';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { IccDoorSize, IccDoorLightsSize } from '@icc/common/data-types/DoorSize';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { BehaviorSubject } from 'rxjs';
import { FillingsService } from '@icc/legacy/configurator/steps/window/glazings/fillings.service';
import { IssuesService, IssueLevel } from '@icc/helpers';
import { IccDrawMathService } from '@icc/draw';
import { DimensionsService } from '@icc/legacy/configurator/steps/window/dimensions/dimensions.service';
import { FillingValidationService } from '@icc/legacy/configurator/steps/window/glazings/filling-validation.service';
import { ResizeService } from '@icc/legacy/configurator/layout/resize.service';
import { SizesService } from './sizes.service';

@Injectable({
    providedIn: 'root',
})
export class SetSizesService {
    doorSizes: IccDoorSize[] = [];
    doorLightsSizes: IccDoorLightsSize[] = [];
    doorLightsSizes$ = new BehaviorSubject<IccDoorLightsSize[]>([]);

    constructor(
        private configuratorsDataService: ConfiguratorsDataService,
        private profilesService: ProfilesService,
        private eventBusService: EventBusService,
        private fillingsService: FillingsService,
        private issuesService: IssuesService,
        private configurationsSerivce: ConfigurationsService,
        private sizeRangeService: SizeRangeService,
        private dimensionsService: DimensionsService,
        private fillingValidationService: FillingValidationService,
        private resizeService: ResizeService,
        private sizesService: SizesService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
    ) {
        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.updateMatchingDoorSizes();
        });
        this.eventBusService.subscribe(['setProfileSet', 'setFrameProfile'], data => {
            if (
                !DoorActiveConfiguration.is(data.activeConfiguration)
                || !data.activeConfiguration.System
                || !data.activeConfiguration.System.door_type
            ) {
                return;
            }
            this.findMatchingDoorSizes(data.activeConfiguration as DoorActiveConfiguration);
        });

        this.eventBusService.subscribe(['setSystem', 'changedDoorSizes'], data => {
            if (DoorActiveConfiguration.is(data.activeConfiguration) && data.activeConfiguration.System?.door_type) {
                this.validateSashesShortening(data.activeConfiguration)
            }
        });
    }

    updateMatchingDoorSizes(conf=this.configurationsSerivce.conf.Current) {
        if (DoorActiveConfiguration.is(conf) && this.configuratorsDataService?.data?.doorSizes && this.configuratorsDataService?.data?.doorLightsSizes) {
            this.doorSizes = this.configuratorsDataService.data.doorSizes.filter(
                size => conf.System && size.systems.includes(Number(conf.System.id))
            );
            this.doorLightsSizes = (this.configuratorsDataService.data.doorLightsSizes || []).filter(
                size => {
                    if (conf.Frames && conf.Frames[0]?.frame?.length > 0 && conf.Frames.length > 0 && conf.System) {
                        const frame = conf.Frames[0];
                        const profileId = frame.frame[1].profileId;
                        return (
                            size.systems.includes(Number(conf.System.id))
                            && size.profiles.includes(Number(profileId))
                        );
                    }
                }
            );
            this.doorLightsSizes$.next(this.doorLightsSizes);
        }
    }

    findMatchingDoorSizes(conf: DoorActiveConfiguration, fromSelectPackage = false) {
        this.updateMatchingDoorSizes(conf);
        this.setDefaultDoorSizes(conf, fromSelectPackage);
    }

    validateSashesShortening(conf = this.configurationsSerivce.conf.Current as DoorActiveConfiguration) {
        const shorteningRange = this.sizesService.getSashesShorteningRange();

        if (shorteningRange.length === 0) {
            conf.doorSizes.shortening = 0;
            conf.doorSizes.frameShortening = 0;
        }

        if (shorteningRange.length > 0 && !shorteningRange.includes(conf.doorSizes.shortening)) {
            conf.doorSizes.shortening = shorteningRange[0];
            conf.doorSizes.frameShortening = conf.doorSizes.shortening;

            if (!conf.System.seperate_frame_and_sash_shortening && conf.doorSizes.shortening >= 0) {
                conf.doorSizes.frameShortening = conf.doorSizes.shortening;
            }
        }

        if (conf.doorSizes.frameShortening !== conf.doorSizes.shortening && conf.doorSizes.frameShortening !== 0) {
            if (conf.doorSizes.shortening > 0) {
                conf.doorSizes.frameShortening = conf.doorSizes.shortening;
            } else {
                conf.doorSizes.frameShortening = 0;
            }
        }
    }


    setDefaultDoorSizes(conf: DoorActiveConfiguration, fromSelectPackage = false) {
        if (!conf.System || !conf.System.door_type) {
            return;
        }
        this.sizesService.updateMatchingDoorSizes(conf);
        const sashSizes = this.sizesService.getSashSizes(conf);
        let selectedSashSize = sashSizes.find(sash => sash.id === conf.doorSizes.sashSizeId);
        const passiveSashSizes = this.sizesService.getPassiveSashSizes(conf);
        let sideLightsSizes = this.sizesService.getSideLightsSizes();
        let topLightsSizes = this.sizesService.getTopLightsSizes();

        if((conf.System.shortening.length === 0 && !conf.doorSizes.customSize) || !selectedSashSize?.shortening_available) {
            conf.doorSizes.shortening = 0;
        }
        if (topLightsSizes.length === 0) {
            topLightsSizes = this.sizesService.findMatchingDoorLightsSizesToDefaultProfile(conf, 'top');
        }
        if (sideLightsSizes.length === 0) {
            sideLightsSizes = this.sizesService.findMatchingDoorLightsSizesToDefaultProfile(conf, 'side');
        }
        if (sashSizes && !selectedSashSize && sashSizes.length > 0) {
            conf.doorSizes.sashSizeId = sashSizes[0].id;
            conf.doorSizesDictionary.door[sashSizes[0].id] = sashSizes[0];
            conf.doorSizes.customHeight = 0;
            conf.doorSizes.customWidth = 0;
            conf.doorSizes.customSize = false;
            conf.doorSizes.isStandardHeightSelected = Boolean(sashSizes[0].standard_default_height);
        }
        this.setStandardWidthSelected(this.sizesService.isStandardWidthSelected(sashSizes, conf), conf);
        selectedSashSize = sashSizes.find(sash => sash.id === conf.doorSizes.sashSizeId);
        if (selectedSashSize) {
            if (!selectedSashSize.custom && !selectedSashSize.custom_height_availability) {
                conf.doorSizes.customWidth = 0;
            }
            if(!conf.doorSizes.isStandardHeightSelected) {
                conf.doorSizes.shortening = 0;
                conf.doorSizes.frameShortening = 0;
            }
            if (conf.doorSizes.isStandardWidthSelected) {
                const sashWidth = selectedSashSize.sizes[conf.ProfileSet.sash]?.sash_width
                conf.doorSizes.customWidth = this.sizesService.getSashWidthInRebate(sashWidth, conf);
            } else {
                conf.doorSizes.customWidth =
                        conf.doorSizes.customWidth === 0
                            ? selectedSashSize?.default_width
                            : conf.doorSizes.customWidth !== selectedSashSize?.default_width
                            ? conf.doorSizes.customWidth
                            : selectedSashSize?.default_width || 0;
                conf.doorSizes.sizeIntervalCustomWidth = selectedSashSize?.size_interval_custom_width || null;
                conf.doorSizes.customSize = selectedSashSize?.custom;
            }
            this.setStandardHeightSelectedInConfig(selectedSashSize, conf);
            if (conf.doorSizes.isStandardHeightSelected) {
                const sashHeight = selectedSashSize.sizes[conf.ProfileSet.sash]?.sash_height
                conf.doorSizes.customHeight = this.sizesService.getSashHeightInRebate(sashHeight, conf);
            } else {
                if(conf.doorSizes.customHeight !== 0) {
                    const rangeId = this.sizesService.getSizeRangeId(conf.doorSizes);
                    const passiveSash = conf.Sashes.find(sash => sash.type.passive);
                    const width = conf?.Width - (conf?.doorSizes?.leftLightSize || 0) - (conf?.doorSizes?.rightLightSize || 0) - (passiveSash?.rWidth || 0);
                    const steps = {
                        height: conf.doorSizes?.sizeIntervalHeight || 1,
                        width: conf.doorSizes?.sizeIntervalCustomWidth || 1,
                    }
                    const sizeRanges = this.sizeRangeService.getSizeRange(rangeId, width, conf?.Height, true, steps);
                    conf.doorSizes.customHeight = conf.doorSizes.customHeight % steps.height === 0
                        && conf.doorSizes.customHeight <= sizeRanges.height.maxY
                        && conf.doorSizes.customHeight >= sizeRanges.height.minY
                        ? conf.doorSizes.customHeight
                        : selectedSashSize?.default_height || 0;

                } else {
                    conf.doorSizes.customHeight = selectedSashSize?.default_height || 0;
                }
                conf.doorSizes.sizeIntervalHeight = selectedSashSize?.size_interval_height || null;
                conf.doorSizes.customSize = selectedSashSize?.custom_height_availability;
            }
        }
        if (
            passiveSashSizes
            && !passiveSashSizes.find(s => s.id === conf.doorSizes.passiveSashSizeId)
            && passiveSashSizes.length > 0
            && conf.Sashes.some(sash => ['DRP', 'DOP'].includes(sash.type?.type))
        ) {
            conf.doorSizes.passiveSashSizeId = passiveSashSizes[0].id;
            conf.doorSizesDictionary.door[passiveSashSizes[0].id] = passiveSashSizes[0];
        } else if (
            !conf.Sashes.some(sash => ['DRP', 'DOP'].includes(sash.type?.type))
            && conf.doorSizes?.passiveSashSizeId
            && conf.doorSizesDictionary?.door[conf.doorSizes?.passiveSashSizeId]
        ) {
            delete conf.doorSizesDictionary.door[conf.doorSizes.passiveSashSizeId];
            conf.doorSizes.passiveSashSizeId = null;
        }
        if (
            sideLightsSizes
            && !sideLightsSizes.find(s => s.id === conf.doorSizes.leftLightSizeId)
            && sideLightsSizes.length > 0
        ) {
            conf.doorSizes.leftLightSizeId = sideLightsSizes[0].id;
            conf.doorSizesDictionary.doorLights[sideLightsSizes[0].id] = sideLightsSizes[0];
            if (conf.doorSizes.leftLightSize || conf.OwnedSashesTypes.doorLeftLight) {
                if (conf.doorSizes.leftLightSize >= sideLightsSizes[0].size_to) {
                    conf.doorSizes.leftLightSize = sideLightsSizes[0].size_to;
                }
                if (conf.doorSizes.leftLightSize <= sideLightsSizes[0].size_from) {
                    conf.doorSizes.leftLightSize = sideLightsSizes[0].size_from;
                }
            }
        }

        if (
            sideLightsSizes
            && !sideLightsSizes.find(s => s.id === conf.doorSizes.rightLightSizeId)
            && sideLightsSizes.length > 0
        ) {
            conf.doorSizes.rightLightSizeId = sideLightsSizes[0].id;
            conf.doorSizesDictionary.doorLights[sideLightsSizes[0].id] = sideLightsSizes[0];
            if (conf.doorSizes.rightLightSize || conf.OwnedSashesTypes.doorRightLight) {
                if (conf.doorSizes.rightLightSize >= sideLightsSizes[0].size_to) {
                    conf.doorSizes.rightLightSize = sideLightsSizes[0].size_to;
                }
                if (conf.doorSizes.rightLightSize <= sideLightsSizes[0].size_from) {
                    conf.doorSizes.rightLightSize = sideLightsSizes[0].size_from;
                }
            }
        }

        if (
            topLightsSizes
            && !topLightsSizes.find(s => s.id === conf.doorSizes.topLightSizeId)
            && topLightsSizes.length > 0
        ) {
            conf.doorSizes.topLightSizeId = topLightsSizes[0].id;
            conf.doorSizesDictionary.doorLights[topLightsSizes[0].id] = topLightsSizes[0];
            if (conf.doorSizes.topLightSize || conf.OwnedSashesTypes.doorTopLight) {
                if (conf.doorSizes.topLightSize >= topLightsSizes[0].size_to) {
                    conf.doorSizes.topLightSize = topLightsSizes[0].size_to;
                }
                if (conf.doorSizes.topLightSize <= topLightsSizes[0].size_from) {
                    conf.doorSizes.topLightSize = topLightsSizes[0].size_from;
                }
            }
        }

        if (!passiveSashSizes || passiveSashSizes.length === 0) {
            conf.doorSizes.passiveSashSizeId = null;
        }

        if (!sideLightsSizes || sideLightsSizes.length === 0) {
            conf.doorSizes.leftLightSizeId = null;
            conf.doorSizes.leftLightSize = 0;
            conf.doorSizes.rightLightSizeId = null;
            conf.doorSizes.rightLightSize = 0;
            conf.doorSizes.topLightSizeId = null;
            conf.doorSizes.topLightSize = 0;
        }

        if (fromSelectPackage) {
            const shape = this.sizesService.getShapeFromDoorSizes(
                conf.doorSizes,
                conf
            );
            this.dimensionsService.changedDimensions();

            const oldWidth = conf.Width;
            const oldHeight = conf.Height;

            this.setSizes(conf.doorSizes, conf);
            this.dimensionsService.setDimensions(shape);
            this.resizeService.resizeLayout(conf.Width - oldWidth, conf.Height - oldHeight, conf);
            this.dimensionsService.validDoorModelDimensions(shape);
            this.profilesService.setDefaultFrames(conf);

            if (this.config().IccConfig.Configurators.dependencies) {
                this.eventBusService.post({ key: 'processDependencies', value: null });
            }

            this.fillingValidationService.valid();
            this.eventBusService.post({
                key: 'icc-redraw',
                value: {},
            });
        }

        this.eventBusService.post({
            key: 'changedDoorSizes',
            value: {},
        });
    }

    setStandardHeightSelectedInConfig(sash, conf: DoorActiveConfiguration) {
        if (sash?.custom_height_availability) {
            conf.doorSizes.isStandardHeightSelected = this.sizesService.isStandardHeightSelected(sash, conf);
        } else if (!sash?.custom_height_availability) {
            conf.doorSizes.isStandardHeightSelected = true;
        }
    }

    setStandardWidthSelected(isStandardWidthSelected, conf: DoorActiveConfiguration) {
        conf.doorSizes.isStandardWidthSelected = isStandardWidthSelected;
    }

    setSizes(doorSizes: DoorSizes, conf: DoorActiveConfiguration) {
        conf.doorSizes = doorSizes;

        const sashSizes = this.sizesService.getSashSizes(conf);
        const passiveSashSizes = this.sizesService.getPassiveSashSizes(conf);
        const sideLightsSizes = this.sizesService.getSideLightsSizes();
        const topLightsSizes = this.sizesService.getTopLightsSizes();

        conf.doorSizesDictionary.door[doorSizes.sashSizeId] = sashSizes.find(s => s.id === doorSizes.sashSizeId);
        if (doorSizes.passiveSashSizeId && passiveSashSizes?.length) {
            conf.doorSizesDictionary.door[doorSizes.passiveSashSizeId] = passiveSashSizes.find(s => s.id === doorSizes.passiveSashSizeId);
        }
        if (doorSizes.leftLightSize && sideLightsSizes?.length) {
            conf.doorSizesDictionary.doorLights[doorSizes.leftLightSizeId] = sideLightsSizes.find(s => s.id === doorSizes.leftLightSizeId);
        }
        if (doorSizes.rightLightSize && sideLightsSizes?.length) {
            conf.doorSizesDictionary.doorLights[doorSizes.rightLightSizeId] = sideLightsSizes.find(s => s.id === doorSizes.rightLightSizeId);
        }
        if (doorSizes.topLightSize && topLightsSizes?.length) {
            conf.doorSizesDictionary.doorLights[doorSizes.topLightSizeId] = topLightsSizes.find(s => s.id === doorSizes.topLightSizeId);
        }

        this.eventBusService.post({
            key: 'changedDoorSizes',
            value: {},
        });
    }
}
