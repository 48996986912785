import angular from 'angular';
import $ from 'jquery';

/**
 * Kontroler okna modal klamek
 * @param {object} $scope                Angular Scope
 * @param {object} $uibModalInstance        Okno modal
 * @param {object} $timeout              Timeout
 * @param {object} handles               Klamki
 * @param {object} selHandle             Ustawianie klamki
 * @param {object} handleY               Klamki Y
 * @param {object} sashHeight            Wysokość skrzydła
 * @param {object} handleHeights         Wysokość klamki
 * @param {object} showHeightSelect      Pokazanie selektu wyboru wysokości
 * @param {object} ConfigurationsService Fabryka konfiguracji
 * @param {object} handleCategories      Kategorie klamek
 * @param {object} handleSubcategories   Podkategorie klamek
 * @param {object} handleCatFilter       Filtr kategorii klamek
 * @param {object} handleData            Dane klamek
 * @param {object} handleColors          Kolory klamek
 * @param {object} selHandleColor        Utawianie koloru klamek
 * @param {object} b2c                   b2c
 * @param {object} sashType              Typ skrzydła
 * @param {object} position              Pozycja
 * @param {object} systemHeight          Czy wysokość jest pobrana przez systemId (jeśli tak to można wybrać wysokość w drzwiach i HS)
 */
export default function ModalHandleCtrl($scope, $filter, $uibModalInstance, $timeout, ColorsMappingService, handles, selHandle, handleY, sashHeight,
                        handleHeights, showHeightSelect, ConfigurationsService, handleCategories, handleSubcategories, place, systemHeight,
                         handleCatFilter, handleData, handleColors, selHandleColor, selHandleOuterColor, b2c, sashType, position, availColorOuter,
                         InfoService, handleHeightType, minHandleHeight, maxHandleHeight, EventBusService, ScrollbarService, customPriceFilter) {
    'ngInject';

    var vm = this;
    vm.config = 'window';
    if (angular.isDefined(ConfigurationsService.conf)) {
        vm.config = ConfigurationsService.conf.Current.type;
    }
    vm.b2c                      = b2c;
    vm.handles                  = handles;
    vm.selHandle                = selHandle;
    vm.systemHeight             = systemHeight;

    if (!handleY && handleHeights && handleHeights[0]) {
        handleY = handleHeights[0].height;
        if (handleHeights.some(h => h.height === 'middle')) {
            handleHeightType = 'middle';
        }
    }
    if (handleHeightType === 'standard') {
        vm.handleHeight = handleY;
        vm.customHandleHeight = handleY;
    } else if (handleHeightType === 'middle') {
        vm.handleHeight = 'middle';
        vm.customHandleHeight = handleY;
    } else if (handleHeightType === 'nonstandard') {
        vm.handleHeight = 'nonstandard';
        vm.customHandleHeight = handleY;
    }
    vm.minHandleHeight          = minHandleHeight;
    vm.maxHandleHeight          = maxHandleHeight;
    vm.handleHeightType         = handleHeightType;
    vm.max                      = sashHeight - 50;
    vm.handleHeights            = handleHeights;
    vm.showHeightSelect         = showHeightSelect;
    vm.colors                   = handleColors;
    vm.sashType                 = sashType;
    vm.position                 = position;
    vm.selHandleColor           = selHandleColor.id;
    let defaultHandleColor      = selHandleColor.isDefault;
    vm.selHandleOuterColor      = selHandleOuterColor.id;
    let defaultHandleOuterColor = selHandleOuterColor.isDefault;
    vm.handleCategories         = handleCategories.filter(el =>
        handleCatFilter(vm.handles, el.id, null).length > 0
    );
    vm.handleSubcategories = handleSubcategories.filter(el =>
        handleCatFilter(vm.handles, vm.selectedCategory, el.id).length > 0
    );
    vm.selectedCategory    = handleData.selectedCategory;
    vm.selectedSubcategory = handleData.selectedSubcategory;
    vm.selectColorOuter    = vm.config == 'door' && (!selHandleOuterColor || !selHandleOuterColor.id || selHandleColor.id != selHandleOuterColor.id);
    vm.availableColorOuter = availColorOuter;
    vm.openPreview         = openPreview;
    vm.customPrice = (id, color, price) => customPriceFilter('WindowAccessory', id, 'WindowHandlesColor', color, null, price);

    /**
     * Funkcja ustawiania klamek
     * @param  {object} handle klamka
     */
    vm.selectHandle = function(handle) {
        vm.selHandle = handle;
        if (handle.colors_ids.indexOf(vm.selHandleColor) == -1 || defaultHandleColor) {
            const windowColorId = ColorsMappingService.getWindowColorId(ConfigurationsService.conf.Current, place == 'outerPull' ? 'outer' : 'inner');
            const matchedColors = ColorsMappingService.getColors(windowColorId, 'window', 'accessory');
            const colors = matchedColors.map(m => handle.colors_ids.filter(c => c == m)[0]).filter(m => m);
            if (colors.length) {
                vm.selHandleColor = colors[0];
            } else {
                vm.selHandleColor = handle.colors_ids[0];
            }
            defaultHandleColor = true;
        }
        if (handle.colors_ids.indexOf(vm.selHandleOuterColor) == -1 || defaultHandleOuterColor) {
            vm.selHandleOuterColor = vm.selHandleColor;
            defaultHandleOuterColor = true;
        }
    };

    vm.selectHandleColor = function(handleColor, outer) {
        if (!outer) {
            vm.selHandleColor = handleColor.id;
            defaultHandleColor = false;
        }
        if (!vm.selectColorOuter || outer) {
            vm.selHandleOuterColor = handleColor.id;
            defaultHandleOuterColor = false;
        }
    };

    /**
     * Funkcja zamykania okna modal
     */
    vm.close = () => {
        $uibModalInstance.close({
            handle           : vm.selHandle,
            height           : vm.handleHeight,
            customHeight     : vm.customHandleHeight,
            category         : vm.selectedCategory,
            subcategory      : vm.selectedSubcategory,
            position         : vm.position,
            color            : vm.selHandleColor,
            colorOuter       : vm.selHandleOuterColor,
            defaultColor     : defaultHandleColor,
            defaultColorOuter: defaultHandleOuterColor
        });
    };

    /**
     * Funkcja odświeżajaca
     */
    vm.refresh = function() {
        ScrollbarService.update();
    };

    /**
     * Funkcja otwierajaca podgląd
     * @param  {object} handle Klamka
     */
    function openPreview(handle) {
        const imageSrc = handle.colors_imgs[vm.selHandleColor] != '' && handle.colors_imgs[vm.selHandleColor] != null
            ? '/files/windowhandlescolorswindowaccessory/' + handle.colors_imgs[vm.selHandleColor]
            : '/files/windowaccessory/' + handle.img;
        InfoService.openInfoModal({template: 'imagePreview.html', title: $filter('translate')('INTERFACE|Podgląd'), data: {imageSrc}});
    }
}
