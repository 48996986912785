import machine from './machine';
import {offerOptions, OfferOptions} from './offer-options';
import {DEBUG} from '@icc/common/helpers';
import { AppConfig } from '@icc/common/app-config';

const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1;
const isPhonegap = !!window.cordova;
export const CONFIG: AppConfig = {
    machine: machine,
    debug: DEBUG,
    IccConfig: IccConfig,
    EnvConfig: EnvConfig,
    LocaleVersion: LocaleVersion,
    Langs: Langs,
    languageCodes: null,
    browserLanguage: false,
    CurLang: LANG_CODE,
    marketId: 1,
    USER_ID: USER_ID,
    ProjectName: ProjectName,
    RavenDSN: RavenDSN,
    isSafari: isSafari,
    isElectron: window.isElectron,
    isPhonegap: isPhonegap,
    isNative: window.isElectron || isPhonegap,
    appScheme: window.appScheme,
    Elements: (window.elements && window.elements[LANG_CODE]) ? window.elements[LANG_CODE] : {},
    offerOptions: offerOptions,
    syncInterval1: 3000,
    syncInterval2: 7000,
    syncInterval3: 11000,
    dimensionUnit: 'mm',
    numberOfPlacesAfterDecimalSeparator: 0,
    number_of_places_after_decimal_separator: 0,
    weightUnit: 'kg',
    preset: 'b2b',
    domain: window.location.host,
    theme: null,
    metaTagData: null,
    hidePricesInB2cConfigurator: false,
    filterAccessoriesByCategories: false,
    userDescription: '',
    quantity: 0,
    maxLengthShortSide: 0,
    maxLengthLongerSide: 0
};
