import angular from 'angular';

import ConvertToNumberDirective from './ConvertToNumberDirective';
import EmptySrcDirective from './EmptySrcDirective';
import FocusDirective from './FocusDirective';

export default angular
    .module('icc.configurators.draw.directives', [])
    .directive('convertToNumber', ConvertToNumberDirective)
    .directive('emptySrc', EmptySrcDirective)
    .directive('focus', FocusDirective).name;
