import { Component, OnInit, Type, Inject } from '@angular/core';
import { _, PageComponent, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { WindowFacade } from '../+state/window.facade';
import { Shape } from '@icc/window';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BrowserShapeService } from '@icc/legacy/configurator/layout/shape.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { AppConfigFactory, APP_CONFIG } from '@icc/common';
import { LayoutService } from '@icc/legacy/configurator/layout/layout.service';

interface IccShapeListItem extends iccListItem {
    shape: Shape['shape'];
    variant?: Shape['type'];
}

@Component({
    selector: 'icc-shapes',
    templateUrl: './shapes.component.html',
    styleUrls: ['./shapes.component.scss'],
})
export class ShapesComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Kształt konstrukcji');
    public options = [];

    public selectedId = 'rect';

    shapes: IccShapeListItem[] = [];

    rectShapes: IccShapeListItem[] = [
        {
            id: 'rect',
            shape: 'rect',
        },
    ];

    arcShapes: IccShapeListItem[] = [
        {
            id: 'arc_F',
            shape: 'arc',
            variant: 'F',
        },
        {
            id: 'arc_L',
            shape: 'arc',
            variant: 'L',
        },
        {
            id: 'arc_R',
            shape: 'arc',
            variant: 'R',
        },
    ];

    circleShapes: IccShapeListItem[] = [
        {
            id: 'circle',
            shape: 'circle',
        },
    ];

    triangleShapes: IccShapeListItem[] = [
        {
            id: 'triangle_F',
            shape: 'triangle',
            variant: 'F',
        },
        {
            id: 'triangle_L',
            shape: 'triangle',
            variant: 'L',
        },
        {
            id: 'triangle_R',
            shape: 'triangle',
            variant: 'R',
        },
    ];

    polygonShapes: IccShapeListItem[] = [
        {
            id: 'poligon_SLT',
            shape: 'poligon',
            variant: 'SLT',
        },
        {
            id: 'poligon_SRT',
            shape: 'poligon',
            variant: 'SRT',
        },
        {
            id: 'poligon_SLS',
            shape: 'poligon',
            variant: 'SLS',
        },
        {
            id: 'poligon_SRS',
            shape: 'poligon',
            variant: 'SRS',
        },
        {
            id: 'poligon_SLC',
            shape: 'poligon',
            variant: 'SLC',
        },
        {
            id: 'poligon_SRC',
            shape: 'poligon',
            variant: 'SRC',
        },
        {
            id: 'poligon_DLT',
            shape: 'poligon',
            variant: 'DLT',
        },
        {
            id: 'poligon_DLS',
            shape: 'poligon',
            variant: 'DLS',
        },
        {
            id: 'poligon_DLC',
            shape: 'poligon',
            variant: 'DLC',
        },
    ];

    constructor(
        private windowFacade: WindowFacade,
        private sharedFacade: SharedFacade,
        private shapeService: BrowserShapeService,
        private configurationsService: ConfigurationsService<'window'>,
        private layoutService: LayoutService,
        @Inject(ICC_PAGE_DATA) private pageData: any,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {
        super();
    }

    selectShape(item: IccShapeListItem) {
        this.pageData.setShape(item.shape, item.variant);
        this.sharedFacade.closePage();
    }

    ngOnInit() {
        const shapes: IccShapeListItem[] = [];
        if (this.isAvailable('rect')) {
            shapes.push(...this.rectShapes);
        }
        this.selectedId = this.pageData.selectedShape.shape;
        if (
            this.pageData.selectedShape.shape !== 'rect'
            && this.pageData.selectedShape.shape !== 'circle'
        ) {
            this.selectedId += '_' + this.pageData.selectedShape.type;
        }
        if (this.isAvailable('arc')) {
            shapes.push(...this.arcShapes);
        }
        if (this.isAvailable('circle')) {
            shapes.push(...this.circleShapes);
        }
        if (this.isAvailable('triangle')) {
            shapes.push(...this.triangleShapes);
        }
        if (this.isAvailable('poligon')) {
            shapes.push(
                ...this.polygonShapes.filter(
                    s =>
                        !s.variant
                        || s.variant.substr(0, 2) !== 'DL'
                        || (s.variant.substr(0, 2) === 'DL'
                            && !this.config().IccConfig.Configurators.poligonsDLDisable)
                )
            );
        }
        this.shapes = shapes.filter(s => this.isLayoutAvailable(s));
    }

    isAvailable(shape: Shape['shape']) {
        return this.shapeService.isAvailable(
            shape,
            this.configurationsService.conf.Current.System.id
        );
    }

    isLayoutAvailable(shape: IccShapeListItem) {
        const adminThumb = Number(sessionStorage.getItem('adminThumb'));

        if (adminThumb) {
            return true;
        }

        return this.layoutService.isShapeLayoutAvailable(this.configurationsService.conf.Current, shape.shape, shape.variant);
    }
}
