export default function ModalInfoCtrl($uibModalInstance, $sce, title, template, message, footer, data, EventBusService) {
    'ngInject';

    var vm = this;
    vm.title = title;
    vm.template = template;
    vm.footer = footer;
    vm.data = data;
    vm.message = $sce.trustAsHtml(message);

    vm.close = close;

    function close() {
        $uibModalInstance.close();
    }
}
