import { WindowInCoupling } from '@icc/common/configurations/parts/coupled_window';
import { core } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';
import { DrawService } from '@icc/common/configurators/draw.service';

export class MoveElementModalController {
    resolve: {
        length: number;
        startShift: number;
        noLength: boolean;
        neighbour: { index: string; type: 'window' | 'sideProfile' };
    };
    close: ({ $value: any }) => any;
    dismiss: () => any;

    translationData = {
        index: '',
    };

    constructor() {}

    save() {
        this.close({
            $value: {
                length: this.resolve.length,
                startShift: this.resolve.startShift,
            },
        });
    }

    closeModal() {
        this.close({ $value: null });
    }

    $onInit() {
        this.translationData.index = this.resolve.neighbour && this.resolve.neighbour.index;
    }
}
MoveElementModalController.$inject = ['DrawService'];
