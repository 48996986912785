import { WindowFacade } from './../+state/window.facade';
import { StepComponent, SharedFacade, ICC_PAGE_DATA, _ } from '@icc/configurator/shared';
import { Component, OnInit, Inject } from '@angular/core';
import { iccListItem } from '@icc/configurator/ui';
import { ModalService } from '@icc/helpers';
import { MuntinsInfoComponent } from '../muntins-info-component/muntins-info.component';


@Component({
  selector: 'icc-muntins-types-modal',
  templateUrl: './muntins-types-modal.component.html',
  styleUrls: ['./muntins-types-modal.component.scss']
})
export class MuntinsTypesModalComponent extends StepComponent implements OnInit {
  public configurator = 'window';
  public stepId = 'muntins_types';
  public title = _('WINDOW|Typy szprosów');

  public muntinsType$ = this.windowFacade.selectedMuntinType$;
  muntinTypes: iccListItem[] = [];

  constructor(
    private windowFacade: WindowFacade,
    private sharedFacade: SharedFacade,
    private modalService: ModalService,
    @Inject(ICC_PAGE_DATA) private pageData: {
      muntinTypes: any
    }
  ) { 
    super();
  }

  ngOnInit() {
    this.muntinTypes = this.pageData.muntinTypes.map(t => ({
      id: Number(t.id),
      title: t.name,
      imageUrl: '/files/windowbartype/' + t.img,
      showInfo: true,
    }));
  }

  selectMuntinType(item: iccListItem) {
    const type = this.pageData.muntinTypes.find(t => Number(t.id) === item.id);
    this.sharedFacade.closePage(type);
  }

  showInfo(item: iccListItem) {
    const type = this.pageData.muntinTypes.find(t => Number(t.id) === item.id);
    const modalService = this.modalService
        .open({
            pageComponent: MuntinsInfoComponent,
            resolve: {
                type: () => type,
                button: () => true,
                showImage: () => type?.show_title_image
            },
        })
        .result.then((result: boolean) => {
            if (result) {
                this.selectMuntinType(item);
            }
        });
}


}
