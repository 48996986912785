import { Injectable } from '@angular/core';
import {
    Common,
    ConfigurationsService,
    ConfiguratorsDataService,
    EventBusService,
} from '@icc/common';
import { core } from '@icc/common/Core';
import { isNumber } from '@icc/helpers';
import { ThermalTransmittanceTabular, ThermalTransmittanceTabularData } from '@icc/window';

class DoorLightThermalTransmittance {
    constructor(public constructionArea?: number, public thermalTransmittance?: number) {}
}

@Injectable({
    providedIn: 'root',
})
export class ThermalTransmittanceTabularService {
    thermalTransmittanceTabular: ThermalTransmittanceTabular[] = [];
    thermalTransmittanceTabularData: ThermalTransmittanceTabularData[] = [];
    topLight: DoorLightThermalTransmittance = new DoorLightThermalTransmittance();
    leftLight: DoorLightThermalTransmittance = new DoorLightThermalTransmittance();
    rightLight: DoorLightThermalTransmittance = new DoorLightThermalTransmittance();

    constructor(
        private configurationsService: ConfigurationsService<'door'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private eventBusService: EventBusService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration(
            ['initializedConfigurator', 'loadedConfiguratorsData'],
            () => {
                this.init();
            }
        );

        this.eventBusService.subscribe(
            [
                'setFrameProfile',
                'setSystem',
                'setPanelGlazing',
                'processDependencies',
                'loadedConfiguratorsData',
                'changedDoorSizes',
            ],
            () => {
                this.onSystemLoadOrChange();
            }
        );
    }

    onSystemLoadOrChange(conf = this.configurationsService.conf.Current) {
        if (conf.type !== 'door') {
            return;
        }

        this.leftLight = {};
        this.topLight = {};
        this.rightLight = {};
        this.calculateAndSaveThermalTransmittanceForDoorLights();
        this.saveInConfiguration(conf);
    }

    getThermalTransmittanceTabular(
        configuratorsDataService = this.configuratorsDataService.data
    ): ThermalTransmittanceTabular[] {
        return configuratorsDataService?.thermalTransmittanceTabular || [];
    }

    getThermalTransmittanceTabularData(
        configuratorsDataService = this.configuratorsDataService.data
    ): ThermalTransmittanceTabularData[] {
        return configuratorsDataService?.thermalTransmittanceTabularData || [];
    }

    init(conf = this.configurationsService.conf.Current) {
        if (conf.type !== 'door') {
            return;
        }

        this.thermalTransmittanceTabular = this.getThermalTransmittanceTabular();
        this.thermalTransmittanceTabularData = this.getThermalTransmittanceTabularData();
    }

    saveInConfiguration(conf = this.configurationsService.conf.Current) {
        conf.Factors.thermalTranssmitance.doorLights.left = this.leftLight.thermalTransmittance;
        conf.Factors.thermalTranssmitance.doorLights.right = this.rightLight.thermalTransmittance;
        conf.Factors.thermalTranssmitance.doorLights.top = this.topLight.thermalTransmittance;
        conf.Factors.thermalTranssmitance.doorLights.total = this.getThermalTransmittanceForDoorLights();
        conf.Factors.thermalTranssmitance.door = this.calculateThermalTranssmitanceForDoor(
            this.getTabularDataForDoor(conf)
        );
        conf.Factors.thermalTranssmitance.construction = this.getThermalTransmittanceForConstruction();
    }

    getThermalTransmittanceForConstruction(conf = this.configurationsService.conf.Current) {
        const width = conf.Width - conf.doorSizes.leftLightSize - conf.doorSizes.rightLightSize;
        const height = conf.Height - conf.doorSizes.topLightSize;
        const doorArea = this.getConstructionArea(height, width);
        const doorTabularData = this.getTabularDataForDoor(conf);
        const door = doorTabularData?.thermalTransmittance;
        const left = this.leftLight.thermalTransmittance || 0;
        const right = this.rightLight.thermalTransmittance || 0;
        const top = this.topLight.thermalTransmittance || 0;
        return Number(
            (
                (door * doorArea + left + right + top) /
                this.getConstructionArea(conf.Height, conf.Width)
            ).toFixed(2)
        );
    }

    calculateThermalTransmittanceForDoorLights(
        matchedTransmittances,
        firstElem,
        lastElem,
        conf = this.configurationsService.conf.Current
    ) {
        if (this.isThermalTransmittanceForTopDoorLightAvailable(firstElem, lastElem, conf)) {
            this.calculateThermalTransmittanceForTopDoorLight(matchedTransmittances, conf);
        }
        if (this.isThermalTransmittanceForDoorRightLightAvailable(firstElem, lastElem, conf)) {
            this.calculateThermalTransmittanceForRightDoorLight(matchedTransmittances, conf);
        }
        if (this.isThermalTransmittanceForLeftDoorLightAvailable(firstElem, lastElem, conf)) {
            this.calculateThermalTransmittanceForLeftDoorLight(matchedTransmittances, conf);
        }
    }

    calculateThermalTransmittanceForTopDoorLight(
        matchedTransmittances,
        conf = this.configurationsService.conf.Current
    ) {
        const {
            topDoorLightHeight,
            topDoorLightWidth,
            constructionArea,
        } = this.calculateConstructionAreaForTopDoorLight(conf);

        const tabularDataForTopLight = matchedTransmittances.find(
            (p) =>
                core.isNumberInRange(topDoorLightHeight, p.heightFrom, p.heightTo) &&
                core.isNumberInRange(topDoorLightWidth, p.widthFrom, p.widthTo)
        );

        this.topLight.constructionArea = constructionArea;

        if (!this.topLight.constructionArea) {
            return;
        }

        this.topLight.thermalTransmittance = Number(
            (tabularDataForTopLight?.thermalTransmittance * constructionArea).toFixed(2)
        );
    }

    calculateConstructionAreaForTopDoorLight(conf = this.configurationsService.conf.Current) {
        const topDoorLightWidth = conf.Width;
        const topDoorLightHeight = conf.doorSizes.topLightSize;
        const constructionArea = this.getConstructionArea(topDoorLightHeight, topDoorLightWidth);

        return { topDoorLightHeight, topDoorLightWidth, constructionArea };
    }

    calculateThermalTransmittanceForRightDoorLight(
        matchedTransmittances,
        conf = this.configurationsService.conf.Current
    ) {
        const {
            rightLightHeight,
            rightLightWidth,
            constructionArea,
        } = this.calculateConstructionAreaForRightDoorLight(conf);
        const tabularDataForTopLight = matchedTransmittances.find(
            (p) =>
                core.isNumberInRange(rightLightHeight, p.heightFrom, p.heightTo) &&
                core.isNumberInRange(rightLightWidth, p.widthFrom, p.widthTo)
        );

        this.rightLight.constructionArea = constructionArea;

        if (!this.rightLight.constructionArea) {
            return;
        }

        this.rightLight.thermalTransmittance = Number(
            (tabularDataForTopLight?.thermalTransmittance * constructionArea).toFixed(2)
        );
    }

    calculateConstructionAreaForRightDoorLight(conf = this.configurationsService.conf.Current) {
        const rightLightHeight = conf.Height - conf.doorSizes.topLightSize;
        const rightLightWidth = conf.doorSizes.rightLightSize;
        const constructionArea = this.getConstructionArea(rightLightHeight, rightLightWidth);

        return { rightLightHeight, rightLightWidth, constructionArea };
    }

    calculateThermalTransmittanceForLeftDoorLight(
        matchedTransmittances,
        conf = this.configurationsService.conf.Current
    ) {
        const {
            leftSideLightHeight,
            leftSideLightWidth,
            constructionArea,
        } = this.calculateConstructionAreaForLeftDoorLight(conf);
        const tabularDataForTopLight = matchedTransmittances.find(
            (p) =>
                core.isNumberInRange(leftSideLightHeight, p.heightFrom, p.heightTo) &&
                core.isNumberInRange(leftSideLightWidth, p.widthFrom, p.widthTo)
        );

        this.leftLight.constructionArea = constructionArea;

        if (!this.leftLight.constructionArea) {
            return;
        }

        this.leftLight.thermalTransmittance = Number(
            (tabularDataForTopLight?.thermalTransmittance * constructionArea).toFixed(2)
        );
    }

    calculateConstructionAreaForLeftDoorLight(conf = this.configurationsService.conf.Current) {
        const leftSideLightHeight = conf.Height - conf.doorSizes.topLightSize;
        const leftSideLightWidth = conf.doorSizes.leftLightSize;
        const constructionArea = this.getConstructionArea(leftSideLightHeight, leftSideLightWidth);

        return { leftSideLightHeight, leftSideLightWidth, constructionArea };
    }

    filterTransmittanceForDoor(conf = this.configurationsService.conf.Current) {
        if (!this.thermalTransmittanceTabular.length) {
            return;
        }

        const doorSash = conf.Sashes?.filter((p) => p.type.type !== 'F');

        const doorSashGlazings =
            doorSash &&
            Common.isObject(doorSash[0]?.panelGlazing) &&
            Object.keys(doorSash[0]?.panelGlazing)?.length > 0 &&
            doorSash.map((p) => Number(p?.panelGlazing?.id));

        return this.thermalTransmittanceTabular.find(
            (t) =>
                t.constructionType === 'door' &&
                t.windowLines.find((w) => w === Number(conf.System?.id)) &&
                t.profiles.find(
                    (p) => p === Number(conf.UsedProfiles.find((f) => f.type === 'frame')?.id)
                ) &&
                ((Array.isArray(doorSashGlazings) &&
                    doorSashGlazings?.some((p) => t.fillings.some((f) => p === f))) ||
                    (!doorSashGlazings && t.withoutGlazing))
        );
    }

    filterTransmittanceForDoorLight(conf = this.configurationsService.conf.Current) {
        const doorRightLightId =
            conf.OneFilling.doorRightLight && core.round(Number(conf.OneFilling.doorRightLight), 1);
        const doorLeftLightId =
            conf.OneFilling.doorLeftLight && core.round(Number(conf.OneFilling.doorLeftLight), 1);
        const doorTopLight =
            conf.OneFilling.doorTopLight && core.round(Number(conf.OneFilling.doorTopLight), 1);

        const fillings: number[] = [];
        if (isNumber(doorRightLightId)) {
            fillings.push(doorRightLightId);
        }
        if (isNumber(doorLeftLightId)) {
            fillings.push(doorLeftLightId);
        }
        if (isNumber(doorTopLight)) {
            fillings.push(doorTopLight);
        }
        return this.thermalTransmittanceTabular.filter(
            (t) =>
                t.constructionType === 'doorLight' &&
                t.windowLines.find((w) => w === Number(conf.System?.id)) &&
                t.profiles.find(
                    (p) => p === Number(conf.UsedProfiles.find((f) => f.type === 'frame')?.id)
                ) &&
                [...fillings].every((p) => t.fillings.some((f) => f === p))
        );
    }

    getTabularDataForDoor(conf = this.configurationsService.conf.Current) {
        const thermalTransmittance = this.filterTransmittanceForDoor(conf);
        const tabularData = this.thermalTransmittanceTabularData.filter(
            (p) => p.tabularId === thermalTransmittance?.tabularId
        );
        const height = conf.Height - conf.doorSizes.topLightSize;
        const width = conf.Width - conf.doorSizes.leftLightSize - conf.doorSizes.rightLightSize;
        return tabularData.find(
            (p) =>
                core.isNumberInRange(height, p.heightFrom, p.heightTo) &&
                core.isNumberInRange(width, p.widthFrom, p.widthTo)
        );
    }

    calculateThermalTranssmitanceForDoor(
        tabularData,
        conf = this.configurationsService.conf.Current
    ) {
        const width = conf.Width - conf.doorSizes.leftLightSize - conf.doorSizes.rightLightSize;
        const height = conf.Height - conf.doorSizes.topLightSize;
        const constructionArea = this.getConstructionArea(height, width);

        return (tabularData?.thermalTransmittance * constructionArea) / constructionArea;
    }

    calculateAndSaveThermalTransmittanceForDoorLights(
        conf = this.configurationsService.conf.Current
    ) {
        const thermalTransmittance = this.filterTransmittanceForDoorLight(conf);

        if (Array.isArray(thermalTransmittance) && !thermalTransmittance.length) {
            return;
        }

        const tabularData = this.thermalTransmittanceTabularData.filter((p) =>
            thermalTransmittance.some((t) => t?.tabularId === p?.tabularId)
        );

        if (Array.isArray(tabularData) && !tabularData.length) {
            return;
        }

        thermalTransmittance.forEach((tT) => {
            const matchedTransmittances = [];
            tabularData.forEach((tD) => {
                if (tT.tabularId === tD.tabularId) {
                    matchedTransmittances.push(tD);
                }
            });
            const firstElem = matchedTransmittances[0];
            const lastElem = matchedTransmittances[matchedTransmittances.length - 1];
            this.calculateThermalTransmittanceForDoorLights(
                matchedTransmittances,
                firstElem,
                lastElem,
                conf
            );
        });
    }

    isThermalTransmittanceForTopDoorLightAvailable(
        firstElem,
        lastElem,
        conf = this.configurationsService.conf.Current
    ) {
        return (
            conf.OwnedSashesTypes.doorTopLight &&
            conf.Width - conf.doorSizes.leftLightSize - conf.doorSizes.rightLightSize >=
                firstElem.widthFrom &&
            conf.Width - conf.doorSizes.leftLightSize - conf.doorSizes.rightLightSize <=
                firstElem.widthTo &&
            conf.Height >= lastElem.heightTo
        );
    }

    isThermalTransmittanceForLeftDoorLightAvailable(
        firstElem,
        lastElem,
        conf = this.configurationsService.conf.Current
    ) {
        return (
            conf.OwnedSashesTypes.doorLeftLight &&
            conf.Width > lastElem.widthTo &&
            conf.Height - (conf.doorSizes.topLightSize || 0) >= firstElem.heightFrom &&
            conf.Height - (conf.doorSizes.topLightSize || 0) <= lastElem.heightTo
        );
    }

    isThermalTransmittanceForDoorRightLightAvailable(
        firstElem,
        lastElem,
        conf = this.configurationsService.conf.Current
    ) {
        return (
            conf.OwnedSashesTypes.doorRightLight &&
            conf.Width > lastElem.widthTo &&
            conf.Height - (conf.doorSizes.topLightSize || 0) >= firstElem.heightFrom &&
            conf.Height - (conf.doorSizes.topLightSize || 0) <= lastElem.heightTo
        );
    }

    getThermalTransmittanceForDoorLights() {
        const sides =
            (this.leftLight.thermalTransmittance || 0) +
            (this.rightLight.thermalTransmittance || 0) +
            (this.topLight.thermalTransmittance || 0);

        const area =
            (this.leftLight.thermalTransmittance ? this.leftLight.constructionArea : 0) +
            (this.rightLight.thermalTransmittance ? this.rightLight.constructionArea : 0) +
            (this.topLight.thermalTransmittance ? this.topLight.constructionArea : 0);

        if (sides > 0 && area > 0) {
            return Number(sides / area).toFixed(2);
        }
    }

    getConstructionArea(height: number, width: number): number {
        return this.convertMilimetersToMeters(height) * this.convertMilimetersToMeters(width);
    }

    private convertMilimetersToMeters(value) {
        return value / 1000;
    }
}
