import angular from 'angular';

/**
 * @ngdoc directive
 * @name ICC.Configurators.iccColorBox
 * @restrict EA
 * @scope {}
 * @description
 *
 * Kontrolka do podglądu i wyboru koloru.
 *
 * @return {object} Directive
 */
export default function ColorBoxDirective() {
    'ngInject';

    var directive = {
        restrict   : 'EA',
        templateUrl: '/application/configurator/steps/window/colors/colorBoxDirective.html',
        scope      : {
            name        : '=',
            'for'       : '=',
            type        : '=',
            config      : '=',
            filterColors: '=',
            refreshConf : '=',
            novalidate  : '=',
            hideCore    : '='
        },
        controller      : ColorBoxCtrl,
        controllerAs    : 'colorBox',
        bindToController: true
    };

    return directive;

    /**
     * Kontroler kontrolki do podglądu i wyboru koloru.
     * @name ICC.Configurators.iccColorBox#ColorBoxCtrl
     * @memberof IccColorBox
     * @param {Service} IccConfig             Konfiguracja systemu.
     * @param {Service} ColorsService         ColorsService
     * @param {Service} ConfigurationsService ConfigurationsService
     */
    function ColorBoxCtrl($rootScope, $scope, IccConfig, ConfigurationsService, EventBusService) {
        var vm = this;
        const subscriptions = [];

        vm.colorStd = () => vm.config.Colors[vm.for][vm.type];
        vm.colorCore = () => vm.config.Colors[vm.for].core;

        vm.$onInit = function() {
            vm.config = vm.config || ConfigurationsService.conf.Current;

            vm.setColorType      = setColorType;
            vm.openModalColor    = openModalColor;

            vm.backgroundImage   = getBackgroundImage;
            vm.backgroundColor   = getBackgroundColor;
            vm.getColor          = getColor;

            vm.getColorBoxName   = getColorBoxName;
            vm.isHidden          = isHidden;
            vm.isDisabled        = isDisabled;
        };

        $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

        function setColorType(type) {
            throw new Error('Not implemented');
        }

        /**
         * Zwraca odpowiedni kolor.
         *
         * @return {object} Kolor
         */
        function getColor() {
            if (IccConfig.Configurators.alwaysShowSideColors
                && (vm.type == 'outer' || vm.type == 'inner')
                && angular.isUndefined(vm.colorStd().id)) {
                return vm.colorCore();
            }
            return vm.colorStd();
        }

        /**
         * Otwiera popupwyboru koloru.
         * @memberof ColorBoxCtrl
         */
        function openModalColor() {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'color'
                 });
            }
        }

        /**
         * Zwraca tło kontrolki.
         * @memberof ColorBoxCtrl
         * @return {string} Link do tła kontrolki.
         */
        function getBackgroundImage() {
            if (vm.type == 'alushell'
                && IccConfig.Configurators.alushellExt
                && vm.config.HasAlushell
                && vm.config.AlushellType == 'brushed'
            ) {
                return 'url(/application/dist/web/img/layouts/default/brushed.png)';
            } else if (angular.isUndefined(getColor().id)) {
                return 'url(/application/dist/web/img/layouts/default/config/nocolor.png)';
            } else {
                return getColor().color_img ? ('url(/files/color/' + getColor().color_img + ')') : 'none';
            }
        }

        /**
         * Zwraca kolor tła kontrolki.
         * @memberof ColorBoxCtrl
         * @return {string} Kolor tła w formacie #hhhhhh.
         */
        function getBackgroundColor() {
            return '#' + getColor().color;
        }

        /**
         * Zwraca nazwę kontrolki.
         * @memberof ColorBoxCtrl
         * @return {string} Nazwa kontorlki.
         */
        function getColorBoxName() {
            var colorBoxName = 'COLOR';
            switch (vm.type) {
                case 'outer':
                    if (vm.config.ColorType == '3D') {
                        colorBoxName = '3D';
                    } else if (['Bilateral', 'Transparent', 'Opaque'].indexOf(vm.config.ColorType) > -1) {
                        colorBoxName = 'BILATERAL';
                    } else {
                        colorBoxName = 'OUTER';
                    }
                    break;
                case 'inner':
                    colorBoxName = 'INNER';
                    break;
                case 'core':
                    if (vm.config.ColorType == 'White') {
                        colorBoxName = 'WHITE';
                    } else if (vm.config.ColorType == 'Cream') {
                        colorBoxName = 'CREAM';
                    } else {
                        colorBoxName = 'CORE';
                    }
                    break;
                case 'alushell':
                    colorBoxName = 'ALUSHELL';
                    break;
            }
            return colorBoxName;
        }

        /**
         * Zwraca czy kontrolka ma być ukryta.
         * @memberof ColorBoxCtrl
         * @return {Boolean} Czy ukryta
         */
        function isHidden() {
            var hidden = false;
            if (vm.for === 'sash' && !vm.config.ColorsSashExt) {
                hidden = true;
            }
            if (vm.hideCore) {
                hidden = true;
            }
            switch (vm.type) {
                case 'outer':
                    if (['Inner', 'White', 'Cream'].indexOf(vm.config.ColorType) > -1
                        && !IccConfig.Configurators.alwaysShowSideColors
                    ) {
                        hidden = true;
                    }
                    break;
                case 'inner':
                    if (['Bilateral', '3D', 'Transparent', 'Opaque'].indexOf(vm.config.ColorType) > -1
                        || ['White', 'Cream', 'Outer'].indexOf(vm.config.ColorType) > -1
                            && !IccConfig.Configurators.alwaysShowSideColors
                    ) {
                        hidden = true;
                    }
                    break;
                case 'core':
                    if (['White', 'Cream', 'Bilateral'].indexOf(vm.config.ColorType) === -1
                        && (['3D', 'Transparent', 'Opaque', 'Outer', 'Inner'].indexOf(vm.config.ColorType) > -1
                            || !IccConfig.Configurators.extendedColorSelect)
                    ) {
                        hidden = true;
                    }
                    if ((vm.config.ColorType == 'White'
                        || vm.config.ColorType == 'Cream')
                        && IccConfig.Configurators.alwaysShowSideColors) {
                        hidden = true;
                    }
                    if (['Bilateral'].indexOf(vm.config.ColorType) > -1
                        && (IccConfig.Configurators.alwaysShowSideColors
                        || !IccConfig.Configurators.extendedColorSelect)) {
                        hidden = true;
                    }
                    if (vm.config.ColorType == 'Bicolor'
                        && IccConfig.Configurators.alwaysShowSideColors) {
                        hidden = true;
                    }
                    if (vm.config.System.type != 'pvc') {
                        hidden = true;
                    }
                    break;
                case 'alushell':
                    if (!vm.config.HasAlushell) {
                        hidden = true;
                    }
                    if (vm.config.System.type == 'alu') {
                        hidden = true;
                    }
                    break;
            }
            return hidden;
        }

        /**
         * Czy kontrolka ma być zablokowana.
         * @memberof ColorBoxCtrl
         * @return {Boolean} Czy zablokowana
         */
        function isDisabled() {
            var disabled = false;
            switch (vm.type) {
                case 'outer':
                    if (['Inner', 'White', 'Cream'].indexOf(vm.config.ColorType) > -1) {
                        disabled = IccConfig.Configurators.alwaysShowSideColors;
                    }
                    break;
                case 'inner':
                    if (['Bilateral', '3D', 'Transparent', 'Opaque', 'White', 'Cream', 'Outer']
                            .indexOf(vm.config.ColorType) > -1
                    ) {
                        disabled = IccConfig.Configurators.alwaysShowSideColors;
                    }
                    break;
                case 'core':
                    if (vm.config.ColorType == 'White'
                        || vm.config.ColorType == 'Cream') {
                        disabled = true;
                    }
                    if (['Bilateral', '3D', 'Transparent', 'Opaque', 'Outer', 'Inner']
                            .indexOf(vm.config.ColorType) > -1
                    ) {
                        disabled = IccConfig.Configurators.alwaysShowSideColors;
                    }
                    break;
                case 'alushell':
                    if (IccConfig.Configurators.alushellExt && vm.config.AlushellType == 'brushed') {
                        disabled = true;
                    }
                    break;
            }
            return disabled;
        }
    }
}
