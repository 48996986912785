import angular from 'angular';

/**
 * Kontroler okna modal sterowania ręcznego
 * @param {object} $scope         Angular Scope
 * @param {object} $uibModalInstance Okno modal
 * @param {object} drives         Napęd
 * @param {object} selDrive       Ustawianie napędu
 */
export default function ModalDrivesManualCtrl($scope, $uibModalInstance, drives, selDrive, EventBusService, b2c) {
    'ngInject';

    $scope.b2c = b2c;

    $scope.data = {
        drives  : drives,
        selDrive: selDrive
    };

    $scope.selectDrive = function(drive) {
        $uibModalInstance.close(drive);
    };

    $scope.isSelected = function(drive) {
        if (angular.isDefined($scope.data.selDrive) && $scope.data.selDrive !== null && angular.isDefined(drive) && drive !== null) {
            if (drive.id == $scope.data.selDrive.id /* && "RAL"==$scope.data.selColor.colorType*/) {
                return true;
            }
        }
        return false;
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };
}
