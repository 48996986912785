import { ActiveConfiguration } from './ActiveConfiguration';

import { WindowActiveConfiguration } from './WindowActiveConfiguration';
import { HSConfiguration } from './HSConfiguration';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';
import { Shape } from '@icc/window';

export class HSActiveConfiguration extends WindowActiveConfiguration
    implements ActiveConfiguration {
    public static is(configuration): configuration is HSActiveConfiguration {
        return configuration instanceof HSActiveConfiguration || !configuration.$version;
    }
    type: 'hs' | 'folding_door' | 'sliding_door' = 'hs';

    Height = 2000;
    Width = 2000;
    Shape: Shape = {
        shape: 'rect',
        width: 2000,
        height: 2000,
        circuit: 8000,
        s1: 2000,
        s2: 0,
        s3: 0,
        h1: 2000,
        h2: 0,
        h3: 2000,
    };

    SashesType: 'Fix' | 'FixSash' | 'Func' | 'Mix' = 'Func';

    constructor(
        configuration?,
        configuratorsDataService?: ConfiguratorsDataService,
        langCode = configuration?.langCode || 'pl'
    ) {
        super(configuration, configuratorsDataService, langCode);
        if (configuration) {
            if (HSActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (HSConfiguration.is(configuration) && configuratorsDataService) {
                    this.Height = configuration.height;
                    this.SashesType = configuration.sashesType;
                    this.Width = configuration.width;
                    this.Shape = configuration.shape;
                }
            }
        }
    }
}
