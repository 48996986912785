export default function FileReadDirective($q) {
    'ngInject';

    return {
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, element, attrs, ngModel) {
            if (!ngModel) return;

            ngModel.$render = function() {};

            element.bind('change', function(e) {
                var element = e.target;

                $q.all([].slice.call(element.files, 0).map(readFile))
                    .then(function(values) {
                              if (element.multiple) ngModel.$setViewValue(values);
                              else ngModel.$setViewValue(values.length ? values[0] : null);
                          });

                function readFile(file) {
                    var deferred = $q.defer();

                    //var reader = new FileReader();
                    //reader.onload = function(e) {
                    //    deferred.resolve(angular.extend(file,{'data':e.target.result}));
                    //};
                    //reader.onerror = function(e) {
                    //    deferred.reject(e);
                    //};
                    //reader.readAsDataURL(file);
                    deferred.resolve(file);

                    return deferred.promise;
                }

            });
        }
    };
}
