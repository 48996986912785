export default class ModalTaxRates {
    constructor($rootScope, $uibModalInstance, systems_tax_rates, callback, taxes) {
        this.$rootScope = $rootScope;
        this.$uibModalInstance = $uibModalInstance;
        this.systems_tax_rates = systems_tax_rates;
        this.callback = callback;
        this.taxes = taxes;
    }

    close() {
        this.$uibModalInstance.close();
    }
}