import { Injectable } from '@angular/core';
import { ConfiguratorsDataService, logger } from '@icc/common';
import { DoorConfiguration } from '@icc/common/configurations/DoorConfiguration';
import * as Sentry from '@sentry/angular';

@Injectable()
export class AnomalyService {

    constructor(
        private configuratorsDataService: ConfiguratorsDataService,
    ) {}

    checkAnomalies(configuration) {
        if (DoorConfiguration.is(configuration)) {
            this.incompatibleDoorPlates(configuration);
        }
    }
    
    incompatibleDoorPlates(configuration: DoorConfiguration) {
        const mainLockPlate = this.configuratorsDataService.get('plates', configuration.doorHardware.plates.mainLock); 
        if (mainLockPlate && !mainLockPlate.window_lines_ids?.map(Number).includes(configuration.system)) {
            Sentry.captureMessage('Niepoprawny szyld główny', {
                level: 'error',
                extra: {
                    doorPlateMainLockId: configuration.doorHardware.plates.mainLock,
                    doorPlateMainLockName: mainLockPlate.name,
                    doorPlateMainLockSystems: mainLockPlate.window_lines_ids,
                    doorPlateMainLockModified: mainLockPlate.modified,
                    system: configuration.system
                }
            });
        }
        const additionalLockPlate = this.configuratorsDataService.get('plates', configuration.doorHardware.plates.additionalLock);
        if (additionalLockPlate && !additionalLockPlate.window_lines_ids?.map(Number).includes(configuration.system)) {
            Sentry.captureMessage('Niepoprawny szyld dodatkowy', {
                level: 'error',
                extra: {
                    doorPlateAdditionalLockId: configuration.doorHardware.plates.additionalLock,
                    doorPlateAdditionalLockName: additionalLockPlate.name,
                    doorPlateAdditionalLockSystems: additionalLockPlate.window_lines_ids,
                    doorPlateAdditionalLockModified: additionalLockPlate.modified,
                    system: configuration.system
                }
            })
        }
    }

}