import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepComponent, _, ModalService } from '@icc/configurator/shared';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { EventBusService, ConfigurationsService } from '@icc/common';
import { AccessoryService } from '@icc/legacy/configurator/steps/awning/accessory.service';
import { AwningAddAccessoriesComponent } from '../awning-add-accessories/awning-add-accessories.component';
import { BehaviorSubject, Observable } from 'rxjs';
@Component({
  selector: 'icc-awning-accessories',
  templateUrl: './awning-accessories.component.html',
  styleUrls: ['./awning-accessories.component.scss']
})
export class AwningAccessoriesComponent extends StepComponent implements OnInit, OnDestroy {

  static stepName = _('STEPS|Akcesoria ');
  static stepIcon = {
    ligature: 'reorder',
  };
  public stepId = 'accessories';
  private subscriptions: any[] = [];
  public sheathings: iccListItem[] = [];
  public configurator = 'awning';
  public title = _('AWNING|Akcesoria');
  public sheathingTabs: iccListTab[];
  fabricId : number | null = null;

  accessories: any;

  constructor(
      private accessoriesService: AccessoryService,
      private configurationsService: ConfigurationsService<'awning'>,
      private modalService: ModalService
  ) {
      super();
  }


  ngOnInit() {
    const accessories = this.configurationsService.conf.Current.Addons;
    this.accessories = accessories.map<iccListItem>(accessory => ({
      id: Number(accessory.id),
      title: accessory.name,
      imageUrl: '/files/awningaccessory/' + accessory.img
    }));
  }

  ngOnDestroy() {
      this.subscriptions.map(el => el.unsubscribe());
  }

  addAccessories() {
    this.modalService.open({
        pageComponent: AwningAddAccessoriesComponent,
        resolve: {
          accessories: this.accessories
        }
    }).result.then((result: boolean) => {
      if (result) {
        this.accessoriesService.selectAccessory(result)
      }
    });
    
  }

  removeAccessory(accessory) {
    const acc = this.configurationsService.conf.Current.Addons.find(a => a.id == accessory.id);
    this.accessoriesService.removeAccessory(acc);
    this.ngOnInit();
  }
}
