import { NgModule } from '@angular/core';
import { TimeLimitService } from './time-limit.service';
import { TimeLimitAccessoryService } from './time-limit-accessory.service';
import { TimeLimitFillingService } from './time-limit-filling.service';
import { TimeLimitFittingService } from './time-limit-fitting.service';
import { TimeLimitGarageControlService } from './time-limit-garage-control.service';
import { TimeLimitGaragePanelStructureColorService } from './time-limit-garage-panel-structure-color.service';
import { TimeLimitHandleService } from './time-limit-handle.service';
import { TimeLimitHingeService } from './time-limit-hinge.service';
import { TimeLimitLockService } from './time-limit-lock.service';
import { TimeLimitProfileService } from './time-limit-profile.service';
import { TimeLimitRollerShutterColorGroupService } from './time-limit-roller-shutter-color-group.service';
import { TimeLimitRollerShutterDriveService } from './time-limit-roller-shutter-drive.service';
import { TimeLimitRollerShutterProfileService } from './time-limit-roller-shutter-profile.service';
import { TimeLimitRollerShutterSystemService } from './time-limit-roller-shutter-system.service';
import { TimeLimitSealColorService } from './time-limit-seal-color.service';
import { TimeLimitShapeService } from './time-limit-shape.service';
import { TimeLimitSystemService } from './time-limit-system.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        TimeLimitService,
        TimeLimitSystemService,
        TimeLimitShapeService,
        TimeLimitSealColorService,
        TimeLimitRollerShutterSystemService,
        TimeLimitRollerShutterProfileService,
        TimeLimitRollerShutterDriveService,
        TimeLimitRollerShutterColorGroupService,
        TimeLimitProfileService,
        TimeLimitLockService,
        TimeLimitHingeService,
        TimeLimitHandleService,
        TimeLimitGaragePanelStructureColorService,
        TimeLimitGarageControlService,
        TimeLimitFittingService,
        TimeLimitFillingService,
        TimeLimitAccessoryService,
    ],
})
export class TimeLimitModule {}
