import { Injectable } from '@angular/core';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';

@Injectable()
export abstract class FramesService {
    static getFrameById(id: number, conf: WindowActiveConfiguration) {
        return conf.Frames.find(frame => frame.id === id);
    }

    static getFrameProfilesForSash(sash: { frameId?: number }, conf: WindowActiveConfiguration) {
        const frame = conf.Frames.find(f => f.id === sash.frameId);
        return frame ? frame.frame : [];
    }
}
