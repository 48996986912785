<mat-card>
    <mat-card-content>
        <ng-container *ngFor="let control of controls">
            <ng-container *ngIf="!control.whiteSwitchVisible && !control.bilateralSwitchVisible && !control.moreColorsSwitchVisible">
                <ng-container *ngTemplateOutlet="colorSelectBox;context: {control: control} "></ng-container>
            </ng-container>
            <ng-container *ngIf="control.whiteSwitchVisible || control.bilateralSwitchVisible || control.moreColorsSwitchVisible">
                <ng-container *ngTemplateOutlet="colorSelectBoxWhiteSwitch;context: {control: control} "></ng-container>
            </ng-container>
        </ng-container>
    </mat-card-content>
    <mat-card-actions>
        <button class="agree-button" mat-raised-button color="primary" (click)="close()">{{ 'INTERFACE|Zatwierdź' | translate }}</button>
    </mat-card-actions>
</mat-card>


<ng-template #colorSelectBox let-colorControl="control">
    <icc-select-box
        [title]="colorControl.title | translate"
        [description]="colorControl?.name"
        [color]="'#' + (colorControl?.color || '')"
        [imageUrl]="colorControl?.colorImg"
        [buttonText]="'INTERFACE|Zmień' | translate"
        [disabledButton1]="colorControl?.disabled"
        [colorSelect]="true"
        (select)="openModalColor(colorControl)"
    ></icc-select-box>
</ng-template>

<ng-template #colorSelectBoxWhiteSwitch let-colorControl="control">
    <icc-select-box
        [title]="colorControl.title | translate"
        [description]="colorControl?.name"
        [color]="'#' + (colorControl?.color || '')"
        [imageUrl]="colorControl?.colorImg"
        [colorSelect]="true"
        [buttonCustomStyle]="true"
        [buttonText]="colorControl.whiteSwitchVisible ? ('INTERFACE|Biały' | translate) : false"
        (select)="setWhiteColor(colorControl)"
        [disabledButton1]="colorControl.whiteSwitchDisabled"
        [buttonText2]="colorControl.bilateralSwitchVisible ? ('INTERFACE|Kolor obustronny' | translate) : false"
        (select2)="setBilateralColor(colorControl)"
        [disabledButton2]="colorControl.bilateralSwitchDisabled"
        (selectExpandMore)="openModalColor(colorControl)"
        [buttonExpandMore]="colorControl.moreColorsSwitchVisible ? ('INTERFACE|Zmień' | translate) : false"
    ></icc-select-box>
</ng-template>
