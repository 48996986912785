import * as angular from 'angular';

import { GlassCatFilter } from './glass-cat.filter';
import { GlassTypeFilter } from './glass-type.filter';
import { GlassCountFilter } from './glass-count.filter';
import { GlassOftenFilter } from './glass-often.filter';
import { GlassOrnamentFilter } from './glass-ornament.filter';
import { GlassRwFilter } from './glass-rw.filter';
import { GlassSecurityFilter } from './glass-security.filter';
import { GlassSubCatsFilter } from './glass-subcats.filter';
import { GlassThicknessFilter } from './glass-thickness.filter';
import DecoPanelTypeFilter from './DecoPanelTypeFilter';
import { GlassUgFilter } from './glass-ug.filter';
import GlazingCtrl from './GlazingCtrl';
import { FillingValidationService } from './filling-validation.service';
import ModalDecoPanelOptionsCtrl from './ModalDecoPanelOptionsCtrl';
import ModalGlazingBeadCtrl from './ModalGlazingBeadCtrl';
import ModalGlazingCtrl from './ModalGlazingCtrl';
import ModalSealColorCtrl from './ModalSealColorCtrl';
import ModalSiliconeColorCtrl from './ModalSiliconeColorCtrl';
import ModalWarmEdgeCtrl from './ModalWarmEdgeCtrl';
import {GlazingFiltersComponent} from './GlazingFiltersComponent';
import {downgradeInjectable} from '@angular/upgrade/static';
import { GlazingBeadsService } from '@icc/common/glazing-beads.service';
import {SealColorsService} from './seal-colors.service';
import {WarmEdgesService} from './warm-edges.service';
import {FillingsService} from './fillings.service';
import {GlazingUnitModalComponent} from './glazing-unit-modal/glazing-unit-modal.component';
import { GlazingUnitsService } from './glazing-units.service';
import { GlazingSizesService } from '@icc/common/configurators/glazing-sizes.service';
import {SiliconeColorsService} from './silicone-colors.service';

export default angular.module('icc.configurator.steps.window.glazings', [])
    .controller('GlazingCtrl', GlazingCtrl)
    .controller('ModalDecoPanelOptionsCtrl', ModalDecoPanelOptionsCtrl)
    .controller('ModalGlazingBeadCtrl', ModalGlazingBeadCtrl)
    .controller('ModalGlazingCtrl', ModalGlazingCtrl)
    .controller('ModalSealColorCtrl', ModalSealColorCtrl)
    .controller('ModalSiliconeColorCtrl', ModalSiliconeColorCtrl)
    .controller('ModalWarmEdgeCtrl', ModalWarmEdgeCtrl)
    .component('glazingFilters', new GlazingFiltersComponent())
    .component('glazingUnitModal', GlazingUnitModalComponent)
    .factory('FillingValidationService', downgradeInjectable(FillingValidationService))
    .factory('GlazingBeadsService', downgradeInjectable(GlazingBeadsService))
    .factory('SealColorsService', downgradeInjectable(SealColorsService))
    .factory('WarmEdgesService', downgradeInjectable(WarmEdgesService))
    .factory('FillingsService', downgradeInjectable(FillingsService))
    .factory('GlazingUnitsService', downgradeInjectable(GlazingUnitsService))
    .factory('GlazingSizesService', downgradeInjectable(GlazingSizesService))
    .factory('SiliconeColorsService', downgradeInjectable(SiliconeColorsService))
    .filter('glassCat', GlassCatFilter)
    .filter('glassType', GlassTypeFilter)
    .filter('glassCount', GlassCountFilter)
    .filter('glassOften', GlassOftenFilter)
    .filter('glassOrnament', GlassOrnamentFilter)
    .filter('glassRw', GlassRwFilter)
    .filter('glassSecurity', GlassSecurityFilter)
    .filter('glassSubCats', GlassSubCatsFilter)
    .filter('glassThickness', GlassThicknessFilter)
    .filter('decoPanelType', DecoPanelTypeFilter)
    .filter('glassUg', GlassUgFilter)
    .name;
