import { NgModule } from '@angular/core';

import { AccessoriesService } from './accessories.service';
import { AccessoriesSpaceService } from './accessories-space.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [AccessoriesService, AccessoriesSpaceService],
})
export class AccessoriesModule {}
