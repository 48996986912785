import {
    ConfigurationsService,
    ConfiguratorsDataService,
    EventBusService,
    TranslateService,
} from '@icc/common';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { PriceService } from '@icc/price';
import { isUndefined } from 'libs/helpers/src/lib/helpers';
import { Injectable } from '@angular/core';
import { StepsService } from '@icc/helpers';
@Injectable()
export class FrillService {
    valances = [];
    rollUpPrices = [];
    inscription = '';
    loadedData = false;
    rollUpValance = false;
    emptyValance = { id: 0, name: this.translateService.instant('AWNING|brak falbany') };

    'ngInject';
    constructor(
        private configurationsService: ConfigurationsService<'awning'>,
        private configuratorsDataService: ConfiguratorsDataService,
        private curConfService: CurrentConfiguratorService,
        private stepsService: StepsService,
        private priceService: PriceService,
        private eventBusService: EventBusService,
        private translateService: TranslateService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribeWithoutConfiguration('changedStep', () => {
            this.init();
        });
    }

    init() {
        if (this.curConfService.conf === 'awning') {
            this.setValues();
            if (isUndefined(this.configurationsService.conf.Current.Valance.id)) {
                this.setDefaultValues();
            }
            this.inscription = this.configurationsService.conf.Current.ValanceInscription;
            this.rollUpValance = this.configurationsService.conf.Current.RollUpValance;
            this.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Ustawianie wartości
     */
    setValues() {
        this.valances = this.getValances();
        this.rollUpPrices = this.getRollupValancePrices();
    }

    /**
     * Zwracanie lambrekiny
     * @return {Array} Lambrekiny
     */
    getValances() {
        var fabricID = this.configurationsService.conf.Current.Fabric.id;
        if (fabricID > 0) {
            return this.configuratorsDataService.data.awningValances[fabricID];
        } else {
            return [];
        }
    }

    /**
     * Zwracanie cen zwijania lambrekinów
     * @return {object} Ceny
     */
    getRollupValancePrices() {
        return this.configuratorsDataService.data.awningRollupValancePrices;
    }

    /**
     * Wybór lambrekinów
     * @param  {Object} valance  Lambrekin
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    selectValance(valance, nextStep?, price?) {
        this.configurationsService.conf.Current.Valance = valance;
        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
        if (price) {
            this.priceService.count();
        }
    }

    selectValanceInscription(inscription, nextStep, price) {
        this.configurationsService.conf.Current.ValanceInscription = inscription;
        // ConfigurationsService.conf.Default.ValanceInscription = inscription;

        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
        if (price) {
            this.priceService.count();
        }
    }

    /**
     * Ustawianie zwijania lambrekinu
     * @param {[type]} rollUpValance [description]
     * @param {[type]} nextStep      [description]
     * @param {[type]} price         [description]
     */
    setRollUpValance(rollUpValance, nextStep, price) {
        this.configurationsService.conf.Current.RollUpValance = rollUpValance;
        // ConfigurationsService.conf.Default.RollUpValance = rollUpValance;

        this.configurationsService.conf.Current.RollUpPrices = this.rollUpPrices;
        // ConfigurationsService.conf.Default.RollUpPrices = this.rollUpPrices;

        if (nextStep) {
            this.stepsService.goToNextStep(true);
        }
        if (price) {
            this.priceService.count();
        }
    }

    /**
     * Ustawianie wartości domyślnych
     * @param {Boolean} reset Reset
     */
    setDefaultValues(reset?) {
        if (isUndefined(this.configurationsService.conf)) {
            return;
        }

        this.setValues();

        if (isUndefined(this.configurationsService.conf.Current.Valance.id) || reset) {
            this.selectValance(this.emptyValance);
        }
    }
}
