import { IIccConfig } from '@icc/config';
import { core } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';

export function unitMFormatter(
    data: any,
    IccConfig: IIccConfig,
    hasunit = 0,
    inmm = 0,
    mb = 0,
    dimensionUnit: 'mm' | 'inch' = 'mm',
    mbUnit: string,
    round = 2
) {
    const unit = {
        type: dimensionUnit === 'mm' ? 0 : 1,
        unit: dimensionUnit,
        separator: dimensionUnit === 'mm' ? ',' : '.',
    };
    const inchToMeter = IccConfig.Configurators.inchToMilimeter / 1000;

    if (Common.isString(data)) {
        data = +data;
    }
    if (!Common.isNumber(data) || isNaN(data) || !Common.isDefined(data)) {
        return 'NaN';
    }

    let unitext = '';
    if (hasunit) {
        if (unit.type === 0) {
            unitext = mb ? mbUnit : 'm';
        } else {
            unitext = '″';
        }
    }

    if (mb === 2 || mb === 3) {
        // milimetry, zwróć po prostu - nie dodawaj nawet drugiego wymiaru, bo po co
        if (unit.type === 0) {
            return mb === 3
                ? data
                : core.formatNumber(data, round, 100, ' ', unit.separator) + ' ' + unitext;
            // do konwertowanych jednostek dodaj wymiar
        } else if (inmm) {
            return mb === 3
                ? data * inchToMeter
                : core.formatNumber(data * inchToMeter, round, 100, ' ', unit.separator)
                      + ' '
                      + unitext
                      + ' ('
                      + data
                      + (mb ? ' ' + mbUnit + ')' : ' m)');
        } else {
            return mb === 3
                ? data * inchToMeter
                : core.formatNumber(data * inchToMeter, round, 100, ' ', unit.separator)
                      + ' '
                      + unitext;
        }
    } else {
        // milimetry, zwróć po prostu - nie dodawaj nawet drugiego wymiaru, bo po co
        if (unit.type === 0) {
            return core.formatNumber(data, round, 100, ' ', unit.separator) + ' ' + unitext;
            // do konwertowanych jednostek dodaj wymiar
        } else if (inmm) {
            return (
                core.formatNumber(data / inchToMeter, round, 100, ' ', unit.separator)
                + ' '
                + unitext
                + ' ('
                + data
                + (mb ? ' ' + mbUnit + ')' : ' m)')
            );
        } else {
            return (
                core.formatNumber(data / inchToMeter, round, 100, ' ', unit.separator) + ' ' + unitext
            );
        }
    }
}

export function unitM2Formatter(
    data: any,
    IccConfig: IIccConfig,
    hasunit = 0,
    inmm = 0,
    mb = 0,
    dimensionUnit: 'mm' | 'inch' = 'mm',
    mbUnit: string
) {
    const unit = {
        type: dimensionUnit === 'mm' ? 0 : 1,
        unit: dimensionUnit,
        separator: dimensionUnit === 'mm' ? ',' : '.',
    };
    const inchToMeter = Math.pow(IccConfig.Configurators.inchToMilimeter / 1000, 2);

    if (Common.isString(data)) {
        data = +data;
    }
    if (!Common.isNumber(data) || isNaN(data) || !Common.isDefined(data)) {
        return 'NaN';
    }

    let unitext = '';
    if (hasunit) {
        if (unit.type === 0) {
            unitext = mb ? mbUnit : 'm²';
        } else {
            unitext = 'inch²';
        }
    }

    if (mb === 2 || mb === 3) {
        if (unit.type === 0) {
            return mb === 3
                ? data
                : core.formatNumber(data, 2, 100, ' ', unit.separator) + ' ' + unitext;
        } else if (inmm) {
            return mb === 3
                ? data * inchToMeter
                : core.formatNumber(data * inchToMeter, 2, 100, ' ', unit.separator)
                      + ' '
                      + unitext
                      + ' ('
                      + data
                      + (mb ? ' ' + mbUnit + ')' : ' m²)');
        } else {
            return mb === 3
                ? data * inchToMeter
                : core.formatNumber(data * inchToMeter, 2, 100, ' ', unit.separator)
                      + ' '
                      + unitext;
        }
    } else {
        if (unit.type === 0) {
            return core.formatNumber(data, 2, 100, ' ', unit.separator) + ' ' + unitext;
        } else if (inmm) {
            return (
                core.formatNumber(data / inchToMeter, 2, 100, ' ', unit.separator)
                + ' '
                + unitext
                + ' ('
                + data
                + (mb ? ' ' + mbUnit + ')' : ' m²)')
            );
        } else {
            return (
                core.formatNumber(data / inchToMeter, 2, 100, ' ', unit.separator) + ' ' + unitext
            );
        }
    }
}
