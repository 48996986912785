import { Component, OnInit, Inject } from '@angular/core';
import { _, StepComponent, SharedFacade, ICC_PAGE_DATA } from '@icc/configurator/shared';
import { iccListItem } from '@icc/configurator/ui';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';

@Component({
    selector: 'icc-fabrics-page',
    templateUrl: './fabrics-page.component.html',
    styleUrls: ['./fabrics-page.component.scss'],
})
export class FabricsPageComponent extends StepComponent implements OnInit {
    public configurator = 'pleated_blinds';
    public stepId = 'fabrics_page';
    public title = _('COLOR|Wybierz tkaninę');
    public options = [];

    system: any = null;
    fabrics: iccListItem[] = [];
    selectedFabricId : Number = null;
    constructor(
        private sharedFacade: SharedFacade,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        @Inject(ICC_PAGE_DATA) private pageData: {
          fabrics: any[],
          selectedFabricId: any
        }
    ) {
        super();
    }

    ngOnInit() {
      this.selectedFabricId = Number(this.pageData.selectedFabricId);
      this.fabrics = this.pageData.fabrics.map<iccListItem>(fabric => ({
          id: Number(fabric.id),
          title: fabric.name,
          imageUrl: '/files/pleatedblindfabric/' + fabric.img,
        }));
    }

    close() {
        this.sharedFacade.closePage();
    }

    selectFabric(item: iccListItem) {
      const fabricId = String(item.id)
      const fabric = this.pageData.fabrics.find(
          c => Number(fabricId) === Number(c.id)
      );
      if (fabric) {
          this.sharedFacade.closePage(fabric);
      }
  }
}
