import {logger, core} from '../helpers';
import { Common } from '../Common';
import format from 'date-fns/format';

export class OfferKeyService {
    /**
     * Generowanie numeru oferty lub zamówienia na podstawie schematu
     * @param  {object}  params Parametry
     * @return {object}         Elementy składowe numeru, numer offerty
     */
    static keyGen(params) {
        // 'N_ALL'      => 'Numer kolejny w całym systemie',
        // 'N_DAY'      => 'Numer kolejny w danym dniu',
        // 'N_MONTH'    => 'Numer kolejny w danym miesaicu',
        // 'N_YEAR'     => 'Numer kolejny w danym roku',
        // 'N_DEALER'   => 'Numer kolejny dla danego dealera',
        // 'K_CUSTOM'   => 'Własny numer',
        // 'K_CLIENT'   => 'Kod klienta',
        // 'K_DEALER'   => 'Kod dealera',
        // 'K_PRODUCER' => 'Kod handlowca producenta',
        // 'K_MARKET'   => 'Kod rynku',
        // 'D_YYYY'     => 'Rok 4-cyfrowo',
        // 'D_YY'       => 'Rok 2-cyfrowo',
        // 'D_MM'       => 'Miesąc',
        // 'D_DD'       => 'Dzień',
        // 'V_DIGIT'    => 'Wesja cyfra',
        // 'V_NUMBER'   => 'Wesja literą',
        // 'O_NUM'      => 'Numer porządkowy ofery',
        // 'O_ALL'      => 'Cały kod oferty',
        // 'O_ENOVA'    => 'Numer z eNova',
        // 'O_V_DIGIT'  => 'Wersja oferty cyfrą',
        // 'O_V_NUMBER' => 'Wersja oferty literą',

        if (~~params.order && !params.user.order_scheme || (params.b2cOfferScheme && !params.user.b2c_offer_scheme || !params.user.offer_scheme)) {
            logger.error(new Error('Brak ustawionego schematu numeracji'));
            return {key: ['N_ALL']};
        }

        const elems = OfferKeyService.parseScheme(~~params.order ? params.user.order_scheme : (params.b2cOfferScheme ? params.user.b2c_offer_scheme : params.user.offer_scheme));
        let date;
        if (params.subOffer && params.originalOfferDate) {
            const tmpDate = params.originalOfferDate.split(/[- ]/);
            date = new Date(tmpDate[0], tmpDate[1] - 1, tmpDate[2]);
        } else {
            date = new Date();
        }
        const key  = [];
        let elem = '';
        let parsedDef;
        let num;
        let applicationNumber = '';
        if (!core.isNode()) {
            applicationNumber = core.parseJson(localStorage.getItem('applicationNumber'));
            applicationNumber = applicationNumber[params.user.id];
        }

        for (let i = 0; i < elems.length; i++) {
            if (Common.isObject(params.def) && Object.keys(params.def).indexOf(elems[i].type) >= 0 && params.def[elems[i].type]) {
                parsedDef = core.parseJson(params.def[elems[i].type], true);
                elem = Common.isArray(parsedDef) ? parsedDef.join('') : params.def[elems[i].type];
            } else if (elems[i].type === 'STRING') {
                elem = elems[i].value || '';
            } else if (elems[i].type === 'N_ALL' || elems[i].type === 'N_DAY' || elems[i].type === 'N_MONTH' || elems[i].type === 'N_YEAR' || elems[i].type === 'N_DEALER') {
                num = elem = params.number ? params.number : (params.offlineNumber ? this.getOfflineNumber(elems[i].type, applicationNumber, params.dealerId) : 'OFFER');
            } else if (elems[i].type === 'K_CLIENT') {
                elem = params.client && params.client.tmp_id != null ? (params.client.reference ? params.client.reference : 'CLIENT') : '';
            } else if (elems[i].type === 'K_DEALER') {
                elem = params.user.dealercode || '';
            } else if (elems[i].type === 'K_PRODUCER') {
                elem = params.user.sellercode || '';
            } else if (elems[i].type === 'K_INITIAL') {
                elem = params.user.initial || '';
            } else if (elems[i].type === 'K_MARKET') {
                elem = params.user.market;
            } else if (elems[i].type === 'D_YYYY') {
                elem = String(date.getFullYear());
            } else if (elems[i].type === 'D_YY') {
                elem = date.getFullYear().toString().substr(2, 2);
            } else if (elems[i].type === 'D_Y') {
                elem = date.getFullYear().toString().substr(3, 1);
            } else if (elems[i].type === 'D_MM') {
                elem = String(date.getMonth() + 1);
            } else if (elems[i].type === 'D_DD') {
                elem = String(date.getDate());
            } else if (elems[i].type === 'V_DIGIT') {
                elem = ~~params.version ? params.version + (params.offlineNumber ? 'C' + applicationNumber : '') : '';
            } else if (elems[i].type === 'V_NUMBER') {
                elem = ~~params.version ? '0abcdefghijklmnopqrstuvwxyz'.charAt(params.version) + (params.offlineNumber ? 'C' + applicationNumber : '') : '';
            } else if (elems[i].type === 'O_NUM') {
                elem = params.offerNumber || '';
            } else if (elems[i].type === 'O_ENOVA') {
                elem = params.enova || '0000';
            } else if (elems[i].type === 'O_V_DIGIT') {
                elem = ~~params.offerVersion ? params.offerVersion + (params.offlineNumber ? 'C' + applicationNumber : '') : '';
            } else if (elems[i].type === 'O_V_NUMBER') {
                elem = ~~params.offerVersion ? '0abcdefghijklmnopqrstuvwxyz'.charAt(params.offerVersion) + (params.offlineNumber ? 'C' + applicationNumber : '') : '';
            } else if (elems[i].type === 'K_CUSTOM') {
                elem = params.customKeyValue ? params.customKeyValue : '';
            } else {
                elem = elems[i].type;
            }

            if (elems[i].b2cOfferSymbol && !params.b2cOfferSource) {
                elem = '';
            }

            elem = core.zeroPad(elem, elems[i].pad);
            elem = (elems[i].empty && !elem) ? elems[i].empty : elem;
            elem = elem.toUpperCase();
            if (elem == '' && elems[i].removePrev && Common.isDefined(key[i - 1])) {
                key[i - 1] = '';
            }

            key.push(elem);
            elem = '';
        }

        return {key, num};
    }

    /**
     * Tworzy tablice elementow skladowych kodu oferty
     * @param  {string} scheme schemat
     * @return {array}         elementy schematu
     */
    static parseScheme(scheme) {
        return scheme.match(/(\{[^\}]*\}|[^\{]+)/gi).map(function afterMapScheme(e) {
            return {
                type      : e.charAt(0) === '{' ? e.replace('{', '').replace('}', '').split(':')[0] : 'STRING',
                value     : e.charAt(0) === '{' ? e.replace('{', '').replace('}', '') : e,
                pad       : ~~e.replace('{', '').replace('}', '').split(':')[1],
                empty     : e.replace('{', '').replace('}', '').split(':')[2] || null,
                removePrev: e.replace('{', '').replace('}', '').split(':')[3] == 1 ? true : false,
                b2cOfferSymbol: e.replace('{', '').replace('}', '').split(':')[4] == 1 ? true : false,
            };
        });
    }

    static getOfflineNumber(type, applicationNumber, dealerId) {

        let offlineNumbers = <any>localStorage.getItem('offlineNumbers');
        if (!offlineNumbers) {
            offlineNumbers = {
                N_ALL: {},
                N_DEALER: {},
                N_YEAR: {},
                N_MONTH: {},
                N_DAY: {},
            };
        } else {
            offlineNumbers = core.parseJson(offlineNumbers);
        }

        let key = {
            'N_ALL': 0,
            'N_DEALER': dealerId || -1,
            'N_YEAR': format(new Date(), 'yyyy'),
            'N_MONTH': format(new Date(), 'yyyyMM'),
            'N_DAY': format(new Date(), 'yyyyMMdd'),
        };

        Object.keys(offlineNumbers).forEach(elem => {
            if (offlineNumbers[elem][key[elem]]) {
                offlineNumbers[elem][key[elem]]++;
            } else {
                offlineNumbers[elem][key[elem]] = 1;
            }
        });

        localStorage.setItem('offlineNumbers', core.stringJson(offlineNumbers));

        return 'C' + applicationNumber + '.' + offlineNumbers[type][key[type]];
    }

    static elemInOfferNumber(num, code, user) {
        if (!Common.isArray(num)) {
            num = core.parseJson(num);
        }
        const index = OfferKeyService.parseScheme(user.order_scheme).map((e, i) => e.type === code ? i : null).filter(e => e !== null)[0];
        return num[index] || '';
    }
}
