import { ColorsPageComponent } from './../colors-page/colors-page.component';
import { FabricsPageComponent } from './../fabrics-page/fabrics-page.component';
import { PleatedBlindFacade } from './../+state/pleated-blind.facade';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { _, StepComponent, ModalService } from '@icc/configurator/shared';
import { APP_CONFIG, AppConfigFactory } from '@icc/common';
import { Subscription } from 'rxjs';

@Component({
    selector: 'icc-fabrics',
    templateUrl: './fabrics.component.html',
    styleUrls: ['./fabrics.component.scss'],
})
export class FabricsComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Tkanina');
    static stepIcon = {
        ligature: 'waves'
    };

    public configurator = 'pleated_blind';
    public stepId = 'fabric';
    public title = _('PLEATEDBLIND|Tkanina');

    fabrics = [];
    colors = [];
    
    selectedMainFabric;
    selectedAdditionalFabric;
    selectedProfilesColor;
    selectedMainFabric$ = this.blindFacade.selectedMainFabric$;
    selectedAdditionalFabric$ = this.blindFacade.selectedAdditionalFabric$;
    selectedProfilesColor$ = this.blindFacade.selectedProfilesColor$;
    selectedVariant$ = this.blindFacade.selectedVariant$;
    availableFabrics$ = this.blindFacade.availableFabrics$;
    availableColors$ = this.blindFacade.availableColors$;

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(APP_CONFIG) protected config: AppConfigFactory,
        private blindFacade: PleatedBlindFacade,
        private modalService: ModalService,
    ) {
        super();
    }
    ngOnInit() {
        this.subscriptions.push(
            this.selectedMainFabric$.subscribe(fabric => {
                this.selectedMainFabric = fabric;
            }),
            this.selectedAdditionalFabric$.subscribe(fabric => {
                this.selectedAdditionalFabric = fabric;
            }),
            this.selectedProfilesColor$.subscribe(color => {
               this.selectedProfilesColor = color; 
            }),
            this.availableFabrics$.subscribe(fabrics => {
                this.fabrics = fabrics
            }),
            this.availableColors$.subscribe(colors => {
                this.colors = colors
            })
        )
    }
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    setMainFabric(fabric) {
        this.blindFacade.setMainFabric(fabric);
    }

    setAdditionalFabric(fabric) {
        this.blindFacade.setAdditionalFabric(fabric);
    }

    setProfilesColor(color) {
        this.blindFacade.setProfilesColor(color);
    }

    selectMainFabric() {
        this.modalService.open({
            pageComponent: FabricsPageComponent,
            resolve: {
                fabrics: () => this.fabrics,
                selectedFabricId: this.selectedMainFabric.id
            }
        }).result.then(result => {
            this.setMainFabric(result);
            this.changeMainFabricColor(result);
        })
    }

    selectAdditionalFabric() {
        this.modalService.open({
            pageComponent: FabricsPageComponent,
            resolve: {
                fabrics: () => this.fabrics,
                selectedFabricId: this.selectedAdditionalFabric.id
            }
        }).result.then(result => {
            this.setAdditionalFabric(result);
            this.changeAdditionalFabricColor(result);
        })
    }

    changeMainFabricColor(fabric) {
        this.modalService.open({
            pageComponent: ColorsPageComponent,
            resolve    : {
            colors: () => this.colors,
            selectedColorId: this.selectedMainFabric.color.id
            }
        }).result.then(result => {
            fabric = {...fabric, color: result}
            this.setMainFabric(fabric)
        })
    }

    changeAdditionalFabricColor(fabric) {
        this.modalService.open({
            pageComponent: ColorsPageComponent,
            resolve    : {
            colors: () => this.colors,
            selectedColorId: () => this.selectedAdditionalFabric.color.id
            }
        }).result.then(result => {
            fabric = {...fabric, color: result}
            this.setAdditionalFabric(fabric)
        })
    }

    changeProfilesColor() {
        this.modalService.open({
            pageComponent: ColorsPageComponent,
            resolve    : {
            colors: () => this.colors,
            selectedColorId: () => this.selectedProfilesColor.id
            }
        }).result.then(result => {
            this.setProfilesColor(result);
        })
    }
}


