import angular from 'angular';

/**
 * Funkcja nazw kolorów
 * @param  {object} Core Core
 */
export default function ColorNameFilter(Core) {
    'ngInject';

    return function(colorId, colors) {
        var color = Core.fIdO(colors, colorId);
        if (angular.isObject(color)) {
            return color.name;
        }
    };
}
