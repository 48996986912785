<ng-container *ngIf="renderMultipleLocks(); then multipleLocks; else singleLock"></ng-container>

<ng-template #multipleLocks>
    <ng-container *ngFor="let lock of lockBoxes">
        <icc-select-box
            [title]="lock.title"
            [description]="lock.description"
            [imageUrl]="lock.imageUrl"
            (select)="openLocksOptions(lock)"
            [buttonText]="'INTERFACE|Zmień' | translate"
        ></icc-select-box>
    </ng-container>
</ng-template>

<ng-template #singleLock>
    <icc-list
        [searchable]="true"
        [items]="locks"
        [itemTemplate]="'itemImgTitle'"
        itemImgHeight="medium"
        (select)="selectLock($event)"
        [selected]="selectedLock$ | async"
    ></icc-list>
</ng-template>
