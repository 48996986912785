import { Pipe, PipeTransform, Inject } from '@angular/core';

@Pipe({name: 'systemsByTag'})
export class SystemsByTagPipe implements PipeTransform {

    constructor() {}

    transform(data: string) {
        return data
    }
}
