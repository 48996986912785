import { Common } from '@icc/common/Common';
import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import {Injectable} from '@angular/core';

@Injectable()
export class PriceHandlePositionService {

    constructor() {}

    /**
     * Dodawanie dopłaty do zmiany pozycji klamki
     * @param  {Number} price         Cena
     * @param  {Array} PriceElems     Składowe ceny
     * @param  {Object} model         Model
     * @param  {Object} colors        Kolory
     * @param  {Number} sashesCount   Liczba skrzydeł
     * @param  {Object} NoPriceCauses Powody braku ceny
     * @return {Number}               Cena
     */
    @PriceFunc({
        shortName: 'handlePosition',
        data: {
            system: 'conf.System',
            sashes: 'conf.Sashes',
        }
    })
    suppHandlePosition({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {system, sashes}): PriceSegment[] {
        if (Common.isObject(system)
            && Common.isDefined(system['handle_position_surcharge'])
            && parseFloat(system['handle_position_surcharge']) > 0
        ) {
            const sashesCount = sashes.filter(sash => sash.type.type === 'K' && sash.type.handle_position && sash.type.handle_position !== 'T').length;
            const supp = parseFloat(system['handle_position_surcharge']) * sashesCount;
            return supp ? <PriceSegment[]>[{
                type: 'handlePosition',
                baseValue: supp,
                value: supp,
                valueType: 'value',
                data: {
                    id   : system.id,
                    name : system.name,
                    count: sashesCount,
                }
            }] : [];
        }

        return [];
    }
}

