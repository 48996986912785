import { Injectable } from '@angular/core';
import { ConfigurationsService, EventBusService, WindowActiveConfiguration } from '@icc/common';
import { LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { ProfilesService } from '@icc/common/profiles.service';
import { Casing } from '@icc/window';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CasingsService {

    private isCasingAvailableForFrame$ = new BehaviorSubject<boolean>(false);
    
    constructor(
        private configurationsService: ConfigurationsService<'window'>,
        private profilesService: ProfilesService,
        private eventBusService: EventBusService,
    ) {

        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            () => { }
        );

        this.eventBusService.subscribe<any[]>(['setFrameProfile'], data => {
            this.validateCasingAndFixIssues(
                data.activeConfiguration as WindowActiveConfiguration
            );
        });
    }

    setIsCasingAvailableForFrame(value: boolean) {
        this.isCasingAvailableForFrame$.next(value);
    }

    get isCasingAvailableForFrame() {
        return this.isCasingAvailableForFrame$.getValue();
    }

    setCasing(
        casing: Casing,
        innnerCasingWidth?: number,
        casingWallOverlap?: number,
        casingHitchPoint?: number,
        conf = this.configurationsService.conf.Current
    ) {
        if (!casing) {
            this.unsetCasing(conf);
        } else if (casing) {
            conf.Casing.isCasingSelected = true,
            conf.Casing.id = casing.id,
            conf.Casing.name = casing.name;
            conf.Casing.code = casing.code;
            conf.Casing.innerWidth = innnerCasingWidth || casing.innerWidth,
            conf.Casing.wallOverlap = casingWallOverlap || casing.wallOverlap;
            conf.Casing.hitchPoint = casingHitchPoint;
        }
    }

    unsetCasing(conf = this.configurationsService.conf.Current) {
        conf.Casing.isCasingSelected = false;
        conf.Casing.id = null;
        conf.Casing.name = null;
        conf.Casing.code = null;
        conf.Casing.innerWidth = null;
        conf.Casing.wallOverlap = null;
        conf.Casing.hitchPoint = null;
    }

    matchCasingsToFrame(profileId: number) {
        const profilesCasingsIds = this.profilesService.getProfilesCompatibleProfiles();
        const casingsRelatedToProfile = profilesCasingsIds.filter(
            c => Number(c.profile_id) === profileId
        );

        const casings = this.profilesService.getCasings();
        
        return casings.filter(c => casingsRelatedToProfile.some(p => c.id === Number(p.compatible_profile_id)));
    }

    getAvailableFramesWithCasings(conf = this.configurationsService.conf.Current) {
        const availableFrames = this.profilesService.getFilteredProfiles(conf, 'frame');
        
        const frames: any[] = []; 
        availableFrames.forEach(frame => {
            if (this.profilesService.matchProfileToFrame(frame.id, 'casing').length > 0) {
                    frames.push(frame);
                }
            }
        );
        
        return frames;
    }

    getCasingBasedOnWallWidth(wallWidth: number, casingHitchPoint: number, casings: Casing) {
        return casings.filter(p => p.innerCasingWidth + casingHitchPoint === wallWidth);
    }

    getWallOverlapsBasedOnInnerCasingWidth(casings: any, innerCasingWidth: number) {
        const casingsArr = casings.filter(c => c.innerCasingWidth === innerCasingWidth)
        return casingsArr.map(p => p.casingWallOverlap);
    }

    getCasingBasedOnInnerWidthAndWallOverlap(width: number, wallOverlap: number, casings: Casing) {
        return casings.find(p => p.innerCasingWidth === width && p.casingWallOverlap === wallOverlap);
    }

    validateCasingAndFixIssues(conf = this.configurationsService.conf.Current) {
        const pauseId = this.eventBusService.pause(['setFrameProfile']);
        try {
            const casing = this.profilesService.getProfilesCompatibleProfiles();
            const viableCasings = casing.filter(x => Number(x.compatible_profile_id) === conf.Casing.id)
            const isCasingViableForFrame = conf.Frames.some(f =>
                f.frame.some(f => f.side !== "bottom" && viableCasings
                    .some(x => Number(x.profile_id) === f.profileId)
                )
            )

            if (!isCasingViableForFrame) {
                this.unsetCasing(conf);
                this.setIsCasingAvailableForFrame(false);
            }

        } finally {
            this.eventBusService.resume(['setFrameProfile'], pauseId);
        }
    }
}
