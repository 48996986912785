import * as angular from 'angular';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { SystemsComparisonService } from './systems-comparison.service';
import { SystemsComparisonModalComponent } from './systems-comparison-modal/systems-comparison-modal.component';
import { SystemsComparisonDetailsModalComponent } from './systems-comparison-details-modal/systems-comparison-details-modal.component';
import { SystemsSelectModalComponent } from './systems-select-modal/systems-select-modal.component';
import { PositionsGroupsModalComponent } from './positions-groups-modal/positions-groups-modal.component';
import { OptionsModalComponent } from './options-modal/options-modal.component';
import { ConfiguratorPageModalComponent } from './configurator-page-modal/configurator-page-modal.component';
import { ConfiguratorPageContainerComponent } from './configurator-page-container/configurator-page-container.component';
import { FillingsModalComponent } from './fillings-modal/fillings-modal.component';
import { ColorsModalComponent } from './colors-modal/colors-modal.component';
import { SystemsModalComponent } from './systems-modal/systems-modal.component';

export default angular
    .module('icc.configurator.steps.window.systems-comparison', [])
    .component('systemsComparisonModal', SystemsComparisonModalComponent)
    .component('systemsComparisonDetailsModal', SystemsComparisonDetailsModalComponent)
    .component('configuratorPageModalComponent', ConfiguratorPageModalComponent)
    .component('systemsSelectModal', SystemsSelectModalComponent)
    .component('positionsGroupsModal', PositionsGroupsModalComponent)
    .component('optionsModal', OptionsModalComponent)
    .component('fillingsModal', FillingsModalComponent)
    .component('colorsModal', ColorsModalComponent)
    .component('systemsModal', SystemsModalComponent)
    .directive(
        'iccConfiguratorPageContainer',
        downgradeComponent({ component: ConfiguratorPageContainerComponent })
    )
    .factory('SystemsComparisonService', downgradeInjectable(SystemsComparisonService))
    .name;
