<mat-card>
    <mat-card-header>
        <img mat-card-avatar class="accessory-box-img"
             [src]="'/files/filling/' + (pageData.filling?.image || '')"
             [alt]="pageData.filling?.name"
             defaultImg>
        <mat-card-title class="accessory-box-title">{{pageData.filling?.name}}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="panel-type">
          <ng-container *ngIf="pageData.filling?.type !== 'door_panels'">
            <ng-container *ngFor="let filling of pageData.filteredFillings">
                <ng-container [ngSwitch]="filling.panel_type">
                    <div matRipple *ngSwitchCase="'panel_type_inset'" (click)="setPanelType('Inset', filling)" [class.selected]="filling.id === selectedFilling.id">
                        <div class="img">
                            <img src="/application/dist/web/img/layouts/default/panel1.png">
                        </div>
                        <p><a>{{'DOOR|Wypełnienie wsadowe' | translate}} ({{filling.thickness_mm | unitmm:1:1}})</a></p>
                    </div>
                    <div matRipple *ngSwitchCase="'panel_type_outer'" (click)="setPanelType('Outer', filling)" [class.selected]="filling.id === selectedFilling.id">
                        <div class="img">
                            <img src="/application/dist/web/img/layouts/default/panel2.png">
                        </div>
                        <p><a>{{'DOOR|Nakładka zewnętrzna' | translate}} ({{filling.thickness_mm | unitmm:1:1}})</a></p>
                    </div>
                    <div matRipple *ngSwitchCase="'panel_type_double'" (click)="setPanelType('Double', filling)" [class.selected]="filling.id === selectedFilling.id">
                        <div class="img">
                            <img src="/application/dist/web/img/layouts/default/panel3.png">
                        </div>
                        <p><a>{{'DOOR|Nakładka obustronna' | translate}} ({{filling.thickness_mm | unitmm:1:1}})</a></p>
                    </div>
                    <div matRipple *ngSwitchCase="'panel_type_inner'" (click)="setPanelType('Inner', filling)" [class.selected]="filling.id === selectedFilling.id">
                        <div class="img">
                            <img src="/application/dist/web/img/layouts/default/panel4.png">
                        </div>
                        <p><a>{{'DOOR|Nakładka wewnętrzna' | translate}} ({{filling.thickness_mm | unitmm:1:1}})</a></p>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
      </div>
      <icc-select-box [title]="panelGlazingBox?.title"
                [description]="panelGlazingBox?.name"
                [imageUrl]="panelGlazingBox?.img"
                [buttonText]="'INTERFACE|Zmień' | translate"
                [descriptionTemplate]="panelGlazingBoxTemplate"
                [descriptionContext]="{box: panelGlazingBox}"
                (select)="changePanelGlazing()"
                *ngIf="pageData.filling?.panel_glazings_ids?.length > 0"
                ></icc-select-box>
  </mat-card-content>
  <mat-card-actions>
      <button mat-raised-button color="primary" (click)="save()"
        >{{ 'INTERFACE|OK' | translate }}</button>
  </mat-card-actions>
</mat-card>

<ng-template #panelGlazingBoxTemplate let-box="box">
  <p class="glassTypeDescription">
      {{box.name}}<br>
      U<sub>g</sub> = {{box.u}}
  </p>
</ng-template>

