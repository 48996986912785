import { Accessory } from '../../parts/common/Accessory';
import { RollerShutter } from '../../parts/roller_shutter/RollerShutter';

/**
 * Aktualizacja konfiguracji z wersji 3.x
 */
export function changesInVersion4(oldConfiguration, dataRequiredToUpdate) {
    const configuration = {
        $version: 4,
        type: 'roller_shutter',
        name: '',
        price: null,
        priceNoMargin: null,
        priceAfterDiscounts: null,
        quantity: 1,
        priceSegments: [],
        priceSegmentsNoMargin: [],
        priceParts: [],
        pricePartsNoMargin: [],
        discountGroups: {},
        discountGroupsNoMargin: {},
        title: '',
        description: '',
        attachments: [],
        valid: {},
        accessories: [],
        rollerShutter: null,
        timeLimit: null,
        timeLimitsStack: [],
        parameters: {
            weight: null,
        },
    };
    configuration.name = oldConfiguration.Name;
    configuration.price = oldConfiguration.Price;
    configuration.priceNoMargin = oldConfiguration.PriceNoMargin;
    configuration.priceAfterDiscounts =
        oldConfiguration.PriceAfterDiscounts || oldConfiguration.Price;
    configuration.quantity = oldConfiguration.Quantity;
    configuration.priceSegments = oldConfiguration.PriceSegments;
    configuration.priceSegmentsNoMargin = oldConfiguration.PriceSegmentsNoMargin;
    configuration.priceParts = oldConfiguration.PriceParts;
    configuration.pricePartsNoMargin = oldConfiguration.PricePartsNoMargin;
    configuration.discountGroups = oldConfiguration.DiscountGroups;
    configuration.discountGroupsNoMargin = oldConfiguration.DiscountGroupsNoMargin;
    configuration.title = oldConfiguration.Title;
    configuration.description = oldConfiguration.Description;
    configuration.attachments = oldConfiguration.Attachments;
    configuration.valid = oldConfiguration.valid;
    configuration.timeLimit = oldConfiguration.timeLimit;
    configuration.timeLimitsStack = oldConfiguration.timeLimitsStack;
    if (oldConfiguration.Accessories) {
        configuration.accessories = oldConfiguration.Accessories.map(
            accessory => new Accessory(accessory)
        );
    }

    configuration.rollerShutter = new RollerShutter(oldConfiguration.RollerShutter);
    configuration.parameters = {
        weight: oldConfiguration.Weight,
    };
    return configuration;
}
