const SystemsComparisonDetailsModalTemplate = require('ngtemplate-loader!./systems-comparison-details-modal.component.html');
import SystemsComparisonDetailsModalController from './systems-comparison-details-modal.controller';

export const SystemsComparisonDetailsModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: SystemsComparisonDetailsModalTemplate,
  controller: SystemsComparisonDetailsModalController
};
