import { Injectable, Inject } from '@angular/core';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { EventBusService } from '@icc/common/event-bus.service';
import { ConfiguratorsDataService, LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { core } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';
import { AlignmentsService } from './alignments.service';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { PriceService } from '@icc/price';
import { ProfilesService } from '@icc/common/profiles.service';
import { TranslateService } from '@icc/common/translate.service';
import { ValidationService } from '@icc/common/configurators/validation.service';
import { IssuesService, IssueLevel } from '@icc/helpers';
import { BrowserShapeService } from './shape.service';
import { IccSashType } from '@icc/common/data-types';
import { SashesLayoutService } from './sashes-layout.service';
import { SplitFrameService } from './split-frame.service';
import { FixSashThresholdService } from './fix-sash-threshold.service';
import { WarrantyService } from '@icc/legacy/price/warranty.service';
import { ConstructionLimitationService } from '../steps/window/dimensions/construction-limitation.service';
import { HandlesService } from '../steps/window/handles/handles.service';

@Injectable()
export class SashTypesService {
    sashTypes: IccSashType[] = [];

    constructor(
        private configuratorsDataService: ConfiguratorsDataService,
        private eventBusService: EventBusService,
        private alignmentsService: AlignmentsService,
        private warrantyService: WarrantyService,
        private parametersService: ParametersService,
        private priceService: PriceService,
        private profilesService: ProfilesService,
        private translateService: TranslateService,
        private validationService: ValidationService,
        private issuesService: IssuesService,
        private shapeService: BrowserShapeService,
        private constructionLimitationService: ConstructionLimitationService,
        private handlesService: HandlesService,
        private sashesLayoutService: SashesLayoutService,
        private splitFrameService: SplitFrameService,
        private fixSashThresholdService: FixSashThresholdService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration<LoadedConfiguratorsDataValue>(
            ['initializedConfigurator', 'loadedConfiguratorsData'],
            data => this.init()
        );

        this.eventBusService.subscribe(['setSystem', 'loadedProfiles'], data => {
            this.setSashesTypes(data.activeConfiguration as WindowActiveConfiguration);
        });
    }

    /**
     * Zmiana niedostępnych typów kwater po zmianie systemu
     *
     * @param {WindowActiveConfiguration} conf Wybrana konfiguracja
     */
    setSashesTypes(conf: WindowActiveConfiguration) {
        if (!this.validationService.isValidElements(conf, ['system', 'loadedProfiles'])) {
            return;
        }
        const compatibleTypes = {
            // 'K': 'OKL',
            D: 'OD',
            DS: 'ODS',
            DSH: 'ODSH',
            DRA: 'DOA',
            DRP: 'DOP',
        };

        const availableSashTypes = conf.System.available_sash_types;
        const defaultSashType = this.sashTypes.find(el => (!availableSashTypes?.length || availableSashTypes.includes(el.id)) && el.type === 'F');
        const defaultPassiveSashTypes = this.sashTypes.filter(el => (!availableSashTypes?.length || availableSashTypes.includes(el.id)) && el.passive);
        const altDefaultOutOpenTypes = this.sashTypes.filter(el => (!availableSashTypes?.length || availableSashTypes.includes(el.id)) && el.type === 'OD');
        const altDefaultInOpenTypes = this.sashTypes.filter(el => (!availableSashTypes?.length || availableSashTypes.includes(el.id)) && el.type === 'D');

        const indexes = [];

        conf.Sashes.forEach(sash => {
            let options = conf.System.door_type ? [] : ['bottom'];
            if (sash.nearMullions.bottom > -1 && !conf.System.door_type) {
                const bottomSashes = conf.Mullions.find(el => el.id === sash.nearMullions.bottom)
                    .multiAlignBottom;

                if (sash.type.type === 'SD' || bottomSashes.some(el => el.type.type === 'SU')) {
                    options = ['bottom_in_top_sash'];
                }
            } else if (sash.nearMullions.top > -1 && !conf.System.door_type) {
                const topSashes = conf.Mullions.find(el => el.id === sash.nearMullions.top)
                    .multiAlignTop;

                if (sash.type.type === 'SU' || topSashes.some(el => el.type.type === 'SD')) {
                    options = ['bottom_in_bottom_sash'];
                }
            }
            const profiles = this.profilesService.getFilteredProfiles(conf, conf.System.door_type ? 'virtual_door_sash' : 'sash', {
                and: options,
            });

            const innerProfiles = profiles.some(el => !el.options.includes('outward_opening') || el.type === 'virtual_door_sash');
            const outerProfiles = profiles.some(el => el.options.includes('outward_opening') || el.type === 'virtual_door_sash');

            let index: number;
            let type: string;
            let valid = true;

            if (!sash.type.out_open && !innerProfiles) {
                index = Object.keys(compatibleTypes).indexOf(sash.type.type);
                type = index !== -1 ? Object.values(compatibleTypes)[index] : null;
                valid = false;
            }

            if (sash.type.out_open && !outerProfiles) {
                index = Object.values(compatibleTypes).indexOf(sash.type.type);
                type = index !== -1 ? Object.keys(compatibleTypes)[index] : null;
                valid = false;
            }

            if (availableSashTypes?.length && !availableSashTypes.includes(sash.type.id)) {
                valid = false;
            }

            if (!valid) {
                let sashType = this.sashTypes.find(
                    el => (type ? el.type === type : true) && (!availableSashTypes?.length || availableSashTypes.includes(el.id))
                    && (sash.type.handle_position ? el.handle_position === sash.type.handle_position : !el.handle_position)
                );

                if (!sashType && sash.type?.passive && defaultPassiveSashTypes.length) {
                    sashType = defaultPassiveSashTypes.find(el => el.handle_position === sash.type.handle_position && el.out_open === sash.type.out_open) || defaultPassiveSashTypes[0];
                }

                if (!sashType) {
                    if (defaultSashType) {
                        sashType = defaultSashType;
                    } else if (innerProfiles && altDefaultInOpenTypes.length) {
                        sashType = altDefaultInOpenTypes.find(el => el.handle_position === sash.type.handle_position) || altDefaultInOpenTypes[0];
                    } else if (outerProfiles && altDefaultOutOpenTypes.length) {
                        sashType = altDefaultOutOpenTypes.find(el => el.handle_position === sash.type.handle_position) || altDefaultOutOpenTypes[0];
                    }
                }

                if (sashType) {
                    this.setType(sash, sashType, conf);

                    indexes.push(sash.index);
                }
            }
        });

        if (indexes.length) {
            this.issuesService.simpleRegister(
                'fixSashTypeAfterSetSystem',
                `Zaktualizowano typy skrzydeł w podanych kwaterach. Powód: brak pasujących profili.`,
                this.translateService.instant(
                    'WINDOW|Zaktualizowano typy skrzydeł w podanych kwaterach: {indexes}. Powód: brak pasujących profili.',
                    {
                        indexes: indexes.join(', '),
                    }
                ),
                conf,
                {
                    logLevel: IssueLevel.NONE,
                    extra: {
                        indexes
                    },
                    blockAddToOffer: false,
                }
            );
        }
    }

    mirrorSashType(sash, conf: WindowActiveConfiguration){
        const isLeft = sash.type.handle_position === 'L';
        const newType = this.sashTypes.find(el=> {
            return el.type === sash.type.type && el.handle_position === (isLeft ? "R" : "L");
        });
        if (newType){
            this.setType(sash,newType,conf)
        }
    }

    /**
     * Ustawia typ skrzydła.
     *
     * @param {Sash} sash Skrzydło.
     * @param {object} type Typ skrzydła.
     */
    setType(sash: ActiveSash, type: IccSashType, conf: WindowActiveConfiguration) {
        sash.type = core.copy(type);

        if (Common.isDefined(sash.type) && sash.type.type === 'F') {
            sash.intSashes = [];
            sash.intMullions = [];
            sash.intAlignments = [];
            sash.intEdgeSashes = { top: [], bottom: [], left: [], right: [] };
        }

        if (
            (Common.isUndefined(sash.intSashes) || Common.isUndefined(sash.intSashes[0]))
            && sash.type.type !== 'F'
        ) {
            sash = this.sashesLayoutService.createInternalSash(sash, conf);
        }

        this.checkSashesType(conf);
        this.eventBusService.post({
            key: 'changedSashes',
            value: {},
            conf
        });
        this.fixSashThresholdService.validateThresholdAlignmentsAndFixIssues(conf);
        this.splitFrameService.automaticallySplitFrames(conf);
        this.shapeService.setShapes(conf);
        this.handlesService.checkIsOneHandleAndAllHasHandle(conf, true);
        this.handlesService.refreshTypes(conf);

        this.constructionLimitationService.findReinforcement(conf);
        this.priceService.count();
        this.parametersService.count(conf);
        this.warrantyService.check(conf);
        conf.Layout.changed = true;

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
            conf
        });
    }

    /**
     * Aktualizuje typy skrzydeł w konstrukcji.
     */
    refreshTypes(conf: WindowActiveConfiguration) {
        for (let i = conf.Sashes.length - 1; i >= 0; i--) {
            let sash = conf.Sashes[i];

            if (Common.isDefined(sash.type) && sash.type.type === 'F') {
                sash.intSashes = [];
                sash.intMullions = [];
                sash.intEdgeSashes = {
                    top: [],
                    bottom: [],
                    left: [],
                    right: [],
                };
            }

            if (
                (Common.isUndefined(sash.intSashes) || Common.isUndefined(sash.intSashes[0]))
                && sash.type.type !== 'F'
            ) {
                sash = this.sashesLayoutService.createInternalSash(sash, conf);
            }
        }
        this.checkSashesType(conf);
        this.splitFrameService.automaticallySplitFrames(conf);
    }

    /**
     * Sprawdza użyte typy skrzydeł w konstrukcji.
     */
    checkSashesType(conf: WindowActiveConfiguration) {
        let allFix = true;
        let allFixSash = true;
        let allFunc = true;

        conf.OwnedSashesTypes = {
            window: false,
            doorActive: false,
            doorPassive: false,
            doorTopLight: false,
            doorLeftLight: false,
            doorRightLight: false
        };
        for (let i = conf.Sashes.length - 1; i >= 0; i--) {
            const sash = conf.Sashes[i];

            if (Common.isDefined(sash.type)) {
                if (sash.type.type === 'F' || sash.type.type === 'FF' || sash.type.type === 'OFF') {
                    allFunc = false;
                    if (sash.type.type === 'F') {
                        allFixSash = false;
                    } else {
                        allFix = false;
                    }
                } else {
                    allFix = false;
                    allFixSash = false;
                }

                if (sash.type.type === 'DRA' || sash.type.type === 'DOA') {
                    conf.OwnedSashesTypes.doorActive = true;
                } else if (sash.type.type === 'DRP' || sash.type.type === 'DOP') {
                    conf.OwnedSashesTypes.doorPassive = true;
                } else {
                    conf.OwnedSashesTypes.window = true;
                }

                if (sash.type.type === 'F') {
                    const frame = conf.Frames.find(f => f.id === sash.frameId);
                    if (sash.nearMullions.left === -1 && ((frame && frame.x) || 0) + sash.rx === 0) {
                        if (
                            sash.nearMullions.top === -1
                            && ((frame && frame.x) || 0) + sash.rx + sash.rWidth === conf.Width
                        ) {
                            conf.OwnedSashesTypes.doorTopLight = true;
                        } else {
                            conf.OwnedSashesTypes.doorLeftLight = true;
                        }
                    } else if (
                        sash.nearMullions.right === -1
                        && ((frame && frame.x) || 0) + sash.rx > 0
                    ) {
                        conf.OwnedSashesTypes.doorRightLight = true;
                    }
                }
            }
        }
        if (allFix) {
            conf.SashesType = 'Fix';
        } else if (allFixSash) {
            conf.SashesType = 'FixSash';
        } else if (allFunc) {
            conf.SashesType = 'Func';
        } else {
            conf.SashesType = 'Mix';
        }
    }

    /**
     * Funkcja inicjujaca
     */
    private init() {
        this.sashTypes = this.configuratorsDataService.data.sashTypes;
    }
}
