<div class="container">
  <h3>{{filling?.name}}</h3>
  <div class="image">
    <img [src]="'/files/filling/' + (fillingViewSide === 'inner' && filling.image_in ? filling.image_in : filling.image)" alt="" defaultImg>
  </div>
  <button mat-raised-button color="primary" (click)="close()" *ngIf="button">
    {{ 'INTERFACE|Wybierz' | translate }}
  </button>
</div>

