import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import {
    MosquitoSystem,
    Variant,
    Colors,
    Montage,
    WindowLine,
    ProfilePositions,
    MuntinPosition,
} from './parts/mosquito';

import { MosquitoActiveConfiguration } from './MosquitoActiveConfiguration';
import { core } from '../helpers';
import { changesInVersion6 } from './converters/mosquito';
import { MosquitoNetType } from '../data-types/Mosquito';
import { Accessory } from './parts/common';

export class MosquitoConfiguration implements Configuration {
    $version = 7;
    type: 'mosquito' = 'mosquito';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscountsInCurrency: number | null = null;
    priceBeforePromotions: number | null = null;
    priceGross: number | null = null;
    priceGrossAfterDiscounts: number | null = null;
    priceGrossBeforePromotions: number | null = null;
    quantity = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceSegmentsBeforePromotions: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    pricePartsBeforePromotions: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    title = '';
    description = '';
    attachments: any[] = [];
    deletedAttachments: any[] = [];
    valid: {} = {};
    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];

    system: MosquitoSystem = null;
    height = 1300;
    width = 1300;
    sashHeight = 1300;
    sashWidth = 1300;
    heightModify = 0;
    widthModify = 0;
    circuit = 0;
    variant: Variant = null;
    netType: MosquitoNetType | null = null
    colors: Colors = {
        frame: null,
        net: null,
        panel: null,
    };
    direction: 'L' | 'R' | null = null;
    montage: Montage = null;
    windowLine: WindowLine = null;
    profilePositions: ProfilePositions = {};
    holdersWidth: number = null;
    muntinPosition: MuntinPosition = null;
    hasThickSeal = false;
    sealThickness: number | null = null;

    static converters = {
        6: changesInVersion6,
    };
    accessories: Accessory[] = [];

    constructor(
        configuration?,
        dataRequiredToUpdate?: any,
        conversion = true,
        configuratorsDataService?,
        colorsDefaultsService?
    ) {
        if (MosquitoConfiguration.is(configuration) && conversion) {
            this.runConversion(configuration, dataRequiredToUpdate);
        } else {
            if (MosquitoConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (MosquitoActiveConfiguration.is(configuration)) {
                    this.name = configuration.Name;
                    this.price = configuration.Price;
                    this.priceNoMargin = configuration.PriceNoMargin;
                    this.priceAfterDiscountsInCurrency =
                        configuration.PriceAfterDiscountsInCurrency || configuration.Price;
                    this.priceGross = configuration.PriceGross || configuration.Price;
                    this.priceGrossAfterDiscounts =
                        configuration.PriceGrossAfterDiscounts || configuration.Price;
                    this.quantity = configuration.Quantity;
                    this.priceSegments = configuration.PriceSegments;
                    this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.priceSegmentsBeforePromotions = configuration.PriceSegmentsBeforePromotions;
                    this.priceParts = configuration.PriceParts;
                    this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.pricePartsBeforePromotions = configuration.PricePartsBeforePromotions;
                    this.discountGroups = configuration.DiscountGroups;
                    this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                    this.title = configuration.Title;
                    this.description = configuration.Description;
                    this.attachments = configuration.Attachments;
                    this.valid = configuration.valid;
                    this.attributes = configuration.attributes;

                    this.system = {
                        id: Number(configuration.System.id),
                        name: configuration.System.name,
                        titleImage: configuration.System.title_image,
                    };
                    this.height = Number(configuration.Height);
                    this.width = Number(configuration.Width);
                    this.sashHeight = Number(configuration.Shape.sashHeight);
                    this.sashWidth = Number(configuration.Shape.sashWidth);
                    this.heightModify = Number(configuration.HeightModify);
                    this.widthModify = Number(configuration.WidthModify);
                    this.circuit = Number(configuration.Shape.circuit);
                    if (configuration.Type && configuration.Type.id) {
                        this.variant = {
                            id: Number(configuration.Type.id),
                            name: configuration.Type.name,
                            hasDirection: configuration.Type.has_direction,
                            profilePosition: configuration.Type.profile_position,
                            extraColor: configuration.Type.extra_color,
                            hasSashDimensions: configuration.Type.has_sash_dimensions,
                            hasThickSeal: configuration.Type.has_thick_seal
                        };
                    }
                    if (configuration.NetType && configuration.NetType.id) {
                        this.netType = configuration.NetType;
                    }

                    ['frame', 'net', 'panel'].forEach(side => {
                        if (configuration.Colors[side] && configuration.Colors[side].name) {
                            this.colors[side] = {
                                id: Number(configuration.Colors[side].id),
                                name: configuration.Colors[side].name,
                                code: configuration.Colors[side].code,
                                RAL: Boolean(configuration.Colors[side].RAL),
                                isDefault: configuration.Colors[side].isDefault,
                            };
                        }
                    });
                    if (this.variant && this.variant.hasDirection) {
                        this.direction = configuration.Direction === 'left' ? 'L' : 'R';
                    }
                    if (configuration.Montage && configuration.Montage.id) {
                        this.montage = {
                            id: Number(configuration.Montage.id),
                            name: configuration.Montage.name,
                        };
                    }
                    if (configuration.WindowLine && configuration.WindowLine.id) {
                        this.windowLine = {
                            id: Number(configuration.WindowLine.id),
                            name: configuration.WindowLine.content,
                            windowLineId: Number(configuration.WindowLine.window_line_id),
                        };
                    }
                    ['up', 'down', 'left', 'right'].forEach(side => {
                        if (configuration.ProfilePositions[side]) {
                            this.profilePositions[side] = configuration.ProfilePositions[side];
                        }
                    });
                    this.holdersWidth = Number(configuration.HoldersWidth);
                    this.muntinPosition = {
                        type: configuration.MuntinType,
                    };
                    if (this.muntinPosition.type === 'custom') {
                        this.muntinPosition.position = Number(configuration.MuntinPosition);
                    }

                    this.hasThickSeal = configuration.hasThickSeal;
                    this.sealThickness = configuration.sealThickness;
                    this.accessories = (configuration.Accessories ?? []).map<Accessory>(
                        accessory => new Accessory(accessory)
                    );
                }
            }
        }
    }

    public static is(configuration): configuration is MosquitoConfiguration {
        return configuration instanceof MosquitoConfiguration || configuration.$version;
    }

    protected runConversion(configuration, dataRequiredToUpdate: any) {
        let updatedConfiguration = core.copy(configuration);

        for (let version = configuration.$version + 1; version <= this.$version; version++) {
            updatedConfiguration.$version = version;
            if (MosquitoConfiguration.converters[version] && typeof MosquitoConfiguration.converters[version] === 'function') {
                updatedConfiguration = MosquitoConfiguration.converters[version](
                    updatedConfiguration,
                    dataRequiredToUpdate
                );
            }
        }
        (Object as any)
            .entries(updatedConfiguration)
            .forEach(([key, value]) => (this[key] = value));
    }
}
