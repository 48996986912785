(window as any).__Zone_ignore_on_properties = [];
const idbtargets = [
    IDBIndex.prototype,
    IDBRequest.prototype,
    IDBOpenDBRequest.prototype,
    IDBDatabase.prototype,
    IDBTransaction.prototype,
    IDBCursor.prototype,
];
idbtargets.forEach((target) => {
    (window as any).__Zone_ignore_on_properties.push({
      target,
      ignoreProperties: [
        'upgradeneeded', 'complete', 'abort', 'success', 'error', 'blocked', 'versionchange', 'close'
      ]
    });
});

(window as any).__Zone_ignore_on_properties.push({
    target: WebSocket.prototype,
    ignoreProperties: [
        'close', 'error', 'open', 'message'
    ]
});
(window as any).__Zone_ignore_on_properties.push({
    target: XMLHttpRequest.prototype,
    ignoreProperties: [
        'loadstart', 'progress', 'abort', 'error', 'load', 'progress', 'timeout', 'loadend',
        'readystatechange'
    ]
});
(window as any).__Zone_ignore_on_properties.push({
    target: XMLHttpRequestEventTarget.prototype,
    ignoreProperties: [
        'loadstart', 'progress', 'abort', 'error', 'load', 'progress', 'timeout', 'loadend',
        'readystatechange'
    ]
});
(window as any).__Zone_disable_XHR = true;
(window as any).__icc_black_listed_events = ['scroll', 'mousemove', 'wheel', 'click', 'load', 'mouseup', 'mousedown', 'resize', 'touchmove', 'touchstart', 'touchend', 'touchcancel', 'pointermove', 'pointerdown', 'pointerup', 'pointerleave', 'pointercancel', 'dragstart', 'drag', 'dragenter', 'dragleave', 'dragover', 'drop', 'dragend', 'dblclick', 'focus', 'blur', 'focusin', 'focusout', 'keydown', 'keyup', 'keypress', 'change', 'input', 'submit', 'contextmenu', 'reset', 'select', 'show', 'toggle', 'mouseenter', 'mouseleave', 'mouseover', 'mouseout', 'animationstart', 'animationend', 'animationiteration', 'transitionend', 'message', 'beforeunload', 'hashchange', 'popstate', 'pagehide', 'pageshow', 'unload', 'online', 'offline', 'storage', 'messageerror', 'languagechange', 'offline', 'online', 'unhandledrejection', 'rejectionhandled', 'beforeinstallprompt', 'appinstalled', 'beforeprint', 'afterprint', 'devicemotion', 'deviceorientation', 'deviceorientationabsolute', 'deviceorientationabsolute', 'deviceorientation', 'devicemotion', 'deviceorientationabsolute', 'deviceorientationabsolute', 'deviceorientation', 'devicemotion', 'deviceorientationabsolute', 'deviceorientationabsolute', 'deviceorientation', 'devicemotion', 'deviceorientationabsolute', 'deviceorientationabsolute', 'deviceorientation', 'devicemotion', 'deviceorientationabsolute', 'deviceorientationabsolute', 'deviceorientation', 'devicemotion', 'deviceorientationabsolute', 'deviceorientationabsolute', 'deviceorientation', 'devicemotion', 'deviceorientationabsolute', 'deviceorientationabsolute', 'visibilitychange', 'readystatechange'];
(window as any).__zone_symbol__BLACK_LISTED_EVENTS = [...(window as any).__icc_black_listed_events];
