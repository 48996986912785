import { Directive, ElementRef, HostListener, Inject, Injector, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { RollerDimensionsService } from './dimensions.service';

@Directive({
  selector: '[iccDimensionsValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DimensionsValidatorDirective, multi: true }]
})
export class DimensionsValidatorDirective implements Validator {
    @Input() iccDimensionsValidator: any;
    @Input() isWidthDimension: false;
    @Input() rollerShutterIndex: number;
    constructor(
        private rollerDimensionsService: RollerDimensionsService,
        private _elementRef: ElementRef,
        private injector: Injector,
    ) {
    }
    areDimensionsOk;
    ngOnInit(){
        this.onChange(this.iccDimensionsValidator);
    }
    @HostListener('change',['$event.target.value'])
    onChange(value: number) {
        this.areDimensionsOk = this.rollerDimensionsService.validateDimension(Number(value), this.isWidthDimension, this.rollerShutterIndex);
    }
    validateDimensions(control: AbstractControl){
        if (!this.areDimensionsOk){
            return {invalidDimensions: {value: "control.value"}}
        } else {
            control.setErrors({'invalidDimensions': null})
            return null;
        }
    }
    validate(control: AbstractControl): ValidationErrors | null {
        control.markAsTouched();
        this.areDimensionsOk = this.rollerDimensionsService.validateDimension(Number(control.value), this.isWidthDimension, this.rollerShutterIndex);
        return this.validateDimensions(control);
    }
}
