import { Pipe, PipeTransform, Inject } from '@angular/core';
import { unitMFormatter } from '@icc/helpers';
import { TranslateService } from '@icc/common/translate.service';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { UserService } from '@icc/common/user.service';

@Pipe({
    name: 'unitm',
})
export class UnitmPipe implements PipeTransform {
    constructor(
        private userService: UserService,
        private translateService: TranslateService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {}

    transform(value: any, hasunit = 0, inmm = 0, mb = 0, dimension_unit = '') {
        const dimensionUnit =
            dimension_unit || this.userService.get().dimension_unit || this.config().dimensionUnit;
        const mbUnit = this.translateService.instant('DIMENSION|mb');
        return unitMFormatter(
            value,
            this.config().IccConfig,
            hasunit,
            inmm,
            mb,
            dimensionUnit,
            mbUnit
        );
    }
}
