import { NgModule } from '@angular/core';
import { ModelService } from './model.service';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    providers: [ModelService],
})
export class ModelsModule {
    constructor() {}
}
