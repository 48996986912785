import {NgModule} from '@angular/core';

import { ColorsDefaultsService } from './colors-defaults.service';
import { SealColorsDefaultsService } from './seal-colors-defaults.service';
import { SiliconeColorsDefaultsService } from './silicone-colors-defaults.service';
import { ColorMappingService } from './colors-mapping.service'
import { ColorRestrictionService } from './colors-restriction.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        ColorsDefaultsService,
        SealColorsDefaultsService,
        SiliconeColorsDefaultsService,
        ColorMappingService,
        ColorRestrictionService
    ],
})
export class ColorsModule { }
