import { Component, OnInit } from '@angular/core';
import { PageComponent, _, SharedFacade } from '@icc/configurator/shared';
import { DrawService, TranslateService, ConfigurationsService, core } from '@icc/common';
import { AccessoriesService } from '@icc/legacy/configurator/steps/window/accessories/accessories.service';
import { WindowFacade } from '../+state/window.facade';
import { ProfilesModalService } from '../profiles/profiles-modal.service';
import { BrowserFramesService } from '@icc/legacy/configurator/layout/frames.service';
import { ExtensionsService } from '@icc/legacy/configurator/layout/extensions.service';

@Component({
    selector: 'icc-accessories-location-page',
    templateUrl: './accessories-location-page.component.html',
    styleUrls: ['./accessories-location-page.component.scss'],
})
export class AccessoriesLocationPageComponent extends PageComponent implements OnInit {
    public title = _('WINDOW|Dodaj akcesoria do całej konstrukcji oraz poszczególnych kwater.');

    drawOptions: any = null;

    width$ = this.windowFacade.width$;
    height$ = this.windowFacade.height$;

    constructor(
        public drawService: DrawService,
        private sharedFacade: SharedFacade,
        private translateService: TranslateService,
        private configurationsService: ConfigurationsService<'window'>,
        private accessoriesService: AccessoriesService,
        private windowFacade: WindowFacade,
        private profilesModalService: ProfilesModalService,
        private framesService: BrowserFramesService,
        private extensionsService: ExtensionsService
    ) {
        super();

        const conf = this.configurationsService.conf.Current;
        const defaultConf = this.configurationsService.conf.Default;
        this.drawOptions = Object.assign({}, drawService.options, {
            base: {
                side: 'inner'
            },
            shape: {
                buttons: [],
            },
            sash: {
                buttons: [
                    {
                        class: 'ico-plus',
                        positions: ['center'],
                        title: this.translateService.instant(
                            'INTERFACE|Wybierz akcesoria dla kwatery'
                        ),
                        condition: response => typeof response.sash.parentId === 'undefined',
                        onClick: response => {
                            const sash = core.fIdO(
                                this.configurationsService.conf.Current.Sashes,
                                response.sash.id
                            );
                            if (sash) {
                                this.accessoriesService.openModalAccessories(sash, sash.index).then((result) => {
                                    if (result) {
                                        this.sharedFacade.closePage();
                                    }
                                });
                            }
                        },
                    },
                ],
            },
            extensions: {
                actions: {
                    onClick: (response: any) =>
                        ((response.extensions[response.index].side === 'bottom'
                            && !conf.foundationProfile)
                        || response.extensions[response.index].side !== 'bottom')
                        && this.profilesModalService.changeProfile(conf, defaultConf, 'extension', {
                            extension: response.extensions[response.index].extension,
                            position: response.extensions[response.index].side,
                        }),
                    onHover: 'single-hover',
                },
                buttons: [
                    {
                        class: 'ico-plus',
                        positions: ['bottom'],
                        title: this.translateService.instant(
                            'INTERFACE|Wybierz akcesoria lub profile dla boku'
                        ),
                        condition: (response: any) => true,
                        buttons: [
                            {
                                class: 'ico-plus',
                                condition: response =>
                                    (response.index === 'bottom'
                                        && this.configurationsService.conf.Current.Shape.shape
                                            !== 'circle')
                                    || (response.index !== 'bottom'
                                        && !this.configurationsService.conf.Current.hasRoller
                                        && this.configurationsService.conf.Current.Shape.shape === 'rect'),
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz akcesoria dla boku'
                                ),
                                onClick: response =>
                                    this.accessoriesService.openModalAccessories(response.index).then(() => {
                                        this.sharedFacade.closePage();
                                    }),
                                positions: ['bottom'],
                            },
                            {
                                class: 'ico-sill',
                                condition: (response: any) =>
                                    ((response.index === 'bottom'
                                        && conf.Shape.shape !== 'circle'
                                        && !conf.foundationProfile)
                                    || (response.index !== 'bottom'
                                        && conf.Shape.shape === 'rect'
                                        && !conf.hasRoller)) && this.isExtensionAvailable('bottom'),
                                onClick: (response: any) =>
                                    this.profilesModalService.selectExtensionAndPut(
                                        conf,
                                        response.index
                                    ),
                                positions: ['bottom'],
                                title: this.translateService.instant(
                                    'INTERFACE|Dodaj poszerzenie'
                                ),
                            },
                        ],
                    },
                    {
                        class: 'ico-plus',
                        positions: ['top'],
                        title: this.translateService.instant(
                            'INTERFACE|Wybierz akcesoria lub profile dla boku'
                        ),
                        condition: (response: any) => true,
                        buttons: [
                            {
                                class: 'ico-plus',
                                condition: response =>
                                    (response.index === 'bottom'
                                        && this.configurationsService.conf.Current.Shape.shape
                                            !== 'circle')
                                    || (response.index !== 'bottom'
                                        && !this.configurationsService.conf.Current.hasRoller
                                        && this.configurationsService.conf.Current.Shape.shape === 'rect'),
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz akcesoria dla boku'
                                ),
                                onClick: response =>
                                    this.accessoriesService.openModalAccessories(response.index).then(() => {
                                        this.sharedFacade.closePage();
                                    }),
                                positions: ['top'],
                            },
                            {
                                class: 'ico-sill',
                                condition: (response: any) =>
                                    ((response.index === 'bottom'
                                        && conf.Shape.shape !== 'circle'
                                        && !conf.foundationProfile)
                                    || (response.index !== 'bottom'
                                        && conf.Shape.shape === 'rect'
                                        && !conf.hasRoller)) && this.isExtensionAvailable('top'),
                                onClick: (response: any) =>
                                    this.profilesModalService.selectExtensionAndPut(
                                        conf,
                                        response.index
                                    ),
                                positions: ['top'],
                                title: this.translateService.instant(
                                    'INTERFACE|Dodaj poszerzenie'
                                ),
                            },
                        ],
                    },
                    {
                        class: 'ico-plus',
                        positions: ['left', 'right'],
                        title: this.translateService.instant(
                            'INTERFACE|Wybierz akcesoria lub profile dla boku'
                        ),
                        condition: (response: any) => true,
                        buttons: [
                            {
                                class: 'ico-plus',
                                condition: response =>
                                    (response.index === 'bottom'
                                        && this.configurationsService.conf.Current.Shape.shape
                                            !== 'circle')
                                    || (response.index !== 'bottom'
                                        && !this.configurationsService.conf.Current.hasRoller
                                        && this.configurationsService.conf.Current.Shape.shape === 'rect'),
                                title: this.translateService.instant(
                                    'INTERFACE|Wybierz akcesoria dla boku'
                                ),
                                onClick: response =>
                                    this.accessoriesService.openModalAccessories(response.index).then(() => {
                                        this.sharedFacade.closePage();
                                    }),
                                positions: ['left', 'right'],
                            },
                            {
                                class: 'ico-sill',
                                condition: (response: any) =>
                                    ((response.index === 'bottom'
                                        && conf.Shape.shape !== 'circle'
                                        && !conf.foundationProfile)
                                    || (response.index !== 'bottom'
                                        && conf.Shape.shape === 'rect'
                                        && !conf.hasRoller)) && this.isExtensionAvailable(response.index),
                                onClick: (response: any) =>
                                    this.profilesModalService.selectExtensionAndPut(
                                        conf,
                                        response.index
                                    ),
                                positions: ['left', 'right'],
                                title: this.translateService.instant(
                                    'INTERFACE|Dodaj poszerzenie'
                                ),
                            },
                        ],
                    },
                    {
                        class: 'ico-remove',
                        condition: (response: any) => {
                            const frameEdges = this.framesService.getFrameProfilesIndexesOnTheEdge(
                                response.index,
                                conf
                            );
                            return (
                                ((response.index === 'bottom'
                                    && conf.Shape.shape !== 'circle')
                                    || (response.index !== 'bottom'
                                        && conf.Shape.shape === 'rect'
                                        && !conf.hasRoller))
                                && frameEdges
                                && conf.SideProfiles.filter(sp =>
                                    sp.framesId.some(f =>
                                        frameEdges.some(
                                            fE =>
                                                f.id === fE.frameId
                                                && f.edges.includes(
                                                    fE.frameEdgeIndex
                                                )
                                        )
                                    )
                                ).length
                            );
                        },
                        onClick: (response: any) =>
                            this.extensionsService.removeExtensionFromSide(
                                conf,
                                response.index
                            ),
                        positions: ['bottom', 'left', 'right', 'top'],
                        title: this.translateService.instant(
                            'INTERFACE|Usuń poszerzenie'
                        ),
                    },
                ],
            },
            onInterfaceClick: () => {},
        });
    }
    ngOnInit(): void {}

    isExtensionAvailable(side: 'bottom' | 'top' | 'left' | 'right', conf = this.configurationsService.conf.Current) {
        return this.extensionsService.isExtensionAvailable(side, conf);
    }
}
