import * as angular from 'angular';

/**
 * Kontroler siatek
 */
export class MosquitoCtrl {

    conf: any;
    drawOptions: any;

    constructor(
        private $scope,
        private $filter,
        private $uibModal,
        private DrawService,
        private PriceService,
        private ConfigurationsService,
        private MosquitoSystemsService,
        private ConfiguratorsAvailabilityService,
        private EventBusService,
    ) {
        'ngInject';

        this.conf = this.ConfigurationsService.conf.Current;

        this.drawOptions = angular.merge({}, this.DrawService.options, {
            sash: {
                buttons: [
                    {
                        class: 'ico-plus',
                        positions: ['center'],
                        title: $filter('translate')('WINDOW|Dodaj siatkę'),
                        condition: response => this.allowModalMosquito(this.conf, response.sash),
                        onClick  : response => this.openModalMosquito(this.conf, response.sash),
                    },
                ],
            },
            onInterfaceClick: () => {
                $scope.$digest();
            }
        });
    }

    allowModalMosquito(conf, sash) {
        if (!sash) {
            return conf.Sashes.some(el => el.type.type !== 'F');
        }

        sash = conf.Sashes.find(s => s.id === sash.id);

        if (!sash) {
            return false;
        }

        let leftSide = conf.Mullions.filter(function filterDividers(e) {
            return e.id === sash.nearMullions.left;
        });
        leftSide = angular.isDefined(leftSide[0]) && angular.isDefined(leftSide[0].multiAlignLeft) && angular.isDefined(leftSide[0].multiAlignLeft[0])
                ? leftSide[0].multiAlignLeft[0] : {type: {}};

        let rightSide = conf.Mullions.filter(function filterDividers(e) {
            return e.id === sash.nearMullions.right;
        });
        rightSide = angular.isDefined(rightSide[0]) && angular.isDefined(rightSide[0].multiAlignRight) && angular.isDefined(rightSide[0].multiAlignRight[0])
                ? rightSide[0].multiAlignRight[0] : {type: {}};

        return this.ConfiguratorsAvailabilityService.availableConfigs['mosquito']
            && ['K', 'D', 'DK', 'PSK', 'HS'].indexOf(sash.type.type) >= 0
            && (sash.type.handle_position === 'L'
                && ['DSC', 'HS'].indexOf(leftSide.type.type) === -1
                && (sash.type.type !== 'PSK'
                    || (sash.type.type === 'PSK'
                        && leftSide.type.type !== 'PSK'))
                || sash.type.handle_position !== 'L')
            && (sash.type.handle_position === 'R'
                && ['DSC'].indexOf(rightSide.type.type) === -1
                || sash.type.handle_position !== 'R')
            && ['window', 'hs'].indexOf(conf.System.confType) > -1;
    }

    openModalMosquito(conf, sash) {
        const modalInstance = this.$uibModal.open({
            templateUrl: 'modalMosquito.html',
            controller : 'ModalMosquitoCtrl as $ctrl',
            resolve    : {
                conf: () => conf,
                sash: () => sash,
            }
        });

        modalInstance.result.then(() => {
            this.PriceService.count();
        });

        modalInstance.closed.then(() =>
            this.EventBusService.post({key: 'processDependencies', value: null})
        );
    }

}
