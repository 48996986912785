import { GarageDoorDefaultsService } from './+state/garage-door-defaults.service';
import { TranslateService } from '@ngx-translate/core';
import { GarageDoorFacade } from './+state/garage-door.facade';
import { Injectable, OnDestroy } from '@angular/core';
import { ModalService, IssueLevel } from '@icc/helpers';
import { ModalListComponent } from './modal-list/modal-list.component';
import { _, IssuesService } from '@icc/configurator/shared';
import { ConfigurationsService, EventBusService } from '@icc/common';
import { Subscription } from 'rxjs';

@Injectable()
export class DrivesService implements OnDestroy {
  private subscriptions: Subscription[] = [];

  private allDrives: any[] = [];
  private selectedDimensions;
  private selectedGuidance;
  private selectedDrive;

  constructor(
      private modalService: ModalService,
      private garageDoorFacade: GarageDoorFacade,
      private eventBusService: EventBusService,
      private issuesService: IssuesService,
      private configurationsService: ConfigurationsService,
      private translateService: TranslateService,
      private defaultsService: GarageDoorDefaultsService
  ) {
    this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
      const conf = this.configurationsService.conf.Current;
      if (conf.type === 'garage_door') {
        this.subscriptions.push(
          this.garageDoorFacade.availableDrives$.subscribe(d => {
            this.allDrives = d;
          }),
          this.garageDoorFacade.selectedGuidance$.subscribe(g => {
            this.selectedGuidance = g;
            this.validateAndSetDefaultDrive();
          }),
          this.garageDoorFacade.selectedDimensions$.subscribe(d => {
            this.selectedDimensions = d;
            this.validateAndSetDefaultDrive();
          }),
          this.garageDoorFacade.selectedDrive$.subscribe(d => {
            if (d) {
              this.issuesService.unregister('no-garage-door-drive', conf);
            } else {
              this.registerNoDriveIssue(conf);
            }
            this.selectedDrive = d;
          })
        );
      }
    });
  }

  getFilteredDrives() {
    return this.allDrives.filter(drive => {
      return this.selectedDimensions 
            && this.selectedGuidance 
            && (drive.is_manual 
              || (!this.selectedGuidance.min_lintel_height_electrical || this.selectedGuidance.min_lintel_height_electrical <= this.selectedDimensions.lintelHeight))
    }) || [];
  }

  openDrivesModal() {
    const filteredDrives = this.getFilteredDrives();
    this.modalService
      .open({
          pageComponent: ModalListComponent,
          resolve: {
              items: filteredDrives,
              selectedItemId: this.selectedDrive.id,
              modalListTitle: _('GARAGEDOOR|Wybierz sterowanie'),
              modalListFilesDir: '/files/garagedoorcontrol/',
              itemImgAttributeName: 'img',
              showItemInfo: true,
              itemTemplate: 'itemImgTitle'
          },
      })
      .result.then(selectedDrive => {
          if (selectedDrive) {
              this.garageDoorFacade.setDrive(selectedDrive);
          }
      });
  }

  validateAndSetDefaultDrive() {
    const filteredDrives = this.getFilteredDrives();
    let isSelectedDriveStillAvailable = false;
    if (this.selectedDrive) {
      isSelectedDriveStillAvailable = filteredDrives.find(g => g.id == this.selectedDrive.id);
    }
    if (!isSelectedDriveStillAvailable) {
      const defaultDrive = filteredDrives.find(d => d.id == this.defaultsService.getDefaultDrive().id) || filteredDrives[0];
      this.garageDoorFacade.setDrive(defaultDrive);
    }
  }

  registerNoDriveIssue(conf) {
    this.issuesService.simpleRegister(
      'no-garage-door-drive',
      'Nie znaleziono pasujących sterowań.',
      this.translateService.instant('CONFIGURATOR|Wybrane sterowanie jest nieprawidłowe.'),
      conf,
      {
          logLevel: IssueLevel.NONE
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.map(el => el.unsubscribe());
  }
}
