import { Injectable, Inject } from '@angular/core';
import { Profile, ProfileSet } from '@icc/window';
import {
    LoadedConfiguratorsDataValue,
    ConfiguratorsDataService,
} from '@icc/common/configurators/configurators-data.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { ProfilesService } from '@icc/common/profiles.service';
import { ProfileSetsService } from '@icc/common/profile-sets.service';
import { EventBusService } from '@icc/common/event-bus.service';
import { core, logger } from '@icc/common/helpers';
import { ValidationService } from '@icc/common/configurators/validation.service';
import { ModalService } from '@icc/helpers';
import { ProfileSetsPageComponent } from './profile-sets-page/profile-sets-page.component';
import { ReplaySubject } from 'rxjs';
import { ConfigurationsService } from '@icc/common';

@Injectable()
export class BrowserProfileSetsService extends ProfileSetsService {
    constructor(
        private modalService: ModalService,
        private eventBusService: EventBusService,
        protected profilesService: ProfilesService,
        private validationService: ValidationService,
        private configurationsService: ConfigurationsService,
    ) {
        super(profilesService);
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            data => {
                this.loadProfileSets(data.value);
                if (data.activeConfiguration && WindowActiveConfiguration.is(data.activeConfiguration) && this.configurationsService.conf.Current) {
                    this.validateAndFixIssues(
                        data.activeConfiguration as WindowActiveConfiguration,
                        data.defaultConfiguration as WindowActiveConfiguration
                    );
                }
            }
        );

        this.eventBusService.subscribe('setSystem', data => {
            try {
                this.validateAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });
    }

    openProfileSetsModal(conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        const modalOptions = {
            component: 'profileSetsModal',
            pageComponent: ProfileSetsPageComponent,
            resolve: {
                profileSets: () => core.copy(this.getProfileSetsForSystem(conf.System.id)),
                profileShapes: () => core.copy(this.profilesService.getProfileShapesNames()),
                selectedProfileSet: () => conf.ProfileSet,
                defaultProfileSet: () => this.getDefaultSet(conf, defaultConf),
                conf: () => conf,
                selectedSet$: () => new ReplaySubject<any>()
            },
        };
        const modalInstance = this.modalService.open(modalOptions);
        modalInstance.result.then((profileSet: ProfileSet) => {
            if (profileSet){
                this.setSet(conf, defaultConf, profileSet);
            }
        });
    }

    validateAndFixIssues(conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        if (
            !this.loadedData
            || (conf.ProfileSet
                && conf.ProfileSet.systems.map(Number).includes(Number(conf.System.id))
                && conf.Frames.length
                && conf.Frames.every(frame => frame.frame.length > 0))
        ) {
            Object.assign(conf, this.validationService.valid(conf, 'profileSet'));
            this.eventBusService.post({
                key: 'validProfileSet',
                value: null,
                conf,
                defaultConf
            });
            return;
        }
        const defaultProfileSet = this.getDefaultSet(conf, defaultConf);
        if (defaultProfileSet) {
            this.setSet(conf, defaultConf, defaultProfileSet, true);
        }
    }

    setSet(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration,
        profileSet: ProfileSet,
        isDefault: boolean = false
    ) {
        conf.ProfileSet = core.copy(profileSet);
        if (conf.ProfileSet){
            conf.ProfileSet.isDefault = isDefault;
        }
        defaultConf.ProfileSet = core.copy(profileSet);
        defaultConf.ProfileSet.isDefault = isDefault;
        Object.assign(conf, this.validationService.valid(conf, 'profileSet'));
        this.eventBusService.post({
            key: 'setProfileSet',
            value: {
                profileSet,
            },
            conf,
            defaultConf
        });
    }
}
