import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepComponent, _, ModalService } from '@icc/configurator/shared';
import { iccListItem, iccListTab } from '@icc/configurator/ui';
import { SheathingService } from '@icc/legacy/configurator/steps/awning/sheathing.service';
import { EventBusService, ConfigurationsService } from '@icc/common';
@Component({
    selector: 'icc-awning-sheathing',
    templateUrl: './awning-sheathing.component.html',
    styleUrls: ['./awning-sheathing.component.scss'],
})
export class AwningSheathingComponent extends StepComponent implements OnInit, OnDestroy {
    static stepName = _('STEPS|Rodzaj poszycia');
    static stepIcon = {
        ligature: 'texture',
    };
    private subscriptions: any[] = [];
    public fabrics: any[] = [];
    public styles: any[] = [];
    public styleTabs: iccListTab[] = []
    public configurator = 'awning';
    public stepId = 'sheathing';
    public title = _('COLOR|Wybierz styl i rodzaj poszycia');
    
    styleId : number | null = null;

    constructor(
        private sheathingService: SheathingService,
        private eventBusService: EventBusService,
        private configurationsService: ConfigurationsService<'awning'>,
    ) {
        super();
        this.subscriptions.push(
            this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () =>
                this.init()
            )
        );
    }
    init() {
        this.fabrics = this.sheathingService.getFabrics();
        this.styles = this.sheathingService.getStyles()
            .map<iccListItem>(style =>  ({            
                    id: Number(style.id),
                    title: style.title,
                    imageUrl: '/files/awningfabricstyle/' + style.img,
                    tabs: [style.fabric_id]
                })
            );
        this.styleTabs = this.fabrics.map<iccListTab>(group => ({
            id: Number(group.id),
            name: group.name,
        }));
    }

    ngOnInit() {
        if (this.configurationsService.conf.Current) {
            this.styleId = Number(this.configurationsService.conf.Current.FabricStyle.id);
        }
        if (this.sheathingService.loadedData)
        {
            this.init();
        }
    }

    ngOnDestroy() {
        this.subscriptions.map(el => el.unsubscribe());
    }

    selectSheathing(item: iccListItem) {
        // item.tabs[0] = Id poszycia
        const fabric = this.sheathingService.fabrics.find(s => Number(s.id) == item.tabs[0]);
        const style = this.sheathingService.styles.find(s => Number(s.id) == item.id);
        this.sheathingService.selectFabric(fabric, null, null);
        this.sheathingService.selectStyle(style, null, null);
    }

}
