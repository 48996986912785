import { Common } from '../Common';

export default function ObjLengthFilter() {
    'ngInject';

    return function(data) {
        if (!Common.isObject(data)) {
            return 0;
        } else {
            return Object.keys(data).length;
        }
    };
}
