<icc-list [searchable]="true" [items]="reinforcements" [itemTemplate]="itemReinforcement" itemImgHeight="medium"
          [itemWidth]="'wide'" (select)="selectReinforcement($event)" [tabs]="null"></icc-list>

<ng-template #itemReinforcement let-item="iccListItem">
    <div class="item-imgTitleDescription">
        <p class="item-imgTitleDescription-title">{{item.name}}</p>
        <p class="item-imgTitleDescription-price">
            {{ 'OFFER|Cena' | translate }}:<br>
            <b>
                <span
                      *ngIf="item.price">{{priceMarket(item.price) | unitm:0:0:3 | currency: currency}}/<span
                      [innerHTML]="2|formatUnit"></span><br></span>
                <span
                      *ngIf="item.price === 0">{{ 'CONFIGURATOR|W cenie' | translate }}<br></span>
                <span
                      *ngIf="!item.price && item.price !== 0">{{ 'CONFIGURATOR|Do wyceny' | translate }}<br></span>
            </b>
        </p>
    </div>
</ng-template>
